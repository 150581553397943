import { CheckCircle, Circle } from "@mui/icons-material";
import { Chip } from "@mui/material";

const ChartLegend = ({
  label,
  color,
  checked,
  chipColor,
  labelColor,
}: {
  color: string;
  label: string;
  checked: boolean;
  chipColor: string;
  labelColor: string;
}) => {
  return (
    <Chip
      label={label}
      icon={
        checked ? (
          <CheckCircle className="!text-white" />
        ) : (
          <Circle
            sx={{
              fill: chipColor,
            }}
          />
        )
      }
      className="!h-10 !rounded-full !px-2 !text-white"
      sx={{
        color: "#464646",
        backgroundColor: color,
        "&:hover": {
          backgroundColor: color,
        },
        "& .MuiChip-label": {
          color: "#464646",
        },
      }}
    />
  );
};

export default ChartLegend;
