import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  ReactElement,
} from "react";
import combineReducers from "react-combine-reducers";
import { Action } from "./commonTypes";
import {
  AppConfigInitialState,
  AppConfigReducer,
  AppConfigReducerType,
  initialAppConfigState,
} from "./reducers/appConfig";
import {
  initialAssetsState,
  AssetsReducer,
  AssetsReducerInitialState,
  AssetsReducerType,
} from "./reducers/asset";
import {
  AssetSearchReducer,
  initialAssetSearchState,
  AssetSearchReducerInitialState,
  AssetSearchReducerType,
} from "./reducers/assetSearch";
import {
  initialAssetsListState,
  AssetsListReducer,
  AssetsListReducerInitialState,
  AssetsListReducerType,
} from "./reducers/assetsList";
import { geofenceInitialState, GeofenceReducer } from "./reducers/geofence";
import { LocationReducer, initialLocationState } from "./reducers/location";
import { PageReducer, initialPageState } from "./reducers/page";
import {
  PageReducerInitialState,
  LocationReducerInitialState,
  GeofenceReducerInitialState,
} from "./reducers/reducersTypes";
import {
  initialReportsState,
  ReportsReducer,
  ReportsReducerInitialState,
  ReportsReducerType,
} from "./reducers/report";
import {
  SelectedOrganizationReducer,
  SelectedOrganizationReducerInitialState,
  SelectedOrganizationReducerType,
  initialSelectedOrganizationState,
} from "./reducers/selectedOrganization";
import {
  TableReducer,
  initialTableState,
  TableInitialState,
  TableReducerType,
} from "./reducers/tableReducer";
import {
  initialThemeState,
  ThemeReducer,
  ThemeInitialState,
  ThemeReducerType,
} from "./reducers/theme";

// rewritten to typescript to avoid duplication of explicit types definitions in different modules
type AppContextState = {
  selectedOrganization: SelectedOrganizationReducerInitialState;
  assetsList: AssetsListReducerInitialState;
  assets: AssetsReducerInitialState;
  page: PageReducerInitialState;
  location: LocationReducerInitialState;
  assetSearch: AssetSearchReducerInitialState;
  table: TableInitialState;
  reports: ReportsReducerInitialState;
  theme: ThemeInitialState;
  appConfig: AppConfigInitialState;
  geofence: GeofenceReducerInitialState;
};

export type AppContextType = {
  state: AppContextState;
  dispatch: React.Dispatch<Action>;
};

type AppContextReducer = (
  state: AppContextState,
  action: Action
) => AppContextState;
type PageReducerType = (
  state: PageReducerInitialState | undefined,
  action: Action
) => PageReducerInitialState;
type LocationReducerType = (
  state: LocationReducerInitialState | undefined,
  action: Action
) => LocationReducerInitialState;
type GeofenceReducerType = (
  state: GeofenceReducerInitialState | undefined,
  action: Action
) => GeofenceReducerInitialState;

export const initialState = {
  selectedOrganization: initialSelectedOrganizationState,
  assetsList: initialAssetsListState,
  assets: initialAssetsState,
  page: initialPageState,
  location: initialLocationState,
  assetSearch: initialAssetSearchState,
  table: initialTableState,
  reports: initialReportsState,
  theme: initialThemeState,
  appConfig: initialAppConfigState,
  geofence: geofenceInitialState,
};

export const AppContext = createContext<AppContextType>({
  state: initialState,
  dispatch: () => {},
});

export const AppContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [rootReducerCombined, initialStateCombined] =
    combineReducers<AppContextReducer>({
      selectedOrganization: [
        SelectedOrganizationReducer as SelectedOrganizationReducerType,
        initialSelectedOrganizationState,
      ],
      assetsList: [
        AssetsListReducer as AssetsListReducerType,
        initialAssetsListState,
      ],
      assets: [AssetsReducer as AssetsReducerType, initialAssetsState],
      page: [PageReducer as PageReducerType, initialPageState],
      location: [LocationReducer as LocationReducerType, initialLocationState],
      geofence: [GeofenceReducer as GeofenceReducerType, geofenceInitialState],
      assetSearch: [
        AssetSearchReducer as AssetSearchReducerType,
        initialAssetSearchState,
      ],
      table: [TableReducer as TableReducerType, initialTableState],
      reports: [ReportsReducer as ReportsReducerType, initialReportsState],
      theme: [ThemeReducer as ThemeReducerType, initialThemeState],
      appConfig: [
        AppConfigReducer as AppConfigReducerType,
        initialAppConfigState,
      ],
    });

  const [state, dispatch] =
    useReducer<AppContextReducer>(rootReducerCombined, initialStateCombined) ||
    {};

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
