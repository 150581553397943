import { PsiWheelEndAxle, TPMS_Axle } from "./interfaces";

// Note: Think about merging ot with MAP_PSI_WHEEL_END_AXLE
export const MAP_TPMS_AXLE: Record<number, TPMS_Axle> = {
  1: TPMS_Axle.One,
  2: TPMS_Axle.Two,
  3: TPMS_Axle.Three,
};

export const defaultBorderStyling = "1px solid var(--gray)";

// We want to align a beautiful grid of cards, so we need to fix the height of the cards
export const TIRE_CARD_HEIGHT = 276;
export const TIRE_CARD_PADDING = 16;
export const WHEEL_END_CARD_HEIGHT = 210;
export const tireBoxDesign = {
  padding: "16px",
  borderRadius: "8px",
  width: "100%",
  backgroundColor: "var(--dashboard_subheader__bg)",
  border: defaultBorderStyling,
};

// We need a custom breakpoint for the desktop view, because the default breakpoint is too small
export const TPMS_TAB_DESKTOP_BREAKPOINT = 1780;

// Note: Think about merging ot with MAP_TPMS_AXLE
export const MAP_PSI_WHEEL_END_AXLE: Record<number, PsiWheelEndAxle> = {
  1: PsiWheelEndAxle.One,
  2: PsiWheelEndAxle.Two,
  3: PsiWheelEndAxle.Three,
};
