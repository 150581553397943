import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import { ProductData } from "../../../../../../../graphql/operations";
import { schema } from "./schema";

export const useProductForm = (
  initialValues: ProductData = {
    _id: "",
    asset_configuration: "",
    organization_name: "",
    product_code: "",
    product_name: "",
  }
) => {
  const form = useForm({
    resolver: yupResolver(schema),
    values: omitBy(initialValues, isNil),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form]
  );
  return { form, getValues, schema };
};
