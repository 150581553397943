import { memo, useState, FC, MouseEvent, ReactElement, useMemo } from "react";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import StopScreenShareOutlinedIcon from "@mui/icons-material/StopScreenShareOutlined";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import {
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { useAuthContext } from "../../../context/AuthContext";
import { DevicesTableData } from "../../../graphql/operations";
import { useFindAssetsByIdsForSharing } from "../../hooks/openSearchMongoPolyfillHooks/useFindAssetsByIdsForSharing";
import { useStopAssetsShare } from "../../hooks/openSearchMongoPolyfillHooks/useStopAssetsShare";
import { ConfirmationDialog } from "../ConfirmationDialog";
import ShareAssetsDialog from "./components/ShareAssetsDialog";
import TransferAssetsDialog from "./components/TransferAssetsDialog";
import { AssetToTransfer, DialogType, TypeTransfer } from "./helpers";

export interface TableActionsMenuProps {
  selectedRow: string[];
  apiRef: React.MutableRefObject<GridApiPremium> | null;
  typeTransfer: TypeTransfer;
  refetchDeviceData?: () => void;
  devices?: DevicesTableData[];
  assets?: Array<AssetToTransfer & { _id: string }>;
}

const TableActionsMenu: FC<TableActionsMenuProps> = ({
  selectedRow,
  typeTransfer,
  devices = [],
  assets = [],
}) => {
  const queryClient = useQueryClient();

  const [dialogToOpenState, setDialogToOpenState] =
    useState<DialogType | null>();
  const isTransferDialogOpen = dialogToOpenState === DialogType.Transfer;
  const isShareDialogOpen = dialogToOpenState === DialogType.Share;
  const isUnshareDialogOpen = dialogToOpenState === DialogType.Unshare;

  const { userRolePermissions } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { dispatch } = useAppContext();
  const isAssetsTransfer = typeTransfer === TypeTransfer.Assets;
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = (dialogType: DialogType) => {
    setDialogToOpenState(dialogType);
    setAnchorEl(null);
  };

  const dispatchSnack = (
    success: boolean,
    customTitle?: string,
    customMessage?: string
  ) => {
    const title =
      customTitle || (success ? "Transfer Successfully!" : "Transfer failed!");
    const defaultMessage = success
      ? `Selected ${typeTransfer} have been transferred successfully.`
      : "Something Went Wrong.";

    const text = customMessage ?? defaultMessage;
    const severity = success ? "success" : "error";
    return dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title,
        text,
        severity,
      },
    });
  };

  const {
    data: dataAssetsForSharing,
    isLoading: isLoadingAssetsForSharing,
    refetch: refetchAssetsForSharing,
  } = useFindAssetsByIdsForSharing(
    {
      assetIds: selectedRow,
    },
    {
      enabled: Boolean(selectedRow.length),
    }
  );

  const {
    mutateAsync: mutateStopAssetsShare,
    isLoading: isLoadingStopAssetsShare,
  } = useStopAssetsShare({
    onSuccess: () => {
      refetchAssetsForSharing();
      queryClient.invalidateQueries(["getAssetsForTableOS"]);
      dispatchSnack(
        true,
        "Unshare successful!",
        "Selected assets have been unshared successfully."
      );

      setDialogToOpenState(null);
    },
    onError: () => {
      dispatchSnack(false, "Unshare failed!");
    },
  });

  const handleDialogClose = () => {
    setDialogToOpenState(null);
  };

  const handleConfirmation = (confirmed?: boolean) => {
    if (confirmed) {
      (async () => {
        await mutateStopAssetsShare({
          input: {
            assetIds: selectedRow,
          },
        });
      })();
    } else {
      setDialogToOpenState(null);
    }
  };

  const atLeastOneSelected = useMemo(() => selectedRow?.length, [selectedRow]);

  type MenuItemType = {
    visible: boolean;
    handler: () => void;
    disabled: boolean;
    text: string;
    icon: ReactElement;
    testId: DialogType;
  };

  const menuItems: MenuItemType[] = [
    {
      visible: true,
      handler: () => handleDialogOpen(DialogType.Transfer),
      disabled: !atLeastOneSelected,
      text: `Transfer ${typeTransfer}`,
      icon: <CompareArrowsIcon fontSize="small" />,
      testId: DialogType.Transfer,
    },
    {
      visible: isAssetsTransfer,
      handler: () => handleDialogOpen(DialogType.Share),
      disabled: !atLeastOneSelected || !userRolePermissions.asset.share,
      text: "Share",
      icon: <ShareOutlinedIcon fontSize="small" />,
      testId: DialogType.Share,
    },
    {
      visible: isAssetsTransfer,
      handler: () => handleDialogOpen(DialogType.Unshare),
      disabled: !atLeastOneSelected || !userRolePermissions.asset.share,
      text: "Unshare",
      icon: <StopScreenShareOutlinedIcon fontSize="small" />,
      testId: DialogType.Unshare,
    },
  ];

  return (
    <>
      <Button
        data-testid="device-actions-button"
        onClick={handleClick}
        disabled={!atLeastOneSelected}
        className={`MuiButton-sizeSmall MuiButton-textSizeSmall !text-sm !font-bold !px-2 MuiSvgIcon-fontSizeMedium `}
        sx={{ color: atLeastOneSelected ? "var(--brand)" : "var(--dark-grey)" }}
      >
        <span className="MuiButton-startIcon MuiButton-iconSizeSmall">
          <TouchAppIcon
            sx={{
              fontSize: "1rem",
              marginRight: "0.4rem",
              marginLeft: "-0.125rem",
              marginTop: "-0.375rem",
            }}
          />
        </span>
        Actions
      </Button>
      <Menu
        id="basic-menu"
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems
          .filter((item) => item.visible)
          .map((item) => (
            <MenuItem
              onClick={item.handler}
              disabled={item.disabled}
              data-testid={`${item.testId}-item-action`}
              key={item.testId}
            >
              <ListItemText>{item.text}</ListItemText>
              <ListItemIcon
                sx={{
                  minWidth: "1.25rem!important",
                  paddingLeft: "1rem",
                }}
              >
                {item.icon}
              </ListItemIcon>
            </MenuItem>
          ))}
      </Menu>

      {isTransferDialogOpen && (
        <TransferAssetsDialog
          open={isTransferDialogOpen}
          onClose={handleDialogClose}
          onSnack={dispatchSnack}
          selectedAssetIds={selectedRow}
          typeTransfer={typeTransfer}
          devices={devices}
          assets={assets}
        />
      )}

      {isShareDialogOpen && (
        <ShareAssetsDialog
          open={isShareDialogOpen}
          onClose={handleDialogClose}
          onSnack={dispatchSnack}
          assets={dataAssetsForSharing}
          isLoadingAssets={isLoadingAssetsForSharing}
          refetchAssetsForSharing={refetchAssetsForSharing}
        />
      )}

      {isUnshareDialogOpen && (
        <ConfirmationDialog
          open={isUnshareDialogOpen}
          isLoading={isLoadingStopAssetsShare}
          handleConfirmationResult={handleConfirmation}
          title="Unshare Assets"
          message="Are you sure you want to unshare the selected assets?"
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
        />
      )}
    </>
  );
};

export default memo(TableActionsMenu);
