import { useMemo } from "react";
import { OrgData } from "../../graphql/operations";
import { useAvailableOrgs } from "./useAvailableOrgs";

export const useGetOrgById = (orgId?: string | null): OrgData | undefined => {
  const availableOrgs = useAvailableOrgs();

  return useMemo(
    () => availableOrgs.find((org) => org._id === orgId),
    [availableOrgs, orgId]
  );
};
