import { centroid, polygon, Position } from "@turf/turf";
import { Feature as FeatureType, Polygon, GeoJsonProperties } from "geojson";
import {
  DistanceUnit,
  GeofenceGeometry,
  GeofenceShape,
  Maybe,
} from "../../../../../graphql/operations";
import { convertSqKmToSqMiles } from "../../../../../utils/convertSqKmToSqMiles";
import { convertSqMetersToSqKm } from "../../../../../utils/convertSqMetersToSqKm";
import { formatNumber } from "../../../../../utils/formatters";

export type CircleProperties = {
  center: {
    lat: number;
    lng: number;
  };
  radius: number;
};

export const convertGeofenceGeometryToLatLngArray = (
  geometry: GeofenceGeometry
): google.maps.LatLng[] => {
  const latLngArray: google.maps.LatLng[] = [];

  if (geometry.coordinates && geometry.coordinates.length > 0) {
    geometry.coordinates.forEach((coordinateSet) => {
      if (coordinateSet) {
        coordinateSet.forEach((coordinate) => {
          if (coordinate !== null && coordinate.length === 2) {
            const lat = coordinate[1] as number;
            const lng = coordinate[0] as number;
            latLngArray.push(new google.maps.LatLng(lat, lng));
          }
        });
      }
    });
  }

  return latLngArray;
};

export const convertPolygonToGeoJSONFeature = (
  polygon: google.maps.Polygon
): FeatureType<Polygon, GeoJsonProperties> => {
  const paths: google.maps.LatLng[] = polygon.getPath().getArray();
  const coordinates: number[][] = [];

  // Convert the polygon path array to GeoJSON coordinates
  paths.forEach(function (latlng: google.maps.LatLng, index: number) {
    coordinates.push([latlng.lng(), latlng.lat()]);
    // Ensure the last point matches the first point
    if (index === paths.length - 1) {
      coordinates.push([paths[0].lng(), paths[0].lat()]);
    }
  });

  const feature: FeatureType<Polygon, GeoJsonProperties> = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [coordinates],
    },
  };

  const centroidPoint = centroid(feature);
  const [lng, lat] = centroidPoint.geometry.coordinates;

  const featureCentroid: FeatureType<Polygon, GeoJsonProperties> = {
    type: "Feature",
    properties: {
      centroid: { lat, lon: lng },
    },
    geometry: {
      type: "Polygon",
      coordinates: [coordinates],
    },
  };
  return featureCentroid;
};

export const calculateNumberOfPoints = (R: number) => {
  // Calculate circle deviation based on radius length
  const d = R < 1000 ? 0.1 : R < 10000 ? 0.2 : 0.5;

  // Calculate circle chord length (L):
  const h = R - d;
  const L = 2 * Math.sqrt(R * R - h * h);

  // Calculate central angle (tita), for a given chord len (L):
  const alpha = Math.asin(L / 2 / R);
  const tita = 2 * alpha;

  // Calculate number of points
  const numPoints = Math.ceil((2 * Math.PI) / tita);

  // Limit the number of points to 3142
  if (numPoints > 3142) {
    return 3142;
  }
  return numPoints;
};

export const convertCircleToGeoJSONPolygon = (
  circle: google.maps.Circle
): FeatureType<Polygon> | null => {
  const center = circle.getCenter();
  if (!center) return null;

  const radius = circle.getRadius();
  const points = [];
  const numPoints = calculateNumberOfPoints(radius);
  const degreeStep = 360 / numPoints;

  for (let i = 0; i < numPoints; i++) {
    const gpos = google.maps.geometry.spherical.computeOffset(
      center,
      radius,
      degreeStep * i
    );
    points.push([gpos.lng(), gpos.lat()]);
  }

  // Duplicate the last point to close the geojson ring
  points.push(points[0]);

  return {
    type: "Feature",
    properties: {
      subType: GeofenceShape.Circle,
      radius,
      centroid: {
        lat: center.lat(),
        lon: center.lng(),
      },
    },
    geometry: {
      type: "Polygon",
      coordinates: [points],
    },
  };
};

export const calculateCircleCenterAndRadius = (
  geometry: GeofenceGeometry
): CircleProperties => {
  const polygonCenter = centroid(polygon(geometry.coordinates as Position[][]));
  const center = {
    lat: polygonCenter.geometry.coordinates[1],
    lng: polygonCenter.geometry.coordinates[0],
  };
  const firstCoordinates = geometry.coordinates?.[0]?.[0];
  const circlePoint = {
    lat: firstCoordinates?.[1] as number,
    lng: firstCoordinates?.[0] as number,
  };
  const radius = google.maps.geometry.spherical.computeDistanceBetween(
    center,
    circlePoint
  );
  return {
    center,
    radius,
  };
};

export const calculatePolygonArea = (polygon: google.maps.Polygon) => {
  const area = google.maps.geometry.spherical.computeArea(polygon.getPath());
  return convertSqMetersToSqKm(area);
};

export const calculateCircleArea = (circle: google.maps.Circle) => {
  const radius = circle.getRadius();
  if (radius) {
    const area = Math.PI * Math.pow(radius, 2);
    return convertSqMetersToSqKm(area);
  }
};

export const convertGeofenceAreaUnit = (
  area: number,
  unit: Maybe<DistanceUnit> | undefined = DistanceUnit.Kilometers // TODO: Replace with DEFAULT_DISTANCE_UNIT
) => {
  if (unit === DistanceUnit.Miles) {
    area = convertSqKmToSqMiles(area);
  }
  return `${formatNumber(area, { maximumFractionDigits: 6 })} Square ${unit}`;
};
