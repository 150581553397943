import * as yup from "yup";
import { PSI_WHEEL_END_FORM_FIELDS } from "./constants";

export const sensorsPsiWheelEndSchema = yup.object().shape({
  typeOfAssignment: yup.string().required("Field is required!"),
  psiWheelEndSensorProfile: yup
    .string()
    .nullable()
    .when("typeOfAssignment", {
      is: (value: string) => {
        return value === PSI_WHEEL_END_FORM_FIELDS[0].options[1].id;
      },
      then: yup.string().required("Field is required!").nullable(),
      otherwise: yup.string().nullable().notRequired(),
    }),
});
