import { ReactNode } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

type IconPosition = "left" | "right";

export const getButtonIconProps = (
  iconPosition: IconPosition,
  icon: ReactNode
) => {
  switch (iconPosition) {
    case "left": {
      return { startIcon: icon ?? <ArrowBackIos /> };
    }
    case "right": {
      return { endIcon: icon ?? <ArrowForwardIos /> };
    }
    default: {
      return {};
    }
  }
};
