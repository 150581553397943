import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { IGeofenceFilters } from "../../../AssetsDataContext";

export const useGeofenceQueryBuilderForm = (
  initialValues: IGeofenceFilters | undefined
) => {
  const schema = yup.object().shape({
    parameters: yup.object().shape({
      filters: yup.string().optional(),
    }),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    values: omitBy(initialValues, isNil),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
