import { useState } from "react";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangeLabel } from "../constants";

interface DatePickerHook {
  startDate: Date | null;
  endDate: Date | null;
  setDateRange: React.Dispatch<React.SetStateAction<DateRange<Date>>>;

  selectedDateRangeOption: DateRangeLabel | null;
  setSelectedDateRangeOption: React.Dispatch<
    React.SetStateAction<DateRangeLabel | null>
  >;
}
interface DatePickerHookInput {
  defaultStartDate?: Date | null;
  defaultEndDate?: Date | null;
}

const useDatePicker = ({
  defaultStartDate,
  defaultEndDate,
}: DatePickerHookInput = {}): DatePickerHook => {
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    defaultStartDate ?? null,
    defaultEndDate ?? null,
  ]);
  const [selectedDateRangeOption, setSelectedDateRangeOption] =
    useState<DateRangeLabel | null>(null);

  // Aliases for date range shortcuts
  const startDate = dateRange[0] ?? null;
  const endDate = dateRange[1] ?? null;

  return {
    startDate,
    endDate,
    setDateRange,

    selectedDateRangeOption,
    setSelectedDateRangeOption,
  };
};

export default useDatePicker;
