import { FC, useMemo, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { SwitchElement } from "react-hook-form-mui";
import { Paper, Typography, Grid, Box } from "@mui/material";
import { HierarchySelectElement } from "../../../../shared/components/react-hook-form-mui";
import { useAvailableOrgs } from "../../../../shared/hooks/useAvailableOrgs";
import { getOrgsHierarchy } from "../../../ReportView/helpers/getOrgsHierarchy";
import { AlertFormValues } from "../../interfaces";

interface NewAlertsTypeProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const NewAlertsType: FC<NewAlertsTypeProps> = ({
  form,
}: NewAlertsTypeProps) => {
  const availableOrgs = useAvailableOrgs();
  const availableOrgsHierarchy = useMemo(
    () => getOrgsHierarchy(availableOrgs),
    [availableOrgs]
  );

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleUpdateOrganizationField = (value: any, field?: string) => {
    if (!field) return;

    form.setValue(field as keyof AlertFormValues, value);
    form.trigger();
  };

  const watchSaveAsNewAlertType = useWatch({
    name: "saveAsNewAlertsType",
    control: form.control,
  });

  return (
    <Box className="px-4 md:px-8 lg:px-16">
      <Paper elevation={0} className="mb-6">
        <Box className="p-12">
          <Typography className="!pb-8 !text-lg" variant="h6" component="div">
            New Alert Type
          </Typography>
          <Grid container spacing={6}>
            <Grid
              item
              xs={12}
              md={5}
              lg={4}
              xl={3}
              data-testid="alert-template-save-as-alert-type"
            >
              <SwitchElement
                name="saveAsNewAlertsType"
                control={form.control}
                label="New Alert Type"
                sx={{ marginTop: "0.5rem", marginRight: "0px" }}
              />
            </Grid>
            {watchSaveAsNewAlertType && (
              <Grid
                item
                xs={12}
                md={6}
                lg={5}
                xl={4}
                data-testid="alert-template-org-ids"
                sx={{ paddingLeft: "0px !important" }}
              >
                <HierarchySelectElement
                  hideValue
                  required
                  hierarchy={availableOrgsHierarchy}
                  loading={!availableOrgsHierarchy.length}
                  label="Organization"
                  control={form.control}
                  name="alertTemplateOrgIds"
                  form={form}
                  handleUpdateField={handleUpdateOrganizationField}
                  fieldPath="alertTemplateOrgIds"
                  isSelectOpen={isSelectOpen}
                  setIsSelectOpen={setIsSelectOpen}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
