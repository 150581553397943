export function omitByDeep<T extends object, K extends keyof T>(
  obj: T,
  predicate: (value: T[K], key: K) => boolean
): Partial<T> {
  if (typeof obj !== "object") {
    return obj;
  }

  const clone: Partial<T> = {};
  for (const entry of Object.entries(obj)) {
    const value = entry[1] as T[K];
    const key = entry[0] as K;
    if (predicate(value, key)) {
      continue;
    }

    if (value && typeof value === "object") {
      clone[key] = omitByDeep(value as T, predicate) as T[K];
    } else {
      clone[key] = value;
    }
  }

  return clone;
}
