import { LOCATION_CHANGE, LOCATION_RESET } from "../../constants";
import { Action } from "../commonTypes";

export const initialLocationState = {
  locationName: "United States",
  isDefault: true,
  time: 0,
};

export const LocationReducer = (
  state = initialLocationState,
  action: Action
) => {
  // should be rewritten to switch after 3 statements to avoid sonarcloud issues
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        locationName: action.payload,
        isDefault: false,
        time: Date.now(), // to force useEffect call on MapComponent.
      };
    case LOCATION_RESET:
      return {
        ...state,
        locationName: initialLocationState.locationName,
        isDefault: true,
        time: Date.now(), // to force useEffect call on MapComponent.
      };
    default:
      return state;
  }
};
