import React, { ReactElement } from "react";
import { ReferenceArea } from "recharts";

export const getReferenceAreas = (
  minValue: number,
  maxValue: number
): ReactElement => {
  return (
    <>
      <ReferenceArea
        key="belowMin"
        y1={0}
        y2={minValue}
        fill="#CA1212"
        fillOpacity={"10%"}
        data-testid="belowMin"
      />
      <ReferenceArea
        key="inRange"
        y1={minValue}
        y2={maxValue}
        fill="#339933"
        fillOpacity={"10%"}
        data-testid="inRange"
      />
      <ReferenceArea
        key="aboveMax"
        y1={maxValue}
        ifOverflow={"visible"}
        fill="#CA1212"
        fillOpacity={"10%"}
        data-testid="aboveMax"
      />
    </>
  );
};
