import { FC } from "react";
import { Box } from "@mui/material";
import { Button } from "../Button";

export interface DrawerFooterProps {
  text: string;
  testId: string;
  disabled: boolean;
  submit: () => void;
}

const DrawerFooter: FC<DrawerFooterProps> = ({
  text,
  testId,
  disabled,
  submit,
}) => {
  return (
    <Box
      className="px-6 py-4 flex justify-center bg-background"
      sx={{
        boxShadow: "0 -2px 4px var(--box-shadow)",
      }}
    >
      <Button
        text={text}
        dataTestid={testId}
        disabled={disabled}
        className="!min-w-[150px]"
        onClick={submit}
        variant="default"
        size="medium"
        theme="blue"
      />
    </Box>
  );
};

export default DrawerFooter;
