import { TableColumnFormat as ReportColumnFormat } from "../../../../../graphql/operations";
import {
  imeiValueFormatter,
  columnTimezoneDateTimeFormatParams,
} from "../../../../../utils";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  date: false,
  assetName: false,
  imei: false,
  vin: false,
  oldCompany: false,
  newCompany: false,
  userName: false,
};

// all dates in this table should be displayed in organization timezone
export const getColumns = (timezone: string): ReportGridColDef[] => [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.IsoDateTime,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  {
    field: "assetName",
    headerName: "Asset Name",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.ImeiString,
    valueFormatter: imeiValueFormatter,
  },
  {
    field: "imei",
    format: ReportColumnFormat.ImeiString,
    headerName: "Device ID",
    flex: 1,
    minWidth: 120,
    valueFormatter: imeiValueFormatter,
  },
  {
    field: "vin",
    headerName: "VIN",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
  },
  {
    field: "oldCompany",
    headerName: "Old Organization",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
  },
  {
    field: "newCompany",
    headerName: "New Organization",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
  },
  {
    field: "userName",
    headerName: "User Name",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
  },
];

export const SEARCH_KEYS = [
  "date",
  "assetName",
  "imei",
  "vin",
  "oldCompany",
  "newCompany",
  "userName",
];
