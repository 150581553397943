import { FC } from "react";
import {
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
} from "react-hook-form";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Box,
  FilterOptionsState,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { isEmpty } from "lodash";

interface OptionType {
  inputValue?: string;
  label: string;
  id: string;
}

interface CreatableAutocompleteProps {
  errors: FieldErrors;
  dataTestId?: string;
  field:
    | ControllerRenderProps<FieldValues, "tags">
    | ControllerRenderProps<FieldValues, "geofence.tags">
    | ControllerRenderProps<FieldValues, "allowedAssetTags">;
  label: string;
  name: string;
  options: OptionType[];
  getLabelTemplate?: any;
  disabled?: boolean;
  loading?: boolean;
  geofence?: boolean;
}

const filter = createFilterOptions<OptionType>();
const isOptionEqualToValue = (option: OptionType, value: OptionType) => {
  return option.id?.trim().toLowerCase() === value.id.trim().toLowerCase();
};

export const CreatableAutocomplete: FC<CreatableAutocompleteProps> = (
  props
) => {
  const {
    errors = {},
    field,
    name,
    label,
    options,
    getLabelTemplate,
    disabled,
    loading,
    geofence,
  } = props;

  let fieldValue = [];

  if (geofence && field.value) {
    // The field value is an array when it comes from OpenSearch
    if (Array.isArray(field.value)) {
      fieldValue = field.value.map((s: string) => ({ id: s, label: s }));
      // The field value is a string when it's generated by the CreatableAutocomplete component
    } else {
      // When there are multiple tags, they are separated with a comma. We need to create an array from them.
      if (field.value.includes(",")) {
        const fieldArray = field.value.split(",");
        for (const field of fieldArray) {
          if (field !== "") {
            fieldValue.push({ id: field, label: field });
          }
        }
        // When there's one tag, it's a single string. We need to return an array with one item.
      } else if (field.value !== "") {
        fieldValue = [{ id: field.value, label: field.value }];
      }
    }
  } else if (field.value) {
    if (Array.isArray(field.value)) {
      fieldValue = field.value.map((s: string) => ({ id: s, label: s }));
      // The field value is a string when it's generated by the CreatableAutocomplete component
    } else {
      fieldValue = field.value
        .split(",")
        .map((s: string) => ({ id: s, label: s }));
    }
  }

  return (
    <Autocomplete
      {...field}
      id="creatable-autocomplete"
      className="w-full"
      isOptionEqualToValue={isOptionEqualToValue}
      value={fieldValue}
      multiple
      loading={loading}
      disabled={disabled}
      onChange={(_, newValue: (OptionType | string)[]) => {
        // if option isn't in options list and was typed by keyboard
        if (
          typeof newValue[newValue.length - 1] === "string" &&
          newValue[newValue.length - 1].toString().trim().length
        ) {
          // check is this option selected
          const existingField = newValue.find((val) => {
            const currentSelectedValue = newValue[
              newValue.length - 1
            ] as OptionType;
            return val === currentSelectedValue.label;
          }) as OptionType;
          if (existingField) {
            // if this option is already selected - unselect it
            const filtered = newValue.slice(0, -1).filter((val) => {
              return val !== existingField.label;
            }) as OptionType[];
            field.onChange(filtered.map((el) => el.id).join(","));
            return;
          }
          const currentSelectedValue = newValue as OptionType[];
          // put newly added option to options list
          field.onChange(
            [
              ...currentSelectedValue.slice(0, -1),
              {
                label: newValue[newValue.length - 1],
                id: newValue[newValue.length - 1],
              },
            ]
              .map((el) => el.id)
              .join(",")
          );
        } else {
          let currentSelectedValue = newValue as OptionType[];
          // not allowing empty entries
          currentSelectedValue = currentSelectedValue.filter(
            (a) => !isEmpty(a.id?.trim())
          );
          field.onChange(currentSelectedValue.map((el) => el.id).join(","));
        }
      }}
      filterOptions={(options, params: FilterOptionsState<OptionType>) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.label
        );
        const isValid = /\S/g.test(inputValue);

        if (inputValue === "" || isExisting) return filtered;
        if (isValid) {
          filtered.push({ label: `add "${inputValue}"`, id: inputValue });
        } else {
          filtered.push({
            label: `Invalid input "${inputValue}"`,
            id: inputValue,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        const currentOption = option as OptionType;
        return currentOption.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      freeSolo
      renderInput={({ InputProps, inputProps, InputLabelProps, ...params }) => (
        <FormControl error={!!errors[name]} {...params} className="w-full">
          {getLabelTemplate ? (
            getLabelTemplate(label, false, InputLabelProps)
          ) : (
            <InputLabel {...InputLabelProps}>{label}</InputLabel>
          )}
          <Input
            {...params}
            {...InputProps}
            inputProps={{
              ...inputProps,
              "data-testid":
                props.dataTestId || `creatable-autocomplete-${name}`,
            }}
          />
          {errors[name] ? (
            <FormHelperText>
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ErrorOutlineOutlinedIcon
                  sx={{ marginRight: "4px", fontSize: "18px" }}
                />{" "}
                Required
              </Box>
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      )}
    />
  );
};
