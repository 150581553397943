import React from "react";

export interface DashboardFrameTitleProps {
  text: string;
}

export const DashboardFrameTitle: React.FC<DashboardFrameTitleProps> = ({
  text,
}) => (
  <span className="text-lg font-semibold" data-testid="dashboard-frame-title">
    {text}
  </span>
);
