export const ASSET_LENGTHS = [
  "20 Feet",
  "28 Feet",
  "32 Feet",
  "40 Feet",
  "45 Feet",
  "48 Feet",
  "53 Feet",
  "57 Feet",
  "60 Feet",
  "80 Feet",
];
export const ASSET_DOOR_TYPES = ["Roll Door", "Swing Door"];
