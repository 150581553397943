import { memo, SVGProps, FC } from "react";

interface BrakePadProps extends SVGProps<SVGSVGElement> {}

const BrakePad: FC<BrakePadProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 101 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M67.3544 15.8554C42.898 15.8567 22.6852 34.9668 21.3371 59.3609C21.2287 60.3676 22.0174 61.1389 22.9755 61.1924C23.9349 61.2473 24.756 60.5109 24.8071 59.5553C26.0555 37.0001 44.7432 19.3341 67.3544 19.3328V15.8554Z"
        fill="#9BDEED"
      />
      <path
        d="M20.526 59.1996C20.4513 60.6411 21.5626 61.8696 23.0029 61.9419C24.4457 62.0154 25.6742 60.9052 25.7465 59.4625C25.82 58.0209 24.7099 56.7924 23.2671 56.7202C21.8255 56.6479 20.5983 57.7568 20.526 59.1996Z"
        fill="#414A56"
      />
      <path
        d="M67.3549 8.46494C38.7495 8.46618 15.2836 30.8294 13.9355 59.3775C13.9044 60.3269 14.632 61.1492 15.5914 61.1953C16.5507 61.2402 17.3631 60.4989 17.4092 59.5395C18.6701 32.8503 40.6072 11.9423 67.3549 11.9411V8.46494Z"
        fill="white"
      />
      <path
        d="M13.1332 59.1996C13.061 60.6411 14.1711 61.8696 15.6126 61.9419C17.0529 62.0154 18.2814 60.9052 18.3562 59.4625C18.4284 58.0209 17.3183 56.7924 15.8755 56.7202C14.434 56.6479 13.2042 57.7568 13.1332 59.1996Z"
        fill="#414A56"
      />
      <path
        d="M67.354 1.07438C34.6022 1.07562 7.8881 26.6895 6.53502 59.3878C6.4802 60.2824 7.24021 61.1558 8.20083 61.1956C9.1602 61.2368 9.97005 60.4892 10.0099 59.5298C11.2858 28.7029 36.4723 4.55177 67.354 4.55053V1.07438Z"
        fill="#9BDEED"
      />
      <path
        d="M67.223 14.7867C68.6645 14.7132 69.893 15.8245 69.9653 17.2648C70.0388 18.7064 68.9287 19.9361 67.4859 20.0084C66.0443 20.0806 64.8158 18.9718 64.7436 17.5277C64.6713 16.0874 65.7802 14.8577 67.223 14.7867Z"
        fill="#414A56"
      />
      <path
        d="M67.223 7.3948C68.6645 7.32254 69.893 8.43267 69.9653 9.87421C70.0388 11.3145 68.9287 12.543 67.4859 12.6178C66.0443 12.69 64.8158 11.5799 64.7436 10.1371C64.6713 8.69431 65.7802 7.46582 67.223 7.3948Z"
        fill="#414A56"
      />
      <path
        d="M67.223 0.00335836C68.6645 -0.0689058 69.893 1.03998 69.9653 2.48277C70.0388 3.92307 68.9287 5.1528 67.4859 5.22507C66.0443 5.29858 64.8158 4.18845 64.7436 2.74441C64.6713 1.30411 65.7802 0.0743766 67.223 0.00335836Z"
        fill="#414A56"
      />
      <path
        d="M5.74271 59.1996C5.6692 60.6411 6.77932 61.8696 8.22087 61.9419C9.66241 62.0154 10.8909 60.9052 10.9632 59.4625C11.0379 58.0209 9.92655 56.7924 8.48376 56.7202C7.04222 56.6479 5.81373 57.7568 5.74271 59.1996Z"
        fill="#414A56"
      />
      <path
        d="M7.4182 90.0482L18.4796 78.9905L25.7496 86.2605L25.7484 66.287L5.77606 66.2845L12.9962 73.5084L1.93485 84.5723C1.17981 85.3273 0.799805 86.3228 0.799805 87.3084C0.799805 88.3026 1.18106 89.2944 1.93983 90.0494C3.44741 91.5645 5.90564 91.5645 7.4182 90.0482Z"
        fill="white"
      />
      <path
        d="M100.8 61.3247C100.8 79.8817 85.7593 94.9238 67.2036 94.9238C48.6466 94.9238 33.6045 79.8817 33.6045 61.3247C33.6045 42.7678 48.6466 27.7281 67.2036 27.7281C85.7593 27.7281 100.8 42.7678 100.8 61.3247Z"
        fill="#9BDEED"
      />
      <path
        d="M80.6422 61.3257C80.6422 68.749 74.6256 74.7656 67.2036 74.7656C59.7803 74.7656 53.7637 68.749 53.7637 61.3257C53.7637 53.9037 59.7803 47.8871 67.2036 47.8871C74.6256 47.8871 80.6422 53.9037 80.6422 61.3257Z"
        fill="white"
      />
      <path
        d="M67.2029 49.0732C60.4462 49.0732 54.9491 54.569 54.9491 61.3245C54.9491 68.0824 60.4462 73.5795 67.2029 73.5795C73.9583 73.5795 79.4554 68.0824 79.4554 61.3245C79.4554 54.569 73.9583 49.0732 67.2029 49.0732ZM67.2029 75.9492C59.1392 75.9492 52.5781 69.3906 52.5781 61.3245C52.5781 53.2608 59.1392 46.7022 67.2029 46.7022C75.2666 46.7022 81.8264 53.2608 81.8264 61.3245C81.8264 69.3906 75.2666 75.9492 67.2029 75.9492Z"
        fill="#434D5A"
      />
      <path
        d="M67.2029 89.3887C56.0419 89.3887 45.9436 82.7777 41.4732 72.5486C41.2115 71.9493 41.4856 71.2504 42.0849 70.9887C42.683 70.7258 43.3832 71.0012 43.6461 71.5992C47.7365 80.9674 56.9825 87.0177 67.2029 87.0177C77.4221 87.0177 86.6682 80.9687 90.7586 71.603C91.019 71.0037 91.7179 70.7271 92.3185 70.9912C92.919 71.2529 93.1919 71.9531 92.929 72.5511C88.4611 82.7802 78.364 89.3887 67.2029 89.3887Z"
        fill="#5F7C8F"
      />
      <path
        d="M42.5596 51.7598C42.4014 51.7598 42.2406 51.7299 42.0861 51.6613C41.4856 51.3984 41.2115 50.6995 41.4731 50.1014C45.9423 39.8723 56.0406 33.2639 67.2017 33.2639C78.3615 33.2639 88.4598 39.8723 92.929 50.0989C93.1918 50.697 92.9177 51.3972 92.3184 51.6601C91.7154 51.9205 91.0189 51.6489 90.7573 51.0483C86.6644 41.6839 77.4183 35.6361 67.2017 35.6361C56.9838 35.6361 47.7377 41.6851 43.6461 51.0496C43.4517 51.4956 43.0156 51.7598 42.5596 51.7598Z"
        fill="#5F7C8F"
      />
      <path
        d="M75.2628 80.9941C74.8043 80.9941 74.3695 80.7288 74.1751 80.2827C73.9135 79.6822 74.1888 78.9832 74.7881 78.7216C81.7055 75.7002 86.1735 68.8725 86.1735 61.3258C86.1735 53.7817 81.7055 46.954 74.7894 43.9314C74.1913 43.6697 73.916 42.9695 74.1776 42.3702C74.4405 41.7709 75.137 41.4956 75.74 41.7584C83.5184 45.1574 88.5457 52.8385 88.5457 61.3258C88.5457 69.8156 83.5171 77.4968 75.7363 80.8945C75.5818 80.963 75.421 80.9941 75.2628 80.9941Z"
        fill="#5F7C8F"
      />
      <path
        d="M59.1366 80.9922C58.9796 80.9922 58.8164 80.961 58.6631 80.8925C50.8835 77.4949 45.8574 69.8149 45.8574 61.3251C45.8574 52.8378 50.8835 45.1566 58.6619 41.7577C59.2574 41.4961 59.9601 41.7677 60.2218 42.3682C60.4847 42.9688 60.2093 43.669 59.6113 43.9306C52.6951 46.9533 48.2284 53.781 48.2284 61.3251C48.2284 68.8717 52.6964 75.6995 59.6125 78.7209C60.2118 78.9813 60.4859 79.6815 60.223 80.2808C60.0287 80.7268 59.5926 80.9922 59.1366 80.9922Z"
        fill="#5F7C8F"
      />
    </svg>
  );
};

export default memo(BrakePad);
