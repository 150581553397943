import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Search from "@mui/icons-material/SearchSharp";
import { Box, InputAdornment, OutlinedInput } from "@mui/material";
import Fuse from "fuse.js";
import { DashboardLibraryWidget } from "../../../graphql/operations";
import Drawer from "../../../shared/components/Drawer";
import DrawerContent from "../../../shared/components/Drawer/DrawerContent";
import DrawerHeader from "../../../shared/components/Drawer/DrawerHeader";
import { DashboardWidgetCard } from "./DashboardWidgetCard";

export interface AddDashboardWidgetDrawerProps {
  open: boolean;
  setOpen: (flag: boolean) => void;
  onAddWidget: (widget: DashboardLibraryWidget) => void;
  currentDashboardWidgetKeys: string[];
  dashboardWidgetsList: DashboardLibraryWidget[];
}

export const AddDashboardWidgetDrawer = (
  props: AddDashboardWidgetDrawerProps
) => {
  const {
    open,
    setOpen,
    currentDashboardWidgetKeys,
    onAddWidget,
    dashboardWidgetsList,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [filteredResults, setFilteredResults] =
    useState<DashboardLibraryWidget[]>(dashboardWidgetsList);

  const options = {
    keys: ["name", "description"],
    threshold: 0.2,
    includeScore: true, // Optional: include the score in the result
  };
  useEffect(() => {
    setFilteredResults(dashboardWidgetsList);
  }, [dashboardWidgetsList]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSearchInputChange = (value: string) => {
    const fuse = new Fuse(dashboardWidgetsList, options);
    setSearchText(value);
    const filteredResult = value
      ? fuse.search(value).map((result) => result.item)
      : dashboardWidgetsList;
    setFilteredResults(filteredResult);
  };
  return (
    <Drawer
      testId="add-dashboard-widget-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader text="Custom Dashboard" onClose={handleClose} />

      <DrawerContent>
        <Box className="pt-3 px-5">
          <OutlinedInput
            placeholder="Widget search"
            className="!mb-2 !w-full !rounded-full !md:w-auto"
            value={searchText}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              onSearchInputChange(value)
            }
            classes={{ notchedOutline: "!border-concrete" }}
            inputProps={{
              className: "!py-[10px] !text-sm !text-typography",
              "data-testid": "input-table-search",
            }}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </Box>
        <Box className="bg-background pb-0">
          {filteredResults.map((widget, index) => {
            return (
              widget && (
                <DashboardWidgetCard
                  key={widget?.key || index}
                  onAddWidget={onAddWidget}
                  dashboardWidget={widget}
                  disabled={currentDashboardWidgetKeys.includes(
                    widget.key ?? ""
                  )}
                />
              )
            );
          })}
        </Box>
      </DrawerContent>
    </Drawer>
  );
};
