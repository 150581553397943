import { FC, useState, useEffect, useMemo } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, ThemeProvider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { cloneDeep, isEmpty } from "lodash";
import * as yup from "yup";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useFindConfigurationSetByIdQuery,
  ConfigurationSetType,
  OrgData,
  useGetConfigurationSetsQuery,
  useFindOrgsQuery,
  ConfigurationSet,
  ToggleConfigurationSetMutation,
} from "../../../../../../graphql/operations";
import { isValidHexColor } from "../../../../../../shared/components/ColorPreview/ColorPreviewUtils";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import { ColorInputElement } from "../../../../../../shared/components/react-hook-form-mui/ColorInputElement/ColorInputElement";
import { useFormTheme } from "../../../../../../shared/hooks/theme/useFormTheme";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import { useOrgsOptions } from "../../../../../AssetsView/TableView/hooks";
import { useConfigurationSetsApi } from "../../../../hooks/useConfigurationSetsApi";
import {
  DrawerType,
  ERROR_GEOFENCE_CONFIGURATION_SET_DUPLICATE,
  GeofenceTypeDrawers,
  Option,
} from "../../configurationsUtils";
import { ToggleConfigurationSetActiveButton } from "../ToggleConfigurationSetActiveButton/ToggleConfigurationSetActiveButton";

// CreateEditGeofenceType
export interface CreateEditGeofenceTypeConfigDrawerProps {
  isOpen: boolean;
  type?: DrawerType;
  selectedConfigId?: string;
  selectedOrgId?: string;
  onClose: () => void;
  onRequestClose: () => void;
}

export type DrawerForm = {
  name: string;
  color: string;
};

type FormInputs = {
  orgName: string;
  geofenceType: string;
  geofenceTypeColor: string;
  parentId: string | null;
};

const CreateEditGeofenceTypeConfigDrawer: FC<
  CreateEditGeofenceTypeConfigDrawerProps
> = ({
  isOpen,
  type,
  selectedConfigId,
  selectedOrgId,
  onClose,
  onRequestClose,
}) => {
  const defaultValues = {
    orgName: "",
    geofenceType: "",
    geofenceTypeColor: "",
    parentId: null,
  };

  const isCreateDrawer = type === GeofenceTypeDrawers.Create;
  const isEditDrawer = type === GeofenceTypeDrawers.Edit;

  const geofenceTypesConfigSchema = {
    orgName: isCreateDrawer
      ? yup.string().required("Field is required!").nullable()
      : yup.string(),
    geofenceType: yup.string().required("Field is required!"),
    geofenceTypeColor: yup
      .string()
      .required("Field is required!")
      .test(
        "isValidHexColor",
        "Please provide a valid Hex Color",
        (value: string | undefined) => Boolean(value && isValidHexColor(value))
      ),
    parentId: yup.string().nullable(),
  };
  const breakpoints = { xs: 12 };

  // States

  const [selectedOrganizationFromDrawer, setSelectedOrganizationFromDrawer] =
    useState<string>("");
  const [
    currentOrgConfigurationSetsForDrawer,
    setCurrentOrgConfigurationSetsForDrawer,
  ] = useState<Option[]>([]);
  const [isFromParentOrganization, setIsFromParentOrganization] =
    useState<boolean>(false);
  const [isDeactivatedForSelectedOrg, setIsDeactivatedForSelectedOrg] =
    useState<boolean>(false);
  const [resetParentIdValue, setResetParentIdValue] = useState<number>(0);
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const { data: dataOrgsList } = useFindOrgsQuery();

  // Hooks

  const formTheme = useFormTheme();
  const { data: dataConfigurations } = useGetConfigurationSetsQuery({
    input: {
      orgId: selectedOrgId ?? "",
      type: ConfigurationSetType.GeofenceType,
    },
  });

  const { data: dataConfigurationsForParentFieldInDrawer } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: selectedOrganizationFromDrawer ?? "",
          type: ConfigurationSetType.GeofenceType,
        },
      },
      {
        enabled: Boolean(selectedOrganizationFromDrawer),
      }
    );

  // initial geofence types
  const allGeofenceTypes: Option[] = useMemo(
    () =>
      dataConfigurations?.getConfigurationSets?.map((item: any) => {
        return {
          label: item.name,
          id: item._id,
          value: item._id,
        };
      }) ?? [],
    [dataConfigurations?.getConfigurationSets]
  );

  // filtered geofence types
  const filteredGeofenceTypes: Option[] = useMemo(
    () =>
      dataConfigurationsForParentFieldInDrawer?.getConfigurationSets?.map(
        (item: any) => {
          return {
            label: item.name,
            id: item._id,
            value: item._id,
          };
        }
      ) ?? [],
    [dataConfigurationsForParentFieldInDrawer?.getConfigurationSets]
  );

  // get current selected geofence type config
  const currentSelectedGeofenceTypeInDrawer = isEditDrawer
    ? dataConfigurations?.getConfigurationSets?.find(
        (config) => config._id === selectedConfigId
      )
    : null;

  const orgsData = useMemo(
    () => dataOrgsList?.findOrgs || [],
    [dataOrgsList?.findOrgs]
  );
  const orgsOptionsSorted = useOrgsOptions(orgsData);
  const orgOptions = useMemo(
    () =>
      orgsOptionsSorted.map((org) => ({
        value: org.id as keyof OrgData,
        id: org.id as keyof OrgData,
        label: org.label,
      })),
    [orgsOptionsSorted]
  );
  const {
    data: dataConfigurationSet,
    isFetching: isFetchingFindConfiguration,
    refetch,
  } = useFindConfigurationSetByIdQuery(
    {
      input: {
        entityId: selectedConfigId ?? "",
      },
    },
    {
      enabled: Boolean(selectedConfigId) && isEditDrawer,
      onSuccess: (data) => {
        const dataParsed =
          data?.findConfigurationSetById?.value &&
          JSON.parse(data?.findConfigurationSetById?.value);

        const defaultValues =
          cloneDeep({
            orgName: data?.findConfigurationSetById?.orgId,
            geofenceType: data?.findConfigurationSetById?.name ?? "",
            geofenceTypeColor: dataParsed?.color,
            parentId: data?.findConfigurationSetById?.parentId ?? null,
          }) ?? {};
        reset(defaultValues);
        setForceRefreshFormState((prev) => !prev);
      },
    }
  );

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormInputs>({
    resolver: yupResolver(yup.object().shape(geofenceTypesConfigSchema)),
    defaultValues,
  });
  const allChildrenIds = useMemo(() => {
    const confSets = dataConfigurations?.getConfigurationSets;

    // base case
    if (!confSets) return [];

    // recursive function that computes all the sub-children
    const getAllChildrenByLevel = (
      level: string[],
      accumulator: string[]
    ): string[] => {
      // when level does not have items, return accumulator
      if (level.length === 0) return accumulator;

      // get the next level children
      // Step 1: Filter confSets based on parentId
      const filteredSets = confSets.filter(
        (set) =>
          typeof set.parentId === "string" && level.includes(set.parentId)
      );

      // Step 2: Extract _id from filteredSets
      const extractedIds = filteredSets.map((set) => set._id);

      // Step 3: Flatten the array
      const nextLevel = extractedIds.flat();

      // call the function with nextLevel and concat current level to accumulator
      return getAllChildrenByLevel(nextLevel, accumulator.concat(level));
    };

    // first level children
    const firstLevel = confSets
      .filter(
        (set) =>
          set.parentId &&
          set.parentId === currentSelectedGeofenceTypeInDrawer?._id
      )
      .map((set) => set._id);

    // using a recursive function with accumulator to get all the children and sub-children ids
    return getAllChildrenByLevel(firstLevel, []);
  }, [
    currentSelectedGeofenceTypeInDrawer?._id,
    dataConfigurations?.getConfigurationSets,
  ]);

  // Effects

  useEffect(() => {
    if (isOpen && isEditDrawer) {
      refetch();
    }
  }, [refetch, isOpen, isEditDrawer]);

  useEffect(() => {
    if (dataConfigurationSet && dataConfigurations) {
      const selectedGeofenceType =
        dataConfigurations?.getConfigurationSets?.find((geofenceType) => {
          return (
            geofenceType._id ===
            dataConfigurationSet?.findConfigurationSetById?._id
          );
        }) as ConfigurationSet;

      if (selectedGeofenceType) {
        const value = JSON.parse(selectedGeofenceType.value);
        setIsFromParentOrganization(Boolean(value?.isFromParentOrganization));
        setIsDeactivatedForSelectedOrg(
          Boolean(value?.disabledForOrganizations?.includes(selectedOrgId))
        );
      }
    }
  }, [dataConfigurationSet, dataConfigurations, selectedOrgId]);

  useEffect(() => {
    if (isCreateDrawer) {
      setCurrentOrgConfigurationSetsForDrawer(
        filteredGeofenceTypes.length ? filteredGeofenceTypes : ([] as Option[])
      );
    } else {
      // current geofence type is excluded as an option for a parent to itself as it will trigger infinite loop
      // & is also impossible as a structure
      const otherGeofenceTypeOptions = allGeofenceTypes?.filter(
        (item) =>
          item.value !== currentSelectedGeofenceTypeInDrawer?._id &&
          !allChildrenIds.includes(item.value)
      );
      setCurrentOrgConfigurationSetsForDrawer(
        otherGeofenceTypeOptions.length
          ? otherGeofenceTypeOptions
          : ([] as Option[])
      );
    }
  }, [
    allGeofenceTypes,
    filteredGeofenceTypes,
    isCreateDrawer,
    currentSelectedGeofenceTypeInDrawer,
    allChildrenIds,
  ]);

  // Handlers
  const createGeofenceTypeConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Type Configuration created!",
        text: "Your Geofence Type Configuration is created successfully.",
        severity: "success",
      },
    });

    onClose();
  };

  const createGeofenceTypeConfigurationSetOnError = (error: Error) => {
    reset(defaultValues);
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Type Configuration create failed!",
        text:
          error?.message === ERROR_GEOFENCE_CONFIGURATION_SET_DUPLICATE
            ? mapServerErrorCodeToHumanReadableMessage(error.message)
            : "An error occurred while creating your Geofence Type Configuration.",
      },
    });
  };

  const updateGeofenceTypeConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Type Configuration updated!",
        text: "Your Geofence Type Configuration is updated successfully.",
        severity: "success",
      },
    });

    onClose();
  };

  const updateGeofenceTypeConfigurationSetOnError = (error: Error) => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Type Configuration update failed!",
        text:
          error?.message === ERROR_GEOFENCE_CONFIGURATION_SET_DUPLICATE
            ? mapServerErrorCodeToHumanReadableMessage(error.message)
            : "An error occurred while creating your Geofence Type Configuration.",
        severity: "error",
      },
    });
  };

  const deleteGeofenceTypeConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Type Configuration deleted!",
        text: "Your Geofence Type Configuration is deleted successfully.",
        severity: "success",
      },
    });

    onClose();
    setToggleDeleteDialogState(false);
  };

  const deleteGeofenceTypeConfigurationSetOnError = (error: Error) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Type Configuration delete failed!",
        text: mapServerErrorCodeToHumanReadableMessage(
          (error as Error).message
        ),
        severity: "error",
      },
    });

    onClose();
    setToggleDeleteDialogState(false);
  };

  const toggleGeofenceTypeConfigurationSetOnSuccess = (
    data: ToggleConfigurationSetMutation
  ) => {
    queryClient.invalidateQueries(["getConfigurationSets"]);
    queryClient.invalidateQueries(["findConfigurationSetById"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Type Configuration updated!",
        text: data.toggleConfigurationSet?.message,
        severity: "success",
      },
    });

    onClose();
  };

  const toggleGeofenceTypeConfigurationSetOnError = (error: unknown) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Type Configuration toggle failed!",
        text: mapServerErrorCodeToHumanReadableMessage(
          (error as Error).message
        ),
        severity: "error",
      },
    });

    onClose();
  };

  const {
    isLoadingCreateConfigurationSet,
    createConfigurationSet,

    isLoadingUpdateConfigurationSet,
    updateConfigurationSet,

    isLoadingDeleteConfigurationSet,
    deleteConfigurationSet,

    isLoadingToggleConfigurationSet,
    toggleConfigurationSet,
  } = useConfigurationSetsApi({
    createConfigurationSetOnSuccess:
      createGeofenceTypeConfigurationSetOnSuccess,
    createConfigurationSetOnError: createGeofenceTypeConfigurationSetOnError,

    updateConfigurationSetOnSuccess:
      updateGeofenceTypeConfigurationSetOnSuccess,
    updateConfigurationSetOnError: updateGeofenceTypeConfigurationSetOnError,

    deleteConfigurationSetOnSuccess:
      deleteGeofenceTypeConfigurationSetOnSuccess,
    deleteConfigurationSetOnError: deleteGeofenceTypeConfigurationSetOnError,

    toggleConfigurationSetOnSuccess:
      toggleGeofenceTypeConfigurationSetOnSuccess,
    toggleConfigurationSetOnError: toggleGeofenceTypeConfigurationSetOnError,
  });

  const [toggleDeleteDialogState, setToggleDeleteDialogState] =
    useState<boolean>(false);
  const [forceRefreshFormState, setForceRefreshFormState] =
    useState<boolean>(false);

  const onSubmit = (data: FieldValues) => {
    if (isEditDrawer && dataConfigurationSet?.findConfigurationSetById) {
      const { _id, orgId } = dataConfigurationSet.findConfigurationSetById;

      updateConfigurationSet({
        _id,
        orgId,
        name: data.geofenceType,
        parentId: data.parentId,
        value: JSON.stringify({ color: data.geofenceTypeColor }),
      });
    }
    if (isCreateDrawer && selectedOrgId) {
      createConfigurationSet({
        type: ConfigurationSetType.GeofenceType,
        orgId: data.orgName,
        name: data.geofenceType,
        parentId: data.parentId,
        value: JSON.stringify({ color: data.geofenceTypeColor }),
      });
    }
  };

  const isLoading =
    isFetchingFindConfiguration ||
    isLoadingUpdateConfigurationSet ||
    isLoadingCreateConfigurationSet ||
    isLoadingDeleteConfigurationSet ||
    isLoadingToggleConfigurationSet;

  const isSubmitDisabled =
    isLoading || isFromParentOrganization || isEmpty(dirtyFields);
  const headerText = `${isCreateDrawer ? "Create" : "Edit"} Geofence Type`;

  return (
    <Drawer
      testId="geofence-config-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={headerText} onClose={onClose} />
      <DrawerContent>
        <ThemeProvider theme={formTheme}>
          <form>
            <Grid container className="bg-background noTopPaddingDrawerSection">
              <Grid item {...breakpoints} data-testid="orgName-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading || isEditDrawer,
                    onChange: (_, value) => {
                      setSelectedOrganizationFromDrawer(value?.id ?? "");
                      setResetParentIdValue(resetParentIdValue + 1);
                    },
                  }}
                  rules={{ required: true }}
                  matchId={true}
                  required
                  label={"Organization Name"}
                  control={control}
                  name={"orgName"}
                  options={orgOptions ?? ([] as Option[])}
                />
              </Grid>
              <Grid item {...breakpoints} data-testid="geofence-type">
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={"geofenceType"}
                  required
                  label={"Geofence Type"}
                  disabled={isLoading || isFromParentOrganization}
                />
              </Grid>
              <Grid item {...breakpoints} data-testid="geofence-type-color">
                <ColorInputElement
                  control={control}
                  name={"geofenceTypeColor"}
                  required
                  label={"Geofence Type Color"}
                  errors={errors}
                  disabled={isLoading || isFromParentOrganization}
                />
              </Grid>
              <Grid item {...breakpoints} data-testid="parentId-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading || isFromParentOrganization,
                  }}
                  matchId={true}
                  label={"Parent"}
                  control={control}
                  name={"parentId"}
                  options={currentOrgConfigurationSetsForDrawer}
                />
              </Grid>
            </Grid>
          </form>
        </ThemeProvider>
        <DrawerActions
          cancelBtnTestId="btn-Brand-form-cancel"
          deleteBtnTestId="action-delete-config"
          disabled={isLoading ?? isFetchingFindConfiguration}
          showDeleteBtn={isEditDrawer && !isFromParentOrganization}
          onDelete={() => setToggleDeleteDialogState(true)}
          onCancel={onClose}
        >
          {isEditDrawer && isFromParentOrganization ? (
            <ToggleConfigurationSetActiveButton
              isActive={!isDeactivatedForSelectedOrg}
              disabled={isLoading ?? isFetchingFindConfiguration}
              onClick={() =>
                toggleConfigurationSet({
                  id: selectedConfigId ?? "",
                  orgId: selectedOrgId ?? "",
                  isEnabled: isDeactivatedForSelectedOrg,
                })
              }
            />
          ) : null}
        </DrawerActions>

        {toggleDeleteDialogState && (
          <ConfirmationDialog
            title="You are about to delete a Configuration"
            message="Are you sure?"
            open={toggleDeleteDialogState}
            isLoading={isLoadingDeleteConfigurationSet}
            confirmButtonText="Delete Configuration"
            cancelButtonText="Cancel"
            handleConfirmationResult={(value) => {
              if (value) {
                deleteConfigurationSet({
                  entityId: selectedConfigId ?? "",
                });
              } else {
                setToggleDeleteDialogState(false);
              }
            }}
          />
        )}
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingUpdateConfigurationSet ||
          isLoadingCreateConfigurationSet ||
          isLoadingDeleteConfigurationSet
            ? "Saving..."
            : "Save"
        }
        disabled={isSubmitDisabled}
        testId="btn-geofences-form-submit"
        submit={handleSubmit(() => onSubmit(getValues()))}
      />
    </Drawer>
  );
};

export default CreateEditGeofenceTypeConfigDrawer;
