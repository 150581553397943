import React, { memo } from "react";

export type SpinnerProps = {
  counter?: number;
  message?: string | null;
};

const Spinner = ({ counter = 0, message = null }: SpinnerProps) => (
  <React.Fragment>
    {counter > 0 && (
      <div className="darken_fullscreen z-50">
        <div className="spinner" data-testid="spinner">
          <svg className="svg" viewBox="0 0 50 50" data-testid="spinner_svg">
            <circle
              className="svgpath"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
          {message}
        </div>
      </div>
    )}
  </React.Fragment>
);

export default memo(Spinner);
