import { ReactNode, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, popoverClasses } from "@mui/material";
import slug from "slug";

export type DashboardActionsMenuOption = {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  onClick: () => void;
};

export interface DashboardActionsMenuProps {
  options: DashboardActionsMenuOption[];
}

export const DashboardActionsMenu: React.FC<DashboardActionsMenuProps> = ({
  options,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOptionClickHandler = (onClick: () => void) => {
    onClick();
    handleClose();
  };

  return (
    <>
      <IconButton
        size="medium"
        sx={{ color: "var(--dark-grey)", padding: 0 }}
        data-testid="dashboard-actions-menu-button"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-testid="dashboard-actions-menu"
        sx={{
          [`& .${popoverClasses.paper}`]: {
            minWidth: "240px",
            maxWidth: "280px",
            borderRadius: "8px",
          },
          "& .MuiList-root": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            fontSize: "1rem",
            "*": {
              fontWeight: "500 !important",
            },
          },
        }}
      >
        {options.map(({ title, disabled, icon, onClick }) => (
          <MenuItem
            key={title}
            data-testid={`dashboard-actions-menu-option-${slug(title)}`}
            className="flex gap-2 !text-inherit"
            onClick={() => onOptionClickHandler(onClick)}
            disabled={disabled}
          >
            {icon}
            <p>{title}</p>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
