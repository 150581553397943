import { useMemo } from "react";
import {
  formatUTCDateToTimezoneAsISOString,
  flattenWildcardStructure,
  HierarchyNode,
} from "../../../../utils";
import { ReportWithParameters } from "../../interfaces";
import { useRunReportForm } from "../components/RunReportForm";

interface AssetParametersFormProps {
  report: ReportWithParameters;
  timezone: string;
  availableOrgsHierarchy: HierarchyNode[];
}
export const useGetReportParametersForm = ({
  report,
  timezone,
  availableOrgsHierarchy,
}: AssetParametersFormProps) => {
  const parametersFormValues = useMemo(() => {
    const reportParameters = {
      orgIds:
        availableOrgsHierarchy && report.parameters?.orgIds
          ? flattenWildcardStructure(
              report.parameters?.orgIds,
              availableOrgsHierarchy
            )
          : report.parameters?.orgIds ?? [],
      // Convert date to time zone since it is stored in UTC
      startDate: report.parameters?.startDate
        ? formatUTCDateToTimezoneAsISOString(
            report.parameters.startDate,
            timezone
          )
        : null,
      // Convert date to time zone since it is stored in UTC
      endDate: report.parameters?.endDate
        ? formatUTCDateToTimezoneAsISOString(
            report.parameters.endDate,
            timezone
          )
        : null,
    };
    return {
      ...reportParameters,
    };
  }, [report.parameters, timezone, availableOrgsHierarchy]);

  const { form } = useRunReportForm(parametersFormValues);
  return { form };
};
