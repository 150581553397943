import React, { useCallback, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  CUSTOMER_ORGS_UPLOAD_FILE_TEMPLATE_PATH,
  PAGE_SNACKBAR,
} from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { BatchTemplate } from "../../../graphql/operations";
import { parseContentAsJsonBlobFile } from "../../../shared/components/TableActionsMenu/helpers";
import UploadFileDialog from "../../../shared/components/UploadFileDialog/UploadFileDialog";
import UploadResult from "../../../shared/components/UploadFileDialog/UploadResult";
import { ParseFileContentError } from "../../../utils";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../AssetsView/TableView/hooks";
import { BatchUploadDialogProps } from "../../BatchesView/BatchManagementUtils";
import { useUploadJsonBatchFileToS3 } from "../../BatchesView/hooks/useUploadJsonBatchFileToS3";
import {
  mapAndValidateCustomerOrgFile,
  parseOrgFiles,
  useOrgsBatchUploadForm,
} from "./UploadCustomerOrgsUtils";

/**
 * Dialog can be used for bulk processing upload of organizations
 * Actions that will be triggered based on the data: Create/Update
 */
export const UploadCustomerOrgsDialog: React.FC<BatchUploadDialogProps> = ({
  title,
  dialogFields,
  customerOrg,
  isOpen,
  onClose,
  onUpload,
}) => {
  // Hooks
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const { uploadBatchFileToS3 } = useUploadJsonBatchFileToS3();
  const { form, getValues } = useOrgsBatchUploadForm(title, customerOrg);

  // State
  const [uploadedMessage, setUploadedMessage] = useState<string | undefined>();
  const [isDevicesUploading, setIsDevicesUploading] = useState<boolean>(false);
  const [isOrgsUploaded, setIsOrgsUploaded] = useState<boolean>(false);
  const [validOrgsLengthState, setValidOrgsLengthState] = useState<any>();

  const [validateUploadedOrgsErrors, setValidateUploadedOrgsErrors] = useState<
    ParseFileContentError[]
  >([]);
  const organizationTypes = useNomenclatures(
    NOMENCLATURE_NAMES.organizationType
  );
  const timeZones = useNomenclatures(NOMENCLATURE_NAMES.timeZones);
  const distanceUnits = useNomenclatures(NOMENCLATURE_NAMES.distanceUnits);
  const pressureUnits = useNomenclatures(NOMENCLATURE_NAMES.pressureUnits);
  const temperatureUntis = useNomenclatures(
    NOMENCLATURE_NAMES.temperatureUnits
  );

  // Handlers and callbacks
  const onFilesUploadDialogClose = useCallback(() => {
    setIsDevicesUploading(false);
    setIsOrgsUploaded(false);
    onClose();
    form.reset();
  }, [form, onClose]);

  const handleSubmit = useCallback(
    async (file: File) => {
      setIsDevicesUploading(true);

      const {
        parent_org: { id: orgId },
        batch_name,
      } = getValues();

      try {
        const { orgs, errors: parseErrors } = await parseOrgFiles(file);

        const { validatedOrgs, validationErrors } =
          mapAndValidateCustomerOrgFile(
            orgs,
            organizationTypes,
            timeZones,
            distanceUnits,
            pressureUnits,
            temperatureUntis
          );

        const combinedErrors = [...validationErrors, ...parseErrors];
        setValidateUploadedOrgsErrors(combinedErrors);
        setValidOrgsLengthState(validatedOrgs?.length);
        if (combinedErrors.length > 0) {
          throw new Error("Validation errors");
        }

        const fileName = file.name.replace(/\.(csv|xls|xlsx)$/i, ".json");
        setUploadedMessage(`${fileName} ${batch_name} Uploaded`);

        const jsonFile = parseContentAsJsonBlobFile(validatedOrgs, fileName);

        if (!validationErrors?.length)
          await uploadBatchFileToS3(
            jsonFile,
            BatchTemplate.CreateOrganizations,
            batch_name,
            orgId
          );

        setIsOrgsUploaded(true);

        if (onUpload) onUpload();

        queryClient.invalidateQueries(["getBatchHistory"], {
          refetchType: "all",
        });
      } catch {
        console.error("Error uploading Organizations:");

        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Customer Organization Uploading Failed",
            text: "Something Went Wrong.",
            severity: "error",
            onClose: () => {},
          },
        });
      } finally {
        setIsDevicesUploading(false);
      }
    },
    [
      getValues,
      organizationTypes,
      timeZones,
      distanceUnits,
      pressureUnits,
      temperatureUntis,
      uploadBatchFileToS3,
      onUpload,
      queryClient,
      dispatch,
    ]
  );

  return (
    <UploadFileDialog
      title={title}
      form={form}
      allowedFormats={["csv", "xls", "xlsx"]}
      dialogFields={dialogFields}
      isOpen={isOpen}
      handleSubmit={handleSubmit}
      onClose={onFilesUploadDialogClose}
      isDataUploading={isDevicesUploading}
      isDataUploaded={isOrgsUploaded}
      downloadTemplateFilePath={CUSTOMER_ORGS_UPLOAD_FILE_TEMPLATE_PATH}
      uploadResultComponent={
        <UploadResult
          uploadedMessage={uploadedMessage}
          errors={validateUploadedOrgsErrors}
          processedRecordsCount={validOrgsLengthState}
          itemName={"Organization"}
        />
      }
    />
  );
};
