import { FC } from "react";
import { IconType } from "./utils";

type DwellIconProps = {
  iconType: string;
  customColor?: string;
  signal?: boolean;
};
type MarkProps = {
  fill: string;
};

const TopMark: FC<MarkProps> = ({ fill }) => (
  <path
    d="M6.06486 4.12927L4.53755 2.60197C6.58613 0.973131 9.17942 0 12 0C14.8296 0 17.4305 0.979398 19.4822 2.6177L17.9553 4.14456C16.3009 2.88837 14.2375 2.14286 12 2.14286C9.77157 2.14286 7.71586 2.88233 6.06486 4.12927Z"
    fill={fill}
  />
);
const RightMar: FC<MarkProps> = ({ fill }: MarkProps) => (
  <path
    d="M19.8707 17.9351C21.1177 16.2841 21.8571 14.2284 21.8571 12C21.8571 9.56156 20.9717 7.32993 19.5049 5.60905L21.0241 4.08986C22.8768 6.20183 24 8.96975 24 12C24 14.8206 23.0269 17.4139 21.398 19.4625L19.8707 17.9351Z"
    fill={fill}
  />
);
const BottomMark: FC<MarkProps> = ({ fill }: MarkProps) => (
  <path
    d="M5.60905 19.5049C7.32993 20.9717 9.56156 21.8571 12 21.8571C14.4475 21.8571 16.6866 20.9651 18.4101 19.4885L19.929 21.0075C17.8147 22.8701 15.0393 24 12 24C8.96975 24 6.20183 22.8768 4.08986 21.0241L5.60905 19.5049Z"
    fill={fill}
  />
);
const LeftMark: FC<MarkProps> = ({ fill }: MarkProps) => (
  <path
    d="M2.99254 4.07098C1.12989 6.18533 0 8.96071 0 12C0 14.8296 0.979398 17.4305 2.6177 19.4822L4.14456 17.9553C2.88837 16.3009 2.14286 14.2375 2.14286 12C2.14286 9.55252 3.03485 7.31338 4.51149 5.58993L2.99254 4.07098Z"
    fill={fill}
  />
);

const DwellIcon: FC<DwellIconProps> = ({ iconType, customColor, signal }) => {
  let fillActive = "var(--asset-status-icon-dwell-active)";
  if (customColor) {
    fillActive = customColor;
  } else if (signal === false) {
    fillActive = "var(--flat-black)";
  }
  if (iconType.startsWith(IconType.LOW_DWELL)) {
    return (
      <>
        <TopMark fill={fillActive} />
      </>
    );
  }
  if (iconType.startsWith(IconType.MEDIUM_DWELL)) {
    return (
      <>
        <TopMark fill={fillActive} />
        <RightMar fill={fillActive} />
      </>
    );
  }
  if (iconType.startsWith(IconType.HIGH_DWELL)) {
    return (
      <>
        <TopMark fill={fillActive} />
        <RightMar fill={fillActive} />
        <BottomMark fill={fillActive} />
      </>
    );
  }
  if (iconType.startsWith(IconType.MEGA_DWELL)) {
    return (
      <>
        <TopMark fill={fillActive} />
        <RightMar fill={fillActive} />
        <BottomMark fill={fillActive} />
        <LeftMark fill={fillActive} />
      </>
    );
  }

  return null;
};

export default DwellIcon;
