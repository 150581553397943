import { useMemo } from "react";
import { Box } from "@mui/system";
import { useGridApiRef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { orderBy } from "lodash";
import { useAppContext } from "../../../../../context/AppContext";
import {
  useFindUserEventsQuery,
  useGetUserDataQuery,
  TableViewType,
  UserEvent,
} from "../../../../../graphql/operations";
import { Table } from "../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../shared/hooks/useExportedFileName";
import { NO_SIDE_PANEL_TABLE } from "../../constants";
import { mapEventDescription, mapEventName } from "./utils";

const SEARCH_KEYS = ["event", "user", "event_date"];
export interface UserHistoryTableProps {
  email: string;
}

const UserHistoryTable: React.FC<UserHistoryTableProps> = ({ email }) => {
  const {
    state: { appConfig },
  } = useAppContext();

  const {
    data: eventsData,
    isLoading,
    isSuccess,
  } = useFindUserEventsQuery(
    { input: { email: email } },
    {
      onSettled: (_, _error) => {
        if (_error) {
          return null;
        }
      },
    }
  );

  const historyGridApiRef = useGridApiRef();
  const { data: accountData } = useGetUserDataQuery();
  const timeZone = accountData?.me?.user_preferences?.time_zones;

  const fileName = useExportedFileName("Activity Log");

  const rows = useMemo(
    () =>
      eventsData?.findUserEvents
        ? orderBy(
            eventsData?.findUserEvents
              ?.filter((row): row is UserEvent => Boolean(row))
              ?.map((row) => ({
                event: mapEventName(row?.event?.type),
                eventDescription: mapEventDescription(row),
                event_date: row?.updated_date,
                user: row?.event?.userFullName,
                id: row?._id,
              })) ?? [],
            "updated_date",
            "desc"
          )
        : [],
    [eventsData?.findUserEvents]
  );

  const columns = useMemo(
    () => [
      {
        field: "event",
        headerName: "Event",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "eventDescription",
        headerName: "Description",
        flex: 1,
        minWidth: 120,
      },
      { field: "user", headerName: "User", flex: 1, minWidth: 120 },
      {
        field: "event_date",
        headerName: "Event Date",
        flex: 1,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams) =>
          params.value
            ? new Date(params.value).toLocaleString("en-US", {
                ...(timeZone && { timeZone: timeZone }),
              })
            : "",
      },
    ],
    [timeZone]
  );

  return (
    <Box className="h-full w-full">
      <Table
        tableType={TableViewType.UserActivityLog}
        showToolbar
        tableName="user-history"
        columns={columns}
        rows={rows}
        apiRef={historyGridApiRef}
        loading={isLoading}
        error={!isLoading && !isSuccess ? true : null}
        className="w-full border-solid"
        enableSearch={true}
        searchKeys={SEARCH_KEYS}
        searchMinLength={appConfig.searchMinLength}
        isPopOver
        initialState={{
          sorting: {
            sortModel: [{ field: "event_date", sort: "desc" }],
          },
        }}
        autoHeight={true}
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        mobileBreakpoint={NO_SIDE_PANEL_TABLE}
      />
    </Box>
  );
};

export default UserHistoryTable;
