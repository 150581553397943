import { memo, ReactNode } from "react";
import { Button, SxProps } from "@mui/material";
import { getButtonIconProps } from "../getButtonIconProps";
import "./textButton.scss";

export type TextButtonProps = {
  size?: "small" | "medium" | "large";
  theme?: "blue" | "black" | "white";
  disabled?: boolean;
  iconPosition?: "none" | "left" | "right";
  icon?: ReactNode;
  text?: string;
  onClick: () => void;
  className?: string;
  sx?: SxProps;
};

const TextButton = ({
  size = "small",
  theme = "black",
  disabled = false,
  iconPosition = "none",
  text = "",
  className = "",
  icon = null,
  sx = {},
  ...props
}: TextButtonProps) => {
  const classes = `global-text-btn global-text-btn--${size} global-text-btn__theme--${theme} ${className}`;
  const iconProps =
    iconPosition && iconPosition !== "none"
      ? getButtonIconProps(iconPosition, icon)
      : {};

  return (
    <Button
      data-testid="global-text-btn-component"
      className={classes}
      disabled={disabled}
      {...iconProps}
      {...props}
    >
      {text}
    </Button>
  );
};
export default memo(TextButton);
