import { memo, SVGProps, FC } from "react";

interface LocationProps extends SVGProps<SVGSVGElement> {}

const Location: FC<LocationProps> = (props) => {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0C2.065 0 0.5 1.565 0.5 3.5C0.5 6.125 4 10 4 10C4 10 7.5 6.125 7.5 3.5C7.5 1.565 5.935 0 4 0ZM1.5 3.5C1.5 2.12 2.62 1 4 1C5.38 1 6.5 2.12 6.5 3.5C6.5 4.94 5.06 7.095 4 8.44C2.96 7.105 1.5 4.925 1.5 3.5Z"
        fill="currentColor"
      />
      <path
        d="M4 4.75C4.69036 4.75 5.25 4.19036 5.25 3.5C5.25 2.80964 4.69036 2.25 4 2.25C3.30964 2.25 2.75 2.80964 2.75 3.5C2.75 4.19036 3.30964 4.75 4 4.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Location);
