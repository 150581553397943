import React, { useEffect, memo } from "react";
import {
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export type DialogProps = {
  title?: string;
  content?: string;
  buttons?: Array<{
    label: string;
    disabled?: boolean;
    color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
    onClick?: () => void;
  }>;
};

const Dialog = (props: DialogProps) => {
  const { title, content, buttons } = props;

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(!!content);
  }, [title, content, buttons]);

  const handleClose = (callback?: () => void) => {
    setOpen(false);
    setTimeout(() => {
      if (!!callback && typeof callback === "function") callback();
    }, 500);
  };

  return (
    <MuiDialog open={open} data-testid="dialog">
      {title && <DialogTitle data-testid="dialogTitle">{title}</DialogTitle>}

      <DialogContent>
        <DialogContentText data-testid="dialogContent">
          {content}
        </DialogContentText>
      </DialogContent>

      <DialogActions data-testid="dialogButtons">
        <React.Fragment>
          {!!buttons?.length &&
            buttons?.map((button, index) => (
              <Button
                key={`dialog_btn_${index}`}
                data-testid={`dialogButton${index}`}
                color={button.color ?? "primary"}
                variant="contained"
                disabled={button.disabled}
                onClick={() => handleClose(button.onClick)}
              >
                {button.label}
              </Button>
            ))}
        </React.Fragment>
      </DialogActions>
    </MuiDialog>
  );
};

export default memo(Dialog);
