import { isUndefined, omitBy } from "lodash";
import * as yup from "yup";
import { getDateTimeFromFormat, getDateTimeNow } from "../date";

export enum SchedulingRepeat {
  Daily = "DAILY",
  Monthly = "MONTHLY",
  Never = "NEVER",
  Weekly = "WEEKLY",
  Yearly = "YEARLY",
}

export interface SchedulingConverted {
  repeat: SchedulingRepeat;
  time: string;
  every?: string | number;
  dayOfMonth?: string | number;
  month?: string | number;
  on?: string[] | number[];
}

interface SchedulingSchema {
  minute: string | number;
  hour: string | number;
  dayOfMonth: string | number;
  month: string | number;
  dayOfWeek: string | number;
  weekOfYear: string | number;
  weekOfMonth: string | number;
  year: string | number;
}

export class SchedulingConverter {
  schema: SchedulingSchema;

  constructor(pattern: string | SchedulingSchema) {
    this.schema = typeof pattern === "string" ? this.parse(pattern) : pattern;
  }

  static fromConverted(schedule: SchedulingConverted): SchedulingConverter {
    const d = getDateTimeFromFormat(schedule.time, "t");
    const schema: Partial<SchedulingSchema> = {
      hour: d.hour,
      minute: d.minute,
      year: "*",
      month: "*",
      dayOfWeek: "*",
      dayOfMonth: "*",
      weekOfMonth: "*",
      weekOfYear: "*",
    };

    if (schedule.repeat === SchedulingRepeat.Daily) {
      const everyCondition = !schedule.every || schedule.every === "1";
      schema.dayOfMonth = everyCondition ? "*" : `1/${schedule.every}`;
    } else if (schedule.repeat === SchedulingRepeat.Weekly) {
      const weekOfYearCondition = !schedule.every || schedule.every === "1";
      schema.weekOfYear = weekOfYearCondition ? "*" : `1/${schedule.every}`;
      schema.dayOfWeek = Array.isArray(schedule.on)
        ? schedule.on?.join(",")
        : "";
    } else if (schedule.repeat === SchedulingRepeat.Monthly) {
      const monthCondition = !schedule.every || schedule.every === "1";
      schema.month = monthCondition ? "1-12" : `1/${schedule.every}`;
      schema.dayOfMonth = schedule.dayOfMonth;
    } else if (schedule.repeat === SchedulingRepeat.Yearly) {
      const yearCondition = !schedule.every || schedule.every === "1";
      schema.year = yearCondition ? "*" : `1/${schedule.every}`;
      schema.month = Number(schedule.month) + 1;
      schema.dayOfMonth = schedule.dayOfMonth;
    }

    return new SchedulingConverter(schema as SchedulingSchema);
  }

  convert(): SchedulingConverted {
    let repeat = SchedulingRepeat.Never;
    let every: string | undefined;
    let month: string;
    let dayOfMonth: string;
    let on: string[];
    let time = getDateTimeNow()
      .set({
        hour: Number(this.schema.hour),
        minute: Number(this.schema.minute),
      })
      .toFormat("t");

    if (
      yup
        .object({
          weekOfYear: yup.string().matches(/\*/),
          weekOfMonth: yup.string().matches(/\*/),
          year: yup.string().matches(/\*/),
          month: yup.string().matches(/\*/),
          hour: yup.string().matches(/!*/),
          minute: yup.string().matches(/!*/),
          dayOfWeek: yup.string().matches(/\*/),
        })
        .unknown()
        .isValidSync(this.schema)
    ) {
      repeat = SchedulingRepeat.Daily;
      if (String(this.schema.dayOfMonth).includes("/")) {
        every = this.schema.dayOfMonth
          ? (String(this.schema.dayOfMonth).split("/").pop() as string)
          : undefined;
      } else {
        every = "1";
        on = ["0", "1", "2", "3", "4", "5", "6"];
      }
    } else if (
      yup
        .object({
          weekOfMonth: yup.string().matches(/\*/),
          year: yup.string().matches(/\*/),
          month: yup.string().matches(/\*/),
          hour: yup.string().matches(/!*/),
          minute: yup.string().matches(/!*/),
          dayOfMonth: yup.string().matches(/\*/),
          dayOfWeek: yup.string().matches(/!*/),
        })
        .unknown()
        .isValidSync(this.schema)
    ) {
      repeat = SchedulingRepeat.Weekly;

      if (String(this.schema.weekOfYear).includes("/")) {
        every = this.schema.weekOfYear
          ? (String(this.schema.weekOfYear).split("/").pop() as string)
          : undefined;
      } else {
        every = "1";
      }
      on = this.schema.dayOfWeek.split(",");
    } else if (
      yup
        .object({
          weekOfYear: yup.string().matches(/\*/),
          weekOfMonth: yup.string().matches(/\*/),
          year: yup.string().matches(/\*/),
          month: yup.string().matches(/(\d+\/\d+)|(1-12)/),
          hour: yup.string().matches(/!*/),
          minute: yup.string().matches(/!*/),
          dayOfMonth: yup.string().matches(/!*/),
          dayOfWeek: yup.string().matches(/\*/),
        })
        .unknown()
        .isValidSync(this.schema)
    ) {
      repeat = SchedulingRepeat.Monthly;
      if (this.schema.month.includes("/")) {
        every = this.schema.month.split("/").pop() as string;
      } else {
        every = "1";
      }
      dayOfMonth = this.schema.dayOfMonth;
    } else if (
      yup
        .object({
          weekOfYear: yup.string().matches(/\*/),
          weekOfMonth: yup.string().matches(/\*/),
          hour: yup.string().matches(/!*/),
          minute: yup.string().matches(/!*/),
          month: yup.string().matches(/!*/),
          dayOfMonth: yup.string().matches(/!*/),
        })
        .unknown()
        .isValidSync(this.schema)
    ) {
      repeat = SchedulingRepeat.Yearly;
      dayOfMonth = this.schema.dayOfMonth;
      month = String(Number(this.schema.month) - 1);
    } else {
      throw new Error("Could not detect frequency type");
    }

    // @ts-ignore
    return omitBy({ repeat, every, on, time, dayOfMonth, month }, isUndefined);
  }

  parse(pattern: string): SchedulingSchema {
    const [
      minute,
      hour,
      dayOfMonth,
      month,
      dayOfWeek,
      weekOfYear = "*",
      weekOfMonth = "*",
      year = "*",
    ] = pattern.split(/\s+/);

    return {
      minute,
      hour,
      dayOfMonth,
      month,
      dayOfWeek,
      weekOfYear,
      weekOfMonth,
      year,
    };
  }

  stringify(): string {
    return [
      this.schema.minute,
      this.schema.hour,
      this.schema.dayOfMonth,
      this.schema.month,
      this.schema.dayOfWeek,
      this.schema.weekOfYear,
      this.schema.weekOfMonth,
      this.schema.year,
    ].join(" ");
  }
}
