import React, { MouseEvent } from "react";
import { Box, Button } from "@mui/material";
import { UserAccessScope, WithPermissions } from "../../../WithPermissions";

export interface SubHeaderActionButtonProps {
  title: string;
  primary?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  accessScope: UserAccessScope;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const SubHeaderActionButton: React.FC<SubHeaderActionButtonProps> = ({
  title,
  primary,
  disabled,
  icon,
  accessScope,
  onClick,
}) => {
  const buttonStyles = primary
    ? `${disabled ? "" : "!border-blue"} !bg-brand !text-white`
    : "";

  return (
    <WithPermissions accessScope={accessScope}>
      <Button
        className={`global-btn !rounded-[100px] !border-2 !border-solid !py-1 !text-sm !font-bold !text-primary ${buttonStyles}`}
        disabled={disabled}
        variant="text"
        type="button"
        onClick={onClick}
        data-testid={`btn-sub-header-action-${title}`}
      >
        {title}
        {icon && (
          <Box className="ml-1.5 flex items-baseline !text-lg">{icon}</Box>
        )}
      </Button>
    </WithPermissions>
  );
};
