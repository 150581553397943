import { UserDataInput } from "../../../../../graphql/operations";
import yup from "../../../../../types/yup";
import { ParseFileContentError, parseFileContent } from "../../../../../utils";
import { UserFileData, ValidatedUser } from "./types";
import { uploadUserSchema } from "./uploadUserSchema";

type MapFileDataResult = {
  validatedUsers: ValidatedUser[];
  validationErrors: ParseFileContentError[];
};
export const parseUsersFile = async (
  file: File
): Promise<{
  fileData: UserFileData[];
  errors: ParseFileContentError[];
}> => {
  const map: { [key: string]: keyof UserFileData } = {
    "Last Name*": "lastName",
    "First Name*": "firstName",
    "Username*": "username",
    "Email*": "email",
    "Phone Number": "phoneNumber",
    "Role*": "groups",
    "Send Invitation": "sendInvitation",
  };

  try {
    const { data, errors } = await parseFileContent<UserFileData>({
      file,
      map,
    });
    return { fileData: data, errors };
  } catch (error) {
    let message = "Error parsing file.";
    if (error instanceof Error) {
      message = error.message;
    }
    return { fileData: [], errors: [{ row: 0, message }] };
  }
};
export const normalizeGroups = (groups: string): string => {
  return groups.toLowerCase().replace(/\s/g, "");
};
export const mapFileDataToCreateUserInput = (
  data: UserFileData[],
  customer_orgs_id: string[],
  org_name: string
): MapFileDataResult => {
  const errors: ParseFileContentError[] = [];

  if (!data.length) {
    errors.push({ row: 0, message: "Uploaded file is empty" });
  }
  const mapped = data.map((fileData, index) => {
    const row: number = index + 1; // Add 1 to index to get row number
    if (fileData.groups) {
      fileData.groups = normalizeGroups(fileData.groups);
    }
    try {
      const user: UserDataInput = uploadUserSchema.validateSync(
        {
          customer_orgs_id: [customer_orgs_id],
          org_name: org_name,
          lastName: fileData.lastName,
          firstName: fileData.firstName,
          name: fileData.username,
          email: fileData.email,
          phoneNumber: fileData.phoneNumber,
          groups: [fileData.groups],
          sendInvitation: fileData.sendInvitation,
        },
        { abortEarly: false }
      );

      return { user, row };
    } catch (error: any) {
      if (error.inner) {
        error.inner.forEach((err: yup.ValidationError) => {
          errors.push({
            row,
            message: err.message ?? "Error validating asset",
          });
        });
      } else {
        errors.push({
          row,
          message:
            error instanceof Error ? error.message : "Error validating asset",
        });
      }

      return null;
    }
  });
  return {
    validatedUsers: mapped.filter((user): user is ValidatedUser => !!user),
    validationErrors: errors,
  };
};
