import { useEffect, useState, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useNavigate } from "react-router-dom";
import { Divider, Stack, Typography, ThemeProvider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { SET_APP_THEME } from "../../constants";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import {
  useUpdateMyAccountMutation,
  useGetUserDataQuery,
  useGetBrandFilesQuery,
} from "../../graphql/operations";
import { Button } from "../../shared/components/Button";
import Dialog from "../../shared/components/Dialog";
import { DialogProps } from "../../shared/components/Dialog/Dialog";
import Spinner from "../../shared/components/Spinner";
import Logo from "../../shared/components/Svg/Logo";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { useCheckRouteSecurity } from "../../utils/routes/useCheckRouteSecurity";
import { useDisagreeDialogTheme } from "./useDisagreeDialogTheme";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TermsAndConditions: React.FC = () => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [agreeButtonDisabled, setAgreeButtonDisabled] = useState<boolean>(true);
  const [isDisagreeDialogVisible, setIsDisagreeDialogVisible] =
    useState<boolean>(false);
  const [isDocumentEmpty, setIsDocumentEmpty] = useState<boolean>(false);
  const theme = useDisagreeDialogTheme();
  const { defaultPath } = useCheckRouteSecurity();

  const { width, height } = useWindowSize();
  const { userInfo, logout } = useAuthContext();
  const { dispatch } = useAppContext();

  const { data: accountData } = useGetUserDataQuery();

  const {
    isLoading: isLoadingBrandFiles,
    isSuccess: isSuccessBrandFiles,
    data: existingBrandFiles,
  } = useGetBrandFilesQuery(
    {
      input: { id: accountData?.me.customerOrg.brandId ?? "" },
    },
    {
      enabled: Boolean(accountData?.me.customerOrg.brandId),
    }
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const ui_theme = accountData?.me?.user_preferences?.ui_theme ?? null;

  const disagreeDialogProps = {
    title: "Warning You Are About To Logout",
    content:
      "If you do not agree with the Terms of Use you will be automatically logged out of the system",
    buttons: [
      {
        label: "Yes, log me out",
        color: "primary",
        onClick: () => {
          setIsDisagreeDialogVisible(false);
          logout();
          // navigate to the default path
          setTimeout(() => {
            navigate(defaultPath);
          });
        },
      },
      {
        label: "No, stay on page",
        color: "primary",
        onClick: () => {
          setIsDisagreeDialogVisible(false);
        },
      },
    ],
  } as DialogProps;

  useEffect(() => {
    if (ui_theme) {
      dispatch({
        type: SET_APP_THEME,
        payload: ui_theme,
      });
    }
  }, [dispatch, ui_theme]);

  const termsWidth = width && width < 700 ? width : 700;
  const pageWidth = width && width < 700 ? width - 30 : 670;
  const pageHeight = height ? height - 220 : 700;

  const { mutate } = useUpdateMyAccountMutation({
    onSuccess: () => {},
    onError: () => {},
  });

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setNumPages(numPages);
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer as HTMLDivElement;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
    setIsDocumentEmpty(false);
  };

  const onDocumentLoadError = (error: Error) => {
    if (error.message.startsWith("The PDF file is empty")) {
      setAgreeButtonDisabled(false);
      setIsDocumentEmpty(true);
    } else {
      throw error;
    }
  };

  const handleAgreeClick = useCallback(() => {
    if (userInfo) {
      mutate({
        user: {
          name: userInfo.username,
          onboarding_details: { terms_conditions_accepted: true },
        },
      });

      // optimistically update the data
      queryClient.setQueryData(["getUserData"], (prev: any) => ({
        me: {
          ...prev.me,
          onboarding_details: {
            terms_conditions_accepted: true,
          },
        },
      }));

      // navigate to the default path
      setTimeout(() => {
        navigate(defaultPath);
      });
    }
  }, [queryClient, defaultPath, mutate, navigate, userInfo]);

  const onEulaScrollHandler = (event: React.UIEvent<HTMLElement>) => {
    if (agreeButtonDisabled) {
      const { scrollHeight, scrollTop, clientHeight } =
        event.target as HTMLElement;

      const scrollableHeight = scrollHeight - clientHeight;

      const scrollPercentage = (scrollTop / scrollableHeight) * 100;

      const isScrollFivePercentAboveBottom = scrollPercentage >= 95;

      setAgreeButtonDisabled(!isScrollFivePercentAboveBottom);
    }
  };

  return (
    <div
      className="fixed top-0 left-0 right-0 !z-[1250] min-h-screen !bg-background"
      data-testid="terms-and-conditions-container"
    >
      {isDisagreeDialogVisible && (
        <ThemeProvider theme={theme}>
          <Dialog {...disagreeDialogProps} />
        </ThemeProvider>
      )}
      <div className="terms-container" style={{ width: termsWidth }}>
        <Stack
          className="mb-1 w-full pt-2 text-primary"
          justifyContent="center"
          alignItems="center"
        >
          <Logo />
        </Stack>
        <Divider className="!mb-6" />

        <Typography
          variant="h3"
          className="!text-2xl !leading-snug"
          gutterBottom
        >
          Phillips Connect Terms of Use
        </Typography>
        {isLoadingBrandFiles && <Spinner counter={1} />}
        {!isLoadingBrandFiles && isSuccessBrandFiles && (
          <>
            <div
              className="pdf-container mb-4"
              style={{ height: pageHeight }}
              onScroll={onEulaScrollHandler}
              data-testid="scrollable-pdf-container"
            >
              <Document
                file={existingBrandFiles?.getBrandFiles.eulaDocument?.url}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
                error={isDocumentEmpty && <div />}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={pageWidth}
                  />
                ))}
              </Document>
            </div>

            <Stack
              className="px-3"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Button
                text="I disagree"
                size="medium"
                sx={{
                  border: "none!important",
                  color: "var(--brand)!important",
                  "&:hover": { background: "transparent!important" },
                  "&.disagreeBtn:disabled": {
                    background: "transparent!important",
                  },
                }}
                onClick={() => setIsDisagreeDialogVisible(true)}
                className="mt-2 disagreeBtn"
                dataTestid="btn-org-form-disagree"
                disabled={agreeButtonDisabled}
              />
              <Button
                text="I agree"
                size="medium"
                theme="blue"
                variant="default"
                onClick={handleAgreeClick}
                className="mt-2"
                dataTestid="btn-org-form-submit"
                disabled={agreeButtonDisabled}
              />
            </Stack>
          </>
        )}
      </div>
    </div>
  );
};

export default TermsAndConditions;
