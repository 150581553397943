import { ASSETS_LIST_TABLE_SEARCH_SET_LOADING } from "../../constants";
import { Action } from "../commonTypes";

export type AssetsListReducerInitialState = {
  isTableSearchLoading: boolean;
};

export type AssetsListReducerType = (
  state: AssetsListReducerInitialState | undefined,
  action: Action
) => AssetsListReducerInitialState;

export const initialAssetsListState: AssetsListReducerInitialState = {
  isTableSearchLoading: false,
};

export const AssetsListReducer = (
  state = initialAssetsListState,
  action: Action
) => {
  switch (action.type) {
    case ASSETS_LIST_TABLE_SEARCH_SET_LOADING:
      return {
        ...state,
        isTableSearchLoading: action.payload.isTableSearchLoading,
      };
    default:
      return state;
  }
};
