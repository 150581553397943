import { useCallback } from "react";
import { Chip, Typography } from "@mui/material";
import { useGetDistanceUnitPreference } from "../../../../../../shared/hooks/useGetDistanceUnitPreference";
import { useGetPressureUnitPreference } from "../../../../../../shared/hooks/useGetPressureUnitPreference";
import { useGetTemperatureUnitPreference } from "../../../../../../shared/hooks/useGetTemperatureUnitPreference";
import { usePreferredTimezone } from "../../../../../../shared/hooks/usePreferredTimezone";
import {
  AssetFilters,
  IGeofenceFilters,
  useAssetsDataContext,
} from "../../../AssetsDataContext";
import { IMapAssetFilterValueParams, mapAssetFilterValue } from "../helpers";

interface IFilterChipProps<
  K extends keyof AssetFilters | keyof IGeofenceFilters
> {
  filter: K extends keyof AssetFilters
    ? AssetFilters[K]
    : K extends keyof IGeofenceFilters
    ? IGeofenceFilters[K]
    : never;
  filterKey: K;
  filterDisplayName: string;
}

const FilterChip = <K extends keyof AssetFilters | keyof IGeofenceFilters>({
  filter,
  filterKey,
  filterDisplayName,
}: IFilterChipProps<K>) => {
  const {
    resetFilterToDefault,
    resetGeofenceFilterToDefault,
    isGeofencesView,
  } = useAssetsDataContext();

  const unitPreferences: IMapAssetFilterValueParams["unitPreferences"] = {
    distance: useGetDistanceUnitPreference(),
    pressure: useGetPressureUnitPreference(),
    temperature: useGetTemperatureUnitPreference(),
    // TODO: PRJIND-9386 Implement usage of weight unit preference
  };

  const preferredTimezone = usePreferredTimezone();

  const filterValue = mapAssetFilterValue({
    filterKey,
    filterValue: filter,
    unitPreferences,
    preferredTimezone,
  });

  const onDelete = useCallback(() => {
    if (isGeofencesView) {
      resetGeofenceFilterToDefault(filterKey as keyof IGeofenceFilters);
    } else {
      resetFilterToDefault(filterKey as keyof AssetFilters);
    }
  }, [
    filterKey,
    resetFilterToDefault,
    isGeofencesView,
    resetGeofenceFilterToDefault,
  ]);

  return (
    <Chip
      onDelete={onDelete}
      label={
        <Typography className="flex !text-[12px] gap-x-2">
          {`${filterDisplayName}:`}
          <Typography
            component="span"
            className="!font-bold !text-[12px] overflow-hidden whitespace-nowrap text-ellipsis"
          >
            {filterValue}
          </Typography>
        </Typography>
      }
    />
  );
};

export default FilterChip;
