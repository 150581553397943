import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { capitalize } from "lodash";
import { AssetActivityEvent } from "../../../../../graphql/operations";
import { createField } from "./../../../../../shared/helpers/utils";
import { columnTimezoneDateTimeFormatParams } from "./../../../../../utils/date";

export const columnVisibilityModel = {
  postedAt: true,
  eventType: true,
  description: true,
  userFullName: true,
};

export const getColumns = (
  timezone: string
): GridColDef<AssetActivityEvent>[] =>
  [
    {
      field: "event",
      headerName: "Event",
      minWidth: 100,
      width: 150,
      renderCell: (params: GridRenderCellParams) => capitalize(params.value),
    },
    {
      ...createField("description", "Description", {
        flex: 1,
        minWidth: 300,
        filterable: false,
      }),
    },
    {
      field: "user",
      headerName: "User",
      minWidth: 300,
    },
    {
      field: "eventDate",
      headerName: "Date/Time",
      minWidth: 200,
      ...columnTimezoneDateTimeFormatParams(timezone),
    },
  ].map((column) => ({
    ...column,
    sortable: column.field !== "description",
  }));

export const SEARCH_KEYS = ["postedAt", "eventType", "userFullName"];
export const ACTIVITY_LOG_DEFAULT_SORT_COLUMN = "postedAt";
