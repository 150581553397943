import { FC } from "react";
import { useGridApiRef, GridRowParams } from "@mui/x-data-grid-premium";
import ABSFaultCodesTable, {
  ABSFaultCodesTableProps,
} from "./Tables/ABSFaultCodes/Table";
import AssetsTable, { AssetsTableProps } from "./Tables/Assets/AssetsTable";
import DetentionRulesTable, {
  DetentionRulesTableProps,
} from "./Tables/DetentionRules/DetentionRulesTable";
import GeofenceTypesTable, {
  GeofenceTypesTableProps,
} from "./Tables/GeofenceTypes/GeofenceTypesTable";
import ProductsTable, {
  ProductsTableProps,
} from "./Tables/Products/ProductsTable";
import {
  ConfigurationOptions,
  ConfigurationTypes,
} from "./configurationsUtils";

export interface TableGatewayProps {
  type: ConfigurationTypes;
  orgId: string | undefined;
  onRowClick: (data: GridRowParams) => void;
}

const tables: Record<
  ConfigurationOptions,
  React.FC<
    | AssetsTableProps
    | GeofenceTypesTableProps
    | ProductsTableProps
    | ABSFaultCodesTableProps
    | DetentionRulesTableProps
  >
> = {
  [ConfigurationOptions.Assets]: AssetsTable,
  [ConfigurationOptions.GeofenceTypes]: GeofenceTypesTable,
  [ConfigurationOptions.Products]: ProductsTable,
  [ConfigurationOptions.FaultCodes]: ABSFaultCodesTable,
  [ConfigurationOptions.DetentionRules]: DetentionRulesTable,
};

const TableGateway: FC<TableGatewayProps> = (props) => {
  const { type, orgId, onRowClick, ...rest } = props;

  const gridApiRef = useGridApiRef();

  const Component = tables[type];

  return (
    <Component
      apiRef={gridApiRef}
      orgId={orgId}
      onRowClick={onRowClick}
      {...rest}
    />
  );
};

export default TableGateway;
