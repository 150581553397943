import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Grid } from "@mui/material";
import { TextButton } from "../../../../../../shared/components/Button";

export interface ToggleConfigurationSetActiveButtonProps {
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const ToggleConfigurationSetActiveButton: React.FC<
  ToggleConfigurationSetActiveButtonProps
> = ({ isActive, disabled, onClick }) => {
  const icon = isActive ? (
    <NotInterestedIcon sx={{ width: 20, height: 20 }} />
  ) : (
    <CheckCircleOutlinedIcon sx={{ width: 20, height: 20 }} />
  );

  return (
    <Grid container className="justify-center bg-background py-3">
      <Grid item>
        <TextButton
          data-testid="action-toggle-config"
          size="medium"
          text={isActive ? "Deactivate" : "Activate"}
          className={isActive ? "!text-error" : "!text-success"}
          disabled={disabled}
          icon={icon}
          iconPosition={"left"}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};
