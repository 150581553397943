import { isFinite, isNil, uniqBy } from "lodash";
import { checkIsValidDate, formatDate } from "./date";

export function shouldNoteHaveTrimSpaces(this: any) {
  return this.test(
    "shouldNoteHaveTrimSpaces",
    function (this: any, value: string | undefined) {
      const { path, createError } = this;
      if (value?.startsWith(" ")) {
        return createError({
          path,
          message: "Should not begin with Whitespace",
        });
      }
      if (value?.endsWith(" ")) {
        return createError({
          path,
          message: "Should not end with Whitespace",
        });
      }
      return true;
    }
  );
}

export const transformers = {
  boolean: (value: unknown) => (typeof value === "boolean" ? value : undefined),
  string: (value: unknown) =>
    isNil(value) ? undefined : typeof value === "string" ? value.trim() : value,
  number: (value: unknown) =>
    value && isFinite(Number(value)) ? Number(value) : undefined,
  date: (value: string | number | undefined) =>
    value && checkIsValidDate(new Date(value))
      ? formatDate(new Date(value))
      : undefined,
  nullableString: (value: unknown) =>
    isNil(value) ? "" : typeof value === "string" ? value.trim() : value,
  geofenceTags: (value: unknown, originalValue: unknown) => {
    let tags;
    if (originalValue) {
      // The field value is an array when it comes from OpenSearch
      if (Array.isArray(originalValue)) {
        // Ensure each tag is a string, remove extra spaces, and deduplicate
        tags = originalValue.map((val) => String(val).trim()).filter(Boolean);
        return uniqBy(tags, (tag) => tag);
      } else if (typeof originalValue === "string") {
        // The field value is a string when it's generated by the CreatableAutocomplete component
        if (originalValue.includes(",")) {
          // When there are multiple tags, they are separated with a comma. We need to create an array from them.
          tags = originalValue
            .split(",")
            .map((val: string) => String(val).trim())
            .filter(Boolean);
          return uniqBy(tags, (tag) => tag);
        } else {
          // When there's one tag, it's a single string. We need to return an array with one item.
          return [String(originalValue).trim()].filter(Boolean);
        }
      }
    } else {
      return [];
    }
  },
  objectToString: (value: unknown, originalValue: any) => {
    if (originalValue && typeof originalValue === "object") {
      if (Object.hasOwn(originalValue, "value")) {
        return originalValue.value;
      } else if (Object.hasOwn(originalValue, "id")) {
        return originalValue.id;
      }
    }
  },
};
