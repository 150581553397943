import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import {
  commonInitialRemindersValues,
  dwellCargoCommonSchema,
  getAlertCommonSchema,
} from "../utils";

export const useAlertDwellForm = (
  initialValues = {
    name: "",
    type: "",
    parameters: {
      assetIds: [],
      assetType: "",
      insideGeofence: false,
      outsideGeofence: false,
      dwellThreshold: "",
      dwellThresholdUnit: "",
    },
    reminders: commonInitialRemindersValues,
    message: "",
    trigger: null,
  }
) => {
  const alertCommonSchema = getAlertCommonSchema();
  const schema = yup.object().shape({
    parameters: yup.object().when(["saveAsNewAlertsType"], {
      is: false,
      then: (scheme) =>
        scheme.shape({
          ...dwellCargoCommonSchema,
          insideGeofence: yup.boolean().optional(),
          outsideGeofence: yup.boolean().optional(),
          dwellThreshold: yup
            .string()
            .nullable()
            .test(
              "dwellThresholdUnit",
              "Select Dwell Threshold, please",
              (value, { parent }) => {
                return !(parent.dwellThresholdUnit && !parent.dwellThreshold);
              }
            )
            .test(
              "dwellThresholdPositive",
              "Value should be positive",
              (value, { parent }) => {
                if (!value) return true;
                return +value > 0;
              }
            )
            .test(
              "dwellThresholdFloat",
              "Value should be an integer or a float with one decimal place",
              (value, { parent }) => {
                if (!value) return true;

                const floatValue = parseFloat(value);
                if (Number.isInteger(floatValue)) {
                  return true;
                } else {
                  return value.split(".")[1].length === 1;
                }
              }
            ),
          dwellThresholdUnit: yup
            .string()
            .nullable()
            .test(
              "dwellThreshold",
              "Select Threshold Unit, please",
              (value, { parent }) => {
                return !(parent.dwellThreshold && !parent.dwellThresholdUnit);
              }
            ),
        }),
    }),
    ...alertCommonSchema,
  });
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    values: omitBy(
      {
        ...initialValues,
        saveAsNewAlertsType: false,
        alertTemplateOrgIds: [],
      },
      isNil
    ),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
