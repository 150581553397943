import React from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Box } from "@mui/material";
import { AutomationActions } from "./AutomationActions";
import { AutomationQueryBuilder } from "./AutomationQueryBuilder";
import { AutomationTrigger } from "./AutomationTrigger";
import { AutomationInitialValues } from "./hooks/useAutomationForm";

interface AutomationFormProps {
  form: UseFormReturn<Partial<AutomationInitialValues>>;
  onSubmit?: (data: FieldValues) => void;
  isTemplate: boolean;
}

export const AutomationForm = ({
  onSubmit,
  form,
  isTemplate,
}: AutomationFormProps) => {
  return (
    <form
      autoComplete="off"
      onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
    >
      <Box className="px-4 md:px-8 lg:px-16">
        <AutomationTrigger form={form} isTemplate={isTemplate} />
        <AutomationQueryBuilder form={form} />
        <AutomationActions form={form} />
      </Box>
    </form>
  );
};
