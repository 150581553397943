import { useMemo } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { OrgData, useFindOrgsQuery } from "../../graphql/operations";
import { useUserData } from "./useUserData";

export const useAvailableOrgs = (): OrgData[] => {
  const userData = useUserData();
  const { data: orgData } = useFindOrgsQuery();
  const { userRolePermissions } = useAuthContext();

  return useMemo(
    () =>
      userRolePermissions.org.view
        ? orgData?.findOrgs ?? []
        : userData?.accessibleOrgs ?? [],
    [userRolePermissions, orgData, userData]
  );
};
