import { ReactElement } from "react";

export interface TableHeaderProps {
  text: string;
  icon?: ReactElement;
}

const TableHeader: React.FC<TableHeaderProps> = ({ text, icon }) => {
  return (
    <div className="font-bold flex justify-center items-center">
      <div className="mr-2" data-testid={`${text}-table-header`}>
        {text}
      </div>

      {icon}
    </div>
  );
};

export default TableHeader;
