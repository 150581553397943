import * as yup from "yup";
import { shouldNoteHaveTrimSpaces } from "../../../../../../../../utils/yupUtils";

yup.addMethod(yup.mixed, "shouldNoteHaveTrimSpaces", shouldNoteHaveTrimSpaces);

export const mainProfileDrawerFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Field is required!")
    .shouldNoteHaveTrimSpaces()
    .nullable(),
  orgName: yup.string().required("Field is required!").nullable(),
  default: yup.boolean().notRequired(),
});
