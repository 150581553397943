import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { QueryFormProps, QueryFormValues } from "../../interfaces";

interface ReportParametersFormProps {
  initialValues: QueryFormValues;
}
export const useGetReportQueryForm = (initialValues: QueryFormProps) => {
  const schema = yup.object().shape({
    trigger: yup.string().optional(),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: omitBy(initialValues, isNil),
  });
  return { form };
};
