import { Box, Typography } from "@mui/material";

const AssetsErrorMessage = () => {
  return (
    <Box
      className="py-4 px-8 md:p-8 bg-background"
      data-testid="assets-error-msg"
    >
      <Typography className="!mb-6 !text-lg !font-semibold text-typography">
        Asset is not connected to device.
      </Typography>
    </Box>
  );
};

export default AssetsErrorMessage;
