import { useAppContext } from "../../../../context/AppContext";

interface GoogleMapsZoomProps {
  mapInstance: google.maps.Map | null;
  setMapZoom: (zoom: number) => void;
}

const useGoogleMapZoom = ({ mapInstance, setMapZoom }: GoogleMapsZoomProps) => {
  const {
    state: { appConfig },
  } = useAppContext();

  const handleZoomIn = () => {
    if (mapInstance) {
      const currentZoom = mapInstance.getZoom() ?? 0;
      setMapZoom(currentZoom + 1);
    }
  };

  const handleZoomOut = () => {
    if (mapInstance) {
      const currentZoom = mapInstance.getZoom() ?? 0;
      setMapZoom(Math.max(currentZoom - 1, appConfig.map.minZoom));
    }
  };

  return {
    handleZoomIn,
    handleZoomOut,
  };
};

export default useGoogleMapZoom;
