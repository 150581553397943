import {
  AssetEventResponseData,
  AssetEventType,
  Maybe,
  OrgData,
} from "../../../../../graphql/operations";
import { mapFieldsLabel } from "../../../TableView/components/AssetForm/assetFormUtils";

export const ASSET_EVENTS_MAPPER: Record<AssetEventType, string> = {
  [AssetEventType.Created]: "Created",
  [AssetEventType.Updated]: "Updated",
  [AssetEventType.Install]: "Install",
  [AssetEventType.Transfer]: "Transfer",
  [AssetEventType.SharedAssetsInvitationCreated]: "Share Invitation Created",
  [AssetEventType.SharedAssetsInvitationDeclined]: "Share Invitation Declined",
  [AssetEventType.SharedAssetsInvitationAccepted]: "Share Accepted",
  [AssetEventType.AssetsSharingStopped]: "Share Stopped",
};

export const mapAssetEventName = (eventType: AssetEventType) => {
  const output = ASSET_EVENTS_MAPPER[eventType] ?? "";

  return output;
};

export const generateEventDescriptionMessage = (
  message: string,
  batchName?: Maybe<string>
): string => {
  return batchName ? `${message} via batch ${batchName}.` : `${message}.`;
};

export const mapAssetEventDescription = (
  eventType: string,
  data: AssetEventResponseData,
  availableOrgs: OrgData[],
  batchName?: Maybe<string>
) => {
  let output = "";

  switch (eventType) {
    case AssetEventType.Created: {
      output = generateEventDescriptionMessage(
        `Asset with id ${data.asset_id} was created`,
        batchName
      );
      break;
    }
    case AssetEventType.Updated: {
      const changedFieldsNames = mapChangedFieldsLabels(data.changes ?? []);

      output =
        data?.changes && data?.changes?.length > 0
          ? generateEventDescriptionMessage(
              `Asset updated. The following properties were changed: ${changedFieldsNames?.join(
                ", "
              )}`,
              batchName
            )
          : "";
      break;
    }
    case AssetEventType.Install: {
      output = generateEventDescriptionMessage(
        `Asset was associated with device ${data.imei}`,
        batchName
      );
      break;
    }
    case AssetEventType.Transfer: {
      const oldOrg = availableOrgs.find((org) => org._id === data.oldOrgId);
      const newOrg = availableOrgs.find((org) => org._id === data.newOrgId);
      output =
        oldOrg && newOrg
          ? generateEventDescriptionMessage(
              `Asset was transferred from organization ${oldOrg.name} to organization ${newOrg.name}`,
              batchName
            )
          : "";
      break;
    }
  }

  return output;
};

export const mapChangedFieldsLabels = (changes: string[]) => {
  return changes?.map(mapFieldsLabel);
};
