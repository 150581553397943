import { PropsWithChildren, ReactElement } from "react";
import { Box, Skeleton } from "@mui/material";

export const VerticalBarChartOverlay = ({ children }: PropsWithChildren) => {
  const verticalBarsHeight = [
    // width of left bars
    // Percentages because we need dynamic responsiveness
    "225px",
    "150px",
    "275px",
    "201px",
    "200px",
    "200px",
  ];

  // Find if legend component is passed in as children to the widget chart
  const isLegendChild = (children as any)?.props?.children?.find(
    (child: ReactElement) => (child?.type as any)?.displayName === "Legend"
  );

  return (
    <>
      <Box
        data-testid="loading-overlay-container"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          height: "100%",
          width: "100%",
        }}
      >
        {verticalBarsHeight.map((value, index) => (
          <Skeleton
            variant="rectangular"
            data-testid={`overlay-horizontal-bar-${index}`}
            key={`overlay-horizontal-bar-${index}`}
            sx={{
              marginRight: "30px",
              height: value,
              width: "40px",
            }}
          />
        ))}
      </Box>
      {isLegendChild && VerticalBarChartLegendOverlay()}
    </>
  );
};

export const VerticalBarChartLegendOverlay = () => {
  const legendElements = ["104px", "130px", "64px"];

  return (
    <Box display="flex" justifyContent="center" gap="8px" marginTop="24px">
      {legendElements.map((width, index) => (
        <Skeleton
          key={`bar-${index}`}
          variant="rounded"
          sx={{
            width: width,
          }}
        ></Skeleton>
      ))}
    </Box>
  );
};
