import CircleIcon from "@mui/icons-material/Circle";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import {
  AssetWithSensors,
  SensorProfileType,
  SensorStatusUppercase,
} from "../../../../../../graphql/operations";
import TableHeader from "../../../../../../shared/components/TableHeader/TableHeader";
import { createField } from "../../../../../../shared/helpers/utils";
import { tableStyleMap } from "../../../Profiles/profileUtils";
import { tableSensorStatusColorsMapping } from "../../sensorsUtils";

enum TableColumnNames {
  Status = "Status",
  AssetId = "Asset ID",
  DeviceId = "Device ID",
  ProductName = "Product Name",
  Profile = "Profile",
  HealthyLevelMoving = "Healthy Level Moving",
  WarningLevelMoving = "Warning Level Moving",
  AlertLevelMoving = "Alert Level Moving",
  CriticalLevelMoving = "Critical Level Moving",
  HealthyLevelParked = "Healthy Level Parked",
  WarningLevelParked = "Warning Level Parked",
  AlertLevelParked = "Alert Level Parked",
  CriticalLevelParked = "Critical Level Parked",
}

const atisDataObjectPath: Record<TableColumnNames, string> = {
  [TableColumnNames.Status]: "sensors.atisAlpha.statusAtisAlpha",
  [TableColumnNames.AssetId]: "asset_id",
  [TableColumnNames.DeviceId]: "imei",
  [TableColumnNames.ProductName]: "prd_cde",
  [TableColumnNames.Profile]: "sensorProfile.configuration.atisAlpha",
  [TableColumnNames.HealthyLevelMoving]: "atisAlpha.moving.healthy",
  [TableColumnNames.WarningLevelMoving]: "atisAlpha.moving.warning",
  [TableColumnNames.AlertLevelMoving]: "atisAlpha.moving.alert",
  [TableColumnNames.CriticalLevelMoving]: "atisAlpha.moving.critical",
  [TableColumnNames.HealthyLevelParked]: "atisAlpha.parked.healthy",
  [TableColumnNames.WarningLevelParked]: "atisAlpha.parked.warning",
  [TableColumnNames.AlertLevelParked]: "atisAlpha.parked.alert",
  [TableColumnNames.CriticalLevelParked]: "atisAlpha.parked.critical",
};

const createFieldWithOptions = (
  field: string,
  label: string,
  options: Record<string, any> = {}
) => ({
  ...createField(field, label, { flex: 1, minWidth: 120, ...options }),
});

export const sensorsAtisAlphaTableColumns: GridColDef<AssetWithSensors>[] = [
  {
    ...createField(atisDataObjectPath.Status, TableColumnNames.Status, {
      minWidth: 64,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CircleIcon
            sx={{
              color:
                tableSensorStatusColorsMapping[
                  params.value as SensorStatusUppercase
                ],
            }}
          />
        );
      },
    }),
  },
  createFieldWithOptions(
    atisDataObjectPath[TableColumnNames.AssetId],
    TableColumnNames.AssetId
  ),
  createFieldWithOptions(
    atisDataObjectPath[TableColumnNames.DeviceId],
    TableColumnNames.DeviceId
  ),
  createFieldWithOptions(
    atisDataObjectPath[TableColumnNames.ProductName],
    TableColumnNames.ProductName
  ),
  {
    ...createField(atisDataObjectPath.Profile, TableColumnNames.Profile, {
      flex: 1,
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => {
        return params?.value?.type === SensorProfileType.Asset
          ? "Custom"
          : params?.value?.profileName;
      },
    }),
  },
  {
    ...createField(
      atisDataObjectPath[TableColumnNames.HealthyLevelMoving],
      TableColumnNames.HealthyLevelMoving,
      {
        renderHeader: () => {
          return <TableHeader text={TableColumnNames.HealthyLevelMoving} />;
        },
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
        minWidth: 120,
        headerClassName: tableStyleMap.healthy,
        cellClassName: tableStyleMap.healthy,
      }
    ),
  },
  {
    ...createField(
      atisDataObjectPath[TableColumnNames.WarningLevelMoving],
      TableColumnNames.WarningLevelMoving,
      {
        renderHeader: () => {
          return <TableHeader text={TableColumnNames.WarningLevelMoving} />;
        },
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value?.warningFrom} - ${params.value?.warningTo}`,
        minWidth: 120,
        headerClassName: tableStyleMap.warning,
        cellClassName: tableStyleMap.warning,
      }
    ),
  },
  {
    ...createField(
      atisDataObjectPath[TableColumnNames.AlertLevelMoving],
      TableColumnNames.AlertLevelMoving,
      {
        renderHeader: () => {
          return <TableHeader text={TableColumnNames.AlertLevelMoving} />;
        },
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value?.alertFrom} - ${params.value?.alertTo}`,
        minWidth: 120,
        headerClassName: tableStyleMap.alert,
        cellClassName: tableStyleMap.alert,
      }
    ),
  },
  {
    ...createField(
      atisDataObjectPath[TableColumnNames.CriticalLevelMoving],
      TableColumnNames.CriticalLevelMoving,
      {
        renderHeader: () => {
          return <TableHeader text={TableColumnNames.CriticalLevelMoving} />;
        },
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
        minWidth: 120,
        headerClassName: tableStyleMap.critical,
        cellClassName: tableStyleMap.critical,
      }
    ),
  },
  {
    ...createField(
      atisDataObjectPath[TableColumnNames.HealthyLevelParked],
      TableColumnNames.HealthyLevelParked,
      {
        renderHeader: () => {
          return <TableHeader text={TableColumnNames.HealthyLevelParked} />;
        },
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
        minWidth: 120,
        headerClassName: tableStyleMap.healthy,
        cellClassName: tableStyleMap.healthy,
      }
    ),
  },
  {
    ...createField(
      atisDataObjectPath[TableColumnNames.WarningLevelParked],
      TableColumnNames.WarningLevelParked,
      {
        renderHeader: () => {
          return <TableHeader text={TableColumnNames.WarningLevelParked} />;
        },
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value?.warningFrom} - ${params.value?.warningTo}`,
        minWidth: 120,
        headerClassName: tableStyleMap.warning,
        cellClassName: tableStyleMap.warning,
      }
    ),
  },
  {
    ...createField(
      atisDataObjectPath[TableColumnNames.AlertLevelParked],
      TableColumnNames.AlertLevelParked,
      {
        renderHeader: () => {
          return <TableHeader text={TableColumnNames.AlertLevelParked} />;
        },
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value?.alertFrom} - ${params.value?.alertTo}`,
        minWidth: 120,
        headerClassName: tableStyleMap.alert,
        cellClassName: tableStyleMap.alert,
      }
    ),
  },
  {
    ...createField(
      atisDataObjectPath[TableColumnNames.CriticalLevelParked],
      TableColumnNames.CriticalLevelParked,
      {
        renderHeader: () => {
          return <TableHeader text={TableColumnNames.CriticalLevelParked} />;
        },
        valueGetter: (params: GridValueGetterParams) =>
          `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
        minWidth: 120,
        headerClassName: tableStyleMap.critical,
        cellClassName: tableStyleMap.critical,
      }
    ),
  },
];
