import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Typography } from "@mui/material";
import { DATE_FORMAT, formatDate } from "../../../../utils/date";

export interface DatePickerTriggerProps {
  startDate: Date | null;
  endDate: Date | null;
  isDatePickerOpen: boolean;
  openDatePicker: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DatePickerTrigger: React.FC<DatePickerTriggerProps> = ({
  startDate,
  endDate,
  isDatePickerOpen,
  openDatePicker,
}) => {
  return (
    <Typography
      sx={{
        p: 1,
        gap: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        fontSize: "14px",
        lineHeight: "16px",
        borderRight: "2px solid var(--autocomplete-highlight)",
        fontWeight: 700,
        color: "var(--brand)",
        cursor: "pointer",
        minWidth: "230px",
        paddingX: "1rem",
        paddingY: "0px",
      }}
      onClick={openDatePicker}
      data-testid="date-range-picker-trigger"
    >
      <CalendarTodayIcon data-testid="date-range-picker-trigger--icon" />

      {startDate && endDate && (
        <span data-testid="date-range-picker-trigger--range">
          {formatDate(startDate, DATE_FORMAT)} -{" "}
          {formatDate(endDate, DATE_FORMAT)}
        </span>
      )}
    </Typography>
  );
};

export default DatePickerTrigger;
