import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../hooks/theme/useCurrentTheme";

export const useReadOnlyMenuTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiDivider: {
          styleOverrides: {
            root: {
              borderColor: "var(--read-only-menu-typography)",
            },
          },
        },
        MuiPopper: {
          styleOverrides: {
            root: {
              borderRadius: "10px",
              padding: "0 10px",
              minWidth: "240px",
              maxWidth: "280px",
              backgroundColor: "var(--read-only-menu-background)",
              zIndex: 100,
              marginRight: "10px",
              color: "var(--read-only-menu-typography)",
            },
          },
        },
      },
    })
  );
};
