import React from "react";
import { Box, Button } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import {
  DateRangeItem,
  DateRangeLabel,
  PREDEFINED_DATE_RANGES,
} from "../constants";

export interface DateRangePickerProps {
  selectedDateRangeOption: DateRangeLabel | null;
  setSelectedDateRangeOption: (value: DateRangeLabel) => void;

  setDateRange: (value: DateRange<Date>) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  selectedDateRangeOption,
  setSelectedDateRangeOption,
  setDateRange,
}) => {
  const handleDateRangeClick = (item: DateRangeItem) => {
    const { label, getValue } = item;
    const [startDate, endDate] = getValue();

    setSelectedDateRangeOption(label);
    setDateRange([startDate, endDate]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "16px",
        margin: "8px",
      }}
      data-testid="date-ranges-picker"
    >
      {PREDEFINED_DATE_RANGES.map((item) => (
        <Button
          key={item.label}
          className={`${
            selectedDateRangeOption === item.label ? "selected" : ""
          }`}
          onClick={() => {
            handleDateRangeClick(item);
          }}
          sx={{
            borderRadius: "4px",
            padding: "8px 12px",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            color: "var(--card__typography)",
            backgroundColor: "var(--card__bg)",
            "&:hover": {
              color: "var(--card__typography)",
              backgroundColor: "var(--card__bg)",
            },
            "&.selected": {
              color: "var(--white)",
              backgroundColor: "var(--brand)",
              "&:hover": {
                color: "var(--white)",
                backgroundColor: "var(--brand)",
              },
            },
          }}
          data-testid="date-ranges-picker--button"
        >
          {item.label}
        </Button>
      ))}
    </Box>
  );
};

export default DateRangePicker;
