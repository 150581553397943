import { memo } from "react";
import Page from "../../shared/components/Page";
import { RegionsContextProvider } from "../AdminPanel/tabs/Regions/context";
import AssetsViewContainer from "./AssetsViewContainer";
import { AssetsDataContextProvider } from "./shared/AssetsDataContext";
import AssetsFilterControls from "./shared/AssetsFilterControls";
import type { AssetViewProps } from "./types";

const AssetView = memo(({ isGeofencesView }: AssetViewProps) => (
  <AssetsDataContextProvider isGeofencesView={isGeofencesView}>
    <RegionsContextProvider>
      <Page className="bg-background" contentClassNames="overflow-hidden">
        <AssetsFilterControls />
        <AssetsViewContainer />
      </Page>
    </RegionsContextProvider>
  </AssetsDataContextProvider>
));

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
AssetView.displayName = "AssetsView";
export default AssetView;
