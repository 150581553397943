import { useCallback, useEffect, useState } from "react";
import { Box, Card, Chip, Icon, Typography } from "@mui/material";
import { Bar, Legend, Tooltip } from "recharts";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import VerticalBarChartWidget from "../../../../../shared/components/DashboardWidgets/VerticalBarChartWidget";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import {
  dateToExactDateTimeStringNoTz,
  getEndOfDay,
  getStartOfDay,
} from "../../../../../utils";
import {
  ChartTooltip,
  ChartType,
} from "../../../../AssetsView/MapView/Shared/ChartTooltip/ChartTooltip";
import {
  DEFAULT_DASHBOARD_END_DATE,
  DEFAULT_DASHBOARD_START_DATE,
} from "../../shared";
import { formatDateIntoLabel, hasNoPermission } from "../../shared/utils";
import { reportingDevicesMap } from "./ReportingDevicesWidget";

export type ReportingDevicesPerPeriod = {
  date: string;
  Reporting: number;
  "Not Reporting": number;
  "N/A": number;
};

export type ReportingDevicesPerPeriodData = {
  date: Date | string;
  reporting: number;
  notReporting: number;
  notApplicable: number;
};

export interface DashboardWidgetReportingDevicesPerPeriodData {
  reportingDevicesPerPeriod: ReportingDevicesPerPeriodData[];
}

export interface DashboardWidgetReportingDevicesPerPeriodTypes
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetReportingDevicesPerPeriodData;
}

export interface ReportingDevicesPerPeriodDataWidgetProps {
  widget: DashboardWidgetReportingDevicesPerPeriodTypes;
}

export const colorMap = {
  [reportingDevicesMap.reporting]: "rechartsGreen",
  [reportingDevicesMap.notReporting]: "rechartsBlue",
  [reportingDevicesMap.notApplicable]: "rechartsCoral",
};

export const ReportingDevicesPerPeriodWidget: React.FC<
  ReportingDevicesPerPeriodDataWidgetProps
> = ({ widget }) => {
  const isDarkMode = useCurrentTheme().palette.mode === "dark";

  const customerOrganization = useSelectedOrg();

  const [startDate, setStartDate] = useState<Date>(
    DEFAULT_DASHBOARD_START_DATE
  );
  const [endDate, setEndDate] = useState<Date>(DEFAULT_DASHBOARD_END_DATE);

  let [data, setData] = useState<ReportingDevicesPerPeriod[]>([]);

  const handleDateChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      if (startDate && endDate) {
        // They come in as dates and we need exact datetime strings
        setStartDate(getStartOfDay(startDate));
        setEndDate(getEndOfDay(endDate));
      }
    },
    [setStartDate, setEndDate]
  );

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: customerOrganization?._id ?? "",
        // Note: prevents the AWSDateTime input type resolver from casting to Date again and altering the desired date
        fromDate: dateToExactDateTimeStringNoTz(startDate),
        toDate: dateToExactDateTimeStringNoTz(endDate),
      },
    },
    {
      enabled:
        Boolean(customerOrganization?._id) &&
        Boolean(startDate) &&
        Boolean(endDate),
    }
  );

  useEffect(() => {
    const widgetData: ReportingDevicesPerPeriod[] = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data || "{}"
    ).reportingDevicesPerPeriod?.map(
      (device: ReportingDevicesPerPeriodData) => ({
        date: device.date,
        [reportingDevicesMap.reporting]: device.reporting,
        [reportingDevicesMap.notReporting]: device.notReporting,
        [reportingDevicesMap.notApplicable]: device.notApplicable,
      })
    );
    setData(widgetData ?? []);
  }, [getDashboardWidgetData]);

  const reportingDeviceLabelsSorted = Object.values(reportingDevicesMap).sort();

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
    >
      {data && !dashboardWidgetError && (
        <div data-testid="dashboard-widget--reporting-devices-per-period">
          <VerticalBarChartWidget
            /*
              startDate and endDate need to be converted to dates again
              to match the requirements of the DateRangePicker
            */
            startDate={startDate}
            endDate={endDate}
            xAxisTickFormatter={formatDateIntoLabel}
            onDateFilterChange={handleDateChange}
            title={widget.name ?? ""}
            yAxisLabel="Number of Devices"
            isLoading={isLoading}
            xAsisKey="date"
            barKey=""
            data={data}
            isDateFilterEnabled
            yAxisLabelColor={isDarkMode ? ColorsPalette.BrightWhite : undefined}
            yAxisLabelPosition="left"
            children={
              <>
                <Tooltip
                  cursor={false}
                  content={
                    <ChartTooltip type={ChartType.ReportingDevicesPerPeriod} />
                  }
                />
                {reportingDeviceLabelsSorted.map((report, index) => (
                  <Bar
                    maxBarSize={70}
                    dataKey={report}
                    key={index}
                    stackId={ChartType.ReportingDevicesPerPeriod}
                    fill={`var(--${colorMap[report]})`}
                  />
                ))}
                <Legend
                  content={({ payload }) => (
                    <Box className="flex justify-center gap-4">
                      {payload?.reverse().map((item: any) => (
                        <Chip
                          icon={
                            <Icon
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: "50%",
                                bgcolor: `var(--${colorMap[item.dataKey]})`,
                                marginRight: "8px",
                              }}
                            />
                          }
                          label={item.dataKey}
                        />
                      ))}
                    </Box>
                  )}
                />
              </>
            }
            noPermission={hasNoPermission(getDashboardWidgetData)}
          />
        </div>
      )}
      {Boolean(dashboardWidgetError) && (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the reporting devices
          per period chart! Please try again later.
        </Typography>
      )}
    </Card>
  );
};
