import { useState } from "react";
import { Path } from "react-hook-form";
import { Grid, ThemeProvider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  Asset,
  AssetSensorProfilesResponseStatus,
  LiteSentryGammaProfileSensorInput,
  LiteSentryGammaThresholds,
  MergedProfileForAsset,
  SetAssetSensorProfilesInput,
  SetAssetSensorProfilesMutation,
  SetAssetSensorProfilesResponse,
  useSetAssetSensorProfilesMutation,
} from "../../../../../graphql/operations";
import { liteSentryGammaDefaultValues } from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import { useUserPermission } from "../../../../../shared/hooks/useUserPermission";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import LiteSentryGammaSliderContainer from "../../../../AdminPanel/tabs/Profiles/Drawers/LiteSentryGammaDrawer/components/LiteSentryGammaSliderContainer";
import { useLiteSentryGammaForm } from "../../../../AdminPanel/tabs/Profiles/Drawers/LiteSentryGammaDrawer/hooks/useLiteSentryGammaForm";
import { LiteSentryGammaFormValues } from "../../../../AdminPanel/tabs/Profiles/Drawers/LiteSentryGammaDrawer/types";
import {
  parseLiteSentryGammaProfiles,
  prepareLiteSentryGammaConfig,
} from "../../../../AdminPanel/tabs/Profiles/Drawers/LiteSentryGammaDrawer/utils/utils";
import { convertLiteSentryGammaConfigToSensorInput } from "../../../../AdminPanel/tabs/Sensors/Drawers/LiteSentryGamma/utils";
import {
  analyzeResponse,
  handleAnalyzedResponse,
} from "../../../../AdminPanel/tabs/Sensors/sensorsUtils";
import { AccordionWrapper } from "../../../TableView/components/AssetForm/components/AccordionWrapper";
import SaveCancelButtons from "../SaveCancelButtons/SaveCancelButtons";
import { mapLightningStateToThresholds, useLightsSettingsState } from "./utils";

export interface LightsSettingsProps {
  asset: Asset;
  assetMergedSensorProfile: MergedProfileForAsset;
  isAssetRefetching: boolean;
}

export const LightsSettings: React.FC<LightsSettingsProps> = ({
  asset,
  assetMergedSensorProfile,
  isAssetRefetching,
}) => {
  const formTheme = useFormTheme();
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const isUserAllowed = useUserPermission("assetManagement.editSettings");
  // this transformation is because liteSentry in admin panel and assetDashboard shares one util
  const liteSentryGamma: any =
    assetMergedSensorProfile?.configuration?.liteSentryGamma;
  for (const scenario in liteSentryGamma) {
    if (
      Object.hasOwnProperty.call(liteSentryGamma, scenario) &&
      liteSentryGamma[scenario]?.match?.thresholds
    ) {
      liteSentryGamma[scenario] = {
        ...liteSentryGamma[scenario],
        ...liteSentryGamma[scenario]?.match?.thresholds,
      };
      delete liteSentryGamma[scenario]?.match;
    }
  }

  const { liteSentryGammaThresholds, liteSentryGammaSetPoints } =
    parseLiteSentryGammaProfiles(
      assetMergedSensorProfile?.configuration
        ?.liteSentryGamma as LiteSentryGammaThresholds
    );

  const [defaultThresholds, setDefaultThresholds] = useState(
    liteSentryGammaThresholds
  );

  const { form: liteSentryGammaForm } = useLiteSentryGammaForm(
    liteSentryGammaSetPoints
  );

  const {
    isValid: isLiteSentryGammaFormValid,
    isDirty: isLiteSentryGammaFormDirty,
  } = liteSentryGammaForm.formState;

  const state = useLightsSettingsState(liteSentryGammaThresholds);

  // GraphQL hooks
  const { mutate: mutateCustomProfile, isLoading } =
    useSetAssetSensorProfilesMutation({
      onSuccess: async (data: SetAssetSensorProfilesMutation) => {
        const response = analyzeResponse(
          data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
          AssetSensorProfilesResponseStatus.Success
        );
        handleAnalyzedResponse(
          response,
          showErrorMessage,
          showSuccessMessage,
          queryClient
        );
      },
    });

  const showSuccessMessage = () => {
    queryClient.invalidateQueries({ queryKey: ["getMergedProfileForAsset"] });
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success",
        text: "Lights Settings Updated Successfully!",
        severity: "success",
      },
    });
  };

  const showErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Lights Settings Update Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleCancelClick = () => {
    liteSentryGammaForm.reset(liteSentryGammaSetPoints);
    state.brake.present.setBluePowerPresentValues(
      liteSentryGammaThresholds.brakeCircuitThresholds
        .bluePowerPresentThresholds ?? liteSentryGammaDefaultValues
    );
    state.brake.notPresent.setBluePowerNotPresentValues(
      liteSentryGammaThresholds.brakeCircuitThresholds
        .bluePowerNotPresentThresholds ?? liteSentryGammaDefaultValues
    );
    state.brake.precheck.setBrakeCircuitPrecheckValues(
      liteSentryGammaThresholds.brakeCircuitThresholds.precheckThresholds ??
        liteSentryGammaDefaultValues
    );
    state.left.present.setLeftTurnSignalCircuitTractorPowerPresentValues(
      liteSentryGammaThresholds.leftTurnSignalThresholds
        .tractorPowerPresentThresholds ?? liteSentryGammaDefaultValues
    );
    state.left.precheck.setLeftTurnSignalCircuitPrecheckValues(
      liteSentryGammaThresholds.leftTurnSignalThresholds.precheckThresholds ??
        liteSentryGammaDefaultValues
    );
    state.right.present.setRightTurnSignalCircuitTractorPowerPresentValues(
      liteSentryGammaThresholds.rightTurnSignalThresholds
        .tractorPowerPresentThresholds ?? liteSentryGammaDefaultValues
    );
    state.right.precheck.setRightTurnSignalCircuitPrecheckValues(
      liteSentryGammaThresholds.rightTurnSignalThresholds.precheckThresholds ??
        liteSentryGammaDefaultValues
    );
    state.marker.present.setMarkerCircuitTractorPowerPresentValues(
      liteSentryGammaThresholds.markerCircuitThresholds
        .tractorPowerPresentThresholds ?? liteSentryGammaDefaultValues
    );
    state.marker.precheck.setMarkerCircuitPrecheckValues(
      liteSentryGammaThresholds.markerCircuitThresholds.precheckThresholds ??
        liteSentryGammaDefaultValues
    );
    state.license.present.setLicenseCircuitTractorPowerPresentValues(
      liteSentryGammaThresholds.licenseCircuitThresholds
        .tractorPowerPresentThresholds ?? liteSentryGammaDefaultValues
    );
    state.license.precheck.setLicenseCircuitPrecheckValues(
      liteSentryGammaThresholds.licenseCircuitThresholds.precheckThresholds ??
        liteSentryGammaDefaultValues
    );
  };

  const handleSubmitClick = () => {
    if (!asset.imei) return;

    const liteSentryGammaFormData: LiteSentryGammaFormValues =
      liteSentryGammaForm.getValues();

    const liteSentryGammaConfig = prepareLiteSentryGammaConfig(
      liteSentryGammaFormData,
      state
    );

    const liteSentryGammaConfigInput: LiteSentryGammaProfileSensorInput =
      convertLiteSentryGammaConfigToSensorInput(liteSentryGammaConfig);

    const input: SetAssetSensorProfilesInput = {
      selectedImeis: [asset.imei ?? ""],
      orgId: asset.customer_orgs_id ?? "",
      sensors: {
        liteSentryGamma: liteSentryGammaConfigInput,
      },
    };

    mutateCustomProfile({ input });

    setDefaultThresholds(mapLightningStateToThresholds(state));
  };

  const areThresholdsEqual = isEqual(
    defaultThresholds,
    mapLightningStateToThresholds(state)
  );

  const areButtonsDisabled =
    (!isLiteSentryGammaFormValid ||
      !isLiteSentryGammaFormDirty ||
      isAssetRefetching) &&
    areThresholdsEqual;

  const liteSentryGammaFormElements: {
    title: string;
    sections: {
      subtitle: string;
      name: Path<LiteSentryGammaFormValues>;
      values: number[];
      onChange: React.Dispatch<React.SetStateAction<number[]>>;
    }[];
  }[] = [
    {
      title: "Brake Circuit Settings",
      sections: [
        {
          subtitle: "Blue Power Present",
          name: "brakeCircuitSetPoints.bluePowerPresentSetPoint",
          values: state.brake.present.bluePowerPresentValues,
          onChange: state.brake.present.setBluePowerPresentValues,
        },
        {
          subtitle: "Blue Power Not Present",
          name: "brakeCircuitSetPoints.bluePowerNotPresentSetPoint",
          values: state.brake.notPresent.bluePowerNotPresentValues,
          onChange: state.brake.notPresent.setBluePowerNotPresentValues,
        },
        {
          subtitle: "Precheck",
          name: "brakeCircuitSetPoints.precheckSetPoint",
          values: state.brake.precheck.brakeCircuitPrecheckValues,
          onChange: state.brake.precheck.setBrakeCircuitPrecheckValues,
        },
      ],
    },
    {
      title: "Left Turn Signal Circuit Settings",
      sections: [
        {
          subtitle: "Tractor Power Present",
          name: "leftTurnSignalCircuitSetPoints.tractorPowerPresentSetPoint",
          values:
            state.left.present.leftTurnSignalCircuitTractorPowerPresentValues,
          onChange:
            state.left.present
              .setLeftTurnSignalCircuitTractorPowerPresentValues,
        },
        {
          subtitle: "Precheck",
          name: "leftTurnSignalCircuitSetPoints.precheckSetPoint",
          values: state.left.precheck.leftTurnSignalCircuitPrecheckValues,
          onChange: state.left.precheck.setLeftTurnSignalCircuitPrecheckValues,
        },
      ],
    },
    {
      title: "Right Turn Signal Circuit Settings",
      sections: [
        {
          subtitle: "Tractor Power Present",
          name: "rightTurnSignalCircuitSetPoints.tractorPowerPresentSetPoint",
          values:
            state.right.present.rightTurnSignalCircuitTractorPowerPresentValues,
          onChange:
            state.right.present
              .setRightTurnSignalCircuitTractorPowerPresentValues,
        },
        {
          subtitle: "Precheck",
          name: "rightTurnSignalCircuitSetPoints.precheckSetPoint",
          values: state.right.precheck.rightTurnSignalCircuitPrecheckValues,
          onChange:
            state.right.precheck.setRightTurnSignalCircuitPrecheckValues,
        },
      ],
    },
    {
      title: "Marker Circuit Settings",
      sections: [
        {
          subtitle: "Tractor Power Present",
          name: "markerCircuitSetPoints.tractorPowerPresentSetPoint",
          values: state.marker.present.markerCircuitTractorPowerPresentValues,
          onChange:
            state.marker.present.setMarkerCircuitTractorPowerPresentValues,
        },
        {
          subtitle: "Precheck",
          name: "markerCircuitSetPoints.precheckSetPoint",
          values: state.marker.precheck.markerCircuitPrecheckValues,
          onChange: state.marker.precheck.setMarkerCircuitPrecheckValues,
        },
      ],
    },
    {
      title: "License Circuit Settings",
      sections: [
        {
          subtitle: "Tractor Power Present",
          name: "licenseCircuitSetPoints.tractorPowerPresentSetPoint",
          values: state.license.present.licenseCircuitTractorPowerPresentValues,
          onChange:
            state.license.present.setLicenseCircuitTractorPowerPresentValues,
        },
        {
          subtitle: "Precheck",
          name: "licenseCircuitSetPoints.precheckSetPoint",
          values: state.license.precheck.licenseCircuitPrecheckValues,
          onChange: state.license.precheck.setLicenseCircuitPrecheckValues,
        },
      ],
    },
  ];

  return (
    <ThemeProvider theme={formTheme}>
      {liteSentryGammaFormElements.flatMap((element) => (
        <AccordionWrapper
          accordionSummaryTitle={element.title}
          showDetailsHeader
          key={element.title}
          classNames="liteSentryGammaAccordionDetails"
        >
          <Grid
            container
            direction={"column"}
            spacing={2}
            data-testid="lights-settings-wrapper"
          >
            {element.sections.map((section) => (
              <LiteSentryGammaSliderContainer
                sectionTitle={section.subtitle}
                control={liteSentryGammaForm.control}
                values={section.values}
                name={section.name}
                disabled={isLoading}
                isLoading={isLoading}
                onChange={section.onChange}
                key={section.subtitle}
              />
            ))}
          </Grid>
        </AccordionWrapper>
      ))}
      {isUserAllowed && (
        <SaveCancelButtons
          handleCancelClick={handleCancelClick}
          handleSubmitClick={handleSubmitClick}
          isLoading={isLoading}
          areButtonsDisabled={areButtonsDisabled}
          isUserAllowed={isUserAllowed}
        />
      )}
    </ThemeProvider>
  );
};
