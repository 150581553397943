import { InternalCameraView } from "./interface";

export const INTERNAL_CAMERA_VIEWS: {
  value: InternalCameraView;
  label: string;
}[] = [
  {
    value: InternalCameraView.OneImage,
    label: "One Image View",
  },
  {
    value: InternalCameraView.FourImages,
    label: "Last 4 Images",
  },
  {
    value: InternalCameraView.SixImages,
    label: "Last 6 Images",
  },
];

export const INTERNAL_CAMERA_VIEWS_MOBILE: {
  value: InternalCameraView;
  label: string;
}[] = [
  {
    value: InternalCameraView.OneImage,
    label: "One Image View",
  },
  {
    value: InternalCameraView.TwoImages,
    label: "Last 2 Images",
  },
  {
    value: InternalCameraView.FourImages,
    label: "Last 4 Images",
  },
];
