import { memo, SVGProps, FC } from "react";

interface AssetsProps extends SVGProps<SVGSVGElement> {}

const Assets: FC<AssetsProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.6667 2L13.56 2.02L10 3.4L6 2L2.24 3.26667C2.1 3.31333 2 3.43333 2 3.58667V13.6667C2 13.8533 2.14667 14 2.33333 14L2.44 13.98L6 12.6L10 14L13.76 12.7333C13.9 12.6867 14 12.5667 14 12.4133V2.33333C14 2.14667 13.8533 2 13.6667 2ZM10 12.6667L6 11.26V3.33333L10 4.74V12.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Assets);
