import { useState, useEffect, memo, useCallback } from "react";
import { Snackbar as MuiSnackbar, Alert, AlertTitle } from "@mui/material";
import { PAGE_SNACKBAR } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";

export type SnackbarProps = {
  title?: string;
  text?: string;
  open?: boolean;
  severity?: "error" | "warning" | "info" | "success";
  horizontal?: "center" | "left" | "right";
  vertical?: "top" | "bottom";
  onClose?: () => void;
};

const Snackbar = ({
  title,
  text,
  open: isOpen,
  severity = "error",
  horizontal = "center",
  vertical = "top",
  onClose,
}: SnackbarProps) => {
  const { dispatch } = useAppContext();

  const [open, setOpen] = useState(isOpen ?? false);

  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch({ type: PAGE_SNACKBAR, payload: null });
  }, [dispatch]);

  useEffect(() => {
    setOpen(!!text);
  }, [title, text, severity, onClose]);

  if (!title && !text) return null;

  return (
    <MuiSnackbar
      data-testid="snackbar"
      onClose={handleClose}
      autoHideDuration={4000}
      anchorOrigin={{ horizontal, vertical }}
      open={open}
      onClick={handleClose}
      className="snackbar"
      ClickAwayListenerProps={{ onClickAway: handleClose }}
    >
      <Alert
        variant="filled"
        severity={severity}
        data-testid="snackbar-alert"
        className="fullwidth"
        onClose={handleClose}
      >
        {!!title && (
          <AlertTitle data-testid="snackbar-title">{title}</AlertTitle>
        )}
        {text}
      </Alert>
    </MuiSnackbar>
  );
};

export default memo(Snackbar);
