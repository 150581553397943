import { useEffect } from "react";
import muiTheme, { muiDarkTheme } from "../../../utils/muiTheme";
import { useTheme } from "./useTheme";
import { themes } from "./utils";

/**
 * This hook returns MUI theme basing on current theme.
 * @param preference
 */
export const useMUITheme = (preference: themes) => {
  const { theme, setPreference } = useTheme(preference);

  useEffect(() => {
    setPreference(preference);
  }, [setPreference, preference]);

  return theme === "dark" ? muiDarkTheme : muiTheme;
};
