import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  ReactElement,
} from "react";
import combineReducers from "react-combine-reducers";
import { Action } from "../../../../../../context/commonTypes";
import {
  initialBreadcrumbsState,
  BreadcrumbsReducer,
  BreadcrumbsInitialState,
  BreadcrumbsReducerType,
} from "./reducers/breadcrumbs";

// rewritten to typescript to avoid duplication of explicit types definitions in different modules
type BreadcrumbsContextState = {
  breadcrumbs: BreadcrumbsInitialState;
};

type BreadcrumbsContextType = {
  state: BreadcrumbsContextState;
  dispatch: React.Dispatch<Action>;
};

type BreadcrumbsContextReducer = (
  state: BreadcrumbsContextState,
  action: Action
) => BreadcrumbsContextState;

export const initialState = {
  breadcrumbs: initialBreadcrumbsState,
};

export const BreadcrumbsContext = createContext<BreadcrumbsContextType>({
  state: initialState,
  dispatch: () => {},
});

export const BreadcrumbsContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [rootReducerCombined, initialStateCombined] =
    combineReducers<BreadcrumbsContextReducer>({
      breadcrumbs: [
        BreadcrumbsReducer as BreadcrumbsReducerType,
        initialBreadcrumbsState,
      ],
    });

  const [state, dispatch] =
    useReducer<BreadcrumbsContextReducer>(
      rootReducerCombined,
      initialStateCombined
    ) || {};

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <BreadcrumbsContext.Provider value={contextValue}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbsContext = () => useContext(BreadcrumbsContext);
