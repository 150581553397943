import * as yup from "yup";
import { AIR_BAG_SENSOR_FORM_FIELDS } from "../../sensorsUtils";

export const airBagSensorSchema = yup.object().shape({
  typeOfAssignment: yup.string().required("Field is required!").nullable(),
  airbagSensorProfile: yup
    .string()
    .nullable()
    .when("typeOfAssignment", {
      is: (value: string) => {
        return value === AIR_BAG_SENSOR_FORM_FIELDS[0].options[1].id;
      },
      then: yup.string().required("Field is required!").nullable(),
      otherwise: yup.string().nullable().notRequired(),
    }),
});
