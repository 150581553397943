import { Box, Typography } from "@mui/material";

export interface ChartTooltipSectionProps {
  sectionHeader: string;
  leftValueLabel: string;
  leftValue: string;
  rightValueLabel: string;
  rightValue: string;
}

/**
 * Reusable chart tooltip section that represents specific design structure for all tooltips in charts
 * Tooltip header
 * label: value  label: value
 */
export const ChartTooltipSection: React.FC<ChartTooltipSectionProps> = ({
  sectionHeader,
  leftValueLabel,
  leftValue,
  rightValueLabel,
  rightValue,
}) => {
  return (
    <Box>
      <Typography
        sx={{
          color: "var(--brand-blue)",
          fontWeight: 700,
          fontSize: "12px",
          paddingBottom: "0.5rem",
        }}
      >
        {sectionHeader}
      </Typography>

      {/* Left value container */}
      <Box display="flex">
        <Box
          display="flex"
          sx={{
            marginRight: "6px",
          }}
          data-testid="chartTooltipLeftContainer"
        >
          <Typography
            sx={{
              color: "var(--battery-label)",
              fontWeight: 400,
              marginRight: "2px",
            }}
            className="!text-xs"
          >
            {leftValueLabel}
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
            }}
            className="!text-xs !text-sub-header-text"
          >
            {leftValue}
          </Typography>
        </Box>

        {/* Right value container */}
        <Box display="flex" data-testid="ChartTooltipRightContainer">
          <Typography
            sx={{
              color: "var(--battery-label)",
              fontWeight: 400,
              marginRight: "2px",
            }}
            className="!text-xs"
          >
            {rightValueLabel}
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
            }}
            className="!text-xs !text-sub-header-text"
          >
            {rightValue}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
