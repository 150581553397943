import { Grid, Typography } from "@mui/material";

interface SummaryProps {
  showDetailsHeader: boolean;
  title: string;
  header?: boolean;
}

export const SectionHeader = ({
  showDetailsHeader = false,
  title,
  header,
}: SummaryProps) => {
  let marginBottom = showDetailsHeader ? "" : "!mb-10";
  return (
    <Grid
      item
      xs={12}
      className={`${marginBottom}`}
      sx={{ color: "var(--form-label-default)" }}
    >
      {showDetailsHeader ? (
        <Typography
          className={`${header ? "!text-brand" : ""}`}
          sx={{
            fontSize: header ? "24px" : "16px",
            fontWeight: 600,
            lineHeight: header ? "32px" : "24px",
          }}
        >
          {title}
        </Typography>
      ) : (
        ""
      )}
    </Grid>
  );
};
