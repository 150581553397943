import { GridColDef } from "@mui/x-data-grid-premium";
import { Dwell } from "../../../../../graphql/operations";
import {
  columnTimezoneDateTimeFormatParams,
  columnTimezoneTimeFormatParams,
} from "../../../../../utils";
import { DwellGridColDef } from "../../Geofences/GeofenceDwellHistory/helpers";

export interface TableRowEventData extends Dwell {
  totalDwell?: any;
  dateTime?: any;
}

const getBaseColumn = (
  field: string,
  headerName: string,
  minWidth: number = 120,
  options?: Partial<GridColDef>
) => ({
  field,
  headerName,
  flex: 1,
  minWidth,
  cellClassName: "capitalize",
  ...options,
});

const getTimezoneColumn = (
  field: string,
  headerName: string,
  timezone: string,
  minWidth = 120,
  isDateTimeNeeded: boolean = true
) => {
  const dateField = isDateTimeNeeded
    ? columnTimezoneDateTimeFormatParams(timezone)
    : columnTimezoneTimeFormatParams(timezone);

  return {
    ...getBaseColumn(field, headerName, minWidth),
    ...dateField,
  };
};

export const getColumns = (
  timezone: string,
  typeOfTable: string
): GridColDef<TableRowEventData>[] | DwellGridColDef[] => {
  const assetDwellColumns = [
    getBaseColumn("geofenceName", "Name", 215),
    getBaseColumn("category", "Category", 150),
    getTimezoneColumn("arrivalTime", "Arrival Time", timezone, 150, false),
    getBaseColumn("currentCargoState", "Current Cargo State", 150),
    getTimezoneColumn("dateTime", "Date and Time", timezone, 180),
    getBaseColumn("unloadDuration", "Unload Duration", 150, {
      renderCell: (option) => Math.round(option.value / 60) + "h",
    }),
    getTimezoneColumn("departureTime", "Departure Time", timezone, 150, false),
    getBaseColumn("arrivalCargoState", "Cargo Status Upon Arrival", 215),
    getBaseColumn("departureCargoState", "Cargo Status Upon Exit", 215),
    getBaseColumn("totalDwell", "Total Dwell", 150),
  ];

  const geofenceDwellColumns = [
    getBaseColumn("assetId", "Asset ID", 150),
    getBaseColumn("imei", "Imei", 150),
    getTimezoneColumn("timestamp", "Date And Time", timezone, 180),
    getBaseColumn("enteredAtDays", "Days In Detention", 150),
    getBaseColumn("detentionStatus", "Detention Status", 150),
    getTimezoneColumn("arrivalTime", "Entered At", timezone, 150, false),
    getTimezoneColumn("departureTime", "Exited At", timezone, 150, false),
    getTimezoneColumn(
      "unloadDuration",
      "Unload Duration",
      timezone,
      150,
      false
    ),
    getBaseColumn("arrivalCargoState", "Cargo Status On Enter", 150),
    getBaseColumn("departureCargoState", "Cargo Status On Exit", 150),
    getBaseColumn("totalDwell", "Total Dwell", 150),
  ];
  return typeOfTable === "asset" ? assetDwellColumns : geofenceDwellColumns;
};
