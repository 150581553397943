import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import { HorizontalBarChart } from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type DashboardWidgetMediaPerSuborgMediaData = {
  name: string;
  value: number;
  percentage: number;
};

export type DashboardWidgetMediaPerSuborgData = {
  mediaPerSuborg: DashboardWidgetMediaPerSuborgMediaData[];
  total: number;
};

export interface DashboardWidgetMediaPerSuborg
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetMediaPerSuborgData;
}

export interface MediaPerSuborgWidgetProps {
  widget: DashboardWidgetMediaPerSuborg;
}

const mapWidgetData = (data: DashboardWidgetMediaPerSuborgData) =>
  data?.mediaPerSuborg?.map((media) => ({
    ...media,
    percent: media.percentage,
    color:
      media.name === "Other"
        ? ColorsPalette.Concrete
        : ColorsPalette.ReChartsMarineBlue,
  })) ?? [];

export const MediaPerSuborgWidget: React.FC<MediaPerSuborgWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "Media";

  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetMediaPerSuborgData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.total ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--media-per-suborg"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the media per
          suborganization widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          data={data}
          title={title}
          totalRecords={total}
          isLoading={isLoading}
          showPercentages
          showTotal
          subtitle="Total Number Of Stored Files"
          showXAxis={false}
          minPointSize={125}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
