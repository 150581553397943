import { ChangeEvent } from "react";
import Search from "@mui/icons-material/SearchSharp";
import { OutlinedInput } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

export type TableSearchProps = {
  className?: string;
  showMinTextLengthWarning?: boolean;
  searchText: string | undefined;
  onChange: (searchText: string) => void;
};
export const TableSearch = ({
  className = "",
  showMinTextLengthWarning,
  searchText,
  onChange,
}: TableSearchProps) => (
  <div className={`${className} flex justify-end`}>
    {showMinTextLengthWarning && (
      <div className="absolute top-[25%] right-[10%] text-xs font-normal text-typography">
        Keep typing ...
      </div>
    )}
    <OutlinedInput
      placeholder="Table search"
      className="!mb-2 w-full !rounded-none md:w-auto"
      value={searchText}
      onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
        onChange(value)
      }
      classes={{ notchedOutline: "!border-concrete" }}
      inputProps={{
        className: "!py-[10px] !text-sm !text-typography",
        "data-testid": "input-table-search",
      }}
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
    />
  </div>
);
