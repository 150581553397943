import * as yup from "yup";

interface FormField<T extends string, V> {
  name: T;
  type: string;
  label: string;
  options: V[];
  required: boolean;
}

export const CARGO_CAMERA_FORM_FIELDS: FormField<
  string,
  { label: string; value: string; id: string }
>[] = [
  {
    name: "typeOfAssignment",
    type: "autocomplete",
    label: "Type of Assignment",
    options: [
      {
        label: "Custom",
        value: "custom",
        id: "custom",
      },
      {
        label: "Profile",
        value: "profile",
        id: "profile",
      },
    ],
    required: true,
  },
  {
    name: "cameraSensorProfile",
    type: "autocomplete",
    label: "Profile Name",
    options: [],
    required: true,
  },
];

export const cargoCameraSensorSchema = yup.object().shape({
  typeOfAssignment: yup.string().required("Field is required!").nullable(),
  cameraSensorProfile: yup
    .string()
    .required("Field is required!")
    .nullable()
    .when("typeOfAssignment", {
      is: (value: string) => {
        return value === CARGO_CAMERA_FORM_FIELDS[0].options[1].id;
      },
      then: yup.string().required("Field is required!").nullable(),
      otherwise: yup.string().nullable().notRequired(),
    }),
});
