import { MutableRefObject, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  TableViewType,
} from "../../../../../graphql/operations";
import { Table, getRows } from "../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../shared/hooks/useExportedFileName";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../constants";
import {
  MappedSensorProfile,
  ProfilesData,
  columnVisibilityModel,
  liteSentryGammaTableColumns,
  liteSentryGammaTableKeyList,
  mapLiteSentryGammaSensorProfileData,
} from "../profileUtils";

export interface LiteSentryGammaProfileTableProps {
  tableType: TableViewType;
  onRowClick: (value: any) => void;
  apiRef: MutableRefObject<GridApiPremium>;
  profilesData: ProfilesData;
}

const LiteSentryGammaProfileTable: React.FC<
  LiteSentryGammaProfileTableProps
> = ({ onRowClick, apiRef, profilesData, tableType }) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const fileName = useExportedFileName("Profiles");

  const [tableRows, setTableRows] = useState<SensorProfileResult[]>([]);

  useEffect(() => {
    const mappedProfiles = mapLiteSentryGammaSensorProfileData(
      profilesData.data ?? []
    );

    setTableRows(
      getRows<MappedSensorProfile[]>(
        mappedProfiles,
        liteSentryGammaTableColumns,
        "",
        "",
        "_id"
      )
    );
  }, [profilesData.data]);

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={tableType}
        showToolbar
        rows={tableRows}
        apiRef={apiRef}
        columns={liteSentryGammaTableColumns}
        columnVisibilityModel={columnVisibilityModel}
        onRowClick={onRowClick}
        loading={profilesData.isFetchingProfile}
        error={profilesData.isErrorProfile}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={liteSentryGammaTableKeyList}
        sortKeys={liteSentryGammaTableKeyList}
        searchMinLength={appConfig.searchMinLength}
        tableName="profiles"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        isDataRefetching={false}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default LiteSentryGammaProfileTable;
