import StarIcon from "@mui/icons-material/Star";
import { GridColDef, ValueOptions } from "@mui/x-data-grid-premium";
import { ROOT_ORGANIZATION_NAME } from "../../../constants";
import { Dashboard, MeUserData } from "../../../graphql/operations";
import { FormFieldDropdownOption } from "../../../types";
import { columnTimezoneDateTimeFormatParams } from "../../../utils";

interface DashboardWithMetadata extends Dashboard {
  rowMetadata?: {
    userFavoriteDashboards: number[];
    organizationFavoriteDashboards: number[];
  };
}

export const getColumns = (
  timezone: string,
  availableOrgs: ValueOptions[]
): GridColDef<DashboardWithMetadata>[] => {
  return [
    {
      field: `name`,
      headerName: "Dashboard Name",
      type: "string",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return params.row.rowMetadata?.userFavoriteDashboards?.includes(
          parseInt(params.id as string)
        ) ||
          params.row.rowMetadata?.organizationFavoriteDashboards?.includes(
            parseInt(params.id as string)
          )
          ? renderDashboardNameElement(params)
          : params.value;
      },
    },
    {
      field: `customerOrgId`,
      headerName: "Organization",
      type: "singleSelect",
      flex: 1,
      minWidth: 120,
      filterable: false,
      sortable: false,
      valueGetter: ({ row }) => {
        /*
          "Initial" dashboards don't have customer_org_id so we simply
          display the PCT organization name.
        */
        if (!row?.customerOrgId) return ROOT_ORGANIZATION_NAME;
        return (
          (
            availableOrgs?.find(
              (org) =>
                (org as FormFieldDropdownOption)?.value === row?.customerOrgId
            ) as FormFieldDropdownOption
          )?.label ?? ROOT_ORGANIZATION_NAME
          /*
           According to the BE logic, the only org whose dashboard a user might see
           and not have access to in "availableOrgs" is Phillips Connect because he/she
           is seeing it from a suborg without access to Phillips Connect
           (so we replace the ID with the Phillips Connect name)
          */
        );
      },
    },
    {
      field: `createdAt`,
      headerName: "Created Date",
      flex: 1,
      minWidth: 120,
      ...columnTimezoneDateTimeFormatParams(timezone),
    },
    {
      field: `updatedAt`,
      headerName: "Updated Date",
      flex: 1,
      minWidth: 120,
      ...columnTimezoneDateTimeFormatParams(timezone),
    },
  ];
};

export const tableKeyList: string[] = getColumns("UTC", []).map(
  (column) => column.field
);

const renderDashboardNameElement = (params: any) => {
  return (
    <div>
      {params.value}
      <StarIcon className="!text-brand ml-2" />
    </div>
  );
};

export const toggleElement = <T extends unknown>(
  array: T[],
  element: T
): T[] => {
  // Find the index of the element in the array
  const index = array.indexOf(element);

  // If the element is not found, add it to the array
  if (index === -1) {
    return [...array, element];
  }
  // If the element is found, remove it from the array
  else {
    return array.filter((_, i) => i !== index);
  }
};

export const toggleFavoriteDashboard = (
  favoriteDashboards: number[],
  dashboardId: number | undefined,
  setFavoriteDashboards: React.Dispatch<React.SetStateAction<number[]>>,
  mutate: (variables: {
    user: { name: string; user_preferences: { favorite_dashboards: number[] } };
  }) => void,
  userData: MeUserData | undefined
) => {
  const updatedFavorites = toggleElement(
    favoriteDashboards,
    dashboardId
  ).filter((id): id is number => id !== null && id !== undefined);
  setFavoriteDashboards(updatedFavorites);

  if (userData) {
    mutate({
      user: {
        name: userData.name,
        user_preferences: {
          favorite_dashboards: updatedFavorites,
        },
      },
    });
  }
};
