import { memo, ReactNode } from "react";
import { Button as MUIButton, SxProps } from "@mui/material";
import "./button.scss";
import { getButtonIconProps } from "./getButtonIconProps";

export type ButtonProps = {
  size?: "small" | "medium" | "large" | "root";
  theme?: "blue" | "black" | "white" | "red";
  variant?: "default" | "ghost";
  disabled?: boolean;
  iconPosition?: "none" | "left" | "right";
  icon?: ReactNode;
  text?: string;
  onClick?: () => void;
  className?: string;
  sx?: SxProps;
  type?: "button" | "reset" | "submit";
  dataTestid?: string;
};

const Button = (props: ButtonProps) => {
  const {
    onClick,
    type,
    size = "small",
    variant = "ghost",
    theme = "black",
    disabled = false,
    iconPosition = "none",
    text = "",
    className = "",
    icon = null,
    sx = {},
  } = props;
  const classBasedOnProps = `global-btn global-btn--${size} global-btn__variant--${variant} global-btn__theme--${theme} ${className}`;
  const iconProps =
    iconPosition && iconPosition !== "none"
      ? getButtonIconProps(iconPosition, icon)
      : {};
  return (
    <MUIButton
      sx={sx}
      data-testid={props.dataTestid || "global-button-component"}
      className={classBasedOnProps}
      disabled={disabled}
      onClick={onClick}
      {...iconProps}
      {...(type && { type: type })}
    >
      {text}
    </MUIButton>
  );
};

export default memo(Button);
