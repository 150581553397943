import { useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  AutocompleteElement,
  CheckboxElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { ThemeProvider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ConfirmationDialog } from "../../../../../../../../shared/components/ConfirmationDialog/ConfirmationDialog";
import { useFormTheme } from "../../../../../../../../shared/hooks/theme/useFormTheme";
import { useAvailableOrgs } from "../../../../../../../../shared/hooks/useAvailableOrgs";
import { useOrgsOptions } from "../../../../../../../AssetsView/TableView/hooks";
import { ProfileDrawerMainFormValues } from "../../hooks/useProfileDrawerMainForm";

export interface ProfilesDrawerMainFormProps {
  form: UseFormReturn<ProfileDrawerMainFormValues>;
  disabled?: boolean;
  isEdit?: boolean;
}

export const ProfilesDrawerMainForm: React.FC<ProfilesDrawerMainFormProps> = ({
  form,
  disabled = false,
  isEdit,
}) => {
  const formTheme = useFormTheme();
  const availableOrgs = useAvailableOrgs();
  const orgOptionsSorted = useOrgsOptions(availableOrgs);
  const orgOptions = useMemo(
    () =>
      orgOptionsSorted.map((org) => ({
        id: org.id ?? "",
        label: org.label ?? "",
      })),
    [orgOptionsSorted]
  );

  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);

  const onCheckboxClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const isChecked = form.getValues().default ?? false;
    if (!isChecked) {
      event.preventDefault();
      setIsConfirmDialogVisible(true);
    }
  };

  const handleConfirm = (isConfirmed: boolean) => {
    if (isConfirmed) {
      form.setValue("default", true, { shouldDirty: true });
    }
    setIsConfirmDialogVisible(false);
  };

  return (
    <ThemeProvider theme={formTheme}>
      <Grid className="noTopPaddingDrawerSection" direction="column" container>
        <Grid item>
          <AutocompleteElement
            autocompleteProps={{
              disabled: disabled || isEdit,
              readOnly: disabled,
            }}
            matchId={true}
            control={form.control}
            loading={!orgOptions?.length}
            rules={{ required: true }}
            label="Organization Name"
            name="orgName"
            options={orgOptions}
          />
        </Grid>

        <Grid item>
          <TextFieldElement
            name="name"
            label="Profile Name"
            control={form.control}
            disabled={disabled}
            fullWidth
            required
          />
        </Grid>

        <CheckboxElement
          name="default"
          label="Set as Default Profile"
          control={form.control}
          onClick={onCheckboxClick}
          disabled={disabled}
        />
      </Grid>

      <ConfirmationDialog
        title="Change of default organization profile"
        message="You are about to change the default organization profile! Not all profiles have the full set of sensor settings. Continue with the change?"
        open={isConfirmDialogVisible}
        confirmButtonText={"OK"}
        cancelButtonText="Cancel"
        handleConfirmationResult={handleConfirm}
      />
    </ThemeProvider>
  );
};
