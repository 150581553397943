import { FC, useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import { Maybe } from "../../../../../../../../graphql/operations";
import { useApplicationTheme } from "../../../../../../../../shared/hooks/theme/useApplicationTheme";
import { AssetTiresSensorState } from "../../../../AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { TiresSensorStateWidget } from "../../../../AssetShortTooltip/components/TiresSensorStateWidget/TiresSensorStateWidget";
import { useGetSensorStateWidget } from "../../../../AssetShortTooltip/hooks/useGetSensorStateWidget";
import { TIRE_CARD_HEIGHT, defaultBorderStyling } from "../../../constants";
import { useTiresTabContext } from "../../../context";
import { TPMS_Axle } from "../../../interfaces";
import { AxleDualImbalanceData } from "../../../types";

export interface AxleSummaryCardProps {
  axle: TPMS_Axle;
  tiresSensorsState: AssetTiresSensorState;
  dualImbalanceAxlesStates: Maybe<AxleDualImbalanceData> | undefined; // left & right state data for every a single axle
}

const AxleSummaryCard: FC<AxleSummaryCardProps> = ({
  axle,
  tiresSensorsState,
  dualImbalanceAxlesStates,
}) => {
  const { getTiresSensorsChips, getDualImbalanceChips } =
    useGetSensorStateWidget();
  const {
    selectedTpmsAxle: selectedAxle,
    selectedTpmsTire: selectedTire,
    selectedDualImbalanceRecord,
  } = useTiresTabContext();

  const { applicationTheme } = useApplicationTheme();
  const [isDarkTheme, setIsDarkTheme] = useState(applicationTheme === "dark");

  useEffect(() => {
    setIsDarkTheme(applicationTheme === "dark");
  }, [applicationTheme]);

  // Determine the state of the axle
  const tiresSensors = getTiresSensorsChips(tiresSensorsState, {
    iconWidth: "26px",
    iconHeight: "26px",
  });

  const dualImbalanceChips = getDualImbalanceChips(dualImbalanceAxlesStates, {
    iconWidth: "26px",
    iconHeight: "26px",
  });

  return (
    <Card
      className={`background-white border min-w-[280px] items-center flex`}
      sx={{
        boxShadow: "none",
        width: "100%",
        borderRadius: "8px",
        height: TIRE_CARD_HEIGHT,
        borderColor: isDarkTheme ? "var(--asset-card-border)" : "var(--gray)",
      }}
      data-testid="asset-dashboard--tires-tab--axle-card-summary"
    >
      <CardContent className="w-full">
        <TiresSensorStateWidget
          sensorLabel={axle}
          sensorsChips={tiresSensors}
          dualImbalanceChips={dualImbalanceChips}
          size="big"
          selectedTire={selectedAxle === axle ? selectedTire : undefined}
          selectedDualImbalanceRecord={selectedDualImbalanceRecord}
        />
      </CardContent>
    </Card>
  );
};

export default AxleSummaryCard;
