import { FC } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import {
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import {
  GetTableViewPreferencesResult,
  TableViewPreferenceColumn,
} from "../../../graphql/operations";

export interface MenuListItemsProps {
  testId: string;
  list: GetTableViewPreferencesResult[];
  isDefault: (name: string | undefined) => boolean;
  isAlterDisabled: (listCreatorId: string | undefined) => boolean;
  onOpenEditDialog: (
    selectedTableLayout: GetTableViewPreferencesResult
  ) => void;
  onOpenDeleteDialog: (_id: string, name: string) => void;
  onSelectItem: (columns: TableViewPreferenceColumn[]) => void;
}

const MenuListItems: FC<MenuListItemsProps> = ({
  testId,
  list,
  isDefault,
  isAlterDisabled,
  onOpenEditDialog,
  onOpenDeleteDialog,
  onSelectItem,
}) => {
  const handleSelect = (item: GetTableViewPreferencesResult) => {
    if (item?.value?.columns) {
      onSelectItem(item.value.columns);
    }
  };

  const handleDelete = (item: GetTableViewPreferencesResult) => {
    if (item?._id && item?.name) {
      onOpenDeleteDialog(item._id, item.name);
    }
  };

  if (!list?.length) return null;

  return (
    <>
      {list?.map((item) => {
        if (!item?.name) return null;

        return (
          <MenuItem data-testid={testId} key={item._id}>
            <ListItemText
              className="mr-5 truncate"
              title={item.name}
              data-testid="list-item-text"
              primaryTypographyProps={{
                style: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              onClick={() => handleSelect(item)}
            >
              {isDefault(item.name) ? `${item.name} - Default` : item.name}
            </ListItemText>
            <ListItemIcon>
              <IconButton
                data-testid="edit-list-action"
                sx={{
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                disabled={isAlterDisabled(item.userId ?? "")}
                onClick={() => onOpenEditDialog(item)}
              >
                <DriveFileRenameOutlineSharpIcon
                  sx={{ width: "18px", height: "22px", marginRight: "8px" }}
                />
              </IconButton>
              <IconButton
                data-testid="delete-list-action"
                sx={{
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                disabled={isAlterDisabled(item.userId ?? "")}
                onClick={() => handleDelete(item)}
              >
                <DeleteOutlineIcon sx={{ width: "19px", height: "22px" }} />
              </IconButton>
            </ListItemIcon>
          </MenuItem>
        );
      })}
    </>
  );
};

export default MenuListItems;
