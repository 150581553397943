import DeviceHistoryTable from "../../DeviceHistoryTable/DeviceHistoryTable";

interface DeviceActivityLogProps {
  device_id: string;
}

export const DeviceActivityLog = ({ device_id }: DeviceActivityLogProps) => {
  return (
    <div data-testid="activity-log-tab" className="h-[90%] px-11 pb-11 mt-8">
      <DeviceHistoryTable
        device_id={device_id}
        useExtendedSearch={false}
        searchThreshold={0.3}
      />
    </div>
  );
};
