import React, { ReactElement } from "react";
import { Bar, Line, Tooltip } from "recharts";
import { BaseDashboardWidgetProps } from "../../../../../../../shared/components/DashboardWidgets/BaseDashboardWidget";
import { Label } from "../../../../../../../shared/components/DashboardWidgets/VerticalBarChartWidget";
import { CustomTooltip } from "../CustomTooltip";
import { GeofenceBaseChart } from "../GeofenceBaseChart";
import { GeofenceBaseChartWidget } from "../GeofenceBaseChartWidget";

type BarConfig = {
  key: string;
  color?: string;
  size?: number;
  label?: Label;
};

export interface GeofenceVerticalBarChartWidgetProps<T>
  extends BaseDashboardWidgetProps {
  data: T[];
  barColor?: string;
  xAsisKey: string;
  barKey: string;
  label?: Label;
  width?: string | number;
  height?: string | number;
  barsConfig?: BarConfig[];
  ReferenceArea?: ReactElement;
  lineDataKey?: string;
  lineColor?: string;
  dateRange: string;
  yAxisLabel: string;
}

export const GeofenceVerticalBarChartWidget = <T,>(
  props: GeofenceVerticalBarChartWidgetProps<T>
) => {
  const {
    data,
    title,
    dateRange,
    children,
    barColor,
    xAsisKey,
    barKey,
    width = "100%",
    height = 500,
    label,
    barsConfig,
    ReferenceArea,
    lineDataKey,
    lineColor,
    yAxisLabel,
  } = props;

  let Bars = (
    <Bar
      key={barKey}
      dataKey={barKey}
      fill={barColor}
      barSize={200}
      label={label}
      data-testid="recharts-bar-rectangle"
    />
  );

  if (children) {
    Bars = <>{children}</>;
  } else if (barsConfig?.length) {
    Bars = (
      <>
        {barsConfig.map(({ key, color, size, label }) => (
          <Bar
            key={key}
            dataKey={key}
            fill={color}
            barSize={size}
            label={label}
            data-testid="recharts-bar-rectangle"
          />
        ))}
      </>
    );
  }

  return (
    <GeofenceBaseChartWidget title={title} dateRange={dateRange}>
      <GeofenceBaseChart
        xAsisKey={xAsisKey}
        data={data}
        width={width}
        height={height}
        yAxisLabel={yAxisLabel}
        data-testid="recharts-base-chart"
      >
        {ReferenceArea}
        {Bars}
        <Line
          type="linear"
          dataKey={lineDataKey}
          stroke={lineColor}
          dot={false}
          strokeWidth="4px"
          activeDot={{
            r: 6,
            fill: "#5D9BE4",
            stroke: "white",
            strokeWidth: 3,
          }}
          legendType="none"
          hide={false}
          data-testid="recharts-line-segment"
        />
        <Tooltip
          content={<CustomTooltip />}
          data-testid="recharts-tooltip-wrapper"
        />
      </GeofenceBaseChart>
    </GeofenceBaseChartWidget>
  );
};
