import { FC, ReactElement } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import {
  AutomationAction,
  Maybe,
  Scalars,
  useGetAutomationActionsQuery,
} from "../../../graphql/operations";
import { DATE_TIME_FORMAT, formatDateToUTC } from "../../../utils";
import { DataAutomationTask } from "../../../views/AdminPanel/tabs/Automations/AutomationTasks";
import { Button } from "../Button";

export type ActionType = {
  title: string;
  startTime: string;
  endTime: string;
  status: string;
};

export type ExtendedAutomationAction = AutomationAction & {
  endAt?: Maybe<Scalars["AWSDateTime"]>;
};

export type TroubleshootingScreenProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  name: string;
  title: string;
  troubleshootingData?: DataAutomationTask;
  submitButtonText: string;
  children?: ReactElement;
  hideSubmitButton?: boolean;
  isSubmitDisabled?: boolean;
};

export const TroubleshootingScreen: FC<TroubleshootingScreenProps> = ({
  open,
  onClose,
  onSubmit,
  name,
  title,
  troubleshootingData,
  submitButtonText,
  children,
  hideSubmitButton = false,
  isSubmitDisabled = false,
}) => {
  const { data } = useGetAutomationActionsQuery(
    {
      input: { automationTaskId: troubleshootingData?.id ?? "" },
    },
    {
      enabled: !!troubleshootingData?.id,
    }
  );

  const automationActions: ExtendedAutomationAction[] =
    data?.getAutomationActions ?? [];

  return (
    <Dialog
      open={open}
      data-testid={`${name}-action-dialog`}
      onClose={onClose}
      aria-labelledby={`${name}-action-dialog-title`}
      aria-describedby={`${name}-action-dialog-description`}
      sx={{ backgroundColor: "var(--backdrop)" }}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        sx: {
          backgroundColor: "var(--background) !important",
          backgroundImage: "none",
          width: "100%",
          maxWidth: "720px !important",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        className="flex justify-between items-center"
        id={`${name}-action-dialog-title`}
        data-testid={`${name}-action-dialog-title`}
      >
        <span className="text-2xl font-semibold !text-typography-secondary">
          {title}
        </span>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="!pb-0">
        <DialogContentText
          className="!mb-1 mt-7 text-base !text-typography-secondary"
          id={`${name}-action-dialog-description`}
          data-testid={`${name}-action-dialog-description`}
        >
          <strong>Trigger information: </strong>
          {troubleshootingData?.triggerInformation}
        </DialogContentText>
        <DialogContentText
          className="!mb-1 text-base !text-typography-secondary"
          id={`${name}-action-dialog-description`}
          data-testid={`${name}-action-dialog-description`}
        >
          <strong>Device ID: </strong>
          {troubleshootingData?.deviceId}
        </DialogContentText>
        <DialogContentText
          className="!mb-6 text-base !text-typography-secondary"
          id={`${name}-action-dialog-description`}
          data-testid={`${name}-action-dialog-description`}
        >
          <strong>Asset ID: </strong> {troubleshootingData?.assetId}
        </DialogContentText>
        <div
          style={{
            maxHeight: "500px",
            overflowY: "auto",
            paddingRight: "10px",
          }}
        >
          {automationActions.map((action, index) => (
            <div key={index} className="!mb-6" style={{ alignSelf: "stretch" }}>
              <Divider
                sx={{
                  width: "100%",
                  borderColor: "#d3d3d3",
                  marginBottom: "8px",
                }}
              />
              <DialogContentText className="!mt-6 text-lg font-semibold !text-primary">
                <Box
                  component="span"
                  className="text-base"
                  sx={{ color: "var(--Brand-Colors-Primary-Blue, #3C88CC)" }}
                >
                  <strong>{`Action ${index + 1}: `}</strong>
                </Box>
                <Box component="span" className="!text-typography-secondary">
                  <strong>{action.name}</strong>
                </Box>
              </DialogContentText>
              <DialogContentText className="!mb-2 !mt-4 !text-sm">
                <strong>PCT Command Result</strong>
              </DialogContentText>
              <DialogContentText className="!text-typography-secondary">
                <strong>Start Time:</strong>{" "}
                {formatDateToUTC(new Date(action.startedAt))
                  .toFormat(DATE_TIME_FORMAT)
                  .toString()}
              </DialogContentText>
              <DialogContentText className="!text-typography-secondary">
                <strong>End Time:</strong> {action.endAt ?? "-"}
              </DialogContentText>
              <DialogContentText className="!text-typography-secondary">
                <strong>Status:</strong> {action.status}
              </DialogContentText>
            </div>
          ))}
        </div>
        {children}
      </DialogContent>

      <DialogActions className="!p-6">
        {!hideSubmitButton && (
          <Button
            text={submitButtonText}
            size="medium"
            theme="blue"
            variant="default"
            onClick={onSubmit}
            dataTestid={`${name}-action-dialog-btn-form-submit`}
            disabled={isSubmitDisabled}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};
