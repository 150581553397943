import * as yup from "yup";

const setPointSchema = yup
  .number()
  .integer("Value must be an integer")
  .min(0, "Value must be between 0 and 1000")
  .max(1000, "Value must be between 0 and 1000")
  .typeError("required")
  .required("required");

export const liteSentryGammaFormSchema = yup.object().shape({
  brakeCircuitSetPoints: yup.object().shape({
    bluePowerPresentSetPoint: setPointSchema,
    bluePowerNotPresentSetPoint: setPointSchema,
    precheckSetPoint: setPointSchema,
  }),
  leftTurnSignalCircuitSetPoints: yup.object().shape({
    tractorPowerPresentSetPoint: setPointSchema,
    precheckSetPoint: setPointSchema,
  }),
  rightTurnSignalCircuitSetPoints: yup.object().shape({
    tractorPowerPresentSetPoint: setPointSchema,
    precheckSetPoint: setPointSchema,
  }),
  markerCircuitSetPoints: yup.object().shape({
    tractorPowerPresentSetPoint: setPointSchema,
    precheckSetPoint: setPointSchema,
  }),
  licenseCircuitSetPoints: yup.object().shape({
    tractorPowerPresentSetPoint: setPointSchema,
    precheckSetPoint: setPointSchema,
  }),
});
