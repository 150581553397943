import { Box, Grid } from "@mui/material";
import { useGetPsiWheelEndSensorHistoricalDataQuery } from "../../../../../../graphql/operations";
import { useFindAssetById } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import {
  getEndOfToday,
  getStartOfDay,
  getSubDays,
} from "../../../../../../utils/date";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext";
import PsiWheelEndChart from "./charts/sensors/PsiWheelEndChart";

const PsiWheelEndSensorChartsSection: React.FC = () => {
  const { selectedAssetId } = useAssetsDataContext();

  const { data: selectedAsset } = useFindAssetById(
    {
      assetId: selectedAssetId ?? "",
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  const imei = selectedAsset?.imei ?? "";
  const customerOrgId = selectedAsset?.customer_orgs_id ?? "";

  const { isLoading } = useGetPsiWheelEndSensorHistoricalDataQuery(
    {
      input: {
        imei,
        customerOrgId,
        startDate: getStartOfDay(getSubDays(new Date(), 30)),
        endDate: getEndOfToday(),
      },
    },
    {
      enabled: Boolean(imei) && Boolean(customerOrgId),
    }
  );

  return (
    <Box
      className="hidden lg:block mt-4 w-full"
      data-testid="asset-dashboard--tires-tab--psi-wheel-end-charts-section"
    >
      {!isLoading && selectedAsset?.sensors?.psiWheelEnd !== null && (
        <Grid
          container
          justifyContent="center"
          rowSpacing={2}
          data-testid="tires-chart-grid"
        >
          <Grid item lg={12}>
            <PsiWheelEndChart asset={selectedAsset} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PsiWheelEndSensorChartsSection;
