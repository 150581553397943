import { SET_APP_CONFIG } from "../../constants";
import { Action } from "../commonTypes";

export type AppConfigInitialState = {
  map: {
    defaultCenter: {
      lat: number;
      lng: number;
    };
    minZoom: number;
    defaultWidgetMapZoom: number;
  };
  table: {
    defaultRowsPerPage: number;
    usersPerPage: number;
    geofenceAlertsPerPage: number;
    dashboardsPerPage: number;
    geofenceAssetsPerPage: number;
    geofenceDwellHistoryPerPage: number;
    breadcrumbsHistoryEventsPerPage: number;
  };
  reports: {
    maxExceededOrgsCount: number;
  };
  assets: {
    otherSensors: {
      internalCameraSensor: {
        maxImages: number;
      };
    };
  };
  debounceTimeShort: number;
  debounceTime: number;
  debounceTimeLong: number;
  searchMinLength: number;
  searchLimit: number;
  searchOptionsLimit: number;
  monthsBackDateLimit: number;
  brightnessThreshold: number;
  selectedAssetsView: string | null;
};

export type AppConfigReducerType = (
  state: AppConfigInitialState | undefined,
  action: Action
) => AppConfigInitialState;

/**
 * Default state for the AppConfig. The actual state is stored in a JSON file and it's set on application load.
 */
export const initialAppConfigState: AppConfigInitialState = {
  map: {
    defaultCenter: {
      lat: 38.6855,
      lng: -30.8722,
    },
    minZoom: 3, // Google Map
    defaultWidgetMapZoom: 16,
  },
  table: {
    defaultRowsPerPage: 100,
    usersPerPage: 25,
    geofenceAlertsPerPage: 25,
    geofenceAssetsPerPage: 10,
    dashboardsPerPage: 25,
    geofenceDwellHistoryPerPage: 10,
    breadcrumbsHistoryEventsPerPage: 50,
  },
  reports: {
    maxExceededOrgsCount: 200,
  },
  assets: {
    otherSensors: {
      internalCameraSensor: {
        maxImages: 12,
      },
    },
  },
  debounceTimeShort: 300,
  debounceTime: 500,
  debounceTimeLong: 1000,
  searchMinLength: 3,
  searchLimit: 20,
  searchOptionsLimit: 7,
  monthsBackDateLimit: 6,
  brightnessThreshold: 190,
  selectedAssetsView: null,
};

export const AppConfigReducer = (
  state = initialAppConfigState,
  action: Action
) => {
  if (action.type === SET_APP_CONFIG) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};
