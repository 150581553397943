import { FC, ReactElement, useState } from "react";
import { Paper, Button, ThemeProvider, SxProps } from "@mui/material";
import { useSidePanelTheme } from "./useSidePanelTheme";

type ActionListElement = {
  title: string;
  adornment?: string | number;
};

export interface SidePanelProps {
  // Use 'children' prop if full control on the component's body render is needed
  children?: ReactElement;
  actionList: ActionListElement[];
  onActionClick?: (index: number, actionElement: ActionListElement) => void;
  sx?: SxProps;
  defaultActive?: number;
}

const SidePanel: FC<SidePanelProps> = (props) => {
  const { actionList, children, onActionClick, sx, defaultActive = 0 } = props;

  const [active, setActive] = useState<number>(defaultActive);

  const theme = useSidePanelTheme();

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} sx={sx} data-testid="sidepanel-container">
        {children ?? (
          <div className="px-[6px]">
            {actionList?.map((action, index) => (
              <Button
                sx={{
                  backgroundColor:
                    active === index ? "var(--brand-transparent)" : "inherit",
                }}
                data-testid="action"
                key={action.title}
                onClick={() => {
                  setActive(index);

                  if (onActionClick) {
                    onActionClick(index, action);
                  }
                }}
                endIcon={action.adornment}
                fullWidth
              >
                {action.title}
              </Button>
            ))}
          </div>
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default SidePanel;
