import {
  Brand,
  DistanceUnit,
  PressureUnit,
  Maybe,
  TemperatureUnit,
} from "../../../../graphql/operations";
import { AutocompleteOption } from "../../../../types";

export const DEFAULT_BRAND_NAME = "Phillips";

export type DropDownItem = {
  label: Maybe<string>;
  value: Maybe<string>;
};

type Units = DistanceUnit | PressureUnit | TemperatureUnit;

export type UnitDropDownItem<T extends Units> = {
  label: Maybe<T>;
  value: Maybe<T>;
};

export const getBrandForDropdown = (
  brands: Brand[],
  brandId: string
): string => {
  const brand = brands.find((brand) => brand._id === brandId);

  return brand?._id ?? "";
};

export const getDefaultBrandForDropdown = (brands: Brand[]): string => {
  const phillipsBrand = brands.find(
    (brand) => brand.name === DEFAULT_BRAND_NAME
  );

  return phillipsBrand?._id ?? "";
};

export const getBrandsForDropdown = (brands: Brand[]): AutocompleteOption[] => {
  return brands.map((brand) => ({
    id: brand._id,
    label: brand.name,
  }));
};
