import { orderBy } from "lodash";
import { DeviceEventParams, DeviceEvents } from "../../../graphql/operations";
import { DEVICE_HISTORY_SKIP_FIELDS, fieldAndFieldNames } from "./constants";

export const getEventText = (event: DeviceEventParams): string => {
  return `${
    fieldAndFieldNames[event?.param] ?? event?.param ?? ""
  } was updated to ${event?.update ?? ""}`;
};

type TransformedDeviceEvent = DeviceEvents & {
  eventText: string;
};

export const getSortedDeviceEventsForTable = (
  deviceEvents: DeviceEvents[]
): TransformedDeviceEvent[] => {
  const events = deviceEvents.filter(
    (deviceEvent) => !DEVICE_HISTORY_SKIP_FIELDS.has(deviceEvent?.event?.param)
  );
  const transformedEvents = orderBy(
    events.map((deviceEvent) => {
      return { ...deviceEvent, eventText: getEventText(deviceEvent?.event) };
    }),
    "event_date",
    "desc"
  );

  return transformedEvents;
};
