import {
  ASSET_MARKER_COLOR_MAPPING,
  DWELL_TYPE_ARC_LINE_MAP_GM,
} from "../../constants/map";
import { ColorsPalette } from "../../design-system/colors-palette";
import { SensorStatus } from "../../graphql/operations";
import { AssetsCountPerHealthStatus } from "../../views/AssetsView/shared/AssetsDataContext";

// Cluster calculation constants
const ARC_STROKE_WIDTH = 4;
const ARC_CENTER = ARC_STROKE_WIDTH / 2;
const CLUSTER_WHITE_BORDER_OFFSET = 1;
const CLUSTER_OUTER_AND_INNER_BORDER_WIDTH = 2;
const CLUSTER_TEXT_FONT_SIZE = 12;

// Cluster size constants
const CLUSTER_SIZE_SMALL = 50;
const CLUSTER_SIZE_MEDIUM = 70;
const CLUSTER_SIZE_LARGE = 90;

// Cluster size ranges
const MEDIUM_CLUSTER_SIZE_RANGE = [500, 1000];
const LARGE_CLUSTER_SIZE_RANGE = 1000;

const generateColor = (colorValues: string, opacity: number) => {
  return `rgba(${colorValues}, ${opacity})`;
};

const dwellArcs = (
  context: CanvasRenderingContext2D,
  dwellType: string,
  size: number,
  type: string
) => {
  const dwellTypeValue = DWELL_TYPE_ARC_LINE_MAP_GM.get(dwellType);
  if (dwellTypeValue) {
    const { arcPos, lineDashed } = dwellTypeValue;
    const opacity = 1;
    context.moveTo(size / 2, size / 2);
    context.beginPath();
    context.arc(size / 2, size / 2, 17, arcPos, 360);
    context.lineWidth = 3;
    context.setLineDash(lineDashed);
    const colorValues = ASSET_MARKER_COLOR_MAPPING.get(type);
    if (colorValues) {
      context.strokeStyle = generateColor(colorValues, opacity);
    }
    context.stroke();
    context.closePath();
  }
};

const mainCircle = (
  context: CanvasRenderingContext2D,
  opacity: number,
  radius: number,
  t: number,
  size: number,
  type: string,
  animate: boolean
) => {
  context.moveTo(size / 2, size / 2);
  context.beginPath();
  context.arc(size / 2, size / 2, radius, 0, Math.PI * 2);
  const colorValues = ASSET_MARKER_COLOR_MAPPING.get(type);
  if (colorValues) {
    context.fillStyle = generateColor(colorValues, opacity);
  }
  if (animate) {
    context.strokeStyle = "white";
    context.lineWidth = 2 + 4 * (1 - t);
    context.stroke();
  }
  context.fill();
  context.closePath();
};

const innerCircle = (
  context: CanvasRenderingContext2D,
  innerRadius: number,
  size: number,
  type: string
) => {
  const isLoaded = /loaded/.test(type);
  const isEmpty = /empty/.test(type);
  const isSignaling = /(?=\w+-(?!not))^(\W|\w)+$/.test(type);
  const isNotSignaling = /(?=\w+-(?=not-signaling))^(\W|\w)+$/.test(type);
  let color = "";
  if (isSignaling) {
    if (isLoaded) {
      color = "#0E49A3";
    } else if (isEmpty) {
      color = "#FFFFFF";
    }
  } else if (isNotSignaling) {
    if (isLoaded) {
      color = "#000000";
    } else if (isEmpty) {
      color = "#FFFFFF";
    }
  }
  context.moveTo(size / 2, size / 2);
  context.beginPath();
  context.arc(size / 2, size / 2, innerRadius, 0, Math.PI * 2);
  context.fillStyle = color;
  context.fill();
  context.closePath();
};

export const getGMFeatureIcon = (
  size: number,
  type: string,
  isBeacon: boolean
) => {
  const canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d");
  const duration = 1000;
  const isEmptyOrLoaded = /^(empty|loaded)/.test(type);
  const isDwelling = /-dwell-/.test(type);
  const t = (performance.now() % duration) / duration;
  let opacity = 0.2;
  const radius = 10;
  const innerRadius = 4;
  if (context) {
    // Draw the outer arc only when dwelling/parked.
    if (isDwelling) {
      const filteredResult = /-dwell-(?:(\w+))/.exec(type);
      if (filteredResult) {
        const dwellType = filteredResult[1];
        dwellArcs(context, dwellType, size, type);
      }
    }
    // Draw the inner circle.
    opacity = 1;
    mainCircle(context, opacity, radius, t, size, type, isBeacon);
    // Draw additional white inner circle for empty load and dwelling asset
    if (isEmptyOrLoaded) {
      innerCircle(context, innerRadius, size, type);
    }

    const imageData = context.canvas.toDataURL();

    return {
      type,
      imageData,
      isBeacon,
    };
  }
  return {
    type,
    imageData: "",
    isBeacon,
  };
};

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
}

const AssetHealthStatusColorMapping = {
  [SensorStatus.Healthy]: ColorsPalette.Success,
  [SensorStatus.Warning]: ColorsPalette.Caution,
  [SensorStatus.Alert]: ColorsPalette.Alert,
  [SensorStatus.Critical]: ColorsPalette.Error,
  [SensorStatus.Unknown]: ColorsPalette.SolidGray,
};

export const getGMClusterSVG = (
  countAbbreviated: string,
  clusterAssetsCount: number,
  assetStatusGroup: AssetsCountPerHealthStatus
) => {
  // convert counts to percentages
  const percentages = Object.keys(assetStatusGroup).reduce((acc, status) => {
    return {
      ...acc,
      [status]:
        ((assetStatusGroup[status as keyof AssetsCountPerHealthStatus] ?? 0) /
          clusterAssetsCount) *
        100,
    };
  }, {} as Record<keyof AssetsCountPerHealthStatus, number>);

  let currentAngle = 0;

  let circleSize = CLUSTER_SIZE_SMALL;

  if (
    clusterAssetsCount > MEDIUM_CLUSTER_SIZE_RANGE[0] &&
    clusterAssetsCount <= MEDIUM_CLUSTER_SIZE_RANGE[1]
  ) {
    circleSize = CLUSTER_SIZE_MEDIUM;
  } else if (clusterAssetsCount > LARGE_CLUSTER_SIZE_RANGE) {
    circleSize = CLUSTER_SIZE_LARGE;
  }
  const circleRadius = circleSize / 2;
  const clusterOuterBorderRadius = circleRadius - CLUSTER_WHITE_BORDER_OFFSET;
  const clusterInnerBorderRadius =
    circleRadius - ARC_STROKE_WIDTH - CLUSTER_WHITE_BORDER_OFFSET;

  const percentageArcRadius =
    circleRadius - ARC_CENTER - CLUSTER_WHITE_BORDER_OFFSET;
  return `<svg xmlns="http://www.w3.org/2000/svg" width="${circleSize}" height="${circleSize}">
  <style>
    
  </style>
    <circle cx="${circleRadius}" cy="${circleRadius}" r="${circleRadius}" fill="#000000" opacity="0.5" />

    <text
      x="${circleRadius}"
      y="${circleRadius}"
      text-anchor="middle"
      font-family="Roboto, sans-serif"
      
      fill="white"
      font-size="${CLUSTER_TEXT_FONT_SIZE}"
      dy=".35em"
    >
      ${countAbbreviated}
    </text>
    <!-- Render the outer border of the circle -->
    <path id="arcOuterBorder" stroke="white" stroke-width="${CLUSTER_OUTER_AND_INNER_BORDER_WIDTH}" fill="none" d="${describeArc(
    circleRadius,
    circleRadius,
    clusterOuterBorderRadius,
    0,
    359
  )}"></path>
    <!-- Render the inner border of the circle -->
    <path id="arcInnerBorder" stroke="white" stroke-width="${CLUSTER_OUTER_AND_INNER_BORDER_WIDTH}" fill="none" d="${describeArc(
    circleRadius,
    circleRadius,
    clusterInnerBorderRadius,
    0,
    359
  )}"></path>

  <!-- Render the arcs based on the percentage values -->
  ${Object.keys(percentages).map((status) => {
    const percentage = percentages[status as keyof AssetsCountPerHealthStatus];
    const color = AssetHealthStatusColorMapping[status as SensorStatus];
    let arcEndAngle = currentAngle + (percentage / 100) * 360;
    if (arcEndAngle === 360) {
      arcEndAngle = 359.99;
    }
    const arcPath = describeArc(
      circleRadius,
      circleRadius,
      percentageArcRadius,
      currentAngle,
      arcEndAngle
    );
    currentAngle = arcEndAngle;
    return `<path id="arc${status}" stroke="${color}"  stroke-width="${ARC_STROKE_WIDTH}" fill="none" d="${arcPath}"></path>`;
  })}
  </svg>`;
};
