import { isEmpty } from "lodash";
import { DevicesTableData } from "../../../graphql/operations";
import {
  TransferDevice,
  transferDeviceReadableNames,
  TransferAsset,
  transferAssetReadableNames,
} from "./types";

const jsonContentType = "application/json";

export type AssetToTransfer = {
  _id: string;
  asset_id: string;
  imei: string;
  vin: string;
};

export enum DialogType {
  Transfer = "transfer",
  Share = "share",
  Unshare = "unshare",
}

export enum TypeTransfer {
  Assets = "Assets",
  Devices = "Devices",
}

const getBlob = (jsonContent: string) =>
  new Blob([jsonContent], {
    type: jsonContentType,
  });

const getJsonFile = (blobContent: Blob, fileName: string) =>
  new File([blobContent], fileName, {
    type: jsonContentType,
  });

export const parseContentAsJsonBlobFile = (
  content: unknown,
  fileName: string
): File => {
  const jsonContent = JSON.stringify(content);

  const blobContent = getBlob(jsonContent);

  const jsonFile = getJsonFile(blobContent, fileName);

  return jsonFile;
};

export const getMappedTransferDevices = (
  devices: DevicesTableData[],
  org = ""
): TransferDevice[] => {
  return devices.map((d) => ({
    asset_id: d.assetIdentifier ?? "",
    imei: d.imei ?? "",
  }));
};

export const getMappedTransferDevicesMissingFields = (
  selectedDevices: TransferDevice[]
): Set<string> => {
  if (selectedDevices.length === 0) {
    const empty = {
      asset_id: "",
      imei: "",
    };

    return new Set<string>(
      Object.keys(empty).map(
        (key) => transferDeviceReadableNames[key as keyof TransferDevice]
      )
    );
  }

  const requiredFields: string[] = ["imei"];
  return selectedDevices.reduce((missingFieldsSet, deviceObj) => {
    Object.entries(deviceObj).forEach(([deviceKey, deviceValue]) => {
      if (requiredFields.includes(deviceKey) && isEmpty(deviceValue)) {
        missingFieldsSet.add(
          transferDeviceReadableNames[deviceKey as keyof TransferDevice]
        );
      }
    });

    return missingFieldsSet;
  }, new Set<string>());
};

export const getMappedTransferAssets = (
  assets: AssetToTransfer[],
  org = ""
): TransferAsset[] => {
  return assets.map((a) => ({
    asset_id: a.asset_id ?? "",
    imei: a.imei ?? "",
    vin: a.vin ?? "",
  }));
};
export const getMappedTransferAssetsMissingFields = (
  selectedAssets: TransferAsset[]
): Set<string> => {
  if (selectedAssets.length === 0) {
    const empty = {
      asset_id: "",
      imei: "",
      vin: "",
    };

    return new Set<string>(
      Object.keys(empty).map(
        (key) => transferAssetReadableNames[key as keyof TransferAsset]
      )
    );
  }

  const requiredFields: string[] = ["asset_id"];
  return selectedAssets.reduce((missingFieldsSet, assetsObj) => {
    Object.entries(assetsObj).forEach(([assetKey, assetValue]) => {
      if (requiredFields.includes(assetKey) && isEmpty(assetValue)) {
        missingFieldsSet.add(
          transferAssetReadableNames[assetKey as keyof TransferAsset]
        );
      }
    });

    return missingFieldsSet;
  }, new Set<string>());
};
