import { FC, ReactNode } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Button, TextButton } from "../Button";

export type ConfirmationDialogProps = {
  title: string;
  message: string;
  open: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  handleConfirmationResult: (isConfirmed: boolean) => void;
  additionalContent?: ReactNode;
  isLoading?: boolean;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  handleConfirmationResult,
  title,
  message,
  open,
  confirmButtonText,
  cancelButtonText,
  additionalContent,
  isLoading,
}) => {
  return (
    <Dialog
      open={open}
      data-testid="confirmation-dialog"
      onClose={() => handleConfirmationResult(false)}
      sx={{ backgroundColor: "var(--backdrop)" }}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        sx: {
          minWidth: { xs: "auto", sm: "540px" },
          maxWidth: "720px !important",
          borderRadius: "8px",
          color: "#1E1E1E",
        },
      }}
    >
      <DialogTitle
        className="flex justify-between !p-6 !px-8"
        id="confirmation-dialog-title"
        data-testid="confirmation-dialog-title"
      >
        <span className="text-2xl font-semibold !text-typography-secondary">
          {title}
        </span>
        <IconButton
          style={{
            height: "fit-content",
          }}
          aria-label="close"
          data-testid="confirmation-dialog-close-icon"
          onClick={() => handleConfirmationResult(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="!pb-0 !px-8">
        <DialogContentText
          className="text-lg"
          id="confirmation-dialog-description"
          data-testid="confirmation-dialog-description"
        >
          {message}
        </DialogContentText>
      </DialogContent>
      {additionalContent && (
        <div className="!pb-0 !p-5">{additionalContent}</div>
      )}
      <DialogActions className="!p-6 !px-8">
        {confirmButtonText === undefined ? (
          <Button
            text={cancelButtonText}
            size="medium"
            theme="blue"
            variant="default"
            onClick={() => handleConfirmationResult(false)}
            dataTestid="confirmation-dialog-btn-cancel"
          />
        ) : (
          <>
            <TextButton
              text={cancelButtonText}
              size="medium"
              onClick={() => handleConfirmationResult(false)}
              theme="blue"
              data-testid="confirmation-dialog-btn-cancel"
              iconPosition="none"
              icon={undefined}
              className={undefined}
              disabled={isLoading}
            />
            <Button
              text={confirmButtonText}
              icon={
                isLoading && (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )
              }
              disabled={isLoading}
              iconPosition={isLoading ? "right" : "none"}
              size="medium"
              theme="blue"
              variant="default"
              onClick={() => handleConfirmationResult(true)}
              dataTestid="confirmation-dialog-btn-confirm"
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
