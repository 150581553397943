import { FC } from "react";
import { Box } from "@mui/material";
import { ReferenceArea, Bar } from "recharts";
import { ColorsPalette } from "../../../../../../../../../design-system/colors-palette";
import { AggregatedEventHistory } from "../../../../../../../../../graphql/operations";
import BaseVerticalBarChart from "../../../../../../../../../shared/components/BaseVerticalBarChart";
import { IReferenceAreaValueType } from "../../../../interfaces";

export interface AtisChartBodyProps {
  data: AggregatedEventHistory[];
  referenceAreasValues?: IReferenceAreaValueType[];
  barColor: string;
  yAxisLabel?: string;
  barKey: string;
  xAsisKey: string;
}

export enum AtisState {
  Critical = "Critical",
  Warning = "Warning",
  Alert = "Alert",
  Healthy = "Healthy",
}

export const STATE_TO_COLOR = {
  [AtisState.Critical]: ColorsPalette.ErrorOpacity20,
  [AtisState.Warning]: ColorsPalette.CautionOpacity10,
  [AtisState.Alert]: ColorsPalette.AlertOpacity20,
  [AtisState.Healthy]: ColorsPalette.SuccessOpacity20,
};

const AtisChartBody: FC<AtisChartBodyProps> = ({
  barColor,
  barKey,
  xAsisKey,
  referenceAreasValues,
  data,
  yAxisLabel,
}) => {
  const getReferenceAreas =
    referenceAreasValues &&
    referenceAreasValues?.map((value: any) => (
      <ReferenceArea
        data-testid="atis-chart-body-reference-area"
        y1={value.min}
        y2={value.max}
        fill={STATE_TO_COLOR[value.state as AtisState]}
        fillOpacity={0.8}
        ifOverflow="extendDomain"
        key={`${value.max}-${value.state.toLowerCase()}`}
      />
    ));

  return (
    <Box
      className="w-full"
      style={{ height: 330 }}
      data-testid="atis-chart-body-container"
    >
      <BaseVerticalBarChart
        data={data}
        xAsisKey={xAsisKey}
        width="100%"
        height="100%"
        yAxisLabel={yAxisLabel}
      >
        {getReferenceAreas}

        <Bar dataKey={barKey} fill={barColor} barSize={200} />
      </BaseVerticalBarChart>
    </Box>
  );
};

export default AtisChartBody;
