import { useState, useCallback, useEffect } from "react";
import debounce from "lodash/debounce";
import { useAppContext } from "../../../context/AppContext";
import { TableSearch } from "./TableSearch";

export type TableServerSideSearchProps = {
  onSearch: (searchText: string) => void;
  className?: string;
  initialSearch?: string;
  searchTextMinLength?: number;
  debounceTime?: number;
};

const validateTextMinLength = (text: string, minLength: number) =>
  text ? text.length >= minLength : true;

export const TableServerSideSearch = ({
  className,
  onSearch,
  debounceTime,
  initialSearch = "",
  searchTextMinLength,
}: TableServerSideSearchProps) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const searchMinLength = searchTextMinLength ?? appConfig.searchMinLength;

  const [searchText, setSearchText] = useState(initialSearch);
  const [showMinTextLengthWarning, setShowMinTextLengthWarning] = useState(
    !validateTextMinLength(initialSearch, searchMinLength)
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkTextLengthWarning = useCallback(
    debounce((text: string) => {
      setShowMinTextLengthWarning(
        !validateTextMinLength(text, searchMinLength)
      );
    }, appConfig.debounceTimeLong),
    [searchMinLength]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkTextLengthWarning(searchText), [searchText]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchCallback = useCallback(
    debounce((text: string) => {
      if (!text || validateTextMinLength(text, searchMinLength)) {
        onSearch(text);
      }
    }, debounceTime ?? appConfig.debounceTime),
    []
  );

  const onChangeHandler = (text: string) => {
    setSearchText(text);
    onSearchCallback(text);
  };

  useEffect(() => {
    if (initialSearch) {
      onChangeHandler(initialSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSearch]);

  return (
    <TableSearch
      className={className}
      showMinTextLengthWarning={showMinTextLengthWarning}
      searchText={searchText}
      onChange={onChangeHandler}
    />
  );
};
