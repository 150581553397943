import { Box, Skeleton } from "@mui/material";

export const HighlightsOverlay = () => {
  const rows = [1, 2, 3];
  return (
    <Box
      data-testid="loading-overlay-container"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Skeleton
        variant="rectangular"
        data-testid="heading-overlay"
        sx={{ marginBottom: "22px", height: "72px", width: "25%" }}
      ></Skeleton>

      <Box display="flex" gap="16px">
        {rows.map((row) => (
          // 3 Box elements per row
          <Box width="100%" key={`row-${row}`}>
            {rows.map((boxElement) => (
              <Box key={`box-element-${boxElement}`} marginBottom="16px">
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "32px", width: "50%", marginBottom: "4px" }}
                ></Skeleton>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "64px", width: "40%" }}
                ></Skeleton>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
