import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DATE_TIME_FORMAT_SHORT, formatDate } from "../../../../../../utils";
import { LightCircuitMergedDataResult } from "../utils/utils";

type LightsTableProps = {
  data: LightCircuitMergedDataResult;
};

const DEVIATION_UNIT = "%";
// Note: This is a potential candidate for a shared component
const LightsTable: React.FC<LightsTableProps> = ({ data }) => {
  return (
    <Box
      className="bg-card__bg rounded-lg"
      sx={{
        padding: "16px",
      }}
      data-testid="asset-dashboard--lights-tab--lights-circuit-widget--table-view"
    >
      <TableContainer
        component={Paper}
        className="border"
        sx={{
          boxShadow: "none",
          borderRadius: "8px",
          borderColor: "var(--asset-table-border)",
        }}
      >
        <Table stickyHeader aria-label="Light Circuits Table">
          <TableHead data-testid="asset-dashboard--lights-tab--lights-circuit-widget-table-summary-section--header">
            <TableRow sx={{ fontSize: "14px" }}>
              <TableCell sx={{ fontWeight: "700" }}>Light Circuit</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>
                Current Deviation
              </TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Source</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="asset-dashboard--lights-tab--lights-circuit-widget-table-summary-section--body">
            {Object.entries(data).map(([circuitName, circuitData]) => (
              <TableRow
                key={circuitName}
                sx={{
                  padding: "0px",
                  fontSize: "14px",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                data-testid="asset-dashboard--lights-tab--lights-circuit-widget-table-summary-section--row"
              >
                <TableCell component="th" scope="row">
                  {circuitName}
                </TableCell>
                <TableCell>
                  {circuitData?.calculated_deviation_percentage}
                  {DEVIATION_UNIT}
                </TableCell>
                <TableCell>{circuitData.scenario_source}</TableCell>
                <TableCell sx={{ textTransform: "capitalize" }}>
                  {circuitData.statusDeviation}
                </TableCell>
                <TableCell>
                  {formatDate(
                    circuitData?.measurement_datetime as string,
                    DATE_TIME_FORMAT_SHORT
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LightsTable;
