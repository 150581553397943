import { useEffect } from "react";
import { PAGE_SPINNER } from "../../constants";
import { useAppContext } from "../../context/AppContext";

/**
 * This hook handle a spinner behaviour.
 * @param isDataLoading
 */
export const useSpinner = (isDataLoading: boolean) => {
  const { dispatch } = useAppContext();
  useEffect(() => {
    if (isDataLoading) {
      dispatch({
        type: PAGE_SPINNER,
        payload: { increment: 1 },
      });
    }

    return () =>
      dispatch({
        type: PAGE_SPINNER,
        payload: { increment: 0 },
      });
  }, [isDataLoading, dispatch]);
};
