import CircleIcon from "@mui/icons-material/Circle";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { formatNumber } from "../../../../../utils/formatters";
import { AssetsCountPerHealthStatus } from "../../../shared/AssetsDataContext";

export interface ClusterTooltipProps {
  totalCount: number;
  assetsCountPerHealthStatus: AssetsCountPerHealthStatus | undefined;
}

export const ClusterTooltip: React.FC<ClusterTooltipProps> = ({
  totalCount,
  assetsCountPerHealthStatus,
}) => {
  const title = `${formatNumber(totalCount)} ${
    totalCount === 1 ? "Asset" : "Assets"
  }`;
  const healthCount = assetsCountPerHealthStatus?.healthy ?? 0;
  const warningCount = assetsCountPerHealthStatus?.warning ?? 0;
  const alertCount = assetsCountPerHealthStatus?.alert ?? 0;
  const criticalCount = assetsCountPerHealthStatus?.critical ?? 0;
  const unknownCount = assetsCountPerHealthStatus?.unknown ?? 0;

  const generateMessageSuffix = (count: number) => {
    const assetLabel = count === 1 ? "asset" : "assets";
    return `${formatNumber(count)} ${assetLabel}`;
  };

  const healthStatuses: { message: string; iconColor: string }[] = [
    {
      message: `Healthy - ${generateMessageSuffix(healthCount)}`,
      iconColor: "var(--success)",
    },
    {
      message: `Warning - ${generateMessageSuffix(warningCount)}`,
      iconColor: "var(--caution)",
    },
    {
      message: `Alert - ${generateMessageSuffix(alertCount)}`,
      iconColor: "var(--warning)",
    },
    {
      message: `Critical - ${generateMessageSuffix(criticalCount)}`,
      iconColor: "var(--error)",
    },
    {
      message: `Unknown -  ${generateMessageSuffix(unknownCount)}`,
      iconColor: "var(--dark-grey)",
    },
  ];

  return (
    <Paper
      data-testid="cluster-tooltip"
      elevation={3}
      sx={{
        padding: "16px 10px",
        width: "16rem",
        borderRadius: "0.5rem",
        background: "none",
        backgroundColor: " var(--custom-tooltip-background)",
      }}
    >
      <Typography sx={{ fontWeight: "500", paddingLeft: "0.5rem" }}>
        {title}
      </Typography>
      <Divider sx={{ marginY: "0.5rem" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          paddingLeft: "0.5rem",
        }}
      >
        {healthStatuses.map(({ message, iconColor }) => (
          <Box
            key={message}
            sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <CircleIcon
              data-testid="cluster-tooltip-status-icon"
              sx={{ width: "10px", height: "10px", color: iconColor }}
            />
            <Typography sx={{ fontSize: "14px" }}>{message}</Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};
