import React, { FC } from "react";
import { Button as CancelButton, Grid, CircularProgress } from "@mui/material";
import { Button } from "../../../../../shared/components/Button";

interface SaveCancelButtonsProps {
  handleCancelClick: () => void;
  handleSubmitClick: () => void;
  isLoading: boolean;
  areButtonsDisabled: boolean;
  isUserAllowed: boolean;
}

const SaveCancelButtons: FC<SaveCancelButtonsProps> = ({
  handleCancelClick,
  handleSubmitClick,
  isLoading,
  areButtonsDisabled,
  isUserAllowed,
}) => {
  return (
    <Grid
      container
      spacing={8}
      className="items-baseline justify-center md:justify-end"
    >
      <Grid item>
        <CancelButton
          data-testid="settings-cancel"
          className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
          onClick={handleCancelClick}
          disabled={areButtonsDisabled}
        >
          Reset
        </CancelButton>
      </Grid>
      <Grid item>
        <Button
          dataTestid="settings-submit"
          className="w-full"
          text={isLoading ? "Saving" : "Save"}
          size="medium"
          theme="blue"
          variant="default"
          type="button"
          onClick={handleSubmitClick}
          iconPosition="right"
          icon={
            isLoading && (
              <CircularProgress size={15} style={{ color: "white" }} />
            )
          }
          disabled={areButtonsDisabled}
        />
      </Grid>
    </Grid>
  );
};

export default SaveCancelButtons;
