import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { GEOFENCE_SETTINGS_TAB_TYPES } from "../../../../../constants/geofences";
import DashboardFrame from "../../../../../shared/components/DashboardFrame";
import { UserAccessScope } from "../../../../../shared/components/WithPermissions";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import { Settings } from "../../Shared/Settings";
import GeofenceAlertsList from "../GeofenceAlerts/GeofenceAlertsList";
import { GeofenceAssetsList } from "../GeofenceAssetsList/GeofenceAssetsList";
import { GeofenceDwellHistory } from "../GeofenceDwellHistory";
import { GeofenceHistoryTab } from "../GeofenceHistoryTab";
import GeofenceSummaryTabPanel from "../GeofenceSummaryTabPanel/GeofenceSummaryTabPanel";

export type GeofenceDashboardProps = {
  onClose: (restoreFilters?: boolean) => void;
  addSubGeofence: () => void;
  isAssetDataMapRefetching: boolean;
};

const GeofenceDashboard: FC<GeofenceDashboardProps> = ({
  onClose,
  addSubGeofence,
  isAssetDataMapRefetching,
}: GeofenceDashboardProps) => {
  const { selectedGeofence, setIsFiltersDrawerOpen, isFiltersDrawerOpen } =
    useAssetsDataContext();
  const [selectedGeofenceDetailTab, setSelectedGeofenceDetailTab] = useState(0);

  useEffect(() => {
    isFiltersDrawerOpen && setIsFiltersDrawerOpen(false);
  }, [setIsFiltersDrawerOpen, isFiltersDrawerOpen]);

  const geofenceMenuOptions = [
    {
      title: "Add sub-geofence",
      onClick: addSubGeofence,
      permission: "geofences.view" as UserAccessScope,
    },
  ];

  return (
    <Box
      className="absolute inset-0 z-[100]"
      data-testid="dashboardFrame-wrap-geofence"
    >
      <DashboardFrame
        title={selectedGeofence?.geofence.name ?? ""}
        geofenceId={selectedGeofence?._id}
        activeTab={selectedGeofenceDetailTab}
        geofenceMenuOptions={geofenceMenuOptions}
        tabs={[
          { value: 0, label: "Summary" },
          { value: 1, label: "Dwell" },
          { value: 2, label: "Assets" },
          { value: 3, label: "Alerts" },
          { value: 4, label: "History" },
          { value: 5, label: "Settings" },
        ]}
        onTabChange={setSelectedGeofenceDetailTab}
        onBack={onClose}
      >
        <GeofenceSummaryTabPanel key={0} />
        <GeofenceDwellHistory
          key={1}
          geofenceId={selectedGeofence?._id ?? ""}
        />
        <GeofenceAssetsList key={2} geofenceId={selectedGeofence?._id ?? ""} />
        <GeofenceAlertsList key={3} geofenceId={selectedGeofence?._id ?? ""} />
        <GeofenceHistoryTab key={4} geofenceId={selectedGeofence?._id ?? ""} />
        <Settings
          key={5}
          geofence={selectedGeofence ?? undefined}
          onBack={onClose}
          settingsTabTypes={GEOFENCE_SETTINGS_TAB_TYPES}
          isAssetRefetching={isAssetDataMapRefetching}
        />
      </DashboardFrame>
    </Box>
  );
};

export default GeofenceDashboard;
