import { DISABLE_ASSET_TABLE_ON_CLICK } from "../../constants";
import { Action } from "../commonTypes";

export type AssetsReducerInitialState = {
  isOnClickDisabled: boolean;
};

export type AssetsReducerType = (
  state: AssetsReducerInitialState | undefined,
  action: Action
) => AssetsReducerInitialState;

export const initialAssetsState: AssetsReducerInitialState = {
  isOnClickDisabled: false,
};

export const AssetsReducer = (state = initialAssetsState, action: Action) => {
  switch (action.type) {
    case DISABLE_ASSET_TABLE_ON_CLICK:
      return {
        ...state,
        isOnClickDisabled: action.payload.isOnClickDisabled,
      };
    default:
      return state;
  }
};
