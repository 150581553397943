import { memo, SVGProps, FC } from "react";

interface LandingGearProps extends SVGProps<SVGSVGElement> {}

const LandingGear: FC<LandingGearProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 43 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0843 48.3164H33.7113C36.9245 48.3164 39.5372 45.4982 39.5372 42.0346V9.70721C39.5372 6.24237 36.9245 3.42414 33.7113 3.42414H16.0843C12.8724 3.42414 10.2598 6.24237 10.2598 9.70721V42.0346C10.2598 45.4982 12.8724 48.3164 16.0843 48.3164Z"
        fill="#434D5A"
      />
      <path
        d="M10.2595 9.70607C10.2595 6.24122 12.8722 3.42424 16.0841 3.42424H33.7111C36.923 3.42424 39.5369 6.24122 39.5369 9.70607V42.0334C39.5369 45.4983 36.923 48.3153 33.7111 48.3153H16.0841C12.8722 48.3153 10.2595 45.4983 10.2595 42.0334V9.70607ZM16.0841 51.7402H33.7111C38.681 51.7402 42.7102 47.3945 42.7102 42.0334V9.70607C42.7102 4.34371 38.681 -0.000743866 33.7111 -0.000743866H16.0841C11.1142 -0.000743866 7.08496 4.34371 7.08496 9.70607V42.0334C7.08496 47.3945 11.1142 51.7402 16.0841 51.7402Z"
        fill="white"
      />
      <path
        d="M20.9335 91.2052L17.9209 34.7199H32.2213L28.8462 91.7285L20.9335 91.2052Z"
        fill="#9BDEED"
      />
      <path
        d="M30.1426 11.5799H19.6509C17.3734 11.5799 15.5107 13.4426 15.5107 15.7201V31.7349C15.5107 34.0111 17.3734 35.875 19.6509 35.875H30.1426C32.4201 35.875 34.2827 34.0111 34.2827 31.7349V15.7201C34.2827 13.4426 32.4201 11.5799 30.1426 11.5799Z"
        fill="#5F7C8F"
      />
      <path
        d="M27.4501 87.404H22.4316C20.5267 87.404 18.9668 88.9626 18.9668 90.8688V96.5364C18.9668 98.4414 20.5267 100 22.4316 100H27.4501C29.3551 100 30.915 98.4414 30.915 96.5364V90.8688C30.915 88.9626 29.3551 87.404 27.4501 87.404Z"
        fill="#5F7C8F"
      />
      <path
        d="M10.5838 76.4113L10.5825 58.1588C10.5825 56.7123 9.41514 55.5462 7.96865 55.5462C6.52714 55.5462 5.35724 56.7148 5.35724 58.1588L5.35849 76.4125H0.199219L8.01475 89.9492L15.8315 76.4113H10.5838Z"
        fill="white"
      />
      <path
        d="M27.742 64.1069C27.742 62.6068 26.5247 61.3896 25.0247 61.3896C23.5246 61.3896 22.3086 62.6068 22.3086 64.1069C22.3086 65.607 23.5246 66.8242 25.0247 66.8242C26.5247 66.8242 27.742 65.607 27.742 64.1069Z"
        fill="#414A56"
      />
      <path
        d="M27.3726 72.3988C27.3726 71.1018 26.321 70.0515 25.0253 70.0515C23.7283 70.0515 22.6768 71.1018 22.6768 72.3988C22.6768 73.6958 23.7283 74.7461 25.0253 74.7461C26.321 74.7461 27.3726 73.6958 27.3726 72.3988Z"
        fill="#414A56"
      />
      <path
        d="M28.4834 54.4789C28.4834 52.5689 26.9347 51.0215 25.026 51.0215C23.116 51.0215 21.5674 52.5689 21.5674 54.4789C21.5674 56.3888 23.116 57.9375 25.026 57.9375C26.9347 57.9375 28.4834 56.3888 28.4834 54.4789Z"
        fill="#414A56"
      />
      <path
        d="M29.3937 43.7549C29.3937 41.3416 27.4377 39.3868 25.0256 39.3868C22.6123 39.3868 20.6562 41.3416 20.6562 43.7549C20.6562 46.1682 22.6123 48.123 25.0256 48.123C27.4377 48.123 29.3937 46.1682 29.3937 43.7549Z"
        fill="#414A56"
      />
    </svg>
  );
};

export default memo(LandingGear);
