import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import useBreakpoint from "../../../../../../../../shared/hooks/useBreakpoint";
import { useInternalCameraSensorPermissions } from "../../hooks/useInternalCameraSensorPermissions";
import ActionButton from "./ActionButton";

interface DeleteButtonProps {}

const DeleteButton: React.FC<DeleteButtonProps> = () => {
  const isMobile = useBreakpoint("down", "sm");
  const { isDeleteButtonEnabled } = useInternalCameraSensorPermissions();

  // TODO: Not implemented yet, until we know how to delete a photo
  const handleOnClick = () => {};

  return (
    <ActionButton
      onClick={handleOnClick}
      dataTestId="asset-dashboard--other-sensors--internal-camera-sensor--delete-button"
      disabled={!isDeleteButtonEnabled || true}
      sx={{
        width: isMobile ? "48px" : "72px",
        minWidth: isMobile ? "48px" : "72px",
        padding: isMobile ? "12px 8px" : "12px 24px",
        color: "var(--white)",
        backgroundColor: "var(--error)",
        borderColor: "var(--error)",
        "&:hover": {
          backgroundColor: "var(--error)",
        },
        "&:disabled": {
          backgroundColor: "var(--error-transparent)",
          borderColor: "var(--error-transparent)",
          color: "var(--error)",
          "svg path": {
            fill: "var(--error-transparent)",
          },
        },
      }}
    >
      <DeleteOutlinedIcon />
    </ActionButton>
  );
};

export default DeleteButton;
