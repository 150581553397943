import { ReactElement, useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceArea,
} from "recharts";
import { ColorsPalette } from "../../../../../../../../../../design-system/colors-palette";
import { PressureUnit } from "../../../../../../../../../../graphql/operations";
import { getConvertedPressureValue } from "../../../../../../../../../../utils/convertPressure";
import { chartTickStyle } from "../../../../../../SummaryTabPanel/Charts/chartUtils";
import { useTiresTabContext } from "../../../../../context";
import { determineDualImbalanceStrokeDash } from "../../../../../helpers";
import {
  STATE_TO_COLOR,
  SensorState,
  IMappedDualImbalanceChartData,
  ImbalanceVariantsByAxle,
  IReferenceAreaValueType,
} from "../../../../../interfaces";
import { DualImbalanceChartTooltip } from "./DualImbalancelChartTooltip";

type DualImbalanceChartBodyProps = {
  data: IMappedDualImbalanceChartData[];
  referenceAreaValues: Array<any>;
  pressureUnit: PressureUnit;
};

const DualImbalanceChartBody: React.FC<DualImbalanceChartBodyProps> = ({
  data,
  referenceAreaValues,
  pressureUnit,
}) => {
  // Compute reference area elements used to display the sensor profile thresholds behind the lines
  const getReferenceAreasForDualImbalance = () => {
    // Compute reference area elements. For pressure we have repeated areas.
    const referenceAreas: ReactElement[] = [];
    referenceAreaValues.forEach((value: IReferenceAreaValueType) => {
      referenceAreas.push(
        <ReferenceArea
          yAxisId="Imbalance"
          y1={getConvertedPressureValue(value.min, pressureUnit)}
          y2={
            value.state === SensorState.Critical
              ? undefined
              : getConvertedPressureValue(value.max, pressureUnit)
          }
          fill={STATE_TO_COLOR[value.state as SensorState]}
          fillOpacity={0.8}
          ifOverflow="extendDomain"
          data-testid="dual-imbalance-body-reference-area"
        />
      );
    });
    return referenceAreas;
  };

  const { selectedDualImbalanceRecord } = useTiresTabContext();

  // Let's keep track of the current hovered dot in the table to show appropriate tooltip data
  const [currentHoveredDot, setCurrentHoveredDot] =
    useState<ImbalanceVariantsByAxle>();

  return (
    <Box
      className="w-full"
      style={{ height: 330 }}
      data-testid="dual-imbalance-end-chart-body-container"
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid
            stroke="#DFDFDF"
            data-testid="dual-imbalance-chart-body-chart-grid"
          />
          <XAxis
            dataKey="date"
            tick={chartTickStyle}
            tickLine={false}
            interval={"preserveStart"}
          />
          <YAxis
            yAxisId="Imbalance"
            allowDataOverflow={false}
            label={{
              value: pressureUnit === PressureUnit.Psi ? "PSI" : "Millibars",
              angle: -90,
              dx: -10,
              style: {
                textAnchor: "middle",
                ...chartTickStyle,
              },
            }}
            tick={chartTickStyle}
            interval={0}
            tickSize={0}
          />
          {/* Show the thresholds legend behind the lines */}
          {getReferenceAreasForDualImbalance()}
          Show information for the currently hovered tire
          <Tooltip
            offset={-5}
            active={true}
            content={
              <DualImbalanceChartTooltip
                selectedDualRecord={currentHoveredDot}
                pressureUnit={pressureUnit}
              />
            }
          />
          {Object.values(ImbalanceVariantsByAxle).map(
            (imbalanceKey: ImbalanceVariantsByAxle) => (
              <Line
                key={imbalanceKey}
                yAxisId="Imbalance"
                dataKey={imbalanceKey}
                type="linear"
                stroke={ColorsPalette.RoyalBlue}
                strokeWidth={3}
                strokeDasharray={determineDualImbalanceStrokeDash(
                  imbalanceKey,
                  selectedDualImbalanceRecord as ImbalanceVariantsByAxle
                )}
                dot={false}
                activeDot={{
                  r: currentHoveredDot === imbalanceKey ? 5 : 3,
                  stroke:
                    currentHoveredDot === imbalanceKey
                      ? ColorsPalette.OffWhite
                      : "transparent",

                  onMouseEnter: () =>
                    setCurrentHoveredDot(
                      imbalanceKey as ImbalanceVariantsByAxle
                    ),
                  onMouseLeave: () => setCurrentHoveredDot(undefined),
                }}
              />
            )
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DualImbalanceChartBody;
