import { Box, Grid, Typography } from "@mui/material";
import { TooltipProps } from "recharts";
import { PressureUnit } from "../../../../../../../../../../graphql/operations";
import { getPressureUnitLabel } from "../../../../../../../../../../utils/convertPressure";
import { ChartTooltipSection } from "../../../../../../../Shared/ChartTooltipSection/ChartTooltipSection";
import {
  ImbalanceVariantsByAxle,
  MAP_AXLE_TO_NUMBER,
} from "../../../../../interfaces";

export interface DualImbalanceChartTooltipProps {
  selectedDualRecord: ImbalanceVariantsByAxle | undefined;
  payload?: TooltipProps<string, string>["payload"];
  pressureUnit: PressureUnit;
}
interface TooltipObj {
  name: string;
  avg: string;
  min: string;
  max: string;
  sectionHeader: string;
}
export const DualImbalanceChartTooltip: React.FC<
  DualImbalanceChartTooltipProps
> = ({ selectedDualRecord, payload, pressureUnit }) => {
  const pressureUnitSuffix = getPressureUnitLabel(pressureUnit);

  if (!payload?.length || !selectedDualRecord) {
    return null;
  }
  const payloadData = payload.find(
    (item) => item.dataKey === selectedDualRecord
  )?.payload;
  const tooltipDate = payloadData.tooltipDate;
  const avgKeys = Object.keys(payloadData)?.filter(
    (item: string) =>
      !item?.includes("-min") &&
      !item?.includes("-max") &&
      !item?.includes("tooltipDate") &&
      !item?.includes("date")
  );
  const dataArr: TooltipObj[] = [];
  const selectedObj = Object.assign(
    {},
    {
      name: selectedDualRecord,
      avg: payloadData?.[selectedDualRecord],
    }
  );
  avgKeys.forEach((key: string) => {
    const axleNumber = Number(key[key.length - 1]);
    const axleName = Object.keys(MAP_AXLE_TO_NUMBER)[axleNumber - 1];
    const sectionHeader = key?.includes("left")
      ? `${axleName} Dual Left`
      : `${axleName} Dual Right`;
    dataArr.push({
      name: key,
      avg: payloadData?.[key],
      min: payloadData?.[`${key}-min`],
      max: payloadData?.[`${key}-max`],
      sectionHeader: sectionHeader,
    });
  });
  const filteredData = dataArr?.filter(
    (item) =>
      item.name === selectedObj.name ||
      (item.name !== selectedObj.name && item.avg === selectedObj.avg)
  );

  return (
    <Box
      className="rounded-lg bg-custom-tooltip-background border border-sub-header-border"
      sx={{
        padding: "16px",
        paddingBottom: "0px",
      }}
    >
      {/* ToolTip header */}
      <Box display="flex">
        <Typography
          sx={{
            color: "var(--checkbox-unchecked)",
            fontWeight: 400,
            paddingBottom: "0.5rem",
          }}
          data-testid={`dual-imbalance-chart-tooltip-date-label`}
        >
          {tooltipDate}
        </Typography>
      </Box>
      {/* ToolTip body */}
      <Box>
        <Grid
          sx={{
            display: "grid",
            gridTemplateColumns: filteredData?.length > 1 ? "1fr 1fr" : "1fr",
            gap: "1rem",
            paddingBottom: "1rem",
          }}
        >
          {filteredData?.map((item) => (
            <Grid item key={item.name}>
              <ChartTooltipSection
                sectionHeader={item.sectionHeader}
                leftValueLabel="Min"
                leftValue={`${item.min} ${pressureUnitSuffix}`}
                rightValueLabel="Max"
                rightValue={`${item.max} ${pressureUnitSuffix}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
