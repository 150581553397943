import { useCallback, useState } from "react";
import { TPMS_CHART_DATE_RANGE_OPTIONS } from "../../../../../../../../../constants/map";
import {
  getEndOfToday,
  getStartOfDay,
  getSubDays,
} from "../../../../../../../../../utils/date";
import { DEFAULT_TPMS_TRENDS_OPTION } from "../../../../../SummaryTabPanel/Charts/chartUtils";

interface DateFiltersHook {
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;

  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;

  dateRangeOption: number;
  setDateRangeOption: React.Dispatch<React.SetStateAction<number>>;

  handleDateRangeChange: (value: number) => void;
}

export const useDateFilters = (
  customStartDate?: Date,
  customEndDate?: Date
): DateFiltersHook => {
  const [dateRangeOption, setDateRangeOption] = useState(
    DEFAULT_TPMS_TRENDS_OPTION
  );
  const [startDate, setStartDate] = useState(
    customStartDate ?? getStartOfDay(getSubDays(new Date(), 30))
  );
  const [endDate, setEndDate] = useState(customEndDate ?? getEndOfToday());

  const handleDateRangeChange = useCallback((value: number) => {
    const option = TPMS_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );

    if (!option) {
      return;
    }

    setDateRangeOption(option.value);

    if (option?.getRange) {
      const range = option.getRange();

      setStartDate(range[0]);
      setEndDate(range[1]);
    }
  }, []);

  return {
    startDate,
    setStartDate,

    endDate,
    setEndDate,

    dateRangeOption,
    setDateRangeOption,

    handleDateRangeChange,
  };
};
