import { useEffect } from "react";
import { SET_APP_CONFIG } from "../../constants";
import { useAppContext } from "../../context/AppContext";

/**
 * Fetches the App Config from a JSON file and sets it in the App Context
 * This hook should be used only once in the App component!
 */
export const useSetAppConfig = () => {
  const { dispatch } = useAppContext();

  useEffect(() => {
    const fetchAppConfig = () => {
      fetch("/appConfig.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          }

          return response.json();
        })
        .then((data) => {
          dispatch({
            type: SET_APP_CONFIG,
            payload: data,
          });
        })
        .catch(() => {
          console.error("Error fetching App Config.");
        });
    };

    fetchAppConfig();
  }, [dispatch]);
};
