// Create Configuration
export const ASSET_CONFIGURATION_CREATE_SUCCESS_PAYLOAD = {
  title: "Asset Configuration created!",
  text: "Your Asset Configuration is created successfully.",
  severity: "success",
};
export const ASSET_CONFIGURATION_CREATE_FAILED_PAYLOAD = {
  title: "Asset Configuration create failed!",
  text: "An error occurred while creating your Asset Configuration.",
  severity: "error",
};
export const ASSET_CONFIGURATION_CREATE_FAILED_DUPLICATE_NAME_PAYLOAD = {
  title: "Asset Configuration create failed!",
  text: "Asset Configuration with provided Name already exists.",
  severity: "error",
};

// Update Configuration
export const ASSET_CONFIGURATION_UPDATE_SUCCESS_PAYLOAD = {
  title: "Asset Configuration updated!",
  text: "Your Asset Configuration is updated successfully.",
  severity: "success",
};
export const ASSET_CONFIGURATION_UPDATE_FAILED_PAYLOAD = {
  title: "Asset Configuration update failed!",
  text: "An error occurred while creating your Asset Configuration.",
  severity: "error",
};

export const ASSET_CONFIGURATION_UPDATE_FAILED_DUPLICATE_NAME_PAYLOAD = {
  title: "Asset Configuration update failed!",
  text: "Asset Configuration with provided Name already exists.",
  severity: "error",
};

// Delete Configuration
export const ASSET_CONFIGURATION_DELETE_SUCCESS_PAYLOAD = {
  title: "Icon Configuration deleted!",
  text: "Your Icon Configuration is deleted successfully.",
  severity: "success",
};
export const ASSET_CONFIGURATION_DELETE_FAILED_PAYLOAD = {
  title: "Icon Configuration delete failed!",
  text: "An error occurred while deleting your Icon Configuration",
  severity: "error",
};
