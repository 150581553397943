import { memo, useState, FC, ReactNode, useCallback } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { BatchTitles } from "../../../constants/batches";
import { BatchFormFieldsTypes } from "../../../views/BatchesView/BatchManagementUtils";
import { getDownloadTemplateHint } from "../../helpers/utils";
import DialogTemplate from "./DialogTemplate/DialogTemplate";
import DialogTitle from "./DialogTitle";
import { renderDialogActions } from "./renderDialogActions";
import { renderDialogContent } from "./renderDialogContent";

type UploadFileDialogProps = {
  title: BatchTitles;
  form: UseFormReturn<FieldValues>;
  allowedFormats?: string[];
  dialogFields: BatchFormFieldsTypes;
  isDataUploading?: boolean;
  isDataUploaded?: boolean;
  uploadResultComponent?: ReactNode;
  downloadTemplateFilePath?: string;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (file: File) => void;
};

const UploadFileDialog: FC<UploadFileDialogProps> = ({
  title,
  form,
  isDataUploading,
  isDataUploaded,
  dialogFields,
  uploadResultComponent,
  allowedFormats = [],
  downloadTemplateFilePath,
  isOpen,
  onClose,
  handleSubmit,
}) => {
  const [file, setFile] = useState<File | undefined>();
  const [missingFileError, setMissingFileError] = useState(false);

  const onHandleSave = useCallback(
    async (fileData?: File) => {
      if (!fileData) {
        return setMissingFileError(true);
      }
      const valid = await form.trigger();

      if (!valid) return;
      handleSubmit(fileData);
    },
    [form, handleSubmit]
  );

  const onCloseClick = (_event?: any, reason?: string) => {
    if (reason !== "backdropClick") {
      setFile(undefined);
      onClose();
    }
  };

  const onDeleteFile = () => setFile(undefined);

  const downloadTemplateHint = getDownloadTemplateHint(
    allowedFormats,
    isDataUploading,
    isDataUploaded
  );

  return (
    <Dialog
      onClose={onCloseClick}
      open={isOpen}
      maxWidth={false}
      data-testid="upload-dialog"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "668px !important",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle title={title} onClose={onCloseClick} />
      <DialogContent className="flex bg-background !p-6">
        <Box className="w-[40rem]">
          {renderDialogContent({
            title,
            file,
            missingFileError,
            allowedFormats,
            isDataUploaded,
            dialogFields,
            isDataUploading,
            control: form.control,
            uploadResultComponent,
            onFilesUpload: setFile,
            onDeleteFile,
            form,
          })}
        </Box>
      </DialogContent>
      {downloadTemplateHint && downloadTemplateFilePath && (
        <DialogTemplate
          text={downloadTemplateHint}
          downloadTemplateFilePath={downloadTemplateFilePath}
        />
      )}
      {renderDialogActions({
        isDataUploaded,
        isDataUploading,
        onDoneClick: onCloseClick,
        onCancelClick: onCloseClick,
        onSaveClick: () => onHandleSave(file),
      })}
    </Dialog>
  );
};

export default memo(UploadFileDialog);
