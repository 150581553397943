import { Box } from "@mui/material";
import { useAppContext } from "../../../../../context/AppContext";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { TOP_SECTION_HEIGHT } from "../FiltersDrawer/FiltersDrawerContent";
import { BaseGeofenceFilters } from "./components/BaseGeofenceFilters";
import { GeofenceQueryBuilder } from "./components/GeofenceQueryBuilder";
import { MoreGeofenceFilters } from "./components/MoreGeofenceFilters";

export const GeofenceFilters = () => {
  const {
    state: {
      theme: { theme },
    },
  } = useAppContext();

  const isMobile = useBreakpoint("down", "sm");
  const borderColor = theme === "dark" ? "gray" : "var(--border-color)";

  return (
    <div>
      <Box
        sx={{
          height: "100%",
          marginTop: TOP_SECTION_HEIGHT,
        }}
        className="w-full p-5 absolute justify-center items-center overflow-x-hidden overflow-y-visible"
      >
        <BaseGeofenceFilters />
        <MoreGeofenceFilters />
        {!isMobile && <GeofenceQueryBuilder />}
      </Box>
    </div>
  );
};
