import { FC, memo, MutableRefObject } from "react";
import { Box } from "@mui/system";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import {
  ProfileConfigProperty,
  TableViewType,
} from "../../../../../../graphql/operations";
import {
  Table,
  TableDataModes,
} from "../../../../../../shared/components/Table";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";
import { SensorsTablePaginationProps } from "../../Sensors";
import {
  sensorsTableKeyList,
  columnVisibilityModel,
  SensorsData,
  liftgateSensorTableColumns,
} from "../../sensorsUtils";
import useSensorsTableLogic from "../../shared/hooks/useSensorsTableLogic";

export interface SensorsLiftgateTableProps extends SensorsTablePaginationProps {
  tableType: TableViewType;
  onRowClick: (value: any) => void;
  onRowSelect: (value: any) => void;
  apiRef: MutableRefObject<GridApiPremium>;
  sensorsData: SensorsData;
  rowSelectionModel?: Array<number | string>;
}

const SensorsLiftgateTable: FC<SensorsLiftgateTableProps> = ({
  tableType,
  onRowClick,
  onRowSelect,
  apiRef,
  sensorsData,
  rowSelectionModel,
  onFilterChange,
  onSortModelChange,
  onPageChange,
  refetchingData,
  totalTableRowsCount,
}) => {
  const { tableRows, canCheckTheRows, fileName, appConfig } =
    useSensorsTableLogic({
      apiRef,
      rowSelectionModel,
      sensorsData: sensorsData.data,
      sensorPropertyType: ProfileConfigProperty.Liftgate,
      sensorColumns: liftgateSensorTableColumns,
    });

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={tableType}
        showToolbar
        rows={tableRows}
        apiRef={apiRef}
        columns={liftgateSensorTableColumns}
        columnVisibilityModel={columnVisibilityModel}
        onRowClick={onRowClick}
        updateSelectedRowsHandler={onRowSelect}
        loading={sensorsData.isFetchingSensor}
        error={sensorsData.isErrorSensor}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={sensorsTableKeyList}
        sortKeys={sensorsTableKeyList}
        searchMinLength={appConfig.searchMinLength}
        tableName="liftgate-sensors"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        checkboxSelection={canCheckTheRows}
        disableSelectionOnClick={true}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
        //pagination props
        // semi-server used for export to work with other server props
        handleDataMode={TableDataModes.SemiServer}
        onFilterModelChange={onFilterChange}
        onSortModelChange={onSortModelChange}
        onPageChange={onPageChange}
        onPaginationModelChange={onPageChange}
        isDataRefetching={refetchingData}
        rowCount={totalTableRowsCount ?? 0}
      />
    </Box>
  );
};

export default memo(SensorsLiftgateTable);
