import React, { useMemo } from "react";
import { Typography, Card } from "@mui/material";
import {
  AlertTypeV2,
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import PieChartWidget, {
  DataItem,
} from "../../../../../shared/components/DashboardWidgets/PieChartWidget";
import {
  mapAlertType,
  mapChartColors,
  mapLegendTextColor,
} from "../../../../../shared/components/DashboardWidgets/PieChartWidget/utils";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type AlertTypesData = {
  _id: string;
  totalCount: number | null;
  percentage: number | null;
};

export type DashboardWidgetAlertTypesData = { alertTypes: AlertTypesData[] };

export interface DashboardWidgetAlertTypes extends GetDashboardWidgetResponse {
  data: DashboardWidgetAlertTypesData;
}

export interface AlertTypesWidgetProps {
  widget: DashboardWidgetAlertTypes;
}

export const mapWidgetData = (
  data: DashboardWidgetAlertTypesData
): DataItem[] => {
  if (!data || !data.alertTypes) {
    return [];
  }

  return data.alertTypes.map((alert, index) => ({
    name: mapAlertType(alert._id as AlertTypeV2),
    value: alert.totalCount ?? 0,
    percentage: alert.percentage ?? 0,
    color: mapChartColors(alert._id, index),
    legendTextColor: mapLegendTextColor(alert._id),
  }));
};

export const AlertTypesWidget: React.FC<AlertTypesWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const data = useMemo(() => {
    const parsedData: DashboardWidgetAlertTypesData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    return mapWidgetData(parsedData);
  }, [getDashboardWidgetData?.getDashboardWidget?.data]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--alert-types"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the alert types chart!
          Please try again later.
        </Typography>
      ) : (
        <PieChartWidget
          title={title}
          isLoading={isLoading}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
