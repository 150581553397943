import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Asset } from "../../../../../../../../graphql/operations";
import { AssetTiresSensorState } from "../../../../AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { MAP_TPMS_AXLE } from "../../../constants";
import {
  parseTpmsAxlesSensors,
  prepareDualImbalanceData,
} from "../../../helpers";
import { AxleDualImbalanceData, DualImbalanceStatuses } from "../../../types";
import AxleSummaryCard from "./AxleSummaryCard";

export interface AxlesSummaryCardsProps {
  asset: Asset;
}

const AxlesSummaryCards: FC<AxlesSummaryCardsProps> = ({ asset }) => {
  const [axlesSensors, setAxlesSensors] = useState<AssetTiresSensorState[]>([]);
  const [axlesDualImbalanceStates, setAxlesDualImbalanceStates] = useState<
    DualImbalanceStatuses | undefined
  >(undefined);

  useEffect(() => {
    if (asset?.sensors?.tpmsBeta && axlesSensors.length === 0) {
      const sensorsStates: AssetTiresSensorState[] =
        parseTpmsAxlesSensors(asset);

      setAxlesSensors(sensorsStates);
    }

    // Note: will be updated in subsequent PR
    if (
      asset?.sensors?.tpmsBeta?.data?.sensors?.length &&
      !axlesDualImbalanceStates
    ) {
      setAxlesDualImbalanceStates(prepareDualImbalanceData(asset));
    }
  }, [asset, axlesDualImbalanceStates, axlesSensors]);
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
      data-testid="asset-dashboard--tires-tab--axles-cards-summary-section"
    >
      {axlesSensors.map((tiresSensorsState, index) => {
        const axle = MAP_TPMS_AXLE[index + 1];

        return (
          <Box
            display="flex"
            width="100%"
            key={`axles-cards-summary-section--card-${index}`}
          >
            <AxleSummaryCard
              axle={axle}
              // Properties come as axle1, axle2 etc.. here we acces with the map index
              dualImbalanceAxlesStates={
                axlesDualImbalanceStates?.[
                  `axle${index + 1}` as unknown as keyof DualImbalanceStatuses
                ] as AxleDualImbalanceData
              }
              tiresSensorsState={tiresSensorsState}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default AxlesSummaryCards;
