import { useMemo, useState } from "react";
import throttle from "lodash/throttle";
import { useFindMatchesQuery, FindMatchesType } from "../../graphql/operations";

export const useGlobalSearchQuery = (
  type: FindMatchesType,
  historyItems: string[],
  limit = 5
) => {
  const [searchText, setSearchText] = useState("");

  const setSearch = throttle((text) => setSearchText(text), 500, {
    leading: false,
  });

  const disabled = useMemo(
    () =>
      historyItems?.find((item) =>
        item.toLowerCase().startsWith(searchText.toLowerCase())
      ) || searchText.length < 3,
    [historyItems, searchText]
  );

  const result = useFindMatchesQuery(
    {
      input: {
        type,
        query: searchText,
        limit,
      },
    },
    { enabled: !disabled }
  );

  const { data: searchData, isLoading } = result;

  const { searchResult, options } = useMemo(() => {
    const searchResult = searchData?.findMatches?.items ?? [];
    const options = searchResult.map((item) => item.name);
    return { searchResult, options };
  }, [searchData?.findMatches]);

  return {
    searchResult,
    isLoading: !disabled && isLoading,
    options,
    setSearch,
  };
};
