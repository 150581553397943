import { FC, useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography, Button } from "@mui/material";
import { CustomTooltip } from "../../../../../shared/components/CustomTooltip/CustomTooltip";
import { assetQueryBuilderFields } from "../../../../../shared/components/QueryBuilder/config";
import {
  helperText,
  QueryBuilderElement,
} from "../../../../../shared/components/react-hook-form-mui/QueryBuilderElement";
import { GeofenceInitialRestrictions } from "../GeofenceForm/useRestrinctionsForm";

interface GeofenceRestrictionsQueryBuilderProps {
  form: UseFormReturn<Partial<GeofenceInitialRestrictions>>;
}

export const GeofenceRestrictionsQueryBuilder: FC<
  GeofenceRestrictionsQueryBuilderProps
> = ({ form }) => {
  const [shouldReset, setShouldReset] = useState(false);

  const resetQueryBuilderHandler = useCallback(() => {
    setShouldReset(true);
  }, []);

  const onReset = useCallback(() => {
    setShouldReset(false);
  }, []);

  return (
    <Box data-testid="geofence-restrictions-query-builder">
      <Box className="flex justify-between pr-2">
        <Typography className="!mb-6 !text-lg !font-semibold !text-brand">
          Custom Query
          <CustomTooltip title={helperText} arrow>
            <InfoOutlinedIcon className="ml-2 cursor-pointer" />
          </CustomTooltip>
        </Typography>
        <Button
          data-testid="reset-query-view-btn"
          className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
          onClick={resetQueryBuilderHandler}
        >
          Reset query view
        </Button>
      </Box>
      <QueryBuilderElement
        control={form.control}
        name="custom"
        configFields={assetQueryBuilderFields}
        shouldReset={shouldReset}
        onReset={onReset}
      />
    </Box>
  );
};
