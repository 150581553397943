import { FC, MutableRefObject } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useContainerDimensions } from "../../../../../../shared/hooks/useContainerDimensions";
import DoorHeader from "./DoorHeader";
import {
  BAR_HEIGHT,
  DoorData,
  calculateDataStatusLast30Days,
  calculateBarWidthBaseOnContainer,
} from "./utils";

export interface DoorStatusProps {
  data: Array<DoorData>;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

const DoorStatus: FC<DoorStatusProps> = ({ data, parentRef }) => {
  const { openDoorsEvents, closedDoorsEvents } =
    calculateDataStatusLast30Days(data);

  const { width: containerWidth } = useContainerDimensions(parentRef);

  const { barWidthA: openBarWidth, barWidthB: closedBarWidth } =
    calculateBarWidthBaseOnContainer(
      openDoorsEvents,
      closedDoorsEvents,
      containerWidth
    );

  return (
    <Grid
      spacing={0}
      justify-content="flex-end"
      container
      className="text-sm w-full p-4"
      data-testid="door-first-card"
    >
      <DoorHeader title={"Door Sensor"} />

      <Typography
        variant="caption"
        className="!text-lg !font-semibold inline-block pb-4"
        data-testid="door-first-card-title"
      >
        Door Status Last 30 Days
      </Typography>

      <Box className="!mb-2 w-full flex flex-no-wrap">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text flex-shrink-0"
        >
          Open
        </Typography>

        {openDoorsEvents > 0 && (
          <Box
            className="rounded inline-block align-middle text-center"
            data-testid="open-door-days"
            style={{
              color: `var(--${openDoorsEvents ? "white" : "primary"})`,
              background: "var(--indigo)",
              flexBasis: `${openBarWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {openDoorsEvents}
          </Box>
        )}
      </Box>

      <Box className="!mb-2 justify w-full flex flex-no-wrap">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text flex-shrink-0"
        >
          Closed
        </Typography>
        {closedDoorsEvents > 0 && (
          <Box
            className=" rounded inline-block align-middle text-center"
            data-testid="closed-door-days"
            style={{
              color: `var(--${closedDoorsEvents ? "white" : "primary"})`,
              background: "var(--indigo)",
              flexBasis: `${closedBarWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {closedDoorsEvents}
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default DoorStatus;
