import { useEffect, useState } from "react";
import useBreakpoint from "../../../../../../../shared/hooks/useBreakpoint";
import { UsedSpaceIndicatorMode } from "../components/UsedSpaceIndicator";
import { InternalCameraView } from "../interface";

interface UseUsedSpaceIndicatorModeHook {
  usedSpaceIndicatorMode: UsedSpaceIndicatorMode;
}

export const useUsedSpaceIndicatorMode = (
  activeView: InternalCameraView
): UseUsedSpaceIndicatorModeHook => {
  const [usedSpaceIndicatorMode, setUsedSpaceIndicatorMode] =
    useState<UsedSpaceIndicatorMode>(UsedSpaceIndicatorMode.Hidden);
  const isMobile = useBreakpoint("down", "sm");

  const determineUsedSpaceIndicatorMode = (
    activeView: InternalCameraView,
    isMobile: boolean
  ): UsedSpaceIndicatorMode => {
    if (isMobile) {
      // On mobile, strictly show the short title on the single image view
      if (activeView === InternalCameraView.OneImage) {
        return UsedSpaceIndicatorMode.ShortTitle;
      }

      return UsedSpaceIndicatorMode.Hidden;
    }

    // Desktop mode
    switch (activeView) {
      case InternalCameraView.OneImage:
        return UsedSpaceIndicatorMode.FullTitle;
      case InternalCameraView.FourImages:
      case InternalCameraView.SixImages:
        return UsedSpaceIndicatorMode.ShortTitle;
      default:
        return UsedSpaceIndicatorMode.Hidden;
    }
  };

  useEffect(() => {
    setUsedSpaceIndicatorMode(
      determineUsedSpaceIndicatorMode(activeView, isMobile)
    );
  }, [activeView, isMobile]);

  return {
    usedSpaceIndicatorMode,
  };
};
