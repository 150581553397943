import { ReactElement } from "react";
import {
  LiftgatetAdvertisementDetails,
  SensorStatus,
  SensorStatusUppercase,
} from "../../../../../../../graphql/operations";

export type AssetSensorState =
  | SensorStatus
  | SensorStatusUppercase
  | DoorSensorState
  | DwellSensorState
  | CargoState;

export enum DoorSensorState {
  Open = "open",
  Closed = "closed",
}

export enum DwellSensorState {
  Low = "low",
  Medium = "medium",
  Large = "large",
  Mega = "mega",
  None = "none",
  NotDwelling = "not-dwelling",
}

export enum AssetSensorType {
  Door = "door",
  Battery = "battery",
  Dwell = "dwell",
  Tires = "tires",
  Lamp = "lamp",
  TPMS = "tpmsBeta",
  CargoUltrasonic = "cargo_state",
  CargoVision = "floorUsagePercentage",
  AirBag = "airbag",
  Regulator = "regulator",
  AirTank = "air_tank",
  AtisAlpha = "atisAlpha",
  LiteSentryGamma = "liteSentryGamma",
  TemperatureInternal = "temperature",
  WheelEndTemperature = "psiWheelEnd",
  AirSupply = "air_supply",
  Liftgate = "liftgateAdvertisement",
}

export enum CargoState {
  Empty = "empty",
  Loaded = "loaded",
}

export enum CargoUltrasonicState {
  Unloaded = "unloaded",
  Loaded = "loaded",
  Unknown = "unknown",
}

export interface AssetTiresSensorState {
  outerLeft: AssetSensorState;
  outerRight: AssetSensorState;
  innerLeft?: AssetSensorState;
  innerRight?: AssetSensorState;
}

export interface AssetShortTooltipSensor {
  type: AssetSensorType;
  sensorLabel: string;
  icon?: ReactElement;
  chipLabel?: string;
  justifyBetween?: boolean;
  state?:
    | AssetSensorState
    | AssetTiresSensorState
    | DwellSensorState
    | CargoState
    | CargoUltrasonicState
    | DoorSensorState;
}
