import { Box, Typography, Grid } from "@mui/material";
import { isNumber } from "lodash";
import { ReactComponent as BatterySensorDark } from "../../../../../assets/svgs/batterySensorDark.svg";
import { ReactComponent as BatterySensorLight } from "../../../../../assets/svgs/batterySensorLight.svg";
import { useAppContext } from "../../../../../context/AppContext";
import { AssetUiConfig, Maybe } from "../../../../../graphql/operations";
import { useDateInPreferredTimezone } from "../../../../../shared/hooks/useDateInPreferredTimezone";
import { useBatteryStatus } from "../../hooks/useBatteryStatus";
import { StatusColor } from "./Cards/StatusCard";

const BatterySummaryWidget = ({
  battery,
  primaryVoltage,
  lastReportedDate,
  secondaryVoltage,
  solarAmperage,
  batteryStatusString,
  assetUIConfig,
}: {
  battery?: number | null;
  primaryVoltage?: number | null;
  lastReportedDate: Date | null;
  secondaryVoltage?: number | null;
  solarAmperage?: number | null;
  batteryStatusString?: string;
  assetUIConfig?: Maybe<AssetUiConfig>;
}) => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const batteryStatus = useBatteryStatus(true, batteryStatusString);
  const timezoneDate = useDateInPreferredTimezone(lastReportedDate);

  const textColor =
    StatusColor[
      batteryStatus.status?.toLowerCase() as keyof typeof StatusColor
    ];
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
  };
  return (
    <Box
      className="rounded-lg bg-dashboard_subheader__bg px-4 py-6 h-90"
      data-testid={`battery-widget-summary`}
    >
      <Typography
        className="!text-lg !font-semibold !text-brand"
        data-testid="battery-widget-title"
      >
        Battery
      </Typography>
      <Typography
        className="!text-xs !font-medium !mb-3 text-asset-info-subheader"
        data-testid={`battery-widget-subtitle`}
      >
        Battery Information About Your Asset
      </Typography>
      <Box className=" flex gap-3 items-center">
        <Box className="mb-1 flex gap-3" data-testid="battery-widget-status">
          {isLightTheme ? (
            <BatterySensorDark style={svgIconSettings} />
          ) : (
            <BatterySensorLight style={svgIconSettings} />
          )}

          <span
            style={{
              alignSelf: "center",
              color: textColor ? `var(--${textColor})` : "inherit",
            }}
          >
            {batteryStatus.status ?? ""}
          </span>
        </Box>
      </Box>

      <Grid container spacing={1}>
        {/* true & null || undefined counts as visible */}
        {assetUIConfig?.power?.battery !== false && (
          <>
            <Grid item xs={6}>
              <Box
                className="my-1 flex gap-1"
                data-testid="battery-widget-battery"
              >
                <Typography className="!text-checked">Battery</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className="my-1 flex gap-1"
                data-testid="battery-widget-battery-value"
              >
                <Typography className="!text-checked">
                  {isNumber(battery) ? `${battery.toFixed(2)}V` : ""}
                </Typography>
              </Box>
            </Grid>
          </>
        )}

        {/* true & null || undefined counts as visible */}
        {assetUIConfig?.power?.main !== false && (
          <>
            <Grid item xs={6}>
              <Box
                className="my-1 flex gap-1"
                data-testid="battery-widget-primary"
              >
                <Typography className="!text-checked">
                  Primary Voltage
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className="my-1 flex gap-1"
                data-testid="battery-widget-primary-value"
              >
                <Typography className="!text-checked">
                  {isNumber(primaryVoltage)
                    ? `${primaryVoltage.toFixed(2)}V`
                    : ""}
                </Typography>
              </Box>
            </Grid>
          </>
        )}

        {/* true & null || undefined counts as visible */}
        {assetUIConfig?.power?.auxiliary !== false && (
          <>
            <Grid item xs={6}>
              <Box
                className="my-1 flex gap-1"
                data-testid="battery-widget-secondary"
              >
                <Typography className="!text-checked">
                  Secondary Voltage
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className="my-1 flex gap-1"
                data-testid="battery-widget-secondary-value"
              >
                <Typography className="!text-checked">
                  {isNumber(secondaryVoltage)
                    ? `${secondaryVoltage.toFixed(2)}V`
                    : ""}
                </Typography>
              </Box>
            </Grid>
          </>
        )}

        {/* true & null || undefined counts as visible */}
        {assetUIConfig?.power?.solarAmperage !== false && (
          <>
            <Grid item xs={6}>
              <Box
                className="my-1 flex gap-1"
                data-testid="battery-widget-solar"
              >
                <Typography className="!text-checked">Solar</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className="my-1 flex gap-1"
                data-testid="battery-widget-solar-value"
              >
                <Typography className="!text-checked">
                  {isNumber(solarAmperage)
                    ? `${solarAmperage.toFixed(2)}A`
                    : ""}
                </Typography>
              </Box>
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <Box className="my-1 flex gap-1" data-testid="battery-widget-date">
            <Typography className="!text-checked">Last Reported</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className="my-1 flex gap-1"
            data-testid="battery-widget-date-value"
          >
            <Typography className="!text-checked">{timezoneDate}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BatterySummaryWidget;
