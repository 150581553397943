import React, { FC, memo } from "react";
import { Control, Controller, get } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { FormControl, Input } from "@mui/material";
import { getLabel } from "../../FormControlElement/FormControlElement";
import { renderFormErrorHint } from "../../FormControlElement/styledElements";

interface PhoneNumberElementProps {
  label?: string;
  name: string;
  control: Control<any>;
  required?: boolean;
  disabled?: boolean;
  errors: any;
  hint?: string;
}

export const PhoneNumberElement: FC<PhoneNumberElementProps> = memo(
  ({
    label,
    name,
    control,
    required,
    disabled,
    errors,
    hint,
  }: PhoneNumberElementProps) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field }: any) => (
          <FormControl
            error={Boolean(get(errors, name, undefined))}
            className="w-full"
            disabled={!!disabled}
          >
            {getLabel(label ?? "", required ?? false, {
              className: "font-bold !leading-4",
            })}
            <PatternFormat
              {...field}
              format="###-###-##############"
              customInput={Input}
              data-testid={`form-control-input-${name}`}
              className="!m-0"
              onChange={(e) => {
                const nonNumericPattern = /[-\s]/g;
                const valueFormatted = e.target.value.replace(
                  nonNumericPattern,
                  ""
                );
                if (isNaN(Number(valueFormatted))) {
                  return;
                }
                field.onChange(valueFormatted);
              }}
            />
            {renderFormErrorHint(errors, name, hint ?? "")}
          </FormControl>
        )}
      />
    );
  }
);
