import { PropsWithChildren } from "react";
import { Button, SxProps, Theme } from "@mui/material";
import useBreakpoint from "../../../../../../../../shared/hooks/useBreakpoint";

export interface ActionButtonProps extends PropsWithChildren {
  sx?: SxProps<Theme>;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  onClick: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  children,
  sx = {},
  className = "",
  disabled = false,
  dataTestId = "asset-dashboard--other-sensors--internal-camera-sensor--action-button",
  onClick,
}) => {
  const isMobile = useBreakpoint("down", "sm");

  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
      sx={{
        width: "72px",
        height: isMobile ? "32px" : "48px",
        border: "2px solid var(--asset-widget-action-button-border)",
        borderRadius: "100px",
        padding: "12px 24px",
        backgroundColor: "transparent",
        boxShadow: "none",
        color: "var(--asset-widget-action-button-border)",
        "svg path": {
          fill: "var(--asset-widget-action-button-border)",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:disabled": {
          color: "var(--light-charcoal)",
          borderColor: "var(--light-charcoal)",
          "svg path": {
            fill: "var(--light-charcoal)",
          },
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
