import { OrgDataInput } from "../../../../../../graphql/operations";
import { EditOrg, FormFieldsData, LogoData } from "../../types";
import { LogoFile } from "../hooks/useCompanyLogo";

export const createOrgPayload = (
  formValues: FormFieldsData,
  logo?: LogoData
): OrgDataInput => {
  return {
    name: formValues.name!,
    brandId: formValues.brand!,
    account_number: formValues.account_number?.trim(),
    parent_org_id: formValues.parent_company! as string,
    distance_unit_preference: formValues.distance_unit_preference!,
    pressure_unit_preference: formValues.pressure_unit_preference!,
    temperature_unit_preference: formValues.temperature_unit_preference!,
    time_zones: formValues.time_zones!,
    type: formValues.type!,
    company_logos: logo ? [{ ...logo }] : null,
    default_dashboard: Number(formValues.default_dashboard),
    favorite_dashboards: (formValues.favorite_dashboards ?? []).map(Number),
  };
};

export const updateOrgPayload = (
  orgPayload: EditOrg,
  value: FormFieldsData,
  logo?: LogoData,
  dirtyFields?: any, //TODO TYPE
  logoIsDeleted?: boolean
): EditOrg => {
  for (let fieldUpdated in dirtyFields) {
    // @ts-ignore
    orgPayload[fieldUpdated] = value[fieldUpdated];
  }
  if (orgPayload?.parent_company) {
    orgPayload["parent_org_id"] = value.parent_company as string;
  }
  delete orgPayload.parent_company;

  if (orgPayload?.brand) {
    orgPayload.brandId = value.brand;
  }
  delete orgPayload.brand;

  if (logoIsDeleted) {
    orgPayload.company_logos = [];
  }
  if (logo) {
    orgPayload.company_logos = [{ ...logo }];
  }
  if (value.default_dashboard) {
    orgPayload.default_dashboard = Number(value.default_dashboard);
  }
  if (value.favorite_dashboards) {
    orgPayload.favorite_dashboards = value.favorite_dashboards.map(Number);
  }

  return orgPayload;
};

export const handleLogoUpload = async (
  data: FormFieldsData,
  companyLogoInput: LogoFile | null,
  uploadCompanyLogoHandler: () => Promise<LogoData | undefined>,
  formValueSubmit: (data: FormFieldsData, companyLogo?: LogoData) => void,
  dispatchUploadFailure: () => void
) => {
  if (companyLogoInput) {
    try {
      const companyLogos = await uploadCompanyLogoHandler();
      formValueSubmit(data, companyLogos as LogoData);
    } catch (error) {
      dispatchUploadFailure();
    }
  } else {
    formValueSubmit(data);
  }
};
