import { HTMLAttributes } from "react";
import { Control, Controller } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  Grid,
  FormControl,
  Divider,
  TextField,
  createFilterOptions,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Input,
  OutlinedInput,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { MobileDateTimePicker } from "@mui/x-date-pickers-pro";
import { get, sortBy } from "lodash";
import {
  AutocompleteOption,
  FormField,
  FormFieldDropdownOption,
} from "../../../types";
import { CreatableAutocomplete } from "../../../views/AssetsView/TableView/components/AssetForm/components";
import { ColorInput } from "../ColorInput/ColorInput";
import { renderFormErrorHint, HelperText, Label } from "./styledElements";

export const getLabel = (
  label: string,
  required: boolean,
  InputLabelProps?: Omit<HTMLAttributes<HTMLLabelElement>, "color">
) => {
  if (required) {
    return (
      <Label {...InputLabelProps}>
        {label}
        <span aria-hidden="true" className="text-error">
          &thinsp;*
        </span>
      </Label>
    );
  } else {
    return <Label {...InputLabelProps}>{label}</Label>;
  }
};

export type FormControlProps = {
  formData: FormField;
  control: Control<any>;
  errors?: any;
  key?: string;
  index?: number;
  onChange: () => any;
};

export const isOptionEqualToValue = (
  option: AutocompleteOption & FormFieldDropdownOption,
  value: AutocompleteOption & FormFieldDropdownOption
) => Boolean(option?.value === value?.value);

/**
 * @deprecated !!! Please do not use this component in any new forms any more,
 * we're swithing to a flexible form creating with global styling
 * @todo Component is to be removed after all usages are removed when form unificitaion is complete
 *
 *
 * @usage in order to use for multiple fields you need to define an array with the fields &
 * then use a loop to call this component
 * @param props
 * @returns
 */
const FormControlElement = (props: any) => {
  const { formData, control, errors, index, onChange: onChangeProp } = props;
  const {
    name,
    type,
    label,
    options,
    disabled,
    dataType,
    loading,
    required,
    onChange,
    hint,
    ...restFormData
  } = formData;

  switch (type) {
    case "autocomplete":
      return (
        <Grid key={name} className="!mt-6 w-full" item>
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }: any) => {
              return (
                <Autocomplete
                  {...field}
                  {...restFormData}
                  data-testid={formData.dataTestid || `autocomplete-${name}`}
                  id="combo-box-demo"
                  options={options}
                  autoHighlight={true}
                  isOptionEqualToValue={isOptionEqualToValue}
                  getOptionLabel={(option: FormFieldDropdownOption) =>
                    option.label || ""
                  }
                  className="w-full"
                  loading={loading}
                  disabled={disabled}
                  filterOptions={(ops, state) => {
                    return createFilterOptions()(ops, state).slice(
                      0,
                      field.optionsLimit ?? 1000
                    );
                  }}
                  onChange={(_, data: any) => {
                    onChangeProp(data, name);
                    field.onChange(data);
                  }}
                  renderInput={({
                    InputProps,
                    inputProps,
                    InputLabelProps,
                    ...params
                  }) => {
                    return (
                      <FormControl
                        error={Boolean(get(errors, name, undefined))}
                        {...params}
                        className="w-full"
                      >
                        {getLabel(label, required, InputLabelProps)}
                        <Input
                          autoFocus={!index}
                          {...params}
                          disabled={disabled}
                          {...InputProps}
                          inputProps={{
                            ...inputProps,
                            "data-testid":
                              formData.dataTestid || `autocomplete-${name}`,
                          }}
                        />
                        {renderFormErrorHint(errors, name, hint)}
                      </FormControl>
                    );
                  }}
                />
              );
            }}
          />
        </Grid>
      );
    case "multiSelect": {
      return (
        <Grid key={name} className="!mt-6 w-full" item>
          <Controller
            name={name}
            control={control}
            // RHF -> react-hook-form (renaming the 'onChange' for some visibility)
            render={({
              field: { value, onChange: onChangeFromRHF, ...field },
            }) => (
              <FormControl className="w-full" variant="standard">
                {getLabel(label, required)}

                <Select
                  {...field}
                  {...restFormData}
                  onChange={(changeEvent) => {
                    // For the cases where some custom logic is required
                    if (onChange) {
                      onChange(
                        changeEvent,
                        value,
                        onChangeFromRHF,
                        changeEvent.target.value,
                        options
                      );
                    } else {
                      onChangeFromRHF(changeEvent.target.value);
                    }
                  }}
                  className="w-full"
                  multiple
                  disabled={disabled}
                  value={Array.isArray(value) ? value : []}
                  MenuProps={{
                    sx: {
                      maxHeight: "40%",
                      "&& .Mui-selected": {
                        background: "inherit !important",
                        color: "inherit",
                      },
                    },
                  }}
                >
                  {(() => {
                    // Logic for setting "All" option always on the first position of the index
                    const alphabeticalySortedOptions = sortBy(options, "label");
                    const indexOfAllOption =
                      alphabeticalySortedOptions.findIndex(
                        (element) => element.label === "All"
                      );

                    if (indexOfAllOption !== -1) {
                      const [element] = alphabeticalySortedOptions.splice(
                        indexOfAllOption,
                        1
                      );
                      alphabeticalySortedOptions.unshift(element);
                    }

                    return alphabeticalySortedOptions.map(
                      ({ value: optionValue, label }) => (
                        <MenuItem key={optionValue} value={optionValue}>
                          <Checkbox
                            checked={value?.indexOf(optionValue) > -1}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                className="!text-checkbox-unchecked"
                                fontSize="small"
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon
                                className="!text-brand"
                                fontSize="small"
                              />
                            }
                          />
                          <ListItemText primary={label} />
                        </MenuItem>
                      )
                    );
                  })()}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      );
    }
    case "singleSelect": {
      return (
        <Grid key={name} className="!mt-6 w-full" item>
          <Controller
            name={name}
            control={control}
            // RHF -> react-hook-form (renaming the 'onChange' for some visibility)
            render={({
              field: { value, onChange: onChangeFromRHF, ...field },
            }) => (
              <FormControl className="w-full" variant="standard">
                {getLabel(label, required)}

                <Select
                  {...field}
                  {...restFormData}
                  className="w-full"
                  disabled={disabled}
                  value={value ?? ""}
                  onChange={(changeEvent) => {
                    // For the cases where some custom logic is required
                    if (onChange) {
                      onChange(
                        changeEvent,
                        value,
                        onChangeFromRHF,
                        changeEvent.target.value,
                        options
                      );
                    } else {
                      onChangeFromRHF(changeEvent.target.value);
                    }
                  }}
                >
                  {options?.map(
                    (option: FormFieldDropdownOption, index: number) => (
                      <MenuItem value={option.value} key={`${option?.value}`}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      );
    }
    case "text":
    case "email":
    case "number":
      return (
        <Grid key={name} className="!mt-6 w-full" item>
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl
                error={Boolean(get(errors, name, undefined))}
                className="w-full"
                disabled={!!disabled}
              >
                {getLabel(label, required, { className: "font-bold" })}
                <Input
                  {...field}
                  {...restFormData}
                  autoFocus={!index}
                  onChange={(e) => {
                    if (dataType === "number" && isNaN(Number(e.target.value)))
                      return;
                    field.onChange(e.target.value);
                  }}
                  type={type}
                  inputProps={{
                    "data-testid":
                      formData.dataTestid || `form-control-input-${name}`,
                  }}
                />
                {renderFormErrorHint(errors, name, hint)}
              </FormControl>
            )}
          />
        </Grid>
      );
    case "phoneNumber":
      return (
        <Grid key={name} className="!mt-6 w-full" item>
          <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({ field }: any) => (
              <FormControl
                error={Boolean(get(errors, name, undefined))}
                className="w-full"
                disabled={!!disabled}
              >
                {getLabel(label, required, { className: "font-bold" })}
                <PatternFormat
                  {...field}
                  {...restFormData}
                  format="###-###-####"
                  autoFocus={!index}
                  type={type}
                  index={index}
                  customInput={Input}
                  data-testid={`form-control-input-${name}`}
                  onChange={(e) => {
                    if (dataType === "number" && isNaN(Number(e.target.value)))
                      return;
                    field.onChange(e.target.value);
                  }}
                />
                {renderFormErrorHint(errors, name, hint)}
              </FormControl>
            )}
          />
        </Grid>
      );
    case "date":
      return (
        <Grid key={name} className="!mt-6 w-full" item>
          <Controller
            name={name}
            control={control}
            defaultValue={new Date()}
            render={({ field }) => (
              <FormControl
                error={Boolean(get(errors, name, undefined))}
                className="w-full"
              >
                {getLabel(label, required, { className: "font-bold" })}
                <MobileDateTimePicker
                  {...field}
                  {...restFormData}
                  DialogProps={{
                    className: formData.className,
                  }}
                  autoFocus={!index}
                  renderInput={(params) => (
                    <TextField
                      data-testid={formData.dataTestid || `calendar-${name}`}
                      {...field}
                      {...params}
                    />
                  )}
                />
                {renderFormErrorHint(errors, name, hint)}
              </FormControl>
            )}
          />
        </Grid>
      );
    case "multiline":
      return (
        <Grid key={name} className="!mt-6 w-full" item>
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl
                error={Boolean(get(errors, name, undefined))}
                className="w-full"
              >
                {getLabel(label, required, { className: "font-bold" })}
                <OutlinedInput
                  {...field}
                  {...restFormData}
                  autoFocus={!index}
                  multiline
                  minRows={3}
                  inputProps={{
                    "data-testid":
                      formData.dataTestid || `multiline-input-${name}`,
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {renderFormErrorHint(errors, name, hint)}
                </Box>
              </FormControl>
            )}
          />
        </Grid>
      );
    case "creatableautocomplete":
      return (
        <Grid key={name} className="!mt-6 w-full" item>
          <Controller
            name={name}
            control={control}
            defaultValue={null}
            render={({ field }) => {
              return (
                <CreatableAutocomplete
                  {...restFormData}
                  name={name}
                  label={label}
                  field={field}
                  errors={{}}
                  onChange={onChange}
                  disabled={disabled}
                  loading={loading}
                  options={options}
                  getLabelTemplate={getLabel}
                />
              );
            }}
          />
        </Grid>
      );
    case "hr":
      return <Divider />;
    case "colorInput": {
      return (
        <Grid key={name} className="!mt-6 w-full" item>
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { ref, onChange, value } }) => (
              <FormControl
                error={Boolean(get(errors, name, undefined))}
                className="w-full"
                disabled={!!disabled}
              >
                <ColorInput
                  {...restFormData}
                  label={label}
                  required
                  value={value}
                  onChange={onChange}
                  inputRef={ref}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {renderFormErrorHint(errors, name, hint)}
                </Box>
              </FormControl>
            )}
          />
        </Grid>
      );
    }
    default:
      return null;
  }
};

FormControlElement.defaultProps = {
  onChange: () => {},
};

export default FormControlElement;
