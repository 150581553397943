import { FC, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Asset } from "../../../../../../../../graphql/operations";
import { AssetSensorState } from "../../../../AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import {
  MAP_PSI_WHEEL_END_AXLE,
  defaultBorderStyling,
} from "../../../constants";
import { parsePsiWheelEndAxlesSensors } from "../../../helpers";
import AxleSummaryCard from "./AxleSummaryCard";

export interface AssetWheelEndsSensorState {
  leftWheelEnd: AssetSensorState;
  rightWheelEnd: AssetSensorState;
}

export interface AxlesSummaryCardsProps {
  asset: Asset;
}

const AxlesSummaryCards: FC<AxlesSummaryCardsProps> = ({ asset }) => {
  const [axlesSensors, setAxlesSensors] = useState<AssetWheelEndsSensorState[]>(
    []
  );

  useEffect(() => {
    if (
      asset?.sensors?.psiWheelEnd?.data?.psiWheelEndMeasure?.length &&
      axlesSensors.length === 0
    ) {
      const sensorsStates: AssetWheelEndsSensorState[] =
        parsePsiWheelEndAxlesSensors(asset);

      setAxlesSensors(sensorsStates);
    }
  }, [asset, axlesSensors]);

  return (
    <Grid
      container
      spacing={2}
      className="flex"
      data-testid="asset-dashboard--tires-tab--psi-wheel-end--axles-cards-summary-section"
    >
      {axlesSensors.map((wheelEndsSensorsState, index) => {
        const axle = MAP_PSI_WHEEL_END_AXLE[index + 1];

        return (
          <Grid item xs={12} key={`axles-cards-summary-section--card-${index}`}>
            <AxleSummaryCard
              axle={axle}
              wheelEndsSensorsState={wheelEndsSensorsState}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AxlesSummaryCards;
