import { memo, FC, useCallback, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/material";
import { sortBy } from "lodash";
import { MAP_LEGENDS } from "../../../../constants/map";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { AssetIcon } from "../../TableView/components/AssetIcon";
import useMapLegend from "./hooks/useMapLegend";

//-------------------------------------
// Local Constants
//-------------------------------------
const Legends = sortBy(MAP_LEGENDS, "label");

//-------------------------------------
// Main Component
//-------------------------------------
export const MapLegend: FC = memo(() => {
  const wrapperRef = useRef<HTMLElement | null>(null);
  const isTabletOrLess: boolean = useBreakpoint("down", "lg");

  const { isDrawerOpened, setIsDrawerOpened } = useMapLegend(wrapperRef);

  const onToggleDrawer = useCallback(() => {
    setIsDrawerOpened(!isDrawerOpened);
  }, [isDrawerOpened, setIsDrawerOpened]);

  return (
    <Box
      ref={wrapperRef}
      sx={
        // Proper padding & width for bigger screens & if the drawer is opened
        isDrawerOpened && !isTabletOrLess
          ? {
              width: "fit-content",
              maxWidth: "820px",
              pt: "16px",
              pb: "16px",
              pl: "24px",
              pr: "24px",
            }
          : { padding: 1 }
      }
      className="w-[146px] sm:w-[183px] max-w-[183px] rounded-md bg-secondary shadow-card-sm !text-primary "
    >
      <button
        data-testid="map-legend-drawer"
        onClick={onToggleDrawer}
        className="flex !w-full !justify-between !items-center"
      >
        <span className="text-sm font-bold">Map Legend</span>
        {isDrawerOpened ? (
          <KeyboardArrowDownIcon
            className="text-primary"
            data-testid="map-legend-icon--chevron-down"
          />
        ) : (
          <KeyboardArrowUpIcon
            className="text-primary"
            data-testid="map-legend-icon--chevron-up"
          />
        )}
      </button>
      {isDrawerOpened && isTabletOrLess && (
        <ul
          className="max-h-[280px] overflow-y-scroll"
          data-testid="map-legend-drawer-content-mobile"
        >
          {Legends.map(({ label, ...props }) => (
            <li key={label} className="flex">
              <AssetIcon {...props} />
              <p className="ml-2">{label}</p>
            </li>
          ))}
        </ul>
      )}
      {isDrawerOpened && !isTabletOrLess && (
        <ul
          className="flex flex-row flex-wrap order-3"
          data-testid="map-legend-drawer-content"
        >
          {Legends.map(({ label, ...props }) => (
            <li key={label} className="flex m-2 w-56">
              <AssetIcon {...props} />
              <p className="ml-2">{label}</p>
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
});
