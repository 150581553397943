import { useMemo } from "react";
import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import {
  useFindAssetByIdQuery,
  FindAssetByIdQuery,
  Asset,
  AssetOs,
  useFindAssetByIdOsQuery,
  FindAssetByIdOsQuery,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";

interface FindAssetByIdHook {
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isRefetching: boolean;
  isError: boolean;
  data: Asset | null; // TODO: Replace it with AssetOS when the while implementation is done with PRJIND-9218
  error: unknown;
}

type FindAssetByIdQueryInput = {
  assetId: string;
};

type FindAssetByIdQueryOptions = UseQueryOptions<
  FindAssetByIdQuery,
  unknown,
  FindAssetByIdQuery,
  QueryKey
>;

export const useFindAssetById = (
  queryInput: FindAssetByIdQueryInput,
  queryOptions?: FindAssetByIdQueryOptions
): FindAssetByIdHook => {
  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const findAssetByIdQuery = fetchAssetsFromOpenSearchFeatureFlag
    ? useFindAssetByIdOsQuery
    : useFindAssetByIdQuery;

  const query = findAssetByIdQuery(queryInput, queryOptions);

  const asset = useMemo<Asset | null | undefined>(
    () =>
      fetchAssetsFromOpenSearchFeatureFlag
        ? mapAssetOsToAsset(
            (query.data as FindAssetByIdOsQuery | undefined)
              ?.findAssetByIdOS as AssetOs
          )
        : (query.data as FindAssetByIdQuery | undefined)?.findAssetById,
    [query.data, fetchAssetsFromOpenSearchFeatureFlag]
  );

  return {
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    isSuccess: query.isSuccess,
    isRefetching: query.isRefetching,
    isError: query.isError,
    data: asset ?? null,
    error: query.error,
  };
};

const mapAssetOsToAsset = (input: AssetOs): Asset | null => {
  if (!input) {
    return null;
  }

  const { sub_asset_type, ...assetOs } = input;

  return {
    ...assetOs,
    subAssetType: sub_asset_type,
  } as Asset;
};
