import { MeUserData, OrgData } from "../../graphql/operations";

/**
 * Initializes the Pendo tracking system with user information.
 *
 * @param {object} userInfo - User information object
 */
const initializePendoWithUser = (
  userInfo: MeUserData,
  organization: OrgData,
  isInImpersonationMode = false
) => {
  (window as any).pendo.initialize({
    visitor: {
      id: userInfo._id,
      email: userInfo.email,
      username: userInfo.name,
      role: userInfo.groups?.[0] ?? "",
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      isInImpersonationMode,
    },
    account: {
      id: organization?._id,
      name: organization.name,
    },
  });
};

export default initializePendoWithUser;
