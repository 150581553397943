import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ActionButton from "./ActionButton";

interface ShareButtonProps {}

const ShareButton: React.FC<ShareButtonProps> = () => {
  // TODO: Not implemented yet, due to limitations on how to share a photo using the mailto:?body=... method
  // Note: We can't inject HTML into the body of the mailto: link, so we can't use the <img> tag to display the image
  const handleOnClick = () => {};

  return (
    <ActionButton
      onClick={handleOnClick}
      disabled={true}
      dataTestId="asset-dashboard--other-sensors--internal-camera-sensor--share-button"
    >
      <ShareOutlinedIcon />
    </ActionButton>
  );
};

export default ShareButton;
