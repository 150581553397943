import { memo, SVGProps, FC } from "react";

interface SearchProps extends SVGProps<SVGSVGElement> {}

const Search: FC<SearchProps> = (props) => {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50326 7.50338H7.97659L7.78992 7.32338C8.44326 6.56338 8.83659 5.57671 8.83659 4.50338C8.83659 2.11004 6.89659 0.170044 4.50326 0.170044C2.10992 0.170044 0.169922 2.11004 0.169922 4.50338C0.169922 6.89671 2.10992 8.83671 4.50326 8.83671C5.57659 8.83671 6.56326 8.44338 7.32326 7.79004L7.50326 7.97671V8.50338L10.8366 11.83L11.8299 10.8367L8.50326 7.50338ZM4.50326 7.50338C2.84326 7.50338 1.50326 6.16338 1.50326 4.50338C1.50326 2.84338 2.84326 1.50338 4.50326 1.50338C6.16326 1.50338 7.50326 2.84338 7.50326 4.50338C7.50326 6.16338 6.16326 7.50338 4.50326 7.50338Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Search);
