import { ReactElement, useState } from "react";
import { Box } from "@mui/material";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceArea,
} from "recharts";
import { ColorsPalette } from "../../../../../../../../../design-system/colors-palette";
import { PressureUnit } from "../../../../../../../../../graphql/operations";
import { getConvertedPressureValue } from "../../../../../../../../../utils/convertPressure";
import { chartTickStyle } from "../../../../../SummaryTabPanel/Charts/chartUtils";
import { useTiresTabContext } from "../../../../context";
import {
  determineTpmsLineStokeColor,
  determineTpmsLineStrokeDash,
} from "../../../../helpers";
import {
  MAP_TPMS_TIRE_TYPES,
  TPMS_Axle,
  TPMS_Tire_Type,
  RangeReferenceAreaValueType,
} from "../../../../interfaces";
import { TemperatureReferenceAreaValueType } from "../../sensors/TemperatureChart";
import { TiresChartTooltip, TiresChartType } from "./TiresChartTooltip";

export enum Axle {
  ONE = "One",
  TWO = "Two",
  THREE = "Three",
}

export const AXLE_TO_KEY = {
  [Axle.ONE]: 1,
  [Axle.TWO]: 2,
  [Axle.THREE]: 3,
};

const LABEL_TO_AXLE: Record<TPMS_Axle, Axle> = {
  [TPMS_Axle.One]: Axle.ONE,
  [TPMS_Axle.Two]: Axle.TWO,
  [TPMS_Axle.Three]: Axle.THREE,
};

export type Trend = {
  date?: string;
  tooltipDate?: string;
  innerCurbside?: number;
  innerRoadside?: number;
  primaryCurbside?: number;
  primaryRoadside?: number;
  "innerCurbside-min"?: number;
  "innerRoadside-min"?: number;
  "primaryCurbside-min"?: number;
  "primaryRoadside-min"?: number;
  "innerCurbside-max"?: number;
  "innerRoadside-max"?: number;
  "primaryCurbside-max"?: number;
  "primaryRoadside-max"?: number;
};

type TiresChartBodyProps = {
  data: Trend[];
  label: string;
  chartTooltipType: TiresChartType;
  axle: Axle;
  referenceAreaValues: Array<any>;
  pressureUnit?: PressureUnit;
};

export enum State {
  Critical = "Critical",
  Warning = "Warning",
  Alert = "Alert",
  Healthy = "Healthy",
}

export const STATE_TO_COLOR = {
  [State.Critical]: ColorsPalette.ErrorOpacity20,
  [State.Warning]: ColorsPalette.CautionOpacity10,
  [State.Alert]: ColorsPalette.AlertOpacity20,
  [State.Healthy]: ColorsPalette.SuccessOpacity20,
};

const TiresChartBody: React.FC<TiresChartBodyProps> = ({
  data,
  label,
  chartTooltipType,
  axle,
  referenceAreaValues,
  pressureUnit,
}) => {
  // get Critical area max value
  const maxCriticalZoneValueForZones = referenceAreaValues[3]?.max2 ?? "auto";
  // then use it as chart yAxis domain
  const yAxisDomain = maxCriticalZoneValueForZones
    ? [0, maxCriticalZoneValueForZones]
    : undefined;
  // Compute reference area elements. For temperature we do not have repeated areas.
  const getReferenceAreasForTemperature = () =>
    referenceAreaValues?.map((value: TemperatureReferenceAreaValueType) => (
      <ReferenceArea
        yAxisId={label}
        y1={value.min}
        y2={value.max}
        fill={STATE_TO_COLOR[value.state as State]}
        fillOpacity={0.8}
        ifOverflow="extendDomain"
        key={`${chartTooltipType}-${value.state.toLowerCase()}`}
        data-testid="tires-chart-body-reference-area"
      />
    ));

  // Compute reference area elements. For pressure we have repeated areas.
  const getReferenceAreasForPressure = () => {
    const referenceAreas: ReactElement[] = [];
    referenceAreaValues.forEach((value: RangeReferenceAreaValueType) => {
      referenceAreas.push(
        <ReferenceArea
          yAxisId={label}
          y1={value.min1}
          y2={value.max1}
          fill={STATE_TO_COLOR[value.state as State]}
          fillOpacity={0.8}
          ifOverflow="extendDomain"
          key={`${chartTooltipType}-${value.state.toLowerCase()}-1`}
          data-testid="tires-chart-body-reference-area"
        />,
        <ReferenceArea
          yAxisId={label}
          y1={value.min2}
          y2={value.max2}
          fill={STATE_TO_COLOR[value.state as State]}
          fillOpacity={0.8}
          ifOverflow="extendDomain"
          key={`${chartTooltipType}-${value.state.toLowerCase()}-2`}
          data-testid="tires-chart-body-reference-area"
        />
      );
    });
    return referenceAreas;
  };

  const { selectedTpmsAxle: selectedAxle, selectedTpmsTire: selectedTire } =
    useTiresTabContext();

  // Helper aliases to determining the selected axle
  const isAxleSelected = selectedAxle
    ? LABEL_TO_AXLE[selectedAxle] === axle
    : false;

  // Let's keep track of the current hovered dot in the table to show appropriate tooltip data
  const [currentHoveredDot, setCurrentHoveredDot] = useState<TPMS_Tire_Type>();

  return (
    <Box
      className="w-full"
      style={{ height: 330 }}
      data-testid="tires-chart-body-container"
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid
            stroke="#DFDFDF"
            data-testid="tires-chart-body-chart-grid"
          />
          <XAxis
            dataKey="date"
            tick={chartTickStyle}
            tickLine={false}
            interval={"preserveStart"}
          />
          <YAxis
            yAxisId={label}
            domain={yAxisDomain}
            label={{
              value: label,
              angle: -90,
              dx: -10,
              style: { textAnchor: "middle", ...chartTickStyle },
            }}
            tick={chartTickStyle}
            interval={0}
            tickSize={0}
          />

          {/* Show the thresholds legend behind the lines */}
          {chartTooltipType === TiresChartType.Pressure
            ? getReferenceAreasForPressure()
            : getReferenceAreasForTemperature()}

          {/* Show information for the currently hovered tire */}
          <Tooltip
            offset={-5}
            content={
              <TiresChartTooltip
                type={chartTooltipType}
                selectedTire={currentHoveredDot}
                pressureUnit={pressureUnit}
              />
            }
          />

          {Object.values(TPMS_Tire_Type).map((tireType: TPMS_Tire_Type) => (
            <Line
              key={tireType}
              yAxisId={label}
              type="linear"
              dataKey={tireType}
              stroke={determineTpmsLineStokeColor(isAxleSelected)}
              strokeWidth={3}
              strokeDasharray={determineTpmsLineStrokeDash(
                isAxleSelected,
                isAxleSelected && selectedTire === MAP_TPMS_TIRE_TYPES[tireType]
              )}
              connectNulls
              dot={false}
              activeDot={{
                r: currentHoveredDot === tireType ? 5 : 3,
                stroke:
                  currentHoveredDot === tireType
                    ? ColorsPalette.OffWhite
                    : "transparent",

                onMouseEnter: () => setCurrentHoveredDot(tireType),
                onMouseLeave: () => setCurrentHoveredDot(undefined),
              }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TiresChartBody;
