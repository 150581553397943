import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import config from "../../config";
import { PAGE_SNACKBAR, USER_REDIRECT_URL } from "../../constants";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import { useCognitoAuthTokens } from "../../services/cognito";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { useCheckRouteSecurity } from "../../utils/routes/useCheckRouteSecurity";

const { cognito } = config;

const Login = () => {
  const pageRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const authCode = useMemo(() => searchParams.get("code"), [searchParams]);
  const { mutate, isError, isSuccess, data } = useCognitoAuthTokens();
  const { dispatch } = useAppContext();
  const { login } = useAuthContext();
  const { defaultPath, isLoadingDefaultPath } = useCheckRouteSecurity();
  const [cookies, setCookie, removeCookie] = useCookies([USER_REDIRECT_URL]);

  const [shouldRedirect, setShouldRedirect] = useState(false);

  if (!authCode) {
    window.location.href = `${cognito.domain}/login?client_id=${cognito.clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${window.location.origin}/login`;
  }

  useEffect(() => {
    if (authCode) {
      mutate(authCode);
    }
  }, [authCode, mutate]);

  useEffect(() => {
    if (isError) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Login Failed!",
          text: "The authorization code is already used or expired. Click here to try again.",
          severity: "error",
          onClose: () => navigate(NavigationRoutes.Login, { replace: true }),
        },
      });
    }
  }, [dispatch, isError, navigate]);

  useEffect(() => {
    if (isSuccess && data?.data) {
      // TODO: setting up accessToken in local storage as well, as far as cookie size is limited
      // probably we will need to find a better solution
      localStorage.setItem("accessToken", data.data.access_token);
      login({
        expiresIn: data.data.expires_in,
        accessToken: data.data.access_token,
        idToken: data.data.id_token,
        refreshToken: data.data.refresh_token,
      });

      const verifier = CognitoJwtVerifier.create({
        userPoolId: cognito.userPoolId,
        tokenUse: "access",
        clientId: cognito.clientId,
      });

      const decodedToken = verifier.verify(data.data.access_token);

      decodedToken
        .then(() => {
          const userRedirectUrl = cookies[USER_REDIRECT_URL];

          if (userRedirectUrl) {
            removeCookie(USER_REDIRECT_URL);
            navigate(userRedirectUrl);
          } else {
            setShouldRedirect(true);
          }
        })
        .catch((error) => {
          console.debug("Token not valid", error);
        });
    }
  }, [data, isSuccess, login, navigate, defaultPath, cookies, removeCookie]);

  useEffect(() => {
    if (shouldRedirect && !isLoadingDefaultPath) {
      navigate(defaultPath, { replace: true });
    }
  }, [defaultPath, isLoadingDefaultPath, shouldRedirect, navigate]);

  return <div ref={pageRef}></div>;
};

export default memo(Login);
