import { useMemo } from "react";
import {
  SensorProfileType,
  useGetSensorProfileNamesWithConfigurationQuery,
} from "../../../../../../graphql/operations";
import {
  SensorProfileDropdownItem,
  sortSensorProfileNameDropdownItems,
} from "../../sensorsUtils";

export const useGetSensorProfilesWithConfigurationSorted = (
  sensorType: string,
  currentOrgId?: string
) => {
  const { data: profilesData, isLoading: isSensorProfileNamesLoading } =
    useGetSensorProfileNamesWithConfigurationQuery(
      {
        input: {
          orgId: currentOrgId ?? "",
          type: SensorProfileType.Organization,
          includeDefaultPctProfile: true,
          mergeProfilesWithDefaultPctProfile: true,
          sensorType,
        },
      },
      {
        enabled: Boolean(currentOrgId),
      }
    );

  const sensorProfilesForOrganization = useMemo(
    () => profilesData?.getSensorProfilesForOrganization ?? [],
    [profilesData]
  );

  const sortedAtisAlphaSensorProfilesDropdownItems: SensorProfileDropdownItem[] =
    sortSensorProfileNameDropdownItems(
      sensorProfilesForOrganization.map(
        (item) =>
          ({
            label: item.name,
            value: item._id,
            id: item._id,
            configuration: item.configuration,
            type: item.type,
          } as SensorProfileDropdownItem)
      )
    );

  return {
    sortedAtisAlphaSensorProfilesDropdownItems,
    isSensorProfileNamesLoading,
  };
};
