import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { DefaultBrandColorPalette } from "../../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { DEFAULT_LEGEND_TEXT_COLOR } from "../../../../../../shared/components/DashboardWidgets/PieChartWidget/constants";
import { useSelectedOrg } from "../../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../../shared/utils";

export type DashboardWidgetAssetsInGeofenceData = {
  assetsCount: Record<string, number>;
};

export interface AssetsInGeofenceWidgetProps {
  widget: GetDashboardWidgetResponse;
}

export const mapWidgetData = (
  titles: string[],
  values: number[]
): HorizontalBarChartData[] => {
  if (!titles.length || !values.length) {
    return [];
  }

  return titles.map((title, index) => ({
    name: title,
    value: values[index],
    color: mapChartColors(title),
    legendTextColor: mapLegendTextColor(title),
  }));
};

const mapChartColors = (title: string): string => {
  if (title === "Other") {
    return "#DFDFDF";
  }

  return `${DefaultBrandColorPalette.Primary}`;
};

const mapLegendTextColor = (title: string): string => {
  if (title === "Other") {
    console.log("HERE");
    return "#1E1E1E";
  }

  return DEFAULT_LEGEND_TEXT_COLOR;
};

export const AssetsInGeofenceWidget: React.FC<AssetsInGeofenceWidgetProps> = ({
  widget,
}) => {
  const widgetTitle = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const data = useMemo(() => {
    const parsedData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const titles = Object.keys(parsedData?.assetsCount ?? {});
    const values = Object.values<number>(parsedData?.assetsCount ?? {});
    const mappedData = mapWidgetData(titles, values);

    return mappedData;
  }, [getDashboardWidgetData?.getDashboardWidget?.data]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--assets-in-geofence"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets in geofence
          widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={widgetTitle}
          subtitle="Total number of Users"
          showTotal={false}
          showPercentages={false}
          isLoading={isLoading}
          totalRecords={data?.length ?? 0}
          data={data}
          showXAxis={false}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
