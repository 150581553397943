import { cloneDeep } from "lodash";
import {
  ConfigurationSet,
  RegionGroupTypeValue,
} from "../../../../graphql/operations";
import {
  TerritoryInUse,
  ParsedRegionsData,
  RegionData,
  RegionCountry,
} from "./interfaces";

export const initialRegionsData: ParsedRegionsData = {
  unitedStates: {
    isDefault: false,
    data: [],
  },
  canada: {
    isDefault: false,
    data: [],
  },
  mexico: {
    isDefault: false,
    data: [],
  },
};

export const parseRegionData = (
  regionsData: ConfigurationSet[],
  isDefault?: boolean
): ParsedRegionsData =>
  [...regionsData].reduce((result, data) => {
    const dataValue = JSON.parse(data.value) as RegionGroupTypeValue;
    if (checkIsCountryValid(dataValue.country)) {
      result[dataValue.country].isDefault = !!isDefault;
      result[dataValue.country].data.push({
        id: data._id,
        name: data.name,
        country: dataValue.country as RegionCountry,
        territories: (dataValue.territories ?? []) as string[],
      });
    }

    return result;
  }, cloneDeep(initialRegionsData));

export const checkIsCountryValid = (
  country: string
): country is keyof ParsedRegionsData =>
  Object.values(RegionCountry).includes(country as RegionCountry);

export const getTerritoriesInUseRegionData = (
  regionData: RegionData[]
): TerritoryInUse[] => {
  const territoriesInUse: TerritoryInUse[] = regionData.reduce(
    (territoriesInUse, region) => {
      const currentRegionTerritories = region.territories.map((territory) => ({
        regionName: region.name,
        territoryName: territory,
      }));

      return [...territoriesInUse, ...currentRegionTerritories];
    },
    [] as TerritoryInUse[]
  );

  return territoriesInUse;
};
