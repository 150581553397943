import { Box, CircularProgress } from "@mui/material";
import {
  DateRangeKey,
  TPMS_CHART_DATE_RANGE_OPTIONS,
} from "../../../../../../../../constants/map";
import {
  Maybe,
  SortOrder,
  TemperatureInternalThresholds,
  TpmsPressureThresholds,
  useGetTpmsSensorHistoricalDataQuery,
} from "../../../../../../../../graphql/operations";
import { useGetPressureUnitPreference } from "../../../../../../../../shared/hooks/useGetPressureUnitPreference";
import { FIVE_MINUTES_REFETCH_INTERVAL } from "../../../../SummaryTabPanel/Charts/chartUtils";
import {
  convertToIsoStringIgnoringTimezoneOffset,
  rangesSensorConfigToReferenceAreaValues,
} from "../../../helpers";
import TiresChartBody, { Axle } from "../components/tpms/TiresChartBody";
import TiresChartHeader from "../components/tpms/TiresChartHeader";
import { TiresChartType } from "../components/tpms/TiresChartTooltip";
import { useDateFilters } from "./hooks/useDateFilters";
import { useTrendData } from "./hooks/useTrendData";

type PressureChartProps = {
  axle: Axle;
  imei: string;
  customerOrgId: string;
  tiresAxleImage: JSX.Element;
  sensorConfig?:
    | Maybe<TpmsPressureThresholds>
    | Maybe<TemperatureInternalThresholds>;
};

const PressureChart: React.FC<PressureChartProps> = ({
  axle,
  imei,
  customerOrgId,
  tiresAxleImage,
  sensorConfig,
}) => {
  const {
    startDate,
    setStartDate,

    endDate,
    setEndDate,

    dateRangeOption,
    setDateRangeOption,

    handleDateRangeChange,
  } = useDateFilters();

  const pressureUnit = useGetPressureUnitPreference();

  const { data, isLoading } = useGetTpmsSensorHistoricalDataQuery(
    {
      input: {
        imei,
        customerOrgId: customerOrgId,
        startDate: convertToIsoStringIgnoringTimezoneOffset(startDate),
        endDate: convertToIsoStringIgnoringTimezoneOffset(endDate),
        period:
          TPMS_CHART_DATE_RANGE_OPTIONS.find(
            (option) => option.value === dateRangeOption
          )?.key ?? DateRangeKey.Last30Days,
        includeEmptyDates: true,
        sort: SortOrder.Asc,
      },
    },
    {
      refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL,
      enabled: Boolean(imei),
    }
  );

  // Compute temperature trend to display by transforming aggregated data
  const pressureTrend = useTrendData(
    data,
    axle,
    TiresChartType.Pressure,
    pressureUnit
  );

  const referenceAreaValues =
    rangesSensorConfigToReferenceAreaValues(sensorConfig, pressureUnit) ?? [];
  return (
    <Box
      className="flex-1 rounded-lg bg-dashboard_subheader__bg border border-asset-card-border p-4"
      data-testid="tires-chart-container"
    >
      <TiresChartHeader
        title={`Axle ${axle} Tire Pressure`}
        subtitle="Trending pressure data over time"
        startDate={startDate}
        setStartDate={setStartDate}
        dateRangeOption={dateRangeOption}
        setDateRangeOption={setDateRangeOption}
        endDate={endDate}
        setEndDate={setEndDate}
        changeDateRangeOption={handleDateRangeChange}
        tiresChartAxleImage={tiresAxleImage}
      />
      {isLoading ? (
        <Box
          className="flex h-full w-full items-center justify-center"
          data-testid="tires-trends-loader"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TiresChartBody
          data={pressureTrend}
          label="Pressure"
          chartTooltipType={TiresChartType.Pressure}
          axle={axle}
          referenceAreaValues={referenceAreaValues}
          pressureUnit={pressureUnit}
        />
      )}
    </Box>
  );
};

export default PressureChart;
