import { memo, FC } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ReportIcon from "@mui/icons-material/Report";
import { Typography, Box, List, ListItem } from "@mui/material";
import { ParseFileContentError } from "../../../utils";

export type UploadResultProps = {
  uploadedMessage?: string;
  errors?: ParseFileContentError[];
  processedRecordsCount?: number;
  itemName?: string;
};

const UploadResult: FC<UploadResultProps> = ({
  uploadedMessage,
  errors = [],
  // with updated design this prop is not used, but lets have it just in case
  itemName,
  processedRecordsCount,
}: UploadResultProps) => {
  const hasErrors = errors.length > 0;
  const itemsWithErrorsCount = Object.keys(
    errors.reduce((rows, err) => ({ ...rows, [err.row]: "" }), {})
  ).length;
  const recordTextError = itemsWithErrorsCount === 1 ? `record` : `records`;
  const errorsText = `${itemsWithErrorsCount} ${recordTextError} encountered issues (${errors.length} total)`;

  const isOneRecord = processedRecordsCount === 1;
  const recordTextSuccess = isOneRecord ? "record" : "records";
  const verbSuccess = isOneRecord ? "is" : "are";

  const successText = `${processedRecordsCount} ${recordTextSuccess} ${verbSuccess} verified and ${verbSuccess} accepted for processing. You will receive an email with results when the batch is processed and available in the system.`;
  return (
    <Box className="max-h-[220px]">
      {!hasErrors && (
        <Box className="mb-5 flex">
          <Typography className="!text-lg !font-bold">
            {uploadedMessage}
          </Typography>
        </Box>
      )}
      {Boolean(processedRecordsCount && processedRecordsCount > 0) && (
        <Box className="flex">
          <Box className="mr-4 text-success">
            <CheckBoxIcon />
          </Box>
          <Typography className="!text-lg !font-bold">{successText}</Typography>
        </Box>
      )}
      {hasErrors && (
        <Box>
          <Box className="flex">
            <Box className="mr-4 text-error">
              <ReportIcon />
            </Box>
            <Typography className="!text-lg !font-bold">
              {errorsText}
            </Typography>
          </Box>
          <List className="!pl-10">
            {errors.map(({ row, message }) => (
              <ListItem
                key={`${row}-${message}`}
                disablePadding
                className="!py-1 !text-base !font-normal"
              >{`Row ${row} - ${message}`}</ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default memo(UploadResult);
