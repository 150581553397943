import { HttpStatusCode } from "axios";
import {
  Maybe,
  Ms2Command,
  Ms2CommandResponse,
} from "../../graphql/operations";
import { SnackbarProps } from "../components/Snackbar/Snackbar";
import { MS2_MAPPED_COMMAND_CODES } from "./constants";
import { Ms2DeviceCode } from "./types";

export const getCommandCode = (command: Ms2Command): Ms2DeviceCode =>
  MS2_MAPPED_COMMAND_CODES[command];

export const parseMs2Response = (
  response: Maybe<Ms2CommandResponse> | undefined,
  commandCode: Ms2DeviceCode
): Pick<SnackbarProps, "severity" | "title" | "text"> | undefined => {
  const isResponseSucess = response?.status?.toLowerCase().includes("success");
  const isResponseInfo = response?.status?.toLowerCase().includes("info");

  if (isResponseSucess) {
    return {
      severity: "success",
      title: "Device responded successfully",
      text: `Device has responded for command ${commandCode}`,
    };
  } else if (isResponseInfo) {
    return {
      severity: "warning",
      title: "Command sent successfully",
      text: `Command ${commandCode} has been sent to the device and awaits execution`,
    };
  }
};

export const parseMs2Error = (
  response: Maybe<Ms2CommandResponse> | undefined,
  commandCode: Ms2DeviceCode
): Pick<SnackbarProps, "title" | "text"> => {
  switch (response?.statusCode) {
    case HttpStatusCode.Unauthorized:
    case HttpStatusCode.Forbidden:
      return {
        title: "Authentication error",
        text: "Unable to communicate with device",
      };
    case HttpStatusCode.NotFound:
      return {
        title: "Communication error",
        text: "Device not found",
      };
    default:
      return {
        title: "Communication Error",
        text: `Device cannot be obtained to send command ${commandCode}. Device hasn't reported in the last 10 days`,
      };
  }
};
