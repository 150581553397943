import { FC, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Asset } from "../../../../../../../../graphql/operations";
import { defaultBorderStyling } from "../../../constants";
import { parsePsiWheelEndSummary } from "../../../helpers";
import { PsiWheelEndSummaryData } from "../../../interfaces";
import TireSummaryCard from "./TireSummaryCard";

export interface TiresSummaryCardsProps {
  asset: Asset;
}

const TiresSummaryCards: FC<TiresSummaryCardsProps> = ({ asset }) => {
  const [sensors, setSensors] = useState<PsiWheelEndSummaryData[]>([]);

  useEffect(() => {
    if (asset?.sensors?.psiWheelEnd && sensors.length === 0) {
      // Check If there are sensors that are not null
      const sensorsData =
        asset.sensors.psiWheelEnd?.data?.psiWheelEndMeasure ?? {};
      if (Object.values(sensorsData).some((sensor) => sensor !== null)) {
        const sensorsSummary = parsePsiWheelEndSummary(asset);

        setSensors(sensorsSummary);
      }
    }
  }, [asset, sensors]);

  return (
    <Grid
      container
      spacing={2}
      className="flex"
      data-testid="asset-dashboard--tires-tab--psi-wheel-end-sensor--tires-cards-summary-section"
    >
      {sensors.map((sensor, index) => {
        // Note: The grid order will be fixed once all layout items are implemented.
        return (
          <Grid
            item
            xs
            xl={6}
            key={`tires-cards-summary-section--psi-wheel-end-sensor-card-${index}`}
          >
            <TireSummaryCard
              axle={sensor.axle}
              wheelEnd={sensor.wheelEnd}
              status={sensor.status}
              temperature={sensor.temperature}
              lastReported={sensor.lastReported}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TiresSummaryCards;
