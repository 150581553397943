import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import slug from "slug";
import { useAppContext } from "../../../../context/AppContext";
import {
  ReportsTableDataResponse,
  GetTableDataInput,
  SortOrder,
  useGetReportsTableDataQuery,
  ReportsTableData,
  TableViewType,
  FileFormat,
  TableDomain,
} from "../../../../graphql/operations";
import Spinner from "../../../../shared/components/Spinner";
import { BackEndProcessingTable } from "../../../../shared/components/Table/BackEndProcessingTable";
import {
  ServerSideExport,
  ServerSideExportFormat,
} from "../../../../shared/components/Table/types";
import { usePreferredTimezone } from "../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { columnVisibilityModel, getColumns } from "./columns";

export const ReportsTable: FC = memo(() => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const timezone = usePreferredTimezone();
  const columns = useMemo(() => getColumns(timezone), [timezone]);
  const [isExporting, setIsExporting] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);

  const {
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const [queryInput, setQueryInput] = useState<GetTableDataInput>({
    sorting: [{ field: "updatedAt", order: SortOrder.Desc }],
    orgId: selectedOrganization.value,
    pagination: {
      skip: 0,
      limit: appConfig.table.defaultRowsPerPage,
    },
  });

  useEffect(() => {
    setQueryInput((prev) => ({ ...prev, orgId: selectedOrganization.value }));
  }, [selectedOrganization]);

  const {
    data: queryData,
    isSuccess,
    isLoading,
  } = useGetReportsTableDataQuery<ReportsTableDataResponse>(
    { input: queryInput },
    {
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getReportsTableData }) => getReportsTableData,
    }
  );

  const updateQueryInput = useCallback(
    (data: Partial<GetTableDataInput>) => {
      setQueryInput((prev) => ({ ...prev, ...data }));
    },
    [setQueryInput]
  );

  const navigateToReportPage = useCallback(
    (data: ReportsTableData) => {
      navigate(`${NavigationRoutes.Reports}/${slug(data.name)}/${data.id}`);
    },
    [navigate]
  );

  useTableDataExporter<ReportsTableData>({
    queryInput,
    apiRef,
    columns,
    domain: TableDomain.Reports,
    fileFormat,
    isExporting,
    setExporting: setIsExporting,
    totalCount: queryData?.pagination.total,
    baseFilters: {
      orgId: selectedOrganization.value,
    },
  });

  const handleExport = useCallback((format: ServerSideExportFormat) => {
    if (format === ServerSideExport.EMAIL) {
      setIsSendingEmail(true);
    } else {
      setFileFormat(format);
      setIsExporting(true);
    }
  }, []);

  return (
    <Box className="h-full w-full px-4 pt-8 pb-4 md:px-6 md:pt-0 md:pb-3 lg:px-16">
      <BackEndProcessingTable
        apiRef={apiRef}
        queryInput={queryInput}
        domain={TableDomain.Reports}
        isSendingEmail={isSendingEmail}
        setSendingEmail={setIsSendingEmail}
        tableType={TableViewType.Reports}
        tableName={"reports-list"}
        columnVisibilityModel={columnVisibilityModel}
        columns={columns}
        data={{
          rows: queryData?.data ?? [],
          pagination: queryData?.pagination,
        }}
        updateQueryInput={updateQueryInput}
        sorting={queryInput.sorting ?? undefined}
        onExport={handleExport}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onRowClick={navigateToReportPage}
        totalCount={queryData?.pagination.total}
        baseFilters={{
          orgId: selectedOrganization.value,
        }}
      />
      <Spinner counter={Number(isExporting) || Number(isSendingEmail)} />
    </Box>
  );
});
