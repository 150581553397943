import { ControllerRenderProps } from "react-hook-form";
import { GeofenceRestrictions } from "../../../graphql/operations";
import { GeofenceInitialRestrictions } from "../../../views/AssetsView/MapView/Geofences/GeofenceForm/useRestrinctionsForm";
import { ItemType } from "./MultiSelectItem";

export type FieldType =
  | ControllerRenderProps<
      Partial<GeofenceInitialRestrictions>,
      "allowedAssetTypes"
    >
  | ControllerRenderProps<
      Partial<GeofenceInitialRestrictions>,
      "allowedAssetLength"
    >
  | ControllerRenderProps<
      Partial<GeofenceInitialRestrictions>,
      "allowedAssetTags"
    >
  | ControllerRenderProps<
      Partial<GeofenceInitialRestrictions>,
      "allowedDoorType"
    >;

export const SELECT_ALL_VALUE = "Select All";

export const mutateState = (
  stateToMutate: ItemType[],
  callback: (item: ItemType) => ItemType
) =>
  stateToMutate.map((stateItem) => {
    const mutated = callback(stateItem);
    return {
      ...mutated,
    };
  });
