import { Box, Skeleton } from "@mui/material";

export const TriplegridOverlay = () => {
  const overlayRows = [1, 2, 3];
  return (
    <Box
      data-testid="loading-overlay-container"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {overlayRows.map((row) => (
        <Box
          key={`grid-row-${row}`}
          width="100%"
          display="flex"
          gap="16px"
          marginBottom="16px"
        >
          <Box data-testid={`first-bars-${row}`} width="100%">
            <Skeleton variant="text" width="60%" height="42px"></Skeleton>
            <Skeleton variant="rounded" height="42px"></Skeleton>
          </Box>
          <Skeleton
            variant="rounded"
            sx={{ height: "42px", width: "100%", alignSelf: "flex-end" }}
          ></Skeleton>
          <Skeleton
            variant="rounded"
            sx={{ height: "42px", width: "100%", alignSelf: "flex-end" }}
          ></Skeleton>
        </Box>
      ))}
    </Box>
  );
};
