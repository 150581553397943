import { FC } from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { capitalize } from "lodash";
import { ReactComponent as AirtankSensorDark } from "../../../../../assets/svgs/airTankSensorDark.svg";
import { ReactComponent as AirtankSensorLight } from "../../../../../assets/svgs/airtankSensorLight.svg";
import { useAppContext } from "../../../../../context/AppContext";
import SensorHeader from "../../../../../shared/components/SensorHeader/SensorHeader";
import { Table } from "../../../../../shared/components/Table";
import { useDateInPreferredTimezone } from "../../../../../shared/hooks/useDateInPreferredTimezone";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { getConvertedPressureValueWithUnit } from "../../../../../utils/convertPressure";
import { AirTankSummaryData } from "../BrakesTab/helpers";
import { columnVisibilityModel, columns } from "./columns";

export interface AirTankInfoTableProps extends AirTankSummaryData {}

export const AirTankInfoTable: FC<AirTankInfoTableProps> = ({
  title,
  status,
  pressure,
  lastReported,
}) => {
  const pressureUnit = useGetPressureUnitPreference();
  const pressureValue = getConvertedPressureValueWithUnit(
    pressure,
    pressureUnit
  );
  const gridApiRef = useGridApiRef();
  const lastUpdated = useDateInPreferredTimezone(
    lastReported,
    "MM/dd/yy h:mm a"
  );
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };
  if (pressureValue) {
    // Note: Currently we support only one Air Tank sensor per asset. In the future, we should create a function here to generate the rows dinamicaly
    const row = {
      _id: 1,
      location: title,
      millibars: pressureValue,
      status: capitalize(status),
      lastUpdated,
    };
    const rows = [row];
    const tableKey = `table-1`;

    return (
      <Grid xs={12} key={tableKey} data-testid="airTankInfoComponent-table">
        <Box className="h-full w-full rounded-lg pt-8">
          <Grid item xs={12} data-testid="airTankInfoComponent-table-icon">
            <SensorHeader title={title} noBorder>
              {isLightTheme ? (
                <AirtankSensorDark style={svgIconSettings} />
              ) : (
                <AirtankSensorLight style={svgIconSettings} />
              )}
            </SensorHeader>
          </Grid>
          <Table
            tableName="airtank-info"
            columns={columns}
            rows={rows}
            showToolbar={false}
            pagination={false}
            getRowId={(row) => row._id}
            allowExport={false}
            apiRef={gridApiRef}
            columnVisibilityModel={columnVisibilityModel}
            initialState={{
              sorting: {
                sortModel: [{ field: "lastUpdated", sort: "desc" }],
              },
            }}
            enableSearch={false}
            disableRowGrouping
            sx={{
              "& .MuiDataGrid-row": { cursor: "pointer" },
              height: "147px",
              minHeight: "147px",
              maxHeight: "147px",
              padding: 0,
              backgroundColor: "unset !important",
            }}
          />
        </Box>
      </Grid>
    );
  } else {
    return null;
  }
};

export default AirTankInfoTable;
