import { AutocompleteOption } from "../../../../../../../types";

export const UNKNOWN_LABEL = "Unknown";

export const sortManufacturersOptions = (
  a: AutocompleteOption,
  b: AutocompleteOption
): number => {
  if (a?.id === UNKNOWN_LABEL) {
    return 1;
  } else if (b?.id === UNKNOWN_LABEL) {
    return -1;
  } else {
    return a.id.localeCompare(b.id);
  }
};
