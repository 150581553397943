import { Typography } from "@mui/material";
import {
  SensorStatus,
  SensorStatusUppercase,
} from "../../../../../../../graphql/operations";
import { useGetSensorStateWidget } from "../../hooks/useGetSensorStateWidget";
import { AssetShortTooltipSensor } from "./AssetShortTooltipSensors.interface";
import AssetTooltipSensorSkeleton from "./AssetTooltipSensorSkeleton";

interface AssetShortTooltipSensorsProps {
  sensors: AssetShortTooltipSensor[];
  isFetchingAssetById?: boolean;
}

export const AssetShortTooltipSensors: React.FC<
  AssetShortTooltipSensorsProps
> = ({ sensors, isFetchingAssetById }) => {
  const { getSensorStateWidget } = useGetSensorStateWidget({
    isFetchingAssetById,
  });
  const visibleSensorStates = sensors.filter(
    (s) =>
      s.state !== undefined &&
      s.state !== null &&
      s.state !== SensorStatus.Unknown &&
      s.state !== SensorStatusUppercase.Unknown
  );

  return (
    <>
      <div className="text-xs flex flex-col gap-4" data-testid="asset-sensors">
        {isFetchingAssetById && (
          <div data-testid="asset-sensors--skeleton-loader">
            {new Array(7).fill(true).map((_, index) => (
              <AssetTooltipSensorSkeleton key={index} />
            ))}
          </div>
        )}
        {visibleSensorStates.map(getSensorStateWidget)}
      </div>
      {!visibleSensorStates.length && !isFetchingAssetById && (
        <div className="h-[245px] flex items-center justify-center">
          <Typography className="!font-medium">
            No sensors data available
          </Typography>
        </div>
      )}
    </>
  );
};
