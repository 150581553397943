import { memo, FC, useMemo } from "react";
import { Box } from "@mui/material";
import MUIDialogActions from "@mui/material/DialogActions";
import { Button } from "../../Button";

type DialogActionsProps = {
  showCancelBtn?: boolean;
  showSaveBtn?: boolean;
  isDataUploading?: boolean;
  onDoneClick: () => void;
  onCancelClick: () => void;
  onSaveClick: () => Promise<void>;
};

const DialogActions: FC<DialogActionsProps> = ({
  showCancelBtn,
  showSaveBtn,
  isDataUploading,
  onDoneClick,
  onCancelClick,
  onSaveClick,
}) => {
  const btnProps = useMemo(
    () => ({
      className: "!bg-brand !py-5 !px-8 !text-base !font-bold !text-white",
      variant: "default" as never,
      text: isDataUploading ? "Saving..." : showSaveBtn ? "Save" : "Done",
      onClick: showSaveBtn ? onSaveClick : onDoneClick,
      disabled: isDataUploading,
    }),
    [isDataUploading, showSaveBtn, onDoneClick, onSaveClick]
  );

  return (
    <MUIDialogActions className="bg-background !p-6">
      <Box>
        {showCancelBtn && (
          <Button
            sx={{ border: "none!important" }}
            className="!mr-2 !text-base !font-bold !text-brand"
            variant="ghost"
            text="Cancel"
            onClick={onCancelClick}
          />
        )}
        <Button {...btnProps} />
      </Box>
    </MUIDialogActions>
  );
};

export default memo(DialogActions);
