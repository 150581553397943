import { DEFAULT_TIMEZONE } from "../../utils";
import { useUserData } from "./useUserData";

export const usePreferredTimezone = (useUserOrgTimezone = false) => {
  const userData = useUserData();
  const customerOrgTZ = userData?.customerOrg?.time_zones ?? DEFAULT_TIMEZONE;
  const userPreferencesTZ = userData?.user_preferences?.time_zones;

  if (useUserOrgTimezone) {
    return customerOrgTZ;
  }

  return userPreferencesTZ ?? customerOrgTZ;
};
