import { ASSETS_TYPES_SIZE_MAP, beaconTypes } from "../../constants/map";
import { getGMFeatureIcon } from "./getGMFeatureIcon";

export interface MarkerImage {
  type: string;
  imageData: string;
  isBeacon: boolean;
}
export const getGMMarkers = () => {
  const markerImages: MarkerImage[] = [];
  ASSETS_TYPES_SIZE_MAP.forEach((size, type) => {
    markerImages.push(getGMFeatureIcon(size, type, beaconTypes.includes(type)));
  });
  return markerImages;
};
