import {
  AutocompleteElement,
  FieldValues,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ThemeProvider,
  createFilterOptions,
  FormHelperText,
  Typography,
  FilterOptionsState,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import { ConfigurationSetType } from "../../../../../graphql/operations";
import {
  Button as SubmitButton,
  TextButton,
} from "../../../../../shared/components/Button";
import Text from "../../../../../shared/components/Text";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useGlobalOrganizationFilter } from "../../../../../shared/hooks/useGlobalOrganizationFilter";
import { useConfigurationSetsApi } from "../../../hooks/useConfigurationSetsApi";
import { ERROR_CONFIGURATION_SET_NAME_DUPLICATE } from "../../Configurations/configurationsUtils";
import { ZoneCountry, ZoneData } from "../interfaces";

export interface CreateEditZoneDialogProps {
  open: boolean;
  country: ZoneCountry;
  isEditDialog?: boolean;
  zoneData?: ZoneData;
  onClose: () => void;
}

export const schema = yup.object().shape({
  name: yup.string().required("Field is required!"),
  matches: yup
    .array()
    .of(yup.string())
    .test(
      "matches-or-ranges",
      "Either matches or ranges must be filled, but not both.",
      function (value) {
        const { ranges } = this.parent;
        return value?.length || ranges?.length;
      }
    ),
  ranges: yup
    .array()
    .of(yup.string())
    .test(
      "ranges-or-matches",
      "Either ranges or matches must be filled, but not both.",
      function (value) {
        const { matches } = this.parent;
        return value?.length || matches?.length;
      }
    ),
});

export const CreateEditZoneDialog: React.FC<CreateEditZoneDialogProps> = ({
  isEditDialog,
  open,
  country,
  zoneData,
  onClose,
}) => {
  // Hooks
  const queryClient = useQueryClient();
  const { dispatch } = useAppContext();
  const formTheme = useFormTheme();
  const globalOrgSelected = useGlobalOrganizationFilter();
  const isMobile = useBreakpoint("down", "sm");

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    values: {
      name: zoneData?.name ?? "",
      matches: zoneData?.matches ?? [],
      ranges: zoneData?.ranges?.map((range: string[]) => range.join("")) ?? [],
    },
  });
  const watchedName = watch("name");
  const watchedMatches = watch("matches");
  const watchedRanges = watch("ranges");

  // API
  const createZoneConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "success",
        title: "Zone created!",
        text: "Your Zone is created successfully.",
      },
    });

    onCloseHandler();
  };

  const createZoneConfigurationSetOnError = (error: Error) => {
    const text =
      error.message === ERROR_CONFIGURATION_SET_NAME_DUPLICATE
        ? "Zone name already exists!"
        : "An error occurred while creating your Zone.";

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "error",
        title: "Zone create failed!",
        text,
      },
    });

    onCloseHandler();
  };

  const updateZoneConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "success",
        title: "Zone updated!",
        text: "Your Zone is updated successfully.",
      },
    });

    onCloseHandler();
  };

  const updateZoneConfigurationSetOnError = (error: Error) => {
    const text =
      error.message === ERROR_CONFIGURATION_SET_NAME_DUPLICATE
        ? "Zone name already exists!"
        : "An error occurred while updating your Zone.";

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "error",
        title: "Zone update failed!",
        text,
      },
    });

    onCloseHandler();
  };

  const {
    isLoadingCreateConfigurationSet,
    createConfigurationSet,

    isLoadingUpdateConfigurationSet,
    updateConfigurationSet,
  } = useConfigurationSetsApi({
    createConfigurationSetOnSuccess: createZoneConfigurationSetOnSuccess,
    createConfigurationSetOnError: createZoneConfigurationSetOnError,

    updateConfigurationSetOnSuccess: updateZoneConfigurationSetOnSuccess,
    updateConfigurationSetOnError: updateZoneConfigurationSetOnError,
  });

  // Handlers
  const onCloseHandler = () => {
    reset();
    onClose();
  };

  const onSubmitHandler = ({ name, matches, ranges }: FieldValues) => {
    const zoneInputBase = {
      name,
      orgId: globalOrgSelected?.value ?? "",
      value: JSON.stringify({
        country,
        matches,
        ranges: ranges.map((range: string) => range.match(/.{1,5}/g)),
      }),
    };

    if (!isEditDialog) {
      createConfigurationSet({
        type: ConfigurationSetType.ZoneGroup,
        ...zoneInputBase,
      });
    } else if (zoneData) {
      updateConfigurationSet({ _id: zoneData.id, ...zoneInputBase });
    }
  };

  const baseDialogTestId = isEditDialog
    ? "edit-zone-dialog"
    : "create-zone-dialog";
  const baseFormTestId = isEditDialog ? "edit-zone-form" : "create-zone-form";
  const isMexico = country === ZoneCountry.Mexico;
  const isCanada = country === ZoneCountry.Canada;
  const allowedKeys = [
    "Backspace",
    "Delete",
    "Escape",
    "Enter",
    "ArrowLeft",
    "ArrowRight",
  ];

  const isLoading =
    isLoadingUpdateConfigurationSet || isLoadingCreateConfigurationSet;
  const isSubmitDisabled =
    isLoading ||
    !watchedName ||
    (!watchedMatches.length && !watchedRanges.length) ||
    !Object.keys(dirtyFields).length;

  type Option = string;

  const getFilterOptions = (
    options: Option[],
    params: FilterOptionsState<Option>
  ) => {
    const filter = createFilterOptions<Option>();
    const { inputValue } = params;
    const isValid = /\S/g.test(inputValue);

    const filtered = filter(options, params);

    if (!inputValue) return filtered;
    if (isValid) {
      filtered.push(inputValue);
    }
    return filtered;
  };

  return (
    <Dialog
      data-testid={baseDialogTestId}
      open={open}
      onClose={onCloseHandler}
      PaperProps={{
        sx: {
          padding: "32px 24px 24px 24px",
          width: "100%",
          maxWidth: "680px !important",
        },
      }}
    >
      <DialogTitle className="flex justify-between items-center !p-0 !pb-[32px]">
        <Text classes="!text-2xl !font-semibold capitalize !text-typography-secondary ">
          {isEditDialog ? "Edit Zone" : "Create New Zone"}
        </Text>
        <IconButton
          onClick={onCloseHandler}
          className="h-6 w-6 flex align-center"
          aria-label="close"
          data-testid={`${baseDialogTestId}-close-icon`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ThemeProvider theme={formTheme}>
        <form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <DialogContent className="!p-0 !pb-[24px] !overflow-hidden">
            <Grid
              container
              className="bg-background topPaddingDrawerSection !pt-0"
            >
              <Grid item xs={12} className="!pt-0 !mb-16">
                <TextFieldElement
                  name="name"
                  label="Zone Name"
                  fullWidth
                  control={control}
                  disabled={isLoading}
                  required
                  placeholder="Type Name..."
                  data-testid={`${baseFormTestId}-name`}
                />
              </Grid>
              <Grid item xs={12} className="!pt-0 !mb-16">
                <AutocompleteElement
                  name="matches"
                  label="Zip Matches"
                  options={[]}
                  autocompleteProps={{
                    disabled: isLoading,
                    getOptionDisabled: (option) => {
                      return isMexico
                        ? option.length < 5
                        : isCanada
                        ? option.length < 6
                        : option.length !== 3 && option.length !== 5;
                    },
                    renderOption: (props, option) => {
                      if (isCanada) {
                        const [start = "", end = ""] =
                          option.toUpperCase()?.match(/.{1,3}/g) ?? [];

                        return <li {...props}>{`add ${start} ${end}`}</li>;
                      }

                      return <li {...props}>{`add ${option}`}</li>;
                    },
                    filterOptions: getFilterOptions,
                    getOptionLabel: (option) => {
                      if (isCanada) {
                        const [start = "", end = ""] =
                          `${option}`.toUpperCase().match(/.{1,3}/g) ?? [];

                        return `${start} ${end}`;
                      }

                      return option;
                    },
                  }}
                  multiple
                  required
                  control={control}
                  textFieldProps={{
                    onKeyDown: (event) => {
                      const isNumeric = /^\d$/.test(event.key);
                      const isNumericOrLetter = /^[a-zA-Z0-9]$/.test(event.key);

                      if (isCanada) {
                        if (
                          !isNumericOrLetter &&
                          !allowedKeys.includes(event.key)
                        ) {
                          event.preventDefault();
                        }
                      } else {
                        if (!isNumeric && !allowedKeys.includes(event.key)) {
                          event.preventDefault();
                        }
                      }
                    },
                    inputProps: {
                      minLength:
                        country === ZoneCountry.UnitedStates
                          ? 3
                          : country === ZoneCountry.Canada
                          ? 6
                          : 5,
                      maxLength: isCanada ? 6 : 5,
                      placeholder: !watchedMatches.length ? "Type Zip..." : "",
                      "data-testid": `${baseFormTestId}-matches`,
                    },
                  }}
                />
              </Grid>
              {country !== ZoneCountry.Canada && (
                <Grid item xs={12} className="!pt-0 !mb-16">
                  <AutocompleteElement
                    name="ranges"
                    label="Zip Ranges"
                    options={[]}
                    autocompleteProps={{
                      disabled: isLoading,
                      getOptionDisabled: (option) => {
                        const [start = "", end = ""] =
                          option.match(/.{1,5}/g) ?? [];
                        const isRangeInvalid =
                          end < start || end === start || option.length < 10;

                        return isRangeInvalid;
                      },

                      renderOption: (props, option) => {
                        const [start = "", end = ""] =
                          option?.match(/.{1,5}/g) ?? [];

                        return <li {...props}>{`add ${start} - ${end}`}</li>;
                      },

                      filterOptions: getFilterOptions,
                      getOptionLabel: (option) => {
                        const [start = "", end = ""] =
                          option.match(/.{1,5}/g) ?? [];

                        return `${start} - ${end}`;
                      },
                    }}
                    multiple
                    required
                    control={control}
                    textFieldProps={{
                      onKeyDown: (event) => {
                        const isNumeric = /^\d$/.test(event.key);

                        if (!isNumeric && !allowedKeys.includes(event.key)) {
                          event.preventDefault();
                        }
                      },
                      inputProps: {
                        maxLength: 10,
                        placeholder: !watchedRanges.length
                          ? "Type Zip Range..."
                          : "",
                        "data-testid": `${baseFormTestId}-ranges`,
                      },
                    }}
                  />
                  <FormHelperText
                    className="!text-[0.7rem] flex"
                    component="div"
                  >
                    <ErrorOutlineIcon className="mr-2" fontSize="small" />
                    <Typography fontSize={12}>
                      Please use two 5-digit zip codes separated by a dash. The
                      first 5 digits being the start range and the second part
                      being the end range. The end range should be bigger than
                      the start range. Example: "01585-02748".
                    </Typography>
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: isMobile ? "center" : "end",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <TextButton
              text="Cancel"
              size="medium"
              theme="blue"
              disabled={isLoading}
              onClick={onCloseHandler}
              data-testid={`${baseFormTestId}-cancel-button`}
            />

            <SubmitButton
              size="medium"
              theme="blue"
              variant="default"
              type="submit"
              text={isLoading ? "Saving..." : "Save"}
              disabled={Boolean(isSubmitDisabled)}
              sx={{ padding: "24px", margin: 0 }}
              dataTestid={`${baseFormTestId}-submit-button`}
            />
          </DialogActions>
        </form>
      </ThemeProvider>
    </Dialog>
  );
};

export default CreateEditZoneDialog;
