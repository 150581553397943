import { ReportType } from "../../../graphql/operations";
import { ReportTypeTitle } from "../../ReportView";

interface OptionType {
  label: string;
  id: ReportType;
}

export const useReportTypeOptions = (): OptionType[] => [
  // Some options are hard-coded until we implement those reports on the API
  ...Object.values(ReportType).map((value) => ({
    id: value,
    label: ReportTypeTitle[value],
  })),
  { id: "DWELL" as any, label: "Dwell" },
  //{ id: "MEDIA_ACTIVITIES" as any, label: "Media Activities" },
];
