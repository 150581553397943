import { FC, memo, useEffect, useState } from "react";
import { Circle, Polygon } from "@react-google-maps/api";
import { getGeofenceRenderOptions } from "../../../../../constants/map";
import { GeofenceData, GeofenceShape } from "../../../../../graphql/operations";
import { Feature } from "../../../shared/AssetsDataContext";
import AssetsMarkers from "../../Assets/AssetsMarkers/AssetsMarkers";
import {
  calculateCircleCenterAndRadius,
  convertGeofenceGeometryToLatLngArray,
} from "./GeofenceGeoJSONHelper";

type GeofenceRendererProps = {
  geofences: GeofenceData[];
  handlePolygonsLoaded?: (polygon: google.maps.Polygon) => void;
  handleCirclesLoaded?: (circle: google.maps.Circle) => void;
  isAssetsVisibleOnGeoFence?: boolean;
  serverSideMapFeatures?: Feature[];
  markerImages?: any[];
  googleMap: google.maps.Map | null;
  geofenceForUpdate: GeofenceData | null;
};

type GoogleMapsPolygon = {
  id: string;
  path: google.maps.LatLng[];
};

type GoogleMapsCircle = {
  id: string;
  radius: number;
  center: google.maps.LatLngLiteral;
};

const geofenceRenderOptions = getGeofenceRenderOptions(false, false);

const GeofenceRenderer: FC<GeofenceRendererProps> = ({
  geofences,
  handlePolygonsLoaded,
  handleCirclesLoaded,
  isAssetsVisibleOnGeoFence = false,
  serverSideMapFeatures,
  markerImages,
  googleMap,
  geofenceForUpdate,
}) => {
  const [polygons, setPolygons] = useState<GoogleMapsPolygon[]>([]);
  const [circles, setCircles] = useState<GoogleMapsCircle[]>([]);

  useEffect(() => {
    const newPolygons: GoogleMapsPolygon[] = [];
    const newCircles: GoogleMapsCircle[] = [];
    geofences.forEach((geofence: GeofenceData) => {
      if (
        geofenceForUpdate &&
        (geofence._id === geofenceForUpdate._id ||
          geofence.orgId !== geofenceForUpdate.orgId)
      ) {
        // Avoid rendering the geofence that's being updated; it's handled separately by the updatePolygon or updateCircle methods
        // Render only the geofences that are in the same organization with the geofence that's updating
        return;
      }
      if (geofence.geofence.coordinates?.coordinates) {
        if (geofence.geofence.gisConfig?.shape === GeofenceShape.Polygon) {
          const path = convertGeofenceGeometryToLatLngArray(
            geofence.geofence.coordinates
          );
          if (path) {
            newPolygons.push({
              id: geofence._id,
              path,
            });
          }
        } else if (
          geofence.geofence.gisConfig?.shape === GeofenceShape.Circle
        ) {
          if (geofence.geofence.coordinates.coordinates?.[0]?.[0]) {
            const { center, radius } = calculateCircleCenterAndRadius(
              geofence.geofence.coordinates
            );
            newCircles.push({
              id: geofence._id,
              radius,
              center,
            });
          }
        }
      }
    });
    setPolygons(newPolygons);
    setCircles(newCircles);
  }, [geofences, geofenceForUpdate]);

  return (
    <>
      {polygons.map((polygon) => (
        <Polygon
          key={polygon.id}
          path={polygon.path}
          options={geofenceRenderOptions}
          onLoad={handlePolygonsLoaded}
        />
      ))}
      {circles.map((circle) => (
        <Circle
          key={circle.id}
          center={circle.center}
          radius={circle.radius}
          options={geofenceRenderOptions}
          onLoad={handleCirclesLoaded}
        />
      ))}
      {isAssetsVisibleOnGeoFence && serverSideMapFeatures && markerImages && (
        <AssetsMarkers
          serverSideMapFeatures={serverSideMapFeatures}
          markerImages={markerImages}
          googleMap={googleMap}
        />
      )}
    </>
  );
};

export default memo(GeofenceRenderer);
