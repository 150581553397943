import { MediaTableData } from "../../../../../graphql/operations";

export interface MediaTabTableData extends MediaTableData {
  id: string;
}

export enum MediaTabTableFloorUsageStatus {
  Loaded = "Loaded",
  Empty = "Empty",
}
