import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { DistanceUnit } from "../../../../graphql/operations";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { AlertFormValues } from "../../interfaces";
import { AlertParametersContainer } from "../AlertParametersContainer";

interface AlertSpeedParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const AlertSpeedParameters: FC<AlertSpeedParametersProps> = ({
  form,
}: AlertSpeedParametersProps) => {
  const currentOrg = useCurrentOrg();
  const valueUnit =
    currentOrg?.distance_unit_preference ?? DistanceUnit.Kilometers; // TODO: Replace with DEFAULT_DISTANCE_UNIT

  const kmphOrMph = (unit: string) => {
    if (unit === "Miles") {
      return "mph";
    } else {
      return "kmph";
    }
  };

  return (
    <AlertParametersContainer>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="speed-parameters-assets-select"
      >
        <TextFieldElement
          fullWidth
          control={form.control}
          name="parameters.speed"
          label={`The speed in ${kmphOrMph(valueUnit)}`}
          type="number"
        />
      </Grid>
    </AlertParametersContainer>
  );
};
