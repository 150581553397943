import { useMemo } from "react";
import { SelectedOrganization } from "../../../../context/reducers/selectedOrganization";
import { useAvailableOrgs } from "../../../../shared/hooks/useAvailableOrgs";
import { HierarchyNode, flattenHierarchy } from "../../../../utils";
import { getOrgsHierarchy } from "../../../ReportView/helpers/getOrgsHierarchy";

export const useOrgsDownwardHierarchy = (
  selectedOrganization: SelectedOrganization,
  fetchAssetsFromOpenSearchFeatureFlag: boolean
) => {
  const availableOrgs = useAvailableOrgs();
  const hierarchy = useMemo(
    () => getOrgsHierarchy(availableOrgs),
    [availableOrgs]
  );
  const orgs: string[] = useMemo(() => {
    const findChildrenOrgs = (
      hierarchy: HierarchyNode[]
    ): string[] | undefined => {
      for (let i = 0; i < hierarchy.length; i++) {
        const element = hierarchy[i];
        if (element.id === selectedOrganization?.value) {
          return flattenHierarchy(element.children)
            .map((child) =>
              fetchAssetsFromOpenSearchFeatureFlag ? child.id : child.name
            )
            .concat(
              fetchAssetsFromOpenSearchFeatureFlag
                ? selectedOrganization.value
                : selectedOrganization.label
            );
        }
        const result = findChildrenOrgs(element.children);
        if (result) {
          return result;
        }
      }
      return;
    };
    return findChildrenOrgs(hierarchy) ?? [];
  }, [
    fetchAssetsFromOpenSearchFeatureFlag,
    hierarchy,
    selectedOrganization.label,
    selectedOrganization.value,
  ]);
  return orgs;
};
