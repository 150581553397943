import LightbulbOutlined from "@mui/icons-material/LightbulbOutlined";
import { Box, Grid, Typography } from "@mui/material";
import { SensorStatusUppercase } from "../../../../../../graphql/operations";
import { tableSensorStatusColorsMapping } from "../../../../../AdminPanel/tabs/Sensors/sensorsUtils";
import { AssetSensorState } from "../../AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { useGetSensorStateWidget } from "../../AssetShortTooltip/hooks/useGetSensorStateWidget";

type LightStatusCardProps = {
  status: SensorStatusUppercase;
  label: string;
};

const LightStatusCard = ({ status, label }: LightStatusCardProps) => {
  const { getTireSensorChip } = useGetSensorStateWidget();

  const lightStatusIcon = getTireSensorChip(status as AssetSensorState, {
    iconWidth: "32px",
    iconHeight: "32px",
  }).icon;

  return (
    <Grid xl={4} lg={4} sm={6} xs={12} item className="grid">
      <Box className="flex justify-center ">
        <Grid
          spacing={0}
          direction={"column"}
          justify-content="center"
          alignItems={"center"}
          container
          className="text-sm w-full p-4 flex-col"
          data-testid="light-circuit-status"
        >
          <Box className="items-center !text-xl mb-3">
            <Typography className="text-card__typography ">
              <LightbulbOutlined
                sx={{
                  fontSize: "80px",
                  fill:
                    status === SensorStatusUppercase.Unknown
                      ? ""
                      : tableSensorStatusColorsMapping[
                          status as SensorStatusUppercase
                        ],
                }}
              />
            </Typography>
          </Box>
          <Box className="items-center mb-2">
            <Typography className="text-card__typography ">
              {lightStatusIcon}
            </Typography>
          </Box>
          <Box className="items-center">
            <Typography
              className="text-typography-tooltip text-center"
              sx={{
                marginBottom: "10px",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 600,
              }}
            >
              {label}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default LightStatusCard;
