import { MutableRefObject, useEffect, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../../context/AppContext";
import { useAuthContext } from "../../../../../../context/AuthContext";
import {
  AssetWithSensors,
  SensorProfileConfigType,
  ProfileConfigProperty,
} from "../../../../../../graphql/operations";
import { getRows } from "../../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../../shared/hooks/useExportedFileName";

const useSensorsTableLogic = ({
  apiRef,
  rowSelectionModel,
  sensorsData,
  sensorPropertyType,
  sensorColumns,
}: {
  apiRef: MutableRefObject<GridApiPremium>;
  rowSelectionModel: Array<number | string> | undefined;
  sensorsData: AssetWithSensors[];
  sensorPropertyType: ProfileConfigProperty | SensorProfileConfigType;
  sensorColumns: GridColDef[];
}) => {
  const {
    state: { appConfig },
  } = useAppContext();

  useEffect(() => {
    if (rowSelectionModel?.length === 1) {
      apiRef.current.setSelectionModel(rowSelectionModel);
    }
  }, [rowSelectionModel, apiRef]);

  const [tableRows, setTableRows] = useState<AssetWithSensors[]>([]);

  const { userRolePermissions } = useAuthContext();
  const canCheckTheRows = userRolePermissions?.sensors?.check || false;

  const fileName = useExportedFileName("Sensors");

  useMemo(() => {
    const mappedSensors = sensorsData ?? [];
    setTableRows(
      getRows<typeof mappedSensors>(mappedSensors, sensorColumns, "", "", "_id")
    );
    return mappedSensors;
  }, [sensorsData, sensorColumns]);
  return {
    tableRows,
    canCheckTheRows,
    fileName,
    appConfig,
  };
};

export default useSensorsTableLogic;
