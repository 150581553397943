import { useRef, useEffect } from "react";

/**
 * Use this hook to obtain a ref which you can pass onto a component to make it
 * horizontally scrollable when the user moves the scroll wheel while hovered
 * over the element.
 *
 * Do not use with conditionally rendered elements.
 */
export const useHorizontalScroll = () => {
  const elementRef = useRef<HTMLElement>();

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaY === 0) return;

        e.preventDefault();

        // Immitate horizontal scroll
        element.scrollTo({
          left: element.scrollLeft + e.deltaY,
        });
      };

      // Attach listener
      element.addEventListener("wheel", onWheel);

      // Clean up on unmount
      return () => element.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elementRef;
};
