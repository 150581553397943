import { MutationKey, UseMutationOptions } from "@tanstack/react-query";
import {
  StopAssetsShareMutation,
  StopAssetsShareMutationVariables,
  StopAssetsShareOsMutation,
  useStopAssetsShareMutation,
  useStopAssetsShareOsMutation,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";

type StopAssetsShareMutationOptions = UseMutationOptions<
  StopAssetsShareMutation | StopAssetsShareOsMutation,
  unknown,
  StopAssetsShareMutationVariables,
  MutationKey
>;

export const useStopAssetsShare = (options: StopAssetsShareMutationOptions) => {
  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const mutation = fetchAssetsFromOpenSearchFeatureFlag
    ? useStopAssetsShareOsMutation
    : useStopAssetsShareMutation;

  const { mutate, mutateAsync, isLoading } = mutation(options);

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
