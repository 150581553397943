import { uniqBy } from "lodash";
import { useOptions, UseOptionsOpts } from "./useOptions";

export const useUniqueOptions = <T = object>(
  data: Array<T>,
  value: keyof T,
  label: keyof T,
  opts?: UseOptionsOpts<T>
) => {
  const options = useOptions(data, value, label, opts);

  return uniqBy(options, (o) => o.label.trim().toLowerCase());
};
