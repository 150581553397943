import { CalendarMonthOutlined } from "@mui/icons-material";
import {
  SvgIconTypeMap,
  TextField,
  IconButtonProps,
  ThemeProvider,
} from "@mui/material";
import { OverridableComponent } from "@mui/types";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { useDateTextFieldTheme } from "./useDateTextFieldTheme";

export type DateTextFieldProps<TInputDate, TDate = TInputDate> = Omit<
  DesktopDatePickerProps<TInputDate, TDate>,
  "renderInput"
> & {
  placeholder?: string;
  fullWidth?: boolean;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  iconProps?: Partial<IconButtonProps<"button", {}>>;
};

export const DateTextField = <TInputDate, TDate = TInputDate>(
  props: DateTextFieldProps<TInputDate, TDate>
) => {
  const theme = useDateTextFieldTheme();

  const { placeholder, fullWidth, Icon, iconProps, ...datePickerProps } = props;

  return (
    <ThemeProvider theme={theme}>
      <DesktopDatePicker
        {...datePickerProps}
        autoFocus={false}
        components={{ OpenPickerIcon: Icon || CalendarMonthOutlined }}
        OpenPickerButtonProps={
          iconProps || {
            size: "small",
            sx: { fontSize: "1rem", svg: { fontSize: "inherit" } },
          }
        }
        dayOfWeekFormatter={(day) => day}
        renderInput={(params) => {
          const inputParams = { ...params };
          // empty string as value shouldn't cause validation error
          if (params.inputProps?.value === "") {
            inputParams.error = false;
          }
          return (
            <TextField
              {...inputParams}
              autoComplete="off"
              size="small"
              fullWidth={!!fullWidth}
              sx={{ alignItems: "baseline" }}
              className="!items-baseline"
              inputProps={{
                ...params.inputProps,
                placeholder: placeholder || params.inputProps?.placeholder,
              }}
            />
          );
        }}
      />
    </ThemeProvider>
  );
};
