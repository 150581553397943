const TableErrorOverlay = (error: unknown) => {
  console.error(`Table error: ${error}`, error);
  return (
    <div className="flex h-full w-full items-center justify-center">
      <span>An error occurred while loading data.</span>
    </div>
  );
};

export default TableErrorOverlay;
