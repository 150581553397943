import { HTMLAttributes, useEffect, useMemo } from "react";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { Grid, Typography } from "@mui/material";
import { omitBy } from "lodash";
import { MultiSelect } from "../../../../../../../shared/components/MultiSelect/MultiSelect";
import { useGetAssetsTags } from "../../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsTags";
import { CreatableAutocomplete } from "../../../../../TableView/components/AssetForm/components";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../../../TableView/hooks";
import { useAssetTagOptions } from "../../../../../TableView/hooks/useAssetTagOptions";
import { useGeofenceRestrictionsOptions } from "../../../../../TableView/hooks/useGeofenceRestrictionsOptions";
import { GeofenceRestrictionsQueryBuilder } from "../../../GeofenceRestrictionsQueryBuilder/GeofenceRestrictionsQueryBuilder";
import { GeofenceInitialRestrictions } from "../../useRestrinctionsForm";
import { ASSET_DOOR_TYPES, ASSET_LENGTHS } from "./helpers";

interface GeofenceRestrictionsFormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onChange" | "onSubmit"> {
  onSubmit?: (data: FieldValues) => void;
  onChange?: (
    restriction?: Partial<GeofenceInitialRestrictions> | undefined
  ) => void;
  form: UseFormReturn<Partial<GeofenceInitialRestrictions>>;
  orgId: string;
  compact?: boolean;
  cancelable?: boolean;
  showDetailsHeader?: boolean;
  disableAllFields?: boolean;
  onCancel?: () => void;
}

export const GeofenceRestrictionsForm = ({
  onSubmit,
  onChange,
  form,
  orgId,
  compact = false,
  showDetailsHeader = false,
  cancelable = true,
  onCancel,
  disableAllFields = false,
  ...props
}: GeofenceRestrictionsFormProps) => {
  const { tags, isLoading: isLoadingAssetsTags } = useGetAssetsTags({
    orgId,
  });
  const assetTagsOptions = useAssetTagOptions(tags);

  useEffect(() => {
    const values = omitBy(form.getValues(), (value) => value === "");
    if (form.formState.isDirty) {
      onChange?.(values);
    }
  }, [form, onChange, form.formState.isDirty]);

  const assetTypesOptionsData = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const rawAssetTypesOptions: string[] =
    assetTypesOptionsData.length > 0
      ? assetTypesOptionsData.map(
          (assetTypeOption) => assetTypeOption.label ?? ""
        )
      : [];

  const { assetTypesOptions, assetLengthsOptions, assetDoorTypesOptions } =
    useGeofenceRestrictionsOptions(
      rawAssetTypesOptions,
      ASSET_LENGTHS,
      ASSET_DOOR_TYPES
    );

  const breakpoints = compact ? { xs: 12 } : { xs: 12, md: 6, lg: 3, xl: 3 };

  function isSelected(
    label: string,
    allowedValues: string[] | null | undefined
  ) {
    return allowedValues?.includes(label) ?? false;
  }

  const updatedAssetTypesOptions = assetTypesOptions.map((assetTypeOption) => ({
    ...assetTypeOption,
    selected: isSelected(
      assetTypeOption.label,
      form.getValues().allowedAssetTypes
    ),
  }));

  const updatedAssetLengthsOptions = assetLengthsOptions.map(
    (assetLengthsOption) => ({
      ...assetLengthsOption,
      selected: isSelected(
        assetLengthsOption.label,
        form.getValues().allowedAssetLength
      ),
    })
  );

  const updatedAssetDoorTypesOptions = assetDoorTypesOptions.map(
    (assetDoorTypesOption) => ({
      ...assetDoorTypesOption,
      selected: isSelected(
        assetDoorTypesOption.label,
        form.getValues().allowedDoorType
      ),
    })
  );
  return (
    <form
      {...props}
      autoComplete="off"
      onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
      className="w-full"
    >
      <Grid
        container
        className={`!text-primary ${showDetailsHeader ? "!mb-4" : ""}`}
        spacing={6}
      >
        <Grid item {...breakpoints}>
          <Typography className="!text-sm !font-bold !mb-2">
            No Assets Over "X" Years Old
          </Typography>
          <TextFieldElement
            fullWidth
            disabled={disableAllFields}
            control={form.control}
            name="noAssetsOverXYearsOld"
            data-testid="configuration-restrictions-noAssetsOverXYearsOld"
          />
        </Grid>

        <Grid
          item
          {...breakpoints}
          data-testid="configuration-restrictions-allowedAssetTypes"
        >
          <Controller
            name="allowedAssetTypes"
            control={form.control}
            defaultValue={null}
            render={({ field }) => (
              <MultiSelect
                onChange={field.onChange}
                options={updatedAssetTypesOptions}
                title="Allowed Asset Types"
                selectAllOption={true}
                field={field}
              />
            )}
          />
        </Grid>

        <Grid
          item
          {...breakpoints}
          data-testid="configuration-restrictions-allowedAssetLength"
        >
          <Controller
            name="allowedAssetLength"
            control={form.control}
            defaultValue={null}
            render={({ field }) => (
              <MultiSelect
                onChange={field.onChange}
                options={updatedAssetLengthsOptions}
                title="Allowed Asset Length"
                selectAllOption={true}
                field={field}
              />
            )}
          />
        </Grid>

        <Grid
          item
          {...breakpoints}
          data-testid="configuration-restrictions-allowedDoorType"
        >
          <Controller
            name="allowedDoorType"
            control={form.control}
            defaultValue={null}
            render={({ field }) => (
              <MultiSelect
                onChange={field.onChange}
                options={updatedAssetDoorTypesOptions}
                title="Allowed Door Type"
                selectAllOption={true}
                field={field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid className="mt-8">
        <Controller
          name="allowedAssetTags"
          control={form.control}
          defaultValue={null}
          data-testid="configuration-restrictions-allowedAssetTags"
          render={({ field }) => {
            return (
              <CreatableAutocomplete
                disabled={disableAllFields}
                name="allowedAssetTags"
                label={"Allowed Asset Tags"}
                field={field}
                loading={isLoadingAssetsTags}
                errors={{}}
                options={assetTagsOptions}
              />
            );
          }}
        />
      </Grid>
      <Grid className="mt-16">
        <GeofenceRestrictionsQueryBuilder form={form} />
      </Grid>
    </form>
  );
};
