import { memo, SVGProps, FC } from "react";

interface BlindSpotProps extends SVGProps<SVGSVGElement> {}

const BlindSpot: FC<BlindSpotProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 82 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.9043 92.2773H55.0309V68.8399H26.9043V92.2773Z"
        fill="#5F7C8F"
      />
      <path
        d="M50.0616 1.02598V4.56033C50.0616 9.56051 45.9692 13.6542 40.9678 13.6542V68.28C45.9692 68.28 50.0616 72.3725 50.0616 77.3738V80.9082C68.3084 76.7699 81.9335 60.4663 81.9335 40.9671C81.9335 21.4679 68.3084 5.16426 50.0616 1.02598Z"
        fill="#9BDEED"
      />
      <path
        d="M31.8719 4.56033V1.02598C13.6263 5.16426 0 21.4691 0 40.9683C0 60.4663 13.6263 76.7711 31.8719 80.9082V77.3751C31.8719 72.3725 35.9656 68.2812 40.9681 68.2812V13.6542C35.9656 13.6542 31.8719 9.56051 31.8719 4.56033Z"
        fill="#5F7C8F"
      />
      <path
        d="M40.9674 13.6543C45.9687 13.6543 50.0612 9.56062 50.0612 4.56044V1.02609C47.1344 0.360683 44.0931 0.000256538 40.9674 0.000256538C37.838 0.000256538 34.7991 0.360683 31.8711 1.02609V4.56044C31.8711 9.56062 35.9648 13.6543 40.9674 13.6543Z"
        fill="#759AA9"
      />
      <path
        d="M40.9674 68.2788C35.9648 68.2788 31.8711 72.3701 31.8711 77.3727V80.907C34.7991 81.57 37.838 81.9316 40.9674 81.9316C44.0931 81.9316 47.1344 81.57 50.0612 80.907V77.3727C50.0612 72.3701 45.9687 68.2788 40.9674 68.2788Z"
        fill="#759AA9"
      />
      <path
        d="M63.4357 40.9666C63.4357 53.3742 53.3751 63.4336 40.9675 63.4336C28.5563 63.4336 18.498 53.3742 18.498 40.9666C18.498 28.5578 28.5563 18.4971 40.9675 18.4971C53.3751 18.4971 63.4357 28.5578 63.4357 40.9666Z"
        fill="#434D5A"
      />
      <path
        d="M60.2265 40.9677C60.2265 51.6033 51.6039 60.2246 40.9683 60.2246C30.3315 60.2246 21.709 51.6033 21.709 40.9677C21.709 30.3297 30.3315 21.7083 40.9683 21.7083C51.6039 21.7083 60.2265 30.3297 60.2265 40.9677Z"
        fill="#C6C5C3"
      />
      <path
        d="M53.0036 40.9656C53.0036 47.6124 47.614 53.002 40.9684 53.002C34.3192 53.002 28.9297 47.6124 28.9297 40.9656C28.9297 34.3164 34.3192 28.9293 40.9684 28.9293C47.614 28.9293 53.0036 34.3164 53.0036 40.9656Z"
        fill="white"
      />
      <g opacity="0.360001">
        <path
          d="M40.5416 37.3348C40.5416 39.3407 38.9167 40.9668 36.9108 40.9668C34.9074 40.9668 33.2812 39.3407 33.2812 37.3348C33.2812 35.3302 34.9074 33.7052 36.9108 33.7052C38.9167 33.7052 40.5416 35.3302 40.5416 37.3348Z"
          fill="#E4E4E3"
        />
      </g>
      <path
        d="M44.1971 6.82506C44.1971 8.59947 42.7626 10.0352 40.9894 10.0352C39.2162 10.0352 37.7793 8.59947 37.7793 6.82506C37.7793 5.05306 39.2162 3.61377 40.9894 3.61377C42.7626 3.61377 44.1971 5.05306 44.1971 6.82506Z"
        fill="#535355"
      />
      <path
        d="M42.5939 6.82599C42.5939 7.7132 41.8742 8.43164 40.9894 8.43164C40.1022 8.43164 39.3838 7.7132 39.3838 6.82599C39.3838 5.93879 40.1022 5.22034 40.9894 5.22034C41.8742 5.22034 42.5939 5.93879 42.5939 6.82599Z"
        fill="white"
      />
      <path
        d="M64.4935 92.2767H17.4379C13.1911 92.2767 9.71582 95.7508 9.71582 100H72.2168C72.2168 95.7508 68.7403 92.2767 64.4935 92.2767Z"
        fill="#9BDEED"
      />
    </svg>
  );
};

export default memo(BlindSpot);
