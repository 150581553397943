import { FC, ReactElement, useCallback, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Box } from "@mui/system";

interface AccordionWrapperProps {
  showDetailsHeader: boolean;
  accordionSummaryTitle?: string;
  children: ReactElement;
  classNames?: string;
  isDashboardSettings?: boolean;
}

export const AccordionWrapper: FC<AccordionWrapperProps> = ({
  showDetailsHeader,
  accordionSummaryTitle,
  children,
  classNames = "",
  isDashboardSettings = false,
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const handleAccordionChange = useCallback(() => {
    setIsAccordionExpanded(!isAccordionExpanded);
  }, [isAccordionExpanded, setIsAccordionExpanded]);

  if (!showDetailsHeader && !isDashboardSettings) {
    return children as ReactElement;
  }

  return (
    <Accordion
      className="w-full"
      expanded={isAccordionExpanded}
      onChange={handleAccordionChange}
      data-testid="asset-settings-accordion"
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        className={
          isDashboardSettings
            ? "!p-0"
            : "assetSettingsAccordionSummaryTitleContainer"
        }
        classes={
          isDashboardSettings
            ? {}
            : { content: "assetSettingsAccordionSummaryTitle" }
        }
        data-testid="asset-settings-accordion-content"
      >
        {accordionSummaryTitle ?? ""}
      </AccordionSummary>

      {isDashboardSettings ? (
        <Box className="px-6">
          <AccordionDetails className={classNames ?? ""}>
            {children}
          </AccordionDetails>
        </Box>
      ) : (
        <AccordionDetails className={classNames ?? ""}>
          {children}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
