import { FC } from "react";
import { Grid } from "@mui/material";
import classNames from "classnames";
import useBreakpoint from "../../../../../../../shared/hooks/useBreakpoint";
import { useInternalCameraSensorContext } from "../context";
import { MediaActivityItem, InternalCameraView } from "../interface";
import CarouselItemImage from "./CarouselItemImage";

export interface InternalCameraStatusCarouselItemProps {
  items: MediaActivityItem[];
}

const CarouselItem: FC<InternalCameraStatusCarouselItemProps> = ({ items }) => {
  const isMobile = useBreakpoint("down", "sm");
  const { activeView } = useInternalCameraSensorContext();

  // Calculate the number of columns and the spacing between them, based on the active view (one image, four images, etc.)
  const columns = activeView > 2 ? Math.ceil(activeView / 2) : activeView;
  const spacing = activeView === InternalCameraView.OneImage ? 0 : 1;

  return (
    <Grid
      container
      columns={columns}
      spacing={spacing}
      data-testid="internal-camera-status--item"
      className={classNames({
        "aspect-square": isMobile,
      })}
    >
      {items.map((item, index) => (
        <Grid item xs={1} key={`internal-camera-status--item-${index}`}>
          <CarouselItemImage item={item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CarouselItem;
