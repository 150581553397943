export enum ProfilesSideMenuOptions {
  Battery = "Battery",
  TPMS = "TPMS",
  AirBag = "Air Bag",
  AirSupply = "Air Supply",
  Camera = "Camera",
  Temperature = "Temperature",
  WheelEnd = "Wheel End",
  ATIS = "ATIS",
  LightCircuit = "Light Circuit",
  Liftgate = "Liftgate",
}
