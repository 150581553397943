import { FC, ReactElement } from "react";
import classNames from "classnames";

export interface WithAsteriskProps {
  children: ReactElement;
  enable?: boolean;
  className?: string;
}
const WithAsterisk: FC<WithAsteriskProps> = (props) => {
  const { children, enable = true, className } = props;

  return enable ? (
    <div className={classNames("flex items-center", className)}>
      {children}
      <span className="block ml-1 text-error" data-testid="asterisk">
        *
      </span>
    </div>
  ) : (
    children
  );
};

export default WithAsterisk;
