import { isEmpty } from "lodash";

export const parsePhoneNumber = (
  phoneNumber: string,
  addCountryCode: boolean = false
) => {
  if (isEmpty(phoneNumber)) {
    return "";
  }
  const number = phoneNumber.replace(/-/g, "").trim();

  return isEmpty(number) ? "" : addCountryCode ? `+1${number}` : number;
};

export const formatPhoneNumber = (
  phoneNumber: string,
  removeCountryCode: boolean = false
) => {
  if (removeCountryCode) phoneNumber = phoneNumber.replace(/^\+1/, "");
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

export const formatEmptySpace = (phoneNumber: string) => {
  return phoneNumber.replace(/^\+1/, "").replace(/\D/g, "");
};

export const removeEmptySpaces = (input: string): string =>
  input?.replace(/\s+/g, "");

export const validatePhoneNumber = (phoneNumber: string) => {
  const regex = /^\+\d{5,18}$/;
  return regex.exec(phoneNumber);
};
