import { useMemo } from "react";
import { startCase } from "lodash";
import { useGetRegionsData } from "../../views/AdminPanel/tabs/Regions/hooks/useGetRegionsData";

export type RegionListElement = {
  _id: string;
  name: string;
  country: string;
  value: string[];
};

export type RegionsListHookResult = {
  regions: RegionListElement[];
  isLoading: boolean;
};

export const useRegionsList = (): RegionsListHookResult => {
  const { regions, isLoading: isRegionsLoading } = useGetRegionsData();

  const regionsList = useMemo(() => {
    const groupedRegions = [
      ...regions.canada.data,
      ...regions.unitedStates.data,
      ...regions.mexico.data,
    ];

    return (
      groupedRegions?.map((region) => {
        return {
          _id: region?.id,
          name: region?.name,
          country: startCase(region.country),
          value: region.territories as string[],
        };
      }) ?? []
    );
  }, [regions]);

  return {
    regions: regionsList,
    isLoading: isRegionsLoading,
  };
};
