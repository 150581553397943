import React, { FC, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Chip, ThemeProvider } from "@mui/material";
import { useExternalChipSelectTheme } from "../react-hook-form-mui/RolesSelectElement/useExternalChipSelectTheme";

export type ChipOption = {
  value: string;
  label: string;
  showWarning?: boolean;
};
export interface ChipsListProps {
  chips: ChipOption[];
  selectedChips: string[];
  handleDelete?: (chipToDelete: string) => void;
  loading?: boolean;
  compact?: boolean;
  icon?: React.ReactNode;
}
export const ChipsList: FC<ChipsListProps> = ({
  chips,
  selectedChips,
  handleDelete,
  loading = false,
  compact = false,
  icon,
}: ChipsListProps) => {
  const [isListExpanded, setIsListExpanded] = useState<boolean>(false);
  const chipsTheme = useExternalChipSelectTheme();

  const chipsToShow = isListExpanded
    ? selectedChips
    : selectedChips.slice(0, 7);
  const buttonText = isListExpanded
    ? "Less"
    : selectedChips.length > 7
    ? `+${selectedChips.length - 7} more`
    : "";
  const shouldShowButton = selectedChips.length > 7;

  const handleDeleteChip = (chipToDelete: string) => {
    if (handleDelete) {
      handleDelete(chipToDelete);
    }
  };

  return (
    <ThemeProvider theme={chipsTheme}>
      <div
        className={`${compact ? "py-1" : "py-8"} flex items-baseline flex-wrap`}
      >
        {chipsToShow.map((chipValue: string) => {
          const chip = chips.find(({ value }) => value === chipValue);
          const { label = chipValue, showWarning = false } = chip ?? {};
          return (
            <Chip
              className="!mb-2 !mr-2"
              key={chipValue}
              label={
                loading ? (
                  "loading"
                ) : (
                  <span className="flex">
                    {label}
                    {icon && showWarning ? icon : null}
                  </span>
                )
              }
              onDelete={
                handleDelete ? () => handleDeleteChip(chipValue) : undefined
              }
              deleteIcon={<ClearIcon />}
            />
          );
        })}
        {shouldShowButton && (
          <button
            className="text-brand font-bold text-sm"
            type="button"
            data-testid="expand-chips-list-button"
            onClick={() => setIsListExpanded(!isListExpanded)}
          >
            {buttonText}
          </button>
        )}
      </div>
    </ThemeProvider>
  );
};
