import { useMemo } from "react";
import { startCase } from "lodash";
import { useGetZonesData } from "../../../../AdminPanel/tabs/Zones/hooks/useGetZonesData";
import { ZoneFilterOption } from "../../AssetsDataContext";

export interface ZonesFilterOptions {
  zonesOptions: ZoneFilterOption[];
  isLoadingZones: boolean;
}

export const useZonesFilterOptions = (): ZonesFilterOptions => {
  const { zones, isLoading: isLoadingZones } = useGetZonesData();

  const zonesOptions = useMemo(() => {
    const { canada, unitedStates, mexico } = zones;

    const allZones = [
      /* 
        UI for canada zones doesn't allow for spaces but
        assets in OS have a space at index 3 
        (remove this processing if changed)
      */
      ...canada.data.map((zone) => ({
        ...zone,
        matches: zone.matches.map(
          (match) => `${match.slice(0, 3)} ${match.slice(3)}`
        ),
      })),
      ...unitedStates.data,
      ...mexico.data,
    ];

    return allZones.map(
      (zone): ZoneFilterOption => ({
        id: zone.id,
        label: zone.name,
        country: startCase(zone.country),
        ranges: zone.ranges,
        matches: zone.matches,
      })
    );
  }, [zones]);

  return { zonesOptions, isLoadingZones };
};
