import * as yup from "yup";
import { BATTERY_FORM_FIELDS } from "../../sensorsUtils";

export const sensorsBatteriesSchema = yup.object().shape({
  typeOfAssignment: yup.string().required("Field is required!").nullable(),
  batterySensorProfile: yup
    .string()
    .nullable()
    .when("typeOfAssignment", {
      is: (value: string) => {
        return value === BATTERY_FORM_FIELDS[0].options[1].id;
      },
      then: yup.string().required("Field is required!").nullable(),
      otherwise: yup.string().nullable().notRequired(),
    }),
});
