import * as yup from "yup";
import { AIR_SUPPLY_FORM_FIELDS } from "../../sensorsUtils";

const commonLabelValueSchema = yup
  .string()
  .required("Field is required!")
  .nullable();

export const sensorsAirSupplySchema = yup.object().shape({
  typeOfAssignment: commonLabelValueSchema,
  airSupplySensorProfile: yup
    .string()
    .nullable()
    .when("typeOfAssignment", {
      is: (value: string) => {
        return value === AIR_SUPPLY_FORM_FIELDS[0].options[1].id;
      },
      then: commonLabelValueSchema,
      otherwise: yup.string().nullable().notRequired(),
    }),
});
