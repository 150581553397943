import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { DeviceData } from "../../../../graphql/operations";
import { AutomationInitialValues } from "../../../AdminPanel/tabs/Automations/hooks/useAutomationForm";

type DeviceFromValues = {
  customer_orgs_id: string | null;
  imei: string | null;
  box_id: string | null;
  order_num: string | null;
  sim_num: string | null;
  packing_list: string | null;
};

export const useDeviceSettingsForm = (
  initialValues: Partial<DeviceData> = {
    customer_orgs_id: null,
    imei: null,
    box_id: null,
    order_num: null,
    sim_num: null,
    packing_list: null,
  }
) => {
  const schema = yup.object().shape({
    customer_orgs_id: yup.string().required("required").nullable(),
    imei: yup
      .string()
      .required("required")
      .matches(/^[0-9]*$/, { message: "Invalid" })
      .min(14, "should be min 14 and max 16 characters length")
      .max(16, "should be min 14 and max 16 characters length"),
    box_id: yup.string().notRequired(),
    order_num: yup.string().required("Required"),
    sim_num: yup.string().notRequired(),
    packing_list: yup.string().notRequired(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    values: omitBy(initialValues, isNil),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { schema, form, getValues };
};
