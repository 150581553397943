import { GridColDef, GridColType } from "@mui/x-data-grid/models";
import { FileFormat, TableColumnFormat } from "../../../graphql/operations";

export type TableGridData = { id: string };

export type TableGridColDef<T extends TableGridData> = GridColDef<T> & {
  format: TableColumnFormat;
  type: GridColType;
  headerName: string;
};

export type ServerSideExportFormat = FileFormat | "EMAIL";

export enum ServerSideExport {
  EXEL = "EXEL",
  CSV = "CSV",
  PDF = "PDF",
  EMAIL = "EMAIL",
}

export enum StringTableFilterValue {
  Contains = "contains",
  DoesNotContain = "doesNotContain",
  IsNotEqual = "isNotEqual",
  Equals = "equals",
  StartsWith = "startsWith",
  EndsWith = "endsWith",
  IsEmpty = "isEmpty",
  IsNotEmpty = "isNotEmpty",
  IsAnyOf = "isAnyOf",
}

export enum SingleSelectTableFilterValue {
  Is = "is",
  Not = "not",
  IsAnyOf = "isAnyOf",
}
