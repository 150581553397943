import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import { HorizontalBarChart } from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type DashboardWidgetLoadedAssetsWidgetData = {
  assets: DashboardWidgetLoadedAssetsWidgetAssetData;
  total: number;
};

export type DashboardWidgetLoadedAssetsWidgetAssetData = {
  loaded: number;
  empty: number;
  loadedPercentage: number;
  emptyPercentage: number;
};

export interface DashboardWidgetLoadedAssetsWidget
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetLoadedAssetsWidgetData;
}

export interface LoadedAssetsWidgetProps {
  widget: DashboardWidgetLoadedAssetsWidget;
}

export const LoadedAssetsWidget: React.FC<LoadedAssetsWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "Loaded Assets";

  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const { loaded, empty, loadedPercentage, emptyPercentage, total } =
    useMemo(() => {
      const parsedData: DashboardWidgetLoadedAssetsWidgetData = JSON.parse(
        getDashboardWidgetData?.getDashboardWidget?.data ?? null
      );

      if (!parsedData) {
        return {
          loaded: 0,
          empty: 0,
          loadedPercentage: 0,
          emptyPercentage: 0,
          total: 0,
        };
      }
      const total = parsedData?.total ?? 0;
      const { loaded, empty, loadedPercentage, emptyPercentage } =
        parsedData.assets;

      return { loaded, empty, loadedPercentage, emptyPercentage, total };
    }, [getDashboardWidgetData]);

  const verticalBarData = [
    {
      name: "Empty Assets In Geofence",
      value: empty,
      percent: emptyPercentage,
    },
    {
      name: "Loaded Assets In Geofence",
      value: loaded,
      percent: loadedPercentage,
      color: "var(--chart-secondary-bar-style)",
    },
  ];

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--loaded-assets"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the loaded assets
          widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          data={verticalBarData}
          title={title}
          totalRecords={total}
          isLoading={isLoading}
          showPercentages
          showTotal
          subtitle="Number Of Assets In Geofence"
          showXAxis={false}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
