import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import { DashboardLibraryWidget } from "../../../graphql/operations";

export interface DashboardWidgetCardProps {
  dashboardWidget: DashboardLibraryWidget;
  onAddWidget: (widget: DashboardLibraryWidget) => void;
  disabled: boolean;
}

export const DashboardWidgetCard = (props: DashboardWidgetCardProps) => {
  const { dashboardWidget, onAddWidget, disabled } = props;

  const handleAddWidget = () => {
    onAddWidget(dashboardWidget);
  };
  return (
    <Box
      sx={{ borderBottom: "1px solid #DFDFDF" }}
      className="p-5 "
      data-testid="dashboard-widget-card"
    >
      <Box className="mb-2" data-testid="dashboard-widget-card-header">
        <Typography className="!font-bold"> {dashboardWidget.name}</Typography>
      </Box>
      <Box className="mb-2" data-testid="dashboard-widget-card-content">
        <Typography
          sx={{ color: "var(--dashboard-add-widget-card-description)" }}
        >
          {dashboardWidget.description}
        </Typography>
      </Box>
      <Box className="mb-2" data-testid="dashboard-widget-card-tags">
        {dashboardWidget?.tags?.map((tag) => (
          <Box
            className="inline-block	rounded-md px-3 py-1 mr-2"
            sx={{
              color: "var(--dashboard-add-widget-card-tags-text)",
              backgroundColor: "var(--concrete)",
            }}
          >
            {tag?.name}
          </Box>
        ))}
      </Box>
      <Box data-testid="dashboard-widget-card-actions">
        <Button
          sx={{
            color: `${disabled ? "var(--concrete)" : "var(--brand)"}`,
            textTransform: "none",
            fontSize: "14px",
            fontWeight: "700",
          }}
          data-testid="add-widget-button"
          disabled={disabled}
          variant="text"
          type="button"
          onClick={handleAddWidget}
        >
          <AddIcon />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "700",
              color: `${
                disabled ? "var(--concrete)" : "var(--brand)"
              } !important`,
            }}
          >
            Add New Widget
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
