import { useState } from "react";
import ChecklistIcon from "@mui/icons-material/Checklist";
import InsertChartIconOutlined from "@mui/icons-material/InsertChartOutlined";
import { CircularProgress, Grid } from "@mui/material";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  Ms2Command,
  useSendCommandToMs2Mutation,
} from "../../../../../graphql/operations";
import DeviceCommandButton from "../../../../../shared/ms2/components/DeviceCommandButton";
import {
  getCommandCode,
  parseMs2Error,
  parseMs2Response,
} from "../../../../../shared/ms2/utils";

export interface DeviceCommandButtonsProps {
  deviceId?: string;
}

const DeviceCommandButtons: React.FC<DeviceCommandButtonsProps> = ({
  deviceId,
}) => {
  const { dispatch } = useAppContext();

  const [isPrecheckLoading, setIsPrecheckLoading] = useState<boolean>(false);
  const [isReportNowLoading, setIsReportNowLoading] = useState<boolean>(false);

  const { mutate } = useSendCommandToMs2Mutation({
    onSuccess(data, variables) {
      setIsPrecheckLoading(false);
      setIsReportNowLoading(false);

      const commandCode = getCommandCode(variables.input.command);

      if (data.sendCommandToMS2?.error) {
        const { title, text } = parseMs2Error(
          data.sendCommandToMS2,
          commandCode
        );

        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            severity: "error",
            title,
            text,
          },
        });
      } else {
        const ms2ParsedResponse = parseMs2Response(
          data.sendCommandToMS2,
          commandCode
        );

        dispatch({
          type: PAGE_SNACKBAR,
          payload: ms2ParsedResponse,
        });
      }
    },

    onError() {
      setIsPrecheckLoading(false);
      setIsReportNowLoading(false);

      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          severity: "error",
          title: "Failed to send device command",
          text: "Something went wrong",
        },
      });
    },
  });

  const handlePrecheckClick = () => {
    setIsPrecheckLoading(true);
    mutate({
      input: {
        command: Ms2Command.DevicePrecheck,
        deviceId: deviceId ?? "",
      },
    });
  };

  const handleReportNowClick = () => {
    setIsReportNowLoading(true);

    mutate({
      input: {
        command: Ms2Command.DeviceReportNow,
        deviceId: deviceId ?? "",
      },
    });
  };

  return (
    <Grid container spacing={1} data-testid="device-commands-buttons-container">
      <Grid item>
        <DeviceCommandButton
          tooltipTitle="Precheck"
          isLoading={isPrecheckLoading}
          onClick={handlePrecheckClick}
          dataTestId="precheck-command-btn"
        >
          {isPrecheckLoading ? (
            <CircularProgress
              size={20}
              data-testid="precheck-progress-loader"
            />
          ) : (
            <ChecklistIcon className="text-white" />
          )}
        </DeviceCommandButton>
      </Grid>
      <Grid item>
        <DeviceCommandButton
          tooltipTitle="Report Now"
          isLoading={isReportNowLoading}
          onClick={handleReportNowClick}
          dataTestId="report-now-command-btn"
        >
          {isReportNowLoading ? (
            <CircularProgress
              size={20}
              data-testid="report-now-progress-loader"
            />
          ) : (
            <InsertChartIconOutlined className="text-white" />
          )}
        </DeviceCommandButton>
      </Grid>
    </Grid>
  );
};

export default DeviceCommandButtons;
