import { FC, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  GridValueFormatterParams,
  useGridApiRef,
  GridCellParams,
} from "@mui/x-data-grid-premium";
import { GridColDef } from "@mui/x-data-grid/models";
import { SET_BREADCRUMBS_CURRENT_PAGE_RANGE } from "../../../../../../constants";
import { EventHistoryEventNameUi } from "../../../../../../constants/map";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  HistoricalEventHistory,
  TableViewType,
} from "../../../../../../graphql/operations";
import {
  Table,
  TableDataModes,
  getRows,
} from "../../../../../../shared/components/Table";
import { usePreferredTimezone } from "../../../../../../shared/hooks/usePreferredTimezone";
import {
  DATE_TIME_FORMAT,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../../../../../utils";
import { AssetIcon } from "../../../../TableView/components/AssetIcon";
import { useBreadcrumbsContext } from "../context/BreadCrumbsContext";

interface ExtendedSearchEventHistoryInputResultData
  extends HistoricalEventHistory {
  "breadcrumbs.eventName": string;
  icon: Record<string, any>;
}

export interface BreadcrumbsTableProps {
  totalTableRowsCount: number;
  isLoading: boolean;
  eventHistory: HistoricalEventHistory[];
}

const BreadcrumbsTable: FC<BreadcrumbsTableProps> = ({
  eventHistory,
  isLoading,
  totalTableRowsCount,
}) => {
  const { state } = useAppContext();
  const { breadcrumbsHistoryEventsPerPage } = state.appConfig.table;
  const gridApiRef = useGridApiRef();
  const {
    state: {
      breadcrumbs: { selectedMapMarkerID, selectedFilters },
    },
    dispatch,
  } = useBreadcrumbsContext();

  const timezone = usePreferredTimezone();

  const [rows, setRows] = useState<any[]>([]);

  const onPageChange = useCallback(
    (page: number) => {
      dispatch({
        type: SET_BREADCRUMBS_CURRENT_PAGE_RANGE,
        payload: breadcrumbsHistoryEventsPerPage * (page - 1),
      });
    },
    [dispatch, breadcrumbsHistoryEventsPerPage]
  );

  useEffect(() => {
    if (eventHistory) {
      const mappedEvents = eventHistory.map((event) => {
        const formattedDate =
          event.date &&
          formatDateInTimezone(
            event.date,
            DATE_TIME_FORMAT,
            mapTimezoneToIANAValue(timezone)
          );

        return {
          ...event,
          eventDate: formattedDate || event.date,
          icon: {
            tripState: event?.breadcrumbs?.tripState,
            dwellTime: event?.breadcrumbs?.dwellTime,
            cargoStatus: event?.breadcrumbs?.cargoState,
          },
        };
      });

      setRows(getRows(mappedEvents, columns, "", "", "id"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventHistory, timezone]);

  const capitalizeFirstLetter = (string: string) =>
    string ? string.charAt(0).toUpperCase() + string.slice(1) : "";

  const columns: GridColDef<ExtendedSearchEventHistoryInputResultData>[] = [
    {
      field: "date",
      headerName: "Time",
      flex: 1,
      minWidth: 210,
      sortable: false,
    },
    {
      field: "breadcrumbs.eventName",
      headerName: "Event Name",
      flex: 1,
      minWidth: 200,
      sortable: false,
      valueGetter: ({ row }) => {
        return (
          row["breadcrumbs.eventName"] ?? EventHistoryEventNameUi.UnknownEvent
        );
      },
    },
    {
      field: "breadcrumbs.primaryPower",
      headerName: "Primary Voltage",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value?.toFixed(2),
      cellClassName: (params: GridCellParams<number>) => {
        let primaryVoltage = params.row.primaryPower ?? 0;
        let speed = params.row.speed ?? 0;

        if (primaryVoltage >= 10.5 && speed > 5) {
          // Moving with Primary Voltage
          return "blue-cell";
        } else if (primaryVoltage < 10.5 && speed > 5) {
          //Moving without Primary Voltage
          return "green-cell";
        } else return "";
      },
      sortable: false,
    },
    {
      field: "breadcrumbs.speed",
      headerName: "Speed",
      flex: 1,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "breadcrumbs.city",
      headerName: "City",
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "breadcrumbs.state",
      headerName: "State",
      flex: 1,
      minWidth: 100,
      sortable: false,
    },
    {
      field: "icon",
      headerName: "Status",
      minWidth: 120,
      groupable: false,
      sortable: false,
      renderCell: (params: any) => {
        const { icon } = params.row;
        const cargoState = capitalizeFirstLetter(icon?.cargoStatus);
        const tripState = capitalizeFirstLetter(icon?.tripState);
        return (
          <AssetIcon
            dwellingDays={Number(icon?.dwellTime)}
            cargo_state={cargoState}
            trip_st={tripState}
            customColor="var(--primary-blue)"
          />
        );
      },
      disableExport: true,
      filterable: false,
    },
  ];

  if (
    typeof gridApiRef.current.getSortedRows !== "undefined" &&
    selectedMapMarkerID
  ) {
    gridApiRef.current.selectRow(selectedMapMarkerID, true, true);
    gridApiRef.current.scrollToIndexes({ rowIndex: selectedMapMarkerID });
  }

  useEffect(() => {
    // update table pagination when new filter is applied.
    dispatch({
      type: SET_BREADCRUMBS_CURRENT_PAGE_RANGE,
      payload: 0,
    });

    // Workaround until we find way to use gridApiRef.current.setPage method when server side paging is used on MUI Premium Grid
    // TODO: Check for other solution
    const paginationPageOneButton = document.querySelector(
      '[aria-label="Go to page 1"]'
    ) as HTMLElement;

    if (paginationPageOneButton) {
      paginationPageOneButton?.click();
    }
  }, [dispatch, gridApiRef, selectedFilters]);

  return (
    <Box
      className="flex justify-center bg-dashboard_subheader__bg flex h-full w-full rounded-lg"
      sx={{
        "& .green-cell": {
          backgroundColor:
            "var(--breadcrumbs-table-moving-without-primary-voltage)",
        },
        "& .blue-cell": {
          backgroundColor:
            "var(--breadcrumbs-table-moving-with-primary-voltage)",
        },
      }}
    >
      <Table
        tableType={TableViewType.Breadcrumbs}
        showToolbar
        rows={rows ?? []}
        apiRef={gridApiRef}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "eventDate", sort: "desc" }],
          },
        }}
        disableColumnFilter={true}
        loading={isLoading}
        handleDataMode={TableDataModes.Client} // TODO: PRJIND-9769 Change to Server, once backend pagination is implemented
        onPageChange={onPageChange}
        onPaginationModelChange={onPageChange}
        pageSize={breadcrumbsHistoryEventsPerPage}
        rowCount={totalTableRowsCount}
        tableName="breadcrumbs"
        tableMinHeight="100%"
        isDataRefetching={false}
      />
    </Box>
  );
};

export default BreadcrumbsTable;
