import { FILTER_OPERATOR_VALUE } from "../constants/map";
import { FiltersInput, FilterListInput } from "../graphql/operations";
import { transformColumnField } from "../views/ReportView/helpers/helpers";

export const validateTableFilters = (value: {
  items: FiltersInput[];
  linkOperator: string;
}): FilterListInput | null | undefined => {
  if (!value?.items.length) {
    return null;
  }
  const filtersValid = isTableFiltersValid(value.items);

  if (filtersValid) {
    const filterItems: FiltersInput[] = value.items.map((i) => ({
      ...i,
      value: JSON.stringify(i?.value || []),
      columnField: transformColumnField(i.columnField),
    }));
    return { filters: filterItems, linkOperator: value.linkOperator };
  }

  const filtersEmpty = isTableFiltersEmpty(value.items);
  if (filtersEmpty) {
    return null;
  }
};

export const isTableFiltersValid = (items: FiltersInput[]): boolean => {
  return items.every((item) => {
    if (
      item.operatorValue === FILTER_OPERATOR_VALUE.IsEmpty ||
      item.operatorValue === FILTER_OPERATOR_VALUE.IsNotEmpty
    ) {
      return true;
    }
    return item.value;
  });
};

export const isTableFiltersEmpty = (items: FiltersInput[]): boolean => {
  return items.every(
    (item) =>
      item.operatorValue !== FILTER_OPERATOR_VALUE.IsEmpty &&
      item.operatorValue !== FILTER_OPERATOR_VALUE.IsNotEmpty &&
      !item.value
  );
};
