import React, { PropsWithChildren } from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

export type BaseDashboardWidgetProps = PropsWithChildren & {
  title: string;
  // Control the visibility of the "More Options" button with the dropdown menu
  onWidgetDelete?: () => void;
  dateRange: string;
};

export const GeofenceBaseChartWidget: React.FC<BaseDashboardWidgetProps> = ({
  title,
  dateRange,
  children,
}) => {
  return (
    <Card
      data-testid="dashboard-widget--card"
      className={`background-white border items-center flex flex-col`}
      sx={{
        boxShadow: "none",
        width: "100%",
        borderRadius: "8px",
        borderColor: "var(--asset-card-border)",
      }}
    >
      <CardMedia className="w-full">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2">{dateRange}</Typography>
        </Box>
      </CardMedia>
      <CardContent className="w-full">{children}</CardContent>
    </Card>
  );
};
