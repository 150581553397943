import { useEffect } from "react";
import { useAuthContext } from "../../context/AuthContext";
import initializePendoWithUser from "../../services/pendo/initializePendoWithUser";
import { useCurrentOrg } from "./useCurrentOrg";
import { useUserData } from "./useUserData";

interface PendoHook {}

const usePendo = (): PendoHook => {
  const { decodedToken } = useAuthContext();
  const organization = useCurrentOrg();
  const user = useUserData();

  useEffect(() => {
    if (user && organization) {
      initializePendoWithUser(user, organization, decodedToken?.impersonation);
    }
  }, [user, organization, decodedToken]);

  return {};
};

export default usePendo;
