import { ChangeEvent, FC, memo } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

export type CheckBoxWithLabelProps = {
  name: string;
  label: string;
  checked: boolean;
  onValueChange: (name: string, value: boolean) => void;
  dataTestId: string;
};

export const CheckBoxWithLabel: FC<CheckBoxWithLabelProps> = memo(
  ({
    name,
    label,
    checked,
    onValueChange,
    dataTestId,
  }: CheckBoxWithLabelProps) => {
    const handleClick = (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      onValueChange(e.target.name, e.target.checked);
    };

    return (
      <Box className="flex items-center w-full">
        <FormControlLabel
          className="!ml-0 !block w-full"
          data-testid={dataTestId}
          control={
            <Checkbox
              className="!text-brand"
              onChange={handleClick}
              checked={checked}
              name={name}
              icon={
                <CheckBoxOutlineBlankIcon
                  className="!text-checkbox-unchecked"
                  fontSize="small"
                />
              }
              checkedIcon={
                <CheckBoxIcon className="!text-brand" fontSize="small" />
              }
            />
          }
          label={label}
        />
      </Box>
    );
  }
);
