import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { ColorsPalette } from "../../../design-system/colors-palette";
import { commonAutocompleteStyles } from "../../../views/AlertView/hooks/useAlertTheme";
import { useCurrentTheme } from "../../hooks/theme/useCurrentTheme";

export const useQueryBuilderTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      palette: {
        primary: {
          main: ColorsPalette.PrimaryBlue,
          light: ColorsPalette.PrimaryBlue,
          dark: ColorsPalette.PrimaryBlue,
        },
        secondary: {
          main: ColorsPalette.LightCharcoal,
        },
      },
      typography: {
        button: {
          textTransform: "capitalize",
          color: "var(--brand)",
        },
        body1: {
          color: "var(--typography-secondary)",
        },
      },
      components: {
        MuiButtonBase: {
          styleOverrides: {
            root: {
              color: "var(--brand)",
              ".MuiSvgIcon-root": {
                color: "var(--query-builder-text-color)",
              },
              "&.MuiButton-textPrimary": {
                color: "var(--brand)!important",
              },
              ".MuiButton-startIcon .MuiSvgIcon-root": {
                color: "var(--brand)",
              },
              "&.MuiButton-text.MuiButton-textNeutral": {
                color: "var(--brand)",
              },
            },
          },
        },
        MuiButtonGroup: {
          styleOverrides: {
            root: {
              ".MuiButtonBase-root": {
                backgroundColor: "transparent",
                color: "var(--typography-secondary)",
              },
              ".MuiButton-contained": {
                border: "none !important",
                borderRadius: "4px!important",
                marginRight: "2px",
              },
              ".MuiButton-containedPrimary, .MuiButton-containedSecondary": {
                backgroundColor: "var(--brand)!important",
                color: "var(--white)",
                "&:hover": {
                  background: "var(--brand)!important",
                },
              },
              ".MuiButton-containedError": {
                color: "var(--typography-secondary)",
                backgroundColor: "var(--error)",
              },
            },
            content: {
              color: "var(--brand)",
            },
          },
        },
        ...commonAutocompleteStyles,
        MuiMenu: {
          styleOverrides: {
            list: {
              backgroundColor: "var(--dashboard_subheader__bg) !important",
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "var(--brand-transparent)",
                textDecoration: "none !important",
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              "&.MuiInputBase-root": {
                color: "var(--typography)",
              },
              "&.MuiInput-underline:before": {
                borderBottom: "1px solid var(--input-border)",
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                borderBottom: "1px solid var(--input-border-hover) !important",
              },
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              color: "var(--query-builder-text-color)",
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              width: "260px !important",
            },
            paper: {
              backgroundColor: "var(--secondary) !important",
              backgroundImage:
                "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)) !important",
            },
          },
        },
        MuiListSubheader: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--dashboard_subheader__bg) !important",
              color: "var(--typography) !important",
            },
          },
        },
        MuiDayPicker: {
          styleOverrides: {
            header: {
              color: "var(--typography)",
            },
            weekDayLabel: {
              color: "var(--typography)",
            },
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            root: {
              color: "var(--typography)",
            },
            today: {
              border: "1px solid var(--input-border) !important",
            },
          },
        },
      },
    })
  );
};
