import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { commonInitialRemindersValues, getAlertCommonSchema } from "../utils";

export const useAlertCustomForm = (
  initialValues = {
    name: "",
    type: "",
    reminders: commonInitialRemindersValues,
    message: "",
    trigger: null,
  }
) => {
  const alertCommonSchema = getAlertCommonSchema();
  const schema = yup.object().shape({
    ...alertCommonSchema,
  });
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    values: omitBy(
      {
        ...initialValues,
        saveAsNewAlertsType: false,
        alertTemplateOrgIds: [],
      },
      isNil
    ),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
