import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  AssetsPerRegionData,
  AssetsPerRegionRecord,
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChartData,
  HorizontalBarChart,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { DEFAULT_LEGEND_TEXT_COLOR } from "../../../../../shared/components/DashboardWidgets/PieChartWidget/constants";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export interface AssetsPerRegionWidgetProps {
  widget: GetDashboardWidgetResponse;
}

export interface DashboardWidgetAssetsPerRegion
  extends GetDashboardWidgetResponse {
  data: AssetsPerRegionData;
}

export const mapWidgetData = (
  data: AssetsPerRegionData
): HorizontalBarChartData[] => {
  if (!data?.perRegion?.length) {
    return [];
  }

  return data.perRegion.map((region) => ({
    name: region?.region as string,
    value: region?.assets as number,
    color: mapChartColors(region as AssetsPerRegionRecord),
    legendTextColor: mapLegendTextColor(region as AssetsPerRegionRecord),
  }));
};

const mapChartColors = (data: AssetsPerRegionRecord): string => {
  if (data.region === "Other") {
    return "var(--concrete)";
  }

  return `${ColorsPalette.PrimaryBlueOpacity20}`;
};

const mapLegendTextColor = (data: AssetsPerRegionRecord): string => {
  if (data.region === "Other") {
    return "var(--dark-charcoal)";
  }

  return DEFAULT_LEGEND_TEXT_COLOR;
};

export const AssetsPerRegionWidget: React.FC<AssetsPerRegionWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";

  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: AssetsPerRegionData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.total ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  console.log("rerender", data);
  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--assets-per-region"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
          data-testid="dashboard-widget--assets-per-region--error-text"
        >
          There was an error when fetching the data for the Regions widget!
          Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          showPercentages={true}
          totalRecords={total}
          data={data}
          isLoading={isLoading}
          showXAxis={false}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
