import { useMemo } from "react";
import { useGetMergedProfileForAssetQuery } from "../../../../../../../../../graphql/operations";
import { sensorConfigToReferenceAreaValues } from "../../../../helpers";
import { IReferenceAreaValueType } from "../../../../interfaces";

interface RegulatorSensorProfileHook {
  referenceAreaValues: IReferenceAreaValueType[];
}

export const useRegulatorSensorProfile = (
  assetId: string | null | undefined
): RegulatorSensorProfileHook => {
  const profileGQL = useGetMergedProfileForAssetQuery(
    {
      input: { assetId: assetId ?? "" },
    },
    { enabled: Boolean(assetId) }
  )?.data;

  const mergedSensorProfile = useMemo(
    () => profileGQL?.getMergedProfileForAsset.sensorProfile ?? null,
    [profileGQL]
  );

  const referenceAreaValues =
    sensorConfigToReferenceAreaValues(
      mergedSensorProfile?.configuration?.psiAirSupply?.supplyPressure?.moving
        ?.match?.thresholds
    ) ?? [];

  return {
    referenceAreaValues,
  };
};
