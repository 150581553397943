import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../shared/hooks/theme/useCurrentTheme";

export const useReportViewTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiMenu: {
          styleOverrides: {
            paper: { boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16) !important" },
          },
        },
        MuiFormControl: {
          defaultProps: {
            variant: "standard",
          },
          styleOverrides: {
            root: {
              fontWeight: 700,
              fontSize: 14,
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: "var(--brand) !important",
            },
          },
        },

        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "unset !important",
              },
              "&.Mui-selected": {
                backgroundColor: "unset!important",
                color: "var(--primary) !important",
              },
            },
          },
        },
      },
    })
  );
};
