import { createTheme } from "@mui/material";
import { createBreakpoints } from "@mui/system";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "./useCurrentTheme";

/**
 * Theme with design specifically for form fields & form structure
 * You can wrap you form in this theme & add or edit any designs in here to change styling
 * examples: assetForm, GeofenceForm, installDeviceForm
 * @returns
 */
export const useFormTheme = () => {
  const muiTheme = useCurrentTheme();
  const breakpoints = createBreakpoints({});

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiInputLabel: {
          defaultProps: {
            shrink: true,
          },
          styleOverrides: {
            root: {
              fontWeight: 700,
              transform: "translate(0, -6px) scale(1)",
              fontSize: 14,
              lineHeight: "1.5rem",
            },
            asterisk: {
              fontWeight: 600,
              fontSize: "1rem",
              lineHeight: "1.5rem",
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            endAdornment: {
              top: "unset",
            },
            clearIndicator: {
              "&:hover, &.Mui-focusVisible": {
                backgroundColor: "transparent", // Disable background color change on hover/focus
              },
            },
            popupIndicator: {
              "&:hover, &.Mui-focusVisible": {
                backgroundColor: "transparent", // Disable background color change on hover/focus
              },
            },
            option: {
              "&:hover": {
                backgroundColor: "var(--brand-transparent)",
              },
              "&.Mui-selected": {
                backgroundColor: "var(--brand)",
                color: "white",
              },
              "&.Mui-focused &:hover": {
                backgroundColor: "var(--brand) !important",
              },
            },
            listbox: {
              backgroundColor: "var(--secondary)",
            },
            popper: {
              "& .MuiListSubheader-root": {
                fontSize: "17px",
                fontWeight: 700,
                color: "var(--typography)",
                borderBottom: "1px solid var(--concrete)",
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              padding: "10px 14px",
            },
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--dark-grey) !important",
              },
            },
          },
        },
        MuiFormControl: {
          defaultProps: {
            variant: "standard",
          },
          styleOverrides: {
            root: {
              fontWeight: 700,
              fontSize: 14,
            },
          },
        },
        MuiGrid: {
          styleOverrides: {
            root: {
              // Inside a drawer
              "&.drawerSection": {
                padding: "48px 24px 0px 72px", // 24*3=72px due to form being slightly out of drawer to the left
                ".MuiGrid-item": {
                  paddingLeft: "0px",
                  paddingTop: "24px", // space between every form field
                },
              },
              "&.secondDrawerSection": {
                padding: "24px 24px 0px 72px", // 24*3=72px due to form being slightly out of drawer to the left
                ".MuiGrid-item": {
                  paddingLeft: "0px",
                  paddingTop: "24px", // space between every form field
                },
              },
              // Used in Admin Panel drawers
              "&.thirdDrawerSection": {
                padding: "24px",
                ".MuiGrid-item": {
                  paddingLeft: "0px",
                  paddingTop: "24px", // space between every form field
                },
              },
              "&.noTopPaddingDrawerSection": {
                padding: "0px 24px 24px 24px",
                ".MuiGrid-item": {
                  paddingLeft: "0px",
                  paddingTop: "24px", // space between every form field
                },
              },

              "&.topPaddingDrawerSection": {
                padding: "24px 0px 0px 0px",
                ".MuiGrid-item": {
                  paddingLeft: "0px",
                  paddingTop: "24px", // space between every form field
                },
              },

              // Inside settings form
              "&.formSection": {
                padding: "24px 0 24px 24px", // space on top, bottom and left to match design
                ".MuiGrid-item": {
                  paddingTop: "24px",
                  paddingLeft: "24px",
                },
              },
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              "&.historyAccordion": {
                paddingTop: "24px",
                // Device form for padding between cancel btn & list
                paddingBottom: "32px",
              },
              // Removes gray bar above accordion
              "&.liteSentryGammaAccordion:before": {
                display: "none",
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              "&.liteSentryGammaAccordionSummary": {
                padding: 0,
              },

              // styles applied to each accordion in asset info form
              "&.assetSettingsAccordionSummaryTitleContainer": {
                borderTop: "1px solid var(--datepicker-hover-border)",
                padding: 0,

                // add padding on resolutions >= md
                [breakpoints.up("md")]: {
                  padding: "16px 0",
                },
              },
            },
            content: {
              // styles applied to each accordion summary in asset info form
              "&.assetSettingsAccordionSummaryTitle": {
                margin: 0,
                padding: "8px 0",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              },
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              "&.liteSentryGammaAccordionDetails": {
                padding: 0,
              },

              "#asset-info-form &": {
                padding: "24px 0px !important",
              },
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              fontSize: "0.875rem",
            },
          },
        },
      },
    })
  );
};
