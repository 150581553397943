export const DEFAULT_PIE_CHART_COLOR = "var(--primary-blue)";

export const DEFAULT_LEGEND_TEXT_COLOR = "var(--white)";

// Offset applied to percentage text to move the text away from the chart
export const CHART_PERCENTAGE_TEXT_OFFSET = 40;

export const RADIAN = Math.PI / 180;

export const COLORS = [
  "rechartsGreen",
  "rechartsPurple",
  "rechartsCoral",
  "rechartsMarineBlue",
  "rechartsTeal",
];

export const BINARY_COLORS = ["primary-blue", "rechartsGreen"];
