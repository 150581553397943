import { FC, memo } from "react";
import classNames from "classnames";
import { isValidHexColor } from "./ColorPreviewUtils";

type ColorPreviewProps = {
  hexColor: string;
  showText?: boolean;
  className?: string;
};

const ColorPreviewComponent: FC<ColorPreviewProps> = (
  props: ColorPreviewProps
) => {
  const { showText = true, hexColor, className } = props;

  const isValidHex = isValidHexColor(hexColor);
  return (
    <div
      className={classNames(
        "flex justify-between w-full items-center",
        className
      )}
      data-testid="text-and-color-container"
    >
      {showText && (
        <div data-testid="color-div" className="mr-2">
          {hexColor}
        </div>
      )}
      {isValidHex && (
        <div
          data-testid="color-box"
          style={{ backgroundColor: hexColor }}
          className={`w-6 h-6 rounded flex-shrink-0`}
        />
      )}
    </div>
  );
};

export default memo(ColorPreviewComponent);
