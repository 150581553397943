import { useState } from "react";
import { Box } from "@mui/material";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceArea,
} from "recharts";
import { ColorsPalette } from "../../../../../../../../../design-system/colors-palette";
import { chartTickStyle } from "../../../../../SummaryTabPanel/Charts/chartUtils";
import { useTiresTabContext } from "../../../../context";
import {
  determinePsiWheelEndLineStokeColor,
  determinePsiWheelEndLineStrokeDash,
} from "../../../../helpers";
import {
  PsiWheelEndAxle,
  PsiWheelEnd,
  IReferenceAreaValueType,
  STATE_TO_COLOR,
  SensorState,
} from "../../../../interfaces";
import { PsiWheelEndChartTooltip } from "./PsiWheelEndChartTooltip";

export const PsiWheelEndAxleTemperatures = {
  AxleOneLeftWheelEndTemperature: `${PsiWheelEndAxle.One}-${PsiWheelEnd.Left}-Temperature`,
  AxleOneRightWheelEndTemperature: `${PsiWheelEndAxle.One}-${PsiWheelEnd.Right}-Temperature`,
  AxleTwoLeftWheelEndTemperature: `${PsiWheelEndAxle.Two}-${PsiWheelEnd.Left}-Temperature`,
  AxleTwoRightWheelEndTemperature: `${PsiWheelEndAxle.Two}-${PsiWheelEnd.Right}-Temperature`,
  AxleThreeLeftWheelEndTemperature: `${PsiWheelEndAxle.Three}-${PsiWheelEnd.Left}-Temperature`,
  AxleThreeRightWheelEndTemperature: `${PsiWheelEndAxle.Three}-${PsiWheelEnd.Right}-Temperature`,
} as const;

export type PsiWheelEndAxleTemperatureKeys =
  keyof typeof PsiWheelEndAxleTemperatures;
export type PsiWheelEndAxleTemperatureValues =
  (typeof PsiWheelEndAxleTemperatures)[keyof typeof PsiWheelEndAxleTemperatures];

export type PsiWheelEndChartData = {
  date: string | null;
  [PsiWheelEndAxleTemperatures.AxleOneLeftWheelEndTemperature]?: {};
  [PsiWheelEndAxleTemperatures.AxleOneRightWheelEndTemperature]?: number | null;
  [PsiWheelEndAxleTemperatures.AxleTwoLeftWheelEndTemperature]?: number | null;
  [PsiWheelEndAxleTemperatures.AxleTwoRightWheelEndTemperature]?: number | null;
  [PsiWheelEndAxleTemperatures.AxleThreeLeftWheelEndTemperature]?:
    | number
    | null;
  [PsiWheelEndAxleTemperatures.AxleThreeRightWheelEndTemperature]?:
    | number
    | null;

  tooltipDate: string | null;
  // TODO: Add min/max values, when added to the API
};

type PsiWheelEndChartBodyProps = {
  data: PsiWheelEndChartData[];
  referenceAreaValues: Array<any>;
};

const PsiWheelEndChartBody: React.FC<PsiWheelEndChartBodyProps> = ({
  data,
  referenceAreaValues,
}) => {
  // Compute reference area elements used to display the sensor profile thresholds behind the lines
  const getReferenceAreasForPsiWheelEnd = () => {
    return referenceAreaValues?.map((value: IReferenceAreaValueType) => {
      return (
        <ReferenceArea
          yAxisId={"temperature"}
          y1={value.min}
          y2={value.max}
          fill={STATE_TO_COLOR[value.state as SensorState]}
          fillOpacity={0.8}
          ifOverflow="extendDomain"
          key={`reference-${value.state.toLowerCase()}`}
          data-testid="psi-wheel-end-chart-body-reference-area"
        />
      );
    });
  };

  const { selectedPsiWheelEndAxle: selectedAxle, selectedPsiWheelEnd } =
    useTiresTabContext();

  // Let's keep track of the current hovered dot in the table to show appropriate tooltip data
  const [currentHoveredDot, setCurrentHoveredDot] =
    useState<PsiWheelEndAxleTemperatureValues>();

  return (
    <Box
      className="w-full"
      style={{ height: 330 }}
      data-testid="psi-wheel-end-chart-body-container"
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid
            stroke="#DFDFDF"
            data-testid="psi-wheel-end-chart-body-chart-grid"
          />
          <XAxis
            dataKey="date"
            tick={chartTickStyle}
            tickLine={false}
            interval={"preserveStart"}
          />
          <YAxis
            yAxisId="temperature"
            label={{
              value: "Temperature",
              angle: -90,
              dx: -10,
              style: {
                textAnchor: "middle",
                ...chartTickStyle,
              },
            }}
            tick={chartTickStyle}
            interval={0}
            tickSize={0}
          />

          {/* Show the thresholds legend behind the lines */}
          {getReferenceAreasForPsiWheelEnd()}

          {/* Show information for the currently hovered tire */}
          <Tooltip
            offset={-5}
            content={
              <PsiWheelEndChartTooltip selectedWheelEnd={currentHoveredDot} />
            }
          />

          {Object.values(PsiWheelEndAxleTemperatures).map(
            (wheelEnd: PsiWheelEndAxleTemperatureValues) => (
              <Line
                key={wheelEnd}
                yAxisId={"temperature"}
                type="linear"
                dataKey={wheelEnd}
                stroke={determinePsiWheelEndLineStokeColor(
                  wheelEnd,
                  selectedAxle,
                  selectedPsiWheelEnd
                )}
                strokeWidth={3}
                strokeDasharray={determinePsiWheelEndLineStrokeDash(
                  wheelEnd,
                  selectedAxle,
                  selectedPsiWheelEnd
                )}
                connectNulls
                dot={false}
                activeDot={{
                  r: currentHoveredDot === wheelEnd ? 5 : 3,
                  stroke:
                    currentHoveredDot === wheelEnd
                      ? ColorsPalette.OffWhite
                      : "transparent",

                  onMouseEnter: () => setCurrentHoveredDot(wheelEnd),
                  onMouseLeave: () => setCurrentHoveredDot(undefined),
                }}
              />
            )
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default PsiWheelEndChartBody;
