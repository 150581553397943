import { useCallback, useState } from "react";
import { Button } from "@mui/base";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography } from "@mui/material";
import ActionButton from "../../../../../../shared/components/Button/ActionButton";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext";
import { useGeofenceQueryBuilderForm } from "../../../../shared/AssetsFilterControls/Filters/QueryBuilderTab/useGeofenceQueryBuilderForm";
import { GeoFenceQBModal } from "./GeofenceQBModal";

export const GeofenceQueryBuilder = () => {
  const [isQueryBuilderOpen, setIsQueryBuilderOpen] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  const { onChangeGeofenceFilters, currentGeofenceFilter } =
    useAssetsDataContext();

  const { form } = useGeofenceQueryBuilderForm(currentGeofenceFilter);
  const filterValues = form.watch();
  const onReset = useCallback(() => {
    setShouldReset(false);
  }, []);

  const onApply = () => {
    onChangeGeofenceFilters({ filters: filterValues.filters || null });
    setIsQueryBuilderOpen(false);
  };

  const resetQueryBuilderHandler = () => {
    setShouldReset(true);
  };

  const handleQueryBuilderOpen = () => {
    setIsQueryBuilderOpen(true);
  };
  const handleQueryBuilderClose = () => {
    form.reset(currentGeofenceFilter);
    setIsQueryBuilderOpen(false);
  };
  return (
    <>
      <Box className="h-12 mb-3 flex items-center justify-between font-semibold text-[18px] leading-[26px] tracking-[-0.01em]">
        <Box>Query Builder</Box>
        <Box>
          <Button
            onClick={handleQueryBuilderOpen}
            data-testid="filters--query-builder--toggle-button"
          >
            <ChevronRightIcon className="cursor" />
          </Button>
        </Box>
      </Box>
      <Box className=" flex flex-col justify-evenly">
        <GeoFenceQBModal
          form={form}
          isOpen={isQueryBuilderOpen}
          onClose={handleQueryBuilderClose}
          onApply={onApply}
          resetQueryBuilderHandler={resetQueryBuilderHandler}
          shouldReset={shouldReset}
          onReset={onReset}
        />
      </Box>
    </>
  );
};
