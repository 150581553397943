import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../shared/hooks/theme/useCurrentTheme";

export const useDisagreeDialogTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiDialogContent: {
          styleOverrides: {
            root: {
              width: "450px",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              background: "var(--brand)",
              textTransform: "inherit",
              borderRadius: "25px",
              boxShadow: "none",
              "&:hover": {
                background: "var(--brand)",
                boxShadow: "none",
              },
              "&:first-of-type": {
                background: "transparent",
                color: "var(--primary-brand)",
              },
            },
          },
        },
      },
    })
  );
};
