export type FlattenHierarchy<T extends { children: T[] }> = Omit<T, "children">;
export type HierarchyNode = {
  id: string;
  name: string;
  level: number;
  children: HierarchyNode[];
};

type SelectedValues = string[];
type WildcardStructure = string[];
type SelectedHierarchy = {
  id: string;
  selected: boolean;
  childrenCnt: number;
  selectedChildren: Array<{ id: string; childrenCnt: number }>;
  children: SelectedHierarchy[];
};

const build = (
  data: Array<{
    id: string;
    name: string;
    parent: string | null | undefined;
  }>,
  level: number,
  parent: string | null
): HierarchyNode[] =>
  data
    // Check if parent exists in data and set parent to null if not.
    .map((item) => ({
      ...item,
      parent: data.find(({ id }) => id === item.parent) ? item.parent : null,
    }))
    // Filter out all items that don't have the parent we're looking for.
    .filter((item) => item.parent === parent)
    // Map all items to HierarchyNode and recursively call buildHierarchy for all children.
    .map(({ id, name }) => ({
      id,
      name,
      level,
      children: build(data, level + 1, id),
    }))
    // Sort orgs alphabetically.
    .sort((a, b) => a.name.localeCompare(b.name));

export const buildHierarchy = (
  data: Array<{ id: string; name: string; parent: string | null | undefined }>
) => build(data, 0, null);

export const flattenHierarchy = <T extends { children: T[] }>(
  hierarchy: T[]
): Array<FlattenHierarchy<T>> => {
  return hierarchy.reduce(
    (acc: Array<FlattenHierarchy<T>>, { children, ...rest }) => {
      acc.push(rest);
      if (children.length > 0) {
        acc.push(...flattenHierarchy(children));
      }
      return acc;
    },
    []
  );
};

/**
 * Build selected values hierarchy for a given hierarchy node.
 * @param hierarchyNode Hierarchy node.
 * @param selectedValues An array of selected values.
 * @returns {SelectedHierarchy} A Selected values' hierarchy.
 */
const buildSelectedValuesHierarchy = (
  { id, children }: HierarchyNode,
  selectedValues: SelectedValues
): SelectedHierarchy => {
  const childrenHierarchy: SelectedHierarchy[] = [];
  // Build selected values hierarchy for each child.
  for (const node of children) {
    childrenHierarchy.push(buildSelectedValuesHierarchy(node, selectedValues));
  }

  return {
    id,
    selected: selectedValues.includes(id),
    selectedChildren: childrenHierarchy
      // Filter out children that are not selected.
      .filter(({ selected, childrenCnt }) => selected)
      .map(({ id, childrenCnt }) => ({ id, childrenCnt })),
    childrenCnt: children.length,
    children: childrenHierarchy,
  };
};

/**
 * Build wildcard structure for a node.
 * @param selectedHierarchy Selected hierarchy node.
 * @returns {WildcardStructure} An array of selected values as a wildcard structure E.g. ['id1', 'id2*', 'id3*'].
 */
const buildWildcardStructureForNode = ({
  children,
  id,
  selectedChildren,
  childrenCnt,
  selected,
}: SelectedHierarchy): WildcardStructure => {
  const wildcardStructure: WildcardStructure = [];
  // Build wildcard structure for each child.
  for (const node of children) {
    wildcardStructure.push(...buildWildcardStructureForNode(node));
  }
  // If the node is selected, add it to wildcard structure.
  if (selected) {
    wildcardStructure.push(id);
  }
  if (
    // If node has children
    childrenCnt > 0 &&
    // And all children are selected
    selectedChildren.length === childrenCnt &&
    // And all children are added to wildcard structure
    selectedChildren.filter(({ id, childrenCnt }) =>
      childrenCnt > 0
        ? // If the child has children, check if it's added to wildcard structure as a wildcard and as a value.
          wildcardStructure.includes(`${id}*`) && wildcardStructure.includes(id)
        : // If the child doesn't have children, check if it's added to wildcard structure as a value.
          wildcardStructure.includes(id)
    ).length === childrenCnt
  ) {
    // Remove all children from wildcard structure.
    for (const { id } of selectedChildren) {
      // Search for value in wildcard structure.
      const valueIndex = wildcardStructure.indexOf(id);
      // Remove value from wildcard structure.
      wildcardStructure.splice(valueIndex, 1);
      // Search for wildcard child in wildcard structure.
      const wildcardChildIndex = wildcardStructure.indexOf(`${id}*`);
      if (wildcardChildIndex > -1) {
        // If the wildcard child exists, remove it from the wildcard structure.
        wildcardStructure.splice(wildcardChildIndex, 1);
      }
    }
    // Add a wildcard value to wildcard structure since all children are selected recursively.
    wildcardStructure.push(`${id}*`);
  }

  return wildcardStructure;
};

/**
 * Build an array of selected values as a wildcard structure.
 * Important: The element itself may not be selected, but all its children may be selected.
 * In this case, the wildcard structure will look like this: ['id1*'].
 * If the element itself is selected and all its children are selected as well,
 * the wildcard structure will look like this: ['id1', 'id1*'].
 * @param selectedValues An array of selected values.
 * @param hierarchy Values hierarchy.
 * @returns {WildcardStructure} An array of selected values as a wildcard structure E.g. ['id1', 'id2*', 'id3*'].
 */
export const buildWildcardStructure = (
  selectedValues: SelectedValues,
  hierarchy: HierarchyNode[]
): WildcardStructure => {
  if (!selectedValues.length || !hierarchy.length) {
    return [];
  }

  const selectedValuesHierarchy: SelectedHierarchy[] = [];
  // Build selected values hierarchy base on given hierarchy and selected values.
  for (const node of hierarchy) {
    selectedValuesHierarchy.push(
      buildSelectedValuesHierarchy(node, selectedValues)
    );
  }

  const wildcardStructure: WildcardStructure = [];
  // Build wildcard structure for each node in selected values' hierarchy.
  for (const node of selectedValuesHierarchy) {
    wildcardStructure.push(...buildWildcardStructureForNode(node));
  }

  return wildcardStructure;
};

/**
 * Flatten wildcard structure for a node.
 * @param wildcardStructure Wildcard structure.
 * @param node Hierarchy node.
 * @returns {SelectedValues} An array of selected values.
 */
const flattenWildcardStructureNode = (
  wildcardStructure: WildcardStructure,
  node: HierarchyNode
): SelectedValues => {
  const { id, children } = node;
  const flattened: SelectedValues = [];

  // Search for value in wildcard structure.
  const valueIndex = wildcardStructure.indexOf(id);
  // Search for the wildcard child in wildcard structure.
  const wildcardIndex = wildcardStructure.indexOf(`${id}*`);
  // If the value exists in wildcard structure, add it to a flattened array.
  if (valueIndex > -1) {
    flattened.push(id);
  }
  if (wildcardIndex > -1) {
    // If the wildcard child exists in wildcard structure, add all children to a flattened array recursively.
    flattened.push(...flattenHierarchy(node.children).map(({ id }) => id));
  } else {
    // Otherwise, flatten wildcard structure for all children recursively.
    for (const child of children) {
      flattened.push(...flattenWildcardStructureNode(wildcardStructure, child));
    }
  }

  return flattened;
};

/**
 * Flatten wildcard structure for selected values.
 * @param wildcardStructure
 * @param hierarchy
 */
export const flattenWildcardStructure = (
  wildcardStructure: WildcardStructure,
  hierarchy: HierarchyNode[]
): string[] => {
  if (!wildcardStructure.length || !hierarchy.length) {
    return [];
  }

  const flattened: SelectedValues = [];
  // Flatten the wildcard structure for each node in hierarchy recursively.
  for (const node of hierarchy) {
    flattened.push(...flattenWildcardStructureNode(wildcardStructure, node));
  }

  return flattened;
};
