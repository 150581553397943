import { memo, SVGProps, FC } from "react";

interface CargoTemperatureProps extends SVGProps<SVGSVGElement> {}

const DoorLock: FC<CargoTemperatureProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 85 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M74.5519 18.9091C58.9323 18.9091 48.2617 12.4065 42.7278 7.88006C37.1939 12.4065 26.5245 18.9091 10.9073 18.9091C9.41542 18.9091 7.90419 18.8485 6.38086 18.7311C6.56008 23.7491 7.31448 31.5644 9.85498 40.7698C15.3695 60.7439 26.4191 78.123 42.7278 92.4941C59.0376 78.123 70.0885 60.7439 75.603 40.7698C78.1435 31.5656 78.8979 23.7528 79.0783 18.7311C77.5538 18.8485 76.0425 18.9091 74.5519 18.9091Z"
        fill="white"
      />
      <path
        d="M42.7275 84.709C56.1191 71.7982 65.3124 56.5261 70.0834 39.2451C71.6116 33.7051 72.4519 28.7101 72.9048 24.6123C59.1682 24.2394 48.9771 19.2565 42.7275 15.011V84.709Z"
        fill="#9BDEED"
      />
      <path
        d="M75.6029 40.7692C70.0884 60.7433 59.0376 78.1236 42.7277 92.4935C26.4191 78.1236 15.3695 60.7433 9.85493 40.7692C7.31322 31.5638 6.55882 23.7485 6.38081 18.7305C7.90415 18.8479 9.41537 18.9085 10.9072 18.9085C26.5244 18.9085 37.1938 12.4059 42.7277 7.87945C48.2616 12.4059 58.9322 18.9085 74.5518 18.9085C76.0425 18.9085 77.5537 18.8479 79.0783 18.7305C78.8978 23.7522 78.1434 31.565 75.6029 40.7692ZM84.7199 12.1988C81.1029 12.8866 77.7111 13.1845 74.5518 13.1845C53.5424 13.1845 42.7277 2.52654e-06 42.7277 2.52654e-06C42.7277 2.52654e-06 31.9094 13.1845 10.9072 13.1845C7.74552 13.1845 4.35737 12.8866 0.736725 12.1988C0.736725 12.1988 -4.0355 61.8282 42.7277 100C89.4946 61.8282 84.7199 12.1988 84.7199 12.1988"
        fill="#9BDEED"
      />
      <path
        d="M42.7223 86.1406C41.9316 86.1406 41.291 85.5025 41.291 84.7093V15.0125C41.291 14.2206 41.9316 13.5812 42.7223 13.5812C43.513 13.5812 44.1536 14.2206 44.1536 15.0125V84.7093C44.1536 85.5025 43.513 86.1406 42.7223 86.1406Z"
        fill="#6299A4"
      />
      <path
        d="M60.9822 44.9706C60.9822 55.0563 52.8061 63.2324 42.7216 63.2324C32.6358 63.2324 24.4609 55.0563 24.4609 44.9706C24.4609 34.8873 32.6358 26.7075 42.7216 26.7075C52.8061 26.7075 60.9822 34.8873 60.9822 44.9706Z"
        fill="white"
      />
      <path
        d="M32.8227 23.1066C32.8227 22.1803 32.9559 21.2842 33.1848 20.4293C34.3715 15.9586 38.4414 12.654 43.2765 12.654C48.113 12.654 52.1792 15.9574 53.3683 20.4281C53.596 21.2842 53.7292 22.1803 53.7292 23.1066V26.5117H58.4215V23.1066C58.4215 14.7416 51.6416 7.95925 43.2765 7.95925C34.9127 7.95925 28.1328 14.7416 28.1328 23.1066V26.5117H32.8227V23.1066Z"
        fill="#605F61"
      />
      <path
        d="M60.0891 26.5123H27.0844C25.0488 26.5123 23.3838 28.1773 23.3838 30.2128V59.1053C23.3838 61.1397 25.0488 62.8047 27.0844 62.8047H60.0891C62.1247 62.8047 63.7897 61.1397 63.7897 59.1053V30.2128C63.7897 28.1773 62.1247 26.5123 60.0891 26.5123Z"
        fill="#7E7F81"
      />
      <path
        d="M47.9538 41.9583C47.9538 39.5425 46.003 37.5833 43.5848 37.5833C41.1702 37.5833 39.2158 39.5425 39.2158 41.9583C39.2158 43.346 39.9 44.5242 40.9026 45.3247L39.2158 52.8105H47.9538L46.2658 45.3247C47.2684 44.5242 47.9538 43.346 47.9538 41.9583Z"
        fill="#434D5A"
      />
    </svg>
  );
};

export default memo(DoorLock);
