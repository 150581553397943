import React, { FC, memo } from "react";

export type BackIconProps = {
  color: string;
};

const BackIcon: FC<BackIconProps> = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4043_252723)">
      <path
        d="M12 20L13.41 18.59L7.83 13H20V11L7.83 11L13.42 5.42L12 4L4 12L12 20Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4043_252723">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default memo(BackIcon);
