import React, { FC, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Menu, ThemeProvider } from "@mui/material";
import { SMART_SEARCH_MATCH_AGAINST } from "../../../../constants/map";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import { useAssetsDataContext } from "../../../../views/AssetsView/shared/AssetsDataContext";
import SmartSearch, {
  OptionType,
} from "../../../../views/AssetsView/shared/SmartSearch";
import { useRecentSearch } from "../../Selector/hooks";
import { useHeaderButtonMenuTheme } from "../hooks/useHeaderButtonMenuTheme";
import { useSearchButtonHandlers } from "./useSearchButtonHandlers";

export const SearchButton: FC = () => {
  const theme = useHeaderButtonMenuTheme();
  const { dispatch } = useAppContext();
  const { pageType, onChangeFilters, currentFilter } = useAssetsDataContext();
  const { userInfo } = useAuthContext();
  const storageKey = `search-items-${userInfo ? userInfo.username : "tmp"}`;
  const { handleHistoryItemAdd } = useRecentSearch(storageKey);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const {
    handleChangeLocation,
    handleChangeAsset,
    handleChangeDevice,
    handleChangeOrganization,
    handleChangeUser,
    handleChangePage,
    handleChangeGeofence,
  } = useSearchButtonHandlers({
    handleHistoryItemAdd,
    dispatch,
    pageType,
    navigate,
    onChangeFilters,
    currentFilter,
  });

  const handlers = useMemo(
    () => ({
      location: handleChangeLocation,
      asset: handleChangeAsset,
      device: handleChangeDevice,
      organization: handleChangeOrganization,
      user: handleChangeUser,
      page: handleChangePage,
      geofences: handleChangeGeofence,
    }),
    [
      handleChangeLocation,
      handleChangeAsset,
      handleChangeDevice,
      handleChangeOrganization,
      handleChangeUser,
      handleChangePage,
      handleChangeGeofence,
    ]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeSmartSearch = useCallback(
    (value: OptionType) => {
      if (value) {
        const { matchedAgainst = "", name = "", id = "" } = value;
        const searchHandler = handlers[matchedAgainst as keyof typeof handlers];

        switch (matchedAgainst) {
          case SMART_SEARCH_MATCH_AGAINST.organization: {
            if (name && id) searchHandler(id, name);
            break;
          }
          case SMART_SEARCH_MATCH_AGAINST.asset:
          case SMART_SEARCH_MATCH_AGAINST.geofence:
          case SMART_SEARCH_MATCH_AGAINST.page: {
            if (id) {
              searchHandler(id);
            }
            break;
          }
          case SMART_SEARCH_MATCH_AGAINST.location:
          case SMART_SEARCH_MATCH_AGAINST.user:
          case SMART_SEARCH_MATCH_AGAINST.device: {
            if (name) {
              searchHandler(name);
            }
            break;
          }
        }
      } else {
        // user cleared Smart Search
        if (currentFilter?.assetId) {
          // clear only if required
          onChangeFilters({ assetId: "" });
        }
        if (currentFilter?.imeiId) {
          // clear only if required
          onChangeFilters({ imeiId: "" });
        }
      }

      handleClose();
    },
    [currentFilter, onChangeFilters, handlers]
  );

  return (
    <ThemeProvider theme={theme}>
      <IconButton
        size="large"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="!p-0 md:!pl-3"
        data-testid="search-button"
        focusRipple={false}
      >
        <SearchIcon
          className="text-primary"
          style={{ color: `var(--${open ? "brand" : "primary"})` }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-testid="search-button-menu"
        className="!text-xs"
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SmartSearch
          placeholder="Global Search"
          variant="outlined"
          adornmentPosition="end"
          size="small"
          handleChange={onChangeSmartSearch}
        />
      </Menu>
    </ThemeProvider>
  );
};
