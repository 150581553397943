import { sortBy } from "lodash";
import {
  AssetUiConfig,
  EventHistoryBatteryChartsData,
} from "../../../../../graphql/operations";
import { addDaysToDate, formatToUTC } from "../../../../../utils";
import {
  BATTERY_CHART_NAME,
  BatteryUnitSymbol,
  ChartDataResult,
} from "./types";

const generateDatesInRange = (startDate: Date, endDate: Date): Date[] => {
  const datesRange: Date[] = [];

  for (
    let i = startDate;
    formatToUTC(i, "MM/dd") !== formatToUTC(endDate, "MM/dd");
    i = addDaysToDate(i, 1)
  ) {
    datesRange.push(i);
  }
  return datesRange;
};

export const formatBatteryChartsData = (
  chartType: BATTERY_CHART_NAME,
  dateStartExpected: Date,
  dateEndExpected: Date,
  data: (EventHistoryBatteryChartsData | null)[],
  uiConfig: AssetUiConfig
): Array<ChartDataResult> => {
  const result = [];

  const eventHistoryWithinRange = sortBy(
    data?.filter((r) => Boolean(r?.eventDate)),
    (r) => new Date(r?.eventDate ?? "")
  );

  const datesRange = generateDatesInRange(dateStartExpected, dateEndExpected);

  for (const dateInRange of datesRange) {
    let includeEmptyDates = true;

    const eventInDateRange = eventHistoryWithinRange.find((e) => {
      if (!e?.eventDate) {
        return false;
      }

      const parsedEventDate = new Date(e?.eventDate);

      return (
        formatToUTC(parsedEventDate, "MM/dd") ===
        formatToUTC(dateInRange, "MM/dd")
      );
    });

    if (
      eventInDateRange &&
      eventInDateRange.eventDate &&
      chartType === BATTERY_CHART_NAME.Battery &&
      uiConfig?.power?.battery !== false
    ) {
      const eventDate = new Date(eventInDateRange.eventDate);
      result.push({
        date: formatToUTC(eventDate, "MM/dd"),
        tooltipLabel: formatToUTC(eventDate, "MM/dd/yy"),
        Battery: eventInDateRange?.averageBatteryVoltage,
        min: {
          Battery: eventInDateRange?.minBatteryVoltage,
        },
        max: {
          Battery: eventInDateRange?.maxBatteryVoltage,
        },
        units: {
          Battery: BatteryUnitSymbol.Voltage,
        },
      });

      includeEmptyDates = false;
    }

    if (
      chartType === BATTERY_CHART_NAME.AllChart &&
      eventInDateRange &&
      eventInDateRange.eventDate
    ) {
      const eventDate = new Date(eventInDateRange.eventDate);
      result.push({
        date: formatToUTC(eventDate, "MM/dd"),
        tooltipLabel: formatToUTC(eventDate, "MM/dd/yy"),
        ...(uiConfig?.power?.main !== false && {
          "Primary Voltage": eventInDateRange?.averageMainVoltage,
        }),
        ...(uiConfig?.power?.auxiliary !== false && {
          "Secondary Voltage": eventInDateRange?.averageAuxiliaryVoltage,
        }),
        ...(uiConfig?.power?.solarAmperage !== false && {
          Solar: eventInDateRange?.averageSolarAmperage,
        }),
        min: {
          "Primary Voltage": eventInDateRange?.minMainVoltage,
          "Secondary Voltage": eventInDateRange?.minAuxiliaryVoltage,
          Solar: eventInDateRange?.minSolarAmperage,
        },
        max: {
          "Primary Voltage": eventInDateRange?.maxMainVoltage,
          "Secondary Voltage": eventInDateRange?.maxAuxiliaryVoltage,
          Solar: eventInDateRange?.maxSolarAmperage,
        },
        units: {
          "Primary Voltage": BatteryUnitSymbol.Voltage,
          "Secondary Voltage": BatteryUnitSymbol.Voltage,
          Solar: BatteryUnitSymbol.Amperage,
        },
      });

      includeEmptyDates = false;
    }

    if (includeEmptyDates) {
      result.push({
        date: formatToUTC(dateInRange, "MM/dd"),
      });
    }
  }

  return result;
};
