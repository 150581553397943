import { useState, useEffect, useMemo, FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Input,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as AirtankSensorDark } from "../../../../../../assets/svgs/airTankSensorDark.svg";
import { ReactComponent as AirtankSensorLight } from "../../../../../../assets/svgs/airtankSensorLight.svg";
import { ReactComponent as RegulatorSensorDark } from "../../../../../../assets/svgs/regulatorSensorDark.svg";
import { ReactComponent as RegulatorSensorLight } from "../../../../../../assets/svgs/regulatorSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useGetSensorProfileNamesWithConfigurationQuery,
  SensorProfileType,
  useAssignOrgProfileToAssetsMutation,
  useSetAssetSensorProfilesMutation,
  SetAssetSensorProfilesMutation,
  AssetSensorProfilesResponseStatus,
  SetAssetSensorProfilesInput,
  ProfileConfigProperty,
  AssignOrgProfileToAssetsMutation,
  AssignOrgProfileToAssetsInput,
  AssignOrgProfileToAssetsStatus,
  AssetWithSensors,
  SetAssetSensorProfilesResponse,
  AssignOrgProfileToAssetsResponse,
  SensorThresholdShort,
  AirTankPressureThresholds,
  MovingParkedThresholds,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import { Label } from "../../../../../../shared/components/FormControlElement/styledElements";
import SensorSlider from "../../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractReversedRules,
  prepareReversedPayload,
  airTankPressureDefaultValues,
  airTankMinSpeed,
  airTankMaxSpeed,
  regulatorDefaultValues,
} from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import WithAsterisk from "../../../../../../shared/components/WithAsterisk";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../../shared/helpers/battery";
import { useGetPressureUnitPreference } from "../../../../../../shared/hooks/useGetPressureUnitPreference";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import {
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../../utils/convertPressure";
import { convertMilesToKm } from "../../../../../../utils/convertUnits";
import {
  AIR_SUPPLY_FORM_FIELDS,
  SensorProfileDropdownItem,
  analyzeResponse,
  handleAnalyzedResponse,
  sortSensorProfileNameDropdownItems,
} from "../../sensorsUtils";
import SelectedRecordInfo from "../../shared/SelectedRecordsInfo";
import ConfigurationDialogAdditionalElement from "../Components/CheckboxComponent";
import SensorProfileAssignmentForm from "../Components/SensorProfileAssignmentForm";
import { sensorsAirSupplySchema } from "./sensorsAirSupplyDrawerSchema";

interface AirSupplySensorDrawerProps {
  currentOrgId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: AssetWithSensors[];
}

const AirSupplySensorDrawer: FC<AirSupplySensorDrawerProps> = ({
  open,
  setOpen,
  selectedRecordsData,
  currentOrgId,
}) => {
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const queryClient = useQueryClient();
  const pressureUnit = useGetPressureUnitPreference();

  // Initial objects preparation
  let defaultAirSupplySensorProfile = undefined;
  let defaultTypeOfAssignment = "custom";

  // Extract asset if defined
  const currentAssetForEdit = selectedRecordsData?.length
    ? selectedRecordsData[0]
    : undefined;
  const psiAirSupply =
    selectedRecordsData?.[0]?.sensorProfile?.configuration?.psiAirSupply;
  const isPresetProfile = psiAirSupply?.type !== SensorProfileType.Asset;

  if (isPresetProfile) {
    defaultTypeOfAssignment = "profile";
    defaultAirSupplySensorProfile = psiAirSupply?.profileId;
  }

  // Regulator Data preparation
  const regulatorDefaultValuesConverted = getConvertedPressureValues(
    regulatorDefaultValues,
    pressureUnit
  );

  const psiAirRegulatorThresholds = currentAssetForEdit?.regulator;
  const initialMovingImbalance =
    extractReversedRules(psiAirRegulatorThresholds?.moving) ??
    regulatorDefaultValuesConverted;
  const initialParkedImbalance =
    extractReversedRules(psiAirRegulatorThresholds?.parked) ??
    regulatorDefaultValuesConverted;

  // Slider values extraction
  const [minRegulatorConverted, maxRegulatorConverted] =
    getConvertedPressureValues(
      [
        MinValuesBySensorType.supplyPressure,
        MaxValuesBySensorType.supplyPressure,
      ],
      pressureUnit
    );

  //set default values and resolver to the main form
  const mainForm = useForm({
    resolver: yupResolver(sensorsAirSupplySchema),
    defaultValues: {
      typeOfAssignment: defaultTypeOfAssignment,
      airSupplySensorProfile: defaultAirSupplySensorProfile,
    },
  });

  // airtank Setup
  const [minAirTankConverted, maxAirTankConverted] = getConvertedPressureValues(
    [MinValuesBySensorType.tankPressure, MaxValuesBySensorType.tankPressure],
    pressureUnit
  );
  const airTankPressureDefaultValuesConverted = getConvertedPressureValues(
    airTankPressureDefaultValues,
    pressureUnit
  );
  const psiAirThresholds = selectedRecordsData?.[0]?.tankPressure;

  const initialMinSpeed = psiAirThresholds?.minSpeed ?? 0;
  const initialAirTank =
    extractReversedRules(psiAirThresholds as SensorThresholdShort) ??
    airTankPressureDefaultValuesConverted;

  //states
  const [airTank, setAirTank] = useState(initialAirTank);
  const [minSpeed, setMinSpeed] = useState<number>(initialMinSpeed);

  const [moving, setMoving] = useState(initialMovingImbalance);
  const [parked, setParked] = useState(initialParkedImbalance);
  const [minSpeedError, setMinSpeedError] = useState<string>("");
  const [
    isConfirmProfileAssignmentVisible,
    setIsConfirmProfileAssignmentVisible,
  ] = useState(false);
  const [isTypeOfAssignmentProfile, setIsTypeOfAssignmentProfile] =
    useState(false);
  const [profileInput, setProfileInput] = useState<
    AssignOrgProfileToAssetsInput | undefined
  >(undefined);
  const [dontShowConfirmationDialog, setDontShowConfirmationDialog] =
    useState(false);
  const [selectedProfileLabel, setSelectedProfileLabel] = useState<string>("");

  //effects
  useEffect(() => {
    if (isPresetProfile) {
      setIsTypeOfAssignmentProfile(true);
    }
    const isDontShowAgain =
      localStorage.getItem("setProfileConformation") === "true";
    setDontShowConfirmationDialog(isDontShowAgain);
  }, [selectedRecordsData, isPresetProfile]);

  // GraphQL
  const queryInput = {
    input: {
      orgId: currentOrgId ?? "",
      type: SensorProfileType.Organization,
      includeDefaultPctProfile: true,
      mergeProfilesWithDefaultPctProfile: true,
      sensorType: ProfileConfigProperty.TankPressure,
    },
  };

  const queryOptions = {
    enabled: Boolean(currentOrgId),
  };

  const { data: profilesData, isLoading: isSensorProfileNamesLoading } =
    useGetSensorProfileNamesWithConfigurationQuery(queryInput, queryOptions);

  const setAssetSensorProfilesMutationConfig = {
    onSuccess: async (data: SetAssetSensorProfilesMutation) => {
      const response = analyzeResponse(
        data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
        AssetSensorProfilesResponseStatus.Success
      );

      handleAnalyzedResponse(
        response,
        dispatchErrorMessage,
        dispatchSuccessMessage,
        queryClient
      );

      setOpen(false);
    },
  };

  const { mutate: mutateCustomProfile, isLoading: isLoadingCustomProfile } =
    useSetAssetSensorProfilesMutation(setAssetSensorProfilesMutationConfig);

  const { mutate: mutateProfile, isLoading: isLoadingProfile } =
    useAssignOrgProfileToAssetsMutation({
      onSuccess: async (data: AssignOrgProfileToAssetsMutation) => {
        const response = analyzeResponse(
          data?.assignOrgProfileToAssets as AssignOrgProfileToAssetsResponse[],
          AssignOrgProfileToAssetsStatus.Fulfilled
        );

        handleAnalyzedResponse(
          response,
          dispatchErrorMessage,
          dispatchSuccessMessage,
          queryClient
        );

        setOpen(false);
      },
    });

  //handlers
  const handleConfirm = (confirmed: boolean) => {
    setIsConfirmProfileAssignmentVisible((prev) => !prev);

    if (confirmed && dontShowConfirmationDialog) {
      localStorage.setItem("setProfileConformation", "true");
    }

    if (confirmed && profileInput) {
      mutateProfile({ input: profileInput });
    }
  };

  const extractStringValues = (key: string, records: any): string[] => {
    return (records ?? [])
      .map((obj: any) => obj?.[key])
      .filter((value: any): value is string => !!value);
  };

  const selectedAssetsImeis: string[] = extractStringValues(
    "imei",
    selectedRecordsData
  );
  const selectedRowsIds: string[] = extractStringValues(
    "_id",
    selectedRecordsData
  );
  const selectedAssetsIds: string[] = extractStringValues(
    "asset_id",
    selectedRecordsData
  );

  const sensorProfilesForOrganization = useMemo(
    () => profilesData?.getSensorProfilesForOrganization ?? [],
    [profilesData]
  );

  const airPressureSensorProfile: SensorProfileDropdownItem[] =
    sensorProfilesForOrganization.map((item: any) => ({
      label: item.name,
      value: item._id,
      id: item._id,
      configuration: item.configuration,
      type: item.type,
    }));

  AIR_SUPPLY_FORM_FIELDS[1].options = sortSensorProfileNameDropdownItems(
    airPressureSensorProfile
  );

  const dispatchSnackbarMessage = (
    type: string,
    text: string,
    severity: string,
    title?: string,
    onClose?: () => void
  ) => {
    dispatch({
      type,
      payload: {
        title: title || "",
        text,
        severity,
        onClose: onClose || (() => {}),
      },
    });
  };

  const dispatchSuccessMessage = () => {
    dispatchSnackbarMessage(
      PAGE_SNACKBAR,
      "Sensor(s) Updated Successfully!",
      "success"
    );
  };

  const dispatchErrorMessage = (message: string) => {
    dispatchSnackbarMessage(
      PAGE_SNACKBAR,
      mapServerErrorCodeToHumanReadableMessage(message),
      "error",
      "Settings Uploading Failed"
    );
  };

  const onSubmit = async () => {
    const isMainFormValid = await mainForm.trigger();
    const isMinSpeedValid = handleMinSpeedChange({
      target: { value: minSpeed.toString() },
    });

    if (!isMainFormValid || !isMinSpeedValid) return;

    // type of Assignment is not profile submit else ask for confirmation
    if (!isTypeOfAssignmentProfile) {
      const input: SetAssetSensorProfilesInput = {
        selectedImeis: selectedAssetsImeis,
        orgId: currentOrgId ?? "",
        sensors: {
          psiAirSupply: {
            tankPressure: {
              minSpeed: convertMilesToKm(minSpeed),
              ...prepareReversedPayload(
                undefined,
                prepareConvertedMbarPressuresPayload(airTank, pressureUnit),
                MinValuesBySensorType.tankPressure,
                MaxValuesBySensorType.tankPressure
              ),
            },
            supplyPressure: {
              moving: prepareReversedPayload(
                ProfileConfigProperty.SupplyPressure,
                prepareConvertedMbarPressuresPayload(moving, pressureUnit),
                MinValuesBySensorType.supplyPressure,
                MaxValuesBySensorType.supplyPressure
              ),
              parked: prepareReversedPayload(
                ProfileConfigProperty.SupplyPressure,
                prepareConvertedMbarPressuresPayload(parked, pressureUnit),
                MinValuesBySensorType.supplyPressure,
                MaxValuesBySensorType.supplyPressure
              ),
            },
          },
        },
      };
      mutateCustomProfile({ input });
    } else {
      const selectedProfile = mainForm.getValues().airSupplySensorProfile;
      const input: AssignOrgProfileToAssetsInput = {
        assetIds: selectedRowsIds,
        profileId: selectedProfile ?? "",
      };
      if (dontShowConfirmationDialog) {
        mutateProfile({ input });
      } else {
        setProfileInput(input);
        setIsConfirmProfileAssignmentVisible((prev) => !prev);
      }
    }
  };

  // handle change of Type of Assignment - show/hide of Battery Sensor question
  const handleAssignmentTypeInputChange = (event: {
    name: string;
    value: string;
  }) => {
    const value = event?.value ?? undefined;
    if (!value) return;

    setIsTypeOfAssignmentProfile(value === "profile");

    // reset to default if custom is selected after being preselected
    if (value === "custom" && !isPresetProfile) {
      setAirTank(initialAirTank);
      setMinSpeed(initialMinSpeed);
    }
  };

  const handleSensorProfileInputChange = (selectedSensorProfile: {
    configuration: {
      psiAirSupply: {
        tankPressure: AirTankPressureThresholds;
        supplyPressure: MovingParkedThresholds;
      };
    };
    label: string;
  }) => {
    // Air tank logic
    const tankPressure =
      selectedSensorProfile?.configuration?.psiAirSupply?.tankPressure;
    const extractedTankPressure = extractReversedRules(
      tankPressure as SensorThresholdShort
    );
    const convertedTankPressure =
      extractedTankPressure &&
      getConvertedPressureValues(extractedTankPressure, pressureUnit);

    setAirTank(convertedTankPressure ?? airTankPressureDefaultValuesConverted);
    setMinSpeed(tankPressure?.minSpeed ?? 0);

    // Regulator Logic
    const supplyPressure =
      selectedSensorProfile?.configuration?.psiAirSupply?.supplyPressure;
    console.log("what is selected profile", selectedSensorProfile);
    const extractedMoving = extractReversedRules(
      supplyPressure?.moving as SensorThresholdShort
    );
    const extractedParked = extractReversedRules(
      supplyPressure?.parked as SensorThresholdShort
    );
    const convertedMoving =
      extractedMoving &&
      getConvertedPressureValues(extractedMoving, pressureUnit);
    const convertedParked =
      extractedParked &&
      getConvertedPressureValues(extractedParked, pressureUnit);

    setMoving(convertedMoving ?? regulatorDefaultValuesConverted);
    setParked(convertedParked ?? regulatorDefaultValuesConverted);

    console.log("what is label", selectedSensorProfile?.label);

    console.log("what are values", mainForm.getValues());

    // Main Profile change
    setSelectedProfileLabel(selectedSensorProfile?.label ?? "");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxClick = () => {
    setDontShowConfirmationDialog((prev) => !prev);
  };

  const handleMinSpeedChange = (event: { target: { value: string } }) => {
    const minSpeedNum = Number(event.target.value);
    if (Number.isNaN(minSpeedNum)) return true;

    setMinSpeed(minSpeedNum);

    if (minSpeedNum > airTankMaxSpeed || minSpeedNum < airTankMinSpeed) {
      setMinSpeedError(
        `Number must be between ${airTankMinSpeed} and ${airTankMaxSpeed}.`
      );
      return false;
    }

    setMinSpeedError("");
    return true;
  };

  const isLightTheme = theme?.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  const isRegulatorSliderDirty =
    moving.toString() !== initialMovingImbalance.toString() ||
    parked.toString() !== initialParkedImbalance.toString();

  const isLoading = isLoadingProfile || isLoadingCustomProfile;
  const isSliderDirty =
    airTank.toString() !== initialAirTank.toString() ||
    minSpeed !== initialMinSpeed;
  const isCustomAndPristine =
    !isPresetProfile &&
    !isTypeOfAssignmentProfile &&
    !isSliderDirty &&
    isRegulatorSliderDirty;
  const isProfileAndPristine = isPresetProfile && !mainForm.formState.isDirty;

  const additionalCheckboxComponent = (
    <ConfigurationDialogAdditionalElement
      checked={dontShowConfirmationDialog}
      onChange={handleCheckboxClick}
    />
  );

  return (
    <Drawer
      testId="air-supply-sensor-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader text="Edit Sensor" onClose={handleClose} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box>
            {selectedRecordsData && (
              <SelectedRecordInfo selectedRecordsData={selectedRecordsData} />
            )}
            <Box className="pt-6 pb-10 flex-auto">
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                className="justify-left px-6"
              ></Grid>
              <SensorProfileAssignmentForm
                isTypeOfAssignmentProfile={isTypeOfAssignmentProfile}
                form={mainForm}
                SENSOR_FORM_FIELDS={AIR_SUPPLY_FORM_FIELDS}
                handleAssignmentTypeInputChange={
                  handleAssignmentTypeInputChange
                }
                handleSensorProfileInputChange={handleSensorProfileInputChange}
                isProfileDataLoading={isSensorProfileNamesLoading}
              />

              <Box className="px-6" data-testid="airSupply-sensors-wrapper">
                <Box className="mb-16">
                  <Box className="pb-6" data-testid="input-min-speed">
                    <FormControl sx={{ width: "100%" }}>
                      <WithAsterisk>
                        <Label htmlFor="Min Speed">Min Speed</Label>
                      </WithAsterisk>
                      <Input
                        value={minSpeed}
                        onChange={handleMinSpeedChange}
                        data-testid="airtank-sensors-min-speed-input"
                        disabled={isLoading || isTypeOfAssignmentProfile}
                        sx={{ width: "100%", mb: "1px" }}
                      />
                      {!!minSpeedError && (
                        <FormHelperText
                          data-testid="airtank-profiles-min-speed-error-text"
                          error={true}
                        >
                          {minSpeedError}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  <Typography sx={{ fontWeight: "bold" }}>
                    Air Tank Settings
                  </Typography>

                  {isLightTheme ? (
                    <AirtankSensorDark style={svgIconSettings} />
                  ) : (
                    <AirtankSensorLight style={svgIconSettings} />
                  )}
                </Box>
                <SensorSlider
                  values={airTank}
                  min={minAirTankConverted}
                  max={maxAirTankConverted}
                  marks={sliderMarks(
                    minAirTankConverted,
                    maxAirTankConverted,
                    getPressureUnitLabel(pressureUnit)
                  )}
                  disabled={isLoading || isTypeOfAssignmentProfile}
                  reversed
                  onChange={setAirTank}
                />

                <Box className="mt-12">
                  <Grid
                    container
                    spacing={{ xs: 1, md: 2 }}
                    className="justify-left px-6"
                  ></Grid>
                  <Box className="mb-16">
                    <Typography
                      sx={{
                        color: "var(--regulator-settings)",
                        fontWeight: "bold",
                      }}
                    >
                      Regulator Settings Moving
                    </Typography>
                    {isLightTheme ? (
                      <RegulatorSensorDark style={svgIconSettings} />
                    ) : (
                      <RegulatorSensorLight style={svgIconSettings} />
                    )}
                  </Box>
                  <SensorSlider
                    values={moving}
                    min={minRegulatorConverted}
                    max={maxRegulatorConverted}
                    marks={sliderMarks(
                      minRegulatorConverted,
                      maxRegulatorConverted,
                      getPressureUnitLabel(pressureUnit)
                    )}
                    disabled={isLoading || isTypeOfAssignmentProfile}
                    reversed
                    onChange={setMoving}
                  />
                </Box>

                <Box className="mt-12" data-testid="sensors-regulator-parked">
                  <Box className="mb-16">
                    <Typography
                      sx={{
                        color: "var(--regulator-settings)",
                        fontWeight: "bold",
                      }}
                    >
                      Regulator Settings Parked
                    </Typography>
                    {isLightTheme ? (
                      <RegulatorSensorDark
                        style={svgIconSettings}
                        data-testid="temperature-profile-drawer-thermostat"
                      />
                    ) : (
                      <RegulatorSensorLight
                        style={svgIconSettings}
                        data-testid="temperature-profile-drawer-thermostat"
                      />
                    )}
                  </Box>
                  <SensorSlider
                    values={parked}
                    min={minRegulatorConverted}
                    max={maxRegulatorConverted}
                    marks={sliderMarks(
                      minRegulatorConverted,
                      maxRegulatorConverted,
                      getPressureUnitLabel(pressureUnit)
                    )}
                    disabled={isLoading || isTypeOfAssignmentProfile}
                    reversed
                    onChange={setParked}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <DrawerActions
            cancelBtnTestId="btn-Brand-form-cancel"
            disabled={isLoading}
            onCancel={handleClose}
          />
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving..." : "Apply to Selected"}
        disabled={isLoading || isCustomAndPristine || isProfileAndPristine}
        testId="global-button-component"
        submit={() => onSubmit()}
      />

      {isTypeOfAssignmentProfile && isConfirmProfileAssignmentVisible && (
        <ConfirmationDialog
          title="Profile Assignment"
          message={`Warning, all of the sensor settings of profile ${selectedProfileLabel}
             will be applied to asset(s) ${selectedAssetsIds.join(
               ", "
             )}. Any custom sensor settings will be lost!`}
          open={isConfirmProfileAssignmentVisible}
          confirmButtonText={"Confirm"}
          cancelButtonText="Cancel"
          handleConfirmationResult={handleConfirm}
          additionalContent={additionalCheckboxComponent}
        />
      )}
    </Drawer>
  );
};

export default AirSupplySensorDrawer;
