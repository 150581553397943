import React, { FC, memo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import {
  GridCallbackDetails,
  GridRowParams,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { Automation } from "../../../../graphql/operations";
import ActionButton from "../../../../shared/components/Button/ActionButton";
import { WithPermissions } from "../../../../shared/components/WithPermissions";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import AutomationsTable from "./AutomationsTable";
import {
  AutomationsDrawerState,
  handleRefresh,
  setSelectedAutomation,
} from "./AutomationsUtils";

const AutomationsComponent: FC = () => {
  const gridApiRef = useGridApiRef();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const refreshAutomations = useRef(() => {});
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [automationToEdit, setAutomationToEdit] = useState<
    (Automation & { id: string }) | undefined
  >(undefined);
  const [formMode, setFormMode] = useState<AutomationsDrawerState>(
    AutomationsDrawerState.create
  );
  const createAutomationHandler = () => {
    navigate(`${NavigationRoutes.AdminPanelAutomations}/template`);
  };

  const onRefresh = () => handleRefresh(queryClient, refreshAutomations);

  const onAutomationSelection = (
    data: GridRowParams,
    event: MuiEvent<React.MouseEvent>
  ) => {
    const target = event.nativeEvent.target as HTMLElement;
    if (target?.closest("#action-button-holder")) {
      return;
    }

    navigate(`${NavigationRoutes.AdminPanelAutomations}/${data.id}`);

    setSelectedAutomation(
      data,
      setAutomationToEdit,
      setFormMode,
      setDrawerOpened
    );
  };

  return (
    <Box data-testid="page-automations" className="bg-background pt-2 h-[90%]">
      <WithPermissions accessScope="automations.create">
        <ActionButton
          data-testid="new-automation-button"
          onClick={createAutomationHandler}
          sx={{ display: "block", margin: "10px 0", marginLeft: "auto" }}
        >
          New Automation
        </ActionButton>
      </WithPermissions>

      <AutomationsTable
        apiRef={gridApiRef}
        refreshAutomations={refreshAutomations}
        onRowClick={onAutomationSelection}
      />
    </Box>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
AutomationsComponent.displayName = "Automations";
export default memo(AutomationsComponent);
