import { GridColDef } from "@mui/x-data-grid-premium";

export const columnVisibilityModel = {
  location: true,
  millibars: true,
  status: true,
  lastUpdated: true,
};

export const columns: GridColDef[] = [
  { field: "location", headerName: "Location", flex: 1 },
  { field: "millibars", headerName: "Mbar", flex: 1 },
  { field: "status", headerName: "Status", flex: 1 },
  { field: "lastUpdated", headerName: "Last Updated", flex: 1 },
];
