import { FC } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Button } from "@mui/material";
import {
  WithPermissions,
  UserAccessScope,
} from "../../../../shared/components/WithPermissions";

interface AlertFooterProps {
  disabled: boolean;
  saveHandler: () => void;
  deleteHandler: () => void;
}

export const AlertFooter: FC<AlertFooterProps> = ({
  disabled,
  saveHandler,
  deleteHandler,
}) => {
  const renderActionButton = (
    title: string,
    handler: () => void,
    accessScope: UserAccessScope,
    icon: JSX.Element | null = null,
    disabled: boolean = false,
    className: string = ""
  ) => (
    <WithPermissions accessScope={accessScope}>
      <Button
        data-testid="alert-action-button"
        className={`global-btn ${className}`}
        disabled={disabled}
        variant="text"
        type="button"
        onClick={handler}
      >
        {icon && <Box className="flex items-center !text-2xl pr-1">{icon}</Box>}
        {title}
      </Button>
    </WithPermissions>
  );

  return (
    <Box className="px-4 md:px-8 lg:px-16 mt-8 flex items-center justify-between">
      {renderActionButton(
        "Delete",
        deleteHandler,
        "alerts.remove",
        <DeleteOutlineIcon />,
        disabled,
        `!text-sm !font-bold !text-brand ${
          disabled ? "h-[60px] w-[160px]" : ""
        }`
      )}
      {renderActionButton(
        "Save",
        saveHandler,
        "alerts.create",
        null,
        false,
        "h-[60px] w-[160px] !rounded-[100px] !text-base !font-bold !text-primary !bg-brand !text-white"
      )}
    </Box>
  );
};
