import { FC, ReactNode } from "react";
import { Box, Grid, Typography } from "@mui/material";

export interface SensorHeaderProps {
  title?: string;
  noBorder?: boolean;
  children?: ReactNode;
  secondTitle?: string;
}

const SensorHeader: FC<SensorHeaderProps> = ({
  children,
  title,
  noBorder,
  secondTitle,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="streched"
      className="pb-4"
    >
      <Box className="!mb-1 justify w-full ">
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Box>{children}</Box>
          <Box>
            <Grid container>
              <Typography
                variant="caption"
                className="!pl-2 !text-lg inline-block !font-semibold !text-brand"
                data-testid={`sensor-icon-${title}`}
              >
                {title}
              </Typography>
            </Grid>
            <Grid container style={{ paddingLeft: "0.5rem" }}>
              <Typography
                variant="caption"
                className="!text-xs !font-semibold inline-block pb-4 !text-brand-blue"
              >
                {secondTitle}
              </Typography>
            </Grid>
          </Box>
        </Grid>

        {!noBorder && (
          <Box
            className="mt-4"
            style={{
              borderTop: "1px solid var(--border-color)",
            }}
          ></Box>
        )}
      </Box>
    </Grid>
  );
};

export default SensorHeader;
