import {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box } from "@mui/system";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { PAGE_SNACKBAR } from "../../../../constants";
import { useAppContext } from "../../../../context/AppContext";
import {
  Brand,
  FilterListInput,
  FiltersInput,
  useGetBrandsQuery,
  TableViewType,
} from "../../../../graphql/operations";
import { getRows, Table } from "../../../../shared/components/Table";
import { useExportedFileName } from "../../../../shared/hooks/useExportedFileName";
import {
  BRAND_COLUMNS,
  BRAND_PAGE_SIZE,
  BRAND_VISIBLE_COLUMNS,
  prepareBrandFilters,
  SEARCH_KEYS,
} from "./BrandUtils";

export type brandTableProps = {
  onRowClick: (value: any) => void;
  refreshBrands: any;
  apiRef: MutableRefObject<GridApiPremium>;
};

const BrandsTable: FC<brandTableProps> = (props: brandTableProps) => {
  const [tableRows, setTableRows] = useState<Brand[]>([]);
  const tableColumns = BRAND_COLUMNS;
  const [currentFilters, setCurrentFilters] = useState<
    FilterListInput[] | null
  >(null);
  const [currentPageNo, setCurrentPageNo] = useState<number>(1);
  const [pagination, setPagination] = useState<number>(0);
  const fileName = useExportedFileName("Brands");
  const { onRowClick, apiRef } = props;

  const {
    state: { appConfig },
    dispatch,
  } = useAppContext();

  const onFilterChange = useCallback((data: GridFilterModel) => {
    const filters = prepareBrandFilters(data);
    // Reset to first page on filter
    setCurrentPageNo(1);
    setPagination(0);

    setCurrentFilters([
      {
        filters: filters.items as FiltersInput[],
        linkOperator: (filters.linkOperator ??
          filters.quickFilterLogicOperator) as string,
      },
    ]);
  }, []);

  const onPageChange = useCallback((page: number) => {
    setCurrentPageNo(page);
    setPagination(BRAND_PAGE_SIZE * (page - 1));
  }, []);

  const {
    data: brandData,
    isSuccess,
    isLoading: isDataLoading,
    refetch: refetchBrandsList,
  } = useGetBrandsQuery(
    {
      input: {
        filterList: currentFilters,
        detailedData: true,
        skip: pagination,
      },
    },
    {
      onSettled: (_, error) => {
        if (error) {
          const displayError = error
            ? (error as { message: string }).message
            : "Something Went Wrong.";
          dispatch({
            type: PAGE_SNACKBAR,
            payload: {
              title: "Brands load failed!",
              text: displayError,
              severity: "error",
            },
          });
        }
      },
    }
  );

  // Bind refresh query to parent so it can be used there
  useEffect(() => {
    props.refreshBrands.current = refetchBrandsList;
  }, [refetchBrandsList, props]);

  useMemo(() => {
    const brands = brandData?.getBrands ?? [];

    setTableRows(getRows<typeof brands>(brands, tableColumns, "", "", "_id"));
    return brands;
  }, [brandData?.getBrands, tableColumns]);

  return (
    <Box className="w-full h-[100%] pb-4">
      <Table
        data-testid="brands-table"
        tableType={TableViewType.Brands}
        showToolbar
        currentPage={currentPageNo}
        onRowClick={onRowClick}
        rows={tableRows ?? []}
        apiRef={apiRef}
        onPaginationModelChange={onPageChange}
        rowCount={tableRows?.length ?? 0}
        onFilterModelChange={onFilterChange}
        columns={tableColumns}
        loading={isDataLoading}
        pageSize={BRAND_PAGE_SIZE}
        onPageChange={onPageChange}
        error={!isDataLoading && !isSuccess ? true : null}
        searchThreshold={0.1}
        columnVisibilityModel={BRAND_VISIBLE_COLUMNS}
        searchExactMatch
        enableSearch
        disableRowGrouping
        pagination={true}
        searchKeys={SEARCH_KEYS}
        sortKeys={SEARCH_KEYS}
        searchMinLength={appConfig.searchMinLength}
        tableName="brands"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
      />
    </Box>
  );
};

export default memo(BrandsTable);
