import type { ReactElement } from "react";
import { useState, useRef, useEffect } from "react";
import {
  Box,
  Tabs as MUITabs,
  Tab as MUITab,
  TabsActions,
} from "@mui/material";
import classNames from "classnames";
import { kebabCase } from "lodash";

export type Tab = { value: number; label: string };
export enum TabsVariant {
  Standard = "standard",
  Scroll = "scrollable",
  FullWidth = "fullWidth",
}

export interface TabsProps {
  tabs: Tab[];
  children?: (activeTab: number) => ReactElement;
  // Will be taken into account only if 'controlled' is 'true'
  value?: number;
  // Will be taken into account only if 'controlled' is 'true'
  onClick?: (value: number) => void;
  // Use to make the component state controlled from outside
  controlled?: boolean;
  tabClasses?: { [key: string]: boolean };
  contentClasses?: { [key: string]: boolean };
  centered?: boolean;
  variant?: TabsVariant;
  scrollButtons?: boolean;
  allowScrollButtonsMobile?: boolean;
  showTabContent?: boolean;
  shouldDisableTabs?: boolean;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  children,
  value: valueProp,
  onClick,
  controlled = false,
  tabClasses,
  contentClasses,
  centered = true,
  variant = TabsVariant.Standard,
  scrollButtons = false,
  allowScrollButtonsMobile = false,
  showTabContent = true,
  shouldDisableTabs = false,
}) => {
  const defaultValue = tabs[0].value;
  const externalValue = valueProp ?? defaultValue;
  const [internalValueState, setInternalValueState] =
    useState<number>(defaultValue);

  const value = controlled ? externalValue : internalValueState;

  const containerRef = useRef(null);
  const actionRef = useRef<TabsActions | null>(null);

  // Fixes active tab indicator positioning. Ref: https://github.com/mui/material-ui/issues/9337#issuecomment-1513996638
  useEffect(() => {
    const ro = new ResizeObserver(() => {
      actionRef.current?.updateIndicator();
    });

    if (containerRef.current) {
      ro.observe(containerRef.current);
    }
  }, [containerRef, actionRef]);

  return (
    <>
      <Box
        ref={containerRef}
        className={classNames(
          "border border-solid border-sub-header-border bg-background-secondary py-3 w-full",
          tabClasses
        )}
      >
        <MUITabs
          action={actionRef}
          value={value}
          onChange={(_, value) => {
            if (controlled && onClick) {
              onClick(value);
            } else {
              setInternalValueState(value);
            }
          }}
          className="dashboard-frame-tabs text-light-charcoal"
          centered={centered}
          variant={variant}
          data-testid="dashboard-frame-tabs"
          scrollButtons={scrollButtons}
          allowScrollButtonsMobile={allowScrollButtonsMobile}
        >
          {tabs?.map((tab) => (
            <MUITab
              disabled={shouldDisableTabs}
              label={tab.label}
              value={tab.value}
              key={tab.value}
              data-testid={`dashboard-frame-tab-${kebabCase(tab.label)}`}
            />
          ))}
        </MUITabs>
      </Box>

      {showTabContent && ( // Conditionally render the tab content based on showTabContent prop
        <Box
          className={classNames("flex-1 h-full bg-background", contentClasses)}
          data-testid="dashboard-frame-tab-content"
        >
          {children ? children(value) : null}
        </Box>
      )}
    </>
  );
};

export default Tabs;
