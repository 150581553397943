import { FC, memo, useRef, useState } from "react";
import { Box } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "../../../../context/AuthContext";
import { Brand } from "../../../../graphql/operations";
import ActionButton from "../../../../shared/components/Button/ActionButton";
import { WithPermissions } from "../../../../shared/components/WithPermissions";
import {
  BrandsDrawerState,
  handleRefresh,
  setSelectedBrand,
} from "./BrandUtils";
import BrandsDrawer from "./BrandsDrawer";
import BrandsTable from "./BrandsTable";

const BrandsComponent: FC = () => {
  const { userRolePermissions } = useAuthContext();
  const gridApiRef = useGridApiRef();
  const queryClient = useQueryClient();
  const refreshBrands = useRef(() => {});
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [brandToEdit, setBrandToEdit] = useState<
    (Brand & { id: string }) | undefined
  >(undefined);
  const [formMode, setFormMode] = useState<BrandsDrawerState>(
    BrandsDrawerState.create
  );
  const createBrandHandler = () => {
    setFormMode(BrandsDrawerState.create);
    setDrawerOpened(!drawerOpened);
  };

  const onRefresh = () => handleRefresh(queryClient, refreshBrands);

  const handleDrawerClose = (value: boolean) => {
    setDrawerOpened(value);
    setBrandToEdit(undefined);
    setFormMode(BrandsDrawerState.create);
  };

  const onBrandSelection = (data: any) =>
    setSelectedBrand(data, setBrandToEdit, setFormMode, setDrawerOpened);

  return (
    <Box data-testid="page-brands" className="bg-background pt-2 h-[90%]">
      <WithPermissions accessScope="brands.create">
        <ActionButton
          data-testid="new-brand-button"
          onClick={createBrandHandler}
          sx={{ display: "block", margin: "10px 0", marginLeft: "auto" }}
        >
          New Brand
        </ActionButton>
      </WithPermissions>

      {(userRolePermissions?.brands?.create ||
        userRolePermissions?.brands?.edit) &&
        drawerOpened && (
          <BrandsDrawer
            open={drawerOpened}
            setOpen={handleDrawerClose}
            onBrandMutation={onRefresh}
            drawerState={formMode}
            brandData={brandToEdit}
          />
        )}
      <BrandsTable
        apiRef={gridApiRef}
        refreshBrands={refreshBrands}
        onRowClick={onBrandSelection}
      />
    </Box>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
BrandsComponent.displayName = "Brands";
export default memo(BrandsComponent);
