import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import slug from "slug";
import {
  AlertTypeV2,
  useFindAlertTemplateByIdQuery,
} from "../../../../graphql/operations";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { AlertTypeTitle } from "../../../AlertsView/constants";
import {
  buildAirTankAlertTemplate,
  buildCargoAlertTemplate,
  buildLightCircuitAlertTemplate,
  buildCustomAlertTemplate,
  buildDoorOpenAndMovingTemplate,
  buildDwellAlertTemplate,
  buildGeofenceAlertTemplate,
  buildMovingWithoutPrimOrSecPowerAlertTemplate,
  buildSensorHealthAlertTemplate,
  buildSpeedAlertTemplate,
  buildTpmsCatastrophicAlertTemplate,
  buildTPMSAlertTemplate,
  buildWheelEndAlertTemplate,
  buildMetricsAlertTemplate,
  buildDoorOpenOutsideOfGeofenceAlertTemplate,
  buildRegulatorAlertTemplate,
  buildTemperatureTemplate,
  buildReeferAlertTemplate,
  buildATISAlertTemplate,
  buildATISNotFunctioningTemplate,
  buildABSFaultAlertTemplate,
} from "../../utils";
import { AlertComponent } from "../Alert/AlertComponent";

interface AlertTemplateProps {
  templateName: string;
}

export type AlertTemplateData = {
  name?: string;
  message?: string;
  trigger?: string;
};

const geofenceAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Geofence]);
const cargoAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Cargo]);
const tpmsAlertSlug = slug(
  AlertTypeTitle[AlertTypeV2.TirePressureMonitorSystem]
);
const dwellAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Dwell]);
const airTankAlertSlug = slug(AlertTypeTitle[AlertTypeV2.AirTank]);
const atisAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Atis]);
const customAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Custom]);
const sensorHealthAlertSlug = slug(AlertTypeTitle[AlertTypeV2.SensorHealth]);
const movingWithoutPrimOrSecPowerAlertSlug = slug(
  AlertTypeTitle[AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower]
);
const speedAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Speeding]);
const wheelEndAlertSlug = slug(AlertTypeTitle[AlertTypeV2.WheelEnd]);
const tpmsCatastrophicAlertSlug = slug(
  AlertTypeTitle[AlertTypeV2.TirePressureMonitorSystemCatastrophic]
);
const LightCircuitAlertSlug = slug(AlertTypeTitle[AlertTypeV2.LightCircuit]); // it should be light circuit but the type remains the same
const metricsAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Metrics]);
const doorOpenOutsideOfGeofenceAlertSlug = slug(
  AlertTypeTitle[AlertTypeV2.DoorOpenOutsideOfGeofence]
);
const reeferAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Reefer]);
const doorOpenAndMovingSlug = slug(
  AlertTypeTitle[AlertTypeV2.DoorOpenAndMoving]
);
const atisNotFunctioningSlug = slug(
  AlertTypeTitle[AlertTypeV2.AtisNotFunctioning]
);
const regulatorAlerSlug = slug(AlertTypeTitle[AlertTypeV2.Regulator]);
const absFaultSlug = slug(AlertTypeTitle[AlertTypeV2.AbsFault]);

const temperatureAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Temperature]);

export const AlertTemplate: FC<AlertTemplateProps> = ({
  templateName,
}: AlertTemplateProps) => {
  const [templateData, setTemplateData] = useState<AlertTemplateData>({});
  const { state: locationState } = useLocation();
  const { templateId, isCustomTemplate } = locationState;
  const { data, refetch, isLoading } = useFindAlertTemplateByIdQuery(
    { alertTemplateId: templateId },
    { enabled: isCustomTemplate }
  );

  useEffect(() => {
    const template = data?.findAlertTemplateById;
    if (template) {
      const { name, message, query } = template;
      setTemplateData({ name, message: message ?? "", trigger: query ?? "" });
    }
  }, [data]);

  useEffect(() => {
    if (isCustomTemplate) {
      refetch();
    }
  }, [templateId, isCustomTemplate, refetch]);
  useSpinner(isLoading && isCustomTemplate);

  return (
    <>
      {((isCustomTemplate &&
        !isEmpty(templateData) &&
        templateId &&
        !isLoading) ||
        !isCustomTemplate) && (
        <>
          {templateName === geofenceAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildGeofenceAlertTemplate(templateData)}
              alertType={AlertTypeV2.Geofence}
            />
          )}
          {templateName === cargoAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildCargoAlertTemplate(templateData)}
              alertType={AlertTypeV2.Cargo}
            />
          )}
          {templateName === tpmsAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildTPMSAlertTemplate(templateData)}
              alertType={AlertTypeV2.TirePressureMonitorSystem}
            />
          )}
          {templateName === dwellAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildDwellAlertTemplate(templateData)}
              alertType={AlertTypeV2.Dwell}
            />
          )}
          {templateName === airTankAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildAirTankAlertTemplate(templateData)}
              alertType={AlertTypeV2.AirTank}
            />
          )}
          {templateName === atisAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildATISAlertTemplate(templateData)}
              alertType={AlertTypeV2.Atis}
            />
          )}
          {templateName === customAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildCustomAlertTemplate(templateData)}
              alertType={AlertTypeV2.Custom}
            />
          )}
          {templateName === sensorHealthAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildSensorHealthAlertTemplate(templateData)}
              alertType={AlertTypeV2.SensorHealth}
            />
          )}
          {templateName === movingWithoutPrimOrSecPowerAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildMovingWithoutPrimOrSecPowerAlertTemplate(
                templateData
              )}
              alertType={AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower}
            />
          )}
          {templateName === speedAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildSpeedAlertTemplate(templateData)}
              alertType={AlertTypeV2.Speeding}
            />
          )}
          {templateName === wheelEndAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildWheelEndAlertTemplate(templateData)}
              alertType={AlertTypeV2.WheelEnd}
            />
          )}
          {templateName === tpmsCatastrophicAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildTpmsCatastrophicAlertTemplate(templateData)}
              alertType={AlertTypeV2.TirePressureMonitorSystemCatastrophic}
            />
          )}
          {templateName === LightCircuitAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildLightCircuitAlertTemplate(templateData)}
              alertType={AlertTypeV2.LightCircuit}
            />
          )}
          {templateName === metricsAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildMetricsAlertTemplate(templateData)}
              alertType={AlertTypeV2.Metrics}
            />
          )}
          {templateName === doorOpenOutsideOfGeofenceAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildDoorOpenOutsideOfGeofenceAlertTemplate(templateData)}
              alertType={AlertTypeV2.DoorOpenOutsideOfGeofence}
            />
          )}
          {templateName === doorOpenAndMovingSlug && (
            <AlertComponent
              isTemplate
              alert={buildDoorOpenAndMovingTemplate(templateData)}
              alertType={AlertTypeV2.DoorOpenAndMoving}
            />
          )}
          {templateName === atisNotFunctioningSlug && (
            <AlertComponent
              isTemplate
              alert={buildATISNotFunctioningTemplate(templateData)}
              alertType={AlertTypeV2.AtisNotFunctioning}
            />
          )}
          {templateName === reeferAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildReeferAlertTemplate(templateData)}
              alertType={AlertTypeV2.Reefer}
            />
          )}
          {templateName === regulatorAlerSlug && (
            <AlertComponent
              isTemplate
              alert={buildRegulatorAlertTemplate(templateData)}
              alertType={AlertTypeV2.Regulator}
            />
          )}
          {templateName === temperatureAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildTemperatureTemplate(templateData)}
              alertType={AlertTypeV2.Temperature}
            />
          )}
          {templateName === reeferAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildReeferAlertTemplate(templateData)}
              alertType={AlertTypeV2.Reefer}
            />
          )}
          {templateName === absFaultSlug && (
            <AlertComponent
              isTemplate
              alert={buildABSFaultAlertTemplate(templateData)}
              alertType={AlertTypeV2.AbsFault}
            />
          )}
        </>
      )}
    </>
  );
};
