import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { transformers } from "../../../utils";
import { commonInitialRemindersValues, getAlertCommonSchema } from "../utils";

export const useAlertReeferForm = (
  initialValues = {
    name: "",
    type: "",
    reminders: commonInitialRemindersValues,
    message: "",
    trigger: null,
  }
) => {
  const getReeferSchema = () => {
    const alertCommonSchema = getAlertCommonSchema();

    return yup.object().shape({
      ...alertCommonSchema,
      parameters: yup.object().when(["saveAsNewAlertsType"], {
        is: false,
        then: (scheme) =>
          scheme.shape({
            reeferTemperatureHealthStatus: yup
              .string()
              .required("Reefer Temperature Health status is required")
              .transform(transformers.string),
          }),
      }),
    });
  };
  const schema = getReeferSchema();

  const getDefaultValues = (initialValues: any) =>
    omitBy(
      {
        ...initialValues,
        saveAsNewAlertsType: false,
        alertTemplateOrgIds: [],
      },
      isNil
    );
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    values: getDefaultValues(initialValues),
  });
  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
