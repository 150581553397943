import { Path, Control } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as LightOutSensorDark } from "../../../../../../../assets/svgs/lightOutSensorDark.svg";
import { ReactComponent as LightOutSensorLight } from "../../../../../../../assets/svgs/lightOutSensorLight.svg";
import { useAppContext } from "../../../../../../../context/AppContext";
import SensorSlider from "../../../../../../../shared/components/SensorSlider/SensorSlider";
import { sliderMarks } from "../../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Text from "../../../../../../../shared/components/Text";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
} from "../../../../../../../shared/helpers/battery";
import { LiteSentryGammaFormValues } from "../types";

export type LiteSentryGammaSliderContainerProps = {
  sectionTitle: string;
  control: Control<LiteSentryGammaFormValues>;
  values: number[];
  isLoading: boolean;
  name: Path<LiteSentryGammaFormValues>;
  onChange: React.Dispatch<React.SetStateAction<number[]>>;
  disabled?: boolean;
};

const LiteSentryGammaSliderContainer: React.FC<
  LiteSentryGammaSliderContainerProps
> = ({
  sectionTitle,
  control,
  values,
  isLoading,
  name,
  onChange,
  disabled,
}) => {
  const maUnit = "mA";
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginBottom: "0.75rem",
  };
  return (
    <>
      <Grid item sx={{ marginBottom: "1.75rem" }}>
        <Typography className="assetSettingsSectionTitle">
          {sectionTitle}
        </Typography>
        {isLightTheme ? (
          <LightOutSensorDark
            style={svgIconSettings}
            data-testid="lite-sentry-gamma-profile-drawer-light-bulb-icon"
          />
        ) : (
          <LightOutSensorLight
            style={svgIconSettings}
            data-testid="lite-sentry-gamma-profile-drawer-light-bulb-icon"
          />
        )}

        <TextFieldElement
          type="number"
          fullWidth
          control={control}
          name={name}
          required
          label={`Set Point (${maUnit})`}
          disabled={disabled}
          data-testid="lite-sentry-gamma-profile-drawer-set-point-input"
        />
      </Grid>

      <Grid item sx={{ marginBottom: "2.75rem" }}>
        <SensorSlider
          values={values}
          min={MinValuesBySensorType.liteSentryGamma}
          max={MaxValuesBySensorType.liteSentryGamma}
          marks={sliderMarks(
            MinValuesBySensorType.liteSentryGamma,
            MaxValuesBySensorType.liteSentryGamma,
            maUnit
          )}
          disabled={isLoading || disabled}
          onChange={onChange}
          reversed
        />
      </Grid>
    </>
  );
};

export default LiteSentryGammaSliderContainer;
