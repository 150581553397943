import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import {
  Maybe,
  TableColumnFormat as ReportColumnFormat,
  MediaActivitiesReportDataRow,
  TableValueDataType,
} from "../../../../../graphql/operations";
import { ImageModal } from "../../../../../shared/components/ImageModal/ImageModal";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  image: false,
  dateAndTime: false,
  assetId: false,
  organization: false,
  cargoStatus: false,
  floorSpace: false,
  loadedEmptyConfidence: false,
  state: false,
  city: false,
  latitude: false,
  longitude: false,
  geofence: false,
  timeInCurrentPercentageFloorSpace: false,
};

export const getColumns = (
  timezone: Maybe<string> | undefined
): ReportGridColDef[] => {
  const commonColumnConfig = {
    flex: 1,
    minWidth: 120,
  };

  const createColumn = (
    field: string,
    headerName: string,
    format: ReportColumnFormat,
    additionalConfig = {}
  ) => ({
    field,
    headerName,
    format,
    ...commonColumnConfig,
    ...additionalConfig,
  });

  return [
    createColumn("image", "Image Thumbnail", ReportColumnFormat.String, {
      renderCell: (
        params: GridRenderCellParams<any, MediaActivitiesReportDataRow, any>
      ) =>
        params.row.image ? (
          <ImageModal imageUrl={params.row.image} />
        ) : (
          "No Data"
        ),
      filterable: false,
      sortable: false,
    }),
    createColumn(
      "dateAndTime",
      "Date and Time",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
      }
    ),
    createColumn("assetId", "Asset ID", ReportColumnFormat.String),
    createColumn("organization", "Organization", ReportColumnFormat.String, {
      filterable: false,
    }),
    createColumn("state", "State", ReportColumnFormat.String),
    createColumn("city", "City", ReportColumnFormat.String),
    createColumn("latitude", "Latitude", ReportColumnFormat.String, {
      type: "number",
      valueGetter: (params: any) => {
        return params?.row.latitude.toString();
      },
    }),
    createColumn("longitude", "Longitude", ReportColumnFormat.String, {
      type: "number",
      valueGetter: (params: any) => {
        return params?.row.longitude.toString();
      },
    }),
    createColumn("geofence", "Geofence", ReportColumnFormat.String),
    createColumn("cargoStatus", "Cargo Status", ReportColumnFormat.String),
    createColumn("floorSpace", "Floor Space", ReportColumnFormat.String),
    createColumn(
      "timeInCurrentPercentageFloorSpace",
      "Time in current % floor space",
      ReportColumnFormat.String,
      {
        filterable: false,
      }
    ),
    createColumn(
      "loadedEmptyConfidence",
      "Confidence reading",
      ReportColumnFormat.String
    ),
  ];
};

export const SEARCH_KEYS = [
  "dateAndTime",
  "assetId",
  "organization",
  "cargoStatus",
  "floorSpace",
  "loadedEmptyConfidence",
  "state",
  "city",
  "latitude",
  "longitude",
  "geofence",
];

export const MEDIA_ACTIVITIES_COLUMN_TYPE_MAP = {
  assetId: TableValueDataType.String,
  cargoStatus: TableValueDataType.String,
  city: TableValueDataType.String,
  dateAndTime: TableValueDataType.Date,
  floorSpace: TableValueDataType.String,
  geofence: TableValueDataType.String,
  image: TableValueDataType.String,
  latitude: TableValueDataType.Number,
  loadedEmptyConfidence: TableValueDataType.String,
  longitude: TableValueDataType.Number,
  organization: TableValueDataType.String,
  state: TableValueDataType.String,
  street: TableValueDataType.String,
  timeInCurrentPercentageFloorSpace: TableValueDataType.String,
};
