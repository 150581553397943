import {
  AssetAlertsTableData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import {
  getTableColumn,
  TableColumnProps,
  TableGridColDef,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { AlertNotificationModal } from "../../../TableView/components";

export const columnVisibilityModel = {
  trigger: true,
  alert_notification: true,
  orgName: true,
  alertType: true,
  remindedAt: true,
  imei: true,
};

export const getColumns = (
  timezone: string
): TableGridColDef<AssetAlertsTableData>[] => {
  const columns: TableColumnProps<AssetAlertsTableData>[] = [
    { field: "trigger", headerName: "Trigger" },
    {
      field: "alert_notification",
      headerName: "Alert Notification",
      options: {
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <AlertNotificationModal
              alertNotificationId={String(params.row.incidentId)}
            />
          );
        },
      },
    },
    { field: "orgName", headerName: "Organization Name" },
    { field: "alertType", headerName: "Alert Type" },
    {
      field: "remindedAt",
      headerName: "Date / Time Alert Sent",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    { field: "imei", headerName: "Device ID" },
  ];

  return columns.map(getTableColumn);
};
