import { memo, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../constants";
import { BatchTitles } from "../../../../constants/batches";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  useResetUserPasswordMutation,
  UserRole,
  UsersTableData,
} from "../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../shared/components/ConfirmationDialog";
import { SubHeader } from "../../../../shared/components/SubHeader";
import {
  SubHeaderActionProps,
  SubHeaderActionType,
} from "../../../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import { useAvailableOrgs } from "../../../../shared/hooks/useAvailableOrgs";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { useUserData } from "../../../../shared/hooks/useUserData";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import {
  BATCH_FORM_FIELDS,
  BatchFormFieldsNames,
  mapOrgs,
} from "../../../BatchesView/BatchManagementUtils";
import CreateUser from "./CreateUserForm/CreateUserForm";
import EditUserForm from "./EditUser/EditUserForm";
import { UploadUsersDialog } from "./UploadUsersDialog";
import { UserManagementTable } from "./UserManagementTable/UserManagementTable";

const UserManagement = () => {
  const { userInfo, userRolePermissions } = useAuthContext();
  const { state, dispatch } = useAppContext();

  const queryClient = useQueryClient();
  const userData = useUserData();
  const parentOrg = useCurrentOrg();

  const [searchParams] = useSearchParams();
  const initialSearch = searchParams.get("search");

  const rolesForSuperAdminOptions = useNomenclatures(
    NOMENCLATURE_NAMES.rolesAllowedForSuperAdmin
  );
  const rolesForClientAdminOptions = useNomenclatures(
    NOMENCLATURE_NAMES.rolesAllowedForClientAdmin
  );
  const availableOrgs = useAvailableOrgs();
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [editUserState, setEditUserState] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [isClientAdmin, setIsClientAdmin] = useState<boolean>(false);
  const [isSupervisor, setIsSupervisor] = useState<boolean>(false);
  const [isMaintenanceManager, setIsMaintenanceManager] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UsersTableData>();
  const [isResetPassDialogVisible, setIsResetPassDialogVisible] =
    useState<boolean>(false);

  const createUserHandler = () => setDrawerState((state) => !state);

  const [isUploadUsersDialogOpen, setIsUploadUsersDialogOpen] = useState(false);

  const handleGridReloadRequest = () => {
    setTimeout(() => {
      queryClient.invalidateQueries();
    }, 3500);
  };

  useEffect(() => {
    setIsSuperAdmin(userInfo?.groups?.includes(UserRole.Superadmin) ?? false);
    setIsClientAdmin(userInfo?.groups?.includes(UserRole.Clientadmin) ?? false);
    setIsSupervisor(userInfo?.groups?.includes(UserRole.Supervisor) ?? false);
    setIsMaintenanceManager(
      userInfo?.groups?.includes(UserRole.Maintenancemanager) ?? false
    );
  }, [userInfo]);

  const onEditUserDetails = (user: UsersTableData | undefined) => {
    if (userRolePermissions.user.edit && user?.id !== undefined) {
      if (user) {
        setSelectedUser(user);
        toggleEditUser();
      }
    }
  };

  const toggleEditUser = useCallback(
    () => setEditUserState((state) => !state),
    []
  );
  // reset password functionalities
  const onSuccess = useCallback(async () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: `Password successfully reset`,
        text: "Success!",
        severity: "success",
      },
    });
  }, [dispatch]);
  const { mutate: resetUserPassword } = useResetUserPasswordMutation({
    onSuccess,
  });

  const handleResetUserPassword = (isConfirmed: boolean) => {
    if (isConfirmed && selectedUser) {
      resetUserPassword({ input: { userEmail: selectedUser.email } });
    }
    setIsResetPassDialogVisible(false);
  };

  const uploadUsersHandler = () => {
    setIsUploadUsersDialogOpen(true);
  };

  const onUploadUsersDialogClose = () => {
    setIsUploadUsersDialogOpen(false);
  };
  BATCH_FORM_FIELDS[BatchFormFieldsNames.AddToOrganization].values = mapOrgs(
    availableOrgs ?? []
  );

  const subHeaderActions: SubHeaderActionProps[] = [];

  subHeaderActions.push({
    type: SubHeaderActionType.Button,
    icon: "",
    title: "Upload",
    handler: uploadUsersHandler,
    accessScope: "user.create",
  });

  subHeaderActions.push({
    type: SubHeaderActionType.Button,
    primary: true,
    title: "New User",
    handler: createUserHandler,
    accessScope: "user.create",
  });

  return (
    <Box
      data-testid="page-user-management"
      className="bg-background pt-2 h-[90%]"
    >
      <SubHeader
        title="Users"
        actions={subHeaderActions}
        hideOrgName={true}
        hideHeaderBorder={true}
        removeHorizontalPadding={true}
        hideTitle={true}
      />

      <CreateUser
        open={!!drawerState}
        setOpen={setDrawerState}
        onUserCreate={handleGridReloadRequest}
        parentOrgId={parentOrg?._id}
        parentOrgName={parentOrg?.name}
        isSuperAdmin={!!isSuperAdmin}
        rolesForSuperAdminOptions={rolesForSuperAdminOptions}
        rolesForClientAdminOptions={rolesForClientAdminOptions}
      />
      {isUploadUsersDialogOpen && (
        <UploadUsersDialog
          title={BatchTitles.CreateUpdateUsers}
          customerOrg={userData?.customerOrg?.name}
          dialogFields={BATCH_FORM_FIELDS}
          isOpen={isUploadUsersDialogOpen}
          onClose={onUploadUsersDialogClose}
          onUpload={() => queryClient.invalidateQueries(["getBatchHistory"])}
        />
      )}

      {Boolean(editUserState) && (
        <EditUserForm
          user={selectedUser}
          open={!!editUserState}
          onUserUpdate={handleGridReloadRequest}
          setOpen={toggleEditUser}
          parentOrgName={parentOrg?.name}
          isSuperAdmin={!!isSuperAdmin}
          isClientAdmin={!!isClientAdmin}
          isSupervisor={!!isSupervisor}
          isMaintenanceManager={!!isMaintenanceManager}
          setOpenResetDialog={setIsResetPassDialogVisible}
          rolesForSuperAdminOptions={rolesForSuperAdminOptions}
          rolesForClientAdminOptions={rolesForClientAdminOptions}
        />
      )}

      <UserManagementTable
        initialSearch={initialSearch}
        onRowClick={onEditUserDetails}
      />

      <ConfirmationDialog
        title={"Reset Password"}
        message={"Are you sure you want to reset this password?"}
        open={isResetPassDialogVisible}
        confirmButtonText={"Reset"}
        cancelButtonText={"Cancel"}
        handleConfirmationResult={handleResetUserPassword}
      />
    </Box>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
UserManagement.displayName = "Users";
export default memo(UserManagement);
