import type { Fields } from "@react-awesome-query-builder/ui";
import {
  prepareConfigTextField,
  prepareConfigBooleanField,
  prepareConfigNumberField,
  prepareConfigDateField,
  prepareConfigTimeField,
} from "../../../../../../shared/components/QueryBuilder/";

// Some lines are commented out because they are not implemented on the BE side yet
// TODO: uncomment and refactor them when they are implemented
export const prepareDayTimeFields = (day: string): Record<string, any> => {
  const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
  return {
    [`configuration.operations.${day}.operatingHours.startAt`]:
      prepareConfigTimeField(`${capitalizedDay} start at`),
    [`configuration.operations.${day}.operatingHours.endAt`]:
      prepareConfigTimeField(`${capitalizedDay} end at`),
    [`configuration.operations.${day}.lunch.startAt`]: prepareConfigTimeField(
      `${capitalizedDay} start at lunch break`
    ),
    [`configuration.operations.${day}.lunch.endAt`]: prepareConfigTimeField(
      `${capitalizedDay} end at lunch break`
    ),
  };
};

const fields: Fields = {
  "geofence.code.keyword": prepareConfigTextField("Code"),
  "geofence.description.keyword": prepareConfigTextField("Description"),
  "geofence.fullAddressFormatted.keyword": prepareConfigTextField("Address"),
  "geofence.name.keyword": prepareConfigTextField("Name"),
  "geofence.tags.keyword": prepareConfigTextField("Tag"),
  "geofence.gisConfig.shape.keyword": prepareConfigTextField("Geofence shape"),
  "metadata.createdAt": prepareConfigDateField("Created At"),
  "metadata.updatedAt": prepareConfigDateField("Updated At"),
  "metadata.createdBy": prepareConfigTextField("Created By"),
  "metadata.updatedBy": prepareConfigTextField("Updated By"),
  "metadata.owner.keyword": prepareConfigTextField("Owner"),
  "configuration.timezone.keyword": prepareConfigTextField("Timezone"),
  "configuration.capacity.boat.min":
    prepareConfigNumberField("Boat Min Capacity"),
  "configuration.capacity.boat.max":
    prepareConfigNumberField("Boat Max Capacity"),
  "configuration.capacity.chassis.min": prepareConfigNumberField(
    "Chassis Min Capacity"
  ),
  "configuration.capacity.chassis.max": prepareConfigNumberField(
    "Chassis Max Capacity"
  ),
  "configuration.capacity.container.min": prepareConfigNumberField(
    "Container Min Capacity"
  ),
  "configuration.capacity.container.max": prepareConfigNumberField(
    "Container Max Capacity"
  ),
  "configuration.capacity.dolly.min":
    prepareConfigNumberField("Dolly Min Capacity"),
  "configuration.capacity.dolly.max":
    prepareConfigNumberField("Dolly Max Capacity"),
  "configuration.capacity.other.min":
    prepareConfigNumberField("Other Min Capacity"),
  "configuration.capacity.other.max":
    prepareConfigNumberField("Other Max Capacity"),
  "configuration.capacity.reefer.min": prepareConfigNumberField(
    "Reefer Min Capacity"
  ),
  "configuration.capacity.reefer.max": prepareConfigNumberField(
    "Reefer Max Capacity"
  ),
  "configuration.capacity.tractor.min": prepareConfigNumberField(
    "Tractor Min Capacity"
  ),
  "configuration.capacity.tractor.max": prepareConfigNumberField(
    "Tractor Max Capacity"
  ),
  "configuration.capacity.trailer.min": prepareConfigNumberField(
    "Trailer Min Capacity"
  ),
  "configuration.capacity.trailer.max": prepareConfigNumberField(
    "Trailer Max Capacity"
  ),
  "configuration.capacity.vehicle.min": prepareConfigNumberField(
    "Vehicle Min Capacity"
  ),
  "configuration.capacity.vehicle.max": prepareConfigNumberField(
    "Vehicle Max Capacity"
  ),
  ...prepareDayTimeFields("monday"),
  ...prepareDayTimeFields("tuesday"),
  ...prepareDayTimeFields("wednesday"),
  ...prepareDayTimeFields("thursday"),
  ...prepareDayTimeFields("friday"),
  ...prepareDayTimeFields("saturday"),
  ...prepareDayTimeFields("sunday"),

  "restrictions.standard.allowedAssetLength": prepareConfigTextField(
    "Allowed Asset Length"
  ),
  "restrictions.standard.allowedAssetTags":
    prepareConfigTextField("Allowed Asset Tags"),
  "restrictions.standard.allowedAssetTypes": prepareConfigTextField(
    "Allowed Asset Types"
  ),
  "restrictions.standard.allowedDoorType":
    prepareConfigTextField("Allowed Door Type"),
  "restrictions.standard.noAssetsOverXYearsOld": prepareConfigNumberField(
    "No assets over XY years old",
    { min: 0 }
  ),
  "geofence.metadata.createdBy": prepareConfigTextField("Created By"),
  "geofence.metadata.updatedBy": prepareConfigTextField("Updated By"),
  "geofence.configuration.typeId": prepareConfigTextField("Type Id"),
  "geofence.configuration.subTypeId": prepareConfigTextField("Sub Type Id"),
};

const assetTypes = [
  "boat",
  "chassis",
  "container",
  "dolly",
  "other",
  "reefer",
  "tractor",
  "trailer",
  "vehicle",
];

const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const entries = Object.entries(fields);
entries.sort((a, b) => {
  const labelA = a[1].label?.toLowerCase(); // Convert labels to uppercase for case-insensitive sorting
  const labelB = b[1].label?.toLowerCase();
  return labelA!.localeCompare(labelB!);
});

const sorted = Object.fromEntries(entries);

const operations: { [k: string]: any } = {};
const capacity: { [k: string]: any } = {};

daysOfWeek.forEach((dayOfWeek) => {
  const capitalizedDayOfWeek =
    dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
  operations[
    `geofence.configuration.operations.${dayOfWeek}.operatingHours.startAt`
  ] = prepareConfigTextField(`${capitalizedDayOfWeek} Operations Start Time`);

  operations[
    `geofence.configuration.operations.${dayOfWeek}.operatingHours.endAt`
  ] = prepareConfigTextField(`${capitalizedDayOfWeek} Operations End Time`);

  operations[`geofence.configuration.operations.${dayOfWeek}.lunch.startAt`] =
    prepareConfigTextField(`${capitalizedDayOfWeek} Lunch Start Time`);

  operations[`geofence.configuration.operations.${dayOfWeek}.lunch.endAt`] =
    prepareConfigTextField(`${capitalizedDayOfWeek} Lunch End Time`);
});

assetTypes.forEach((assetType) => {
  const capitalizedAssetType =
    assetType.charAt(0).toUpperCase() + assetType.slice(1);
  capacity[`geofence.configuration.capacity.${assetType}.min`] =
    prepareConfigNumberField(`${capitalizedAssetType} Capacity Min`, {
      min: 0,
    });
  capacity[`geofence.configuration.capacity.${assetType}.max`] =
    prepareConfigNumberField(`${capitalizedAssetType} Capacity Max`, {
      min: 0,
    });
});

export const geofenceQueryBuilderFields = {
  ...sorted,
  // ...operations,
  // ...capacity,
};
