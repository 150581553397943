import { useMemo } from "react";
import { sortBy } from "lodash";
import { OrgData } from "../../../../../../graphql/operations";
import { AutocompleteOption } from "../../../../../../types";
import { EditOrg } from "../../types";

interface ParentOrgsHook {
  parentOrgs: AutocompleteOption[];
}

export const getAllowedParentOrgs = (
  organizationsList: OrgData[],
  selectOrganization: EditOrg | null
) => {
  const rootNodeOrgs = selectOrganization?.org_key?.split("/");
  if (!rootNodeOrgs || rootNodeOrgs.length < 2) {
    return [];
  }
  const rootNode = rootNodeOrgs[0];

  const orgsOfSameRoot = organizationsList.filter((org: OrgData) =>
    org?.org_key?.includes(rootNode)
  );

  const allowedParentOrgsList = orgsOfSameRoot.filter(
    (org: OrgData) =>
      !org?.org_key?.includes(selectOrganization?.org_key as string)
  );
  return allowedParentOrgsList;
};

export const useParentOrgs = (
  isEditMode: boolean,
  orgToEdit: EditOrg | null,
  organizations: OrgData[]
): ParentOrgsHook => {
  const parentOrgs = useMemo(() => {
    if (isEditMode) {
      const allowedParentCompanies = getAllowedParentOrgs(
        organizations,
        orgToEdit
      );

      return sortBy(
        [...allowedParentCompanies].map((org) => ({
          id: org._id,
          label: org.name,
        })),
        "label"
      );
    } else {
      return sortBy(
        [...organizations].map((org) => ({
          id: org._id,
          label: org.name,
        })),
        "label"
      );
    }
  }, [isEditMode, orgToEdit, organizations]);

  return { parentOrgs };
};
