import { GridColumnOrderChangeParams } from "@mui/x-data-grid-premium";
import { isUndefined } from "lodash";
import {
  ReportAlertHistoryTableData,
  DwellHierarchyTableData,
  FiltersInput,
  TableFilterLinkOperator,
  TableFiltersInput,
  TableFilterInput,
  TableValueDataType,
  ReportType,
  FilterListInput,
  GetTableDataInput,
} from "../../../graphql/operations";
import {
  MUI_GRID_FILTER_OPERATOR_TO_TABLE_FILTER_OPERATOR_MAP,
  MUI_GRID_LINK_OPERATOR_TO_TABLE_FILTER_LINK_OPERATOR_MAP,
  TableGridColDef,
} from "../../../shared/components/Table";
import { checkIsAfter, checkIsBefore } from "../../../utils";
import {
  isTableFiltersEmpty,
  isTableFiltersValid,
  validateTableFilters,
} from "../../../utils/validateTableFilters";
import { ReportGridColDef, transformColumnField } from "../helpers/helpers";
import { ReportWithParameters } from "../interfaces";
import { ASSET_ABS_FAULT_COLUMN_TYPE_MAP } from "./components/AssetABSFaultTable/columns";
import { ASSET_DETENTION_COLUMN_TYPE_MAP } from "./components/AssetDetentionTable/columns";
import { ASSET_INVENTORY_COLUMN_TYPES_MAP } from "./components/AssetInventoryTable/columns";
import { ASSET_YARD_COLUMN_TYPES_MAP } from "./components/AssetYardTable/columns";
import { DWELL_HIERARCHY_COLUMN_TYPES_MAP } from "./components/DwellHierarchyTable/columns";
import { MEDIA_ACTIVITIES_COLUMN_TYPE_MAP } from "./components/MediaActivitiesTable/columns";
import {
  MILEAGE_COLUMN_TYPE_MAP,
  OUTSIDE_GEOFENCE_COLUMN_TYPE_MAP,
} from "./components/MileageTable/columns";
import { SENSORS_COLUMN_TYPES_MAP } from "./components/SensorsTable/columns";

export const changeColumnsOrder = (
  colData: GridColumnOrderChangeParams,
  columns: (
    | ReportGridColDef
    | TableGridColDef<DwellHierarchyTableData | ReportAlertHistoryTableData>
  )[],
  report: ReportWithParameters
) => {
  const { targetIndex, field } = colData;
  const oldIndex = columns.findIndex((col) => col.field === field);
  const newColumns = [...columns];
  const newReportColumns = [...report.columns];
  const oldReportColumnIndex = newReportColumns.findIndex(
    (col) => col.field === field
  );
  newColumns.splice(targetIndex, 0, newColumns.splice(oldIndex, 1)[0]);
  newReportColumns.splice(
    targetIndex,
    0,
    newReportColumns.splice(oldReportColumnIndex, 1)[0]
  );
  return { newColumns, newReportColumns };
};

interface Parent {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}

export const isStartAfterEnd = (
  value: string | null | undefined,
  parent: Parent
) => {
  if (!value || !parent.endDate) return true;

  return !checkIsAfter(new Date(value), new Date(parent.endDate));
};

export const isEndBeforeStart = (
  value: string | null | undefined,
  parent: Parent
) => {
  if (!value || !parent.startDate) return true;

  return !checkIsBefore(new Date(value), new Date(parent.startDate));
};

export const validateAndMapFiltersOS = (value: {
  items: FiltersInput[];
  linkOperator: TableFilterLinkOperator;
  columnTypesMap: Record<string, TableValueDataType>;
}): TableFiltersInput | null | undefined => {
  if (!value?.items.length) {
    return null;
  }
  const filtersValid = isTableFiltersValid(value.items);

  if (filtersValid) {
    const filterItems: TableFilterInput[] = value.items.map((i) => ({
      ...(i?.value && { value: JSON.stringify({ value: i.value }) }),
      dataType: value.columnTypesMap[i.columnField],
      field: transformColumnField(i.columnField),
      operator:
        MUI_GRID_FILTER_OPERATOR_TO_TABLE_FILTER_OPERATOR_MAP[
          value.columnTypesMap[i.columnField]
        ][i.operatorValue],
    }));
    return {
      filters: filterItems,
      linkOperator:
        MUI_GRID_LINK_OPERATOR_TO_TABLE_FILTER_LINK_OPERATOR_MAP[
          value.linkOperator
        ],
    };
  }

  const filtersEmpty = isTableFiltersEmpty(value.items);
  if (filtersEmpty) {
    return null;
  }
};

const REPORT_TYPE_TO_COLUMN_TYPES_MAP: Partial<
  Record<ReportType, Record<string, TableValueDataType>>
> = {
  [ReportType.Sensors]: SENSORS_COLUMN_TYPES_MAP,
  [ReportType.AssetInventory]: ASSET_INVENTORY_COLUMN_TYPES_MAP,
  [ReportType.MediaActivities]: MEDIA_ACTIVITIES_COLUMN_TYPE_MAP,
  [ReportType.DwellHierarchy]: DWELL_HIERARCHY_COLUMN_TYPES_MAP,
  [ReportType.AssetYard]: ASSET_YARD_COLUMN_TYPES_MAP,
  [ReportType.Mileage]: MILEAGE_COLUMN_TYPE_MAP,
  [ReportType.OutsideGeofence]: OUTSIDE_GEOFENCE_COLUMN_TYPE_MAP,
  [ReportType.AssetAbsFaultHistory]: ASSET_ABS_FAULT_COLUMN_TYPE_MAP,
  [ReportType.AssetDetention]: ASSET_DETENTION_COLUMN_TYPE_MAP,
};

export const applyFilters = ({
  value,
  reportType,
  queryInput,
  setFiltersOS,
  setFilters,
  setQueryInput,
}: {
  value: {
    items: FiltersInput[] & TableFilterInput[];
    linkOperator: string & TableFilterLinkOperator;
  };
  reportType: ReportType;
  queryInput: GetTableDataInput;
  setFiltersOS: (filters: TableFiltersInput | null) => void;
  setFilters: (filters: FilterListInput | null) => void;
  setQueryInput: (queryInput: GetTableDataInput) => void;
}) => {
  const columnTypesMap = REPORT_TYPE_TO_COLUMN_TYPES_MAP[reportType];

  if (columnTypesMap) {
    const newFiltersOS = validateAndMapFiltersOS({
      ...value,
      columnTypesMap,
    });

    if (!isUndefined(newFiltersOS)) {
      setFiltersOS(newFiltersOS);
      setQueryInput({ ...queryInput, tableFilters: newFiltersOS });
    }
  } else {
    const newFilters = validateTableFilters(value);
    if (!isUndefined(newFilters)) {
      setFilters(newFilters);
    }
  }
};
