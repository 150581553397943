import { memo, FC, ReactNode } from "react";
import { Popover as MuiPopover } from "@mui/material";

interface IProp {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  children: ReactNode;
  [x: string]: unknown;
}

const Popover: FC<IProp> = ({ anchorEl, onClose, children, ...props }) => {
  return (
    <MuiPopover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      {...props}
    >
      {children}
    </MuiPopover>
  );
};

export default memo(Popover);
