import { useCallback, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import slug from "slug";
import { useAppContext } from "../../context/AppContext";
import { ReportType } from "../../graphql/operations";
import Page from "../../shared/components/Page";
import { SubHeader } from "../../shared/components/SubHeader";
import { SubHeaderActionType } from "../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { ReportView, ReportTypeTitle } from "../ReportView";
import { MaximumExceededModal } from "../ReportView/AssetReport/components/MaximumExceededModal";
import { ReportParameters } from "../ReportView/interfaces";
import { CreateReportDialog, ReportsTable } from "./components";
import { useReportListViewTheme } from "./hooks";

const ReportsList = () => {
  const page = useRef();
  const navigate = useNavigate();
  const reportListViewTheme = useReportListViewTheme();

  const {
    state: { appConfig },
  } = useAppContext();

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
  const [maxExceededModalOpened, setMaxExceededModalOpened] = useState(false);

  const handleDialogClose = useCallback(
    () => setIsCreateDialogOpen(false),
    [setIsCreateDialogOpen]
  );

  const createReport = useCallback(
    (reportType: ReportType, reportParams: ReportParameters) => {
      navigate(
        `${NavigationRoutes.Reports}/${slug(
          ReportTypeTitle[reportType]
        )}/template`,
        { state: { reportParams } }
      );
    },
    [navigate]
  );

  const handleMaxExceededModalClose = useCallback(() => {
    setMaxExceededModalOpened(false);
  }, []);

  return (
    <ThemeProvider theme={reportListViewTheme}>
      <Page ref={page} title="" className={"bg-background"}>
        <SubHeader
          title="Reports"
          actions={[
            {
              type: SubHeaderActionType.Button,
              primary: true,
              title: "New Report",
              handler: () => setIsCreateDialogOpen(true),
              accessScope: "reports.create",
            },
          ]}
          hideHeaderBorder={true}
          hideOrgName
        />
        <CreateReportDialog
          open={isCreateDialogOpen}
          onClose={handleDialogClose}
          onSubmit={createReport}
          setMaxExceededModalOpened={setMaxExceededModalOpened}
        />
        <MaximumExceededModal
          isOpened={maxExceededModalOpened}
          onClose={handleMaxExceededModalClose}
          maxCount={appConfig.reports.maxExceededOrgsCount}
        />
        <ReportsTable />
      </Page>
    </ThemeProvider>
  );
};

export const ReportsListView = () => (
  <Routes>
    <Route path="*" element={<ReportsList />} />
    <Route path=":name/:id" element={<ReportView />} />
  </Routes>
);
