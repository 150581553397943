import { ReactElement } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Text from "../../../../../../../shared/components/Text";

export interface LiteSentryGammaAccordionProps {
  accordionTitle: string;
  children: ReactElement;
  dataTestId: string;
}

const LiteSentryGammaAccordion: React.FC<LiteSentryGammaAccordionProps> = ({
  accordionTitle,
  children,
  dataTestId,
}) => {
  return (
    <Accordion
      className="liteSentryGammaAccordion"
      data-testid={`${dataTestId}-accordion`}
    >
      <AccordionSummary
        className="liteSentryGammaAccordionSummary"
        data-testid={`${dataTestId}-accordion-summary`}
      >
        <Text
          fontSize={24}
          classes="!font-semibold !text-typography-secondary"
          dataTestId="lite-sentry-gamma-accordion-title"
        >
          {accordionTitle}
        </Text>
      </AccordionSummary>

      <AccordionDetails
        className="liteSentryGammaAccordionDetails"
        data-testid={`${dataTestId}-accordion-details`}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default LiteSentryGammaAccordion;
