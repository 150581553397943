import { FC, memo, MutableRefObject, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridRowGroupingModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../context/AppContext";
import {
  AccountsReportData,
  AssetInstallEvent,
  AssetInventoryReportDataRow,
  AssetReportDataRow,
  AssetTransferEvent,
  DwellHierarchyReportDataRow,
  FiltersInput,
  MediaActivitiesReportDataRow,
  MileageReportDataRow,
  ReportAlertHistory,
  SensorsReportDataRow,
  TableFilterInput,
  TableFilterLinkOperator,
  TableViewType,
  ReportType,
  TableDomain,
  GetTableDataInput,
  AssetAbsFaultCodesDataRow,
} from "../../../graphql/operations";
import { TableViewMenuRef } from "../../../views/ReportView/AssetReport";
import { ReportGridColDef } from "../../../views/ReportView/helpers/helpers";
import {
  SendTableDataToEmailDialog,
  ServerSideExportFormat,
  Table,
  TableDataModes,
} from "../Table";

export type ReportTableProps = {
  data:
    | AccountsReportData[]
    | AssetTransferEvent[]
    | AssetAbsFaultCodesDataRow[]
    | AssetInstallEvent[]
    | AssetInventoryReportDataRow[]
    | ReportAlertHistory[]
    | DwellHierarchyReportDataRow[]
    | AssetReportDataRow[]
    | MileageReportDataRow[]
    | MediaActivitiesReportDataRow[]
    | SensorsReportDataRow[];
  isLoading?: boolean;
  isSuccess?: boolean;
  apiRef: MutableRefObject<GridApiPremium>;
  columnsVisibilityModel?: GridColumnVisibilityModel;
  changeColumnsVisibility: (model: GridColumnVisibilityModel) => void;
  onColumnOrderChange: (colData: GridColumnOrderChangeParams) => void;
  columns: ReportGridColDef[];
  sortModel?: GridSortModel;
  onSortModelChange?: (model: GridSortModel) => void;
  searchKeys: string[];
  reportName: string;
  onPageChange: (page: number) => void;
  handleDataMode?: TableDataModes;
  currentPage: number;
  pageSize: number;
  rowCount: number;
  onSearch?: (searchQuery: string) => void;
  onFilterChange: (value: {
    items: FiltersInput[] & TableFilterInput[];
    linkOperator: string & TableFilterLinkOperator;
  }) => void;
  tableViewsMenuRef?: MutableRefObject<TableViewMenuRef | null>;
  reportType: string;
  onExport?: (format: ServerSideExportFormat, domain: TableDomain) => void;
  domain: string;
  baseFilters?: Record<string, unknown>;
  queryInput: GetTableDataInput;
  isSendingEmail?: boolean;
  totalCount?: number;
  setSendingEmail?: (arg: boolean) => void;
};

export const ReportTable: FC<ReportTableProps> = memo(
  (props: ReportTableProps) => {
    const {
      reportName,
      isSuccess,
      isLoading,
      data,
      columnsVisibilityModel,
      changeColumnsVisibility,
      onColumnOrderChange,
      columns,
      searchKeys,
      onSearch,
      onPageChange,
      currentPage,
      pageSize,
      rowCount,
      sortModel,
      handleDataMode,
      onSortModelChange,
      onFilterChange,
      tableViewsMenuRef,
      reportType,
      onExport,
      domain,
      baseFilters,
      apiRef,
      queryInput,
      isSendingEmail,
      totalCount,
      setSendingEmail,
      ...rest
    } = props;

    const {
      state: { appConfig },
    } = useAppContext();
    const [rowGroupingModel, setRowGroupingModel] =
      useState<GridRowGroupingModel>();

    let gridApiRef = useGridApiRef();

    if (apiRef) {
      gridApiRef = apiRef;
    }

    const isExportAllowed = useCallback(() => {
      //This flag is temporary solution
      // to implement export for the reports step by step
      // after implementing of export for the all reports this flag should be removed
      switch (reportType) {
        case ReportType.Mileage:
          return true;
        default:
          return false;
      }
    }, [reportType]);

    const onExportClick = useCallback(
      (format: ServerSideExportFormat) => {
        onExport && onExport(format, domain as TableDomain);
      },
      [onExport, domain]
    );

    const onDialogClose = () => {
      setSendingEmail && setSendingEmail(false);
    };

    return (
      <Box
        className="h-full w-full p-4 pt-2 md:px-6 md:pb-10 lg:px-16 lg:pb-20"
        data-testid="asset-report-table-container"
      >
        <Table
          apiRef={gridApiRef}
          tableType={TableViewType.Report}
          tableHeight="570px"
          tableName="report_table"
          columns={columns}
          rows={data}
          loading={isLoading}
          error={!isLoading && !isSuccess ? true : null}
          columnVisibilityModel={columnsVisibilityModel}
          changeColumnsVisibility={changeColumnsVisibility}
          onColumnOrderChange={onColumnOrderChange}
          showToolbar
          disableRowGrouping // TEMPORARY DISABLED UNTIL WE HAVE A BE GROUPING SOLUTION
          getRowId={(row) => row._id}
          allowExport={isExportAllowed()}
          {...rest}
          onExportClick={onExportClick}
          enableSearch={true}
          searchKeys={searchKeys}
          searchMinLength={appConfig.searchMinLength}
          searchExactMatch={true}
          onSearch={onSearch}
          sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
          rowGroupingModel={rowGroupingModel}
          onRowGroupingModelChange={(model: GridRowGroupingModel) => {
            setRowGroupingModel(model);
            props.apiRef.current.setSortModel([
              {
                field: "__row_group_by_columns_group__",
                sort: "asc",
              },
            ]);
          }}
          pagination
          handleDataMode={TableDataModes.Server}
          onPageChange={onPageChange}
          currentPage={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          onPaginationModelChange={onPageChange}
          onFilterModelChange={onFilterChange}
          tableViewsMenuRef={tableViewsMenuRef}
        />
        <SendTableDataToEmailDialog
          baseFilters={baseFilters}
          gridApiRef={gridApiRef}
          tableDomain={domain as TableDomain}
          getDataInput={queryInput}
          open={!!isSendingEmail}
          selectedRowsCount={totalCount ?? 0}
          onClose={onDialogClose}
        />
      </Box>
    );
  }
);
