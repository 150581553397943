import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import {
  AssetOs,
  AssetSortOrder,
  AssetsResult,
  AssetTableData,
  AssetTableDataResponse,
  GetTableDataInput,
  Maybe,
  OrgData,
  Pagination,
  ProductData,
  useGetAssetImeisQuery,
  useGetAssetsForTableOsQuery,
  useGetAssetsTableDataQuery,
} from "../../../graphql/operations";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";
import { useFeatureFlag } from "../../../utils/useFeatureFlag";
import { useAssetsDataContext } from "../../../views/AssetsView/shared/AssetsDataContext/AssetsDataContext";
import { useAssetsComplexFilters } from "../../../views/AssetsView/shared/hooks/useAssetsComplexFilters";
import { getParsedAssetShareData } from "../../helpers/assetShare";
import { useAvailableOrgs } from "../useAvailableOrgs";
import { usePreferredTimezone } from "../usePreferredTimezone";
import { useProductsList } from "../useProductsList";

export interface AssetsTableDataHook {
  isLoading: boolean;
  isSuccess: boolean;
  data: AssetTableData[];
  pagination: Pagination;
}

type MapOpenSearchDataParams = {
  data: Maybe<AssetOs>[];
  currentOrgId: string;
  availableOrgs: OrgData[];
  timezone: string;
  products: ProductData[];
};

export const mapOpenSearchData = ({
  data,
  currentOrgId,
  availableOrgs,
  timezone,
  products,
}: MapOpenSearchDataParams): AssetTableData[] =>
  (data.filter(Boolean) as AssetOs[]).map((asset) => {
    const [lat, lon] = asset.location?.coordinates ?? [];
    const shared = asset?.shared ?? {};
    const parsedShareData = getParsedAssetShareData({
      assetOwnerOrgId: asset?.customer_orgs_id,
      shared,
      currentOrgId,
      availableOrgs,
      timezone,
    });
    const productName = asset.prd_cde
      ? products.find((product) => product._id === asset.prd_cde)?.product_name
      : "";

    return {
      id: asset._id,
      imei: asset.imei ?? "",
      assetIdentifier: asset.asset_id ?? "",
      vin: asset.vin ?? "",
      associated: asset.associated ?? "",
      name: asset.name ?? "",
      orgId: asset.customer_orgs_id ?? "",
      orgName: asset.org_name ?? "",
      productName: productName ?? "",
      category: asset.category ?? "",
      tags: asset.tags ?? "",

      state: asset.fullAddress?.state ?? "",
      zip: asset.fullAddress?.postcode ?? "",
      city: asset.fullAddress?.city ?? "",
      lat: lat,
      lon: lon,

      tripStatus: asset.tripStatus?.tripState ?? "",
      batteryVolt: asset.sensors?.voltage?.data?.battery,
      batteryStatus: asset.sensors?.voltage?.data?.statusBattery ?? "",
      cargoState: asset.tripStatus?.cargoState ?? "",
      doorType: asset.door_type ?? "",
      numOfAxles: asset.num_of_axles,
      numOfTires: asset.wheel_config,
      length: asset.length,
      lastGpsTime: asset.last_gps_t ?? "",
      lastEventTime: asset.lst_evnt_t ?? "",
      dwellingDays: asset.dwelling?.dwellingDays,
      signal: asset.tripStatus?.signal,
      geofence: asset.geofence,

      ...parsedShareData,
    };
  });

export const useAssetsTableData = (
  queryInput: GetTableDataInput
): AssetsTableDataHook => {
  const {
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const availableOrgs = useAvailableOrgs();
  const { products } = useProductsList();
  const timezone = usePreferredTimezone();

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState<AssetTableData[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    skip: queryInput.pagination?.skip ?? 0,
    limit: queryInput.pagination?.limit ?? appConfig.table.defaultRowsPerPage,
  });

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const { complexFiltersQuery } = useAssetsComplexFilters();

  const { assetsInput } = useAssetsDataContext();

  const {
    data: queryDataDocumentDb,
    isSuccess: isSuccessDocumentDb,
    isLoading: isLoadingDocumentDb,
  } = useGetAssetsTableDataQuery<AssetTableDataResponse>(
    { input: queryInput },
    {
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getAssetsTableData }) => getAssetsTableData,
      enabled:
        !fetchAssetsFromOpenSearchFeatureFlag && Boolean(complexFiltersQuery),
    }
  );

  const { data: imeisDataDocumentDb } = useGetAssetImeisQuery(
    {
      input: { ...assetsInput, cluster: false },
    },
    { enabled: !fetchAssetsFromOpenSearchFeatureFlag }
  );

  const { sorting } = queryInput;
  const sortField =
    sorting && sorting?.length > 0 ? sorting[0].field : "lst_evnt_t";
  const sortOrder =
    sorting && sorting?.length > 0 ? sorting[0].order : AssetSortOrder.Desc;

  const {
    data: queryDataOS,
    isSuccess: isSuccessOS,
    isLoading: isLoadingOS,
  } = useGetAssetsForTableOsQuery<AssetsResult>(
    {
      input: {
        skip: queryInput.pagination?.skip,
        limit: queryInput.pagination?.limit,
        search: queryInput.subStringSearchText,
        sort: {
          field: sortField,
          direction: sortOrder as AssetSortOrder,
        },
        viewport: queryInput.viewport,
        cluster: false,
        filterList: queryInput.tableFilters,
        complexFilters: JSON.stringify(complexFiltersQuery),
      },
    },
    {
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getAssetsOS }) => getAssetsOS,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag && Boolean(complexFiltersQuery),
    }
  );

  useEffect(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      setIsLoading(isLoadingOS);
      setIsSuccess(isSuccessOS);
    } else {
      setIsLoading(isLoadingDocumentDb);
      setIsSuccess(isSuccessDocumentDb);
    }
  }, [
    fetchAssetsFromOpenSearchFeatureFlag,
    isLoadingDocumentDb,
    isLoadingOS,
    isSuccessDocumentDb,
    isSuccessOS,
  ]);

  useEffect(() => {
    if (queryDataDocumentDb && imeisDataDocumentDb) {
      setData(queryDataDocumentDb.data);
      setPagination(queryDataDocumentDb.pagination);
    } else if (queryDataOS) {
      setData(
        mapOpenSearchData({
          data: queryDataOS?.assets ?? [],
          currentOrgId: selectedOrganization.value,
          availableOrgs,
          timezone,
          products,
        })
      );
      setPagination({
        total: queryDataOS?.total ?? 0,
        skip: queryDataOS.skip ?? 0,
        limit: appConfig.table.defaultRowsPerPage,
      });
    }
  }, [
    appConfig,
    queryDataDocumentDb,
    imeisDataDocumentDb,
    queryDataOS,
    selectedOrganization,
    availableOrgs,
    timezone,
    products,
  ]);

  return {
    isLoading,
    isSuccess,
    pagination,
    data,
  };
};
