import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { nanoid } from "nanoid";

export interface SensorSliderLegendProps {
  reversed?: boolean;
}

const SensorSliderLegend: FC<SensorSliderLegendProps> = ({ reversed }) => {
  const defaultLegend = [
    { text: "Healthy", color: "green" },
    { text: "Warning", color: "yellow-custom-slider" },
    { text: "Alert", color: "warning" },
    { text: "Critical", color: "error" },
  ];
  const legend = reversed ? defaultLegend.reverse() : defaultLegend;

  return (
    <Box className="flex" data-testid="sensor-slider-legend">
      {legend.map((item, index) => (
        <Typography
          data-testid={`sensor-slider-legend-item-${index + 1}`}
          className="flex-1 text-center !font-bold"
          key={nanoid()}
          sx={{
            color: item.color
              ? `var(--${item.color})`
              : "var(--battery-settings)",
          }}
        >
          {item.text}
        </Typography>
      ))}
    </Box>
  );
};

export default SensorSliderLegend;
