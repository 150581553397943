import React, { createContext, useContext, useState } from "react";
import { DEFAULT_CHART_DATE_RANGE_OPTION } from "../../../../../../constants/map";
import { CargoCameraFloorSpaceData } from "./types";

interface InternalCameraFloorSpaceGraphContextValue {
  // Setup the date range filters for the graph
  startDate: Date | undefined;
  setStartDate: (date: Date) => void;

  endDate: Date | undefined;
  setEndDate: (date: Date) => void;

  selectedDateRangeOption: number;
  setSelectedDateRangeOption: (value: number) => void;

  // Contain the data fetched from the server
  aggregatedCargoCameraFloorSpaceData: CargoCameraFloorSpaceData[];
  setAggregatedCargoCameraFloorSpaceData: (
    data: CargoCameraFloorSpaceData[]
  ) => void;
}

const InternalCameraFloorSpaceGraphContext = createContext<
  InternalCameraFloorSpaceGraphContextValue | undefined
>(undefined);

interface InternalCameraFloorSpaceGraphContextProps {
  children: React.ReactNode;
}

export const InternalCameraFloorSpaceGraphProvider: React.FC<
  InternalCameraFloorSpaceGraphContextProps
> = ({ children }: InternalCameraFloorSpaceGraphContextProps) => {
  // Setup the date range filters for the graph
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [selectedDateRangeOption, setSelectedDateRangeOption] =
    useState<number>(DEFAULT_CHART_DATE_RANGE_OPTION);

  // Contain the data fetched from the server
  const [
    aggregatedCargoCameraFloorSpaceData,
    setAggregatedCargoCameraFloorSpaceData,
  ] = useState<CargoCameraFloorSpaceData[]>([]);

  const contextValue: InternalCameraFloorSpaceGraphContextValue = {
    startDate,
    setStartDate,

    endDate,
    setEndDate,

    selectedDateRangeOption,
    setSelectedDateRangeOption,

    aggregatedCargoCameraFloorSpaceData,
    setAggregatedCargoCameraFloorSpaceData,
  };

  return (
    <InternalCameraFloorSpaceGraphContext.Provider value={contextValue}>
      {children}
    </InternalCameraFloorSpaceGraphContext.Provider>
  );
};

export const useInternalCameraFloorSpaceGraphContext = () => {
  const context = useContext(InternalCameraFloorSpaceGraphContext);
  if (!context) {
    throw new Error(
      "useInternalCameraFloorSpaceGraphContext must be used within an InternalCameraFloorSpaceGraphProvider"
    );
  }
  return context;
};
