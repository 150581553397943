import { Box, Typography } from "@mui/material";
import UnderDevelopmentIcon from "../../components/Svg/UnderDevelopment";

const UnderDevelopment = () => {
  return (
    <Box
      className="flex items-center py-[4.25rem] px-[5.7rem] my-10 mx-[auto] shadow-[0px_4px_16px_0px_rgba(0,0,0,0.16)] rounded-2xl bg-background-secondary"
      data-testid="under-development"
    >
      <UnderDevelopmentIcon className="mr-8" />
      <Typography className="!font-bold !text-3xl text-typography">
        Under Development
      </Typography>
    </Box>
  );
};

export default UnderDevelopment;
