import { FC, ReactElement, ReactNode } from "react";
import { ThemeProvider, Tooltip } from "@mui/material";
import { useTooltipTheme } from "./useTooltipTheme";

interface CustomTooltipProps {
  title: string | ReactNode;
  arrow?: boolean;
  disableInteractive?: boolean;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
  children: ReactElement<any, any>;
}
export const CustomTooltip: FC<CustomTooltipProps> = ({
  title,
  arrow,
  disableInteractive,
  placement,
  children,
}) => {
  const theme = useTooltipTheme();

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={title}
        arrow={arrow}
        placement={placement ?? "top"}
        disableInteractive={disableInteractive}
      >
        {children}
      </Tooltip>
    </ThemeProvider>
  );
};
