import { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { GridRowParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { ColorsPalette } from "../../../../../../../../design-system/colors-palette";
import {
  LiftgateFaultHistoryData,
  useGetLiftgateFaultHistoryTableDataQuery,
} from "../../../../../../../../graphql/operations";
import {
  Button,
  TextButton,
} from "../../../../../../../../shared/components/Button";
import { Table } from "../../../../../../../../shared/components/Table";
import useBreakpoint from "../../../../../../../../shared/hooks/useBreakpoint";
import {
  DATE_TIME_FORMAT_SHORT,
  formatDate,
} from "../../../../../../../../utils";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../../../../../AdminPanel/tabs/constants";
import { LiftgateHeader } from "../../header/LiftgateHeader";
import { columns, columnVisibilityModel } from "./utils";

type LiftgateFaultHistoryTableProps = {
  imei: string;
};

const modalLabelStyle = {
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "16px",
  textAlign: "left",
  color: "var(--primary)",
};

const modalValueStyle = {
  marginTop: "4px",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "14px",
  textAlign: "left",
  color: "var(--primary)",
};

const LiftgateFaultHistoryTable: React.FC<LiftgateFaultHistoryTableProps> = ({
  imei,
}) => {
  const [tableRows, setTableRows] = useState<LiftgateFaultHistoryData[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState({
    startTime: "",
    endTime: "",
    name: "",
    description: "",
  });
  const isMobile = useBreakpoint("down", "md");

  const apiRef = useGridApiRef();
  const { data, isLoading, isError } = useGetLiftgateFaultHistoryTableDataQuery(
    {
      input: {
        imei,
      },
    }
  );

  useEffect(() => {
    if (data?.getLiftgateFaultHistoryTableData?.data) {
      setTableRows(data.getLiftgateFaultHistoryTableData.data ?? []);
    }
  }, [data?.getLiftgateFaultHistoryTableData?.data]);

  const onRowClick = useCallback((params: GridRowParams) => {
    setModalState({
      startTime: params.row.startTime,
      endTime: params.row.endTime,
      name: params.row.name,
      description: params.row.description,
    });

    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "full",
          width: "full",
          backgroundColor: "var(--card__bg)",
          borderRadius: "0.5rem",
        }}
        data-testid="liftgate-fault-history-table-wrapper"
      >
        {/* Header */}
        <Box
          sx={{
            paddingTop: "1rem",
            margin: "1rem",
          }}
          data-testid="liftgate-fault-history-table-header"
        >
          <LiftgateHeader text="Liftgate Fault History Table" />
        </Box>

        {/* Table */}
        <Box
          sx={{
            height: "50%",
            padding: "1rem",
          }}
        >
          <Table
            sx={{
              height: "23.3rem",
            }}
            showPopover={false}
            showToolbar={false}
            pagination={false}
            rows={tableRows}
            apiRef={apiRef}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            onRowClick={onRowClick}
            loading={isLoading}
            error={isError}
            tableName="LiftgateFaultHistory"
            isDataRefetching={false}
            mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
            disableColumnMenu
            disableColumnReorder
            disableColumnResize
          />
        </Box>
      </Box>

      {/* Modal */}
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "47vh",
            width: isMobile ? "80vw" : "40vw",
            overflowY: "auto",
            backgroundColor: "var(--card__bg)",
            borderRadius: "0.5rem",
          }}
          data-testid="liftgate-fault-history-modal-wrapper"
        >
          <Box
            sx={{ marginLeft: "40px", marginRight: "40px", marginTop: "22px" }}
          >
            <IconButton
              style={{
                height: "fit-content",
                float: "right",
                color: "var(--primary)",
              }}
              aria-label="close"
              data-testid="liftgate-fault-history-modal-icon"
              onClick={closeModal}
            >
              <CloseIcon />
            </IconButton>

            {/* Modal Header */}
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "1.5rem",
                fontWeight: 600,
                lineHeight: "2rem",
                letterSpacing: "-0.02em",
                textAlign: "left",
                color: "var(--primary)",
              }}
              data-testid="liftgate-fault-history-modal-header"
            >
              Fault Details
            </Typography>

            {/* Modal Body */}
            <Grid
              container
              direction="row"
              spacing="2"
              rowSpacing="5"
              justifyContent="space-between"
              sx={{
                marginTop: "2.1875rem",
              }}
              data-testid="liftgate-fault-history-modal-body"
            >
              <Grid item>
                <Typography sx={modalLabelStyle}>Start Time</Typography>
                <Typography sx={modalValueStyle}>
                  {formatDate(modalState.startTime, DATE_TIME_FORMAT_SHORT)}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={modalLabelStyle}>End Time</Typography>
                <Typography sx={modalValueStyle}>
                  {formatDate(modalState.endTime, DATE_TIME_FORMAT_SHORT)}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={modalLabelStyle}>Name</Typography>
                <Typography sx={modalValueStyle}>{modalState.name}</Typography>
              </Grid>
            </Grid>

            <Box sx={{ marginTop: "24px" }}>
              <Typography sx={modalLabelStyle}>Description</Typography>
              <Typography
                sx={{ ...modalValueStyle, lineHeight: "1.375rem" }}
                paragraph
              >
                {modalState.description}
              </Typography>
            </Box>
          </Box>

          <Button
            sx={{
              position: "absolute",
              bottom: "2rem",
              right: "2rem",
            }}
            text={"Close"}
            size="medium"
            theme="blue"
            variant="default"
            onClick={closeModal}
            data-testid="liftgate-fault-history-modal-wrapper"
          />
        </Box>
      </Modal>
    </>
  );
};

export default LiftgateFaultHistoryTable;
