import { AutocompleteOption } from "../../../../../../../types/form";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../../../../AssetsView/TableView/hooks";

interface ManufacturerOptionsHook {
  manufacturers: AutocompleteOption[];
}

export const useManufacturerOptions = (): ManufacturerOptionsHook => {
  const manufacturers = useNomenclatures(NOMENCLATURE_NAMES.partsManufacturer);

  return {
    manufacturers,
  };
};
