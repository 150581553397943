import { useMemo, useState, ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { isNumber, isUndefined, kebabCase } from "lodash";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { TooltipProps } from "recharts/types/component/Tooltip";
import { Props as DotProps } from "recharts/types/shape/Dot";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import { BATTERY_CHART_NAME } from "../../Assets/BatteryTabPanel/types";
import BatteryChartLegend from "./BatteryChartLegend";

const darkColors = ["#339933", "#3C88CC", "#F2F2F2", "#E78D3A"];
const lightColors = ["#6AC87E", "#3C88CC", "#121212", "#E78D3A"];
const containerHeight = 330;
const chartHeight = containerHeight - 40; // containerHeight - xAxisHeight(30) - margins(5 * 2)
const yAxisTickCount = 10;
const yAxisTickLev = 10;

export const getChartYAxisDomains = (
  data: Record<string, any>[],
  lineKeys: string[]
) => {
  const [maxVoltage, maxAmps] = data.reduce<[number, number]>(
    (prevMax, value) => {
      const [maxVoltage, maxAmps] = lineKeys.reduce(
        (pv, cv) => {
          const v = (value as any)[cv] ?? 0;
          const voltage = cv === "Solar" ? 0 : Math.max(v, pv[0]);
          const amps = cv === "Solar" ? Math.max(v, pv[1]) : 0;
          return [Math.max(voltage, pv[0]), Math.max(amps, pv[1])];
        },
        [0, 0]
      );
      return [Math.max(prevMax[0], maxVoltage), Math.max(prevMax[1], maxAmps)];
    },
    [0, 0]
  );

  return [
    [
      0,
      Math.max(
        (Math.ceil((maxVoltage * 100) / yAxisTickLev) * yAxisTickLev) / 100,
        1
      ),
    ],
    [
      0,
      Math.max(
        (Math.ceil((maxAmps * 100) / yAxisTickLev) * yAxisTickLev) / 100,
        yAxisTickLev / 100
      ),
    ],
  ];
};

export const getTicksFromDomain = (domain: Array<number>) => {
  const tickInterval = (domain[1] - domain[0]) / yAxisTickCount;
  return Array(yAxisTickCount + 1)
    .fill(0)
    .map((_, index) => (tickInterval * index).toFixed(2));
};

export const CustomizedTooltip = ({
  active,
  payload,
  activeIndex,
  domains,
}: TooltipProps<any, any> & {
  activeIndex?: number;
  domains: Array<Array<number>>;
}) => {
  const itemsToDisplay = useMemo(() => {
    if (
      isUndefined(activeIndex) ||
      isUndefined(payload) ||
      !active ||
      !payload[activeIndex]
    ) {
      return [];
    }

    const getDotHeight = (item: any) =>
      (item.value * chartHeight) /
      (item.name === "Solar" ? domains[1][1] : domains[0][1]);

    const intersectItems =
      payload
        ?.filter(
          (item) =>
            Math.abs(getDotHeight(item) - getDotHeight(payload[activeIndex])) <=
            10
        )
        ?.map((item) => ({
          label: item.name,
          min: item.payload.min[item.dataKey!],
          max: item.payload.max[item.dataKey!],
          unit: item.payload.units[item.dataKey!],
        })) ?? [];

    return intersectItems;
  }, [active, activeIndex, domains, payload]);

  return active &&
    payload?.length &&
    !isUndefined(activeIndex) &&
    payload[activeIndex] ? (
    <Box className="rounded-lg bg-custom-tooltip-background border border-sub-header-border p-2 flex flex-col gap-2">
      <Typography
        className="!text-xs !text-sub-header-text !font-medium !leading-3"
        data-testid="battery-chart-tooltip-label"
      >
        {payload[activeIndex].payload.tooltipLabel ??
          payload[activeIndex].payload?.date?.replaceAll("-", "/")}
      </Typography>

      {itemsToDisplay.map((item, index) => (
        <Box
          key={item.label}
          className="flex items-center gap-4"
          data-testid={`battery-chart-tooltip-value-${index}`}
        >
          <Typography className="!text-xs !text-sub-header-text !font-medium !leading-3">
            {item.label}
          </Typography>
          <Typography className="!text-xs !text-sub-header-text !font-medium !leading-3">
            {`${item.min.toFixed(2)}${item.unit} - ${item.max.toFixed(2)}${
              item.unit
            }`}
          </Typography>
        </Box>
      ))}
    </Box>
  ) : null;
};

export const CustomizedDot = <TValue extends Record<string, any>>(
  props: DotProps & {
    data: TValue[];
    index: number;
    dataKey: string;
    payload: any;
  }
) => {
  const { cx, cy, stroke, width, height, data, index, dataKey, payload } =
    props;

  const hasNoConnection = useMemo(() => {
    const hasNextValue =
      index < data.length - 1
        ? isNumber((data[index + 1] as any)[dataKey])
        : false;
    const hasPrevValue =
      index > 0 ? isNumber((data[index - 1] as any)[dataKey]) : false;

    return !hasNextValue && !hasPrevValue;
  }, [data, dataKey, index]);

  return isNumber(cx) && isNumber(cy) && hasNoConnection ? (
    <circle
      data-testid={`chart-line-dot-${kebabCase(dataKey)}-${payload[dataKey]}`}
      r={2}
      strokeWidth={2}
      stroke={stroke}
      fill={stroke}
      width={width}
      height={height}
      cx={cx}
      cy={cy}
    />
  ) : null;
};

const BatteryChart = <TValue extends Record<string, any>>({
  data,
  lineKeys,
  xKey,
  typeChart,
  showLegend = true,
  leftYAxisTicks,
  customTooltip,
  xAxisTickFormatter,
}: {
  data: TValue[];
  lineKeys: string[];
  xKey: string;
  typeChart?: BATTERY_CHART_NAME;
  showLegend?: boolean;
  leftYAxisTicks?: number[];
  customTooltip?: (payload: any, hoveredLineIndex?: number) => ReactElement;
  xAxisTickFormatter?: (tickItem: any) => string;
}) => {
  const muiTheme = useCurrentTheme();
  const isDarkMode = muiTheme.palette.mode === "dark";
  const [hoveredLineIndex, setHoveredLineIndex] = useState<number>();
  const [hiddenLines, setHiddenLines] = useState<string[]>([]);

  const chartColors = isDarkMode ? darkColors : lightColors;

  const tickStyle = useMemo(
    () => ({
      fontSize: 10,
      fill: isDarkMode ? "#FCFCFC" : "#808080",
      fontWeight: 500,
    }),
    [isDarkMode]
  );

  const calculatedDomains = useMemo(
    () => getChartYAxisDomains(data, lineKeys),
    [data, lineKeys]
  );

  const visibleLines = lineKeys.filter((key) => !hiddenLines.includes(key));

  return (
    <Box>
      <Box className="relative" style={{ height: containerHeight }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              left: 0,
              right: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke="#DFDFDF" />
            <XAxis
              dataKey={xKey}
              tick={tickStyle}
              tickLine={false}
              tickFormatter={xAxisTickFormatter}
            />
            <YAxis
              yAxisId="voltage"
              type="number"
              orientation="left"
              tick={tickStyle}
              tickLine={false}
              ticks={
                leftYAxisTicks
                  ? leftYAxisTicks
                  : !typeChart
                  ? getTicksFromDomain(calculatedDomains[0])
                  : undefined
              }
              label={{
                value: "Voltage",
                angle: -90,
                position: "insideLeft",
                style: { textAnchor: "middle", ...tickStyle },
              }}
              domain={!typeChart ? calculatedDomains[0] : ["auto", "auto"]}
            />
            {!typeChart && (
              <YAxis
                yAxisId="amperage"
                type="number"
                orientation="right"
                tick={tickStyle}
                tickLine={false}
                ticks={getTicksFromDomain(calculatedDomains[1])}
                label={{
                  value: "Amps",
                  angle: 90,
                  position: "insideRight",
                  style: { textAnchor: "middle", ...tickStyle },
                }}
                domain={calculatedDomains[1]}
              />
            )}

            <Tooltip
              content={
                customTooltip ? (
                  customTooltip(calculatedDomains, hoveredLineIndex)
                ) : (
                  <CustomizedTooltip
                    activeIndex={hoveredLineIndex}
                    domains={calculatedDomains}
                  />
                )
              }
            />
            {lineKeys.map((key, index) =>
              hiddenLines.includes(key) ? null : (
                <Line
                  key={key}
                  strokeWidth={3}
                  yAxisId={key === "Solar" ? "amperage" : "voltage"}
                  type="linear"
                  dataKey={key}
                  stroke={chartColors[typeChart ? index : index + 1]}
                  activeDot={{
                    onMouseOver: () =>
                      setHoveredLineIndex(
                        visibleLines.findIndex((k) => k === key)
                      ),
                    onMouseOut: () => setHoveredLineIndex(undefined),
                  }}
                  dot={(props) => (
                    <CustomizedDot<TValue> {...props} data={data} />
                  )}
                />
              )
            )}
          </LineChart>
        </ResponsiveContainer>
      </Box>
      {!typeChart && showLegend && (
        <Box className="my-12 flex justify-center gap-8">
          {lineKeys.map((key, index) => (
            <>
              {/* Condition checks if the line button is supplied from parent */}
              {key && (
                <BatteryChartLegend
                  key={key}
                  label={key}
                  checked={!hiddenLines.includes(key)}
                  color={chartColors[typeChart ? index : index + 1]}
                  onClick={() =>
                    setHiddenLines((prevState) =>
                      prevState.includes(key)
                        ? prevState.filter((k) => k !== key)
                        : [...prevState, key]
                    )
                  }
                />
              )}
            </>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default BatteryChart;
