import { memo, SVGProps, FC } from "react";

interface PausedProps extends SVGProps<SVGSVGElement> {}

const Paused: FC<PausedProps> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_19233_82940)">
        <path
          d="M6 1C3.24 1 1 3.24 1 6C1 8.76 3.24 11 6 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 6 1ZM5.5 8H4.5V4H5.5V8ZM7.5 8H6.5V4H7.5V8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_19233_82940">
          <rect width="12" height="12" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(Paused);
