import { memo, SVGProps, FC } from "react";

interface RunningProps extends SVGProps<SVGSVGElement> {}

const Running: FC<RunningProps> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_20913_12246)">
        <path
          d="M8 3L9.145 4.145L6.705 6.585L4.705 4.585L1 8.295L1.705 9L4.705 6L6.705 8L9.855 4.855L11 6V3H8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_20913_12246">
          <rect width="12" height="12" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(Running);
