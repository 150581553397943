import {
  getEndOfToday,
  getEndOfWeek,
  getEndOfYesterday,
  getStartOfDay,
  getStartOfToday,
  getStartOfWeek,
  getSubDays,
} from "../../../utils/date";

export enum DateRangeLabel {
  Today = "Today",
  Last2Days = "Last 2 Days",
  ThisWeek = "This Week",
  LastWeek = "Last Week",
  Last30Days = "Last 30 Days",
}

export interface DateRangeItem {
  label: DateRangeLabel;
  getValue: () => [Date, Date];
}

export const PREDEFINED_DATE_RANGES: DateRangeItem[] = [
  {
    label: DateRangeLabel.Today,
    getValue: () => {
      const startDate = getStartOfToday();
      const endDate = getEndOfToday();

      return [startDate, endDate];
    },
  },
  {
    label: DateRangeLabel.Last2Days,
    getValue: () => {
      const today = new Date();
      const startDate = getStartOfDay(getSubDays(today, 2));
      const endDate = getEndOfYesterday();

      return [startDate, endDate];
    },
  },
  {
    label: DateRangeLabel.ThisWeek,
    getValue: () => {
      const today = new Date();
      const startDate = getStartOfWeek(today);
      const endDate = getEndOfWeek(today);

      return [startDate, endDate];
    },
  },
  {
    label: DateRangeLabel.LastWeek,
    getValue: () => {
      const today = new Date();
      const previousWeek = getSubDays(today, 7);
      const startDate = getStartOfWeek(previousWeek);
      const endDate = getEndOfWeek(previousWeek);

      return [startDate, endDate];
    },
  },
  {
    label: DateRangeLabel.Last30Days,
    getValue: () => {
      const today = new Date();

      return [getStartOfDay(getSubDays(today, 30)), getEndOfToday()];
    },
  },
];

export const DESKTOP_CALENDAR_MONTHS = 2;
export const MOBILE_CALENDAR_MONTHS = 1;
