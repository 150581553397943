import { FC, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Button } from "../../../shared/components/Button";
import { useCheckRouteSecurity } from "../../../utils/routes/useCheckRouteSecurity";

export interface ShareAssetsInvitationErrorProps {
  title: string;
  children?: ReactElement;
}

export const ShareAssetsInvitationError: FC<
  ShareAssetsInvitationErrorProps
> = ({ title, children }) => {
  const navigate = useNavigate();

  const { defaultPath, isLoadingDefaultPath } = useCheckRouteSecurity();
  const [shouldRedirectToRoot, setShouldRedirectToRoot] = useState(false);

  useEffect(() => {
    if (shouldRedirectToRoot && !isLoadingDefaultPath) {
      setShouldRedirectToRoot(false);
      navigate(defaultPath, { replace: true });
    }
  }, [defaultPath, isLoadingDefaultPath, shouldRedirectToRoot, navigate]);

  const goToHomepage = () => {
    setShouldRedirectToRoot(true);
  };

  return (
    <Box
      className="flex flex-col justify-center items-center text-center w-full h-full px-16 pt-8 pb-16"
      data-testid="share-assets-invitation---error--container"
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "48px",
          fontWeight: "600",
          lineHeight: "64px",
          textTransform: "capitalize",
          marginBottom: "50px",
        }}
        className="text-sub-header-text"
        data-testid="share-assets-invitation---error--title"
      >
        {title}
      </Typography>

      {children && (
        <Box
          sx={{
            fontSize: "30px",
            fontWeight: "400",
            lineHeight: "38px",
            marginBottom: "50px",
          }}
          className="text-sub-header-text"
          data-testid="share-assets-invitation--error--children-container"
        >
          {children}
        </Box>
      )}

      <Button
        size="medium"
        theme="blue"
        variant="default"
        text="Go to Homepage"
        onClick={goToHomepage}
        dataTestid="share-assets-invitation--error--children-go-to-homepage-btn"
      />
    </Box>
  );
};
