import { Box, Skeleton } from "@mui/material";

export const DoubleGridWidgetOverlay = () => {
  const rows = ["upper", "lower"];
  return (
    <Box
      data-testid="loading-overlay-container"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {rows.map((row) => (
        <Box
          display="flex"
          justifyContent="space-between"
          data-testid={`${row}-row`}
        >
          <Box marginBottom="24px" marginRight="30px" width="100%">
            <Skeleton
              variant="rounded"
              width="65%"
              sx={{
                marginBottom: "8px",
              }}
            />
            <Skeleton variant="rounded" />
          </Box>
          <Box width="100%">
            <Skeleton
              variant="rounded"
              width="65%"
              sx={{
                marginBottom: "8px",
              }}
            />
            <Skeleton variant="rounded" />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
