import { MouseEvent, ReactNode, useCallback, useMemo, useState } from "react";
import SectionsIcon from "@mui/icons-material/Dashboard";
import { Box, Button, Tab } from "@mui/material";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useAuthContext } from "../../../context/AuthContext";
import useBreakpoint from "../../hooks/useBreakpoint";
import BackIcon from "../BackIcon/BackIcon";
import Tabs from "../Tabs";
import { TabsVariant } from "../Tabs/Tabs";
import { MenuOption, ThreeDotsMenu } from "../ThreeDotsMenu/ThreeDotsMenu";
import { DashboardFrameSubtitle } from "./components/DashboardFrameSubtitle/DashboardFrameSubtitle";
import { DashboardFrameTitle } from "./components/DashboardFrameTitle/DashboardFrameTitle";
import {
  DashboardSectionsMenu,
  Sections,
} from "./components/DashboardSectionsMenu/DashboardSectionsMenu";

export enum TabsSecurityMapping {
  Summary = "summary",
  Battery = "battery",
  Cargo = "cargo",
  Tires = "tires",
  Media = "media",
  Brakes = "brakes",
  Temperature = "temperature",
  "Light Circuit" = "lightCircuit",
  Liftgate = "liftgate",
  Dwell = "dwell",
  Breadcrumbs = "breadcrumbs",
  Alerts = "alerts",
  Settings = "settings",
  "Activity Log" = "activityLog",
  "Other Sensors" = "otherSensors",
  "Event History" = "eventHistory",
  "Detention History" = "detentionHistory",
}

export interface SectionsItem {
  visible: boolean;
  icon: JSX.Element;
  title: string;
  section: Sections;
}

export interface DashboardFrameProps {
  title?: string;
  subtitle?: string;
  assetId?: string;
  geofenceId?: string;
  children: ReactNode[] | string[];
  tabs: Array<{ label: string; value: number }>;
  activeTab: number;
  onTabChange: (tab: number) => any;
  onBack: () => void;
  backButtonText?: string;
  showSectionsMenu?: boolean;
  sectionItemsList?: SectionsItem[];
  shouldDisableTabs?: boolean;
  geofenceMenuOptions?: MenuOption[];
}

const DashboardFrame = ({
  title,
  subtitle,
  children,
  activeTab,
  tabs,
  onTabChange,
  onBack,
  backButtonText,
  showSectionsMenu,
  sectionItemsList = [],
  shouldDisableTabs = false,
  geofenceId,
  geofenceMenuOptions,
}: DashboardFrameProps) => {
  const { userRolePermissions } = useAuthContext();
  const shouldScroll = useBreakpoint("down", "xl");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const tabIndex = tabs?.findIndex((tab) => tab.value === activeTab);

  // Memos
  const sectionsButtonDisabled = useMemo(
    () => isEmpty(sectionItemsList.filter(({ visible }) => visible)),
    [sectionItemsList]
  );
  const buttonColorToUse = useMemo(
    () =>
      sectionsButtonDisabled
        ? "var(--light-charcoal) !important"
        : "var(--brand) !important",
    [sectionsButtonDisabled]
  );

  // filter asset dashboard tabs based on user permissions
  if (!geofenceId) {
    tabs = tabs.filter((tab) => {
      const tabName =
        TabsSecurityMapping[tab.label as keyof typeof TabsSecurityMapping];
      return userRolePermissions.assetDashboardTabs?.[tabName]?.view ?? false;
    });
  }

  return (
    <Box className="flex h-full flex-col" data-testid="dashboard-frame">
      <Box className="flex h-14 items-center justify-between border border-solid border-sub-header-border bg-dashboard_subheader__bg px-4">
        <Button
          className="!text-sm !font-bold !text-brand !normal-case"
          size="small"
          startIcon={<BackIcon color={"var(--brand)"} />}
          onClick={onBack}
          data-testid="dashboard-frame-back-to-map-btn"
        >
          {backButtonText ?? "Back"}
        </Button>
        <Box className="flex items-center gap-4 text-header-text">
          <DashboardFrameTitle text={title ?? ""} />
          {geofenceId && geofenceMenuOptions ? (
            <ThreeDotsMenu menuOptions={geofenceMenuOptions} />
          ) : (
            ""
          )}
          <DashboardFrameSubtitle text={subtitle ?? ""} />
        </Box>
      </Box>

      <Tabs
        tabs={tabs}
        centered={!shouldScroll}
        variant={shouldScroll ? TabsVariant.Scroll : TabsVariant.Standard}
        controlled={true}
        scrollButtons={shouldScroll}
        allowScrollButtonsMobile={shouldScroll}
        value={activeTab}
        onClick={(value) => onTabChange(value)}
        showTabContent={false}
        tabClasses={{ "border-t-0 flex justify-center": true }}
        shouldDisableTabs={shouldDisableTabs}
      >
        {(activeTab) => (
          <Tab
            label={tabs[activeTab].label}
            value={tabs[activeTab].value}
            key={tabs[activeTab].label}
          />
        )}
      </Tabs>

      {showSectionsMenu && (
        <Box
          className="flex h-14 items-center shadow-card-sm justify-end bg-card__placeholder px-4"
          data-testid="sections-menu"
        >
          <Button
            sx={{
              color: buttonColorToUse,
            }}
            data-testid="dashboard-frame__sections-button"
            disabled={sectionsButtonDisabled}
            startIcon={
              <SectionsIcon
                sx={{ color: buttonColorToUse, fill: buttonColorToUse }}
              />
            }
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(e?.currentTarget);
            }}
          >
            SECTIONS
          </Button>

          <DashboardSectionsMenu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            sectionItemsList={sectionItemsList}
            onClose={handleClose}
          />
        </Box>
      )}

      <Box
        className={classNames("flex-1 overflow-auto bg-panel_content__bg", {
          "p-4": tabIndex === 0 || tabIndex === 1,
        })}
        data-testid="dashboard-frame-tab-content"
      >
        {children[tabIndex]}
      </Box>
    </Box>
  );
};

export default DashboardFrame;
