import { HTMLAttributes, memo, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import {
  PRODUCT_CREATE_FAILED_DUPLICATE_CODE_PAYLOAD,
  PRODUCT_CREATE_FAILED_DUPLICATE_NAME_PAYLOAD,
  PRODUCT_CREATE_FAILED_PAYLOAD,
  PRODUCT_CREATE_SUCCESS_PAYLOAD,
  PRODUCT_DELETE_FAILED_PAYLOAD,
  PRODUCT_DELETE_SUCCESS_PAYLOAD,
  PRODUCT_UPDATE_FAILED_DUPLICATE_CODE_PAYLOAD,
  PRODUCT_UPDATE_FAILED_DUPLICATE_NAME_PAYLOAD,
  PRODUCT_UPDATE_FAILED_PAYLOAD,
  PRODUCT_UPDATE_SUCCESS_PAYLOAD,
} from "../../../../../../constants/product";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  ConfigurationSetType,
  CreateConfigurationSetInput,
  DeleteConfigurationSetInput,
  ProductData,
  UpdateConfigurationSetInput,
  useFindConfigurationSetByIdQuery,
} from "../../../../../../graphql/operations";
import { useConfigurationSetsApi } from "../../../../hooks/useConfigurationSetsApi";
import {
  DrawerType,
  ERROR_CONFIGURATION_SET_NAME_DUPLICATE,
  ERROR_PRODUCT_CODE_CONFIGURATION_SET_DUPLICATE,
} from "../../configurationsUtils";
import { ProductForm } from "./product-form/ProductForm";

interface ProductJSON {
  product_code: string;
  asset_configuration: string;
}

export interface ProductsDrawerProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onChange" | "onSubmit"> {
  selectedConfigId?: string;
  isOpen?: boolean;
  onClose: () => void;
  type?: DrawerType;
}
export const ProductsDrawer: React.FC<ProductsDrawerProps> = ({
  selectedConfigId,
  isOpen,
  onClose,
  type,
}) => {
  const { dispatch } = useAppContext();
  const [product, setProduct] = useState<ProductData | undefined>(undefined);
  const queryClient = useQueryClient();

  const {
    data: productConfigurationData,
    isFetching: isFetchingFindConfiguration,
  } = useFindConfigurationSetByIdQuery(
    {
      input: {
        entityId: selectedConfigId ?? "",
      },
    },
    {
      enabled: selectedConfigId !== undefined,
    }
  );

  useEffect(() => {
    if (productConfigurationData != null && selectedConfigId) {
      const productValueJSONParsed = JSON.parse(
        productConfigurationData.findConfigurationSetById?.value as string
      ) as ProductJSON;

      setProduct({
        _id: productConfigurationData?.findConfigurationSetById?._id,
        organization_name:
          productConfigurationData?.findConfigurationSetById?.orgId,
        product_name: productConfigurationData?.findConfigurationSetById?.name,
        product_code: productValueJSONParsed.product_code,
        asset_configuration: productValueJSONParsed.asset_configuration,
      } as ProductData);
    }
  }, [productConfigurationData, selectedConfigId]);

  const createProductConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    onCancel();

    dispatch({
      type: PAGE_SNACKBAR,
      payload: PRODUCT_CREATE_SUCCESS_PAYLOAD,
    });
  };

  const createProductConfigurationSetOnError = (error: Error) => {
    let errorPayload: {
      title: string;
      text: string;
      severity: string;
    };
    switch (error.message) {
      case ERROR_PRODUCT_CODE_CONFIGURATION_SET_DUPLICATE:
        errorPayload = PRODUCT_CREATE_FAILED_DUPLICATE_CODE_PAYLOAD;
        break;
      case ERROR_CONFIGURATION_SET_NAME_DUPLICATE:
        errorPayload = PRODUCT_CREATE_FAILED_DUPLICATE_NAME_PAYLOAD;
        break;
      default:
        errorPayload = PRODUCT_CREATE_FAILED_PAYLOAD;
    }

    dispatch({
      type: PAGE_SNACKBAR,
      payload: errorPayload,
    });
  };

  const updateProductConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);
    queryClient.invalidateQueries(["findConfigurationSetById"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: PRODUCT_UPDATE_SUCCESS_PAYLOAD,
    });

    onCancel();
  };

  const updateProductConfigurationSetOnError = (error: Error) => {
    let errorPayload: {
      title: string;
      text: string;
      severity: string;
    };

    switch (error.message) {
      case ERROR_PRODUCT_CODE_CONFIGURATION_SET_DUPLICATE:
        errorPayload = PRODUCT_UPDATE_FAILED_DUPLICATE_CODE_PAYLOAD;
        break;
      case ERROR_CONFIGURATION_SET_NAME_DUPLICATE:
        errorPayload = PRODUCT_UPDATE_FAILED_DUPLICATE_NAME_PAYLOAD;
        break;
      default:
        errorPayload = PRODUCT_UPDATE_FAILED_PAYLOAD;
    }

    dispatch({
      type: PAGE_SNACKBAR,
      payload: errorPayload,
    });
  };

  const deleteProductConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: PRODUCT_DELETE_SUCCESS_PAYLOAD,
    });

    onClose();
  };

  const deleteProductConfigurationSetOnError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: PRODUCT_DELETE_FAILED_PAYLOAD,
    });
  };

  const {
    isLoadingCreateConfigurationSet,
    createConfigurationSet,

    isLoadingUpdateConfigurationSet,
    updateConfigurationSet,

    isLoadingDeleteConfigurationSet,
    deleteConfigurationSet,
  } = useConfigurationSetsApi({
    createConfigurationSetOnSuccess: createProductConfigurationSetOnSuccess,
    createConfigurationSetOnError: createProductConfigurationSetOnError,

    updateConfigurationSetOnSuccess: updateProductConfigurationSetOnSuccess,
    updateConfigurationSetOnError: updateProductConfigurationSetOnError,

    deleteConfigurationSetOnSuccess: deleteProductConfigurationSetOnSuccess,
    deleteConfigurationSetOnError: deleteProductConfigurationSetOnError,
  });

  const onCancel = () => {
    setProduct(undefined);
    onClose();
  };

  const onRemove = (configurationSetId: string) => {
    const payload: DeleteConfigurationSetInput = {
      entityId: configurationSetId,
    };

    deleteConfigurationSet(payload);

    setProduct(undefined);
  };

  const onSubmit = (e: FieldValues) => {
    const payload: CreateConfigurationSetInput = {
      name: e.product_name,
      orgId: e.organization_name,
      value: JSON.stringify({
        product_code: e.product_code,
        asset_configuration: e.asset_configuration,
      }),
      type: ConfigurationSetType.ProductCode,
    };

    createConfigurationSet(payload);
  };

  const onUpdate = (e: FieldValues) => {
    const payload: UpdateConfigurationSetInput = {
      _id: e._id,
      name: e.product_name,
      orgId: e.organization_name,
      value: JSON.stringify({
        product_code: e.product_code,
        asset_configuration: e.asset_configuration,
      }),
    };

    updateConfigurationSet(payload);
  };

  const onSave = (e: FieldValues) => {
    if (product) {
      onUpdate(e);
    } else {
      onSubmit(e);
    }
  };

  const isLoadingConfigurationSet =
    isLoadingUpdateConfigurationSet ||
    isLoadingCreateConfigurationSet ||
    isLoadingDeleteConfigurationSet;

  return (
    <Box data-testid="product-drawer">
      <ProductForm
        product={product}
        className="md:px-3"
        onSave={onSave}
        isOpen={isOpen}
        onCancel={onCancel}
        onRemove={onRemove}
        drawerType={type}
        isLoadingFindConfiguration={isFetchingFindConfiguration}
        isLoadingConfigurationSet={isLoadingConfigurationSet}
      />
    </Box>
  );
};

export default memo(ProductsDrawer);
