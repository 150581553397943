export type ParseFileContentError = {
  row: number;
  message: string;
};

export type MappedGeofenceFileDataResult = {
  validatedGeofences: any[];
  validationErrors: ParseFileContentError[];
};

export type GeofencesFileData = {
  name: string;
  type: string;
  subType: string;
  parent: string;
  owner: string;
  tags?: string;
  locationCode: string;
  streetAddress: string;
  geometryType: string;
  geometryCoordinates: string;
  radius?: number;
  boatMinimum: number;
  boatMaximum: number;
  chassisMinimum: number;
  chassisMaximum: number;
  containerMinimum: number;
  containerMaximum: number;
  dollyMinimum: number;
  dollyMaximum: number;
  reeferMinimum: number;
  reeferMaximum: number;
  tractorMinimum: number;
  tractorMaximum: number;
  trailerMinimum: number;
  trailerMaximum: number;
  vehicleMinimum: number;
  vehicleMaximum: number;
  otherMinimum: number;
  otherMaximum: number;
};

export enum GeofenceGeometryType {
  Circle = "Circle",
  Point = "Point",
  Polygon = "Polygon",
}

export interface GeofencesInput {
  owner: string | undefined;
  orgId: string;
  geofence: {
    name: string;
    type: string;
    tags: string | undefined;
    code: string | undefined;
    centroid:
      | {
          lat: number;
          lon: number;
        }
      | undefined;
    coordinates: number[][] | undefined;
    radius: number | undefined;
    fullAddressFormatted: string | undefined;
  };
  configuration: {
    typeId: string;
    subTypeId: string | undefined;
    parentId: string | undefined;
    capacity: {
      boat:
        | {
            min: number;
            max: number;
          }
        | undefined;
      chassis:
        | {
            min: number;
            max: number;
          }
        | undefined;
      container:
        | {
            min: number;
            max: number;
          }
        | undefined;
      dolly:
        | {
            min: number;
            max: number;
          }
        | undefined;
      reefer:
        | {
            min: number;
            max: number;
          }
        | undefined;
      tractor:
        | {
            min: number;
            max: number;
          }
        | undefined;
      trailer:
        | {
            min: number;
            max: number;
          }
        | undefined;
      vehicle:
        | {
            min: number;
            max: number;
          }
        | undefined;
      other:
        | {
            min: number;
            max: number;
          }
        | undefined;
    };
  };
}
