import { FC } from "react";
import { Grid } from "@mui/material";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  MediaActivity,
  Ms2Command,
  useSendCommandToMs2Mutation,
} from "../../../../../../graphql/operations";
import {
  getCommandCode,
  parseMs2Error,
  parseMs2Response,
} from "../../../../../../shared/ms2/utils";
import Actions from "./components/Actions";
import Carousel from "./components/Carousel";
import Header from "./components/Header";
import MediaActivityModal from "./components/MediaActivityModal";
import { InternalCameraSensorProvider } from "./context";
import { parseMediaActivityData } from "./helpers";

export interface InternalCameraStatusProps {
  data: Array<MediaActivity>;
  refreshMedia: () => void;
  deviceId: string; // imei
}

const InternalCameraStatus: FC<InternalCameraStatusProps> = ({
  data,
  deviceId,
  refreshMedia,
}) => {
  const items = parseMediaActivityData(data);

  const { dispatch } = useAppContext();

  const { mutate, isLoading: IsSendCommandLoading } =
    useSendCommandToMs2Mutation({
      onSuccess(data, variables) {
        const commandCode = getCommandCode(variables.input.command);

        if (data.sendCommandToMS2?.error) {
          const { title, text } = parseMs2Error(
            data.sendCommandToMS2,
            commandCode
          );

          dispatch({
            type: PAGE_SNACKBAR,
            payload: {
              severity: "error",
              title,
              text,
            },
          });
        } else {
          const ms2ParsedResponse = parseMs2Response(
            data.sendCommandToMS2,
            commandCode
          );

          refreshMedia();

          dispatch({
            type: PAGE_SNACKBAR,
            payload: ms2ParsedResponse,
          });
        }
      },

      onError() {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            severity: "error",
            title: "Failed to send device command",
            text: "Something went wrong",
          },
        });
      },
    });

  const onCaptureClick = () => {
    mutate({
      input: {
        deviceId: deviceId,
        command: Ms2Command.DeviceCamera,
      },
    });
  };

  return (
    <InternalCameraSensorProvider>
      <Grid
        spacing={0}
        justify-content="flex-end"
        container
        className="text-sm w-full p-4"
        data-testid="asset--cargo-tab-panel--internal-camera-status"
      >
        <Header />
        <Actions
          onCaptureBtnClick={onCaptureClick}
          isCaptureLoading={IsSendCommandLoading}
        />
        <Carousel items={items} />

        <MediaActivityModal />
      </Grid>
    </InternalCameraSensorProvider>
  );
};

export default InternalCameraStatus;
