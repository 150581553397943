import { memo } from "react";
import { Box } from "@mui/material";
import { AssetWithSensors } from "../../../../../graphql/operations";
import Text from "../../../../../shared/components/Text";

interface SelectedRecordInfoProps {
  selectedRecordsData: AssetWithSensors[];
}

const SelectedRecordInfo: React.FC<SelectedRecordInfoProps> = ({
  selectedRecordsData,
}) => {
  return (
    <div className="flex w-full flex-initial justify-between border-b border-concrete bg-background p-6">
      {selectedRecordsData?.length === 1 && (
        <Box>
          <Text
            fontSize={14}
            classes="bg-background !text-primary"
            dataTestId="organization-name"
          >
            Organization Name:{" "}
            <span className="font-normal pl-1 text-primary mb-2">
              {selectedRecordsData[0]?.org_name}
            </span>
          </Text>

          <Text
            fontSize={14}
            fontWeight="bold"
            classes="!text-primary"
            dataTestId="asset-id"
          >
            Asset ID:{" "}
            <span className="font-normal pl-1 text-primary mb-2">
              {selectedRecordsData[0]?.asset_id}
            </span>
          </Text>
          <Text
            fontSize={14}
            fontWeight="bold"
            classes="!text-primary"
            dataTestId="asset-nickname"
          >
            Asset NickName:{" "}
            <span className="font-normal pl-1 text-primary mb-2">
              {selectedRecordsData[0]?.name ?? ""}
            </span>
          </Text>

          <Text
            fontSize={14}
            fontWeight="bold"
            classes="!text-primary"
            dataTestId="device-id"
          >
            Device ID:{" "}
            <span className="font-normal pl-1 text-primary mb-2">
              {selectedRecordsData[0]?.imei}
            </span>
          </Text>

          <Text
            fontSize={14}
            fontWeight="bold"
            classes="!text-primary"
            dataTestId="product-name"
          >
            Product name:{" "}
            <span className="font-normal pl-1 text-primary mb-2">
              {selectedRecordsData[0]?.prd_cde}
            </span>
          </Text>
        </Box>
      )}
      {(selectedRecordsData ?? []).length > 1 && (
        <Box>
          <Text fontSize={18} fontWeight="bold" classes="!text-primary">
            Number of assets selected:
            <span className="font-normal pl-1">
              {selectedRecordsData?.length}
            </span>
          </Text>
        </Box>
      )}
    </div>
  );
};

export default memo(SelectedRecordInfo);
