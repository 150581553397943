import { FC, memo, useMemo, useCallback, useState, useEffect } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { BatchTitles } from "../../../../constants/batches";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  OrgData,
  SensorProfileResult,
  SensorProfileType,
  ProfileConfigProperty,
  SensorProfileConfigType,
  useFindOrgsQuery,
  useGetSensorProfilesForOrganizationQuery,
} from "../../../../graphql/operations";
import ActionButton from "../../../../shared/components/Button/ActionButton";
import { UploadSensorProfilesDialog } from "../../../../shared/components/SensorProfilesUploadDialog/UploadSensorProfilesDialog";
import { WithPermissions } from "../../../../shared/components/WithPermissions";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import {
  BATCH_FORM_FIELDS,
  BatchFormFieldsNames,
  mapOrgs,
} from "../../../BatchesView/BatchManagementUtils";
import SidePanel from "../../components/SidePanel";
import DrawerGateway from "./ProfilesDrawerGateway";
import TableGateway from "./ProfilesTableGateway";
import {
  DrawerType,
  sensorsCounter,
  ConfigurationTypes,
  ConfigurationOptions,
  drawersTypeMapping,
  DrawersTypeMapping,
} from "./profileUtils";
import { ProfilesSideMenuOptions } from "./types";

interface ConfigurationsProps {
  defaultConfiguration?: ConfigurationTypes;
}

const Profiles: FC<ConfigurationsProps> = (props) => {
  const { defaultConfiguration = ConfigurationOptions.Voltage } = props;

  const queryClient = useQueryClient();

  const dataCurrentOrg = useCurrentOrg();

  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const [
    isUploadSensorProfilesDialogOpen,
    setIsUploadSensorProfilesDialogOpen,
  ] = useState<boolean>(false);

  const {
    data: profilesData,
    isError: isErrorProfile,
    isFetching: isFetchingProfile,
    refetch: refetchProfiles,
  } = useGetSensorProfilesForOrganizationQuery(
    {
      input: {
        orgId: selectedOrganization?.value ?? "",
        type: SensorProfileType.Organization,
      },
    },
    {
      enabled: Boolean(selectedOrganization?.value),
    }
  );

  const sensorProfilesForOrganization = useMemo(
    () => profilesData?.getSensorProfilesForOrganization ?? [],
    [profilesData]
  );

  const { userRolePermissions } = useAuthContext();

  const getProfileCount = useCallback(
    (profile: SensorProfileConfigType | ProfileConfigProperty) => {
      return String(sensorsCounter(sensorProfilesForOrganization, profile));
    },
    [sensorProfilesForOrganization]
  );

  const sideMenuOptions = [
    {
      title: ProfilesSideMenuOptions.Battery,
      type: SensorProfileConfigType.Voltage,
      visible: true,
      adornment: getProfileCount(SensorProfileConfigType.Voltage),
    },
    {
      title: ProfilesSideMenuOptions.TPMS,
      type: SensorProfileConfigType.TpmsBeta,
      visible: true,
      adornment: getProfileCount(SensorProfileConfigType.TpmsBeta),
    },
    {
      title: ProfilesSideMenuOptions.AirBag,
      type: SensorProfileConfigType.Airbag,
      visible: true,
      adornment: getProfileCount(SensorProfileConfigType.Airbag),
    },
    {
      title: ProfilesSideMenuOptions.AirSupply,
      type: ProfileConfigProperty.AirSupply,
      visible: true,
      adornment: getProfileCount(ProfileConfigProperty.AirSupply),
    },
    {
      title: ProfilesSideMenuOptions.Camera,
      type: SensorProfileConfigType.CargoCamera,
      visible: true,
      adornment: getProfileCount(SensorProfileConfigType.CargoCamera),
    },
    {
      title: ProfilesSideMenuOptions.Temperature,
      type: SensorProfileConfigType.Temperature,
      visible: true,
      adornment: getProfileCount(SensorProfileConfigType.Temperature),
    },
    {
      title: ProfilesSideMenuOptions.WheelEnd,
      type: SensorProfileConfigType.PsiWheelEnd,
      visible: true,
      adornment: getProfileCount(SensorProfileConfigType.PsiWheelEnd),
    },
    {
      title: ProfilesSideMenuOptions.ATIS,
      type: SensorProfileConfigType.AtisAlpha,
      visible: true,
      adornment: getProfileCount(SensorProfileConfigType.AtisAlpha),
    },
    {
      title: ProfilesSideMenuOptions.LightCircuit,
      type: SensorProfileConfigType.LiteSentryGamma,
      visible: true,
      adornment: getProfileCount(SensorProfileConfigType.LiteSentryGamma),
    },
    {
      title: ProfilesSideMenuOptions.Liftgate,
      type: SensorProfileConfigType.Liftgate,
      visible: true,
      adornment: getProfileCount(SensorProfileConfigType.Liftgate),
    },
  ];
  sideMenuOptions.sort((a, b) => a.title.localeCompare(b.title));

  const [indexState, setIndexState] = useState<number>(0);
  const [isOpenState, setIsOpenState] = useState<boolean>(false);

  const [selectedSensorProfile, setSelectedSensorProfile] = useState<
    SensorProfileResult | undefined
  >(undefined);

  const { data: dataOrgsList } = useFindOrgsQuery();

  // find the first visible tab to prevent empty table results
  const firstVisibleIndex = dataCurrentOrg
    ? sideMenuOptions.findIndex((sideMenuOption) => sideMenuOption.visible)
    : 0;

  const orgs = mapOrgs((dataOrgsList?.findOrgs ?? []) as OrgData[]);
  BATCH_FORM_FIELDS[BatchFormFieldsNames.AddToOrganization].values = orgs;

  const handleEditProfile = (row: GridRowParams) => {
    // TODO Remove the selectedSideMenuOption check and implement dralwer gateway
    if (userRolePermissions.profiles.edit) {
      const foundSensorProfile = sensorProfilesForOrganization.find(
        (data) => data._id.toString() === row.id.toString()
      );

      if (!foundSensorProfile) return;

      setSelectedSensorProfile(foundSensorProfile);
      setIsOpenState(true);
      setDrawerTypeState(
        drawersTypeMapping[selectedSideMenuOption as keyof DrawersTypeMapping]
          .edit as DrawerType
      );
    }
  };

  const handleDrawerClose = () => {
    setIsOpenState(false);
    setSelectedSensorProfile(undefined);
  };

  const onRefresh = () => {
    setIsOpenState(false);
    setSelectedSensorProfile(undefined);
    refetchProfiles();
    queryClient.invalidateQueries(["getSensorProfileNamesWithConfiguration"], {
      refetchType: "all",
    });
  };

  const uploadSensorProfilesHandler = () => {
    setIsUploadSensorProfilesDialogOpen(true);
  };

  const onUploadSensorProfilesDialogClose = () => {
    setIsUploadSensorProfilesDialogOpen(false);
  };

  const isMobile = useBreakpoint("down", "sm");
  const actionList = sideMenuOptions.filter((option) =>
    Boolean(option.visible)
  );
  const actionListOptions = actionList.map((option) => option.title);

  const selectedSideMenuOption = sideMenuOptions[indexState].type;

  const [drawerTypeState, setDrawerTypeState] = useState<DrawerType>(
    drawersTypeMapping[defaultConfiguration as keyof DrawersTypeMapping]
      .create as DrawerType
  );

  const handleCreateConfig = () => {
    setIsOpenState(true);
    setDrawerTypeState(
      drawersTypeMapping[selectedSideMenuOption as keyof DrawersTypeMapping]
        .create as DrawerType
    );
  };

  // Re-trigger asset request on sidemenu option change
  useEffect(() => {
    if (!isErrorProfile && !isFetchingProfile && selectedOrganization?.value)
      refetchProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchProfiles, selectedOrganization?.value]);

  return (
    <Box data-testid="page-profiles" className="bg-background pt-2 h-[90%]">
      <Box display="flex" justifyContent="flex-end">
        <WithPermissions accessScope="profiles.upload">
          <Button
            className={"global-btn !py-1 !text-sm !text-primary"}
            variant="text"
            type="button"
            onClick={uploadSensorProfilesHandler}
            sx={{
              display: "block",
              margin: "10px 0",
              border: "solid",
              borderWidth: "2px",
              fontWeight: "bold",
            }}
          >
            Upload
          </Button>
        </WithPermissions>
        <WithPermissions accessScope="profiles.create">
          <ActionButton
            data-testid="new-profile-button"
            onClick={() => {
              handleCreateConfig();
            }}
            sx={{ display: "block", margin: "10px 0", marginLeft: "1rem" }}
          >
            New Profile
          </ActionButton>
        </WithPermissions>
      </Box>

      {isOpenState && (
        <DrawerGateway
          isOpen={isOpenState && Boolean(drawerTypeState)}
          type={drawerTypeState}
          sensorType={selectedSideMenuOption}
          sensorProfileData={selectedSensorProfile}
          onClose={handleDrawerClose}
          onRequestClose={handleDrawerClose}
          onProfileMutation={onRefresh}
        />
      )}

      <Box className="flex flex-wrap h-full pb-4 gap-4">
        <SidePanel
          sx={{
            flexGrow: isMobile ? 1 : 0,
          }}
          key={String(dataCurrentOrg)}
          actionList={actionList}
          defaultActive={indexState}
          onActionClick={(index) => setIndexState(index)}
        >
          {isMobile ? (
            <Autocomplete
              sx={{ marginBottom: "1rem" }}
              disableClearable
              options={actionListOptions}
              defaultValue={actionListOptions[firstVisibleIndex]}
              onChange={(_, value) => {
                setIndexState(
                  Number(
                    sideMenuOptions.findIndex(
                      (option) => option.title === value
                    )
                  )
                );
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          ) : undefined}
        </SidePanel>

        <TableGateway
          type={selectedSideMenuOption}
          orgId={selectedOrganization?.value}
          onRowClick={handleEditProfile}
          profilesData={{
            data: sensorProfilesForOrganization,
            isErrorProfile,
            isFetchingProfile,
          }}
        />

        {isUploadSensorProfilesDialogOpen && (
          <UploadSensorProfilesDialog
            title={BatchTitles.AssignSensorProfiles}
            customerOrg={dataCurrentOrg?.name}
            dialogFields={BATCH_FORM_FIELDS}
            isOpen={isUploadSensorProfilesDialogOpen}
            onClose={onUploadSensorProfilesDialogClose}
            onUpload={() => queryClient.invalidateQueries(["getBatchHistory"])}
          />
        )}
      </Box>
    </Box>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
Profiles.displayName = "Profiles";
export default memo(Profiles);
