import { memo } from "react";
import { Box, ThemeProvider } from "@mui/material";
import { useAssetManagementTheme } from "../../../TableView/hooks/useAssetManagementTheme";
import { DetentionHistoryTable } from "./DetentionHistoryTable";

export interface DetentionHistoryProps {
  customerOrgId?: string;
  selectedAssetId: string;
}

const DetentionHistory: React.FC<DetentionHistoryProps> = ({
  customerOrgId,
  selectedAssetId,
}) => {
  const assetManagementTheme = useAssetManagementTheme();
  return (
    <ThemeProvider theme={assetManagementTheme}>
      <Box
        className="h-full w-full bg-background"
        data-testid="asset-detention-history-container"
      >
        <Box className="h-full pt-2">
          <DetentionHistoryTable
            customerOrgId={customerOrgId}
            selectedAssetId={selectedAssetId}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default memo(DetentionHistory);
