/**
 * Prompts a user when they exit the page
 */
import { useContext, useEffect, useState } from "react";
import {
  NavigateOptions,
  UNSAFE_NavigationContext as NavigationContext,
} from "react-router-dom";
import { useAssetsDataContext } from "../../views/AssetsView/shared/AssetsDataContext";

interface PushActionArgs {
  to?: string;
  state?: any;
  opts?: NavigateOptions;
}

type ActionArgs = PushActionArgs | undefined;
// variable to store the arguments of the action that triggered the prompt
let actionArgs: ActionArgs;
type PromptCallback = () => void;

export const usePrompt = (when = false, handleLeave?: PromptCallback) => {
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { navigator } = useContext(NavigationContext);

  // useEffect to handle the browser's default prompt
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return "";
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);

  // useEffect to handle the custom prompt
  useEffect(() => {
    if (!when) {
      return;
    }
    // save the original action
    const push = navigator.push;

    if (isConfirmed && handleLeave) {
      handleLeave();
    } else if (!handleLeave) {
      // override the action to show the custom prompt
      navigator.push = (...args: Parameters<typeof push>) => {
        setIsLeaveModalOpen(true);
        // save the arguments of the action
        actionArgs = args as ActionArgs;
      };
      // if the user confirms the prompt, execute the original action
      if (isConfirmed) {
        push(...(actionArgs as Parameters<typeof push>));
      }
    }

    return () => {
      // reset the action to the original
      navigator.push = push;
    };
  }, [navigator, when, isConfirmed, handleLeave]);
  return { isLeaveModalOpen, setIsLeaveModalOpen, setIsConfirmed };
};
