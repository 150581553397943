import { useEffect, useState } from "react";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { ORGANIZATION_LOGO_UPLOAD_URL_QUERY_FAILED_PAYLOAD } from "../../../../../../constants/organizations";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  FileCategory,
  S3UploadObject,
  useGetOrgFileUploadUrlQuery,
} from "../../../../../../graphql/operations";
import { LogoData } from "../../types";
import { uploadCompanyLogo } from "../createOrgForm.api";

export type LogoFile = {
  file: File;
};

interface CompanyLogoHook {
  logoIsDeleted: boolean;
  companyLogoInput: LogoFile | null;
  setCompanyLogoInput: React.Dispatch<React.SetStateAction<LogoFile | null>>;
  setLogoIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  uploadCompanyLogoHandler: () => Promise<LogoData | undefined>;
  dispatchUploadFailure: () => void;
}

export const useCompanyLogo = (): CompanyLogoHook => {
  const { dispatch } = useAppContext();

  const [logoUploadS3Placeholder, setlogoUploadS3Placeholder] = useState<
    S3UploadObject | null | undefined
  >(null);
  const [companyLogoInput, setCompanyLogoInput] = useState<{
    file: File;
  } | null>(null);
  const [logoIsDeleted, setLogoIsDeleted] = useState(false);

  const dispatchUploadFailure = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: ORGANIZATION_LOGO_UPLOAD_URL_QUERY_FAILED_PAYLOAD,
    });
  };

  const { data: UPLOAD_URL_QUERY_DATA, isSuccess: UPLOAD_URL_QUERY_SUCCESS } =
    useGetOrgFileUploadUrlQuery(
      { orgFileUploadInput: { file_category: FileCategory.Logo } },
      {
        cacheTime: 0,
        staleTime: 0,
        refetchOnMount: true,
        onSettled: (_, error) => {
          if (error) {
            dispatchUploadFailure();
          }
        },
      }
    );

  useEffect(() => {
    if (UPLOAD_URL_QUERY_SUCCESS) {
      const _s3UploadObj = UPLOAD_URL_QUERY_DATA.getOrgFileUploadUrl;
      setlogoUploadS3Placeholder(_s3UploadObj);
    }
  }, [UPLOAD_URL_QUERY_DATA, UPLOAD_URL_QUERY_SUCCESS]);
  const uploadCompanyLogoHandler = async (): Promise<LogoData | undefined> => {
    if (!companyLogoInput) {
      dispatchUploadFailure();
      return;
    }

    if (!logoUploadS3Placeholder) {
      dispatchUploadFailure();
      return;
    }

    const { bucket, key, region } = logoUploadS3Placeholder;
    const company_logos = {
      bucket,
      key,
      region,
      file_name: companyLogoInput?.file?.name,
      content_type: companyLogoInput?.file?.type,
    } as LogoData;

    await uploadCompanyLogo(
      logoUploadS3Placeholder.uploadUrl as string,
      companyLogoInput.file
    );

    return company_logos;
  };
  return {
    logoIsDeleted,
    companyLogoInput,
    setCompanyLogoInput,
    setLogoIsDeleted,
    uploadCompanyLogoHandler,
    dispatchUploadFailure,
  };
};
