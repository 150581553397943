import { SET_DISPLAY_SCHEDULE_MODAL } from "../../constants";
import { Action } from "../commonTypes";

export type ReportsReducerInitialState = {
  displayScheduleModal: boolean;
};

export type ReportsReducerType = (
  state: ReportsReducerInitialState | undefined,
  action: Action
) => ReportsReducerInitialState;

export const initialReportsState: ReportsReducerInitialState = {
  displayScheduleModal: false,
};

export const ReportsReducer = (state = initialReportsState, action: Action) => {
  switch (action.type) {
    case SET_DISPLAY_SCHEDULE_MODAL:
      return {
        ...state,
        displayScheduleModal: action.payload,
      };
    default:
      return state;
  }
};
