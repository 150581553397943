import { FC, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../context/AppContext";
import {
  useFindAssetsByIdsOsQuery,
  useFindAssetsByIdsQuery,
} from "../../../../graphql/operations";
import { useFindAssetListOptions } from "../../../../shared/hooks/useFindAssetListOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  isFindAssetByIdQueryEnabled,
  onFindAssetsByIdsOSCallback,
  onFindAssetsSettledCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface AlertABSFaultParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

interface AssetOption {
  id: string;
  label: string;
}

export const AlertABSFaultParameters: FC<AlertABSFaultParametersProps> = ({
  form,
}: AlertABSFaultParametersProps) => {
  const { state } = useAppContext();

  const typeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);

  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const [assetsOptionsList, setAssetsOptionsList] = useState<AssetOption[]>([]);
  const [nameSearch, setNameSearch] = useState(form.getValues().name ?? "");

  const watchAssetsValue = useWatch({
    name: "parameters.assetIds",
    control: form.control,
  });

  const watchAssetTypeValue = useWatch({
    name: "parameters.assetType",
    control: form.control,
  });

  const shouldBeEnabled = isFindAssetByIdQueryEnabled(watchAssetsValue);

  const {
    data: asset,
    isLoading: findAssetLoading,
    isSuccess: findAssetSuccess,
  } = useFindAssetsByIdsOsQuery(
    { assetIds: getAlertMultiselectIds(watchAssetsValue) ?? [] },
    {
      enabled: shouldBeEnabled,
    }
  );

  useEffect(() => {
    if (asset && !findAssetLoading && findAssetSuccess) {
      onFindAssetsByIdsOSCallback({ data: asset, form, setSelectedAsset });
    }
  }, [asset, findAssetLoading, findAssetSuccess, form, shouldBeEnabled]);

  useEffect(() => {
    form.setValue("parameters.assetIds", selectedAsset);
  }, [form, selectedAsset]);
  const { options, isFetching } = useFindAssetListOptions(
    nameSearch,
    state.appConfig.searchOptionsLimit
  );

  useEffect(() => {
    setAssetsOptionsList(options);
  }, [options]);

  const hideInputValue =
    !selectedAsset &&
    !!watchAssetsValue?.length &&
    findAssetLoading &&
    !findAssetSuccess;

  return (
    <AlertParametersContainer>
      <AssetsParameters
        form={form}
        setAssetNameSearch={setNameSearch}
        setSelectedAsset={setSelectedAsset}
        assetsOptionsList={assetsOptionsList}
        setAssetsOptionsList={setAssetsOptionsList}
        assetTypeOptions={typeOptions}
        watchAssetsValue={watchAssetsValue}
        watchAssetTypeValue={watchAssetTypeValue}
        isFetching={isFetching}
        hideInputValue={hideInputValue}
        assetsSelectTestId="abs-fault-assets-select"
        assetTypeSelectTestId="abs-fault-asset-type-select"
      />
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="abs-fault-parameters-number-of-faults"
      >
        <TextFieldElement
          fullWidth
          control={form.control}
          name="parameters.absNumberOfFaults"
          label={`Number of ABS faults`}
          type="number"
        />
      </Grid>
    </AlertParametersContainer>
  );
};
