import { useMemo } from "react";
import { ReportScheduleFrequency } from "../../../../graphql/operations";
import { formatUTCDateToTimezoneAsISOString } from "../../../../utils";
import {
  SchedulingConverter,
  SchedulingRepeat,
} from "../../../../utils/scheduling";
import { convertTimeTo12Hour } from "../../helpers/helpers";
import { ReportWithParameters } from "../../interfaces";
import { useScheduleForm } from "../components/AssetReportForm/hooks/useScheduleForm";

interface AssetScheduleFormProps {
  report: ReportWithParameters;
  timezone: string;
}

export const useGetReportScheduleForm = ({
  report,
  timezone,
}: AssetScheduleFormProps) => {
  const scheduleFormValues = useMemo(() => {
    const reportSchedule = report.schedule[0];
    let converted =
      reportSchedule?.expression && reportSchedule?.expression !== "@never"
        ? new SchedulingConverter(reportSchedule?.expression).convert()
        : null;

    const [time, timeFormat] = reportSchedule?.time
      ? convertTimeTo12Hour(reportSchedule?.time).split(" ")
      : ["", ""];

    const containsAsterisk = /\*/.test(`${reportSchedule?.expression}`);

    const frequency = containsAsterisk
      ? ReportScheduleFrequency.Custom
      : reportSchedule?.frequency ?? null;

    const reportScheduleFormValues = {
      // Convert date to time zone since it is stored in UTC
      startDate: reportSchedule?.startDate
        ? formatUTCDateToTimezoneAsISOString(reportSchedule.startDate, timezone)
        : null,
      // Convert date to time zone since it is stored in UTC
      endDate: reportSchedule?.endDate
        ? formatUTCDateToTimezoneAsISOString(reportSchedule.endDate, timezone)
        : null,
      frequency: frequency ?? null,
      format: reportSchedule?.format ?? null,
      subscribers: reportSchedule?.subscribers ?? {
        emails: [],
        users: [],
        roles: [],
      },
      time,
      timeFormat,
      on: (converted?.on?.map(String) as string[]) ?? [],
      repeat: (() => {
        if (reportSchedule?.frequency === ReportScheduleFrequency.DoNotRepeat) {
          return SchedulingRepeat.Never;
        } else if (converted) {
          return converted.repeat;
        }
        return "";
      })(),
      every: (converted?.every as string) ?? "",
    };
    return {
      ...reportScheduleFormValues,
    };
  }, [report, timezone]);

  const { form } = useScheduleForm(scheduleFormValues, timezone);
  return { form };
};
