import { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export interface ZoneNameCountPair {
  name: string;
  count: number;
}

export interface DashboardWidgetZonesData {
  zones: ZoneNameCountPair[];
}

export interface DashboardWidgetZones extends GetDashboardWidgetResponse {
  data: DashboardWidgetZonesData;
}

export interface AssetsZonesWidgetProps {
  widget: DashboardWidgetZones;
}

export const mapWidgetData = (
  data: DashboardWidgetZonesData
): HorizontalBarChartData[] => {
  if (!data) return [];

  return data.zones.map((zone) => {
    return {
      name: zone.name,
      value: zone.count,
      color:
        zone.name !== "Other"
          ? ColorsPalette.ExtraLightBlue
          : ColorsPalette.Concrete,
    };
  });
};

export const AssetsZonesWidget: React.FC<AssetsZonesWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";

  const customerOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: customerOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(customerOrg && customerOrg._id),
    }
  );

  const zonesData = useMemo(() => {
    if (!getDashboardWidgetData?.getDashboardWidget?.data) return [];

    const parsedData: DashboardWidgetZonesData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );

    return mapWidgetData(parsedData);
  }, [getDashboardWidgetData]);

  const totalRecords = useMemo(() => {
    if (!zonesData) return 0;

    return zonesData.reduce((sum, item) => sum + item.value, 0);
  }, [zonesData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--zones"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets zones widget!
          Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          isLoading={isLoading}
          showPercentages={true}
          showXAxis={false}
          totalRecords={totalRecords}
          data={zonesData}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
