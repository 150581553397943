import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../shared/hooks/theme/useCurrentTheme";

export const useDashboardViewTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {},
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              border: "none !important",
              borderColor: "transparent !important",
              borderWidth: "0px !important",
              borderRightWidth: "0px !important",
              borderLeftWidth: "0px !important",
              borderTopWidth: "0px !important",
              borderBottomWidth: "0px !important",
              borderTopColor: "transparent !important",
            },
          },
        },
      },
    })
  );
};
