import { PropsWithChildren, memo } from "react";
import { Button, SxProps } from "@mui/material";

export type ActionButtonProps = {
  className?: string;
  sx?: SxProps;
  testId?: string;
  onClick: () => void;
};

const ActionButton: React.FC<PropsWithChildren & ActionButtonProps> = ({
  children,
  className = "",
  sx = {},
  testId = "action-button",
  onClick,
  ...props
}) => {
  const classes = `global-btn !rounded-[100px] !border-2 !border-solid !px-3 !py-1 !text-sm !font-bold !border-blue !bg-brand !text-white ${className}`;

  return (
    <Button
      className={classes}
      sx={sx}
      variant="text"
      type="button"
      onClick={onClick}
      data-testid={testId}
      {...props}
    >
      {children}
    </Button>
  );
};

export default memo(ActionButton);
