import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { Box, ThemeProvider } from "@mui/material";
import {
  DATE_FORMAT,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../../../../utils";
import { ReportWithParameters, ScheduleFormFields } from "../../../interfaces";
import { AssetReportForm } from "../AssetReportForm";
import { useReportSettingsTheme } from "./hooks/useReportSettingsTheme";

interface ReportSettingsProps {
  timezone: string;
  report: ReportWithParameters;
  scheduleForm: UseFormReturn<Partial<ScheduleFormFields>>;
  parametersForm: UseFormReturn<Partial<Record<string, any>>>;
  queryForm: UseFormReturn;
  handleScheduleReportSubmit: () => void;
  wasSaved: boolean;
  handleUpdateField: (value: any, field?: string) => void;
  isNextRunDateCalculating: boolean;
  isSelectOpen?: boolean;
  setIsSelectOpen?: (open: boolean) => void;
}
export const ReportSettings: FC<ReportSettingsProps> = ({
  timezone,
  report,
  scheduleForm,
  parametersForm,
  handleScheduleReportSubmit,
  wasSaved,
  handleUpdateField,
  isNextRunDateCalculating,
  queryForm,
  isSelectOpen,
  setIsSelectOpen,
}) => {
  const alertTheme = useReportSettingsTheme();

  const scheduledToRun = report.schedule?.[0]?.nextRunAt
    ? formatDateInTimezone(
        report.schedule[0].nextRunAt,
        "MM/dd/yyyy hh:mm a",
        mapTimezoneToIANAValue(timezone)
      )
    : "";
  const scheduleEndDate = report.schedule?.[0]?.endDate
    ? formatDateInTimezone(
        report.schedule[0].endDate,
        DATE_FORMAT,
        mapTimezoneToIANAValue(timezone)
      )
    : "";
  return (
    <ThemeProvider theme={alertTheme}>
      <Box className="px-4 md:px-8 md:pt-4 lg:px-16 lg:pt-4">
        <AssetReportForm
          report={report}
          scheduleForm={scheduleForm}
          parametersForm={parametersForm}
          queryForm={queryForm}
          onSubmit={() => {}}
          data-testid="asset-transfer-report-form"
          compact
          scheduledToRun={scheduledToRun}
          scheduleEndDate={scheduleEndDate}
          handleScheduleReportSubmit={handleScheduleReportSubmit}
          wasSaved={wasSaved}
          handleUpdateField={handleUpdateField}
          isNextRunDateCalculating={isNextRunDateCalculating}
          isSelectOpen={isSelectOpen}
          setIsSelectOpen={setIsSelectOpen}
        />
      </Box>
    </ThemeProvider>
  );
};
