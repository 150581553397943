import {
  GetAssetsQueryInput,
  useGetGeofenceNamesQuery,
} from "../../graphql/operations";

interface UseGetGeofenceNamesResponse {
  isFetching: boolean;
  geofenceNames: string[];
}

export const useGetGeofenceNames = (
  input: GetAssetsQueryInput
): UseGetGeofenceNamesResponse => {
  const { data, isFetching } = useGetGeofenceNamesQuery(
    { input },
    { enabled: !!input?.orgId }
  );

  return {
    isFetching,
    geofenceNames: data?.getAssetsGeofenceNamesOS ?? ([] as string[]),
  };
};
