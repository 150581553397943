import { FC, JSX } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Grid } from "@mui/material";
import { TextButton } from "../Button";

export interface DrawerActionsProps {
  deleteBtnText?: string;
  deleteBtnTestId?: string;
  cancelBtnTestId?: string;
  children?: JSX.Element | null;
  disabled?: boolean;
  classNames?: string;
  showDeleteBtn?: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
}

const DrawerActions: FC<DrawerActionsProps> = ({
  deleteBtnText,
  deleteBtnTestId,
  cancelBtnTestId,
  children,
  disabled,
  showDeleteBtn,
  classNames,
  onCancel,
  onDelete,
}) => (
  <Grid container className={`bg-background !text-primary ${classNames}`}>
    {children}

    {onDelete && showDeleteBtn && (
      <Grid container className="justify-center bg-background py-3">
        <Grid item>
          <TextButton
            text={deleteBtnText ?? "Delete"}
            size="medium"
            className="!text-error"
            icon={<DeleteForeverIcon />}
            iconPosition={"left"}
            data-testid={deleteBtnTestId ?? "drawer-actions-delete-btn"}
            disabled={disabled}
            onClick={onDelete}
          />
        </Grid>
      </Grid>
    )}

    {onCancel && (
      <Grid container className="justify-center bg-background py-3">
        <Grid>
          <TextButton
            text="Cancel"
            size="medium"
            theme="blue"
            data-testid={cancelBtnTestId ?? "drawer-actions-cancel-btn"}
            disabled={disabled}
            onClick={onCancel}
          />
        </Grid>
      </Grid>
    )}
  </Grid>
);

export default DrawerActions;
