import { ReactElement } from "react";
import { FeatureFlags } from "../featureFlagsConstants";
import { useFeatureFlag } from "../useFeatureFlag";

export function useFeatureFlagProtectedRoutes() {
  const reportsFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1RolloutReports
  );
  const alertsFeatureFlag = useFeatureFlag(FeatureFlags.Connect1RolloutAlerts);

  const isFeatureEnabled = (element: ReactElement) => {
    const elementName =
      (element?.type as Record<string, any>)?.displayName ??
      (element?.type as Record<string, any>)?.type?.displayName ??
      (element?.type as Record<string, any>)?.name;

    if (elementName === "ReportsListView") {
      return reportsFeatureFlag;
    }

    if (elementName === "AlertsView") {
      return alertsFeatureFlag;
    }

    return true;
  };

  return { isFeatureEnabled };
}
