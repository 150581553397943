import { HTMLAttributes, useEffect, useMemo } from "react";
import { FieldValues, UseFormReturn, Controller } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FormHelperText, Grid, TextField, ThemeProvider } from "@mui/material";
import { omitBy } from "lodash";
import { SET_PARENT_GEOFENCE } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  GeofenceData,
  GetConfigurationSetsQuery,
  useFindGeofencesByOrgIdsQuery,
  useGetGeofencesTagsQuery,
} from "../../../../../graphql/operations";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { FormFieldDropdownOption } from "../../../../../types";
import { CreatableAutocomplete } from "../../../../AssetsView/TableView/components/AssetForm/components";
import { useGeofenceTagOptions } from "../../../../AssetsView/TableView/hooks/useGeofenceTagOptions";
import { useOrgsOptions } from "../../../TableView/hooks";
import {
  GeofenceParent,
  useGeofenceParentOptions,
} from "../../../TableView/hooks/useGeofenceParentOptions";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import { useGetTypeOptions } from "../../hooks/useGetTypeOptions";

export interface GeofenceFormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onChange" | "onSubmit"> {
  onSubmit?: (data: FieldValues, e?: any) => any;
  onChange?: (geofence?: Partial<GeofenceData> | undefined) => any;
  onCancel?: () => void;
  form: UseFormReturn<Partial<GeofenceData>>;
  compact?: boolean;
  cancelable?: boolean;
  existingGeofence?: Partial<GeofenceData> | null;
  disableAllFields?: boolean;
  showDetailsHeader?: boolean;
  hideDetentionRule?: boolean;
}

interface ConfigurationSet {
  _id: string;
  archived?: boolean | null;
  archivedAt?: any;
  createdAt: any;
  createdBy: string;
  name: string;
  orgId: string;
  parentId?: string | null;
  type: string | null;
  updatedAt: any;
  updatedBy: string;
  value: string;
}

interface ValueObject {
  color: string;
  disabledForOrganizations: string[];
  isFromParentOrganization: boolean;
}

export function extractIdsWithDisabledOrgs(
  configurationSets: ConfigurationSet[] | undefined
): string[] {
  const idsWithDisabledOrgs: string[] = [];

  if (!configurationSets) {
    return idsWithDisabledOrgs;
  }

  configurationSets.forEach((configurationSet) => {
    try {
      const valueObject: ValueObject = JSON.parse(configurationSet.value);

      if (
        valueObject.disabledForOrganizations &&
        valueObject.disabledForOrganizations.length > 0
      ) {
        idsWithDisabledOrgs.push(...valueObject.disabledForOrganizations);
      }
    } catch (error) {
      console.error("Error parsing value:", error);
    }
  });

  return idsWithDisabledOrgs;
}

export const mapIdsToConfigurationSets = (
  ids: string[],
  configs: GetConfigurationSetsQuery | undefined
): Record<string, string[]> => {
  const result: Record<string, string[]> = {};
  if (configs?.getConfigurationSets) {
    configs.getConfigurationSets.forEach(
      (config: { value: string; _id: string }) => {
        let valueObj: { disabledForOrganizations: string[] };
        try {
          valueObj = JSON.parse(config.value);
        } catch (e) {
          console.error("Error parsing JSON into 'value':", e);
          return;
        }
        if (
          valueObj.disabledForOrganizations &&
          Array.isArray(valueObj.disabledForOrganizations)
        ) {
          ids.forEach((id) => {
            if (valueObj.disabledForOrganizations.includes(id)) {
              if (!result[id]) {
                result[id] = [];
              }
              if (!result[id].includes(config._id)) {
                result[id].push(config._id);
              }
            }
          });
        }
      }
    );
  }
  return result;
};

export const GeofenceForm = ({
  onSubmit,
  onChange,
  form,
  existingGeofence,
  hideDetentionRule = false,
  compact = false,
  showDetailsHeader = false,
  cancelable = true,
  onCancel,
  disableAllFields = false,
  ...props
}: GeofenceFormProps) => {
  const { dispatch, state } = useAppContext();
  const formTheme = useFormTheme();
  const { geofenceCreateFormRef } = useAssetsDataContext();
  const availableOrgs = useAvailableOrgs();
  const orgOptions = useOrgsOptions(availableOrgs).map(
    (
      option // done due to org options coming with value instead of id
    ) => ({
      id: (option as unknown as FormFieldDropdownOption)?.value || option?.id,
      label: option?.label,
    })
  );
  const { data: geofenceTagsData, isLoading: isGeofenceTagsLoading } =
    useGetGeofencesTagsQuery({ input: {} });
  const geofenceTags = useMemo(
    () => geofenceTagsData?.getGeofencesTags ?? [],
    [geofenceTagsData]
  );
  const geofenceTagsOptions = useGeofenceTagOptions(geofenceTags);
  const geofenceTypeValue = form.watch("configuration.typeId");

  const orgId = form.watch("orgId");

  const parentGeofenceId = form.watch("configuration.parentId");

  const formSubmit = (e: any) => {
    e.preventDefault();
    return onSubmit ? onSubmit(form.getValues()) : undefined;
  };

  useEffect(() => {
    const values = omitBy(form.getValues(), (value) => value === "");
    if (form.formState.isDirty) {
      if (onChange) {
        onChange(values);
      }
    }
  }, [form, onChange, form.formState.isDirty]);
  const breakpoints = compact ? { xs: 12 } : { xs: 12, md: 6, lg: 3, xl: 3 };

  const { geofenceTypesOpts, isConfigurationFetching, getSubTypeOptions } =
    useGetTypeOptions({ watchOrgName: orgId });

  const { data: geofenceParentData, isFetching: geofenceParentsLoading } =
    useFindGeofencesByOrgIdsQuery(
      { orgIds: orgId ?? [] },
      {
        enabled: !!(orgId && orgId.length),
      }
    );

  const geofenceParents = useMemo(
    () => geofenceParentData?.findGeofencesByOrgIds ?? [],
    [geofenceParentData]
  );
  const parentGeofence =
    geofenceParents.find((geofence) => geofence._id === parentGeofenceId) ??
    null;

  useEffect(() => {
    if (
      parentGeofence &&
      parentGeofence?._id !== state.geofence.parentGeofence?._id
    ) {
      dispatch({
        type: SET_PARENT_GEOFENCE,
        payload: parentGeofence,
      });
    }
  }, [parentGeofence, dispatch, state.geofence.parentGeofence?._id]);

  const geofenceObjectsArray: GeofenceParent[] = geofenceParents.map(
    (geofenceData) => ({
      _id: geofenceData._id,
      name: geofenceData.geofence.name ?? "",
    })
  );
  const geofenceParentOptions = useGeofenceParentOptions(geofenceObjectsArray);

  const subTypesOpts = getSubTypeOptions(geofenceTypeValue);

  useEffect(() => {
    if (!orgId && geofenceTypeValue) {
      form.setValue("configuration.typeId", null);
    }
  }, [orgId, geofenceTypeValue, form]);

  const getFormHelperTextWithIcon = (text: string) => (
    <FormHelperText className="!text-[0.7rem]">
      <ErrorOutlineIcon className="mr-2" fontSize="small" />
      {text}
    </FormHelperText>
  );

  return (
    <ThemeProvider theme={formTheme}>
      <form
        {...props}
        autoComplete="off"
        onSubmit={formSubmit}
        ref={geofenceCreateFormRef}
      >
        <Grid
          container
          className={`!text-primary ${
            showDetailsHeader ? "formSection" : "drawerSection"
          }`}
          spacing={6}
        >
          {/* Required Fields */}
          {/* Name Field */}
          <Grid item {...breakpoints} data-testid="geofence.name">
            <TextFieldElement
              disabled={disableAllFields}
              fullWidth
              control={form.control}
              name="geofence.name"
              required
              label="Name"
              data-testid="name"
            />
          </Grid>

          {/* Company */}
          <Grid item {...breakpoints}>
            <AutocompleteElement
              autocompleteProps={{
                disabled: disableAllFields,
                readOnly: disableAllFields,
                onChange: (event, value) => {
                  form.setValue("configuration.typeId", null);
                },
              }}
              loading={!availableOrgs.length}
              rules={{ required: true }}
              matchId={true}
              label={"Organization Name"}
              control={form.control}
              name="orgId"
              options={orgOptions}
              data-testid="orgId"
            />
          </Grid>
          {/* Type */}
          <Grid item {...breakpoints} data-testid="configuration-typeId">
            <AutocompleteElement
              autocompleteProps={{
                disabled: disableAllFields || !orgId,
                readOnly: disableAllFields,
              }}
              loading={isConfigurationFetching}
              rules={{ required: true }}
              matchId={true}
              label={"Type"}
              control={form.control}
              name="configuration.typeId"
              options={geofenceTypesOpts}
            />
            {!orgId && (
              <FormHelperText>Please choose Organization first</FormHelperText>
            )}
          </Grid>
          {/* subType */}
          <Grid item {...breakpoints}>
            <AutocompleteElement
              autocompleteProps={{
                disabled: disableAllFields || !geofenceTypeValue,
                readOnly: disableAllFields || !geofenceTypeValue,
              }}
              loading={isConfigurationFetching}
              matchId={true}
              label={"Subtype"}
              control={form.control}
              name="configuration.subTypeId"
              options={subTypesOpts}
              data-testid="configuration-subTypeId"
            />
          </Grid>
          {/* Parent */}
          <Grid item {...breakpoints} data-testid="configuration-parent">
            <AutocompleteElement
              autocompleteProps={{
                disabled:
                  disableAllFields ||
                  !orgId ||
                  state.geofence.useParentGeofenceOrgId,
                readOnly: !compact,
              }}
              loading={geofenceParentsLoading}
              matchId={true}
              label={"Parent"}
              control={form.control}
              name="configuration.parentId"
              options={geofenceParentOptions}
            />
            {!compact
              ? getFormHelperTextWithIcon(
                  'Parent can be updated via “Edit Geofence"'
                )
              : !orgId
              ? getFormHelperTextWithIcon("Please choose organization first")
              : null}
          </Grid>
          {/* Optional Fields */}
          {/* Tags */}
          <Grid item {...breakpoints}>
            <Controller
              name="geofence.tags"
              control={form.control}
              defaultValue={null}
              render={({ field }) => {
                return (
                  <CreatableAutocomplete
                    geofence={true}
                    disabled={disableAllFields}
                    name="geofence.tags"
                    label={"Tags"}
                    field={field}
                    dataTestId="geofence-tags"
                    loading={isGeofenceTagsLoading}
                    errors={{}}
                    options={geofenceTagsOptions}
                  />
                );
              }}
            />
          </Grid>
          {/* Adrress */}
          <Grid item {...breakpoints}>
            <TextFieldElement
              disabled={disableAllFields}
              fullWidth
              control={form.control}
              name="geofence.fullAddressFormatted"
              label="Address"
              data-testid="geofence-fulladdress-formatted"
            />
          </Grid>

          {/* Owner */}
          <Grid item {...breakpoints}>
            <TextFieldElement
              disabled={disableAllFields}
              fullWidth
              control={form.control}
              name="metadata.owner"
              label="Owner"
              data-testid="metadata-owner"
            />
          </Grid>
          {!hideDetentionRule && (
            <Grid item {...breakpoints}>
              <TextField
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                label="Detention/Dwell"
                variant="standard"
                defaultValue={
                  existingGeofence?.configuration?.detentionRule?.name ?? ""
                }
              />
            </Grid>
          )}
          <Grid item {...breakpoints}>
            <TextFieldElement
              disabled={disableAllFields}
              fullWidth
              control={form.control}
              name="geofence.code"
              label="Location Code"
              data-testid="geofence-location-code"
            />
          </Grid>
          <Grid item {...breakpoints}>
            <TextFieldElement
              disabled={disableAllFields}
              fullWidth
              control={form.control}
              name="geofence.description"
              label="Location Description"
              data-testid="geofence-description"
            />
          </Grid>
        </Grid>
        <input type="submit" hidden data-testid="geofence-form-submit" />
      </form>
    </ThemeProvider>
  );
};
