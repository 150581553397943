import { useMemo } from "react";

export interface UseOptionsOpts<T = object> {
  sort?: keyof T | [keyof T, "asc" | "desc"];
}

export const useOptions = <T = object>(
  data: Array<T>,
  value: keyof T,
  label: keyof T,
  opts: UseOptionsOpts<T> = {}
) => {
  return useMemo(
    () =>
      data && Array.isArray(data)
        ? data
            .filter((row) => row[label] || row[value])
            .map((row) => ({
              id: row[value],
              label: (row[label] || row[value]) as string,
            }))
            .sort((a, b) => {
              const aLabel = a.label.trim().toLowerCase();
              const bLabel = b.label.trim().toLowerCase();

              if (Array.isArray(opts.sort) && opts.sort[1] === "desc")
                return bLabel.localeCompare(aLabel);
              return aLabel.localeCompare(bLabel);
            })
        : [],
    [data, value, label, opts]
  );
};
