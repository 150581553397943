import React, { useEffect, useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Button,
  Divider,
  Menu,
  ThemeProvider,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useQueryClient } from "@tanstack/react-query";
import {
  useGetBellNotificationsTotalUnreadQuery,
  useMarkAllUserBellNotificationsAsReadMutation,
} from "../../../../graphql/operations";
import { ModalTemplates } from "../../../../views/AssetsView/TableView/components/AlertNotificationModal/ModalTemplates";
import { useHeaderButtonMenuTheme } from "../hooks/useHeaderButtonMenuTheme";
import { NotificationsList } from "./NotificationsList";

export const NotificationsComponent = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [alertNotificationId, setAlertNotificationId] = useState<string | null>(
    null
  );
  const [totalUnread, setTotalUnread] = useState(0);
  const [isAllMarkedAsRead, setIsAllMarkedAsRead] = useState(false);
  const theme = useHeaderButtonMenuTheme();
  const queryClient = useQueryClient();

  const open = Boolean(anchorEl);

  const { data: bellNotificationsTotalUnread, refetch } =
    useGetBellNotificationsTotalUnreadQuery();

  const { mutate: markAllUserBellNotificationsAsRead } =
    useMarkAllUserBellNotificationsAsReadMutation({
      onSuccess: () => {
        refetch();
      },
    });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotificationsCount = () => {
    return totalUnread > 99 ? "99+" : totalUnread;
  };

  const notificationsCount = getNotificationsCount();

  const handleMarkAllAsRead = () => {
    markAllUserBellNotificationsAsRead({});
    setTotalUnread(0);
    setIsAllMarkedAsRead(true);
  };

  useEffect(() => {
    setTotalUnread(
      bellNotificationsTotalUnread?.getBellNotificationsTotalUnread
        ?.totalUnread ?? 0
    );
  }, [
    bellNotificationsTotalUnread?.getBellNotificationsTotalUnread?.totalUnread,
  ]);

  useEffect(() => {
    // get notifications every 5 minutes
    const interval = setInterval(() => {
      refetch();
    }, 300000);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isMarkAllAsReadDisabled = totalUnread === 0;

  return (
    <ThemeProvider theme={theme}>
      <IconButton
        size="large"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={!notificationsCount ? "!p-0" : "!pl-2 !pr-3"}
        data-testid="notifications-bell-button"
        sx={{
          margin: 0,
          padding: !notificationsCount ? 0 : "",
        }}
      >
        <NotificationsNoneIcon className="text-primary" />
        {!!notificationsCount && (
          <div
            data-testid="notifications-bell-count"
            className="!bg-error !text-white h-[18px] leading-[18px] top-[6px] left-[20px] text-xs font-bold px-1 rounded-2xl absolute"
          >
            {notificationsCount}
          </div>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-testid="notifications-menu"
        className="!text-xs"
        PaperProps={{
          id: "scrollableMenu",
        }}
      >
        <div className="flex mb-2 justify-between items-baseline">
          <Typography
            data-testid="notifications-menu-title"
            className="px-4 py-2 !text-base !font-medium"
          >
            Notifications
          </Typography>
          <Button
            className="global-btn !h-6 !rounded-[100px] !border-2 !border-solid !py-0 !px-[8px] !text-xs !font-bold !text-primary"
            disabled={isMarkAllAsReadDisabled}
            variant="text"
            type="button"
            onClick={handleMarkAllAsRead}
            data-testid="notifications-mark-all-as-read-button"
          >
            Mark all as read
          </Button>
        </div>
        <Divider className="!mb-2" />
        <NotificationsList
          queryClient={queryClient}
          setAlertNotificationId={setAlertNotificationId}
          setIsOpenModal={setIsOpenModal}
          setIsAllMarkedAsRead={setIsAllMarkedAsRead}
          isAllMarkedAsRead={isAllMarkedAsRead}
        />
      </Menu>
      {alertNotificationId && (
        <ModalTemplates
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          alertNotificationId={alertNotificationId}
          useDefaultQuery={false}
        />
      )}
    </ThemeProvider>
  );
};
