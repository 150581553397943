import { FC, MouseEvent, memo } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, Checkbox, Typography } from "@mui/material";

export type ItemType = {
  id: string;
  label: string;
  selected: boolean;
  visible: boolean;
};

type MultiSelectItemProps = {
  item: ItemType;
  onValueChange: (item: ItemType) => void;
  disableHover?: boolean;
};

export const MultiSelectItem: FC<MultiSelectItemProps> = memo(
  ({ item, onValueChange, disableHover }) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onValueChange(item);
    };

    return (
      <Box
        className={`flex items-start pl-4 ${
          !disableHover ? "hover:bg-brand-transparent" : ""
        } ${item.selected ? "bg-brand-transparent" : ""}`}
        aria-hidden={!item.visible}
        data-testid={`multi-select-drop-down-item-${item.id}`}
      >
        <Checkbox
          data-testid={`multi-select-drop-down-item-checkbox-${item.id}`}
          className="!mr-3 !pt-2 !pb-0 !pr-0 !pl-0 !text-brand"
          onClick={handleClick}
          checked={item.selected}
          icon={
            <CheckBoxOutlineBlankIcon
              className="!text-checkbox-unchecked"
              fontSize="small"
            />
          }
          checkedIcon={
            <CheckBoxIcon className="!text-brand" fontSize="small" />
          }
        />

        <Box className="pt-1.5">
          <Typography>{item.label}</Typography>
        </Box>
      </Box>
    );
  }
);
