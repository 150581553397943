import {
  AssignOrgProfileToAssetsInput,
  Maybe,
  MergedAtisAlphaSensorConfig,
  SensorProfileType,
} from "../../../../../../graphql/operations";

type DefaultAtisProfileAndTypeofAssigment = {
  defaultAtisAlphaSensorProfile: Maybe<string> | undefined;
  defaultTypeOfAssignment: string;
};

export const getDefaultAtisAlphaSensorProfileAndAssigmentType = (
  atisAlphaSensorProfile: Maybe<MergedAtisAlphaSensorConfig> | undefined
): DefaultAtisProfileAndTypeofAssigment => {
  let defaultAtisAlphaSensorProfile = undefined;
  let defaultTypeOfAssignment = "custom";

  if (atisAlphaSensorProfile?.type !== SensorProfileType.Asset) {
    defaultTypeOfAssignment = "profile";
    defaultAtisAlphaSensorProfile = atisAlphaSensorProfile?.profileId;
  }

  return { defaultAtisAlphaSensorProfile, defaultTypeOfAssignment };
};

export const prepareProfileToBeAssignedToAssetsInput = (
  selectedRowsIds: string[],
  selectedProfile: Maybe<string> | undefined
): AssignOrgProfileToAssetsInput => {
  return {
    assetIds: selectedRowsIds,
    profileId: selectedProfile ?? "",
  };
};
