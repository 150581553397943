import React, { ReactNode, useMemo } from "react";
import { Box, Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import {
  gridColumnsTotalWidthSelector,
  gridColumnPositionsSelector,
  gridDensityRowHeightSelector,
  useGridApiContext,
} from "@mui/x-data-grid-premium";

const SkeletonCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(0.5),
}));

const LoaderOverlay = () => {
  const apiRef = useGridApiContext();

  const dimensions = apiRef.current?.getRootDimensions();
  const viewportHeight = dimensions?.viewportInnerSize.height ?? 600;

  const rowHeight = gridDensityRowHeightSelector(apiRef);
  const skeletonRowsCount = Math.ceil(viewportHeight / rowHeight);

  const totalWidth = gridColumnsTotalWidthSelector(apiRef);
  const positions = gridColumnPositionsSelector(apiRef);
  const inViewportCount = useMemo(
    () => positions.filter((value) => value <= totalWidth).length,
    [totalWidth, positions]
  );
  const visibleColumns = useMemo(
    () => apiRef.current.getVisibleColumns().slice(0, inViewportCount),
    [apiRef, inViewportCount]
  );

  const children = useMemo(() => {
    const array: ReactNode[] = [];

    for (let i = 0; i < skeletonRowsCount; i += 1) {
      visibleColumns.forEach((element, index) => {
        const width = Math.round(25 + Math.random() * (75 - 25));
        array.push(
          <SkeletonCell
            data-testid="skeleton-cell"
            key={`col-${element.field}-${i}`}
          >
            <Skeleton sx={{ mx: 0, marginLeft: 0 }} width={`${width}%`} />
          </SkeletonCell>
        );
      });
    }
    return array;
  }, [skeletonRowsCount, visibleColumns]);

  return (
    <div
      data-testid="loading-overlay"
      style={{
        display: "grid",
        gridTemplateColumns: `${visibleColumns
          .map(({ computedWidth }) => `${computedWidth}px`)
          .join(" ")}`,
        gridAutoRows: `${rowHeight}px`,
      }}
    >
      {children}
    </div>
  );
};

export const LoadingOverlay = React.memo(LoaderOverlay);
