import BatteryChartLegend from "../../../../Shared/BatteryChart/BatteryChartLegend";
import {
  BRAKE_LIGHT_SCENARIO_COLORS,
  BrakeLightScenarioTitlesMap,
  LeftTurnLightScenarioTitlesMap,
  LicenseLightScenarioTitlesMap,
  LITE_SENTRY_LIGHT_TYPES,
  MarkerLightScenarioTitlesMap,
  OtherLightScenarioColors,
  RightTurnLightScenarioTitlesMap,
} from "./helpers";

export interface LightsChartLegendProps {
  hiddenDots: number[];
  onClick: (key: number) => void;
  lightSensorType: LITE_SENTRY_LIGHT_TYPES;
}

export const LightsChartLegend: React.FC<LightsChartLegendProps> = ({
  hiddenDots,
  onClick,
  lightSensorType,
}) => {
  return (
    <>
      {lightSensorType === LITE_SENTRY_LIGHT_TYPES.BrakeLight &&
        Array.from(BrakeLightScenarioTitlesMap.keys()).map((key) => (
          <BatteryChartLegend
            key={key}
            label={BrakeLightScenarioTitlesMap.get(key) as string}
            checked={!hiddenDots.includes(key)}
            color={BRAKE_LIGHT_SCENARIO_COLORS.get(key) as string}
            onClick={() => onClick(key)}
          />
        ))}
      {lightSensorType === LITE_SENTRY_LIGHT_TYPES.LeftTurnSignal &&
        Array.from(LeftTurnLightScenarioTitlesMap.keys()).map((key) => (
          <BatteryChartLegend
            key={key}
            label={LeftTurnLightScenarioTitlesMap.get(key) as string}
            checked={!hiddenDots.includes(key)}
            color={OtherLightScenarioColors.get(key) as string}
            onClick={() => onClick(key)}
          />
        ))}
      {lightSensorType === LITE_SENTRY_LIGHT_TYPES.RightTurnSignal &&
        Array.from(RightTurnLightScenarioTitlesMap.keys()).map((key) => (
          <BatteryChartLegend
            key={key}
            label={RightTurnLightScenarioTitlesMap.get(key) as string}
            checked={!hiddenDots.includes(key)}
            color={OtherLightScenarioColors.get(key) as string}
            onClick={() => onClick(key)}
          />
        ))}
      {lightSensorType === LITE_SENTRY_LIGHT_TYPES.License &&
        Array.from(LicenseLightScenarioTitlesMap.keys()).map((key) => (
          <BatteryChartLegend
            key={key}
            label={LicenseLightScenarioTitlesMap.get(key) as string}
            checked={!hiddenDots.includes(key)}
            color={OtherLightScenarioColors.get(key) as string}
            onClick={() => onClick(key)}
          />
        ))}
      {lightSensorType === LITE_SENTRY_LIGHT_TYPES.Marker &&
        Array.from(MarkerLightScenarioTitlesMap.keys()).map((key) => (
          <BatteryChartLegend
            key={key}
            label={MarkerLightScenarioTitlesMap.get(key) as string}
            checked={!hiddenDots.includes(key)}
            color={OtherLightScenarioColors.get(key) as string}
            onClick={() => onClick(key)}
          />
        ))}
    </>
  );
};
