import { GridValueFormatterParams } from "@mui/x-data-grid-premium";
import { isNil } from "lodash";

/**
 * Formats the value of the imei column. Adds a tab character at the end of the value to avoid displaying
 * value in the scientific format while opening CSV file in Excel.
 * @param params
 */
export const imeiValueFormatter = (params: GridValueFormatterParams) =>
  isNil(params.value) ? undefined : params.value ? `${params.value}\t` : "";

export const arrayValueFormatter = (params: GridValueFormatterParams) =>
  isNil(params.value) ? undefined : params.value ? params.value.join(" ,") : "";

export const associatedValueFormatter = (params: GridValueFormatterParams) =>
  isNil(params.value) ? "No" : "Yes";

export const snakeCaseToCapitalizedCaseValueFormatter = (
  params: GridValueFormatterParams
) => {
  if (isNil(params.value)) return "";
  const words = params.value.split("_");

  return words
    .map((word: string) => {
      return `${word[0].toUpperCase()}${word.slice(1)}`;
    })
    .join(" ");
};
