import { useAppContext } from "../../../context/AppContext";
import { env } from "../../../env";
import { themes } from "./utils";

/**
 * Single source for extracting current application theme
 * @returns
 */
export const useApplicationTheme = () => {
  const { state } = useAppContext();
  const appTheme = state?.theme?.theme;
  return {
    applicationTheme: appTheme || (env.REACT_APP_THEME as themes) || "light",
  };
};
