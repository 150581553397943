import {
  FormLabel,
  FormHelperText,
  Input as InputElement,
  OutlinedInput as OutlinedInputElement,
} from "@mui/material";
import { styled } from "@mui/system";
import { get, capitalize } from "lodash";

export const HelperText = styled(FormHelperText)(() => ({
  marginLeft: 0,
  marginRight: 0,
}));

export const Label = styled(FormLabel)(() => ({
  fontWeight: "bold",
  fontSize: 14,
}));

export const Input = styled(InputElement)(() => ({
  "label + &": {
    marginTop: 0,
  },
}));

export const OutlinedInput = styled(OutlinedInputElement)(() => ({
  padding: 4,
}));

/**
 * Renders helper text if there are errors or hint
 * @param errors
 * @param name
 * @param hint
 * @returns
 */
export const renderFormErrorHint = (
  errors: any,
  name: string,
  hint: string
) => {
  const error = get(errors, name, undefined);
  const message = get(error, "message");
  const messageFromNestedObject = get(error, "value.message");
  const messageToRender = message
    ? message
    : messageFromNestedObject
    ? messageFromNestedObject
    : hint;
  return (
    (error || hint) && <HelperText>{capitalize(messageToRender)}</HelperText>
  );
};
