/*
 * Safely parses a JSON value or returns a defaultValue instead
 */
export function safeJsonParse(jsonStr: string, defaultValue?: any): any {
  try {
    return JSON.parse(jsonStr);
  } catch {
    return defaultValue;
  }
}
