import React, { FC, memo } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import { AlertRemindersSelect } from "../../../../views/AlertView/components/AlertRemindersSelect";
import { RequiredFlag } from "../../RequiredFlag";

interface AlertRemindersElementProps {
  label?: string;
  rules?: ControllerProps["rules"];
  name: string;
  control: Control<any>;
  labelClassName?: string;
  required?: boolean;
  form: any;
  disabled: boolean;
}

export const AlertRemindersElement: FC<AlertRemindersElementProps> = memo(
  ({
    label,
    rules,
    name,
    control,
    labelClassName = "!mb-2 !text-sm !font-bold",
    required,
    disabled,
    ...rest
  }: AlertRemindersElementProps) => {
    return (
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error, invalid },
        }) => (
          <FormControl
            fullWidth
            required={required}
            variant="standard"
            error={invalid}
            disabled={disabled}
          >
            {label && (
              <Typography
                className={labelClassName}
                data-testid="alert-reminders-select-element-label"
              >
                {label}
                {rules?.required && <RequiredFlag />}
              </Typography>
            )}
            <AlertRemindersSelect
              {...rest}
              values={[value]}
              onChange={onChange}
              onBlur={onBlur}
              invalid={invalid}
              required={required}
            />

            {error?.message && (
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    );
  }
);
