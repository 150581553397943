import { useMemo, useState, useEffect } from "react";
import { useAppContext } from "../../../context/AppContext";
import { useBrowserColorScheme } from "./useBrowserColorScheme";
import { themes } from "./utils";

/**
 * This function "detects" color scheme should be applied basing on preference.
 * @param preference
 * @param browserColorScheme
 */
const detect = (preference: themes, browserColorScheme: themes): themes => {
  return preference === themes.system ? browserColorScheme : preference;
};

/**
 * This hook helps to set theme preference and return suitable color scheme name.
 * @param initial
 */
export const useTheme = (initial: themes = themes.dark) => {
  const browserColorScheme = useBrowserColorScheme();
  const { state } = useAppContext();
  const brandColor = state?.theme?.brandColor;
  const appTheme = state?.theme?.theme || initial;
  const [preference, setPreference] = useState<themes>(appTheme);
  const theme = useMemo(
    () => detect(preference, browserColorScheme),
    [preference, browserColorScheme]
  );
  useEffect(() => {
    setPreference(appTheme);
  }, [appTheme]);

  return {
    preference,
    setPreference,
    browserColorScheme,
    theme,
    brandColor,
  };
};
