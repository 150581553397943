import { Box, Grid, Typography } from "@mui/material";
import { SensorStatusUppercase } from "../../../../../../graphql/operations";
import { LightCircuitMergedDataResult } from "../utils/utils";
import LightStatusCard from "./LightStatusCard";

type LightCircuitsProps = {
  data: LightCircuitMergedDataResult;
};
const LightCircuits = ({ data }: LightCircuitsProps) => {
  return (
    <Box
      className="w-full flex flex-col gap-4"
      data-testid="light-sensors-tab-panel--light-circuits-card-wrapper"
    >
      <Typography
        className="text-brand"
        sx={{
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "26px",
        }}
      >
        Light Circuit Sensors
      </Typography>
      <Grid container className="w-full !pl-12 !pr-12">
        {Object.entries(data).map(([circuitName, circuitData], index) => (
          <LightStatusCard
            key={index}
            label={circuitName}
            status={
              circuitData?.statusDeviation ?? SensorStatusUppercase.Unknown
            }
          />
        ))}
      </Grid>
    </Box>
  );
};

export default LightCircuits;
