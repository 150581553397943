import { FC } from "react";
import { Typography } from "@mui/material";
import { Maybe } from "../../../../graphql/operations";
import { parseISODate } from "../../../../utils/date";
import { SchedulingRepeat } from "../../../../utils/scheduling";
import { buildOccurrenceStringParams } from "../../../../views/ReportView/helpers/helpers";

type BuildOccurrenceStringParamsProps = {
  endDate?: Maybe<Date | string>;
  frequency?: string;
  on?: string[];
  repeat?: string;
  startDate?: Maybe<Date | string>;
};
export const Occurrence: FC<BuildOccurrenceStringParamsProps> = ({
  endDate,
  frequency,
  on,
  repeat,
  startDate,
}) => {
  const processedStartDate =
    typeof startDate === "string" ? parseISODate(startDate) : startDate;
  const processedEndDate =
    typeof endDate === "string" ? parseISODate(endDate) : endDate;
  const { occurrence, starting, ending } = buildOccurrenceStringParams({
    frequency,
    repeat,
    on,
    startDate: processedStartDate ?? undefined,
    endDate: processedEndDate ?? undefined,
  });
  return (
    <Typography className="!text-sm" data-testid="frequency-occurrence">
      {frequency === SchedulingRepeat.Never ? (
        <span>
          Occurs <span className="font-bold">{occurrence}</span> on {starting}
        </span>
      ) : (
        <span>
          Occurs <span className="font-bold">{occurrence}</span> starting{" "}
          {starting} {ending ? `until ${ending}` : ``}
        </span>
      )}
    </Typography>
  );
};
