export const ATIS_ALPHA_FORM_FIELDS = [
  {
    name: "typeOfAssignment",
    type: "autocomplete",
    label: "Type of Assignment",
    options: [
      {
        label: "Custom",
        value: "custom",
        id: "custom",
      },
      {
        label: "Profile",
        value: "profile",
        id: "profile",
      },
    ],
    required: true,
  },
  {
    name: "atisAlphaSensorProfile",
    type: "autocomplete",
    label: "Profile Name",
    options: [],
    required: true,
  },
];
