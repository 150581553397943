import {
  DEFAULT_DISTANCE_UNIT,
  DEFAULT_PRESSURE_UNIT,
  DEFAULT_TEMPERATURE_UNIT,
} from "../../../../../../constants/units";
import { FormFieldsData, DefaultValuesInput } from "../../types";

const useFormDefaultValues = ({
  orgToEdit,
  defaultBrandForDropdown,
}: DefaultValuesInput) => {
  const prepareObject: FormFieldsData = {
    brand: orgToEdit?.brand?._id
      ? orgToEdit?.brand?._id ?? ""
      : defaultBrandForDropdown,
    name: orgToEdit?.name ?? "",
    account_number: orgToEdit?.account_number ?? "",
    parent_company: orgToEdit?.parent_org_id ?? "",
    type: orgToEdit?.type ?? "",
    time_zones: orgToEdit?.time_zones ?? "",
    distance_unit_preference:
      orgToEdit?.distance_unit_preference ?? DEFAULT_DISTANCE_UNIT,
    pressure_unit_preference:
      orgToEdit?.pressure_unit_preference ?? DEFAULT_PRESSURE_UNIT,
    temperature_unit_preference:
      orgToEdit?.temperature_unit_preference ?? DEFAULT_TEMPERATURE_UNIT,
    default_dashboard: orgToEdit?.default_dashboard?.toString() ?? undefined,
    favorite_dashboards: (orgToEdit?.favorite_dashboards ?? []).map(String),
  };

  return prepareObject;
};

export default useFormDefaultValues;
