import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadScriptNext } from "@react-google-maps/api";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import App from "./App";
import config from "./config";
import { googleLibraries } from "./constants/map";
import { AppContextProvider } from "./context/AppContext";
import AuthContextProvider from "./context/AuthContext";
import { env } from "./env";
import initializePendo from "./services/pendo/initializePendo";
import "./styles/main.scss";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
  });
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: 5 * 60 * 1000,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  initializePendo(env.REACT_APP_PENDO_IO_KEY);

  root.render(
    <LDProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <CookiesProvider>
          <AppContextProvider>
            <AuthContextProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <LoadScriptNext
                  // this replace default "Loading..." text by google map added in element with id root in dom three
                  loadingElement={<div id="google-map-loading"></div>}
                  googleMapsApiKey={config.map.apiKey}
                  libraries={googleLibraries}
                >
                  <div className="h-full font-sans">
                    <App />
                  </div>
                </LoadScriptNext>
              </LocalizationProvider>
            </AuthContextProvider>
          </AppContextProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </LDProvider>
  );
})();
