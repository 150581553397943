import { ReportScheduleFrequency } from "../../../graphql/operations";
import { SchedulingRepeat } from "../../../utils/scheduling";
import {
  fillEveryOptions,
  fillHourOptions,
  fillOnOptions,
} from "../../../views/ReportView/helpers/helpers";

export const FREQUENCY_OPTIONS = [
  { label: "Send Once", value: ReportScheduleFrequency.DoNotRepeat },
  { label: "Every Week Day", value: ReportScheduleFrequency.EveryWeekDay },
  {
    label: "Every Day (incl. Weekends)",
    value: ReportScheduleFrequency.EveryDay,
  },
  {
    label: "First Day of the Month",
    value: ReportScheduleFrequency.FirstDayOfMonth,
  },
  {
    label: "Last Day of the Month",
    value: ReportScheduleFrequency.LastDayOfMonth,
  },
  {
    label: "First Business Day of the Month",
    value: ReportScheduleFrequency.FirstBusinessDayOfMonth,
  },
  {
    label: "Last Business Day of the Month",
    value: ReportScheduleFrequency.LastBusinessDayOfMonth,
  },
  {
    label: "Custom",
    value: ReportScheduleFrequency.Custom,
  },
];

export const AM_PM_OPTIONS = [
  {
    label: "AM",
    value: "AM",
  },
  {
    label: "PM",
    value: "PM",
  },
];

export const HOUR_OPTIONS = fillHourOptions();

export const REPEAT_OPTIONS = [
  {
    label: "Never",
    value: SchedulingRepeat.Never,
  },
  {
    label: "Daily",
    value: SchedulingRepeat.Daily,
  },
  {
    label: "Weekly",
    value: SchedulingRepeat.Weekly,
  },
  {
    label: "Monthly",
    value: SchedulingRepeat.Monthly,
  },
  {
    label: "Yearly",
    value: SchedulingRepeat.Yearly,
  },
];

export const EVERY_OPTIONS = fillEveryOptions();

export const ON_OPTIONS = fillOnOptions();
