import * as yup from "yup";
import {
  ParseFileContentError,
  parseFileContent,
  transformers,
} from "../../../utils";
import { SensorProfileFileData } from "./types";

type MapFileDataResult = {
  validatedSensorProfiles: any[];
  validationErrors: ParseFileContentError[];
};

export type ValidatedSensor = {
  sensor: SensorProfileInput;
  row: number;
};

export type SensorProfileInput = {
  asset_id: string;
  imei: string;
  name: string;
  orgId: string;
};

const schema = yup.object().shape({
  asset_id: yup
    .string()
    .required("Asset ID is required")
    .transform(transformers.string),
  imei: yup
    .string()
    .transform(transformers.string)
    // Allow quotes and numbers for IMEI
    .matches(/^['|"]?[0-9]*['|"]?$/, { message: "Device IMEI is not valid" })
    .transform((value) => (value ? value.replace(/['|"]/g, "") : undefined)) // Ignore quotes
    .min(14, "Device IMEI should be min 14 and max 16 numbers length")
    .max(16, "Device IMEI should be min 14 and max 16 numbers length")
    .required(),
  name: yup
    .string()
    .required("Sensor Name is required")
    .transform(transformers.string),
  orgId: yup
    .string()
    .required("Organization ID is required")
    .transform(transformers.string),
});

export const parseSensorProfilesFile = async (
  file: File
): Promise<{
  fileData: SensorProfileFileData[];
  errors: ParseFileContentError[];
}> => {
  const map: { [key: string]: keyof SensorProfileFileData } = {
    "Asset ID*": "assetId",
    "Device ID*": "deviceId",
    "Profile Name*": "profileName",
  };

  try {
    const { data, errors } = await parseFileContent<SensorProfileFileData>({
      file,
      map,
    });
    return { fileData: data, errors };
  } catch (error) {
    let message = "Error parsing file.";
    if (error instanceof Error) {
      message = error.message;
    }
    return { fileData: [], errors: [{ row: 0, message }] };
  }
};

export const mapFileDataToCreateAssetInput = (
  data: SensorProfileFileData[],
  customer_orgs_id: string
): MapFileDataResult => {
  const errors: ParseFileContentError[] = [];

  if (!data.length) {
    errors.push({ row: 0, message: "Uploaded file is empty" });
  }

  const mapped = data.map((fileData, index) => {
    const row = index + 1; // Add 1 to index to get row number

    try {
      const sensor: SensorProfileInput = schema.validateSync(
        {
          asset_id: fileData.assetId,
          name: fileData.profileName,
          imei: fileData.deviceId,
          orgId: customer_orgs_id,
        },
        { abortEarly: false }
      );

      return { sensor, row };
    } catch (error: any) {
      if (error.inner) {
        error.inner.forEach((err: yup.ValidationError) => {
          errors.push({
            row,
            message: err.message ?? "Error validating asset",
          });
        });
      } else {
        errors.push({
          row,
          message:
            error instanceof Error ? error.message : "Error validating asset",
        });
      }

      return null;
    }
  });

  return {
    validatedSensorProfiles: mapped.filter(
      (sensor): sensor is ValidatedSensor => !!sensor
    ),
    validationErrors: errors,
  };
};
