import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";

export enum TiresTabView {
  Card = "card-view",
  Table = "table-view",
}

export const TAB_VIEW_LABEL: Record<TiresTabView, string> = {
  [TiresTabView.Card]: "Card View",
  [TiresTabView.Table]: "Table View",
};

export interface ToggleViewButtonProps {
  activeView: TiresTabView;
  onClick: (newActiveView: TiresTabView) => void;
  whiteTheme?: boolean;
}

const ToggleViewButton: React.FC<ToggleViewButtonProps> = ({
  activeView = TiresTabView.Card,
  onClick,
  whiteTheme = false,
}) => {
  const isDesktop = useBreakpoint("up", "xl");

  const handleOnClick = (
    event: React.MouseEvent<HTMLElement>,
    newActiveView: string | null
  ) => {
    if (newActiveView !== null) {
      onClick(newActiveView as TiresTabView);
    }
  };

  return (
    <ToggleButtonGroup
      value={activeView}
      exclusive
      onChange={handleOnClick}
      aria-label="Tire Sensors View"
      className="border h-[32px] min-w-[160px]"
      sx={{
        borderRadius: "24px",
        backgroundColor: whiteTheme
          ? "var(--asset-toggle-view-button-background-light)"
          : "var(--asset-toggle-view-button-background)",
        borderColor: whiteTheme
          ? "var(--asset-toggle-view-button-background-light)"
          : "var(--asset-toggle-view-button-background)",
        "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type)":
          {
            borderTopRightRadius: "24px",
            borderBottomRightRadius: "24px",
          },
        "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type)":
          {
            borderTopLeftRadius: "24px",
            borderBottomLeftRadius: "24px",
          },
      }}
      data-testid="asset-dashboard--tires-tab--toggle-view-button"
    >
      <ToggleButton
        sx={{
          borderRadius: "24px",
          fontWeight: 500,
          fontSize: "12px",
          textTransform: "none",
          borderColor: whiteTheme
            ? "var(--asset-toggle-view-button-background-light)"
            : "var(--asset-toggle-view-button-background)",
          padding: isDesktop ? "12px 16px" : "6px",
          "&.MuiButtonBase-root, &.MuiButtonBase-root:hover": {
            backgroundColor: whiteTheme
              ? "var(--asset-toggle-view-button-background-light)"
              : "var(--asset-toggle-view-button-background)",
            color: whiteTheme
              ? "var(--asset-toggle-view-button-background-black)"
              : "var(--asset-toggle-view-button-text)",
          },
          "&.Mui-selected,&.Mui-selected:hover": {
            backgroundColor: "var(--blue)",
            color: "var(--white)",
          },
        }}
        value={TiresTabView.Card}
        aria-label="Card View"
      >
        {TAB_VIEW_LABEL[TiresTabView.Card]}
      </ToggleButton>
      <ToggleButton
        sx={{
          borderRadius: "24px",
          fontWeight: 500,
          fontSize: "12px",
          textTransform: "none",
          borderColor: whiteTheme
            ? "var(--asset-toggle-view-button-background-light)"
            : "var(--asset-toggle-view-button-background)",
          padding: isDesktop ? "12px 16px" : "6px",
          "&.MuiButtonBase-root, &.MuiButtonBase-root:hover": {
            backgroundColor: whiteTheme
              ? "var(--asset-toggle-view-button-background-light)"
              : "var(--asset-toggle-view-button-background)",
            color: whiteTheme
              ? "var(--asset-toggle-view-button-background-black)"
              : "var(--asset-toggle-view-button-text)",
          },
          "&.Mui-selected,&.Mui-selected:hover": {
            backgroundColor: "var(--blue)",
            color: "var(--white)",
          },
        }}
        value={TiresTabView.Table}
        aria-label="Table View"
      >
        {TAB_VIEW_LABEL[TiresTabView.Table]}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleViewButton;
