import { useState, useEffect, useMemo, FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { ReactComponent as PsiWheelEndSensorDark } from "../../../../../../assets/svgs/wheelEndTemperatureSensorDark.svg";
import { ReactComponent as PsiWheelEndSensorLight } from "../../../../../../assets/svgs/wheelEndTemperatureSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useGetSensorProfileNamesWithConfigurationQuery,
  SensorProfileType,
  SetAssetSensorProfilesInput,
  SensorProfileConfigType,
  AssignOrgProfileToAssetsInput,
  AssetWithSensors,
  SensorThresholdShort,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../../shared/components/SensorSlider/SensorSlider";
import {
  extractRules,
  prepareRulesPayload,
  sliderMarks,
  wheelEndDefaultValues,
} from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
} from "../../../../../../shared/helpers/battery";
import { useGetTemperatureUnitPreference } from "../../../../../../shared/hooks/useGetTemperatureUnitPreference";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import {
  getConvertedTemperatureValues,
  getTemperatureUnitLabel,
  prepareConvertedCelsiusTemperaturesPayload,
} from "../../../../../../utils/convertTemperature";
import {
  SensorProfileDropdownItem,
  sortSensorProfileNameDropdownItems,
} from "../../sensorsUtils";
import SelectedRecordInfo from "../../shared/SelectedRecordsInfo";
import SensorProfileAssignmentForm from "../Components/SensorProfileAssignmentForm";
import { useMutateCustomProfile, useMutateProfile } from "../drawer.hooks";
import ConfigurationDialogAdditionalElement from "./../Components/CheckboxComponent";
import { PSI_WHEEL_END_FORM_FIELDS } from "./constants";
import { sensorsPsiWheelEndSchema } from "./validationSchema";

export interface PsiWheelEndSensorDrawerProps {
  currentOrgId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: AssetWithSensors[];
}

const PsiWheelEndSensorDrawer: FC<PsiWheelEndSensorDrawerProps> = ({
  open,
  setOpen,
  selectedRecordsData,
  currentOrgId,
}) => {
  const queryClient = useQueryClient();
  let defaultPsiWheelEndSensorProfile = undefined;
  let defaultTypeOfAssignment = "custom";

  const tempUnitPreference = useGetTemperatureUnitPreference();

  const [minWheelEndTemperatureConverted, maxWheelEndTemperatureConverted] =
    getConvertedTemperatureValues(
      [MinValuesBySensorType.psiWheelEnd, MaxValuesBySensorType.psiWheelEnd],
      tempUnitPreference
    );

  const psiWheelEndSensorProfile =
    selectedRecordsData?.[0]?.sensorProfile?.configuration?.psiWheelEnd;
  const psiWheelEndThresholds = selectedRecordsData?.[0]?.psiWheelEnd;
  const isPresetProfile =
    psiWheelEndSensorProfile?.type !== SensorProfileType.Asset;
  if (isPresetProfile) {
    defaultTypeOfAssignment = "profile";
    defaultPsiWheelEndSensorProfile = psiWheelEndSensorProfile?.profileId;
  }

  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  //set default values and resolver to the main form
  const mainForm = useForm({
    resolver: yupResolver(sensorsPsiWheelEndSchema),
    defaultValues: {
      typeOfAssignment: defaultTypeOfAssignment,
      psiWheelEndSensorProfile: defaultPsiWheelEndSensorProfile,
    },
  });

  //states
  const initialPsiWheelEndValues =
    extractRules(psiWheelEndThresholds) || wheelEndDefaultValues;

  const [psiWheelEndValues, setPsiWheelEndValues] = useState(
    initialPsiWheelEndValues
  );

  const [
    isConfirmProfileAssignmentVisible,
    setIsConfirmProfileAssignmentVisible,
  ] = useState(false);
  const [isTypeOfAssignmentProfile, setIsTypeOfAssignmentProfile] =
    useState(false);
  const [profileInput, setProfileInput] = useState<
    AssignOrgProfileToAssetsInput | undefined
  >(undefined);
  const [dontShowConfirmationDialog, setDontShowConfirmationDialog] =
    useState(false);
  const [selectedProfileLabel, setSelectedProfileLabel] = useState<string>("");

  //effects

  useEffect(() => {
    if (isPresetProfile) {
      setIsTypeOfAssignmentProfile(true);
    }
    const isDontShowAgain =
      localStorage.getItem("setProfileConformation") === "true";
    setDontShowConfirmationDialog(isDontShowAgain);
  }, [selectedRecordsData, isPresetProfile]);

  // GraphQL
  const { data: profilesData, isLoading: isSensorProfileNamesLoading } =
    useGetSensorProfileNamesWithConfigurationQuery(
      {
        input: {
          orgId: currentOrgId ?? "",
          type: SensorProfileType.Organization,
          includeDefaultPctProfile: true,
          mergeProfilesWithDefaultPctProfile: true,
          sensorType: SensorProfileConfigType.PsiWheelEnd,
        },
      },
      {
        enabled: Boolean(currentOrgId),
      }
    );

  //handlers

  const handleConfirm = (confirmed: boolean) => {
    setIsConfirmProfileAssignmentVisible((prev) => !prev);
    if (!confirmed) {
      return;
    }
    if (dontShowConfirmationDialog) {
      localStorage.setItem("setProfileConformation", "true");
    }
    if (profileInput) {
      mutateProfile({ input: profileInput });
    }
  };

  const selectedAssetsImeis: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.imei)
    .filter((imei): imei is string => !!imei);

  const selectedRowsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?._id)
    .filter((id): id is string => !!id);

  const selectedAssetsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.asset_id)
    .filter((id): id is string => !!id);

  const sensorProfilesForOrganization = useMemo(
    () => profilesData?.getSensorProfilesForOrganization ?? [],
    [profilesData]
  );

  const psiWheelEndSensorProfiles: SensorProfileDropdownItem[] =
    sensorProfilesForOrganization.map((item: any) => ({
      label: item.name,
      value: item._id,
      id: item._id,
      configuration: item.configuration,
      type: item.type,
    }));

  PSI_WHEEL_END_FORM_FIELDS[1].options = sortSensorProfileNameDropdownItems(
    psiWheelEndSensorProfiles
  );

  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        text: "Sensor(s) Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const onSubmit = async () => {
    const isMainFormValid = await mainForm.trigger();

    if (!isMainFormValid) return;
    // type of Assignment is not profile submit else ask for confirmation
    if (!isTypeOfAssignmentProfile) {
      const input: SetAssetSensorProfilesInput = {
        selectedImeis: selectedAssetsImeis,
        orgId: currentOrgId ?? "",
        sensors: {
          psiWheelEnd: {
            temperature: prepareRulesPayload(
              SensorProfileConfigType.PsiWheelEnd,
              prepareConvertedCelsiusTemperaturesPayload(
                psiWheelEndValues,
                tempUnitPreference
              ),
              MinValuesBySensorType.psiWheelEnd,
              MaxValuesBySensorType.psiWheelEnd
            ),
          },
        },
      };
      mutateCustomProfile({ input });
    } else {
      const selectedProfile = mainForm.getValues().psiWheelEndSensorProfile;
      const input: AssignOrgProfileToAssetsInput = {
        assetIds: selectedRowsIds,
        profileId: selectedProfile ?? "",
      };
      // if we have dontShowConfirmationDialog again clicked
      // and submitted to local storage, don't show confirmation dialog
      if (dontShowConfirmationDialog) {
        mutateProfile({ input });
      } else {
        setProfileInput(input);
        setIsConfirmProfileAssignmentVisible((prev) => !prev);
      }
    }
  };

  // handle change of Type of Assignment - show/hide of PSI Wheel End Sensor question
  const handleAssignmentTypeInputChange = (e: {
    name: string;
    value: string;
  }) => {
    const value = e?.value ?? undefined;
    if (value === "profile") {
      setIsTypeOfAssignmentProfile(true);
    } else {
      setIsTypeOfAssignmentProfile(false);

      if (!isPresetProfile) setPsiWheelEndValues(initialPsiWheelEndValues);
    }
  };

  // handle change of psi wheel end sensor profile dropdown
  const handleSensorProfileInputChange = (selectedSensorProfile: {
    configuration: { psiWheelEnd: { temperature: SensorThresholdShort } };
    label: string;
  }) => {
    const psiWheelEndTresholds =
      selectedSensorProfile?.configuration?.psiWheelEnd?.temperature;
    const selectedSensorProfileValues =
      extractRules(psiWheelEndTresholds) || wheelEndDefaultValues;

    setPsiWheelEndValues(selectedSensorProfileValues);
    setSelectedProfileLabel(selectedSensorProfile?.label);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxClick = () => {
    setDontShowConfirmationDialog((prev) => !prev);
  };

  // this is a mutation that we use when Type of Assignment is CUSTOM
  const { mutate: mutateCustomProfile, isLoading: isLoadingCustomProfile } =
    useMutateCustomProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  // this is a mutation that we use when Type of Assignment is PROFILE
  const { mutate: mutateProfile, isLoading: isLoadingProfile } =
    useMutateProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  const isLoading = isLoadingProfile || isLoadingCustomProfile;

  const isSliderDirty = !isEqual(psiWheelEndValues, initialPsiWheelEndValues);
  const isCustomAndPristine =
    !isPresetProfile && !isTypeOfAssignmentProfile && !isSliderDirty;
  const isProfileAndPristine = isPresetProfile && !mainForm.formState.isDirty;

  const additionalCheckboxComponent = (
    <ConfigurationDialogAdditionalElement
      checked={dontShowConfirmationDialog}
      onChange={handleCheckboxClick}
    />
  );

  return (
    <Drawer
      testId="psi-wheel-end-sensor-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader text="Edit Sensor" onClose={handleClose} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box className="pb-10">
            {selectedRecordsData && (
              <SelectedRecordInfo selectedRecordsData={selectedRecordsData} />
            )}
            <Box className="pt-6 pb-10 flex-auto">
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                className="justify-left px-6"
              ></Grid>
              <SensorProfileAssignmentForm
                isTypeOfAssignmentProfile={isTypeOfAssignmentProfile}
                form={mainForm}
                SENSOR_FORM_FIELDS={PSI_WHEEL_END_FORM_FIELDS}
                handleAssignmentTypeInputChange={
                  handleAssignmentTypeInputChange
                }
                handleSensorProfileInputChange={handleSensorProfileInputChange}
                isProfileDataLoading={isSensorProfileNamesLoading}
              />
              <div
                className="flex flex-col"
                data-testid="sensor-profile-psi-wheel-end"
              >
                <Grid container className="bg-background px-6">
                  <Grid item xs={12}>
                    <Box className="mb-16">
                      <Typography sx={{ fontWeight: "bold" }}>
                        Wheel End Temperature Settings
                      </Typography>
                      {isLightTheme ? (
                        <PsiWheelEndSensorDark style={svgIconSettings} />
                      ) : (
                        <PsiWheelEndSensorLight style={svgIconSettings} />
                      )}
                    </Box>
                    <SensorSlider
                      values={psiWheelEndValues}
                      min={minWheelEndTemperatureConverted}
                      max={maxWheelEndTemperatureConverted}
                      disabled={isTypeOfAssignmentProfile}
                      onChange={setPsiWheelEndValues}
                      marks={sliderMarks(
                        minWheelEndTemperatureConverted,
                        maxWheelEndTemperatureConverted,
                        getTemperatureUnitLabel(tempUnitPreference, true)
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Box>

          <DrawerActions disabled={isLoading} onCancel={handleClose} />
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving..." : "Apply to Selected"}
        disabled={isLoading || isCustomAndPristine || isProfileAndPristine}
        testId="psi-wheel-end-sensors-drawer-submit-btn"
        submit={() => onSubmit()}
      />

      {isTypeOfAssignmentProfile && isConfirmProfileAssignmentVisible && (
        <ConfirmationDialog
          title="Profile Assignment"
          message={`Warning, all of the sensor settings of profile ${selectedProfileLabel}
             will be applied to asset(s) ${selectedAssetsIds.join(
               ", "
             )}. Any custom sensor settings will be lost!`}
          open={isConfirmProfileAssignmentVisible}
          confirmButtonText={"Confirm"}
          cancelButtonText="Cancel"
          handleConfirmationResult={handleConfirm}
          additionalContent={additionalCheckboxComponent}
        />
      )}
    </Drawer>
  );
};

export default PsiWheelEndSensorDrawer;
