import { memo, SVGProps, FC } from "react";

interface CloseProps extends SVGProps<SVGSVGElement> {}

const Close: FC<CloseProps> = (props) => {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="button"
    >
      <path
        d="M5.33342 1.13663L4.86341 0.666626L3.00008 2.52996L1.13675 0.666626L0.666748 1.13663L2.53008 2.99996L0.666748 4.86329L1.13675 5.33329L3.00008 3.46996L4.86341 5.33329L5.33342 4.86329L3.47008 2.99996L5.33342 1.13663Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Close);
