import { FC, ReactElement } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Button, TextButton } from "../Button";

export type ActionDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  name: string;
  title: string;
  textContent: string;
  cancelButtonText?: string;
  submitButtonText: string;
  children?: ReactElement;
  hideSubmitButton?: boolean;
  isSubmitDisabled?: boolean;
};

export const ActionDialog: FC<ActionDialogProps> = ({
  open,
  onClose,
  onSubmit,
  name,
  title,
  textContent,
  cancelButtonText = "Cancel",
  submitButtonText,
  children,
  hideSubmitButton = false,
  isSubmitDisabled = false,
}) => {
  return (
    <Dialog
      open={open}
      data-testid={`${name}-action-dialog`}
      onClose={onClose}
      aria-labelledby={`${name}-action-dialog-title`}
      aria-describedby={`${name}-action-dialog-description`}
      sx={{ backgroundColor: "var(--backdrop)" }}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        sx: {
          backgroundColor: "var(--background) !important",
          backgroundImage: "none",
          width: "100%",
          maxWidth: "720px !important",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        className="flex justify-between items-center"
        id={`${name}-action-dialog-title`}
        data-testid={`${name}-action-dialog-title`}
      >
        <span className="text-2xl font-semibold !text-typography-secondary">
          {title}
        </span>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="!pb-0">
        <DialogContentText
          className="!mb-7 text-base !text-typography-secondary"
          id={`${name}-action-dialog-description`}
          data-testid={`${name}-action-dialog-description`}
        >
          {textContent}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions className="!p-6">
        <TextButton
          text={cancelButtonText}
          size="medium"
          onClick={onClose}
          theme="blue"
          data-testid={`${name}-action-dialog-btn-form-cancel`}
          iconPosition="none"
          icon={undefined}
          className={undefined}
        />
        {!hideSubmitButton && (
          <Button
            text={submitButtonText}
            size="medium"
            theme="blue"
            variant="default"
            onClick={onSubmit}
            dataTestid={`${name}-action-dialog-btn-form-submit`}
            disabled={isSubmitDisabled}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};
