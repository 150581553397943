import {
  SensorStatusUppercase,
  TemperatureSensorDetails,
} from "../../../../../graphql/operations";

export interface TemperatureSummarydata {
  title: string;
  status: SensorStatusUppercase;
  temperature: string | null;
  receivedDate: Date;
}

export const mapTemperatureSummary = (
  temperatureSensor: TemperatureSensorDetails,
  tempSensorStatus: SensorStatusUppercase
): TemperatureSummarydata => {
  return {
    title: "Temperature Sensor",
    status: tempSensorStatus,
    temperature: temperatureSensor?.data?.internal?.toFixed(2) as string,
    receivedDate: temperatureSensor?.receivedDate,
  };
};
