import { FC } from "react";
import { Divider } from "@mui/material";
import { FREQUENCY_OPTIONS } from "../constants";
import { OptionElement } from "./Option";

interface DefaultFrequencyListProps {
  selectedDefaultOption: string;
  setSelectedDefaultOption: (value: string) => void;
}
export const DefaultFrequencyList: FC<DefaultFrequencyListProps> = ({
  selectedDefaultOption,
  setSelectedDefaultOption,
}) => {
  const options = [...FREQUENCY_OPTIONS].slice(1);
  const customOption = options.pop();
  return (
    <div>
      <OptionElement
        selected={selectedDefaultOption === FREQUENCY_OPTIONS[0].value}
        setSelectedDefaultOption={setSelectedDefaultOption}
        option={FREQUENCY_OPTIONS[0]}
      />
      <Divider />
      <div className="pb-4 pt-2">
        {options.map((option) => {
          return (
            <OptionElement
              key={option.value}
              setSelectedDefaultOption={setSelectedDefaultOption}
              selected={selectedDefaultOption === option.value}
              option={option}
            />
          );
        })}
        <Divider />
        <OptionElement
          setSelectedDefaultOption={setSelectedDefaultOption}
          selected={selectedDefaultOption === customOption!.value}
          option={customOption!}
        />
      </div>
    </div>
  );
};
