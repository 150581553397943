import { useMemo } from "react";
import { useSearchBatteryChartsDataBatteryOnlyQuery } from "../../../../../../graphql/operations";
import { formatBatteryChartsData } from "../helpers";
import {
  BATTERY_CHART_NAME,
  BatteryChartsDataHookProps,
  ChartDataResult,
} from "../types";

interface BatteryOnlyChartsDataHookResult {
  chartBatteryOnlyData: ChartDataResult[];
  isLoadingVoltageBatteryOnlyOsData: boolean;
  isFetchingVoltageBatteryOnlyOsData: boolean;
  isSuccessVoltageBatteryOnlyOsData: boolean;
}

// only battery data
export const useBatteryChartsBatteryOnlyData = ({
  imei,
  startDate,
  endDate,
  uiConfig,
}: BatteryChartsDataHookProps): BatteryOnlyChartsDataHookResult => {
  const {
    data: voltageBatteryOnlyOsData,
    isLoading: isLoadingVoltageBatteryOnlyOsData,
    isFetching: isFetchingVoltageBatteryOnlyOsData,
    isSuccess: isSuccessVoltageBatteryOnlyOsData,
  } = useSearchBatteryChartsDataBatteryOnlyQuery(
    {
      input: {
        imei,
        startDate: startDate,
        endDate: endDate,
      },
    },
    {
      enabled: Boolean(imei),
    }
  );

  const batteryOnlyChartsData = useMemo(
    () => voltageBatteryOnlyOsData?.searchEventHistory?.batteryChartsData ?? [],
    [voltageBatteryOnlyOsData?.searchEventHistory?.batteryChartsData]
  );

  const chartBatteryOnlyData = useMemo(() => {
    const expectedStartDate = startDate ?? new Date();
    const expectedEndDate = endDate ?? new Date();

    return formatBatteryChartsData(
      BATTERY_CHART_NAME.Battery,
      expectedStartDate,
      expectedEndDate,
      batteryOnlyChartsData,
      uiConfig
    );
  }, [batteryOnlyChartsData, startDate, endDate, uiConfig]);

  return {
    chartBatteryOnlyData,
    isLoadingVoltageBatteryOnlyOsData,
    isFetchingVoltageBatteryOnlyOsData,
    isSuccessVoltageBatteryOnlyOsData,
  };
};
