import {
  Maybe,
  TableValueDataType,
  TableColumnFormat as ReportColumnFormat,
} from "../../../../../graphql/operations";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  assetId: true,
  faultEndTime: true,
  faultStartTime: true,
  manufacturer: true,
  orgName: true,
  problemArea: true,
  sid: true,
  fmi: true,
  dtc: true,
  duration: true,
};

export const getColumns = (
  timezone: Maybe<string> | undefined
): ReportGridColDef[] => {
  const commonColumnConfig = {
    flex: 1,
    minWidth: 120,
  };

  const createColumn = (
    field: string,
    headerName: string,
    format: ReportColumnFormat,
    additionalConfig = {}
  ) => ({
    field,
    headerName,
    format,
    ...commonColumnConfig,
    ...additionalConfig,
  });

  const columns = [
    createColumn("assetId", "Asset ID", ReportColumnFormat.String),
    createColumn(
      "faultEndTime",
      "Fault End Time",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
      }
    ),
    createColumn(
      "faultStartTime",
      "Fault Start Time",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
      }
    ),
    createColumn("manufacturer", "Manufacturer", ReportColumnFormat.String),
    createColumn("orgName", "Organization", ReportColumnFormat.String),
    createColumn("problemArea", "Problem Area", ReportColumnFormat.String),
    createColumn("sid", "SID", ReportColumnFormat.String),
    createColumn("fmi", "FMI", ReportColumnFormat.String),
    createColumn("dtc", "DTC", ReportColumnFormat.String),
    createColumn("duration", "Duration", ReportColumnFormat.String),
  ];
  return columns;
};

export const SEARCH_KEYS = [
  "assetId",
  "faultEndTime",
  "faultStartTime",
  "manufacturer",
  "orgName",
  "problemArea",
  "sid",
  "fmi",
  "dtc",
  "duration",
];

export const ASSET_ABS_FAULT_COLUMN_TYPE_MAP: Record<
  string,
  TableValueDataType
> = {
  assetId: TableValueDataType.String,
  faultEndTime: TableValueDataType.Date,
  faultStartTime: TableValueDataType.Date,
  manufacturer: TableValueDataType.String,
  orgName: TableValueDataType.String,
  problemArea: TableValueDataType.String,
  sid: TableValueDataType.String,
  fmi: TableValueDataType.String,
  dtc: TableValueDataType.String,
  duration: TableValueDataType.Number,
};
