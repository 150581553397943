import { FC, ReactNode, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import RemoveIcon from "@mui/icons-material/Remove";
import { GoogleMap } from "@react-google-maps/api";
import { CENTER_FOCUS_ICON } from "../../../constants/map";
import { useAppContext } from "../../../context/AppContext";
import { GoogleMapsTypeIds, MapType } from "../../../enums/map";
import useGoogleMapFullscreen from "../../../views/AssetsView/MapView/hooks/useGoogleMapFullscreen";
import useGoogleMapStyle from "../../../views/AssetsView/MapView/hooks/useGoogleMapStyle";
import useGoogleMapViewChange from "../../../views/AssetsView/MapView/hooks/useGoogleMapViewChange";
import useGoogleMapZoom from "../../../views/AssetsView/MapView/hooks/useGoogleMapZoom";
import { MapViewSwitcher } from "../../../views/AssetsView/shared/AssetsFilterControls";

interface GoogleMapComponentProps {
  googleMap: google.maps.Map | null;
  googleMapCenter: google.maps.LatLngLiteral;
  handleMapLoaded: (mapInstance: google.maps.Map) => void;
  children?: ReactNode;
}
export const GoogleMapComponent: FC<GoogleMapComponentProps> = ({
  googleMap,
  googleMapCenter,
  handleMapLoaded,
  children,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();

  const { minZoom, defaultWidgetMapZoom } = appConfig.map;
  const [mapZoom, setMapZoom] = useState(defaultWidgetMapZoom);
  const { googleMapTypeId, setGoogleMapTypeId } = useGoogleMapStyle(
    MapType.Widget,
    GoogleMapsTypeIds.SATELLITE
  );
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);

  const { toggleFullscreen } = useGoogleMapFullscreen({
    mapInstance: googleMap,
    isMapFullscreen,
    setIsMapFullscreen,
  });

  const { handleZoomIn, handleZoomOut } = useGoogleMapZoom({
    mapInstance: googleMap,
    setMapZoom,
  });

  const onGoogleMapViewChange = useGoogleMapViewChange({
    mapInstance: googleMap,
    setGoogleMapTypeId,
  });

  return (
    <GoogleMap
      mapContainerStyle={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
      center={googleMapCenter}
      zoom={mapZoom}
      onLoad={handleMapLoaded}
      options={{
        restriction: {
          latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
          strictBounds: true,
        },
        mapTypeId: googleMapTypeId,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false,
        rotateControl: false,
        minZoom,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
        ],
      }}
    >
      {children}
      <section className="absolute top-4 right-12 sm:right-14">
        <MapViewSwitcher
          mapStyleSelected={googleMapTypeId}
          onMapViewChange={onGoogleMapViewChange}
        />
      </section>
      <button
        onClick={() => toggleFullscreen()}
        className="default-view-btn shadow-card-sm absolute top-[10px] right-[10px] p-[1px] rounded z-10 overflow-hidden"
        data-testid="map-widget-fullscreen-btn"
      >
        {isMapFullscreen && <FullscreenExitIcon sx={CENTER_FOCUS_ICON} />}
        {!isMapFullscreen && <FullscreenIcon sx={CENTER_FOCUS_ICON} />}
      </button>
      <button
        onClick={() => handleZoomIn()}
        className="default-view-btn shadow-card-sm absolute bottom-[48px] right-[10px] p-[1px] rounded z-10 overflow-hidden"
        data-testid="map-widget-zoom-in-btn"
      >
        {<AddIcon sx={CENTER_FOCUS_ICON} />}
      </button>
      <button
        onClick={() => handleZoomOut()}
        className="default-view-btn shadow-card-sm absolute bottom-[10px] right-[10px] p-[1px] rounded z-10 overflow-hidden"
        data-testid="map-widget-zoom-out-btn"
      >
        {<RemoveIcon sx={CENTER_FOCUS_ICON} />}
      </button>
    </GoogleMap>
  );
};
