export interface MediaActivityItem {
  imageUrl: string;
  address: string;
  floorUsagePercentage: string;
  floorUsageStatus: string;
  loadedEmptyConfidence: string;
  uploadDate: string;
  geofenceName: string;
  geofenceEntry: string;
  longitude: number;
  latitude: number;
}

export enum InternalCameraView {
  OneImage = 1,
  TwoImages = 2,
  FourImages = 4,
  SixImages = 6,
}
