import { FC } from "react";
import { Typography } from "@mui/material";
import { UserData } from "../../../../graphql/operations";
import { CheckBoxWithLabel } from "../../CheckBoxWithLabel/CheckBoxWithLabel";
import { StateObject } from "../interfaces";

interface UsersListProps {
  usersData: UserData[];
  selectedUsers: StateObject;
  setSelectedUsers: (users: StateObject) => void;
}

export const UsersList: FC<UsersListProps> = ({
  usersData,
  selectedUsers,
  setSelectedUsers,
}) => {
  const handleUsersChange = (userId: string, state: boolean) => {
    setSelectedUsers({ ...selectedUsers, [userId]: state });
  };
  return (
    <div className="mb-6">
      <Typography className="!mb-4 !text-sm !font-bold">Users</Typography>
      <div
        className="mb-6 max-h-36 overflow-auto"
        data-testid="subscribers-users-checkbox-group"
      >
        {usersData.map((user) => {
          return (
            <div key={user._id} className="hover:bg-brand-transparent">
              <CheckBoxWithLabel
                name={user._id}
                label={`${user.firstName} ${user.lastName} (${user.name})`}
                checked={selectedUsers[user._id]}
                onValueChange={handleUsersChange}
                dataTestId={`subscribers-user-checkbox-${user._id}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
