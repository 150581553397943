import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Button, TextButton } from "../Button";

interface InactivateAssetDialogProps {
  open: boolean;
  handleClose: () => void;
  handleInactivate: () => void;
  isLoading: boolean;
}

export const InactivateAssetDialog: FC<InactivateAssetDialogProps> = ({
  open,
  handleClose,
  handleInactivate,
  isLoading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="report-scheduleModal-dialog-title"
      aria-describedby="report-scheduleModal-dialog-description"
      data-testid="inactivate-asset-dialog"
      sx={{ backgroundColor: "var(--backdrop)" }}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        sx: {
          backgroundColor: "var(--background) !important",
          backgroundImage: "none",
          width: "100%",
          maxWidth: "480px !important",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        className="flex justify-between !p-6 items-baseline"
        id="report-scheduleModal-dialog-title"
        data-testid="inactivate-asset-dialog-title"
      >
        <span className="text-2xl font-semibold !text-typography-secondary">
          Confirm Asset Inactivation
        </span>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="!pb-0 !px-6">
        <DialogContentText
          className="!mb-2 flex items-center text-base !text-typography-secondary"
          id="report-scheduleModal-dialog-heading"
          data-testid="inactive-asset-dialog-content"
        >
          <span className="text-base font-normal">Are you sure?</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="!p-6">
        <TextButton
          text="Cancel"
          size="medium"
          onClick={handleClose}
          theme="blue"
          data-testid="inactive-asset-dialog-cancel-btn"
          iconPosition="none"
          icon={undefined}
          className="!p-0"
          disabled={isLoading}
        />
        <Button
          text="Confirm"
          disabled={isLoading}
          size="medium"
          theme="blue"
          variant="default"
          onClick={handleInactivate}
          dataTestid="inactive-asset-dialog-inactivate-btn"
          className="!ml-4 !h-[40px] !py-3"
          iconPosition="right"
          icon={
            isLoading && (
              <CircularProgress size={15} style={{ color: "white" }} />
            )
          }
        />
      </DialogActions>
    </Dialog>
  );
};
