export enum FeatureFlags {
  Connect1RolloutGeofencing = "connect1RolloutGeofencing",
  Connect1RolloutReports = "connect1RolloutReports",
  Connect1RolloutAlerts = "connect1RolloutAlerts",
  Connect1FetchAssetsFromOpenSearch = "connect1FetchAssetsFromOpenSearch", // TODO: Cleanup with PRJIND-9218
  connect1RolloutCustomAuthFlow = "connect1RolloutCustomAuthFlow",

  /**
   * Disable the option to navigate through the different Assets views (Map, List and Gallery)
   * while the map is updating (viewport is recalculating due to a change in the zoom level or the position of the map)
   *
   * Reference: https://phillips-connect.atlassian.net/browse/PRJIND-9847
   */
  Connect1RolloutMapUpdatingSemaphore = "connect1RolloutMapUpdatingSemafor",
}
