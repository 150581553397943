import { get } from "lodash";
import { GeofenceAddressInput } from "../graphql/operations";

export type GeocodeResponse = google.maps.GeocoderResult;

export const getGeocodeFromAddress = async (
  address: string
): Promise<GeocodeResponse[]> => {
  const geocoder = new google.maps.Geocoder();

  const { results } = await geocoder.geocode({
    address: address,
    language: "en",
  });
  return results;
};

export const getGeocodeFromCoordinates = async (
  lat: number,
  lng: number
): Promise<GeocodeResponse[]> => {
  const geocoder = new google.maps.Geocoder();
  const { results } = await geocoder.geocode({
    location: { lat, lng },
    language: "en",
  });
  const filteredResults = results.filter(
    (result) => !result.types.includes("plus_code")
  );

  return filteredResults;
};

export const getGeocodeWithBounds = (data: GeocodeResponse[]) =>
  data.find(
    (item) =>
      get(item, "geometry.bounds", undefined) as
        | google.maps.GeocoderResult
        | undefined
  );

export const mapGeocodeResultToGeofenceAddressInput = (
  geocodeResult: GeocodeResponse
): GeofenceAddressInput => {
  // Extract relevant address components
  const addressComponents = geocodeResult.address_components.reduce(
    (acc, component) => {
      const types = component.types;
      if (types.includes("locality")) acc.city = component.long_name;
      if (types.includes("administrative_area_level_1"))
        acc.state = component.long_name;
      if (types.includes("postal_code")) acc.postcode = component.long_name;
      if (types.includes("country")) acc.country = component.long_name;

      return acc;
    },
    {
      city: "",
      state: "",
      postcode: "",
      country: "",
    }
  );

  return {
    ...addressComponents,
  };
};
