import { isNil } from "lodash";
import {
  LiteSentryGammaObservationData,
  LiteSentryGammaScenarioConfig,
  LiteSentryGammaSensorConfig,
  MergedLiteSentryGammaSensorConfig,
  SensorStatusUppercase,
} from "../../../../../../graphql/operations";

export enum LightCircuits {
  BrakeCircuit = "Brake Circuit",
  LeftTurnCircuit = "Left Turn Circuit",
  RightTurnCircuit = "Right Turn Circuit",
  MarkerCircuit = "Marker Circuit",
  LicenseCircuit = "License Circuit",
}

export const sortLiteSentryGammaSensorHealthObject = (
  liteSentryGammaObject: LiteSentryGammaObservationData[]
): LiteSentryGammaObservationData[] => {
  const liteSentryGammaScenarios = liteSentryGammaObject.sort(
    sensorHealthStatusSeveritySort
  );

  return liteSentryGammaScenarios;
};

// Creating a partial type by combining properties from LiteSentryGammaScenarioConfig, LiteSentryGammaObservationData and AssetSensorsHealthStatus
export type LightCircuitMergedData = Partial<
  {
    calculated_deviation_percentage?: number;
    scenario_source: LiteSentryGammaScenarioSource;
  } & LiteSentryGammaObservationData
>;

type LightCircuitsScenarioMappingType = {
  [key in LightCircuits]: number[];
};

export const SeverityStatusOrder: Record<SensorStatusUppercase, number> = {
  [SensorStatusUppercase.Critical]: 4,
  [SensorStatusUppercase.Alert]: 3,
  [SensorStatusUppercase.Warning]: 2,
  [SensorStatusUppercase.Healthy]: 1,
  [SensorStatusUppercase.Unknown]: 0,
};
export enum LiteSentryGammaScenarioSource {
  NoPowerPrecheck = "No power, Precheck",
  TractorPower = "Tractor power",
  TractorPowerWithBluePowerPresent = "Tractor power with Blue power present",
  TractorPowerWithoutBluePowerPresent = "Tractor power without Blue power present",
}
export enum LiteSentryGammaScenarioSourceMapping {
  // Brake (red line) - Scenario ID 0 - Tractor power with Blue power present
  scenario_0 = LiteSentryGammaScenarioSource.TractorPowerWithBluePowerPresent,
  //Brake (red line) - Scenario ID 5 - Tractor power without Blue power present
  scenario_5 = LiteSentryGammaScenarioSource.TractorPowerWithoutBluePowerPresent,
  // Brake (red line) - Scenario ID 6 - No power, Precheck
  scenario_6 = LiteSentryGammaScenarioSource.NoPowerPrecheck,

  // Right (green line) - Scenario ID 1 - Tractor power
  scenario_1 = LiteSentryGammaScenarioSource.TractorPower,
  // Right (green line) - Scenario ID 7 - No power, Precheck
  scenario_7 = LiteSentryGammaScenarioSource.NoPowerPrecheck,

  // Left (yellow line) - Scenario ID 3 - Tractor power
  scenario_3 = LiteSentryGammaScenarioSource.TractorPower,
  // Left (yellow line) - Scenario ID 9 - No power, Precheck
  scenario_9 = LiteSentryGammaScenarioSource.NoPowerPrecheck,

  // Marker (brown line) - Scenario ID 4 - Tractor power
  scenario_4 = LiteSentryGammaScenarioSource.TractorPower,
  // Marker (brown line) - Scenario ID 10/A - No power, Precheck
  scenario_10 = LiteSentryGammaScenarioSource.NoPowerPrecheck,

  // License (black line) - Scenario ID 2 - Tractor power
  scenario_2 = LiteSentryGammaScenarioSource.TractorPower,
  // License (black line) - Scenario ID 8 - No power, Precheck
  scenario_8 = LiteSentryGammaScenarioSource.NoPowerPrecheck,
}

export const LightsScenarioIndexMapping: {
  [index: number]: LiteSentryGammaScenarioSourceMapping;
} = {
  0: LiteSentryGammaScenarioSourceMapping.scenario_0,
  1: LiteSentryGammaScenarioSourceMapping.scenario_1,
  2: LiteSentryGammaScenarioSourceMapping.scenario_2,
  3: LiteSentryGammaScenarioSourceMapping.scenario_3,
  4: LiteSentryGammaScenarioSourceMapping.scenario_4,
  5: LiteSentryGammaScenarioSourceMapping.scenario_5,
  6: LiteSentryGammaScenarioSourceMapping.scenario_6,
  7: LiteSentryGammaScenarioSourceMapping.scenario_7,
  8: LiteSentryGammaScenarioSourceMapping.scenario_8,
  9: LiteSentryGammaScenarioSourceMapping.scenario_9,
  10: LiteSentryGammaScenarioSourceMapping.scenario_10,
};

export enum LightCircuitsScenarioIndexMapping {
  scenario_0,
  scenario_1,
  scenario_2,
  scenario_3,
  scenario_4,
  scenario_5,
  scenario_6,
  scenario_7,
  scenario_8,
  scenario_9,
  scenario_10,
}

export const getLightsSensorsHealthData = (
  lightsSensorData: LiteSentryGammaObservationData[]
) => {
  const lightsSensorsHealthData: {
    [key: string]: SensorStatusUppercase;
  } = {};

  lightsSensorData.forEach((sensorData) => {
    const mappedScenarioId =
      typeof sensorData?.scenario_id === "number"
        ? LightCircuitsScenarioIndexMapping[sensorData.scenario_id]
        : undefined;

    if (mappedScenarioId) {
      lightsSensorsHealthData[mappedScenarioId] =
        sensorData.statusDeviation ?? SensorStatusUppercase.Unknown;
    }
  });

  return lightsSensorsHealthData;
};

export const LightCircuitsScenarioMapping: LightCircuitsScenarioMappingType = {
  [LightCircuits.BrakeCircuit]: [0, 5, 6],
  [LightCircuits.RightTurnCircuit]: [1, 7],
  [LightCircuits.LeftTurnCircuit]: [3, 9],
  [LightCircuits.MarkerCircuit]: [4, 10],
  [LightCircuits.LicenseCircuit]: [2, 8],
};
export type LightCircuitMergedDataResult = {
  [key in LightCircuits]: LightCircuitMergedData;
};

export const calculateDeviationPercentage = (
  observedCurrentValue: number,
  setPoint: number
) => {
  return Math.round(((observedCurrentValue - setPoint) / setPoint) * 100);
};
export const getMergedLightsSensorAndProfileData = (
  lightsSensorData: LiteSentryGammaObservationData[],
  lightsSensorHealthData: { [key: string]: SensorStatusUppercase },
  lightsProfileData: MergedLiteSentryGammaSensorConfig,
  circuitName: LightCircuits
): LightCircuitMergedData[] => {
  const circuitMergedData: LightCircuitMergedData[] = lightsSensorData
    // eslint-disable-next-line array-callback-return
    .filter((observation: LiteSentryGammaObservationData) => {
      if (
        !isNil(observation?.scenario_id) &&
        LightCircuitsScenarioMapping[circuitName].includes(
          observation?.scenario_id
        )
      ) {
        return observation;
      }
    })
    // eslint-disable-next-line array-callback-return
    .map((observation: LiteSentryGammaObservationData) => {
      if (!isNil(observation?.scenario_id)) {
        const mappedScenario =
          LightCircuitsScenarioIndexMapping[observation.scenario_id];

        // get lights configured profile data
        const currentLightsProfileData: LiteSentryGammaScenarioConfig =
          (lightsProfileData[
            mappedScenario as keyof LiteSentryGammaSensorConfig
          ] as LiteSentryGammaScenarioConfig) ?? {};
        let calculated_deviation_percentage = null;

        const observedCurrent = observation?.observed_current as number;
        const setPoint = currentLightsProfileData.setPoint;
        // get scenario source
        const scenario_source: LiteSentryGammaScenarioSource =
          LightsScenarioIndexMapping[
            observation.scenario_id
          ] as unknown as LiteSentryGammaScenarioSource;

        if (
          isFinite(observedCurrent) &&
          isFinite(currentLightsProfileData.setPoint)
        ) {
          calculated_deviation_percentage = calculateDeviationPercentage(
            observedCurrent,
            setPoint
          );
        }
        return {
          ...observation,
          calculated_deviation_percentage,
          scenario_source,
        };
      }
    }) as LightCircuitMergedData[];

  return circuitMergedData.sort(sensorHealthStatusSeveritySort);
};

export const sensorHealthStatusSeveritySort = (
  a: LiteSentryGammaObservationData,
  b: LiteSentryGammaObservationData
) => {
  const statusSeverityA =
    SeverityStatusOrder[a?.statusDeviation ?? SensorStatusUppercase.Unknown];
  const statusSeverityB =
    SeverityStatusOrder[b?.statusDeviation ?? SensorStatusUppercase.Unknown];

  // Compare the severity levels
  if (statusSeverityA >= statusSeverityB) {
    return -1;
  } else if (statusSeverityA < statusSeverityB) {
    return 1;
  } else {
    return 0;
  }
};
export const getCircuitsData = (
  lightsSensorData: LiteSentryGammaObservationData[],
  lightsSensorHealthData: { [key: string]: SensorStatusUppercase },
  lightsProfileData: MergedLiteSentryGammaSensorConfig
): LightCircuitMergedDataResult => {
  const brakeCircuitData: LightCircuitMergedData[] =
    getMergedLightsSensorAndProfileData(
      lightsSensorData,
      lightsSensorHealthData,
      lightsProfileData,
      LightCircuits.BrakeCircuit
    );

  const leftTurnCircuitData: LightCircuitMergedData[] =
    getMergedLightsSensorAndProfileData(
      lightsSensorData,
      lightsSensorHealthData,
      lightsProfileData,
      LightCircuits.LeftTurnCircuit
    );

  const rightTurnCircuitData: LightCircuitMergedData[] =
    getMergedLightsSensorAndProfileData(
      lightsSensorData,
      lightsSensorHealthData,
      lightsProfileData,
      LightCircuits.RightTurnCircuit
    );

  const licenseCircuitData: LightCircuitMergedData[] =
    getMergedLightsSensorAndProfileData(
      lightsSensorData,
      lightsSensorHealthData,
      lightsProfileData,
      LightCircuits.LicenseCircuit
    );

  const markerCircuitData: LightCircuitMergedData[] =
    getMergedLightsSensorAndProfileData(
      lightsSensorData,
      lightsSensorHealthData,
      lightsProfileData,
      LightCircuits.MarkerCircuit
    );

  return {
    [LightCircuits.BrakeCircuit]: brakeCircuitData?.[0] ?? null,
    [LightCircuits.LeftTurnCircuit]: leftTurnCircuitData?.[0] ?? null,
    [LightCircuits.RightTurnCircuit]: rightTurnCircuitData?.[0] ?? null,
    [LightCircuits.LicenseCircuit]: licenseCircuitData?.[0] ?? null,
    [LightCircuits.MarkerCircuit]: markerCircuitData?.[0] ?? null,
  };
};
