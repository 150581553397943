import { FC } from "react";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";
import { SensorFilterSliderColors, SensorFiltersNames } from "../../types";
import { DEFAULT_SENSOR_VALUES } from "../../utils";
import BatteryVoltageSlider from "./BatteryVoltageSlider";

const BackupBatteryVoltageSlider: FC = () => {
  const {
    onChangeFilters,
    currentFilter: { backupBatteryVoltage },
  } = useAssetsDataContext();

  const onChangeBackupBatteryVoltage = (newValues: number[]) => {
    const [minVoltage, maxVoltage] = newValues;

    onChangeFilters({
      backupBatteryVoltage: { minVoltage, maxVoltage },
    });
  };

  return (
    <BatteryVoltageSlider
      onChange={onChangeBackupBatteryVoltage}
      currentSensor={{
        minValue:
          backupBatteryVoltage?.minVoltage ??
          DEFAULT_SENSOR_VALUES[SensorFiltersNames.BackupBatteryVoltage]!.min,
        maxValue:
          backupBatteryVoltage?.maxVoltage ??
          DEFAULT_SENSOR_VALUES[SensorFiltersNames.BackupBatteryVoltage]!.max,
      }}
      maxValue={4.2}
      marks={[
        {
          value: 0,
          label: `0V`,
        },
        {
          value: 4.2,
          label: `4.2V`,
        },
      ]}
      valueBarColor={SensorFilterSliderColors.BackupBatteryVoltage}
    />
  );
};

export default BackupBatteryVoltageSlider;
