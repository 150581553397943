import * as yup from "yup";
import { ATIS_ALPHA_FORM_FIELDS } from "./constants";

export const sensorsAtisAlphaSchema = yup.object().shape({
  typeOfAssignment: yup.string().nullable().required("Field is required!"),
  atisAlphaSensorProfile: yup
    .string()
    .nullable()
    .when("typeOfAssignment", {
      is: (value: string) => {
        return value === ATIS_ALPHA_FORM_FIELDS[0].options[1].id;
      },
      then: yup.string().required("Field is required!").nullable(),
      otherwise: yup.string().nullable().notRequired(),
    }),
});
