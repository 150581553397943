import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/system";

const GraphTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -30],
            },
          },
        ],
      },
    }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--background)",
    color: "var(--typography)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.16)",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 500,
    padding: "12px 6px",
    borderRadius: "2px",
    opacity: 1,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    color: "var(--background)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.16)",
  },
}));

export default GraphTooltip;
