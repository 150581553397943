import type { Fields, Config } from "@react-awesome-query-builder/core";
import { MuiConfig } from "@react-awesome-query-builder/mui";
import { useQueryBuilderTheme } from "./useQueryBuilderTheme";

export const useQueryBuilderConfig = (fields: Fields): Config => {
  const config = {
    ...MuiConfig,
    fields,
  };

  config.operators.equal.label = "equals";
  config.operators.not_equal.label = "is not equal";

  // @ts-ignore - delete Not Contains for now
  delete config.operators.not_like;

  const theme = useQueryBuilderTheme();
  // use mui theme
  config.settings.theme = { mui: theme };

  // not show Not label for now
  config.settings.showNot = false;

  return config;
};
