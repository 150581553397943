/**
 * Design System Colors Palette
 * @see https://www.figma.com/file/jNpmRBcsbP2BCbgkzj0Y8H/PC-Design-System?type=design&node-id=3029-20712&mode=design&t=NX96KvxKlJPkB6a1-0
 */
export enum ColorsPalette {
  // Grayscale Colors Palette
  RichBlack = "#000000",
  RichBlackOpacity10 = "#0000001A",
  RichBlackOpacity16 = "#00000029",
  RichBlackOpacity30 = "#0000004D",
  RichBlackOpacity60 = "#00000099",
  FlatBlack = "#121212",
  FlatBlackOpacity60 = "#12121299",
  FlatBlackOpacity80 = "#121212CC",
  DarkCharcoal = "#1E1E1E",
  MidCharcoal = "#464646",
  LightCharcoal = "#808080",
  Concrete = "#DFDFDF",
  ConcreteOpacity40 = "#DFDFDF40",
  ConcreteOpacity70 = "#DFDFDFB3",
  FeatherGray = "#F2F2F2",
  LightGray = "#656667",
  SilverGray = "#C4C4C4",
  SolidGray = "#9C9C9C",
  OffWhite = "#FCFCFC",
  BrightWhite = "#FFFFFF",
  BrightWhiteOpacity10 = "#FFFFFF1A",
  BrightWhiteOpacity30 = "#FFFFFF4D",
  BrightWhiteOpacity60 = "#FFFFFF99",
  // Brand Accents Colors Palette
  RoyalBlue = "#1E3768",
  DarkBlue = "#468BB0",
  PrimaryBlue = "#3C88CC",
  PrimaryBlueOpacity90 = "#3C88CC99",
  PrimaryBlueOpacity80 = "#3C88CCCC",
  PrimaryBlueOpacity70 = "#3C88CCB3",
  PrimaryBlueOpacity60 = "#3C88CC99",
  PrimaryBlueOpacity50 = "#3C88CC80",
  PrimaryBlueOpacity40 = "#3C88CC66",
  PrimaryBlueOpacity30 = "#3C88CC4D",
  PrimaryBlueOpacity20 = "#3C88CC33",
  PrimaryBlueOpacity10 = "#3C88CC1A",
  LightBlue = "#5D9BE4",
  ExtraLightBlue = "#B8D1EE",
  AccentAqua = "#61B396",
  AccentGreen = "#6AC87E",
  // Validation Colors Palette
  Success = "#339933",
  SuccessOpacity10 = "#3399331A",
  SuccessOpacity20 = "#33993333",
  SuccessOpacity80 = "#339933CC",
  Caution = "#E5BE33",
  CautionOpacity10 = "#E5BE331A",
  CautionOpacity20 = "#E5BE3333",
  CautionOpacity80 = "#E5BE33CC",
  Alert = "#E78D3A",
  AlertOpacity10 = "#E78D3A1A",
  AlertOpacity20 = "#E78D3A33",
  AlertOpacity80 = "#E78D3ACC",
  Error = "#CA1212",
  ErrorOpacity10 = "#CA12121A",
  ErrorOpacity20 = "#CA121233",
  ErrorOpacity80 = "#CA1212CC",

  NoImageBG = "#D1DAE5",
  InactiveTireAxle = "#D7E3F1",

  AmberOrange = "#F18D00",
  DodgerBlue = "#4A80F5",
  BabyBlueEyes = "#9BBFF4",
  PowderBlue = "#A7CDF2",
  PaleGreen = "#BBDAA4",

  ReChartsGreen = "#54B285",
  ReChartsPurple = "#B564A4",
  ReChartsCoral = "#CA5E71",
  ReChartsMarineBlue = "#556BB9",
  ReChartsTeal = "#71B0BE",
  ReChartsViolet = "#695C9D",
  ReChartsGold = "#CDC155",
  ReChartsRed = "#CB5252",
  ReChartsOrange = "#D9745E",
  ReChartsBlue = "#165BAA",
}

export enum DefaultBrandColorPalette {
  Primary = "#3C88CC",
  Secondary = "#468BB0",
}
