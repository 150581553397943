import { ReactElement, useMemo } from "react";
import { find, isNil } from "lodash";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../views/AssetsView/TableView/hooks/useNomenclatures";

interface DoorTypeLabelProps {
  value?: string;
}

export const DoorTypeLabel = ({ value }: DoorTypeLabelProps): ReactElement => {
  const options = useNomenclatures(NOMENCLATURE_NAMES.assetDoorType);

  const found = useMemo(() => find(options, { id: value }), [options, value]);

  const text = found ? found.label : value;

  return isNil(text) ? <>&nbsp;</> : <>{text}</>;
};
