import React, { memo } from "react";
import { Box, ThemeProvider } from "@mui/material";
import { useAssetManagementTheme } from "../../../TableView/hooks/useAssetManagementTheme";
import { EventHistoryTable } from "./EventHistoryTable";

export interface EventHistoryProps {
  imei?: string;
  customerOrgId?: string;
  selectedAssetId: string;
}

const EventHistory: React.FC<EventHistoryProps> = ({
  imei,
  customerOrgId,
  selectedAssetId,
}) => {
  const assetManagementTheme = useAssetManagementTheme();

  return (
    <ThemeProvider theme={assetManagementTheme}>
      <Box className="h-full w-full bg-background">
        <Box className="h-full pt-2">
          <EventHistoryTable
            imei={imei}
            customerOrgId={customerOrgId}
            selectedAssetId={selectedAssetId}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default memo(EventHistory);
