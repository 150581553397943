import { useEffect, useRef, useState } from "react";
import RangeSlider from "../../../../../../../../shared/components/RangeSlider/RangeSlider";
import { formatNumber } from "../../../../../../../../utils/formatters";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";

const minWeightStatusValues = 0;
const maxWeightStatusValues = 50_000;

const WeightStatusSlider: React.FC = () => {
  const {
    currentFilter: { weightStatus },
    onChangeFilters,
  } = useAssetsDataContext();

  const defaultValues = useRef<number[]>([
    weightStatus?.startValue ?? minWeightStatusValues,
    weightStatus?.endValue ?? maxWeightStatusValues,
  ]);

  const [values, setValues] = useState<number[]>(defaultValues.current);

  useEffect(() => {
    const incomingValue = [
      weightStatus?.startValue ?? minWeightStatusValues,
      weightStatus?.endValue ?? maxWeightStatusValues,
    ];
    if (JSON.stringify(incomingValue) !== JSON.stringify(values)) {
      setValues(incomingValue);
    }
    // We only want to trigger this effect when the value in store is different than our local
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [weightStatus]);

  const onValueChanged = (values: number | number[]) => {
    if (Array.isArray(values)) {
      // call onChange event to update filter values in asset context
      const [startValue, endValue] = values;
      onChangeFilters({ weightStatus: { startValue, endValue } });
    }
  };

  const marks = [
    {
      value: minWeightStatusValues,
      label: `${formatNumber(minWeightStatusValues)} kg`,
    },
    {
      value: maxWeightStatusValues,
      label: `${formatNumber(maxWeightStatusValues)} kg`,
    },
  ];

  return (
    <RangeSlider
      min={minWeightStatusValues}
      max={maxWeightStatusValues}
      values={values}
      setValues={setValues}
      defaultValues={defaultValues.current}
      marks={marks}
      marksMargin={{ left: "0.5rem", right: "-1.5rem" }}
      onChange={onValueChanged}
    />
  );
};

export default WeightStatusSlider;
