import {
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  ThemeProvider,
} from "@mui/material";
import { UseMutateFunction } from "@tanstack/react-query";
import { isEmpty, cloneDeep } from "lodash";
import * as yup from "yup";
import { DEVICE_FORM_FIELDS } from "../../../constants/device";
import { useAuthContext } from "../../../context/AuthContext";
import { FormModes } from "../../../enums/formModes";
import {
  DeviceDataInput,
  FindOrgsQuery,
  Asset,
  DeleteDeviceMutation,
  DeleteDeviceInput,
  Exact,
  useGetUnassociatedAssetsByOrgIdOsQuery,
} from "../../../graphql/operations";
import { ConfirmationDialog } from "../../../shared/components/ConfirmationDialog/ConfirmationDialog";
import Drawer from "../../../shared/components/Drawer";
import DrawerActions from "../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../shared/components/Drawer/DrawerHeader";
import { useFormTheme } from "../../../shared/hooks/theme/useFormTheme";
import { CreatableAutocomplete } from "../../AssetsView/TableView/components/AssetForm/components";
import DeviceHistoryTable from "../DeviceHistoryTable/DeviceHistoryTable";

export interface InstallDeviceFormProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  deviceToEdit?: DeviceDataInput;
  formMode: FormModes;
  handleFormSubmit: (data: any, dirtyFields: any) => any;
  handleDeleteDevice: UseMutateFunction<
    DeleteDeviceMutation,
    any,
    Exact<{ input: DeleteDeviceInput }>
  >;
  orgData?: FindOrgsQuery;
  setIsDataLoading: (value: boolean) => void;
}

const InstallDeviceForm: React.FC<InstallDeviceFormProps> = ({
  open,
  setOpen,
  deviceToEdit,
  formMode,
  handleFormSubmit,
  handleDeleteDevice,
  orgData,
  setIsDataLoading,
}) => {
  const { userRolePermissions } = useAuthContext();
  const fieldLength = { xs: 12 }; // Always 12 columns, full width
  const formTheme = useFormTheme();

  const isEdit = formMode === FormModes.edit;

  const hasCreateEditAccess =
    userRolePermissions.device.create || userRolePermissions.device.edit;
  const hasHistoryViewAccess = userRolePermissions.device.viewHistory;

  const hasDeleteAccess = userRolePermissions.device.remove;
  const canUserDeleteDevice = hasDeleteAccess && !deviceToEdit?.assets_id;

  // Remove device dialog properties and helper functions
  const [showRemoveDeviceDialog, setShowRemoveDeviceDialog] = useState(false);

  const tagsList = deviceToEdit?.tags?.filter((tag: string | null) => !!tag);

  const deviceSchema = yup.object().shape({
    customer_orgs_id: yup.string().required("required").nullable(),
    imei: yup
      .string()
      .required("required")
      .matches(/^[0-9]*$/, { message: "Invalid" })
      .min(14, "should be min 14 and max 16 characters length")
      .max(16, "should be min 14 and max 16 characters length"),
    box_id: yup.string().notRequired(),
    order_num: yup.string().required("Required"),
    sim_num: yup.string().notRequired(),
    packing_list: yup.string().notRequired(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
  } = useForm({
    resolver: yupResolver(deviceSchema),
    defaultValues: {
      assets_id: deviceToEdit?.assets_id ?? "",
      box_id: deviceToEdit?.box_id ?? "",
      customer_orgs_id: deviceToEdit?.customer_orgs_id ?? "",
      imei: deviceToEdit?.imei ?? "",
      order_num: deviceToEdit?.order_num ?? "",
      packing_list: deviceToEdit?.packing_list ?? "",
      sim_num: deviceToEdit?.sim_num ?? "",
      tags: tagsList ?? undefined,
      _id: deviceToEdit?._id ?? "",
    },
  });

  const isSubmitDisabled = isEmpty(dirtyFields);

  const selected_org_id = watch("customer_orgs_id");

  const { data: unassociatedAssetsData, isLoading: assetsLoading } =
    useGetUnassociatedAssetsByOrgIdOsQuery(
      { input: { orgId: selected_org_id } },
      { cacheTime: 0, enabled: Boolean(selected_org_id) }
    );

  const unassociatedAssets = useMemo(
    () => unassociatedAssetsData?.getUnassociatedAssetsByOrgIdOS ?? [],
    [unassociatedAssetsData]
  );

  const loadUnassociatedAssets = useCallback(
    (editDeviceData: DeviceDataInput, assets: Asset[]) => {
      const additionalAsset =
        editDeviceData.assets_id && editDeviceData.asset_name
          ? [
              {
                _id: editDeviceData.assets_id,
                asset_id: editDeviceData.assets_id,
              },
            ]
          : [];

      const combinedAssets = [...assets, ...additionalAsset].map((asset) => {
        return {
          id: asset._id,
          label: asset.asset_id,
          asset_id: asset.asset_id,
        };
      });

      return combinedAssets;
    },
    []
  );

  //organizations
  const orgOptions = useMemo(
    () =>
      (orgData?.findOrgs || [])
        .map((org) => {
          return { value: org._id, id: org._id, label: org.name ?? "" };
        })
        .sort((org1, org2) => (org1.label < org2.label ? -1 : 1)),
    [orgData?.findOrgs]
  );

  //assets
  const assetOptions = useMemo(
    () =>
      loadUnassociatedAssets(
        cloneDeep(deviceToEdit) ?? ({} as DeviceDataInput),
        unassociatedAssets ?? []
      ),
    [deviceToEdit, loadUnassociatedAssets, unassociatedAssets]
  );

  //tags
  const getOrganizationTags = (
    orgs: FindOrgsQuery | undefined,
    selected_org_id: string
  ) => {
    let tags: { label: string | null; id: string | null }[] = [];
    if (selected_org_id) {
      const org = (orgs?.findOrgs || []).find((x) => x._id === selected_org_id);
      tags = (org?.device_tags || []).map((tag) => {
        return { label: tag, id: tag };
      });
    }
    return tags;
  };
  const tagOptions: { label: string | null; id: string | null }[] = useMemo<
    any | null
  >(() => {
    return getOrganizationTags(orgData, selected_org_id);
  }, [orgData, selected_org_id]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleConfirmationResult = (isConfirmed: boolean) => {
    if (isConfirmed) {
      setShowRemoveDeviceDialog(false);
      setIsDataLoading(true);
      handleDeleteDevice({
        input: { _id: deviceToEdit?._id ?? "", imei: deviceToEdit?.imei ?? "" },
      });

      onClose();
    } else {
      setShowRemoveDeviceDialog(false);
    }
  };

  const headerText = hasCreateEditAccess
    ? `${isEdit ? "Edit" : "Create"} Device`
    : "Device Management";
  const subHeader = hasCreateEditAccess ? "" : String(deviceToEdit?.org_name);
  const enrichFormData: any = (
    data: any,
    allOrgs: Partial<FindOrgsQuery>,
    allAssets: Asset[]
  ) => {
    const currentOrg = (allOrgs as any[]).find(
      (org: any) => org.id === data.customer_orgs_id
    );
    const currentAsset = allAssets.find(
      (asset: any) => asset.id === data.assets_id
    );
    data.org_name = currentOrg.label ?? "";
    data.asset_name = currentAsset?.asset_id ?? "";
    return data;
  };

  const {
    customerOrgIdInput,
    imeiInput,
    assetIdInput,
    boxIdInput,
    tagsInput,
    orderNumInput,
    simNumInput,
    packingListInput,
  } = DEVICE_FORM_FIELDS;

  return (
    <ThemeProvider theme={formTheme}>
      <Drawer testId="device-drawer" isOpen={open} onRequestClose={onClose}>
        <DrawerHeader text={headerText} sub={subHeader} onClose={onClose} />

        {showRemoveDeviceDialog && (
          <ConfirmationDialog
            title="You are about to delete a device"
            message="Are you sure?"
            open={showRemoveDeviceDialog}
            confirmButtonText="Remove Device"
            cancelButtonText="Cancel"
            handleConfirmationResult={handleConfirmationResult}
          />
        )}

        <DrawerContent>
          <form data-testid="form-org-create-edit">
            <Grid container className="drawerSection" spacing={6}>
              <Grid item {...fieldLength} data-testid={customerOrgIdInput.id}>
                {/* Organization */}
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: Boolean(!hasCreateEditAccess),
                    readOnly: Boolean(!hasCreateEditAccess),
                  }}
                  matchId={true}
                  control={control}
                  loading={!orgOptions?.length}
                  rules={{ required: customerOrgIdInput.required }}
                  label={customerOrgIdInput.label}
                  name={customerOrgIdInput.name as never}
                  options={orgOptions}
                />
              </Grid>

              {/* imei */}
              <Grid item {...fieldLength} data-testid={imeiInput.testId}>
                <TextFieldElement
                  fullWidth
                  disabled={Boolean(!hasCreateEditAccess)}
                  control={control}
                  name={imeiInput.name as never}
                  required
                  label={imeiInput.label}
                />
              </Grid>

              {/* Asset Id */}
              <Grid item {...fieldLength} data-testid={assetIdInput.id}>
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: Boolean(!hasCreateEditAccess),
                    readOnly: Boolean(!hasCreateEditAccess),
                  }}
                  matchId={true}
                  loading={Boolean(assetsLoading && selected_org_id)}
                  label={assetIdInput.label}
                  control={control}
                  name={assetIdInput.name as never}
                  options={assetOptions}
                />
              </Grid>

              {/* Order Group */}
              <Grid item {...fieldLength}>
                <TextFieldElement
                  fullWidth
                  disabled={Boolean(!hasCreateEditAccess)}
                  control={control}
                  name={boxIdInput.name as never}
                  label={boxIdInput.label}
                />
              </Grid>

              {/* Tags */}
              <Grid item {...fieldLength}>
                <Controller
                  name={tagsInput.name as never}
                  control={control}
                  defaultValue={undefined}
                  render={({ field }) => {
                    return (
                      <CreatableAutocomplete
                        disabled={Boolean(!hasCreateEditAccess)}
                        name={tagsInput.name}
                        label={tagsInput.label}
                        field={field}
                        errors={errors}
                        options={tagOptions as any}
                      />
                    );
                  }}
                />
              </Grid>

              {/* Order # */}
              <Grid item {...fieldLength}>
                <TextFieldElement
                  fullWidth
                  disabled={Boolean(!hasCreateEditAccess)}
                  control={control}
                  required
                  name={orderNumInput.name as never}
                  label={orderNumInput.label}
                />
              </Grid>

              {/* SIM ID */}
              <Grid item {...fieldLength}>
                <TextFieldElement
                  fullWidth
                  disabled={Boolean(!hasCreateEditAccess)}
                  control={control}
                  name={simNumInput.name as never}
                  label={simNumInput.label}
                />
              </Grid>

              {/* Packing List */}
              <Grid item {...fieldLength}>
                <TextFieldElement
                  fullWidth
                  control={control}
                  disabled={Boolean(!hasCreateEditAccess)}
                  name={packingListInput.name as never}
                  label={packingListInput.label}
                />
              </Grid>
            </Grid>

            {hasHistoryViewAccess && isEdit && (
              <Accordion className="historyAccordion">
                <AccordionSummary>History</AccordionSummary>
                <AccordionDetails>
                  <DeviceHistoryTable
                    device_id={deviceToEdit?._id}
                    useExtendedSearch={false}
                    searchThreshold={0.3}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </form>

          <DrawerActions
            deleteBtnTestId="btn-device-form-remove"
            showDeleteBtn={isEdit && canUserDeleteDevice}
            onCancel={() => setOpen(false)}
            onDelete={() => setShowRemoveDeviceDialog(true)}
          />
        </DrawerContent>

        {hasCreateEditAccess && (
          <DrawerFooter
            text="Save"
            disabled={isSubmitDisabled}
            testId="btn-device-form-submit"
            submit={handleSubmit((data) => {
              data = enrichFormData(data, orgOptions, assetOptions);
              handleFormSubmit(data, dirtyFields);
            })}
          />
        )}
      </Drawer>
    </ThemeProvider>
  );
};

export default InstallDeviceForm;
