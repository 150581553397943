import type { GeofenceCreateInput } from "../graphql/operations";

export enum GeofenceSettingsType {
  Details = "details",
  Operations = "operations",
  Capacity = "capacity",
  Restrictions = "restrictions",
}

export enum GeofenceSettingsTitle {
  Details = "Geofence Details",
  Operations = "Hours Of Operation",
  Capacity = "Min/Max",
  Restrictions = "Geofence Restrictions",
}

export const GEOFENCE_SETTINGS_TAB_TYPES = [
  {
    label: GeofenceSettingsTitle.Details,
    value: GeofenceSettingsType.Details,
  },
  {
    label: GeofenceSettingsTitle.Operations,
    value: GeofenceSettingsType.Operations,
  },
  {
    label: GeofenceSettingsTitle.Capacity,
    value: GeofenceSettingsType.Capacity,
  },
  {
    label: GeofenceSettingsTitle.Restrictions,
    value: GeofenceSettingsType.Restrictions,
  },
];

export const UPDATE_GEOFENCE_INPUT_KEYS: Array<keyof GeofenceCreateInput> = [
  "name",
  "fullAddressFormatted",
  "description",
  "code",
  "tags",
];

type GeofenceAssetTypes = {
  boat: "boat";
  container: "container";
  chassis: "chassis";
  dolly: "dolly";
  tractor: "tractor";
  trailer: "trailer";
  reefer: "reefer";
  vehicle: "vehicle";
  other: "other";
};

export const GEOFENCE_ASSET_TYPES: GeofenceAssetTypes = {
  boat: "boat",
  container: "container",
  chassis: "chassis",
  dolly: "dolly",
  tractor: "tractor",
  trailer: "trailer",
  reefer: "reefer",
  vehicle: "vehicle",
  other: "other",
};

type DaysOfWeekTypes = {
  monday: "monday";
  tuesday: "tuesday";
  wednesday: "wednesday";
  thursday: "thursday";
  friday: "friday";
  saturday: "saturday";
  sunday: "sunday";
};

export const GEOFENCE_OPERATIONS_DAYS: DaysOfWeekTypes = {
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday",
  sunday: "sunday",
};

export type DayOfWeek =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export const DAYS_OF_WEEK_ARRAY: DayOfWeek[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const defaultTimeProps = {
  endAt: "",
  endAtFormat: "",
  startAt: "",
  startAtFormat: "",
};

export const EMPTY_OPERATIONS = {
  monday: {
    operatingHours: {
      ...defaultTimeProps,
    },
    lunch: {
      ...defaultTimeProps,
    },
  },
  tuesday: {
    operatingHours: {
      ...defaultTimeProps,
    },
    lunch: {
      ...defaultTimeProps,
    },
  },
  wednesday: {
    operatingHours: {
      ...defaultTimeProps,
    },
    lunch: {
      ...defaultTimeProps,
    },
  },
  thursday: {
    operatingHours: {
      ...defaultTimeProps,
    },
    lunch: {
      ...defaultTimeProps,
    },
  },
  friday: {
    operatingHours: {
      ...defaultTimeProps,
    },
    lunch: {
      ...defaultTimeProps,
    },
  },
  saturday: {
    operatingHours: {
      ...defaultTimeProps,
    },
    lunch: {
      ...defaultTimeProps,
    },
  },
  sunday: {
    operatingHours: {
      ...defaultTimeProps,
    },
    lunch: {
      ...defaultTimeProps,
    },
  },
};
