import { FC, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useAppContext } from "../../../../context/AppContext";
import {
  FindAssetsByIdsForSharingOsQuery,
  FindAssetsByIdsQuery,
  useFindAssetsByIdsForSharingOsQuery,
  useFindAssetsByIdsQuery,
} from "../../../../graphql/operations";
import { useFindAssetListOptions } from "../../../../shared/hooks/useFindAssetListOptions";
import { AutocompleteOption } from "../../../../types";
import { useFeatureFlag } from "../../../../utils";
import { FeatureFlags } from "../../../../utils/featureFlagsConstants";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  isFindAssetByIdQueryEnabled,
  onFindAssetsOsSettledCallback,
  onFindAssetsSettledCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface ATISNotFunctioningProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
type SelectedAsset =
  | { id: string | undefined; label: string | null | undefined }[]
  | null;

export const ATISNotFunctioningParameters: FC<ATISNotFunctioningProps> = ({
  form,
}: ATISNotFunctioningProps) => {
  const { state } = useAppContext();
  const [selectedAsset, setSelectedAsset] = useState<SelectedAsset>(null);
  const [assetsOptionsList, setAssetsOptionsList] = useState<
    AutocompleteOption[]
  >([]);
  const assetTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const [assetNameSearch, setAssetNameSearch] = useState(
    form.getValues().name ?? ""
  );

  const watchAssets = useWatch({
    name: "parameters.assetIds",
    control: form.control,
  });
  const watchAssetType = useWatch({
    name: "parameters.assetType",
    control: form.control,
  });
  let isEnabled = isFindAssetByIdQueryEnabled(watchAssets);
  const getAssetsFromOS = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const useOsQuery = useFindAssetsByIdsForSharingOsQuery(
    { assetIds: getAlertMultiselectIds(watchAssets) ?? [] },
    {
      enabled: isEnabled && getAssetsFromOS,
    }
  );

  const useDefaultQuery = useFindAssetsByIdsQuery(
    { assetIds: getAlertMultiselectIds(watchAssets) ?? [] },
    {
      enabled: isEnabled && !getAssetsFromOS,
    }
  );

  const foundAsset = getAssetsFromOS ? useOsQuery.data : useDefaultQuery.data;
  const findAssetLoading = getAssetsFromOS
    ? useOsQuery.isLoading
    : useDefaultQuery.isLoading;
  const findAssetSuccess = getAssetsFromOS
    ? useOsQuery.isSuccess
    : useDefaultQuery.isSuccess;

  useEffect(() => {
    if (foundAsset && !findAssetLoading && findAssetSuccess) {
      getAssetsFromOS
        ? onFindAssetsOsSettledCallback({
            data: foundAsset as FindAssetsByIdsForSharingOsQuery,
            form,
            setSelectedAsset,
          })
        : onFindAssetsSettledCallback({
            data: foundAsset as FindAssetsByIdsQuery,
            form,
            setSelectedAsset,
          });
    }
  }, [
    foundAsset,
    findAssetLoading,
    findAssetSuccess,
    form,
    isEnabled,
    getAssetsFromOS,
  ]);

  useEffect(() => {
    if (selectedAsset) {
      form.setValue("parameters.assetIds", selectedAsset ?? []);
    }
  }, [form, selectedAsset]);

  const { options, isFetching } = useFindAssetListOptions(
    assetNameSearch,
    state.appConfig.searchOptionsLimit
  );

  useEffect(() => {
    setAssetsOptionsList(options);
  }, [options]);

  const hideInputValue =
    !selectedAsset &&
    !!watchAssets?.length &&
    findAssetLoading &&
    !findAssetSuccess;

  return (
    <AlertParametersContainer>
      <AssetsParameters
        setAssetNameSearch={setAssetNameSearch}
        form={form}
        setSelectedAsset={setSelectedAsset}
        setAssetsOptionsList={setAssetsOptionsList}
        watchAssetTypeValue={watchAssetType}
        hideInputValue={hideInputValue}
        isFetching={isFetching}
        assetsOptionsList={assetsOptionsList}
        watchAssetsValue={watchAssets}
        assetTypeOptions={assetTypeOptions}
        assetsSelectTestId="atis-not-functioning-assets-select"
        assetTypeSelectTestId="atis-not-functioning-asset-type-select"
      />
    </AlertParametersContainer>
  );
};
