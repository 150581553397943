import { FC, useState } from "react";
import {
  GEOFENCES_UPLOAD_FILE_TEMPLATE_PATH,
  PAGE_SNACKBAR,
} from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import { BatchTemplate } from "../../../../../graphql/operations";
import { parseContentAsJsonBlobFile } from "../../../../../shared/components/TableActionsMenu/helpers";
import UploadFileDialog from "../../../../../shared/components/UploadFileDialog/UploadFileDialog";
import UploadResult from "../../../../../shared/components/UploadFileDialog/UploadResult";
import {
  BatchUploadDialogProps,
  useBatchUploadForm,
} from "../../../../BatchesView/BatchManagementUtils";
import { useUploadJsonBatchFileToS3 } from "../../../../BatchesView/hooks/useUploadJsonBatchFileToS3";
import {
  mapFileDataToCreateGeofenceInput,
  parseGeofencesFile,
  ValidatedGeofence,
} from "./helpers";
import { ParseFileContentError } from "./types";

export const UploadGeofencesDialog: FC<BatchUploadDialogProps> = ({
  title,
  customerOrg,
  dialogFields,
  isOpen,
  onClose,
  onUpload,
}) => {
  const { dispatch } = useAppContext();

  const { uploadBatchFileToS3 } = useUploadJsonBatchFileToS3();
  const { form, getValues } = useBatchUploadForm(title, customerOrg);

  const [uploadedMessage, setUploadedMessage] = useState<string | undefined>();
  const [areGeofencesUploading, setAreGeofencesUploading] = useState(false);
  const [areGeofencesUploaded, setAreGeofencesUploaded] = useState(false);
  const [parseFileResult, setParseFileResult] = useState<{
    validatedGeofences: ValidatedGeofence[];
    validationErrors: ParseFileContentError[];
  }>({ validatedGeofences: [], validationErrors: [] });

  const resetState = () => {
    setAreGeofencesUploading(false);
    setAreGeofencesUploaded(false);
    setParseFileResult({ validatedGeofences: [], validationErrors: [] });
  };

  const handleSubmit = async (file: File) => {
    setAreGeofencesUploading(true);

    const {
      org_select: { id: orgId },
      batch_name,
    } = getValues();

    try {
      const { fileData, errors: parseErrors } = await parseGeofencesFile(file);

      const { validatedGeofences, validationErrors } =
        mapFileDataToCreateGeofenceInput(fileData, orgId);

      const combinedErrors = [...validationErrors, ...parseErrors];

      setParseFileResult({
        validatedGeofences,
        validationErrors: combinedErrors,
      });
      if (combinedErrors.length > 0) {
        throw new Error("Validation errors");
      }

      const fileName = file.name.replace(/\.(csv|xls|xlsx)$/i, ".json");
      setUploadedMessage(`${fileName} ${batch_name} Uploaded`);

      const geofences = validatedGeofences.map(({ geofence }) => geofence);

      const jsonFile = parseContentAsJsonBlobFile(geofences, fileName);

      await uploadBatchFileToS3(
        jsonFile,
        BatchTemplate.CreateGeofences,
        batch_name,
        orgId
      );
    } catch (error) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Geofences Uploading Failed",
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
    }

    setAreGeofencesUploading(false);
    setAreGeofencesUploaded(true);

    if (onUpload) {
      onUpload();
    }
  };

  const onFilesUploadDialogClose = () => {
    onClose();
    resetState();
  };

  return (
    <UploadFileDialog
      title={title}
      form={form}
      allowedFormats={["csv", "xls", "xlsx"]}
      dialogFields={dialogFields}
      isOpen={isOpen}
      onClose={onFilesUploadDialogClose}
      handleSubmit={handleSubmit}
      isDataUploading={areGeofencesUploading}
      isDataUploaded={areGeofencesUploaded}
      downloadTemplateFilePath={GEOFENCES_UPLOAD_FILE_TEMPLATE_PATH}
      uploadResultComponent={
        <UploadResult
          uploadedMessage={uploadedMessage}
          errors={parseFileResult.validationErrors}
          processedRecordsCount={parseFileResult.validatedGeofences.length}
          itemName={"geofence"}
        />
      }
    />
  );
};
