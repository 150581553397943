import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { omitBy, isNil } from "lodash";
import * as yup from "yup";
import { transformers } from "../../../../utils";

export type SendTableDataToEmailFormValues = {
  fileFormat: string;
  email: string;
};

const schema = yup.object().shape({
  fileFormat: yup
    .object()
    .required("File Type is required")
    .transform(transformers.string),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
});

export const useSendTableDataToEmailForm = (
  initialValues: Partial<SendTableDataToEmailFormValues>
) =>
  useForm({
    resolver: yupResolver(schema),
    values: omitBy(initialValues, isNil),
  });
