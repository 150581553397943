import { QueryClient } from "@tanstack/react-query";
import {
  AssetSensorProfilesResponseStatus,
  AssignOrgProfileToAssetsMutation,
  AssignOrgProfileToAssetsResponse,
  AssignOrgProfileToAssetsStatus,
  SetAssetSensorProfilesMutation,
  SetAssetSensorProfilesResponse,
  useAssignOrgProfileToAssetsMutation,
  useSetAssetSensorProfilesMutation,
} from "../../../../../graphql/operations";
import { analyzeResponse, handleAnalyzedResponse } from "../sensorsUtils";

export const useMutateCustomProfile = ({
  dispatchSuccessMessage,
  dispatchErrorMessage,
  queryClient,
  handleClose,
}: {
  dispatchSuccessMessage: () => void;
  dispatchErrorMessage: (message: string) => void;
  queryClient: QueryClient;
  handleClose: () => void;
}) => {
  const mutateCustomProfile = useSetAssetSensorProfilesMutation({
    onSuccess: async (data: SetAssetSensorProfilesMutation) => {
      const response = analyzeResponse(
        data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
        AssetSensorProfilesResponseStatus.Success
      );
      handleAnalyzedResponse(
        response,
        dispatchErrorMessage,
        dispatchSuccessMessage,
        queryClient
      );
      queryClient.invalidateQueries(["getMergedProfileForAsset"], {
        refetchType: "all",
      });
      handleClose();
    },
  });

  return mutateCustomProfile;
};

export const useMutateProfile = ({
  dispatchSuccessMessage,
  dispatchErrorMessage,
  queryClient,
  handleClose,
}: {
  dispatchSuccessMessage: () => void;
  dispatchErrorMessage: (message: string) => void;
  queryClient: QueryClient;
  handleClose: () => void;
}) => {
  const mutateProfile = useAssignOrgProfileToAssetsMutation({
    onSuccess: async (data: AssignOrgProfileToAssetsMutation) => {
      const response = analyzeResponse(
        data?.assignOrgProfileToAssets as AssignOrgProfileToAssetsResponse[],
        AssignOrgProfileToAssetsStatus.Fulfilled
      );
      handleAnalyzedResponse(
        response,
        dispatchErrorMessage,
        dispatchSuccessMessage,
        queryClient
      );
      queryClient.invalidateQueries(["getMergedProfileForAsset"], {
        refetchType: "all",
      });
      handleClose();
    },
  });

  return mutateProfile;
};
