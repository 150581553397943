import { AirBagSensorMeasures, Maybe } from "../../../../../graphql/operations";
import { AirBagHexCode } from "./AirBagStatusCards/helpers";

// We only support the measures with `location_code_hex_str` in AirBagHexCode enum, so we filter out the rest
export const filterAirBagSensorMeasures = (
  measures: Maybe<Maybe<AirBagSensorMeasures>[]> | undefined
) => {
  return (measures ?? []).filter((measure): measure is AirBagSensorMeasures => {
    const locationCode = measure?.location_code_hex_str as AirBagHexCode;

    return (
      measure !== null && Object.values(AirBagHexCode).includes(locationCode)
    );
  });
};
