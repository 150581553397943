import { FC, memo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Button, TextButton } from "../../../../../shared/components/Button";

export type MaximumExceededModalProps = {
  isOpened: boolean;
  onClose: () => void;
  maxCount: number;
  onReturn?: () => void;
};

export const MaximumExceededModal: FC<MaximumExceededModalProps> = memo(
  ({ isOpened, onClose, maxCount, onReturn }) => {
    return (
      <Dialog
        open={isOpened}
        onClose={onReturn}
        data-testid="maximum-exceeded-dialog"
        sx={{ backgroundColor: "var(--backdrop)" }}
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{
          sx: {
            backgroundColor: "var(--background) !important",
            backgroundImage: "none",
            width: "100%",
            maxWidth: "720px !important",
            borderRadius: "8px !important",
          },
        }}
      >
        <DialogTitle
          className="!h-30 flex justify-between !p-6 sm:!h-20"
          data-testid="maximum-exceeded-dialog-title"
        >
          <span className="text-2xl font-semibold !text-typography-secondary">
            Maximum Exceeded
          </span>
          <IconButton
            aria-label="close"
            onClick={onReturn ?? onClose}
            data-testid="maximum-exceeded-dialog-close-icon-btn"
            className="!-mt-1 h-10 w-10"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="!pb-0">
          <DialogContentText
            data-testid="maximum-exceeded-dialog-message"
            className="!mb-6 text-base !text-typography-secondary"
          >
            Oops! Looks like you've selected more than the maximum number of
            companies allowed.
          </DialogContentText>
          <DialogContentText
            data-testid="maximum-exceeded-dialog-message-tip"
            className="text-base !text-typography-secondary"
          >
            {`Please select up to ${maxCount} companies to proceed.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="!p-6">
          <TextButton
            text="Cancel"
            size="medium"
            onClick={onReturn ?? onClose}
            theme="blue"
            data-testid="maximum-exceeded-dialog-cancel-btn"
            iconPosition="none"
            icon={undefined}
            className="!p-0"
          />
          <Button
            text="Okay"
            size="medium"
            theme="blue"
            variant="default"
            onClick={onClose}
            dataTestid="maximum-exceeded-dialog-okay-btn"
            className="!ml-4 !h-[40px] !w-[92px] !py-3"
          />
        </DialogActions>
      </Dialog>
    );
  }
);
