import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { ReportScheduleFrequency } from "../../../../../../graphql/operations";
import {
  checkIsAfter,
  checkIsBefore,
  checkIsValidDate,
  getDateTime,
} from "../../../../../../utils/date";
import { SchedulingRepeat } from "../../../../../../utils/scheduling";
import { convertTimeTo24Hour } from "../../../../helpers/helpers";

export const currentDateInOrgTimezone = (timeZone: string) =>
  new Date(
    getDateTime().fromISO(new Date().toISOString(), { zone: timeZone }).toISO({
      includeOffset: false,
    }) as string
  );

/**
 * Checks if date1 is after date2 ignoring time
 * @param date1
 * @param date2
 */
export const isDateAfter = (date1: string, date2: string) =>
  checkIsAfter(
    new Date(date1).setHours(0, 0, 0, 0),
    new Date(date2).setHours(0, 0, 0, 0)
  );

export const isTimeBeforeCurrentTime = (
  time: string,
  timeFormat: string,
  startDate: string,
  timezone: string
) => {
  const selectedDate = new Date(startDate);
  const [hours, minutes] = convertTimeTo24Hour(`${time} ${timeFormat}`).split(
    ":"
  );
  selectedDate.setHours(Number(hours));
  selectedDate.setMinutes(Number(minutes));

  return checkIsBefore(selectedDate, currentDateInOrgTimezone(timezone));
};

export const isDateBeforeCurrentDate = (date: string, timezone: string) => {
  const selectedDate = new Date(date);
  if (!checkIsValidDate(selectedDate)) return false;

  const now = currentDateInOrgTimezone(timezone);
  // need to remove time to compare only dates
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  return checkIsBefore(selectedDate, now);
};

export const isTimeNotInThePast = (parent: any, timezone: string) => {
  if (
    parent.frequency === ReportScheduleFrequency.DoNotRepeat ||
    (parent.frequency === ReportScheduleFrequency.Custom &&
      parent.repeat === SchedulingRepeat.Never)
  ) {
    return parent.time
      ? !isTimeBeforeCurrentTime(
          parent.time,
          parent.timeFormat,
          parent.startDate,
          timezone
        )
      : false;
  } else {
    return true;
  }
};

export const isStartDateNotInThePast = (
  value: string | undefined,
  parent: any,
  timezone: string
) => {
  if (
    parent.frequency === ReportScheduleFrequency.DoNotRepeat ||
    (parent.frequency === ReportScheduleFrequency.Custom &&
      parent.repeat === SchedulingRepeat.Never)
  ) {
    return value ? !isDateBeforeCurrentDate(value, timezone) : false;
  } else {
    return true;
  }
};

const everyCheck = (parent: any) => {
  if (
    parent.frequency === ReportScheduleFrequency.Custom &&
    parent.repeat !== SchedulingRepeat.Yearly &&
    parent.repeat !== SchedulingRepeat.Never
  ) {
    return !!parent.every;
  }
  return true;
};

const repeatCheck = (parent: any) => {
  if (parent.frequency === ReportScheduleFrequency.Custom) {
    return !!parent.repeat;
  }
  return true;
};

const onCheck = (parent: any) => {
  if (
    (parent.frequency === ReportScheduleFrequency.Custom &&
      parent.repeat === SchedulingRepeat.Daily &&
      parent.every === "1") ||
    (parent.frequency === ReportScheduleFrequency.Custom &&
      parent.repeat === SchedulingRepeat.Weekly)
  ) {
    return !!parent.on?.length;
  }

  return true;
};

export const isSubscribersSelected = (parent: any) => {
  return (
    !!parent?.roles?.length ||
    !!parent?.users?.length ||
    !!parent?.emails?.length
  );
};

export const useScheduleForm = (initialValues: any, timezone: string) => {
  const schema = yup.object().shape({
    subscribers: yup.object().shape({
      roles: yup
        .array(yup.string())
        .test(
          "anyOfRoles",
          "At least one recipient should be selected",
          (value, { parent }) => {
            return isSubscribersSelected(parent);
          }
        ),
      users: yup
        .array(yup.string())
        .test(
          "anyOfEmails",
          "At least one recipient should be selected",
          (value, { parent }) => {
            return isSubscribersSelected(parent);
          }
        ),
      emails: yup
        .array(yup.string())
        .test(
          "anyOfEmails",
          "At least one recipient should be selected",
          (value, { parent }) => {
            return isSubscribersSelected(parent);
          }
        ),
    }),
    time: yup
      .string()
      .required("Time is required")
      .test(
        "time-not-in-past",
        "Please select time not in the past for your Organization`s time zone",
        (value, { parent }) => {
          return isTimeNotInThePast(parent, timezone);
        }
      ),
    timeFormat: yup.string().required("AM/PM is required"),
    startDate: yup
      .string()
      .required("Start date is required")
      .test(
        "date-not-in-past",
        "Please select Start Date not in the past for your Organization`s time zone",
        (value, { parent }) => {
          return isStartDateNotInThePast(value, parent, timezone);
        }
      )
      .test(
        "date-not-after-end",
        "Start Date should be equal to or be before the End Date",
        (value, { parent }) =>
          parent.endDate ? !isDateAfter(parent.startDate, parent.endDate) : true
      ),
    endDate: yup
      .string()
      .test(
        "date-not-in-past",
        "Please select End Date not in the past for your Organization`s time zone",
        (value) => {
          return value ? !isDateBeforeCurrentDate(value, timezone) : true;
        }
      )
      .test(
        "date-not-before-start",
        "End Date should be equal to or be after the Start Date",
        (value, { parent }) =>
          parent.endDate ? !isDateAfter(parent.startDate, parent.endDate) : true
      ),
    frequency: yup
      .string()
      .test(
        "time-selected",
        "Frequency, Start Date, Time, AM/PM are required",
        (value, { parent }) =>
          parent.time && parent.timeFormat && parent.startDate
      )
      .test(
        "time-not-in-past",
        "Please select Date/Time not in the past for your Organization`s time zone",
        (value, { parent }) => {
          return isTimeNotInThePast(parent, timezone);
        }
      )
      .test(
        "date-not-in-past",
        "Please select End Date not in the past for your Organization`s time zone",
        (value, { parent }) =>
          !isDateBeforeCurrentDate(parent.endDate, timezone)
      )
      .test(
        "date-not-before-start",
        "End Date should be equal to or be after the Start Date",
        (value, { parent }) =>
          parent.endDate ? !isDateAfter(parent.startDate, parent.endDate) : true
      )
      .test("repeat-selected", "Repeat is required", (value, { parent }) =>
        repeatCheck(parent)
      )
      .test("every-selected", "Every is required", (value, { parent }) =>
        everyCheck(parent)
      )
      .test("on-selected", "On is required", (value, { parent }) =>
        onCheck(parent)
      )
      .required("Frequency is required"),
    format: yup.string().required("Export file type is required"),
    every: yup
      .string()
      .test("every-required", "Every is required", (value, { parent }) => {
        return everyCheck(parent);
      }),
    repeat: yup
      .string()
      .test("repeat-required", "Repeat is required", (value, { parent }) => {
        return repeatCheck(parent);
      }),
    on: yup
      .array(yup.string())
      .test("on-required", "On is required", (value, { parent }) => {
        return onCheck(parent);
      }),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: omitBy(initialValues, isNil),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
