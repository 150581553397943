import { CargoCameraStats } from "../../../../../../graphql/operations";
import { checkIsSameHour } from "../../../../../../utils/date";
import { CargoCameraFloorSpaceData } from "./types";

export const getGraphChipBoxColor = (item: CargoCameraStats): string => {
  if (item.floorUsageStatus === "loaded" && item?.floorUsagePercentage) {
    const value = parseInt(item.floorUsagePercentage.replace("%", ""));

    // Round value up to the nearest 10
    // Examples: 31 goes to 40, 54 goes to 60 ans 69 goes to 70
    const roundUp = Math.ceil(value / 10) * 10;

    if (roundUp === 100) {
      return "bg-brand-light-blue";
    } else {
      return `bg-primary-blue-transparent-${roundUp}`;
    }
  }

  if (item.floorUsageStatus === "empty") {
    return "border";
  }

  return "bg-box-shadow";
};

export const parseMediaActivity = (
  data: CargoCameraFloorSpaceData[]
): Date[] => {
  const mediaActivity: Date[] = [];

  data.forEach((day) => {
    if (day?.eventHistoryData?.mediaActivity?.mediaTimestamps) {
      day?.eventHistoryData?.mediaActivity?.mediaTimestamps.forEach(
        (timestamp) => {
          if (timestamp?.uploadDate) {
            mediaActivity.push(new Date(timestamp?.uploadDate));
          }
        }
      );
    }
  });

  return mediaActivity;
};

export const checkIsPhotoTaken = (
  item: CargoCameraStats,
  date: Date,
  mediaActivity: Date[]
): boolean => {
  if (!item.hour) {
    return false;
  }

  // Strip off the last 2 digits of the hour string and convert it to a number
  // Example: "1300" -> 13
  const hour = parseInt(item.hour.slice(0, 2));

  // Create a new event date using the input date and the hours from the item
  const eventDate = new Date(date);
  eventDate.setHours(hour);

  const isPhotoTaken = mediaActivity.some((mediaDate) => {
    return checkIsSameHour(mediaDate, eventDate);
  });

  return isPhotoTaken;
};
