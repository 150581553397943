import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import classNames from "classnames";
import { orderBy, get } from "lodash";
import { useAuthContext } from "../../context/AuthContext";
import RequireAuth from "../../permissions/RequireAuth";
import Page from "../../shared/components/Page";
import Tabs from "../../shared/components/Tabs";
import { Tab, TabsVariant } from "../../shared/components/Tabs/Tabs";
import UnderDevelopment from "../../shared/components/UnderDevelopment";
import useBreakpoint from "../../shared/hooks/useBreakpoint";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { adminPanelTabs } from "./adminPanelUtils";
import AutomationsComponent from "./tabs/Automations/AutomationsComponent";
import BrandsComponent from "./tabs/Brands/BrandsComponent";
import Configurations from "./tabs/Configurations";
import Organizations from "./tabs/Organizations/OrgManagement";
import Profiles from "./tabs/Profiles/Profiles";
import Regions from "./tabs/Regions/Regions";
import Sensors from "./tabs/Sensors/Sensors";
import Users from "./tabs/Users/UserManagement";
import Zones from "./tabs/Zones/Zones";

const AdminPanel = () => {
  const { userRolePermissions } = useAuthContext();
  const isMobile = useBreakpoint("down", "sm");
  const location = useLocation();
  const { state, pathname } = location;
  const currentRoute = pathname
    .split("/")
    .filter((part) => part !== "")
    .pop();

  const tabs = orderBy(
    adminPanelTabs.filter(({ viewRights }) => {
      return get(userRolePermissions, viewRights).view;
    }),
    ["name"],
    ["asc"]
  ).map((tab, index) => ({ label: tab.name, value: index })) as Tab[];

  const panels = orderBy(
    [
      {
        id: "automations",
        element: <AutomationsComponent key="automations" />,
        viewRights: "automations",
      },
      {
        id: "brands",
        element: <BrandsComponent key="brands" />,
        viewRights: "brands",
      },
      {
        id: "organizations",
        element: <Organizations key="organizations" />,
        viewRights: "org",
      },
      {
        id: "users",
        element: <Users key="users" />,
        viewRights: "user",
      },
      {
        id: "profiles",
        element: <Profiles key="profiles" />,
        viewRights: "profiles",
      },
      {
        id: "configurations",
        element: <Configurations key="configurations" />,
        viewRights: "configurations",
      },
      {
        id: "sensors",
        element: <Sensors key="sensors" />,
        viewRights: "sensors",
      },
      {
        id: "regions",
        element: <Regions key="regions" />,
        viewRights: "regions",
      },
      {
        id: "zones",
        element: <Zones key="zones" />,
        viewRights: "zones",
      },
    ].filter(({ viewRights }) => get(userRolePermissions, viewRights).view),
    ["id"],
    ["asc"]
  );

  const activeTabIndex = panels.findIndex((tab) => tab.id === currentRoute);
  const navigate = useNavigate();

  const defaultPath = panels.length
    ? `${NavigationRoutes.AdminPanel}/${panels[0].id}`
    : NavigationRoutes.Dashboard;

  return (
    <Page className="px-6">
      {tabs.length ? (
        <>
          <Tabs
            tabs={tabs}
            controlled
            showTabContent={false}
            value={state?.activeTabIndex ?? activeTabIndex}
            scrollButtons={isMobile}
            allowScrollButtonsMobile={isMobile}
            variant={isMobile ? TabsVariant.Scroll : TabsVariant.Standard}
            onClick={(value) => {
              const route: string = panels[value].id;
              navigate(route, { state: { activeTabIndex: value } });
            }}
            tabClasses={{ "mt-16": isMobile, "mt-5": !isMobile }}
          />

          <div
            className={classNames("flex-1 bg-background")}
            data-testid="dashboard-frame-tab-content"
          >
            <Routes>
              <Route
                path="automations"
                element={
                  <RequireAuth>
                    <AutomationsComponent key="automations" />
                  </RequireAuth>
                }
              />
              <Route
                path="brands"
                element={
                  <RequireAuth>
                    <BrandsComponent key="brands" />
                  </RequireAuth>
                }
              />
              <Route
                path="organizations"
                element={
                  <RequireAuth>
                    <Organizations />
                  </RequireAuth>
                }
              />
              <Route
                path="users"
                element={
                  <RequireAuth>
                    <Users />
                  </RequireAuth>
                }
              />
              <Route
                path="profiles"
                element={
                  <RequireAuth>
                    <Profiles />
                  </RequireAuth>
                }
              />
              <Route
                path="configurations"
                element={
                  <RequireAuth>
                    <Configurations />
                  </RequireAuth>
                }
              />
              <Route
                path="sensors"
                element={
                  <RequireAuth>
                    <Sensors />
                  </RequireAuth>
                }
              />
              <Route
                path="regions"
                element={
                  <RequireAuth>
                    <Regions />
                  </RequireAuth>
                }
              />
              <Route
                path="zones"
                element={
                  <RequireAuth>
                    <Zones />
                  </RequireAuth>
                }
              />
              <Route path="/" element={<Navigate to={defaultPath} />} />
            </Routes>
          </div>
        </>
      ) : (
        <UnderDevelopment />
      )}
    </Page>
  );
};

export default AdminPanel;
