import { FC } from "react";
import Box from "@mui/material/Box";

interface AlertTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

export const AlertTab: FC<AlertTabProps> = ({ label, isActive, onClick }) => {
  if (!label) return null;

  return (
    <Box
      data-testid={`alert-tab-${label.toLowerCase()}`}
      className={`px-2 py-1 font-normal text-battery-label cursor-pointer border-b-2 ${
        isActive ? "border-brand font-semibold" : "border-table_row_bg"
      }`}
      onClick={onClick}
    >
      {label}
    </Box>
  );
};
