import React from "react";

export interface ReadOnlyMenuItemProps {
  text: string;
}

export const ReadOnlyMenuItem: React.FC<ReadOnlyMenuItemProps> = ({ text }) => (
  <div className="flex items-center h-14">
    <span className="inline-block">{text}</span>
  </div>
);
