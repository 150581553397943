import isString from "lodash/isString";

export type Option = { value: string; label: string; id: string };

export const checkOptionEquality = (
  option: Option,
  value: Option | string | null | undefined
) =>
  isString(value)
    ? option?.value.toString() === value.toString()
    : option?.value.toString() === value?.value.toString();
