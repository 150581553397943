import * as yup from "yup";

export const TPMS_FORM_FIELDS = [
  {
    name: "typeOfAssigment",
    type: "autocomplete",
    label: "Type of Assignment",
    options: [
      {
        label: "Custom",
        value: "custom",
        id: "custom",
      },
      {
        label: "Profile",
        value: "profile",
        id: "profile",
      },
    ],
    required: true,
  },
  {
    name: "tpmsSensorProfile",
    type: "autocomplete",
    label: "Profile Name",
    options: [],
    required: true,
  },
];

export const tpmsSensorSchema = yup.object().shape({
  typeOfAssigment: yup.string().required("Field is required!").nullable(),
  tpmsSensorProfile: yup
    .string()
    .required("Field is required!")
    .nullable()
    .when("typeOfAssigment", {
      is: (value: { label: string; value: string }) => {
        return value?.label === TPMS_FORM_FIELDS[0].options[1].label;
      },
      then: yup
        .object()
        .shape({
          label: yup.string().required("required"),
          value: yup.string().required("required"),
          id: yup.string().required("required"),
        })
        .required("Field is required!")
        .nullable(),
      otherwise: yup.string().nullable().notRequired(),
    }),
});
