import { FC, useMemo, useState } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";
import { ODOMETER_CHART_DATE_RANGE_OPTIONS } from "../../../../../../constants/map";
import {
  useGetOdometerTrendsWithSpecificPowerSupplyQuery,
  Asset,
  DistanceUnit,
} from "../../../../../../graphql/operations";
import { useCurrentOrg } from "../../../../../../shared/hooks/useCurrentOrg";
import OdometerChart from "../../../Shared/OdometerChart/OdometerChart";
import {
  DEFAULT_ODOMETER_TRENDS_OPTION,
  getOdometerTrendsChartData,
  chartTickStyle,
  chartColors,
  AggregatedEventHistoryReportTypes,
} from "./chartUtils";

export interface OdomoterTrendsProps {
  selectedAsset?: Asset;
}

const OdometerTrends: FC<OdomoterTrendsProps> = ({ selectedAsset }) => {
  const currentOrg = useCurrentOrg();
  const distanceUnit =
    currentOrg?.distance_unit_preference ?? DistanceUnit.Kilometers;
  const isDistanceUnitMiles = distanceUnit === DistanceUnit.Miles;

  const [selectedOdometerDateRangeOption, setSelectedOdometerDateRangeOption] =
    useState(DEFAULT_ODOMETER_TRENDS_OPTION);

  const defaultDateRangeFilter = ODOMETER_CHART_DATE_RANGE_OPTIONS.find(
    (option) => option.value === DEFAULT_ODOMETER_TRENDS_OPTION
  );
  const [defaultStartDateFilter, defaultEndDateFilter] =
    defaultDateRangeFilter?.getRange() ?? [];

  // Revise the String type it prevents issues when time zone is added when new Date() is used
  const [odometerChartStartDate, setOdometerChartStartDate] = useState<
    Date | string | null
  >(defaultStartDateFilter);

  const [odometerChartEndDate, setOdometerChartEndDate] = useState<
    Date | string | null
  >(defaultEndDateFilter);

  const { isLoading: isLoadingOdometerChartData, data: odometerTrendsData } =
    useGetOdometerTrendsWithSpecificPowerSupplyQuery({
      input: {
        startDate: odometerChartStartDate,
        endDate: odometerChartEndDate,
        assetId: selectedAsset?.asset_id!,
        reportType: AggregatedEventHistoryReportTypes.DailyReportTrip,
        includeEmptyDates: true,
      },
    });

  const odometerChartData = useMemo(() => {
    const formattedOdometerChartData = getOdometerTrendsChartData(
      odometerTrendsData,
      isDistanceUnitMiles
    );
    return formattedOdometerChartData;
  }, [odometerTrendsData, isDistanceUnitMiles]);

  const handleOdometerDateRangeChange = (value: number) => {
    const option = ODOMETER_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );

    if (option) {
      setSelectedOdometerDateRangeOption(value);
      if (option.getRange) {
        const [startDate, endDate] = option.getRange();
        setOdometerChartStartDate(startDate);
        setOdometerChartEndDate(endDate);
      }
    }
  };

  return (
    <Grid item xl={6} lg={12} xs={12} data-testid="odometer-chart-container">
      {isLoadingOdometerChartData ? (
        <Box
          className="flex h-full w-full items-center justify-center"
          data-testid="odometer-trends-loader"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box className="flex-1 rounded-lg bg-dashboard_subheader__bg p-4">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "26px",
                }}
                className="text-brand"
                data-testid="chart-title"
              >
                Odometer Trends
              </Typography>
              <Typography
                className="text-asset-info-subheader"
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "16px",
                }}
                data-testid="chart-subtitle"
              >
                Odometer trends over time
              </Typography>
            </Box>
            <TextField
              data-testid="odometer-trends-chart-date-range-select"
              select
              value={selectedOdometerDateRangeOption}
              variant="outlined"
              className="w-40 "
              onChange={(e) => handleOdometerDateRangeChange(+e.target.value)}
              inputProps={{
                "data-testid": "odometer-trends-chart-date-range-input",
              }}
            >
              {ODOMETER_CHART_DATE_RANGE_OPTIONS.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  data-testid={`odometer-trends-chart-date-range-select-option-${option.value}`}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <OdometerChart
            data={odometerChartData}
            chartColors={chartColors}
            tickStyle={chartTickStyle}
            isDistanceUnitMiles={isDistanceUnitMiles}
          />
        </Box>
      )}
    </Grid>
  );
};

export default OdometerTrends;
