import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import { Drawer, Grid, Switch, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { capitalize } from "lodash";
import { SET_APP_THEME } from "../../../../constants";
import { roleToHumanReadableMapper } from "../../../../constants/users";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  MeUserData,
  useUpdateMyAccountMutation,
} from "../../../../graphql/operations";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import useBreakpoint from "../../../hooks/useBreakpoint";

const drawerWidth = 375;

export type PopoverMenuProps = {
  userData?: MeUserData;
  open: boolean;
  onClose: () => void;
  onLogout: () => void;
};

const PopoverMenu = ({
  userData,
  open,
  onClose,
  onLogout,
}: PopoverMenuProps) => {
  const [showUserSelection, setShowUserSelection] = useState(false);
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const { userInfo, decodedToken } = useAuthContext();
  const isMobile = useBreakpoint("down", "sm");
  const handleClose = () => {
    setShowUserSelection(false);
    onClose();
  };
  const getInitialThemeModeFromLocalStorage = localStorage.getItem("theme");

  const { mutate: userThemeMutation } = useUpdateMyAccountMutation({
    onSuccess: () => {},
  });

  const updateTheme = (theme: string) => {
    userThemeMutation({
      user: {
        name: userInfo?.username!,
        user_preferences: {
          ui_theme: theme,
        },
      },
    });
    dispatch({
      type: SET_APP_THEME,
      payload: theme,
    });
  };

  return (
    <Drawer
      disableEnforceFocus
      data-testid="popover-menu"
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          paddingTop: "76px",
          "@media (max-width:640px)": {
            paddingTop: "119px",
          },
          height: "fit-content",
          maxHeight: window.innerHeight + "px",
        },
      }}
    >
      {!showUserSelection && (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item pt={2} px={3}>
            <Typography fontWeight={"bold"}>
              {capitalize(userData?.firstName ?? "")}{" "}
              {capitalize(userData?.lastName ?? "")}
            </Typography>
          </Grid>
          <Grid
            item
            px={3}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography fontSize={16} fontWeight={400}>
                {
                  roleToHumanReadableMapper[
                    userData?.groups
                      ? userData.groups[0] ?? "unknown"
                      : "unknown"
                  ]
                }
              </Typography>
            </Grid>
          </Grid>
          {isMobile && (
            <Grid item px={3}>
              <Typography fontSize={16} fontWeight={400}>
                {capitalize(decodedToken?.["cognito:username"] || "")}
              </Typography>
            </Grid>
          )}
          <Grid item px={3} pb={2}>
            <Typography sx={{ wordBreak: "break-word" }}>
              {userData?.email}
            </Typography>
          </Grid>
          <Divider />
          <Grid item>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItemButton
                onClick={() => navigate(NavigationRoutes.MyAccount)}
              >
                <ListItemIcon>
                  <AccountCircleOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="My Account" />
              </ListItemButton>
              <ListItem>
                <ListItemIcon>
                  <SettingsBrightnessIcon />
                </ListItemIcon>
                <ListItemText primary="Dark Mode" />
                <Switch
                  checked={getInitialThemeModeFromLocalStorage === "dark"}
                  onChange={(e) =>
                    updateTheme(e.target.checked ? "dark" : "light")
                  }
                  inputProps={
                    {
                      "aria-label": "controlled",
                      "data-testid": "dark-mode-switch",
                    } as any // Using as any here because "data-testid" is unknown to MUI
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Divider />
          <Grid item>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItemButton data-testid="logoutBtn" onClick={onLogout}>
                <ListItemIcon>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </Grid>
        </Grid>
      )}
    </Drawer>
  );
};

export default PopoverMenu;
