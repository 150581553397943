import { PressureUnit } from "../../graphql/operations";
import { useUserData } from "./useUserData";

export const useGetPressureUnitPreference = (): PressureUnit => {
  const userData = useUserData();

  const pressureUnit = userData?.customerOrg.pressure_unit_preference;

  return pressureUnit ?? PressureUnit.Mbar; // TODO; Replace with DEFAULT_PRESSURE_UNIT;
};
