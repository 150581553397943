import { memo, SVGProps, FC } from "react";

interface UnderDevelopmentProps extends SVGProps<SVGSVGElement> {}

const UnderDevelopment: FC<UnderDevelopmentProps> = (props) => {
  return (
    <svg {...props} width="40" height="46" viewBox="0 0 40 46" fill="none">
      <path
        d="M19.9998 31.4737C9.29872 31.4737 0.631348 35.8074 0.631348 41.1579V46H39.3682V41.1579C39.3682 35.8074 30.7008 31.4737 19.9998 31.4737ZM10.3156 16.9474C10.3156 19.5158 11.3359 21.979 13.152 23.7951C14.9681 25.6113 17.4314 26.6316 19.9998 26.6316C22.5682 26.6316 25.0314 25.6113 26.8475 23.7951C28.6637 21.979 29.684 19.5158 29.684 16.9474M18.7892 0C18.0629 0 17.5787 0.508421 17.5787 1.21053V8.47368H15.1577V2.42105C15.1577 2.42105 9.7103 4.50316 9.7103 11.5C9.7103 11.5 7.89451 11.8389 7.89451 14.5263H32.105C31.984 11.8389 30.2892 11.5 30.2892 11.5C30.2892 4.50316 24.8419 2.42105 24.8419 2.42105V8.47368H22.4208V1.21053C22.4208 0.508421 21.9608 0 21.2103 0H18.7892Z"
        fill="#3C88CC"
      />
    </svg>
  );
};

export default memo(UnderDevelopment);
