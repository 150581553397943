import React, { createContext, useContext, useState } from "react";
import { TerritoryInUse } from "./interfaces";

interface RegionsContextProps {
  children: React.ReactNode;
}

interface RegionsContextValue {
  territoriesInUseUSA: TerritoryInUse[];
  setTerritoriesInUseUSA: (territories: TerritoryInUse[]) => void;

  territoriesInUseCanada: TerritoryInUse[];
  setTerritoriesInUseCanada: (territories: TerritoryInUse[]) => void;

  territoriesInUseMexico: TerritoryInUse[];
  setTerritoriesInUseMexico: (territories: TerritoryInUse[]) => void;
}

const RegionsContext = createContext<RegionsContextValue | undefined>(
  undefined
);

export const RegionsContextProvider: React.FC<RegionsContextProps> = ({
  children,
}) => {
  // Setup the date range filters for the graph
  const [territoriesInUseUSA, setTerritoriesInUseUSA] = useState<
    TerritoryInUse[]
  >([]);
  const [territoriesInUseCanada, setTerritoriesInUseCanada] = useState<
    TerritoryInUse[]
  >([]);
  const [territoriesInUseMexico, setTerritoriesInUseMexico] = useState<
    TerritoryInUse[]
  >([]);

  const contextValue: RegionsContextValue = {
    territoriesInUseUSA,
    setTerritoriesInUseUSA,

    territoriesInUseCanada,
    setTerritoriesInUseCanada,

    territoriesInUseMexico,
    setTerritoriesInUseMexico,
  };

  return (
    <RegionsContext.Provider value={contextValue}>
      {children}
    </RegionsContext.Provider>
  );
};

export const useRegionsContext = () => {
  const context = useContext(RegionsContext);
  if (!context) {
    throw new Error(
      "useRegionsContext must be used within an RegionsContextProvider"
    );
  }
  return context;
};
