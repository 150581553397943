import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MenuItem, Typography, IconButton } from "@mui/material";
import { useAuthContext } from "../../../../../context/AuthContext";
import { AssetFilter } from "../../../../../graphql/operations";

export type FilterItemProps = {
  filter?: AssetFilter;
  selectedFilter?: AssetFilter | null;
  onClick: (mode: string, filter?: AssetFilter | undefined) => void;
};
const FilterItem = ({ filter, selectedFilter, onClick }: FilterItemProps) => {
  const { userInfo } = useAuthContext();
  const disabled = userInfo?.email !== filter?.email;
  return (
    <MenuItem
      className="flex min-w-[220px] !cursor-default !justify-between !text-inherit"
      onClick={() => onClick("", filter)}
    >
      <Typography
        className={`overflow-hidden text-ellipsis whitespace-nowrap !text-inherit ${
          filter?._id === selectedFilter?._id && "underline"
        }`}
      >
        {filter?.name ?? ""}
      </Typography>
      <div className="asset-filter-actions">
        <span className="cursor-pointer text-inherit" title="Update">
          <IconButton
            disabled={disabled}
            size="small"
            onClick={() => onClick("Update", filter)}
            component="a"
            data-testid="filter-item-button-update"
          >
            <BorderColorIcon fontSize="small" sx={{ fontSize: 14 }} />
          </IconButton>
        </span>
        <span className="cursor-pointer text-inherit" title="Delete">
          <IconButton
            disabled={disabled}
            size="small"
            onClick={() => onClick("Delete", filter)}
            component="a"
            data-testid="filter-item-button-delete"
          >
            <DeleteOutlineIcon fontSize="small" sx={{ fontSize: 14 }} />
          </IconButton>
        </span>
      </div>
    </MenuItem>
  );
};

export default FilterItem;
