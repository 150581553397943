import React, { ReactNode } from "react";
import { Button } from "@mui/material";
import classNames from "classnames";

interface AssetFilterMenuButtonProps {
  disabled?: boolean;
  onClick: () => void;
  title?: string;
  endIcon: ReactNode;
}

const AssetFilterMenuButton: React.FC<AssetFilterMenuButtonProps> = ({
  disabled,
  onClick,
  title,
  endIcon,
}) => {
  return (
    <Button
      className={classNames(
        "!hidden !normal-case !text-primary md:!inline-flex !min-w-[20px]",
        {
          "opacity-30": disabled,
        }
      )}
      size="small"
      title={title}
      disabled={disabled}
      onClick={onClick}
      endIcon={endIcon}
    >
      {title}
    </Button>
  );
};

export default AssetFilterMenuButton;
