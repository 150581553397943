import { FC, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  TextFieldElement,
  CheckboxElement,
  AutocompleteElement,
} from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ThemeProvider,
  TextField,
  Grid,
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import * as yup from "yup";
import {
  useFindUserByEmailQuery,
  useFindOrgQuery,
  Asset,
  SharedAssetStatus,
} from "../../../../graphql/operations";
import { useDateFilters } from "../../../../views/AssetsView/MapView/Assets/TiresTabPanel/components/charts/sensors/hooks/useDateFilters";
import { useInitateAssetsShare } from "../../../hooks/openSearchMongoPolyfillHooks/useInitateAssetsShare";
import { useFormTheme } from "../../../hooks/theme/useFormTheme";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { useCurrentOrg } from "../../../hooks/useCurrentOrg";
import { Button as SubmitButton, TextButton } from "../../Button";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { CustomTooltip } from "../../CustomTooltip/CustomTooltip";
import Text from "../../Text";

export interface ShareAssetsDialogProps {
  open: boolean;
  onClose: () => void;
  onSnack: (
    success: boolean,
    customTitle?: string,
    customMessage?: string
  ) => void;
  assets: Asset[];
  refetchAssetsForSharing: () => void;
  isLoadingAssets: boolean;
}

export const schema = yup.object().shape({
  group: yup.string().required("Field is required!"),
  email: yup.string().required("Field is required!"),
  speed: yup.string(),
  mileage: yup.string(),
  assets: yup.array().min(1, "Field is required!").required(),
});

const ShareAssetsDialog: FC<ShareAssetsDialogProps> = ({
  open,
  onClose,
  onSnack,
  assets,
  refetchAssetsForSharing,
  isLoadingAssets,
}) => {
  const formTheme = useFormTheme();
  const isMobile = useBreakpoint("down", "sm");

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      group: "",
      email: "",
      speed: "",
      mileage: "",
      assets: [] as string[],
    },
  });

  const [watchedAssetsValue, watchedEmailValue] = watch(["assets", "email"]);

  const { _id, org_key } = useCurrentOrg() ?? {};
  const { data: dataFindOrg } = useFindOrgQuery({
    input: {
      id: _id,
      org_key: org_key,
    },
  });

  const {
    data: dataUserByEmail,
    isSuccess: isSuccessFindUser,
    isFetching: isFetchingFindUserByEmail,
    refetch: refetchFindUserByEmail,
  } = useFindUserByEmailQuery(
    {
      input: {
        email: watchedEmailValue ?? "",
      },
    },
    {
      enabled: false,
      onError: () => {
        onSnack(false, "Something Went Wrong.");
      },
    }
  );

  const recepientUserExists = useMemo(
    () => dataUserByEmail?.findUserByEmail.data,
    [dataUserByEmail?.findUserByEmail.data]
  );

  const options = useMemo(
    () =>
      assets?.map((asset) => ({
        label: asset?.asset_id,
        id: asset?._id,
        imei: asset?.imei,
        shared: Boolean(
          asset?.shared && asset?.shared?.status === SharedAssetStatus.Shared
        ),
      })),
    [assets]
  );

  const { warningAlreadyShared, warningNotAssociatedAssets } = useMemo(
    () => ({
      warningAlreadyShared: assets?.some(
        (asset) =>
          asset?.shared &&
          watchedAssetsValue.includes(asset?._id as never) &&
          asset?.shared?.status === SharedAssetStatus.Shared
      ),
      warningNotAssociatedAssets: assets?.some(
        (asset) =>
          !asset?.imei && watchedAssetsValue.includes(asset?._id as never)
      ),
    }),
    [assets, watchedAssetsValue]
  );

  const warningChildToParentShare =
    dataFindOrg?.findOrg?.parent_org_id ===
    dataUserByEmail?.findUserByEmail?.data?.customer_orgs_id[0];

  useEffect(() => {
    if (!isLoadingAssets) {
      reset({
        group: "",
        email: "",
        speed: "",
        mileage: "",
        assets: options?.map((option) => option?.id),
      });
    }
  }, [reset, options, isLoadingAssets]);

  const {
    startDate,
    setStartDate,

    endDate,
    setEndDate,
  } = useDateFilters(new Date());

  const {
    mutateAsync: mutateInitiateAssetsShare,
    isLoading: isLoadingInitiateAssetsShare,
  } = useInitateAssetsShare({
    onSuccess: () => {
      reset(undefined, {
        keepValues: true,
      });

      onSnack(
        true,
        "Share Successful!",
        "Selected assets have been shared successfully."
      );
      refetchAssetsForSharing();

      onClose();
    },
    onError: () => {
      onSnack(false, "Something Went Wrong.");
    },
  });

  const onSubmit = () => {
    refetchFindUserByEmail();
  };

  useEffect(() => {
    const formData = getValues();

    const input = {
      assetIds: formData.assets,
      recipientEmail: formData.email,
      endDate: endDate,
      startDate: startDate,
      hideProperties: {
        mileage: Boolean(formData.mileage),
        speed: Boolean(formData.speed),
      },
      sharedGroupName: {
        owner: formData.group,
      },
      organizationId: _id ?? "",
    };

    if (
      !warningChildToParentShare &&
      isSuccessFindUser &&
      recepientUserExists &&
      isSubmitted &&
      !warningNotAssociatedAssets &&
      watchedAssetsValue.length
    ) {
      (async () => {
        await mutateInitiateAssetsShare({
          input,
        });
      })();
    }
  }, [
    _id,
    watchedAssetsValue,
    warningNotAssociatedAssets,
    warningChildToParentShare,
    warningAlreadyShared,
    mutateInitiateAssetsShare,
    endDate,
    startDate,
    isSubmitted,
    isSuccessFindUser,
    recepientUserExists,
    dataUserByEmail,
    getValues,
  ]);

  const isControlDisabled =
    isLoadingAssets ||
    isLoadingInitiateAssetsShare ||
    isFetchingFindUserByEmail;

  if (warningNotAssociatedAssets && isSubmitted && recepientUserExists) {
    return (
      <ConfirmationDialog
        open={warningNotAssociatedAssets}
        isLoading={false}
        handleConfirmationResult={() => {
          onClose();
        }}
        title="Sharing Asset Without Device"
        message="Please note that only assets associated with a device can be shared."
        cancelButtonText="Cancel"
        confirmButtonText="Okay"
      />
    );
  }

  if (
    warningChildToParentShare &&
    isSubmitted &&
    !warningNotAssociatedAssets &&
    recepientUserExists
  ) {
    return (
      <ConfirmationDialog
        open={warningChildToParentShare}
        isLoading={false}
        handleConfirmationResult={() => {
          onClose();
        }}
        title="Sharing Asset To Parent Organization"
        message="Sharing assets from sub to parent organization is not allowed."
        cancelButtonText="Cancel"
        confirmButtonText="Okay"
      />
    );
  }

  return (
    <Dialog
      data-testid="share-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "32px 0",
          width: "100%",
          maxWidth: "1000px !important",
        },
      }}
    >
      <DialogTitle className="flex justify-between items-center !pt-0 px-[24px] !pb-[32px]">
        <Text
          classes="!text-2xl !font-semibold capitalize !text-typography-secondary "
          dataTestId="table-dialog-title"
        >
          Share Assets
        </Text>
        <IconButton
          aria-label="close"
          onClick={onClose}
          data-testid="table-dialog-close-icon-btn"
          className="h-6 w-6 flex align-center"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ThemeProvider theme={formTheme}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent className="!p-0 !pb-[24px]">
            <Grid
              container
              className="bg-background topPaddingDrawerSection !pt-0 !px-[24px]"
            >
              <Grid item xs={12} className="!pt-0">
                <TextFieldElement
                  data-testid="control-group-name"
                  fullWidth
                  control={control}
                  sx={{ marginBottom: "2rem" }}
                  disabled={isControlDisabled}
                  name="group"
                  required
                  label="Share Group Name"
                />
                <Box sx={{ marginBottom: "2rem" }}>
                  <TextFieldElement
                    data-testid="control-email"
                    fullWidth
                    control={control}
                    disabled={isControlDisabled}
                    name="email"
                    required
                    label="Recipient Email"
                    sx={{ marginBottom: "3px" }}
                    InputProps={{
                      endAdornment: isFetchingFindUserByEmail ? (
                        <CircularProgress size={20} />
                      ) : null,
                    }}
                  />
                  {!dataUserByEmail?.findUserByEmail.data &&
                    isSubmitted &&
                    isSuccessFindUser && (
                      <CustomTooltip title="The Recipient Email has to exist in the application. In case the e-mail does not exist, please advise the recipient to create an account in the application.">
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "var(--error)",
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          The e-mail does not exist in the application.
                        </Typography>
                      </CustomTooltip>
                    )}
                </Box>
              </Grid>

              <Grid item xs={12} className="!pt-0 mb-8">
                <Text fontSize={14} color="var(--form-label-default)">
                  Share Time Frame
                  <span className="text-error !mb-3.5 ml-[2px]">*</span>
                </Text>
                <Box className="flex items-center">
                  <MobileDatePicker
                    label="Start date"
                    inputFormat="MM/dd/yyyy"
                    closeOnSelect
                    value={startDate}
                    disabled={isControlDisabled}
                    disablePast
                    onChange={(date) => {
                      setStartDate(date as Date);
                    }}
                    DialogProps={{
                      className: "trending-voltage-date-picker",
                    }}
                    renderInput={(params) => (
                      <TextField
                        data-testid="control-start-date"
                        variant="outlined"
                        sx={{
                          "& .MuiInputLabel-root": {
                            top: -6,
                            left: 9,
                            fontWeight: "normal",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                  <span className="text-base font-normal text-primary mx-3">
                    to
                  </span>
                  <MobileDatePicker
                    DialogProps={{
                      className: "control-end-date-picker",
                    }}
                    label="End date"
                    closeOnSelect
                    inputFormat="MM/dd/yyyy"
                    value={endDate}
                    disabled={isControlDisabled}
                    disablePast
                    onChange={(date) => {
                      setEndDate(date as Date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        data-testid="control-end-date"
                        variant="outlined"
                        sx={{
                          "& .MuiInputLabel-root": {
                            top: -6,
                            left: 9,
                            fontWeight: "normal",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Text fontSize={14} color="var(--form-label-default)">
                  Exclude information
                </Text>
                <CheckboxElement
                  data-testid="control-speed"
                  name="speed"
                  label="Speed"
                  control={control}
                  disabled={isControlDisabled}
                />
                <CheckboxElement
                  data-testid="control-mileage"
                  name="mileage"
                  label="Mileage"
                  sx={{ marginLeft: "2.5rem" }}
                  control={control}
                  disabled={isControlDisabled}
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ marginBottom: "3px" }}>
                  <AutocompleteElement
                    required
                    name="assets"
                    label={`Assets (${watchedAssetsValue.length})`}
                    control={control}
                    options={options ?? []}
                    multiple
                    matchId
                    loading={isLoadingAssets}
                    textFieldProps={{
                      placeholder: "Select assets",
                      inputProps: {
                        disabled: isControlDisabled,
                        "data-testid": "assets",
                      },
                    }}
                    autocompleteProps={{
                      renderTags: (value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            sx={{
                              border:
                                option.shared || !option.imei
                                  ? "1px solid var(--error)"
                                  : undefined,
                            }}
                            label={option.label}
                            {...getTagProps({ index })}
                          />
                        )),
                    }}
                  />
                </Box>
                {warningAlreadyShared && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "var(--error)",
                      fontWeight: 500,
                    }}
                  >
                    You are sharing assets that are already shared. Continuing
                    with the process will cancel the previous shares and create
                    a new one.
                  </Typography>
                )}

                {warningNotAssociatedAssets && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "var(--error)",
                      fontWeight: 500,
                    }}
                  >
                    Sharing assets not assosciated with a PCT device is not
                    allowed.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              padding: "12px 24px",
              justifyContent: isMobile ? "center" : "end",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <TextButton
              data-testid="cancel-action"
              text="Cancel"
              size="medium"
              theme="blue"
              disabled={isLoadingInitiateAssetsShare}
              onClick={onClose}
            />

            <SubmitButton
              sx={{ padding: "24px", margin: 0 }}
              dataTestid="submit-action"
              text={isLoadingInitiateAssetsShare ? "Sharing..." : "Share"}
              disabled={isControlDisabled}
              size="medium"
              theme="blue"
              variant="default"
              type="submit"
            />
          </DialogActions>
        </form>
      </ThemeProvider>
    </Dialog>
  );
};

export default ShareAssetsDialog;
