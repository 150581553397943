import React, { FC, memo } from "react";
import {
  Control,
  Controller,
  ControllerProps,
  FieldValues,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Select,
  Typography,
} from "@mui/material";
import { ChipsList } from "../../ChipsList";
import { RequiredFlag } from "../../RequiredFlag";
import { RolesSelect, RolesSelectProps } from "../../RolesSelect";
import { roles } from "../../SubscribersSelect/constants";

export type RolesSelectElementProps = Pick<RolesSelectProps, "loading"> & {
  label?: string;
  rules?: ControllerProps["rules"];
  name: string;
  control: Control<any>;
  labelClassName?: string;
  required?: boolean;
  form: UseFormReturn<FieldValues>;
  compact?: boolean;
  disabled?: boolean;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
};

export const RolesSelectElement: FC<RolesSelectElementProps> = memo(
  ({
    label,
    rules,
    name,
    control,
    labelClassName = "!mb-2 !text-sm !font-bold",
    required,
    form,
    compact = false,
    disabled = false,
    handleUpdateField,
    fieldPath,
    ...rest
  }: RolesSelectElementProps) => {
    const rolesList = useWatch({
      name: name,
      control: form.control,
    });

    const handleDelete = (chip: string) => {
      const filteredRoles = rolesList.filter((role: string) => role !== chip);
      handleUpdateField?.(filteredRoles, fieldPath);
      form.setValue(name, filteredRoles);
    };

    return (
      <>
        <Controller
          rules={rules}
          name={name}
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error, invalid },
          }) => (
            <FormControl
              fullWidth
              required={required}
              variant="standard"
              error={invalid}
            >
              <>
                {label && (
                  <Typography
                    className={labelClassName}
                    data-testid="roles-select-element-label"
                  >
                    {label}
                    {rules?.required && <RequiredFlag />}
                  </Typography>
                )}
                {!rest.loading ? (
                  <RolesSelect
                    {...rest}
                    values={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    invalid={invalid}
                    required={required}
                    disabled={disabled}
                    handleUpdateField={handleUpdateField}
                    fieldPath={fieldPath}
                  />
                ) : (
                  <Box>
                    <Box
                      className="absolute bottom-0 right-6"
                      data-testid="roles-select-loader"
                    >
                      {rest.loading && <CircularProgress size={20} />}
                    </Box>
                    <Select fullWidth disabled value={""} />
                  </Box>
                )}
                {error?.message && (
                  <FormHelperText error={true}>{error?.message}</FormHelperText>
                )}
              </>
            </FormControl>
          )}
        />
        <ChipsList
          selectedChips={rolesList}
          chips={
            roles as {
              label: string;
              value: string;
            }[]
          }
          handleDelete={handleDelete}
          compact={compact}
        />
      </>
    );
  }
);
