import { Grid } from "@mui/material";
import { CustomTooltip } from "../../../../../../../shared/components/CustomTooltip/CustomTooltip";
import ToggleViewButton from "./ToggleViewButton";
import CapturePhotoButton from "./buttons/CapturePhotoButton";
import DeleteButton from "./buttons/DeleteButton";
import DownloadButton from "./buttons/DownloadButton";
import ShareButton from "./buttons/ShareButton";

interface ActionsProps {
  onCaptureBtnClick: () => void;
  isCaptureLoading: boolean;
}

export enum ActionButtonText {
  Download = "Download visible image(s)",
  Share = "Share visible image(s) via e-mail",
  Capture = "Send a request to the device for a new image",
  Delete = "Delete visible image(s)",
}

const Actions: React.FC<ActionsProps> = ({
  onCaptureBtnClick,
  isCaptureLoading,
}) => {
  return (
    <Grid
      container
      direction="row"
      className="w-full pb-4 justify-between items-center gap-4"
      data-testid="internal-camera-status-actions"
    >
      <ToggleViewButton />

      <div className="flex gap-x-4 max-[640px]:w-full max-[640px]:justify-between">
        <CustomTooltip title={ActionButtonText.Download} placement="top" arrow>
          <span>
            <DownloadButton />
          </span>
        </CustomTooltip>
        <CustomTooltip title={ActionButtonText.Share} placement="top" arrow>
          <span>
            <ShareButton />
          </span>
        </CustomTooltip>
        <CustomTooltip title={ActionButtonText.Capture} placement="top" arrow>
          <span>
            <CapturePhotoButton
              handleOnClick={onCaptureBtnClick}
              isCaptureLoading={isCaptureLoading}
            />
          </span>
        </CustomTooltip>
        <CustomTooltip title={ActionButtonText.Delete} placement="top" arrow>
          <span>
            <DeleteButton />
          </span>
        </CustomTooltip>
      </div>
    </Grid>
  );
};

export default Actions;
