import { FC, MutableRefObject } from "react";
import { Circle } from "@mui/icons-material";
import { Box, Grid, Typography, Chip } from "@mui/material";
import { useContainerDimensions } from "../../../../../../shared/hooks/useContainerDimensions";
import DoorHeader from "./DoorHeader";
import {
  calculateBarWidthBaseOnContainer,
  calculateDoorStatusInLocationsLast30Days,
  calculateDataStatusLast30Days,
  BAR_HEIGHT,
  DoorData,
} from "./utils";

export interface DoorStatusInLocationProps {
  data: Array<DoorData>;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

const DoorStatusInLocation: FC<DoorStatusInLocationProps> = ({
  data,
  parentRef,
}) => {
  const {
    closedDoorsEventsOutsideGeofence,
    closedDoorsEventsInsideGeofence,
    openDoorsEventsInsideGeofence,
    openDoorsEventsOutsideGeofence,
  } = calculateDoorStatusInLocationsLast30Days(data);

  const { openDoorsEvents, closedDoorsEvents } =
    calculateDataStatusLast30Days(data);

  const { width: containerWidth } = useContainerDimensions(parentRef);

  const {
    barWidthA: openReferenceBarWidth,
    barWidthB: closedReferenceBarWidth,
  } = calculateBarWidthBaseOnContainer(
    openDoorsEvents,
    closedDoorsEvents,
    containerWidth
  );

  const totalClosedDoorDays =
    closedDoorsEventsOutsideGeofence + closedDoorsEventsInsideGeofence;
  const totalOpenDoorDays =
    openDoorsEventsOutsideGeofence + openDoorsEventsInsideGeofence;

  // For closed bar geofence split
  const closedDaysInsideGeofenceWidth =
    (closedDoorsEventsInsideGeofence / totalClosedDoorDays) *
    closedReferenceBarWidth;
  const closedDaysOutsideGeofenceWidth =
    (closedDoorsEventsOutsideGeofence / totalClosedDoorDays) *
    closedReferenceBarWidth;

  // For open bar geofence split
  const openDaysInsideGeofenceWidth =
    (openDoorsEventsInsideGeofence / totalOpenDoorDays) * openReferenceBarWidth;
  const openDaysOutsideGeofenceWidth =
    (openDoorsEventsOutsideGeofence / totalOpenDoorDays) *
    openReferenceBarWidth;

  return (
    <Grid
      spacing={0}
      justify-content="flex-end"
      container
      className="text-sm w-full p-4"
      data-testid="last-30-days-door-status-in-locations"
    >
      <DoorHeader title={"Door Sensor"} />

      <Typography
        variant="caption"
        className="!text-lg !font-semibold inline-block pb-4"
      >
        Door Status in Locations Last 30 Days
      </Typography>

      <Box className="!mb-3 justify w-full flex">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text flex-shrink-0"
        >
          Open
        </Typography>
        {openDoorsEventsOutsideGeofence > 0 && (
          <Box
            data-testid="open-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              flexBasis: `${openDaysOutsideGeofenceWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {openDoorsEventsOutsideGeofence}
          </Box>
        )}
        {openDoorsEventsInsideGeofence > 0 && (
          <Box
            data-testid="open-days-inside-geofence"
            className="rounded inline-block align-middle text-center"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              flexBasis: `${openDaysInsideGeofenceWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {openDoorsEventsInsideGeofence}
          </Box>
        )}
      </Box>

      <Box className="!mb-2 justify w-full flex">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text flex-shrink-0"
        >
          Closed
        </Typography>
        {closedDoorsEventsOutsideGeofence > 0 && (
          <Box
            data-testid="closed-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              flexBasis: `${closedDaysOutsideGeofenceWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {closedDoorsEventsOutsideGeofence}
          </Box>
        )}
        {closedDoorsEventsInsideGeofence > 0 && (
          <Box
            className="rounded inline-block align-middle text-center"
            data-testid="closed-days-inside-geofence"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              flexBasis: `${closedDaysInsideGeofenceWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {closedDoorsEventsInsideGeofence}
          </Box>
        )}
      </Box>

      <Box className="justify gap-2">
        <Chip
          label={"Inside Geofence"}
          icon={
            <Circle
              sx={{
                width: "16px",
                fill: "var(--primary-blue)",
              }}
            />
          }
          className="!h-6 !mb-2 !mr-2 !rounded-full !text-white"
          sx={{
            color: "var(--mid-charcoal)",
            backgroundColor: "var(--grayscale-feather-gray)",
            "& .MuiChip-label": {
              color: "var(--mid-charcoal)",
            },
          }}
        />
        <Chip
          label={"Outside Geofence"}
          icon={
            <Circle
              sx={{
                width: "16px",
                fill: "var(--indigo)",
              }}
            />
          }
          className="!h-6 !mb-2 !mr-2 !rounded-full !text-white"
          sx={{
            color: "var(--mid-charcoal)",
            backgroundColor: "var(--grayscale-feather-gray)",
            "& .MuiChip-label": {
              color: "var(--mid-charcoal)",
            },
          }}
        />
      </Box>
    </Grid>
  );
};

export default DoorStatusInLocation;
