import ReactSlidingPane, { ReactSlidingPaneProps } from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./drawer.scss";

export interface DrawerProps extends ReactSlidingPaneProps {
  testId: string;
}

const Drawer = ({ ...props }: DrawerProps) => {
  const defaultProps: ReactSlidingPaneProps = {
    className: "sm:!w-[520px] !w-full !bg-background !text-primary",
    hideHeader: true,
    ...props,
  };

  return (
    <ReactSlidingPane {...defaultProps}>
      <div
        className="h-full flex flex-col"
        data-testid={props.testId ?? "generic-drawer"}
      >
        {props.children}
      </div>
    </ReactSlidingPane>
  );
};

export default Drawer;
