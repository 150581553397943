import { useCallback, useMemo } from "react";
import {
  Brand,
  useGetBrandNamesQuery,
} from "../../../../../../graphql/operations";
import { AutocompleteOption } from "../../../../../../types";
import { DEFAULT_BRAND_NAME } from "../../orgUtils";

interface BrandsHook {
  brandDropdownOptions: AutocompleteOption[];
  defaultBrandForDropdown: string;
  getBrandForDropdown: (brandId: string) => string;
}

export const useBrands = (): BrandsHook => {
  const { data: brandNamesData } = useGetBrandNamesQuery({
    input: {
      detailedData: false,
    },
  });

  const brandNames: Brand[] = useMemo(
    () => (brandNamesData?.getBrands ?? []) as Brand[],
    [brandNamesData?.getBrands]
  );

  const brandDropdownOptions = useMemo(
    () =>
      brandNames?.map((brand) => ({
        id: brand._id,
        label: brand.name,
      })) ?? [],
    [brandNames]
  );
  const defaultBrandForDropdown: string =
    brandNames.find((brand) => brand.name === DEFAULT_BRAND_NAME)?._id ?? "";

  const getBrandForDropdown = useCallback(
    (brandId: string): string => {
      const brand = brandNames.find((brand) => brand._id === brandId);

      return brand?._id ?? "";
    },
    [brandNames]
  );

  return {
    brandDropdownOptions,
    defaultBrandForDropdown,
    getBrandForDropdown,
  };
};
