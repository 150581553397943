import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  CircularProgress,
} from "@mui/material";
import useBreakpoint from "../../hooks/useBreakpoint";
import { Button as SubmitButton, TextButton } from "../Button";
import Text from "../Text";

export interface DeleteTableLayoutDialogProps {
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
  isLoading: boolean;
  selectedTableLayoutName: string;
  processing: boolean;
}

const DeleteTableLayoutDialog: FC<DeleteTableLayoutDialogProps> = ({
  open,
  onDelete,
  onClose,
  isLoading,
  selectedTableLayoutName,
  processing,
}) => {
  const isMobile = useBreakpoint("down", "sm");

  return (
    <Dialog
      data-testid="delete-table-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "32px 24px 24px 24px",
          width: "100%",
          maxWidth: "680px !important",
        },
      }}
    >
      <DialogTitle className="flex justify-between items-center !p-0 !pb-[32px]">
        <Text
          classes="!text-2xl !font-semibold capitalize !text-typography-secondary "
          dataTestId="table-dialog-title"
        >
          Delete Table View
        </Text>
        <IconButton
          aria-label="close"
          onClick={onClose}
          data-testid="table-dialog-close-icon-btn"
          className="h-6 w-6 flex align-center"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="!p-0 !pb-[24px]">
        <DialogContentText
          className="!mb-2 flex items-center text-base !text-typography-secondary"
          id="report-scheduleModal-dialog-heading"
          data-testid="inactive-asset-dialog-content"
        >
          <span className="text-base font-normal">{`Are you sure you want to delete ${selectedTableLayoutName}?`}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: isMobile ? "center" : "end",
          gap: "4px",
          flexWrap: "wrap",
        }}
      >
        <TextButton
          data-testid="cancel-action"
          text="Cancel"
          size="medium"
          theme="blue"
          disabled={isLoading || processing}
          onClick={onClose}
        />

        <SubmitButton
          sx={{ padding: "24px", margin: 0 }}
          dataTestid="submit-action"
          text="Yes"
          icon={
            processing && <CircularProgress size={20} sx={{ color: "white" }} />
          }
          iconPosition="left"
          disabled={isLoading || processing}
          size="medium"
          theme="blue"
          variant="default"
          type="submit"
          onClick={onDelete}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTableLayoutDialog;
