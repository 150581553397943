import { useMemo } from "react";
import { useSearchBatteryChartsDataQuery } from "../../../../../../graphql/operations";
import { formatBatteryChartsData } from "../helpers";
import {
  BATTERY_CHART_NAME,
  BatteryChartsDataHookProps,
  ChartDataResult,
} from "../types";

interface BatteryChartsDataHookResult {
  chartData: ChartDataResult[];
  isLoadingVoltageOsData: boolean;
  isFetchingVoltageOsData: boolean;
  isSuccessVoltageOsData: boolean;
}

export const useBatteryChartsData = ({
  imei,
  startDate,
  endDate,
  uiConfig,
}: BatteryChartsDataHookProps): BatteryChartsDataHookResult => {
  const {
    data: voltageOsData,
    isLoading: isLoadingVoltageOsData,
    isFetching: isFetchingVoltageOsData,
    isSuccess: isSuccessVoltageOsData,
  } = useSearchBatteryChartsDataQuery(
    {
      input: {
        imei,
        startDate,
        endDate,
      },
    },
    {
      enabled: Boolean(imei),
    }
  );

  const data = useMemo(
    () => voltageOsData?.searchEventHistory?.batteryChartsData ?? [],
    [voltageOsData?.searchEventHistory?.batteryChartsData]
  );

  const chartData = useMemo(() => {
    const expectedStartDate = startDate ?? new Date();
    const expectedEndDate = endDate ?? new Date();

    return formatBatteryChartsData(
      BATTERY_CHART_NAME.AllChart,
      expectedStartDate,
      expectedEndDate,
      data,
      uiConfig
    );
  }, [data, startDate, endDate, uiConfig]);

  return {
    chartData,
    isLoadingVoltageOsData,
    isFetchingVoltageOsData,
    isSuccessVoltageOsData,
  };
};
