import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { commonInitialRemindersValues, getAlertCommonSchema } from "../utils";

export const useAlertSpeedForm = (
  initialValues = {
    name: "",
    type: "",
    reminders: commonInitialRemindersValues,
    message: "",
    trigger: null,
  }
) => {
  const alertCommonSchema = getAlertCommonSchema();
  const schema = yup.object().shape({
    ...alertCommonSchema,
    parameters: yup.object().when(["saveAsNewAlertsType"], {
      is: false,
      then: (scheme) =>
        scheme.shape({
          speed: yup
            .number()
            .integer("Value must be an integer")
            .nullable(true)
            .optional()
            .positive("Value should be positive")
            .typeError("Value is required")
            .min(0, "Value should be positive")
            .max(
              Number.MAX_SAFE_INTEGER,
              `Value must be less than or equal to ${Number.MAX_SAFE_INTEGER}`
            ),
        }),
    }),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    values: omitBy(
      {
        ...initialValues,
        saveAsNewAlertsType: false,
        alertTemplateOrgIds: [],
      },
      isNil
    ),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
