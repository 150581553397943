import { ReactNode } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FileFormat } from "../../../../graphql/operations";
import { ReportWithParameters } from "../../../ReportView/interfaces";

export interface ExportFileTypeOption {
  value: FileFormat;
  label: string | ReactNode;
  disabled?: boolean;
}

export const useExportFileTypeOptions = (
  columnsCount: number,
  stringLabel?: boolean
) => {
  const isPdfDisabled = columnsCount >= 20;
  const pdfLabel = stringLabel ? (
    "PDF"
  ) : (
    <span className="flex items-center">
      <span className="mr-4">PDF</span>
      <ErrorOutlineIcon className="mr-2 !text-typography !text-[16px]" />
      <span className="!text-typography !text-xs !normal-case">
        This option is unavailable
      </span>
    </span>
  );
  const options: ExportFileTypeOption[] = [
    { value: FileFormat.Csv, label: "CSV" },
    { value: FileFormat.Excel, label: "Excel" },
    {
      value: FileFormat.Pdf,
      disabled: isPdfDisabled,
      label: isPdfDisabled ? pdfLabel : "PDF",
    },
  ];

  return options;
};
