import { FC, useCallback, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton } from "@mui/material";
import { ThumbnailModal } from "./ThumbnailModal/ThumbnailModal";

interface ReportImageModalProps {
  imageUrl: string;
}

export const ImageModal: FC<ReportImageModalProps> = ({ imageUrl }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openLink = useCallback(() => {
    window.open(imageUrl, "_blank");
  }, [imageUrl]);

  return (
    <>
      <button
        onClick={() => setIsOpenModal(true)}
        tabIndex={0}
        data-testid="open-image-modal-btn"
        className="text-blue text-sm font-semibold"
      >
        View Image
      </button>
      <IconButton
        aria-label="close"
        onClick={openLink}
        className="!ml-2 h-10 w-10"
        data-testid="open-image-btn"
        sx={{ color: "var(--breadcrumbs-filters-border)" }}
      >
        <OpenInNewIcon />
      </IconButton>

      <ThumbnailModal
        imageUrl={imageUrl}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </>
  );
};
