import { FC } from "react";
import {
  AutocompleteElement,
  Control,
  FieldValues,
  TextFieldElement,
  FieldErrors,
} from "react-hook-form-mui";
import { Grid, ThemeProvider } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { USER_FORM_FIELDS } from "../../../../constants/users";
import { PhoneNumberElement } from "../../../../shared/components/react-hook-form-mui/PhoneNumberElement/PhoneNumberElement";

interface UserFormProps {
  form: {
    control: Control<FieldValues>;
    formState: { errors: FieldErrors<FieldValues> };
  };
  availableOrgs: any[];
  roleSelect: any;
  breakpoints: {
    xs: number;
  };
  formTheme: Theme;
  orgOptions: {
    id: any;
    label: string;
  }[];
  isEditMode: boolean;
}

export const UserFormContent: FC<UserFormProps> = ({
  form,
  availableOrgs,
  roleSelect,
  breakpoints,
  formTheme,
  orgOptions,
  isEditMode,
}) => {
  return (
    <ThemeProvider theme={formTheme}>
      <form>
        <Grid container className="bg-background noTopPaddingDrawerSection">
          <Grid
            item
            {...breakpoints}
            data-testid="autocomplete-customer_orgs_id"
          >
            <AutocompleteElement
              loading={!availableOrgs.length}
              rules={{ required: true }}
              matchId={true}
              required
              label={USER_FORM_FIELDS[0].label}
              control={form.control}
              name={USER_FORM_FIELDS[0].name}
              options={orgOptions}
            />
          </Grid>
          <Grid item {...breakpoints} data-testid="form-control-input-lastName">
            <TextFieldElement
              fullWidth
              control={form.control}
              name={USER_FORM_FIELDS[1].name}
              required
              label={USER_FORM_FIELDS[1].label}
            />
          </Grid>
          <Grid
            item
            {...breakpoints}
            data-testid="form-control-input-firstName"
          >
            <TextFieldElement
              fullWidth
              control={form.control}
              name={USER_FORM_FIELDS[2].name}
              required
              label={USER_FORM_FIELDS[2].label}
            />
          </Grid>
          <Grid item {...breakpoints}>
            <TextFieldElement
              disabled={isEditMode}
              fullWidth
              control={form.control}
              name={USER_FORM_FIELDS[3].name}
              required
              label={USER_FORM_FIELDS[3].label}
            />
          </Grid>
          <Grid item {...breakpoints}>
            <TextFieldElement
              fullWidth
              control={form.control}
              name={USER_FORM_FIELDS[4].name}
              required
              label={USER_FORM_FIELDS[4].label}
              data-testid="form-control-input-email"
            />
          </Grid>
          <Grid item {...breakpoints}>
            <PhoneNumberElement
              control={form.control}
              name={USER_FORM_FIELDS[5].name}
              label={USER_FORM_FIELDS[5].label}
              errors={form.formState.errors}
              hint={"Phone number must be 5 to 20 digits"}
            />
          </Grid>
          <Grid item {...breakpoints} data-testid="autocomplete-groups">
            <AutocompleteElement
              loading={!availableOrgs.length}
              rules={{ required: true }}
              matchId={true}
              required
              label={USER_FORM_FIELDS[6].label}
              control={form.control}
              name={USER_FORM_FIELDS[6].name}
              options={roleSelect?.options ?? []}
            />
          </Grid>
        </Grid>
      </form>
    </ThemeProvider>
  );
};
