import { memo, FC } from "react";
import FileDownloadIcon from "@mui/icons-material/DownloadOutlined";
import { Typography, Box } from "@mui/material";

type DialogTemplateProps = {
  text: string;
  downloadTemplateFilePath: string;
};

const DialogTemplate: FC<DialogTemplateProps> = ({
  text,
  downloadTemplateFilePath,
}) => (
  <Box className="bg-background -mt-5">
    <Box
      sx={{ display: "flex", padding: "10px 24px" }}
      className="items-center font-semibold"
    >
      <Typography className="!text-base">{text}</Typography>
      <a href={downloadTemplateFilePath} download>
        <Typography className="!ml-2 text-brand !font-bold">
          Download template
          <FileDownloadIcon fontSize="medium" />
        </Typography>
      </a>
    </Box>
  </Box>
);

export default memo(DialogTemplate);
