import { FC } from "react";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";
import { SensorFilterSliderColors, SensorFiltersNames } from "../../types";
import { DEFAULT_SENSOR_VALUES } from "../../utils";
import BatteryVoltageSlider from "./BatteryVoltageSlider";

const BatterySecondaryVoltageSlider: FC = () => {
  const {
    onChangeFilters,
    currentFilter: { batterySecondaryVoltage },
  } = useAssetsDataContext();

  const onChangeSecondaryVoltage = (newValues: number[]) => {
    const [minVoltage, maxVoltage] = newValues;

    onChangeFilters({
      batterySecondaryVoltage: { minVoltage, maxVoltage },
    });
  };

  return (
    <BatteryVoltageSlider
      onChange={onChangeSecondaryVoltage}
      currentSensor={{
        minValue:
          batterySecondaryVoltage?.minVoltage ??
          DEFAULT_SENSOR_VALUES[SensorFiltersNames.BatterySecondaryVoltage]!
            .min,
        maxValue:
          batterySecondaryVoltage?.maxVoltage ??
          DEFAULT_SENSOR_VALUES[SensorFiltersNames.BatterySecondaryVoltage]!
            .max,
      }}
      maxValue={25}
      marks={[
        {
          value: 0,
          label: `0V`,
        },
        {
          value: 25,
          label: `25V`,
        },
      ]}
      valueBarColor={SensorFilterSliderColors.BatterySecondaryVoltage}
    />
  );
};

export default BatterySecondaryVoltageSlider;
