import axios, { AxiosResponse } from "axios";

export const uploadCompanyLogo = (
  s3Url: string,
  logo: File
): Promise<AxiosResponse> => {
  const config = {
    maxBodyLength: Infinity,
    headers: {
      "Content-Type": logo?.type,
    },
  };
  return axios.put(s3Url, logo, config);
};
