import React, { memo, FC } from "react";
import { nanoid } from "nanoid";
import { ColorsPalette } from "../../../design-system/colors-palette";
import { Axle } from "../../../views/AssetsView/MapView/Assets/TiresTabPanel/components/charts/components/tpms/TiresChartBody";
import { useCurrentTheme } from "../../hooks/theme/useCurrentTheme";

const LightColors = {
  Active: ColorsPalette.MidCharcoal,
  Inactive: ColorsPalette.InactiveTireAxle,
};

const DarkColors = {
  Active: ColorsPalette.InactiveTireAxle,
  Inactive: ColorsPalette.MidCharcoal,
};

const X_POS_WHEELS = [
  7, // left inner wheel
  31, // right inner wheel
  0, // left outer wheel
  38, // right outer wheel
];

interface TiresProps {
  numberOfWheels: number;
  activeAxle: Axle;
  numberOfAxles: number;
}

const Tires: FC<TiresProps> = (props) => {
  const muiTheme = useCurrentTheme();
  const isDarkMode = muiTheme.palette.mode === "dark";
  const colors = isDarkMode ? DarkColors : LightColors;
  const wheelsXpos =
    props.numberOfWheels === 1 ? X_POS_WHEELS.slice(0, 2) : X_POS_WHEELS;

  const AxleOne = (active: boolean) => {
    const color = active ? colors.Active : colors.Inactive;
    const axleOneWheels = wheelsXpos.map((posX, index) => (
      <rect key={nanoid()} x={posX} width="6" height="12" rx="2" fill={color} />
    ));
    return (
      <React.Fragment key={`icon-axle-one`}>
        <g filter="url(#filter0_i_5_47013)">
          <rect
            x="13"
            y="6"
            width="0.999999"
            height="18"
            transform="rotate(-90 13 6)"
            fill={color}
          />
        </g>
        {axleOneWheels}
      </React.Fragment>
    );
  };

  const AxleTwo = (active: boolean) => {
    const color = active ? colors.Active : colors.Inactive;
    const axleTwoWheels = wheelsXpos.map((posX, index) => (
      <rect
        key={nanoid()}
        x={posX}
        y="17"
        width="6"
        height="13"
        rx="2"
        fill={color}
      />
    ));
    return (
      <React.Fragment key={`icon-axle-two`}>
        <g filter="url(#filter1_i_5_47013)">
          <rect
            x="13"
            y="24"
            width="0.999999"
            height="18"
            transform="rotate(-90 13 24)"
            fill={color}
          />
        </g>
        <rect
          x="13.25"
          y="23.75"
          width="0.5"
          height="17.5"
          transform="rotate(-90 13.25 23.75)"
          stroke={color}
          strokeWidth="0.5"
        />
        {axleTwoWheels}
      </React.Fragment>
    );
  };

  const AxleThree = (active: boolean) => {
    const color = active ? colors.Active : colors.Inactive;
    const axleThreeWheels = wheelsXpos.map((posX, index) => (
      <rect
        key={nanoid()}
        x={posX}
        y="35"
        width="6"
        height="13"
        rx="2"
        fill={color}
      />
    ));
    return (
      <React.Fragment key={`icon-axle-three`}>
        <g filter="url(#filter2_i_5_47013)">
          <rect
            x="13"
            y="42"
            width="0.999999"
            height="18"
            transform="rotate(-90 13 42)"
            fill={color}
          />
        </g>
        <rect
          x="13.25"
          y="41.75"
          width="0.5"
          height="17.5"
          transform="rotate(-90 13.25 41.75)"
          stroke={color}
          strokeWidth="0.5"
        />
        {axleThreeWheels}
      </React.Fragment>
    );
  };

  const axles = [
    AxleOne(props.activeAxle === Axle.ONE),
    AxleTwo(props.activeAxle === Axle.TWO),
    AxleThree(props.activeAxle === Axle.THREE),
  ].slice(0, props.numberOfAxles);

  const verticalLineHeight = 18 * (axles.length - 1);

  const filterContent = (
    <>
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="BackgroundImageFix"
        result="shape"
      />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" />
      <feGaussianBlur stdDeviation="2" />
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
      />
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5_47013" />
    </>
  );

  return (
    <svg
      width="44"
      height="48"
      viewBox="0 0 44 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* { vertical line } */}
      <g filter="url(#filter3_i_5_47013)">
        <rect
          x="22"
          y="6"
          width="1"
          height={verticalLineHeight}
          fill="#D7E3F1"
        />
      </g>
      <rect
        x="22.25"
        y="6.25"
        width="0.5"
        height={verticalLineHeight - 0.5}
        stroke="#D7E3F1"
        strokeWidth="0.5"
      />

      {axles}

      <defs>
        <filter
          id="filter0_i_5_47013"
          x="13"
          y="5"
          width="22"
          height="1"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          {filterContent}
        </filter>
        <filter
          id="filter1_i_5_47013"
          x="13"
          y="23"
          width="22"
          height="1"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          {filterContent}
        </filter>
        <filter
          id="filter2_i_5_47013"
          x="13"
          y="41"
          width="22"
          height="1"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          {filterContent}
        </filter>
        <filter
          id="filter3_i_5_47013"
          x="22"
          y="6"
          width="5"
          height={verticalLineHeight}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          {filterContent}
        </filter>
      </defs>
    </svg>
  );
};

export default memo(Tires);
