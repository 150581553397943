import { useMemo, useState } from "react";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { isEqual } from "lodash";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  CreateOrganizationSensorProfileInput,
  LiteSentryGammaSensorConfigInput,
  SensorProfileResult,
  UpdateOrganizationSensorProfileInput,
  LiteSentryGammaThresholds,
} from "../../../../../../graphql/operations";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import { liteSentryGammaDefaultValues } from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import { useFormTheme } from "../../../../../../shared/hooks/theme/useFormTheme";
import { useAvailableOrgs } from "../../../../../../shared/hooks/useAvailableOrgs";
import { useSensorProfilesApi } from "../../../../hooks/useSensorProfilesApi";
import { DrawerType, LiteSentryGammaDrawers } from "../../profileUtils";
import DeleteProfileDialog from "../shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "../shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import {
  ProfileDrawerMainFormValues,
  useProfileDrawerMainForm,
} from "../shared/hooks/useProfileDrawerMainForm";
import LiteSentryGammaAccordion from "./components/LiteSentryGammaAccordion";
import LiteSentryGammaSliderContainer from "./components/LiteSentryGammaSliderContainer";
import { useLiteSentryGammaForm } from "./hooks/useLiteSentryGammaForm";
import { LiteSentryGammaFormValues } from "./types";
import {
  getBrakeCircuitConfigInput,
  getLeftTurnSignalCircuitConfigInput,
  getLicenseCircuitConfigInput,
  getMarkerCircuitConfigInput,
  getRightTurnSignalCircuitConfigInput,
  parseLiteSentryGammaProfiles,
  prepareLiteSentryGammaProfileConfigInput,
} from "./utils/utils";

export type LiteSentryGammaDrawerProps = {
  sensorProfileData: SensorProfileResult | undefined;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
  onRequestClose: () => void;
};

const LiteSentryGammaDrawer: React.FC<LiteSentryGammaDrawerProps> = ({
  sensorProfileData,
  type,
  isOpen,
  onClose,
  onProfileMutation,
  onRequestClose,
}) => {
  const { dispatch } = useAppContext();

  const formTheme = useFormTheme();

  const isCreateMode = useMemo(() => {
    return type === LiteSentryGammaDrawers.Create;
  }, [type]);

  const isEditMode = useMemo(() => {
    return type === LiteSentryGammaDrawers.Edit;
  }, [type]);

  const { liteSentryGammaThresholds, liteSentryGammaSetPoints } =
    parseLiteSentryGammaProfiles(
      sensorProfileData?.configuration
        ?.liteSentryGamma as LiteSentryGammaThresholds
    );

  const {
    brakeCircuitThresholds,
    leftTurnSignalThresholds,
    rightTurnSignalThresholds,
    markerCircuitThresholds,
    licenseCircuitThresholds,
  } = liteSentryGammaThresholds;

  // brake circuit states
  const initialBluePowerPresentValues =
    brakeCircuitThresholds.bluePowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialBluePowerNotPresentValues =
    brakeCircuitThresholds.bluePowerNotPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialBrakeCircuitPrecheckValues =
    brakeCircuitThresholds.precheckThresholds ?? liteSentryGammaDefaultValues;

  const [bluePowerPresentValues, setBluePowerPresentValues] = useState<
    number[]
  >(initialBluePowerPresentValues);

  const [bluePowerNotPresentValues, setBluePowerNotPresentValues] = useState<
    number[]
  >(initialBluePowerNotPresentValues);

  const [brakeCircuitPrecheckValues, setBrakeCircuitPrecheckValues] = useState<
    number[]
  >(initialBrakeCircuitPrecheckValues);

  // left turn signal states
  const initialLeftTurnSignalCircuitTractorPowerPresentValues =
    leftTurnSignalThresholds.tractorPowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialLeftTurnSignalCircuitPrecheckValues =
    leftTurnSignalThresholds.precheckThresholds ?? liteSentryGammaDefaultValues;

  const [
    leftTurnSignalCircuitTractorPowerPresentValues,
    setLeftTurnSignalCircuitTractorPowerPresentValues,
  ] = useState<number[]>(initialLeftTurnSignalCircuitTractorPowerPresentValues);

  const [
    leftTurnSignalCircuitPrecheckValues,
    setLeftTurnSignalCircuitPrecheckValues,
  ] = useState<number[]>(initialLeftTurnSignalCircuitPrecheckValues);

  // right turn signal states
  const initialRightTurnSignalCircuitTractorPowerPresentValues =
    rightTurnSignalThresholds.tractorPowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialRightTurnSignalCircuitPrecheckValues =
    rightTurnSignalThresholds.precheckThresholds ??
    liteSentryGammaDefaultValues;

  const [
    rightTurnSignalCircuitTractorPowerPresentValues,
    setRightTurnSignalCircuitTractorPowerPresentValues,
  ] = useState<number[]>(
    initialRightTurnSignalCircuitTractorPowerPresentValues
  );

  const [
    rightTurnSignalCircuitPrecheckValues,
    setRightTurnSignalCircuitPrecheckValues,
  ] = useState<number[]>(initialRightTurnSignalCircuitPrecheckValues);

  // marker states
  const initialMarkerCircuitTractorPowerPresentValues =
    markerCircuitThresholds.tractorPowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialMarkerCircuitPrecheckValues =
    markerCircuitThresholds.precheckThresholds ?? liteSentryGammaDefaultValues;

  const [
    markerCircuitTractorPowerPresentValues,
    setMarkerCircuitTractorPowerPresentValues,
  ] = useState<number[]>(initialMarkerCircuitTractorPowerPresentValues);

  const [markerCircuitPrecheckValues, setMarkerCircuitPrecheckValues] =
    useState<number[]>(initialMarkerCircuitPrecheckValues);

  // license states
  const initialLicenseCircuitTractorPowerPresentValues =
    licenseCircuitThresholds.tractorPowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialLicenseCircuitPrecheckValues =
    licenseCircuitThresholds.precheckThresholds ?? liteSentryGammaDefaultValues;

  const [
    licenseCircuitTractorPowerPresentValues,
    setLicenseCircuitTractorPowerPresentValues,
  ] = useState<number[]>(initialLicenseCircuitTractorPowerPresentValues);

  const [licenseCircuitPrecheckValues, setLicenseCircuitPrecheckValues] =
    useState<number[]>(initialLicenseCircuitPrecheckValues);

  const [deleteLiteSentryGammaPopupState, setDeleteLiteSentryGammaPopupState] =
    useState<boolean>(false);

  const toggleDeleteLiteSentryGammaProfilePopup = () => {
    setDeleteLiteSentryGammaPopupState(!deleteLiteSentryGammaPopupState);
  };

  const availableOrgs = useAvailableOrgs();

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEditMode) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [availableOrgs, isEditMode, sensorProfileData]);

  const { form: mainForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  const { isValid: isMainFormValid, isDirty: isMainFormDirty } =
    mainForm.formState;

  const { form: liteSentryGammaForm } = useLiteSentryGammaForm(
    liteSentryGammaSetPoints
  );

  const {
    isValid: isLiteSentryGammaFormValid,
    isDirty: isLiteSentryGammaFormDirty,
  } = liteSentryGammaForm.formState;

  const handleLightsProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Light Circuit Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handleLightsProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Light Circuit Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleLightsProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Light Circuit Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleLightsProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Light Circuit Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleLightsProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Light Circuit Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handleLightsProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleLightsProfileCreateSuccess,
    createSensorProfileOnError: handleLightsProfileCreateError,
    updateSensorProfileOnSuccess: handleLightsProfileUpdateSuccess,
    updateSensorProfileOnError: handleLightsProfileUpdateError,
    deleteSensorProfileOnSuccess: handleLightsProfileDeleteSuccess,
    deleteSensorProfileOnError: handleLightsProfileDeleteError,
  });

  const handleSubmitClick = async () => {
    const isMainFormValid = await mainForm.trigger();
    const isLiteSentryGammaFormDataValid = await liteSentryGammaForm.trigger();

    if (!isMainFormValid || !isLiteSentryGammaFormDataValid) return;
    const mainFormData: ProfileDrawerMainFormValues = mainForm.getValues();

    const liteSentryGammaFormData: LiteSentryGammaFormValues =
      liteSentryGammaForm.getValues();

    const {
      orgName: orgId,
      name: profileName,
      default: isDefault,
    } = mainFormData;

    const {
      brakeCircuitSetPoints,
      leftTurnSignalCircuitSetPoints,
      rightTurnSignalCircuitSetPoints,
      markerCircuitSetPoints,
      licenseCircuitSetPoints,
    } = liteSentryGammaFormData;

    const brakeCircuitConfigInput = getBrakeCircuitConfigInput(
      brakeCircuitSetPoints,
      {
        bluePowerPresentThresholds: bluePowerPresentValues,
        bluePowerNotPresentThresholds: bluePowerNotPresentValues,
        precheckThresholds: brakeCircuitPrecheckValues,
      }
    );

    const leftTurnSignalCircuitConfigInput =
      getLeftTurnSignalCircuitConfigInput(leftTurnSignalCircuitSetPoints, {
        tractorPowerPresentThresholds:
          leftTurnSignalCircuitTractorPowerPresentValues,
        precheckThresholds: leftTurnSignalCircuitPrecheckValues,
      });

    const rightTurnSignalCircuitConfigInput =
      getRightTurnSignalCircuitConfigInput(rightTurnSignalCircuitSetPoints, {
        tractorPowerPresentThresholds:
          rightTurnSignalCircuitTractorPowerPresentValues,
        precheckThresholds: rightTurnSignalCircuitPrecheckValues,
      });

    const markerCircuitConfigInput = getMarkerCircuitConfigInput(
      markerCircuitSetPoints,
      {
        tractorPowerPresentThresholds: markerCircuitTractorPowerPresentValues,
        precheckThresholds: markerCircuitPrecheckValues,
      }
    );

    const licenseCircuitConfigInput = getLicenseCircuitConfigInput(
      licenseCircuitSetPoints,
      {
        tractorPowerPresentThresholds: licenseCircuitTractorPowerPresentValues,
        precheckThresholds: licenseCircuitPrecheckValues,
      }
    );

    const liteSentryGammaConfig: LiteSentryGammaSensorConfigInput =
      prepareLiteSentryGammaProfileConfigInput(
        brakeCircuitConfigInput,
        leftTurnSignalCircuitConfigInput,
        rightTurnSignalCircuitConfigInput,
        markerCircuitConfigInput,
        licenseCircuitConfigInput
      );

    if (isCreateMode) {
      const sensorProfileData: CreateOrganizationSensorProfileInput = {
        name: profileName,
        configuration: {
          liteSentryGamma: liteSentryGammaConfig,
        },
        default: isDefault ?? false,
        orgId: orgId ?? "",
      };
      createSensorProfile(sensorProfileData);
    } else if (isEditMode) {
      const updateSensorProfilePayload: UpdateOrganizationSensorProfileInput = {
        _id: sensorProfileData!._id,
        orgId: sensorProfileData!.orgId,
      };
      if (profileName !== sensorProfileData?.name) {
        updateSensorProfilePayload.name = profileName;
      }
      if (isDefault !== sensorProfileData?.default) {
        updateSensorProfilePayload.default = isDefault;
      }
      if (orgId !== sensorProfileData?.orgId) {
        updateSensorProfilePayload.orgId = orgId;
      }
      updateSensorProfilePayload.configuration = {
        liteSentryGamma: liteSentryGammaConfig,
      };
      updateSensorProfile(updateSensorProfilePayload);
    }
  };

  const handleDeleteLiteSentryGammaProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const handleClose = () => onClose(false);

  const isLoadingOrUpdating =
    isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile;
  const isInvalid =
    isLoadingOrUpdating || !isMainFormValid || !isLiteSentryGammaFormValid;

  const isBrakeCircuitPristine =
    isEqual(bluePowerPresentValues, initialBluePowerPresentValues) &&
    isEqual(bluePowerNotPresentValues, initialBluePowerNotPresentValues) &&
    isEqual(brakeCircuitPrecheckValues, initialBrakeCircuitPrecheckValues);

  const isLeftTurnSingalPristine =
    isEqual(
      leftTurnSignalCircuitTractorPowerPresentValues,
      initialLeftTurnSignalCircuitTractorPowerPresentValues
    ) &&
    isEqual(
      leftTurnSignalCircuitPrecheckValues,
      initialLeftTurnSignalCircuitPrecheckValues
    );

  const isRightTurnSignalPristine =
    isEqual(
      rightTurnSignalCircuitTractorPowerPresentValues,
      initialRightTurnSignalCircuitTractorPowerPresentValues
    ) &&
    isEqual(
      rightTurnSignalCircuitPrecheckValues,
      initialRightTurnSignalCircuitPrecheckValues
    );

  const isMarkerCircuitPristine =
    isEqual(
      markerCircuitTractorPowerPresentValues,
      initialMarkerCircuitTractorPowerPresentValues
    ) &&
    isEqual(markerCircuitPrecheckValues, initialMarkerCircuitPrecheckValues);

  const isLicenseCircuitPristine =
    isEqual(
      licenseCircuitTractorPowerPresentValues,
      initialLicenseCircuitTractorPowerPresentValues
    ) &&
    isEqual(licenseCircuitPrecheckValues, initialLicenseCircuitPrecheckValues);

  const isEditAndPristine =
    isEditMode &&
    !isMainFormDirty &&
    !isLiteSentryGammaFormDirty &&
    isBrakeCircuitPristine &&
    isLeftTurnSingalPristine &&
    isRightTurnSignalPristine &&
    isMarkerCircuitPristine &&
    isLicenseCircuitPristine;

  const text = isEditMode ? "Edit Profile" : "Create Profile";

  return (
    <Drawer
      testId="lite-sentry-gamma-profile-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={text} onClose={handleClose} />

      <DrawerContent>
        <ThemeProvider theme={formTheme}>
          <Box className="h-full flex flex-col justify-between">
            <Box>
              <ProfilesDrawerMainForm
                form={mainForm}
                disabled={isLoadingOrUpdating}
                isEdit={isEditMode}
              />

              <Grid
                container
                className="drawerSection"
                spacing={6}
                direction="column"
              >
                <LiteSentryGammaAccordion
                  accordionTitle="Brake Circuit Settings"
                  dataTestId="brake-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Blue Power Present"
                      control={liteSentryGammaForm.control}
                      values={bluePowerPresentValues}
                      name="brakeCircuitSetPoints.bluePowerPresentSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={setBluePowerPresentValues}
                    />
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Blue Power Not Present"
                      control={liteSentryGammaForm.control}
                      values={bluePowerNotPresentValues}
                      name="brakeCircuitSetPoints.bluePowerNotPresentSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={setBluePowerNotPresentValues}
                    />
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={brakeCircuitPrecheckValues}
                      name="brakeCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={setBrakeCircuitPrecheckValues}
                    />
                  </>
                </LiteSentryGammaAccordion>

                <LiteSentryGammaAccordion
                  accordionTitle="Left Turn Signal Circuit Settings"
                  dataTestId="left-turn-signal-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Tractor Power Present"
                      control={liteSentryGammaForm.control}
                      values={leftTurnSignalCircuitTractorPowerPresentValues}
                      name="leftTurnSignalCircuitSetPoints.tractorPowerPresentSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={
                        setLeftTurnSignalCircuitTractorPowerPresentValues
                      }
                    />

                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={leftTurnSignalCircuitPrecheckValues}
                      name="leftTurnSignalCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={setLeftTurnSignalCircuitPrecheckValues}
                    />
                  </>
                </LiteSentryGammaAccordion>

                <LiteSentryGammaAccordion
                  accordionTitle="Right Turn Signal Circuit Settings"
                  dataTestId="right-turn-signal-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Tractor Power Present"
                      control={liteSentryGammaForm.control}
                      values={rightTurnSignalCircuitTractorPowerPresentValues}
                      name="rightTurnSignalCircuitSetPoints.tractorPowerPresentSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={
                        setRightTurnSignalCircuitTractorPowerPresentValues
                      }
                    />

                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={rightTurnSignalCircuitPrecheckValues}
                      name="rightTurnSignalCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={setRightTurnSignalCircuitPrecheckValues}
                    />
                  </>
                </LiteSentryGammaAccordion>

                <LiteSentryGammaAccordion
                  accordionTitle="Marker Circuit Settings"
                  dataTestId="marker-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Tractor Power Present"
                      control={liteSentryGammaForm.control}
                      values={markerCircuitTractorPowerPresentValues}
                      name="markerCircuitSetPoints.tractorPowerPresentSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={setMarkerCircuitTractorPowerPresentValues}
                    />

                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={markerCircuitPrecheckValues}
                      name="markerCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={setMarkerCircuitPrecheckValues}
                    />
                  </>
                </LiteSentryGammaAccordion>

                <LiteSentryGammaAccordion
                  accordionTitle="License Circuit Settings"
                  dataTestId="license-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Tractor Power Present"
                      control={liteSentryGammaForm.control}
                      values={licenseCircuitTractorPowerPresentValues}
                      name="licenseCircuitSetPoints.tractorPowerPresentSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={setLicenseCircuitTractorPowerPresentValues}
                    />
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={licenseCircuitPrecheckValues}
                      name="licenseCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoadingOrUpdating}
                      onChange={setLicenseCircuitPrecheckValues}
                    />
                  </>
                </LiteSentryGammaAccordion>
              </Grid>
            </Box>

            <DrawerActions
              cancelBtnTestId="lite-sentry-gamma-profile-drawer-cancel"
              deleteBtnTestId="btn-delete-lite-sentry-gamma-profile"
              showDeleteBtn={isEditMode}
              disabled={isLoadingOrUpdating}
              onCancel={handleClose}
              onDelete={toggleDeleteLiteSentryGammaProfilePopup}
            />

            {deleteLiteSentryGammaPopupState && isEditMode && (
              <DeleteProfileDialog
                isOpen={deleteLiteSentryGammaPopupState}
                onClose={toggleDeleteLiteSentryGammaProfilePopup}
                isLoading={isLoadingDeleteSensorProfile}
                onClick={handleDeleteLiteSentryGammaProfile}
                testId="lite-sentry-gamma"
              />
            )}
          </Box>
        </ThemeProvider>
      </DrawerContent>

      <DrawerFooter
        text={isLoadingOrUpdating ? "Saving..." : "Save"}
        disabled={isInvalid || isEditAndPristine}
        testId="btn-lite-sentry-gamma-profile-form-submit"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default LiteSentryGammaDrawer;
