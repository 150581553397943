import { NOMENCLATURE_NAMES } from "../../../AssetsView/TableView/hooks";

export interface ParsedZonesData {
  unitedStates: ZoneGroupData;
  canada: ZoneGroupData;
  mexico: ZoneGroupData;
}

export interface ZoneGroupData {
  data: ZoneData[];
}

export interface ZoneData {
  id: string;
  name: string;
  country: ZoneCountry;
  matches: string[];
  ranges: string[][];
}

export interface RegionOptionType {
  id: string;
  label: string;
}

export interface TerritoryInUse {
  regionName: string;
  territoryName: string;
}

export enum ZoneCountry {
  UnitedStates = "unitedStates",
  Canada = "canada",
  Mexico = "mexico",
}

export const countryToTerritoriesNomenclatures: Record<
  ZoneCountry,
  NOMENCLATURE_NAMES
> = {
  [ZoneCountry.UnitedStates]: NOMENCLATURE_NAMES.territoriesUSA,
  [ZoneCountry.Canada]: NOMENCLATURE_NAMES.territoriesCanada,
  [ZoneCountry.Mexico]: NOMENCLATURE_NAMES.territoriesMexico,
};

export const countryToDisplayName: Record<ZoneCountry, string> = {
  [ZoneCountry.UnitedStates]: "United States",
  [ZoneCountry.Canada]: "Canada",
  [ZoneCountry.Mexico]: "Mexico",
};
