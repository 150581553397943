import { useMemo } from "react";
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import { capitalize, round } from "lodash";
import {
  AssetTableData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import { BatteryIcon } from "../../../../../shared/components/BatteryIcon";
import {
  getTableColumn,
  TableColumnProps,
  TableGridColDef,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { NOMENCLATURE_NAMES, useNomenclatures } from "../../hooks";
import { AssetIcon, getIconType } from "../AssetIcon";

export enum IconTypeComplete {
  EMPTY,
  MOVING,
  LOW_DWELL,
  MEDIUM_DWELL,
  HIGH_DWELL,
  MEGA_DWELL,
  MOVING_ONLINE,
  LOW_DWELL_ONLINE,
  MEDIUM_DWELL_ONLINE,
  HIGH_DWELL_ONLINE,
  MEGA_DWELL_ONLINE,
  MOVING_EMPTY,
  LOW_DWELL_EMPTY,
  MEDIUM_DWELL_EMPTY,
  HIGH_DWELL_EMPTY,
  MEGA_DWELL_EMPTY,
  MOVING_ONLINE_EMPTY,
  LOW_DWELL_ONLINE_EMPTY,
  MEDIUM_DWELL_ONLINE_EMPTY,
  HIGH_DWELL_ONLINE_EMPTY,
  MEGA_DWELL_ONLINE_EMPTY,
  MOVING_LOADED,
  LOW_DWELL_LOADED,
  MEDIUM_DWELL_LOADED,
  HIGH_DWELL_LOADED,
  MEGA_DWELL_LOADED,
  MOVING_ONLINE_LOADED,
  LOW_DWELL_ONLINE_LOADED,
  MEDIUM_DWELL_ONLINE_LOADED,
  HIGH_DWELL_ONLINE_LOADED,
  MEGA_DWELL_ONLINE_LOADED,
}

export const columnVisibilityModel = {
  name: true,
  assetIdentifier: true,
  imei: true,
  associated: true,
  orgName: true,
  productName: true,
  category: true,
  city: true,
  state: true,
  zip: false,
  vin: false,
  tags: false,
  batteryStatus: true,
  dwellingDays: true,
  doorType: false,
  tripStatus: true,
  cargoState: true,
  batteryVolt: true,
  length: false,
  lastEventTime: true,
  lastGpsTime: false,
  numOfTires: false,
  numOfAxles: false,
  lon: false,
  lat: false,
  signal: false,
  shareType: false,
  shareOrganizationName: false,
  shareGroupName: false,
  shareStartDate: false,
  shareEndDate: false,
};

export const getIconValue = (
  params: GridValueGetterParams<any, AssetTableData>
) => {
  const iconType = getIconType(
    params.row.tripStatus,
    params.row.dwellingDays,
    params.row.signal,
    params.row.cargoState
  );
  const value = IconTypeComplete[iconType as keyof typeof IconTypeComplete];
  return value;
};

export const CategoryFormatter = ({
  params,
}: {
  params: GridRenderCellParams;
}) => {
  const nomenclatures = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const result = useMemo(() => {
    const value = params.value;
    if (!value) return "";
    const nomenclature = nomenclatures.find(
      (nomenclature) => nomenclature.value === value
    );
    return nomenclature ? nomenclature.label : value;
  }, [nomenclatures, params.value]);
  return result;
};

export const getDefaultAssetsListColumns = (
  timezone: string
): TableColumnProps<AssetTableData>[] => {
  const columns: TableColumnProps<AssetTableData>[] = [
    { field: "orgName", headerName: "Organization Name", order: 1 },
    {
      field: "icon",
      headerName: "Icon",
      order: 2,
      options: {
        valueGetter: getIconValue,
        renderCell: (params) => (
          <AssetIcon
            dwellingDays={Number(params.row.dwellingDays)}
            signal={Boolean(params.row.signal)}
            cargo_state={String(params.row.cargoState)}
            trip_st={String(params.row.tripStatus)}
          />
        ),
      },
    },
    {
      field: "batteryVolt",
      headerName: "Battery Status",
      order: 3,
      options: {
        renderCell: (params) => (
          <BatteryIcon
            chargeLevel={params.value ? round(params.value, 2) : undefined}
            svgProps={{ width: "24px", height: "24px" }}
            status={
              params.row.batteryStatus
                ? params.row.batteryStatus.toLowerCase()
                : ""
            }
          />
        ),
      },
    },
    { field: "assetIdentifier", headerName: "Asset ID", order: 4 },
    {
      field: "name",
      headerName: "Nickname",
      order: 5,
    },
    { field: "imei", headerName: "Device ID", order: 6 },
    { field: "productName", headerName: "Product Name", order: 7 },
    {
      field: "lastEventTime",
      headerName: "Last Reported Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      order: 8,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    { field: "associated", headerName: "Associated", order: 9 },
    {
      field: "tripStatus",
      headerName: "Trip Status",
      order: 10,
      options: {
        renderCell: (params) => capitalize(params.row.tripStatus),
      },
    },
    { field: "city", headerName: "City", order: 12 },
    { field: "state", headerName: "State", order: 13 },
    { field: "zip", headerName: "Zip", order: 14 },
    {
      field: "category",
      headerName: "Category",
      order: 15,
      options: {
        renderCell: (params) => <CategoryFormatter params={params} />,
      },
    },
    { field: "tags", headerName: "Asset Tags", order: 16 },
    { field: "lat", headerName: "Latitude", order: 17 },
    { field: "lon", headerName: "Longitude", order: 18 },
    { field: "vin", headerName: "VIN", order: 19 },
    { field: "numOfTires", headerName: "# of Tires", order: 20 },
    { field: "numOfAxles", headerName: "# of Axles", order: 21 },
    { field: "length", headerName: "Length", order: 22 },
    { field: "doorType", headerName: "Door Type", order: 23 },
    {
      field: "lastGpsTime",
      headerName: "GPS Time",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      order: 24,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "shareType",
      headerName: "Share Type",
      order: 25,
    },
    {
      field: "shareOrganizationName",
      headerName: "Share Organization",
      order: 26,
    },
    {
      field: "shareGroupName",
      headerName: "Share Group Name",
      order: 27,
    },
    {
      field: "shareStartDate",
      headerName: "Share Start Date",
      order: 28,
    },
    {
      field: "shareEndDate",
      headerName: "Share End Date",
      order: 29,
    },
  ];

  return columns;
};

export const getColumns = (
  timezone: string
): TableGridColDef<AssetTableData>[] => {
  const columns: TableColumnProps<AssetTableData>[] = [
    ...getDefaultAssetsListColumns(timezone),
    {
      field: "dwellingDays",
      headerName: "Dwell",
      order: 11,
      options: {
        renderCell: (params) => (params.row.dwellingDays ?? "") + " days",
        sortable: false,
        filterable: false,
      },
    },
  ];

  return columns
    .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))
    .map(getTableColumn);
};
