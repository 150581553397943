import { FC } from "react";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import { ReactComponent as TPMSSensorDark } from "../../../../../../../../assets/svgs/tpmsSensorDark.svg";
import { ReactComponent as TPMSSensorLight } from "../../../../../../../../assets/svgs/tpmsSensorLight.svg";
import { useAppContext } from "../../../../../../../../context/AppContext";
import { useDateInPreferredTimezone } from "../../../../../../../../shared/hooks/useDateInPreferredTimezone";
import { useGetSensorStateWidget } from "../../../../AssetShortTooltip/hooks/useGetSensorStateWidget";
import {
  WHEEL_END_CARD_HEIGHT,
  defaultBorderStyling,
} from "../../../constants";
import { useTiresTabContext } from "../../../context";
import { PsiWheelEndSummaryData } from "../../../interfaces";

export interface TireSummaryCardProps extends PsiWheelEndSummaryData {}

const TireSummaryCard: FC<TireSummaryCardProps> = ({
  axle,
  wheelEnd,
  status,
  temperature,
  lastReported,
}) => {
  const { getTireSensorChip } = useGetSensorStateWidget();
  const timezoneDate = useDateInPreferredTimezone(lastReported);

  const statusDetails = getTireSensorChip(status, {
    iconWidth: "16px",
    iconHeight: "16px",
  });
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "3.125rem",
    height: "3.125rem",
    display: "block",
    marginTop: "0.75rem",
  };

  const {
    selectedPsiWheelEndAxle: selectedAxle,
    setSelectedPsiWheelEndAxle: setSelectedAxle,
    selectedPsiWheelEnd: selectedWheelEnd,
    setSelectedPsiWheelEnd: setSelectedWheelEnd,
  } = useTiresTabContext();

  const isWheelEndSelected =
    selectedAxle === axle && selectedWheelEnd === wheelEnd;

  const handleCardClick = () => {
    if (isWheelEndSelected) {
      setSelectedAxle(undefined);
      setSelectedWheelEnd(undefined);
    } else {
      setSelectedAxle(axle);
      setSelectedWheelEnd(wheelEnd);
    }
  };

  return (
    <Card
      className={`background-white border min-w-[280px] cursor-pointer`}
      sx={{
        boxShadow: "none",
        height: WHEEL_END_CARD_HEIGHT,
        border: defaultBorderStyling,
        borderRadius: "8px",
        borderColor: isWheelEndSelected ? "var(--primary-blue)" : "var(--gray)",
      }}
      onClick={handleCardClick}
      data-testid="asset-dashboard--tires-tab--psi-wheel-end--card-summary"
    >
      <CardHeader
        title={
          <div className="flex">
            {isLightTheme ? (
              <TPMSSensorDark style={svgIconSettings} />
            ) : (
              <TPMSSensorLight style={svgIconSettings} />
            )}

            <div className="ml-4">
              <Typography
                className="text-brand"
                sx={{
                  fontSize: "18px",
                  lineHeight: "26px",
                  fontWeight: 600,
                }}
              >
                {axle}
              </Typography>
              <Typography
                className="text-card__typography"
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 600,
                }}
              >
                {wheelEnd}
              </Typography>
            </div>
          </div>
        }
      ></CardHeader>
      <CardContent>
        <Typography
          className="text-card__typography"
          sx={{ marginBottom: "10px" }}
        >
          {statusDetails.label}
          <span className="ml-2">{statusDetails.icon}</span>
        </Typography>
        <Typography
          className="text-base text-card__typography"
          sx={{ marginBottom: "10px" }}
        >
          Temperature:
          {temperature && (
            <span className="ml-1 font-bold">{temperature}F</span>
          )}
        </Typography>
        <Typography
          className="text-base text-card__typography"
          sx={{ marginBottom: "10px" }}
        >
          Last Reported:
          <span className="ml-1">{timezoneDate}</span>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TireSummaryCard;
