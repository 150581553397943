import React, { FC, ReactNode } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  SxProps,
  Typography,
} from "@mui/material";
import { RequiredFlag } from "../../RequiredFlag";
import { SelectComponent } from "../../SelectComponent";

interface SelectElementProps {
  name: string;
  control: Control<any>;
  label?: string;
  rules?: ControllerProps["rules"];
  labelClassName?: string;
  placeholder?: string;
  required?: boolean;
  options: { value: string; label: string | ReactNode; disabled?: boolean }[];
  dataTestId?: string;
  formControlSx?: SxProps;
  multiple?: boolean;
  renderCheckMarks?: boolean;
  disabled?: boolean;
  handleChange?: (value: string) => void;
}

export const SelectElement: FC<SelectElementProps> = ({
  label,
  rules,
  name,
  placeholder,
  control,
  labelClassName = "!mb-2 !text-sm !font-bold",
  required,
  options,
  multiple,
  renderCheckMarks,
  formControlSx,
  disabled,
  handleChange,
  dataTestId,
}) => {
  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error, invalid },
      }) => (
        <FormControl
          sx={formControlSx}
          fullWidth
          required={required}
          error={invalid}
          disabled={disabled}
        >
          {label && (
            <Typography
              className={labelClassName}
              sx={{ color: "var(--form-label-default)" }}
              data-testid="select-element-label"
            >
              {label}
              {rules?.required && <RequiredFlag />}
            </Typography>
          )}
          <SelectComponent
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              handleChange?.(e.target.value);
            }}
            options={options}
            placeholder={placeholder}
            multiple={multiple}
            renderCheckMarks={renderCheckMarks}
            name={name}
            dataTestId={dataTestId}
          />

          {error?.message && (
            <FormHelperText
              className="!h-fit"
              error={true}
              sx={{
                position: "relative",
                "::before": { top: "50%", transform: "translateY(-50%)" },
              }}
            >
              {error?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
