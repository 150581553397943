import React, { FC, memo } from "react";
import {
  Control,
  Controller,
  ControllerProps,
  UseFormReturn,
} from "react-hook-form";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Select,
  Typography,
} from "@mui/material";
import { ScheduleFormFields } from "../../../../views/ReportView/interfaces";
import { FrequencySelect, FrequencySelectProps } from "../../FrequencySelect";
import { RequiredFlag } from "../../RequiredFlag";

export type FrequencySelectElementProps = Pick<
  FrequencySelectProps,
  "loading"
> & {
  label?: string;
  rules?: ControllerProps["rules"];
  name: string;
  control: Control<any>;
  labelClassName?: string;
  required?: boolean;
  form: UseFormReturn<Partial<ScheduleFormFields>>;
  handleScheduleReportSubmit: () => void;
  disabled?: boolean;
};

export const FrequencySelectElement: FC<FrequencySelectElementProps> = memo(
  ({
    label,
    rules,
    name,
    control,
    labelClassName = "!mb-2 !text-sm !font-bold",
    required,
    handleScheduleReportSubmit,
    disabled = false,
    ...rest
  }: FrequencySelectElementProps) => {
    return (
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error, invalid },
        }) => (
          <FormControl
            fullWidth
            required={required}
            variant="standard"
            error={invalid}
          >
            {label && (
              <Typography
                className={labelClassName}
                data-testid="frequency-select-element-label"
              >
                {label}
                {rules?.required && <RequiredFlag />}
              </Typography>
            )}
            {!rest.loading ? (
              <FrequencySelect
                {...rest}
                values={[value]}
                onChange={onChange}
                onBlur={onBlur}
                invalid={invalid}
                required={required}
                handleScheduleReportSubmit={handleScheduleReportSubmit}
                disabled={disabled}
              />
            ) : (
              <Box>
                <Box
                  className="absolute bottom-0 right-6"
                  data-testid="frequency-select-loader"
                >
                  {rest.loading && <CircularProgress size={20} />}
                </Box>
                <Select fullWidth disabled value={""} />
              </Box>
            )}

            {error?.message && (
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    );
  }
);
