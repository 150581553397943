import React, { RefObject, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Typography } from "@mui/material";
import { getS3SignedDownloadUrl } from "../../../../../services/aws/aws";

const allowedLogoTypes: string[] = ["image/jpg", "image/jpeg", "image/png"];

const UploadLogo = (props: any) => {
  const { onFileInput, disableFileInput = false, company_logos = [] } = props;
  const [selectedLogo, setSelectedLogo] = useState<any>(null);
  const [selectedLogoUrl, setSelectedLogoUrl] = useState<string>("");
  const fileInputRef: RefObject<HTMLInputElement> = useRef(null);

  React.useEffect(() => {
    setSelectedLogo(company_logos[0] ? { logo: company_logos[0] } : null);
    if (company_logos && company_logos[0]) {
      const logoUrl = getS3SignedDownloadUrl({
        bucket: company_logos[0]?.bucket,
        key: company_logos[0]?.key,
        contentType: company_logos[0]?.contentType,
      });
      setSelectedLogoUrl(logoUrl);
    }
  }, [company_logos]);

  // This below useEffect block is requiredto restrict browser to open new tab when dropping image file
  React.useEffect(() => {
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );
  }, []);

  const handleLogoSelect = (event: any) => {
    const logoSelected = event?.target?.files[0] || event;
    if (logoSelected) {
      if (!allowedLogoTypes.includes(logoSelected?.type)) {
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(logoSelected);
      reader.onloadend = (e) => {
        setSelectedLogo({ file: logoSelected, preview: reader?.result });
      };

      if (onFileInput) {
        onFileInput({
          file: logoSelected,
        });
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = null as any;
      }
      setSelectedLogo(company_logos[0] ? { logo: company_logos[0] } : null);
      if (onFileInput) {
        onFileInput(null);
      }
    }
  };

  const handleLogoDrop = (event: any) => {
    if (disableFileInput) {
      return;
    }
    const fileDropped = event.dataTransfer.files[0];
    if (allowedLogoTypes.includes(fileDropped?.type)) {
      handleLogoSelect(fileDropped);
    }
  };

  return (
    <Grid container sx={{ p: 3 }} data-testid="wrapper">
      <Grid item>
        <Typography sx={{ fontWeight: "600", marginBottom: "0.75rem" }}>
          Upload logo
        </Typography>
        <Typography
          sx={{ fontSize: "16px", lineHeight: 1.25, marginBottom: "0.75rem" }}
        >
          Images must be in either jpg, jpeg, or png format.
        </Typography>
      </Grid>
      <Grid item container sx={{ pt: 3, pb: 1 }}>
        <div
          className="drag-and-drop-area"
          onDragOver={(event) => {
            event.preventDefault();
          }}
          onDrop={handleLogoDrop}
          data-testid="logo_drop"
          style={{
            background: "rgba(25, 137, 210,0.15)",
            border: "1px dashed rgba(25, 137, 210, 1)",
            borderRadius: "0.5rem",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "150px",
          }}
        >
          <div className="d-block text-center">
            <Typography sx={{ textAlign: "center", mb: 1 }}>
              Drag & drop your file(s) here
            </Typography>
            <Typography sx={{ textAlign: "center", mb: 2 }}>or</Typography>
            <label
              htmlFor="logo_upload"
              style={{
                padding: "0.5rem 1.5rem",
                fontWeight: "600",
                borderRadius: "24px",
                color: "var(--white)",
                background: "var(--brand)",
                cursor: "pointer",
              }}
            >
              <input
                ref={fileInputRef}
                type="file"
                name="upload_logo"
                id="logo_upload"
                data-testid="logo_upload"
                style={{ width: 0, height: 0 }}
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleLogoSelect}
                disabled={disableFileInput}
              />
              Browse
            </label>
          </div>
        </div>
      </Grid>
      {(!!selectedLogo || !!company_logos.length) && (
        <Grid item container>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              columnGap: "0.75rem",
            }}
          >
            <div
              className="col logo-preview"
              style={{
                width: "42px",
                height: "42px",
                backgroundImage: `url(${
                  selectedLogo?.preview || selectedLogoUrl
                })`,
                backgroundSize: "contain",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                boxShadow: selectedLogo?.preview
                  ? "none"
                  : "0px 0px 6px rgba(0, 0, 0, 0.5)",
              }}
            ></div>
            <div
              className="col text-ellipsis"
              style={{
                flexGrow: 1,
                wordBreak: "break-word",
              }}
            >
              {selectedLogo?.file?.name || selectedLogo?.logo?.file_name}
            </div>
            {selectedLogo?.preview && (
              <div className="col">
                <IconButton
                  type="button"
                  onClick={() => handleLogoSelect(null)}
                  data-testid="close-button"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default UploadLogo;
