import React, { PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import "./text.scss";

export type TextProps = PropsWithChildren & {
  noWrap?: boolean;
  align?: "left" | "right" | "center";
  classes?: string;
  variant?: Variant;
  fontWeight?: "bold" | "regular";
  color?: string;
  fontSize?: number;
  dataTestId?: string;
};

const Text = (props: TextProps) => {
  const {
    noWrap = false,
    align = "left",
    classes = "",
    variant = "inherit",
    fontSize = 12,
    fontWeight = "bold",
    color = "black",
    dataTestId = "text-style-wrapper",
    children,
  } = props;

  const classNames = `global-text ${classes} text-align--${align}  text-style__variant--${variant} text-style__font-weight--${fontWeight}`;
  return (
    <Typography
      data-testid={dataTestId}
      noWrap={noWrap}
      className={classNames}
      variant={variant}
      sx={{
        fontSize,
        color,
      }}
    >
      {children || null}
    </Typography>
  );
};

export default Text;
