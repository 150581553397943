import { FC, MutableRefObject, useMemo } from "react";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Box } from "@mui/material";
import {
  DataGridPremiumProps,
  GridRenderCellParams,
  GridColDef,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useGetConfigurationSetsQuery,
  ConfigurationSetType,
  ConfigurationSet,
  TableViewType,
} from "../../../../../../graphql/operations";
import ColorPreviewComponent from "../../../../../../shared/components/ColorPreview/ColorPreview.component";
import { CustomGridTreeDataGroupingCell } from "../../../../../../shared/components/CustomGridTreeDataGroupingCell/CustomGridTreeDataGroupingCell";
import { Table } from "../../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../../shared/hooks/useExportedFileName";
import { formatDate } from "../../../../../../utils";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";

export interface GeofenceTypesTableProps {
  apiRef: MutableRefObject<GridApiPremium>;
  orgId: string | undefined;
  onRowClick: (data: any) => void;
}

export type ConfigurationSetData = Partial<
  ConfigurationSet & {
    hierarchy: string[];
    id: string;
    geofenceName: string;
    parentId: string;
  }
>;
//export function getParents
const GeofenceTypesTable: FC<GeofenceTypesTableProps> = ({
  apiRef,
  orgId,
  onRowClick,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();

  const columns: GridColDef<ConfigurationSetData>[] = [
    {
      flex: 1,
      field: "geofenceName",
      headerName: "Geofence Type / Parent",
      minWidth: 200,
    },
    {
      field: "geofenceColor",
      headerName: "Geofence Color",
      renderCell: (params: any) => (
        <ColorPreviewComponent hexColor={params.value} />
      ),
      flex: 1,
      minWidth: 120,
    },
    {
      flex: 1,
      field: "createdDate",
      headerName: "Created Date",
      minWidth: 120,
      type: "date",
      valueFormatter: (params: any) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "updatedDate",
      headerName: "Updated Date",
      minWidth: 120,
      type: "date",
      valueFormatter: (params: any) => formatDate(params.value),
    },
  ];

  const fileName: string = useExportedFileName("GeofenceTypes");

  const {
    data: dataConfigurations,
    isError: isErrorConfiguration,
    // using isFetching because for some reason isLoading stucks on 'true'
    isFetching: isFetchingConfiguration,
  } = useGetConfigurationSetsQuery(
    {
      input: {
        orgId: orgId ?? "",
        type: ConfigurationSetType.GeofenceType,
        includeSuborgs: true,
      },
    },
    {
      enabled: Boolean(orgId),
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keysList = useMemo(() => columns.map((column) => column.field), []);
  const dataParsed = useMemo(() => {
    const data: ConfigurationSetData[] =
      // get the needed data for the table
      dataConfigurations?.getConfigurationSets?.map((singleSet) => {
        const singleSetCopy = singleSet as any;
        const valuePropertyParsed =
          singleSet?.value && JSON.parse(singleSet?.value);

        return {
          id: singleSetCopy._id,
          parentId: singleSetCopy.parentId,
          geofenceName: singleSetCopy.name,
          geofenceColor: valuePropertyParsed.color,
          createdDate: singleSetCopy.createdAt,
          updatedDate: singleSetCopy.updatedAt,
          isDisabledForOrganization: Boolean(
            valuePropertyParsed?.disabledForOrganizations?.includes(orgId)
          ),
        };
      }) ?? [];

    // get rows hierarchy by the parentId field
    if (data) {
      const itemsById = Object.fromEntries(
        data.map((item: ConfigurationSetData) => [item.id, item])
      );

      // For every geofence config set, check if parent is available &
      // call itself to get full path for hierarchy of nested config sets
      const recurse = (
        item: ConfigurationSetData,
        path: any[] = []
      ): string[] => {
        path.unshift(item.geofenceName);
        const parent = itemsById[item?.parentId ?? ""];
        return parent ? recurse(parent, path) : path;
      };

      data.forEach((item: ConfigurationSetData, index: number) => {
        data[index]["hierarchy"] = recurse(item);
      });
    }

    return data;
  }, [dataConfigurations?.getConfigurationSets, orgId]);

  const groupingColDef: DataGridPremiumProps["groupingColDef"] = {
    headerName: "Geofence Type / Parent",
    flex: 1,
    minWidth: 120,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <CustomGridTreeDataGroupingCell
          params={params}
          icon={
            params.row.isDisabledForOrganization && (
              <NotInterestedIcon color="error" />
            )
          }
        />
      );
    },
  };
  const getTreeDataPath: DataGridPremiumProps["getTreeDataPath"] = (row: any) =>
    row.hierarchy;

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={TableViewType.GeofenceTypesConfigurations}
        showToolbar
        treeData
        groupingColDef={groupingColDef}
        getTreeDataPath={getTreeDataPath}
        rows={dataParsed ?? []}
        apiRef={apiRef}
        columnVisibilityModel={{
          geofenceName: false,
        }}
        onRowClick={onRowClick}
        columns={columns}
        loading={isFetchingConfiguration}
        error={isErrorConfiguration}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={keysList}
        sortKeys={keysList}
        searchMinLength={appConfig.searchMinLength}
        tableName="geofence-types-config"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        isDataRefetching={false}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default GeofenceTypesTable;
