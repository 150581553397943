import {
  PAGE_DIALOG,
  PAGE_SNACKBAR,
  PAGE_SPINNER,
  PAGE_SPINNER_MESSAGE,
} from "../../constants";
import { Action } from "../commonTypes";

export const initialPageState = {
  dialog: null,
  snackbar: null,
  spinner: {
    counter: 0,
    message: null,
  },
};

//  Calculates safe spinner value
export const spinner = (currentValue: number, increment = 1) => {
  let retval = currentValue + increment;
  if (retval < 0 || increment === 0) retval = 0;
  return retval;
};

export const PageReducer = (state = initialPageState, action: Action) => {
  switch (action.type) {
    case PAGE_DIALOG: {
      return {
        ...state,
        dialog: action.payload,
      };
    }

    case PAGE_SNACKBAR: {
      return {
        ...state,
        snackbar: action.payload,
      };
    }

    case PAGE_SPINNER: {
      return {
        ...state,
        spinner: {
          counter: spinner(
            state.spinner.counter,
            (action.payload as { increment: number }).increment
          ),
          message: (action.payload as { message: string }).message,
        },
      };
    }

    case PAGE_SPINNER_MESSAGE: {
      return {
        ...state,
        spinner: {
          ...state.spinner,
          message: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
