import { memo, SVGProps, FC } from "react";

interface SvgWarningBatteryProps extends SVGProps<SVGSVGElement> {}

const SvgWarningBattery: FC<SvgWarningBatteryProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="-5 -9 47 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="battery-svg-warning"
      {...props}
    >
      <path
        d="M36 17.34V14H40V6L36 6V2.66C36 1.2 34.8 0 33.34 0L2.68 0C1.2 0 0 1.2 0 2.66V17.32C0 18.8 1.2 20 2.66 20L33.34 20C34.8 20 36 18.8 36 17.34Z"
        fill="#E5BE33"
      />
    </svg>
  );
};

export default memo(SvgWarningBattery);
