import { FC, useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import SliderLegend from "./SensorSliderLegend";

export interface SensorSliderProps {
  values: number[];
  min: number;
  max: number;
  marks: { value: number; label: string }[];
  step?: number;
  disabled?: boolean;
  reversed?: boolean;
  marksMargin?: { left?: string; right?: string };
  onChange: (values: number[]) => void;
}

const SensorSlider: FC<SensorSliderProps> = ({
  values,
  min,
  max,
  marks,
  step = 1,
  disabled,
  reversed,
  marksMargin,
  onChange,
}) => {
  const [baseDiffPercent, setBaseDiffPercent] = useState<number>(0.061);
  const [perc, setPerc] = useState([25, 50, 75]);

  // set the slider colors on parent values and min/max change
  useEffect(() => {
    const [minVal, midVal, maxVal] = values;
    const minPerc = ((minVal - min) / (max - min)) * 100;
    const midPerc = ((midVal - minVal) / (maxVal - minVal)) * 100;
    const maxPerc = ((maxVal - min) / (max - min)) * 100;
    setPerc([minPerc, midPerc, maxPerc]);
  }, [min, max, values]);

  const [index1Styles, setIndex1Styles] = useState<string>("");
  const [index2Styles, setIndex2Styles] = useState<string>("");
  const [index3Styles, setIndex3Styles] = useState<string>("");

  const handleChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    // only trigger if the thumb values have a difference of 3+ steps
    if (
      Array.isArray(value) &&
      value[0] + step * 2 < value[1] &&
      value[1] + step * 2 < value[2]
    ) {
      onChange(value);
    }

    setSlidersLabelProps(value);
  };

  const setSlidersLabelProps = (value: number | number[]) => {
    if (
      !(Array.isArray(value) && value[0] + max * baseDiffPercent < value[1]) ||
      !(Array.isArray(value) && value[1] - max * baseDiffPercent > value[0])
    ) {
      setIndex2Styles(`calc(2 * -0.7rem)`);
    } else {
      setIndex2Styles("");
    }

    if (
      !(Array.isArray(value) && value[1] + max * baseDiffPercent < value[2]) ||
      !(Array.isArray(value) && value[2] - max * baseDiffPercent > value[1])
    ) {
      setIndex3Styles(`calc(3 * -0.7rem)`);
    } else {
      setIndex3Styles("");
    }
  };

  const sliderStyles = {
    "& .MuiSlider-track": {
      background: reversed
        ? `linear-gradient(to right, var(--warning) 0% ${perc[1]}%, var(--yellow-custom-slider) ${perc[1]}% 100%)`
        : `linear-gradient(to right, var(--yellow-custom-slider) 0% ${perc[1]}%, var(--warning) ${perc[1]}% 100%)`,
      borderColor: "transparent",
    },
    "& .MuiSlider-rail": {
      background: reversed
        ? `linear-gradient(to right, var(--error)0% ${perc[0]}%,var(--green) ${perc[2]}% 100%)`
        : `linear-gradient(to right, var(--green)0% ${perc[0]}%,var(--error) ${perc[2]}% 100%)`,
      opacity: 0.7,
    },
    "& .MuiSlider-thumb[data-index='0']": {
      ".MuiSlider-valueLabel": {
        top: index1Styles,
      },
    },
    "& .MuiSlider-thumb[data-index='1']": {
      ".MuiSlider-valueLabel": {
        top: index2Styles,
      },
    },
    "& .MuiSlider-thumb[data-index='2']": {
      ".MuiSlider-valueLabel": {
        top: index3Styles,
      },
    },

    "& .MuiSlider-thumb": {
      background: "transparent",
      "&:focus,&:hover,&$active": {
        boxShadow: "inherit",
      },
      [`&:nth-of-type(${1}n)`]: {
        background: disabled ? "transparent" : "var(--border-color)",
        "&:before": {
          boxShadow: disabled ? "none" : "inherit",
        },
        "& span": {
          background: "transparent",
          color: "var(--battery-settings)",
        },
      },
      [`&:nth-of-type(${2}n)`]: {
        background: disabled ? "transparent" : "var(--border-color)",
        "& span": {
          background: "transparent",
          color: "var(--battery-settings)",
        },
      },
      [`&:nth-of-type(${3}n)`]: {
        background: disabled ? "transparent" : "var(--border-color)",
        "& span": {
          background: "transparent",
          color: "var(--battery-settings)",
        },
      },
    },
    "& .MuiSlider-mark": {
      background: "none",
    },
    "& .MuiSlider-markLabel[data-index='0']": {
      marginLeft: marksMargin?.left ?? "0rem",
      transform: "translateX(0%)",
    },
    "& .MuiSlider-markLabel[data-index='1']": {
      marginLeft: marksMargin?.right ?? "0rem",
      transform: "translateX(-100%)",
    },
  };
  useEffect(() => {
    setSlidersLabelProps(values);
  });

  return (
    <>
      <Slider
        value={values}
        min={min}
        max={max}
        marks={marks}
        step={step}
        disabled={disabled}
        sx={sliderStyles}
        valueLabelDisplay="on"
        disableSwap
        data-testid="sensor-slider"
        onChange={handleChange}
      />
      <SliderLegend reversed={reversed} />
    </>
  );
};

export default SensorSlider;
