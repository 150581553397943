import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import { HorizontalBarChart } from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type DashboardWidgetGeofencesOrgData = {
  name: string;
  value: number;
  percentage: number;
};

export type DashboardWidgetGeofencesData = {
  orgs: DashboardWidgetGeofencesOrgData[];
  total: number;
};

export interface DashboardWidgetGeofences extends GetDashboardWidgetResponse {
  data: DashboardWidgetGeofencesData;
}

export interface GeofencesWidgetProps {
  widget: DashboardWidgetGeofences;
}

const mapWidgetData = (data: DashboardWidgetGeofencesData) =>
  data?.orgs?.map((org) => ({
    ...org,
    percent: org.percentage,
    color:
      org.name === "Other"
        ? ColorsPalette.Concrete
        : ColorsPalette.ReChartsViolet,
  })) ?? [];

export const GeofencesWidget: React.FC<GeofencesWidgetProps> = ({ widget }) => {
  const title = widget.name ?? "Geofences";

  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetGeofencesData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.total ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--geofences"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the geofences widget!
          Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          data={data}
          title={title}
          totalRecords={total}
          isLoading={isLoading}
          showPercentages
          showTotal
          subtitle="Total Number Of Geofences"
          showXAxis={false}
          minPointSize={125}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
