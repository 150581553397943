import { MutationKey, UseMutationOptions } from "@tanstack/react-query";
import {
  InitiateAssetsShareMutation,
  InitiateAssetsShareMutationVariables,
  InitiateAssetsShareOsMutation,
  useInitiateAssetsShareMutation,
  useInitiateAssetsShareOsMutation,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";

type InitiateAssetsShareMutationOptions = UseMutationOptions<
  InitiateAssetsShareMutation | InitiateAssetsShareOsMutation,
  unknown,
  InitiateAssetsShareMutationVariables,
  MutationKey
>;

export const useInitateAssetsShare = (
  options: InitiateAssetsShareMutationOptions
) => {
  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const mutation = fetchAssetsFromOpenSearchFeatureFlag
    ? useInitiateAssetsShareOsMutation
    : useInitiateAssetsShareMutation;

  const { mutate, mutateAsync, isLoading } = mutation(options);

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
