import { FC, memo, ReactNode } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Menu,
  MenuItem,
  popoverClasses,
  Typography,
} from "@mui/material";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import slug from "slug";
import { useApplicationTheme } from "../../hooks/theme/useApplicationTheme";
import { UserAccessScope } from "../WithPermissions";
import WithPermissions from "../WithPermissions/WithPermissions";

export type MenuOption = {
  title: string;
  icon?: ReactNode;
  onClick: () => void;
  permission: UserAccessScope;
};

export type ThreeDotsMenuProps = {
  title?: string;
  menuOptions: MenuOption[];
};

export const ThreeDotsMenu: FC<ThreeDotsMenuProps> = memo((props) => {
  const { title, menuOptions } = props;

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-actions-popup",
  });
  const { applicationTheme } = useApplicationTheme();

  const onMenuItemClick = (onClick: () => void) => {
    popupState.close();
    onClick();
  };

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        title="Three Dots Menu"
        data-testid="three-dots-menu-button"
        {...bindTrigger(popupState)}
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>

      <Menu
        {...bindMenu(popupState)}
        data-testid="three-dots-menu"
        className="!text-xs"
        sx={{
          [`& .${popoverClasses.paper}`]: {
            backgroundColor: "var(--primary)",
            minWidth: "240px",
            maxWidth: "280px",
          },
          "& .MuiList-root": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            fontSize: "1rem",
            "*": {
              fontWeight: "500 !important",
            },
          },
        }}
      >
        {title && (
          <Typography data-testid="three-dots-menu-title" className="px-4 py-2">
            {title}
          </Typography>
        )}

        {menuOptions.map((option) => (
          <WithPermissions accessScope={option.permission} key={option.title}>
            <MenuItem
              data-testid={`three-dots-menu-option-${slug(option.title)}`}
              className="flex !justify-between !text-inherit"
              onClick={() => onMenuItemClick(option.onClick)}
            >
              <p>{option.title}</p>
              {option?.icon}
            </MenuItem>
          </WithPermissions>
        ))}
      </Menu>
    </>
  );
});
