import { FC } from "react";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";
import { SensorFilterSliderColors, SensorFiltersNames } from "../../types";
import { DEFAULT_SENSOR_VALUES } from "../../utils";
import BatteryVoltageSlider from "./BatteryVoltageSlider";

const BatteryPrimaryVoltageSlider: FC = () => {
  const {
    onChangeFilters,
    currentFilter: { batteryPrimaryVoltage },
  } = useAssetsDataContext();

  const onChangePrimaryVoltage = (newValues: number[]) => {
    const [minVoltage, maxVoltage] = newValues;

    onChangeFilters({
      batteryPrimaryVoltage: { minVoltage, maxVoltage },
    });
  };

  return (
    <BatteryVoltageSlider
      onChange={onChangePrimaryVoltage}
      currentSensor={{
        minValue:
          batteryPrimaryVoltage?.minVoltage ??
          DEFAULT_SENSOR_VALUES[SensorFiltersNames.BatteryPrimaryVoltage]!.min,
        maxValue:
          batteryPrimaryVoltage?.maxVoltage ??
          DEFAULT_SENSOR_VALUES[SensorFiltersNames.BatteryPrimaryVoltage]!.max,
      }}
      maxValue={25}
      marks={[
        {
          value: 0,
          label: `0V`,
        },
        {
          value: 25,
          label: `25V`,
        },
      ]}
      valueBarColor={SensorFilterSliderColors.BatteryPrimaryVoltage}
    />
  );
};

export default BatteryPrimaryVoltageSlider;
