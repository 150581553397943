import { useQuery } from "@tanstack/react-query";
import {
  getGeocodeFromAddress,
  getGeocodeWithBounds,
} from "../../../utils/geocode";

export const useGeocodeFromAddress = (address: string) => {
  return useQuery(["geocode", address], () => getGeocodeFromAddress(address), {
    enabled: Boolean(address),
    select: getGeocodeWithBounds,
  });
};
