import { useEffect } from "react";
import { getSchemas } from "../../../design-system";
import { useTheme } from "./useTheme";
import { themes } from "./utils";

/**
 * This hook provides functionality of keeping css variables in sync with passed theme.
 * @param theme
 */
export const useThemeSynchronizer = (theme: themes.light | themes.dark) => {
  const { brandColor } = useTheme(theme);

  useEffect(() => {
    const schemas = getSchemas(brandColor);

    Object.keys(schemas[theme]).forEach((color) => {
      document.body.style.setProperty(`--${color}`, schemas[theme][color]);
    });
  }, [theme, brandColor]);
};
