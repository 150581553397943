import * as yup from "yup";
import { CreateConfigurationSetFaultCodeInput } from "../../../../../graphql/operations";
import { parseFileContent, ParseFileContentError } from "../../../../../utils";
import { FaultCodeFileData, ValidatedFaultCode } from "./types";
import { FaultCodeType, uploadFaultCodeSchema } from "./uploadFaultCodeSchema";

type MapFileDataResult = {
  validatedFaultCodes: ValidatedFaultCode[];
  validationErrors: ParseFileContentError[];
};

export const faultTypeTemplateToEnumValueMap: Record<string, FaultCodeType> = {
  ABS: FaultCodeType.ABS,
  "Air Tank": FaultCodeType.AirTank,
  "Internal Camera": FaultCodeType.InternalCamera,
  Liftgate: FaultCodeType.Liftgate,
  Other: FaultCodeType.Other,
};

export const parseFaultCodeFile = async (
  file: File
): Promise<{
  fileData: FaultCodeFileData[];
  errors: ParseFileContentError[];
}> => {
  const map: { [key: string]: keyof FaultCodeFileData } = {
    "Name*": "name",
    Manufacturer: "manufacturer",
    "Fault Code Type*": "faultCodeType",
    PGN: "pgn",
    SID: "sid",
    FMI: "fmi",
    DTC: "dtc",
    "Problem Area": "problemArea",
    Cause: "cause",
    "Description*": "description",
    "PCT Explains": "pctExplains",
  };

  try {
    const { data, errors } = await parseFileContent<FaultCodeFileData>({
      file,
      map,
    });
    return { fileData: data, errors };
  } catch (error) {
    let message = "Error parsing file.";
    if (error instanceof Error) {
      message = error.message;
    }
    return { fileData: [], errors: [{ row: 0, message }] };
  }
};

export const mapFileDataToCreateFaultCodeInput = (
  data: FaultCodeFileData[],
  customer_orgs_id: string,
  name: string,
  org_name: string
): MapFileDataResult => {
  const errors: ParseFileContentError[] = [];

  if (!data.length) {
    errors.push({ row: 0, message: "Uploaded file is empty" });
  }

  const mapped = data.map((fileData, index) => {
    const row = index + 1; // Add 1 to index to get row number

    try {
      const faultCode: CreateConfigurationSetFaultCodeInput =
        uploadFaultCodeSchema.validateSync(
          {
            name: fileData.name,
            manufacturer: fileData.manufacturer,
            faultCodeType: fileData.faultCodeType,
            pgn: fileData.pgn,
            dtc: fileData.dtc,
            sid: fileData.sid,
            fmi: fileData.fmi,
            problemArea: fileData.problemArea,
            cause: fileData.cause,
            description: fileData.description,
            pctExplains: fileData.pctExplains,
          },
          { abortEarly: false }
        );

      return { faultCode, row };
    } catch (error: any) {
      if (error.inner) {
        error.inner.forEach((err: yup.ValidationError) => {
          errors.push({
            row,
            message: err.message ?? "Error validating fault code",
          });
        });
      } else {
        errors.push({
          row,
          message:
            error instanceof Error
              ? error.message
              : "Error validating fault code",
        });
      }

      return null;
    }
  });

  return {
    validatedFaultCodes: mapped.filter(
      (faultCode): faultCode is ValidatedFaultCode => !!faultCode
    ),
    validationErrors: errors,
  };
};
