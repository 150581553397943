import { GridColDef } from "@mui/x-data-grid-premium";

export enum EntityPrefix {
  Default = "0",
  User = "1",
  Organization = "2",
  BatchHistory = "3",
  Brand = "4",
  GeofenceEventInfo = "5",
  Asset = "6",
  ConfigurationSet = "7",
  SensorProfile = "8",
  ReportsTableData = "9",
  Device = "10",
  GeofenceIncidentsTableData = "11",
  AlertsTableData = "12",
  AlertHistoryTableData = "13",
  DeviceEvent = "14",
  AssetsTableData = "15",
  Automation = "16",
  UserEvent = "17",
  AssetEvent = "18",
  AlertNotification = "19",
  AssetAlertsTableData = "20",
  Geofence = "21",
  GeofenceType = "22",
}

export const generateFakeObjectId = (
  id: number = 1,
  prefix: EntityPrefix = EntityPrefix.Default
): string => {
  return `${prefix}${String(id).padStart(23, "0")}`;
};

export const createField = (
  field: string,
  headerName?: string,
  rest?: Partial<GridColDef>
): GridColDef => {
  return {
    field,
    headerName,
    flex: 0,
    minWidth: 120,
    groupable: false,
    disableExport: false,
    filterable: true,
    ...rest,
  };
};

export const formatBooleanLabel = (
  value: boolean | null | undefined,
  defaultFallback = ""
): string => {
  if (value === null || value === undefined) {
    return defaultFallback;
  }

  return value ? "Yes" : "No";
};

export const getDownloadTemplateHint = (
  allowedFormats: string[],
  isDataUploading: boolean = false,
  isDataUploaded: boolean = false
): string | undefined => {
  const formats =
    allowedFormats.length > 1
      ? `${allowedFormats
          .slice(0, -1)
          .map((f) => `.${f}`)
          .join(", ")} or .${allowedFormats.slice(-1)[0]} formats`
      : `.${allowedFormats} format`;

  if (isDataUploaded) {
    return undefined;
  } else if (isDataUploading) {
    return "Your file is uploading now";
  } else if (allowedFormats.length) {
    return `File must be in ${formats}`;
  } else {
    return "";
  }
};
