import { useState, useMemo } from "react";
import throttle from "lodash/throttle";
import { useGeoSearch } from "../Selector.queries";

export const useLocationSearch = (historyItems: string[], limit = 5) => {
  const [locationSearchText, setLocationSearchText] = useState("");

  const setSearch = throttle((text) => setLocationSearchText(text), 500, {
    leading: false,
  });

  const disabled = useMemo(
    () =>
      historyItems?.find((item) =>
        item.toLowerCase().startsWith(locationSearchText.toLowerCase())
      ),
    [historyItems, locationSearchText]
  );

  const {
    data: geoSearchResult,
    isLoading,
    isFetching,
  } = useGeoSearch({ searchText: locationSearchText, limit }, !disabled);
  const options = useMemo(
    () =>
      geoSearchResult?.map((item) => item.formatted).filter((item) => !!item),
    [geoSearchResult]
  );

  return {
    options,
    isLoading: !disabled && isLoading && isFetching,
    setSearch,
  };
};
