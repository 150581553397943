import { FC, useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, Typography } from "@mui/material";
import { ReportType } from "../../../../../graphql/operations";
import { CustomTooltip } from "../../../../../shared/components/CustomTooltip/CustomTooltip";
import {
  assetQueryBuilderFields,
  incidentsQueryBuilderFields,
} from "../../../../../shared/components/QueryBuilder/config";
import {
  helperText,
  QueryBuilderElement,
} from "../../../../../shared/components/react-hook-form-mui/QueryBuilderElement";

interface ReportQueryBuilderProps {
  form: UseFormReturn;
  handleUpdateField: (value: any, field?: string) => void;
  fieldPath: string;
  reportType?: string;
}

export const ReportQueryBuilder: FC<ReportQueryBuilderProps> = ({
  form,
  handleUpdateField,
  fieldPath,
  reportType,
}) => {
  const [shouldReset, setShouldReset] = useState(false);

  const resetQueryBuilderHandler = useCallback(() => {
    setShouldReset(true);
  }, []);

  const onReset = useCallback(() => {
    setShouldReset(false);
  }, []);

  const configFields =
    reportType === ReportType.AlertHistory
      ? incidentsQueryBuilderFields
      : assetQueryBuilderFields;

  return (
    <Box className="py-4" data-testid="report-query-builder">
      <Box className="flex justify-between pr-2">
        <Typography className="!pb-8 !text-lg" variant="h6" component="div">
          Custom Query
          <CustomTooltip title={helperText} arrow>
            <InfoOutlinedIcon className="ml-2 cursor-pointer" />
          </CustomTooltip>
        </Typography>
        <Button
          data-testid="reset-query-view-btn"
          className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
          onClick={resetQueryBuilderHandler}
        >
          Reset query view
        </Button>
      </Box>
      <QueryBuilderElement
        label=""
        control={form.control}
        name="parameters.query.logic"
        configFields={configFields}
        shouldReset={shouldReset}
        onReset={onReset}
        handleUpdateField={handleUpdateField}
        fieldPath={fieldPath}
      />
    </Box>
  );
};
