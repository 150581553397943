import {
  MovingParkedThresholds,
  SensorThresholdShort,
  CargoCameraThresholds,
  LiteSentryGammaThresholds,
} from "../../graphql/operations";
import { MaxValuesBySensorType, MinValuesBySensorType } from "./battery";

export enum SensorValueType {
  Percentage = "percentage", // unit for cargo camera is percentage
  Temperature = "temperature",
  Time = "time",
  Amperage = "amperage", // unit for lite sentry gamma is amperage
  Pressure = "pressure",
  Battery = "battery",
  Voltage = "voltage",
}

export interface ThresholdsRange {
  underFrom: number; // starting value from lower diapason
  underTo: number; // ending value from lower diapason
  overFrom: number; // ending value from upper diapason
  overTo: number; // ending value from upper diapason
  healthyFrom?: number;
  healthyTo?: number;
  warningFrom?: number;
  warningTo?: number;
  alertFrom?: number;
  alertTo?: number;
  criticalFrom?: number;
  criticalTo?: number;
}

export type PressureSensorThresholds = {
  healthy: ThresholdsRange;
  warning: ThresholdsRange;
  alert: ThresholdsRange;
  critical: ThresholdsRange;
};

export type SensorThresholds = {
  healthy: {
    healthyFrom: number;
    healthyTo: number;
  };
  warning: {
    warningTo: number;
    warningFrom: number;
  };
  alert: {
    alertFrom: number;
    alertTo: number;
  };
  critical: {
    criticalFrom: number;
    criticalTo: number;
  };
};

export interface InternalTemperatureRange {
  coldSideFrom: number;
  coldSideTo: number;
  hotSideFrom: number;
  hotSideTo: number;
}

export interface InternalTemperatureSensorThresholds {
  healthy: InternalTemperatureRange;
  warning: InternalTemperatureRange;
  alert: InternalTemperatureRange;
  critical: InternalTemperatureRange;
}

export type SensorData = {
  batterySensors?: SensorThresholds;
  temperature: SensorThresholds;
  temperatureSensor?: {
    internal: InternalTemperatureSensorThresholds;
  };
  pressure: PressureSensorThresholds;
  airbag?: SensorThresholds;
  supplyPressure?: MovingParkedThresholds;
  tankPressure?: SensorThresholdShort;
  cargoCamera?: CargoCameraThresholds;
  cargoCameraSensors: CargoCameraThresholds;
  regulatorMoving: SensorThresholds;
  regulatorParked: SensorThresholds;
  regulator: MovingParkedThresholds;
  psiWheelEnd: SensorThresholds;
  imbalance: SensorThresholds;
  atisAlpha: MovingParkedThresholds;
  liteSentryGamma: LiteSentryGammaThresholds;
  liftgate: SensorThresholds;
};

/**
 * Converts the temperature json structure to imitate the one from the batteries
 * @param temperatureTresholds
 * @returns
 */

export const defaultImbalanceValues = {
  healthy: {
    healthyFrom: MinValuesBySensorType.imbalance,
    healthyTo: 5,
  },
  warning: {
    warningFrom: 6,
    warningTo: 10,
  },
  alert: {
    alertFrom: 11,
    alertTo: 15,
  },
  critical: {
    criticalFrom: 20,
    criticalTo: MaxValuesBySensorType.imbalance,
  },
};

export const defaultTemperatureValues = {
  healthy: {
    healthyFrom: MinValuesBySensorType.temperature,
    healthyTo: 50,
  },
  warning: {
    warningTo: 125,
    warningFrom: 51,
  },
  alert: {
    alertFrom: 126,
    alertTo: 200,
  },
  critical: {
    criticalFrom: 201,
    criticalTo: MaxValuesBySensorType.temperature,
  },
};

export const defaultPressureValue = {
  alert: {
    underFrom: 33,
    underTo: 65,
    overFrom: 150,
    overTo: 175,
  },
  critical: {
    underFrom: MinValuesBySensorType.tpmsPressure,
    underTo: 32,
    overFrom: 189,
    overTo: MaxValuesBySensorType.tpmsPressure,
  },
  healthy: {
    underFrom: 106,
    underTo: 100,
    overFrom: 100,
    overTo: 125,
  },
  warning: {
    underFrom: 66,
    underTo: 105,
    overFrom: 125,
    overTo: 150,
  },
};
