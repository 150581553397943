import { useState, useEffect, useMemo, FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Box, IconButton, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as AirbagSensorDark } from "../../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirbagSensorLight } from "../../../../../../assets/svgs/airbagSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useGetSensorProfileNamesWithConfigurationQuery,
  SensorProfileType,
  useAssignOrgProfileToAssetsMutation,
  useSetAssetSensorProfilesMutation,
  SetAssetSensorProfilesMutation,
  AssetSensorProfilesResponseStatus,
  SetAssetSensorProfilesInput,
  SensorProfileConfigType,
  AssignOrgProfileToAssetsMutation,
  AssignOrgProfileToAssetsInput,
  AssignOrgProfileToAssetsStatus,
  AssetWithSensors,
  SetAssetSensorProfilesResponse,
  AssignOrgProfileToAssetsResponse,
  SensorThresholdShort,
  PressureUnit,
} from "../../../../../../graphql/operations";
import { Button, TextButton } from "../../../../../../shared/components/Button";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import SensorSlider from "../../../../../../shared/components/SensorSlider/SensorSlider";
import {
  airbagDefaultValues,
  extractRules,
  prepareRulesPayload,
  sliderMarks,
} from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Text from "../../../../../../shared/components/Text";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
} from "../../../../../../shared/helpers/battery";
import { useGetPressureUnitPreference } from "../../../../../../shared/hooks/useGetPressureUnitPreference";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import {
  getConvertedPressureValue,
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../../utils/convertPressure";
import {
  AIR_BAG_SENSOR_FORM_FIELDS,
  analyzeResponse,
  handleAnalyzedResponse,
  SensorProfileDropdownItem,
  sortSensorProfileNameDropdownItems,
} from "../../sensorsUtils";
import ConfigurationDialogAdditionalElement from "../Components/CheckboxComponent";
import SensorProfileAssignmentForm from "../Components/SensorProfileAssignmentForm";
import { airBagSensorSchema } from "./airBagSensorSchema";

interface AirbagSensorDrawerProps {
  currentOrgId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: AssetWithSensors[];
}

const AirbagSensorDrawer: FC<AirbagSensorDrawerProps> = ({
  open,
  setOpen,
  selectedRecordsData,
  currentOrgId,
}) => {
  const queryClient = useQueryClient();
  const pressureUnit = useGetPressureUnitPreference();

  let defaultAirbagSensorProfile = undefined;
  let defaultTypeOfAssignment = "custom";
  const airBag = selectedRecordsData?.[0]?.sensorProfile?.configuration?.airbag;
  const airBagThresholds = selectedRecordsData?.[0]?.airbag;
  const isPresetProfile = Boolean(airBag?.type !== SensorProfileType.Asset);
  const [minAirBagConverted, maxAirBagConverted] = getConvertedPressureValues(
    [MinValuesBySensorType.airbag, MaxValuesBySensorType.airbag],
    pressureUnit
  );

  if (airBag?.profileName && isPresetProfile) {
    defaultTypeOfAssignment = "profile";
    defaultAirbagSensorProfile = airBag?.profileId;
  }

  //hooks

  const airbagData = extractRules(airBagThresholds) || airbagDefaultValues;

  const [airbagSettingsValues, setAirbagSettingsValues] = useState(airbagData);

  const airbagMarks = sliderMarks(
    minAirBagConverted,
    maxAirBagConverted,
    getPressureUnitLabel(pressureUnit)
  );

  const isSliderDirty = Boolean(
    airbagSettingsValues.toString() !== airbagData.toString()
  );

  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };

  //set default values and resolver to the main form
  const mainForm = useForm({
    resolver: yupResolver(airBagSensorSchema),
    defaultValues: {
      typeOfAssignment: defaultTypeOfAssignment,
      airbagSensorProfile: defaultAirbagSensorProfile,
    },
  });

  //states

  const [
    isConfirmProfileAssignmentVisible,
    setIsConfirmProfileAssignmentVisible,
  ] = useState(false);
  const [isTypeOfAssignmentProfile, setIsTypeOfAssignmentProfile] =
    useState(false);
  const [profileInput, setProfileInput] = useState<
    AssignOrgProfileToAssetsInput | undefined
  >(undefined);
  const [dontShowConfirmationDialog, setDontShowConfirmationDialog] =
    useState(false);
  const [selectedProfileLabel, setSelectedProfileLabel] = useState<string>("");

  //effects

  useEffect(() => {
    if (airBag?.type !== SensorProfileType.Asset) {
      setIsTypeOfAssignmentProfile(true);
    }
    const isDontShowAgain =
      localStorage.getItem("setProfileConformation") === "true";
    setDontShowConfirmationDialog(isDontShowAgain);
  }, [airBag]);

  // GraphQL
  const { data: profilesData, isLoading: isSensorProfileNamesLoading } =
    useGetSensorProfileNamesWithConfigurationQuery(
      {
        input: {
          orgId: currentOrgId ?? "",
          type: SensorProfileType.Organization,
          includeDefaultPctProfile: true,
          mergeProfilesWithDefaultPctProfile: true,
          sensorType: SensorProfileConfigType.Airbag,
        },
      },
      {
        enabled: Boolean(currentOrgId),
      }
    );

  // this is a mutation that we use when Type of Assignment is CUSTOM
  const { mutate: mutateCustomProfile, isLoading: isLoadingCustomProfile } =
    useSetAssetSensorProfilesMutation({
      onSuccess: async (data: SetAssetSensorProfilesMutation) => {
        const response = analyzeResponse(
          data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
          AssetSensorProfilesResponseStatus.Success
        );
        handleAnalyzedResponse(
          response,
          dispatchErrorMessage,
          dispatchSuccessMessage,
          queryClient
        );
        setOpen(false);
      },
    });

  // this is a mutation that we use when Type of Assignment is PROFILE
  const { mutate: mutateProfile, isLoading: isLoadingProfile } =
    useAssignOrgProfileToAssetsMutation({
      onSuccess: async (data: AssignOrgProfileToAssetsMutation) => {
        const response = analyzeResponse(
          data?.assignOrgProfileToAssets as AssignOrgProfileToAssetsResponse[],
          AssignOrgProfileToAssetsStatus.Fulfilled
        );
        handleAnalyzedResponse(
          response,
          dispatchErrorMessage,
          dispatchSuccessMessage,
          queryClient
        );
        setOpen(false);
      },
    });

  //handlers

  const handleConfirmWarning = (confirmed: boolean) => {
    setIsConfirmProfileAssignmentVisible((prev) => !prev);
    if (!confirmed) {
      return;
    }
    if (dontShowConfirmationDialog) {
      localStorage.setItem("setProfileConformation", "true");
    }
    if (profileInput) {
      mutateProfile({ input: profileInput });
    }
  };

  const [selectedAssetsImeis, selectedRowsIds, selectedAssetsIds] = useMemo(
    () =>
      (selectedRecordsData ?? [])?.reduce(
        (acc: string[][], obj: AssetWithSensors) => {
          if (obj.imei) {
            acc[0].push(obj.imei);
          }

          if (obj._id) {
            acc[1].push(obj._id);
          }

          if (obj.asset_id) {
            acc[2].push(obj.asset_id);
          }
          return acc;
        },
        [[], [], []]
      ),
    [selectedRecordsData]
  );

  const sensorProfilesForOrganization = useMemo(
    () => profilesData?.getSensorProfilesForOrganization ?? [],
    [profilesData]
  );

  const airbagSensorProfiles: SensorProfileDropdownItem[] =
    sensorProfilesForOrganization.map(
      (item) =>
        ({
          label: item.name,
          value: item._id,
          id: item._id,
          configuration: item.configuration,
          type: item.type,
        } as SensorProfileDropdownItem)
    );

  AIR_BAG_SENSOR_FORM_FIELDS[1].options =
    sortSensorProfileNameDropdownItems(airbagSensorProfiles);

  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        text: "Sensor(s) Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const onSubmit = async () => {
    const isMainFormValid = await mainForm.trigger();

    if (!isMainFormValid) return;
    // type of Assignment is not profile submit else ask for confirmation
    if (!isTypeOfAssignmentProfile && isSliderDirty) {
      const input: SetAssetSensorProfilesInput = {
        selectedImeis: selectedAssetsImeis,
        orgId: currentOrgId ?? "",
        sensors: {
          airbag: {
            measures: prepareRulesPayload(
              SensorProfileConfigType.Airbag,
              prepareConvertedMbarPressuresPayload(
                airbagSettingsValues,
                pressureUnit
              ),
              MinValuesBySensorType.airbag,
              MaxValuesBySensorType.airbag
            ),
          },
        },
      };
      mutateCustomProfile({ input });
    } else {
      const selectedProfile = mainForm.getValues().airbagSensorProfile;
      const input: AssignOrgProfileToAssetsInput = {
        assetIds: selectedRowsIds,
        profileId: selectedProfile ?? "",
      };

      if (dontShowConfirmationDialog) {
        mutateProfile({ input });
      } else {
        setProfileInput(input);
        setIsConfirmProfileAssignmentVisible((prev) => !prev);
      }
    }
  };

  // handle change of airbag sensor profile dropdown
  const handleSensorProfileInputChange = (selectedSensorProfile: {
    configuration: { airbag: { measures: SensorThresholdShort } };
    label: string;
  }) => {
    const extractedRules = extractRules(
      selectedSensorProfile?.configuration?.airbag
        ?.measures as SensorThresholdShort
    );
    const airbagConverted = extractedRules
      ? getConvertedPressureValues(extractedRules, pressureUnit)
      : null;

    setAirbagSettingsValues(airbagConverted ?? airbagData);
    setSelectedProfileLabel(selectedSensorProfile?.label ?? "");
  };

  // handle change of Type of Assignment - show/hide of airbag Sensor question
  const handleAssignmentTypeInputChange = (e: {
    name: string;
    value: string;
  }) => {
    const value = e?.value ?? undefined;
    if (value === "profile") {
      setIsTypeOfAssignmentProfile(true);
    } else if (value === "custom") {
      setIsTypeOfAssignmentProfile(false);

      setAirbagSettingsValues(airbagData);
    }
  };

  const renderDrawerField = (label: string, value: string) => {
    return (
      <Text fontSize={14} fontWeight="bold" classes="!text-primary">
        {label}:<span className="font-normal pl-1 mb-2">{value}</span>
      </Text>
    );
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const handleCheckboxClick = () => {
    setDontShowConfirmationDialog((prev) => !prev);
  };

  const isDataLoading = isLoadingProfile || isLoadingCustomProfile;

  const additionalCheckbox = (
    <ConfigurationDialogAdditionalElement
      checked={dontShowConfirmationDialog}
      onChange={handleCheckboxClick}
    />
  );

  const isCustomAndPristine =
    !isPresetProfile && !isTypeOfAssignmentProfile && !isSliderDirty;
  const isProfileAndPristine = isPresetProfile && !mainForm.formState.isDirty;

  return (
    <Drawer
      testId="airbag-sensor-drawer"
      isOpen={open}
      onRequestClose={handleCloseDrawer}
      width="34rem"
      hideHeader
    >
      <div
        className="flex h-full flex-col"
        data-testid="component-edit-airbag-sensor"
      >
        <div className="relative flex w-full flex-initial justify-between border-b border-concrete bg-background px-6 pb-6 pt-14 !text-primary">
          <Box>
            <Text fontSize={24} fontWeight="bold" classes="!text-primary">
              Edit Sensor
            </Text>
            <IconButton
              aria-label="close"
              className="!absolute right-5 top-0"
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
        <div className="relative flex w-full flex-initial justify-between border-b border-concrete bg-background px-6 pb-6 pt-6 !text-primary">
          {selectedRecordsData?.length === 1 && (
            <Box>
              {renderDrawerField(
                "Organization Name",
                selectedRecordsData[0].org_name ?? ""
              )}
              {renderDrawerField(
                "Asset ID",
                selectedRecordsData[0].asset_id ?? ""
              )}
              {renderDrawerField(
                "Device ID",
                selectedRecordsData[0].imei ?? ""
              )}
              {renderDrawerField(
                "Product name",
                selectedRecordsData[0].prd_cde ?? ""
              )}
            </Box>
          )}
          {!!selectedRecordsData && selectedRecordsData.length > 1 && (
            <Box>
              {renderDrawerField(
                "Number of selected rows",
                `${selectedRecordsData.length}`
              )}
            </Box>
          )}
        </div>
        <div className="h-full flex-auto overflow-auto">
          <Box className="pt-6">
            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              className="justify-left px-6"
            ></Grid>
            <SensorProfileAssignmentForm
              isTypeOfAssignmentProfile={isTypeOfAssignmentProfile}
              form={mainForm}
              SENSOR_FORM_FIELDS={AIR_BAG_SENSOR_FORM_FIELDS}
              handleAssignmentTypeInputChange={handleAssignmentTypeInputChange}
              handleSensorProfileInputChange={handleSensorProfileInputChange}
              isProfileDataLoading={isSensorProfileNamesLoading}
            />
            <div
              className="flex flex-col mt-4"
              data-testid="sensor-profile-airbag"
            >
              <Box className="px-6" data-testid="sensors-camera-floor">
                <Box className="mb-16">
                  <Typography sx={{ fontWeight: "bold" }}>
                    Air Bag Pressure Settings
                  </Typography>
                  {isLightTheme ? (
                    <AirbagSensorDark style={svgIconSettings} />
                  ) : (
                    <AirbagSensorLight style={svgIconSettings} />
                  )}
                </Box>
                <SensorSlider
                  values={airbagSettingsValues}
                  min={minAirBagConverted}
                  max={maxAirBagConverted}
                  marks={airbagMarks}
                  disabled={isTypeOfAssignmentProfile}
                  onChange={setAirbagSettingsValues}
                />
              </Box>
            </div>
          </Box>
        </div>
        <div className="flex-initial bg-background">
          <Grid
            container
            className="justify-center bg-background py-2 shadow-[0_-2px_4px_rgba(0,0,0,0.16)]"
          >
            <Grid item>
              <TextButton
                text="Cancel"
                size="medium"
                onClick={handleCloseDrawer}
                theme="blue"
                data-testid="btn-airbag-drawer-form-cancel"
              />
            </Grid>
          </Grid>
          <Grid container className="justify-center bg-background py-2">
            <Grid item>
              <Button
                text={isDataLoading ? "Loading..." : "Apply to Selected"}
                size="medium"
                theme="blue"
                variant="default"
                data-testid="btn-airbag-drawer-form-submit"
                disabled={
                  isDataLoading || isCustomAndPristine || isProfileAndPristine
                }
                onClick={() => onSubmit()}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      {isTypeOfAssignmentProfile && isConfirmProfileAssignmentVisible && (
        <ConfirmationDialog
          title="Profile Assignment"
          message={`Warning, all of the sensor settings of profile ${selectedProfileLabel}
             will be applied to asset(s) ${selectedAssetsIds.join(
               ", "
             )}. Any custom sensor settings will be lost!`}
          open={isConfirmProfileAssignmentVisible}
          confirmButtonText={"Confirm"}
          cancelButtonText="Cancel"
          handleConfirmationResult={handleConfirmWarning}
          additionalContent={additionalCheckbox}
        />
      )}
    </Drawer>
  );
};

export default AirbagSensorDrawer;
