import { Box, Grid } from "@mui/material";
import { SensorStatusUppercase } from "../../../../../../graphql/operations";
import { LightCircuitMergedDataResult } from "../utils/utils";
import LightCardViewItem from "./LightCardViewItem";

type LightsCardViewProps = {
  data: LightCircuitMergedDataResult;
};

const LightsCardView: React.FC<LightsCardViewProps> = ({ data }) => {
  return (
    <Box
      className="bg-card__bg rounded-lg"
      data-testid="asset-dashboard--lights-tab--lights-circuit-widget--card-view"
    >
      <Grid container spacing={0} className="w-full">
        {Object.entries(data).map(([circuitName, circuitData], index) => (
          <LightCardViewItem
            key={index}
            label={circuitName}
            deviation={circuitData.calculated_deviation_percentage as number}
            lastReportedDate={circuitData.measurement_datetime as string}
            status={
              circuitData.statusDeviation ?? SensorStatusUppercase.Unknown
            }
          />
        ))}
      </Grid>
    </Box>
  );
};

export default LightsCardView;
