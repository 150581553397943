import * as yup from "yup";
import {
  ProfileConfigProperty,
  SensorProfileConfigType,
} from "../../../../../../../../graphql/operations";

const healthStatusSchema = {
  healthy: yup.boolean(),
  warning: yup.boolean(),
  alert: yup.boolean(),
  critical: yup.boolean(),
};

export const statusFilterSchema = yup.object().shape({
  [SensorProfileConfigType.Airbag]: yup.object().shape(healthStatusSchema),
  [ProfileConfigProperty.TankPressure]: yup.object().shape(healthStatusSchema),
  [SensorProfileConfigType.AtisAlpha]: yup.object().shape(healthStatusSchema),
  [ProfileConfigProperty.Battery]: yup.object().shape(healthStatusSchema),
  [ProfileConfigProperty.Liftgate]: yup.object().shape(healthStatusSchema),
  [SensorProfileConfigType.LiteSentryGamma]: yup
    .object()
    .shape(healthStatusSchema),
  [ProfileConfigProperty.AirSupply]: yup.object().shape(healthStatusSchema),
  [ProfileConfigProperty.Temperature]: yup.object().shape(healthStatusSchema),
  [SensorProfileConfigType.DualImbalance]: yup
    .object()
    .shape(healthStatusSchema),
});
