import { FC } from "react";
import { TextFieldElement } from "react-hook-form-mui";
import { Box, Grid, Paper, Typography } from "@mui/material";

interface AlertEmailProps {
  form: any;
}

export const AlertEmail: FC<AlertEmailProps> = ({ form }: AlertEmailProps) => {
  return (
    <Grid container item xs={12} md={6} lg={6} xl={6}>
      <Paper elevation={0} className="w-full">
        <Box className="p-12">
          <Typography className="!pb-6 !text-lg" variant="h6" component="div">
            Message
          </Typography>
          <TextFieldElement
            name="message"
            rows={5}
            multiline
            control={form.control}
            data-testid="alert-message"
            placeholder="Message (optional)"
            variant="outlined"
            fullWidth
          />
        </Box>
      </Paper>
    </Grid>
  );
};
