import { FC, useState, useMemo, useEffect } from "react";
import { FileUpload } from "@mui/icons-material";
import { Box, Autocomplete, TextField } from "@mui/material";
import { GridRowParams, GridRowId } from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { ROOT_ORGANIZATION_NAME } from "../../../../constants";
import { BatchTitles } from "../../../../constants/batches";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import { useFindOrgsQuery, UserRole } from "../../../../graphql/operations";
import { SubHeader } from "../../../../shared/components/SubHeader";
import {
  SubHeaderActionProps,
  SubHeaderActionType,
} from "../../../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { BATCH_FORM_FIELDS } from "../../../BatchesView/BatchManagementUtils";
import SidePanel from "../../components/SidePanel";
import DrawerGateway from "./DrawerGateway";
import TableGateway from "./TableGateway";
import { UploadFaultCodeDialog } from "./UploadFaultCodeDialog";
import {
  ConfigurationOptions,
  ConfigurationTypes,
  DrawerType,
  drawersTypeMapping,
} from "./configurationsUtils";

interface ConfigurationsProps {
  defaultConfiguration?: ConfigurationTypes;
}

const Configurations: FC<ConfigurationsProps> = (props) => {
  const { defaultConfiguration = ConfigurationOptions.Assets } = props;
  const dataCurrentOrg = useCurrentOrg();
  const { userInfo } = useAuthContext();
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const isPhillipsConnectMember =
    dataCurrentOrg?.name === ROOT_ORGANIZATION_NAME ||
    dataCurrentOrg?.org_key === ROOT_ORGANIZATION_NAME;
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isClientAdmin, setIsClientAdmin] = useState(false);

  useEffect(() => {
    if (!userInfo) return;

    setIsSuperAdmin(userInfo?.groups?.includes(UserRole.Superadmin));
    setIsClientAdmin(userInfo?.groups?.includes(UserRole.Clientadmin));
  }, [userInfo]);

  const sideMenuOptions = [
    {
      title: "Assets",
      type: ConfigurationOptions.Assets,
      visible: isPhillipsConnectMember,
    },
    {
      title: "Products",
      type: ConfigurationOptions.Products,
      visible: isPhillipsConnectMember,
    },
    {
      title: "Geofence Types",
      type: ConfigurationOptions.GeofenceTypes,
      visible: true,
    },
    {
      title: "Fault Codes",
      type: ConfigurationOptions.FaultCodes,
      visible: isPhillipsConnectMember && (isSuperAdmin || isClientAdmin),
    },
    {
      title: "Detention Rules",
      type: ConfigurationOptions.DetentionRules,
      visible: isSuperAdmin || isClientAdmin,
    },
  ];
  sideMenuOptions.sort((a, b) => a.title.localeCompare(b.title));

  const actionList = sideMenuOptions.filter((option) => option.visible);
  // find the first visible tab to prevent empty table results
  const firstVisibleIndex = dataCurrentOrg
    ? actionList.findIndex((action) => action.visible)
    : 0;

  const [indexState, setIndexState] = useState<number>(firstVisibleIndex);

  const actionListOptions = actionList.map((option) => option.title);
  const selectedSideMenuOption = actionList[indexState]
    ?.type as ConfigurationTypes;

  const [isOpenState, setIsOpenState] = useState<boolean>(false);
  const [selectedConfigIdState, setSelectedConfigIdState] = useState<
    GridRowId | undefined
  >();

  const { data: dataOrgsList } = useFindOrgsQuery();

  const queryClient = useQueryClient();

  const [drawerTypeState, setDrawerTypeState] = useState<DrawerType>(
    drawersTypeMapping[defaultConfiguration].create as DrawerType
  );

  const orgList = useMemo(
    () => dataOrgsList?.findOrgs || [],
    [dataOrgsList?.findOrgs]
  );

  const selectedOrg = useMemo(
    () => orgList.find((org) => org._id === selectedOrganization.value),
    [orgList, selectedOrganization]
  );

  // Fault code Upload
  const [isUploadFaultCodeDialogOpen, setIsUploadFaultCodeDialogOpen] =
    useState(false);
  const uploadHandler = () => setIsUploadFaultCodeDialogOpen(true);

  const onUploadFaultCodeDialogClose = () => {
    setIsUploadFaultCodeDialogOpen(false);
  };

  const handleCreateConfig = () => {
    setIsOpenState(true);
    setDrawerTypeState(
      drawersTypeMapping[selectedSideMenuOption].create as DrawerType
    );
  };

  const { userRolePermissions } = useAuthContext();

  const handleEditConfig = (row: GridRowParams) => {
    if (userRolePermissions.configurations.edit) {
      setSelectedConfigIdState(row.id);

      setIsOpenState(true);
      setDrawerTypeState(
        drawersTypeMapping[selectedSideMenuOption].edit as DrawerType
      );
    }
  };

  const handleOnClose = () => {
    setSelectedConfigIdState(undefined);
    setIsOpenState(false);
  };
  const isMobile = useBreakpoint("down", "sm");

  const subHeaderActions: SubHeaderActionProps[] = [];

  if (selectedSideMenuOption === ConfigurationOptions.FaultCodes) {
    subHeaderActions.push({
      type: SubHeaderActionType.Button,
      icon: <FileUpload />,
      title: "Upload",
      handler: uploadHandler,
      accessScope: "configurations.create",
    });
  }

  subHeaderActions.push({
    type: SubHeaderActionType.Button,
    primary: true,
    title: "New Configuration",
    handler: handleCreateConfig,
    accessScope: "configurations.create",
  });

  return (
    <Box
      data-testid="tab-configurations"
      className="bg-background pt-2 h-[90%]"
    >
      <SubHeader
        title="Configuration Sets"
        actions={subHeaderActions}
        hideOrgName={true}
        hideHeaderBorder={true}
        removeHorizontalPadding={true}
        hideTitle={true}
      />
      <Box className="flex flex-wrap h-full pb-4 gap-4">
        <SidePanel
          sx={{
            flexGrow: isMobile ? 1 : 0,
          }}
          key={String(dataCurrentOrg)}
          actionList={actionList}
          defaultActive={indexState}
          onActionClick={(index) => setIndexState(index)}
        >
          {isMobile ? (
            <Autocomplete
              disableClearable
              options={actionListOptions}
              defaultValue={actionListOptions[firstVisibleIndex]}
              onChange={(_, value) => {
                setIndexState(
                  Number(
                    sideMenuOptions.findIndex(
                      (option) => option.title === value
                    )
                  )
                );
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          ) : undefined}
        </SidePanel>

        <TableGateway
          type={selectedSideMenuOption}
          orgId={selectedOrganization.value}
          onRowClick={handleEditConfig}
        />
      </Box>

      {isOpenState && Boolean(drawerTypeState) && (
        <DrawerGateway
          type={drawerTypeState}
          isOpen={isOpenState && Boolean(drawerTypeState)}
          selectedConfigId={selectedConfigIdState}
          selectedOrg={selectedOrg}
          onClose={() => {
            handleOnClose();
          }}
          onRequestClose={() => {
            handleOnClose();
          }}
        />
      )}
      <UploadFaultCodeDialog
        title={BatchTitles.CreateUpdateFaultCodes}
        customerOrg={dataCurrentOrg?.name}
        dialogFields={BATCH_FORM_FIELDS}
        isOpen={isUploadFaultCodeDialogOpen}
        onClose={onUploadFaultCodeDialogClose}
        onUpload={() => queryClient.invalidateQueries(["getConfigurationSet"])}
      />
    </Box>
  );
};

export default Configurations;
