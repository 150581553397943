// Create Configuration
export const PRODUCT_CREATE_SUCCESS_PAYLOAD = {
  title: "Product Created Successfully!",
  text: "You can find the Product in the table",
  severity: "success",
};

export const PRODUCT_CREATE_FAILED_PAYLOAD = {
  title: "Product Creation Failed,",
  text: "Something Went Wrong.",
  severity: "error",
};

export const PRODUCT_CREATE_FAILED_DUPLICATE_NAME_PAYLOAD = {
  title: "Product Creation Failed,",
  text: "Product Name already exists.",
  severity: "error",
};

export const PRODUCT_CREATE_FAILED_DUPLICATE_CODE_PAYLOAD = {
  title: "Product Creation Failed,",
  text: "Product Code already exists.",
  severity: "error",
};

// Update Configuration
export const PRODUCT_UPDATE_SUCCESS_PAYLOAD = {
  title: "Product Updated Successfully!",
  text: "You can find the Updated Product in the table",
  severity: "success",
};

export const PRODUCT_UPDATE_FAILED_PAYLOAD = {
  title: "Product Update Failed,",
  text: "Something Went Wrong.",
  severity: "error",
};

export const PRODUCT_UPDATE_FAILED_DUPLICATE_NAME_PAYLOAD = {
  title: "Product Update Failed,",
  text: "Product Name already exists.",
  severity: "error",
};

export const PRODUCT_UPDATE_FAILED_DUPLICATE_CODE_PAYLOAD = {
  title: "Product Update Failed,",
  text: "Product Code already exists.",
  severity: "error",
};

// Delete Configuration
export const PRODUCT_DELETE_SUCCESS_PAYLOAD = {
  title: "Product Deleted Successfully!",
  text: "You won't be able to find the Deleted Product in the table anymore",
  severity: "success",
};

export const PRODUCT_DELETE_FAILED_PAYLOAD = {
  title: "Product Deletion Failed,",
  text: "Something Went Wrong. Product deletion failed.",
  severity: "error",
};
