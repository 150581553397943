import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { omitBy, isNil } from "lodash";
import * as yup from "yup";
import { EMPTY_OPERATIONS } from "../../../../../constants/geofences";
import {
  checkIsAfter,
  checkIsBefore,
  isEqualDates,
  parseDate,
  transformers,
} from "../../../../../utils";

type ExtendedOperatingHoursType = {
  startAt: string;
  endAt: string;
  startAtFormat: string;
  endAtFormat: string;
};

export type GeofenceInitialOperations = {
  configuration: {
    operations: {
      monday: {
        operatingHours: ExtendedOperatingHoursType;
      };
      tuesday: {
        operatingHours: ExtendedOperatingHoursType;
      };
      wednesday: {
        operatingHours: ExtendedOperatingHoursType;
      };
      thursday: {
        operatingHours: ExtendedOperatingHoursType;
      };
      friday: {
        operatingHours: ExtendedOperatingHoursType;
      };
      saturday: {
        operatingHours: ExtendedOperatingHoursType;
      };
      sunday: {
        operatingHours: ExtendedOperatingHoursType;
      };
    };
  };
};

type LunchKeys = {
  lunchValue: string;
  lunchFormat: string;
};
type OperationKeys = {
  operationValue: string;
  operationFormat: string;
};

const isDateBeforeDate = (
  parent: any,
  lunchKeys: LunchKeys,
  operationKeys: OperationKeys
) => {
  const { lunch, operatingHours } = parent.value;

  const { lunchValue, lunchFormat } = lunchKeys;
  const { operationValue, operationFormat } = operationKeys;

  const lunchKeyValue = lunch?.[lunchValue];
  const lunchKeyValueFormat = lunch?.[lunchFormat];

  if (!lunchKeyValue || !lunchKeyValueFormat) return true;

  const operationKeyValue = operatingHours?.[operationValue];
  const operationKeyFormat = operatingHours?.[operationFormat];

  const operationDate = parseDate(
    `${operationKeyValue} ${operationKeyFormat}`,
    "h:mm a",
    new Date()
  );
  const lunchDate = parseDate(
    `${lunchKeyValue} ${lunchKeyValueFormat}`,
    "h:mm a",
    new Date()
  );

  return checkIsBefore(lunchDate, operationDate);
};

const sharedTimeSchema = yup
  .object()
  .optional()
  .shape({
    startAt: yup
      .string()
      .default("")
      .transform(transformers.string)
      .test(
        "startAt",
        "Start time is required if end time is provided",
        function (startAt) {
          const { startAtFormat, endAt, endAtFormat } = this.parent;
          return !(!startAt && (!!endAt || !!endAtFormat || !!startAtFormat));
        }
      )
      .test(
        "startAt",
        "Start time can't be after end time",
        function (startAt) {
          const { startAtFormat, endAt, endAtFormat } = this.parent;
          const startDateTime = parseDate(
            `${startAt} ${startAtFormat}`,
            "h:mm a",
            new Date()
          );
          const endDateTime = parseDate(
            `${endAt} ${endAtFormat}`,
            "h:mm a",
            new Date()
          );

          return (
            !checkIsAfter(startDateTime, endDateTime) &&
            !isEqualDates(startDateTime, endDateTime)
          );
        }
      )
      .test(
        "lunchBeforeStart",
        "Lunch time can't be before start time",
        function (startAt) {
          // @ts-ignore
          const [parent1, parent2, ...rest] = this.from;
          if (
            startAt !== parent2.value.lunch.startAt ||
            !startAt ||
            !parent2.value.lunch.startAtFormat
          )
            return true;

          const lunchKeys = {
            lunchValue: "startAt",
            lunchFormat: "startAtFormat",
          };
          const operationKeys = {
            operationValue: "startAt",
            operationFormat: "startAtFormat",
          };

          return !isDateBeforeDate(parent2, lunchKeys, operationKeys);
        }
      )
      .test(
        "lunchAfterEnd",
        "Lunch time can't be after end time",
        function (startAt) {
          // @ts-ignore
          const [parent1, parent2, ...rest] = this.from;
          const { startAtFormat } = this.parent;
          if (
            startAt !== parent2.value.lunch.startAt ||
            startAtFormat !== parent2.value.lunch.startAtFormat ||
            !startAt ||
            !parent2.value.lunch.startAtFormat
          )
            return true;

          const lunchKeys = {
            lunchValue: "startAt",
            lunchFormat: "startAtFormat",
          };
          const operationKeys = {
            operationValue: "endAt",
            operationFormat: "endAtFormat",
          };

          return isDateBeforeDate(parent2, lunchKeys, operationKeys);
        }
      ),
    startAtFormat: yup
      .string()
      .default("")
      .transform(transformers.string)
      .test(
        "startAtFormat",
        "Start time is required if end time is provided",
        function (startAtFormat) {
          const { startAt, endAt, endAtFormat } = this.parent;
          return !(!startAtFormat && (!!endAt || !!endAtFormat || !!startAt));
        }
      ),
    endAt: yup
      .string()
      .default("")
      .transform(transformers.string)
      .test(
        "endAt",
        "End time is required if start time is provided",
        function (endAt) {
          const { startAt, startAtFormat, endAtFormat } = this.parent;
          return !((!!startAt || !!startAtFormat || !!endAtFormat) && !endAt);
        }
      )
      .test("endAt", "End time can't be before start time", function (endAt) {
        const { startAt, startAtFormat, endAtFormat } = this.parent || {};
        const startDateTime = parseDate(
          `${startAt} ${startAtFormat}`,
          "h:mm a",
          new Date()
        );
        const endDateTime = parseDate(
          `${endAt} ${endAtFormat}`,
          "h:mm a",
          new Date()
        );

        return (
          !checkIsBefore(endDateTime, startDateTime) &&
          !isEqualDates(endDateTime, startDateTime)
        );
      })

      .test(
        "lunchEndBeforeStart",
        "Lunch time can't be before start time",
        function (endAt) {
          // @ts-ignore
          const [parent1, parent2, ...rest] = this.from;
          if (
            endAt !== parent2.value.lunch.endAt ||
            !endAt ||
            !parent2.value.lunch.endAtFormat
          )
            return true;
          const lunchKeys = {
            lunchValue: "endAt",
            lunchFormat: "endAtFormat",
          };
          const operationKeys = {
            operationValue: "startAt",
            operationFormat: "startAtFormat",
          };
          return !isDateBeforeDate(parent2, lunchKeys, operationKeys);
        }
      )
      .test(
        "lunchAfterEnd",
        "Lunch time can't be after end time",
        function (endAt) {
          // @ts-ignore
          const [parent1, parent2, ...rest] = this.from;
          if (
            endAt !== parent2.value.lunch.endAt ||
            !endAt ||
            !parent2.value.lunch.endAtFormat
          )
            return true;

          const lunchKeys = {
            lunchValue: "endAt",
            lunchFormat: "endAtFormat",
          };
          const operationKeys = {
            operationValue: "endAt",
            operationFormat: "endAtFormat",
          };
          return isDateBeforeDate(parent2, lunchKeys, operationKeys);
        }
      ),
    endAtFormat: yup
      .string()
      .default("")
      .transform(transformers.string)
      .test(
        "endAtFormat",
        "End time format is required if start time is provided",
        function (endAtFormat) {
          const { startAt, startAtFormat, endAt } = this.parent;
          return !((!!startAt || !!startAtFormat || !!endAt) && !endAtFormat);
        }
      ),
  });

export const useOperationsForm = (
  initialValues: GeofenceInitialOperations = {
    configuration: {
      operations: { ...EMPTY_OPERATIONS },
    },
  }
) => {
  const sharedOperationsSchema = yup.object().nullable().shape({
    operatingHours: sharedTimeSchema,
    lunch: sharedTimeSchema,
  });
  const schema = yup.object().shape({
    configuration: yup
      .object()
      .default({})
      .required()
      .shape({
        operations: yup.object().nullable().notRequired().shape({
          monday: sharedOperationsSchema,
          tuesday: sharedOperationsSchema,
          wednesday: sharedOperationsSchema,
          thursday: sharedOperationsSchema,
          friday: sharedOperationsSchema,
          saturday: sharedOperationsSchema,
          sunday: sharedOperationsSchema,
        }),
      }),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    values: omitBy(initialValues, isNil),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );
  return { form, getValues, schema };
};
