import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import csvtojson from "csvtojson";
import { isNil, omitBy } from "lodash";
import * as XLSX from "xlsx";
import * as yup from "yup";
import { ValidateAssetError } from "../../../../graphql/operations";
import {
  transformers,
  parseFileContent,
  ParseFileContentError,
  mapServerErrorCodeToHumanReadableMessage,
  formatDate,
  DATE_FORMAT_GLUED,
} from "../../../../utils";
import { BatchFormFieldsNames } from "../../../BatchesView/BatchManagementUtils";
import { schemaV2 } from "../../TableView/components/AssetForm";
import { AssetFileData, CreateAssetInput, ValidatedAsset } from "./types";

type MapFileDataResult = {
  validatedAssets: ValidatedAsset[];
  validationErrors: ParseFileContentError[];
};

export type CreateAssetBatchInput = {
  org_name: string;
};

export const parseAssetsFile = async (
  file: File
): Promise<{
  fileData: AssetFileData[];
  errors: ParseFileContentError[];
}> => {
  const map: { [key: string]: keyof AssetFileData } = {
    "Asset ID*": "assetId",
    "Asset Nickname": "assetNickname",
    "Device ID": "deviceId",
    "Asset Type*": "assetType",
    Tags: "tags",
    "VIN*": "vin",
    "# of Tires": "numOfTires",
    "# of Axles": "numOfAxles",
    Length: "length",
    "Door Type": "doorType",
    "Sub Asset Type": "subAssetType",
    Height: "height",
  };

  try {
    const { data, errors } = await parseFileContent<AssetFileData>({
      file,
      map,
    });
    return { fileData: data, errors };
  } catch (error) {
    let message = "Error parsing file.";
    if (error instanceof Error) {
      message = error.message;
    }
    return { fileData: [], errors: [{ row: 0, message }] };
  }
};

/**
 * Map file data to CreateAssetInput and  validate data according to the CreateAssetInput schema
 * @param data
 * @param orgs
 * @returns Mapped assets that passed validation successfully and an errors array with explanations why assets didn't pass validation
 */

export const mapFileDataToCreateAssetInput = (
  data: AssetFileData[],
  org_name: string
): MapFileDataResult => {
  const errors: ParseFileContentError[] = [];

  if (!data.length) {
    errors.push({ row: 0, message: "Uploaded file is empty" });
  }

  const mapped = data.map((fileData, index) => {
    const row = index + 1; // Add 1 to index to get row number

    try {
      const asset: CreateAssetInput = schemaV2.validateSync(
        {
          org_name,
          asset_id: fileData.assetId,
          name: fileData.assetNickname,
          imei: fileData.deviceId,
          vin: fileData.vin,
          category: fileData.assetType,
          wheel_config: fileData.numOfTires,
          num_of_axles: fileData.numOfAxles,
          length: fileData.length,
          door_type: fileData.doorType,
          sub_asset_type: fileData.subAssetType,
          height: fileData.height,
          tags: fileData.tags,
        },
        { abortEarly: false }
      );

      return { asset, row };
    } catch (error: any) {
      if (error.inner) {
        error.inner.forEach((err: yup.ValidationError) => {
          errors.push({
            row,
            message: err.message ?? "Error validating asset",
          });
        });
      } else {
        errors.push({
          row,
          message:
            error instanceof Error ? error.message : "Error validating asset",
        });
      }

      return null;
    }
  });

  return {
    validatedAssets: mapped.filter((asset): asset is ValidatedAsset => !!asset),
    validationErrors: errors,
  };
};

export const processCreateAssetsErrors = (
  serverErrors: ValidateAssetError[],
  { validatedAssets, validationErrors }: MapFileDataResult
): ParseFileContentError[] => {
  const mappedServerErrors = serverErrors.map((err) => {
    return {
      row: validatedAssets[err.index].row,
      message: mapServerErrorCodeToHumanReadableMessage(err.message),
    };
  });

  return [...validationErrors, ...mappedServerErrors].sort(
    (a, b) => a.row - b.row
  );
};

export const convertCsvToJson = async (csvContent: string) => {
  return csvtojson().fromString(csvContent);
};

/**
 * Convert a file (CSV, XLS, XLSX) to JSON
 *
 * @param {File} file - The file to convert.
 * @returns {Promise<Array<Object>>} - A Promise that resolves to an array of JSON objects.
 */
export const convertFileToJson = async (file: File): Promise<Array<object>> => {
  try {
    if (!file) {
      throw new Error("No file provided");
    }

    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (!fileExtension) {
      throw new Error("File extension could not be determined");
    }

    if (fileExtension === "csv") {
      const fileText = await file.text();
      const jsonArray = await convertCsvToJson(fileText);
      return jsonArray;
    } else if (fileExtension === "xls" || fileExtension === "xlsx") {
      const arrayBuffer = await file.arrayBuffer();
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonArray: Array<object> = XLSX.utils.sheet_to_json(worksheet);
      return jsonArray;
    } else {
      throw new Error("Unsupported file type");
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const assetsUploadFormSchema = yup.object().shape({
  [BatchFormFieldsNames.BatchName]: yup
    .string()
    .required("Batch Name is required")
    .transform(transformers.string),
  [BatchFormFieldsNames.AddToOrganization]: yup
    .object()
    .required("Company Name is required")
    .transform(transformers.string),
});

export const useAssetBatchUploadForm = (action: string, orgName?: string) => {
  const batch_name = orgName
    ? `${orgName} ${action} - ${formatDate(new Date(), DATE_FORMAT_GLUED)}`
    : "";
  const form = useForm({
    resolver: yupResolver(assetsUploadFormSchema),
    values: omitBy(
      {
        [BatchFormFieldsNames.BatchName]: batch_name,
        [BatchFormFieldsNames.AddToOrganization]: "",
      },
      isNil
    ),
  });

  const getValues = useCallback(
    () => assetsUploadFormSchema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues, assetsUploadFormSchema };
};
