import { GridColumns } from "@mui/x-data-grid-premium";

// list of columns to be displayed
export const columnVisibilityModel = {
  event: true,
  user: true,
  event_date: true,
};

export const fieldAndFieldNames: {
  [fieldName: string]: string | undefined;
} = {
  org_name: "Organization Name",
  imei: "Device ID",
  asset_name: "Asset ID",
  prd_cde: "Product Code",
  box_id: "Order Group",
  tags: "Device Tags",
  order_num: "Order #",
  sim_num: "SIM ID",
  packing_list: "Packing List",
  firmwareVersion: "Firmware Version",
  config: "Config",
  install_dt: "Installation Date",
  installer: "Installer",
};

export const DEVICE_HISTORY_SKIP_FIELDS = new Set<string | undefined>([
  "customer_orgs_id",
  "assets_id",
]);

export const SEARCH_KEYS: Array<string> = [
  "event",
  "user",
  "event_date",
  "param",
  "update",
  "eventText",
];

export const DEVICE_HISTORY_COLUMNS: GridColumns = [
  {
    field: "eventText",
    headerName: "Event",
    flex: 1,
    minWidth: 120,
  },
  { field: "user", headerName: "User", flex: 1, minWidth: 120 },
  {
    field: "event_date",
    headerName: "Event Date",
    flex: 1,
    minWidth: 120,
    type: "date",
    renderCell: (params) =>
      params.value
        ? Intl.DateTimeFormat("en-US").format(new Date(params.value))
        : "",
  },
];
