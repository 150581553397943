import { FC } from "react";
import { Typography } from "@mui/material";

type Option = {
  label: string;
  value: string;
};

interface OptionProps {
  option: Option;
  selected: boolean;
  setSelectedDefaultOption: (value: string) => void;
}

export const OptionElement: FC<OptionProps> = ({
  option,
  selected,
  setSelectedDefaultOption,
}) => {
  return (
    <Typography
      onClick={() => setSelectedDefaultOption(option.value)}
      key={option.value}
      data-testid={`default-frequency-list-option-${option.value}`}
      className={`cursor-pointer px-4 !py-2 ${
        selected ? "bg-brand text-white" : "hover:bg-brand-transparent"
      }`}
    >
      {option.label}
    </Typography>
  );
};
