import Papa from "papaparse";
import readXlsxFile from "read-excel-file";
import { MapObject, ParseWithMapOptions } from "read-excel-file/types";

export type ParseFileContentError = {
  row: number;
  message: string;
};

type ParseFileArgs<T> = {
  file: File;
  map: { [key: string]: keyof T };
  dynamicTyping?: boolean;
};

type ParseFileResult<T> = Promise<{
  data: T[];
  errors: ParseFileContentError[];
}>;

/**
 * Parse csv file using PapaParse library.
 * @see https://www.npmjs.com/package/papaparse
 * @param file File to parse
 * @param map Map of column names to object keys
 * @param dynamicTyping Whether to parse data types automatically
 */
const parseCSV = async <T extends object>({
  file,
  map,
  dynamicTyping,
}: ParseFileArgs<T>): ParseFileResult<T> => {
  const fileContent = await file.text();
  const { data, errors } = Papa.parse(fileContent, {
    header: true,
    dynamicTyping,
    skipEmptyLines: true,
  });
  // Map file column names to provided object keys.
  const parsed = data.map((item: any) => {
    const result: any = {};
    Object.keys(map).forEach((key) => {
      result[map[key]] = item[key] === "" ? undefined : item[key];
    });
    return result as T;
  });

  return {
    data: parsed,
    errors: errors
      .filter(
        (err: Papa.ParseError) =>
          err.code !== "TooFewFields" && err.code !== "TooManyFields" // Ignore errors caused by missing or extra columns.
      )
      .map(
        // Map PapaParse errors to ParseFileContentError.
        (err: Papa.ParseError): ParseFileContentError => ({
          row: err.row + 1, // PapaParse row starts at 0. @see https://www.papaparse.com/docs#row
          message: err.message,
        })
      ),
  };
};

/**
 * Parse xls/x file using read-excel-file library.
 * @see https://www.npmjs.com/package/read-excel-file
 * @param file File to parse
 * @param map Map of column names to object keys
 */
const parseXLS = async <T extends object>({
  file,
  map,
}: ParseFileArgs<T>): ParseFileResult<T> => {
  const options: ParseWithMapOptions = { map: map as MapObject };
  const { rows, errors } = await readXlsxFile<T>(file, options);

  return {
    data: rows,
    errors: errors.map(
      // Map read-excel-file errors to ParseFileContentError.
      (err): ParseFileContentError => ({
        row: err.row,
        message: `${err.error} because ${err.value} ${err.reason}`,
      })
    ),
  };
};

export const parseFileContent = <T extends object>(
  args: ParseFileArgs<T>
): ParseFileResult<T> => {
  const extension = args.file.name.split(".").pop();
  switch (extension) {
    case "csv":
      return parseCSV(args);
    case "xls":
    case "xlsx":
      return parseXLS(args);
    default: {
      throw new Error(
        "Provided file extension is not supported. Supported extensions are: csv, xls, xlsx."
      );
    }
  }
};
