import { FC, MouseEvent, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-premium";

export interface TablePopoverProps {
  isPopOver?: boolean;
}

const TablePopover: FC<TablePopoverProps> = ({ isPopOver }) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const handleClick = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div aria-describedby={id} className="border-b border-concrete !py-4">
        {
          <>
            <GridToolbarContainer
              style={{ display: isPopOver ? "none" : "flex" }}
              data-testid="table-popover-grid-toolbar-container"
            >
              <GridToolbarColumnsButton
                data-testid="table-popover-grid-toolbar-columns-btn"
                className="!text-[#1976d2]"
              />
              <GridToolbarFilterButton
                data-testid="table-popover-grid-toolbar-filter-btn"
                className="!text-[#1976d2]"
              />
              <GridToolbarDensitySelector
                data-testid="table-popover-grid-toolbar-density-selector"
                className="!text-[#1976d2]"
              />
              <GridToolbarExport
                data-testid="table-popover-grid-toolbar-export-btn"
                className="!text-[#1976d2]"
              />
            </GridToolbarContainer>
            {isPopOver && (
              <MoreVertIcon
                className="absolute -mt-11 cursor-pointer"
                sx={{ margin: "-3.2rem 1rem" }}
                onClick={handleClick}
                data-testid="table-popover-more-btn"
              />
            )}
          </>
        }
      </div>
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <GridToolbarContainer>
            <div
              style={{ display: "flex", flexDirection: "column", margin: 8 }}
            >
              <GridToolbarColumnsButton className="!text-[#1976d2]" />
              <GridToolbarFilterButton className="!text-[#1976d2]" />
              <GridToolbarDensitySelector className="!text-[#1976d2]" />
              <GridToolbarExport className="!text-[#1976d2]" />
            </div>
          </GridToolbarContainer>
        </Popover>
      </div>
    </div>
  );
};

export default TablePopover;
