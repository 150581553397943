import * as yup from "yup";
import { transformers } from "../../../../../utils";
import { GeofenceGeometryType } from "./types";

export const geofencesBulkValidationSchema = yup.object().shape({
  owner: yup.string().optional().transform(transformers.string),
  orgId: yup
    .string()
    .required("Organization ID is required")
    .transform(transformers.string),
  geofence: yup.object({
    name: yup
      .string()
      .required("Name is required")
      .transform(transformers.string),
    type: yup
      .string()
      .required("Geometry Type is required")
      .transform(transformers.string),
    tags: yup.string().optional().transform(transformers.string),
    code: yup.string().optional().transform(transformers.string),
    centroid: yup
      .object()
      .shape({
        lat: yup
          .number()
          .required("Latitude is required")
          .transform(transformers.number),
        lon: yup
          .number()
          .required("Longitude is required")
          .transform(transformers.number),
      })
      .default(undefined),
    coordinates: yup.array().when("type", {
      is: GeofenceGeometryType.Polygon,
      then: yup
        .array()
        .of(yup.array().of(yup.number()))
        .required("Polygon coordinates are required"),
      otherwise: yup.array().notRequired(),
    }),
    radius: yup.number().when("type", {
      is: GeofenceGeometryType.Circle,
      then: yup
        .number()
        .required("Radius is required")
        .transform(transformers.number),
      otherwise: yup.number().notRequired(),
    }),
    fullAddressFormatted: yup
      .string()
      .optional()
      .transform(transformers.string),
  }),
  configuration: yup.object().shape({
    typeId: yup
      .string()
      .required("Type is required")
      .transform(transformers.string),
    subTypeId: yup.string().optional().transform(transformers.string),
    parentId: yup.string().optional().transform(transformers.string),
    capacity: yup.object().shape({
      boat: yup
        .object()
        .shape({
          min: yup.number().optional().transform(transformers.number),
          max: yup.number().optional().transform(transformers.number),
        })
        .notRequired(),
      chassis: yup
        .object()
        .shape({
          min: yup.number().optional().transform(transformers.number),
          max: yup.number().optional().transform(transformers.number),
        })
        .notRequired(),
      container: yup
        .object()
        .shape({
          min: yup.number().optional().transform(transformers.number),
          max: yup.number().optional().transform(transformers.number),
        })
        .notRequired(),
      dolly: yup
        .object()
        .shape({
          min: yup.number().optional().transform(transformers.number),
          max: yup.number().optional().transform(transformers.number),
        })
        .notRequired(),
      reefer: yup
        .object()
        .shape({
          min: yup.number().optional().transform(transformers.number),
          max: yup.number().optional().transform(transformers.number),
        })
        .notRequired(),
      tractor: yup
        .object()
        .shape({
          min: yup.number().optional().transform(transformers.number),
          max: yup.number().optional().transform(transformers.number),
        })
        .notRequired(),
      trailer: yup
        .object()
        .shape({
          min: yup.number().optional().transform(transformers.number),
          max: yup.number().optional().transform(transformers.number),
        })
        .notRequired(),
      vehicle: yup
        .object()
        .shape({
          min: yup.number().optional().transform(transformers.number),
          max: yup.number().optional().transform(transformers.number),
        })
        .notRequired(),
      other: yup
        .object()
        .shape({
          min: yup.number().optional().transform(transformers.number),
          max: yup.number().optional().transform(transformers.number),
        })
        .notRequired(),
    }),
  }),
});

export type YupValidationError = yup.ValidationError;
