import { Grid, Typography, Box } from "@mui/material";
import { ReactComponent as LiftgateSensorDark } from "../../../../../../../assets/svgs/liftGateSensorDark.svg";
import { ReactComponent as LiftgateSensorLight } from "../../../../../../../assets/svgs/liftgateSensorLight.svg";
import { useAppContext } from "../../../../../../../context/AppContext";
import { themes } from "../../../../../../../shared/hooks/theme/utils";

type LiftgateHeaderProps = {
  text: string;
};

export const LiftgateHeader: React.FC<LiftgateHeaderProps> = ({ text }) => {
  const {
    state: { theme },
  } = useAppContext();

  const isLightTheme = theme.theme === themes.light;
  const svgIconSettings = {
    width: "80px",
    height: "80px",
    display: "block",
  };

  return (
    <Box className="!mb-1 justify w-full">
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <Box>
          {isLightTheme ? (
            <LiftgateSensorDark style={svgIconSettings} />
          ) : (
            <LiftgateSensorLight style={svgIconSettings} />
          )}
        </Box>

        <Box>
          <Typography
            variant="caption"
            className="!pl-2 !text-base inline-block !font-semibold !text-primary-blue"
          >
            {text}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};
