import { Box, CircularProgress } from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext/AssetsDataContext";
import AtisSensorSection from "./components/AtisSensorSection";
import PsiWheelEndSensorCardsSection from "./components/PsiWheelEndSensorCardsSection";
import PsiWheelEndSensorChartsSection from "./components/PsiWheelEndSensorChartsSection";
import RegulatorSensorSection from "./components/RegulatorSensorSection";
import TPMSCardsSection from "./components/TPMSCardsSection";
import TPMSChartsSection from "./components/TPMSChartsSection";
import DualImbalanceChartSection from "./components/charts/components/tpms/dual-imbalance-charts/DualImbalanceChartSection";
import { TiresTabProvider } from "./context";

const TiresTabPanel = () => {
  const { selectedAssetId } = useAssetsDataContext();

  const isFetchingAsset = useIsFetching({
    queryKey: ["FindAssetById", { assetId: selectedAssetId }],
  });

  // Note: Consider waiting for more than one query before showing the real content
  const isLoading = isFetchingAsset > 0;
  return (
    <TiresTabProvider>
      <Box className="min-h-full p-4" data-testid="asset-dashboard--tires-tab">
        {isLoading && (
          <Box
            className="flex h-full w-full items-center justify-center"
            data-testid="asset-dashboard--tires-tab"
          >
            <CircularProgress />
          </Box>
        )}

        <Box sx={{ display: isLoading ? "none" : "block" }}>
          <TPMSCardsSection />
          <DualImbalanceChartSection />
          <TPMSChartsSection />

          <RegulatorSensorSection />

          <PsiWheelEndSensorCardsSection />
          <PsiWheelEndSensorChartsSection />

          <AtisSensorSection />
        </Box>
      </Box>
    </TiresTabProvider>
  );
};

export default TiresTabPanel;
