import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../shared/hooks/theme/useCurrentTheme";

export const useReportViewTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "&.MuiIconButton-root": {
                width: "40px",
                height: "40px",
                padding: "5px",
              },
            },
          },
        },
        MuiInputLabel: {
          defaultProps: {
            shrink: true,
          },
          styleOverrides: {
            root: {
              fontWeight: 700,
              transform: "translate(0, -6px) scale(1)",
              fontSize: 14,
              lineHeight: "14px",
            },
            asterisk: {
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: "1.5rem",
            },
          },
        },
        MuiFormControl: {
          defaultProps: {
            variant: "standard",
          },
          styleOverrides: {
            root: {
              fontWeight: 700,
              fontSize: 14,
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: { boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16) !important" },
          },
        },
        MuiPickersPopper: {
          styleOverrides: {
            paper: { boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16) !important" },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "var(--brand-transparent) !important",
              },
              "&.Mui-selected": {
                backgroundColor: "var(--brand) !important",
                color: "white",
              },
            },
          },
        },
      },
    })
  );
};
