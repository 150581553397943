const dummySuccessHandler = () => {
  console.info("Promise safely resolved");
};

const dummyErrorHandler = (err: unknown) => {
  console.error("Promise was rejected: ", err);
};

export const resolvePromiseSafely = (
  promise: Promise<any>,
  onSuccess?: () => any,
  onError?: (err: unknown) => any
) => {
  promise
    .then(onSuccess ?? dummySuccessHandler)
    .catch(onError ?? dummyErrorHandler);
};
