import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { omitBy, isNil } from "lodash";
import { LiteSentryGammaFormValues } from "../types";
import { liteSentryGammaFormSchema } from "../utils/validationSchema";

export const liteSentryGammaFormInitialValues: LiteSentryGammaFormValues = {
  brakeCircuitSetPoints: {
    bluePowerPresentSetPoint: 10,
    bluePowerNotPresentSetPoint: 10,
    precheckSetPoint: 10,
  },
  leftTurnSignalCircuitSetPoints: {
    tractorPowerPresentSetPoint: 10,
    precheckSetPoint: 10,
  },
  rightTurnSignalCircuitSetPoints: {
    tractorPowerPresentSetPoint: 10,
    precheckSetPoint: 10,
  },
  markerCircuitSetPoints: {
    tractorPowerPresentSetPoint: 10,
    precheckSetPoint: 10,
  },
  licenseCircuitSetPoints: {
    tractorPowerPresentSetPoint: 10,
    precheckSetPoint: 10,
  },
};

export const useLiteSentryGammaForm = (
  incomingInitialValues: Partial<LiteSentryGammaFormValues> = {}
) => {
  const form = useForm<LiteSentryGammaFormValues>({
    resolver: yupResolver(liteSentryGammaFormSchema),
    mode: "all", // trigger validation on both blur and change events
    defaultValues: omitBy(
      { ...liteSentryGammaFormInitialValues, ...incomingInitialValues },
      isNil
    ),
  });

  return { form };
};
