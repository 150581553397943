import { FC, HTMLAttributes, useEffect, Fragment } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { Box, Grid, Typography } from "@mui/material";
import { omitBy, kebabCase, capitalize } from "lodash";
import { GEOFENCE_ASSET_TYPES } from "../../../../../../../constants/geofences";
import { GeofenceData } from "../../../../../../../graphql/operations";

interface GeofenceCapacityFormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onChange" | "onSubmit"> {
  onSubmit?: (data: FieldValues) => void;
  geofence?: Partial<GeofenceData>;
  onChange?: (geofence?: Partial<GeofenceData> | undefined) => void;
  form: UseFormReturn<Partial<GeofenceData>>;
  compact?: boolean;
  cancelable?: boolean;
  showDetailsHeader?: boolean;
  disableAllFields?: boolean;
  onCancel?: () => void;
}

export const GeofenceCapacityForm: FC<GeofenceCapacityFormProps> = ({
  onSubmit,
  geofence = {},
  onChange,
  form,
  compact = false,
  showDetailsHeader = false,
  cancelable = true,
  onCancel,
  disableAllFields = false,
  ...props
}: GeofenceCapacityFormProps) => {
  useEffect(() => {
    const values = omitBy(form.getValues(), (value) => value === "");
    if (form.formState.isDirty) {
      onChange?.(values);
    }
  }, [form, onChange, form.formState.isDirty]);

  return (
    <form
      {...props}
      autoComplete="off"
      onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
    >
      <Grid
        container
        className={`!text-primary ${showDetailsHeader ? "!mb-4" : ""}`}
        spacing={1}
      >
        {Object.entries(GEOFENCE_ASSET_TYPES).map(([key, value]) => {
          return (
            <Fragment key={key}>
              <Grid item xs={12} sm={2}>
                <Box
                  className="my-1 flex gap-1"
                  data-testid={`geofence-capacity-${kebabCase(value)}-label`}
                >
                  <Typography className="!text-checked">
                    {capitalize(value)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} sm={2}>
                <Box
                  className="my-1 flex flex-col items-center gap-1"
                  data-testid={`geofence-capacity-${kebabCase(
                    value
                  )}-min-label`}
                >
                  <Typography className="!text-checked">Min</Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{ display: { xs: "none", sm: "block" } }}
                sm={1}
              ></Grid>
              <Grid item xs={3} sm={2}>
                <TextFieldElement
                  disabled={disableAllFields}
                  fullWidth
                  control={form.control}
                  name={`configuration.capacity.${value}.min`}
                  data-testid={`geofence-capacity-${kebabCase(value)}-min`}
                />
              </Grid>
              <Grid
                item
                sx={{ display: { xs: "none", sm: "block" } }}
                sm={1}
              ></Grid>
              <Grid item xs={3} sm={2}>
                <Box
                  className="my-1 flex flex-col items-center gap-1"
                  data-testid={`geofence-capacity-${kebabCase(
                    value
                  )}-max-label`}
                >
                  <Typography className="!text-checked">Max</Typography>
                </Box>
              </Grid>
              <Grid item xs={3} sm={2}>
                <TextFieldElement
                  disabled={disableAllFields}
                  fullWidth
                  control={form.control}
                  name={`configuration.capacity.${value}.max`}
                  data-testid={`geofence-capacity-${kebabCase(value)}-max`}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </form>
  );
};
