import { FC } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid, Typography } from "@mui/material";
import { SensorStatusUppercase } from "../../../../../graphql/operations";

interface AirTankCardProps {
  title: string;
  status: SensorStatusUppercase;
}

export const AirTankCard: FC<AirTankCardProps> = ({ status, title }) => {
  return (
    <Grid item xs={12} data-testid="airTankCard">
      <div className=" py-4 flex items-center">
        <div className="h-[65px] w-[27px] border-2 border-transparent-gray rounded-l-xl rounded-r-sm mr-1"></div>
        <div
          data-testid="airTankCard-status"
          className="h-[100px] w-[150px] border-2 border-transparent-gray rounded-xl mr-1 flex flex-col justify-center items-center"
        >
          {status !== SensorStatusUppercase.Healthy ? (
            <WarningAmberIcon
              data-testid="airTankCard-warning-status-icon"
              className="text-warning "
            />
          ) : (
            <CheckCircleOutlineIcon
              data-testid="airTankCard-healthy-status-icon"
              className="text-success"
            />
          )}

          <Typography
            data-testid="airTankCard-title"
            className="text-base text-center font-semibold text-transparent-gray"
          >
            {title}
          </Typography>
        </div>
        <div className="h-[65px] w-[27px] border-2 border-transparent-gray rounded-r-xl rounded-l-sm"></div>
      </div>
    </Grid>
  );
};
