import classNames from "classnames";
import { TireSensorChip } from "../../../../AssetShortTooltip/components/TireSensorChip/TireSensorChip";
import { SensorChip } from "../../../../AssetShortTooltip/hooks/useGetSensorStateWidget";
import { PsiWheelEnd } from "../../../interfaces";
import { PsiWheelEndSensorChip } from "./PsiWheelEndSensorChip";

export interface WheelEndSensorChips {
  leftWheelEnd: SensorChip;
  rightWheelEnd: SensorChip;
}

export interface PsiWheelEndSensorWidgetProps {
  sensorLabel: string;
  sensorsChips: WheelEndSensorChips;
  size?: "small" | "big";
  selectedWheelEnd?: PsiWheelEnd | undefined;
}

export const PsiWheelEndSensorWidget: React.FC<
  PsiWheelEndSensorWidgetProps
> = ({ sensorLabel, sensorsChips, size = "big", selectedWheelEnd }) => {
  const { leftWheelEnd, rightWheelEnd } = sensorsChips;

  return (
    <div
      className="flex justify-between"
      data-testid="psi-wheel-end-sensor-widget"
    >
      <PsiWheelEndSensorChip
        dataTestid="psi-wheel-end--left-chip"
        position="left"
        size={size}
        isSelected={selectedWheelEnd === PsiWheelEnd.Left}
      />

      <TireSensorChip
        dataTestid="psi-wheel-end--left-tire"
        color={leftWheelEnd.color}
        icon={leftWheelEnd.icon}
        size={size}
      />

      <div
        className={classNames(
          `flex items-center`,
          {
            "w-[76px]": size === "small",
          },
          {
            "flex-grow": size === "big",
          }
        )}
      >
        <div
          className={classNames(
            `w-full front-divider leading-none font-medium border-gray text-center text-light-charcoal`,
            {
              "text-[9px]": size === "small",
              "border-b": size === "small",
            },
            {
              "text-[24px] ": size === "big",
              "border-b-4": size === "big",
            }
          )}
        >
          {sensorLabel}
        </div>
      </div>

      <TireSensorChip
        dataTestid="psi-wheel-end--right-tire"
        color={rightWheelEnd.color}
        icon={rightWheelEnd.icon}
        size={size}
      />

      <PsiWheelEndSensorChip
        dataTestid="psi-wheel-end--right-chip"
        position="right"
        size={size}
        isSelected={selectedWheelEnd === PsiWheelEnd.Right}
      />
    </div>
  );
};

export default PsiWheelEndSensorWidget;
