import { Ms2Command } from "../../graphql/operations";
import { Ms2DeviceCode } from "./types";

export const MS2_MAPPED_COMMAND_CODES: Record<Ms2Command, Ms2DeviceCode> = {
  [Ms2Command.DevicePrecheck]: "AT+XPRECHK",
  [Ms2Command.DeviceReportNow]: "AT+XRN",
  [Ms2Command.DeviceCamera]: "AT+XCMRARN",
  [Ms2Command.GladhandsLock]: "AT+XBTGHUL",
  [Ms2Command.GladhandsUnlock]: "AT+XBTGHLO",
};
