import { useState, useEffect, FC, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { isEmpty } from "lodash";
import {
  PAGE_SNACKBAR,
  PAGE_SPINNER,
  ROOT_ORGANIZATION_NAME,
} from "../../../../../constants";
import { USER_FORM_FIELDS } from "../../../../../constants/users";
import { useAppContext } from "../../../../../context/AppContext";
import {
  UserDataInput,
  UserRole,
  useCreateUserMutation,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { parsePhoneNumber } from "../../../../../utils";
import { useOrgsOptions } from "../../../../AssetsView/TableView/hooks";
import { UserFormContent } from "../UserFormContent";
import { UserRoleDropdownItem } from "../helpers";
import {
  UserFormValues,
  useUserForm,
  userFormInitialValues,
} from "../hooks/useUserForm";

export interface CreateUserProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUserCreate: () => void;
  parentOrgId: string | undefined;
  parentOrgName: string | undefined;
  isSuperAdmin: boolean;
  rolesForSuperAdminOptions: UserRoleDropdownItem[];
  rolesForClientAdminOptions: UserRoleDropdownItem[];
}

const CreateUser: FC<CreateUserProps> = ({
  open,
  setOpen,
  onUserCreate,
  isSuperAdmin,
  rolesForSuperAdminOptions,
  rolesForClientAdminOptions,
}) => {
  const { form, getValues } = useUserForm();
  const formTheme = useFormTheme();
  const breakpoints = { xs: 12 };
  const {
    formState: { dirtyFields },
    reset,
    trigger,
    watch,
  } = form;

  const selectedOrgId = watch("customer_orgs_id");
  const selectedUserRole = watch("groups");
  const [formValues, setFormValues] = useState(userFormInitialValues);

  const [showSuperAdminRoles, setShowSuperAdminRoles] =
    useState<boolean>(false);

  const [isUserCreating, setIsUserCreating] = useState<boolean>(false);
  const { dispatch } = useAppContext();

  // Fetch & prepare organisation options
  const availableOrgs = useAvailableOrgs();
  const orgOptionsSorted = useOrgsOptions(availableOrgs);
  const orgOptions = useMemo(() => {
    return orgOptionsSorted.map((org) => ({
      id: org.id ?? "",
      label: org.label ?? "",
    }));
  }, [orgOptionsSorted]);

  useEffect(() => {
    if (!isSuperAdmin) {
      return;
    }

    const isSelectedGroupSuperAdmin = selectedUserRole === UserRole.Superadmin;
    const isSelectedOrgPhillipsConnect = availableOrgs.some(
      (org) => org._id === selectedOrgId && org.name === ROOT_ORGANIZATION_NAME
    );
    /**
     * Super admin is true and user has selected Phillips Connect -
     * show Super admin role, otherwise do not show it
     */
    setShowSuperAdminRoles(isSelectedOrgPhillipsConnect);

    // make sure super admin is not selected for other orgs
    if (!isSelectedOrgPhillipsConnect && isSelectedGroupSuperAdmin) {
      form.setValue("groups", null);
    }
  }, [selectedOrgId, selectedUserRole, isSuperAdmin, form, availableOrgs]);

  useEffect(() => {
    if (open) {
      reset(userFormInitialValues);
    }
  }, [open, reset]);

  useEffect(() => {
    if (isUserCreating) {
      dispatch({
        type: PAGE_SPINNER,
        payload: { increment: 1 },
      });
    }

    return () =>
      dispatch({
        type: PAGE_SPINNER,
        payload: { increment: 0 },
      });
  }, [dispatch, isUserCreating]);

  const { mutate, isLoading } = useCreateUserMutation({
    onSuccess: (data) => {
      setFormValues(userFormInitialValues);
      setIsUserCreating(false);
      onUserCreate();
      setOpen(false);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "User created successfully!",
          text: "Your changes will be visible soon",
          severity: "success",
        },
      });
    },
    onError: (error: Error) => {
      setIsUserCreating(false);
      const displayError = error ? error.message : "Something Went Wrong.";
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "User Creation Failed",
          text: displayError,
          severity: "error",
        },
      });
    },
  });
  const isSubmitDisabled = isLoading || isEmpty(dirtyFields);

  const updateUserRoleDropdownValues = () => {
    const roleSelect = USER_FORM_FIELDS.find((obj) => obj.name === "groups");
    if (roleSelect) {
      roleSelect.options = showSuperAdminRoles
        ? rolesForSuperAdminOptions
        : rolesForClientAdminOptions;
    }
    return roleSelect;
  };
  const roleSelect = updateUserRoleDropdownValues();

  const onSubmit = async (data: FieldValues) => {
    const valid = await trigger();
    if (!valid) return;
    // dispatch post api call to create organization with payload as formValues
    setIsUserCreating(true);

    const userData = data as UserFormValues;
    const userPayload: UserDataInput = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      name: userData.name,
      email: userData.email,
      groups: userData.groups !== null ? [userData.groups] : [],
      customer_orgs_id: [userData.customer_orgs_id as string],
    };

    if (userData?.phoneNumber) {
      userPayload.phoneNumber = parsePhoneNumber(userData.phoneNumber, true);
    }

    mutate({
      user: userPayload,
    });
  };

  const handleClose = () => {
    setFormValues(userFormInitialValues);
    setOpen(false);
  };
  return (
    <Drawer
      testId="create-user-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader text="Create User Account" onClose={handleClose} />

      <DrawerContent>
        <UserFormContent
          form={form}
          availableOrgs={availableOrgs}
          roleSelect={roleSelect}
          breakpoints={breakpoints}
          formTheme={formTheme}
          orgOptions={orgOptions}
          isEditMode={false}
        />

        <DrawerActions
          cancelBtnTestId="btn-user-form-cancel"
          disabled={isLoading}
          onCancel={handleClose}
        />
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving..." : "Save"}
        disabled={isSubmitDisabled}
        testId="crete-user-form-submit-btn"
        submit={() => onSubmit(getValues())}
      />
    </Drawer>
  );
};

export default CreateUser;
