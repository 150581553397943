import { StateObject } from "../interfaces";

export const convertStateObjectToValuesArr = (obj: StateObject) => {
  let resultArr: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      resultArr.push(key);
    }
  });
  return resultArr;
};
