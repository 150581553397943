import { FC, useMemo } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportantOutlined";
import QuestionMark from "@mui/icons-material/QuestionMarkOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmberOutlined";
import { Card, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/types";
import { capitalize } from "lodash";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type AssetsHealthData = {
  count: number;
  percentage: number;
};

export type DashboardWidgetAssetsPerHealthStatusData = {
  total: number;
  healthy: AssetsHealthData;
  warning: AssetsHealthData;
  alert: AssetsHealthData;
  critical: AssetsHealthData;
  unknown: AssetsHealthData;
};

export interface DashboardWidgetAssetsPerHealthType
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetAssetsPerHealthStatusData;
}

export interface AssetsTypeWidgetProps {
  widget: DashboardWidgetAssetsPerHealthType;
}

const ColorByHealthMapping = {
  healthy: ColorsPalette.SuccessOpacity20,
  warning: ColorsPalette.CautionOpacity20,
  alert: ColorsPalette.AlertOpacity20,
  critical: ColorsPalette.ErrorOpacity20,
  unknown: ColorsPalette.Concrete,
};
type KeyToIcon = Record<
  string,
  {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
      muiName: string;
    };
    iconColor: ColorsPalette;
  }
>;

const keyToIcon: KeyToIcon = {
  healthy: { icon: CheckCircleIcon, iconColor: ColorsPalette.ReChartsGreen },
  warning: {
    icon: NotificationImportantIcon,
    iconColor: ColorsPalette.ReChartsGold,
  },
  alert: { icon: WarningAmberIcon, iconColor: ColorsPalette.ReChartsOrange },
  critical: { icon: ErrorOutlineIcon, iconColor: ColorsPalette.ReChartsRed },
  unknown: { icon: QuestionMark, iconColor: ColorsPalette.LightCharcoal },
};

const mapWidgetData = (
  statuses: Omit<DashboardWidgetAssetsPerHealthStatusData, "total">
) => {
  return Object.entries(statuses).reduce(
    (acc: HorizontalBarChartData[], [key, value]) => {
      const resultObject: HorizontalBarChartData = {
        name: capitalize(key),
        color: ColorByHealthMapping[key as keyof typeof ColorByHealthMapping],
        value: value.count,
      };

      Object.assign(resultObject, { ...keyToIcon[key] });

      acc.push(resultObject);

      return acc;
    },
    []
  );
};

export const AssetsPerHealthStatusWidget: FC<AssetsTypeWidgetProps> = ({
  widget,
}) => {
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );

    let mappedData: HorizontalBarChartData[] = [];
    if (parsedData) {
      const { total, ...statuses }: DashboardWidgetAssetsPerHealthStatusData =
        parsedData;
      mappedData = mapWidgetData(statuses);
    }

    const total = parsedData?.total ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--assets-in-dwell"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets per health
          status widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={widget.name ?? "Overall Assets Health"}
          subtitle={"Assets"}
          isLoading={isLoading}
          showXAxis={false}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
          showPercentages={true}
          showTotal={true}
          totalRecords={total}
        />
      )}
    </Card>
  );
};
