import {
  SET_BREADCRUMBS_FILTERS,
  SET_BREADCRUMBS_START_DATE,
  SET_BREADCRUMBS_END_DATE,
  SET_BREADCRUMBS_CURRENT_PAGE_RANGE,
  SET_BREADCRUMBS_MAP_SELECTED_MARKER_ID,
} from "../../../../../../../constants";
import { Action } from "../../../../../../../context/commonTypes";

export type BreadcrumbsInitialState = {
  selectedFilters: [];
  startDate: null;
  endDate: null;
  paginationLimit: null;
  selectedMapMarkerID: null;
};

export type BreadcrumbsReducerType = (
  state: BreadcrumbsInitialState | undefined,
  action: Action
) => BreadcrumbsInitialState;

export const initialBreadcrumbsState: BreadcrumbsInitialState = {
  selectedFilters: [],
  startDate: null,
  endDate: null,
  paginationLimit: null,
  selectedMapMarkerID: null,
};

export const BreadcrumbsReducer = (
  state = initialBreadcrumbsState,
  action: Action
) => {
  switch (action.type) {
    case SET_BREADCRUMBS_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload,
      };
    case SET_BREADCRUMBS_CURRENT_PAGE_RANGE:
      return {
        ...state,
        paginationLimit: action.payload,
      };
    case SET_BREADCRUMBS_MAP_SELECTED_MARKER_ID:
      return {
        ...state,
        selectedMapMarkerID: action.payload,
      };
    case SET_BREADCRUMBS_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case SET_BREADCRUMBS_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    default:
      return state;
  }
};
