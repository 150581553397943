import React, { FC, ReactNode } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface SelectComponentProps {
  value?: string;
  onChange: (event: SelectChangeEvent) => void;
  placeholder?: string;
  options: { value: string; label: string | ReactNode; disabled?: boolean }[];
  multiple?: boolean;
  renderCheckMarks?: boolean;
  name?: string;
  dataTestId?: string;
}

export const SelectComponent: FC<SelectComponentProps> = ({
  value,
  onChange,
  placeholder = "Select",
  options,
  multiple = false,
  renderCheckMarks = false,
  name,
  dataTestId,
}) => {
  const getter = name ? name : placeholder;

  return (
    <Select
      data-testid={dataTestId ? dataTestId : `select-${getter}`}
      value={value ? value : ""}
      onChange={onChange}
      placeholder={placeholder}
      multiple={multiple}
      renderValue={(selected) => {
        if (Array.isArray(selected)) {
          return selected
            .map((value) => {
              const option = options.find((option) => option.value === value);
              return option?.label;
            })
            .join(", ");
        } else {
          const option = options.find((option) => option.value === selected);
          return option?.label ?? "";
        }
      }}
    >
      {options.map((option) => {
        return (
          <MenuItem
            key={option.value}
            data-testid={`select-${getter}-${option.value}`}
            value={option.value}
            disabled={option.disabled}
          >
            {renderCheckMarks && (
              <Checkbox
                size="small"
                checked={value ? value?.indexOf(option.value) > -1 : false}
                icon={
                  <CheckBoxOutlineBlankIcon
                    className="!text-checkbox-unchecked"
                    fontSize="small"
                  />
                }
                checkedIcon={
                  <CheckBoxIcon className="!text-brand" fontSize="small" />
                }
              />
            )}
            {option.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};
