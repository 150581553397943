import { FindMatchesType } from "../../../../graphql/operations";
import { useGlobalSearchQuery } from "../../../../shared/hooks/useGlobalSearchQuery";

export function useGlobalSearchQueries(keyword: string, limit: number) {
  const {
    searchResult: users,
    isLoading: isUserDataLoading,
    setSearch: setUsersSearch,
  } = useGlobalSearchQuery(FindMatchesType.User, [], limit);

  const {
    searchResult: organizations,
    isLoading: isOrganizationDataLoading,
    setSearch: setOrganizationsSearch,
  } = useGlobalSearchQuery(FindMatchesType.Organization, [], limit);

  const {
    searchResult: assets,
    isLoading: isAssetsDataLoading,
    setSearch: setAssetsSearch,
  } = useGlobalSearchQuery(FindMatchesType.Asset, [], limit);

  const {
    searchResult: devices,
    isLoading: isDevicesDataLoading,
    setSearch: setDevicesSearch,
  } = useGlobalSearchQuery(FindMatchesType.Device, [], limit);

  const {
    searchResult: geofences,
    isLoading: isGeofencesDataLoading,
    setSearch: setGeofencesSearch,
  } = useGlobalSearchQuery(FindMatchesType.Geofence, [], limit);

  setUsersSearch(keyword);
  setOrganizationsSearch(keyword);
  setAssetsSearch(keyword);
  setDevicesSearch(keyword);
  setGeofencesSearch(keyword);

  return {
    users,
    organizations,
    assets,
    devices,
    geofences,
    isLoading:
      isUserDataLoading ||
      isOrganizationDataLoading ||
      isAssetsDataLoading ||
      isDevicesDataLoading ||
      isGeofencesDataLoading,
  };
}
