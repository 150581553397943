import { env } from "../env";

const config = {
  cognito: {
    domain: env.REACT_APP_COGNITO_DOMAIN ?? "",
    userPoolId: env.REACT_APP_COGNITO_USER_POOL_ID ?? "",
    clientId: env.REACT_APP_COGNITO_CLIENT_ID ?? "",
    identityPoolId: env.REACT_APP_COGNITO_IDENTITY_POOL_ID ?? "",
  },
  region: env.REACT_APP_REGION ?? "",
  appsync: {
    url: env.REACT_APP_APPSYNC_URL ?? "",
  },
  map: {
    satelliteView: env.REACT_APP_SATELLITE_MAP_NAME ?? "",
    regularView: env.REACT_APP_MAP_NAME ?? "",
    token: env.REACT_APP_MAPBOX_TOKEN ?? "",
    apiKey: env.REACT_APP_GOOGLE_MAPS_KEY ?? "",
  },
  accessKeyId: env.REACT_APP_AWS_ACCESS_KEY_ID ?? "",
  secretAccessKey: env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? "",
  muiPremiumLicence: env.REACT_APP_MUI_PREMIUM_LICENSE ?? "",
  theme: env.REACT_APP_THEME ?? "",
  idleTimeout: env.REACT_APP_IDLE_TIMEOUT ?? undefined,
  totalIdleThreshold: env.REACT_APP_TOTAL_IDLE_THRESHOLD ?? undefined,
};

export default config;
