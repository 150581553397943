import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last } from "lodash";
import { TPMS_CHART_DATE_RANGE_OPTIONS } from "../../../../../../../../../constants/map";
import {
  getStartOfDay,
  getSubMonths,
} from "../../../../../../../../../utils/date";
import { DEFAULT_TPMS_TRENDS_OPTION } from "../../../../../SummaryTabPanel/Charts/chartUtils";

type TiresChartHeaderProps = {
  title: string;
  subtitle: string;
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  dateRangeOption: number;
  setDateRangeOption: React.Dispatch<React.SetStateAction<number>>;
  changeDateRangeOption: (value: number) => void;
  tiresChartAxleImage?: JSX.Element;
};

const dateLabelStyle = {
  "& > label": {
    top: "-6px",
    left: "15px",
  },
};

const TiresChartHeader: React.FC<TiresChartHeaderProps> = ({
  title,
  subtitle,
  setStartDate,
  setDateRangeOption,
  startDate,
  setEndDate,
  endDate,
  dateRangeOption,
  changeDateRangeOption,
  tiresChartAxleImage,
}) => {
  return (
    <>
      <Box className="relative" data-testid="tire-chart-header-header">
        <Typography
          className="!text-lg !font-semibold text-brand"
          data-testid="tires-chart-title"
        >
          {title}
        </Typography>
        <Typography
          className="!text-xs !font-medium text-asset-info-subheader"
          data-testid="tires-chart-subtitle"
        >
          {subtitle}
        </Typography>
        <Box
          className="absolute top-0 right-0 w-11 h-12"
          data-testid="tires-chart-image"
        >
          {tiresChartAxleImage}
        </Box>
      </Box>
      <Box
        className="flex items-center justify-end gap-4 py-4"
        data-testid="tire-chart-header-body"
      >
        <Box className="flex items-center">
          <MobileDatePicker
            label="Start date"
            inputFormat="MM/dd/yyyy"
            closeOnSelect
            value={startDate}
            shouldDisableDate={(day) =>
              day < getStartOfDay(getSubMonths(new Date(), 6))
            }
            disableFuture
            onChange={(date) => {
              setStartDate(date ?? startDate);
              setDateRangeOption(
                last(TPMS_CHART_DATE_RANGE_OPTIONS)?.value! ??
                  DEFAULT_TPMS_TRENDS_OPTION
              );
            }}
            DialogProps={{
              className: "trending-tires-chart-date-picker",
            }}
            renderInput={(params) => (
              <TextField
                data-testid="tires-chart-start-date"
                variant="outlined"
                sx={dateLabelStyle}
                {...params}
              />
            )}
          />
          <span className="text-base font-normal text-primary p-4">to</span>
          <MobileDatePicker
            DialogProps={{
              className: "trending-tires-chart-date-picker",
            }}
            label="End date"
            closeOnSelect
            inputFormat="MM/dd/yyyy"
            value={endDate}
            shouldDisableDate={(day) => !!startDate && day < startDate}
            disableFuture
            onChange={(date) => {
              setEndDate(date ?? endDate);
              setDateRangeOption(
                last(TPMS_CHART_DATE_RANGE_OPTIONS)?.value! ??
                  DEFAULT_TPMS_TRENDS_OPTION
              );
            }}
            renderInput={(params) => (
              <TextField
                data-testid="tires-chart-end-date"
                variant="outlined"
                sx={dateLabelStyle}
                {...params}
              />
            )}
          />
        </Box>
        <TextField
          data-testid="tires-trends-chart-date-range-select"
          select
          value={dateRangeOption}
          variant="outlined"
          className="w-40 "
          onChange={(e) => changeDateRangeOption(+e.target.value)}
          inputProps={{
            "data-testid": "tires-trends-chart-date-range-input",
          }}
        >
          {TPMS_CHART_DATE_RANGE_OPTIONS.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              data-testid={`tires-trends-chart-date-range-select-option-${option.value}`}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </>
  );
};

export default TiresChartHeader;
