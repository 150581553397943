import { Box, Typography } from "@mui/material";
import { isNumber, kebabCase } from "lodash";
import { ReactComponent as BatterySensorDark } from "../../../../../assets/svgs/batterySensorDark.svg";
import { ReactComponent as BatterySensorLight } from "../../../../../assets/svgs/batterySensorLight.svg";
import { useAppContext } from "../../../../../context/AppContext";
import { useDateInPreferredTimezone } from "../../../../../shared/hooks/useDateInPreferredTimezone";
import { StatusColor } from "../../Assets/SummaryTabPanel/Cards/StatusCard";
import { useBatteryStatus } from "../../hooks/useBatteryStatus";

const BatteryWidget = ({
  label,
  voltage,
  date,
  unit,
  isStatusVisible,
  batteryStatusString,
}: {
  label: string;
  voltage?: number | null;
  date: Date | null;
  unit: string;
  isStatusVisible?: boolean;
  batteryStatusString?: string;
}) => {
  const batteryStatus = useBatteryStatus(isStatusVisible, batteryStatusString);
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
  };

  const dateInUserTimezone = useDateInPreferredTimezone(date);
  const textColor =
    StatusColor[
      batteryStatus.status?.toLowerCase() as keyof typeof StatusColor
    ];
  return (
    <Box
      className="rounded-lg bg-dashboard_subheader__bg px-4 py-6 h-46 min-h-full"
      data-testid={`battery-widget-${kebabCase(label)}`}
    >
      <Typography
        className="!text-lg !font-semibold !text-brand"
        data-testid="battery-widget-label"
      >
        {label}
      </Typography>

      <Box
        className="my-5 flex gap-3"
        sx={{ color: `var(--${batteryStatus.color})` }}
        data-testid="battery-widget-status"
      >
        {isNumber(voltage) ? (
          isLightTheme ? (
            <BatterySensorDark style={svgIconSettings} />
          ) : (
            <BatterySensorLight style={svgIconSettings} />
          )
        ) : null}

        <span
          style={{
            alignSelf: "center",
            color: textColor ? `var(--${textColor})` : "inherit",
          }}
        >
          {batteryStatus.status ?? ""}
        </span>
      </Box>

      <Box
        className="flex gap-1 text-battery-label"
        data-testid="battery-widget-value"
      >
        <Typography className="!text-md">Last Reading:</Typography>
        <Typography className="!text-md !font-bold">
          {isNumber(voltage) ? `${voltage.toFixed(2)}${unit}` : ""}
        </Typography>
      </Box>

      <Box
        className="flex gap-1 text-battery-label"
        data-testid="battery-widget-date"
      >
        <Typography className="!text-md">Last Reported:</Typography>
        <Typography className="!text-md">{dateInUserTimezone}</Typography>
      </Box>
    </Box>
  );
};

export default BatteryWidget;
