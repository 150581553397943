import DialogActions from "./DialogActions/DialogActions";

export const renderDialogActions = ({
  isDataUploaded,
  isDataUploading,
  onDoneClick,
  onCancelClick,
  onSaveClick,
}: {
  isDataUploading?: boolean;
  isDataUploaded?: boolean;
  onDoneClick: () => void;
  onCancelClick: () => void;
  onSaveClick: () => Promise<void>;
}) => {
  const handlers = {
    onDoneClick,
    onCancelClick,
    onSaveClick,
  };
  return (
    <DialogActions
      showCancelBtn={!isDataUploaded}
      showSaveBtn={!isDataUploaded}
      isDataUploading={isDataUploading}
      {...handlers}
    />
  );
};
