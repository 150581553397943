import React, { useState } from "react";
import { Scalars } from "../../../../../graphql/operations";
import { ModalTemplates } from "./ModalTemplates";

interface AlertNotificationModalProps {
  alertNotificationId: Scalars["ID"];
}

export const AlertNotificationModal: React.FC<AlertNotificationModalProps> = ({
  alertNotificationId,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setIsOpenModal(true);
    }
  };

  return (
    <>
      <div
        onClick={() => setIsOpenModal(true)}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
        className="text-blue text-sm font-semibold cursor-pointer"
      >
        View
      </div>

      <ModalTemplates
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        alertNotificationId={alertNotificationId}
      />
    </>
  );
};
