import { FC, useState, SyntheticEvent, useEffect, useCallback } from "react";
import Slider from "@mui/material/Slider";
import { formatNumber } from "../../../utils/formatters";

export interface RangeSliderProps {
  min?: number;
  max: number;
  defaultValues: number[];
  values: number[];
  setValues: (newValue: number[]) => void;
  step?: number;
  color?: string;
  marks: { value: number; label: string }[];
  marksMargin?: { left?: string; right?: string };
  onChange?: (newValue: number | number[]) => void;
  shouldFormatSliderNumberLabel?: boolean;
}
const RangeSlider: FC<RangeSliderProps> = ({
  min = 0,
  max,
  defaultValues = [],
  values = [],
  setValues,
  step = 1,
  color = "primary-blue",
  marks,
  marksMargin,
  onChange,
  shouldFormatSliderNumberLabel = true,
}) => {
  const biggestFourDigitNumber: number = 9999;
  const biggestThreeDigitNumber: number = 999;

  const biggerThanFourDigitsNumberDifferenceCoef: number = 0.16;
  const smallerThanFourDigitsNumberDifferenceCoef: number = 0.006;
  const smallerThanThreeDigitsNumberDifferenceCoef: number = 0.1;

  const valueLabelBasePadding: string = "-1.4rem";

  const [index1Styles, setIndex1Styles] = useState<string>("");

  const [baseDiffPercent, setBaseDiffPercent] = useState<number>(
    max > biggestFourDigitNumber
      ? biggerThanFourDigitsNumberDifferenceCoef
      : max > biggestThreeDigitNumber
      ? smallerThanFourDigitsNumberDifferenceCoef
      : smallerThanThreeDigitsNumberDifferenceCoef
  );

  const setSlidersLabelProps = (setStyles: boolean) => {
    if (setStyles) {
      setIndex1Styles(`calc(1 * ${valueLabelBasePadding})`);
    } else {
      setIndex1Styles("");
    }
  };
  // Update the slider values on any thumb change
  const handleChange = (
    event: Event,
    newValues: number | number[],
    activeThumb: number
  ) => {
    if (
      !(
        Array.isArray(newValues) &&
        newValues[0] + max * baseDiffPercent < newValues[1]
      ) ||
      !(
        Array.isArray(newValues) &&
        newValues[1] - max * baseDiffPercent > newValues[0]
      )
    ) {
      setIndex1Styles(`calc(1 * ${valueLabelBasePadding})`);
    } else {
      setIndex1Styles("");
    }

    setValues(newValues as number[]);
  };

  // Trigger parent onChange callback on thumb release
  const handleCommitChange = (
    event: Event | SyntheticEvent<Element, Event>,
    newValues: number | number[]
  ) => {
    onChange && onChange(newValues as number[]);
  };

  const sliderStyles = {
    // Color of the slider track
    "& .MuiSlider-track": {
      background: `linear-gradient(to right, var(--${color}) 100%, var(--${color}) 100%)`,
      borderColor: "transparent",
      height: 8,
      borderRadius: 4,
    },

    // Color of the slider rail
    "& .MuiSlider-rail": {
      background: "var(--border-color)",
      opacity: 1,
      height: 8,
      borderRadius: 4,
    },

    "& .MuiSlider-thumb[data-index='0']": {
      ".MuiSlider-valueLabel": {
        top: index1Styles,
      },
    },

    // Color of the thumb
    "& .MuiSlider-thumb": {
      color: "var(--asset-sensor-filters-slider-labels)",
      background: "var(--border-color)",

      "&:before": {
        boxShadow: "inherit",
      },

      // Styling of the thumb labels
      "& span": {
        background: "transparent",
        fontSize: "12px",
        fontWeight: 600,
        color: "var(--asset-sensor-filters-slider-labels)",
      },
    },

    // Styling of the marks
    "& .MuiSlider-markLabel": {
      fontSize: "12px",
      fontWeight: 500,
      color: "var(--asset-sensor-filters-slider-labels)",
    },

    // Margin of the left mark label
    "& .MuiSlider-markLabel[data-index='0']": {
      marginLeft: marksMargin?.left || "0",
    },

    // Margin of the right mark label
    "& .MuiSlider-markLabel[data-index='1']": {
      marginLeft: marksMargin?.right || "1",
    },

    // Remove the background of the marks
    "& .MuiSlider-mark": {
      background: "none",
    },
  };

  useEffect(() => {
    setSlidersLabelProps(
      !(
        Array.isArray(defaultValues) &&
        defaultValues[0] + max * baseDiffPercent < defaultValues[1]
      ) ||
        !(
          Array.isArray(defaultValues) &&
          defaultValues[1] - max * baseDiffPercent > defaultValues[0]
        )
    );
  }, [defaultValues, max, baseDiffPercent]);

  const formatSliderLabel = useCallback(
    (value: number): string => {
      // This check is to prevent unnecessary formatting of values (such as years)
      if (shouldFormatSliderNumberLabel) {
        return formatNumber(value);
      }
      return `${value}`;
    },
    [shouldFormatSliderNumberLabel]
  );

  return (
    <Slider
      value={values}
      min={min}
      max={max}
      step={step}
      marks={marks}
      sx={sliderStyles}
      valueLabelDisplay="on"
      data-testid="range-slider"
      valueLabelFormat={formatSliderLabel}
      onChange={handleChange}
      onChangeCommitted={handleCommitChange}
    />
  );
};

export default RangeSlider;
