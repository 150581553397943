import { FC, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import {
  AutocompleteElement,
  CheckboxElement,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { Chip, Grid } from "@mui/material";
import { useAppContext } from "../../../../context/AppContext";
import { useFindAssetsByIdsOsQuery } from "../../../../graphql/operations";
import { useFindAssetListOptions } from "../../../../shared/hooks/useFindAssetListOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  isFindAssetByIdQueryEnabled,
  onFindAssetsByIdsOSCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";

const THRESHOLD_UNIT_OPTIONS = [
  { id: "hours", label: "Hours" },
  { id: "days", label: "Days" },
];

interface AlertDwellParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const AlertDwellParameters: FC<AlertDwellParametersProps> = ({
  form,
}: AlertDwellParametersProps) => {
  const { state } = useAppContext();
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const [searchKeyword, setSearchKeyword] = useState(
    form.getValues().name ?? ""
  );
  const [assetsOptions, setAssetsOptions] = useState<
    { id: string; label: string }[]
  >([]);
  const assetTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);

  const watchThresholdAssets = useWatch({
    name: "parameters.assetIds",
    control: form.control,
  });
  const watchThresholdAssetType = useWatch({
    name: "parameters.assetType",
    control: form.control,
  });
  let shouldBeEnabled = isFindAssetByIdQueryEnabled(watchThresholdAssets);

  const {
    data: foundAsset,
    isLoading: findAssetLoading,
    isSuccess: findAssetSuccess,
  } = useFindAssetsByIdsOsQuery(
    { assetIds: getAlertMultiselectIds(watchThresholdAssets) ?? [] },
    {
      enabled: shouldBeEnabled,
      refetchOnMount: true,
      staleTime: 0,
    }
  );

  useEffect(() => {
    if (foundAsset && !findAssetLoading && findAssetSuccess) {
      onFindAssetsByIdsOSCallback({ data: foundAsset, form, setSelectedAsset });
    }
  }, [foundAsset, findAssetLoading, findAssetSuccess, form, shouldBeEnabled]);

  useEffect(() => {
    form.setValue("parameters.assetIds", selectedAsset);
  }, [form, selectedAsset]);

  const { options: searchResult, isFetching: isGetAssetsForListFetching } =
    useFindAssetListOptions(searchKeyword, state.appConfig.searchOptionsLimit);

  useEffect(() => {
    setAssetsOptions(searchResult);
  }, [searchResult]);

  const hideInputValue =
    !selectedAsset &&
    !!watchThresholdAssets?.length &&
    findAssetLoading &&
    !findAssetSuccess;

  return (
    <AlertParametersContainer>
      <Grid item xs={12} md={6} lg={6} xl={4} data-testid="dwell-assets-select">
        <AutocompleteElement
          autocompleteProps={{
            noOptionsText: "No options",
            onInputChange: (event, newInputValue) => {
              if (newInputValue) {
                setSearchKeyword(newInputValue);
              }
            },
            onChange: (event, newInputValue) => {
              if (newInputValue) {
                form.setValue("parameters.assetIds", newInputValue);
                setSelectedAsset(newInputValue);
                setAssetsOptions([]);
              }
            },
            autoComplete: true,
            blurOnSelect: true,
            disabled: !!watchThresholdAssetType,
            readOnly: !!watchThresholdAssetType,
            renderTags: (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={
                    hideInputValue || !option.label ? "loading" : option.label
                  }
                  {...getTagProps({ index })}
                />
              )),
          }}
          multiple
          loading={isGetAssetsForListFetching || hideInputValue}
          label="Asset(s)"
          control={form.control}
          name="parameters.assetIds"
          options={assetsOptions}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="dwell-asset-type-select"
      >
        <AutocompleteElement
          autocompleteProps={{
            disabled: !!watchThresholdAssets?.length,
            readOnly: !!watchThresholdAssets?.length,
          }}
          matchId={true}
          label="Asset Type"
          control={form.control}
          name="parameters.assetType"
          options={assetTypeOptions}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        data-testid="alert-parameters-dwell-threshold"
      >
        <TextFieldElement
          fullWidth
          control={form.control}
          name="parameters.dwellThreshold"
          label="Dwell Threshold"
          type="number"
          InputProps={{ inputProps: { step: "0.1" } }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        data-testid="alert-parameters-dwell-threshold-format"
      >
        <SelectElement
          fullWidth
          placeholder="Select"
          name="parameters.dwellThresholdUnit"
          data-testid="alert-parameters-dwell-threshold-unit"
          label="Threshold Unit"
          control={form.control}
          options={THRESHOLD_UNIT_OPTIONS}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        data-testid="alert-parameters-dwell-inside-geofence"
      >
        <CheckboxElement
          name="parameters.insideGeofence"
          control={form.control}
          label="Inside Geofence"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        data-testid="alert-parameters-dwell-outside-geofence"
      >
        <CheckboxElement
          name="parameters.outsideGeofence"
          control={form.control}
          label="Outside Geofence"
        />
      </Grid>
    </AlertParametersContainer>
  );
};
