import {
  Exact,
  CreateOrganizationSensorProfileMutation,
  UpdateOrganizationSensorProfileMutation,
  CreateOrganizationSensorProfileInput,
  UpdateOrganizationSensorProfileInput,
  DeleteSensorProfileMutation,
  DeleteSensorProfileInput,
  useCreateOrganizationSensorProfileMutation,
  useDeleteSensorProfileMutation,
  useUpdateOrganizationSensorProfileMutation,
} from "../../../graphql/operations";

interface ConfigurationSetsApiInput {
  createSensorProfileOnSuccess?: (
    data: CreateOrganizationSensorProfileMutation,
    variables: Exact<{
      input: CreateOrganizationSensorProfileInput;
    }>,
    context: unknown
  ) => unknown;
  createSensorProfileOnError?: (error: Error) => unknown;

  updateSensorProfileOnSuccess?: (
    data: UpdateOrganizationSensorProfileMutation,
    variables: Exact<{
      input: UpdateOrganizationSensorProfileInput;
    }>,
    context: unknown
  ) => unknown;
  updateSensorProfileOnError?: (error: Error) => unknown;

  deleteSensorProfileOnSuccess?: (
    data: DeleteSensorProfileMutation,
    variables: Exact<{
      input: DeleteSensorProfileInput;
    }>,
    context: unknown
  ) => unknown;
  deleteSensorProfileOnError?: (error: Error) => unknown;
}

interface ConfigurationSetsApiHook {
  isLoadingCreateSensorProfile: boolean;
  createSensorProfile: (input: CreateOrganizationSensorProfileInput) => unknown;

  updateSensorProfile: (input: UpdateOrganizationSensorProfileInput) => unknown;
  isLoadingUpdateSensorProfile: boolean;

  deleteSensorProfile: (input: DeleteSensorProfileInput) => unknown;
  isLoadingDeleteSensorProfile: boolean;
  isSuccessDeleteSensorProfile: boolean;
}

export const useSensorProfilesApi = ({
  createSensorProfileOnSuccess,
  createSensorProfileOnError,

  updateSensorProfileOnSuccess,
  updateSensorProfileOnError,

  deleteSensorProfileOnSuccess,
  deleteSensorProfileOnError,
}: ConfigurationSetsApiInput): ConfigurationSetsApiHook => {
  const {
    mutate: createSensorProfile,
    isLoading: isLoadingCreateSensorProfile,
  } = useCreateOrganizationSensorProfileMutation({
    onSuccess: createSensorProfileOnSuccess,
    onError: createSensorProfileOnError,
  });

  const {
    mutate: updateSensorProfile,
    isLoading: isLoadingUpdateSensorProfile,
  } = useUpdateOrganizationSensorProfileMutation({
    onSuccess: updateSensorProfileOnSuccess,
    onError: updateSensorProfileOnError,
  });

  const {
    mutate: deleteSensorProfile,
    isLoading: isLoadingDeleteSensorProfile,
    isSuccess: isSuccessDeleteSensorProfile,
  } = useDeleteSensorProfileMutation({
    onSuccess: deleteSensorProfileOnSuccess,
    onError: deleteSensorProfileOnError,
  });

  return {
    updateSensorProfile: (input: UpdateOrganizationSensorProfileInput) => {
      return updateSensorProfile({ input });
    },
    isLoadingUpdateSensorProfile,
    deleteSensorProfile: (input: DeleteSensorProfileInput) => {
      return deleteSensorProfile({ input });
    },
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile: (input: CreateOrganizationSensorProfileInput) => {
      return createSensorProfile({ input });
    },
    isLoadingCreateSensorProfile,
  };
};
