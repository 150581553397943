import { get } from "lodash";
import { useAuthContext } from "../../context/AuthContext";
import { userPermissions } from "../../permissions/permissions";

export const useUserPermission = (actionPath: string) => {
  const { userInfo } = useAuthContext();
  const getUserRole = () => {
    if (userInfo) {
      const [userRole] = userInfo.groups;
      return userRole;
    } else {
      return false;
    }
  };
  const allowedRoles = get(userPermissions, actionPath);
  if (!allowedRoles) return false;
  return allowedRoles.includes(getUserRole());
};
