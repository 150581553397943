export enum SessionStorageItem {
  CodeDeliveryDestination = "codeDeliveryDestination",
  AccessToken = "accessToken",
  IdToken = "idToken",
  RefreshToken = "refreshToken",
  ExpiresIn = "expiresIn",
  Password = "password",
  Session = "session",
  Username = "username",
  SecretCodeForAuthApp = "SecretCodeForAuthApp",
}
