import { AggregatedEventHistory } from "../../../../../../graphql/operations";

export type CargoCameraFloorSpaceData = {
  eventHistoryData?: Partial<Pick<
    AggregatedEventHistory,
    "date" | "cargoCamera" | "mediaActivity"
  > | null>;
} | null;

export enum InternalCameraFloorSpaceStatus {
  Loaded = "loaded",
  Empty = "empty",
  Unknown = "unknown",
}
