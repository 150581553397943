import { useEffect, useMemo, useState } from "react";
import { camelCase } from "lodash";

type DOTResult = {
  Value: string | null;
  ValueId: string | null;
  Variable: string;
  VariableId: number;
};

export type VehicleData = {
  errorCode: string;
  manufacturerName: string;
  model: string;
  modelYear: string;
  vehicleType: string;
};

export const useDOTInfoByVin = (
  vin: string | undefined | null
): VehicleData | null => {
  const [results, setResults] = useState<VehicleData | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const updatedResult: VehicleData = {
          errorCode: "",
          manufacturerName: "",
          model: "",
          modelYear: "",
          vehicleType: "",
        };
        const url = vin
          ? `https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${vin}/?format=json`
          : "";
        let res;

        if (vin) {
          res = await fetch(url, {
            headers: {
              mode: "cors",
            },
          });
        } else {
          res = await Promise.resolve({
            ok: true,
            json: () => Promise.resolve({ Results: [] }),
          });
        }
        if (!res.ok) {
          throw new Error("Failed to fetch data");
        }

        const jsonData = await res.json();
        const vehicleInfo =
          jsonData.Results &&
          jsonData.Results.map(
            (item: DOTResult) =>
              item.Variable &&
              camelCase(item.Variable.replace("(", "").replace(")", ""))
          );
        vehicleInfo?.forEach((key: string) => {
          const matchingRecord: DOTResult = jsonData.Results.find(
            (item: DOTResult) =>
              key === camelCase(item.Variable.replace("(", "").replace(")", ""))
          );
          if (
            jsonData.Results.some(
              (item: DOTResult) =>
                key ===
                camelCase(item.Variable.replace("(", "").replace(")", ""))
            ) &&
            key in updatedResult
          ) {
            updatedResult[key as keyof VehicleData] =
              (matchingRecord && matchingRecord.Value) ?? "";
          }
        });
        setResults(updatedResult);
      } catch (error) {
        console.error(error);
        setResults(null);
      }
    };

    fetchData();
  }, [vin]);

  return useMemo(() => results, [results]);
};

export default useDOTInfoByVin;
