import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../../../../../shared/hooks/theme/useCurrentTheme";

export const useLightsTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiTextField: {
          defaultProps: {
            variant: "outlined",
          },
        },
        MuiFormHelperText: {
          defaultProps: {
            variant: "outlined",
          },
        },
        MuiInputLabel: {
          defaultProps: {
            variant: "outlined",
          },
        },
      },
    })
  );
};
