import { FC, useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { ColorsPalette } from "../../../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  AlertsPerSubOrgData,
  useGetDashboardWidgetQuery,
} from "../../../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../../../shared/utils";

export interface DashboardWidgetAlertsPerSuborg
  extends GetDashboardWidgetResponse {
  data: AlertsPerSubOrgData;
}

export interface AlertsPerSuborgWidgetProps {
  widget: DashboardWidgetAlertsPerSuborg;
}

export const mapWidgetData = (
  data: AlertsPerSubOrgData
): HorizontalBarChartData[] =>
  data?.alertsPerOrgData?.map(({ orgName, percentage, totalCount }) => ({
    name: orgName,
    value: totalCount,
    percent: percentage,
    color: mapChartColors(orgName),
  })) ?? [];

const mapChartColors = (orgName: string) =>
  orgName === "Other" ? "var(--concrete)" : `${ColorsPalette.ReChartsPurple}`;

export const AlertsPerSuborgWidget: FC<AlertsPerSuborgWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: AlertsPerSubOrgData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.totalCount ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData?.getDashboardWidget?.data]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--alerts-per-suborg"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the alerts per
          suborganization widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          subtitle="Total Number Of Alerts"
          showTotal={true}
          isLoading={isLoading}
          showPercentages={true}
          totalRecords={total}
          data={data}
          showXAxis={false}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
