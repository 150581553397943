import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "../../permissions/RequireAuth";
import DeviceDashboard from "./DeviceDashboard/DeviceDashboard";
import DeviceManagement from "./DeviceManagement";

const DeviceManagementView = () => {
  return (
    <Routes>
      <Route
        path=":id"
        element={
          <RequireAuth>
            <DeviceDashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/"
        element={
          <RequireAuth>
            <DeviceManagement />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
DeviceManagementView.displayName = "DeviceManagementView";
export default memo(DeviceManagementView);
