import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../hooks/theme/useCurrentTheme";

export const useTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiAccordion: {
          styleOverrides: {
            root: {
              "&:before": {
                display: "none",
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              padding: "0px",
              minHeight: "auto",
              lineHeight: "36px",
            },
            content: {
              fontSize: "16px",
              fontWeight: "400",
              margin: "0px",
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              padding: "0px",
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              padding: "0px",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              overflowY: "hidden",
              backgroundColor: "var(--dashboard_subheader__bg)",
              backgroundImage: "none",
            },
          },
        },
      },
    })
  );
};
