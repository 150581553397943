import { FC, ReactElement } from "react";
import { get } from "lodash";
import { useAuthContext } from "../../../context/AuthContext";
import { userPermissions } from "../../../permissions/permissions";

interface AuthorizedComponentProps {
  actionPath: string;
  children: ReactElement;
}

export const AuthorizedComponent: FC<AuthorizedComponentProps> = ({
  children,
  actionPath,
}) => {
  const { userInfo } = useAuthContext();
  const getUserRole = () => {
    if (userInfo) {
      const [userRole] = userInfo.groups;
      return userRole;
    } else {
      return undefined;
    }
  };
  const allowedRoles = get(userPermissions, actionPath);
  if (!allowedRoles) return null;
  const isUserAuthorized = allowedRoles.includes(getUserRole());
  return isUserAuthorized ? children : null;
};
