import {
  LiteSentryGammaScenarioInput,
  LiteSentryGammaSensorConfigInput,
  Maybe,
  SensorThresholdShort,
  LiteSentryGammaThresholds as LiteSentryGammaThresholdsType,
  LiteSentryGammaScenarioThresholdShort,
} from "../../../../../../../graphql/operations";
import {
  extractReversedRules,
  prepareReversedPayload,
} from "../../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
} from "../../../../../../../shared/helpers/battery";
import { LiteSentryGammaScenario } from "../../../../../../../shared/helpers/liteSentryGamma";
import { ExcludeNull } from "../../../../../../../types/utilityTypes";
import {
  LightsSettingsState,
  mapLightningStateToThresholds,
} from "../../../../../../AssetsView/MapView/Shared/LightsSettings/utils";
import { liteSentryGammaFormInitialValues } from "../hooks/useLiteSentryGammaForm";
import {
  BrakeCircuitThresholdsAndSetPoints,
  LeftSignalCicruitThresholdsAndSetPoints,
  LicenseCicruitThresholdsAndSetPoints,
  LiteSentryGammaFormValues,
  LiteSentryGammaSensorProfiles,
  LiteSentryGammaThresholds,
  MarkerCicruitThresholdsAndSetPoints,
  ParsedLiteSentryGammaSensorProfiles,
  RightSignalCicruitThresholdsAndSetPoints,
} from "../types";

export const getLiteSentryGammaProfiles = (
  liteSentryGammaProfilesConfig:
    | Maybe<LiteSentryGammaThresholdsType>
    | undefined
): LiteSentryGammaSensorProfiles => {
  const brakeCircuitProfiles = {
    bluePowerPresentConfig:
      liteSentryGammaProfilesConfig?.[LiteSentryGammaScenario.BluePowerPresent],
    bluePowerNotPresentConfig:
      liteSentryGammaProfilesConfig?.[
        LiteSentryGammaScenario.BluePowerNotPresent
      ],
    precheckConfig:
      liteSentryGammaProfilesConfig?.[
        LiteSentryGammaScenario.BrakeCircuitPrecheck
      ],
  };

  const rightTurnSignalCircuitProfiles = {
    tractorPowerConfig:
      liteSentryGammaProfilesConfig?.[
        LiteSentryGammaScenario.RightTurnSignalTractorPower
      ],
    precheckConfig:
      liteSentryGammaProfilesConfig?.[
        LiteSentryGammaScenario.RightTurnSignalPrecheck
      ],
  };

  const leftTurnSignalCircuitProfiles = {
    tractorPowerConfig:
      liteSentryGammaProfilesConfig?.[
        LiteSentryGammaScenario.LeftTurnSignalTractorPower
      ],
    precheckConfig:
      liteSentryGammaProfilesConfig?.[
        LiteSentryGammaScenario.LeftTurnSignalPrecheck
      ],
  };

  const markerCircuitProfiles = {
    tractorPowerConfig:
      liteSentryGammaProfilesConfig?.[
        LiteSentryGammaScenario.MarkerTractorPower
      ],
    precheckConfig:
      liteSentryGammaProfilesConfig?.[LiteSentryGammaScenario.MarkerPrecheck],
  };

  const licenseCircuitProfiles = {
    tractorPowerConfig:
      liteSentryGammaProfilesConfig?.[
        LiteSentryGammaScenario.LicenseTractorPower
      ],
    precheckConfig:
      liteSentryGammaProfilesConfig?.[LiteSentryGammaScenario.LicensePrecheck],
  };

  const liteSentrySensorProfiles = {
    brakeCircuitProfiles,
    leftTurnSignalCircuitProfiles,
    rightTurnSignalCircuitProfiles,
    markerCircuitProfiles,
    licenseCircuitProfiles,
  };

  return liteSentrySensorProfiles as LiteSentryGammaSensorProfiles;
};

export const getBrakeCircuitThresholdsAndSetPoints = (
  brakeCircuitProfiles: LiteSentryGammaSensorProfiles["brakeCircuitProfiles"]
): BrakeCircuitThresholdsAndSetPoints => {
  const brakeCircuitThresholds = {
    bluePowerPresentThresholds: extractReversedRules(
      brakeCircuitProfiles.bluePowerPresentConfig as unknown as SensorThresholdShort
    ),
    bluePowerNotPresentThresholds: extractReversedRules(
      brakeCircuitProfiles.bluePowerNotPresentConfig as unknown as SensorThresholdShort
    ),
    precheckThresholds: extractReversedRules(
      brakeCircuitProfiles.precheckConfig as unknown as SensorThresholdShort
    ),
  };

  const brakeCircuitSetPoints = {
    bluePowerPresentSetPoint:
      brakeCircuitProfiles.bluePowerPresentConfig?.setPoint ??
      liteSentryGammaFormInitialValues.brakeCircuitSetPoints
        .bluePowerPresentSetPoint,
    bluePowerNotPresentSetPoint:
      brakeCircuitProfiles.bluePowerNotPresentConfig?.setPoint ??
      liteSentryGammaFormInitialValues.brakeCircuitSetPoints
        .bluePowerNotPresentSetPoint,
    precheckSetPoint:
      brakeCircuitProfiles.precheckConfig?.setPoint ??
      liteSentryGammaFormInitialValues.brakeCircuitSetPoints.precheckSetPoint,
  };

  return { brakeCircuitThresholds, brakeCircuitSetPoints };
};

export const getLeftSignalCicruitThresholdsAndSetPoints = (
  leftTurnSignalCircuitProfiles: LiteSentryGammaSensorProfiles["leftTurnSignalCircuitProfiles"]
): LeftSignalCicruitThresholdsAndSetPoints => {
  const leftTurnSignalThresholds = {
    tractorPowerPresentThresholds: extractReversedRules(
      leftTurnSignalCircuitProfiles.tractorPowerConfig as unknown as SensorThresholdShort
    ),
    precheckThresholds: extractReversedRules(
      leftTurnSignalCircuitProfiles.precheckConfig as unknown as SensorThresholdShort
    ),
  };

  const leftTurnSignalCircuitSetPoints = {
    tractorPowerPresentSetPoint:
      leftTurnSignalCircuitProfiles.tractorPowerConfig?.setPoint ??
      liteSentryGammaFormInitialValues.leftTurnSignalCircuitSetPoints
        .tractorPowerPresentSetPoint,
    precheckSetPoint:
      leftTurnSignalCircuitProfiles.precheckConfig?.setPoint ??
      liteSentryGammaFormInitialValues.leftTurnSignalCircuitSetPoints
        .precheckSetPoint,
  };

  return { leftTurnSignalThresholds, leftTurnSignalCircuitSetPoints };
};

export const getRightSignalCicruitThresholdsAndSetPoints = (
  rightTurnSignalCircuitProfiles: LiteSentryGammaSensorProfiles["rightTurnSignalCircuitProfiles"]
): RightSignalCicruitThresholdsAndSetPoints => {
  const rightTurnSignalThresholds = {
    tractorPowerPresentThresholds: extractReversedRules(
      rightTurnSignalCircuitProfiles.tractorPowerConfig as unknown as SensorThresholdShort
    ),
    precheckThresholds: extractReversedRules(
      rightTurnSignalCircuitProfiles.precheckConfig as unknown as SensorThresholdShort
    ),
  };

  const rightTurnSignalCircuitSetPoints = {
    tractorPowerPresentSetPoint:
      rightTurnSignalCircuitProfiles.tractorPowerConfig?.setPoint ??
      liteSentryGammaFormInitialValues.rightTurnSignalCircuitSetPoints
        .tractorPowerPresentSetPoint,
    precheckSetPoint:
      rightTurnSignalCircuitProfiles.precheckConfig?.setPoint ??
      liteSentryGammaFormInitialValues.rightTurnSignalCircuitSetPoints
        .precheckSetPoint,
  };

  return { rightTurnSignalThresholds, rightTurnSignalCircuitSetPoints };
};

export const getMarkerCicruitThresholdsAndSetPoints = (
  markerCircuitProfiles: LiteSentryGammaSensorProfiles["markerCircuitProfiles"]
): MarkerCicruitThresholdsAndSetPoints => {
  const markerCircuitThresholds = {
    tractorPowerPresentThresholds: extractReversedRules(
      markerCircuitProfiles.tractorPowerConfig as unknown as SensorThresholdShort
    ),
    precheckThresholds: extractReversedRules(
      markerCircuitProfiles.precheckConfig as unknown as SensorThresholdShort
    ),
  };

  const markerCircuitSetPoints = {
    tractorPowerPresentSetPoint:
      markerCircuitProfiles.tractorPowerConfig?.setPoint ??
      liteSentryGammaFormInitialValues.markerCircuitSetPoints
        .tractorPowerPresentSetPoint,
    precheckSetPoint:
      markerCircuitProfiles.precheckConfig?.setPoint ??
      liteSentryGammaFormInitialValues.markerCircuitSetPoints.precheckSetPoint,
  };

  return { markerCircuitThresholds, markerCircuitSetPoints };
};

export const getLicenseCicruitThresholdsAndSetPoints = (
  licenseCircuitProfiles: LiteSentryGammaSensorProfiles["licenseCircuitProfiles"]
): LicenseCicruitThresholdsAndSetPoints => {
  const licenseCircuitThresholds = {
    tractorPowerPresentThresholds: extractReversedRules(
      licenseCircuitProfiles.tractorPowerConfig as unknown as SensorThresholdShort
    ),
    precheckThresholds: extractReversedRules(
      licenseCircuitProfiles.precheckConfig as unknown as SensorThresholdShort
    ),
  };

  const licenseCircuitSetPoints = {
    tractorPowerPresentSetPoint:
      licenseCircuitProfiles.tractorPowerConfig?.setPoint ??
      liteSentryGammaFormInitialValues.licenseCircuitSetPoints
        .tractorPowerPresentSetPoint,
    precheckSetPoint:
      licenseCircuitProfiles.precheckConfig?.setPoint ??
      liteSentryGammaFormInitialValues.licenseCircuitSetPoints.precheckSetPoint,
  };

  return { licenseCircuitThresholds, licenseCircuitSetPoints };
};

export const parseLiteSentryGammaProfiles = (
  liteSentryGammaProfilesConfig:
    | Maybe<LiteSentryGammaThresholdsType>
    | undefined
): ParsedLiteSentryGammaSensorProfiles => {
  const {
    brakeCircuitProfiles,
    leftTurnSignalCircuitProfiles,
    rightTurnSignalCircuitProfiles,
    markerCircuitProfiles,
    licenseCircuitProfiles,
  } = getLiteSentryGammaProfiles(liteSentryGammaProfilesConfig);
  const { brakeCircuitThresholds, brakeCircuitSetPoints } =
    getBrakeCircuitThresholdsAndSetPoints(brakeCircuitProfiles);

  const { leftTurnSignalThresholds, leftTurnSignalCircuitSetPoints } =
    getLeftSignalCicruitThresholdsAndSetPoints(leftTurnSignalCircuitProfiles);

  const { rightTurnSignalThresholds, rightTurnSignalCircuitSetPoints } =
    getRightSignalCicruitThresholdsAndSetPoints(rightTurnSignalCircuitProfiles);

  const { markerCircuitThresholds, markerCircuitSetPoints } =
    getMarkerCicruitThresholdsAndSetPoints(markerCircuitProfiles);

  const { licenseCircuitThresholds, licenseCircuitSetPoints } =
    getLicenseCicruitThresholdsAndSetPoints(licenseCircuitProfiles);

  const liteSentryGammaSetPoints: LiteSentryGammaFormValues = {
    brakeCircuitSetPoints,
    leftTurnSignalCircuitSetPoints,
    rightTurnSignalCircuitSetPoints,
    markerCircuitSetPoints,
    licenseCircuitSetPoints,
  };

  const thresholdsAndSetPoints = {
    liteSentryGammaThresholds: {
      brakeCircuitThresholds,
      leftTurnSignalThresholds,
      rightTurnSignalThresholds,
      markerCircuitThresholds,
      licenseCircuitThresholds,
    },
    liteSentryGammaSetPoints,
  };

  return thresholdsAndSetPoints;
};
//handles thresholds like strings  from asset settings

export const getProfileConfig = (
  setPoint: number,
  thresholds: number[]
): LiteSentryGammaScenarioInput => {
  const profileConfigInput = {
    setPoint,
    match: prepareReversedPayload(
      undefined,
      thresholds,
      MinValuesBySensorType.liteSentryGamma,
      MaxValuesBySensorType.liteSentryGamma
    ),
  };

  return profileConfigInput;
};

export const getBrakeCircuitConfigInput = (
  brakeCircuitSetPoints: LiteSentryGammaFormValues["brakeCircuitSetPoints"],
  brakeCircuitThresholds: ExcludeNull<
    LiteSentryGammaThresholds["brakeCircuitThresholds"]
  >
): LiteSentryGammaSensorProfiles["brakeCircuitProfiles"] => {
  const {
    bluePowerPresentThresholds,
    bluePowerNotPresentThresholds,
    precheckThresholds: brakeCircuitPrecheckThresholds,
  } = brakeCircuitThresholds;

  const {
    bluePowerPresentSetPoint,
    bluePowerNotPresentSetPoint,
    precheckSetPoint,
  } = brakeCircuitSetPoints;

  const bluePowerPresentConfig = getProfileConfig(
    bluePowerPresentSetPoint,
    bluePowerPresentThresholds
  );

  const bluePowerNotPresentConfig = getProfileConfig(
    bluePowerNotPresentSetPoint,
    bluePowerNotPresentThresholds
  );

  const brakeCircuitPrecheckConfig = getProfileConfig(
    precheckSetPoint,
    brakeCircuitPrecheckThresholds
  );

  return {
    bluePowerPresentConfig,
    bluePowerNotPresentConfig,
    precheckConfig: brakeCircuitPrecheckConfig,
  };
};

export const getLeftTurnSignalCircuitConfigInput = (
  leftTurnSignalCircuitSetPoints: LiteSentryGammaFormValues["leftTurnSignalCircuitSetPoints"],
  leftTurnSignalCircuitThresholds: ExcludeNull<
    LiteSentryGammaThresholds["leftTurnSignalThresholds"]
  >
): LiteSentryGammaSensorProfiles["leftTurnSignalCircuitProfiles"] => {
  const { tractorPowerPresentThresholds, precheckThresholds } =
    leftTurnSignalCircuitThresholds;

  const { tractorPowerPresentSetPoint, precheckSetPoint } =
    leftTurnSignalCircuitSetPoints;

  const leftTurnSignalTractorPowerConfig = getProfileConfig(
    tractorPowerPresentSetPoint,
    tractorPowerPresentThresholds
  );

  const leftTurnSignalPrecheckConfig = getProfileConfig(
    precheckSetPoint,
    precheckThresholds
  );

  return {
    tractorPowerConfig: leftTurnSignalTractorPowerConfig,
    precheckConfig: leftTurnSignalPrecheckConfig,
  };
};

export const getRightTurnSignalCircuitConfigInput = (
  rightTurnSignalCircuitSetPoints: LiteSentryGammaFormValues["rightTurnSignalCircuitSetPoints"],
  rightTurnSignalCircuitThresholds: ExcludeNull<
    LiteSentryGammaThresholds["rightTurnSignalThresholds"]
  >
): LiteSentryGammaSensorProfiles["rightTurnSignalCircuitProfiles"] => {
  const { tractorPowerPresentThresholds, precheckThresholds } =
    rightTurnSignalCircuitThresholds;

  const { tractorPowerPresentSetPoint, precheckSetPoint } =
    rightTurnSignalCircuitSetPoints;

  const rightTurnSignalTractorPowerConfigInput = getProfileConfig(
    tractorPowerPresentSetPoint,
    tractorPowerPresentThresholds
  );

  const rightTurnSignalPrecheckConfigInput = getProfileConfig(
    precheckSetPoint,
    precheckThresholds
  );

  return {
    tractorPowerConfig: rightTurnSignalTractorPowerConfigInput,
    precheckConfig: rightTurnSignalPrecheckConfigInput,
  };
};

export const getMarkerCircuitConfigInput = (
  markerCircuitSetPoints: LiteSentryGammaFormValues["markerCircuitSetPoints"],
  markerCircuitThresholds: ExcludeNull<
    LiteSentryGammaThresholds["markerCircuitThresholds"]
  >
): LiteSentryGammaSensorProfiles["markerCircuitProfiles"] => {
  const { tractorPowerPresentThresholds, precheckThresholds } =
    markerCircuitThresholds;

  const { tractorPowerPresentSetPoint, precheckSetPoint } =
    markerCircuitSetPoints;

  const markerCircuitTractorPowerConfigInput = getProfileConfig(
    tractorPowerPresentSetPoint,
    tractorPowerPresentThresholds
  );

  const markerCircuitPrecheckConfigInput = getProfileConfig(
    precheckSetPoint,
    precheckThresholds
  );

  return {
    tractorPowerConfig: markerCircuitTractorPowerConfigInput,
    precheckConfig: markerCircuitPrecheckConfigInput,
  };
};

export const getLicenseCircuitConfigInput = (
  licenseCircuitSetPoints: LiteSentryGammaFormValues["licenseCircuitSetPoints"],
  licenseCircuitThresholds: ExcludeNull<
    LiteSentryGammaThresholds["markerCircuitThresholds"]
  >
): LiteSentryGammaSensorProfiles["licenseCircuitProfiles"] => {
  const { tractorPowerPresentThresholds, precheckThresholds } =
    licenseCircuitThresholds;

  const { tractorPowerPresentSetPoint, precheckSetPoint } =
    licenseCircuitSetPoints;

  const licenseCircuitTractorPowerConfigInput = getProfileConfig(
    tractorPowerPresentSetPoint,
    tractorPowerPresentThresholds
  );

  const licenseCircuitPrecheckConfigInput = getProfileConfig(
    precheckSetPoint,
    precheckThresholds
  );

  return {
    tractorPowerConfig: licenseCircuitTractorPowerConfigInput,
    precheckConfig: licenseCircuitPrecheckConfigInput,
  };
};

export const prepareLiteSentryGammaProfileConfigInput = (
  brakeCircuitConfigInput: LiteSentryGammaSensorProfiles["brakeCircuitProfiles"],
  leftTurnSignalCircuitConfigInput: LiteSentryGammaSensorProfiles["leftTurnSignalCircuitProfiles"],
  rightTurnSignalCircuitConfigInput: LiteSentryGammaSensorProfiles["rightTurnSignalCircuitProfiles"],
  markerCircuitConfigInput: LiteSentryGammaSensorProfiles["markerCircuitProfiles"],
  licenseCircuitConfigInput: LiteSentryGammaSensorProfiles["licenseCircuitProfiles"]
): LiteSentryGammaSensorConfigInput => {
  const {
    bluePowerPresentConfig,
    bluePowerNotPresentConfig,
    precheckConfig: brakeCircuitPrecheckConfig,
  } = brakeCircuitConfigInput;

  const {
    tractorPowerConfig: leftTurnSignalTractorPowerConfig,
    precheckConfig: leftTurnSignalPrecheckConfig,
  } = leftTurnSignalCircuitConfigInput;
  const {
    tractorPowerConfig: rightTurnSignalTractorPowerConfig,
    precheckConfig: rightTurnSignalPrecheckConfig,
  } = rightTurnSignalCircuitConfigInput;
  const {
    tractorPowerConfig: markerCircuitTractorPowerConfig,
    precheckConfig: markerCircuitPrecheckConfig,
  } = markerCircuitConfigInput;
  const {
    tractorPowerConfig: licenseCircuitTractorPowerConfig,
    precheckConfig: licenseCircuitPrecheckConfig,
  } = licenseCircuitConfigInput;

  return {
    // Brake (red line) - Scenario ID 0 - Tractor power with Blue power present
    [LiteSentryGammaScenario.BluePowerPresent]: bluePowerPresentConfig,
    // Right (green line) - Scenario ID 1 - Tractor power
    [LiteSentryGammaScenario.RightTurnSignalTractorPower]:
      rightTurnSignalTractorPowerConfig,
    // License (black line) - Scenario ID 2 - Tractor power
    [LiteSentryGammaScenario.LicenseTractorPower]:
      licenseCircuitTractorPowerConfig,
    // Left (yellow line) - Scenario ID 3 - Tractor power
    [LiteSentryGammaScenario.LeftTurnSignalTractorPower]:
      leftTurnSignalTractorPowerConfig,
    // Marker (brown line) - Scenario ID 4 - Tractor power
    [LiteSentryGammaScenario.MarkerTractorPower]:
      markerCircuitTractorPowerConfig,
    // Brake (red line) - Scenario ID 5 - Tractor power without Blue power present
    [LiteSentryGammaScenario.BluePowerNotPresent]: bluePowerNotPresentConfig,
    // Brake (red line) - Scenario ID 6 - No power, Precheck
    [LiteSentryGammaScenario.BrakeCircuitPrecheck]: brakeCircuitPrecheckConfig,
    // Right (green line) - Scenario ID 7 - No power, Precheck
    [LiteSentryGammaScenario.RightTurnSignalPrecheck]:
      rightTurnSignalPrecheckConfig,
    // License (black line) - Scenario ID 8 - No power, Precheck
    [LiteSentryGammaScenario.LicensePrecheck]: licenseCircuitPrecheckConfig,
    // Left (yellow line) - Scenario ID 9 - No power, Precheck
    [LiteSentryGammaScenario.LeftTurnSignalPrecheck]:
      leftTurnSignalPrecheckConfig,
    // Marker (brown line) - Scenario ID 10/A - No power, Precheck
    [LiteSentryGammaScenario.MarkerPrecheck]: markerCircuitPrecheckConfig,
  };
};

export const prepareLiteSentryGammaConfig = (
  liteSentryGammaFormData: LiteSentryGammaFormValues,
  state: LightsSettingsState
): LiteSentryGammaSensorConfigInput => {
  const {
    brakeCircuitSetPoints,
    leftTurnSignalCircuitSetPoints,
    rightTurnSignalCircuitSetPoints,
    markerCircuitSetPoints,
    licenseCircuitSetPoints,
  } = liteSentryGammaFormData;

  const thresholds = mapLightningStateToThresholds(state);

  const brakeCircuitConfigInput = getBrakeCircuitConfigInput(
    brakeCircuitSetPoints,
    thresholds.brakeCircuitThresholds
  );

  const leftTurnSignalCircuitConfigInput = getLeftTurnSignalCircuitConfigInput(
    leftTurnSignalCircuitSetPoints,
    thresholds.leftTurnSignalThresholds
  );

  const rightTurnSignalCircuitConfigInput =
    getRightTurnSignalCircuitConfigInput(
      rightTurnSignalCircuitSetPoints,
      thresholds.rightTurnSignalThresholds
    );

  const markerCircuitConfigInput = getMarkerCircuitConfigInput(
    markerCircuitSetPoints,
    thresholds.markerCircuitThresholds
  );

  const licenseCircuitConfigInput = getLicenseCircuitConfigInput(
    licenseCircuitSetPoints,
    thresholds.licenseCircuitThresholds
  );
  return prepareLiteSentryGammaProfileConfigInput(
    brakeCircuitConfigInput,
    leftTurnSignalCircuitConfigInput,
    rightTurnSignalCircuitConfigInput,
    markerCircuitConfigInput,
    licenseCircuitConfigInput
  );
};
