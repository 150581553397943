import { useMemo } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutlineOutlined";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportantOutlined";
import QuestionMark from "@mui/icons-material/QuestionMarkOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmberOutlined";
import { Card, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import { useGetDashboardWidgetQuery } from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type DashboardWidgetDevicesPerBatteryLevelData = {
  devicesPerBatteryLevel: AssetsPerBatteryStatusData[];
};

export interface AssetsPerBatteryStatusData {
  _id: string;
  total_count: number;
  percentage?: number;
}

export interface AssetsPerBatteryStatus {
  data: DashboardWidgetDevicesPerBatteryLevelData;
  id: number;
  key: string;
  name: string;
}

export interface AssetsPerBatteryStatusProps {
  widget: AssetsPerBatteryStatus;
}

export const mapBatteryLevelWidgetData = (
  data: DashboardWidgetDevicesPerBatteryLevelData
): HorizontalBarChartData[] => {
  if (!data) {
    return [];
  }
  // determine order of the elements
  const order = ["Healthy", "Warning", "Alert", "Critical", "Unknown"];

  // sort
  const sortedData = data.devicesPerBatteryLevel.sort((a, b) => {
    const indexA = order.indexOf(a._id || "Unknown");
    const indexB = order.indexOf(b._id || "Unknown");
    return indexA - indexB;
  });

  // map
  return sortedData.map((item) => {
    const icon = mapWidgetIcon(item._id ?? "");
    const barColor = mapBarColor(item._id ?? "");
    const iconColor = mapIconColor(item._id ?? "");
    return {
      name: item._id ?? "",
      value: item.total_count,
      percent: item.percentage,
      color: barColor,
      icon: icon,
      iconColor: iconColor,
    };
  });
};
const mapBarColor = (label: string): string => {
  const defaultColor = ColorsPalette.Concrete;
  switch (label) {
    case "Healthy":
      return ColorsPalette.SuccessOpacity20;
    case "Warning":
      return ColorsPalette.CautionOpacity20;
    case "Alert":
      return ColorsPalette.AlertOpacity20;
    case "Critical":
      return ColorsPalette.ErrorOpacity20;
    case "Unknown":
      return ColorsPalette.Concrete;
    default:
      return defaultColor;
  }
};
const mapIconColor = (label: string): string => {
  const defaultColor = ColorsPalette.LightCharcoal;
  switch (label) {
    case "Healthy":
      return ColorsPalette.ReChartsGreen;
    case "Warning":
      return ColorsPalette.ReChartsGold;
    case "Alert":
      return ColorsPalette.ReChartsOrange;
    case "Critical":
      return ColorsPalette.ReChartsRed;
    case "Unknown":
      return ColorsPalette.LightCharcoal;
    default:
      return defaultColor;
  }
};

const mapWidgetIcon = (
  label: string
): OverridableComponent<SvgIconTypeMap<{}, "svg">> => {
  switch (label) {
    case "Healthy":
      return CheckCircleIcon;
    case "Warning":
      return NotificationImportantIcon;
    case "Alert":
      return WarningAmberIcon;
    case "Critical":
      return ErrorOutlineIcon;
    default:
      return QuestionMark;
  }
};

export const DevicesByBatteryLevel: React.FC<AssetsPerBatteryStatusProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const data = useMemo(() => {
    const parsedData: DashboardWidgetDevicesPerBatteryLevelData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapBatteryLevelWidgetData(parsedData);

    return mappedData;
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--devices-per-battery-level"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the devices per battery
          level widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          showPercentages={true}
          isLoading={isLoading}
          data={data}
          showXAxis={false}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
