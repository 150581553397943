import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  LOCATION_CHANGE,
  SELECTED_ORGANIZATION,
  SET_SELECTED_ORGANIZATION,
} from "../../../../constants";
import { useAppContext } from "../../../../context/AppContext";
import { InitialDashboardSequence } from "../../../../enums/dashboards";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { PageTypes } from "../../../../views/AssetsView/shared/AssetsDataContext";
import { isNilOrEmpty } from "../../../../views/AssetsView/utils";
import { useAvailableOrgs } from "../../../hooks/useAvailableOrgs";

export type UseSearchButtonHandlersProps = {
  handleHistoryItemAdd: (item: string) => void;
  dispatch: (action: { type: string; payload: string }) => void;
  pageType: PageTypes;
  navigate: (path: string) => void;
  onChangeFilters: (filters: { imeiId: string; assetId: string }) => void;
  currentFilter: { imeiId: string };
};

type SearchButtonHandlers = {
  handleChangeLocation: (location: string) => void;
  handleChangeAsset: (asset: string) => void;
  handleChangeDevice: (device: string) => void;
  handleChangeOrganization: (
    organizationId: string,
    organizationName?: string
  ) => void;
  handleChangeUser: (user: string) => void;
  handleChangePage: (page: string) => void;
  handleChangeGeofence: (geofence: string) => void;
};

const PageTypesToNavigationRoutes = {
  [PageTypes.AssetMap]: NavigationRoutes.AssetMap,
  [PageTypes.AssetTable]: NavigationRoutes.AssetTable,
  [PageTypes.Geofences]: NavigationRoutes.Geofences,
  [PageTypes.Geofencing]: NavigationRoutes.Geofences,
  [PageTypes.AssetGallery]: NavigationRoutes.AssetGallery,
  [PageTypes.ShareAssetsInvitation]: NavigationRoutes.SharedAssetInvitation,
};

export const useSearchButtonHandlers = ({
  handleHistoryItemAdd,
  dispatch,
  pageType,
  navigate,
  onChangeFilters,
  currentFilter,
}: UseSearchButtonHandlersProps): SearchButtonHandlers => {
  const {
    dispatch: appContextDispatch,
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const [searchParams] = useSearchParams();
  const [_, setCookie] = useCookies([SELECTED_ORGANIZATION]);
  const availableOrgs = useAvailableOrgs();

  const handleChangeLocation = useCallback(
    (value: string) => {
      if (!isEmpty(value)) {
        handleHistoryItemAdd(value);
        dispatch({
          type: LOCATION_CHANGE,
          payload: value,
        });
      }
      const url = PageTypesToNavigationRoutes[pageType];

      navigate(url);
    },
    [navigate, handleHistoryItemAdd, pageType, dispatch]
  );

  const handleChangeAsset = useCallback(
    (asset: string) => {
      navigate(
        `${NavigationRoutes.AssetTable}/${asset}${
          !isNilOrEmpty(searchParams.toString())
            ? `?${searchParams.toString()}`
            : ""
        }`
      );
    },
    [searchParams, navigate]
  );

  const handleChangeDevice = useCallback(
    (imei: string) => {
      if (!imei) return;

      if (currentFilter?.imeiId !== imei) {
        onChangeFilters({ imeiId: imei, assetId: "" });
      }

      handleHistoryItemAdd(imei);
      navigate(`${NavigationRoutes.Devices}?search=${imei}`);
    },
    [currentFilter, onChangeFilters, handleHistoryItemAdd, navigate]
  );

  const handleChangeOrganization = useCallback(
    (organizationId: string, organizationName?: string) => {
      if (organizationId) {
        const orgData = availableOrgs.find((org) => org._id === organizationId);
        const dashboardId =
          orgData?.default_dashboard ??
          InitialDashboardSequence.OrganizationDashboard;
        const dashboardPath = `${NavigationRoutes.Dashboard}/${dashboardId}?orgId=${organizationId}`;

        handleHistoryItemAdd(organizationId);
        navigate(dashboardPath);
        if (
          organizationName &&
          selectedOrganization?.value.toString() !== organizationId.toString()
        ) {
          const newOrg = {
            value: organizationId,
            label: organizationName,
          };
          appContextDispatch({
            type: SET_SELECTED_ORGANIZATION,
            payload: newOrg,
          });
          setCookie(SELECTED_ORGANIZATION, newOrg, { path: "/" });
        }
      }
    },
    [
      handleHistoryItemAdd,
      navigate,
      appContextDispatch,
      setCookie,
      selectedOrganization?.value,
      availableOrgs,
    ]
  );

  const handleChangeUser = useCallback(
    (value: string) => {
      if (value) {
        handleHistoryItemAdd(value);
      }
      // serialize search string to be able to use it in url
      const searchStr = new URLSearchParams({ search: value }).toString();
      navigate(`${NavigationRoutes.AdminPanelUsers}?${searchStr}`);
    },
    [handleHistoryItemAdd, navigate]
  );

  const handleChangePage = useCallback(
    (value: string) => {
      navigate(value);
    },
    [navigate]
  );

  const handleChangeGeofence = useCallback(
    (geofence: string) => {
      if (geofence) {
        handleHistoryItemAdd(geofence);
        navigate(`${NavigationRoutes.Geofences}/${geofence}`);
      }
    },
    [handleHistoryItemAdd, navigate]
  );

  return {
    handleChangeLocation,
    handleChangeAsset,
    handleChangeDevice,
    handleChangeOrganization,
    handleChangeUser,
    handleChangePage,
    handleChangeGeofence,
  };
};
