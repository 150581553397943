import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetsDetentionTableDataInput,
  FileFormat,
  FindAssetDetentionByAssetIdOsQuery,
  GetEventHistoryTableDataInput,
  Pagination,
  AssetDetentionTableData,
  SortOrder,
  TableDomain,
  TableViewType,
  useFindAssetDetentionByAssetIdOsQuery,
} from "../../../../../graphql/operations";
import Spinner from "../../../../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
  StringTableFilterValue,
} from "../../../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../../../shared/components/Table/BackEndProcessingTable";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import {
  getEndOfToday,
  getStartOfYesterday,
  getSubMonths,
} from "../../../../../utils/date";
import { columnVisibilityModel, getColumns } from "./columns";

export type DetentionHistoryTableProps = {
  selectedAssetId: string;
  customerOrgId?: string;
};

export const DetentionHistoryTable: FC<DetentionHistoryTableProps> =
  memo<DetentionHistoryTableProps>(
    ({ selectedAssetId }: { selectedAssetId: string }) => {
      const apiRef = useGridApiRef();
      const {
        state: { appConfig },
      } = useAppContext();
      const timezone = usePreferredTimezone();
      const columns = useMemo(() => getColumns(timezone), [timezone]);
      const [isExporting, setIsExporting] = useState(false);
      const [fileFormat, setFileFormat] = useState<FileFormat>(
        FileFormat.Excel
      );
      const [isSendingEmail, setIsSendingEmail] = useState(false);

      const [queryInput, setQueryInput] =
        useState<AssetsDetentionTableDataInput>({
          asset_id: selectedAssetId,
          startDate: getSubMonths(getStartOfYesterday(), 6),
          endDate: getEndOfToday(),
          sorting: [{ field: "eventDate", order: SortOrder.Desc }],
          pagination: {
            skip: 0,
            limit: appConfig.table.defaultRowsPerPage,
          },
        });

      const { data, isSuccess, isLoading, isRefetching } =
        useFindAssetDetentionByAssetIdOsQuery<
          FindAssetDetentionByAssetIdOsQuery,
          unknown
        >(
          {
            input: queryInput,
          },
          {
            enabled: Boolean(selectedAssetId),
          }
        );

      const updateQueryInput = useCallback(
        (data: Partial<GetEventHistoryTableDataInput>) =>
          setQueryInput((prev: AssetsDetentionTableDataInput) => ({
            ...prev,
            ...data,
          })),
        [setQueryInput]
      );

      useTableDataExporter<AssetDetentionTableData>({
        queryInput,
        apiRef,
        columns,
        domain: TableDomain.AssetsDetention,
        fileFormat,
        isExporting,
        setExporting: setIsExporting,
        totalCount: data?.findAssetDetentionByAssetIdOS?.pagination.total,
      });

      const handleExport = useCallback((format: ServerSideExportFormat) => {
        if (format === ServerSideExport.EMAIL) {
          setIsSendingEmail(true);
        } else {
          setFileFormat(format);
          setIsExporting(true);
        }
      }, []);

      return (
        <Box className="h-full w-full px-4 pt-8 pb-4 md:px-6 md:pt-0 md:pb-3 lg:px-16">
          <BackEndProcessingTable
            domain={TableDomain.AssetsDetention}
            queryInput={queryInput}
            apiRef={apiRef}
            tableType={TableViewType.AssetsDetention}
            tableName={"asset-detention-history"}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            data={{
              rows: (data?.findAssetDetentionByAssetIdOS
                ?.assetDetentionTableData ?? []) as AssetDetentionTableData[],
              pagination:
                data?.findAssetDetentionByAssetIdOS?.pagination ?? undefined,
            }}
            updateQueryInput={updateQueryInput}
            sorting={queryInput.sorting ?? undefined}
            isDataRefetching={isRefetching}
            onExport={handleExport}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isSendingEmail={isSendingEmail}
            setSendingEmail={setIsSendingEmail}
            rowsPerPage={data?.findAssetDetentionByAssetIdOS?.pagination?.limit}
            totalCount={data?.findAssetDetentionByAssetIdOS?.pagination?.total}
            tableFiltersToSkip={[
              StringTableFilterValue.DoesNotContain,
              StringTableFilterValue.IsAnyOf,
            ]}
          />
          <Spinner counter={Number(isExporting) || Number(isSendingEmail)} />
        </Box>
      );
    }
  );
