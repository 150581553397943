import { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";

export interface HeaderOptionsProps {
  isEditMode?: boolean;
  onWidgetDelete?: () => void;
}

const HeaderOptions: React.FC<HeaderOptionsProps> = ({
  isEditMode,
  onWidgetDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isActionsMenuOpen = Boolean(anchorEl);

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActionsMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteWidget = () => {
    if (onWidgetDelete) {
      onWidgetDelete();
    }

    closeActionsMenu();
  };

  if (!isEditMode) {
    return null;
  }

  return (
    <div data-testid="dashboard-widget-options">
      <IconButton
        aria-label="Options"
        id="dashboard-widget-options-button"
        data-testid="dashboard-widget-options-button"
        aria-controls={
          isActionsMenuOpen ? "dashboard-widget-options-menu" : undefined
        }
        aria-expanded={isActionsMenuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={openActionsMenu}
        sx={{ padding: 0 }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="dashboard-widget-options-menu"
        data-testid="dashboard-widget-options-menu"
        MenuListProps={{
          "aria-labelledby": "dashboard-widget-options-button",
        }}
        anchorEl={anchorEl}
        open={isActionsMenuOpen}
        onClose={closeActionsMenu}
      >
        <MenuItem onClick={handleDeleteWidget}>
          <DeleteOutlineIcon /> Delete Widget
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HeaderOptions;
