import { memo, SVGProps, FC } from "react";

interface ImagePlaceholderProps extends SVGProps<SVGSVGElement> {}

const ImagePlaceholder: FC<ImagePlaceholderProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.2663 5.33333V42.6667H5.93294V5.33333H43.2663ZM43.2663 0H5.93294C2.99961 0 0.599609 2.4 0.599609 5.33333V42.6667C0.599609 45.6 2.99961 48 5.93294 48H43.2663C46.1996 48 48.5996 45.6 48.5996 42.6667V5.33333C48.5996 2.4 46.1996 0 43.2663 0ZM30.3063 23.6267L22.3063 33.9467L16.5996 27.04L8.59961 37.3333H40.5996L30.3063 23.6267Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ImagePlaceholder);
