import {
  PsiAirSupplySensorDetails,
  SensorStatusUppercase,
} from "../../../../../graphql/operations";

export interface AirTankSummaryData {
  title: string;
  status: SensorStatusUppercase;
  pressure: number;
  tripStatus: string | null;
  lastReported: Date;
}

export const parseAirTankSummary = (
  psiAirSupply: PsiAirSupplySensorDetails,
  trip_st?: string | null
): AirTankSummaryData => {
  const { receivedDate, data } = psiAirSupply;
  // Note: Currently we support only one Air Tank sensor per asset. In the future, we should change the title dinamicaly
  return {
    title: "Air Tank Sensor",
    status:
      psiAirSupply?.data?.statusTankPressure ?? SensorStatusUppercase.Unknown,
    pressure: data?.tankPressure ?? 0,
    tripStatus: trip_st ?? null,
    lastReported: new Date(receivedDate),
  };
};
