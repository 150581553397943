import { FC } from "react";
import { UseFormReturn, Controller } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { Grid, TextField } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers-pro";
import { parseISO } from "date-fns";
import { DATE_TIME_FORMAT_SHORT } from "../../../../utils/";
import { AlertFormValues } from "../../interfaces";
import { metricsOptions, operatorOptions } from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";

interface AlertMetricsParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const AlertMetricsParameters: FC<AlertMetricsParametersProps> = ({
  form,
}) => {
  return (
    <AlertParametersContainer>
      <Grid item xs={12} md={6} lg={6} xl={4} data-testid="metric-select">
        <AutocompleteElement
          label="Metric"
          options={metricsOptions}
          name="parameters.metric"
          control={form.control}
          matchId={true}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} xl={4} data-testid="operator-select">
        <AutocompleteElement
          label="Operator"
          options={operatorOptions}
          name="parameters.operator"
          control={form.control}
          matchId={true}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} xl={4} data-testid="value-input">
        <TextFieldElement
          label="Value"
          name="parameters.value"
          type={"number"}
          control={form.control}
          fullWidth
        />
      </Grid>

      {/* Start Date Picker */}
      <Grid item xs={12} md={6} lg={6} xl={4}>
        <Controller
          name="parameters.startDate"
          control={form.control}
          defaultValue={new Date().toISOString()}
          render={({ field: { onChange, value, ...field } }) => (
            <MobileDateTimePicker
              {...field}
              value={value ? new Date(value) : null}
              onChange={(date) => {
                onChange(date?.toISOString());
              }}
              shouldDisableDate={(date) => {
                const endDate = parseISO(form.getValues("parameters.endDate"));

                return date > endDate;
              }}
              label="Start At"
              inputFormat={DATE_TIME_FORMAT_SHORT}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  data-testid="start-date-input"
                />
              )}
            />
          )}
        />
      </Grid>

      {/* End Date Picker */}
      <Grid item xs={12} md={6} lg={6} xl={4}>
        <Controller
          name="parameters.endDate"
          control={form.control}
          defaultValue={new Date().toISOString()}
          render={({ field: { onChange, value, ...field } }) => (
            <MobileDateTimePicker
              {...field}
              value={value ? new Date(value) : null}
              shouldDisableDate={(date) => {
                const startDate = parseISO(
                  form.getValues("parameters.startDate")
                );

                return date < startDate;
              }}
              onChange={(date) => {
                onChange(date?.toISOString());
              }}
              label="End At"
              inputFormat={DATE_TIME_FORMAT_SHORT}
              renderInput={(params) => (
                <TextField {...params} fullWidth data-testid="end-date-input" />
              )}
            />
          )}
        />
      </Grid>
    </AlertParametersContainer>
  );
};
