import { FC } from "react";
import { Card, CardContent } from "@mui/material";
import { useGetSensorStateWidget } from "../../../../AssetShortTooltip/hooks/useGetSensorStateWidget";
import {
  WHEEL_END_CARD_HEIGHT,
  defaultBorderStyling,
} from "../../../constants";
import { useTiresTabContext } from "../../../context";
import { PsiWheelEndAxle, TPMS_Axle } from "../../../interfaces";
import { AssetWheelEndsSensorState } from "./AxlesSummaryCards";
import { PsiWheelEndSensorWidget } from "./PsiWheelEndSensorWidget";

export interface AxleSummaryCardProps {
  axle: PsiWheelEndAxle;
  wheelEndsSensorsState: AssetWheelEndsSensorState;
}

const AxleSummaryCard: FC<AxleSummaryCardProps> = ({
  axle,
  wheelEndsSensorsState,
}) => {
  const { getWheelEndSensorsChips } = useGetSensorStateWidget();
  const { selectedPsiWheelEndAxle: selectedAxle, selectedPsiWheelEnd } =
    useTiresTabContext();

  // Determine the state of the axle
  const wheelEndSensors = getWheelEndSensorsChips(wheelEndsSensorsState, {
    iconWidth: "26px",
    iconHeight: "26px",
  });

  return (
    <Card
      className={`background-white border min-w-[280px] items-center flex`}
      sx={{
        boxShadow: "none",
        borderRadius: "8px",
        border: defaultBorderStyling,
        height: WHEEL_END_CARD_HEIGHT,
      }}
      data-testid="asset-dashboard--tires-tab--psi-wheel-end--axle-card-summary"
    >
      <CardContent className="w-full">
        <PsiWheelEndSensorWidget
          sensorLabel={axle}
          sensorsChips={wheelEndSensors}
          size="big"
          selectedWheelEnd={
            selectedAxle === axle ? selectedPsiWheelEnd : undefined
          }
        />
      </CardContent>
    </Card>
  );
};

export default AxleSummaryCard;
