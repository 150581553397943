import { SchedulingRepeat } from "../../../utils/scheduling";

export const getPeriod = (frequency: SchedulingRepeat | undefined) => {
  switch (frequency) {
    case SchedulingRepeat.Daily:
      return "day(s)";
    case SchedulingRepeat.Weekly:
      return "week(s)";
    case SchedulingRepeat.Monthly:
      return "month(s)";
    default:
      return "";
  }
};
