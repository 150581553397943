import { Maybe } from "yup/lib/types";
import { GeofenceData } from "../../../../../../graphql/operations";
import { convertTimeTo12Hour } from "../../../../../ReportView/helpers/helpers";
import { DetailItem } from "../types";

type Day =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";
const DAYS_OF_WEEK: Day[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

function getOperatingHours(geofence: Maybe<GeofenceData>, day: Day) {
  const dayOperatingHours =
    geofence?.configuration?.operations?.[day]?.operatingHours;
  const timeFrom = dayOperatingHours?.startAt;
  const timeTo = dayOperatingHours?.endAt;
  return {
    timeFrom: timeFrom ? convertTimeTo12Hour(timeFrom) : "-",
    timeTo: timeTo ? convertTimeTo12Hour(timeTo) : "-",
  };
}
function getLunchHours(geofence: Maybe<GeofenceData>, day: Day) {
  const dayLunchHours = geofence?.configuration?.operations?.[day]?.lunch;
  const timeFrom = dayLunchHours?.startAt;
  const timeTo = dayLunchHours?.endAt;
  return {
    timeFromLunch: timeFrom ? convertTimeTo12Hour(timeFrom) : "",
    timeToLunch: timeTo ? convertTimeTo12Hour(timeTo) : "",
  };
}

export function getGeofenceHoursInfo(geofence: Maybe<GeofenceData>) {
  const detailList: Array<DetailItem<string | number>> = [];

  DAYS_OF_WEEK.forEach((day) => {
    const { timeFrom, timeTo } = getOperatingHours(geofence, day);
    const { timeFromLunch, timeToLunch } = getLunchHours(geofence, day);
    detailList.push({
      label: day.charAt(0).toUpperCase() + day.slice(1), // Capitalize first letter
      detailItemValue:
        timeFrom + ", " + timeTo + ", " + timeFromLunch + ", " + timeToLunch,
      truncateText: true,
    });
  });

  return detailList;
}
