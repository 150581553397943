import * as yup from "yup";
import { LITE_SENTRY_GAMMA_FORM_FIELDS } from "../../sensorsUtils";

export const LiteSentryGammaDrawerSchema = yup.object().shape({
  typeOfAssignment: yup.string().required("Field is required!").nullable(),
  liteSentryGammaSensorProfile: yup
    .string()
    .nullable()
    .when("typeOfAssignment", {
      is: (value: string) => {
        return value === LITE_SENTRY_GAMMA_FORM_FIELDS[0].options[1].id;
      },
      then: yup.string().required("Field is required!").nullable(),
      otherwise: yup.string().nullable().notRequired(),
    }),
});
