import { FC, memo } from "react";
import { Box } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { Table } from "../../../shared/components/Table";
import {
  SEARCH_KEYS,
  columnVisibilityModel,
  DEVICE_HISTORY_COLUMNS,
} from "./constants";
import { useDeviceHistoryTableViewController } from "./useDeviceHistoryTableViewController";

export interface DeviceHistoryTableProps {
  device_id: string | undefined;
  useExtendedSearch: boolean;
  searchThreshold: number;
}

const DeviceHistoryTable: FC<DeviceHistoryTableProps> = ({
  device_id,
  useExtendedSearch,
  searchThreshold,
}) => {
  const devicesGridApiRef = useGridApiRef();

  const { rows, isLoading, isSuccess, fileName, searchMinLength } =
    useDeviceHistoryTableViewController({
      device_id,
    });

  return (
    <Box className="pb-3 h-full w-full">
      <Table
        tableName="device-history"
        columns={DEVICE_HISTORY_COLUMNS}
        rows={rows || []}
        apiRef={devicesGridApiRef}
        loading={isLoading}
        error={!isLoading && !isSuccess ? true : null}
        columnVisibilityModel={columnVisibilityModel}
        className="w-full border-solid"
        enableSearch
        searchKeys={SEARCH_KEYS}
        searchMinLength={searchMinLength}
        isPopOver
        useExtendedSearch={useExtendedSearch}
        searchThreshold={searchThreshold}
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
      />
    </Box>
  );
};

export default memo(DeviceHistoryTable);
