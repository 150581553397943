import { useState, useEffect } from "react";

const getDataFromLocalStorage = (key: string) => {
  const searchHistoryJSON = localStorage.getItem(key);
  const searchHistory: string[] = searchHistoryJSON
    ? JSON.parse(searchHistoryJSON)
    : [];

  return searchHistory;
};

export const useRecentSearch = (key: string) => {
  const [historyItems, setHistoryItems] = useState([] as string[]);

  useEffect(() => {
    setHistoryItems(getDataFromLocalStorage(key));
  }, [key]);

  // Button handler to remove item from local storage
  const handleHistoryItemRemove = (
    e: React.SyntheticEvent,
    element: string
  ) => {
    e.stopPropagation();

    const currentHistory = localStorage.getItem(key);

    if (currentHistory) {
      const parsedHistory: string[] = JSON.parse(currentHistory);

      parsedHistory.splice(parsedHistory.indexOf(element), 1);
      setHistoryItems(parsedHistory);
      localStorage.setItem(key, JSON.stringify(parsedHistory));
    }
  };

  // function to add an item to history in local storage
  const handleHistoryItemAdd = (value: string) => {
    let history = [value];
    const currentHistory = localStorage.getItem(key);

    if (currentHistory) {
      const parsedHistory: string[] = JSON.parse(currentHistory);

      if (parsedHistory.includes(value)) {
        parsedHistory.splice(parsedHistory.indexOf(value), 1);
      }

      history = [value, ...parsedHistory];
    }

    setHistoryItems(history);
    localStorage.setItem(key, JSON.stringify(history));
  };

  return { historyItems, handleHistoryItemAdd, handleHistoryItemRemove };
};
