import { memo, FC, ReactNode } from "react";
import { get } from "lodash";
import { useAuthContext } from "../../../context/AuthContext";
import { UserAccessScope } from "./userRolePermissions";

type WithPermissionsProps = {
  accessScope: UserAccessScope;
  children: ReactNode;
};

const WithPermissions: FC<WithPermissionsProps> = ({
  accessScope,
  children,
}) => {
  const { userRolePermissions } = useAuthContext();
  // Render children if user has access to the given scope.
  if (get(userRolePermissions, accessScope)) {
    return <>{children}</>;
  }

  return null;
};

export default memo(WithPermissions);
