import {
  AlertType,
  CreateAlertInput,
  UpdateAlertInput,
  CreateAlertMutation,
  UpdateAlertMutation,
  Exact,
  useDeleteAlertMutation,
  Scalars,
  useCreateAlertMutation,
  useUpdateAlertMutation,
  useCreateAlertTemplateMutation,
  CreateAlertTemplateMutation,
  CreateAlertTemplateInput,
  useUpdateAlertV2Mutation,
  useDeleteAlertV2Mutation,
  useCreateAlertV2Mutation,
  CreateAlertV2Mutation,
  UpdateAlertV2Mutation,
  CreateAlertInputV2,
  UpdateAlertV2Input,
  Alert,
  AlertV2,
} from "../../../graphql/operations";

interface IAlertApi {
  createAlertOnSuccess: (
    data: CreateAlertV2Mutation,
    variables: Exact<{ input: CreateAlertInputV2 }>,
    context: unknown
  ) => unknown;
  createAlertTemplateOnSuccess: (
    data: CreateAlertTemplateMutation,
    variables: Exact<{ input: CreateAlertTemplateInput }>,
    context: unknown
  ) => unknown;
  updateAlertOnSuccess: (
    data: UpdateAlertV2Mutation,
    variables: Exact<{ input: UpdateAlertV2Input }>,
    context: unknown
  ) => unknown;
  deleteAlertOnSuccess: () => unknown;
  createAlertOnError: (error: unknown) => unknown;
  createAlertTemplateOnError: (error: unknown) => unknown;
  updateAlertOnError: (error: unknown) => unknown;
  deleteAlertOnError: (error: unknown) => unknown;
}

export const useAlertApi = ({
  createAlertOnSuccess,
  updateAlertOnSuccess,
  deleteAlertOnSuccess,
  createAlertOnError,
  updateAlertOnError,
  deleteAlertOnError,
  createAlertTemplateOnSuccess,
  createAlertTemplateOnError,
}: IAlertApi) => {
  const { mutate: createAlert } = useCreateAlertV2Mutation({
    onSuccess: createAlertOnSuccess,
    onError: createAlertOnError,
  });
  const { mutate: updateAlert } = useUpdateAlertV2Mutation({
    onSuccess: updateAlertOnSuccess,
    onError: updateAlertOnError,
  });
  const { mutate: deleteAlert } = useDeleteAlertV2Mutation({
    onSuccess: deleteAlertOnSuccess,
    onError: deleteAlertOnError,
  });
  const { mutate: createAlertTemplate } = useCreateAlertTemplateMutation({
    onSuccess: createAlertTemplateOnSuccess,
    onError: createAlertTemplateOnError,
  });

  return {
    createAlert: (alert: Partial<AlertV2>, parameters: unknown) => {
      return createAlert({
        input: {
          archived: alert.archived,
          escalationSendSms: alert.escalationSendSms,
          escalationTime: alert.escalationTime,
          escalationUsers: alert.escalationUsers,
          message: alert.message,
          name: alert.name!,
          orgId: alert.orgId!,
          parameters,
          reminder: alert.reminder!,
          threshold: alert.threshold,
          trigger: alert.trigger,
          type: alert.type!,
        },
      });
    },
    createAlertTemplate: (input: CreateAlertTemplateInput) => {
      return createAlertTemplate({
        input,
      });
    },
    updateAlert: (alert: Partial<AlertV2>, parameters: unknown) => {
      return updateAlert({
        input: {
          _id: alert._id!,
          escalationSendSms: alert.escalationSendSms,
          escalationTime: alert.escalationTime,
          escalationUsers: alert.escalationUsers,
          message: alert.message,
          name: alert.name,
          parameters,
          reminder: alert.reminder,
          threshold: alert.threshold,
          trigger: alert.trigger,
        },
      });
    },
    deleteAlert: (_id: Scalars["ID"]) => {
      return deleteAlert({ id: _id });
    },
  };
};
