import { useMemo } from "react";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MopedIcon from "@mui/icons-material/Moped";
import SchemaIcon from "@mui/icons-material/Schema";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { Card, Typography } from "@mui/material";
import {
  AssetType,
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import Highlights, {
  HighlightItem,
} from "../../../../../shared/components/DashboardWidgets/HighlightsWidget/Highlights";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export type DashboardWidgetAssetsTypeData = {
  total: number;
  categories: Record<AssetType, number>;
};

export interface DashboardWidgetAssetsType extends GetDashboardWidgetResponse {
  data: DashboardWidgetAssetsTypeData;
}

export interface AssetsTypeWidgetProps {
  widget: DashboardWidgetAssetsType;
}

const mapWidgetData = (
  data: DashboardWidgetAssetsTypeData
): HighlightItem[] => {
  if (!data || !data.categories) {
    return [];
  }

  const categoryNames = Object.keys(data.categories);

  return categoryNames.map((category) => ({
    icon: mapCategoryIcon(category as AssetType),
    label: category,
    count: data.categories[category as AssetType],
  }));
};

const mapCategoryIcon = (category: AssetType) => {
  switch (category) {
    case AssetType.Boat:
      return <DirectionsBoatIcon />;
    case AssetType.Container:
      return <ViewColumnIcon />;
    case AssetType.Chassis:
      return <SchemaIcon />;
    case AssetType.Dolly:
      return <MopedIcon />;
    case AssetType.Trailer:
      return <LocalShippingIcon />;
    case AssetType.Tractor:
      return <AgricultureIcon />;
    case AssetType.Reefer:
      return <DirectionsBusIcon />;
    case AssetType.Vehicle:
      return <DriveEtaIcon />;
    case AssetType.Other:
    default:
      return <HelpOutlineIcon />;
  }
};

export const AssetsTypeWidget: React.FC<AssetsTypeWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";
  const subTitle = widget.data?.total
    ? {
        subTitle: "Number of Assets",
        subCount: widget.data.total,
      }
    : undefined;

  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const data = useMemo(() => {
    const parsedData: DashboardWidgetAssetsTypeData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);

    return mappedData;
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--assets-type"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets type widget!
          Please try again later.
        </Typography>
      ) : (
        <Highlights
          title={title}
          subTitle={subTitle}
          isLoading={isLoading}
          items={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
