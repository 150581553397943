import { GetDashboardWidgetQuery } from "../../../../graphql/operations";
import { DAY_MONTH_FORMAT, formatDate } from "../../../../utils/date";

export const formatDateIntoLabelForTooltip = (date: string) => {
  if (date?.length > 10) {
    const startDateString = date.slice(0, 10);
    const endDateString = date.slice(11, 22);
    const formattedStartDate = formatDate(
      new Date(startDateString),
      DAY_MONTH_FORMAT
    );
    const formattedEndDate = formatDate(
      new Date(endDateString),
      DAY_MONTH_FORMAT
    );

    return `Dates: ${formattedStartDate}-${formattedEndDate}`;
  }
  return `Date: ${formatDate(new Date(date), DAY_MONTH_FORMAT)}`;
};

export const formatDateIntoLabel = (date: string) => {
  // If the date is a range -> return only start date for label
  if (date?.length > 10)
    return formatDate(new Date(date.slice(0, 10)), DAY_MONTH_FORMAT);
  return formatDate(new Date(date), DAY_MONTH_FORMAT);
};

export const hasNoPermission = <T extends GetDashboardWidgetQuery>(
  queryData?: T
) => {
  return queryData?.getDashboardWidget?.data === null;
};
