import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { UseMutateFunction, useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR, PAGE_SPINNER } from "../../constants";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import {
  Exact,
  ImpersonateUserMutation,
  ImpersonationUserInput,
  useImpersonateUserMutation,
  useGetUserDataQuery,
} from "../../graphql/operations";
import { NavigationRoutes } from "../../utils/routes/routesUtils";

const IMPERSONATION_NOT_FOUND_ERROR = "Impersonation session not found";

export const useImpersonate = (
  successMsg: string
): {
  mutate: UseMutateFunction<
    ImpersonateUserMutation,
    any,
    Exact<{ impersonationUserInput: ImpersonationUserInput }>,
    unknown
  >;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
} => {
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const { refetch } = useGetUserDataQuery();
  const { refreshAuthTokens$ } = useAuthContext();
  const navigate = useNavigate();

  const refreshTokens = useCallback(
    () =>
      refreshAuthTokens$((error: any) => {
        if (error) {
          const displayError = error ? error?.message : "Something went wrong";
          dispatch({
            type: PAGE_SNACKBAR,
            payload: {
              title: "Impersonation request failed!",
              text: displayError,
              severity: "error",
            },
          });
          return;
        }

        queryClient.invalidateQueries();
        refetch();
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Success!",
            text: successMsg,
            severity: "success",
          },
        });
        setTimeout(() => {
          navigate(NavigationRoutes.AdminPanelUsers);
          navigate(0);
          dispatch({ type: PAGE_SNACKBAR, payload: null });
        }, 3000);
      }),
    [dispatch, navigate, queryClient, refetch, refreshAuthTokens$, successMsg]
  );

  const { mutate, isLoading, isSuccess, isError } = useImpersonateUserMutation({
    onSuccess: () => {
      refreshTokens();
    },
    onError: (error: any) => {
      const displayError = error ? error?.message : "Something went wrong";
      if (displayError === IMPERSONATION_NOT_FOUND_ERROR) {
        refreshTokens();
        return;
      }
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Impersonation request failed!",
          text: displayError,
          severity: "error",
        },
      });
      dispatch({
        type: PAGE_SPINNER,
        payload: { increment: 0 },
      });
    },
  });
  return { mutate, isLoading, isSuccess, isError };
};
