import { MutationKey, UseMutationOptions } from "@tanstack/react-query";
import {
  UpdateAssetMutationVariables,
  UpdateAssetMutation,
  UpdateAssetOsMutation,
  useUpdateAssetMutation,
  useUpdateAssetOsMutation,
  UpdateAssetOsMutationVariables,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";

type UpdateAssetMutationOptions = UseMutationOptions<
  UpdateAssetMutation,
  unknown,
  UpdateAssetMutationVariables,
  MutationKey
> &
  UseMutationOptions<
    UpdateAssetOsMutation,
    unknown,
    UpdateAssetOsMutationVariables,
    MutationKey
  >;

export const useUpdateAsset = (options: UpdateAssetMutationOptions) => {
  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const mutation = fetchAssetsFromOpenSearchFeatureFlag
    ? useUpdateAssetOsMutation
    : useUpdateAssetMutation;

  const { mutate, mutateAsync, isLoading } = mutation(options);

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
