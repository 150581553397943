import { FC, memo } from "react";
import { Box } from "@mui/system";
import { ProfileConfigProperty } from "../../../../../../graphql/operations";
import {
  Table,
  TableDataModes,
} from "../../../../../../shared/components/Table";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";
import {
  sensorsTableKeyList,
  columnVisibilityModel,
  sensorsAirSupplyTableColumns,
} from "../../sensorsUtils";
import useSensorsTableLogic from "../../shared/hooks/useSensorsTableLogic";
import { SensorsBatteriesTableProps } from "../Batteries/SensorsBatteriesTable";

const SensorsAirSupplyTable: FC<SensorsBatteriesTableProps> = ({
  tableType,
  onRowClick,
  onRowSelect,
  apiRef,
  sensorsData,
  rowSelectionModel,
  onFilterChange,
  onSortModelChange,
  onPageChange,
  refetchingData,
  totalTableRowsCount,
}) => {
  const { tableRows, canCheckTheRows, fileName, appConfig } =
    useSensorsTableLogic({
      apiRef,
      rowSelectionModel,
      sensorsData: sensorsData.data,
      sensorPropertyType: ProfileConfigProperty.AirSupply,
      sensorColumns: sensorsAirSupplyTableColumns,
    });

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={tableType}
        showToolbar
        rows={tableRows}
        apiRef={apiRef}
        columns={sensorsAirSupplyTableColumns}
        columnVisibilityModel={columnVisibilityModel}
        onRowClick={onRowClick}
        updateSelectedRowsHandler={onRowSelect}
        loading={sensorsData.isFetchingSensor}
        error={sensorsData.isErrorSensor}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={sensorsTableKeyList}
        sortKeys={sensorsTableKeyList}
        searchMinLength={appConfig.searchMinLength}
        tableName="air-supply-sensors"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        checkboxSelection={canCheckTheRows}
        disableSelectionOnClick={true}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
        //pagination props
        // semi-server used for export to work with other server props
        handleDataMode={TableDataModes.SemiServer}
        onFilterModelChange={onFilterChange}
        onSortModelChange={onSortModelChange}
        onPageChange={onPageChange}
        onPaginationModelChange={onPageChange}
        isDataRefetching={refetchingData}
        rowCount={totalTableRowsCount ?? 0}
      />
    </Box>
  );
};

export default memo(SensorsAirSupplyTable);
