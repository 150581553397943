import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useFrequencySelectTheme } from "../../../views/ReportView/hooks/useFrequencySelectTheme";

export const useEscalationInputTheme = () => {
  const theme = useFrequencySelectTheme();
  return createTheme(
    deepmerge(theme, {
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              fontSize: "48px",
              backgroundColor: "var(--panel_content__bg)",
              width: "128px",
              height: "98px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& input": {
                textAlign: "center",
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  WebkitAppearance: "none",
                  margin: 0,
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
            },
          },
        },
      },
    })
  );
};
