import { CircularProgress } from "@mui/material";
import Button, { ButtonProps } from "../../../shared/components/Button/Button";
import { useAuthenticationViewBrand } from "../hooks/useAuthenticationViewBrand";

export const AuthButton: React.FC<ButtonProps> = (props) => {
  const brand = useAuthenticationViewBrand();
  const isGhostType = props.variant === "ghost";

  return (
    <Button
      variant="default"
      sx={{
        backgroundColor: isGhostType
          ? "transparent"
          : `${brand.color} !important`,
        width: "100%",
        height: "38px !important",
        fontSize: "14px !important",
      }}
      iconPosition="right"
      icon={<CircularProgress size={15} style={{ color: "white" }} />}
      {...props}
    />
  );
};
