import { useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  DialogActions,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { AbsFaultCodeDetails } from "../../../../../../graphql/operations";
import { Button } from "../../../../../../shared/components/Button";
import { formatDate } from "../../../../../../utils";

export type DialogProps = {
  faultCode?: AbsFaultCodeDetails;
  logo: string;
  onClose: () => void;
};

const AbsFaultCodeDetailsDialog = (props: DialogProps) => {
  const { faultCode, logo, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Box
      sx={{
        left: "0",
        right: "0",
        marginLeft: "auto",
        marginRight: "auto",
        width: { md: "58rem" },
        padding: "20px",
        position: "absolute",
        zIndex: "999",
        backgroundColor: "var(--secondary) !important",
        color: "var(--primary) !important",
      }}
      className="border rounded-[8px] border-concrete"
      data-testid="abs-fault-codes-details-dialog"
    >
      <DialogTitle sx={{ fontSize: "27px" }} id="customized-dialog-title">
        Fault Code Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container className="mb-6">
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography fontWeight="bold" gutterBottom>
                  Fault Start Time
                </Typography>
                <Typography gutterBottom data-testid="fault_start_time">
                  {faultCode?.fault_start_time
                    ? formatDate(
                        faultCode?.fault_start_time,
                        "dd/MM/yyyy hh:mm:ss"
                      )
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight="bold" gutterBottom>
                  Fault End Time
                </Typography>
                <Typography gutterBottom data-testid="fault_end_time">
                  {faultCode?.fault_end_time
                    ? formatDate(
                        faultCode?.fault_end_time,
                        "dd/MM/yyyy hh:mm:ss"
                      )
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight="bold" gutterBottom>
                  Duration
                </Typography>
                <Typography gutterBottom data-testid="duration">
                  {faultCode?.duration}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight="bold" gutterBottom>
                  DTC
                </Typography>
                <Typography gutterBottom data-testid="dtc">
                  {faultCode?.dtc}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight="bold" gutterBottom>
                  SID
                </Typography>
                <Typography gutterBottom data-testid="sid">
                  {faultCode?.sid}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight="bold" gutterBottom>
                  FMI
                </Typography>
                <Typography gutterBottom data-testid="fmi">
                  {faultCode?.fmi}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight="bold" gutterBottom>
                  Cause
                </Typography>
                <Typography gutterBottom data-testid="cause">
                  {faultCode?.cause}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography fontWeight="bold" gutterBottom>
                  Description
                </Typography>
                <Typography gutterBottom data-testid="description">
                  {faultCode?.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography fontWeight="bold" gutterBottom>
              Manufacturer
            </Typography>
            <Typography gutterBottom>
              <img width={200} src={logo} alt="logo" />
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Typography fontWeight="bold" gutterBottom>
            PCT Explains
          </Typography>
          <Typography gutterBottom data-testid="pctExplains">
            {faultCode?.pctExplains}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          text="Close"
          size="medium"
          theme="blue"
          variant="default"
          onClick={handleClose}
          dataTestid="abs-fault-code-details-close-btn"
          className="!ml-4 !h-[40px] !px-10 !py-3 !font-bold"
        />
      </DialogActions>
    </Box>
  );
};

export default AbsFaultCodeDetailsDialog;
