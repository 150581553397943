import { useState, useCallback, FC, useMemo, useEffect } from "react";
import { Box } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  FileFormat,
  GetTableDataInput,
  SortOrder,
  TableDomain,
  TableViewType,
  useGetUsersTableDataQuery,
  UsersTableData,
  UsersTableDataResponse,
} from "../../../../../graphql/operations";
import Spinner from "../../../../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
} from "../../../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../../../shared/components/Table/BackEndProcessingTable";
import { useGlobalOrganizationFilter } from "../../../../../shared/hooks/useGlobalOrganizationFilter";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { columnVisibilityModel, getColumns } from "./columns";

export interface UserManagementTableProps {
  onRowClick: (row: UsersTableData) => void;
  initialSearch?: string | null;
}

export const UserManagementTable: FC<UserManagementTableProps> = ({
  onRowClick,
  initialSearch,
}) => {
  const apiRef = useGridApiRef();
  const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
  const [isExporting, setIsExporting] = useState(false);
  const timezone = usePreferredTimezone();
  const columns = useMemo(() => getColumns(timezone), [timezone]);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const globalOrgFilter = useGlobalOrganizationFilter();

  const {
    state: { appConfig },
  } = useAppContext();

  const [queryInput, setQueryInput] = useState<GetTableDataInput>({
    sorting: [{ field: "lastLoginDate", order: SortOrder.Desc }],
    pagination: {
      limit: appConfig.table.defaultRowsPerPage,
      skip: 0,
    },
  });

  const {
    data: queryData,
    isLoading,
    isSuccess,
  } = useGetUsersTableDataQuery<UsersTableDataResponse>(
    { input: queryInput },
    { select: ({ getUsersTableData }) => getUsersTableData }
  );

  const updateQueryInput = useCallback(
    (data: Partial<GetTableDataInput>) => {
      setQueryInput((prev) => ({ ...prev, ...data }));
    },
    [setQueryInput]
  );

  useEffect(() => {
    if (globalOrgFilter.value) {
      updateQueryInput({
        orgId: globalOrgFilter.value,
      });
    }
  }, [globalOrgFilter.value, updateQueryInput]);

  useTableDataExporter<UsersTableData>({
    queryInput,
    apiRef,
    columns,
    domain: TableDomain.Users,
    fileFormat,
    isExporting,
    setExporting: setIsExporting,
    totalCount: queryData?.pagination.total,
  });

  const handleExport = useCallback((format: ServerSideExportFormat) => {
    if (format === ServerSideExport.EMAIL) {
      setIsSendingEmail(true);
    } else {
      setFileFormat(format);
      setIsExporting(true);
    }
  }, []);

  return (
    <Box className="w-full h-full pb-4">
      <BackEndProcessingTable
        apiRef={apiRef}
        domain={TableDomain.Users}
        isSendingEmail={isSendingEmail}
        setSendingEmail={setIsSendingEmail}
        queryInput={queryInput}
        tableType={TableViewType.Users}
        tableName={"users-list"}
        columnVisibilityModel={columnVisibilityModel}
        columns={columns}
        data={{
          rows: queryData?.data ?? [],
          pagination: queryData?.pagination,
        }}
        initialSearch={initialSearch as string}
        updateQueryInput={updateQueryInput}
        sorting={queryInput.sorting ?? undefined}
        onExport={handleExport}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onRowClick={onRowClick}
        totalCount={queryData?.pagination.total}
      />
      <Spinner counter={Number(isSendingEmail)} />
    </Box>
  );
};
