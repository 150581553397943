import React, { FC, MutableRefObject, useRef, useState } from "react";
import { FieldValues, UseFormReturn, useWatch } from "react-hook-form";
import {
  Box,
  Grid,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { pick } from "lodash";
import * as yup from "yup";
import { getDurationFromMillis } from "../../../utils/date";
import { getAlertCommonSchema } from "../../../views/AlertView/utils";
import { useFrequencySelectTheme } from "../../../views/ReportView/hooks/useFrequencySelectTheme";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import ActionButton from "../Button/ActionButton";
import { SelectedValues } from "../HierarchySelect/types";
import { useEscalationInputTheme } from "./useEscalationInputTheme";

export type EscalationTimeSelectProps = {
  loading?: boolean;
  values: SelectedValues;
  onBlur: () => void;
  required?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  form: UseFormReturn<FieldValues>;
};

export const EscalationTimeSelect: FC<EscalationTimeSelectProps> = ({
  values,
  onBlur,
  required,
  invalid,
  disabled,
  form,
}) => {
  const theme = useFrequencySelectTheme();
  const inputTheme = useEscalationInputTheme();
  const select: MutableRefObject<HTMLInputElement | null | undefined> =
    useRef();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { width } = useContainerDimensions(
    select as MutableRefObject<HTMLDivElement>
  );

  const watchDays = useWatch({
    name: "escalationTime.days",
    control: form.control,
  });
  const watchHours = useWatch({
    name: "escalationTime.hours",
    control: form.control,
  });
  const watchMinutes = useWatch({
    name: "escalationTime.minutes",
    control: form.control,
  });

  const [days, setDays] = useState<number>(watchDays);
  const [hours, setHours] = useState<number>(watchHours);
  const [minutes, setMinutes] = useState<number>(watchMinutes);
  const [daysWarning, setDaysWarning] = useState<string>("");
  const [hoursWarning, setHoursWarning] = useState<string>("");
  const [minutesWarning, setMinutesWarning] = useState<string>("");

  const handleClose = (e: React.SyntheticEvent) => {
    onBlur();
    // @ts-ignore
    if (!e.target.classList.contains("MuiBackdrop-root")) {
      return;
    }
    setIsOpen(false);
    setDays(watchDays);
    setHours(watchHours);
    setMinutes(watchMinutes);
    setDaysWarning("");
    setHoursWarning("");
    setMinutesWarning("");
  };

  const getTransformedTime = (days: number, hours: number, minutes: number) => {
    const daysTransformed = days ? days * 24 * 60 * 60 * 1000 : 0;
    const hoursTransformed = hours ? hours * 60 * 60 * 1000 : 0;
    const minutesTransformed = minutes ? minutes * 60 * 1000 : 0;

    const result = getDurationFromMillis(
      daysTransformed + hoursTransformed + minutesTransformed
    )
      .shiftTo("days", "hours", "minutes")
      .toObject();
    return result;
  };

  const setEscalationTime = async () => {
    const inputs = [
      { value: days, setValueWarning: setDaysWarning },
      { value: hours, setValueWarning: setHoursWarning },
      {
        value: minutes,
        setValueWarning: setMinutesWarning,
      },
    ];
    const schema = yup.object().shape({
      ...pick(
        getAlertCommonSchema(),
        "escalationTimeSummary",
        "escalationTime"
      ),
    });

    let error = false;
    inputs.forEach(({ value, setValueWarning }) => {
      if (value < 0) {
        setValueWarning("Value cannot be negative");
        error = true;
      } else {
        setValueWarning("");
      }
    });

    if (error) return;

    const result = getTransformedTime(days, hours, minutes);

    form.setValue("escalationTime.days", result.days);
    form.setValue("escalationTime.hours", result.hours);
    form.setValue("escalationTime.minutes", result.minutes);
    setMinutes(result.minutes ?? 0);
    setHours(result.hours ?? 0);
    setDays(result.days ?? 0);

    form.setValue(
      "escalationTimeSummary",
      `${result.days}:${result.hours}:${result.minutes}`
    );

    try {
      await schema.validate(form.getValues());
    } catch (validationError: any) {
      setDaysWarning(validationError.message);
      return;
    }
    setIsOpen(false);
    setDaysWarning("");
    setHoursWarning("");
    setMinutesWarning("");
  };

  const getInputValue = () => {
    return `${watchDays} days ${watchHours} hours ${watchMinutes} minutes`;
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseInt(event.target.value);
    if (parsedValue && parsedValue >= 0) {
      return parsedValue;
    }
    return 0;
  };

  const onWheelEvent = (event: React.WheelEvent<HTMLDivElement>) => {
    if (event.target instanceof HTMLElement) event.target.blur();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Select
          data-testid="escalation-time-select"
          value={values}
          error={invalid}
          required={required}
          onBlur={onBlur}
          fullWidth
          displayEmpty
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={handleClose}
          renderValue={() => getInputValue()}
          ref={select}
          disabled={disabled}
        >
          <Box
            sx={{
              width: width ? `${width}px` : "320px",
            }}
            className="min-h-80 max-h-96 overflow-y-auto py-6 px-16 bg-card__bg"
            data-testid="escalation-time-select-box"
          >
            <Typography className="!text-typography !text-sm !font-bold text-center">
              Select Time
            </Typography>
            <Grid container spacing={3} className="pb-5 pt-8 flex items-center">
              <ThemeProvider theme={inputTheme}>
                <Grid
                  item
                  container
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  className="flex justify-center sm:justify-center lg:justify-start"
                >
                  <Grid
                    item
                    container
                    columnSpacing={4}
                    xs={12}
                    className="!max-w-[500px] !mx-auto"
                  >
                    <Grid item lg={4} className="!flex !flex-col !items-center">
                      <Typography className="!text-base !font-medium !mb-3.5">
                        Days
                      </Typography>
                      <TextField
                        data-testid="escalation-time-days"
                        variant="outlined"
                        className="!mb-4"
                        type="Number"
                        error={!!daysWarning}
                        helperText={daysWarning}
                        value={days}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setDays(onInputChange(event))}
                        onWheel={onWheelEvent}
                      />
                    </Grid>
                    <Grid item lg={4} className="!flex !flex-col !items-center">
                      <Typography className="!text-base !font-medium !mb-3.5">
                        Hours
                      </Typography>
                      <TextField
                        data-testid="escalation-time-hours"
                        variant="outlined"
                        className="!mb-4"
                        type="Number"
                        error={!!hoursWarning}
                        helperText={hoursWarning}
                        value={hours}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setHours(onInputChange(event))}
                        onWheel={onWheelEvent}
                      />
                    </Grid>
                    <Grid item lg={4} className="!flex !flex-col !items-center">
                      <Typography className="!text-base !font-medium !mb-3.5">
                        Minutes
                      </Typography>
                      <TextField
                        data-testid="escalation-time-minutes"
                        variant="outlined"
                        className="!mb-4"
                        type="Number"
                        error={!!minutesWarning}
                        helperText={minutesWarning}
                        value={minutes}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setMinutes(onInputChange(event))}
                        onWheel={onWheelEvent}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </ThemeProvider>
              <Grid
                item
                container
                xs={12}
                md={12}
                lg={12}
                xl={6}
                className="justify-center"
              >
                <Box className="flex flex-col">
                  <Typography className="!text-base !font-medium">
                    Escalation notification will be sent after:
                  </Typography>
                  <Typography className="!text-5xl !font-semibold">
                    {`${days} days ${hours} hours ${minutes} minutes`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <ActionButton
              data-testid="escalation-time-select-set-button"
              className="!flex !mt-[20px] !mb-[20px]"
              sx={{ margin: "0 auto" }}
              onClick={setEscalationTime}
            >
              Set
            </ActionButton>
          </Box>
        </Select>
      </Box>
    </ThemeProvider>
  );
};
