import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../shared/hooks/theme/useCurrentTheme";

export const useReportListViewTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiInputBase: {
          styleOverrides: {
            root: {
              textTransform: "capitalize",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "17.6px",
              "&::before": {
                borderBottomColor: "var(--typography-secondary)",
              },
              "&:hover": {
                "&::before": {
                  borderBottomWidth: "1px !important",
                },
              },
              "input::placeholder": {
                color: "var(--dark-grey)",
              },
            },
          },
        },
      },
    })
  );
};
