import { useMemo, useState } from "react";
import {
  Typography,
  TextField,
  Box,
  CircularProgress,
  MenuItem,
  ThemeProvider,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { last } from "lodash";
import { VOLTAGE_CHART_DATE_RANGE_OPTIONS } from "../../../../../../constants/map";
import {
  useGetMergedProfileForAssetQuery,
  useGetTemperatureChartHistoricalDataQuery,
} from "../../../../../../graphql/operations";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";
import {
  getEndOfToday,
  getStartOfDay,
  getSubDays,
} from "../../../../../../utils/date";
import { rangesSensorConfigToReferenceAreaValues } from "../../../Assets/TiresTabPanel/helpers";
import { useBrakesTabTheme } from "../../BrakesTab/useBrakesTabTheme";
import { TemperatureChartBody } from "./TemperatureChartBody";

export interface TemperatureChartSectionProps {
  selectedAssetId: string;
  internalId: string;
  customerOrgId: string;
  assetImei?: string;
}

interface Temperature {
  min: number;
  median: number;
  max: number;
  readingDay: string;
}

export interface TemperatureData {
  temperature: Temperature;
  date: string;
}

export const TemperatureTabChartSection: React.FC<
  TemperatureChartSectionProps
> = ({ selectedAssetId, internalId, assetImei, customerOrgId }) => {
  const isMobile = useBreakpoint("down", "sm");
  // same variant changes
  const theme = useBrakesTabTheme();

  const handleDateRangeChange = (value: number) => {
    const option = VOLTAGE_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    setSelectedDateRangeOption(value);
    if (option?.getRange) {
      const range = option.getRange();
      setStartDate(range[0]);
      setEndDate(range[1]);
    }
  };

  const setSelectedLastDateRangeOption = () => {
    setSelectedDateRangeOption(last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!);
  };

  const [startDate, setStartDate] = useState<Date | null>(
    getStartOfDay(getSubDays(new Date(), 30))
  );
  const [endDate, setEndDate] = useState<Date | null>(getEndOfToday());
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    VOLTAGE_CHART_DATE_RANGE_OPTIONS[0].value
  );
  const { isLoading: isTemperatureLoading, data } =
    useGetTemperatureChartHistoricalDataQuery(
      {
        input: {
          startDate: startDate,
          endDate: endDate,
          imei: assetImei,
          customerOrgId,
          includeEmptyDates: true,
        },
      },
      {
        enabled: Boolean(customerOrgId && assetImei),
      }
    );
  const { data: profileData } = useGetMergedProfileForAssetQuery({
    input: { assetId: internalId },
  });

  const temperateHistoricalData = useMemo(() => {
    return data?.searchHistoricalEventHistory?.data ?? [];
  }, [data]);

  const profileAreaValues = rangesSensorConfigToReferenceAreaValues(
    profileData?.getMergedProfileForAsset?.sensorProfile?.configuration
      ?.temperature?.internal?.match?.thresholds
  );

  return (
    <ThemeProvider theme={theme}>
      {!isMobile && (
        <>
          {isTemperatureLoading ? (
            <Box
              className="flex h-full w-full items-center justify-center"
              data-testid="temperature-loading-screen"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box className="flex-1 rounded-lg bg-dashboard_subheader__bg p-4">
              <Typography className="!text-lg !font-semibold text-brand">
                Temperature Trending Graph
              </Typography>
              <Typography
                className="!text-xs !font-medium text-asset-info-subheader"
                data-testid="trending-temperature"
              >
                Trending temperature data over time
              </Typography>
              <Box className="flex items-center justify-end gap-4 py-4">
                <MobileDatePicker
                  label="Start date"
                  inputFormat="MM/dd/yyyy"
                  closeOnSelect
                  value={startDate}
                  shouldDisableDate={(day) =>
                    day < getStartOfDay(getSubDays(new Date(), 90))
                  }
                  disableFuture
                  onChange={(date) => {
                    setStartDate(date);
                    setSelectedLastDateRangeOption();
                  }}
                  DialogProps={{
                    className: "trending-temperature-date-picker",
                  }}
                  renderInput={(params) => (
                    <TextField
                      data-testid="temperature-chart-start-date"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
                <span className="text-base font-normal text-primary">to</span>
                <MobileDatePicker
                  DialogProps={{
                    className: "trending-temperature-date-picker",
                  }}
                  label="End date"
                  closeOnSelect
                  inputFormat="MM/dd/yyyy"
                  value={endDate}
                  shouldDisableDate={(day) =>
                    Boolean(startDate && day < startDate)
                  }
                  disableFuture
                  onChange={(date) => {
                    setEndDate(date);
                    setSelectedLastDateRangeOption();
                  }}
                  renderInput={(params) => (
                    <TextField
                      data-testid="temperature-chart-end-date"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />

                <TextField
                  data-testid="temperature-chart-date-range-select"
                  select
                  value={selectedDateRangeOption}
                  variant="outlined"
                  className="w-40"
                  onChange={(e) =>
                    handleDateRangeChange(Number(e.target.value))
                  }
                >
                  {VOLTAGE_CHART_DATE_RANGE_OPTIONS.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      data-testid={`temperature-chart-date-range-select-option-${option.value}`}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <TemperatureChartBody
                data={temperateHistoricalData as TemperatureData[]}
                referenceAreaValues={profileAreaValues}
              />
            </Box>
          )}
        </>
      )}
    </ThemeProvider>
  );
};
