import { FC } from "react";
import { CHART_PERCENTAGE_TEXT_OFFSET, RADIAN } from "../constants";

interface PieCustomLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  outerRadius: number;
  percent: number;
}

const PieCustomLabel: FC<PieCustomLabelProps> = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
}) => {
  const radius = outerRadius + CHART_PERCENTAGE_TEXT_OFFSET;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="var(--typography-secondary)"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      style={{
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
      }}
    >
      {new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }).format(percent)}
    </text>
  );
};

export default PieCustomLabel;
