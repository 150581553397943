import {
  SET_PARENT_GEOFENCE,
  USE_PARENT_GEOFENCE_ORGID,
} from "../../constants";
import { Action } from "../commonTypes";

export const geofenceInitialState = {
  parentGeofence: null,
  useParentGeofenceOrgId: false,
};

export const GeofenceReducer = (
  state = geofenceInitialState,
  action: Action
) => {
  switch (action.type) {
    case SET_PARENT_GEOFENCE:
      return {
        ...state,
        parentGeofence: action.payload,
      };
    case USE_PARENT_GEOFENCE_ORGID:
      return {
        ...state,
        useParentGeofenceOrgId: action.payload,
      };
    default:
      return state;
  }
};
