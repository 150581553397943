import { SET_APP_BRAND_COLOR, SET_APP_THEME } from "../../constants";
import { DefaultBrandColorPalette } from "../../design-system/colors-palette";
import { themes } from "../../shared/hooks/theme/utils";
import { Action } from "../commonTypes";

export type ThemeInitialState = {
  theme: themes;
  brandColor: string;
};

export type ThemeReducerType = (
  state: ThemeInitialState | undefined,
  action: Action
) => ThemeInitialState;

const getInitialTheme = (): themes => {
  const theme = localStorage.getItem("theme");
  return theme === themes?.dark ? themes?.dark : themes?.light;
};

const getInitialBrandColor = (): string => {
  const brandColor = localStorage.getItem("brandColor");
  return brandColor ?? DefaultBrandColorPalette.Primary;
};

export const initialThemeState: ThemeInitialState = {
  theme: getInitialTheme(),
  brandColor: getInitialBrandColor(),
};

export const ThemeReducer = (state = initialThemeState, action: Action) => {
  switch (action.type) {
    case SET_APP_THEME:
      localStorage.setItem("theme", action.payload);
      return {
        ...state,
        theme: action.payload,
      };
    case SET_APP_BRAND_COLOR:
      localStorage.setItem("brandColor", action.payload);
      return {
        ...state,
        brandColor: action.payload,
      };
    default:
      return state;
  }
};
