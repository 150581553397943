import { OrgData } from "../../../graphql/operations";
import { buildHierarchy } from "../../../utils";

export const getOrgsHierarchy = (orgs: OrgData[]) => {
  return buildHierarchy(
    orgs.map((org) => ({
      id: org._id,
      name: org.name,
      parent: org.parent_org_id,
    }))
  );
};
