import { useState, useMemo, useCallback, useEffect, FC } from "react";
import { Polyline } from "@react-google-maps/api";
import { useAppContext } from "../../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../../design-system/colors-palette";
import { HistoricalEventHistory } from "../../../../../../graphql/operations";
import { GoogleMapComponent } from "../../../../../../shared/components/GoogleMapComponent";
import { useFindAssetById } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { useTheme } from "../../../../../../shared/hooks/theme/useTheme";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext";
import { useBreadcrumbsContext } from "../context/BreadCrumbsContext";
import { BreadcrumbsMarkers } from "./BreadcrumbsMarkers";
import { BreadcrumbsFeature, getEventHistoryFeatures } from "./utils";

export interface BreadcrumbsMapWidgetProps {
  eventHistory: HistoricalEventHistory[];
}

const BreadCrumbsMapWidget: FC<BreadcrumbsMapWidgetProps> = ({
  eventHistory,
}: {
  eventHistory: HistoricalEventHistory[];
}) => {
  const {
    state: { appConfig },
  } = useAppContext();

  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);

  const isMobile = useBreakpoint("down", "xl");

  const { dispatch } = useBreadcrumbsContext();

  const { theme } = useTheme();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const { selectedAssetId } = useAssetsDataContext();

  const { data: selectedAsset } = useFindAssetById(
    { assetId: selectedAssetId ?? "" },
    { enabled: !!selectedAssetId }
  );

  const mapEventHistoryFeatures: BreadcrumbsFeature[] = useMemo(
    () => (eventHistory ? getEventHistoryFeatures(eventHistory) : []),
    [eventHistory]
  );

  const fitGoogleMapViewToMarkers = useCallback(
    (features: BreadcrumbsFeature[]) => {
      if (features.length === 0) return;

      const latLngLikeArray = features.map((x) => ({
        lat: x.geometry.coordinates[1],
        lng: x.geometry.coordinates[0],
      }));
      // Initialize the bounds with the first coordinate
      const mapBounds = new google.maps.LatLngBounds(latLngLikeArray[0]);

      // Extend the bounds to include all features
      for (const feature of latLngLikeArray) {
        mapBounds.extend(new google.maps.LatLng(feature.lat, feature.lng));
      }

      const padding = isInitialLoad
        ? { top: 50, bottom: 50, left: 50, right: 50 }
        : { top: 0, bottom: 0, left: 0, right: 0 };

      googleMap?.fitBounds(mapBounds, padding);
    },
    [googleMap, isInitialLoad]
  );

  useEffect(() => {
    fitGoogleMapViewToMarkers(mapEventHistoryFeatures);
  }, [mapEventHistoryFeatures, fitGoogleMapViewToMarkers]);

  const handleMapLoaded = (mapInstance: google.maps.Map) => {
    if (mapInstance) {
      setGoogleMap(mapInstance);
    }
  };

  const googleMapCenter = useMemo(() => {
    let mapCenter: google.maps.LatLngLiteral = {
      lat: selectedAsset?.location?.coordinates?.[1] as number,
      lng: selectedAsset?.location?.coordinates?.[0] as number,
    };
    if (mapEventHistoryFeatures.length) {
      const lastEvent = mapEventHistoryFeatures[0];
      mapCenter = {
        lat: lastEvent.geometry.coordinates[1],
        lng: lastEvent.geometry.coordinates[0],
      };
    }
    return mapCenter;
  }, [mapEventHistoryFeatures, selectedAsset]);

  const wayPoints = new google.maps.MVCArray();

  mapEventHistoryFeatures.forEach((item: BreadcrumbsFeature, index: number) => {
    const location = {
      lat: item.geometry.coordinates[1],
      lng: item.geometry.coordinates[0],
    };
    // Check if it's the first location or if the current location is different from the previous one
    if (
      index === 0 ||
      !wayPoints
        .getAt(wayPoints.getLength() - 1)
        .equals(new google.maps.LatLng(location))
    ) {
      wayPoints.push(new google.maps.LatLng(location));
    }
  });

  return (
    <div
      data-testid="breadcrumbs-map-container"
      className={theme}
      style={{
        width: "100%",
        height: isMobile ? "600px" : "100%",
        flexGrow: 1,
      }}
    >
      <GoogleMapComponent
        googleMap={googleMap}
        googleMapCenter={googleMapCenter}
        handleMapLoaded={handleMapLoaded}
      >
        <Polyline
          path={wayPoints}
          options={{
            strokeColor: ColorsPalette.DarkBlue,
            strokeOpacity: 1,
            strokeWeight: 8,
          }}
        />
        <BreadcrumbsMarkers
          googleMap={googleMap}
          mapEventHistoryFeatures={mapEventHistoryFeatures}
        />
      </GoogleMapComponent>
    </div>
  );
};

export default BreadCrumbsMapWidget;
