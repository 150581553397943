import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ReportIcon from "@mui/icons-material/Report";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Button } from "../../../../shared/components/Button";

interface ScheduleModalProps {
  open: boolean;
  handleClose: () => void;
  shouldPrompt: boolean;
  wasSaved: boolean;
}

export const ScheduleModal: FC<ScheduleModalProps> = ({
  open,
  handleClose,
  shouldPrompt,
  wasSaved,
}) => {
  const heading =
    !wasSaved || shouldPrompt
      ? "Save Report Before Scheduling"
      : "Run Report Before Scheduling";
  const description =
    !wasSaved || shouldPrompt
      ? "Before you schedule your report, it's important to save Report."
      : "Before you schedule your report, it's important to set the report parameters by selecting Run Report.";
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="report-scheduleModal-dialog-title"
      aria-describedby="report-scheduleModal-dialog-description"
      sx={{ backgroundColor: "var(--backdrop)" }}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        sx: {
          backgroundColor: "var(--background) !important",
          backgroundImage: "none",
          width: "100%",
          maxWidth: "720px !important",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        className="flex justify-between"
        id="report-scheduleModal-dialog-title"
        data-testid="report-scheduleModal-dialog-title"
      >
        <span className="text-2xl font-semibold !text-typography-secondary">
          Schedule Report
        </span>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="!pb-0">
        <DialogContentText
          className="!mb-2 flex items-center text-base !text-typography-secondary"
          id="report-scheduleModal-dialog-heading"
          data-testid="report-scheduleModal-dialog-heading"
        >
          <ReportIcon className="mr-4 text-error" />

          <span className="text-lg font-semibold">{heading}</span>
        </DialogContentText>
        <DialogContentText
          className="text-base !text-typography-secondary"
          id="report-scheduleModal-dialog-description"
          data-testid="report-scheduleModal-dialog-description"
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="!p-6">
        <Button
          text="Done"
          size="medium"
          theme="blue"
          variant="default"
          onClick={handleClose}
          dataTestid="report-scheduleModal-goToSchedule"
        />
      </DialogActions>
    </Dialog>
  );
};
