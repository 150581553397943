import { ChangeEvent, FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  ThemeProvider,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as ATISSensorLight } from "../../../../../../assets/svgs/aitsSensorLight.svg";
import { ReactComponent as ATISSensorDark } from "../../../../../../assets/svgs/atisSensorDark.svg";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  AssetWithSensors,
  AssignOrgProfileToAssetsInput,
  SensorProfileConfigType,
  SensorProfileType,
  MovingParkedThresholds,
  SensorThresholdShort,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import { Label } from "../../../../../../shared/components/FormControlElement/styledElements";
import SensorSlider from "../../../../../../shared/components/SensorSlider/SensorSlider";
import {
  atisDefaultValues,
  getMaxValueFromThresholds,
  extractRules,
  prepareRulesPayload,
  returnDynamicLimit,
  returnEvenValuesFromRange,
  sliderMarks,
} from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Text from "../../../../../../shared/components/Text";
import WithAsterisk from "../../../../../../shared/components/WithAsterisk";
import {
  ATIS_ALPHA_HEALTHY_POINT_MAX,
  ATIS_ALPHA_HEALTHY_POINT_MIN,
} from "../../../../../../shared/helpers/atis";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
} from "../../../../../../shared/helpers/battery";
import { useFormTheme } from "../../../../../../shared/hooks/theme/useFormTheme";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import { SensorProfileDropdownItem } from "../../sensorsUtils";
import SelectedRecordInfo from "../../shared/SelectedRecordsInfo";
import { useGetSensorProfilesWithConfigurationSorted } from "../../shared/hooks/useGetSensorProfilesWithConfigurationSorted";
import ConfigurationDialogAdditionalElement from "../Components/CheckboxComponent";
import SensorProfileAssignmentForm from "../Components/SensorProfileAssignmentForm";
import { useMutateCustomProfile, useMutateProfile } from "../drawer.hooks";
import { ATIS_ALPHA_FORM_FIELDS } from "./constants";
import {
  getDefaultAtisAlphaSensorProfileAndAssigmentType,
  prepareProfileToBeAssignedToAssetsInput,
} from "./utils";
import { sensorsAtisAlphaSchema } from "./validationSchema";

export interface AtisDrawerProps {
  currentOrgId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: AssetWithSensors[];
}

const AtisDrawer: FC<AtisDrawerProps> = ({
  open,
  setOpen,
  selectedRecordsData,
  currentOrgId,
}) => {
  const queryClient = useQueryClient();

  const atisAlphaSensorProfile =
    selectedRecordsData?.[0]?.sensorProfile?.configuration?.atisAlpha;
  const atisAlphaThresholds = selectedRecordsData?.[0]?.atisAlpha;
  const { defaultAtisAlphaSensorProfile, defaultTypeOfAssignment } =
    getDefaultAtisAlphaSensorProfileAndAssigmentType(atisAlphaSensorProfile);

  const isPresetProfile =
    atisAlphaSensorProfile?.type !== SensorProfileType.Asset;

  // values extraction from sensor profile
  const min = MinValuesBySensorType.atisAlpha;
  const max = MaxValuesBySensorType.atisAlpha;
  const initialMoving =
    extractRules(atisAlphaThresholds?.moving) || atisDefaultValues;
  const initialParked =
    extractRules(atisAlphaThresholds?.parked) || atisDefaultValues;
  const initialMovingLimit =
    getMaxValueFromThresholds(atisAlphaThresholds?.moving) ?? max;
  const initialParkedLimit =
    getMaxValueFromThresholds(atisAlphaThresholds?.parked) ?? max;

  // states
  const [atisMoving, setAtisMoving] = useState(initialMoving);
  const [atisParked, setAtisParked] = useState(initialParked);
  const [movingLimit, setMovingLimit] = useState<number>(initialMovingLimit);
  const [parkedLimit, setParkedLimit] = useState<number>(initialParkedLimit);
  const [movingLimitError, setMovingLimitError] = useState<string>("");
  const [parkedLimitError, setParkedLimitError] = useState<string>("");

  const [
    isConfirmProfileAssignmentVisible,
    setIsConfirmProfileAssignmentVisible,
  ] = useState(false);
  const [isTypeOfAssignmentProfile, setIsTypeOfAssignmentProfile] =
    useState(false);
  const [profileInput, setProfileInput] = useState<
    AssignOrgProfileToAssetsInput | undefined
  >(undefined);
  const [dontShowConfirmationDialog, setDontShowConfirmationDialog] =
    useState(false);
  const [selectedProfileLabel, setSelectedProfileLabel] = useState<string>("");

  const {
    dispatch,
    state: { theme },
  } = useAppContext();

  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    margin: "0.75rem 0rem",
  };
  const isLightTheme = theme.theme === "light";

  const formTheme = useFormTheme();

  const mainForm = useForm({
    resolver: yupResolver(sensorsAtisAlphaSchema),
    defaultValues: {
      typeOfAssignment: defaultTypeOfAssignment,
      atisAlphaSensorProfile: defaultAtisAlphaSensorProfile,
    },
  });

  const {
    sortedAtisAlphaSensorProfilesDropdownItems,
    isSensorProfileNamesLoading,
  } = useGetSensorProfilesWithConfigurationSorted(
    SensorProfileConfigType.AtisAlpha,
    currentOrgId
  );
  ATIS_ALPHA_FORM_FIELDS[1].options =
    sortedAtisAlphaSensorProfilesDropdownItems;

  // effects
  useEffect(() => {
    if (isPresetProfile) {
      setIsTypeOfAssignmentProfile(true);
    }
    const isDontShowAgain =
      localStorage.getItem("setProfileConformation") === "true";
    setDontShowConfirmationDialog(isDontShowAgain);
  }, [selectedRecordsData, isPresetProfile]);

  // GraphQL

  // handlers
  const handleConfirm = (confirmed: boolean) => {
    setIsConfirmProfileAssignmentVisible((prev) => !prev);
    if (!confirmed) {
      return;
    }
    if (dontShowConfirmationDialog) {
      localStorage.setItem("setProfileConformation", "true");
    }
    if (profileInput) {
      mutateProfile({ input: profileInput });
    }
  };

  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        text: "Sensor(s) Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor(s) Update Failed!",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const selectedAssetsImeis: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.imei)
    .filter((imei): imei is string => !!imei);

  const selectedRowsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?._id)
    .filter((id): id is string => !!id);

  const selectedAssetsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.asset_id)
    .filter((id): id is string => !!id);

  const onSubmit = async () => {
    const isMainFormValid = await mainForm.trigger();
    if (!isMainFormValid || !!movingLimitError || !!parkedLimitError) return;

    if (isTypeOfAssignmentProfile) {
      const selectedProfile = mainForm.getValues().atisAlphaSensorProfile;
      const input: AssignOrgProfileToAssetsInput =
        prepareProfileToBeAssignedToAssetsInput(
          selectedRowsIds,
          selectedProfile
        );
      // if we have dontShowConfirmationDialog again clicked
      // and submitted to local storage, don't show confirmation dialog
      if (dontShowConfirmationDialog) {
        mutateProfile({ input });
      } else {
        setProfileInput(input);
        setIsConfirmProfileAssignmentVisible((prev) => !prev);
      }
    } else {
      const input = {
        selectedImeis: selectedAssetsImeis,
        orgId: currentOrgId ?? "",
        sensors: {
          atisAlpha: {
            lightActivatedSeconds: {
              moving: prepareRulesPayload(
                SensorProfileConfigType.AtisAlpha,
                atisMoving,
                min,
                movingLimit
              ),
              parked: prepareRulesPayload(
                SensorProfileConfigType.AtisAlpha,
                atisParked,
                min,
                parkedLimit
              ),
            },
          },
        },
      };
      mutateCustomProfile({ input });
    }
  };

  // handle change of Type of Assignment - show/hide of ATIS Alpha Sensor question
  const handleAssignmentTypeInputChange = (e: {
    name: string;
    value: string;
  }) => {
    const value = e?.value ?? undefined;
    if (!value) return;

    setIsTypeOfAssignmentProfile(value === "profile");

    // reset to initial sensor values if custom is reselected after being set initially
    if (value === "custom" && !isPresetProfile) {
      setAtisMoving(initialMoving);
      setAtisParked(initialParked);
      setMovingLimit(initialMovingLimit);
      setParkedLimit(initialParkedLimit);
    }

    // reset input field errors if the profile is selected
    if (value === "profile") {
      setMovingLimitError("");
      setParkedLimitError("");
    }
  };

  // handle change of atis alpha sensor profile dropdown
  const handleSensorProfileInputChange = (selectedSensorProfile: {
    configuration: {
      atisAlpha: { lightActivatedSeconds: MovingParkedThresholds };
    };
    label: string;
  }) => {
    // extract sensor values from selected profile
    const profileAtisData =
      selectedSensorProfile?.configuration?.atisAlpha?.lightActivatedSeconds;
    const moving = profileAtisData?.moving ?? [];
    const parked = profileAtisData?.parked ?? [];
    const selectedMovingLimit =
      getMaxValueFromThresholds(moving as SensorThresholdShort) ?? max;
    const selectedParkedLimit =
      getMaxValueFromThresholds(parked as SensorThresholdShort) ?? max;
    const selectedMoving =
      extractRules(moving as SensorThresholdShort) ?? atisDefaultValues;
    const selectedParked =
      extractRules(parked as SensorThresholdShort) ?? atisDefaultValues;

    // set values to state
    setAtisMoving(selectedMoving);
    setAtisParked(selectedParked);
    setMovingLimit(selectedMovingLimit);
    setParkedLimit(selectedParkedLimit);
    setSelectedProfileLabel(selectedSensorProfile?.label ?? "");
  };

  const handleCheckboxClick = () => {
    setDontShowConfirmationDialog((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inputRangeError = `Number must be between ${ATIS_ALPHA_HEALTHY_POINT_MIN} and ${ATIS_ALPHA_HEALTHY_POINT_MAX}.`;

  const handleMovingLimitInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valueMoving = Number(e.target.value);
    if (Number.isNaN(valueMoving)) return true;

    let evenMoving = returnEvenValuesFromRange(min, valueMoving);
    let movingErrorMsg = "";

    if (valueMoving < ATIS_ALPHA_HEALTHY_POINT_MIN) {
      evenMoving = returnEvenValuesFromRange(min, ATIS_ALPHA_HEALTHY_POINT_MIN);
      movingErrorMsg = inputRangeError;
    } else if (valueMoving > ATIS_ALPHA_HEALTHY_POINT_MAX) {
      evenMoving = returnEvenValuesFromRange(min, ATIS_ALPHA_HEALTHY_POINT_MAX);
      movingErrorMsg = inputRangeError;
    }

    setAtisMoving(evenMoving);
    setMovingLimit(valueMoving);
    setMovingLimitError(movingErrorMsg);
  };

  const handleParkedLimitInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parkedValue = Number(e.target.value);
    if (Number.isNaN(parkedValue)) return true;

    let evenParked = returnEvenValuesFromRange(min, parkedValue);
    let parkedErrorMsg = "";

    if (parkedValue < ATIS_ALPHA_HEALTHY_POINT_MIN) {
      evenParked = returnEvenValuesFromRange(min, ATIS_ALPHA_HEALTHY_POINT_MIN);
      parkedErrorMsg = inputRangeError;
    } else if (parkedValue > ATIS_ALPHA_HEALTHY_POINT_MAX) {
      evenParked = returnEvenValuesFromRange(min, ATIS_ALPHA_HEALTHY_POINT_MAX);
      parkedErrorMsg = inputRangeError;
    }

    setAtisParked(evenParked);
    setParkedLimit(parkedValue);
    setParkedLimitError(parkedErrorMsg);
  };

  // this is a mutation that we use when Type of Assignment is PROFILE
  const { mutate: mutateProfile, isLoading: isLoadingProfile } =
    useMutateProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  // this is a mutation that we use when Type of Assignment is CUSTOM
  const { mutate: mutateCustomProfile, isLoading: isLoadingCustomProfile } =
    useMutateCustomProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  const additionalCheckboxComponent = (
    <ConfigurationDialogAdditionalElement
      checked={dontShowConfirmationDialog}
      onChange={handleCheckboxClick}
    />
  );

  const movingMax = returnDynamicLimit(
    movingLimit,
    ATIS_ALPHA_HEALTHY_POINT_MIN,
    ATIS_ALPHA_HEALTHY_POINT_MAX
  );

  const parkedMax = returnDynamicLimit(
    parkedLimit,
    ATIS_ALPHA_HEALTHY_POINT_MIN,
    ATIS_ALPHA_HEALTHY_POINT_MAX
  );

  const isLoading = isLoadingProfile || isLoadingCustomProfile;
  const isAnySliderOrInputDirty =
    atisMoving.toString() !== initialMoving.toString() ||
    atisParked.toString() !== initialParked.toString() ||
    movingLimit !== initialMovingLimit ||
    parkedLimit !== initialParkedLimit;
  const isCustomAndPristine =
    !isPresetProfile && !isTypeOfAssignmentProfile && !isAnySliderOrInputDirty;
  const isCustomAndInvalid =
    !isTypeOfAssignmentProfile && (!!movingLimitError || !!parkedLimitError);
  const isProfileAndPristine = isPresetProfile && !mainForm.formState.isDirty;

  return (
    <Drawer
      testId="atis-alpha-sensor-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader text="Edit Sensor" onClose={handleClose} />

      <DrawerContent>
        <ThemeProvider theme={formTheme}>
          <Box className="h-full flex flex-col justify-between">
            <Box>
              {selectedRecordsData && (
                <SelectedRecordInfo selectedRecordsData={selectedRecordsData} />
              )}
              <Box className="pt-6 pb-10 flex-auto">
                <SensorProfileAssignmentForm
                  isTypeOfAssignmentProfile={isTypeOfAssignmentProfile}
                  form={mainForm}
                  SENSOR_FORM_FIELDS={ATIS_ALPHA_FORM_FIELDS}
                  handleAssignmentTypeInputChange={
                    handleAssignmentTypeInputChange
                  }
                  handleSensorProfileInputChange={
                    handleSensorProfileInputChange
                  }
                  isProfileDataLoading={isSensorProfileNamesLoading}
                />

                <Box data-testid="atis-form-control">
                  <Grid
                    container
                    className="drawerSection"
                    spacing={6}
                    direction="column"
                  >
                    <Grid item sx={{ marginBottom: "1.75rem" }}>
                      <Text
                        fontSize={14}
                        fontWeight="bold"
                        classes="!text-base !text-primary"
                      >
                        ATIS Settings - Moving
                      </Text>
                      {isLightTheme ? (
                        <ATISSensorDark style={svgIconSettings} />
                      ) : (
                        <ATISSensorLight style={svgIconSettings} />
                      )}

                      <FormControl sx={{ width: "100%" }}>
                        <WithAsterisk>
                          <Label htmlFor="Moving Limit">
                            Set the custom end of the slider
                          </Label>
                        </WithAsterisk>
                        <Input
                          value={movingLimit}
                          onChange={handleMovingLimitInputChange}
                          data-testid="atis-sensors-moving-limit-input"
                          disabled={isLoading || isTypeOfAssignmentProfile}
                          sx={{ width: "100%", mb: "1px" }}
                        />
                        {!!movingLimitError && (
                          <FormHelperText
                            data-testid="atis-sensors-moving-limit-error-text"
                            error={true}
                          >
                            {movingLimitError}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item sx={{ marginBottom: "2.75rem" }}>
                      <SensorSlider
                        values={atisMoving}
                        min={min}
                        max={movingMax}
                        marks={sliderMarks(min, movingMax, "Seconds")}
                        disabled={isLoading || isTypeOfAssignmentProfile}
                        onChange={setAtisMoving}
                      />
                    </Grid>

                    <Grid item sx={{ marginBottom: "1.75rem" }}>
                      <Text
                        fontSize={14}
                        fontWeight="bold"
                        classes="!text-base !text-primary"
                      >
                        ATIS Settings - Parked
                      </Text>
                      {isLightTheme ? (
                        <ATISSensorDark
                          style={svgIconSettings}
                          data-testid="temperature-profile-drawer-thermostat"
                        />
                      ) : (
                        <ATISSensorLight
                          style={svgIconSettings}
                          data-testid="temperature-profile-drawer-thermostat"
                        />
                      )}

                      <FormControl sx={{ width: "100%" }}>
                        <WithAsterisk>
                          <Label htmlFor="Parked Limit">
                            Set the custom end of the slider
                          </Label>
                        </WithAsterisk>
                        <Input
                          value={parkedLimit}
                          onChange={handleParkedLimitInputChange}
                          data-testid="atis-sensors-parked-limit-input"
                          disabled={isLoading || isTypeOfAssignmentProfile}
                          sx={{ width: "100%", mb: "1px" }}
                        />
                        {!!parkedLimitError && (
                          <FormHelperText
                            data-testid="atis-sensors-parked-limit-error-text"
                            error={true}
                          >
                            {parkedLimitError}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item sx={{ marginBottom: "2.75rem" }}>
                      <SensorSlider
                        values={atisParked}
                        min={min}
                        max={parkedMax}
                        marks={sliderMarks(min, parkedMax, "Seconds")}
                        disabled={isLoading}
                        onChange={setAtisParked}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <DrawerActions disabled={isLoading} onCancel={handleClose} />
          </Box>
        </ThemeProvider>
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving..." : "Apply to Selected"}
        disabled={
          isLoading ||
          isCustomAndPristine ||
          isCustomAndInvalid ||
          isProfileAndPristine
        }
        testId="atis-alpha-sensors-drawer-submit-btn"
        submit={() => onSubmit()}
      />

      {isTypeOfAssignmentProfile && isConfirmProfileAssignmentVisible && (
        <ConfirmationDialog
          title="Profile Assignment"
          message={`Warning, all of the sensor settings of profile ${selectedProfileLabel}
             will be applied to asset(s) ${selectedAssetsIds.join(
               ", "
             )}. Any custom sensor settings will be lost!`}
          open={isConfirmProfileAssignmentVisible}
          confirmButtonText="Confirm"
          cancelButtonText="Cancel"
          handleConfirmationResult={handleConfirm}
          additionalContent={additionalCheckboxComponent}
        />
      )}
    </Drawer>
  );
};

export default AtisDrawer;
