import { useEffect, useState } from "react";
import { themes } from "./utils";

/**
 * This hook observes current color scheme and returns currently set color scheme in the system.
 */
export const useBrowserColorScheme = () => {
  const initial =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? themes.dark
      : themes.light;
  const [state, setState] = useState<themes>(initial);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => {
      setState(e.matches ? themes.dark : themes.light);
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handler);
    return () =>
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", handler);
  }, []);

  return state;
};
