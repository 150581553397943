import { useState } from "react";
import { Feature as GeoJsonFeature } from "geojson";

export const useMapFocusedFeature = <T extends GeoJsonFeature>() => {
  const [focusedFeature, setFocusedFeature] = useState<T | null>(null);

  return {
    focusedFeature,
    setFocusedFeature,
  };
};
