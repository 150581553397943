declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_COGNITO_DOMAIN: string;
  REACT_APP_COGNITO_USER_POOL_ID: string;
  REACT_APP_COGNITO_CLIENT_ID: string;
  REACT_APP_COGNITO_IDENTITY_POOL_ID: string;
  REACT_APP_BULK_S3_BUCKET_NAME: string;
  REACT_APP_REGION: string;
  REACT_APP_APPSYNC_URL: string;
  REACT_APP_OPEN_CAGE_ID: string;
  REACT_APP_MAP_NAME: string;
  REACT_APP_MAPBOX_TOKEN: string;
  REACT_APP_GOOGLE_MAPS_KEY: string;
  REACT_APP_MUI_PREMIUM_LICENSE: string;
  REACT_APP_THEME: string;
  REACT_APP_LAUNCHDARKLY_CLIENT_ID: string;
  REACT_APP_GEO_SEARCH_KEY: string;
  REACT_APP_SATELLITE_MAP_NAME: string;
  REACT_APP_FETCHER_EAGER_DEFAULT_LIMIT: string;
  REACT_APP_FETCHER_EAGER_DEFAULT_CONCURRENCY: string;
  REACT_APP_FETCHER_EAGER_DEBUG: string;
  REACT_APP_FETCHER_EAGER_DEVICES_LIMIT: string;
  REACT_APP_FETCHER_EAGER_DEVICES_CONCURRENCY: string;
  REACT_APP_AWS_RUM_APPLICATION_ID: string;
  REACT_APP_AWS_RUM_ENDPOINT: string;
  REACT_APP_IDLE_TIMEOUT: string;
  REACT_APP_TOTAL_IDLE_THRESHOLD: string;
  REACT_APP_RELEASE_TAG: string;
  REACT_APP_ROLLOUT_API_PERFORMANCE: string;
  REACT_APP_AWS_ACCESS_KEY_ID: string;
  REACT_APP_AWS_SECRET_ACCESS_KEY: string;
  PUBLIC_URL: string;
  REACT_APP_PENDO_IO_KEY: string;
};
export const env: EnvType = { ...process.env, ...window.env };
