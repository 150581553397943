import { memo, SVGProps, FC } from "react";

interface HistoryProps extends SVGProps<SVGSVGElement> {}

const History: FC<HistoryProps> = (props) => {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 0.5C4.265 0.5 2.25 2.515 2.25 5H0.75L2.695 6.945L2.73 7.015L4.75 5H3.25C3.25 3.065 4.815 1.5 6.75 1.5C8.685 1.5 10.25 3.065 10.25 5C10.25 6.935 8.685 8.5 6.75 8.5C5.785 8.5 4.91 8.105 4.28 7.47L3.57 8.18C4.385 8.995 5.505 9.5 6.75 9.5C9.235 9.5 11.25 7.485 11.25 5C11.25 2.515 9.235 0.5 6.75 0.5ZM6.25 3V5.5L8.375 6.76L8.76 6.12L7 5.075V3H6.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(History);
