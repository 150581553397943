import { FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetAlertQuery,
  useGetAlertV2Query,
} from "../../../../graphql/operations";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { parseAlertParametersToObject } from "../../utils";
import { AlertComponent } from "../Alert/AlertComponent";

interface AlertRecordProps {
  id: string;
}

export const AlertRecord: FC<AlertRecordProps> = ({ id }: AlertRecordProps) => {
  const navigate = useNavigate();

  const {
    data: alertData,
    isLoading,
    isRefetching,
  } = useGetAlertV2Query(
    { id },
    {
      refetchOnMount: true,
      staleTime: 1000,
    }
  );

  const foundAlert = useMemo(() => {
    if (alertData?.getAlertV2) {
      return parseAlertParametersToObject(alertData.getAlertV2);
    }
    return alertData?.getAlertV2;
  }, [alertData?.getAlertV2]);
  const loading = isLoading;

  useEffect(() => {
    if (!isLoading && !foundAlert) {
      navigate(NavigationRoutes.Alerts);
    }
  }, [navigate, foundAlert, isLoading]);

  useSpinner(loading || isRefetching);
  return (
    <>
      {foundAlert && !isLoading && !isRefetching ? (
        <AlertComponent alert={foundAlert} alertType={foundAlert.type} />
      ) : (
        ""
      )}
    </>
  );
};
