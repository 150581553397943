import { memo, FC, SyntheticEvent } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import { HierarchyStateItem } from "../types";
import { getHiddenClass } from "../utils";
import { HierarchyNode } from "./HierarchyNode";

export type HierarchyProps = {
  item: HierarchyStateItem;
  onValueChange: (item: HierarchyStateItem) => void;
  onExpandedStateChange: (item: HierarchyStateItem, expanded: boolean) => void;
};

export const Hierarchy: FC<HierarchyProps> = memo(
  ({ item, onValueChange, onExpandedStateChange }: HierarchyProps) => {
    const handleAccordionStateChange = (
      e: SyntheticEvent,
      expanded: boolean
    ) => {
      e.stopPropagation();
      onExpandedStateChange(item, expanded);
    };

    return (
      <Box className={`leading-9 ${item.level === 0 ? "pl-0" : "pl-6"}`}>
        {item.children.length > 0 ? (
          <Accordion
            className={getHiddenClass(item.visible)}
            key={item.value}
            expanded={item.expanded}
            onChange={handleAccordionStateChange}
            elevation={0}
            sx={{
              "&.MuiPaper-root": {
                overflow: "hidden",
              },
            }}
          >
            <AccordionSummary
              className="leading-9 hover:bg-brand-transparent"
              aria-hidden={!item.visible}
              data-testid={`hierarchy-accordion-${item.value}`}
            >
              <HierarchyNode
                disableHover
                onValueChange={onValueChange}
                item={item}
              />
            </AccordionSummary>
            <AccordionDetails>
              {item.children.map((child) => (
                <Hierarchy
                  onExpandedStateChange={onExpandedStateChange}
                  onValueChange={onValueChange}
                  key={child.value}
                  item={child}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          <HierarchyNode
            item={item}
            key={item.value}
            onValueChange={onValueChange}
          />
        )}
      </Box>
    );
  }
);
