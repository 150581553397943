import { Maybe } from "yup/lib/types";
import {
  TableColumnFormat as ReportColumnFormat,
  TableValueDataType,
} from "../../../../../graphql/operations";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  dateAndTime: false,
  totalMileage: false,
  speedMph: false,
  speedKmph: false,
  tripState: false,
  assetType: false,
  assetId: false,
  imei: false,
  orgName: false,
  lastUpdated: false,
  healthStatus: false,
  engineHours: false,
};

export const getColumns = (
  timezone: Maybe<string> | undefined
): ReportGridColDef[] => {
  const commonColumnConfig = {
    flex: 1,
    minWidth: 120,
  };

  const createColumn = (
    field: string,
    headerName: string,
    format: ReportColumnFormat,
    additionalConfig = {}
  ) => ({
    field,
    headerName,
    format,
    ...commonColumnConfig,
    ...additionalConfig,
  });

  const columns = [
    createColumn(
      "dateAndTime",
      "Date and time",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
      }
    ),
    createColumn("totalMileage", "Total Mileage", ReportColumnFormat.String, {
      type: "number",
    }),
    createColumn("engineHours", "Engine Hours", ReportColumnFormat.String, {
      type: "number",
    }),
    createColumn("speedMph", "Speed (mph)", ReportColumnFormat.String, {
      type: "number",
    }),
    createColumn("speedKmph", "Speed (kmph)", ReportColumnFormat.String, {
      type: "number",
    }),
    createColumn("tripState", "Trip State", ReportColumnFormat.String),
    createColumn("assetType", "Asset Type", ReportColumnFormat.String),
    createColumn("assetId", "Asset ID", ReportColumnFormat.String),
    createColumn("imei", "Device ID", ReportColumnFormat.String),
    createColumn("orgName", "Organization", ReportColumnFormat.String, {
      filterable: false,
    }),
    createColumn(
      "lastUpdated",
      "Last Updated",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
      }
    ),
    createColumn("healthStatus", "Health Status", ReportColumnFormat.String, {
      filterable: false,
      sortable: false,
    }),
  ];
  return columns;
};

export const SEARCH_KEYS = [
  "dateAndTime",
  "totalMileage",
  "speedMph",
  "speedKmph",
  "tripState",
  "assetType",
  "assetId",
  "imei",
  "orgName",
  "lastUpdated",
  "healthStatus",
];

export const MILEAGE_COLUMN_TYPE_MAP = {
  dateAndTime: TableValueDataType.String,
  totalMileage: TableValueDataType.Number,
  engineHours: TableValueDataType.Number,
  speedMph: TableValueDataType.Number,
  speedKmph: TableValueDataType.Number,
  tripState: TableValueDataType.String,
  assetType: TableValueDataType.String,
  assetId: TableValueDataType.String,
  imei: TableValueDataType.String,
  orgName: TableValueDataType.String,
  lastUpdated: TableValueDataType.String,
  healthStatus: TableValueDataType.String,
};

export const OUTSIDE_GEOFENCE_COLUMN_TYPE_MAP: Record<
  string,
  TableValueDataType
> = {
  _id: TableValueDataType.String,
  "geofence.name": TableValueDataType.String,
  assetId: TableValueDataType.String,
  "dwelling.dwellingDays": TableValueDataType.Number,
  "dwelling.dwellingStatus": TableValueDataType.String,
  "fullAddress._category": TableValueDataType.String,
  "fullAddress._type": TableValueDataType.String,
  "fullAddress.continent": TableValueDataType.String,
  "fullAddress.country": TableValueDataType.String,
  "fullAddress.country_code": TableValueDataType.String,
  "fullAddress.postcode": TableValueDataType.String,
  "fullAddress.state": TableValueDataType.String,
  "fullAddress.state_code": TableValueDataType.String,
  "fullAddress.city": TableValueDataType.String,
  "tripStatus.cargoState": TableValueDataType.String,
  "tripStatus.odometer": TableValueDataType.Number,
  "tripStatus.tripState": TableValueDataType.String,
  asset_id: TableValueDataType.String,
  name: TableValueDataType.String,
  category: TableValueDataType.String,
  vin: TableValueDataType.String,
  lst_evnt_t: TableValueDataType.Date,
  imei: TableValueDataType.String,
  org_name: TableValueDataType.String,
  wheel_config: TableValueDataType.String,
  num_of_axles: TableValueDataType.Number,
  length: TableValueDataType.Number,
  last_gps_t: TableValueDataType.Date,
  door_type: TableValueDataType.String,
  prd_cde: TableValueDataType.String,

  // Sensors
  "sensors.temperature.data.internal": TableValueDataType.Number,
  "sensors.temperature.statusTemperature": TableValueDataType.String,
  "sensors.voltage.condition": TableValueDataType.String,
  "sensors.voltage.data.auxiliary": TableValueDataType.Number,
  "sensors.voltage.data.battery": TableValueDataType.Number,
  "sensors.voltage.data.main": TableValueDataType.Number,
  "sensors.voltage.data.solarAmperage": TableValueDataType.Number,
  "sensors.voltage.data.statusBattery": TableValueDataType.String,
  "sensors.voltage.displayName": TableValueDataType.String,
  "sensors.voltage.tlvId": TableValueDataType.String,
  "sensors.voltage.receivedDate": TableValueDataType.Date,

  // TPMS sensors
  "sensors.tpmsBeta.data.sensors.inner_curbside_pressure_psi":
    TableValueDataType.Number,
  "sensors.tpmsBeta.data.sensors.inner_curbside_temperature_f":
    TableValueDataType.Number,
  "sensors.tpmsBeta.data.sensors.inner_roadside_pressure_psi":
    TableValueDataType.Number,
  "sensors.tpmsBeta.data.sensors.inner_roadside_temperature_f":
    TableValueDataType.Number,
  "sensors.tpmsBeta.statusTpmsBeta": TableValueDataType.String,

  // Lite Sentry Gamma
  "sensors.liteSentryGamma.data.observations.deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.stopLightOut": TableValueDataType.Boolean,

  // ATIS
  "sensors.atisAlpha.statusAtisAlpha": TableValueDataType.String,
  "sensors.atisAlpha.data.lightActivatedNumber": TableValueDataType.Number,

  // Psi Wheel End
  "sensors.psiWheelEnd.data.psiWheelEndMeasure.left_temperature":
    TableValueDataType.Number,

  // Air Supply
  "sensors.psiAirSupply.statusPsiAirSupply": TableValueDataType.String,
  "sensors.psiAirSupply.data.tankPressure": TableValueDataType.Number,

  // Airbag
  "sensors.airbag.statusAirbag": TableValueDataType.String,

  // BLE Door Sensor
  "sensors.bleDoorSensor.data.doorState": TableValueDataType.String,

  // ABS
  "sensors.absBeta.data.numberOfFaults": TableValueDataType.Number,

  // Cargo Camera
  "sensors.cargoCamera.data.floorUsageStatus": TableValueDataType.String,

  // Liftgate Advertisement
  "sensors.liftgateAdvertisement.statusLiftgate": TableValueDataType.String,
  "sensors.liftgateAdvertisement.data.liftgateVoltage":
    TableValueDataType.Number,
};
