import { UserRole } from "../graphql/operations";

export const userPermissions = {
  dashboard: {
    search: {
      assets: [
        UserRole.Superadmin,
        UserRole.Clientadmin,
        UserRole.Equipmentmanager,
        UserRole.Fleetmanager,
        UserRole.Supervisor,
        UserRole.Customerservice,
        UserRole.Leaseagent,
        UserRole.Loadplanner,
        UserRole.Fleetviewer,
        UserRole.Installer,
      ],
      locations: [
        UserRole.Superadmin,
        UserRole.Clientadmin,
        UserRole.Equipmentmanager,
        UserRole.Fleetmanager,
        UserRole.Supervisor,
        UserRole.Customerservice,
      ],
      devices: [
        UserRole.Superadmin,
        UserRole.Clientadmin,
        UserRole.Supervisor,
        UserRole.Installer,
      ],
      geofences: [
        UserRole.Superadmin,
        UserRole.Clientadmin,
        UserRole.Supervisor,
        UserRole.Leaseagent,
      ],
      users: [
        UserRole.Superadmin,
        UserRole.Clientadmin,
        UserRole.Supervisor,
        UserRole.Maintenancemanager,
      ],
      organizations: [
        UserRole.Superadmin,
        UserRole.Clientadmin,
        UserRole.Equipmentmanager,
        UserRole.Supervisor,
        UserRole.Maintenancemanager,
      ],
    },
    deviceButton: [
      UserRole.Superadmin,
      UserRole.Clientadmin,
      UserRole.Supervisor,
    ],
  },
  assetManagement: {
    upload: [
      UserRole.Superadmin,
      UserRole.Clientadmin,
      UserRole.Equipmentmanager,
    ],
    addAsset: [
      UserRole.Superadmin,
      UserRole.Clientadmin,
      UserRole.Equipmentmanager,
    ],
    editAsset: [
      UserRole.Superadmin,
      UserRole.Clientadmin,
      UserRole.Equipmentmanager,
      UserRole.Installer,
    ],
    editSettings: [UserRole.Superadmin, UserRole.Clientadmin],
  },
};
