import { useState, FC } from "react";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last } from "lodash";
import { Bar, Tooltip } from "recharts";
import { ReactComponent as LiftgateSensorDark } from "../../../../../../../assets/svgs/liftGateSensorDark.svg";
import { ReactComponent as LiftgateSensorLight } from "../../../../../../../assets/svgs/liftgateSensorLight.svg";
import { VOLTAGE_CHART_DATE_RANGE_OPTIONS } from "../../../../../../../constants/map";
import { useAppContext } from "../../../../../../../context/AppContext";
import {
  ColorsPalette,
  DefaultBrandColorPalette,
} from "../../../../../../../design-system/colors-palette";
import {
  useGetLiftgateMotorTimeAggregatedDataOsQuery,
  ReportType,
} from "../../../../../../../graphql/operations";
import BaseBarChart from "../../../../../../../shared/components/BaseVerticalBarChart";
import { VerticalBarChartOverlay } from "../../../../../../../shared/components/LoadingOverlaySkeletons/VerticalBarChartLoader/VerticalBarChartOverlay";
import { themes } from "../../../../../../../shared/hooks/theme/utils";
import {
  getEndOfToday,
  getStartOfDay,
  getSubDays,
  formatDate,
} from "../../../../../../../utils/date";
import BatteryChartLegendButton from "../../../../Shared/BatteryChart/BatteryChartLegend";

export type LiftgateMotorRunTimeChartProps = {
  assetDetails: { id?: string | null; imei?: string | null };
};

const LiftgateMotorRunTimeChart: FC<LiftgateMotorRunTimeChartProps> = ({
  assetDetails,
}) => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === themes.light;

  const [motorStartDate, setMotorStartDate] = useState<Date | null>(
    getStartOfDay(getSubDays(new Date(), 30))
  );

  const [motorEndDate, setMotorEndDate] = useState<Date | null>(
    getEndOfToday()
  );
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    VOLTAGE_CHART_DATE_RANGE_OPTIONS[0].value
  );
  const setSelectedLastDateRangeOption = () => {
    setSelectedDateRangeOption(last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!);
  };

  const handleMotorDateRangeChange = (value: number) => {
    const option = VOLTAGE_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    setSelectedDateRangeOption(value);
    if (option?.getRange) {
      const range = option.getRange();
      setMotorStartDate(range[0]);
      setMotorEndDate(range[1]);
    }
  };

  const { data: dataMotorTime, isLoading } =
    useGetLiftgateMotorTimeAggregatedDataOsQuery(
      {
        input: {
          imei: assetDetails.imei,
          assetId: assetDetails.id ?? "",
          reportType: ReportType.Sensors,
          startDate: motorStartDate,
          endDate: motorEndDate,
        },
      },
      {
        enabled: Boolean(assetDetails.imei && assetDetails.id),
      }
    );

  const graphData =
    dataMotorTime?.getLiftgateAggregatedDataOS?.data?.[0]?.eventHistoryData
      ?.liftgate?.motorTime ?? [];

  const cappedGraphData = graphData.map((item) => ({
    ...item,
    motor1_max:
      item && item.motor1_max && item.motor1_max > 24 ? 24 : item?.motor1_max,
    motor2_max:
      item && item.motor2_max && item.motor2_max > 24 ? 24 : item?.motor2_max,
  }));

  const [barsState, setBarsState] = useState([
    {
      key: "motor1_max",
      fill: DefaultBrandColorPalette.Primary,
      name: "Motor One",
      visible: true,
    },
    {
      key: "motor2_max",
      fill: ColorsPalette.AccentAqua,
      name: "Motor Two",
      visible: true,
    },
  ]);

  const handleLegendClick = (key: string) => {
    setBarsState((prevState) =>
      prevState.map((bar) =>
        bar.key === key ? { ...bar, visible: !bar.visible } : bar
      )
    );
  };

  const svgIconSettings = {
    width: "80px",
    height: "80px",
    display: "block",
  };

  return isLoading ? (
    <VerticalBarChartOverlay />
  ) : (
    <>
      <Box className="!mb-1 justify w-full flex items-center">
        {isLightTheme ? (
          <LiftgateSensorDark style={svgIconSettings} />
        ) : (
          <LiftgateSensorLight style={svgIconSettings} />
        )}

        <Typography
          variant="caption"
          className="!pl-2 !text-base inline-block !font-semibold !text-primary-blue"
        >
          Liftgate Motor Run Time Graph
        </Typography>
      </Box>

      <Box className="flex items-center justify-end gap-4 py-4">
        <MobileDatePicker
          label="Start date"
          inputFormat="MM/dd/yyyy"
          closeOnSelect
          value={motorStartDate}
          shouldDisableDate={(day) =>
            day < getStartOfDay(getSubDays(new Date(), 90))
          }
          disableFuture
          onChange={(date) => {
            setMotorStartDate(date);
            setSelectedLastDateRangeOption();
          }}
          DialogProps={{
            className: "trending-temperature-date-picker",
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  top: -6,
                  left: 15,
                  fontWeight: "normal",
                },
              }}
              data-testid="chart-start-date"
              variant="outlined"
              {...params}
            />
          )}
        />
        <span className="text-base font-normal text-primary">to</span>
        <MobileDatePicker
          DialogProps={{
            className: "trending-temperature-date-picker",
          }}
          label="End date"
          closeOnSelect
          inputFormat="MM/dd/yyyy"
          value={motorEndDate}
          shouldDisableDate={(day) =>
            Boolean(motorStartDate && day < motorStartDate)
          }
          disableFuture
          onChange={(date) => {
            setMotorEndDate(date);
            setSelectedLastDateRangeOption();
          }}
          renderInput={(params) => (
            <TextField
              data-testid="chart-end-date"
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": {
                  top: -6,
                  left: 15,
                  fontWeight: "normal",
                },
              }}
              {...params}
            />
          )}
        />

        <TextField
          data-testid="chart-date-range-select"
          select
          value={selectedDateRangeOption}
          variant="outlined"
          className="w-40"
          onChange={(e) => handleMotorDateRangeChange(Number(e.target.value))}
        >
          {VOLTAGE_CHART_DATE_RANGE_OPTIONS.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              data-testid={`chart-date-range-select-option-${option.value}`}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <BaseBarChart
        data={cappedGraphData}
        xAxisTickFormatter={(tickItem: string) => {
          return formatDate(new Date(tickItem), "MM/dd");
        }}
        xAsisKey="date"
        yAxisLabel="Hours"
        yAxisDomain={[0, 24]}
        width="100%"
        height={500}
      >
        <Tooltip
          offset={-5}
          content={(props) => {
            return (
              <Box className="rounded-lg bg-custom-tooltip-background border border-sub-header-border p-3 flex flex-col gap-3">
                <Typography className="!text-md !text-sub-header-text !font-medium !leading-3 !mb-1">
                  {props.label?.replaceAll("-", "/")}
                </Typography>
                {props.payload?.[0] && (
                  <Typography className="!text-md !text-sub-header-text !font-medium !leading-3 !mb-1">
                    {props.payload?.[0]?.name} :{" "}
                    <span className="ml-2">
                      {props.payload?.[0]?.value} Hours
                    </span>
                  </Typography>
                )}
                {props.payload?.[1] && (
                  <Typography className="!text-md !text-sub-header-text !font-medium !leading-3">
                    {props.payload?.[1]?.name} :{" "}
                    <span className="ml-2">
                      {props.payload?.[1]?.value} Hours
                    </span>
                  </Typography>
                )}
              </Box>
            );
          }}
        />
        {barsState
          .filter((bar) => bar.visible)
          .map((bar) => (
            <Bar
              key={bar.key}
              dataKey={bar.key}
              fill={bar.fill}
              name={bar.name}
              barSize={50}
            />
          ))}
      </BaseBarChart>
      {Boolean(graphData.length) && (
        <div className="flex justify-center gap-7">
          {barsState.map((bar) => (
            <BatteryChartLegendButton
              key={bar.key}
              label={bar.name}
              checked={bar.visible}
              color={bar.fill}
              onClick={() => handleLegendClick(bar.key)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default LiftgateMotorRunTimeChart;
