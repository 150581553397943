import {
  Maybe,
  SharedAssetsInvitationHideProperties,
} from "../../../graphql/operations";

export const hidePropertiesLabels: {
  [key: string]: "Speed" | "Mileage";
} = {
  speed: "Speed",
  mileage: "Mileage",
};

export const parseHiddenProperties = (
  hiddenProperties?: Maybe<SharedAssetsInvitationHideProperties>
): string | null => {
  if (!hiddenProperties) {
    return null;
  }

  return Object.entries(hiddenProperties)
    .filter(([_, isHidden]) => Boolean(isHidden)) // leave only properties where hide is true
    .map(([propertyName]) => hidePropertiesLabels[propertyName]) // map incoming property name to readable string
    .join(", ");
};

export enum SharedAssetInvitationServerErrors {
  InvitationNotFound = "ERROR_INVITATION_NOT_FOUND",
  UnauthorizedAccess = "ERROR_USER_NOT_AUTHORIZED_TO_VIEW_INVITATION",
  InvitationExpired = "ERROR_INVITATION_EXPIRED",
}
