import { ReactElement } from "react";
import { Box, Skeleton } from "@mui/material";
import type { SensorChip } from "../../hooks/useGetSensorStateWidget";

export interface SensorStateWidgetProps {
  sensorLabel: string;
  sensorChip: SensorChip | null;
  icon?: ReactElement;
  isFetching?: boolean;
  justifyBetween?: boolean;
}

export const SensorStateWidget: React.FC<SensorStateWidgetProps> = ({
  sensorLabel,
  sensorChip,
  icon,
  isFetching,
  justifyBetween = true,
}) => {
  return sensorChip && sensorChip.label !== "Unknown" ? (
    <div
      className={`flex items-center ${justifyBetween ? "justify-between" : ""}`}
    >
      <div className="flex items-center mr-2 text-typography-tooltip">
        <div className="mr-1">{icon}</div>
        <div className="flex pt-0.5 font-normal">{sensorLabel}</div>
      </div>
      {isFetching ? (
        <Box
          data-testid={"skeleton-sensor-loader-" + sensorLabel}
          key={"skeleton-sensor-loader-" + sensorLabel}
        >
          <Skeleton
            sx={{ height: "14px", width: "65px" }}
            variant="rectangular"
          />
        </Box>
      ) : (
        <Box
          className={`flex items-center text-white py-[2px] px-[4px] rounded-[4px]`}
          sx={{ backgroundColor: sensorChip.color }}
        >
          {sensorChip.icon}
          <p className="ml-0.5 text-[9px] font-bold leading-none">
            {sensorChip.label}
          </p>
        </Box>
      )}
    </div>
  ) : (
    <></>
  );
};
