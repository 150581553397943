import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useTheme } from "../../../shared/components/HierarchySelect/hooks/useTheme";
import { useReportViewTheme } from "./useReportViewTheme";

export const useFrequencySelectTheme = () => {
  const currentTheme = useTheme();
  const reportViewTheme = useReportViewTheme();

  return createTheme(deepmerge(currentTheme, reportViewTheme));
};
