import React, {
  FC,
  memo,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Select, ThemeProvider } from "@mui/material";
import { useFrequencySelectTheme } from "../../../views/ReportView/hooks/useFrequencySelectTheme";
import { SelectedValues } from "../HierarchySelect/types";
import { RolesList } from "../SubscribersSelect/components/RolesList";
import { roles } from "../SubscribersSelect/constants";
import { StateObject } from "../SubscribersSelect/interfaces";
import { convertStateObjectToValuesArr } from "../SubscribersSelect/utils/convertStateObjectToValuesArr";

export type RolesSelectProps = {
  loading?: boolean;
  values: SelectedValues;
  onChange: (values: string[]) => void;
  onBlur: () => void;
  required?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
};

export const RolesSelect: FC<RolesSelectProps> = memo(
  ({
    values,
    onChange,
    onBlur,
    required,
    invalid,
    disabled,
    handleUpdateField,
    fieldPath,
  }: RolesSelectProps) => {
    const theme = useFrequencySelectTheme();
    const select: MutableRefObject<HTMLInputElement | null | undefined> =
      useRef();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const preparedRolesState = useMemo(() => {
      const result: StateObject = {};
      roles.forEach((role) => {
        result[role.value] = values.includes(role.value as string);
      });

      return result;
    }, [values]);

    const [selectedRoles, setSelectedRoles] =
      useState<StateObject>(preparedRolesState);

    useEffect(() => {
      setSelectedRoles(preparedRolesState);
    }, [preparedRolesState]);

    const handleClose = () => {
      const rolesList = convertStateObjectToValuesArr(selectedRoles);
      // Trigger the onChange event with the new values
      onChange && onChange(rolesList);
      handleUpdateField && handleUpdateField(rolesList, fieldPath);

      setIsOpen(false);
    };

    const selectWidth = select?.current?.clientWidth;

    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Select
            data-testid="roles-select"
            MenuProps={{ autoFocus: false }}
            value={values}
            multiple
            error={invalid}
            required={required}
            onBlur={onBlur}
            fullWidth
            displayEmpty
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            renderValue={() => []} // we don't want to display any value in the select box
            ref={select}
            disabled={disabled}
          >
            <Box
              sx={{
                width: selectWidth ? `${selectWidth}px` : "320px",
              }}
              className="min-h-48 max-h-96 overflow-y-auto px-4"
            >
              <Box className="pb-5 pt-8">
                <RolesList
                  selectedRoles={selectedRoles}
                  setSelectedRoles={setSelectedRoles}
                  roles={
                    roles as {
                      label: string;
                      value: string;
                    }[]
                  }
                />
              </Box>
            </Box>
          </Select>
        </Box>
      </ThemeProvider>
    );
  }
);
