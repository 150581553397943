import { ADD_TABLE_STATE, TABLE_FILTERS_RESET } from "../../constants";
import { Action } from "../commonTypes";

export type TableInitialState = {
  tableData: any;
};

export type TableReducerType = (
  state: TableInitialState | undefined,
  action: Action
) => TableInitialState;

export const initialTableState: TableInitialState = {
  tableData: {},
};

export const TableReducer = (state = initialTableState, action: Action) => {
  switch (action.type) {
    case ADD_TABLE_STATE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          [action.payload.key]: {
            tableName: action.payload.key,
            ...action.payload.data,
          },
        },
      };
    case TABLE_FILTERS_RESET:
      const { key } = action.payload;
      const targetTable = state.tableData[key];
      if (targetTable) {
        return {
          ...state,
          tableData: {
            ...state.tableData,
            [key]: {
              ...targetTable,
              filter: {
                ...targetTable.filter,
                filterModel: {
                  ...targetTable.filter.filterModel,
                  items: [],
                },
              },
            },
          },
        };
      }
      return state;
    default:
      return state;
  }
};
