import { useState } from "react";
import { liteSentryGammaDefaultValues } from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import { LiteSentryGammaThresholds } from "../../../../AdminPanel/tabs/Profiles/Drawers/LiteSentryGammaDrawer/types";

export type LightsSettingsState = {
  brake: {
    present: {
      bluePowerPresentValues: number[];
      setBluePowerPresentValues: React.Dispatch<React.SetStateAction<number[]>>;
    };
    notPresent: {
      bluePowerNotPresentValues: number[];
      setBluePowerNotPresentValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
    precheck: {
      brakeCircuitPrecheckValues: number[];
      setBrakeCircuitPrecheckValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
  };
  left: {
    present: {
      leftTurnSignalCircuitTractorPowerPresentValues: number[];
      setLeftTurnSignalCircuitTractorPowerPresentValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
    precheck: {
      leftTurnSignalCircuitPrecheckValues: number[];
      setLeftTurnSignalCircuitPrecheckValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
  };
  right: {
    present: {
      rightTurnSignalCircuitTractorPowerPresentValues: number[];
      setRightTurnSignalCircuitTractorPowerPresentValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
    precheck: {
      rightTurnSignalCircuitPrecheckValues: number[];
      setRightTurnSignalCircuitPrecheckValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
  };
  marker: {
    present: {
      markerCircuitTractorPowerPresentValues: number[];
      setMarkerCircuitTractorPowerPresentValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
    precheck: {
      markerCircuitPrecheckValues: number[];
      setMarkerCircuitPrecheckValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
  };
  license: {
    present: {
      licenseCircuitTractorPowerPresentValues: number[];
      setLicenseCircuitTractorPowerPresentValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
    precheck: {
      licenseCircuitPrecheckValues: number[];
      setLicenseCircuitPrecheckValues: React.Dispatch<
        React.SetStateAction<number[]>
      >;
    };
  };
};

export const useLightsSettingsState = (
  thresholds: LiteSentryGammaThresholds
): LightsSettingsState => {
  const [bluePowerPresentValues, setBluePowerPresentValues] = useState<
    number[]
  >(
    thresholds.brakeCircuitThresholds.bluePowerPresentThresholds ??
      liteSentryGammaDefaultValues
  );
  const [bluePowerNotPresentValues, setBluePowerNotPresentValues] = useState<
    number[]
  >(
    thresholds.brakeCircuitThresholds.bluePowerNotPresentThresholds ??
      liteSentryGammaDefaultValues
  );
  const [brakeCircuitPrecheckValues, setBrakeCircuitPrecheckValues] = useState<
    number[]
  >(
    thresholds.brakeCircuitThresholds.precheckThresholds ??
      liteSentryGammaDefaultValues
  );
  const [
    leftTurnSignalCircuitTractorPowerPresentValues,
    setLeftTurnSignalCircuitTractorPowerPresentValues,
  ] = useState<number[]>(
    thresholds.leftTurnSignalThresholds.tractorPowerPresentThresholds ??
      liteSentryGammaDefaultValues
  );
  const [
    leftTurnSignalCircuitPrecheckValues,
    setLeftTurnSignalCircuitPrecheckValues,
  ] = useState<number[]>(
    thresholds.leftTurnSignalThresholds.precheckThresholds ??
      liteSentryGammaDefaultValues
  );
  const [
    rightTurnSignalCircuitTractorPowerPresentValues,
    setRightTurnSignalCircuitTractorPowerPresentValues,
  ] = useState<number[]>(
    thresholds.rightTurnSignalThresholds.tractorPowerPresentThresholds ??
      liteSentryGammaDefaultValues
  );
  const [
    rightTurnSignalCircuitPrecheckValues,
    setRightTurnSignalCircuitPrecheckValues,
  ] = useState<number[]>(
    thresholds.rightTurnSignalThresholds.precheckThresholds ??
      liteSentryGammaDefaultValues
  );
  const [
    markerCircuitTractorPowerPresentValues,
    setMarkerCircuitTractorPowerPresentValues,
  ] = useState<number[]>(
    thresholds.markerCircuitThresholds.tractorPowerPresentThresholds ??
      liteSentryGammaDefaultValues
  );
  const [markerCircuitPrecheckValues, setMarkerCircuitPrecheckValues] =
    useState<number[]>(
      thresholds.markerCircuitThresholds.precheckThresholds ??
        liteSentryGammaDefaultValues
    );
  const [
    licenseCircuitTractorPowerPresentValues,
    setLicenseCircuitTractorPowerPresentValues,
  ] = useState<number[]>(
    thresholds.licenseCircuitThresholds.tractorPowerPresentThresholds ??
      liteSentryGammaDefaultValues
  );
  const [licenseCircuitPrecheckValues, setLicenseCircuitPrecheckValues] =
    useState<number[]>(
      thresholds.licenseCircuitThresholds.precheckThresholds ??
        liteSentryGammaDefaultValues
    );

  return {
    brake: {
      present: { bluePowerPresentValues, setBluePowerPresentValues },
      notPresent: { bluePowerNotPresentValues, setBluePowerNotPresentValues },
      precheck: { brakeCircuitPrecheckValues, setBrakeCircuitPrecheckValues },
    },
    left: {
      present: {
        leftTurnSignalCircuitTractorPowerPresentValues,
        setLeftTurnSignalCircuitTractorPowerPresentValues,
      },
      precheck: {
        leftTurnSignalCircuitPrecheckValues,
        setLeftTurnSignalCircuitPrecheckValues,
      },
    },
    right: {
      present: {
        rightTurnSignalCircuitTractorPowerPresentValues,
        setRightTurnSignalCircuitTractorPowerPresentValues,
      },
      precheck: {
        rightTurnSignalCircuitPrecheckValues,
        setRightTurnSignalCircuitPrecheckValues,
      },
    },
    marker: {
      present: {
        markerCircuitTractorPowerPresentValues,
        setMarkerCircuitTractorPowerPresentValues,
      },
      precheck: { markerCircuitPrecheckValues, setMarkerCircuitPrecheckValues },
    },
    license: {
      present: {
        licenseCircuitTractorPowerPresentValues,
        setLicenseCircuitTractorPowerPresentValues,
      },
      precheck: {
        licenseCircuitPrecheckValues,
        setLicenseCircuitPrecheckValues,
      },
    },
  };
};

export const mapLightningStateToThresholds = (state: LightsSettingsState) => ({
  brakeCircuitThresholds: {
    bluePowerPresentThresholds: state.brake.present.bluePowerPresentValues,
    bluePowerNotPresentThresholds:
      state.brake.notPresent.bluePowerNotPresentValues,
    precheckThresholds: state.brake.precheck.brakeCircuitPrecheckValues,
  },
  leftTurnSignalThresholds: {
    tractorPowerPresentThresholds:
      state.left.present.leftTurnSignalCircuitTractorPowerPresentValues,
    precheckThresholds: state.left.precheck.leftTurnSignalCircuitPrecheckValues,
  },
  rightTurnSignalThresholds: {
    tractorPowerPresentThresholds:
      state.right.present.rightTurnSignalCircuitTractorPowerPresentValues,
    precheckThresholds:
      state.right.precheck.rightTurnSignalCircuitPrecheckValues,
  },
  markerCircuitThresholds: {
    tractorPowerPresentThresholds:
      state.marker.present.markerCircuitTractorPowerPresentValues,
    precheckThresholds: state.marker.precheck.markerCircuitPrecheckValues,
  },
  licenseCircuitThresholds: {
    tractorPowerPresentThresholds:
      state.license.present.licenseCircuitTractorPowerPresentValues,
    precheckThresholds: state.license.precheck.licenseCircuitPrecheckValues,
  },
});
