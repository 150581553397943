import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { useJwt, decodeToken } from "react-jwt";
import { AwsRum, AwsRumConfig } from "aws-rum-web";
import AWS from "aws-sdk";
import { interval } from "rxjs";
import packageJson from "../../package.json";
import config from "../config";
import { env } from "../env";
import { UserRole } from "../graphql/operations";
import { useRefreshCognitoAuthTokens } from "../services/cognito";
import {
  defaultUserRolePermissions,
  UserRolePermissionsByScope,
  getPermissionsMap,
} from "../shared/components/WithPermissions/userRolePermissions";
import { themes } from "../shared/hooks/theme/utils";
import useIsMounted from "../shared/hooks/useIsMounted";

const { region, cognito } = config;

AWS.config.region = region;

// Setup the threshold for refreshing the token, it will be performed X seconds before the token expires
const REFRESH_TOKEN_EXPIRATION_OFFSET = 3 * 60; // 3 minutes, in seconds

// Setup the lifetime of the refresh token, that should match with the Cognito configuration
// Note: Cognito doesn't provide the expiration time for the refresh token, so we need to set it manually, and keep it in sync
const REFRESH_TOKEN_EXPIRATION = 1 * 24 * 60 * 60; // 1 day, in seconds

export type CognitoToken = {
  expiresIn: number;
  idToken: string;
  accessToken?: string;
  refreshToken: string;
};

export type UserInfo = {
  atHash: string;
  sub: string;
  groups: UserRole[];
  emailVerified: string;
  username: string;
  givenName: string;
  familyName: string;
  email: string;
};

export type ImpersonationSession = {
  active: boolean;
  impersonate_by: string;
  impersonated_user_id: string;
  added_date: string | Date | number;
  updated_date: string | Date | number;
} | null;

export type AuthContextState = {
  tokens: CognitoToken | null;
  accessToken: any;
  isAuthorized: boolean;
  userInfo: UserInfo | null;
  userRolePermissions: UserRolePermissionsByScope;
  login: (tokens: CognitoToken) => void;
  logout: () => void;
  refreshAuthTokens$: (callbackFunction?: any) => any;
  impersonationSession: ImpersonationSession;
  setImpersonationSession: (
    impersonationSessionData: ImpersonationSession
  ) => void;
  decodedToken: any;
};

const AuthContext = createContext<AuthContextState>({
  tokens: null,
  accessToken: null,
  isAuthorized: false,
  userInfo: null,
  userRolePermissions: defaultUserRolePermissions,
  login: () => {},
  logout: () => {},
  refreshAuthTokens$: (callbackFunction?: any): any => {},
  impersonationSession: null,
  setImpersonationSession: () => {},
  decodedToken: null,
});

export const AUTH_COOKIE_NAME = "PI-auth-tokens";
export const REFRESH_COOKIE_NAME = "PI-refresh-token";
export const ACCESS_COOKIE_NAME = "PI-access-token";

const getRolePermissions = (decodedToken: any): UserRolePermissionsByScope => {
  const userRoles: string[] = decodedToken
    ? decodedToken["cognito:groups"]
    : [];
  return getPermissionsMap(userRoles);
};

const AuthContextProvider = ({ children }: { children: ReactElement }) => {
  const isMounted = useIsMounted();

  const [cookies, setCookie, removeCookie] = useCookies([
    AUTH_COOKIE_NAME,
    ACCESS_COOKIE_NAME,
    REFRESH_COOKIE_NAME,
  ]);
  const [accessTokenCookies, setAccessTokenCookies] = useCookies([
    ACCESS_COOKIE_NAME,
  ]);
  const [refreshTokenCookies, setRefreshTokenCookies] = useCookies([
    REFRESH_COOKIE_NAME,
  ]);

  const initialTokens =
    cookies[AUTH_COOKIE_NAME] && refreshTokenCookies[REFRESH_COOKIE_NAME]
      ? {
          idToken: cookies[AUTH_COOKIE_NAME].idToken,
          refreshToken: refreshTokenCookies[REFRESH_COOKIE_NAME],
          expiresIn: cookies[AUTH_COOKIE_NAME].expiresIn,
        }
      : null;

  const [tokens, setTokens] = useState<CognitoToken | null>(
    initialTokens ?? null
  );
  const [accessToken, setAccessToken] = useState<string | undefined>(
    accessTokenCookies[ACCESS_COOKIE_NAME] ?? null
  );
  const [refreshToken, setRefreshToken] = useState<string | undefined>(
    refreshTokenCookies[REFRESH_COOKIE_NAME] ?? null
  );

  const [impersonationSession, setImpersonationSessionData] =
    useState<ImpersonationSession>(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { mutate: refreshAuthTokens } = useRefreshCognitoAuthTokens();
  const { decodedToken } = useJwt<any>(tokens?.idToken ?? "");

  const handleLogin = useCallback(
    ({ expiresIn, accessToken, idToken, refreshToken }: CognitoToken) => {
      if (cookies[AUTH_COOKIE_NAME]) {
        removeCookie(AUTH_COOKIE_NAME, { path: "/" });
      }

      // Set smart expiration dates for the tokens stored in cookies
      const idTokenExpirationDate = getTokenExpirationDate(idToken);
      const accessTokenExpirationDate = getTokenExpirationDate(accessToken);

      // Set cookies for the tokens
      const tokenData = {
        idToken,
        refreshToken,
        expiresIn,
      };
      setCookie(
        AUTH_COOKIE_NAME,
        { idToken, expiresIn },
        {
          path: "/",
          expires: idTokenExpirationDate,
        }
      );
      setAccessTokenCookies(ACCESS_COOKIE_NAME, accessToken, {
        path: "/",
        expires: accessTokenExpirationDate,
      });

      // Refresh token should be stored in cookies only if it's not there, otherwise it will corrupt the expiration date
      if (!refreshTokenCookies[REFRESH_COOKIE_NAME]) {
        const refreshTokenExpirationDate = new Date(
          Date.now() + REFRESH_TOKEN_EXPIRATION * 1000
        );

        setRefreshTokenCookies(REFRESH_COOKIE_NAME, refreshToken, {
          path: "/",
          expires: refreshTokenExpirationDate,
        });
      }

      // Set the state for the tokens
      setTokens(tokenData);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
    },
    [
      cookies,
      refreshTokenCookies,
      setCookie,
      removeCookie,
      setAccessTokenCookies,
      setRefreshTokenCookies,
    ]
  );

  const handleLogout = useCallback(() => {
    if (tokens?.idToken) {
      const credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId,
        Logins: {
          [`cognito-idp.${region}.amazonaws.com/${cognito.userPoolId}`]:
            tokens.idToken,
        },
      });
      credentials.clearCachedId();
    }
    removeCookie(AUTH_COOKIE_NAME);
    removeCookie(ACCESS_COOKIE_NAME);
    removeCookie(REFRESH_COOKIE_NAME);
    setTokens(null);
    // Ref: https://phillips-connect.atlassian.net/browse/PRJIND-6604
    // storing theme even after logout
    const storedTheme = localStorage.getItem("theme") ?? themes.light;
    localStorage.clear();
    localStorage.setItem("theme", storedTheme);
  }, [removeCookie, tokens?.idToken]);

  useEffect(() => {
    if (tokens?.idToken) {
      const newCredentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId,
        Logins: {
          [`cognito-idp.${region}.amazonaws.com/${cognito.userPoolId}`]:
            tokens.idToken,
        },
      });
      (newCredentials as AWS.CognitoIdentityCredentials)
        .refreshPromise()
        .then(() => {
          AWS.config.credentials = newCredentials;
          if (isMounted.current) {
            setIsAuthorized(true);
          }
          if (env.REACT_APP_AWS_RUM_APPLICATION_ID) {
            try {
              const authenticatedConfig: AwsRumConfig = {
                sessionSampleRate: 1,
                endpoint: env.REACT_APP_AWS_RUM_ENDPOINT,
                telemetries: ["errors", "performance", "http"],
                allowCookies: true,
                enableXRay: true,
                cookieAttributes: { unique: true },
                enableRumClient: false,
              };

              const APPLICATION_ID: string =
                env.REACT_APP_AWS_RUM_APPLICATION_ID;
              const APPLICATION_VERSION: string = packageJson.version;
              const APPLICATION_REGION: string = env.REACT_APP_REGION;

              const rum = new AwsRum(
                APPLICATION_ID,
                APPLICATION_VERSION,
                APPLICATION_REGION,
                authenticatedConfig
              );
              rum.setAwsCredentials(AWS.config.credentials as AWS.Credentials);
              rum.enable();
            } catch (error) {
              console.log(error);
            }
          }
        });
    }
  }, [isMounted, tokens?.idToken]);

  // This functionality is to refresh auth tockens on-demand
  // Especially when the scenarios like, Whenevr a new organization is created,
  // User auth tokens will get refetched to get the latest tokens,So that,usercan accessthe latest organizations list
  const refreshAuthTokens$ = useCallback(
    (callbackFunction?: any): any => {
      if (refreshToken) {
        // TODO: Remove console logs once PRJIND-10095 is tested and completed
        console.log("PRJIND-10095: refresh access token");
        refreshAuthTokens(refreshToken, {
          onSuccess: ({ data }) => {
            // TODO: Remove console logs once PRJIND-10095 is tested and completed
            console.log("PRJIND-10095: refreshed access token successfully");
            if (data && isMounted.current) {
              handleLogin({
                expiresIn: data.expires_in,
                accessToken: data.access_token,
                idToken: data.id_token,
                refreshToken,
              });
            }
            if (callbackFunction) {
              callbackFunction(null);
            }
          },
          onError: (error) => {
            if (callbackFunction) {
              callbackFunction(error);
            }
          },
        });
      }
    },
    [refreshAuthTokens, handleLogin, refreshToken, isMounted]
  );

  const setImpersonationSession = (
    impersonationSessionData: ImpersonationSession
  ) => {
    setImpersonationSessionData(impersonationSessionData);
  };

  useEffect(() => {
    // TODO: Remove console logs once PRJIND-10095 is tested and completed
    console.log("PRJIND-10095: setup refresh token interval");

    let expiresIn = 0; // Default to 0, to immediately refresh the token or force login

    if (!refreshToken) {
      // TODO: Remove console logs once PRJIND-10095 is tested and completed
      console.log("PRJIND-10095: no refresh token found, force login");
      return;
    }

    if (accessToken) {
      const accessTokenData = decodeToken<{ iat: number; exp: number }>(
        accessToken
      );

      if (accessTokenData) {
        // TODO: Remove console logs once PRJIND-10095 is tested and completed
        const accessTokenIssuedAt = new Date(accessTokenData.iat * 1000);
        const accessTokenExpiresAt = new Date(accessTokenData.exp * 1000);

        console.log(
          "PRJIND-10095: accessToken is present, determine the next refresh time based on it"
        );
        console.log("PRJIND-10095: accessTokenIssuedAt", accessTokenIssuedAt);
        console.log("PRJIND-10095: accessTokenExpiresAt", accessTokenExpiresAt);

        // Setup timer to refresh token 3 minutes before it expires
        const refreshTokenAt =
          accessTokenData.exp - REFRESH_TOKEN_EXPIRATION_OFFSET;
        const dateNow = Math.floor(Date.now() / 1000);
        expiresIn = refreshTokenAt - dateNow;

        // TODO: Remove console logs once PRJIND-10095 is tested and completed
        console.log(
          "PRJIND-10095: access token will be refreshed at %s after %s seconds",
          new Date(refreshTokenAt * 1000),
          expiresIn
        );
      }
    }

    if (expiresIn <= 0) {
      // TODO: Remove console logs once PRJIND-10095 is tested and completed
      console.log("PRJIND-10095: access token has expired, time to refresh");
      refreshAuthTokens$();
      return;
    }

    const subscription = interval(expiresIn * 1000).subscribe(() => {
      // TODO: Remove console logs once PRJIND-10095 is tested and completed
      console.log("PRJIND-10095: timer runs out, time to refresh access token");
      refreshAuthTokens$();
    });

    return () => subscription.unsubscribe();
  }, [isMounted, refreshToken, accessToken, refreshAuthTokens$]);

  const userInfo = useMemo<UserInfo | null>(
    () =>
      decodedToken
        ? {
            atHash: decodedToken.at_hash,
            sub: decodedToken.sub,
            groups: decodedToken["cognito:groups"],
            emailVerified: decodedToken.email_verified,
            username: decodedToken["cognito:username"],
            givenName: decodedToken.given_name,
            familyName: decodedToken.family_name,
            email: decodedToken.email,
          }
        : null,
    [decodedToken]
  );

  const userRolePermissions = useMemo<any | null>(
    () => getRolePermissions(decodedToken),
    [decodedToken]
  );

  return (
    <AuthContext.Provider
      value={{
        tokens,
        accessToken,
        isAuthorized,
        userInfo,
        userRolePermissions,
        impersonationSession,
        setImpersonationSession,
        login: handleLogin,
        logout: handleLogout,
        refreshAuthTokens$: refreshAuthTokens$,
        decodedToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContextProvider;

const getTokenExpirationDate = (token: string | undefined): Date => {
  const expirationDate = new Date();

  if (!token) {
    return expirationDate;
  }

  const tokenData = decodeToken<{ exp: number }>(token);
  if (!tokenData) {
    return expirationDate;
  }

  return new Date(tokenData.exp * 1000);
};
