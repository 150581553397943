import { memo, SVGProps, FC } from "react";

interface SvgSolarBatteryProps extends SVGProps<SVGSVGElement> {}

const SvgSolarBattery: FC<SvgSolarBatteryProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="battery-svg-solar-battery"
      {...props}
    >
      <g clipPath="url(#clip0_11689_5498)">
        <path
          d="M24.6105 44L18.6436 38.2099H10.4227V29.989L4.5 24.0663L10.4227 18.1436V9.92265H18.6436L24.6105 4L30.489 9.92265H38.7099V18.1436L44.6326 24.0663L38.7099 29.989V38.2099H30.489L24.6105 44ZM24.6105 35.558C27.7928 35.558 30.4963 34.4383 32.721 32.1989C34.9457 29.9595 36.058 27.2339 36.058 24.0221C36.058 20.8398 34.9457 18.1363 32.721 15.9116C30.4963 13.6869 27.7928 12.5746 24.6105 12.5746C21.3987 12.5746 18.6731 13.6869 16.4337 15.9116C14.1943 18.1363 13.0746 20.8398 13.0746 24.0221C13.0746 27.2339 14.1943 29.9595 16.4337 32.1989C18.6731 34.4383 21.3987 35.558 24.6105 35.558ZM24.6105 32.9061C22.1354 32.9061 20.0359 32.0442 18.3122 30.3204C16.5884 28.5967 15.7265 26.4972 15.7265 24.0221C15.7265 21.5764 16.5884 19.4991 18.3122 17.7901C20.0359 16.081 22.1354 15.2265 24.6105 15.2265C27.0562 15.2265 29.1335 16.081 30.8425 17.7901C32.5516 19.4991 33.4061 21.5764 33.4061 24.0221C33.4061 26.4972 32.5516 28.5967 30.8425 30.3204C29.1335 32.0442 27.0562 32.9061 24.6105 32.9061Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11689_5498">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(SvgSolarBattery);
