import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { IconButton } from "@mui/material";
import { Dashboard, MeUserData } from "../../../../../graphql/operations";
import { CustomTooltip } from "../../../../../shared/components/CustomTooltip/CustomTooltip";

export interface FavoriteButtonProps {
  favoriteDashboards: number[];
  toggleFavoriteDashboard: () => void;
  dashboard?: Dashboard;
  isDashboardFavoriteForTheUserOrganization?: boolean;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  isDashboardFavoriteForTheUserOrganization,
  favoriteDashboards,
  toggleFavoriteDashboard,
  dashboard,
}) => {
  return (
    <CustomTooltip
      title={
        isDashboardFavoriteForTheUserOrganization
          ? "This dashboard is marked as favorite by your organization"
          : ""
      }
      arrow
    >
      <IconButton
        size="large"
        onClick={() => {
          if (isDashboardFavoriteForTheUserOrganization) {
            return;
          }
          toggleFavoriteDashboard();
        }}
        className={`!p-1 !mr-1 ${
          isDashboardFavoriteForTheUserOrganization && "!cursor-default"
        }`}
        data-testid="favorite-dashboard-button"
      >
        <StarOutlinedIcon
          sx={{
            color: isDashboardFavoriteForTheUserOrganization
              ? "var(--brand-lighter) !important"
              : "",
          }}
          className={
            favoriteDashboards?.includes(dashboard?.id as number)
              ? "!text-brand"
              : "text-primary"
          }
        />
      </IconButton>
    </CustomTooltip>
  );
};

export default FavoriteButton;
