import { useMemo } from "react";
import { UseQueryOptions } from "@tanstack/react-query";
import {
  ConfigurationSetType,
  GetConfigurationSetsQuery,
  ProductData,
  useGetConfigurationSetsQuery,
} from "../../graphql/operations";
import { useCurrentOrg } from "./useCurrentOrg";

export const useProductsList = (
  orgId: string | undefined = undefined,
  options: UseQueryOptions<
    GetConfigurationSetsQuery,
    unknown,
    GetConfigurationSetsQuery
  > = {}
): {
  products: ProductData[];
  productsMap: Map<string, ProductData>;
  isLoading: boolean;
} => {
  const currentOrganization = useCurrentOrg();
  const { isLoading, data } = useGetConfigurationSetsQuery(
    {
      input: {
        orgId: orgId ?? currentOrganization?._id ?? "",
        type: ConfigurationSetType.ProductCode,
      },
    },
    {
      enabled: Boolean(currentOrganization),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 86400, // 1 day. Products list is not supposed to change frequently
      staleTime: Infinity,
      ...options,
    }
  );

  // Memoize products list and filter out duplicates by product code
  const products: ProductData[] = useMemo(() => {
    const configurationSets = data?.getConfigurationSets ?? [];

    return configurationSets
      .map((configurationSet) => {
        if (!configurationSet?.value) {
          return null;
        }

        const product = JSON.parse(configurationSet.value); // TODO: Add type guard
        if (!product.product_code || !product.asset_configuration) {
          return null;
        }

        return {
          _id: configurationSet._id,
          product_name: `${configurationSet.name} (${product.product_code})`,
          product_code: product.product_code,
          asset_configuration: product.asset_configuration,
        };
      })
      .filter(Boolean) as ProductData[];
  }, [data?.getConfigurationSets]);

  // Memoize products list as a map for faster lookup
  const productsMap = useMemo(
    () =>
      new Map<string, ProductData>(
        products.map((product) => [product._id, product])
      ),
    [products]
  );

  return {
    products,
    productsMap,
    isLoading,
  };
};
