import { useQuery } from "@tanstack/react-query";

type Coordinates = {
  lat: string;
  lng: string;
};

export type GeoSearchResult = {
  matchedAgainst?: string;
  bounds: {
    northeast: Coordinates;
    southwest: Coordinates;
  };
  formatted: string;
  geometry: Coordinates;
  name: string;
};

type GeoSearchParams = {
  searchText: string;
  limit: number;
};

export const createLocationInfo = (
  placeDetails: google.maps.places.PlaceResult | null
): GeoSearchResult | null => {
  if (!placeDetails) return null;

  const northeastLat =
    placeDetails.geometry?.viewport?.getNorthEast()?.lat?.().toString() ?? "";
  const northeastLng =
    placeDetails.geometry?.viewport?.getNorthEast()?.lng?.().toString() ?? "";
  const southwestLat =
    placeDetails.geometry?.viewport?.getSouthWest()?.lat?.().toString() ?? "";
  const southwestLng =
    placeDetails.geometry?.viewport?.getSouthWest()?.lng?.().toString() ?? "";

  const lat = placeDetails.geometry?.location?.lat?.().toString() ?? "";
  const lng = placeDetails.geometry?.location?.lng?.().toString() ?? "";

  return {
    bounds: {
      northeast: { lat: northeastLat, lng: northeastLng },
      southwest: { lat: southwestLat, lng: southwestLng },
    },
    formatted: placeDetails.formatted_address ?? "",
    geometry: { lat, lng },
    name: placeDetails.name ?? "",
  };
};
export const fetchGeoSearchResults = async (
  params: GeoSearchParams
): Promise<GeoSearchResult[]> => {
  return new Promise((resolve, reject) => {
    const autocompleteService = new google.maps.places.AutocompleteService();
    const geoSearchResults: GeoSearchResult[] = [];

    autocompleteService.getPlacePredictions(
      { input: params.searchText },
      async (predictions, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          const geocodePredictions = predictions.filter((prediction) =>
            prediction.types.includes("geocode")
          );

          if (geocodePredictions.length === 0) {
            // No geocode predictions, resolve with empty array to avoid infinite waiting
            resolve(geoSearchResults);
            return;
          }

          // Collect promises for fetching place details
          const placeDetailsPromises = geocodePredictions.map((prediction) => {
            return getPlaceDetails(prediction.place_id).then((placeDetails) =>
              createLocationInfo(placeDetails)
            );
          });

          // Wait for all promises to resolve
          const resolvedLocationInfos = await Promise.all(placeDetailsPromises);

          // Filter out null values and add to geoSearchResults
          resolvedLocationInfos.forEach((locationInfo) => {
            if (locationInfo) {
              geoSearchResults.push(locationInfo);
            }
          });

          resolve(geoSearchResults);
        } else {
          // Handle error or no predictions, resolve with empty array
          resolve(geoSearchResults);
        }
      }
    );
  });
};

export const getPlaceDetails = (
  placeId: string
): Promise<google.maps.places.PlaceResult | null> => {
  return new Promise((resolve, reject) => {
    const placesService = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    placesService.getDetails({ placeId }, (placeResult, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(placeResult);
      } else {
        // If status is not OK, resolve with null
        resolve(null);
      }
    });
  });
};

export const useGeoSearch = (params: GeoSearchParams, isEnabled: boolean) => {
  return useQuery<GeoSearchResult[], Error, GeoSearchResult[]>(
    ["geoSearch", params],
    async () => {
      return await fetchGeoSearchResults(params);
    },
    {
      enabled: params.searchText.length > 1 && isEnabled,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};
