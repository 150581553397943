import { isEmpty } from "lodash";
import * as yup from "yup";
import { UserRole } from "../../../../../graphql/operations";
import { transformers, validatePhoneNumber } from "../../../../../utils";

export const uploadUserSchema = yup.object().shape({
  customer_orgs_id: yup
    .array()
    .max(1)
    .min(1)
    .required("Org is required")
    .transform(transformers.string),
  lastName: yup
    .string()
    .required("Last Name is required")
    .transform(transformers.string),
  firstName: yup
    .string()
    .required("First Name is required")
    .transform(transformers.string),
  name: yup
    .string()
    .required("Username is required")
    .transform(transformers.string),
  email: yup
    .string()
    .required("Email is required")
    .transform(transformers.string),
  phoneNumber: yup
    .string()
    .nullable()
    .optional()
    .test(
      "isValidPhoneNumber",
      "Phone number must be 5 to 20 digits",
      (input) => {
        if (!input) return true;
        return isEmpty(input) ? true : validatePhoneNumber(input) !== null;
      }
    )
    .transform(transformers.string),
  groups: yup
    .array(
      yup
        .mixed<UserRole>()
        .oneOf(Object.values(UserRole))
        .required("Group is required")
    )
    .max(1)
    .min(1)
    .required("Group is required")
    .transform((value: unknown[]) => value.map(transformers.string)),
  sendInvitation: yup.string().optional().transform(transformers.string),
});
