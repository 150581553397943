import { useState } from "react";
import { GoogleMapsTypeIds, MapType } from "../../../../enums/map";

interface GoogleMapTypeIdHook {
  googleMapTypeId: string;
  setGoogleMapTypeId: (mapTypeId: string) => void;
}

/**
 * Simple hook to manage map style
 *
 * The map style will be saved in local storage and will be used as default
 * The hook will return the map style and a function to update it
 *
 * @param type MapType - manage the map style in the global map and the widget map separately
 * @param style string - control the default map style if there is no value in local storage
 */
const useGoogleMapStyle = (
  type: MapType = MapType.Global,
  style: string = GoogleMapsTypeIds.ROADMAP
): GoogleMapTypeIdHook => {
  const GOOGLE_MAP_STYLE_KEY = `${type}_google_map_style`;

  const [googleMapTypeId, setGoogleMapTypeIdState] = useState<string>(
    localStorage.getItem(GOOGLE_MAP_STYLE_KEY) ?? style
  );

  const setGoogleMapTypeId = (mapTypeId: string): void => {
    localStorage.setItem(GOOGLE_MAP_STYLE_KEY, mapTypeId);
    setGoogleMapTypeIdState(mapTypeId);
  };

  return {
    googleMapTypeId,
    setGoogleMapTypeId,
  };
};

export default useGoogleMapStyle;
