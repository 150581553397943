import {
  GetAssetsQueryInput,
  useGetGeofenceCategoriesQuery,
  ConfigurationSet,
  ConfigurationSetType,
  Maybe,
  useGetConfigurationSetsQuery,
} from "../../../../graphql/operations";

interface UseGetTypeOptionsProps {
  watchOrgName: Maybe<string> | undefined;
}
interface UseGetGeofenceNamesResponse {
  isFetching: boolean;
  geofenceAssetCategories: string[];
}

export const useGetTypeAssetOptions = (
  input: GetAssetsQueryInput
): UseGetGeofenceNamesResponse => {
  const { data, isFetching } = useGetGeofenceCategoriesQuery(
    { input },
    { enabled: !!input?.orgId }
  );

  return {
    isFetching,
    geofenceAssetCategories:
      data?.getAssetsGeofenceCategoriesOS ?? ([] as string[]),
  };
};

export const useGetTypeOptions = ({ watchOrgName }: UseGetTypeOptionsProps) => {
  const { data: dataConfigurations, isFetching: isConfigurationFetching } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: watchOrgName ?? "",
          type: ConfigurationSetType?.GeofenceType,
        },
      },
      {
        enabled: !!watchOrgName,
      }
    );

  const configsList = dataConfigurations?.getConfigurationSets ?? [];
  const mapTypesToOptions = (
    data: ConfigurationSet[],
    orgName: string | undefined | null
  ) => {
    const disabledOrgIds: string[] = [];

    data.forEach((item) => {
      const parsedValue = JSON.parse(item.value);
      const orgIds = parsedValue.disabledForOrganizations || [];

      if (orgIds.length > 0) {
        disabledOrgIds.push(item._id);
      }
    });

    return data
      .filter((item) => {
        const parsedValue = JSON.parse(item.value);
        const orgDisabledIds = parsedValue.disabledForOrganizations || [];

        const isDisabled = orgName && orgDisabledIds.includes(orgName);
        const isParentDisabled =
          item.parentId && disabledOrgIds.includes(item.parentId);
        return !isDisabled && !isParentDisabled;
      })
      .map((item) => ({
        id: item._id,
        label: item.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const geofenceTypesOpts = mapTypesToOptions(configsList, watchOrgName);

  const getSubTypeOptions = (typeId?: string | null) => {
    if (!typeId) return [];

    const childTypes = configsList.filter(
      (configItem) => configItem.parentId === typeId
    );
    return mapTypesToOptions(childTypes, watchOrgName);
  };

  return {
    geofenceTypesOpts,
    isConfigurationFetching,
    getSubTypeOptions,
  };
};
