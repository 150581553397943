import * as yup from "yup";
import { FormField } from "../../../../../types";
import { transformers } from "../../../../../utils";

const nullableString = yup.string().transform(transformers.string);
export const schema = yup.object().shape({
  _id: yup.string().optional().transform(transformers.string),
  orgId: yup.string().required("Organization is required").nullable(),
  configuration: yup.object().shape({
    typeId: yup.string().required("Type is required").nullable(),
    subTypeId: yup.string().nullable(),
  }),
  geofence: yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .transform(transformers.string),
    fullAddressFormatted: nullableString,
    code: nullableString,
    description: nullableString,
    tags: yup
      .array()
      .notRequired()
      .nullable()
      .optional()
      .transform(transformers.geofenceTags),
  }),
  metadata: yup.object().optional().shape({
    owner: nullableString,
  }),
});

export const GEOFENCE_FORM_FIELDS: Array<Partial<FormField>> = [
  // Required Fields
  // Name Field
  {
    name: "geofence.name",
    type: "text",
    label: "Name",
    required: true,
    disabled: false,
  },
  {
    name: "orgId",
    type: "autocomplete",
    label: "Organization Name",
    required: true,
    matchid: "true",
    disabled: false,
    loading: false,
    options: [],
  },
  {
    name: "configuration.typeId",
    type: "autocomplete",
    label: "Type",
    required: true,
    matchid: "true",
    disabled: false,
    loading: false,
    options: [],
  },
  {
    name: "configuration.subTypeId",
    type: "autocomplete",
    label: "Subtype",
    required: true,
    matchid: "true",
    disabled: false,
    loading: false,
    options: [],
  },

  // Optional Fields
  {
    name: "geofence.parentId",
    type: "autocomplete",
    label: "Parent",
    required: false,
    matchid: "true",
    disabled: false,
    loading: false,
    options: [],
  },
  {
    name: "geofence.tags",
    type: "creatableautocomplete",
    label: "Tags",
    required: false,
    geofence: true,
    matchid: "true",
    disabled: false,
    loading: false,
    options: [],
  },
  {
    name: "geofence.fullAddressFormatted",
    type: "text",
    label: "Address",
    disabled: false,
    required: false,
  },
  {
    name: "metadata.owner",
    type: "text",
    label: "Owner",
    disabled: false,
    required: false,
  },
  {
    name: "geofence.code",
    type: "text",
    label: "Location Code",
    disabled: false,
    required: false,
  },
  {
    name: "geofence.description",
    type: "text",
    label: "Location Description",
    disabled: false,
    required: false,
  },
];
