import { ReactNode } from "react";
import { Control, FieldValues, UseFormReturn } from "react-hook-form";
import { Box, CircularProgress } from "@mui/material";
import { BatchTitles } from "../../../constants/batches";
import { BatchFormFieldsTypes } from "../../../views/BatchesView/BatchManagementUtils";
import FileUploadForm from "./FileUploadForm/FileUploadForm";

export const renderDialogContent = ({
  title,
  file,
  missingFileError,
  control,
  isDataUploaded,
  isDataUploading,
  dialogFields,
  uploadResultComponent,
  allowedFormats,
  onFilesUpload,
  onDeleteFile,
  form,
}: {
  title: BatchTitles;
  file?: File;
  missingFileError: boolean;
  control: Control;
  isDataUploading?: boolean;
  isDataUploaded?: boolean;
  dialogFields: BatchFormFieldsTypes;
  uploadResultComponent: ReactNode;
  onFilesUpload: (file: File) => void;
  onDeleteFile: () => void;
  allowedFormats: string[];
  form: UseFormReturn<FieldValues>;
}) => {
  if (isDataUploaded) {
    return <>{uploadResultComponent}</>;
  }
  if (isDataUploading) {
    return (
      <Box className="flex justify-center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <FileUploadForm
      title={title}
      file={file}
      dialogFields={dialogFields}
      missingFileError={missingFileError}
      control={control}
      allowedFormats={allowedFormats}
      onDeleteFile={onDeleteFile}
      onFileUpload={onFilesUpload}
      form={form}
    />
  );
};
