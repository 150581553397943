import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton, Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import {
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-premium";

export interface CustomGridTreeDataGroupingCellProps {
  params: GridRenderCellParams;
  icon?: React.ReactNode;
}

export const CustomGridTreeDataGroupingCell: React.FC<
  CustomGridTreeDataGroupingCellProps
> = ({ params, icon }) => {
  const { id, field, rowNode } = params;
  const apiRef = useGridApiContext();

  const handleClick: BoxProps["onClick"] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box
      data-testid="custom-grouping-cell"
      sx={{
        ml: rowNode.depth * 4, // applying a larger margin-left on the left for each depth level
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box onClick={handleClick} tabIndex={-1}>
        {rowNode.children && (
          <IconButton
            aria-label="expand"
            data-testid="custom-grouping-cell-expand"
          >
            {rowNode.childrenExpanded ? (
              <KeyboardArrowDownIcon sx={{ height: 18, width: 18 }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ height: 18, width: 18 }} />
            )}
          </IconButton>
        )}
      </Box>
      <Typography fontSize={14}>{rowNode.groupingKey}</Typography>
      {icon}
    </Box>
  );
};
