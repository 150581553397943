interface HslBrandColors {
  primary: string;
  secondary: string;
  transparent: string;
  lighter: string;
}

/**
 * Parses a given HEX color to its HSL representation with multiple shades.
 *
 * @see http://en.wikipedia.org/wiki/HSL_color_space
 * @see https://gist.github.com/mjackson/5311256
 */
export const parseHexToHslBrandColors = (
  hex: string
): HslBrandColors | null => {
  // extract the pair groups of HEX color - #[FF][FF][FF]
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    return null;
  }

  // converts the extracted pair groups to RGB
  const r = parseInt(result[1], 16) / 255;
  const g = parseInt(result[2], 16) / 255;
  const b = parseInt(result[3], 16) / 255;

  // Converts the RGB color to HSL.
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = (max + min) / 2;
  let l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  const primaryColorInHsl = `hsl(${h}, ${s}%, ${l}%)`;
  const secondaryColorInHsl = `hsl(${h}, ${s}%, ${Math.round(l / 2)}%)`;
  const primaryColorInHslTransparent = `hsla(${h}, ${s}%, ${l}%, 0.2)`;
  const primaryColorInHslLighter = `hsl(${h}, ${s}%, 75%)`;
  // Add more color variations if needed.

  return {
    primary: primaryColorInHsl,
    secondary: secondaryColorInHsl,
    transparent: primaryColorInHslTransparent,
    lighter: primaryColorInHslLighter,
  };
};
