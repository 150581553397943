/**
 * Removes the ID from the URL and navigates to the new URL without ID.
 * @param navigate Function to perform navigation.
 * @param location Current location object containing path and search params.
 */
export const redirectWithoutId = (
  navigate: (path: string, options?: { replace?: boolean }) => void,
  location: { pathname: string; search: string }
) => {
  const basePath = location.pathname.split("/").slice(0, 3).join("/");
  const searchParams = location.search;
  navigate(`${basePath}${searchParams}`, { replace: true });
};
