import { useImperativeHandle, forwardRef, memo, ReactNode } from "react";
import Box from "@mui/material/Box";
import {
  PAGE_DIALOG,
  PAGE_SNACKBAR,
  PAGE_SPINNER,
  PAGE_SPINNER_MESSAGE,
} from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import Dialog from "../Dialog";
import Header from "../Header";
import Snackbar from "../Snackbar";
import Spinner from "../Spinner";

export type PageProps = {
  title?: string;
  className?: string;
  contentClassNames?: string;
  children?: ReactNode;
  styles?: object;
  "data-testid"?: string;
};

const Page = forwardRef<any, PageProps>(
  (
    {
      className = "",
      contentClassNames = "",
      title = "",
      styles = {},
      children,
      "data-testid": dataTestId = "page",
    },
    ref
  ) => {
    const { state, dispatch } = useAppContext() || {};

    const fixSnackbarOnClose = (onClose?: () => void) => {
      if (onClose) onClose();
      dispatch({ type: PAGE_SNACKBAR, payload: null });
    };

    useImperativeHandle(ref, () => ({
      /**
       * Opens a modal dialog with a message, confirmation, etc.
       * @param config See DialogConfig interface
       */
      showDialog(config: any) {
        dispatch({ type: PAGE_DIALOG, payload: config });
      },

      /**
       * Hides the current dialog
       */
      hideDialog() {
        dispatch({ type: PAGE_DIALOG, payload: null });
      },

      /**
       * Adds or removes a full screen spinner
       * You can have multiple spinners, because there may be more than one background process running.
       * When a process finishes, call addSpinner(-1) to remove one
       * Call addSpinner(0) to remove all spinners
       *
       * @param increment Spinners to add, or negative integer to remove them. 0 turns off all current spinners.
       * @param message Message to be displayed under the spinner
       */
      addSpinner(increment: number, message: string) {
        dispatch({
          type: PAGE_SPINNER,
          payload: {
            increment,
            message,
          },
        });
      },

      /**
       * Changes the text message under the spinner
       *
       * @param message The message
       */
      changeSpinner(message: string) {
        dispatch({ type: PAGE_SPINNER_MESSAGE, payload: message });
      },

      /**
       * Displays a snackbar message
       */
      snackbar(props: any) {
        const payload = {
          ...props,
          onClose: () => fixSnackbarOnClose(props.onClose),
        };

        dispatch({ type: PAGE_SNACKBAR, payload });
      },

      /**
       * Closes the current snackbar
       */
      closeSnackbar() {
        dispatch({ type: PAGE_SNACKBAR, payload: null });
      },
    }));

    return (
      <div
        className={`flex h-full flex-col relative ${className}`}
        data-testid={dataTestId}
        style={styles}
      >
        <Header title={title} />
        {/* Page content from the parent component */}
        <Box
          className={`h-full relative mt-[68px] md:mt-[76px] flex w-full ${contentClassNames}`}
        >
          <div className="flex w-full flex-col">{children}</div>
        </Box>

        {/* Full screen spinner */}
        <Spinner {...state.page.spinner} />

        {/* Error / warning / info snackbar */}
        <Snackbar {...state.page.snackbar} />

        {/* Dialog for messages, confirmations, etc */}
        <Dialog {...state.page.dialog} />
      </div>
    );
  }
);

export default memo(Page);
