import { FC, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { getConvertedPressureValueWithUnit } from "../../../../../utils/convertPressure";
import { AirTankInfoCard } from "../AirTankInfoCard/AirTankInfoCard";
import { AirTankInfoTable } from "../AirTankInfoTable/AirTankInfoTable";
import { AirTankSummaryData } from "../BrakesTab/helpers";
import ToggleViewButton from "../ToggleViewButton";
import { TiresTabView } from "../ToggleViewButton/ToggleViewButton";

export interface AirTankStatusProps {
  sensor: AirTankSummaryData;
}

export const AirTankInfoComponent: FC<AirTankStatusProps> = ({ sensor }) => {
  const pressureUnit = useGetPressureUnitPreference();

  const [activeView, setActiveView] = useState<TiresTabView>(TiresTabView.Card);

  return (
    <>
      {sensor && (
        <Grid item lg={12} className="w-full flex">
          <Box
            className="mb-4 w-full flex flex-col bg-dashboard_subheader__bg rounded-lg pr-4 pt-4 pl-4 pb-20"
            data-testid="airTankInfoComponent"
          >
            <Box className="pb-4">
              <Grid item xs={12} className="flex justify-end relative">
                <Grid
                  item
                  xs={activeView === TiresTabView.Table}
                  style={{
                    position:
                      activeView === TiresTabView.Table
                        ? "absolute"
                        : "relative",
                  }}
                  className="flex"
                >
                  <ToggleViewButton
                    activeView={activeView}
                    onClick={setActiveView}
                    data-testid="toggle-view-button"
                    whiteTheme={true}
                  />
                </Grid>
              </Grid>
            </Box>
            {activeView === TiresTabView.Card && sensor && (
              <AirTankInfoCard
                title={sensor.title}
                status={sensor.status}
                pressure={(sensor.pressure as unknown as number) ?? 0}
                tripStatus={sensor.tripStatus}
                lastReported={sensor.lastReported}
              />
            )}
            {activeView === TiresTabView.Table && sensor && (
              <AirTankInfoTable
                title={sensor.title}
                status={sensor.status}
                pressure={(sensor.pressure as unknown as number) ?? 0}
                tripStatus={sensor.tripStatus}
                lastReported={sensor.lastReported}
              />
            )}
          </Box>
        </Grid>
      )}
    </>
  );
};
