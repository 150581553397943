import { useMemo } from "react";
import {
  getGridStringOperators,
  GridCellParams,
  GridFilterInputValue,
  GridFilterItem,
  GridFilterOperator,
} from "@mui/x-data-grid-premium";
import { GridColDef } from "@mui/x-data-grid/models";
import { isNil } from "lodash";
import { StringTableFilterValue } from "./types";

export const useExtendTableFilters = (
  tableColumns: GridColDef[],
  tableFiltersToSkip: StringTableFilterValue[] = []
) => {
  const isNotEqualOperator: GridFilterOperator = useMemo(() => {
    return {
      label: "is not equal",
      value: "isNotEqual",
      getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }

        return (params: GridCellParams): boolean => {
          if (isNil(params.value)) return true;
          // value can be a number if the column type is not specified. To prevent errors
          // we need to operate it as a string
          if (typeof params.value === "number") {
            return params.value.toString() !== filterItem.value.toString();
          } else {
            return (
              params.value.toLowerCase() !== filterItem.value.toLowerCase()
            );
          }
        };
      },
      InputComponent: GridFilterInputValue,
      InputComponentProps: { type: "string" },
    };
  }, []);

  const doesNotContainOperator: GridFilterOperator = useMemo(() => {
    return {
      label: "does not contain",
      value: "doesNotContain",
      getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }

        return (params: GridCellParams): boolean => {
          if (isNil(params.value)) return true;
          // value can be a number if the column type is not specified. To prevent errors
          // we need to operate it as a string
          if (typeof params.value === "number") {
            return !params.value.toString().includes(filterItem.value);
          } else {
            return !params.value
              .toLowerCase()
              .includes(filterItem.value.toLowerCase());
          }
        };
      },
      InputComponent: GridFilterInputValue,
      InputComponentProps: { type: "string" },
    };
  }, []);

  const updatedColumns = useMemo(() => {
    return tableColumns.map((column) => {
      if (column.field === "dwelling.dwellingDays") {
        column.type = "number";
      }
      if (!column.type || column.type === "string") {
        const allStringOperators = getGridStringOperators();
        const containsOperator =
          allStringOperators.shift() as GridFilterOperator<any, any, any>;
        const equalsOperator = allStringOperators.shift() as GridFilterOperator<
          any,
          any,
          any
        >;

        const filterOperators = [
          containsOperator,
          doesNotContainOperator,
          equalsOperator,
          isNotEqualOperator,
          ...allStringOperators,
        ];

        column.filterOperators =
          tableFiltersToSkip?.length > 0
            ? filterOperators.filter(
                (f) =>
                  !tableFiltersToSkip.includes(
                    f.value as StringTableFilterValue
                  )
              )
            : filterOperators;
      }
      return column;
    });
  }, [
    tableColumns,
    isNotEqualOperator,
    doesNotContainOperator,
    tableFiltersToSkip,
  ]);
  return updatedColumns;
};
