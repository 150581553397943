import { isNil } from "lodash";
import {
  DAYS_OF_WEEK_ARRAY,
  EMPTY_OPERATIONS,
} from "../../../../../constants/geofences";
import {
  Geofence,
  GeofenceData,
  GeofenceMetadata,
  Maybe,
  Operations,
  Scalars,
  SetGeofenceRestrictionsInput,
} from "../../../../../graphql/operations";
import { convertTimeTo12Hour } from "../../../../ReportView/helpers/helpers";
import { GeofenceInitialCapacity } from "./useCapacityForm";
import { GeofenceInitialDetails } from "./useDetailsForm";
import { GeofenceInitialRestrictions } from "./useRestrinctionsForm";

// Extend the OperatingHours type to include the transient properties
type ExtendedOperatingHoursType = {
  startAt: string;
  endAt: string;
  startAtFormat: string;
  endAtFormat: string;
};

// Extend the GeofenceOperations type with the updated OperatingHours type
export type ExtendedGeofenceOperations = {
  friday: Operations & {
    operatingHours: ExtendedOperatingHoursType;
    lunch: ExtendedOperatingHoursType;
  };
  monday: Operations & {
    operatingHours: ExtendedOperatingHoursType;
    lunch: ExtendedOperatingHoursType;
  };
  saturday: Operations & {
    operatingHours: ExtendedOperatingHoursType;
    lunch: ExtendedOperatingHoursType;
  };
  sunday: Operations & {
    operatingHours: ExtendedOperatingHoursType;
    lunch: ExtendedOperatingHoursType;
  };
  thursday: Operations & {
    operatingHours: ExtendedOperatingHoursType;
    lunch: ExtendedOperatingHoursType;
  };
  tuesday: Operations & {
    operatingHours: ExtendedOperatingHoursType;
    lunch: ExtendedOperatingHoursType;
  };
  wednesday: Operations & {
    operatingHours: ExtendedOperatingHoursType;
    lunch: ExtendedOperatingHoursType;
  };
};

type GeofenceDataWithConfiguration = {
  _id: Scalars["ID"];
  configuration: {
    operations: ExtendedGeofenceOperations;
  };
  geofence: Geofence;
  metadata?: Maybe<GeofenceMetadata>;
  orgId?: Maybe<Scalars["ID"]>;
};

export const getOperationsInitialValues = (
  operations: Partial<ExtendedGeofenceOperations>
) => {
  let initialOperations: ExtendedGeofenceOperations = { ...EMPTY_OPERATIONS };

  for (const day of DAYS_OF_WEEK_ARRAY) {
    const startTime = operations[day]?.operatingHours?.startAt as string;
    const endTime = operations[day]?.operatingHours?.endAt as string;
    const lunchStartTime = operations[day]?.lunch?.startAt as string;
    const lunchEndTime = operations[day]?.lunch?.endAt as string;
    const [startAt, startAtFormat] = startTime
      ? convertTimeTo12Hour(startTime).split(" ")
      : ["", ""];
    const [endAt, endAtFormat] = endTime
      ? convertTimeTo12Hour(endTime).split(" ")
      : ["", ""];
    const [LunchStartAt, lunchStartAtFormat] = lunchStartTime
      ? convertTimeTo12Hour(lunchStartTime).split(" ")
      : ["", ""];
    const [lunchEndAt, lunchEndAtFormat] = lunchEndTime
      ? convertTimeTo12Hour(lunchEndTime).split(" ")
      : ["", ""];
    initialOperations[day] = {
      operatingHours: {
        startAt: startAt,
        startAtFormat: startAtFormat,
        endAt: endAt,
        endAtFormat: endAtFormat,
      },
      lunch: {
        startAt: LunchStartAt,
        startAtFormat: lunchStartAtFormat,
        endAt: lunchEndAt,
        endAtFormat: lunchEndAtFormat,
      },
    };
  }
  return initialOperations;
};

export const appendInitialOpperations = (
  initialGeofence: GeofenceData
): GeofenceDataWithConfiguration => {
  if (
    !isNil(initialGeofence.configuration) &&
    !isNil(initialGeofence?.configuration?.operations)
  ) {
    const extendedOperations = getOperationsInitialValues(
      initialGeofence.configuration?.operations as ExtendedGeofenceOperations
    );
    initialGeofence = {
      ...initialGeofence,
      configuration: {
        operations: { ...extendedOperations },
      },
    };
  } else {
    initialGeofence = {
      ...initialGeofence,
      configuration: {
        operations: { ...EMPTY_OPERATIONS },
      },
    };
  }
  return initialGeofence as GeofenceDataWithConfiguration;
};

export const getDetailsInitialValues = (
  geofence: GeofenceData
): GeofenceInitialDetails => {
  const initialValues = {
    _id: geofence._id,
    orgId: geofence.orgId ?? "",
    configuration: {
      typeId: geofence.configuration?.typeId ?? "",
      subTypeId: geofence.configuration?.subTypeId ?? "",
      parentId: geofence.configuration?.parentId ?? "",
    },
    geofence: {
      name: geofence.geofence.name ?? "",
      fullAddressFormatted: geofence.geofence.fullAddressFormatted ?? "",
      code: geofence.geofence.code ?? "",
      description: geofence.geofence.description ?? "",
      tags: geofence.geofence.tags ?? [],
    },
    metadata: {
      owner: geofence.metadata?.owner ?? "",
    },
  };
  return initialValues;
};

export const getCapacityInitialValues = (
  geofence: GeofenceData
): GeofenceInitialCapacity => {
  const initialValues = {
    _id: geofence._id,
    configuration: {
      capacity: {
        chassis: {
          min: geofence.configuration?.capacity?.chassis?.min ?? null,
          max: geofence.configuration?.capacity?.chassis?.max ?? null,
        },
        container: {
          min: geofence.configuration?.capacity?.container?.min ?? null,
          max: geofence.configuration?.capacity?.container?.max ?? null,
        },
        trailer: {
          min: geofence.configuration?.capacity?.trailer?.min ?? null,
          max: geofence.configuration?.capacity?.trailer?.max ?? null,
        },
        dolly: {
          min: geofence.configuration?.capacity?.dolly?.min ?? null,
          max: geofence.configuration?.capacity?.dolly?.max ?? null,
        },
        boat: {
          min: geofence.configuration?.capacity?.boat?.min ?? null,
          max: geofence.configuration?.capacity?.boat?.max ?? null,
        },
        tractor: {
          min: geofence.configuration?.capacity?.tractor?.min ?? null,
          max: geofence.configuration?.capacity?.tractor?.max ?? null,
        },
        reefer: {
          min: geofence.configuration?.capacity?.reefer?.min ?? null,
          max: geofence.configuration?.capacity?.reefer?.max ?? null,
        },
        vehicle: {
          min: geofence.configuration?.capacity?.vehicle?.min ?? null,
          max: geofence.configuration?.capacity?.vehicle?.max ?? null,
        },
        other: {
          min: geofence.configuration?.capacity?.other?.min ?? null,
          max: geofence.configuration?.capacity?.other?.max ?? null,
        },
      },
    },
  };
  return initialValues;
};

export const getRestrictionsInitialValues = (
  restriction: SetGeofenceRestrictionsInput | null | undefined
): GeofenceInitialRestrictions => {
  const restrictionParsed = JSON.parse(
    restriction?.restrictions?.standard ?? null
  );
  const initialValues = {
    geofenceId: restriction?.geofenceId ?? "",
    noAssetsOverXYearsOld: restrictionParsed?.noAssetsOverXYearsOld ?? "",
    allowedAssetTypes: restrictionParsed?.allowedAssetTypes ?? [],
    allowedAssetLength: restrictionParsed?.allowedAssetLength ?? [],
    allowedAssetTags: restrictionParsed?.allowedAssetTags ?? [],
    allowedDoorType: restrictionParsed?.allowedDoorType ?? [],
    custom: JSON.parse(restriction?.restrictions?.custom ?? null) ?? null,
  };

  return initialValues;
};
