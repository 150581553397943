import { FC, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import {
  Grid,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import { useFindAssetByIdQuery } from "../../../../../graphql/operations";
import { Button as ModalBtn } from "../../../../../shared/components/Button";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import MapWidget from "../../../MapView/Assets/SummaryTabPanel/MapWidget";

export interface FindDeviceModalProps {
  selectedAssetId: string | null;
  isModalVisible: boolean;
  onModalCloseHandler: () => void;
  onModalAssociateHandler: () => void;
}

export const FindDeviceModal: FC<FindDeviceModalProps> = ({
  selectedAssetId,
  isModalVisible,
  onModalCloseHandler,
  onModalAssociateHandler,
}) => {
  const { data: assetData, isLoading: selectedAssetIsLoading } =
    useFindAssetById(
      { assetId: selectedAssetId ?? "" },
      { enabled: !!selectedAssetId }
    );

  return (
    <Dialog
      open={isModalVisible}
      onClose={() => {
        onModalCloseHandler();
      }}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          padding: "24px",
          borderRadius: "0.5rem",
          maxWidth: "480px",
        },
      }}
      data-testid="modal-show-device"
    >
      <DialogTitle className="!px-0 !py-6">
        <div className="flex justify-between items-center">
          <Typography className="!text-2xl !font-semibold capitalize">
            Association verification
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              onModalCloseHandler();
            }}
            data-testid="modal-show-device-x-btn"
            className="!-mt-1 h-6 w-6"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="!p-0 !mt-6">
        <Grid item className="mapWidgetContainer">
          {assetData && !selectedAssetIsLoading ? (
            <>
              <Box
                className="h-[320px] bg-no_img_bg text-center"
                data-testid="modal-show-device-map-widget"
              >
                <MapWidget selectedAsset={assetData} />
              </Box>
              <Typography className="!mb-[24px] !mt-[24px]">
                This device is located in {assetData.fullAddress?.city},
                {assetData.fullAddress?.city}.
              </Typography>
            </>
          ) : (
            <>
              <Box
                className="h-[320px] bg-no_img_bg text-center rounded-lg "
                data-testid="modal-show-device-no-location"
              >
                <NotInterestedIcon className="!h-[88px] !w-[88px] mt-[115px]" />
              </Box>
              <Typography className="!mb-[24px] !mt-[24px]">
                Device is not found. Do you still want to complete the
                association?
              </Typography>
            </>
          )}

          <Box className="text-right">
            <Button
              className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !capitalize !text-brand !text-[14px]"
              onClick={onModalCloseHandler}
              data-testid="modal-show-device-cancel-btn"
            >
              Cancel
            </Button>
            <ModalBtn
              className="!text-[14px]"
              type="button"
              theme="blue"
              variant="default"
              onClick={onModalAssociateHandler}
              text={"Associate"}
              data-testid="modal-show-device-associate-btn"
            />
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
