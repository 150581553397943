import { Dispatch, SetStateAction } from "react";
import {
  EventHistoryCategoryToUiNamesMap,
  EventHistoryEventNameUi,
  EventHistoryEventCategory,
} from "../../../../../constants/map";
import { HistoricalEventHistory } from "../../../../../graphql/operations";

export interface HistoryEventsCount {
  healthEvent: number;
  locationUpdate: number;
  cargoUpdate: number;
  doorEvent: number;
  locationEntry: number;
}

export interface HistoryEventsFiltersData {
  circleBackgroundColor: string;
  iconColor: string;
  filterName: string;
  filterValue: number;
  filterType: string;
  onClick?: () => void;
  isSelected: boolean;
  setSelectedFilter: Dispatch<SetStateAction<string>>;
}

export const getEventCategoryFromEventUiName = (
  eventName: EventHistoryEventNameUi
) => {
  let eventCategory: EventHistoryEventCategory | undefined;

  // Find the category based on the event name
  for (const eventCategoryInMap of EventHistoryCategoryToUiNamesMap.keys()) {
    const uiNamesUsedForThisEventCategory =
      EventHistoryCategoryToUiNamesMap.get(eventCategoryInMap);
    if ((uiNamesUsedForThisEventCategory ?? []).includes(eventName)) {
      eventCategory = eventCategoryInMap;
      break;
    }
  }

  return eventCategory;
};

export const getHistoryEventsCount = (
  eventHistory: HistoricalEventHistory[]
): HistoryEventsCount => {
  const historyEventsCount: HistoryEventsCount = {
    healthEvent: 0,
    locationUpdate: 0,
    cargoUpdate: 0,
    doorEvent: 0,
    locationEntry: 0,
  };

  // Go through all events and increment the counters based on the event category
  for (const event of eventHistory) {
    const eventName = event?.breadcrumbs?.eventName as EventHistoryEventNameUi;

    // Find the category based on the event name
    const eventCategory = getEventCategoryFromEventUiName(eventName);

    switch (eventCategory) {
      case EventHistoryEventCategory.LocationUpdate: {
        historyEventsCount.locationUpdate++;
        break;
      }
      case EventHistoryEventCategory.Heartbeat: {
        historyEventsCount.healthEvent++;
        break;
      }
      case EventHistoryEventCategory.Door: {
        historyEventsCount.doorEvent++;
        break;
      }
      case EventHistoryEventCategory.Cargo: {
        historyEventsCount.cargoUpdate++;
        break;
      }
      case EventHistoryEventCategory.LocationEntry: {
        historyEventsCount.locationEntry++;
        break;
      }
      default: {
        console.error(`Event category not found for event name: ${eventName}`);
        break;
      }
    }
  }

  return historyEventsCount;
};

export const getTravelledDistance = (
  eventHistory: HistoricalEventHistory[]
): number => {
  if (eventHistory.length > 1) {
    const firstEventHistory = eventHistory[0];
    const lastEventHistory = eventHistory[eventHistory.length - 1];

    if (
      firstEventHistory?.breadcrumbs?.odometer &&
      lastEventHistory?.breadcrumbs?.odometer
    ) {
      const travelledDistance =
        firstEventHistory?.breadcrumbs?.odometer -
        lastEventHistory?.breadcrumbs?.odometer;
      if (travelledDistance >= 0) {
        return Math.round(travelledDistance);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  return 0;
};
