import { useMemo, useEffect, Dispatch, SetStateAction } from "react";
import {
  GridColumnVisibilityModel,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import debounce from "lodash/debounce";
import delay from "lodash/delay";

type ApplyDataTestIdFunctionInput = {
  className?: string;
  selector?: string;
  testId: string;
};

export interface DataTestIdHookInput<T extends GridValidRowModel> {
  rows: GridRowsProp<T>;
  pinnedColumns: object;
  visibleColumns: GridColumnVisibilityModel | undefined;
  needUpdateDataTestIds: boolean;
  setNeedUpdateDataTestIds: Dispatch<SetStateAction<boolean>>;
}

// custom hook for Table component to add data-testid property to particular MaterialUI Data Grid elements
export const useDataTestId = <T extends GridValidRowModel>({
  rows,
  pinnedColumns,
  visibleColumns,
  needUpdateDataTestIds,
  setNeedUpdateDataTestIds,
}: DataTestIdHookInput<T>) => {
  const applyDataTestId = ({
    className,
    selector,
    testId,
  }: ApplyDataTestIdFunctionInput) => {
    let elements;
    if (className) {
      elements = document.querySelectorAll(`.${className}`);
    } else if (selector) {
      elements = document.querySelectorAll(selector);
    }

    if (!elements) return;
    for (let [index, element] of elements.entries()) {
      if (className === "MuiDataGrid-columnHeaderTitle") {
        const columnHeaderTitle = element?.innerHTML;
        if (columnHeaderTitle) {
          element?.setAttribute(
            "data-testid",
            `${testId}-${columnHeaderTitle.toLowerCase().replaceAll(" ", "-")}`
          );
        } else {
          element?.setAttribute("data-testid", `${testId}-${index}`);
        }
      } else {
        element?.setAttribute("data-testid", `${testId}-${index}`);
      }
    }
  };

  const updateTestIds = useMemo(
    () =>
      debounce(() => {
        // Apply data-testid to header titles
        applyDataTestId({
          className: "MuiDataGrid-columnHeaderTitle",
          testId: "column-header",
        });

        // Apply data-testid to pinned columns header on left
        applyDataTestId({
          className: "MuiDataGrid-pinnedColumnHeaders--left",
          testId: "items-list-pinned-column-left-header",
        });

        // Apply data-testid to pinned columns header on right
        applyDataTestId({
          className: "MuiDataGrid-pinnedColumnHeaders--right",
          testId: "items-list-pinned-column-right-header",
        });

        // Apply data-testid to action menu items
        applyDataTestId({
          className: "MuiMenuItem-root",
          testId: "items-list-column-actions-menu-item",
        });

        // Apply data-testid to column separators
        applyDataTestId({
          className: "MuiDataGrid-columnSeparator",
          testId: "items-list-column-separator",
        });

        // Apply data-testid to column sort button
        applyDataTestId({
          selector: 'button[title="Sort"][class~="MuiIconButton-root"]',
          testId: "items-list-column-sort-btn",
        });
        // Apply data-testid to column sort button
        applyDataTestId({
          selector: '[placeholder="Filter value"',
          testId: "filter-value",
        });

        setNeedUpdateDataTestIds(false);
      }, 100),
    [setNeedUpdateDataTestIds]
  );

  // As there is a lot of edge cases when some actions on a table can trigger DOM changes
  // for this reason updating all data-testids at once is essential for keeping all elements up to date
  useEffect(() => {
    let menuButtons: NodeListOf<HTMLElement> | undefined;
    let filterElement: HTMLElement | undefined;
    let addFilterElement: HTMLElement | undefined;
    const handler = () => {
      setNeedUpdateDataTestIds(true);
      delay(() => {
        const elements =
          document.querySelectorAll(
            'li[role="menuitem"][class~="MuiMenuItem-root"]'
          ) || [];
        for (let element of elements) {
          if (element?.textContent?.includes("Filter")) {
            filterElement = element as HTMLElement;
            filterElement.addEventListener("click", filterHandler);
          }
        }
      }, 100);
    };

    const filterHandler = () => {
      setNeedUpdateDataTestIds(true);
      delay(() => {
        const elements =
          document.getElementsByClassName("MuiButton-root") || [];
        for (let element of elements) {
          const htmlElement = element as HTMLElement;
          if (htmlElement.innerText === "ADD FILTER") {
            addFilterElement = htmlElement;
            addFilterElement.addEventListener("click", addFilterHandler);
          }
        }
      }, 100);
    };

    const addFilterHandler = () => setNeedUpdateDataTestIds(true);
    delay(() => {
      menuButtons =
        document.querySelectorAll(
          'button[aria-label="Menu"][class~="MuiDataGrid-menuIconButton"]'
        ) || [];
      for (let btn of menuButtons) {
        btn.addEventListener("click", handler);
      }
      updateTestIds();
    }, 100);

    return () => {
      if (menuButtons) {
        for (let btn of menuButtons) {
          btn.removeEventListener("click", handler);
        }
      }

      if (filterElement) {
        filterElement.removeEventListener("click", filterHandler);
      }

      if (addFilterElement) {
        addFilterElement.removeEventListener("click", addFilterHandler);
      }
    };
  }, [
    updateTestIds,
    rows,
    pinnedColumns,
    visibleColumns,
    needUpdateDataTestIds,
    setNeedUpdateDataTestIds,
  ]);

  // Search input is permanently same during working with a table, so we can handle it separately
  useEffect(() => {
    const searchInput = document.querySelector('[placeholder="Search"');
    if (searchInput) {
      searchInput?.setAttribute("data-testid", "items-list-search-input");
    }
  }, [rows]);
};
