import React from "react";
import { TooltipProps } from "recharts";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { getConvertedPressureValueWithUnit } from "../../../../../utils/convertPressure";
import { AirBagData } from "../../Assets/CargoTabPanel/DoorStatus/utils";

// @ts-ignore
export const AirBagCustomTooltip: React.FC<
  // @ts-ignore
  TooltipProps<AirBagData, string>
> = ({ active, payload, label }) => {
  const pressureUnit = useGetPressureUnitPreference();
  if (active && payload?.length) {
    const rightAvg =
      getConvertedPressureValueWithUnit(
        payload?.[0].payload.airbag.average_right_pressure,
        pressureUnit
      ) ?? "N/A";
    const leftAvg =
      getConvertedPressureValueWithUnit(
        payload?.[0].payload.airbag.average_left_pressure,
        pressureUnit
      ) ?? "N/A";

    const date = payload?.[0].payload.eventHistoryData.toolTipDate;

    if (!leftAvg && !rightAvg) return;

    return (
      <div
        className="p-6 bg-custom-tooltip-background rounded-lg"
        data-testid="tooltip-wrapper"
      >
        <p
          className="text-base font-normal text-typography"
          data-testid="tooltip-date"
        >
          {date}
        </p>
        <div className="flex justify-between">
          <div className="mr-6">
            <p className="text-base text-info">Axle One Left Air Bag</p>
            <p className="text-base font-normal text-typography">
              Pressure:
              <span className="font-bold" data-testid="tooltip-pressure-left">
                {leftAvg}
              </span>
            </p>
          </div>
          <div>
            <p className="text-base text-info">Axle One Right Air Bag</p>
            <p className="text-base font-normal text-typography">
              Pressure:
              <span className="font-bold" data-testid="tooltip-pressure-right">
                {rightAvg}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
