import { useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import LiftgateBatteryChart from "./components/charts/LiftgateBatteryChart";
import LiftgateCycleHistoryChart from "./components/charts/LiftgateCycleHistoryChart";
import LiftgateMotorRunTimeChart from "./components/charts/LiftgateMotorRunTimeChart";
import LiftgateBatteryStatus from "./components/status/LiftgateBatteryStatus";
import LiftgateBatteryInfoTable from "./components/tables/LiftgateBatteryInfoTable/LiftgateBatteryInfoTable";
import LiftgateFaultHistoryTable from "./components/tables/LiftgateFaultHistoryTable/LiftgateFaultHistoryTable";

export const LiftgateTabPanel: React.FC = () => {
  // Hooks
  const { selectedAssetId } = useAssetsDataContext();

  // Queries
  const { data: assetData } = useFindAssetById(
    {
      assetId: selectedAssetId as string,
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  // Variables
  const liftgateSensorReadings = useMemo(
    () => assetData?.sensors?.liftgateAdvertisement,
    [assetData]
  );

  const assetDetails = { id: assetData?._id, imei: assetData?.imei };

  return (
    <Box sx={{ padding: "1rem" }} data-testid="liftgate-tab-panel">
      <Grid container spacing={2}>
        {liftgateSensorReadings?.data?.statusBattery && (
          <Grid item lg={3} className="!hidden lg:!flex">
            <LiftgateBatteryStatus
              status={liftgateSensorReadings.data.statusBattery}
              date={liftgateSensorReadings.readingDate}
            />
          </Grid>
        )}
        {assetData?.imei && (
          <Grid
            className="!pt-0"
            item
            xs={12}
            sm={12}
            md={12}
            lg={liftgateSensorReadings?.data?.statusBattery ? 9 : 12}
          >
            <LiftgateBatteryInfoTable imei={assetData?.imei} />
          </Grid>
        )}
        <Grid item md={12} className="!hidden sm:!block">
          <Box className="bg-dashboard_subheader__bg p-4 rounded-lg h-full">
            <LiftgateBatteryChart assetDetails={assetDetails} />
          </Box>
        </Grid>
        {assetData?.imei && (
          <Grid item xs={12} sm={12} className="!pt-0">
            <LiftgateFaultHistoryTable imei={assetData?.imei} />
          </Grid>
        )}
        <Grid item md={6} className="!hidden sm:!block">
          <Box className="bg-dashboard_subheader__bg p-4 rounded-lg h-full">
            <LiftgateMotorRunTimeChart assetDetails={assetDetails} />
          </Box>
        </Grid>
        <Grid item md={6} className="!hidden sm:!block">
          <Box className="bg-dashboard_subheader__bg p-4 rounded-lg h-full">
            <LiftgateCycleHistoryChart assetDetails={assetDetails} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
