import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { CircularProgress, Box } from "@mui/material";
import { useInternalCameraSensorPermissions } from "../../hooks/useInternalCameraSensorPermissions";
import ActionButton from "./ActionButton";

interface CapturePhotoButtonProps {
  handleOnClick: () => void;
  isCaptureLoading: boolean;
}

const CapturePhotoButton: React.FC<CapturePhotoButtonProps> = ({
  handleOnClick,
  isCaptureLoading,
}) => {
  const { isCaptureButtonEnabled } = useInternalCameraSensorPermissions();

  return (
    <ActionButton
      onClick={handleOnClick}
      disabled={!isCaptureButtonEnabled || isCaptureLoading}
      dataTestId="asset-dashboard--other-sensors--internal-camera-sensor--capture-photo-button"
      sx={{
        backgroundColor: isCaptureLoading ? "var(--card__placeholder_img)" : "",
      }}
    >
      {isCaptureLoading ? (
        <Box>
          <CircularProgress
            sx={{
              position: "relative",
              top: "4px",
              width: "24px !important",
              height: "24px !important",
            }}
          />
        </Box>
      ) : (
        <CameraAltOutlinedIcon />
      )}
    </ActionButton>
  );
};

export default CapturePhotoButton;
