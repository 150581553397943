import {
  LiteSentryGammaSensorConfigInput,
  LiteSentryGammaProfileSensorInput,
} from "../../../../../../graphql/operations";
import { SensorValueType } from "../../../../../../shared/helpers/sensors.utils";

export const convertLiteSentryGammaConfigToSensorInput = (
  config: LiteSentryGammaSensorConfigInput & {
    [key: string]: any;
  }
): LiteSentryGammaProfileSensorInput => {
  const liteSentryGammaSensorConfigInput = { ...config };
  for (const key in liteSentryGammaSensorConfigInput) {
    liteSentryGammaSensorConfigInput[key].thresholds =
      liteSentryGammaSensorConfigInput[key].match.thresholds;
    liteSentryGammaSensorConfigInput[key].sensorType = SensorValueType.Amperage;
    delete liteSentryGammaSensorConfigInput[key].match;
  }
  return liteSentryGammaSensorConfigInput as LiteSentryGammaProfileSensorInput;
};
