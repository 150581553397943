import { useMemo } from "react";
import { useGetMergedProfileForAssetQuery } from "../../../../../../../../../graphql/operations";
import { sensorConfigToReferenceAreaValues } from "../../../../helpers";
import { IReferenceAreaValueType } from "../../../../interfaces";

interface PsiWheelEndSensorProfileHook {
  referenceAreaValues: IReferenceAreaValueType[];
}

export const usePsiWheelEndSensorProfile = (
  assetId: string | null | undefined
): PsiWheelEndSensorProfileHook => {
  const profileGQL = useGetMergedProfileForAssetQuery(
    {
      input: { assetId: assetId ?? "" },
    },
    { enabled: Boolean(assetId) }
  )?.data;

  const mergedSensorProfile = useMemo(
    () => profileGQL?.getMergedProfileForAsset.sensorProfile ?? null,
    [profileGQL]
  );

  const referenceAreaValues =
    sensorConfigToReferenceAreaValues(
      mergedSensorProfile?.configuration?.psiWheelEnd?.temperature?.match
        ?.thresholds
    ) ?? [];

  return {
    referenceAreaValues,
  };
};
