import { useEffect, useState } from "react";
import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import {
  AssetInput,
  AssetInputOs,
  AssetResult,
  AssetsResult,
  GetAssetsForClustersOsQuery,
  GetAssetsForListQuery,
  useGetAssetsClustersQuery,
  useGetAssetsForClustersOsQuery,
} from "../../../graphql/operations";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";
import { mapClustersToMapFeaturesV2 } from "../../../utils/maps/core";
import { useFeatureFlag } from "../../../utils/useFeatureFlag";
import { Feature } from "../../../views/AssetsView/shared/AssetsDataContext/AssetsDataContext";

export interface AssetsClustersDataHook {
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isRefetching: boolean;
  data: Feature[];
}

type GetAssetsForListQueryOptions = UseQueryOptions<
  GetAssetsForListQuery,
  unknown,
  GetAssetsForListQuery,
  QueryKey
>;

type GetAssetsForClustersOSQueryOptions = UseQueryOptions<
  GetAssetsForClustersOsQuery,
  unknown,
  GetAssetsForClustersOsQuery,
  QueryKey
>;

export const useAssetsClustersData = (
  queryInput: AssetInput | AssetInputOs,
  queryOptions:
    | GetAssetsForListQueryOptions
    | GetAssetsForClustersOSQueryOptions
): AssetsClustersDataHook => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [data, setData] = useState<Feature[]>([]);

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const {
    data: dataDocumentDB,
    isLoading: isLoadingDocumentDB,
    isFetching: isFetchingDocumentDB,
    isSuccess: isSuccessDocumentDB,
    isRefetching: isRefetchingDocumentDB,
  } = useGetAssetsClustersQuery(
    {
      input: queryInput as AssetInput,
    },
    {
      ...queryOptions,
      enabled: queryOptions.enabled && !fetchAssetsFromOpenSearchFeatureFlag,
    } as GetAssetsForListQueryOptions
  );

  const {
    data: dataOpenSearch,
    isLoading: isLoadingOpenSearch,
    isFetching: isFetchingOpenSearch,
    isSuccess: isSuccessOpenSearch,
    isRefetching: isRefetchingOpenSearch,
  } = useGetAssetsForClustersOsQuery(
    {
      input: queryInput as AssetInputOs,
    },
    {
      ...queryOptions,
      enabled: queryOptions.enabled && fetchAssetsFromOpenSearchFeatureFlag,
    } as GetAssetsForClustersOSQueryOptions
  );

  useEffect(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      setIsFetching(isFetchingOpenSearch);
      setIsLoading(isLoadingOpenSearch);
      setIsSuccess(isSuccessOpenSearch);
      setIsRefetching(isRefetchingOpenSearch);
    } else {
      setIsLoading(isLoadingDocumentDB);
      setIsFetching(isFetchingDocumentDB);
      setIsSuccess(isSuccessDocumentDB);
      setIsRefetching(isRefetchingDocumentDB);
    }
  }, [
    fetchAssetsFromOpenSearchFeatureFlag,
    isLoadingDocumentDB,
    isFetchingDocumentDB,
    isSuccessDocumentDB,
    isRefetchingDocumentDB,
    isLoadingOpenSearch,
    isFetchingOpenSearch,
    isSuccessOpenSearch,
    isRefetchingOpenSearch,
  ]);

  useEffect(() => {
    if (dataDocumentDB) {
      setData(mapClustersToMapFeaturesV2(dataDocumentDB.getAssets));
    } else if (dataOpenSearch) {
      setData(
        mapClustersToMapFeaturesV2(
          mapOpenSearchAssets(dataOpenSearch?.getAssetsOS)
        )
      );
    }
  }, [dataDocumentDB, dataOpenSearch]);

  return {
    isLoading,
    isFetching,
    isSuccess,
    isRefetching,
    data,
  };
};

// TODO: Once we switch to the new API, and ditch the feature flag, we should remove this mapping and make the list work with the new format instead
// TODO: Cleanup with PRJIND-9218
export const mapOpenSearchAssets = (data: AssetsResult): AssetResult => {
  return data as AssetResult;
};
