import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { metricsSchema, getAlertCommonSchema } from "../utils";

export const useAlertMetricsForm = (
  initialValues = {
    parameters: {
      metric: "",
      operator: "",
      value: 0,
      startDate: "",
      endDate: "",
    },
  }
) => {
  const alertCommonSchema = getAlertCommonSchema();
  const schema = yup.object().shape({
    ...alertCommonSchema,
    parameters: yup.object().when(["saveAsNewAlertsType"], {
      is: false,
      then: (scheme) =>
        scheme.shape({
          ...metricsSchema,
        }),
    }),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    values: omitBy(
      {
        ...initialValues,
        saveAsNewAlertsType: false,
        alertTemplateOrgIds: [],
      },
      isNil
    ),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
