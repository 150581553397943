import { memo, SVGProps, FC } from "react";

interface TargetProps extends SVGProps<SVGSVGElement> {}

const Target: FC<TargetProps> = (props) => {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4502 9.16658C17.0668 5.69158 14.3085 2.93325 10.8335 2.54992V0.833252H9.16683V2.54992C5.69183 2.93325 2.9335 5.69158 2.55016 9.16658H0.833496V10.8333H2.55016C2.9335 14.3083 5.69183 17.0666 9.16683 17.4499V19.1666H10.8335V17.4499C14.3085 17.0666 17.0668 14.3083 17.4502 10.8333H19.1668V9.16658H17.4502ZM10.0002 15.8333C6.77516 15.8333 4.16683 13.2249 4.16683 9.99992C4.16683 6.77492 6.77516 4.16658 10.0002 4.16658C13.2252 4.16658 15.8335 6.77492 15.8335 9.99992C15.8335 13.2249 13.2252 15.8333 10.0002 15.8333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Target);
