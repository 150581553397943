export const convertTo24HoursFormat = (
  time12: string | undefined,
  format: string
) => {
  if (!time12) {
    return "";
  }
  const [hours12, minutes] = time12.split(":").map(Number);
  let hours24 = hours12;
  if (format === "PM" && hours12 < 12) {
    hours24 += 12;
  } else if (format === "AM" && hours12 === 12) {
    hours24 = 0;
  }
  const time24 = `${hours24.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  return time24;
};

export const convertTo12HoursFormat = (time24: string | undefined) => {
  if (!time24) {
    return { time: "", format: "" };
  }
  const [hours24, minutes] = time24.split(":").map(Number);
  const date = new Date(0, 0, 0, hours24, minutes); // Month and year are set to 0
  let hours12 = date.getHours();
  const format = hours12 >= 12 ? "PM" : "AM";
  hours12 = hours12 % 12 || 12; // Convert midnight (0) to 12
  const time12 = `${hours12.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  return { time: time12, format };
};

export const optionise = (value: string) => ({ value, label: value });
