import React, { FC, memo, useCallback, useState } from "react";
import {
  Control,
  Controller,
  ControllerProps,
  UseFormReturn,
} from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Chip,
  FormControl,
  FormHelperText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import lodashGet from "lodash/get";
import { HierarchySelect, HierarchySelectProps } from "../../HierarchySelect";
import { RequiredFlag } from "../../RequiredFlag";
import { useExternalChipSelectTheme } from "../RolesSelectElement/useExternalChipSelectTheme";

export type HierarchySelectElementProps = Pick<
  HierarchySelectProps,
  "hierarchy" | "loading"
> & {
  label?: string;
  rules?: ControllerProps["rules"];
  name: string;
  control: Control<any>;
  labelClassName?: string;
  required?: boolean;
  hideValue?: boolean;
  form: UseFormReturn;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
  isSelectOpen?: boolean;
  setIsSelectOpen?: (open: boolean) => void;
  disabled?: boolean;
  readOnly?: boolean;
};

export type ChipOption = {
  label: string;
  value: string;
};

export const HierarchySelectElement: FC<HierarchySelectElementProps> = memo(
  ({
    label,
    rules,
    name,
    control,
    hideValue = false,
    labelClassName = "!mb-2 !text-sm !font-bold",
    required,
    form,
    handleUpdateField,
    fieldPath,
    isSelectOpen,
    setIsSelectOpen,
    disabled,
    readOnly,
    ...rest
  }: HierarchySelectElementProps) => {
    const [chipsList, setChipsList] = useState<ChipOption[]>([]);
    const [isListExpanded, setIsListExpanded] = useState<boolean>(false);
    const chipsTheme = useExternalChipSelectTheme();
    // @ts-ignore
    const orgs = form.watch(name);

    const sortedChips = chipsList?.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    const chipsListFormatted = isListExpanded
      ? sortedChips
      : sortedChips?.slice(0, 7);
    const buttonText = isListExpanded
      ? "Less"
      : chipsList.length > 7
      ? `+${chipsList.length - 7} more`
      : "";
    const shouldShowButton = chipsList.length > 7;

    const handleDelete = useCallback(
      (orgId: string) => {
        const filteredOrgs = orgs?.filter((org: string) => org !== orgId);
        // @ts-ignore
        form.setValue(name, filteredOrgs);
        handleUpdateField && handleUpdateField(filteredOrgs, fieldPath);
      },
      [orgs, form, name, handleUpdateField, fieldPath]
    );
    return (
      <>
        <Controller
          rules={rules}
          name={name}
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error, invalid },
            formState: { errors },
          }) => {
            const validationError = lodashGet(errors, fieldPath || "");
            return (
              <FormControl
                fullWidth
                required={required}
                variant="standard"
                error={invalid || !!validationError}
              >
                {label && (
                  <Typography
                    className={labelClassName}
                    data-testid="hierarchy-select-element-label"
                  >
                    {label}
                    {rules?.required && <RequiredFlag />}
                  </Typography>
                )}
                <HierarchySelect
                  {...rest}
                  hideValue={hideValue}
                  values={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  invalid={invalid || !!validationError}
                  required={required}
                  setChipsList={setChipsList}
                  handleUpdateField={handleUpdateField}
                  fieldPath={fieldPath}
                  isSelectOpen={isSelectOpen}
                  setIsSelectOpen={setIsSelectOpen}
                  disabled={disabled}
                  readOnly={readOnly}
                />
                {(error?.message || validationError?.message) && (
                  <FormHelperText error={true}>
                    {error?.message || validationError?.message?.toString()}
                  </FormHelperText>
                )}
              </FormControl>
            );
          }}
        />
        <ThemeProvider theme={chipsTheme}>
          {chipsListFormatted?.length ? (
            <div className="py-8 flex items-baseline flex-wrap">
              {chipsListFormatted.map((chip: ChipOption) => {
                return (
                  <Chip
                    className="!mb-2 !mr-2"
                    key={`${chip.label}-${chip.value}`}
                    label={chip.label}
                    onDelete={() => handleDelete(chip.value)}
                    data-testid={`hierarchy-select-element-chip-${chip.value}`}
                    deleteIcon={
                      <ClearIcon
                        data-testid={`hierarchy-select-element-chip-delete-${chip.value}`}
                      />
                    }
                  />
                );
              })}
              {shouldShowButton && (
                <button
                  className="text-brand font-bold text-sm"
                  type="button"
                  onClick={() => setIsListExpanded(!isListExpanded)}
                >
                  {buttonText}
                </button>
              )}
            </div>
          ) : (
            ""
          )}
        </ThemeProvider>
      </>
    );
  }
);
