import { useEffect, useState } from "react";
import { useAuthContext } from "../../../../../../../context/AuthContext";
import { UserRole } from "../../../../../../../graphql/operations";
import { useCurrentOrg } from "../../../../../../../shared/hooks/useCurrentOrg";

interface InternalCameraSensorPermissionsHook {
  isCaptureButtonEnabled: boolean;
  isDeleteButtonEnabled: boolean;
}

export const useInternalCameraSensorPermissions =
  (): InternalCameraSensorPermissionsHook => {
    const { userInfo } = useAuthContext();
    const dataCurrentOrg = useCurrentOrg();

    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isClientAdmin, setIsClientAdmin] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);

    const isPhillipsConnectMember =
      dataCurrentOrg?.name === "Phillips Connect" ||
      dataCurrentOrg?.org_key === "Phillips Connect";

    useEffect(() => {
      if (!userInfo) return;

      setIsSuperAdmin(userInfo?.groups?.includes(UserRole.Superadmin));
      setIsClientAdmin(userInfo?.groups?.includes(UserRole.Clientadmin));
      setIsSupervisor(userInfo?.groups?.includes(UserRole.Supervisor));
    }, [userInfo]);

    const isCaptureButtonEnabled =
      (isPhillipsConnectMember && isSuperAdmin) ||
      isClientAdmin ||
      isSupervisor;

    const isDeleteButtonEnabled =
      (isPhillipsConnectMember && isSuperAdmin) ||
      isClientAdmin ||
      isSupervisor;

    return { isCaptureButtonEnabled, isDeleteButtonEnabled };
  };
