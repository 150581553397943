export enum BatchTitles {
  CreateUpdateAssets = "Create / Update Assets",
  CreateUpdateDevices = "Create / Update Devices",
  CreateUpdateFaultCodes = "Create / Update Fault Codes",
  CreateUpdateOrganizations = "Create Organizations",
  CreateUpdateUsers = "Create / Update Users",
  AssignSensorProfiles = "Assign Sensor Profiles",
  InstallDevices = "Install Devices",
  TransferAssets = "Transfer Assets",
  TransferDevices = "Transfer Devices",
  TransferUsers = "Transfer Users",
  CreateUpdateGeofences = "Create / Update Geofences",
}

type BatchTemplatesType = {
  [key: string]: BatchTitles;
};

type BatchStatesType = {
  [key: string]: string;
};

export const templatesToHumanReadableMapper: BatchTemplatesType = {
  create_users: BatchTitles.CreateUpdateUsers,
  create_organizations: BatchTitles.CreateUpdateOrganizations,
  transfer_devices: BatchTitles.TransferDevices,
  create_devices: BatchTitles.CreateUpdateDevices,
  transfer_assets: BatchTitles.TransferAssets,
  transfer_users: BatchTitles.TransferUsers,
  create_fault_codes: BatchTitles.CreateUpdateFaultCodes,
};

export const statesToHumanReadableMapper: BatchStatesType = {
  In_Review: "In Review",
  Paused: "Paused",
  Failed: "Failed",
  Success: "Success", // Note: Not sure whether this is up to date
  Successful: "Successful", // Note: This one comes from the GraphQL Schema
  Processing: "Processing", // Note: This one comes from the GraphQL Schema
  Draft: "Draft",
};
