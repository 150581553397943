import {
  AssetOs,
  AssetTableData,
  OrgData,
  SharedAssetsInvitationGroupName,
  SharedAssetStatusOs,
} from "../../graphql/operations";
import { DATE_FORMAT, formatDateInTimezone } from "../../utils";
import { UNKNOWN_ORGANIZATION_STRING } from "../../views/AssetsView/constants";
import { AssetTableShareType } from "../../views/AssetsView/types";

export interface IAssetSharingParserParams {
  // customer_orgs_id of the asset
  assetOwnerOrgId: string | undefined | null;
  shared: AssetOs["shared"];
  // Current org according to the global org filter
  currentOrgId: string;
  availableOrgs: OrgData[];
}

export type EnhancedAssetTableData = AssetTableData & {
  shared?: AssetOs["shared"];
};

export type GetSharedOrganizationNameParams = Omit<
  IAssetSharingParserParams,
  "shared"
> & {
  // ID of the organization the asset is shared with
  sharedOrganizationId: string;
};

export type GetParsedAssetShareDataParams = IAssetSharingParserParams & {
  timezone: string;
};

export const getShareType = (
  shareStatus: SharedAssetStatusOs,
  // ID of the organization the asset is shared with
  sharedOrganizationId: string,
  currentOrgId: string
): AssetTableShareType => {
  let shareType: AssetTableShareType;
  if (shareStatus === SharedAssetStatusOs.Pending) {
    shareType = AssetTableShareType.Pending;
  }
  // If the asset has been shared with the current organization (by the owner organization)
  else if (sharedOrganizationId.toString() === currentOrgId.toString()) {
    shareType = AssetTableShareType.SharedBy;
  } else {
    // If the asset has been shared with a different organization (by the current organization)
    shareType = AssetTableShareType.SharedWith;
  }

  return shareType;
};

export const getShareOrganizationName = ({
  sharedOrganizationId,
  assetOwnerOrgId,
  currentOrgId,
  availableOrgs,
}: GetSharedOrganizationNameParams) => {
  // If the asset has been shared with the current organization -> return the owner organization name
  if (
    assetOwnerOrgId &&
    sharedOrganizationId.toString() === currentOrgId.toString()
  ) {
    const ownerOrgName =
      availableOrgs.find(
        ({ _id }) => _id.toString() === assetOwnerOrgId.toString()
      )?.name ?? UNKNOWN_ORGANIZATION_STRING;
    return ownerOrgName;
  } else {
    // If the asset has been shared with a different organization -> return that organization's name
    const orgName =
      availableOrgs.find(
        ({ _id }) => _id.toString() === sharedOrganizationId.toString()
      )?.name ?? UNKNOWN_ORGANIZATION_STRING;
    return orgName;
  }
};

export const getShareGroupName = (
  shareType: AssetTableShareType,
  sharedGroupName: SharedAssetsInvitationGroupName
): string => {
  // If the asset has been shared with the current organization -> return the "recepient" share group name
  if (shareType === AssetTableShareType.SharedBy) {
    return sharedGroupName?.recipient ?? "";
  } else {
    // Else if viewing from the owner organization's perspective -> return the "owner" share group name
    return sharedGroupName?.owner ?? "";
  }
};

export const getParsedAssetShareData = ({
  assetOwnerOrgId,
  shared,
  currentOrgId,
  availableOrgs,
  timezone,
}: GetParsedAssetShareDataParams) => {
  // Handle shareTye
  let shareType: string = "";
  if (shared?.status && shared?.organizationId) {
    shareType = getShareType(
      shared.status,
      shared.organizationId,
      currentOrgId
    );
  }

  // Handle shareOrganizationName
  let shareOrganizationName: string = "";
  if (shared?.organizationId && assetOwnerOrgId) {
    shareOrganizationName = getShareOrganizationName({
      sharedOrganizationId: shared.organizationId,
      assetOwnerOrgId,
      currentOrgId,
      availableOrgs,
    });
  }

  // Handle shareGroupName
  let shareGroupName: string = "";
  if (shareType && shared?.sharedGroupName) {
    shareGroupName = getShareGroupName(
      shareType as AssetTableShareType,
      shared.sharedGroupName
    );
  }

  // Handle dates formatting
  const shareEndDate = shared?.endDate
    ? formatDateInTimezone(shared?.endDate, DATE_FORMAT, timezone)
    : null;

  const shareStartDate = shared?.startDate
    ? formatDateInTimezone(shared?.startDate, DATE_FORMAT, timezone)
    : null;

  return {
    shareType,
    shareEndDate,
    shareStartDate,
    shareGroupName,
    shareOrganizationName,
  };
};
