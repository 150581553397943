import React, { MouseEvent, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ListItemText, Menu, MenuItem } from "@mui/material";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import { UserAccessScope, WithPermissions } from "../../../WithPermissions";
import { SubHeaderActionButton } from "../SubHeaderActionButton/SubHeaderActionButton";

export interface SubHeaderDropdownActions {
  title: string;
  accessScope: UserAccessScope;
  onClick: () => void;
  disabled?: boolean;
}

export interface SubHeaderActionDropdownProps {
  title: string;
  showDropdownIcon?: boolean;
  dropdownActions: SubHeaderDropdownActions[];
  accessScope: UserAccessScope;
  primary?: boolean;
  disabled?: boolean;
}

export const SubHeaderActionDropdown: React.FC<
  SubHeaderActionDropdownProps
> = ({
  title,
  primary,
  disabled,
  dropdownActions,
  accessScope,
  showDropdownIcon = true,
}) => {
  const isMobile = useBreakpoint("down", "sm");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SubHeaderActionButton
        title={title}
        primary={primary}
        disabled={disabled}
        icon={
          showDropdownIcon && open ? (
            <ArrowDropUpIcon />
          ) : showDropdownIcon && !open ? (
            <ArrowDropDownIcon />
          ) : null
        }
        onClick={onClickHandler}
        accessScope={accessScope}
      />
      <Menu
        id="sub-header-dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isMobile ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isMobile ? "left" : "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            marginTop: 1,
            minWidth: "240px",
            maxWidth: "280px",
          },
        }}
      >
        {dropdownActions.map(
          ({ accessScope, title, onClick, disabled = false }) => {
            const key = `sub-header-dropdown-action-${title}`;

            return (
              <WithPermissions accessScope={accessScope} key={key}>
                <MenuItem
                  disabled={disabled}
                  data-testid={key}
                  onClick={() => {
                    onClick();
                    handleClose();
                  }}
                >
                  <ListItemText primary={title} />
                </MenuItem>
              </WithPermissions>
            );
          }
        )}
      </Menu>
    </>
  );
};
