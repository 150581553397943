import dayjs from "dayjs";
import {
  dateToExactDateTimeStringNoTz,
  getEndOfDay,
  getStartOfDay,
} from "../../../../utils";

export const DEFAULT_DASHBOARD_START_DATE = getStartOfDay(
  dayjs().subtract(1, "month").toDate()
);
export const DEFAULT_DASHBOARD_START_DATE_DATESTRING =
  dateToExactDateTimeStringNoTz(DEFAULT_DASHBOARD_START_DATE);
export const DEFAULT_DASHBOARD_END_DATE = getEndOfDay(dayjs().toDate());
export const DEFAULT_DASHBOARD_END_DATE_DATESTRING =
  dateToExactDateTimeStringNoTz(DEFAULT_DASHBOARD_END_DATE);
