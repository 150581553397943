import { AssetTableData } from "../../../../../graphql/operations";
import {
  getTableColumn,
  TableColumnProps,
  TableGridColDef,
} from "../../../../../shared/components/Table";
import { getDefaultAssetsListColumns } from "../../../TableView/components/AssetsTable/columns";

export const getColumns = (
  timezone: string
): TableGridColDef<AssetTableData>[] => {
  const columns: TableColumnProps<AssetTableData>[] = [
    ...getDefaultAssetsListColumns(timezone),
    {
      field: "dwellingDays",
      headerName: "Dwell",
      order: 11,
      options: {
        renderCell: (params) =>
          (params.row.geofence?.[0]?.dwelling?.dwellingDays ?? "") + " days",
        sortable: false,
        filterable: false,
      },
    },
  ];

  return columns
    .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))
    .map((column) => getTableColumn(column));
};
