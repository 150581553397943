import { useState, FC } from "react";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last } from "lodash";
import { Bar, Tooltip, YAxis } from "recharts";
import { ReactComponent as LiftgateSensorDark } from "../../../../../../../assets/svgs/liftGateSensorDark.svg";
import { ReactComponent as LiftgateSensorLight } from "../../../../../../../assets/svgs/liftgateSensorLight.svg";
import { VOLTAGE_CHART_DATE_RANGE_OPTIONS } from "../../../../../../../constants/map";
import { useAppContext } from "../../../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../../../design-system/colors-palette";
import {
  useGetLiftgateCycleHistoryAggregatedDataOsQuery,
  ReportType,
} from "../../../../../../../graphql/operations";
import BaseBarChart from "../../../../../../../shared/components/BaseVerticalBarChart";
import { VerticalBarChartOverlay } from "../../../../../../../shared/components/LoadingOverlaySkeletons/VerticalBarChartLoader/VerticalBarChartOverlay";
import { themes } from "../../../../../../../shared/hooks/theme/utils";
import {
  getEndOfToday,
  getStartOfDay,
  getSubDays,
  formatDate,
} from "../../../../../../../utils/date";

export type LiftgateCycleHistoryChartProps = {
  assetDetails: { id?: string | null; imei?: string | null };
};

const LiftgateCycleHistoryChart: FC<LiftgateCycleHistoryChartProps> = ({
  assetDetails,
}) => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === themes.light;
  const [cycleStartDate, setStartDate] = useState<Date | null>(
    getStartOfDay(getSubDays(new Date(), 30))
  );

  const [cycleEndDate, setCycleEndDate] = useState<Date | null>(
    getEndOfToday()
  );
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    VOLTAGE_CHART_DATE_RANGE_OPTIONS[0].value
  );

  const { data: dataCycleHistory, isInitialLoading } =
    useGetLiftgateCycleHistoryAggregatedDataOsQuery(
      {
        input: {
          imei: assetDetails.imei,
          assetId: assetDetails.id ?? "",
          reportType: ReportType.Sensors,
          startDate: cycleStartDate,
          endDate: cycleEndDate,
        },
      },
      {
        enabled: Boolean(assetDetails.imei && assetDetails.id),
      }
    );

  const dataGraph =
    dataCycleHistory?.getLiftgateAggregatedDataOS?.data?.[0]?.eventHistoryData
      ?.liftgate?.cycleHistory ?? [];

  const cappedGraphData = dataGraph?.map((item) => ({
    ...item,
    total_liftgate_cycles:
      item && item.total_liftgate_cycles && item.total_liftgate_cycles > 24
        ? 24
        : item?.total_liftgate_cycles,
  }));

  const setSelectedLastDateRangeOption = () => {
    setSelectedDateRangeOption(last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!);
  };

  const handleCycleDateRangeChange = (value: number) => {
    const option = VOLTAGE_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    setSelectedDateRangeOption(value);
    if (option?.getRange) {
      const range = option.getRange();
      setStartDate(range[0]);
      setCycleEndDate(range[1]);
    }
  };

  const svgIconSettings = {
    width: "80px",
    height: "80px",
    display: "block",
  };

  return isInitialLoading ? (
    <VerticalBarChartOverlay />
  ) : (
    <>
      <Box className="!mb-1 justify w-full flex items-center">
        {isLightTheme ? (
          <LiftgateSensorDark style={svgIconSettings} />
        ) : (
          <LiftgateSensorLight style={svgIconSettings} />
        )}

        <Typography
          variant="caption"
          className="!pl-2 !text-base inline-block !font-semibold !text-primary-blue"
        >
          Liftgate Cycle History Graph
        </Typography>
      </Box>

      <Box className="flex items-center justify-end gap-4 py-4">
        <MobileDatePicker
          label="Start date"
          inputFormat="MM/dd/yyyy"
          closeOnSelect
          value={cycleStartDate}
          shouldDisableDate={(day) =>
            day < getStartOfDay(getSubDays(new Date(), 90))
          }
          disableFuture
          onChange={(date) => {
            setStartDate(date);
            setSelectedLastDateRangeOption();
          }}
          DialogProps={{
            className: "trending-temperature-date-picker",
          }}
          renderInput={(params) => (
            <TextField
              data-testid="chart-start-date"
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": {
                  top: -6,
                  left: 15,
                  fontWeight: "normal",
                },
              }}
              {...params}
            />
          )}
        />
        <span className="text-base font-normal text-primary">to</span>
        <MobileDatePicker
          DialogProps={{
            className: "trending-temperature-date-picker",
          }}
          label="End date"
          closeOnSelect
          inputFormat="MM/dd/yyyy"
          value={cycleEndDate}
          shouldDisableDate={(day) =>
            Boolean(cycleStartDate && day < cycleStartDate)
          }
          disableFuture
          onChange={(date) => {
            setCycleEndDate(date);
            setSelectedLastDateRangeOption();
          }}
          renderInput={(params) => (
            <TextField
              data-testid="chart-end-date"
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": {
                  top: -6,
                  left: 15,
                  fontWeight: "normal",
                },
              }}
              {...params}
            />
          )}
        />

        <TextField
          data-testid="chart-date-range-select"
          select
          value={selectedDateRangeOption}
          variant="outlined"
          className="w-40"
          onChange={(e) => handleCycleDateRangeChange(Number(e.target.value))}
        >
          {VOLTAGE_CHART_DATE_RANGE_OPTIONS.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              data-testid={`chart-date-range-select-option-${option.value}`}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <BaseBarChart
        data={cappedGraphData}
        xAxisTickFormatter={(tickItem: string) => {
          return formatDate(new Date(tickItem), "MM/dd");
        }}
        xAsisKey="date"
        yAxisLabel="Number of Cycles"
        yAxisDomain={[0, 200]}
        width="100%"
        height={500}
      >
        <YAxis ticks={[0, 50, 100, 150, 200]} />
        <Tooltip
          offset={-5}
          content={(props) => {
            return (
              <Box className="rounded-lg bg-custom-tooltip-background border border-sub-header-border p-3 flex flex-col gap-3">
                <Typography className="!text-md !text-sub-header-text !font-medium !leading-3 !mb-1">
                  {props.label?.replaceAll("-", "/")}
                </Typography>
                {props.payload?.[0] && (
                  <Typography className="!text-md !text-sub-header-text !font-medium !leading-3 !mb-1">
                    {props.payload?.[0]?.name} :{" "}
                    <span className="ml-2">{props.payload?.[0]?.value}</span>
                  </Typography>
                )}
              </Box>
            );
          }}
        />
        <Bar
          key="total_liftgate_cycles"
          dataKey="total_liftgate_cycles"
          fill={ColorsPalette.RoyalBlue}
          barSize={50}
          name="Liftgate Cycles"
        />
      </BaseBarChart>
    </>
  );
};

export default LiftgateCycleHistoryChart;
