import React from "react";
import { PAGE_SNACKBAR } from "../../../../constants";
import { DEVICE_DELETE_SUCCESS_PAYLOAD } from "../../../../constants/device";
import { Action } from "../../../../context/commonTypes";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";

export const onSuccessDelete = (
  navigate: (path: string) => void,
  dispatch: React.Dispatch<Action>
) => {
  navigate(NavigationRoutes.Devices);
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      ...DEVICE_DELETE_SUCCESS_PAYLOAD,
      text: DEVICE_DELETE_SUCCESS_PAYLOAD.text,
    },
  });
};

export const onErrorDelete = (dispatch: React.Dispatch<Action>) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      ...DEVICE_DELETE_SUCCESS_PAYLOAD,
      text: DEVICE_DELETE_SUCCESS_PAYLOAD.text,
    },
  });
};
