import {
  AssetDetentionTableData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import Status from "../../../../../shared/components/Status";
import {
  TableColumnProps,
  TableGridColDef,
  getTableColumn,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";

export const columnVisibilityModel = {
  geofenceName: true, //geofence_name
  freeDaysStart: true, //free_days_start
  detentionStart: true, //free_days_end
  detentionEnd: true, //exited_at
  totalDaysInDetention: true, //exited_at - free_days_end OR today_date - free_days_end
  chargesAndDetails: true, //detention_charges
  statusOfEntryExit: true, // detention_status
};

export const EVENT_HISTORY_DEFAULT_SORT_COLUMN = "eventDate";
export const EVENT_HISTORY_PAGE_SIZE = 100;

export const getColumns = (
  timezone: string
): TableGridColDef<AssetDetentionTableData>[] => {
  const columns: TableColumnProps<AssetDetentionTableData>[] = [
    {
      field: "geofence_name",
      headerName: "Geofence Name",
      type: "string",
      options: {
        filterable: false,
      },
    },
    {
      field: "free_days_start",
      headerName: "Free Days Start",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "free_days_end",
      headerName: "Detention Start",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "exited_at",
      headerName: "Detention End",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "total_days_in_detention",
      headerName: "Total Days In Detention",
      options: {
        filterable: false,
      },
      type: "string",
    },
    {
      field: "detention_charges",
      headerName: "Charges and Details",
      type: "string",
      options: {
        filterable: false,
        renderCell: (params) => (params.value ? "$" + params.value : ""),
      },
    },
    {
      field: "detention_status",
      headerName: "Status of Entry/Exit",
      type: "string",
      options: {
        filterable: false,
        renderCell: (params) => getDetentionStatus(params.value as string),
      },
    },
  ];

  return columns.map(getTableColumn);
};

export const getDetentionStatus = (status: string) => {
  const maxWidth: number = 103;
  switch (status) {
    case "In detention":
      return (
        <Status bgColor="bg-green" text="In detention" maxWidth={maxWidth} />
      );
    case "Out of detention":
      return (
        <Status
          bgColor="bg-dark-grey"
          text="Out of detention"
          maxWidth={maxWidth}
        />
      );
    case "Free days":
      return (
        <Status bgColor="bg-dark-grey" text="Free days" maxWidth={maxWidth} />
      );
    default:
      return (
        <Status bgColor="bg-dark-grey" text="Unknown" maxWidth={maxWidth} />
      );
  }
};
