import { Color, getColors } from "./colors";

export interface Schemas {
  light: Record<string, string>;
  dark: Record<string, string>;
}

/**
 * Transform Colors object to Schemas object.
 */
export const getSchemas = (brandHex: string): Schemas => {
  const colors = getColors(brandHex);

  return Object.entries(colors).reduce(
    (schemas: Schemas, [name, value]: [string, Color]) => {
      schemas.light[name] = typeof value === "string" ? value : value.light;
      schemas.dark[name] = typeof value === "string" ? value : value.dark;

      return schemas;
    },
    {
      light: {},
      dark: {},
    }
  );
};
