import { cloneDeep } from "lodash";
import {
  ConfigurationSet,
  ZoneGroupTypeValue,
} from "../../../../graphql/operations";
import { ParsedZonesData, ZoneCountry } from "./interfaces";

export const initialZonesData: ParsedZonesData = {
  unitedStates: {
    data: [],
  },
  canada: {
    data: [],
  },
  mexico: {
    data: [],
  },
};

export const parseZonesData = (
  regionsData: ConfigurationSet[]
): ParsedZonesData =>
  [...regionsData].reduce((result, data) => {
    const dataValue = JSON.parse(data.value) as ZoneGroupTypeValue;
    result[dataValue.country as keyof ParsedZonesData].data.push({
      id: data._id,
      name: data.name,
      country: dataValue.country as ZoneCountry,
      ranges: dataValue.ranges ?? [],
      matches: (dataValue.matches ?? []) as unknown as string[],
    });

    return result;
  }, cloneDeep(initialZonesData));
