import { GridColDef } from "@mui/x-data-grid-premium";
import { LiftgateFaultHistoryData } from "../../../../../../../../graphql/operations";
import {
  DATE_TIME_FORMAT_SHORT,
  formatDate,
} from "../../../../../../../../utils";

export const columns: GridColDef<LiftgateFaultHistoryData>[] = [
  {
    field: "startTime",
    headerName: "Start Time",
    type: "date",
    valueFormatter: (params: { value: string }) =>
      formatDate(params.value, DATE_TIME_FORMAT_SHORT),
  },
  {
    field: "endTime",
    headerName: "End Time",
    type: "date",
    valueFormatter: (params: { value: string }) =>
      formatDate(params.value, DATE_TIME_FORMAT_SHORT),
  },
  {
    field: "name",
    headerName: "Name",
  },
  {
    field: "description",
    headerName: "Description",
  },
].map((column) => ({
  ...column,
  flex: 1,
  minWidth: 120,
  sortable: false,
}));

export const columnVisibilityModel: Record<string, boolean> = {
  startTime: true,
  endTime: true,
  name: true,
  description: true,
};
