import {
  DistanceUnit,
  PressureUnit,
  TemperatureUnit,
} from "../graphql/operations";

// Note: Think about moving this as a nomenclature at some point, if requested too many changes
export const DEFAULT_DISTANCE_UNIT = DistanceUnit.Miles;
export const DEFAULT_TEMPERATURE_UNIT = TemperatureUnit.Fahrenheit;
export const DEFAULT_PRESSURE_UNIT = PressureUnit.Psi;
