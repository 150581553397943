import { S3Object } from "../../../graphql/operations";
import brandMock from "../brandMock/brandMock.json";

interface AuthenticationViewBrand {
  color: string;
  supportEmail: string;
  supportPhoneNumber: string;
  logo: S3Object;
  backgroundImage: S3Object;
}

/**
 * This hook should be used to obtain brand data, that is used in branding the authentication flows.
 *
 * We should fetch the brand data from S3 bucket based on organization id, once we have such S3 bucket configured.
 *
 * TODO: Once we have the S3 bucket configured, we should:
 *  - replace the mock data with the actual data
 *  - delete `brandMock.json`
 *  - delete `phillipsBackgroundImage.png` and `phillipsLogo.png` from `public/authenticationView` directory
 */
export const useAuthenticationViewBrand = (): AuthenticationViewBrand => {
  return brandMock; // Replace this with the actual brand data from S3;
};
