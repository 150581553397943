import { DISABLE_ASSET_TABLE_ON_CLICK } from "../../../../constants";
import { Action } from "../../../../context/commonTypes";

export const onGetAssetsSuccess = (
  isOnClickDisabled: boolean,
  dispatch: React.Dispatch<Action>
) => {
  // we need to enable the click on the table rows after refetching data, as sometimes outdated data is still accessible during data loading
  if (isOnClickDisabled) {
    dispatch({
      type: DISABLE_ASSET_TABLE_ON_CLICK,
      payload: {
        isOnClickDisabled: false,
      },
    });
  }
};
