import { memo, FC, MouseEvent } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, Checkbox } from "@mui/material";
import { HierarchyStateItem } from "../types";
import { getHiddenClass } from "../utils";

export type HierarchyNodeProps = {
  item: HierarchyStateItem;
  onValueChange: (item: HierarchyStateItem) => void;
  disableHover?: boolean;
};

export const HierarchyNode: FC<HierarchyNodeProps> = memo(
  ({ item, onValueChange, disableHover }: HierarchyNodeProps) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onValueChange(item);
    };

    return (
      <Box
        className={`flex items-start ${
          !disableHover ? "hover:bg-brand-transparent" : ""
        } ${getHiddenClass(item.visible)}`}
        aria-hidden={!item.visible}
        data-testid={`hierarchy-item-${item.value}`}
      >
        <Checkbox
          data-testid={`hierarchy-item-checkbox-${item.value}`}
          className="!mr-3 !pt-2 !pb-0 !pr-0 !pl-0 !text-brand"
          onClick={handleClick}
          checked={item.selected}
          icon={
            <CheckBoxOutlineBlankIcon
              className="!text-checkbox-unchecked"
              fontSize="small"
            />
          }
          checkedIcon={
            <CheckBoxIcon className="!text-brand" fontSize="small" />
          }
        />
        {item.label}
      </Box>
    );
  }
);
