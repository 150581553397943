import { UseFormReturn } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Dictionary } from "lodash";
import ActionButton from "../../../../../../shared/components/Button/ActionButton";
import { QueryBuilderElement } from "../../../../../../shared/components/react-hook-form-mui/QueryBuilderElement";
import { geofenceQueryBuilderFields } from "../../../../shared/AssetsFilterControls/Filters/QueryBuilderTab/config";

interface GeoFenceQBModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: () => void;
  resetQueryBuilderHandler: () => void;
  form: UseFormReturn<Dictionary<any>, any, undefined>;
  shouldReset: boolean;
  onReset: () => void;
}

export const GeoFenceQBModal = ({
  isOpen,
  onClose,
  onApply,
  resetQueryBuilderHandler,
  form,
  shouldReset,
  onReset,
}: GeoFenceQBModalProps) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        sx: {
          backgroundColor: "var(--background) !important",
          backgroundImage: "none",
          width: "100%",
          maxWidth: "720px !important",
          borderRadius: "8px",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          data-testid="geofence-qb"
        >
          <Typography className="!text-lg" variant="h6" component="div">
            Custom Query
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className="!ml-auto  h-10 w-10"
            data-testid="close-modal-btn"
            sx={{
              color: "var(--app_header_rev__bg)",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="flex justify-end pr-2">
          <Button
            data-testid="reset-query-view-btn"
            className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
            onClick={resetQueryBuilderHandler}
          >
            Reset query view
          </Button>
        </Box>
        <div className="mb-4">
          <QueryBuilderElement
            label=""
            control={form.control}
            name="filters"
            configFields={geofenceQueryBuilderFields}
            shouldReset={shouldReset}
            onReset={onReset}
          />
        </div>
        <div className="flex justify-end pr-2">
          <Button
            data-testid="cancel-query-view-btn"
            className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand !mr-4"
            onClick={onClose}
          >
            Cancel
          </Button>
          <ActionButton
            testId="apply-query-view-btn"
            className="block text-primary border-primary text-typography"
            onClick={onApply}
          >
            Save
          </ActionButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
