import { FC } from "react";

type CargoStateIconProps = {
  cargoState: string | undefined;
  signal: boolean;
};

const CargoStateIcon: FC<CargoStateIconProps> = ({ cargoState, signal }) => {
  switch (cargoState) {
    case "unloaded":
      return (
        <circle cx="12" cy="12" r="2" fill="var(--asset-status-icon-empty)" />
      );
    case "empty":
      return (
        <circle cx="12" cy="12" r="2" fill="var(--asset-status-icon-empty)" />
      );
    case "loaded":
      return (
        <g style={{ mixBlendMode: "multiply" }}>
          <circle
            cx="12"
            cy="12"
            r="2"
            fill={
              signal
                ? "var(--asset-status-icon-loaded)"
                : "var(--asset-status-icon-loaded-no-gps)"
            }
          />
        </g>
      );
    default:
      return null;
  }
};

export default CargoStateIcon;
