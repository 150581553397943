import {
  FC,
  memo,
  useState,
  useRef,
  DragEvent,
  ChangeEvent,
  RefObject,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import { Typography, Box, InputLabel } from "@mui/material";
import PropTypes from "prop-types";
import {
  downloadBrandFile,
  downloadFile,
  prepareFileDataForPreview,
} from "../BrandUtils";
import { handleDragEnter, handleDrop } from "./FileUploadUtils";

type FileUploadFormProps = {
  onFileUpload: (file: File) => void;
  allowedFormats: string[];
  onDeleteFile: () => void;
  fileData?: any;
};
const BrandFileUpload: FC<FileUploadFormProps> = ({
  onFileUpload,
  onDeleteFile,
  allowedFormats,
  fileData,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [filePreview, setFilePreview] = useState<string>();
  const inputRef: RefObject<HTMLInputElement> = useRef(null);

  const onDragAction = (event: DragEvent) => {
    handleDragEnter(event as any, setDragActive);
  };

  useEffect(() => {
    if (allowedFormats.includes("pdf")) return;

    if (!fileData) {
      setFilePreview(undefined);
      return;
    }
    if (fileData.url) {
      downloadBrandFile(fileData.url, fileData.content_type)
        .then((response) => {
          if (response.data) {
            prepareFileDataForPreview(response.data, setFilePreview);
          }
        })
        .catch((e) => console.error(e));
    } else {
      prepareFileDataForPreview(fileData, setFilePreview);
    }
  }, [allowedFormats, fileData]);

  const onDropAction = (event: DragEvent) => {
    handleDrop(event as any, setDragActive, onFileUpload);
  };

  /**
   * Handle file input change
   * @param event
   */
  const handleFileChange = function (event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      onFileUpload(event.target.files[0]);
    }
  };

  /**
   * Call download functionality for any available files,
   * if not available attempt to download it from s3
   */
  const handleFileDownload = () => {
    if (filePreview)
      downloadFile(filePreview, fileData?.name || fileData?.file_name);
    else if (fileData.url) {
      downloadBrandFile(fileData.url, fileData.content_type)
        .then((response) => {
          if (response.data) {
            const objectUrl = URL.createObjectURL(response.data);
            downloadFile(objectUrl, fileData?.name || fileData?.file_name);

            return () => URL.revokeObjectURL(objectUrl);
          }
        })
        .catch((e) => console.error(e));
    }
  };

  /**
   * Handle Browse button click to open the select file dialog
   */
  const onBrowseButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div className="container">
      {!fileData && (
        <form
          onSubmit={(event) => event.preventDefault()}
          className="relative h-[8rem] text-center"
          onDragEnter={onDragAction}
        >
          <InputLabel
            className={`!flex h-full items-center justify-center rounded-lg border border-dashed border-upload_file__form_border bg-upload_file__form_bg ${
              dragActive && "bg-white"
            }`}
          >
            <input
              data-testid="file-upload-input"
              ref={inputRef}
              className="hidden"
              type="file"
              accept={
                allowedFormats.length > 0
                  ? allowedFormats.map((format) => `.${format}`).join(",")
                  : undefined
              }
              onChange={handleFileChange}
            />
            <Box className="flex flex-wrap justify-center text-sm">
              <Typography>Drag & drop your file here or</Typography>
              <button
                className="font-bold text-brand"
                onClick={onBrowseButtonClick}
              >
                &nbsp;browse
              </button>
            </Box>
          </InputLabel>
          {dragActive && (
            <Box
              className="absolute inset-0 h-full w-full"
              data-testid="file-upload-drag-box"
              onDragEnter={onDragAction}
              onDragLeave={onDragAction}
              onDragOver={onDragAction}
              onDrop={onDropAction}
            />
          )}
        </form>
      )}
      {fileData && (
        <Box>
          <Box
            key={fileData?.name || fileData?.file_name}
            className="m-2 flex justify-between bg-upload_file__form_bg p-2 text-upload_file__icon"
          >
            {filePreview ? (
              <Box>
                <img
                  className="w-8 h-6"
                  alt={fileData?.name || fileData?.file_name}
                  src={filePreview}
                />
              </Box>
            ) : (
              <Box className="mr-5">
                <DescriptionIcon />
              </Box>
            )}
            <Box
              onClick={handleFileDownload}
              data-testid="download-btn"
              className="flex cursor-pointer"
            >
              <Typography className="text-sm text-typography">
                {fileData?.name || fileData?.file_name}
              </Typography>
            </Box>
            <Box>
              <button onClick={onDeleteFile}>
                <DeleteIcon />
              </button>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

BrandFileUpload.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  allowedFormats: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fileData: PropTypes.any,
  onDeleteFile: PropTypes.func.isRequired,
};

BrandFileUpload.defaultProps = {
  fileData: undefined,
};

export default memo(BrandFileUpload);
