export const columnVisibilityModel = {
  arrivalCargoState: true,
  arrivalTime: true,
  assetId: true,
  departureCargoState: true,
  departureTime: true,
  detentionStatus: true,
  enteredAtDays: true,
  imei: true,
  timestamp: true,
  totalDwell: true,
  unloadDuration: true,
};
