import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useTheme } from "../../HierarchySelect/hooks/useTheme";

export const useExternalChipSelectTheme = () => {
  const currentTheme = useTheme();

  return createTheme(
    deepmerge(currentTheme, {
      components: {
        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: "4px",
              backgroundColor: "var(--border-color) !important",
              marginRight: "16px",
              height: "24px",
            },
            label: {
              color: "var(--mid-charcoal) !important",
            },
            deleteIcon: {
              color: "var(--mid-charcoal) !important",
              fontSize: "18px",
            },
          },
        },
      },
    })
  );
};
