import {
  Exact,
  CreateConfigurationSetInput,
  CreateConfigurationSetMutation,
  useCreateConfigurationSetMutation,
  DeleteConfigurationSetMutation,
  DeleteConfigurationSetInput,
  useDeleteConfigurationSetMutation,
  UpdateConfigurationSetMutation,
  UpdateConfigurationSetInput,
  useUpdateConfigurationSetMutation,
  ToggleConfigurationSetInput,
  ToggleConfigurationSetMutation,
  useToggleConfigurationSetMutation,
  DeleteManyConfigurationSetsInput,
  useDeleteManyConfigurationSetsMutation,
} from "../../../graphql/operations";

interface ConfigurationSetsApiInput {
  createConfigurationSetOnSuccess?: (
    data: CreateConfigurationSetMutation,
    variables: Exact<{
      input: CreateConfigurationSetInput;
    }>,
    context: unknown
  ) => unknown;
  createConfigurationSetOnError?: (error: Error) => unknown;

  updateConfigurationSetOnSuccess?: (
    data: UpdateConfigurationSetMutation,
    variables: Exact<{
      input: UpdateConfigurationSetInput;
    }>,
    context: unknown
  ) => unknown;
  updateConfigurationSetOnError?: (error: Error) => unknown;

  deleteConfigurationSetOnSuccess?: (
    data: DeleteConfigurationSetMutation,
    variables: Exact<{
      input: DeleteConfigurationSetInput;
    }>,
    context: unknown
  ) => unknown;
  deleteConfigurationSetOnError?: (error: Error) => unknown;

  deleteManyConfigurationSetsOnSuccess?: (
    data: DeleteConfigurationSetMutation,
    variables: Exact<{
      input: DeleteManyConfigurationSetsInput;
    }>,
    context: unknown
  ) => unknown;
  deleteManyConfigurationSetsOnError?: (error: Error) => unknown;

  toggleConfigurationSetOnSuccess?: (
    data: ToggleConfigurationSetMutation,
    variables: Exact<{
      input: ToggleConfigurationSetInput;
    }>,
    context: unknown
  ) => unknown;
  toggleConfigurationSetOnError?: (error: unknown) => unknown;
}

interface ConfigurationSetsApiHook {
  isLoadingCreateConfigurationSet: boolean;
  createConfigurationSet: (input: CreateConfigurationSetInput) => unknown;

  isLoadingUpdateConfigurationSet: boolean;
  updateConfigurationSet: (input: UpdateConfigurationSetInput) => unknown;

  isLoadingDeleteConfigurationSet: boolean;
  deleteConfigurationSet: (input: DeleteConfigurationSetInput) => unknown;

  isLoadingDeleteManyConfigurationSets: boolean;
  deleteManyConfigurationSets: (
    input: DeleteManyConfigurationSetsInput
  ) => unknown;

  isLoadingToggleConfigurationSet: boolean;
  toggleConfigurationSet: (input: ToggleConfigurationSetInput) => unknown;
}

export const useConfigurationSetsApi = ({
  createConfigurationSetOnSuccess,
  createConfigurationSetOnError,

  updateConfigurationSetOnSuccess,
  updateConfigurationSetOnError,

  deleteConfigurationSetOnSuccess,
  deleteConfigurationSetOnError,

  deleteManyConfigurationSetsOnSuccess,
  deleteManyConfigurationSetsOnError,

  toggleConfigurationSetOnSuccess,
  toggleConfigurationSetOnError,
}: ConfigurationSetsApiInput): ConfigurationSetsApiHook => {
  const {
    isLoading: isLoadingCreateConfigurationSet,
    mutate: createConfigurationSet,
  } = useCreateConfigurationSetMutation({
    onSuccess: createConfigurationSetOnSuccess,
    onError: createConfigurationSetOnError,
  });

  const {
    isLoading: isLoadingUpdateConfigurationSet,
    mutate: updateConfigurationSet,
  } = useUpdateConfigurationSetMutation({
    onSuccess: updateConfigurationSetOnSuccess,
    onError: updateConfigurationSetOnError,
  });

  const {
    isLoading: isLoadingDeleteConfigurationSet,
    mutate: deleteConfigurationSet,
  } = useDeleteConfigurationSetMutation({
    onSuccess: deleteConfigurationSetOnSuccess,
    onError: deleteConfigurationSetOnError,
  });

  const {
    isLoading: isLoadingDeleteManyConfigurationSets,
    mutate: deleteManyConfigurationSets,
  } = useDeleteManyConfigurationSetsMutation({
    onSuccess: deleteManyConfigurationSetsOnSuccess,
    onError: deleteManyConfigurationSetsOnError,
  });

  const {
    isLoading: isLoadingToggleConfigurationSet,
    mutate: toggleConfigurationSet,
  } = useToggleConfigurationSetMutation({
    onSuccess: toggleConfigurationSetOnSuccess,
    onError: toggleConfigurationSetOnError,
  });

  return {
    isLoadingCreateConfigurationSet,
    createConfigurationSet: (input: CreateConfigurationSetInput) => {
      return createConfigurationSet({ input });
    },

    isLoadingUpdateConfigurationSet,
    updateConfigurationSet: (input: UpdateConfigurationSetInput) => {
      return updateConfigurationSet({ input });
    },

    isLoadingDeleteConfigurationSet,
    deleteConfigurationSet: (input: DeleteConfigurationSetInput) => {
      return deleteConfigurationSet({ input });
    },

    isLoadingDeleteManyConfigurationSets,
    deleteManyConfigurationSets: (input: DeleteManyConfigurationSetsInput) => {
      return deleteManyConfigurationSets({ input });
    },

    isLoadingToggleConfigurationSet,
    toggleConfigurationSet: (input: ToggleConfigurationSetInput) => {
      return toggleConfigurationSet({ input });
    },
  };
};
