import { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as CargoSensorDark } from "../../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLight } from "../../../../../../assets/svgs/cargoSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import { Asset, SensorStatus } from "../../../../../../graphql/operations";
import SensorHeader from "../../../../../../shared/components/SensorHeader/SensorHeader";
import { formatDistanceToNowStrictCustom } from "../../../../../../utils/date";

export interface CargoStatusInLocationsProps {
  selectedAsset: Asset;
}

const CargoState: FC<CargoStatusInLocationsProps> = ({ selectedAsset }) => {
  const { sensors } = selectedAsset;
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };
  return (
    <Grid
      spacing={0}
      justify-content="flex-end"
      container
      className="text-sm w-full p-4"
      data-testid="last-30-days-loaded-empty"
    >
      <SensorHeader title="Cargo Status">
        {isLightTheme ? (
          <CargoSensorDark style={svgIconSettings} />
        ) : (
          <CargoSensorLight style={svgIconSettings} />
        )}
      </SensorHeader>
      <Grid container alignItems="stretch" className="mt-auto">
        <Box className="truncate w-full text-left">
          <Typography
            className="truncate capitalize !text-5xl !mb-4 !font-semibold !text-brand-blue"
            data-testid="battery-widget-label"
            sx={{ lineHeight: "1.5 !important" }}
          >
            {!sensors?.chassis?.data?.cargoState ||
            sensors?.chassis?.data?.cargoState === "na"
              ? SensorStatus.Unknown
              : sensors?.chassis?.data?.cargoState}
          </Typography>
        </Box>
        <Box className="!mb-2 w-full text-right">
          <Box
            className="inline-block rounded p-2 !text-center"
            sx={{ background: "var(--primary-blue)", color: "var(--white)" }}
          >
            <Typography className=" !text-xs	 !font-bold">
              {formatDistanceToNowStrictCustom(
                new Date(sensors?.chassis?.readingDate)
              )}
            </Typography>
          </Box>
        </Box>

        <Box className="justify w-full text-right">
          <Typography className="!text-sm inline-block align-middle text-card-sensors-text">
            in current status
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CargoState;
