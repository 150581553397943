import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last, sortBy } from "lodash";
import {
  DateRangeKey,
  VOLTAGE_CHART_DATE_RANGE_OPTIONS,
} from "../../../../../../../constants/map";
import {
  Asset,
  HistoricalEventHistory,
  useGetLightsOutHistoricalDataQuery,
} from "../../../../../../../graphql/operations";
import useBreakpoint from "../../../../../../../shared/hooks/useBreakpoint";
import {
  getEndOfToday,
  getStartOfDay,
  getSubDays,
} from "../../../../../../../utils/date";
import { useLightsTheme } from "../Theme/useLightsTheme";
import { LightsChartLegend } from "./LightsChartLegend";
import { LightsChartScatterSection } from "./LightsChartScatterSection";
import {
  LightsMappedValues,
  LITE_SENTRY_LIGHT_TYPES,
  mapAggregatedLightsObservationData,
} from "./helpers";

export enum LightsChartObservationType {
  ObservedCurrent = "observedCurrent",
  DeviationPercentage = "deviationPercentage",
}

export interface LightsChartProps {
  asset: Asset;
  chartTitle: string;
  yAxisText: string;
  lightSensorType: LITE_SENTRY_LIGHT_TYPES;
  observationType: LightsChartObservationType;
}

/**
 * Used to show any either current or % in deviation for any type of light & scenario
 */
export const LightsChart: React.FC<LightsChartProps> = ({
  asset,
  chartTitle,
  yAxisText,
  lightSensorType,
  observationType,
}) => {
  // Hooks
  const theme = useLightsTheme();
  const isMobile = useBreakpoint("down", "sm");

  // States
  const [lightsData, setLightsData] = useState<LightsMappedValues[]>([]);
  const [hiddenDots, setHiddenDots] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(
    getStartOfDay(getSubDays(new Date(), 30))
  );
  const [endDate, setEndDate] = useState<Date | null>(getEndOfToday());
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    VOLTAGE_CHART_DATE_RANGE_OPTIONS[0].value
  );

  // GraphQL hooks
  const { isLoading, data } = useGetLightsOutHistoricalDataQuery(
    {
      input: {
        imei: asset.imei,
        customerOrgId: asset.customer_orgs_id,
        startDate: startDate,
        endDate: endDate,
        period: DateRangeKey.Last30Days,
        includeEmptyDates: true,
      },
    },
    {
      enabled:
        Boolean(asset.imei) &&
        Boolean(asset.customer_orgs_id) &&
        Boolean(asset.asset_id),
    }
  );

  // Effects
  useEffect(() => {
    const lightsReadings = (data?.searchHistoricalEventHistory?.data ??
      []) as HistoricalEventHistory[];
    const aggregatedLightsData = mapAggregatedLightsObservationData(
      lightSensorType,
      lightsReadings,
      observationType
    );
    const sortedLightsData = sortBy(aggregatedLightsData, (d) => d?.date);

    setLightsData(sortedLightsData);
  }, [data, lightSensorType, observationType]);

  // Handlers
  const handleDateRangeChange = (value: number) => {
    const option = VOLTAGE_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    setSelectedDateRangeOption(value);
    if (option?.getRange) {
      const [startDate, endDate] = option.getRange();
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const setSelectedLastDateRangeOption = () => {
    setSelectedDateRangeOption(last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!);
  };

  // leave some padding around the xAxis ( 86400000 - 1 day in ms )
  const domain = ["dataMin - 86400000", "dataMax + 86400000"];

  return (
    <ThemeProvider theme={theme}>
      <Box width="100%">
        {!isMobile && (
          <Box className="flex-1 rounded-lg bg-dashboard_subheader__bg p-4">
            <Typography className="!text-lg !font-semibold text-brand">
              {chartTitle}
            </Typography>
            <Box className="flex items-center justify-end gap-4 py-4">
              <MobileDatePicker
                label="Start date"
                inputFormat="MM/dd/yyyy"
                closeOnSelect
                value={startDate}
                shouldDisableDate={(day: any) =>
                  day < getStartOfDay(getSubDays(new Date(), 90))
                }
                disableFuture
                onChange={(date: any) => {
                  setStartDate(date);
                  setSelectedLastDateRangeOption();
                }}
                DialogProps={{
                  className: "lights-date-picker",
                }}
                renderInput={(params) => (
                  <TextField
                    data-testid="lights-chart-start-date"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
              <span className="text-base font-normal text-primary">to</span>
              <MobileDatePicker
                DialogProps={{
                  className: "lights-date-picker",
                }}
                label="End date"
                closeOnSelect
                inputFormat="MM/dd/yyyy"
                value={endDate}
                shouldDisableDate={(day) => !!startDate && day < startDate}
                disableFuture
                onChange={(date) => {
                  setEndDate(date);
                  setSelectedLastDateRangeOption();
                }}
                renderInput={(params) => (
                  <TextField
                    data-testid="lights-chart-end-date"
                    variant="outlined"
                    {...params}
                  />
                )}
              />

              <TextField
                data-testid="lights-chart-date-range-select"
                select
                value={selectedDateRangeOption}
                variant="outlined"
                className="w-40"
                onChange={(e) => handleDateRangeChange(+e.target.value)}
              >
                {VOLTAGE_CHART_DATE_RANGE_OPTIONS.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    data-testid={`lights-chart-date-range-select-option-${option.value}`}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {isLoading ? (
              <Box
                className="flex h-full w-full items-center justify-center"
                data-testid="lights-chart-loader"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <LightsChartScatterSection
                  lightSensorType={lightSensorType}
                  yAxisText={yAxisText}
                  data={lightsData}
                  xAxisdomain={domain}
                  hiddenDots={hiddenDots}
                />

                <Box className="mb-12 flex justify-center gap-8">
                  <LightsChartLegend
                    hiddenDots={hiddenDots}
                    onClick={(key: number) =>
                      setHiddenDots((prevState) =>
                        prevState.includes(key)
                          ? prevState.filter((k) => k !== key)
                          : [...prevState, key]
                      )
                    }
                    lightSensorType={lightSensorType}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};
