import * as yup from "yup";
import { transformers } from "../../../../../../../utils";

const nullableString = yup.string().transform(transformers.string);
export const schema = yup.object().shape({
  product_code: yup
    .string()
    .required("Code is required")
    .transform(transformers.string),
  product_name: yup
    .string()
    .required("Name is required")
    .transform(transformers.string),
  organization_name: yup
    .string()
    .nullable()
    .required("Organization name is required"),
  asset_configuration: yup
    .string()
    .required("Configuration is required")
    .transform(transformers.string),
  metadata: yup.object().optional().shape({
    owner: nullableString,
  }),
});
