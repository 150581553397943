import { AssetFilters } from "../../../AssetsDataContext";

export enum SensorFiltersNames {
  CargoUltrasonic = "Cargo Ultrasonic", // TODO: Looks like it's related to deprecated code and should be removed
  Door = "Door",
  InternalCameraStatus = "Internal Camera Status",
  InternalCameraPercentage = "Internal Camera Percentage",
  BatteryPrimaryVoltage = "Primary Voltage",
  BatterySecondaryVoltage = "Secondary Voltage",
  BackupBatteryVoltage = "Battery",
  SolarAmperage = "Solar",
  AtisAlpha = "ATIS",
  LiteSentryGamma = "Lights",
  TemperatureInternal = "Temperature (Internal)",
  WheelEndTemperature = "Wheel End Temperature",
  Airbag = "Air Bag",
  TpmsBeta = "TPMS",
  TankPressure = "Air Tank",
  SupplyPressure = "Regulator ",
}

export enum SensorFilterSliderColors {
  BatteryPrimaryVoltage = "primary-blue",
  BatterySecondaryVoltage = "dark-charcoal",
  BackupBatteryVoltage = "success",
  SolarAmperage = "warning",
}

export type SensorFilterDropdownElement = {
  label: string;
  id: number;
  contextObjectKey: keyof AssetFilters;
};

export type SensorFiltersRenderedElement = {
  id: string;
  selectedValue: SensorFilterDropdownElement | null;
};
