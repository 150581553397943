import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  mfaCode: yup
    .string()
    .required("Code is required")
    .matches(/^\d{6}$/, "Code must be exactly 6 digits"),
});

export const useMfaForm = () =>
  useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mfaCode: "",
    },
  });
