import { useMemo } from "react";
import { useDeviceHistoryModel } from "./useDeviceHistoryModel";

interface DeviceHistoryViewModelProps {
  device_id: string | undefined;
}

export const useDeviceHistoryViewModel = ({
  device_id,
}: DeviceHistoryViewModelProps) => {
  const { findDeviceEvents, isSuccess, isLoading } = useDeviceHistoryModel({
    device_id,
  });

  const deviceEvents = useMemo(
    () => findDeviceEvents?.findDeviceEvents?.events ?? [],
    [findDeviceEvents]
  );

  return {
    deviceEvents,
    isSuccess,
    isLoading,
  };
};
