import { useState, useEffect, useMemo, FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Box, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as BatterySensorDark } from "../../../../../../assets/svgs/batterySensorDark.svg";
import { ReactComponent as BatterySensorLight } from "../../../../../../assets/svgs/batterySensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useGetSensorProfileNamesWithConfigurationQuery,
  SensorProfileType,
  SetAssetSensorProfilesInput,
  ProfileConfigProperty,
  AssignOrgProfileToAssetsInput,
  AssetWithSensors,
  SensorProfileConfigType,
  SensorThresholdShort,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractReversedRules,
  prepareReversedPayload,
  batteryDefaultValues,
  batteryStep,
} from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../../shared/helpers/battery";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import {
  SensorProfileDropdownItem,
  BATTERY_FORM_FIELDS,
  sortSensorProfileNameDropdownItems,
} from "../../sensorsUtils";
import SelectedRecordInfo from "../../shared/SelectedRecordsInfo";
import ConfigurationDialogAdditionalElement from "../Components/CheckboxComponent";
import SensorProfileAssignmentForm from "../Components/SensorProfileAssignmentForm";
import { useMutateCustomProfile, useMutateProfile } from "../drawer.hooks";
import { sensorsBatteriesSchema } from "./sensorsBatteriesDrawerSchema";

interface BatterySensorDrawerProps {
  currentOrgId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: AssetWithSensors[];
}

const BatterySensorDrawer: FC<BatterySensorDrawerProps> = ({
  open,
  setOpen,
  selectedRecordsData,
  currentOrgId,
}) => {
  const queryClient = useQueryClient();
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };
  let defaultBatterySensorProfile = undefined;
  let defaultTypeOfAssignment = "custom";
  const voltage =
    selectedRecordsData?.[0]?.sensorProfile?.configuration?.voltage;
  const voltageThresholds = selectedRecordsData?.[0]?.batterySensors;
  const isPresetProfile = voltage?.type !== SensorProfileType.Asset;

  if (isPresetProfile) {
    defaultTypeOfAssignment = "profile";
    defaultBatterySensorProfile = voltage?.profileId;
  }

  //set default values and resolver to the main form
  const mainForm = useForm({
    resolver: yupResolver(sensorsBatteriesSchema),
    defaultValues: {
      typeOfAssignment: defaultTypeOfAssignment,
      batterySensorProfile: defaultBatterySensorProfile,
    },
  });
  const parsedBatteryValues = extractReversedRules(voltageThresholds);
  const initialBattery = parsedBatteryValues || batteryDefaultValues;

  //states
  const [battery, setBattery] = useState(initialBattery);
  const [
    isConfirmProfileAssignmentVisible,
    setIsConfirmProfileAssignmentVisible,
  ] = useState(false);
  const [isTypeOfAssignmentProfile, setIsTypeOfAssignmentProfile] =
    useState(false);
  const [profileInput, setProfileInput] = useState<
    AssignOrgProfileToAssetsInput | undefined
  >(undefined);
  const [dontShowConfirmationDialog, setDontShowConfirmationDialog] =
    useState(false);
  const [selectedProfileLabel, setSelectedProfileLabel] = useState<string>("");

  //effects
  useEffect(() => {
    if (isPresetProfile) {
      setIsTypeOfAssignmentProfile(true);
    }
    const isDontShowAgain =
      localStorage.getItem("setProfileConformation") === "true";
    setDontShowConfirmationDialog(isDontShowAgain);
  }, [isPresetProfile]);

  // GraphQL
  const { data: profilesData, isLoading: isSensorProfileNamesLoading } =
    useGetSensorProfileNamesWithConfigurationQuery(
      {
        input: {
          orgId: currentOrgId ?? "",
          type: SensorProfileType.Organization,
          includeDefaultPctProfile: true,
          mergeProfilesWithDefaultPctProfile: true,
          sensorType: SensorProfileConfigType.Voltage,
        },
      },
      {
        enabled: Boolean(currentOrgId),
      }
    );

  //handlers
  const handleConfirm = (confirmed: boolean) => {
    setIsConfirmProfileAssignmentVisible((prev) => !prev);
    if (!confirmed) {
      return;
    }
    if (dontShowConfirmationDialog) {
      localStorage.setItem("setProfileConformation", "true");
    }
    if (profileInput) {
      mutateProfile({ input: profileInput });
    }
  };

  const selectedAssetsImeis: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.imei)
    .filter((imei): imei is string => !!imei);

  const selectedRowsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?._id)
    .filter((id): id is string => !!id);

  const selectedAssetsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.asset_id)
    .filter((id): id is string => !!id);

  const sensorProfilesForOrganization = useMemo(
    () => profilesData?.getSensorProfilesForOrganization ?? [],
    [profilesData]
  );

  const batterySensorProfiles: SensorProfileDropdownItem[] =
    sensorProfilesForOrganization.map((item: any) => ({
      label: item.name,
      value: item._id,
      id: item._id,
      configuration: item.configuration,
      type: item.type,
    }));

  BATTERY_FORM_FIELDS[1].options = sortSensorProfileNameDropdownItems(
    batterySensorProfiles
  );

  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        text: "Sensor(s) Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const onSubmit = async () => {
    const isMainFormValid = await mainForm.trigger();
    if (!isMainFormValid) return;

    // type of Assignment is not profile submit else ask for confirmation
    if (!isTypeOfAssignmentProfile) {
      const input: SetAssetSensorProfilesInput = {
        selectedImeis: selectedAssetsImeis,
        orgId: currentOrgId ?? "",
        sensors: {
          voltage: {
            battery: prepareReversedPayload(
              ProfileConfigProperty.Battery,
              battery,
              MinValuesBySensorType.battery,
              MaxValuesBySensorType.battery,
              batteryStep
            ),
          },
        },
      };
      mutateCustomProfile({ input });
    } else {
      const selectedProfile = mainForm.getValues().batterySensorProfile;
      const input: AssignOrgProfileToAssetsInput = {
        assetIds: selectedRowsIds,
        profileId: selectedProfile ?? "",
      };
      // if we have dontShowConfirmationDialog again clicked and submitted to local storage,
      // don't show confirmation dialog
      if (dontShowConfirmationDialog) {
        mutateProfile({ input });
      } else {
        setProfileInput(input);
        setIsConfirmProfileAssignmentVisible((prev) => !prev);
      }
    }
  };

  // handle change of Type of Assignment - show/hide of Battery Sensor question
  const handleAssignmentTypeInputChange = (e: {
    name: string;
    value: string;
    id: string;
  }) => {
    const id = e?.id ?? undefined;
    if (id === "profile") {
      setIsTypeOfAssignmentProfile(true);
    } else {
      if (!isPresetProfile) setBattery(initialBattery);
      setIsTypeOfAssignmentProfile(false);
    }
  };

  // handle change of battery sensor profile dropdown
  const handleSensorProfileInputChange = (selectedSensorProfile: {
    configuration: { voltage: { battery: SensorThresholdShort } };
    label: string;
  }) => {
    const selectedProfileBatteryValues = extractReversedRules(
      selectedSensorProfile?.configuration?.voltage?.battery
    );
    setBattery(selectedProfileBatteryValues ?? batteryDefaultValues);

    setSelectedProfileLabel(selectedSensorProfile?.label ?? "");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxClick = () => {
    setDontShowConfirmationDialog((prev) => !prev);
  };

  // this is a mutation that we use when Type of Assignment is CUSTOM
  const { mutate: mutateCustomProfile, isLoading: isLoadingCustomProfile } =
    useMutateCustomProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  // this is a mutation that we use when Type of Assignment is PROFILE
  const { mutate: mutateProfile, isLoading: isLoadingProfile } =
    useMutateProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  const batteryMarks = sliderMarks(
    MinValuesBySensorType.battery,
    MaxValuesBySensorType.battery,
    "V"
  );

  const isLoading = isLoadingProfile || isLoadingCustomProfile;
  const isInvalid = isLoading || !mainForm.formState.isValid;
  const isCustomPristine =
    !isPresetProfile &&
    !isTypeOfAssignmentProfile &&
    battery.toString() === initialBattery.toString();
  const isProfilePristine = isPresetProfile && !mainForm.formState.isDirty;
  const isDisabled = isInvalid || isCustomPristine || isProfilePristine;

  const additionalCheckboxComponent = (
    <ConfigurationDialogAdditionalElement
      checked={dontShowConfirmationDialog}
      onChange={handleCheckboxClick}
    />
  );

  return (
    <Drawer
      testId="battery-sensor-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader text="Edit Sensor" onClose={handleClose} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box>
            {selectedRecordsData && (
              <SelectedRecordInfo selectedRecordsData={selectedRecordsData} />
            )}
            <Box className="pt-6 pb-10 flex-auto">
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                className="justify-left px-6"
              ></Grid>
              <SensorProfileAssignmentForm
                isTypeOfAssignmentProfile={isTypeOfAssignmentProfile}
                form={mainForm}
                SENSOR_FORM_FIELDS={BATTERY_FORM_FIELDS}
                handleAssignmentTypeInputChange={
                  handleAssignmentTypeInputChange
                }
                handleSensorProfileInputChange={handleSensorProfileInputChange}
                isProfileDataLoading={isSensorProfileNamesLoading}
              />
              <Box className="px-6" data-testid="sensors-drawer-battery-slider">
                <Box className="mb-16">
                  <Typography sx={{ fontWeight: "bold" }}>
                    Battery Settings
                  </Typography>
                  {isLightTheme ? (
                    <BatterySensorDark style={svgIconSettings} />
                  ) : (
                    <BatterySensorLight style={svgIconSettings} />
                  )}
                </Box>
                <SensorSlider
                  values={battery}
                  min={MinValuesBySensorType.battery}
                  max={MaxValuesBySensorType.battery}
                  marks={batteryMarks}
                  disabled={isLoading || isTypeOfAssignmentProfile}
                  step={batteryStep}
                  reversed
                  onChange={setBattery}
                />
              </Box>
            </Box>
          </Box>

          <DrawerActions disabled={isLoading} onCancel={handleClose} />
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving..." : "Apply to Selected"}
        disabled={isDisabled}
        testId="battery-sensors-drawer-submit-btn"
        submit={() => onSubmit()}
      />

      {isTypeOfAssignmentProfile && isConfirmProfileAssignmentVisible && (
        <ConfirmationDialog
          title="Profile Assignment"
          message={`Warning, all of the sensor settings of profile ${selectedProfileLabel}
             will be applied to asset(s) ${selectedAssetsIds.join(
               ", "
             )}. Any custom sensor settings will be lost!`}
          open={isConfirmProfileAssignmentVisible}
          confirmButtonText={"Confirm"}
          cancelButtonText="Cancel"
          handleConfirmationResult={handleConfirm}
          additionalContent={additionalCheckboxComponent}
        />
      )}
    </Drawer>
  );
};

export default BatterySensorDrawer;
