import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { TableGridColDef, TableGridData } from "../components/Table";

export const getExportColumns = <T extends TableGridData>(
  apiRef: React.MutableRefObject<GridApiPremium>,
  columns: TableGridColDef<T>[]
) => {
  const { all, columnVisibilityModel } = apiRef.current?.state?.columns || {
    all: [],
    columnVisibilityModel: {},
  };

  return all
    .filter(
      (columnName) =>
        columns.find((column) => column.field === columnName) &&
        columnVisibilityModel[columnName]
    )
    .map((columnName) => {
      const column = columns.find((column) => column.field === columnName)!;
      return {
        disableExport: column.disableExport,
        field: column.field,
        format: column.format,
        label: column.headerName,
      };
    });
};
