import { FC } from "react";
import AirBagSensorDrawer from "./Drawers/AirBag/AirBagSensorDrawer";
import AirSupplySensorDrawer from "./Drawers/AirSupply/SensorsAirSupplyDrawer";
import AtisDrawer from "./Drawers/AtisAlpha/AtisDrawer";
import BatterySensorDrawer from "./Drawers/Battery/SensorsBatteriesDrawer";
import CargoCameraSensorDrawer from "./Drawers/CargoCamera/CargoCameraSensorDrawer";
import LiftgateDrawer from "./Drawers/Liftgate/LiftgateDrawer";
import LiteSentryGammaSensorDrawer from "./Drawers/LiteSentryGamma/LightSentryGammaSensorDrawer";
import PsiWheelEndSensorDrawer from "./Drawers/PsiWheelEnd/PsiWheelEndSensorDrawer";
import TemperatureDrawer from "./Drawers/Temperature/TemperatureDrawer";
import TpmsDrawer from "./Drawers/Tpms/TpmsDrawer";
import { SensorsGatewayTypes } from "./sensorsUtils";

export interface DrawerGatewayProps {
  type: SensorsGatewayTypes;
  currentOrgId: string | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: any[];
}

const drawers: Record<SensorsGatewayTypes, React.FC<any>> = {
  [SensorsGatewayTypes.Voltage]: BatterySensorDrawer,
  [SensorsGatewayTypes.TpmsBeta]: TpmsDrawer,
  [SensorsGatewayTypes.Airbag]: AirBagSensorDrawer,
  [SensorsGatewayTypes.airSupply]: AirSupplySensorDrawer,
  [SensorsGatewayTypes.CargoCamera]: CargoCameraSensorDrawer,
  [SensorsGatewayTypes.PsiWheelEnd]: PsiWheelEndSensorDrawer,
  [SensorsGatewayTypes.AtisAlpha]: AtisDrawer,
  [SensorsGatewayTypes.Internal]: TemperatureDrawer,
  [SensorsGatewayTypes.LiteSentryGamma]: LiteSentryGammaSensorDrawer,
  [SensorsGatewayTypes.Liftgate]: LiftgateDrawer,
};

const SensorsDrawerGateway: FC<DrawerGatewayProps> = ({ type, ...rest }) => {
  const Component = drawers[type];

  return <Component {...rest} />;
};

export default SensorsDrawerGateway;
