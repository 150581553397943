import { FC, MutableRefObject } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as CargoSensorDark } from "../../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLight } from "../../../../../../assets/svgs/cargoSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import SensorHeader from "../../../../../../shared/components/SensorHeader/SensorHeader";
import { useContainerDimensions } from "../../../../../../shared/hooks/useContainerDimensions";
import { calculateBarWidthBaseOnContainer } from "../DoorStatus/utils";
import { BAR_HEIGHT, CargoData, calculateCargoStatusLast30Days } from "./utils";

export interface CargoStatusInLocationsProps {
  data: Array<CargoData>;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

const CargoStatus: FC<CargoStatusInLocationsProps> = ({ data, parentRef }) => {
  const {
    state: { theme },
  } = useAppContext();

  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };

  const { emptyCargoEvents, loadedCargoEvents } =
    calculateCargoStatusLast30Days(data);

  const { width: containerWidth } = useContainerDimensions(parentRef);

  const { barWidthA: loadedBarWidth, barWidthB: unloadedBarWidth } =
    calculateBarWidthBaseOnContainer(
      loadedCargoEvents,
      emptyCargoEvents,
      containerWidth
    );

  return (
    <Box
      className="text-sm w-full inline-block p-4"
      data-testid="last-30-days-loaded-unloaded"
    >
      <SensorHeader title="Cargo Sensor">
        {isLightTheme ? (
          <CargoSensorDark style={svgIconSettings} />
        ) : (
          <CargoSensorLight style={svgIconSettings} />
        )}
      </SensorHeader>

      <Typography
        variant="caption"
        className="!text-lg !font-semibold inline-block pb-4"
        data-testid="battery-widget-label"
      >
        Cargo Status Last 30 Days
      </Typography>
      <Box className="!mb-2 w-full flex flex-no-wrap">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text flex-shrink-0"
        >
          Empty
        </Typography>
        {emptyCargoEvents > 0 && (
          <Box
            data-testid="empty-days"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              color: `var(--${emptyCargoEvents ? "white" : "primary"})`,
              background: "var(--indigo)",
              flexBasis: `${unloadedBarWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {emptyCargoEvents}
          </Box>
        )}
      </Box>

      <Box className="!mb-2 w-full flex flex-no-wrap">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text flex-shrink-0"
        >
          Loaded
        </Typography>
        {loadedCargoEvents > 0 && (
          <Box
            data-testid="loaded-days"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              color: `var(--${loadedCargoEvents ? "white" : "primary"})`,
              background: "var(--indigo)",
              flexBasis: `${loadedBarWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {loadedCargoEvents}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CargoStatus;
