import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../hooks/theme/useCurrentTheme";

export const useTooltipTheme = () => {
  const muiTheme = useCurrentTheme();
  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: "var(--custom-tooltip-background)",
              maxWidth: "none",
              color: "var(--sub-header-text)",
              fontSize: "12px",
              fontWeight: "500",
              padding: "8px 16px",
              borderRadius: "6px",
              filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, .3))",
            },
            arrow: {
              color: "var(--custom-tooltip-background)",
            },
          },
        },
      },
    })
  );
};
