import { useState } from "react";

const TableNoRowsOverlay = () => {
  const [showTextState, setShowTextState] = useState(false);

  /* This is sort of a 'hack' the helps the 'No data found.' message to not 'flash'
    before UI is completely rendered and all other operations are completed.
  */
  setTimeout(() => {
    setShowTextState(true);
  }, 0);

  return (
    <div className="flex h-full w-full items-center justify-center">
      {showTextState && <span>No data found.</span>}
    </div>
  );
};

export default TableNoRowsOverlay;
