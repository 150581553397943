import { useMemo } from "react";
import { formatDate } from "../../utils";

/**
 * Similar to useExportedFileName hook, but with customized organization name.
 * @param orgName asset organization name
 * @param scope Scope of the exported file. E.g. "Assets", "Users", etc.
 */
export default function useExportedFileNameForOrg(
  orgName: string,
  scope: string
) {
  const formattedDate = formatDate(new Date(), "MM-dd-yyyy");
  return useMemo(
    () => `${orgName} ${scope} - ${formattedDate}`.trim(),
    [scope, orgName, formattedDate]
  );
}
