import * as yup from "yup";
import { useAuthContext } from "../../../../../../context/AuthContext";
import { FormModes } from "../../../../../../enums";
import { OrgData } from "../../../../../../graphql/operations";
import { isSuperAdmin } from "../../../../../../shared/components/WithPermissions";
import { EditOrg } from "../../types";

interface OrgValidationSchemaHook {
  orgSchema: yup.ObjectSchema<any>;
}

export const getLevelOneOrgNames = (organizationsList: OrgData[]) => {
  const forbiddenNames: string[] = [];
  organizationsList.forEach((org: OrgData) => {
    const orgStructure = org?.org_key?.split("/") ?? [];
    if (orgStructure.length > 1) {
      forbiddenNames.push(orgStructure[1].trim().toLowerCase());
    }
  });
  return forbiddenNames;
};

export const getForbiddenOrgNames = (organizationsList: OrgData[]) => {
  const forbiddenNames = organizationsList.map((org: OrgData) =>
    org.name.trim().toLowerCase()
  );
  return forbiddenNames;
};

export const useOrgFormValidationSchema = (
  orgToEdit: EditOrg | null,
  organizations: OrgData[],
  isNotAnAdmin: boolean | undefined,
  formMode: FormModes,
  isPhillipsConnectMember?: boolean
): OrgValidationSchemaHook => {
  const { userInfo } = useAuthContext();

  interface FormContext {
    test: (
      name: string,
      message: string,
      validator: (value: string) => boolean | any
    ) => any;
  }
  function validateCompanyName(this: FormContext, message: string) {
    const self = this;
    return self.test(
      "validateCompanyName",
      message,
      function (this: any, value: any) {
        const { path, createError } = this;

        const regEx = /^(?![ /\\])(?!.*[ /\\]$)[^/\\]*$/;
        if (value.includes("/") || !regEx.test(value)) {
          return createError({
            path,
            message:
              "Trailing Spaces, Forward and Backward slashes are not allowed in company name",
          });
        }

        if (!!orgToEdit && orgToEdit.name.trim() === value.trim()) {
          return true;
        }

        const forbiddenOrgnames =
          userInfo && isSuperAdmin(userInfo?.groups)
            ? getLevelOneOrgNames(organizations)
            : getForbiddenOrgNames(organizations);

        if (forbiddenOrgnames.includes(value.trim().toLowerCase())) {
          return createError({
            path,
            message: "Company already exists",
          });
        }

        return true;
      }
    );
  }

  yup.addMethod(yup.mixed, "validateCompanyName", validateCompanyName);

  let baseSchemaObj;
  baseSchemaObj = {
    name: yup
      .string()
      .required("Organization name is required!")
      .validateCompanyName("Company name is invalid"),
    brand: yup
      .mixed()
      .test("isValidBrand", "Brand is required!", function (value) {
        if (isPhillipsConnectMember && !value) {
          return this.createError({ message: "Brand is required!" });
        }
        return true;
      })
      .nullable(),
    time_zones: yup.string().nullable().required("Time zone is required!"),
    distance_unit_preference: yup
      .string()
      .nullable()
      .required("Distance is required!"),
    pressure_unit_preference: yup
      .string()
      .nullable()
      .required("Pressure is required!"),
    temperature_unit_preference: yup
      .string()
      .nullable()
      .required("Temperature is required!"),
    type: yup.string().nullable().required("Type is required!"),
  };
  if (!isNotAnAdmin && formMode !== FormModes.edit) {
    // @ts-ignore
    baseSchemaObj.parent_company = yup
      .string()
      .nullable()
      .required("Parent organization name is required!");
  }
  const orgSchema = yup.object().shape(baseSchemaObj);

  return { orgSchema };
};
