import {
  AssetWithSensorsInput,
  useGetAssetsWithSensorProfilesOsQuery,
  useGetAssetsWithSensorProfilesQuery,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";

export const useGetAssetsWithSensorProfiles = (
  input: AssetWithSensorsInput,
  selectedOrg: string
) => {
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const {
    data: sensorsData,
    isError: isErrorSensor,
    isFetching: isFetchingSensor,
    refetch: refetchAssets,
  } = useGetAssetsWithSensorProfilesQuery(
    {
      input: {
        orgId: input.orgId,
        sensorType: input.sensorType,
        skip: input.skip,
        limit: input.limit,
      },
    },
    { enabled: Boolean(!fetchAssetsFromOpenSearchFeatureFlag && selectedOrg) }
  );
  const {
    data: sensorsDataOS,
    isError: isErrorSensorOS,
    isFetching: isFetchingSensorOS,
    refetch: refetchAssetsOS,
  } = useGetAssetsWithSensorProfilesOsQuery(
    {
      input: {
        orgId: input.orgId,
        sensorType: input.sensorType,
        skip: input.skip,
        limit: input.limit,
      },
    },
    { enabled: Boolean(fetchAssetsFromOpenSearchFeatureFlag && selectedOrg) }
  );

  const data = fetchAssetsFromOpenSearchFeatureFlag
    ? sensorsDataOS?.getAssetsWithSensorProfilesOS
    : sensorsData?.getAssetsWithSensorProfiles;
  const isError = fetchAssetsFromOpenSearchFeatureFlag
    ? isErrorSensorOS
    : isErrorSensor;
  const isFetching = fetchAssetsFromOpenSearchFeatureFlag
    ? isFetchingSensorOS
    : isFetchingSensor;
  const refetch = fetchAssetsFromOpenSearchFeatureFlag
    ? refetchAssetsOS
    : refetchAssets;

  return {
    data,
    isError,
    isFetching,
    refetch,
  };
};
