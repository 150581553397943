import React, { FC, useCallback, useMemo, useState } from "react";
import { useWatch, UseFormReturn } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import sortBy from "lodash/sortBy";
import { useFindUsersQuery } from "../../../../graphql/operations";
import { ChipOption, ChipsList } from "../../../../shared/components/ChipsList";
import { roles } from "../../../../shared/components/SubscribersSelect/constants";
import { EmailsSelectElement } from "../../../../shared/components/react-hook-form-mui/EmailsSelectElement";
import { EscalationSelectElement } from "../../../../shared/components/react-hook-form-mui/EscalationSelectElement";
import { RolesSelectElement } from "../../../../shared/components/react-hook-form-mui/RolesSelectElement";
import { useExternalChipSelectTheme } from "../../../../shared/components/react-hook-form-mui/RolesSelectElement/useExternalChipSelectTheme";
import { UsersSelectElement } from "../../../../shared/components/react-hook-form-mui/UsersSelectElement";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { combineSelectedChips } from "../../../ReportView/helpers/helpers";
import { AlertFormValues } from "../../interfaces";

interface AlertRecipientsProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const AlertRecipients: FC<AlertRecipientsProps> = ({
  form,
}: AlertRecipientsProps) => {
  const parentOrg = useCurrentOrg();

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const chipsTheme = useExternalChipSelectTheme();

  const { data: usersData, isLoading: isDataLoading } = useFindUsersQuery({
    input: {
      customer_orgs_id: parentOrg?._id ? [parentOrg._id] : [],
      active: true,
    },
  });

  const escalationSendSms = useWatch({
    name: "escalationSendSms",
    control: form.control,
    defaultValue: false,
  });

  const { usersOptions, sortedUsersList } = useMemo(() => {
    const users = usersData?.findUsers.data ?? [];

    const usersOptions: ChipOption[] = users.map(
      ({ _id, firstName, lastName, name, user_preferences, phoneNumber }) => ({
        value: _id,
        label: `${firstName} ${lastName} (${name})`,
        showWarning:
          escalationSendSms &&
          (!user_preferences?.alertReceivingMethod?.sms || !phoneNumber),
        // TODO Add !phone_verified_status to the above check when this field will be implemented
      })
    );
    const sortedUsersList = sortBy(users, ({ firstName, lastName }) =>
      `${firstName} ${lastName}`.toLowerCase()
    );

    return { usersOptions, sortedUsersList };
  }, [usersData, escalationSendSms]);

  const watchPause = useWatch({
    name: "reminder.schedule.suspended",
    control: form.control,
  });
  const watchEmails = useWatch({
    name: "reminder.subscribers.emails",
    control: form.control,
  });

  const emailsOptions = watchEmails?.map((email: string) => ({
    value: email,
    label: email,
  }));

  const handleAccordionChange = useCallback(() => {
    setIsAccordionExpanded(!isAccordionExpanded);
  }, [isAccordionExpanded, setIsAccordionExpanded]);

  const formValues = form.getValues();

  const combinedSubscribersList = useMemo(() => {
    return [...usersOptions, ...roles, ...emailsOptions];
  }, [usersOptions, emailsOptions]);
  const combinedSelectedChipsList = combineSelectedChips(
    formValues.reminder?.subscribers
  );

  return (
    <Grid container item xs={12}>
      <Paper elevation={0} className="w-full">
        <Box className="p-12">
          <Accordion
            className="w-full"
            expanded={isAccordionExpanded}
            onChange={handleAccordionChange}
            data-testid="report-recipients-accordion"
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="!px-0 !py-0"
              data-testid="report-recipients-accordion-summary"
            >
              <Typography
                className="!pb-6 !text-lg"
                variant="h6"
                component="div"
              >
                Recipients
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="!p-0 !mt-12">
              <RolesSelectElement
                form={form}
                loading={false}
                label="Roles"
                control={form.control}
                name="reminder.subscribers.roles"
              />
              <UsersSelectElement
                form={form}
                loading={isDataLoading}
                label="Users"
                control={form.control}
                name="reminder.subscribers.users"
                usersData={sortedUsersList}
                users={usersOptions}
              />
              <EmailsSelectElement
                loading={isDataLoading}
                label="Emails"
                control={form.control}
                name="reminder.subscribers.emails"
              />
              <UsersSelectElement
                form={form}
                loading={isDataLoading}
                label="Escalation Users"
                control={form.control}
                name="escalationUsers"
                usersData={sortedUsersList}
                users={usersOptions}
                isEscalation
                disabled={watchPause ?? false}
                checkboxName="escalationSendSms"
                checkboxLabel={
                  <Typography>
                    SMS Notifications{" "}
                    <Typography
                      sx={{
                        color: "var(--light-charcoal)",
                        display: "inline-block",
                      }}
                    >
                      (The SMS Notifications may take extra charge)
                    </Typography>
                  </Typography>
                }
              />
              <EscalationSelectElement
                name="escalationTimeSummary"
                control={form.control}
                label="Escalate After"
                form={form}
                disabled={watchPause ?? false}
              />
            </AccordionDetails>
          </Accordion>
          {!isAccordionExpanded && (
            <ThemeProvider theme={chipsTheme}>
              <ChipsList
                selectedChips={combinedSelectedChipsList}
                chips={combinedSubscribersList}
                compact={false}
              />
            </ThemeProvider>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};
