import { useMemo } from "react";
import {
  Autocomplete,
  Box,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import {
  AlertV2,
  ConfigurationSet,
  ConfigurationSetType,
  GeofenceData,
  useFindAlertsV2Query,
  useFindGeofencesQuery,
  useGetConfigurationSetsQuery,
} from "../../../../graphql/operations";
import usePopover from "../../../../shared/components/DateRangePicker/hooks/usePopover";
import { useAssetTypes } from "../../../../shared/hooks/useAssetTypes";
import { useSelectedOrg } from "../../../../shared/hooks/useSelectedOrg";
import { FormFieldDropdownOption } from "../../../../types";
import {
  NOMENCLATURE_NAMES,
  useGeofenceNameOptions,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { useAlertFiltersContext } from "./AlertFiltersContext";

const filtersMenuWidth = "360px";
const filtersMenuHeight = "500px";

const fieldDesign = {
  lineHeight: "16px",
  fontSize: "10px",
};

export const AlertIncidentFilters: React.FC = () => {
  const {
    isPopoverOpen,
    popoverId,
    popoverAnchorElement,
    openPopover,
    closePopover,
  } = usePopover("alert-filters-menu");
  const selectedOrg = useSelectedOrg();

  const { data: allGeofencesData, isLoading: isLoadingGeofenceData } =
    useFindGeofencesQuery({ input: {} }, { enabled: true });

  const geofenceNameOptions = useGeofenceNameOptions(
    (allGeofencesData?.findGeofences?.data ?? []) as GeofenceData[]
  );

  const { assetTypeOptionsState: assetTypeOptions } = useAssetTypes();

  const {
    data: alertsQuery,
    isLoading: findAlertsLoading,
    isSuccess: findAlertsSuccess,
  } = useFindAlertsV2Query(
    {
      input: {
        orgId: selectedOrg?._id,
      },
    },
    {
      enabled: Boolean(selectedOrg?._id),
    }
  );

  const alertNameOptions = useMemo(() => {
    if (findAlertsSuccess) {
      return (
        alertsQuery?.findAlertsV2?.alerts?.map((alert: AlertV2) => ({
          value: alert?._id,
          label: alert?.name,
        })) ?? []
      );
    }
  }, [alertsQuery?.findAlertsV2?.alerts, findAlertsSuccess]);

  const alertTypesOptions =
    useNomenclatures(NOMENCLATURE_NAMES.alertType) ?? [];

  const { data: dataGeofenceTypes, isFetching: isFetchingGeofenceTypes } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: selectedOrg?._id ?? "",
          type: ConfigurationSetType.GeofenceType,
        },
      },
      { enabled: Boolean(selectedOrg?._id) }
    );

  const geofenceTypesOps = useMemo(() => {
    if (!isFetchingGeofenceTypes) {
      return (
        dataGeofenceTypes?.getConfigurationSets?.map(
          (item: ConfigurationSet) => ({
            value: item._id,
            label: item.name,
          })
        ) ?? []
      );
    }
  }, [dataGeofenceTypes?.getConfigurationSets, isFetchingGeofenceTypes]);

  // get filter values from context
  const {
    alertFilters: {
      alertTypes,
      alertName,
      assetTypes,
      geofenceTypes,
      geofences,
    },
    totalFiltersActive,
    changeAlertFilters,
  } = useAlertFiltersContext();

  return (
    <>
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "16px",
          fontWeight: 700,
          color: "var(--brand)",
          cursor: "pointer",
          paddingX: "1rem",
          paddingY: "4px",
          borderRight: "2px solid var(--autocomplete-highlight)",
          borderLeft: "2px solid var(--autocomplete-highlight)",
        }}
        onClick={openPopover}
      >
        Filters {totalFiltersActive ? `+${totalFiltersActive}` : ""}
      </Typography>

      <Popover
        id={popoverId ?? undefined}
        anchorEl={popoverAnchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          overflow: "hidden",
          overflowY: "scroll",
          // move manually slightly so it's more consistent with design
          left: "50px",
          "& .MuiPopover-paper ": {
            height: filtersMenuHeight,
            padding: "16px",
          },
        }}
        onClose={closePopover}
        open={isPopoverOpen}
        data-testid="modal"
      >
        <Box width={filtersMenuWidth}>
          <Box>
            <Box className="font-bold text-[14px] leading-4">Alert Types</Box>
            <Autocomplete
              data-testid="alerts-alert__types"
              limitTags={3}
              multiple
              sx={fieldDesign}
              options={alertTypesOptions ?? []}
              value={alertTypes}
              isOptionEqualToValue={(o, v) => o?.value === v?.value}
              onChange={(_, value) => {
                changeAlertFilters({ alertTypes: value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Alert Types"
                  data-testid="alert-types-input"
                />
              )}
            />
          </Box>
          <Box marginTop="32px">
            <Box className="font-bold text-[14px] leading-4">Alert Name</Box>
            <Autocomplete
              data-testid="alerts-alert__name"
              options={alertNameOptions ?? []}
              fullWidth
              sx={fieldDesign}
              loading={findAlertsLoading}
              disabled={findAlertsLoading}
              value={alertName}
              isOptionEqualToValue={(o, v) => o?.value === v?.value}
              onChange={(_, value) => {
                changeAlertFilters({ alertName: value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Alert Name"
                  data-testid="alert-name-input"
                />
              )}
            />
          </Box>
          <Box marginTop="32px">
            <Box className="font-bold text-[14px] leading-4">Asset Types</Box>
            <Autocomplete
              data-testid="alerts-assets__types"
              options={assetTypeOptions ?? []}
              limitTags={3}
              multiple
              sx={fieldDesign}
              fullWidth
              loading={Boolean(!assetTypeOptions?.length)}
              value={assetTypes}
              isOptionEqualToValue={(o, v) => o?.value === v?.value}
              onChange={(_, value) => {
                changeAlertFilters({ assetTypes: value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Asset Types"
                  data-testid="asset-type-input"
                />
              )}
            />
          </Box>
          <Box marginTop="32px">
            <Box className="font-bold text-[14px] leading-4">
              Geofence Types
            </Box>
            <Autocomplete
              data-testid="alerts-geofence__types"
              options={geofenceTypesOps ?? []}
              limitTags={3}
              multiple
              sx={fieldDesign}
              fullWidth
              loading={isFetchingGeofenceTypes}
              disabled={isFetchingGeofenceTypes}
              value={geofenceTypes}
              isOptionEqualToValue={(o, v) => o?.value === v?.value}
              onChange={(_, value) => {
                changeAlertFilters({ geofenceTypes: value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Geofence Types"
                  data-testid="geofence-types-input"
                />
              )}
            />
          </Box>
          <Box marginTop="32px">
            <Box className="font-bold text-[14px] leading-4">
              Geofence Names
            </Box>
            <Autocomplete
              data-testid="alerts-geofence__names"
              sx={fieldDesign}
              options={geofenceNameOptions ?? []}
              limitTags={3}
              multiple
              fullWidth
              loading={isLoadingGeofenceData}
              disabled={isLoadingGeofenceData}
              value={geofences}
              isOptionEqualToValue={(o, v) => o?.id === v?.id}
              onChange={(_, value) => {
                changeAlertFilters({ geofences: value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Geofence Names"
                  data-testid="geofence-name-input"
                />
              )}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};
