import { FieldValues, UseFormReturn } from "react-hook-form";
import { AlertTypeV2 } from "../../../graphql/operations";
import { useATISNotFunctioningForm } from "./useATISNotFunctioningForm";
import { useAlertABSFaultForm } from "./useAlertABSFaultForm";
import { useAlertATISForm } from "./useAlertATISForm";
import { useAlertAirTankForm } from "./useAlertAirTankForm";
import { useAlertCargoForm } from "./useAlertCargoForm";
import { useAlertCustomForm } from "./useAlertCustomForm";
import { useAlertDoorOpenOutsideOfGeofenceForm } from "./useAlertDoorOpenOutsideOfGeofenceForm";
import { useAlertDwellForm } from "./useAlertDwellForm";
import { useAlertGeofenceForm } from "./useAlertGeofenceForm";
import { useAlertLightCircuitForm } from "./useAlertLightCircuitForm";
import { useAlertMetricsForm } from "./useAlertMetricsForm";
import { useAlertMovingWithoutPrimOrSecPowerForm } from "./useAlertMovingWithoutPrimOrSecPowerForm";
import { useAlertReeferForm } from "./useAlertReeferForm";
import { useAlertRegulatorForm } from "./useAlertRegulatorForm";
import { useAlertSensorHealthForm } from "./useAlertSensorHealthForm";
import { useAlertSpeedForm } from "./useAlertSpeedForm";
import { useAlertTPMSForm } from "./useAlertTPMSForm";
import { useAlertTemperatureForm } from "./useAlertTemperatureForm";
import { useAlertTpmsCatastrophicForm } from "./useAlertTpmsCatastrophic";
import { useAlertWheelEndForm } from "./useAlertWheelEndForm";
import { useDoorOpenMovingForm } from "./useDoorOpenMovingForm";

type FormReturnType = {
  schema: any;
  form: UseFormReturn<FieldValues, any, undefined>;
  getValues: () => any;
};

export const useCurrentForm = (
  type: AlertTypeV2,
  initialValues: any
): FormReturnType => {
  const {
    form: alertGeofenceForm,
    schema: alertGeofenceSchema,
    getValues: alertGeofenceGetValues,
  } = useAlertGeofenceForm(initialValues);
  const {
    form: alertCargoForm,
    schema: alertCargoSchema,
    getValues: alertCargoGetValues,
  } = useAlertCargoForm(initialValues);
  const {
    form: doorOpenMovingForm,
    schema: doorOpenMovingSchema,
    getValues: doorOpenMovingValues,
  } = useDoorOpenMovingForm(initialValues);
  const {
    form: atisNotFunctioningForm,
    schema: atisNotFunctioningSchema,
    getValues: atisNotFunctioningValues,
  } = useATISNotFunctioningForm(initialValues);
  const {
    form: alertTPMSForm,
    schema: alertTPMSSchema,
    getValues: alertTPMSGetValues,
  } = useAlertTPMSForm(initialValues);
  const {
    form: alertAirTankForm,
    schema: alertAirTankSchema,
    getValues: alertAirTankGetValues,
  } = useAlertAirTankForm(initialValues);
  const {
    form: alertATISForm,
    schema: alertATISSchema,
    getValues: alertATISGetValues,
  } = useAlertATISForm(initialValues);
  const {
    form: alertDwellForm,
    schema: alertDwellSchema,
    getValues: alertDwellGetValues,
  } = useAlertDwellForm(initialValues);
  const {
    form: alertCustomForm,
    schema: alertCustomSchema,
    getValues: alertCustomGetValues,
  } = useAlertCustomForm(initialValues);
  const {
    form: alertSensorHealthForm,
    schema: alertSensorHealthSchema,
    getValues: alertSensorHealthGetValues,
  } = useAlertSensorHealthForm(initialValues);
  const {
    form: alertMovingWithoutPrimOrSecPowerForm,
    schema: alertMovingWithoutPrimOrSecPowerSchema,
    getValues: alertMovingWithoutPrimOrSecPowerGetValues,
  } = useAlertMovingWithoutPrimOrSecPowerForm(initialValues);
  const {
    form: alertSpeedForm,
    schema: alertSpeedSchema,
    getValues: alertSpeedGetValues,
  } = useAlertSpeedForm(initialValues);
  const {
    form: alertWheelEndForm,
    schema: alertWheelEndSchema,
    getValues: alertWheelEndGetValues,
  } = useAlertWheelEndForm(initialValues);
  const {
    form: alertTpmsCatastrophicForm,
    schema: alertTpmsCatastrophicSchema,
    getValues: alertTpmsCatastrophicGetValues,
  } = useAlertTpmsCatastrophicForm(initialValues);
  const {
    form: lightCircuitForm,
    schema: lightCircuitSchema,
    getValues: lightCircuitGetValues,
  } = useAlertLightCircuitForm(initialValues);
  const {
    form: alertDoorOpenOutsideOfGeofenceForm,
    schema: alertDoorOpenOutsideOfGeofenceSchema,
    getValues: alertDoorOpenOutsideOfGeofenceGetValues,
  } = useAlertDoorOpenOutsideOfGeofenceForm(initialValues);
  const {
    form: alertMetricsForm,
    schema: alertMetricsSchema,
    getValues: alertGetValues,
  } = useAlertMetricsForm(initialValues);
  const {
    form: alertRegulatorForm,
    schema: alertRegulatorSchmea,
    getValues: alertRegulatorGetValues,
  } = useAlertRegulatorForm(initialValues);
  const {
    form: alertReeferForm,
    schema: alertReeferSchema,
    getValues: alertReeferGetValues,
  } = useAlertReeferForm(initialValues);
  const {
    form: alertABSFaultForm,
    schema: alertABSFaultSchema,
    getValues: alertABSFaultGetValues,
  } = useAlertABSFaultForm(initialValues);
  const {
    form: alertTemperatureForm,
    schema: alertTemperatureSchema,
    getValues: alertTemperatureGetValues,
  } = useAlertTemperatureForm(initialValues);
  switch (type) {
    case AlertTypeV2.Geofence:
      return {
        schema: alertGeofenceSchema,
        form: alertGeofenceForm,
        getValues: alertGeofenceGetValues,
      };
    case AlertTypeV2.Cargo:
      return {
        schema: alertCargoSchema,
        form: alertCargoForm,
        getValues: alertCargoGetValues,
      };
    case AlertTypeV2.TirePressureMonitorSystem:
      return {
        schema: alertTPMSSchema,
        form: alertTPMSForm,
        getValues: alertTPMSGetValues,
      };
    case AlertTypeV2.AirTank:
      return {
        schema: alertAirTankSchema,
        form: alertAirTankForm,
        getValues: alertAirTankGetValues,
      };
    case AlertTypeV2.Atis:
      return {
        schema: alertATISSchema,
        form: alertATISForm,
        getValues: alertATISGetValues,
      };
    case AlertTypeV2.Dwell:
      return {
        schema: alertDwellSchema,
        form: alertDwellForm,
        getValues: alertDwellGetValues,
      };
    case AlertTypeV2.Custom:
      return {
        schema: alertCustomSchema,
        form: alertCustomForm,
        getValues: alertCustomGetValues,
      };
    case AlertTypeV2.SensorHealth:
      return {
        schema: alertSensorHealthSchema,
        form: alertSensorHealthForm,
        getValues: alertSensorHealthGetValues,
      };
    case AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower:
      return {
        schema: alertMovingWithoutPrimOrSecPowerSchema,
        form: alertMovingWithoutPrimOrSecPowerForm,
        getValues: alertMovingWithoutPrimOrSecPowerGetValues,
      };
    case AlertTypeV2.Speeding:
      return {
        schema: alertSpeedSchema,
        form: alertSpeedForm,
        getValues: alertSpeedGetValues,
      };
    case AlertTypeV2.WheelEnd:
      return {
        schema: alertWheelEndSchema,
        form: alertWheelEndForm,
        getValues: alertWheelEndGetValues,
      };
    case AlertTypeV2.TirePressureMonitorSystemCatastrophic:
      return {
        schema: alertTpmsCatastrophicSchema,
        form: alertTpmsCatastrophicForm,
        getValues: alertTpmsCatastrophicGetValues,
      };
    case AlertTypeV2.LightCircuit:
      return {
        schema: lightCircuitSchema,
        form: lightCircuitForm,
        getValues: lightCircuitGetValues,
      };

    case AlertTypeV2.DoorOpenOutsideOfGeofence:
      return {
        schema: alertDoorOpenOutsideOfGeofenceSchema,
        form: alertDoorOpenOutsideOfGeofenceForm,
        getValues: alertDoorOpenOutsideOfGeofenceGetValues,
      };
    case AlertTypeV2.Reefer:
      return {
        schema: alertReeferSchema,
        form: alertReeferForm,
        getValues: alertReeferGetValues,
      };
    case AlertTypeV2.DoorOpenAndMoving:
      return {
        schema: doorOpenMovingSchema,
        form: doorOpenMovingForm,
        getValues: doorOpenMovingValues,
      };
    case AlertTypeV2.AtisNotFunctioning:
      return {
        schema: atisNotFunctioningSchema,
        form: atisNotFunctioningForm,
        getValues: atisNotFunctioningValues,
      };
    case AlertTypeV2.Metrics:
      return {
        schema: alertMetricsSchema,
        form: alertMetricsForm,
        getValues: alertGetValues,
      };
    case AlertTypeV2.Regulator:
      return {
        schema: alertRegulatorSchmea,
        form: alertRegulatorForm,
        getValues: alertRegulatorGetValues,
      };
    case AlertTypeV2.AbsFault:
      return {
        schema: alertABSFaultSchema,
        form: alertABSFaultForm,
        getValues: alertABSFaultGetValues,
      };
    case AlertTypeV2.Temperature:
      return {
        schema: alertTemperatureSchema,
        form: alertTemperatureForm,
        getValues: alertTemperatureGetValues,
      };
    default:
      return {} as FormReturnType;
  }
};
