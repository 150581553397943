import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NavigationRoutes } from "../../../../../utils/routes/routesUtils";
import {
  Feature,
  useAssetsDataContext,
} from "../../../shared/AssetsDataContext";
import { isNilOrEmpty } from "../../../utils";

interface UseClickHandlersProps {
  googleMap: google.maps.Map | null;
  setFocusedFeature: (feature: Feature) => void;
}
export const useClickHandlers = ({
  googleMap,
  setFocusedFeature,
}: UseClickHandlersProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setSelectedFeature, setSelectedAssetId } = useAssetsDataContext();

  const onMarkerClick = useCallback(
    (feature: Feature) => {
      setSelectedFeature(feature);
      setFocusedFeature(feature);
      setSelectedAssetId(feature.properties.id as string);
      navigate(
        `${NavigationRoutes.AssetTable}/${feature.properties.id}${
          !isNilOrEmpty(searchParams.toString())
            ? `?${searchParams.toString()}`
            : ""
        }`
      );
    },
    [
      searchParams,
      navigate,
      setFocusedFeature,
      setSelectedAssetId,
      setSelectedFeature,
    ]
  );

  const onClusterClick = (feature: Feature) => {
    const currentZoom = googleMap?.getZoom() ?? 0;
    const coords = {
      lat: feature.geometry.coordinates[1],
      lng: feature.geometry.coordinates[0],
    };
    googleMap?.panTo(coords);
    googleMap?.setZoom(currentZoom + 2);
  };
  return {
    onMarkerClick,
    onClusterClick,
  };
};
