import {
  ASSET_SEARCH,
  ASSET_TARGET,
  ASSET_TYPE,
  ASSET_SEARCH_HISTORY,
  ASSET_PARTIAL_SEARCH,
  ASSET_TYPE_RESET,
  ASSET_SEARCH_RESET,
  ASSET_TARGET_RESET,
  ASSET_PARTIAL_SEARCH_RESET,
} from "../../constants";
import { Action } from "../commonTypes";

export type SearchHistoryType = {
  id?: string;
  name?: string;
  matchedAgainst?: string;
};

export type AssetSearchReducerInitialState = {
  searchText: string;
  partialSearch: string | null;
  searchAssetId: string | null;
  searchType: string | null;
  searchHistory: SearchHistoryType[];
};

export type AssetSearchReducerType = (
  state: AssetSearchReducerInitialState | undefined,
  action: Action
) => AssetSearchReducerInitialState;

export const initialAssetSearchState: AssetSearchReducerInitialState = {
  searchText: "",
  partialSearch: "",
  searchAssetId: null,
  searchType: null,
  searchHistory: [],
};

export const AssetSearchReducer = (
  state = initialAssetSearchState,
  action: Action
) => {
  switch (action.type) {
    case ASSET_SEARCH:
      return {
        ...state,
        searchText: action.payload,
      };
    case ASSET_PARTIAL_SEARCH:
      return {
        ...state,
        partialSearch: action.payload,
      };
    case ASSET_TARGET:
      return {
        ...state,
        searchAssetId: action.payload,
      };
    case ASSET_TYPE:
      return {
        ...state,
        searchType: action.payload,
      };
    case ASSET_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: [...state.searchHistory, action.payload],
      };
    case ASSET_SEARCH_RESET:
      return {
        ...state,
        searchText: initialAssetSearchState.searchText,
      };
    case ASSET_PARTIAL_SEARCH_RESET:
      return {
        ...state,
        partialSearch: initialAssetSearchState.partialSearch,
      };
    case ASSET_TARGET_RESET:
      return {
        ...state,
        searchAssetId: initialAssetSearchState.searchAssetId,
      };
    case ASSET_TYPE_RESET:
      return {
        ...state,
        searchType: initialAssetSearchState.searchType,
      };
    default:
      return state;
  }
};
