import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { sortBy, isNil, omitBy } from "lodash";
import * as yup from "yup";
import { BatchTitles } from "../../constants/batches";
import { OrgData } from "../../graphql/operations";
import { DATE_FORMAT_GLUED, formatDate, transformers } from "../../utils";

export enum BatchFieldTypes {
  File = "file",
  Text = "text",
  SingleSelect = "singleSelect",
}

export enum BatchFormFieldsNames {
  BatchName = "batch_name",
  AddToOrganization = "org_select",
  ParentOrganization = "parent_org",
  AssignProduct = "product_select",
  UploadFile = "file_input",
}

export type BatchUploadDialogProps = {
  title: BatchTitles;
  customerOrg?: string;
  dialogFields: BatchFormFieldsTypes;
  downloadTemplateFilePath?: string;
  isOpen: boolean;
  onClose: () => void;
  onUpload?: () => void;
};

export type BatchFormFieldsTypes = {
  [BatchFormFieldsNames.BatchName]: {
    required: boolean;
    type: BatchFieldTypes.Text;
    label: string;
    name: string;
    visible: Array<BatchTitles>;
  };
  [BatchFormFieldsNames.ParentOrganization]: {
    required: boolean;
    type: BatchFieldTypes.SingleSelect;
    label: string;
    name: string;
    visible: Array<BatchTitles>;
    values: Array<{ id: string; label: string }>;
  };
  [BatchFormFieldsNames.AddToOrganization]: {
    required: boolean;
    type: BatchFieldTypes.SingleSelect;
    label: string;
    name: string;
    visible: Array<BatchTitles>;
    values: Array<{ id: string; label: string }>;
  };
  [BatchFormFieldsNames.AssignProduct]: {
    required: boolean;
    type: BatchFieldTypes.SingleSelect;
    label: string;
    name: string;
    visible: Array<BatchTitles>;
    values: Array<{ id: string; label: string }>;
  };
  [BatchFormFieldsNames.UploadFile]: {
    required: boolean;
    type: BatchFieldTypes.File;
    label: string;
    name: string;
    visible: Array<BatchTitles>;
  };
};

export const BATCH_FORM_FIELDS: BatchFormFieldsTypes = {
  [BatchFormFieldsNames.BatchName]: {
    required: true,
    type: BatchFieldTypes.Text,
    label: "Batch Name",
    name: "batch_name",
    visible: Object.values(BatchTitles).map((item) => item),
  },
  [BatchFormFieldsNames.AddToOrganization]: {
    required: true,
    type: BatchFieldTypes.SingleSelect,
    label: "Add to Organization",
    name: "org_select",
    visible: Object.values(BatchTitles).filter(
      (item) => item !== BatchTitles.CreateUpdateOrganizations
    ),
    values: [],
  },
  [BatchFormFieldsNames.ParentOrganization]: {
    required: true,
    type: BatchFieldTypes.SingleSelect,
    label: "Parent Organization Name",
    name: "parent_org",
    visible: Object.values(BatchTitles).filter(
      (item) => item === BatchTitles.CreateUpdateOrganizations
    ),
    values: [],
  },
  [BatchFormFieldsNames.AssignProduct]: {
    required: true,
    type: BatchFieldTypes.SingleSelect,
    label: "Assign Product",
    name: "product_select",
    visible: [],
    values: [],
  },
  [BatchFormFieldsNames.UploadFile]: {
    required: true,
    type: BatchFieldTypes.File,
    label: "Upload File",
    name: "file_input",
    visible: Object.values(BatchTitles).map((item) => item),
  },
};

export const batchUploadFormSchema = yup.object().shape({
  [BatchFormFieldsNames.BatchName]: yup
    .string()
    .required("Batch Name is required")
    .transform(transformers.string),
  [BatchFormFieldsNames.AddToOrganization]: yup
    .object()
    .required("Company Name is required")
    .transform(transformers.string),
});

export const useBatchUploadForm = (action: string, orgName?: string) => {
  const batch_name = orgName
    ? `${orgName} ${action} - ${formatDate(new Date(), DATE_FORMAT_GLUED)}`
    : "";
  const form = useForm({
    resolver: yupResolver(batchUploadFormSchema),
    values: omitBy(
      {
        [BatchFormFieldsNames.BatchName]: batch_name,
        [BatchFormFieldsNames.AddToOrganization]: "",
      },
      isNil
    ),
  });

  const getValues = useCallback(
    () => batchUploadFormSchema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues, batchUploadFormSchema };
};

export const mapOrgs = (orgs: OrgData[]) => {
  return sortBy(
    [...orgs].map((org) => ({
      id: org._id,
      label: org.name,
    })),
    "label"
  );
};
