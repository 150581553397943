import { useMemo } from "react";
import { OrgData } from "../../graphql/operations";
import { useUserData } from "./useUserData";

/**
 * Returns the current organization object
 */
export const useCurrentOrg = (): OrgData | undefined => {
  const userData = useUserData();
  return useMemo(() => userData?.customerOrg, [userData]);
};
