import { useMemo } from "react";
import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";

export const useAirBagTheme = () => {
  const muiTheme = useCurrentTheme();
  const theme = useMemo(
    () =>
      createTheme(
        deepmerge(muiTheme, {
          components: {
            MuiTextField: {
              defaultProps: {
                variant: "outlined",
              },
            },
            MuiFormHelperText: {
              defaultProps: {
                variant: "outlined",
              },
            },
            MuiInputLabel: {
              defaultProps: {
                variant: "outlined",
              },
            },
          },
        })
      ),
    [muiTheme]
  );
  return theme;
};
