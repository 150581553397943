import { FC } from "react";
import { SensorStatusUppercase } from "../../../../../../graphql/operations";

interface AirBagStatusProps {
  status: SensorStatusUppercase;
}

const AirBagStatusWrapperImg: FC<AirBagStatusProps> = ({ status }) => {
  const renderIcon = (airBagState: SensorStatusUppercase) => {
    switch (airBagState) {
      case SensorStatusUppercase.Healthy:
        return (
          <path
            d="M75.0001 51.1666C67.6401 51.1666 61.6667 57.14 61.6667 64.5C61.6667 71.86 67.6401 77.8333 75.0001 77.8333C82.3601 77.8333 88.3334 71.86 88.3334 64.5C88.3334 57.14 82.3601 51.1666 75.0001 51.1666ZM75.0001 75.1666C69.1201 75.1666 64.3334 70.38 64.3334 64.5C64.3334 58.62 69.1201 53.8333 75.0001 53.8333C80.8801 53.8333 85.6667 58.62 85.6667 64.5C85.6667 70.38 80.8801 75.1666 75.0001 75.1666ZM81.1201 58.6066L72.3334 67.3933L68.8801 63.9533L67.0001 65.8333L72.3334 71.1666L83.0001 60.5L81.1201 58.6066Z"
            fill="#339933"
          />
        );
      case SensorStatusUppercase.Warning:
        return (
          <path
            d="M75 57.1534L85.04 74.5H64.96L75 57.1534ZM75 51.8334L60.3333 77.1667H89.6666L75 51.8334ZM76.3333 70.5H73.6666V73.1667H76.3333V70.5ZM76.3333 62.5H73.6666V67.8334H76.3333V62.5Z"
            fill="#E78D3A"
          />
        );
      case SensorStatusUppercase.Alert:
        return (
          <path
            d="M75 57.1534L85.04 74.5H64.96L75 57.1534ZM75 51.8334L60.3333 77.1667H89.6666L75 51.8334ZM76.3333 70.5H73.6666V73.1667H76.3333V70.5ZM76.3333 62.5H73.6666V67.8334H76.3333V62.5Z"
            fill="#E78D3A"
          />
        );
      case SensorStatusUppercase.Critical:
        return (
          <path
            d="M75 57.1534L85.04 74.5H64.96L75 57.1534ZM75 51.8334L60.3333 77.1667H89.6666L75 51.8334ZM76.3333 70.5H73.6666V73.1667H76.3333V70.5ZM76.3333 62.5H73.6666V67.8334H76.3333V62.5Z"
            fill="#E78D3A"
          />
        );
      default:
        return null;
    }
  };

  return (
    <svg
      width="150"
      height="126"
      viewBox="0 0 150 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="16.5" width="146" height="96" rx="14" fill="white" />
      <rect
        x="2"
        y="16.5"
        width="146"
        height="96"
        rx="14"
        stroke="#9C9C9C"
        strokeWidth="4"
      />
      {renderIcon(status)}
      <rect x="28" y="2" width="96" height="26" rx="6" fill="white" />
      <rect
        x="28"
        y="2"
        width="96"
        height="26"
        rx="6"
        stroke="#9C9C9C"
        strokeWidth="4"
      />
      <rect x="28" y="98" width="96" height="26" rx="6" fill="white" />
      <rect
        x="28"
        y="98"
        width="96"
        height="26"
        rx="6"
        stroke="#9C9C9C"
        strokeWidth="4"
      />
    </svg>
  );
};

export default AirBagStatusWrapperImg;
