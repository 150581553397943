import { useState, useEffect } from "react";
import { formatDistanceToNowStrictCustom } from "../../../../../../../utils/date";
import { getLastEventChipColor } from "../../helpers";

export interface LastEventChipProps {
  lastEvent: Date;
}

export const LastEventChip: React.FC<LastEventChipProps> = ({ lastEvent }) => {
  const [chipColor, setChipColor] = useState<string>("");

  useEffect(() => {
    const chipColor = getLastEventChipColor(lastEvent);
    setChipColor(chipColor);
  }, [lastEvent]);

  return (
    <div
      className={`whitespace-nowrap py-[2px] px-[4px] rounded-[4px] text-2xs text-white ${chipColor}`}
    >
      <span className="leading-none">
        {formatDistanceToNowStrictCustom(lastEvent)}
      </span>
    </div>
  );
};
