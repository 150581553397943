import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton } from "@mui/material";

interface ThumbnailModalProps {
  imageUrl: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ThumbnailModal: FC<ThumbnailModalProps> = ({
  imageUrl,
  isOpen,
  onClose,
}) => (
  <Dialog open={isOpen} onClose={onClose} maxWidth="xl">
    <Box
      sx={{
        backgroundColor: "var(--dashboard_subheader__bg)",
        padding: "20px 20px 20px 20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="!ml-auto !mb-2 h-10 w-10"
          data-testid="close-modal-btn"
          sx={{
            color: "var(--app_header_rev__bg)",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <div>
        <img
          data-testid="image-thumbnail"
          alt="Thumbnail"
          src={imageUrl}
          style={{
            border: 0,
            display: "block",
            outline: "none",
            textDecoration: "none",
            height: "auto",
            width: "700px",
            fontSize: "13px",
          }}
        />
      </div>
    </Box>
  </Dialog>
);
