import { useMemo } from "react";
import { GridSortModel } from "@mui/x-data-grid-premium";
import { QueryObserverResult } from "@tanstack/react-query";
import {
  AssetEventType,
  FilterListInput,
  FindAssetActivityHistoryQuery,
  Pagination,
  SortOrder,
  useFindAssetActivityHistoryQuery,
} from "../../../../../graphql/operations";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { TableAssetEvent } from "./types";
import { mapAssetEventDescription, mapAssetEventName } from "./utils";

interface AssetEventsHook {
  assetEvents: TableAssetEvent[];
  pagination: Pagination | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => Promise<QueryObserverResult<FindAssetActivityHistoryQuery>>;
}

export interface UseAssetEventsProps {
  selectedAssetId: string;
  filters: FilterListInput | null;
  paginationSkip: number;
  activityLogsPerPage: number;
  sortModel: GridSortModel;
  initialSortModel: GridSortModel;
  searchValue: string;
  selectedAssetOrgName: string;
}

const useAssetEvents = ({
  selectedAssetId,
  filters,
  paginationSkip,
  activityLogsPerPage,
  sortModel,
  initialSortModel,
  searchValue,
  selectedAssetOrgName,
}: UseAssetEventsProps): AssetEventsHook => {
  const availableOrgs = useAvailableOrgs();

  const { data, isLoading, isSuccess, refetch } =
    useFindAssetActivityHistoryQuery(
      {
        input: {
          assetId: selectedAssetId,
          filterList: filters?.filters ? [filters] : null,
          pagination: { skip: paginationSkip, limit: activityLogsPerPage },
          sorting: [
            {
              field: sortModel[0]?.field ?? initialSortModel[0].field,
              order: (sortModel[0]?.sort ??
                initialSortModel[0].sort) as SortOrder,
            },
          ],
          searchText: searchValue,
        },
      },
      {
        enabled: !!selectedAssetOrgName && !!selectedAssetId,
      }
    );
  const eventsData = useMemo(
    () => data?.findAssetActivityHistory?.data ?? [],
    [data]
  );

  const assetEvents = useMemo(
    () =>
      eventsData.map((eventItem) => ({
        _id: eventItem._id,
        event: mapAssetEventName(eventItem.event.type as AssetEventType),
        description: mapAssetEventDescription(
          eventItem.event.type,
          eventItem.event.data ?? {},
          availableOrgs,
          eventItem.event.batchName
        ),
        user: eventItem.event.userFullName,
        eventDate: eventItem.updatedAt,
      })),
    [eventsData, availableOrgs]
  );

  return {
    assetEvents,
    pagination: data?.findAssetActivityHistory?.pagination,
    isLoading,
    isSuccess,
    refetch,
  };
};

export default useAssetEvents;
