import { useMemo } from "react";
import { Box, Grid, CircularProgress, Typography } from "@mui/material";
import { ColorsPalette } from "../../../../design-system/colors-palette";
import {
  DeviceData,
  Asset,
  GeofenceEventInfo,
  useGetAssetsOsQuery,
  TableValueDataType,
  TableFilterOperator,
  TableFilterLinkOperator,
} from "../../../../graphql/operations";
import { getLocationInfo } from "../../../AssetsView/MapView/Assets/SummaryTabPanel/LocationSummaryHelper";
import MapWidget from "../../../AssetsView/MapView/Assets/SummaryTabPanel/MapWidget";
import DetailListWidget from "../../../AssetsView/MapView/Shared/DashboardWidgets/DetailListWidget";
import { getDeviceDetails, isAssetHasGeoInfo } from "./utils";

interface DeviceSummaryTabProps {
  device: DeviceData;
}

export const DeviceSummaryTab = ({ device }: DeviceSummaryTabProps) => {
  const { data, isFetching } = useGetAssetsOsQuery({
    input: {
      filterList: {
        filters: [
          {
            dataType: TableValueDataType.String,
            field: "imei",
            operator: TableFilterOperator.Equals,
            value: `{"value":"${device.imei ?? ""}"}`,
          },
        ],
        linkOperator: TableFilterLinkOperator.And,
      },
      limit: 1,
    },
  });

  const asset = data?.getAssetsOS?.assets?.[0];

  const deviceInfoDetails = useMemo(
    () => ({
      title: "Device Information",
      subTitle: "Detailed Information About Your Device",
      deviceInfoDetailsList: getDeviceDetails(device),
    }),
    [device]
  );

  const addressData = asset?.fullAddressFormatted;
  const locationData = asset?.location?.coordinates as number[];
  const lastReportedDate = asset?.updated_date;
  const geofenceData = useMemo(
    () => (asset?.geofence as GeofenceEventInfo[]) ?? [],
    [asset]
  );

  const deviceLocationDetails = useMemo(
    () => ({
      title: "Device Location",
      subTitle: "Current Location Information About Your Device",
      deviceLocationDetailsList: getLocationInfo(
        addressData ?? "",
        locationData ?? [],
        lastReportedDate,
        asset?.tripStatus?.tripState ?? "",
        geofenceData
      ),
    }),
    [
      addressData,
      locationData,
      lastReportedDate,
      asset?.tripStatus?.tripState,
      geofenceData,
    ]
  );

  return (
    <Box
      className="flex-1 overflow-auto bg-panel_content__bg p-4"
      data-testid="device-summary-tab"
    >
      <Grid container spacing={2}>
        <Grid item xl={6} xs={12} className="mapWidgetContainer">
          {isFetching ? (
            <Box
              className="flex justify-center items-center"
              data-testid={"device-summary-tab-map-loader"}
            >
              <CircularProgress />
            </Box>
          ) : asset && isAssetHasGeoInfo(asset as Asset) ? (
            <MapWidget selectedAsset={asset as Asset} />
          ) : (
            <Box
              className="flex justify-center items-center h-full w-full bg-primary-blue-transparent-20 rounded-lg"
              data-testid={"device-summary-tab-map-placeholder"}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: ColorsPalette.LightCharcoal,
                  lineHeight: "38px",
                  fontSize: "2rem",
                  textAlign: "center",
                }}
              >
                Device location was not found.
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xl={6} xs={12} container spacing={2}>
          <Grid item xl={6} lg={6} xs={12}>
            <DetailListWidget
              title={deviceInfoDetails.title}
              subTitle={deviceInfoDetails.subTitle}
              detailList={deviceInfoDetails.deviceInfoDetailsList}
            />
          </Grid>
          <Grid item xl={6} lg={6} xs={12}>
            {isFetching ? (
              <Box
                className="flex justify-center items-center"
                data-testid={"device-summary-tab-location-info-loader"}
              >
                <CircularProgress />
              </Box>
            ) : (
              <DetailListWidget
                title={deviceLocationDetails.title}
                subTitle={deviceLocationDetails.subTitle}
                detailList={deviceLocationDetails.deviceLocationDetailsList}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
