import React, { FC, memo, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  ThemeProvider,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFrequencySelectTheme } from "../../../views/ReportView/hooks/useFrequencySelectTheme";
import { useUserData } from "../../hooks/useUserData";
import { SelectedValues } from "../HierarchySelect/types";

export type EmailsSelectProps = {
  values: SelectedValues;
  onChange: (values: string[]) => void;
  compact?: boolean;
  disabled?: boolean;
  loading?: boolean;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
  error?: string;
};
const emailSchema = yup.string().email().required("required");

export const EmailsSelect: FC<EmailsSelectProps> = memo(
  ({
    values,
    onChange,
    compact,
    disabled = false,
    handleUpdateField,
    fieldPath,
    loading,
    error,
  }: EmailsSelectProps) => {
    const theme = useFrequencySelectTheme();

    const userData = useUserData();
    const [emails, setEmails] = useState<string[]>(values);

    const [emailInputValue, setEmailInputValue] = useState<string>("");
    const [emailInputValueError, setEmailInputValueError] =
      useState<boolean>(false);
    const [shouldAddEmail, setShouldAddEmail] = useState<boolean>(true);

    useEffect(() => {
      setEmailInputValueError(!!error);
    }, [error]);

    const isEmailWasAdded = (email: string) => emails.includes(email);

    const handleRemoveEmail = (email: string) => {
      const filteredEmails = emails.filter((el) => el !== email);
      setEmails(filteredEmails);
      onChange?.(filteredEmails);
      handleUpdateField?.(filteredEmails, fieldPath);
      setShouldAddEmail(false);
    };

    const handleAddNewEmail = async (email: string) => {
      if (isEmailWasAdded(email)) return;
      try {
        await emailSchema.validate(email);
        setEmails([...emails, email]);
        onChange && onChange([...emails, email]);
        handleUpdateField?.([...emails, email], fieldPath);
        setEmailInputValue("");
        setEmailInputValueError(false);
      } catch (e) {
        setEmailInputValueError(true);
      }
    };

    useEffect(() => {
      if (!values.length && userData && shouldAddEmail) {
        setEmails([userData!.email]);
        onChange([userData!.email]);
        handleUpdateField && handleUpdateField([userData!.email], fieldPath);
      }
    }, [userData, loading, values, shouldAddEmail]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <ThemeProvider theme={theme}>
        <Box>
          <FormControl
            fullWidth
            error={emailInputValueError}
            variant="standard"
          >
            <Input
              data-testid="subscribers-email-input"
              value={emailInputValue}
              onChange={(e) => setEmailInputValue(e.target.value)}
              disabled={disabled}
              endAdornment={
                <InputAdornment
                  position="end"
                  className="cursor-pointer"
                  data-testid="subscribers-email-add-icon"
                  onClick={() => handleAddNewEmail(emailInputValue)}
                >
                  <AddIcon />
                </InputAdornment>
              }
            />
            {emailInputValueError ? (
              <FormHelperText>
                {error || "Please enter valid email"}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
          <Grid
            container
            columnSpacing={6}
            rowSpacing={1}
            data-testid="subscribers-emails-list"
            className={compact ? "py-2" : "py-8"}
          >
            {emails.map((email, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={compact ? 12 : 4}
                  lg={compact ? 12 : 4}
                  key={`${email}-${i}`}
                  className="flex items-center justify-between"
                  data-testid={`subscribers-emails-list-el-${i}`}
                >
                  <div className="bg-email__chip w-full flex items-center justify-between px-2 rounded">
                    <Typography className=" !text-sm !font-normal">
                      {email}
                    </Typography>
                    <IconButton
                      size={"small"}
                      disableRipple
                      className="!px-0 !py-0 !w-[24px] !h-6"
                      data-testid={`subscribers-email-clear-btn-${i}`}
                      aria-label="delete"
                      onClick={() => handleRemoveEmail(email)}
                      disabled={disabled}
                    >
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </ThemeProvider>
    );
  }
);
