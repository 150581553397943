import { memo, SVGProps, FC } from "react";

interface CargoTemperatureProps extends SVGProps<SVGSVGElement> {}

const CargoTemperature: FC<CargoTemperatureProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 101 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M61.5832 46.3008L48.8618 25.9492H13.2485L0.52832 46.3008H61.5832Z"
        fill="#535355"
      />
      <path
        d="M0.527344 87.0059H61.5822V46.3014H0.527344V87.0059Z"
        fill="#7E7F81"
      />
      <path
        d="M26.6029 25.9492L23.4229 46.3008H38.6869L35.5068 25.9492H26.6029Z"
        fill="#2F3744"
      />
      <path
        d="M38.6869 46.3024V61.5664L31.0555 53.9338L23.4229 61.5664V46.3024H38.6869Z"
        fill="#434D5A"
      />
      <path d="M45.0469 82.998H52.6795V80.836H45.0469V82.998Z" fill="#758C9B" />
      <path
        d="M45.0469 77.9102H56.4946V75.7481H45.0469V77.9102Z"
        fill="#758C9B"
      />
      <path d="M5.61621 82.998H36.1443V80.836H5.61621V82.998Z" fill="#B7E7EF" />
      <path
        d="M27.1211 78.4102H32.0839V72.1284H36.1449L29.6037 65.5871L23.0625 72.1284H27.1211V78.4102Z"
        fill="#B7E7EF"
      />
      <path
        d="M9.67601 78.4102H14.6388V72.1284H18.6998L12.1574 65.5871L5.61621 72.1284H9.67601V78.4102Z"
        fill="#B7E7EF"
      />
      <path
        d="M67.9671 31.6902L67.2109 32.4464V33.5158L67.9671 34.2695H69.0365L69.7927 33.5158V32.444L69.0365 31.6902H67.9671Z"
        fill="#434D5A"
      />
      <path
        d="M51.8604 32.9793C51.8604 42.172 59.3119 49.6211 68.5021 49.6211C77.6923 49.6211 85.1414 42.172 85.1414 32.9793C85.1414 23.7879 77.6923 16.3388 68.5021 16.3388C59.3119 16.3388 51.8604 23.7879 51.8604 32.9793Z"
        fill="white"
      />
      <path
        d="M56.2988 32.9792C56.2988 39.7211 61.7621 45.1855 68.5015 45.1855C75.2409 45.1855 80.7041 39.7211 80.7041 32.9792C80.7041 26.241 75.2409 20.7753 68.5015 20.7753C61.7621 20.7753 56.2988 26.241 56.2988 32.9792Z"
        fill="white"
      />
      <path
        d="M56.2988 32.9792C56.2988 39.7211 61.7621 45.1855 68.5015 45.1855C75.2409 45.1855 80.7041 39.7211 80.7041 32.9792C80.7041 26.241 75.2409 20.7753 68.5015 20.7753C61.7621 20.7753 56.2988 26.241 56.2988 32.9792Z"
        fill="#9BDEED"
      />
      <path
        d="M68.5016 42.6387C68.0697 42.6387 67.7197 42.2912 67.7197 41.8556C67.7197 41.4237 68.0697 41.0762 68.5016 41.0762C72.9639 41.0762 76.5931 37.4446 76.5931 32.9798C76.5931 28.5175 72.9639 24.8884 68.5016 24.8884C68.0697 24.8884 67.7197 24.5384 67.7197 24.1065C67.7197 23.6709 68.0697 23.3234 68.5016 23.3234C73.8278 23.3234 78.1592 27.6561 78.1592 32.9798C78.1592 38.306 73.8278 42.6387 68.5016 42.6387Z"
        fill="#434D5A"
      />
      <path
        d="M67.2109 51.7901V63.7162C67.2109 64.4271 67.7897 65.0059 68.5018 65.0059C69.2139 65.0059 69.7914 64.4271 69.7914 63.7162V51.7901C69.3656 51.8207 68.9362 51.839 68.5018 51.839C68.0674 51.839 67.6392 51.8207 67.2109 51.7901Z"
        fill="#9BDEED"
      />
      <path
        d="M69.7924 14.1699V2.24501C69.7924 1.5329 69.2149 0.955372 68.5016 0.955372C67.7894 0.955372 67.2119 1.5329 67.2119 2.24501V14.1699C67.6389 14.1418 68.0672 14.121 68.5016 14.121C68.9359 14.121 69.3654 14.1418 69.7924 14.1699Z"
        fill="#9BDEED"
      />
      <path
        d="M99.2377 31.6902H87.3115C87.3397 32.1173 87.3592 32.5455 87.3592 32.9799C87.3592 33.4143 87.3397 33.8437 87.3115 34.2695H99.2377C99.9498 34.2695 100.527 33.6945 100.527 32.9823C100.527 32.2678 99.9498 31.6902 99.2377 31.6902Z"
        fill="#9BDEED"
      />
      <path
        d="M56.114 18.7675L47.6812 10.3322C47.1771 9.8305 46.3585 9.8305 45.8556 10.3322C45.3515 10.8387 45.3515 11.6561 45.8556 12.1577L54.2872 20.5918C54.855 19.9445 55.4643 19.334 56.114 18.7675Z"
        fill="#9BDEED"
      />
      <path
        d="M80.8906 47.1937L89.3235 55.6278C89.8251 56.1319 90.6449 56.1319 91.1478 55.6278C91.6507 55.1237 91.6507 54.3051 91.1478 53.801L82.7162 45.3694C82.1497 46.0166 81.5403 46.6272 80.8906 47.1937Z"
        fill="#9BDEED"
      />
      <path
        d="M82.7152 20.5918L91.1481 12.1565C91.6522 11.6561 91.6522 10.8375 91.1481 10.3358C90.6439 9.82928 89.8266 9.82928 89.3225 10.3322L80.8896 18.7675C81.5394 19.334 82.1487 19.9445 82.7152 20.5918Z"
        fill="#9BDEED"
      />
      <path
        d="M50.4305 50.9707C50.532 50.9707 50.6324 50.9585 50.7278 50.9377C50.6189 50.945 50.5149 50.9683 50.4023 50.9695C50.4121 50.9695 50.4207 50.9707 50.4305 50.9707Z"
        fill="#9BDEED"
      />
      <path
        d="M54.2882 45.3674L45.8565 53.8003C45.3512 54.3044 45.3512 55.1229 45.8565 55.6258C46.3607 56.1299 47.1768 56.1299 47.6821 55.6258L56.1137 47.1918C55.4652 46.6252 54.8547 46.0147 54.2882 45.3674Z"
        fill="#9BDEED"
      />
      <path
        d="M60.5028 33.8008C60.5028 30.4189 57.762 27.6781 54.3813 27.6781C50.9981 27.6781 48.2585 30.4189 48.2585 33.8008H43.3594V82.7803H48.2585C48.2585 86.1635 50.9993 88.9043 54.3813 88.9043C57.762 88.9043 60.5028 86.1635 60.5028 82.7803H65.4008V33.8008H60.5028Z"
        fill="#B7E7EF"
      />
      <path
        d="M56.2169 82.7794C56.2169 83.7925 55.3946 84.6172 54.3815 84.6172C53.3659 84.6172 52.5449 83.7925 52.5449 82.7794C52.5449 81.765 53.3659 80.9428 54.3815 80.9428C55.3946 80.9428 56.2169 81.765 56.2169 82.7794Z"
        fill="#414A56"
      />
      <path
        d="M56.2169 33.8009C56.2169 34.814 55.3946 35.6387 54.3815 35.6387C53.3659 35.6387 52.5449 34.814 52.5449 33.8009C52.5449 32.7865 53.3659 31.9631 54.3815 31.9631C55.3946 31.9631 56.2169 32.7865 56.2169 33.8009Z"
        fill="#414A56"
      />
      <path d="M61.728 79.1094H47.0342V37.4762H61.728V79.1094Z" fill="white" />
      <path
        d="M57.8903 55.5391C58.3675 55.5391 58.7554 55.1537 58.7554 54.6765C58.7554 54.1981 58.3675 53.8114 57.8903 53.8114H55.7307V48.1928H57.8903C58.3675 48.1928 58.7554 47.8061 58.7554 47.3289C58.7554 46.8505 58.3675 46.4651 57.8903 46.4651H55.7307V42.1997C55.7307 41.4546 55.1263 40.8489 54.3811 40.8489C53.6347 40.8489 53.0303 41.4546 53.0303 42.1997V46.4651H50.8707C50.3935 46.4651 50.0068 46.8505 50.0068 47.3289C50.0068 47.8061 50.3935 48.1928 50.8707 48.1928H53.0303V53.8114H50.8707C50.3935 53.8114 50.0068 54.1981 50.0068 54.6765C50.0068 55.1537 50.3935 55.5391 50.8707 55.5391H53.0303V61.1602H50.8707C50.3935 61.1602 50.0068 61.5456 50.0068 62.024C50.0068 62.5012 50.3935 62.8891 50.8707 62.8891H53.0303V67.1655C53.4414 67.0345 53.8709 66.944 54.326 66.944C54.8216 66.944 55.289 67.0443 55.7307 67.1973V62.8891H57.8903C58.3675 62.8891 58.7554 62.5012 58.7554 62.024C58.7554 61.5456 58.3675 61.1602 57.8903 61.1602H55.7307V55.5391H57.8903Z"
        fill="#414A56"
      />
      <path
        d="M55.7306 67.1978C55.2889 67.0449 54.8215 66.9445 54.3272 66.9445C53.8708 66.9445 53.4413 67.0338 53.0302 67.166C51.3013 67.7166 50.041 69.3182 50.041 71.2307C50.041 73.5958 51.9583 75.5156 54.3272 75.5156C54.3357 75.5156 54.3443 75.5144 54.3541 75.5144C54.4654 75.5132 54.5694 75.4887 54.6796 75.4814C56.8783 75.2978 58.6121 73.4772 58.6121 71.2307C58.6121 69.3586 57.4045 67.7839 55.7306 67.1978Z"
        fill="#F57D60"
      />
    </svg>
  );
};

export default memo(CargoTemperature);
