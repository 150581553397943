import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useReportViewTheme } from "../../ReportView/hooks";

export const commonAutocompleteStyles = {
  MuiAutocomplete: {
    styleOverrides: {
      endAdornment: {
        top: "unset",
      },
      clearIndicator: {
        "&:hover, &.Mui-focusVisible": {
          backgroundColor: "transparent", // Disable background color change on hover/focus
        },
      },
      popupIndicator: {
        "&:hover, &.Mui-focusVisible": {
          backgroundColor: "transparent", // Disable background color change on hover/focus
        },
      },
      option: {
        "&:hover": {
          backgroundColor: "var(--brand-transparent)",
        },
        "&.Mui-selected": {
          backgroundColor: "var(--brand)",
          color: "white",
        },
        "&.Mui-focused &:hover": {
          backgroundColor: "var(--brand) !important",
        },
      },
      listbox: {
        backgroundColor: "var(--dashboard_subheader__bg)",
      },
      paper: {
        borderRadius: "4px",
        backgroundColor: "var(--dashboard_subheader__bg)",
      },
      tag: {
        margin: "0 3px",
        backgroundColor: "var(--chip-bg)",
        border: "none",
      },
      input: {
        flexGrow: "0.95",
      },
    },
  },
};

export const useAlertTheme = () => {
  const reportsTheme = useReportViewTheme();

  return createTheme(
    deepmerge(reportsTheme, {
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--accordion-bg)",
              borderRadius: "8px",
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--dark-grey) !important",
              },
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            switchBase: {
              "&.Mui-checked": {
                color: "var(--white) !important",
              },
            },
            track: {
              backgroundColor: "var(--upload_file__icon) !important",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              padding: "10px 14px",
            },
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--dark-grey) !important",
              },
            },
          },
        },
        ...commonAutocompleteStyles,
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "var(--brand-transparent) !important",
              },
              "&.Mui-selected": {
                backgroundColor: "var(--brand) !important",
                color: "white",
              },
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--dashboard_subheader__bg)",
              borderRadius: "4px",
            },
          },
        },
      },
    })
  );
};
