import { useCallback } from "react";
import { GoogleMapsTypeIds } from "../../../../enums/map";

interface GoogleMapsViewChangeProps {
  mapInstance: google.maps.Map | null;
  setGoogleMapTypeId: (mapTypeId: string) => void;
}

const useGoogleMapViewChange = ({
  mapInstance,
  setGoogleMapTypeId,
}: GoogleMapsViewChangeProps) => {
  const onGoogleMapViewChange = useCallback(
    (googleMapTypeId: string) => {
      if (mapInstance) {
        mapInstance.setMapTypeId(googleMapTypeId);
        setGoogleMapTypeId(googleMapTypeId);
      }
    },

    [setGoogleMapTypeId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return onGoogleMapViewChange;
};

export default useGoogleMapViewChange;
