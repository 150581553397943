import { useCallback, useEffect, useState } from "react";
import { useIsFetching } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { AssetType, Maybe } from "../../graphql/operations";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../views/AssetsView/TableView/hooks";

export type AssetTypeNomenclature = {
  id: string;
  label: string;
  value: string;
};

export type AssetTypesResult = {
  getAssetTypeLabel: (type?: Maybe<string>) => Maybe<string>;
  isFetchingNomenclatures: number;
  assetTypeOptionsState: AssetTypeNomenclature[];
};

/**
 * This hooks is used to simplify working with asset types.
 * It fetches asset types from nomenclatures collection and returns them along with
 * a function to map asset type from db (category column) to a value from nomenclatures collection.
 * It should be used anywhere this mapping is needed and NOT hardcoded values in frontend.
 * @param assetTypesSubset - if only a subset of all asset types are needed, note the order of the passed array is preserved
 * @returns {Object}
 */
export const useAssetTypes = (
  assetTypesSubset?: AssetType[]
): AssetTypesResult => {
  const [assetTypeOptionsState, setAssetTypeOptionsState] = useState<
    AssetTypeNomenclature[]
  >([]);

  const assetTypeNomenclatures = useNomenclatures(NOMENCLATURE_NAMES.assetType);

  // Currently used in AssetsFilters to determine if a loader should be displayed while
  // nomenclatures are loading
  const isFetchingNomenclatures = useIsFetching({
    queryKey: ["getNomenclatures"],
  });

  const assetFilterTypes =
    assetTypesSubset && assetTypesSubset.length > 0
      ? assetTypeNomenclatures
          .filter(({ label }) => assetTypesSubset.includes(label))
          .sort((a, b) => {
            return (
              // sort items in order of passed assetTypesSubset array
              assetTypesSubset.indexOf(a?.label) -
              assetTypesSubset.indexOf(b?.label)
            );
          })
      : assetTypeNomenclatures;

  useEffect(() => {
    // isEqual is used to prevent endless rerender loops
    if (!isEqual(assetFilterTypes, assetTypeOptionsState)) {
      setAssetTypeOptionsState(assetFilterTypes);
    }
  }, [assetFilterTypes, assetTypeOptionsState]);

  const getAssetTypeLabel = useCallback(
    (type?: Maybe<string>): Maybe<string> => {
      if (!type) {
        return null;
      }

      const assetTypeLabel =
        assetTypeOptionsState.find(
          (o) => o?.value?.toLowerCase() === type.toLowerCase()
        )?.label ??
        assetTypeOptionsState.find((o) => o?.value === "other")?.label;

      return assetTypeLabel ?? "";
    },
    [assetTypeOptionsState]
  );

  return { getAssetTypeLabel, isFetchingNomenclatures, assetTypeOptionsState };
};
