import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button as MuiButton,
} from "@mui/material";
import { Button } from "../Button";

interface LeaveModalProps {
  isOpen: boolean;
  onClose: () => void;
  confirmNavigation: () => void;
}

export const LeaveModal: FC<LeaveModalProps> = ({
  isOpen = false,
  onClose,
  confirmNavigation,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      data-testid="leave-report-dialog"
      sx={{ backgroundColor: "var(--backdrop)" }}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        sx: {
          backgroundColor: "var(--background) !important",
          backgroundImage: "none",
          width: "100%",
          maxWidth: "720px !important",
          borderRadius: "8px !important",
        },
      }}
    >
      <DialogTitle
        className="!h-30 flex justify-between !p-6 sm:!h-20"
        data-testid="leave-report-dialog-title"
      >
        <span className="text-2xl font-semibold capitalize !text-typography-secondary">
          Unsaved Changes
        </span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="h-10 w-10"
          data-testid="leave-report-dialog-close-icon-btn"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="!pb-0">
        <DialogContentText
          data-testid="leave-report-dialog-message"
          className=" text-base !text-typography-secondary"
        >
          You have unsaved changes. Are you sure you want to leave this page?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="!p-6">
        <MuiButton
          className="global-btn !rounded-[100px] !border-2 !border-solid !px-7 !py-2 !text-base !font-bold !text-primary"
          variant="text"
          type="button"
          onClick={onClose}
          data-testid="leave-report-dialog-cancel-btn"
        >
          Stay
        </MuiButton>
        <Button
          text="Leave"
          size="medium"
          theme="blue"
          variant="default"
          onClick={confirmNavigation}
          dataTestid="leave-report-dialog-okay-btn"
          className="!ml-4 !h-[40px] !px-10 !py-3 !font-bold"
        />
      </DialogActions>
    </Dialog>
  );
};
