import React, { createContext, useContext, useEffect, useState } from "react";
import { filter, isArray } from "lodash";
import { AlertType } from "../../../../graphql/operations";
import { AssetTypeNomenclature } from "../../../../shared/hooks/useAssetTypes";
import { FormFieldDropdownOption } from "../../../../types";

interface AlertFiltersContextProps {
  children: React.ReactNode;
}

export type geofenceNameOption = {
  id: string;
  label: string;
};

interface AlertFilters {
  alertTypes: FormFieldDropdownOption[];
  alertName: FormFieldDropdownOption | null;
  assetTypes: AssetTypeNomenclature[];
  geofenceTypes: FormFieldDropdownOption[];
  geofences: geofenceNameOption[];
}

interface AlertFiltersObject {
  alertFilters: AlertFilters;
}

// For the payload we need only the selected options as lists
// The AlertId should always be one
interface AlertFiltersPayload {
  alertTypes?: AlertType[];
  alertId?: string;
  assetTypes?: string[];
  geofenceTypes?: string[];
  geofenceIds?: string[];
}

interface AlertFiltersContextValue extends AlertFiltersObject {
  filtersPayload: AlertFiltersPayload;
  changeAlertFilters: (newFilters: Partial<AlertFilters>) => void;
  totalFiltersActive: number; // used for showing count of total filters active
}

export const initialFiltersState = {
  alertTypes: [],
  alertName: { label: "", value: "" },
  assetTypes: [],
  geofences: [],
  geofenceTypes: [],
};

const AlertFiltersContext = createContext<AlertFiltersContextValue | undefined>(
  {
    alertFilters: initialFiltersState,
    filtersPayload: {},
    changeAlertFilters: () => {},
    totalFiltersActive: 0,
  }
);

export const AlertFiltersContextProvider: React.FC<
  AlertFiltersContextProps
> = ({ children }) => {
  const [alertFilters, setAlertFilters] =
    useState<AlertFilters>(initialFiltersState);
  const [totalFiltersActive, setTotalFiltersActive] = useState<number>(0);
  const [filtersPayload, setFiltersPayload] = useState<AlertFiltersPayload>({});

  const changeAlertFilters = (newFilters: Partial<AlertFilters>) => {
    setAlertFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));

    updateFiltersPayload(newFilters);
  };

  useEffect(() => {
    updateTotalActiveFilters(alertFilters, setTotalFiltersActive);
  }, [alertFilters, filtersPayload]);

  const contextValue: AlertFiltersContextValue = {
    alertFilters,
    totalFiltersActive,
    filtersPayload,
    changeAlertFilters,
  };

  const updateFiltersPayload = (newFilters: Partial<AlertFilters>) => {
    const filtersKeys = Object.keys(newFilters) as (keyof AlertFilters)[];
    const preparedPayload = {} as Partial<AlertFiltersPayload>;
    // Add Filters to payload depending on their type &
    filtersKeys.forEach((key) => {
      switch (key) {
        case "alertTypes": {
          preparedPayload.alertTypes = (newFilters?.alertTypes?.map(
            (alertType) => alertType?.value ?? ""
          ) ?? []) as AlertType[];
          break;
        }
        case "alertName":
          preparedPayload.alertId = newFilters.alertName?.value ?? ""; // we're using the id of the alert name as names can be duplicated
          break;
        case "assetTypes":
          preparedPayload.assetTypes =
            newFilters?.assetTypes?.map((assetType) => assetType?.value) ?? [];
          break;
        case "geofenceTypes":
          preparedPayload.geofenceTypes =
            newFilters?.geofenceTypes?.map((geoType) => geoType?.value ?? "") ??
            [];
          break;
        case "geofences":
          preparedPayload.geofenceIds =
            newFilters?.geofences?.map((geofence) => geofence.id) ?? [];
          break;
      }
    });
    setFiltersPayload((prevPayload) => ({
      ...prevPayload,
      ...preparedPayload,
    }));
  };

  return (
    <AlertFiltersContext.Provider value={contextValue}>
      {children}
    </AlertFiltersContext.Provider>
  );
};

export const useAlertFiltersContext = () => {
  const context = useContext(AlertFiltersContext);
  if (!context) {
    throw new Error(
      "useRegionsContext must be used within an RegionsContextProvider"
    );
  }
  return context;
};

export const updateTotalActiveFilters = (
  filters: AlertFilters,
  setTotalFiltersActive: (number: number) => void
) => {
  const activeFilters = Object.values(filters).filter((filter) => {
    // second part of the condition handles the alertId
    if (
      (isArray(filter) && filter.length) ||
      (!isArray(filter) && filter?.value)
    ) {
      return true;
    }

    return false;
  });
  setTotalFiltersActive(activeFilters.length);
};
