import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { kebabCase } from "lodash";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";
import { DetailItemValueTypes, DetailListWidgetProps } from "../types";

const GeofenceHoursWidget = <T extends DetailItemValueTypes>({
  title,
  subTitle,
  detailList,
}: DetailListWidgetProps<T>) => {
  const isMobile = useBreakpoint("down", "sm");
  return (
    <Box
      className="rounded-lg bg-dashboard_subheader__bg px-4 py-6 min-h-full"
      data-testid={`hours-widget-header`}
    >
      <Typography
        className="!text-lg !font-semibold !text-brand"
        data-testid="hours-widget-title"
      >
        {title}
      </Typography>
      <Typography
        className="!text-xs !font-medium !mb-3 text-asset-info-subheader"
        data-testid={`hours-widget-subtitle`}
      >
        {subTitle}
      </Typography>
      <Grid container direction={"column"} spacing={2}>
        {Object.entries(detailList).map(([key, value]) => {
          const detailItemValue = value.detailItemValue
            ? value.detailItemValue.toString()
            : "";
          const dayOfWeekValue = isMobile
            ? (value?.label || "").slice(0, 3)
            : value?.label || "";
          return (
            <React.Fragment key={key}>
              <Grid container item>
                <Grid container item>
                  <Grid item xs={3}>
                    <Box
                      className="my-1 flex gap-1"
                      data-testid={`hours-widget-${kebabCase(
                        value.label
                      )}-label-1`}
                    >
                      <Typography className="my-1 !text-checked">
                        {dayOfWeekValue}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      className="my-1 flex flex-col items-center gap-1"
                      data-testid={`hours-widget-${kebabCase(
                        value.label
                      )}-value-0`}
                    >
                      <Typography className="!text-checked">
                        {detailItemValue.split(", ")[0]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      className="my-1 flex flex-col items-center gap-1"
                      data-testid={`hours-widget-${kebabCase(
                        value.label
                      )}-to-1`}
                    >
                      <Typography className="!text-checked">to</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      className="my-1 flex flex-col items-center gap-1"
                      data-testid={`hours-widget-${kebabCase(
                        value.label
                      )}-value-1`}
                    >
                      <Typography className="!text-checked">
                        {detailItemValue.split(", ")[1]}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {detailItemValue.split(", ")[2] &&
                  detailItemValue.split(", ")[3] && (
                    <Grid container item>
                      <Grid item xs={3}>
                        <Box
                          className="my-1 flex gap-1"
                          data-testid={`hours-widget-${kebabCase(
                            value.label
                          )}-label-2`}
                        >
                          <Typography className="!text-brand !font-semibold ">
                            {"Lunch Break"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          className="my-1 flex flex-col items-center gap-1"
                          data-testid={`hours-widget-${kebabCase(
                            value.label
                          )}-value-2`}
                        >
                          <Typography className="!text-brand  !font-semibold">
                            {detailItemValue.split(", ")[2]}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={3}>
                        <Box
                          className="my-1 flex flex-col items-center gap-1"
                          data-testid={`hours-widget-${kebabCase(
                            value.label
                          )}-to-1`}
                        >
                          <Typography className="!text-brand  !font-semibold">
                            to
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          className="my-1 flex flex-col items-center gap-1"
                          data-testid={`hours-widget-${kebabCase(
                            value.label
                          )}-value-3`}
                        >
                          <Typography className="!text-brand !font-semibold">
                            {detailItemValue.split(", ")[3]}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

export default GeofenceHoursWidget;
