import { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { SensorStatus } from "../../../../../../graphql/operations";
import { formatDistanceToNowStrictCustom } from "../../../../../../utils/date";
import {
  CargoState,
  CargoUltrasonicState,
} from "../../AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { StatusCardsType } from "../interfaces";
import StatusCardHeader from "./StatusCardHeader";

export enum StatusColor {
  healthy = "success",
  warning = "caution",
  alert = "warning",
  critical = "error",
  unknown = "gray",
  na = "gray",
}

export interface StatusCardProps {
  statusInfo: string | null | undefined;
  cardType: StatusCardsType;
  lastEventData?: string;
  tripStatus?: string;
}

const StatusCard: FC<StatusCardProps> = ({
  statusInfo,
  cardType,
  lastEventData,
  tripStatus,
}) => {
  const getCorrectStatusText = () => {
    switch (statusInfo) {
      case "na":
        return SensorStatus.Unknown;
      default:
        return statusInfo;
    }
  };

  const handleCargoStatuses = () => {
    switch (statusInfo) {
      case CargoUltrasonicState.Unloaded:
      case CargoState.Empty:
        return "Empty";
      case CargoUltrasonicState.Loaded:
      case CargoState.Loaded:
        return "Loaded";
      default:
        return "Unknown";
    }
  };
  const textColor =
    StatusColor[statusInfo?.toLowerCase() as keyof typeof StatusColor];
  return getCorrectStatusText() !== SensorStatus.Unknown ? (
    <Grid item className="assetStatusCards" xl={3} lg={6} xs={12}>
      <Box className="flex justify-center bg-dashboard_subheader__bg rounded-lg min-h-[290px]">
        <Grid
          className="text-sm w-full p-4"
          data-testid="summary-card-first-card"
        >
          <StatusCardHeader cardType={cardType} />
          <Box className="w-full text-left min-h-[42px]">
            <Typography
              className="capitalize !text-4xl !mb-4 !font-semibold !text-primary-blue"
              data-testid="summary-card-state-label"
            >
              {cardType === StatusCardsType.CameraVision && (
                <span className="text-lg">Loaded Floor Space </span>
              )}
              <span
                className={
                  cardType === StatusCardsType.CameraVision
                    ? "text-lg"
                    : "text-4xl"
                }
                data-testid="summary-card-status-text"
                style={{
                  color: textColor ? `var(--${textColor})` : "inherit",
                }}
              >
                {cardType === StatusCardsType.Chassis
                  ? handleCargoStatuses()
                  : getCorrectStatusText()}
              </span>
            </Typography>
          </Box>

          <Box>
            {tripStatus && (
              <Box
                className="justify w-full text-card-sensors-text"
                sx={{
                  marginTop: tripStatus && !lastEventData ? "92px" : "16px",
                }}
              >
                <Typography className="!text-sm inline-block">
                  Trip Status: {<b>{tripStatus}</b>}
                </Typography>
              </Box>
            )}
          </Box>

          <Box>
            {lastEventData && (
              <>
                <Box
                  className="w-full text-right"
                  sx={{
                    marginTop: tripStatus ? "18px" : "54px",
                    marginBottom: "4px",
                  }}
                >
                  <Box className="inline-block rounded py-2 px-4 bg-primary-blue text-center text-white">
                    <Typography
                      className=" !text-xs !font-bold"
                      data-testid="summary-card-status-last-event"
                    >
                      {formatDistanceToNowStrictCustom(new Date(lastEventData))}
                    </Typography>
                  </Box>
                </Box>

                <Box className="justify w-full text-right">
                  <Typography className="!text-sm inline-block align-middle text-card-sensors-text">
                    in current status
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Box>
    </Grid>
  ) : (
    <></>
  );
};

export default StatusCard;
