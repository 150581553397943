import { useEffect, useState } from "react";

export enum SensorGraphSize {
  small = 6,
  medium = 9,
  large = 12,
}

interface ChartExpandHook {
  gridWidth: SensorGraphSize;
  gridWidthIndicator: number;
  handleToggleGridWidth: () => void;
}

export default function useChartExpand(): ChartExpandHook {
  const initialChartSize = SensorGraphSize.large;
  const [gridWidth, setGridWidth] = useState(initialChartSize);

  // gridWidthIndicator is used to indicate the width of the grid. 1 - is for half the page size, 2 for 3/4 size and 3 is for full size. The indicator is presented in top right corner of the door graph container.
  // use for initial value the biggest size for chart. In future this could be pass as params if there is a need to be more flexible.
  const [gridWidthIndicator, setGridWidthIndicator] = useState(3);

  useEffect(() => {
    let gridWidthIndicatorInitialValue = 3;

    switch (gridWidth) {
      case SensorGraphSize.small:
        gridWidthIndicatorInitialValue = 1;
        break;
      case SensorGraphSize.medium:
        gridWidthIndicatorInitialValue = 2;
        break;
      case SensorGraphSize.large:
        gridWidthIndicatorInitialValue = 3;
        break;
      default:
        gridWidthIndicatorInitialValue = 3;
        break;
    }
  }, [gridWidth]);

  const handleToggleGridWidth = () => {
    if (gridWidth === 6) {
      setGridWidth(9);
      setGridWidthIndicator(2);
    } else if (gridWidth === 9) {
      setGridWidth(12);
      setGridWidthIndicator(3);
    } else {
      setGridWidth(6);
      setGridWidthIndicator(1);
    }
  };

  return { gridWidth, gridWidthIndicator, handleToggleGridWidth };
}
