import { useAuthContext } from "../../context/AuthContext";
import { MeUserData, useGetUserDataQuery } from "../../graphql/operations";

export const useUserData = (): MeUserData | undefined => {
  const { isAuthorized } = useAuthContext();
  const { data } = useGetUserDataQuery(undefined, {
    enabled: isAuthorized,
  });
  return data?.me;
};
