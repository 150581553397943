import { FC, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slug from "slug";
import Page from "../../shared/components/Page";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { RegionsContextProvider } from "../AdminPanel/tabs/Regions/context";
import { AlertTypeTitle } from "../AlertsView/constants";
import { AlertRecord } from "./components/AlertRecord";
import { AlertTemplate } from "./components/AlertTemplate";

export const AlertView: FC = () => {
  const navigate = useNavigate();
  const page = useRef();
  const { id, name } = useParams();

  useEffect(() => {
    if (!id || !name) {
      return navigate(NavigationRoutes.Alerts);
    }
    const availableAlertTypes = Object.values(AlertTypeTitle).map((value) =>
      slug(value)
    );
    // Check if unknown report template name provided
    if (id === "template" && !availableAlertTypes.includes(name)) {
      return navigate(NavigationRoutes.Alerts);
    }
  }, [id, name, navigate]);

  return (
    <Page ref={page} className={"bg-background"}>
      <RegionsContextProvider>
        {id === "template" ? (
          <AlertTemplate templateName={name!} />
        ) : (
          <AlertRecord id={id!} />
        )}
      </RegionsContextProvider>
    </Page>
  );
};
