import {
  AggregatedEventHistory,
  HistoricalEventHistory,
} from "../../../../../../graphql/operations";

export const BAR_HEIGHT = 24;

export type DoorData = {
  eventHistoryData?: Partial<Pick<
    AggregatedEventHistory,
    "door" | "date"
  > | null>;
} | null;
export type AirBagData = {
  eventHistoryData?: Pick<AggregatedEventHistory, "airbag" | "date">;
};
export type MediaData = {
  eventHistoryData?: Pick<HistoricalEventHistory, "mediaActivity">;
};

export const calculateDataStatusLast30Days = (data: Array<DoorData>) => {
  let closedDoorsEvents = 0;
  let openDoorsEvents = 0;

  data.forEach((day: DoorData) => {
    openDoorsEvents += day?.eventHistoryData?.door?.doorSummary?.opened ?? 0;
    closedDoorsEvents += day?.eventHistoryData?.door?.doorSummary?.closed ?? 0;
  });

  return {
    closedDoorsEvents,
    openDoorsEvents,
  };
};

export function calculateDoorStatusInLocationsLast30Days(
  data: Array<DoorData>
) {
  let closedDoorsEventsInsideGeofence = 0;
  let closedDoorsEventsOutsideGeofence = 0;
  let openDoorsEventsInsideGeofence = 0;
  let openDoorsEventsOutsideGeofence = 0;

  data.forEach((aggreagatedData: DoorData) => {
    const open =
      aggreagatedData?.eventHistoryData?.door?.doorSummary?.openCountGeofence ??
      0;
    const openCountOutGeofence =
      aggreagatedData?.eventHistoryData?.door?.doorSummary
        ?.openedCountOutGeofence ?? 0;
    const closedCountGeofence =
      aggreagatedData?.eventHistoryData?.door?.doorSummary
        ?.closedCountGeofence ?? 0;
    const closedCountOutGeofence =
      aggreagatedData?.eventHistoryData?.door?.doorSummary
        ?.closedCountOutGeofence ?? 0;

    openDoorsEventsInsideGeofence += open ?? 0;
    openDoorsEventsOutsideGeofence += openCountOutGeofence ?? 0;
    closedDoorsEventsInsideGeofence += closedCountGeofence ?? 0;
    closedDoorsEventsOutsideGeofence += closedCountOutGeofence ?? 0;
  });

  return {
    openDoorsEventsInsideGeofence,
    openDoorsEventsOutsideGeofence,
    closedDoorsEventsInsideGeofence,
    closedDoorsEventsOutsideGeofence,
  };
}

export const calculateBarWidthBaseOnContainer = (
  valueA: number,
  valueB: number,
  containerWidth: number
) => {
  const totalValue = valueA + valueB;
  const closeness = Math.min(valueA, valueB) / Math.max(valueA, valueB);

  // Threshold for applying a scale factor
  const closenessThreshold = 0.8;
  let scaleFactor = 1;

  // Adjust scale factor based on how close the values are
  if (closeness >= closenessThreshold) {
    scaleFactor = 1.2;
  }

  const percentageA = valueA / totalValue;
  const percentageB = valueB / totalValue;

  const barWidthA = percentageA * containerWidth * scaleFactor;
  const barWidthB = percentageB * containerWidth * scaleFactor;

  return { barWidthA, barWidthB };
};
