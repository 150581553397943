import { ReactNode } from "react";
import { Box } from "@mui/material";
import useBreakpoint from "../../../shared/hooks/useBreakpoint";
import { useAuthenticationViewBrand } from "../hooks/useAuthenticationViewBrand";

interface AuthenticationBoxProps {
  children: ReactNode;
}

export const AuthenticationBox: React.FC<AuthenticationBoxProps> = ({
  children,
}) => {
  const brand = useAuthenticationViewBrand();
  const isMobile = useBreakpoint("down", "sm");

  const backgroundStyle = brand.backgroundImage.url
    ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${brand.backgroundImage.url})`
    : "var(--custom-auth-background-color)";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        background: backgroundStyle,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "4rem",
          width: isMobile ? "350px" : "400px",
          maxHeight: "90vh",
          overflowY: "auto",
          margin: "0 1rem",
          paddingY: "3rem",
          borderRadius: "8px",
          borderTop: `20px solid ${brand.color}`,
          backgroundColor: "var(--white)",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <Box sx={{ maxWidth: "200px" }}>
          <img src={brand.logo.url ?? ""} alt={brand.logo.file_name ?? ""} />
        </Box>

        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
