import { QueryClient, UseQueryResult } from "@tanstack/react-query";
import { cloneDeep } from "lodash";
import {
  Asset,
  GetAssetsQuery,
  UpdateAssetMutation,
  NomenclatureData,
} from "../../../graphql/operations";

interface OnAssetMutateSuccessProps {
  updated: UpdateAssetMutation;
  getAssetsQuery: UseQueryResult<GetAssetsQuery, unknown>;
  queryClient: QueryClient;
}

interface NomenclaturesParseProps {
  nomenclatures: ({
    name: string;
    items?: any;
  } | null)[];
  name: string;
}

export const onAssetMutateSuccess = ({
  updated,
  getAssetsQuery,
  queryClient,
}: OnAssetMutateSuccessProps) => {
  const index = getAssetsQuery?.data?.getAssets?.assets?.findIndex(
    (a) => a?._id === updated.updateAsset._id
  );
  const initial = getAssetsQuery?.data?.getAssets?.assets![index!];

  if (index !== undefined && index > -1) {
    const nextAssets = [
      ...(getAssetsQuery?.data?.getAssets.assets as Array<Asset>),
    ];
    nextAssets.splice(index, 1, updated.updateAsset as Asset);
    const data = cloneDeep(
      getAssetsQuery?.data ?? { getAssets: { assets: [] } }
    );
    data.getAssets.assets = nextAssets;
    queryClient.setQueriesData(["getAssets"], data);
  } else {
    console.warn("Unexpected problem. Forcing to refetch all assets.");
    getAssetsQuery.refetch({ cancelRefetch: true });
  }

  if (
    // If device imei set for the first time
    (!initial && updated.updateAsset.imei) ||
    // Or device imei has changed
    (initial && updated.updateAsset.imei !== initial.imei) ||
    // Or asset was transferred to another org
    (initial &&
      updated.updateAsset.customer_orgs_id !== initial.customer_orgs_id)
  ) {
    queryClient.invalidateQueries(["findDevices"], {
      refetchType: "all",
    });
  }
};

export const parseNomenclatures = ({
  nomenclatures,
  name,
}: NomenclaturesParseProps) => {
  const found = nomenclatures.find((x) => {
    return x?.name === name;
  });

  const itemsParsed = found ? JSON.parse(found?.items?.toString()) : [];
  const result = Object.keys(itemsParsed).map((k) => ({
    id: itemsParsed[k].value,
    ...itemsParsed[k],
  }));

  return result;
};
