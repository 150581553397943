import { FC, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SET_BREADCRUMBS_FILTERS } from "../../../../../constants";
import {
  EventHistoryCategoryToUiNamesMap,
  EventHistoryEventCategory,
} from "../../../../../constants/map";
import {
  HistoricalEventHistory,
  useGetBreadcrumbsHistoricalDataQuery,
} from "../../../../../graphql/operations";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import BreadcrumbsFiltersWidget from "./Filters/BreadcrumbsFiltersWidget";
import BreadCrumbsMapWidget from "./Map/BreadcrumbsMap";
import BreadcrumbsTable from "./Table/BreadcrumbsTable";
import { useBreadcrumbsContext } from "./context/BreadCrumbsContext";
import {
  HistoryEventsFiltersData,
  getHistoryEventsCount,
  getTravelledDistance,
} from "./utils";

export interface BreadcrumbsTabPanelProps {
  imei?: string;
}

const BreadcrumbsTabPanel: FC<BreadcrumbsTabPanelProps> = ({ imei }) => {
  const isMobile = useBreakpoint("down", "xl");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const {
    state: {
      breadcrumbs: { startDate, endDate, selectedFilters },
    },
    dispatch,
  } = useBreadcrumbsContext();

  const { data: eventHistoryData, isLoading: isEventHistoryDataLoading } =
    useGetBreadcrumbsHistoricalDataQuery(
      {
        input: {
          imei: imei ?? "",
          startDate: startDate,
          endDate: endDate,
          events: selectedFilters ?? [],
          // Note: Temporary request huge limit to get all data and paginate it virtually on the client side
          limit: 10000,
          // TODO: PRJIND-9769 Pass the correct limit value, once the backend pagination is implemented
          //skip: paginationLimit ?? 0,
          //limit: appConfig.table.breadcrumbsHistoryEventsPerPage,
        },
      },
      {
        enabled: !!imei && Boolean(startDate && endDate),
      }
    );

  const eventHistory = useMemo(
    () =>
      (
        (eventHistoryData?.searchHistoricalEventHistory
          ?.data as HistoricalEventHistory[]) || []
      ).map((item, index) => {
        if (item.breadcrumbs) {
          item.breadcrumbs.imei = index.toString();
        }
        return item;
      }),
    [eventHistoryData?.searchHistoricalEventHistory?.data]
  );

  // TODO: PRJIND-9769 Add additional request that will fetch the count of all events types
  const historyEventsCount = getHistoryEventsCount(eventHistory);
  const travelledDistance = getTravelledDistance(eventHistory);

  const onFilterClick = (eventName: EventHistoryEventCategory) => {
    if (selectedFilter && selectedFilter === eventName) {
      // Clear filter when the currently active filter is clicked again
      setSelectedFilter("");
      dispatch({
        type: SET_BREADCRUMBS_FILTERS,
        payload: [],
      });
    } else {
      switch (eventName) {
        case EventHistoryEventCategory.LocationUpdate:
          dispatch({
            type: SET_BREADCRUMBS_FILTERS,
            payload: EventHistoryCategoryToUiNamesMap.get(
              EventHistoryEventCategory.LocationUpdate
            ),
          });
          break;
        case EventHistoryEventCategory.Heartbeat:
          dispatch({
            type: SET_BREADCRUMBS_FILTERS,
            payload: EventHistoryCategoryToUiNamesMap.get(
              EventHistoryEventCategory.Heartbeat
            ),
          });
          break;
        case EventHistoryEventCategory.Door:
          dispatch({
            type: SET_BREADCRUMBS_FILTERS,
            payload: EventHistoryCategoryToUiNamesMap.get(
              EventHistoryEventCategory.Door
            ),
          });
          break;
        case EventHistoryEventCategory.Cargo:
          dispatch({
            type: SET_BREADCRUMBS_FILTERS,
            payload: EventHistoryCategoryToUiNamesMap.get(
              EventHistoryEventCategory.Cargo
            ),
          });
          break;
        case EventHistoryEventCategory.LocationEntry:
          dispatch({
            type: SET_BREADCRUMBS_FILTERS,
            payload: EventHistoryCategoryToUiNamesMap.get(
              EventHistoryEventCategory.LocationEntry
            ),
          });
          break;
        default:
          console.error("Unrecognized filter event name passed in!", eventName);
          break;
      }
    }
  };
  const historyEventsFilterDataDefaultObject: HistoryEventsFiltersData = {
    circleBackgroundColor: "",
    iconColor: "",
    filterName: "",
    filterValue: 0,
    filterType: "",
    onClick: () => {},
    isSelected: false,
    setSelectedFilter: setSelectedFilter,
  };
  const historyEventsFiltersData: HistoryEventsFiltersData[] = [
    {
      ...historyEventsFilterDataDefaultObject,
      circleBackgroundColor: "var(--breadcrumbs-filter-location-update)",
      iconColor: "var(--breadcrumbs-filters-icon-color)",
      filterName: EventHistoryEventCategory.LocationUpdate,
      filterValue: historyEventsCount.locationUpdate,
      filterType: EventHistoryEventCategory.LocationUpdate,
      isSelected: selectedFilter === EventHistoryEventCategory.LocationUpdate,
      onClick: () => {
        setSelectedFilter(EventHistoryEventCategory.LocationUpdate);
        onFilterClick(EventHistoryEventCategory.LocationUpdate);
      },
    },
    {
      ...historyEventsFilterDataDefaultObject,
      circleBackgroundColor: "var(--breadcrumbs-filter-health)",
      iconColor: "var(--breadcrumbs-filters-icon-color)",
      filterName: EventHistoryEventCategory.Heartbeat,
      filterValue: historyEventsCount.healthEvent,
      filterType: EventHistoryEventCategory.Heartbeat,
      isSelected: selectedFilter === EventHistoryEventCategory.Heartbeat,

      onClick: () => {
        setSelectedFilter(EventHistoryEventCategory.Heartbeat);
        onFilterClick(EventHistoryEventCategory.Heartbeat);
      },
    },
    {
      ...historyEventsFilterDataDefaultObject,
      circleBackgroundColor: "var(--breadcrumbs-filter-cargo)",
      iconColor: "var(--breadcrumbs-filters-icon-color)",
      filterName: EventHistoryEventCategory.Cargo,
      filterValue: historyEventsCount.cargoUpdate,
      filterType: EventHistoryEventCategory.Cargo,
      isSelected: selectedFilter === EventHistoryEventCategory.Cargo,
      onClick: () => {
        setSelectedFilter(EventHistoryEventCategory.Cargo);
        onFilterClick(EventHistoryEventCategory.Cargo);
      },
    },
    {
      ...historyEventsFilterDataDefaultObject,
      circleBackgroundColor: "var(--breadcrumbs-filter-door)",
      iconColor: "var(--breadcrumbs-filters-icon-color)",
      filterName: EventHistoryEventCategory.Door,
      filterValue: historyEventsCount.doorEvent,
      filterType: EventHistoryEventCategory.Door,
      isSelected: selectedFilter === EventHistoryEventCategory.Door,
      onClick: () => {
        setSelectedFilter(EventHistoryEventCategory.Door);
        onFilterClick(EventHistoryEventCategory.Door);
      },
    },
    {
      ...historyEventsFilterDataDefaultObject,
      circleBackgroundColor: "var(--breadcrumbs-filter-location-entry)",
      iconColor: "var(--breadcrumbs-filters-icon-color)",
      filterName: EventHistoryEventCategory.LocationEntry,
      filterValue: historyEventsCount.locationEntry,
      filterType: EventHistoryEventCategory.LocationEntry,
      isSelected: selectedFilter === EventHistoryEventCategory.LocationEntry,
      onClick: () => {
        setSelectedFilter(EventHistoryEventCategory.LocationEntry);
        onFilterClick(EventHistoryEventCategory.LocationEntry);
      },
    },
  ];

  return (
    <Box
      className="flex gap-4 p-4"
      data-testid="breadcrumbs-tab-panel"
      sx={{ height: "100%" }}
    >
      <Box
        sx={{
          gap: "20px",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
        className={`breadcrumbsMainContainer flex w-full ${
          isMobile ? "flex-col" : "flex-row"
        }`}
      >
        <Box
          sx={{
            height: isMobile ? "600px" : "100%",
            flexDirection: "column",
            flex: 1,
            flexGrow: 1,
            width: isMobile ? "100%" : "50%",
          }}
          className="breadcrumbsMapWidgetContainer"
        >
          <BreadCrumbsMapWidget eventHistory={eventHistory} />
        </Box>
        <Box
          sx={{
            flex: 1,
            flexGrow: 1,
            display: "flex",
            flexFlow: "column nowrap",
          }}
          data-testid="breadcrumbs-right-panel"
          className={"breadcrumbsSideContainer"}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
            data-testid="breadcrumbsFiltersContainer"
            className={"mb-5 "}
          >
            <BreadcrumbsFiltersWidget
              historyEventsFiltersData={historyEventsFiltersData}
              travelledDistance={travelledDistance}
            />
          </Box>
          <Box
            data-testid="breadcrumbsTableContainer"
            sx={{
              flexGrow: 1,
              display: "flex",
              height: isMobile ? "600px" : "100%",
              flexFlow: "column nowrap",
            }}
          >
            <BreadcrumbsTable
              eventHistory={eventHistory}
              isLoading={isEventHistoryDataLoading}
              totalTableRowsCount={
                eventHistory.length // this needs to be changed after implementing pagination
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BreadcrumbsTabPanel;
