import { memo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import classNames from "classnames";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { CustomerSupport } from "./CustomerSupport";
import { NavList } from "./NavList";

export type CustomerSupportDataType = {
  email: string;
  phone: string;
};

export type NavBarProps = {
  onClose: () => void;
  isMobile: boolean;
  customerSupportData: CustomerSupportDataType;
};

const NavBar = memo(
  ({ onClose, isMobile, customerSupportData }: NavBarProps) => (
    <Container
      className={classNames(
        "h-full overflow-auto bg-app_header__bg !pb-0 !pr-0 !pl-0 text-feather-gray",
        {
          "!pt-20": !isMobile,
          "!pt-[6.5rem]": isMobile,
        }
      )}
    >
      <Grid
        container
        data-testid="side-menu"
        className="h-full w-64 !flex-col !flex-nowrap justify-between text-base"
        role="presentation"
      >
        <Grid item container className="!flex-col !mb-2">
          <Grid item>
            <div className="flex items-center justify-between px-6 py-4">
              <span className="text-2xl font-semibold tracking-tight text-typography">
                Menu
              </span>
              <p
                className="h-6 w-6 cursor-pointer text-typography"
                onClick={onClose}
                onKeyDown={onClose}
              >
                <CloseIcon />
              </p>
            </div>
            <hr className="opacity-25" />
          </Grid>
          <Grid item>
            <NavList onItemClick={onClose} />
          </Grid>
        </Grid>
        <Grid item onClick={onClose} onKeyDown={onClose}>
          <CustomerSupport customerSupportData={customerSupportData} />
        </Grid>
      </Grid>
    </Container>
  )
);

export type SideMenuProps = {
  open: boolean;
  onClose: () => void;
  customerSupportData: CustomerSupportDataType;
};

const SideMenu = ({ open, onClose, customerSupportData }: SideMenuProps) => {
  const isMobile = useBreakpoint("down", "sm");

  return (
    <Drawer
      disableEnforceFocus
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root.MuiDrawer-paper": {
          boxShadow: "0px 0px 10px 4px var(--box-shadow)",
        },
      }}
    >
      <NavBar
        onClose={onClose}
        isMobile={isMobile}
        customerSupportData={customerSupportData}
      />
    </Drawer>
  );
};

export default memo(SideMenu);
