import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useFormTheme } from "../../../shared/hooks/theme/useFormTheme";

export const useAuthFormTheme = () => {
  const formTheme = useFormTheme();

  return createTheme(
    deepmerge(formTheme, {
      components: {
        MuiInput: {
          styleOverrides: {
            root: {
              color: "var(--flat-black)",
              "&::before": {
                borderBottomColor: "var(--flat-black) !important",
              },
            },
          },
        },
      },
    })
  );
};
