import { useCallback, FC } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import slug from "slug";
import { PAGE_SNACKBAR } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";

export type ReportHeaderProps = {
  id: string;
  name: string;
  setIsDialogOpen?: (isOpen: boolean) => void;
};

export const ReportHeader: FC<ReportHeaderProps> = ({
  id,
  name,
  setIsDialogOpen,
}: ReportHeaderProps) => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const onBackBtnClick = useCallback(() => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: null,
    });
    navigate(NavigationRoutes.Reports);
  }, [navigate, dispatch]);

  return (
    <Box className="px-4 pt-4 md:px-8 md:pt-4 lg:px-16 lg:pt-4">
      <Box className="mb-1">
        <Button
          className="!p-0 !font-bold !capitalize !text-brand"
          onClick={onBackBtnClick}
        >
          <ArrowBackIcon className="mr-3" />
          Back
        </Button>
      </Box>
      <h2
        className="group min-h-[3rem] text-2xl font-semibold leading-10 text-header-text mb-4"
        data-testid={`report-${slug(name)}`}
      >
        {name}
        {id !== "template" && setIsDialogOpen ? (
          <IconButton
            color="inherit"
            className="!hidden group-hover:!inline-flex"
            data-testid="editReportNameBtn"
            aria-label="editReportName"
            onClick={() => setIsDialogOpen(true)}
          >
            <ModeEditIcon />
          </IconButton>
        ) : (
          ""
        )}
      </h2>
    </Box>
  );
};
