import { FC } from "react";
import { Box, Grid } from "@mui/material";
import { ReactComponent as AirtankSensorDark } from "../../../../../assets/svgs/airTankSensorDark.svg";
import { ReactComponent as AirtankSensorLight } from "../../../../../assets/svgs/airtankSensorLight.svg";
import { useAppContext } from "../../../../../context/AppContext";
import SensorHeader from "../../../../../shared/components/SensorHeader/SensorHeader";
import { AirTankSummaryData } from "../BrakesTab/helpers";
import { AirTankCard } from "./AirTankCard";

export interface AirTankStatusProps {
  sensor: AirTankSummaryData;
}

export const AirTankStatus: FC<AirTankStatusProps> = ({ sensor }) => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };
  return (
    <Grid
      item
      container
      xs={12}
      className="min-h-[331px] flex align-center justify-center"
    >
      <Box className="w-full p-6 bg-dashboard_subheader__bg rounded-lg">
        <SensorHeader title="Air Tank Sensor" noBorder>
          {isLightTheme ? (
            <AirtankSensorDark style={svgIconSettings} />
          ) : (
            <AirtankSensorLight style={svgIconSettings} />
          )}
        </SensorHeader>
        <Grid item container xs={12} columnSpacing={2} className=" !px-10">
          <AirTankCard title={sensor.title} status={sensor.status} />
        </Grid>
      </Box>
    </Grid>
  );
};
