import { useMemo } from "react";
import { AssetFilterConfig, FilterPayloadList } from "../AssetsDataContext";

export const useFiltersParam = (
  lastAssetDateAtPage: string,
  filters: AssetFilterConfig
): FilterPayloadList => {
  return useMemo(() => {
    const filtersAcc = filters as unknown as FilterPayloadList;
    if (lastAssetDateAtPage !== "" && filtersAcc.filterList) {
      const filtersRef = filtersAcc.filterList[0].filters;
      if (filtersRef[filtersRef.length - 1].columnField === "lst_evnt_t") {
        filtersRef[filtersRef.length - 1].value =
          JSON.stringify(lastAssetDateAtPage);
      } else {
        filtersRef.push({
          columnField: "lst_evnt_t",
          operatorValue: "before",
          value: JSON.stringify(lastAssetDateAtPage),
        });
      }
    }
    return filtersAcc;
  }, [lastAssetDateAtPage, filters]);
};
