import { AssetDetentionState } from "./types";

type AssetDetentionStateMember = keyof typeof AssetDetentionState;

export const ASSET_DETENTION_STATE_OPTIONS = Object.keys(
  AssetDetentionState
).map((key) => ({
  id: AssetDetentionState[key as AssetDetentionStateMember],
  label: AssetDetentionState[key as AssetDetentionStateMember],
  value: AssetDetentionState[key as AssetDetentionStateMember],
}));
