import { GridColDef } from "@mui/x-data-grid-premium";

export const columnVisibilityModel = {
  tire: true,
  pressure: true,
  status: true,
  lastUpdated: true,
};

export const columns: GridColDef[] = [
  { field: "tire", headerName: "Tire", flex: 1 },
  { field: "pressure", headerName: "Pressure", flex: 1 },
  { field: "status", headerName: "Status", flex: 1 },
  { field: "lastUpdated", headerName: "Last Updated", flex: 1 },
];

export const SEARCH_KEYS = columns.map((column) => column.field);
