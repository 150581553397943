import { SensorStatus } from "../../../../../../graphql/operations";
import { SensorFilterDropdownElement, SensorFiltersNames } from "./types";

export const sensorFiltersDropDownOptions: SensorFilterDropdownElement[] = [
  {
    id: 1,
    label: SensorFiltersNames.CargoUltrasonic,
    contextObjectKey: "cargoUltrasonicState",
  },
  {
    id: 2,
    label: SensorFiltersNames.Door,
    contextObjectKey: "doorState",
  },
  {
    id: 3,
    label: SensorFiltersNames.InternalCameraStatus,
    contextObjectKey: "internalCameraStatus",
  },
  {
    id: 4,
    label: SensorFiltersNames.InternalCameraPercentage,
    contextObjectKey: "internalCameraFloorUsagePercentage",
  },
  {
    id: 5,
    label: SensorFiltersNames.BatteryPrimaryVoltage,
    contextObjectKey: "batteryPrimaryVoltage",
  },
  {
    id: 6,
    label: SensorFiltersNames.BatterySecondaryVoltage,
    contextObjectKey: "batterySecondaryVoltage",
  },
  {
    id: 7,
    label: SensorFiltersNames.BackupBatteryVoltage,
    contextObjectKey: "backupBatteryVoltage",
  },
  {
    id: 8,
    label: SensorFiltersNames.SolarAmperage,
    contextObjectKey: "solarAmperage",
  },
  {
    id: 9,
    label: SensorFiltersNames.AtisAlpha,
    contextObjectKey: "atisAlpha",
  },
  {
    id: 10,
    label: SensorFiltersNames.LiteSentryGamma,
    contextObjectKey: "liteSentryGamma",
  },
  {
    id: 11,
    label: SensorFiltersNames.TemperatureInternal,
    contextObjectKey: "temperatureInternal",
  },
  {
    id: 12,
    label: SensorFiltersNames.WheelEndTemperature,
    contextObjectKey: "wheelEndTemperature",
  },
  {
    id: 13,
    label: SensorFiltersNames.Airbag,
    contextObjectKey: "airbag",
  },
  {
    id: 14,
    label: SensorFiltersNames.TpmsBeta,
    contextObjectKey: "tpmsBeta",
  },
  {
    id: 15,
    label: SensorFiltersNames.SupplyPressure,
    contextObjectKey: "supplyPressure",
  },
  {
    id: 16,
    label: SensorFiltersNames.TankPressure,
    contextObjectKey: "tankPressure",
  },
];

export const DEFAULT_SENSOR_VALUES: Partial<
  Record<SensorFiltersNames, { min: number; max: number }>
> = {
  [SensorFiltersNames.BatteryPrimaryVoltage]: {
    min: 0,
    max: 25,
  },
  [SensorFiltersNames.BatterySecondaryVoltage]: {
    min: 0,
    max: 25,
  },
  [SensorFiltersNames.BackupBatteryVoltage]: {
    min: 0,
    max: 4.2,
  },
  [SensorFiltersNames.SolarAmperage]: {
    min: 0,
    max: 4,
  },
};

export const getSensorWidgetColorFromType = (type: SensorStatus) => {
  switch (type) {
    case SensorStatus.Healthy:
      return "var(--success)";
    case SensorStatus.Alert:
      return "var(--warning)";
    case SensorStatus.Warning:
      return "var(--caution)";
    case SensorStatus.Critical:
      return "var(--error)";
    default:
      return "";
  }
};
