import {
  ConfigurationSet,
  GetConfigurationSetsQuery,
} from "../../../../../../graphql/operations";
import { ABSFaultCodeConfigurationSetTableData } from "./interfaces";

export const parseGetFaultCodesConfigurationSetsQueryData = (
  data: GetConfigurationSetsQuery | undefined
): ABSFaultCodeConfigurationSetTableData[] => {
  return (
    data?.getConfigurationSets?.map(mapFaultCodesConfigurationSetsTableData) ??
    []
  );
};

export const mapFaultCodesConfigurationSetsTableData = (
  data: ConfigurationSet
): ABSFaultCodeConfigurationSetTableData => {
  const values = data?.value && JSON.parse(data?.value);

  return {
    id: data._id,
    ...data,
    ...values,
  };
};
