import React, {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box } from "@mui/system";
import {
  GridCallbackDetails,
  GridRowParams,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "../../../../context/AppContext";
import {
  Automation,
  FilterListInput,
  FiltersInput,
  useGetAutomationsQuery,
  TableViewType,
  TableSortingInput,
  SortOrder,
  TableFilterOperator,
  TableFilterLinkOperator,
  useUpdateAutomationMutation,
  AutomationMode,
  UpdateAutomationMutation,
} from "../../../../graphql/operations";
import { getRows, Table } from "../../../../shared/components/Table";
import { useExportedFileName } from "../../../../shared/hooks/useExportedFileName";
import { usePreferredTimezone } from "../../../../shared/hooks/usePreferredTimezone";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { validateTableFilters } from "../../../../utils/validateTableFilters";
import { useOrgsDownwardHierarchy } from "../../../AssetsView/shared/hooks/useOrgsDownwardHierarchy";
import {
  automationsVisibleColumns,
  getAutomationColumns,
  searchKeys,
} from "./AutomationsUtils";
import {
  onSettledGetAutomations,
  onSuccessUpdateCallback,
  updateAutomationOnErrorCallback,
} from "./utils/utils";

export type automationTableProps = {
  onRowClick: (value: GridRowParams, event: MuiEvent<React.MouseEvent>) => void;
  refreshAutomations: MutableRefObject<Function>;
  apiRef: MutableRefObject<GridApiPremium>;
};

export const handleFilterChange = (
  data: { items: FiltersInput[]; linkOperator: string },
  defaultSubOrgsFilter: FiltersInput,
  setCurrentPage: (page: number) => void,
  setPaginationSkip: (skip: number) => void,
  setFilters: (filters: FilterListInput | null) => void
) => {
  setCurrentPage(1);
  setPaginationSkip(0);

  const newFilters = validateTableFilters(data) as FilterListInput;
  setFilters({
    ...newFilters,
    filters: [
      ...(newFilters?.filters ? newFilters.filters : []),
      defaultSubOrgsFilter,
    ],
  } as FilterListInput);
};

const AutomationsTable: FC<automationTableProps> = (
  props: automationTableProps
) => {
  const {
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
    dispatch,
  } = useAppContext();
  const queryClient = useQueryClient();

  const timezone = usePreferredTimezone();

  const [tableRows, setTableRows] = useState<Automation[]>([]);

  const orgsHierarchy = useOrgsDownwardHierarchy(selectedOrganization, false);
  const defaultSubOrgsFilter = useMemo(
    () => ({
      operatorValue: TableFilterOperator.IsAnyOf,
      columnField: "orgName",
      value: JSON.stringify(orgsHierarchy),
    }),
    [orgsHierarchy]
  );

  const [filters, setFilters] = useState<FilterListInput | null>();
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    setFilters({
      filters: [defaultSubOrgsFilter],
      linkOperator: TableFilterLinkOperator.And,
    });
    if (orgsHierarchy.length > 0) {
      setIsEnabled(true);
    }
  }, [orgsHierarchy, defaultSubOrgsFilter]);

  const [sort, setSort] = useState<TableSortingInput>({
    field: "addedAt",
    order: SortOrder.Desc,
  });
  const [paginationSkip, setPaginationSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 100;

  const fileName = useExportedFileName("Automations");
  const { onRowClick, apiRef } = props;

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const sort = sortModel.map((sortItem) => ({
        field: sortItem.field,
        order: sortItem.sort as SortOrder,
      }));
      setSort(sort[0]);
    },
    [setSort]
  );

  const onFilterChange = useCallback(
    (data: { items: FiltersInput[]; linkOperator: string }) => {
      handleFilterChange(
        data,
        defaultSubOrgsFilter,
        setCurrentPage,
        setPaginationSkip,
        setFilters
      );
    },
    [defaultSubOrgsFilter, setCurrentPage, setPaginationSkip, setFilters]
  );

  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
    setPaginationSkip(pageSize * (page - 1));
  }, []);

  const {
    data: automationsData,
    isSuccess,
    isLoading: isDataLoading,
    refetch: refetchAutomationsList,
  } = useGetAutomationsQuery(
    {
      input: {
        search: undefined,
        sort,
        skip: paginationSkip,
        limit: pageSize,
        filterList: filters ? [filters] : null,
      },
    },
    {
      onSettled: (_, error: unknown) => {
        onSettledGetAutomations({ error, dispatch });
      },
      enabled: isEnabled,
    }
  );

  const updateAutomationOnSuccess = async (data: UpdateAutomationMutation) => {
    await onSuccessUpdateCallback({
      dispatch,
      updated: data.updateAutomation,
      queryClient,
      setAutomation: () => {},
      setLoading: () => {},
    });
  };

  const updateAutomationOnError = (error: unknown) => {
    updateAutomationOnErrorCallback({ error, dispatch, setLoading: () => {} });
  };

  const { mutate: updateAutomation, isLoading: isAutomationUpdating } =
    useUpdateAutomationMutation({
      onSuccess: updateAutomationOnSuccess,
      onError: updateAutomationOnError,
    });

  const updateAutomationMode = useCallback(
    (id: string, mode: AutomationMode) => {
      updateAutomation({
        input: {
          id,
          mode,
        },
      });
    },
    [updateAutomation]
  );

  const automationColumns = getAutomationColumns({
    timezone,
    updateAutomationMode,
  });

  useEffect(() => {
    props.refreshAutomations.current = refetchAutomationsList;
  }, [refetchAutomationsList, props]);

  useMemo(() => {
    const automations = automationsData?.getAutomations ?? [];
    setTableRows(
      getRows<typeof automations>(automations, automationColumns, "", "", "_id")
    );
    return automations;
  }, [automationsData?.getAutomations]); // eslint-disable-line react-hooks/exhaustive-deps

  useSpinner(isAutomationUpdating);

  return (
    <Box className="w-full h-[100%] pb-4">
      <Table
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterChange}
        onPageChange={onPageChange}
        currentPage={currentPage}
        data-testid="automations-table"
        tableType={TableViewType.Automations}
        showToolbar
        onRowClick={onRowClick}
        rows={tableRows ?? []}
        apiRef={apiRef}
        rowCount={tableRows?.length ?? 0}
        columns={automationColumns}
        loading={isDataLoading}
        error={!isDataLoading && !isSuccess ? true : null}
        searchThreshold={0.1}
        columnVisibilityModel={automationsVisibleColumns}
        searchExactMatch
        enableSearch
        disableRowGrouping
        pagination={true}
        searchKeys={searchKeys}
        sortKeys={searchKeys}
        searchMinLength={appConfig.searchMinLength}
        tableName="automations"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
      />
    </Box>
  );
};

export default memo(AutomationsTable);
