import { memo, SVGProps, FC } from "react";

interface WheelEndProps extends SVGProps<SVGSVGElement> {}

const WheelEnd: FC<WheelEndProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 94 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.22656 45.7692V53.9866L10.0045 55.8834L7.99356 58.5801L10.6623 66.352L13.9038 67.244L12.8783 70.4479L17.9257 76.9335L21.2826 76.7247L21.3517 80.0864L28.2317 84.5804L31.3385 83.294L32.4963 86.4518L40.4624 88.47L42.9831 86.243L45.1032 88.8535L53.2927 88.1751L54.9541 85.2503L57.8073 87.0319L65.3329 83.7309L65.9554 80.4262L69.2322 81.1847L75.2772 75.6191L74.7929 72.2902L78.1388 71.9431L82.0491 64.7161L80.5102 61.7257L83.5612 60.3107L84.9144 52.2062L82.4872 49.8773L84.9144 47.5484L83.5612 39.4439L80.5102 38.0289L82.0491 35.0385L78.1388 27.8115L74.7929 27.4644L75.2772 24.1367L69.2322 18.5711L65.9554 19.3296L65.3329 16.0225L57.8073 12.7227L54.9541 14.5031L53.2927 11.5795L45.1032 10.9011L42.9831 13.5116L40.4624 11.2858L32.4963 13.3028L31.3385 16.4606L28.2317 15.173L21.3517 19.6682L21.2826 23.0311L17.9257 22.8224L12.8783 29.3067L13.9038 32.5094L10.6623 33.4038L7.99356 41.1745L10.0045 43.8724L7.22656 45.7692Z"
        fill="#2F3744"
      />
      <path
        d="M13.3115 49.8759C13.3115 67.9683 27.978 82.6348 46.0692 82.6348C64.1617 82.6348 78.8281 67.9683 78.8281 49.8759C78.8281 31.7846 64.1617 17.1182 46.0692 17.1182C27.978 17.1182 13.3115 31.7846 13.3115 49.8759Z"
        fill="#434D5A"
      />
      <path
        d="M24.3691 49.8764C24.3691 61.862 34.0841 71.5781 46.0697 71.5781C58.054 71.5781 67.7702 61.862 67.7702 49.8764C67.7702 37.892 58.054 28.1759 46.0697 28.1759C34.0841 28.1759 24.3691 37.892 24.3691 49.8764Z"
        fill="#2F3744"
      />
      <g opacity="0.300003">
        <path
          d="M28.9287 49.8761C28.9287 59.347 36.6084 67.0098 46.0624 67.0098C52.2154 67.0098 57.6232 63.7755 60.6415 58.9004C62.2677 56.2778 63.2131 53.1928 63.2131 49.8761C63.2131 40.4051 55.5334 32.7423 46.0624 32.7423C39.6764 32.7423 34.1035 36.2424 31.1678 41.4172C29.7406 43.9051 28.9287 46.7911 28.9287 49.8761Z"
          fill="#EFF1F3"
        />
      </g>
      <g opacity="0.179993">
        <path
          d="M28.9287 49.8761C28.9287 59.347 36.6084 67.0098 46.0624 67.0098C52.2154 67.0098 57.6232 63.7755 60.6415 58.9004C57.5893 59.0825 54.5383 59.0157 51.503 58.5509C44.7347 57.506 38.0174 54.205 34.2188 48.4998C32.7601 46.3263 31.7977 43.9221 31.1678 41.4172C29.7406 43.9051 28.9287 46.7911 28.9287 49.8761Z"
          fill="#D7D9DD"
        />
      </g>
      <path
        d="M37.3063 3.5298C15.607 7.87816 0 26.8274 0 49.1299C0 50.0983 0.786418 50.8848 1.75609 50.8848C2.72455 50.8848 3.51097 50.0983 3.51097 49.1299C3.51097 28.6284 17.7721 11.1877 37.6522 7.03713L37.9883 10.4474L46.5212 4.332L36.958 -0.000579834L37.3063 3.5298Z"
        fill="white"
      />
      <path
        d="M5.39987 67.8145C4.52729 68.2356 4.16078 69.2842 4.5819 70.158L4.63045 70.2575C4.92657 70.8716 5.52245 71.209 6.15352 71.209C6.41809 71.209 6.69115 71.1483 6.94844 71.0245C7.82224 70.6034 8.16326 69.5039 7.74214 68.6313C7.32102 67.7575 6.27124 67.391 5.39987 67.8145Z"
        fill="#9ADEED"
      />
      <path
        d="M4.59746 59.6561C4.59625 59.6512 4.57076 59.5396 4.56955 59.5347C4.34746 58.5905 3.40449 58.0044 2.4603 58.2264C1.51733 58.4473 0.931155 59.3915 1.15203 60.3369L1.17266 60.4279C1.35835 61.2447 2.08166 61.7969 2.88385 61.7969C3.01128 61.7969 3.14113 61.7835 3.2722 61.7544C4.2176 61.5408 4.80984 60.6003 4.59746 59.6561Z"
        fill="#9ADEED"
      />
      <path
        d="M10.3973 76.5096C9.64001 77.1152 9.51743 78.2196 10.123 78.9769L10.191 79.063C10.5284 79.4829 11.0102 79.6953 11.5017 79.6953C11.8925 79.6953 12.2893 79.5618 12.6243 79.2936C13.3803 78.688 13.4689 77.5399 12.8646 76.7839C12.2578 76.0278 11.1546 75.904 10.3973 76.5096Z"
        fill="#9ADEED"
      />
      <path
        d="M36.887 91.9315C35.9416 91.7155 35.0023 92.3102 34.7887 93.2568C34.5751 94.201 35.1673 95.1403 36.1127 95.3539C36.1152 95.3539 36.2244 95.3806 36.2268 95.3806C36.353 95.4073 36.478 95.4219 36.6018 95.4219C37.3955 95.4219 38.0824 94.8551 38.2669 94.0371C38.4781 93.093 37.8312 92.1439 36.887 91.9315Z"
        fill="#9ADEED"
      />
      <path
        d="M27.8084 88.7409C26.9346 88.3186 25.8861 88.6863 25.465 89.5565C25.0438 90.4303 25.4091 91.4788 26.2817 91.9012L26.3812 91.9485C26.6203 92.065 26.8715 92.1172 27.1155 92.1172C27.7599 92.1172 28.3703 91.7398 28.6749 91.1063C29.0973 90.2337 28.6798 89.1621 27.8084 88.7409Z"
        fill="#9ADEED"
      />
      <path
        d="M19.7532 83.6711C19.7495 83.6662 19.6585 83.5946 19.6537 83.591C18.8903 82.9939 17.7884 83.1274 17.19 83.8883C16.5917 84.6529 16.724 85.7549 17.4874 86.3532L17.5602 86.4102C17.8842 86.6699 18.2702 86.7949 18.6549 86.7949C19.1707 86.7949 19.6792 86.5704 20.0275 86.1359C20.6331 85.3786 20.5105 84.2767 19.7532 83.6711Z"
        fill="#9ADEED"
      />
      <path
        d="M91.2927 49.1159C90.3242 49.1159 89.5378 49.8999 89.5378 50.8707C89.5378 71.3734 75.2767 88.8118 55.3966 92.9635L55.0617 89.5508L46.5264 95.6674L56.0896 100L55.7413 96.4696C77.4406 92.1225 93.0476 73.1732 93.0476 50.8707C93.0476 49.8999 92.2624 49.1159 91.2927 49.1159Z"
        fill="white"
      />
      <path
        d="M88.4184 29.7428C87.9961 28.8703 86.9476 28.5037 86.0762 28.9249C85.2036 29.3472 84.8359 30.3958 85.2582 31.2696C85.2606 31.272 85.3104 31.3739 85.3116 31.3788C85.6162 31.9989 86.2412 32.3594 86.8881 32.3594C87.1478 32.3594 87.4099 32.3023 87.6599 32.1798C88.5313 31.7514 88.8905 30.7004 88.4621 29.8314L88.4184 29.7428Z"
        fill="#9ADEED"
      />
      <path
        d="M90.1878 38.3125H90.1259C89.1562 38.3125 88.4014 39.0977 88.4014 40.0662C88.4014 41.0358 89.2181 41.8223 90.1878 41.8223C91.1563 41.8223 91.9427 41.0358 91.9427 40.0662C91.9427 39.0977 91.1563 38.3125 90.1878 38.3125Z"
        fill="#9ADEED"
      />
      <path
        d="M83.728 19.6297C83.728 12.8833 78.2619 7.42083 71.5167 7.42083C68.1028 7.42083 65.0263 8.82619 62.8079 11.092C60.593 8.82619 57.5129 7.42083 54.0978 7.42083C47.3526 7.42083 41.8877 12.8833 41.8877 19.6297C41.8877 22.9137 43.1923 25.8907 45.3052 28.0861L62.7581 45.4492L80.6515 27.7099C80.8469 27.4854 81.3518 26.8434 81.3518 26.8434C82.8409 24.8179 83.728 22.3264 83.728 19.6297Z"
        fill="#9BDEED"
      />
      <path
        d="M28.3873 67.8086L22.0462 40.6845L19.1274 51.3836H1.50586V49.0984H17.3835L22.2161 31.3761L28.0899 56.5014L31.289 38.6286L34.7405 49.3848H49.1497L55.1825 30.9756L61.5673 57.6871L66.9715 39.0534L69.6718 49.38L92.3601 49.2416L92.3735 51.5268L67.9084 51.6761L66.8562 47.6506L61.3537 66.6217L54.839 39.3641L50.805 51.67H33.073L31.9201 48.0729L28.3873 67.8086Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(WheelEnd);
