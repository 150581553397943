import { FC } from "react";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { TableViewType } from "../../../../graphql/operations";
import SensorsAirbagTable from "./Tables/Airbag/SensorsAirbagTable";
import SensorsAirSupplyTable from "./Tables/Airtank/SensorsAirSupplyTable";
import SensorsAtisAlphaTable from "./Tables/AtisAlpha/SensorsAtisAlphaTable";
import SensorsBatteriesTable from "./Tables/Batteries/SensorsBatteriesTable";
import SensorsCargoCameraTable from "./Tables/CargoCamera/SensorsCargoCameraTable";
import SensorsLiftgateTable from "./Tables/Liftgate/LiftgateTable";
import SensorsLiteSentryGammaTable from "./Tables/LiteSentryGamma/SensorsLiteSentryGammaTable";
import SensorsPsiWheelEndTable from "./Tables/PsiWheelEnd/SensorsPsiWheelEndTable";
import SensorsTPMSTable from "./Tables/TPMS/SensorsTPMSTable";
import SensorsTemperatureTable from "./Tables/Temperature/SensorsTemperatureTable";
import { SensorsData, SensorsGatewayTypes } from "./sensorsUtils";

export interface TableGatewayProps {
  type: SensorsGatewayTypes;
  orgId: string | undefined;
  sensorsData: SensorsData;
  onRowClick: (data: any) => void;
  onRowSelect: (data: any) => void;
  rowSelectionModel?: Array<number | string>;

  onFilterChange: (value: any) => void;
  onSortModelChange: (value: any) => void;
  onPageChange: (value: any) => void;
  refetchingData: boolean;
  totalTableRowsCount: number;
}

const tables: Record<SensorsGatewayTypes, React.FC<any>> = {
  [SensorsGatewayTypes.Voltage]: SensorsBatteriesTable,
  [SensorsGatewayTypes.TpmsBeta]: SensorsTPMSTable,
  [SensorsGatewayTypes.CargoCamera]: SensorsCargoCameraTable,
  [SensorsGatewayTypes.airSupply]: SensorsAirSupplyTable,
  [SensorsGatewayTypes.Airbag]: SensorsAirbagTable,
  [SensorsGatewayTypes.PsiWheelEnd]: SensorsPsiWheelEndTable,
  [SensorsGatewayTypes.AtisAlpha]: SensorsAtisAlphaTable,
  [SensorsGatewayTypes.Internal]: SensorsTemperatureTable,
  [SensorsGatewayTypes.LiteSentryGamma]: SensorsLiteSentryGammaTable,
  [SensorsGatewayTypes.Liftgate]: SensorsLiftgateTable,
};

const SensorsTableGateway: FC<TableGatewayProps> = (props) => {
  const {
    type,
    orgId,
    onRowClick,
    onRowSelect,
    onFilterChange,
    onSortModelChange,
    onPageChange,
    refetchingData,
    totalTableRowsCount,
    ...rest
  } = props;
  const gridApiRef = useGridApiRef();
  let tableType: TableViewType;
  const Component = tables[type];

  switch (type) {
    case SensorsGatewayTypes.Airbag:
      tableType = TableViewType.AirBagSensors;
      break;
    case SensorsGatewayTypes.CargoCamera:
      tableType = TableViewType.CargoCameraSensors;
      break;
    case SensorsGatewayTypes.AtisAlpha:
      tableType = TableViewType.AtisAlphaSensors;
      break;
    case SensorsGatewayTypes.Internal:
      tableType = TableViewType.TemperatureSensors;
      break;
    case SensorsGatewayTypes.LiteSentryGamma:
      tableType = TableViewType.LiteSentryGammaSensors;
      break;
    case SensorsGatewayTypes.PsiWheelEnd:
      tableType = TableViewType.PsiWheelEndSensors;
      break;
    case SensorsGatewayTypes.airSupply:
      tableType = TableViewType.SupplyPressureSensors;
      break;
    case SensorsGatewayTypes.TpmsBeta:
      tableType = TableViewType.TpmsBetaSensors;
      break;
    case SensorsGatewayTypes.Voltage:
      tableType = TableViewType.VoltageSensors;
      break;
    case SensorsGatewayTypes.Liftgate:
      tableType = TableViewType.LiftgateSensors;
      break;
    default:
      tableType = TableViewType.VoltageSensors;
      break;
  }

  return (
    <Component
      apiRef={gridApiRef}
      tableType={tableType}
      onRowClick={onRowClick}
      onRowSelect={onRowSelect}
      onFilterChange={onFilterChange}
      onSortModelChange={onSortModelChange}
      onPageChange={onPageChange}
      refetchingData={refetchingData}
      totalTableRowsCount={totalTableRowsCount}
      {...rest}
    />
  );
};

export default SensorsTableGateway;
