import React from "react";
import { Checkbox } from "@mui/material";

interface ConfigurationDialogAdditionalElementProps {
  checked: boolean;
  onChange: () => void;
}

const ConfigurationDialogAdditionalElement: React.FC<
  ConfigurationDialogAdditionalElementProps
> = ({ checked, onChange }) => {
  return (
    <div>
      <Checkbox checked={checked} onChange={onChange} color="primary" />
      <span className="font-bold !text-typography-secondary">
        Don’t show again
      </span>
    </div>
  );
};

export default ConfigurationDialogAdditionalElement;
