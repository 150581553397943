import { AutocompleteElement } from "react-hook-form-mui";
import { Chip, Grid } from "@mui/material";

interface AssetsParametersProps {
  setAssetNameSearch: (newInputValue: string) => void;
  form: any;
  setSelectedAsset: (newInputValue: any) => void;
  setAssetsOptionsList: (newInputValue: any) => void;
  watchAssetTypeValue?: string;
  hideInputValue: boolean;
  isFetching: boolean;
  assetsOptionsList: { id: string; label: string }[];
  watchAssetsValue?: {
    id: string | undefined;
    label: string | null | undefined;
  }[];
  assetTypeOptions: {
    id: string;
    label: string;
    value: string;
  }[];
  assetsSelectTestId?: string;
  assetTypeSelectTestId?: string;
}

export const AssetsParameters = ({
  setAssetNameSearch,
  form,
  setSelectedAsset,
  setAssetsOptionsList,
  watchAssetTypeValue,
  hideInputValue,
  isFetching,
  assetsOptionsList,
  watchAssetsValue,
  assetTypeOptions,
  assetsSelectTestId,
  assetTypeSelectTestId,
}: AssetsParametersProps) => {
  return (
    <>
      <Grid item xs={12} md={6} lg={6} xl={4} data-testid={assetsSelectTestId}>
        <AutocompleteElement
          autocompleteProps={{
            noOptionsText: "No options",
            onInputChange: (event, newInputValue) => {
              if (newInputValue) {
                setAssetNameSearch(newInputValue);
              }
            },
            onChange: (event, newInputValue) => {
              if (newInputValue) {
                form.setValue("parameters.assetIds", newInputValue);
                setSelectedAsset(newInputValue);
                setAssetsOptionsList([]);
              }
            },
            autoComplete: true,
            blurOnSelect: true,
            disabled: !!watchAssetTypeValue,
            readOnly: !!watchAssetTypeValue,
            renderTags: (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={
                    hideInputValue || !option.label ? "loading" : option.label
                  }
                  {...getTagProps({ index })}
                />
              )),
          }}
          multiple
          loading={isFetching || hideInputValue}
          label="Asset(s)"
          control={form.control}
          name="parameters.assetIds"
          options={assetsOptionsList}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid={assetTypeSelectTestId}
      >
        <AutocompleteElement
          autocompleteProps={{
            disabled: !!watchAssetsValue?.length,
            readOnly: !!watchAssetsValue?.length,
          }}
          matchId={true}
          label="Asset Type"
          control={form.control}
          name="parameters.assetType"
          options={assetTypeOptions}
        />
      </Grid>
    </>
  );
};
