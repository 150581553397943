import { flattenHierarchy, HierarchyNode } from "../../../utils";
import { HierarchyStateItem, SelectedValues } from "./types";

export const SelectAllOption: HierarchyStateItem = {
  value: "all",
  label: "Select All",
  children: [],
  level: 0,
  allChildrenLabels: [],
  allChildrenValues: [],
  expanded: false,
  selected: false,
  order: -1,
  visible: true,
};

const buildState = (
  item: HierarchyNode,
  selectedValues: SelectedValues,
  order: number
): HierarchyStateItem => {
  const flatHierarchy = flattenHierarchy(item.children);
  const allChildrenLabels = flatHierarchy.map((i) => i.name);
  const allChildrenValues = flatHierarchy.map((i) => i.id);
  return {
    value: item.id,
    label: item.name,
    level: item.level,
    order,
    allChildrenLabels,
    allChildrenValues,
    expanded: allChildrenValues.some((value) => selectedValues.includes(value)),
    selected: selectedValues.includes(item.id),
    children: item.children.map((child, i) =>
      buildState(child, selectedValues, i)
    ),
    visible: true,
  };
};

export const buildHierarchyState = (
  hierarchy: HierarchyNode[],
  selectedValues: SelectedValues
): HierarchyStateItem[] =>
  hierarchy.map((item, i) => buildState(item, selectedValues, i));

export const mutateHierarchyState = (
  hierarchy: Readonly<HierarchyStateItem[]>,
  callback: (item: HierarchyStateItem) => HierarchyStateItem
): HierarchyStateItem[] =>
  hierarchy.map((item) => {
    const mutated = callback(item);
    return {
      ...mutated,
      children: mutateHierarchyState(mutated.children, callback),
    };
  });

export const getHiddenClass = (visible: boolean) => (visible ? "" : "hidden");

export const containsText = (value: string, text: string) =>
  text ? value.toLowerCase().indexOf(text.toLowerCase()) > -1 : true;
