import { memo, SVGProps, FC } from "react";

interface HamburgerProps extends SVGProps<SVGSVGElement> {}

const Hamburger: FC<HamburgerProps> = (props) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 5.01L0 5V7H18V5.01ZM0 10H12V12H0V10ZM18 0H0V2.01L18 2V0Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(Hamburger);
