import { FC, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useGetAutomationQuery } from "../../../../graphql/operations";
import Page from "../../../../shared/components/Page";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { Automation } from "./Automation";

export const AutomationView: FC = () => {
  const page = useRef();
  const { id } = useParams();

  const isTemplate = id === "template";

  const { data: automationData, isLoading: isDataLoading } =
    useGetAutomationQuery(
      {
        input: {
          filters: {
            id: id,
          },
        },
      },
      {
        enabled: !isTemplate,
        cacheTime: 0,
      }
    );

  const automation = useMemo(() => {
    return automationData?.getAutomation;
  }, [automationData]);

  useSpinner(isDataLoading && !isTemplate);

  return (
    <Page ref={page} className={"bg-background"}>
      {(automation || isTemplate) && (
        <Automation isTemplate={isTemplate} automation={automation} />
      )}
    </Page>
  );
};
