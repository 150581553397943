import React, { FC } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

interface AlertParametersContainerProps {
  children?: React.ReactNode;
}

export const AlertParametersContainer: FC<AlertParametersContainerProps> = ({
  children,
}: AlertParametersContainerProps) => {
  return (
    <Paper elevation={0} className="mb-6">
      <Box className="p-12">
        <Typography className="!pb-8 !text-lg" variant="h6" component="div">
          Parameters
        </Typography>
        <Grid container spacing={6}>
          {children}
        </Grid>
      </Box>
    </Paper>
  );
};
