import { Grid } from "@mui/material";
import { MergedProfileForAsset } from "../../../../../../../../../graphql/operations";
import { TiresSVG } from "../../../../../../../../../shared/components/Svg";
import PressureChart from "../../sensors/PressureChart";
import TemperatureChart from "../../sensors/TemperatureChart";
import { Axle } from "./TiresChartBody";

type TiresChartRowProps = {
  axle: Axle;
  imei: string;
  customerOrgId: string;
  numberOfAxles: number;
  numberOfWheels: number;
  sensorProfile?: MergedProfileForAsset | null;
};

const TiresChartRow: React.FC<TiresChartRowProps> = ({
  axle,
  imei,
  customerOrgId,
  numberOfAxles,
  numberOfWheels,
  sensorProfile,
}) => {
  const tiresAxleImage = (
    <TiresSVG
      numberOfWheels={numberOfWheels}
      numberOfAxles={numberOfAxles}
      activeAxle={axle}
    />
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      data-testid="tires-chart-row"
    >
      <Grid item lg={6} data-testid="tires-chart-temperature">
        <TemperatureChart
          axle={axle}
          imei={imei}
          customerOrgId={customerOrgId}
          sensorConfig={sensorProfile?.configuration.tpmsBeta?.temperature}
          tiresAxleImage={tiresAxleImage}
        />
      </Grid>
      <Grid item lg={6} data-testid="tires-chart-pressure">
        <PressureChart
          axle={axle}
          imei={imei}
          customerOrgId={customerOrgId}
          sensorConfig={
            sensorProfile?.configuration.tpmsBeta?.pressure?.match?.thresholds
          }
          tiresAxleImage={tiresAxleImage}
        />
      </Grid>
    </Grid>
  );
};

export default TiresChartRow;
