import { FC } from "react";
import { Divider, Typography } from "@mui/material";
import { CheckBoxWithLabel } from "../../CheckBoxWithLabel/CheckBoxWithLabel";
import { IRoles, StateObject } from "../interfaces";

interface RolesListProps {
  selectedRoles: StateObject;
  setSelectedRoles: (roles: StateObject) => void;
  roles: IRoles[];
}

export const RolesList: FC<RolesListProps> = ({
  selectedRoles,
  setSelectedRoles,
  roles,
}) => {
  const handleRolesChange = (role: string, state: boolean) => {
    setSelectedRoles({ ...selectedRoles, [role]: state });
  };

  return (
    <div className="mb-6">
      <Typography className="!mb-4 !text-sm !font-bold">Roles</Typography>
      <div
        className="mb-6 h-36 overflow-auto"
        data-testid="subscribers-roles-checkbox-group"
      >
        {roles.map((role) => {
          return (
            <div key={role.value} className="hover:bg-brand-transparent">
              <CheckBoxWithLabel
                name={role.value}
                label={role.label}
                checked={selectedRoles[role.value]}
                onValueChange={handleRolesChange}
                dataTestId={`subscribers-role-checkbox-${role.value}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
