import { Box, Skeleton } from "@mui/material";
import { nanoid } from "nanoid";

export interface GraphOverlayProps {
  daysCount: number;
  hoursCount?: number;
}

export const GraphOverlay: React.FC<GraphOverlayProps> = ({
  daysCount,
  hoursCount = 24,
}) => (
  <Box
    data-testid="graph-overlay-wrapper"
    sx={{ display: "flex", width: "100%" }}
  >
    {Array.from({ length: daysCount }).map((_, dayIndex) => (
      <Box sx={{ width: "100%" }} key={nanoid()}>
        {Array.from({ length: hoursCount }).map((_, hourIndex) => (
          <Skeleton
            key={nanoid()}
            variant="rounded"
            sx={{
              height: "36px",
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              margin: "4px 4px 8px 4px",
            }}
            data-testid={`graph-overlay-box-${dayIndex}-${hourIndex}`}
          />
        ))}
      </Box>
    ))}
  </Box>
);
