import { UseFormReturn } from "react-hook-form";
import { SelectElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { Asset } from "../../../../../../graphql/operations";
import { Breakpoints } from "../AssetForm";
import {
  yesNoOptions,
  ASSET_RAIL_COMPLIANCE_FORM_FIELDS,
  AssetRailComplianceFields,
  AssetFormSectionTitles,
} from "../assetFormUtils";
import { AccordionWrapper } from "../components/AccordionWrapper";

interface RailComplianceProps {
  showDetailsHeader?: boolean;
  form: UseFormReturn<Partial<Asset>>;
  breakpoints: Breakpoints;
  asset?: Partial<Asset>;
  disableAllFields?: boolean;
}

export const RailCompliance = ({
  showDetailsHeader = false,
  form,
  breakpoints,
  disableAllFields = false,
}: RailComplianceProps) => {
  return (
    <AccordionWrapper
      accordionSummaryTitle={AssetFormSectionTitles.RailCompliance}
      showDetailsHeader={showDetailsHeader}
      isDashboardSettings={showDetailsHeader}
    >
      <Grid
        container
        rowGap={showDetailsHeader ? 3 : 0}
        className={`!text-primary ${
          showDetailsHeader ? "formSection" : "secondDrawerSection"
        }`}
        spacing={6}
      >
        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_RAIL_COMPLIANCE_FORM_FIELDS[
                AssetRailComplianceFields.FlushMountedNose
              ].name
            }
            label={
              ASSET_RAIL_COMPLIANCE_FORM_FIELDS[
                AssetRailComplianceFields.FlushMountedNose
              ].label
            }
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_RAIL_COMPLIANCE_FORM_FIELDS[
                AssetRailComplianceFields.GridExtensionPlate
              ].name
            }
            label={
              ASSET_RAIL_COMPLIANCE_FORM_FIELDS[
                AssetRailComplianceFields.GridExtensionPlate
              ].label
            }
            options={yesNoOptions}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_RAIL_COMPLIANCE_FORM_FIELDS[
                AssetRailComplianceFields.LiftPads
              ].name
            }
            label={
              ASSET_RAIL_COMPLIANCE_FORM_FIELDS[
                AssetRailComplianceFields.LiftPads
              ].label
            }
            options={yesNoOptions}
          />
        </Grid>
      </Grid>
    </AccordionWrapper>
  );
};
