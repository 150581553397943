import { useState, SyntheticEvent } from "react";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useDebounce } from "use-debounce";
import { useAppContext } from "../../../context/AppContext";
import { GeoSearchResult, useGeoSearch } from "../Selector/Selector.queries";

export interface SearchAddressFieldProps {
  placeholder?: string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    option: GeoSearchResult | null
  ) => void;
}

export const SearchAddressField: React.FC<SearchAddressFieldProps> = ({
  placeholder,
  onChange,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();
  // States
  const [selectedValue, setSelectedValue] = useState<GeoSearchResult | null>(
    null
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [input] = useDebounce(inputValue, appConfig.debounceTime);

  // Queries
  const { data: geoSearchResult, isFetching: isFetchingGeoSearch } =
    useGeoSearch({ searchText: input, limit: 20 }, !!input);

  // Handlers
  const onChangeHandler = (
    event: SyntheticEvent<Element, Event>,
    option: GeoSearchResult | null
  ) => {
    setSelectedValue(option);
    onChange(event, option);
  };

  const onInputChangeHandler = (
    _: SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setSelectedValue(null);
    setInputValue(value);
  };

  const renderInputHandler = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      data-testid="search-address-input"
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            {isFetchingGeoSearch ? (
              <CircularProgress
                size={20}
                data-testid="search-address-loading-icon"
              />
            ) : (
              <ZoomInMapIcon
                sx={{ width: "16px", height: "16px" }}
                data-testid="search-address-icon"
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <Autocomplete
      id="search-address-autocomplete"
      data-testid="search-address-autocomplete"
      value={selectedValue}
      options={geoSearchResult ?? []}
      getOptionLabel={(option) => option.formatted}
      isOptionEqualToValue={(option, value) =>
        option.formatted === value.formatted
      }
      onChange={onChangeHandler}
      onInputChange={onInputChangeHandler}
      renderInput={renderInputHandler}
      sx={{
        "& .MuiInput-root": {
          paddingRight: "6px !important",
        },
      }}
    />
  );
};
