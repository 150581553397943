import { FC, useEffect } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { TextFieldElement, CheckboxElement } from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ThemeProvider,
  Grid,
  IconButton,
} from "@mui/material";
import * as yup from "yup";
import { GetTableViewPreferencesResult } from "../../../graphql/operations";
import { useFormTheme } from "../../hooks/theme/useFormTheme";
import useBreakpoint from "../../hooks/useBreakpoint";
import { Button as SubmitButton, TextButton } from "../Button";
import Text from "../Text";

export interface CreateEditTableLayoutDialogProps {
  open: boolean;
  isLoading: boolean;
  processing: boolean;
  isSharedDisabled: boolean;
  type: TableLayoutDialogType | undefined;
  selectedTableLayout:
    | (Partial<GetTableViewPreferencesResult> & { setAsDefault?: boolean })
    | undefined;
  onClose: () => void;
  onSubmit: (formData: FieldValues) => void;
}

export enum TableLayoutDialogType {
  Create = "create",
  Edit = "edit",
  Delete = "delete",
}

export const schema = yup.object().shape({
  name: yup.string().required("Field is required!"),
  shared: yup.boolean(),
  setAsDefault: yup.boolean(),
});

const CreateEditTableLayoutDialog: FC<CreateEditTableLayoutDialogProps> = ({
  open,
  isLoading,
  processing,
  isSharedDisabled,
  type,
  selectedTableLayout,
  onClose,
  onSubmit: onSubmitProp,
}) => {
  const formTheme = useFormTheme();
  const isMobile = useBreakpoint("down", "sm");

  const isEditDialog = type === TableLayoutDialogType.Edit;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      shared: false,
      setAsDefault: false,
    },
  });

  useEffect(() => {
    if (isEditDialog) {
      reset({
        name: selectedTableLayout?.name ?? "",
        shared: selectedTableLayout?.isPublic ?? false,
        setAsDefault: selectedTableLayout?.setAsDefault,
      });
    }
  }, [
    isEditDialog,
    reset,
    selectedTableLayout?.isPublic,
    selectedTableLayout?.name,
    selectedTableLayout?.setAsDefault,
  ]);

  const onSubmit = (formData: FieldValues) => {
    onSubmitProp(formData);
  };

  return (
    <Dialog
      data-testid={isEditDialog ? "edit-table-dialog" : "save-table-dialog"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "32px 24px 24px 24px",
          width: "100%",
          maxWidth: "680px !important",
        },
      }}
    >
      <DialogTitle className="flex justify-between items-center !p-0 !pb-[32px]">
        <Text
          classes="!text-2xl !font-semibold capitalize !text-typography-secondary "
          dataTestId="table-dialog-title"
        >
          {isEditDialog ? "Edit Table View" : "Save New Table View"}
        </Text>
        <IconButton
          aria-label="close"
          onClick={onClose}
          data-testid="table-dialog-close-icon-btn"
          className="h-6 w-6 flex align-center"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ThemeProvider theme={formTheme}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent className="!p-0 !pb-[24px]">
            <Grid
              container
              className="bg-background topPaddingDrawerSection !pt-0"
            >
              <Grid
                item
                data-testid="transfer-asset-autocomplete"
                xs={12}
                className="!pt-0"
              >
                <TextFieldElement
                  data-testid="name-control"
                  fullWidth
                  control={control}
                  sx={{ marginBottom: "24px" }}
                  disabled={isLoading || processing}
                  name="name"
                  required
                  label="View Name"
                />
                <Grid item sm={12} className="!pt-0">
                  <Grid container>
                    <Grid item sm={6} className="!p-0">
                      <CheckboxElement
                        name="shared"
                        label="Shared"
                        control={control}
                        disabled={isSharedDisabled}
                      />
                    </Grid>
                    <Grid item sm={6} className="!p-0">
                      <CheckboxElement
                        name="setAsDefault"
                        label="Set as Default"
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: isMobile ? "center" : "end",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <TextButton
              data-testid="cancel-action"
              text="Cancel"
              size="medium"
              theme="blue"
              disabled={isLoading || processing}
              onClick={onClose}
            />

            <SubmitButton
              sx={{ padding: "24px", margin: 0 }}
              dataTestid="submit-action"
              text={processing ? "Saving..." : "Save"}
              disabled={isLoading || processing}
              size="medium"
              theme="blue"
              variant="default"
              type="submit"
              onClick={() => undefined}
            />
          </DialogActions>
        </form>
      </ThemeProvider>
    </Dialog>
  );
};

export default CreateEditTableLayoutDialog;
