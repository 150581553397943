import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";

export const useBrakesTabTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      palette: {
        primary: {
          main: ColorsPalette.PrimaryBlue,
        },
      },
      components: {
        MuiTextField: {
          defaultProps: {
            variant: "outlined",
          },
        },
        MuiFormHelperText: {
          defaultProps: {
            variant: "outlined",
          },
        },
        MuiInputLabel: {
          defaultProps: {
            variant: "outlined",
          },
        },
      },
    })
  );
};
