import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";

export const useActivityLogDialogTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiDialog: {
          styleOverrides: {
            paper: { padding: "1.5rem", borderRadius: "0.5rem" },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: { padding: "0" },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: { padding: "0", marginTop: "1.5rem" },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: { padding: "0", marginTop: "-0.25rem" },
          },
        },
      },
    })
  );
};
