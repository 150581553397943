import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, SvgIconTypeMap, ThemeProvider, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { OverridableComponent } from "@mui/types";
import { round, uniq, toNumber } from "lodash";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import tinycolor from "tinycolor2";
import { v4 } from "uuid";
import { useAppContext } from "../../../../context/AppContext";
import { ColorsPalette } from "../../../../design-system/colors-palette";
import { formatNumber } from "../../../../utils/formatters";
import { useCurrentTheme } from "../../../hooks/theme/useCurrentTheme";
import { themes } from "../../../hooks/theme/utils";
import { HorizontalBarChartOverlay } from "../../LoadingOverlaySkeletons/HorizontalBarChartLoader/HorizontalBarChartOverlay";
import BaseDashboardWidget from "../BaseDashboardWidget";
import { NoDataAvailableComponent } from "../EmptyWidgetState/NoDataAvailable";

type HorizontalBarProps = {
  x: number;
  y: number;
  width: number;
  value: number;
  height: number;
  total: number;
  fill: string;
  background: {
    width: number;
  };
  showPercentages: boolean;
  theme: Theme;
  brightnessThreshold: number;
  percent?: number;
  widgetContainerElement?: HTMLDivElement | null;
  labelColor?: string;
};

const PERCENTAGE_BOX_WIDTH = 70;
const DEFAULT_BAR_WIDTH = 40;

const HorizontalBar: FC<HorizontalBarProps> = (props) => {
  const {
    x,
    y,
    width,
    value,
    height,
    total,
    fill,
    background,
    showPercentages,
    theme,
    brightnessThreshold,
    percent,
    widgetContainerElement,
  } = props;
  const [textWidth, setTextWidth] = useState(0);
  const themeBasedColor = useMemo(
    () =>
      theme.palette.mode === themes.light
        ? ColorsPalette.BrightWhite
        : ColorsPalette.FlatBlack,
    [theme]
  );

  const parsedColor = tinycolor(!value ? themeBasedColor : fill);
  const textRef = useRef<SVGTextElement>(null);
  useEffect(() => {
    if (textRef?.current?.getBBox) {
      const bbox = textRef.current.getBBox();
      setTextWidth(bbox.width);
    }
  }, [value, textRef]);

  const percentageBoxOffset = showPercentages ? PERCENTAGE_BOX_WIDTH : 0;
  const percentCalculated = round(percent ?? (value / total) * 100, 1);

  const backgroundColorChart = useMemo(
    () =>
      (widgetContainerElement &&
        getComputedStyle(widgetContainerElement?.childNodes?.[0] as Element)
          .backgroundColor) ||
      "#000",
    [widgetContainerElement]
  );

  // calculate width of the bar
  const _barWidth = useMemo(() => {
    // Ensure parent ref is present and that we want to show percentages at all
    if (!widgetContainerElement || !showPercentages) return width;

    // Calculate the available width for the bar (minus the percentage box and padding)
    const availableWidth =
      widgetContainerElement.clientWidth -
      (percentageBoxOffset + PERCENTAGE_BOX_WIDTH);

    // Calculate the width of the bar itself based on the percentage value
    const calculatedBarWidth = (availableWidth * percentCalculated) / 100;

    return calculatedBarWidth;
  }, [
    widgetContainerElement,
    percentCalculated,
    showPercentages,
    percentageBoxOffset,
    width,
  ]);

  // depending of the width of the text in bar determine the color of the text
  const color = useMemo(() => {
    if (textWidth > _barWidth) {
      // text is wider of the bar so calc contrast between chart background and
      return tinycolor(backgroundColorChart).getBrightness() >
        brightnessThreshold
        ? ColorsPalette.FlatBlack
        : ColorsPalette.BrightWhite;
    } else {
      return parsedColor.getBrightness() > brightnessThreshold
        ? ColorsPalette.FlatBlack
        : ColorsPalette.BrightWhite;
    }
  }, [
    textWidth,
    backgroundColorChart,
    parsedColor,
    _barWidth,
    brightnessThreshold,
  ]);
  // set barWidth to a default if calculated _barWidth is smaller
  const barWidth = useMemo(
    () =>
      toNumber(_barWidth) > DEFAULT_BAR_WIDTH
        ? toNumber(_barWidth)
        : DEFAULT_BAR_WIDTH,
    [_barWidth]
  );

  return (
    <g>
      {/* Bar */}
      <rect
        x={x}
        y={y}
        width={barWidth}
        height={height}
        fill={fill}
        rx={4}
        ry={4}
      />

      {/* Label */}
      <text
        ref={textRef}
        x={10}
        y={y + height / 2}
        textAnchor="start"
        dominantBaseline="middle"
        fontSize="18px"
        fontFamily="Montserrat"
        fontWeight="500"
        fill={color}
      >
        {formatNumber(value ?? 0)}
      </text>

      {showPercentages && (
        <>
          {/* Percentage Rect */}
          <rect
            x={background.width - percentageBoxOffset}
            y={y}
            rx={4}
            ry={4}
            width={PERCENTAGE_BOX_WIDTH}
            height={height}
            fill={ColorsPalette.ExtraLightBlue}
          />
          {/* Percentage Text */}
          <text
            x={background.width - (percentageBoxOffset - 10)}
            y={y + height / 2}
            dominantBaseline="middle"
            fontSize="18px"
            fontFamily="Montserrat"
            fontWeight="500"
            fill={ColorsPalette.RichBlack}
          >
            {`${percentCalculated}%`}
          </text>
        </>
      )}
    </g>
  );
};

type HorizontalBarTickProps = {
  x: number;
  y: number;
  payload: {
    value: string;
  };
  data: HorizontalBarChartData[];
};

const HorizontalBarTick: FC<HorizontalBarTickProps> = ({
  x,
  y,
  payload: { value },
  data,
}) => {
  // find an icon for the bar
  const barData = data.find((point) => point.name === value);
  const CustomIcon = barData?.icon;

  const iconSize = 24;
  /*
 TODO: Fix
 https://phillips-connect.atlassian.net/browse/PRJIND-8515
 Find a way to fix this (the 'y' attribute on the bars is NaN if we have duplicate values which breaks the chart)
*/
  const labelsList = data.map(({ name }) => name);
  if (labelsList?.length > uniq(labelsList)?.length)
    console.warn(
      "[ Horizontal Bar Chart ] You have multiple entries in your data with the same name, this will cause the chart to break.",
      labelsList
    );

  return (
    <>
      {CustomIcon && (
        <CustomIcon
          x={x - 5}
          y={y - 10}
          width={iconSize}
          height={iconSize}
          fontSize="small"
          style={{ color: barData.iconColor }}
        />
      )}

      <text
        x={CustomIcon ? x + iconSize : x - 8}
        y={y + 10}
        textAnchor="start"
        fontSize="18px"
        fontFamily="Montserrat"
        fontWeight="500"
        fill="var(--primary)"
      >
        {value}
      </text>
    </>
  );
};

export type HorizontalBarChartData = {
  name: string;
  value: number;
  color?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  iconColor?: string;
  percent?: number;
};

type HorizontalBarChartProps = {
  title: string;
  showXAxis?: boolean;
  data: HorizontalBarChartData[];
  isEditMode?: boolean;
  subtitle?: string;
  showTotal?: boolean;
  isLoading: boolean;
  showPercentages?: boolean;
  totalRecords?: number;
  minPointSize?: number;
  isTotalVisible?: boolean;
  noPermission?: boolean;
};

export const HorizontalBarChart: FC<HorizontalBarChartProps> = ({
  title,
  showXAxis = true,
  data,
  isEditMode,
  subtitle,
  isLoading,
  showTotal,
  showPercentages,
  totalRecords,
  minPointSize,
  isTotalVisible = true,
  noPermission,
}) => {
  const theme = useCurrentTheme();

  const {
    state: {
      appConfig: { brightnessThreshold },
    },
  } = useAppContext();

  const [widgetContainerElement, setWidgetContainerElement] =
    useState<HTMLDivElement | null>(null);

  return (
    <ThemeProvider theme={theme}>
      <div ref={setWidgetContainerElement}>
        <BaseDashboardWidget
          title={title}
          isLoading={isLoading}
          isEditMode={isEditMode}
          noPermission={noPermission}
        >
          {isLoading ? (
            <HorizontalBarChartOverlay />
          ) : (
            <>
              {!totalRecords ? (
                <NoDataAvailableComponent title="No data available" />
              ) : (
                <>
                  {showTotal && (
                    <Box
                      className="p-2 pt-0"
                      data-testid="hor-bar-chart--subtitle-wrapper"
                    >
                      <Box>
                        <Typography className="!text-lg !font-semibold">
                          {subtitle}
                        </Typography>
                      </Box>
                      {isTotalVisible && (
                        <Box>
                          <Typography className="!text-4xl !font-semibold">
                            {totalRecords !== undefined &&
                              formatNumber(totalRecords, { locale: "en-US" })}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </>
              )}

              {data?.length > 0 && totalRecords !== 0 && (
                <ResponsiveContainer width="100%" height={data.length * 100}>
                  <BarChart
                    layout="vertical"
                    data={data}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 0,
                      left: 0,
                    }}
                  >
                    <XAxis
                      type="number"
                      hide={!showXAxis}
                      axisLine={false}
                      tickLine={false}
                    />
                    <YAxis
                      dataKey="name"
                      type="category"
                      scale="band"
                      tickLine={false}
                      axisLine={false}
                      mirror
                      tick={(props) => (
                        <HorizontalBarTick {...props} data={data} />
                      )}
                    />
                    <Bar
                      dataKey="value"
                      barSize={42}
                      minPointSize={minPointSize}
                      shape={(props) => (
                        <HorizontalBar
                          {...props}
                          total={totalRecords}
                          showPercentages={showPercentages}
                          theme={theme}
                          brightnessThreshold={
                            theme.palette.mode === themes.light
                              ? 110
                              : brightnessThreshold
                          } // TMP use different brightnessThreshold for light theme
                          widgetContainerElement={widgetContainerElement}
                        />
                      )}
                    >
                      {data.map((props) => (
                        <Cell
                          key={`cell-${v4()}`}
                          fill={props.color ?? ColorsPalette.PrimaryBlue}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </>
          )}
        </BaseDashboardWidget>
      </div>
    </ThemeProvider>
  );
};
