interface LiftgateBatteryIconProps {
  statusIcon: React.ReactNode;
  width?: number;
  height?: number;
}

const LiftgateBatteryIcon: React.FC<LiftgateBatteryIconProps> = ({
  statusIcon,
  width = 340,
  height = 344,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 340 344`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M122.001 16C122.001 8.8203 127.821 3 135.001 3H337.001V297H135.001C127.821 297 122.001 291.18 122.001 284V16Z"
        fill="transparent"
      />
      <path
        d="M122.001 16C122.001 8.8203 127.821 3 135.001 3H337.001V297H135.001C127.821 297 122.001 291.18 122.001 284V16Z"
        stroke="#808080"
        strokeWidth="6"
      />
      <svg x="212" y="130" width={32} height={32}>
        {statusIcon}
      </svg>
      <rect x="4" y="206" width="83" height="65" rx="4" fill="transparent" />
      <rect
        x="4"
        y="206"
        width="83"
        height="65"
        rx="4"
        stroke="#808080"
        strokeWidth="8"
      />
      <rect x="25" y="138" width="62" height="51" rx="4" fill="transparent" />
      <rect
        x="25"
        y="138"
        width="62"
        height="51"
        rx="4"
        stroke="#808080"
        strokeWidth="8"
      />
      <path
        d="M11 291.98H77.3174C80.2552 291.98 83.4098 291.73 86.5 291.049C94.3445 289.319 101.774 284.802 104.186 274.5C104.764 272.031 105.054 269.23 104.993 266.056C104.575 244.415 104.819 92.5016 104.993 58.5"
        stroke="#808080"
        strokeWidth="6"
      />
      <circle
        cx="193.063"
        cy="296.5"
        r="35.5"
        fill="transparent"
        stroke="#808080"
        strokeWidth="24"
      />
    </svg>
  );
};

export default LiftgateBatteryIcon;
