import {
  DATE_TIME_FORMAT_SHORT,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../utils";
import { usePreferredTimezone } from "./usePreferredTimezone";

export const useDateInPreferredTimezone = (
  date: Date | string | undefined | null,
  format: string = DATE_TIME_FORMAT_SHORT
) => {
  const timezone = usePreferredTimezone();
  const timezoneDate =
    date &&
    formatDateInTimezone(date, format, mapTimezoneToIANAValue(timezone));

  return timezoneDate ?? "N/A";
};
