import { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { formatNumber } from "../../../utils/formatters";

const paginationCustomization = {
  "& .MuiPaginationItem-root": {
    color: "var(--typography)",
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    fontWeight: 800,
    color: "var(--dashboard_subheader__bg)",
    backgroundColor: "var(--app_header_rev__bg)",
  },
  "& .MuiPaginationItem-root.Mui-selected:hover": {
    fontWeight: 800,
    color: "var(--app_header_rev__bg)",
    backgroundColor: "var(--dashboard_subheader__bg)",
  },
};

export type TableFooterProps = {
  page: number;
  pages: number;
  onPageChange: (page: number) => void;
  rowsPerPage: number;
  totalRows: number;
  selectedCount: number;
  pagination: boolean;
  noBorder?: boolean;
  noPx?: boolean;
};

const TableFooter = ({
  page,
  pages,
  onPageChange,
  rowsPerPage,
  totalRows,
  selectedCount,
  pagination,
  noBorder,
  noPx,
}: TableFooterProps) => {
  const [currentPage, setCurrentPage] = useState(page);

  /**
   * programmatically Controlled page change
   * If context page changes, we have to trigger the state here, so it's correctly updated
   */
  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const displayedFromRow = (currentPage - 1) * rowsPerPage + 1;
  const displayedToRow =
    currentPage * rowsPerPage > totalRows
      ? totalRows
      : currentPage * rowsPerPage;
  const rowsText = `${displayedFromRow} - ${displayedToRow} of ${formatNumber(
    totalRows
  )}`;
  const rowSelected =
    selectedCount > 0 ? `${formatNumber(selectedCount)} selected` : "";

  return (
    <>
      {pagination && (
        <Box
          sx={{
            borderTop: `${noBorder ? "" : "1px solid rgba(224, 224, 224, 1)"}`,
          }}
          className={`items-center justify-between ${
            noPx ? "" : "px-4 md:px-7"
          } py-2 text-sm md:flex md:py-4`}
          data-testid="pagination-container"
        >
          <Typography>{rowSelected}</Typography>
          <div className="mt-2 flex items-center justify-end md:mt-0">
            <div className="mr-3">{rowsText}</div>
            <Pagination
              data-testid={"pagination-component"}
              onChange={(event, value) => {
                setCurrentPage(value);
                onPageChange(value);
              }}
              page={currentPage}
              size="small"
              hidePrevButton
              hideNextButton
              count={pages}
              shape="rounded"
              sx={paginationCustomization}
            />
          </div>
        </Box>
      )}
    </>
  );
};

export default memo(TableFooter);
