import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { transformers } from "../../../../../utils";

export type GeofenceInitialRestrictions = {
  geofenceId: string | undefined | null;
  noAssetsOverXYearsOld: number | undefined | null;
  allowedAssetTypes: string[] | undefined | null;
  allowedAssetLength: string[] | undefined | null;
  allowedAssetTags: string[] | undefined | null;
  allowedDoorType: string[] | undefined | null;
  custom: string[] | undefined | null;
};

export const useRestrictionsForm = (
  initialValues: GeofenceInitialRestrictions = {
    geofenceId: " ",
    noAssetsOverXYearsOld: null,
    allowedAssetTypes: null,
    allowedAssetLength: null,
    allowedAssetTags: null,
    allowedDoorType: null,
    custom: null,
  }
) => {
  const schema: yup.SchemaOf<GeofenceInitialRestrictions> = yup
    .object()
    .default({})
    .notRequired()
    .shape({
      geofenceId: yup.string().nullable().notRequired().optional(),
      noAssetsOverXYearsOld: yup
        .number()
        .nullable()
        .notRequired()
        .optional()
        .typeError("No Assets Over X Years Old must be a number")
        .min(0, "No Assets Over X Years Old must be greater than or equal to 0")
        .transform((value, originalValue) => {
          // Convert empty string to null to handle the case where max is an empty string
          if (originalValue === "" || Number(originalValue) === 0) {
            return null;
          }
          return value;
        }),
      allowedAssetTypes: yup
        .array()
        .of(yup.string())
        .nullable()
        .notRequired()
        .optional(),
      allowedAssetLength: yup
        .array()
        .of(yup.string())
        .nullable()
        .notRequired()
        .optional(),
      allowedAssetTags: yup
        .array()
        .notRequired()
        .nullable()
        .optional()
        .transform(transformers.geofenceTags),
      allowedDoorType: yup
        .array()
        .of(yup.string())
        .nullable()
        .notRequired()
        .optional(),
      custom: yup.mixed().nullable().notRequired().optional(),
    });

  const form = useForm({
    resolver: yupResolver(schema),
    values: omitBy(initialValues, isNil),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { schema, form, getValues };
};
