import { FC, useState } from "react";
import { Box, Grid } from "@mui/material";
// import ToggleViewButton from "../ToggleViewButton";
import { TiresTabView } from "../ToggleViewButton/ToggleViewButton";
import { TemperatureInfoCard } from "./TemperatureInfoCard";
// import TemperatureInfoTable from "./TemperatureInfoTable/TemperatureInfoTable";
import { TemperatureSummarydata } from "./helpers";

export interface TemperatureInfoComponentProps {
  sensor: TemperatureSummarydata;
}

export const TemperatureInfoComponent: FC<TemperatureInfoComponentProps> = ({
  sensor,
}) => {
  const [activeView] = useState<TiresTabView>(TiresTabView.Card);

  return (
    <>
      {sensor && (
        <Grid item lg={12} className="w-full flex">
          <Box
            className="mb-4 w-full flex flex-col bg-dashboard_subheader__bg rounded-lg pr-4 pt-4 pl-4"
            data-testid="TemperatureInfoComponent"
          >
            <Box className="pb-4">
              <Grid item xs={12} className="flex justify-end relative">
                <Grid
                  item
                  xs={activeView === TiresTabView.Table}
                  style={{
                    position:
                      activeView === TiresTabView.Table
                        ? "absolute"
                        : "relative",
                  }}
                  className="flex"
                >
                  {/* Table/card view switcher, uncomment when feature is put back  */}
                  {/* <ToggleViewButton
                    activeView={activeView}
                    onClick={setActiveView}
                    data-testid="toggle-view-button"
                    whiteTheme={true}
                  /> */}
                </Grid>
              </Grid>
            </Box>
            {activeView === TiresTabView.Card && sensor && (
              <TemperatureInfoCard temperatureInfo={sensor} />
            )}
            {/* temporary removal as stated in the ticket, uncomment when put back */}
            {/* {activeView === TiresTabView.Table && sensor && (
              <TemperatureInfoTable temperatureSummary={sensor} />
            )} */}
          </Box>
        </Grid>
      )}
    </>
  );
};
