import { memo, SVGProps, FC } from "react";

interface SvgChargingBatteryProps extends SVGProps<SVGSVGElement> {}

const SvgChargingBattery: FC<SvgChargingBatteryProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="battery-svg-charging"
      {...props}
    >
      <path
        d="M0 2.64463C0 1.88902 0.259743 1.25935 0.779221 0.755607C1.2987 0.251869 1.93625 0 2.69186 0L33.5301 0C34.2857 0 34.9233 0.251869 35.4427 0.755607C35.9622 1.25935 36.222 1.88902 36.222 2.64463V5.66706L40 5.66706V15.4427H36.222V18.4179C36.222 19.1736 35.9622 19.8111 35.4427 20.3306C34.9233 20.8501 34.2857 21.1098 33.5301 21.1098L2.69186 21.1098C1.93625 21.1098 1.2987 20.8501 0.779221 20.3306C0.259743 19.8111 0 19.1736 0 18.4179V2.64463ZM5.14758 8.64227L19.5041 16.34V12.3259L30.1299 12.3259L15.7733 4.72255V8.64227L5.14758 8.64227Z"
        fill="#339933"
      />
    </svg>
  );
};

export default memo(SvgChargingBattery);
