import { FC, HTMLAttributes, useEffect, Fragment } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { omitBy, capitalize } from "lodash";
import {
  DayOfWeek,
  GEOFENCE_OPERATIONS_DAYS,
} from "../../../../../../../constants/geofences";
import { GeofenceData } from "../../../../../../../graphql/operations";
import {
  AM_PM_OPTIONS,
  HOUR_OPTIONS,
} from "../../../../../../../shared/components/FrequencySelect/constants";
import { SelectElement } from "../../../../../../../shared/components/react-hook-form-mui/SelectElement/SelectElement";

interface GeofenceOperationsFormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onChange" | "onSubmit"> {
  onSubmit?: (data: FieldValues) => void;
  geofence?: Partial<GeofenceData>;
  selectAll: boolean;
  selectedDays: string[];
  daysWithLunch: string[];
  onChange?: (geofence?: Partial<GeofenceData> | undefined) => void;
  onDayChange: (day: DayOfWeek) => void;
  handleLunchToggle: (day: DayOfWeek) => void;
  onSelectAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  form: UseFormReturn<Partial<GeofenceData>>;
  compact?: boolean;
  cancelable?: boolean;
  showDetailsHeader?: boolean;
  disableAllFields?: boolean;
  onCancel?: () => void;
}

export const GeofenceOperationsForm: FC<GeofenceOperationsFormProps> = ({
  onSubmit,
  geofence = {},
  selectAll,
  selectedDays,
  onChange,
  onDayChange,
  onSelectAllChange,
  form,
  compact = false,
  showDetailsHeader = false,
  cancelable = true,
  onCancel,
  disableAllFields = false,
  daysWithLunch,
  handleLunchToggle,
  ...props
}: GeofenceOperationsFormProps) => {
  useEffect(() => {
    const values = omitBy(form.getValues(), (value) => value === "");
    if (form.formState.isDirty) {
      onChange?.(values);
    }
  }, [form, onChange, form.formState.isDirty]);

  return (
    <form
      {...props}
      autoComplete="off"
      onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
      data-testid="geofence-operations-form"
    >
      <Grid
        container
        className={`!text-primary ${showDetailsHeader ? "!mb-4" : ""}`}
        alignItems="center"
        spacing={1}
      >
        <Grid
          item
          xs={12}
          data-testid="geofence-operations-select-all"
          className="!mb-10"
          style={{ display: "none" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                className="!text-brand"
                checked={selectAll}
                onChange={onSelectAllChange}
              />
            }
            label="All Days"
          />
        </Grid>
        <Grid container item xs={12} className="!pl-10">
          {Object.entries(GEOFENCE_OPERATIONS_DAYS).map(([key, day]) => {
            const isLunchChecked = daysWithLunch.includes(day);
            const isDaySelected = selectedDays.includes(day);
            return (
              <Fragment key={day}>
                <Grid container columnSpacing={2} className="!mb-8">
                  <Grid
                    item
                    lg={2}
                    md={12}
                    sm={12}
                    xs={12}
                    data-testid={`geofence-operations-${day}-checkmark`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="!text-brand"
                          checked={isDaySelected}
                          onChange={() => onDayChange(day)}
                        />
                      }
                      label={capitalize(day)}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <SelectElement
                      name={`configuration.operations.${day}.operatingHours.startAt`}
                      placeholder="HH:MM"
                      control={form.control}
                      options={HOUR_OPTIONS}
                      rules={{ required: true }}
                      dataTestId={`geofence-operations-${day}-start-time`}
                      disabled={!isDaySelected}
                    />
                  </Grid>
                  <Grid item lg={1} md={6} sm={6} xs={6}>
                    <SelectElement
                      name={`configuration.operations.${day}.operatingHours.startAtFormat`}
                      placeholder="AM/PM"
                      control={form.control}
                      options={AM_PM_OPTIONS}
                      rules={{ required: true }}
                      dataTestId={`geofence-operations-${day}-start-format`}
                      disabled={!isDaySelected}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ textAlign: "center" }}
                  >
                    <Box
                      className="my-1 flex flex-col items-center gap-1"
                      data-testid={`geofence-operations-${day}-label-to`}
                    >
                      <Typography className="!text-checked items-center">
                        to
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <SelectElement
                      name={`configuration.operations.${day}.operatingHours.endAt`}
                      placeholder="HH:MM"
                      control={form.control}
                      options={HOUR_OPTIONS}
                      rules={{ required: true }}
                      dataTestId={`geofence-operations-${day}-end-time`}
                      disabled={!isDaySelected}
                    />
                  </Grid>
                  <Grid item lg={1} md={6} sm={6} xs={6}>
                    <SelectElement
                      name={`configuration.operations.${day}.operatingHours.endAtFormat`}
                      placeholder="AM/PM"
                      control={form.control}
                      options={AM_PM_OPTIONS}
                      rules={{ required: true }}
                      dataTestId={`geofence-operations-${day}-end-format`}
                      disabled={!isDaySelected}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={12}
                    sm={12}
                    xs={12}
                    data-testid={`geofence-operations-${day}-lunch-checkmark`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isLunchChecked}
                          className="!text-brand"
                          onChange={() => handleLunchToggle(day)}
                        />
                      }
                      label="Set a lunch break"
                      disabled={!isDaySelected}
                    />
                  </Grid>
                </Grid>
                {/*LUNCH BREAK*/}
                {isLunchChecked && (
                  <Grid container item columnSpacing={2} className="!mb-12">
                    <Grid
                      className="!pl-10"
                      item
                      md={2}
                      sm={2}
                      xs={12}
                      data-testid={`geofence-operations-${day}-lunch-title`}
                    >
                      <Typography className="!text-brand !font-bold">
                        Lunch Break
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={2} xs={6}>
                      <SelectElement
                        name={`configuration.operations.${day}.lunch.startAt`}
                        placeholder="HH:MM"
                        control={form.control}
                        options={HOUR_OPTIONS}
                        rules={{ required: true }}
                        dataTestId={`geofence-operations-${day}-lunch-start-time`}
                        disabled={!isDaySelected}
                      />
                    </Grid>
                    <Grid item md={1} sm={1} xs={6}>
                      <SelectElement
                        name={`configuration.operations.${day}.lunch.startAtFormat`}
                        placeholder="AM/PM"
                        control={form.control}
                        options={AM_PM_OPTIONS}
                        rules={{ required: true }}
                        dataTestId={`geofence-operations-${day}-lunch-start-format`}
                        disabled={!isDaySelected}
                      />
                    </Grid>
                    <Grid
                      item
                      md={1}
                      sm={1}
                      xs={12}
                      sx={{ textAlign: "center" }}
                    >
                      <Box
                        className="my-1 flex flex-col items-center gap-1"
                        data-testid={`geofence-operations-${day}-lunch-label-to`}
                      >
                        <Typography className="!text-checked items-center">
                          to
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} sm={2} xs={6}>
                      <SelectElement
                        name={`configuration.operations.${day}.lunch.endAt`}
                        placeholder="HH:MM"
                        control={form.control}
                        options={HOUR_OPTIONS}
                        rules={{ required: true }}
                        dataTestId={`geofence-operations-${day}-lunch-end-time`}
                        disabled={!isDaySelected}
                      />
                    </Grid>
                    <Grid item md={1} sm={1} xs={6}>
                      <SelectElement
                        name={`configuration.operations.${day}.lunch.endAtFormat`}
                        placeholder="AM/PM"
                        control={form.control}
                        options={AM_PM_OPTIONS}
                        rules={{ required: true }}
                        dataTestId={`geofence-operations-${day}-lunch-end-format`}
                        disabled={!isDaySelected}
                      />
                    </Grid>
                  </Grid>
                )}
                <Divider className="w-full !mb-10" />
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
    </form>
  );
};
