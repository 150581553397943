import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../../shared/hooks/theme/useCurrentTheme";

export const useSidePanelTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      typography: {
        button: {
          textTransform: "capitalize",
          color: "var(--primary-blue)",
        },
        caption: {
          color: "var(--typography-secondary)",
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              padding: "10px",
              border: "1px solid var(--card__placeholder_img)",
              flexBasis: "241px",
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              marginBottom: "2px",
              textTransform: "capitalize!important",
              fontWeight: "700!important",
              color: "var(--typography-secondary)!important",
              justifyContent: "space-between",
              ".MuiButton-endIcon": {
                color: "var(--typography-secondary)!important",
                fontWeight: "400!important",
              },
              "&:hover": {
                backgroundColor: "var(--brand-transparent)!important",
              },
            },
          },
        },
      },
    })
  );
};
