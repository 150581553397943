import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { CircularProgress, MenuItem, Typography } from "@mui/material";
import { QueryClient } from "@tanstack/react-query";
import {
  BellNotification,
  useGetBellNotificationsQuery,
  useMarkBellNotificationsAsReadMutation,
} from "../../../../graphql/operations";
import { humanizeDateString } from "../../../../utils/date";

export const NotificationsList = ({
  queryClient,
  setAlertNotificationId,
  setIsOpenModal,
  isAllMarkedAsRead,
  setIsAllMarkedAsRead,
}: {
  queryClient: QueryClient;
  setAlertNotificationId: (param: string) => void;
  setIsOpenModal: (param: boolean) => void;
  isAllMarkedAsRead: boolean;
  setIsAllMarkedAsRead: (param: boolean) => void;
}) => {
  const [notificationsList, setNotificationsList] = useState<
    BellNotification[]
  >([]);
  const [selectedNotificationId, setSelectedNotificationId] = useState<
    string | null
  >(null);
  const [skip, setSkip] = useState(0);

  const {
    data: bellNotifications,
    isFetching,
    isLoading,
  } = useGetBellNotificationsQuery(
    {
      input: {
        pagination: {
          limit: 10,
          skip: skip,
        },
      },
    },
    {
      cacheTime: 0,
    }
  );

  const { mutate: markBellNotificationsAsRead } =
    useMarkBellNotificationsAsReadMutation({
      onSuccess: () => {
        setSelectedNotificationId(null);
        queryClient.invalidateQueries(["getBellNotificationsTotalUnread"]);
        const updatedNotificationsList = notificationsList.map(
          (notification) => {
            if (notification._id === selectedNotificationId) {
              return { ...notification, isRead: true };
            }
            return notification;
          }
        );
        setNotificationsList(updatedNotificationsList);
      },
    });

  const handleNext = () => {
    setSkip((prevSkip) => prevSkip + 10);
  };

  const handleNotificationClick = (
    alertNotificationId: string,
    notificationId: string
  ) => {
    setAlertNotificationId(alertNotificationId);
    setIsOpenModal(true);
    setSelectedNotificationId(notificationId);
    markBellNotificationsAsRead({
      input: {
        ids: [notificationId],
      },
    });
  };

  const totalNotifications =
    bellNotifications?.getBellNotifications?.pagination?.total ?? 0;
  const totalFetchedNotifications = notificationsList.length ?? 0;
  const hasMore = totalFetchedNotifications < totalNotifications;

  useEffect(() => {
    setNotificationsList((prevList) =>
      prevList.concat(bellNotifications?.getBellNotifications?.data ?? [])
    );
  }, [bellNotifications]);

  useEffect(() => {
    if (isAllMarkedAsRead) {
      const updatedNotificationsList = notificationsList.map(
        (notification) => ({
          ...notification,
          isRead: true,
        })
      );
      setNotificationsList(updatedNotificationsList);
      setIsAllMarkedAsRead(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllMarkedAsRead]);

  return (
    <>
      {isLoading && notificationsList.length === 0 ? (
        <div className="flex flex-col items-center py-[150px]">
          <CircularProgress />
        </div>
      ) : notificationsList.length !== 0 ? (
        <InfiniteScroll
          dataLength={totalFetchedNotifications}
          next={handleNext}
          hasMore={hasMore}
          scrollableTarget={"scrollableMenu"}
          loader={
            <div className="w-full h-16 my-5 justify-center flex">
              <CircularProgress size={50} />
            </div>
          }
        >
          {notificationsList.map((notification) => (
            <MenuItem
              key={notification._id}
              className={`flex flex-col !text-inherit !mb-1 !rounded-lg !whitespace-normal ${
                notification.isRead ? "" : "!bg-primary-blue-transparent-10"
              }`}
              onClick={() =>
                handleNotificationClick(
                  notification.payload.alertNotificationId,
                  notification._id
                )
              }
              data-testid={`notification-item-${notification._id}`}
            >
              <div className="flex items-center justify-between w-full">
                <Typography
                  className="!text-sm !font-bold"
                  data-testid={`notification-item-${notification._id}-asset`}
                >
                  Asset {notification.payload.assetId}
                </Typography>
                <Typography
                  className="!font-medium !text-xs text-asset-sensor-filters-slider-labels"
                  data-testid={`notification-item-${notification._id}-date`}
                  sx={{ textTransform: "none" }}
                >
                  {humanizeDateString(notification.orgTime)}
                </Typography>
              </div>
              <Typography
                className="!text-sm !font-normal w-full"
                data-testid={`notification-item-${notification._id}-message`}
              >
                {notification.payload.message}
              </Typography>
            </MenuItem>
          ))}
        </InfiniteScroll>
      ) : (
        <div className="flex flex-col items-center py-[150px]">
          <div>
            <NotificationsOffIcon className="text-primary" />
          </div>
          <Typography>No new notifications</Typography>
        </div>
      )}
      {isFetching && notificationsList.length > 0 && (
        <div className="flex flex-col items-center py-[150px]">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
