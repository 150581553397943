import React, { memo } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import { DateTextField, DateTextFieldProps } from "../../DateTextField";
import { RequiredFlag } from "../../RequiredFlag";

export type DatePickerElementElementProps<
  TInputDate,
  TDate = TInputDate
> = Omit<DateTextFieldProps<TInputDate, TDate>, "onChange" | "value"> & {
  name: string;
  title: string;
  control: Control<any>;
  label?: string;
  rules?: ControllerProps["rules"];
  labelClassName?: string;
  required?: boolean;
  validate?: (value: Date) => boolean;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
  prepareValue?: (value: Date) => Date;
};

export const DatePickerElement = memo(
  <TInputDate, TDate = TInputDate>({
    label,
    rules,
    name,
    title,
    control,
    labelClassName = "!mb-2 !text-sm !font-bold",
    required,
    validate,
    handleUpdateField,
    fieldPath,
    prepareValue,
    ...dateTextFieldProps
  }: DatePickerElementElementProps<TInputDate, TDate>) => {
    return (
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error, invalid },
        }) => (
          <FormControl
            fullWidth
            required={required}
            variant="standard"
            error={invalid}
          >
            {label && (
              <Typography
                className={labelClassName}
                data-testid="date-picker-element-label"
              >
                {label}
                {rules?.required && <RequiredFlag />}
              </Typography>
            )}
            <DateTextField
              Icon={dateTextFieldProps.Icon || CalendarTodayIcon}
              iconProps={
                dateTextFieldProps.iconProps || {
                  size: "small",
                  sx: { svg: { height: "20px", width: "22px" } },
                }
              }
              data-testid={`date-picker-${title}`}
              data-title={title}
              value={value ?? null}
              onChange={(e) => {
                if (!e) {
                  onChange("");
                  handleUpdateField && handleUpdateField(e, fieldPath);
                } else if (validate) {
                  const eValue = prepareValue?.(e as unknown as Date) ?? e;
                  const isValid = validate(eValue as unknown as Date);
                  const value = isValid ? eValue : "Invalid date";
                  onChange(value);
                  handleUpdateField && handleUpdateField(value, fieldPath);
                } else {
                  onChange(e);
                  handleUpdateField && handleUpdateField(e, fieldPath);
                }

                onBlur();
              }}
              {...dateTextFieldProps}
            />
            {error?.message && (
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    );
  }
);
