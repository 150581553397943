import { ComponentType } from "react";

// used to set name to components that are lazy loaded
export const withDisplayName = <P extends object>(
  Component: ComponentType<P>,
  displayName: string
) => {
  const WrappedComponent: React.FC<P> = (props) => (
    <Component {...(props as P)} />
  );
  WrappedComponent.displayName = displayName;
  return WrappedComponent;
};
