import { parseFileContent } from "../../../../../utils";
import {
  GeofenceGeometryType,
  GeofencesFileData,
  MappedGeofenceFileDataResult,
  ParseFileContentError,
  GeofencesInput,
} from "./types";
import {
  geofencesBulkValidationSchema,
  YupValidationError,
} from "./validationSchema";

export type ValidatedGeofence = {
  geofence: GeofencesInput;
  row: number;
};

export const parseGeofencesFile = async (
  file: File
): Promise<{
  fileData: GeofencesFileData[];
  errors: ParseFileContentError[];
}> => {
  const map: { [key: string]: keyof GeofencesFileData } = {
    "Name*": "name",
    "Type*": "type",
    "Sub Type": "subType",
    Parent: "parent",
    Owner: "owner",
    Tags: "tags",
    "Location Code": "locationCode",
    "Street Address": "streetAddress",
    "Geometry Type*": "geometryType",
    "Geometry Coordinates*": "geometryCoordinates",
    Radius: "radius",
    "Boat Minimum": "boatMinimum",
    "Boat Maximum": "boatMaximum",
    "Chassis Minimum": "chassisMinimum",
    "Chassis Maximum": "chassisMaximum",
    "Container Minimum": "containerMinimum",
    "Container Maximum": "containerMaximum",
    "Dolly Minimum": "dollyMinimum",
    "Dolly Maximum": "dollyMaximum",
    "Reefer Minimum": "reeferMinimum",
    "Reefer Maximum": "reeferMaximum",
    "Tractor Minimum": "tractorMinimum",
    "Tractor Maximum": "tractorMaximum",
    "Trailer Minimum": "trailerMinimum",
    "Trailer Maximum": "trailerMaximum",
    "Vehicle Minimum": "vehicleMinimum",
    "Vehicle Maximum": "vehicleMaximum",
    "Other Minimum": "otherMinimum",
    "Other Maximum": "otherMaximum",
  };

  try {
    const { data, errors } = await parseFileContent<GeofencesFileData>({
      file,
      map,
    });
    return { fileData: data, errors };
  } catch (error) {
    const message =
      error instanceof Error ? error.message : "Error parsing file.";
    return { fileData: [], errors: [{ row: 0, message }] };
  }
};

/**
 * This function is used to parse and validate centroid coordinates
 * when the geometry type is Point or Circle.
 * The data that comes is a string in the format of "[[lat, lon]]".
 * The first validation is for valid JSON.
 * The second validation checks if the parsed value is an array of arrays with exactly one element.
 * The inner element should contain two numbers.
 *
 * @param incomingCoordinates - "[[lat, lon]]"
 * @returns
 */
export const parseCentroidCoordinates = (
  incomingCoordinates: string
): { lat: number; lon: number } => {
  const invalidCoordinatesError = new Error("Invalid coordinates provided");

  let parsedCoordinates;
  try {
    parsedCoordinates = JSON.parse(incomingCoordinates) as number[][];
  } catch (error) {
    throw invalidCoordinatesError;
  }

  if (
    !Array.isArray(parsedCoordinates) ||
    !Array.isArray(parsedCoordinates[0]) ||
    parsedCoordinates.length !== 1 ||
    parsedCoordinates[0].length !== 2 ||
    !parsedCoordinates[0].every((coord) => typeof coord === "number")
  ) {
    throw invalidCoordinatesError;
  }

  const [lat, lon] = parsedCoordinates[0];

  return { lat, lon };
};

/**
 * This function is used to parse coordinates when geometry type is Polygon.
 * First it validated the incoming string as JSON. This string should be array of number arrays.
 * Finally, it checks if the parsed value is an array of arrays with exactly two elements.
 * @param incomingCoordinates - [[lat, lon], [lat, lon], ...]
 * @returns
 */
export const parsePolygonCoordinates = (
  incomingCoordinates: string
): number[][] => {
  const invalidCoordinatesError = new Error(
    "Invalid polygon coordinates provided"
  );

  let parsedCoordinates;
  try {
    parsedCoordinates = JSON.parse(incomingCoordinates) as number[][];
  } catch (error) {
    throw invalidCoordinatesError;
  }

  if (
    !Array.isArray(parsedCoordinates) ||
    parsedCoordinates.length === 0 ||
    !parsedCoordinates.every(
      (coord) => Array.isArray(coord) && coord.length === 2
    )
  ) {
    throw invalidCoordinatesError;
  }

  return parsedCoordinates;
};

export const parseGeometryType = (
  geometryType: string
): GeofenceGeometryType | string => {
  return geometryType === GeofenceGeometryType.Point ||
    geometryType === GeofenceGeometryType.Circle
    ? GeofenceGeometryType.Circle
    : geometryType;
};

export const mapGeofenceData = (
  parsedFileData: GeofencesFileData,
  orgId: string
): GeofencesInput => ({
  owner: parsedFileData.owner,
  orgId,
  geofence: {
    name: parsedFileData.name,
    type: parseGeometryType(parsedFileData.geometryType),
    tags: parsedFileData.tags,
    code: parsedFileData.locationCode,
    fullAddressFormatted: parsedFileData.streetAddress,
    radius: parsedFileData.radius,
    coordinates:
      parsedFileData.geometryType === GeofenceGeometryType.Polygon
        ? parsePolygonCoordinates(parsedFileData.geometryCoordinates)
        : undefined,
    centroid:
      parsedFileData.geometryType === GeofenceGeometryType.Point ||
      parsedFileData.geometryType === GeofenceGeometryType.Circle
        ? parseCentroidCoordinates(parsedFileData.geometryCoordinates)
        : undefined,
  },
  configuration: {
    typeId: parsedFileData.type,
    subTypeId: parsedFileData.subType,
    parentId: parsedFileData.parent,
    capacity: {
      boat:
        parsedFileData.boatMinimum && parsedFileData.boatMaximum
          ? {
              min: parsedFileData.boatMinimum,
              max: parsedFileData.boatMaximum,
            }
          : undefined,
      chassis:
        parsedFileData.chassisMinimum && parsedFileData.chassisMaximum
          ? {
              min: parsedFileData.chassisMinimum,
              max: parsedFileData.chassisMaximum,
            }
          : undefined,
      container:
        parsedFileData.containerMinimum && parsedFileData.containerMaximum
          ? {
              min: parsedFileData.containerMinimum,
              max: parsedFileData.containerMaximum,
            }
          : undefined,
      dolly:
        parsedFileData.dollyMinimum && parsedFileData.dollyMaximum
          ? {
              min: parsedFileData.dollyMinimum,
              max: parsedFileData.dollyMaximum,
            }
          : undefined,
      reefer:
        parsedFileData.reeferMinimum && parsedFileData.reeferMaximum
          ? {
              min: parsedFileData.reeferMinimum,
              max: parsedFileData.reeferMaximum,
            }
          : undefined,
      tractor:
        parsedFileData.tractorMinimum && parsedFileData.tractorMaximum
          ? {
              min: parsedFileData.tractorMinimum,
              max: parsedFileData.tractorMaximum,
            }
          : undefined,
      trailer:
        parsedFileData.trailerMinimum && parsedFileData.trailerMaximum
          ? {
              min: parsedFileData.trailerMinimum,
              max: parsedFileData.trailerMaximum,
            }
          : undefined,
      vehicle:
        parsedFileData.vehicleMinimum && parsedFileData.vehicleMaximum
          ? {
              min: parsedFileData.vehicleMinimum,
              max: parsedFileData.vehicleMaximum,
            }
          : undefined,
      other:
        parsedFileData.otherMinimum && parsedFileData.otherMaximum
          ? {
              min: parsedFileData.otherMinimum,
              max: parsedFileData.otherMaximum,
            }
          : undefined,
    },
  },
});

export const mapFileDataToCreateGeofenceInput = (
  data: GeofencesFileData[],
  customer_orgs_id: string
): MappedGeofenceFileDataResult => {
  const errors: ParseFileContentError[] = [];

  if (!data.length) {
    errors.push({ row: 0, message: "Uploaded file is empty" });
  }

  const mapped = data.map((fileData, index) => {
    const row = index + 1; // Add 1 to index to get row number

    try {
      const geofence: GeofencesInput =
        geofencesBulkValidationSchema.validateSync(
          mapGeofenceData(fileData, customer_orgs_id),
          {
            abortEarly: false,
          }
        );

      return { geofence, row };
    } catch (error: any) {
      if (error.inner) {
        error.inner.forEach((err: YupValidationError) => {
          errors.push({
            row,
            message: err.message ?? "Error validating geofence",
          });
        });
      } else {
        errors.push({
          row,
          message:
            error instanceof Error
              ? error.message
              : "Error validating geofence",
        });
      }

      return null;
    }
  });

  return {
    validatedGeofences: mapped.filter(
      (geofence): geofence is ValidatedGeofence => !!geofence
    ),
    validationErrors: errors,
  };
};
