import { useEffect, useState } from "react";
import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import {
  Asset,
  AssetInput,
  AssetInputOs,
  AssetOs,
  GetAssetsForListOsQuery,
  GetAssetsForListQuery,
  useGetAssetsForListOsQuery,
  useGetAssetsForListQuery,
} from "../../../graphql/operations";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";
import { useFeatureFlag } from "../../../utils/useFeatureFlag";

export interface AssetsListDataHook {
  isFetching: boolean;
  assets: Asset[] | AssetOs[];
  total: number;
}

type GetAssetsForListQueryOptions = UseQueryOptions<
  GetAssetsForListQuery,
  unknown,
  GetAssetsForListQuery,
  QueryKey
>;

type GetAssetsForListOSQueryOptions = UseQueryOptions<
  GetAssetsForListOsQuery,
  unknown,
  GetAssetsForListOsQuery,
  QueryKey
>;

export const useAssetsListData = (
  queryInput: AssetInput | AssetInputOs,
  queryOptions: GetAssetsForListQueryOptions | GetAssetsForListOSQueryOptions
): AssetsListDataHook => {
  const [isFetching, setIsFetching] = useState(false);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [total, setTotal] = useState(0);

  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const { data: dataDocumentDb, isFetching: isFetchingDocumentDb } =
    useGetAssetsForListQuery(
      {
        input: queryInput as AssetInput,
      },
      {
        ...queryOptions,
        enabled: queryOptions.enabled && !fetchAssetsFromOpenSearchFeatureFlag,
      } as GetAssetsForListQueryOptions
    );

  const { data: dataOpenSearch, isFetching: isFetchingOpenSearch } =
    useGetAssetsForListOsQuery(
      {
        input: queryInput as AssetInputOs,
      },
      {
        ...queryOptions,
        enabled: queryOptions.enabled && fetchAssetsFromOpenSearchFeatureFlag,
      } as GetAssetsForListOSQueryOptions
    );

  useEffect(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      setIsFetching(isFetchingOpenSearch);
    } else {
      setIsFetching(isFetchingDocumentDb);
    }
  }, [
    fetchAssetsFromOpenSearchFeatureFlag,
    isFetchingDocumentDb,
    isFetchingOpenSearch,
  ]);

  useEffect(() => {
    if (dataDocumentDb?.getAssets) {
      setAssets((dataDocumentDb.getAssets?.assets as Asset[]) ?? []);
      setTotal(dataDocumentDb.getAssets?.total ?? 0);
    } else if (dataOpenSearch?.getAssetsOS) {
      setAssets(
        mapOpenSearchAssets(dataOpenSearch?.getAssetsOS.assets as AssetOs[])
      );
      setTotal(dataOpenSearch?.getAssetsOS.total ?? 0);
    }
  }, [dataDocumentDb, dataOpenSearch]);

  return {
    isFetching,
    assets,
    total,
  };
};

// TODO: Once we switch to the new API, and ditch the feature flag, we should remove this mapping and make the list work with the new format instead
export const mapOpenSearchAssets = (
  assets: AssetOs[] | null | undefined
): Asset[] => {
  if (!assets) {
    return [];
  }

  return assets.map((asset) => ({
    ...asset,
    city: asset.fullAddress?.city,
    state: asset.fullAddress?.state,
  })) as Asset[];
};
