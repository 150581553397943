import { FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MUIButton,
} from "@mui/material";

export interface DeleteProfileDialogProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onClick: () => void;
  testId: string;
}

const DeleteProfileDialog: FC<DeleteProfileDialogProps> = ({
  isOpen,
  onClose,
  isLoading,
  onClick,
  testId = "",
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="delete-Profile-alert-dialog-title"
      aria-describedby="delete-Profile-alert-dialog-description"
      data-testid={`delete-sensor-profile-dialog-${testId}`}
    >
      <DialogTitle id="delete-Profile-alert-dialog-title">
        Delete Profile
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-profile-alert-dialog-description">
          You are about to delete this sensor Profile. This cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MUIButton
          sx={{ fontWeight: "bold" }}
          onClick={onClose}
          autoFocus
          disabled={isLoading}
        >
          Cancel
        </MUIButton>
        <MUIButton
          data-testid={`delete-${testId}-profile-confirmation-dialog-remove-btn`}
          className="!text-error"
          sx={{ fontWeight: "bold" }}
          variant="text"
          onClick={onClick}
          disabled={isLoading}
        >
          {isLoading ? "Removing..." : "Remove"}
        </MUIButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProfileDialog;
