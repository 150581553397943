import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, Modal, Grid, Paper } from "@mui/material";
import useBreakpoint from "../../../../../../../shared/hooks/useBreakpoint";
import { useDateInPreferredTimezone } from "../../../../../../../shared/hooks/useDateInPreferredTimezone";
import { useInternalCameraSensorContext } from "../context";
import UsedSpaceIndicator, {
  UsedSpaceIndicatorMode,
} from "./UsedSpaceIndicator";

export interface MediaActivityModalProps {}

const MediaActivityModal: React.FC<MediaActivityModalProps> = () => {
  const isMobile = useBreakpoint("down", "sm");
  const {
    mediaActivityModalIsOpen,
    setMediaActivityModalIsOpen,
    mediaActivityItem,
  } = useInternalCameraSensorContext();

  const timezoneDate = useDateInPreferredTimezone(
    mediaActivityItem?.uploadDate
  );

  const handleCloseModal = () => {
    setMediaActivityModalIsOpen(false);
  };

  // Determine the mode for the used space indicator (normal or compact)
  const usedSpaceIndicatorMode = isMobile
    ? UsedSpaceIndicatorMode.ShortTitle
    : UsedSpaceIndicatorMode.FullTitle;

  if (!mediaActivityItem) {
    return null;
  }

  return (
    <Modal
      open={mediaActivityModalIsOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="internal-camera-status--modal"
    >
      <Box
        className="w-5/6 bg-card__bg rounded-lg shadow-md p-4"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        {/* Modal Header */}
        <Box className="flex justify-between items-center mb-4">
          <Typography
            className="text-card__typography"
            sx={{
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: "600",
            }}
          >
            Image Thumbnail
          </Typography>

          <CloseIcon
            className="cursor-pointer"
            onClick={handleCloseModal}
            data-testid="internal-camera-status--modal--close-button"
            sx={{ fill: "var(--typography-secondary)" }}
          />
        </Box>

        {/* Modal Body */}
        <Box className="relative">
          <Paper
            sx={{
              backgroundColor: "var(--asset-image-fallback-background)",
              minHeight: "120px",
              boxShadow: "none",
            }}
          >
            <img
              src={mediaActivityItem?.imageUrl}
              alt={mediaActivityItem?.address}
              className="w-full rounded-lg"
            />
          </Paper>

          <div className="absolute top-4 right-4">
            {/** TODO: Uncomment when we start receiving data for Cube Space */}
            {/* <UsedSpaceIndicator
              title="Cube Space"
              value={mediaActivityItem.loadedEmptyConfidence}
              mode={usedSpaceIndicatorMode}
            /> */}
            <UsedSpaceIndicator
              title="Floor Space"
              value={mediaActivityItem.floorUsagePercentage}
              mode={usedSpaceIndicatorMode}
            />
          </div>
        </Box>

        <Grid
          container
          className="w-full mt-4 justify-between"
          columns={{ xs: 1, md: 2, lg: 5 }}
          data-testid="internal-camera-status--modal--details"
        >
          <Grid item className="py-2">
            <MediaActivityInfo title="Date & Time:" value={timezoneDate} />
            <MediaActivityInfo
              title="Location:"
              value={mediaActivityItem.address}
            />
          </Grid>

          <Grid item className="py-2">
            <MediaActivityInfo
              title="Geofence:"
              value={mediaActivityItem.geofenceName}
            />

            <MediaActivityInfo
              title="Cargo status:"
              value={mediaActivityItem.floorUsageStatus}
            />
          </Grid>

          <Grid item className="py-2">
            {/** TODO: Uncomment when we start receiving data for Cube Space */}
            {/* <MediaActivityInfo title="Cube Space:" value={mediaActivityItem} /> */}
            <MediaActivityInfo
              title="Floor Space:"
              value={mediaActivityItem.floorUsagePercentage}
            />

            <MediaActivityInfo
              title="Confidence reading:"
              value={mediaActivityItem.loadedEmptyConfidence}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

interface MediaActivityInfoProps {
  title: string;
  value: string;
}

const MediaActivityInfo: React.FC<MediaActivityInfoProps> = ({
  title,
  value,
}) => {
  return (
    <Typography
      className="text-card__typography"
      sx={{
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "600",
      }}
    >
      <span>{title}</span>
      <span className="ml-1">{value}</span>
    </Typography>
  );
};

export default MediaActivityModal;
