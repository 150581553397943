import { memo, SVGProps, FC } from "react";

interface BrakeStrokeProps extends SVGProps<SVGSVGElement> {}

const BrakeStroke: FC<BrakeStrokeProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 101 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.2177 45.3857L3.6102 73.9957C0.727588 76.8783 0.727588 81.5526 3.6102 84.4352C6.49155 87.3165 11.1658 87.3152 14.0484 84.4352L42.6571 55.8239C45.5385 52.9425 45.5385 48.2696 42.6571 45.3882C39.7745 42.5043 35.1016 42.5043 32.2177 45.3857Z"
        fill="#9BDEED"
      />
      <path
        d="M35.3111 9.04712C23.2492 21.1104 23.2492 40.6707 35.3111 52.7352C47.3757 64.7972 66.936 64.7959 78.998 52.7352C91.0612 40.672 91.0625 21.1104 78.9967 9.04712C66.9347 -3.01485 47.3744 -3.01485 35.3111 9.04712Z"
        fill="#9BDEED"
      />
      <path
        d="M40.7174 14.4518C31.6385 23.5294 31.6385 38.2489 40.7174 47.3266C49.795 56.4068 64.5158 56.4068 73.5935 47.3266C82.6724 38.2489 82.6724 23.5294 73.5935 14.4518C64.5158 5.37159 49.795 5.37159 40.7174 14.4518Z"
        fill="white"
      />
      <path
        d="M43.5213 17.258C36.0051 24.7742 36.0064 37.0053 43.5225 44.524C44.0439 45.0441 44.8869 45.0441 45.4082 44.524C45.9296 44.0027 45.9296 43.1584 45.4082 42.6396C38.9297 36.161 38.9284 25.6211 45.407 19.1438C51.8855 12.6652 62.4242 12.6652 68.9015 19.1438C69.4228 19.6651 70.2672 19.6651 70.786 19.1438C71.3086 18.6237 71.3073 17.7806 70.786 17.2593C63.2698 9.74314 51.0387 9.74187 43.5213 17.258Z"
        fill="#9BDEED"
      />
      <path
        d="M48.1975 21.9323C43.2575 26.8723 43.2588 34.9073 48.1988 39.8473C48.7189 40.3686 49.5632 40.3686 50.0832 39.8473C50.6046 39.3247 50.6046 38.4829 50.0832 37.9628C46.1834 34.0617 46.1834 27.7166 50.082 23.8168C53.9806 19.9195 60.3256 19.9195 64.2255 23.818C64.7481 24.3394 65.5911 24.3394 66.1112 23.818C66.6338 23.2992 66.6325 22.4549 66.1112 21.9349C61.1725 16.9949 53.1362 16.9949 48.1975 21.9323Z"
        fill="#9BDEED"
      />
      <path
        d="M23.5616 64.4812C20.356 61.2756 18.2363 58.1883 18.7653 57.5106C18.7195 57.5538 18.6712 57.5919 18.6292 57.6364L2.84032 73.4228C-0.414859 76.6767 -0.413587 81.9524 2.84032 85.2051C6.09297 88.4577 11.3674 88.4577 14.6213 85.2051L30.4102 69.4161C30.4522 69.3716 30.489 69.3259 30.5323 69.2801C29.8558 69.8078 26.7685 67.6868 23.5616 64.4812Z"
        fill="#5F7C8F"
      />
      <path
        d="M30.5653 69.2622C30.578 69.2482 30.5831 69.2291 30.5933 69.2139C30.5716 69.2355 30.5538 69.2596 30.5322 69.2812C30.5411 69.2711 30.5564 69.2685 30.5653 69.2622Z"
        fill="#B7E7EF"
      />
      <path
        d="M18.7646 57.5117C18.7875 57.4914 18.8092 57.471 18.8333 57.452C18.8181 57.4621 18.7977 57.4659 18.7837 57.4799C18.7735 57.4888 18.771 57.5041 18.7646 57.5117Z"
        fill="#B7E7EF"
      />
      <path
        d="M78.6171 43.6831C73.6288 43.6831 69.584 47.7266 69.584 52.7162C69.584 57.7045 73.6288 61.748 78.6171 61.748C83.6054 61.748 87.6503 57.7045 87.6503 52.7162C87.6503 47.7266 83.6054 43.6831 78.6171 43.6831Z"
        fill="white"
      />
      <path
        d="M78.6172 48.0377C76.0347 48.0377 73.9404 50.1307 73.9404 52.7171C73.9404 55.2996 76.0347 57.3926 78.6172 57.3926C81.1997 57.3926 83.294 55.2996 83.294 52.7171C83.294 50.1307 81.1997 48.0377 78.6172 48.0377Z"
        fill="#5F7C8F"
      />
      <path
        d="M78.617 61.748C73.6287 61.748 69.5839 57.7044 69.5839 52.7149C69.5839 47.7265 73.6287 43.683 78.617 43.683C83.6053 43.683 87.6501 47.7265 87.6501 52.7149C87.6501 57.7044 83.6053 61.748 78.617 61.748ZM92.6957 47.9987C92.4769 47.3476 92.2125 46.7131 91.9098 46.0913L96.3183 40.0107C95.6368 39.066 94.8776 38.1568 94.0257 37.3061C93.1712 36.4529 92.2646 35.6951 91.3186 35.011L85.2405 39.4246C84.62 39.1156 83.9804 38.8524 83.3319 38.6375L82.1519 31.2192C79.8135 30.8352 77.4205 30.8352 75.0795 31.223L73.8995 38.6375C73.2498 38.8536 72.6127 39.113 71.9884 39.4233L65.9167 35.0123C64.9707 35.6951 64.0615 36.458 63.2083 37.3061C62.3576 38.1581 61.5921 39.0673 60.9169 40.0146L65.3203 46.0888C65.0152 46.7131 64.757 47.3502 64.5383 47.9987L57.1226 49.1825C56.7373 51.5209 56.7373 53.9088 57.1175 56.2549L64.5358 57.4336C64.7532 58.0783 65.019 58.7204 65.3241 59.3409L60.9106 65.4202C61.5934 66.365 62.3551 67.2691 63.2057 68.1223C64.0589 68.973 64.9656 69.7372 65.9091 70.4213L71.9947 66.0077C72.6127 66.3154 73.2498 66.5774 73.8995 66.7973L75.0782 74.2131C77.4205 74.5958 79.811 74.5907 82.1519 74.2118L83.3332 66.7948C83.9804 66.5723 84.6187 66.3167 85.2405 66.0052L91.3173 70.4149C92.2659 69.7397 93.175 68.973 94.0257 68.1223C94.8776 67.2742 95.6406 66.365 96.317 65.4164L91.9073 59.3422C92.2163 58.7191 92.4744 58.0834 92.6957 57.4336L100.109 56.2549C100.494 53.9063 100.497 51.5209 100.113 49.1825L92.6957 47.9987Z"
        fill="#5F7C8F"
      />
      <path
        d="M47.1583 83.8178C44.0583 83.8178 41.5431 81.3014 41.5444 78.1988C41.5419 75.0962 44.057 72.5811 47.157 72.5811C50.2558 72.5798 52.776 75.0975 52.7786 78.2001C52.776 81.3014 50.2571 83.8178 47.1583 83.8178ZM55.9626 75.2526C55.824 74.8381 55.6561 74.4452 55.4692 74.0612L58.2247 70.2618C57.3575 69.0538 56.3021 67.9984 55.1005 67.1325L51.2985 69.8892C50.9119 69.6972 50.5165 69.5332 50.1071 69.3946L49.3695 64.7585C48.6498 64.6402 47.9111 64.5754 47.1583 64.5754C46.4056 64.5779 45.668 64.6428 44.9496 64.7597L44.2121 69.3946C43.8001 69.5319 43.4021 69.6985 43.0194 69.8892L39.2187 67.1312C38.0171 67.9971 36.9592 69.0551 36.0958 70.2592L38.8512 74.0586C38.6592 74.4439 38.4927 74.8381 38.3541 75.2514L33.7205 75.9863C33.6035 76.706 33.5387 77.4435 33.5361 78.1988C33.5374 78.949 33.601 79.6891 33.7205 80.4062L38.3541 81.1476C38.4927 81.557 38.6592 81.9537 38.8525 82.3365L36.097 86.1384C36.9592 87.3426 38.0158 88.3967 39.22 89.2639L43.0207 86.5072C43.4009 86.6966 43.8039 86.8632 44.2121 87.0005L44.9496 91.6404C45.668 91.7561 46.403 91.8185 47.1596 91.8223C47.9111 91.8185 48.6498 91.7561 49.3683 91.6404L50.1071 87.0005C50.5178 86.8632 50.9132 86.6966 51.2998 86.5072L55.0979 89.2626C56.3021 88.3992 57.3575 87.3426 58.2247 86.1384L55.4692 82.3365C55.6574 81.9537 55.8252 81.5557 55.9626 81.145L60.5961 80.4075C60.7182 79.6891 60.7805 78.9478 60.7805 78.1988C60.7805 77.4461 60.7182 76.706 60.5987 75.9863L55.9626 75.2526Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(BrakeStroke);
