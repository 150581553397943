import convert from "convert";

export const convertMilesToKm = (miles: number): number => {
  const km = convert(miles, "miles").to("km").toFixed(1);
  return Number(km);
};
export const convertKmToMiles = (km: number): number => {
  const miles = convert(km, "km").to("miles").toFixed(1);
  return Number(miles);
};
export const convertMillibarsToPSI = (millibars: number): number => {
  const PSI = convert(millibars, "millibars").to("psi").toFixed(0);
  return Number(PSI);
};
export const convertPSIToMillibars = (PSI: number): number => {
  const millibars = convert(PSI, "psi").to("millibars").toFixed(0);
  return Number(millibars);
};
