import { capitalize } from "lodash";
import { Maybe } from "yup/lib/types";
import { GeofenceData } from "../../../../../../graphql/operations";
import { DetailItem } from "../types";

type VehicleType =
  | "boat"
  | "container"
  | "chassis"
  | "dolly"
  | "tractor"
  | "trailer"
  | "reefer"
  | "vehicle"
  | "other";

const VEHICLE_TYPES: VehicleType[] = [
  "boat",
  "container",
  "chassis",
  "dolly",
  "tractor",
  "trailer",
  "reefer",
  "vehicle",
  "other",
];

function getCapacityInfo(
  geofence: Maybe<GeofenceData>,
  vehicleType: VehicleType
) {
  const { min, max } = geofence?.configuration?.capacity?.[vehicleType] || {};
  return {
    min: min ?? "0",
    max: max ?? "0",
  };
}

export function getGeofenceCapacityInfo(geofence: Maybe<GeofenceData>) {
  const detailList: Array<DetailItem<string>> = [];

  VEHICLE_TYPES.forEach((vehicleType) => {
    const { min, max } = getCapacityInfo(geofence, vehicleType);
    detailList.push({
      label: capitalize(vehicleType), // Capitalize first letter
      detailItemValue: `${min}, ${max}`,
      truncateText: true,
    });
  });

  return detailList;
}
