import { SET_SELECTED_ORGANIZATION } from "../../constants";
import { Action } from "../commonTypes";

export type SelectedOrganization = {
  label: string;
  value: string;
  timezone: string;
  level?: number;
  index?: number;
};

export type SelectedOrganizationReducerInitialState = {
  selectedOrganization: SelectedOrganization;
};

export type SelectedOrganizationReducerType = (
  state: SelectedOrganizationReducerInitialState | undefined,
  action: Action
) => SelectedOrganizationReducerInitialState;

export const initialSelectedOrganizationState: SelectedOrganizationReducerInitialState =
  {
    selectedOrganization: { label: "", value: "", timezone: "" },
  };

export const SelectedOrganizationReducer = (
  state = initialSelectedOrganizationState,
  action: Action
) => {
  switch (action.type) {
    case SET_SELECTED_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    default:
      return state;
  }
};
