import { ReactElement } from "react";
import classNames from "classnames";

export interface TireSensorChipProps {
  color: string;
  icon?: ReactElement;
  size?: "small" | "big";
  isSelected?: boolean;
  dataTestid?: string;
}

export const TireSensorChip: React.FC<TireSensorChipProps> = ({
  icon,
  color,
  size = "small",
  isSelected = false,
  dataTestid = "tire-sensor-chip",
}) => {
  return (
    <div className="front-left-inner" data-testid={dataTestid}>
      <div
        className={classNames(
          `${color} border flex items-center justify-center`,
          {
            "w-4 h-7 border-1 rounded-lg": size === "small",
          },
          {
            "w-[75px] h-[140px] m-1 border-4 rounded-2xl ": size === "big",
          },
          {
            "border-primary-blue": isSelected,
          },
          {
            "border-light-charcoal": !isSelected,
          }
        )}
      >
        {icon}
      </div>
    </div>
  );
};
