import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useReportViewTheme } from "../../../ReportView/hooks";

export const useSettingsTheme = () => {
  const reportViewTheme = useReportViewTheme();

  return createTheme(
    deepmerge(reportViewTheme, {
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              padding: "10px 14px",
            },
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--dark-grey) !important",
              },
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            switchBase: {
              "&.Mui-checked": {
                color: "var(--brand) !important",
              },
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            endAdornment: {
              top: "unset",
            },
            clearIndicator: {
              "&:hover, &.Mui-focusVisible": {
                backgroundColor: "transparent", // Disable background color change on hover/focus
              },
            },
            popupIndicator: {
              "&:hover, &.Mui-focusVisible": {
                backgroundColor: "transparent", // Disable background color change on hover/focus
              },
            },
            option: {
              "&:hover": {
                backgroundColor: "var(--brand-transparent)",
              },
              "&.Mui-selected": {
                backgroundColor: "var(--brand)",
                color: "white",
              },
              "&.Mui-focused &:hover": {
                backgroundColor: "var(--brand) !important",
              },
            },
            listbox: {
              backgroundColor: "var(--secondary)",
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "var(--brand-transparent) !important",
              },
              "&.Mui-selected": {
                backgroundColor: "var(--brand) !important",
                color: "white",
              },
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--secondary)",
            },
          },
        },
      },
    })
  );
};
