import * as yup from "yup";
import { TEMPERATURE_FORM_FIELDS } from "./constants";

export const sensorsTemperatureSchema = yup.object().shape({
  typeOfAssignment: yup.string().required("Field is required!").nullable(),
  temperatureSensorProfile: yup
    .string()
    .nullable()
    .when("typeOfAssignment", {
      is: TEMPERATURE_FORM_FIELDS[0].options[1].value,
      then: yup.string().required("Field is required!").nullable(),
      otherwise: yup.string().nullable().notRequired(),
    }),
});
