import { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { nanoid } from "nanoid";

export const AlertsHistogramSkeleton: React.FC = () => {
  const [bars, setBars] = useState<string[]>([]);

  useEffect(() => {
    const getRandomHeight = () => `${Math.floor(Math.random() * 100) + 100}px`;

    // Calculate the number of bars based on the window width
    const calculateBars = () => {
      const numberOfBars = Math.max(10, Math.floor(window.innerWidth / 65));

      return Array.from({ length: numberOfBars }, getRandomHeight);
    };

    const handleResize = () => {
      const newBars = calculateBars();
      setBars(newBars);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      data-testid="alerts-histogram-skeleton-loader"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        height: "100%",
        width: "100%",
      }}
    >
      {bars.map((height, index) => (
        <Skeleton
          variant="rectangular"
          data-testid={`alerts-histogram-skeleton-bar-${index}`}
          key={nanoid()}
          sx={{
            height,
            marginRight: "30px",
            width: "1.5rem",
          }}
        />
      ))}
    </Box>
  );
};
