import { FC, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useFindAssetById } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { useApplicationTheme } from "../../../../../../shared/hooks/theme/useApplicationTheme";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext/AssetsDataContext";
import AssetErrorMessage from "../../../Shared/AssetsErrorMessage";
import ToggleViewButton from "../../../Shared/ToggleViewButton";
import { TiresTabView } from "../../../Shared/ToggleViewButton/ToggleViewButton";
import { defaultBorderStyling } from "../constants";
import AxlesSummaryCards from "./cards/psiWheelEnd/AxlesSummaryCards";
import TiresSummaryCards from "./cards/psiWheelEnd/TiresSummaryCards";
import TiresSummaryTable from "./cards/psiWheelEnd/TiresSummaryTable";

interface PsiWheelEndSensorCardsSectionProps {}

const PsiWheelEndSensorCardsSection: FC<
  PsiWheelEndSensorCardsSectionProps
> = () => {
  const { selectedAssetId } = useAssetsDataContext();
  const [activeView, setActiveView] = useState<TiresTabView>(TiresTabView.Card);
  const isDesktop = useBreakpoint("up", "md");
  const { applicationTheme } = useApplicationTheme();
  const [isDarkTheme, setIsDarkTheme] = useState(applicationTheme === "dark");

  useEffect(() => {
    setIsDarkTheme(applicationTheme === "dark");
  }, [applicationTheme]);

  const {
    isSuccess,
    isError,
    data: selectedAsset,
  } = useFindAssetById(
    {
      assetId: selectedAssetId ?? "",
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  return (
    <Box
      className="flex flex-col w-full mt-4"
      data-testid="asset-dashboard--tires-tab--psi-wheel-end-cards-section"
    >
      {isError && <AssetErrorMessage />}

      {isSuccess && selectedAsset?.sensors?.psiWheelEnd !== null && (
        <>
          {selectedAsset?.sensors?.psiWheelEnd?.data?.psiWheelEndMeasure && (
            <Box display="flex" className="w-full">
              {isDesktop && (
                <Box
                  sx={{
                    backgroundColor: "var(--dashboard_subheader__bg)",
                    width: "50%",
                    borderRadius: "8px",
                    border: defaultBorderStyling,
                    padding: "16px",
                    marginRight: "24px",
                    minHeight: "550px",
                  }}
                >
                  <Typography
                    data-testid="atis-alpha-table-label"
                    sx={{
                      fontSize: "18px",
                      paddingBottom: "16px",
                      color: "var(--brand)",
                      fontWeight: 600,
                    }}
                  >
                    Wheel End Sensors
                  </Typography>
                  <AxlesSummaryCards asset={selectedAsset} />
                </Box>
              )}

              <Box
                sx={{
                  backgroundColor: !isDesktop
                    ? ""
                    : "var(--dashboard_subheader__bg)",
                  width: "100%",
                  border: defaultBorderStyling,
                  borderRadius: "8px",
                  padding: isDesktop ? "16px" : "0px 16px 0px 16px",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{
                    paddingBottom: isDesktop ? "16px" : "0",
                    alignSelf: "flex-end",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ paddingBottom: "16px" }}
                  >
                    <Typography
                      data-testid="psi-wheelend-table-label"
                      sx={{
                        fontSize: "18px",
                        color: "var(--brand)",
                        fontWeight: 600,
                      }}
                    >
                      Wheel End Sensors Details
                    </Typography>

                    <ToggleViewButton
                      whiteTheme={isDarkTheme ? false : true}
                      activeView={activeView}
                      onClick={setActiveView}
                    />
                  </Box>
                  {activeView === TiresTabView.Card && (
                    <TiresSummaryCards asset={selectedAsset} />
                  )}

                  {activeView === TiresTabView.Table && (
                    <TiresSummaryTable asset={selectedAsset} />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PsiWheelEndSensorCardsSection;
