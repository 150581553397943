import { ReactElement } from "react";
import { Typography } from "@mui/material";
import { Bar } from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import BaseBarChart from "../../BaseVerticalBarChart";
import { VerticalBarChartOverlay } from "../../LoadingOverlaySkeletons/VerticalBarChartLoader/VerticalBarChartOverlay";
import BaseWidget, { BaseDashboardWidgetProps } from "../BaseDashboardWidget";
import { NoDataAvailableComponent } from "../EmptyWidgetState/NoDataAvailable";

export type Label =
  | boolean
  | string
  | { [key: string]: string }
  | ((props: any) => ReactElement);

type BarConfig = {
  key: string;
  color?: string;
  size?: number;
  label?: Label;
};

export interface VerticalBarChartWidgetProps<T>
  extends BaseDashboardWidgetProps {
  data: T[];
  barColor?: string;
  xAsisKey: string;
  xAxisTickFormatter?: (tickItem: any) => string;
  barKey: string;
  label?: Label;
  width?: string | number;
  height?: string | number;
  barsConfig?: BarConfig[];
  isLoading: boolean;
  ReferenceArea?: ReactElement;
  maxBarSize?: number;
  yAxisLabel?: string;
  yAxisLabelColor?: string;
  yAxisLabelPosition?: string;
  yAxisDomain?: AxisDomain;
  noPermission?: boolean;
}

export const chartTickStyle = {
  fontSize: 10,
  fill: "var(--chart-tick-style)",
  fontWeight: 500,
};

const VerticalBarChartWidget = <T,>(props: VerticalBarChartWidgetProps<T>) => {
  const {
    data,
    title,
    children,
    isDateFilterEnabled,
    isEditMode = false,
    onWidgetDelete,
    startDate,
    endDate,
    onDateFilterChange,
    barColor,
    xAsisKey,
    xAxisTickFormatter,
    barKey,
    width = "100%",
    height = 500,
    label,
    yAxisLabel,
    barsConfig,
    maxBarSize,
    isLoading = false,
    ReferenceArea,
    yAxisLabelColor,
    yAxisLabelPosition,
    yAxisDomain,
    noPermission,
  } = props;

  /* There are 3 ways to render bars:
    1. Pass children prop for full control (highest priority)
    2. Pass list of options that will render multiple Bar elements (lower priority)
    3. Pass single props to render a single Bar (lowest priority)
  */
  let Bars = (
    <Bar dataKey={barKey} fill={barColor} barSize={200} label={label} />
  );

  if (children) {
    Bars = <> {children} </>;
  } else if (barsConfig?.length) {
    Bars = (
      <>
        {barsConfig.map(({ key, color, size, label }) => (
          <Bar dataKey={key} fill={color} barSize={size} label={label} />
        ))}
      </>
    );
  }

  return (
    <BaseWidget
      title={title}
      isDateFilterEnabled={isDateFilterEnabled}
      onDateFilterChange={onDateFilterChange}
      isEditMode={isEditMode}
      isLoading={isLoading}
      onWidgetDelete={onWidgetDelete}
      startDate={startDate}
      endDate={endDate}
      noPermission={noPermission}
    >
      {isLoading ? (
        <VerticalBarChartOverlay children={children} />
      ) : (
        <BaseBarChart
          xAsisKey={xAsisKey}
          yAxisLabel={yAxisLabel}
          data={data}
          width={width}
          height={height}
          maxBarSize={maxBarSize}
          xAxisTickFormatter={xAxisTickFormatter}
          yAxisLabelColor={yAxisLabelColor}
          yAxisLabelPosition={yAxisLabelPosition}
          yAxisDomain={yAxisDomain}
        >
          {ReferenceArea}

          {Bars}
        </BaseBarChart>
      )}

      {!data?.length && !isLoading && (
        <NoDataAvailableComponent title={"No data available"} />
      )}
    </BaseWidget>
  );
};

export default VerticalBarChartWidget;
