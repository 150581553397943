import { HTMLAttributes, useMemo } from "react";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { property } from "lodash";
import {
  useAssetsDataContext,
  ZoneFilterOption,
} from "../../../shared/AssetsDataContext";
import { useZonesFilterOptions } from "../../../shared/AssetsFilterControls/hooks/useZonesFilterOptions";

export interface ZonesFilterProps {
  type: "assets" | "geofences";
}

export const ZonesFilter: React.FC<ZonesFilterProps> = ({ type }) => {
  const { zonesOptions, isLoadingZones } = useZonesFilterOptions();
  const {
    // assets
    currentFilter: { zones: assetsZonesFilter },
    onChangeFilters: onChangeAssetsFilters,

    // geofence
    currentGeofenceFilter: { zones: geofenceZonesFilter },
    onChangeGeofenceFilters,
  } = useAssetsDataContext();

  const onChangeFilter = useMemo(
    () => (type === "assets" ? onChangeAssetsFilters : onChangeGeofenceFilters),
    [onChangeAssetsFilters, onChangeGeofenceFilters, type]
  );
  const selectedZones = useMemo(
    () => (type === "assets" ? assetsZonesFilter : geofenceZonesFilter),
    [assetsZonesFilter, geofenceZonesFilter, type]
  );

  return (
    <Box className="my-4 flex flex-col justify-evenly">
      <Box className="font-bold text-[10px] leading-4">Zones</Box>
      <Autocomplete
        openOnFocus
        size="small"
        sx={{
          "& .MuiChip-label": {
            maxWidth: "15ch",
          },
        }}
        data-testid={`${type}-zones-filter`}
        groupBy={property("country")}
        loading={isLoadingZones}
        disabled={isLoadingZones}
        options={zonesOptions}
        value={selectedZones}
        isOptionEqualToValue={(option, value) =>
          option.id.toString() === value.id.toString()
        }
        onChange={(
          _: React.SyntheticEvent<Element, Event>,
          selectedZone: ZoneFilterOption | null
        ) => {
          onChangeFilter({ zones: selectedZone });
        }}
        renderOption={(
          props: HTMLAttributes<HTMLLIElement>,
          option: ZoneFilterOption
        ) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((zone, index) => (
            <Chip
              {...getTagProps({ index })}
              // If there are multiple zones with the same label append
              // the country name as well for clarity
              label={
                value.filter(({ label }) => label === zone.label).length > 1
                  ? `${zone.label} (${zone.country})`
                  : zone.label
              }
              key={zone.id}
              data-testid={`${type}-zones-filter-chip-${zone.id}`}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Zones"
            data-testid={`${type}-zones-filter-input`}
          />
        )}
      />
    </Box>
  );
};
