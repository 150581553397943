import { MutationKey, UseMutationOptions } from "@tanstack/react-query";
import {
  RemoveAssetMutation,
  RemoveAssetMutationVariables,
  RemoveAssetOsMutation,
  useRemoveAssetMutation,
  useRemoveAssetOsMutation,
  RemoveAssetOsMutationVariables,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";

type RemoveAssetMutationOptions = UseMutationOptions<
  RemoveAssetMutation,
  unknown,
  RemoveAssetMutationVariables,
  MutationKey
> &
  UseMutationOptions<
    RemoveAssetOsMutation,
    unknown,
    RemoveAssetOsMutationVariables,
    MutationKey
  >;

export const useRemoveAsset = (options: RemoveAssetMutationOptions) => {
  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const mutation = fetchAssetsFromOpenSearchFeatureFlag
    ? useRemoveAssetOsMutation
    : useRemoveAssetMutation;

  const { mutate, mutateAsync, isLoading } = mutation(options);

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
