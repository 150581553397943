import { GridColDef } from "@mui/x-data-grid-premium";
import { LiftgateBatteryTableData } from "../../../../../../../../graphql/operations";
import {
  DATE_TIME_FORMAT_SHORT,
  formatDate,
} from "../../../../../../../../utils";

export const columns: GridColDef<LiftgateBatteryTableData>[] = [
  {
    field: "currentBattery",
    headerName: "Current Battery",
  },
  {
    field: "stateOfCharge",
    headerName: "State of Charge",
  },
  {
    field: "chargingStatus",
    headerName: "Charging Status",
  },
  {
    field: "status",
    headerName: "Status",
  },
  {
    field: "lastUpdated",
    headerName: "Last Updated",
    type: "date",
    valueFormatter: (params: { value: string }) =>
      formatDate(params.value, DATE_TIME_FORMAT_SHORT),
  },
].map((column) => ({
  ...column,
  flex: 1,
  minWidth: 120,
  sortable: false,
}));

export const columnVisibilityModel: Record<string, boolean> = {
  currentBattery: true,
  stateOfCharge: true,
  chargingStatus: true,
  status: true,
  lastUpdated: true,
};
