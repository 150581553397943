import { GridColDef } from "@mui/x-data-grid-premium";
import { AbsFaultCodeDetails } from "../../../../../graphql/operations";
import { createField } from "../../../../../shared/helpers/utils";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";

export const defaultColumnVisibilityModel = {
  faultStart: true,
  faultEnd: true,
  duration: true,
  manufacturer: true,
  dtc: true,
  sid: true,
  fmi: true,
  cause: true,
  description: true,
};

export const getColumns = (
  timezone: string
): GridColDef<AbsFaultCodeDetails>[] => [
  {
    field: "faultStart",
    headerName: "Fault Start Time",
    flex: 1,
    minWidth: 120,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  {
    field: "faultEnd",
    headerName: "Fault End Time",
    flex: 1,
    minWidth: 120,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  {
    field: "duration",
    headerName: "Duration",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "manufacturer",
    headerName: "Manufacturer",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "dtc",
    headerName: "DTC",
    flex: 0,
    minWidth: 120,
    filterable: false,
  },
  {
    field: "sid",
    headerName: "SID",
    flex: 0,
    minWidth: 120,
  },
  {
    field: "fmi",
    headerName: "FMI",
    flex: 0,
    minWidth: 120,
    filterable: false,
  },
  {
    field: "cause",
    headerName: "Cause",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    minWidth: 120,
  },
];

export const BRAKES_ABSFC_PAGE_SIZE = 100;
