import BallotIcon from "@mui/icons-material/Ballot";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCurrentTheme } from "../../../../shared/hooks/theme/useCurrentTheme";

const NoWidgetsToDisplay = () => {
  const muiTheme = useCurrentTheme();
  const colorToUse =
    muiTheme.palette.mode === "dark"
      ? "var(--feather-gray)"
      : "var(--light-charcoal)";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        color: colorToUse,
      }}
      data-testid="no-widgets-to-display-message"
    >
      <BallotIcon
        className="!opacity-40"
        sx={{
          fontSize: "8rem !important",
          color: `${colorToUse} !important`,
        }}
      />
      <Typography className="text-2xl font-semibold	opacity-40">
        No Widgets Are Added
      </Typography>
    </Box>
  );
};

export default NoWidgetsToDisplay;
