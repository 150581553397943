export interface TransferDevice {
  asset_id: string;
  imei: string;
}

export interface TransferAsset extends TransferDevice {
  vin: string;
}

export const transferDeviceReadableNames: TransferDevice = {
  asset_id: "Asset ID",
  imei: "Device ID",
};

export const transferAssetReadableNames: TransferAsset = {
  asset_id: "Asset ID",
  imei: "Device ID",
  vin: "VIN",
};
