import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

export interface DrawerContentProps {
  styles?: string;
  children: ReactNode;
}

const DrawerContent: FC<DrawerContentProps> = ({ styles, children }) => (
  <Box
    data-testid="drawer-content"
    className={`flex-1 bg-background overflow-x-hidden ${styles ?? ""}`}
  >
    {children}
  </Box>
);

export default DrawerContent;
