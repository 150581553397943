import { IconButton } from "@mui/material";
import { CustomTooltip } from "../../components/CustomTooltip/CustomTooltip";

export interface DeviceCommandButtonProps {
  tooltipTitle: string;
  onClick: () => void;
  isLoading: boolean;
  children: JSX.Element;
  dataTestId: string;
  btnBgColor?: string;
}

const DeviceCommandButton: React.FC<DeviceCommandButtonProps> = ({
  tooltipTitle,
  isLoading,
  onClick,
  children,
  dataTestId,
  btnBgColor = "var(--primary-blue)",
}) => {
  return (
    <CustomTooltip title={tooltipTitle} arrow>
      <IconButton
        disabled={isLoading}
        onClick={onClick}
        data-testid={dataTestId}
        sx={{
          backgroundColor: btnBgColor,
          width: "48px",
          height: "32px",
          borderRadius: "100px",
          "&:hover": {
            backgroundColor: btnBgColor,
          },
          "&:disabled": {
            backgroundColor: "var(--gray)",
            border: "2px solid var(--dark-charcoal)",
            pointerEvents: "auto",
          },
        }}
      >
        {children}
      </IconButton>
    </CustomTooltip>
  );
};

export default DeviceCommandButton;
