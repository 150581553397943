import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { ColorsPalette } from "../../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
  UsersPerOrgData,
  UsersPerOrgRecord,
} from "../../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { DEFAULT_LEGEND_TEXT_COLOR } from "../../../../../../shared/components/DashboardWidgets/PieChartWidget/constants";
import { useSelectedOrg } from "../../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../../shared/utils";

export interface UsersPerOrganizationWidgetProps {
  widget: GetDashboardWidgetResponse;
}

export interface DashboardWidgetUsersPerSubOrg
  extends GetDashboardWidgetResponse {
  data: UsersPerOrgData;
}

export const mapWidgetData = (
  data: UsersPerOrgData
): HorizontalBarChartData[] => {
  if (!data?.orgRecords?.length) {
    return [];
  }

  // Push other in the main array so it's mapped correctly
  data?.orgRecords?.push({
    name: "Other",
    org_users: data.otherOrgsUserCount,
  });

  return data.orgRecords.map((org, index) => ({
    name: org?.name ?? `Unknown ${index + 1}`,
    value: org?.org_users as number,
    color: mapChartColors(org as UsersPerOrgRecord),
    legendTextColor: mapLegendTextColor(org as UsersPerOrgRecord),
  }));
};

const mapChartColors = (data: UsersPerOrgRecord): string => {
  if (data.name === "Other") {
    return "var(--concrete)";
  }

  return `${ColorsPalette.ReChartsRed}`;
};

const mapLegendTextColor = (data: UsersPerOrgRecord): string => {
  if (data.name === "Other") {
    return "var(--dark-charcoal)";
  }

  return DEFAULT_LEGEND_TEXT_COLOR;
};

export const UsersPerSubOrganizationWidget: React.FC<
  UsersPerOrganizationWidgetProps
> = ({ widget }) => {
  const title = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: UsersPerOrgData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.total ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData?.getDashboardWidget?.data]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--users-per-sub-org"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the users per
          suborganization widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          subtitle="Total number of Users"
          isLoading={isLoading}
          showXAxis={false}
          showTotal={true}
          showPercentages={true}
          totalRecords={total}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
