import React from "react";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import { ReadOnlyMenu } from "../../../ReadOnlyMenu/ReadOnlyMenu";

export interface DashboardFrameSubtitleProps {
  text: string;
}

export const DashboardFrameSubtitle: React.FC<DashboardFrameSubtitleProps> = ({
  text,
}) => {
  const isMobile = useBreakpoint("down", "sm");

  return isMobile ? (
    <ReadOnlyMenu menuItems={[{ text }]} />
  ) : (
    <span
      className="text-base font-medium"
      data-testid="dashboard-frame-subtitle"
    >
      {text}
    </span>
  );
};
