import { isEmpty } from "lodash";
import * as yup from "yup";
import {
  parsePhoneNumber,
  shouldNoteHaveTrimSpaces,
  validatePhoneNumber,
} from "../../../../utils";

yup.addMethod(yup.mixed, "shouldNoteHaveTrimSpaces", shouldNoteHaveTrimSpaces);

export const userSchema = yup.object().shape({
  firstName: yup.string().required("required").shouldNoteHaveTrimSpaces(),
  lastName: yup.string().required("required").shouldNoteHaveTrimSpaces(),
  name: yup
    .string()
    .required("required")
    .matches(/^(\S+$)/, "No blank spaces"),
  customer_orgs_id: yup.string().nullable().required("required"),
  groups: yup.string().nullable().required("required"),
  phoneNumber: yup
    .string()
    .nullable()
    .test(
      "isValidPhoneNumber",
      "Phone number must be 5 to 20 digits",
      (input) => {
        let number = parsePhoneNumber(input ?? "");
        return isEmpty(number)
          ? true
          : validatePhoneNumber(`+${number}`) !== null; // this + is added because input does not alow + sign but later +1 is added to the phone number
      }
    ),
  email: yup.string().email().required("required"),
});
