import { ASSET_FORM_TYPES } from "../../../../../constants/assets";
import {
  ReportAlertHistoryTableData,
  DwellHierarchyTableData,
  TableColumnFormat as ReportColumnFormat,
  TableValueDataType,
} from "../../../../../graphql/operations";
import {
  TableGridColDef,
  TableColumnProps,
  getTableColumn,
} from "../../../../../shared/components/Table";

export const defaultColumnVisibilityModel = {
  geofenceAddress: false,
  geofenceCity: false,
  geofenceState: false,
  geofenceName: false,
  geofenceType: false,
  currentAssetCount: false,
  averageDwell: false,
  currentTrailerCount: false,
  averageTrailerDwell: false,
  currentBoatCount: false,
  averageBoatDwell: false,
  currentChassisCount: false,
  averageChassisDwell: false,
  currentContainerCount: false,
  averageContainerDwell: false,
  currentDollyCount: false,
  averageDollyDwell: false,
  currentOtherCount: false,
  averageOtherDwell: false,
  currentReeferCount: false,
  averageReeferDwell: false,
  currentTractorCount: false,
  averageTractorDwell: false,
  currentVehicleCount: false,
  averageVehicleDwell: false,
  averageAssetDwellDays: false,
  averageTrailerDwellDays: false,
  averageBoatDwellDays: false,
  averageChassisDwellDays: false,
  averageContainerDwellDays: false,
  averageDollyDwellDays: false,
  averageOtherDwellDays: false,
  averageReeferDwellDays: false,
  averageTractorDwellDays: false,
  averageVehicleDwellDays: false,
};

const createAssetTypeColumns = () => {
  const columns: TableColumnProps<
    DwellHierarchyTableData | ReportAlertHistoryTableData
  >[] = [];
  Object.values(ASSET_FORM_TYPES).forEach((value) => {
    if (value !== "Trailer") {
      columns.push({
        field: `current${value}Count`,
        headerName: `Current ${value} Count`,
        type: "number",
      });
      columns.push({
        field: `average${value}DwellDays`,
        headerName: `Average ${value} Dwell (days)`,
        type: "number",
      });
    }
  });
  return columns;
};

export const getColumns = (): TableGridColDef<
  DwellHierarchyTableData | ReportAlertHistoryTableData
>[] => {
  const columns: TableColumnProps<
    DwellHierarchyTableData | ReportAlertHistoryTableData
  >[] = [
    {
      field: "geofenceAddress",
      headerName: "Geofence Address",
      type: "string",
    },
    {
      field: "geofenceCity",
      headerName: "Geofence City",
      type: "string",
    },
    {
      field: "geofenceState",
      headerName: "Geofence State",
      type: "string",
    },
    {
      field: "geofenceName",
      headerName: "Geofence Name",
      type: "string",
    },
    {
      field: "geofenceType",
      headerName: "Geofence Type",
      type: "string",
    },
    {
      field: "currentAssetCount",
      headerName: "Current Asset Count",
      type: "number",
    },
    {
      field: "averageAssetDwellDays",
      headerName: "Average Dwell (days)",
      type: "number",
    },
    {
      field: "currentTrailerCount",
      headerName: "Current Trailer Count",
      type: "number",
    },
    {
      field: "averageTrailerDwellDays",
      headerName: "Average Trailer Dwell (days)",
      type: "number",
    },
    ...createAssetTypeColumns(),
  ];

  return columns.map(getTableColumn);
};

export const DWELL_HIERARCHY_COLUMN_TYPES_MAP: Record<
  string,
  TableValueDataType
> = {
  geofenceAddress: TableValueDataType.String,
  geofenceCity: TableValueDataType.String,
  geofenceState: TableValueDataType.String,
  geofenceName: TableValueDataType.String,
  geofenceType: TableValueDataType.String,
  currentAssetCount: TableValueDataType.Number,
  averageDwell: TableValueDataType.Number,
  currentTrailerCount: TableValueDataType.Number,
  averageTrailerDwell: TableValueDataType.Number,
  currentBoatCount: TableValueDataType.Number,
  averageBoatDwell: TableValueDataType.Number,
  currentChassisCount: TableValueDataType.Number,
  averageChassisDwell: TableValueDataType.Number,
  currentContainerCount: TableValueDataType.Number,
  averageContainerDwell: TableValueDataType.Number,
  currentDollyCount: TableValueDataType.Number,
  averageDollyDwell: TableValueDataType.Number,
  currentOtherCount: TableValueDataType.Number,
  averageOtherDwell: TableValueDataType.Number,
  currentReeferCount: TableValueDataType.Number,
  averageReeferDwell: TableValueDataType.Number,
  currentTractorCount: TableValueDataType.Number,
  averageTractorDwell: TableValueDataType.Number,
  currentVehicleCount: TableValueDataType.Number,
  averageVehicleDwell: TableValueDataType.Number,
  averageAssetDwellDays: TableValueDataType.Number,
  averageTrailerDwellDays: TableValueDataType.Number,
  averageBoatDwellDays: TableValueDataType.Number,
  averageChassisDwellDays: TableValueDataType.Number,
  averageContainerDwellDays: TableValueDataType.Number,
  averageDollyDwellDays: TableValueDataType.Number,
  averageOtherDwellDays: TableValueDataType.Number,
  averageReeferDwellDays: TableValueDataType.Number,
  averageTractorDwellDays: TableValueDataType.Number,
  averageVehicleDwellDays: TableValueDataType.Number,
};
