import { Box } from "@mui/material";

export enum UsedSpaceIndicatorMode {
  FullTitle = "full",
  ShortTitle = "short",
  Hidden = "hidden",
}

export interface UsedSpaceIndicatorProps {
  title: string;
  value: string;
  mode?: UsedSpaceIndicatorMode;
}

const UsedSpaceIndicator: React.FC<UsedSpaceIndicatorProps> = ({
  title,
  value,
  mode = UsedSpaceIndicatorMode.FullTitle,
}) => {
  const opacity = parseInt(value.replace("%", "")) / 100;

  const formatTitle = (title: string, mode: UsedSpaceIndicatorMode): string => {
    switch (mode) {
      case UsedSpaceIndicatorMode.FullTitle:
        return title;

      case UsedSpaceIndicatorMode.ShortTitle:
        // Get only the first letter of each word
        return title
          .split(" ")
          .map((word) => word.substring(0, 1))
          .join("");

      case UsedSpaceIndicatorMode.Hidden:
      default:
        return "";
    }
  };

  if (mode === UsedSpaceIndicatorMode.Hidden) {
    return null;
  }

  return (
    <Box
      className="flex items-center bg-card__bg h-[40px] rounded px-2 py-1 mb-2 text-sm leading-4 font-bold text-card__typography"
      data-testid="internal-camera-sensor--used-space-indicator"
    >
      <span>{formatTitle(title, mode)}</span>
      <Box
        className="inline-block w-[32px] h-[32px] mx-2 bg-primary-blue rounded"
        sx={{ opacity: opacity }}
        data-testid="internal-camera-sensor--used-space-indicator--bar"
      ></Box>
      <span>{value}</span>
    </Box>
  );
};

export default UsedSpaceIndicator;
