import { ChangeEventHandler, Ref } from "react";
import { RefCallBack } from "react-hook-form";
import { Input, InputAdornment } from "@mui/material";
import ColorPreviewComponent from "../ColorPreview/ColorPreview.component";
import { Label } from "../FormControlElement/styledElements";
import WithAsterisk from "../WithAsterisk";

export interface ColorInputProps {
  value: string;
  label: string;
  inputRef: RefCallBack | Ref<HTMLInputElement>;
  disabled?: boolean;
  required?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const ColorInput: React.FC<ColorInputProps> = ({
  value,
  label,
  inputRef,
  disabled,
  required,
  onChange,
}) => {
  return (
    <>
      <WithAsterisk enable={required}>
        <Label>{label}</Label>
      </WithAsterisk>
      <Input
        ref={inputRef}
        type="text"
        disabled={disabled}
        onChange={onChange}
        value={value}
        inputProps={{
          "data-testid": "input-color",
        }}
        endAdornment={
          <InputAdornment position="end">
            <ColorPreviewComponent
              showText={false}
              hexColor={value}
              className="basis-0"
            />
          </InputAdornment>
        }
      />
    </>
  );
};
