import { FC } from "react";
import { Typography } from "@mui/material";

interface NoDataAvailableProps {
  title: string;
}
export const NoDataAvailableComponent: FC<NoDataAvailableProps> = ({
  title,
}) => {
  return (
    <div className="h-[120px] flex items-center justify-center">
      <Typography className="!font-medium">{title}</Typography>
    </div>
  );
};
