import { memo, SVGProps, FC } from "react";

interface SuccessProps extends SVGProps<SVGSVGElement> {}

const Success: FC<SuccessProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.86211 7.275L8.39961 3.7375L7.82461 3.175L4.86211 6.1375L3.36211 4.6375L2.79961 5.2L4.86211 7.275ZM5.59961 10C4.91628 10 4.27044 9.86875 3.66211 9.60625C3.05378 9.34375 2.52253 8.98542 2.06836 8.53125C1.61419 8.07708 1.25586 7.54583 0.993359 6.9375C0.730859 6.32917 0.599609 5.68333 0.599609 5C0.599609 4.30833 0.730859 3.65833 0.993359 3.05C1.25586 2.44167 1.61419 1.9125 2.06836 1.4625C2.52253 1.0125 3.05378 0.65625 3.66211 0.39375C4.27044 0.13125 4.91628 0 5.59961 0C6.29128 0 6.94128 0.13125 7.54961 0.39375C8.15794 0.65625 8.68711 1.0125 9.13711 1.4625C9.58711 1.9125 9.94336 2.44167 10.2059 3.05C10.4684 3.65833 10.5996 4.30833 10.5996 5C10.5996 5.68333 10.4684 6.32917 10.2059 6.9375C9.94336 7.54583 9.58711 8.07708 9.13711 8.53125C8.68711 8.98542 8.15794 9.34375 7.54961 9.60625C6.94128 9.86875 6.29128 10 5.59961 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Success);
