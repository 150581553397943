import { useEffect, useState } from "react";
import {
  ConfigurationSetType,
  useGetConfigurationSetsQuery,
} from "../../../../../graphql/operations";
import { useGlobalOrganizationFilter } from "../../../../../shared/hooks/useGlobalOrganizationFilter";
import { ParsedZonesData } from "../interfaces";
import { parseZonesData, initialZonesData } from "../utils";

interface UseGetZonesData {
  zones: ParsedZonesData;
  isLoading: boolean;
}

export const useGetZonesData = (): UseGetZonesData => {
  // Hooks
  const globalOrgSelected = useGlobalOrganizationFilter();

  // States
  const [zonesState, setZonesState] =
    useState<ParsedZonesData>(initialZonesData);

  // Queries

  const { data: zonesData, isFetching: isLoadingZonesData } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: globalOrgSelected?.value ?? "",
          type: ConfigurationSetType.ZoneGroup,
          excludeDataFromParents: true,
          includeSuborgs: false,
        },
      },
      {
        enabled: Boolean(globalOrgSelected?.value),
      }
    );

  // Effects
  useEffect(() => {
    if (zonesData) {
      setZonesState(parseZonesData(zonesData?.getConfigurationSets));
    }
  }, [zonesData, setZonesState]);

  const isLoading = isLoadingZonesData;

  return {
    zones: zonesState,
    isLoading,
  };
};
