import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import config from "../config";

const { cognito } = config;

export const useCognitoAuthTokens = () => {
  return useMutation((authorizationCode: string) =>
    axios.post(
      `${cognito.domain}/oauth2/token?grant_type=authorization_code&code=${authorizationCode}&redirect_uri=${window.location.origin}/login&client_id=${cognito.clientId}&scope=aws.cognito.signin.user.admin%20openid%20email`,
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
  );
};

export const useRefreshCognitoAuthTokens = () => {
  return useMutation((refreshToken: string) =>
    axios.post(
      `${cognito.domain}/oauth2/token?grant_type=refresh_token&client_id=${cognito.clientId}&refresh_token=${refreshToken}`,
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
  );
};
