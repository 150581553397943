import { uniqBy } from "lodash";

export const useDropdownOptions = <T extends object>(
  data: Array<T>,
  value: keyof T,
  label: keyof T
) => {
  const options = data
    .map((row) => ({
      value: row[value] ?? "",
      label: row[label] ?? "",
    }))
    // Filter out rows with empty label or id
    .filter((row) => row.label && row.value);

  return uniqBy(options, (o) => o.label);
};
