import { memo, FC, useCallback, useState, MouseEvent } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/material";
import classNames from "classnames";
import RegularIcon from "../../../../assets/pngs/regular.png";
import SatelliteIcon from "../../../../assets/pngs/satellite.png";
import config from "../../../../config";
import { GOOGLE_MAP_STYLES } from "../../../../constants/map";
import { GoogleMapsTypeIds } from "../../../../enums/map";

//-------------------------------------
// Types
//-------------------------------------
export interface MapViewSwitcherProps {
  onMapViewChange: (view: string) => void;
  mapStyleSelected: string;
}

//-------------------------------------
// Main Component
//-------------------------------------
export const MapViewSwitcher: FC<MapViewSwitcherProps> = memo(
  ({ onMapViewChange, mapStyleSelected }) => {
    const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);
    const onToggleDrawer = useCallback((e: MouseEvent) => {
      e.stopPropagation();
      setIsDrawerOpened((prev) => !prev);
    }, []);

    const mapStyles = GOOGLE_MAP_STYLES;

    return (
      <Box className="w-[146px] sm:w-[183px] !max-w-[183px] p-2  rounded-lg bg-secondary shadow-card-sm !text-primary">
        <button
          data-testid="map-details-drawer"
          onClick={onToggleDrawer}
          className="flex !w-full !justify-between !items-center"
        >
          <span className="text-sm font-bold font-sans">Map Details</span>
          {isDrawerOpened ? (
            <KeyboardArrowUpIcon className="text-primary" />
          ) : (
            <KeyboardArrowDownIcon className="text-primary" />
          )}
        </button>
        {isDrawerOpened && (
          <>
            {mapStyles.map((item) => (
              <button
                data-testid={`map-details-value-${item.label}`}
                key={item.value}
                onClick={() => {
                  onMapViewChange(item.value);
                }}
                className={classNames(
                  "flex !w-full !items-center mt-1 text-sm rounded bg-[var(--map-details-item)] border-[3px] border-[var(--map-details-item)]  hover:border-[var(--brand)]",
                  {
                    "!border-[3px] !border-[var(--brand)]":
                      mapStyleSelected === item.value,
                  }
                )}
              >
                {item.value === config.map.satelliteView ||
                item.value === GoogleMapsTypeIds.SATELLITE ? (
                  <Box className="w-1/2">
                    <img src={SatelliteIcon} alt={"satellite-view"} />
                  </Box>
                ) : (
                  <Box className="w-1/2">
                    <img src={RegularIcon} alt={"street-view"} />
                  </Box>
                )}
                <span className="w-1/2 font-sans text-[10px] sm:text-base font-medium	sm:font-normal inline-block">
                  {item.label}
                </span>
              </button>
            ))}
          </>
        )}
      </Box>
    );
  }
);
