import React, { FC, useState } from "react";
import BusinessIcon from "@mui/icons-material/Business";
import { Button, Menu, MenuItem } from "@mui/material";
import { isEmpty } from "lodash";

export interface CompanySelectProps {
  selectedCompany: string;
  setSelectedCompany: (newlySelectedCompany: string) => void;
  companyOptions: string[];
}

const CompanySelect: FC<CompanySelectProps> = ({
  companyOptions,
  selectedCompany,
  setSelectedCompany,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-testid="detention-rules-config-company-select-trigger"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpenMenu}
        startIcon={<BusinessIcon fontSize="small" />}
        disabled={isEmpty(companyOptions)}
        sx={{
          fontWeight: "bold",
          color: isEmpty(companyOptions)
            ? "initial"
            : "var(--brand) !important",
        }}
      >
        {isEmpty(selectedCompany) ? "Company" : selectedCompany}
      </Button>
      <Menu
        id="detention-rules-config-company-select-dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          data-testid="detention-rules-config-company-select-show-all"
          key={`show-all`}
          onClick={() => {
            setSelectedCompany("");
            handleClose();
          }}
        >
          Show All
        </MenuItem>
        {companyOptions.map((company, index) => (
          <MenuItem
            data-testid={`detention-rules-config-company-select-${company}`}
            key={`${index}-${company}`}
            onClick={() => {
              setSelectedCompany(company);
              handleClose();
            }}
          >
            {company}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CompanySelect;
