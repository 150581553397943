import { FC } from "react";
import { Box, Tooltip } from "@mui/material";
import isNaN from "lodash/isNaN";
import isUndefined from "lodash/isUndefined";
import startCase from "lodash/startCase";
import { ColorsPalette } from "../../../design-system/colors-palette";
import { SensorStatusUppercase } from "../../../graphql/operations";
import { BatteryState } from "../../helpers/battery";
import {
  SvgAlertBattery,
  SvgBattery,
  SvgChargingBattery,
  SvgCriticalBattery,
  SvgFullBattery,
  SvgSolarBattery,
  SvgWarningBattery,
} from "../Svg";

interface BatteryIconProps {
  chargeLevel?: number;
  charging?: boolean;
  isSolar?: boolean;
  svgProps?: Record<string, unknown>;
  status?: string;
}

const solarColors = new Map<BatteryState, ColorsPalette>([
  [BatteryState.Healthy, ColorsPalette.Success],
  [BatteryState.Warning, ColorsPalette.Caution],
  [BatteryState.Alert, ColorsPalette.Alert],
  [BatteryState.Critical, ColorsPalette.Error],
]);

const getSolarIcon = (
  batteryState: BatteryState,
  props?: Record<string, unknown>
) => {
  return <SvgSolarBattery color={solarColors.get(batteryState)} {...props} />;
};

export const getBatteryIcon = (
  batteryState: BatteryState | SensorStatusUppercase,
  props?: Record<string, unknown>
) => {
  switch (batteryState) {
    case BatteryState.Healthy:
    case SensorStatusUppercase.Healthy:
      return <SvgFullBattery {...props} />;
    case BatteryState.Warning:
    case SensorStatusUppercase.Warning:
      return <SvgWarningBattery {...props} />;
    case BatteryState.Alert:
    case SensorStatusUppercase.Alert:
      return <SvgAlertBattery {...props} />;
    case BatteryState.Critical:
    case SensorStatusUppercase.Critical:
      return <SvgCriticalBattery {...props} />;
    case BatteryState.Charging: // TODO: Should this be removed?
      return <SvgChargingBattery {...props} />;
    case BatteryState.Unknown:
    case SensorStatusUppercase.Unknown:
    default:
      return <SvgBattery {...props} />;
  }
};

export const BatteryIcon: FC<BatteryIconProps> = ({
  chargeLevel,
  charging,
  isSolar = false,
  svgProps = {},
  status,
}) => {
  if (isUndefined(chargeLevel) || isNaN(chargeLevel))
    status = BatteryState.Unknown;

  const statusAsEnum = status as BatteryState;
  return (
    <Tooltip title={startCase(statusAsEnum)} placement="right">
      {/* Box is needed because the MUI tooltip currently does not natively support non-MUI element children */}
      <Box>
        {isSolar
          ? getSolarIcon(statusAsEnum, svgProps)
          : getBatteryIcon(statusAsEnum, svgProps)}
      </Box>
    </Tooltip>
  );
};
