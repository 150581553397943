export enum LiteSentryGammaScenario {
  // Brake (red line) - Scenario ID 0 - Tractor power with Blue power present
  BluePowerPresent = "scenario_0",
  // Brake (red line) - Scenario ID 5 - Tractor power without Blue power present
  BluePowerNotPresent = "scenario_5",
  // Brake (red line) - Scenario ID 6 - No power, Precheck
  BrakeCircuitPrecheck = "scenario_6",
  // Right (green line) - Scenario ID 1 - Tractor power
  RightTurnSignalTractorPower = "scenario_1",
  // Right (green line) - Scenario ID 7 - No power, Precheck
  RightTurnSignalPrecheck = "scenario_7",
  // Left (yellow line) - Scenario ID 3 - Tractor power
  LeftTurnSignalTractorPower = "scenario_3",
  // Left (yellow line) - Scenario ID 9 - No power, Precheck
  LeftTurnSignalPrecheck = "scenario_9",
  // Marker (brown line) - Scenario ID 4 - Tractor power
  MarkerTractorPower = "scenario_4",
  // Marker (brown line) - Scenario ID 10/A - No power, Precheck
  MarkerPrecheck = "scenario_10",
  // License (black line) - Scenario ID 2 - Tractor power
  LicenseTractorPower = "scenario_2",
  // License (black line) - Scenario ID 8 - No power, Precheck
  LicensePrecheck = "scenario_8",
}
