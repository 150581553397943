import { KeyboardEventHandler, useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import { FormHelperText, Grid } from "@mui/material";
import { Asset } from "../../../../../../graphql/operations";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../hooks/useNomenclatures";
import { Breakpoints } from "../AssetForm";
import {
  ASSET_DETAILS_FORM_FIELDS,
  ASSET_VIN_HELPER_TEXT,
  AssetDetailsFields,
  AssetFormSectionTitles,
  booleanOptions,
} from "../assetFormUtils";
import { AccordionWrapper } from "../components/AccordionWrapper";

interface AssetDetailsProps {
  showDetailsHeader?: boolean;
  form: UseFormReturn<Partial<Asset>>;
  breakpoints: Breakpoints;
  asset?: Partial<Asset>;
  disableAllFields?: boolean;
  numberChangeHandler: KeyboardEventHandler<HTMLDivElement>;
}

export const AssetDetails = ({
  showDetailsHeader = false,
  form,
  breakpoints,
  disableAllFields = false,
  numberChangeHandler,
}: AssetDetailsProps) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const handleAccordionChange = useCallback(() => {
    setIsAccordionExpanded(!isAccordionExpanded);
  }, [isAccordionExpanded, setIsAccordionExpanded]);

  const doorTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetDoorType);
  const tiresOptions = useNomenclatures(NOMENCLATURE_NAMES.assetWheels);
  const axlesOptions = useNomenclatures(NOMENCLATURE_NAMES.assetNumberOfAxles);

  return (
    <AccordionWrapper
      accordionSummaryTitle={AssetFormSectionTitles.AssetDetails}
      showDetailsHeader={showDetailsHeader}
      isDashboardSettings={showDetailsHeader}
    >
      <Grid
        container
        rowGap={showDetailsHeader ? 3 : 0}
        className={`!text-primary ${
          showDetailsHeader ? "formSection" : "secondDrawerSection"
        }`}
        spacing={6}
      >
        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.ExternalLength].name
            }
            label={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.ExternalLength].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Manufacturer].name
            }
            label={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Manufacturer].label
            }
          />
          <FormHelperText>{ASSET_VIN_HELPER_TEXT}</FormHelperText>
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Model].name}
            label={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Model].label}
          />
          <FormHelperText>{ASSET_VIN_HELPER_TEXT}</FormHelperText>
        </Grid>

        <Grid item {...breakpoints} data-testid={"asset-details-year"}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Year].name}
            label={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Year].label}
          />
          <FormHelperText>{ASSET_VIN_HELPER_TEXT}</FormHelperText>
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.AssetInserviceDate]
                .name
            }
            label={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.AssetInserviceDate]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.EmptyWeight].name
            }
            label={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.EmptyWeight].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.CubeCapacity].name
            }
            label={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.CubeCapacity].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Length].name}
            label={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Length].label}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.RailCompliant].name
            }
            label={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.RailCompliant].label
            }
            options={booleanOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.NumberOfTires].name
            }
            label={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.NumberOfTires].label
            }
            options={tiresOptions}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.NumberOfAxles].name
            }
            label={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.NumberOfAxles].label
            }
            options={axlesOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            data-testid="form-control-input-door_type"
            control={form.control}
            name={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.DoorType].name}
            label={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.DoorType].label}
            options={doorTypeOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.AirRide].name}
            label={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.AirRide].label}
            options={booleanOptions}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Odometer].name}
            label={ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.Odometer].label}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.EngineHours].name
            }
            label={
              ASSET_DETAILS_FORM_FIELDS[AssetDetailsFields.EngineHours].label
            }
          />
        </Grid>
      </Grid>
    </AccordionWrapper>
  );
};
