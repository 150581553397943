import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { omitBy, isNil } from "lodash";
import { FormFieldDropdownOption } from "../../../../../../../types";
import { mainProfileDrawerFormSchema } from "../components/ProfilesDrawerMainForm/mainFormValidationSchema";

export interface ProfileDrawerMainFormValues {
  name: string;
  orgName: string;
  default: boolean;
}

export const userFormInitialValues: ProfileDrawerMainFormValues = {
  name: "",
  orgName: "",
  default: false,
};

export const useProfileDrawerMainForm = (
  incomingInitialValues: Partial<ProfileDrawerMainFormValues> = {}
) => {
  const form = useForm<ProfileDrawerMainFormValues>({
    resolver: yupResolver(mainProfileDrawerFormSchema),
    defaultValues: omitBy(
      { ...userFormInitialValues, ...incomingInitialValues },
      isNil
    ),
  });

  const getValues = useCallback(
    () => mainProfileDrawerFormSchema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues };
};
