import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ToggleViewButton, {
  TiresTabView,
} from "../../../Shared/ToggleViewButton/ToggleViewButton";
import { LightCircuitMergedDataResult } from "../utils/utils";
import LightsCardView from "./LightsCardView";
import LightsTable from "./LightsTable";

type LightOutDetectionInformationProps = {
  data: LightCircuitMergedDataResult;
};

const LightOutDetectionInformation = ({
  data,
}: LightOutDetectionInformationProps) => {
  const [activeView, setActiveView] = useState<TiresTabView>(TiresTabView.Card);

  return (
    <Grid item lg={12} className="w-full flex">
      <Box
        className="w-full flex flex-col bg-dashboard_subheader__bg rounded-lg"
        data-testid="LightOutDetectionInformationComponent"
      >
        <Box className="pb-4">
          <Grid item xs={12} className="flex justify-between relative">
            <Typography
              className="text-brand"
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "26px",
              }}
            >
              Light Circuit Sensors Details
            </Typography>
            <ToggleViewButton
              activeView={activeView}
              onClick={setActiveView}
              data-testid="toggle-view-button"
              whiteTheme={true}
            />
          </Grid>
        </Box>

        {activeView === TiresTabView.Card && <LightsCardView data={data} />}
        {activeView === TiresTabView.Table && <LightsTable data={data} />}
      </Box>
    </Grid>
  );
};

export default LightOutDetectionInformation;
