import { USER_EVENTS_MAPPER } from "../../../../../constants/users";
import {
  Maybe,
  UserEvent,
  UserEventType,
} from "../../../../../graphql/operations";

export enum UpdateUserEventProperties {
  Email = "email",
  PhoneNumber = "phoneNumber",
  FirstName = "firstName",
  LastName = "lastName",
  Groups = "groups",
  Status = "status",
  CustomerOrgsId = "customer_orgs_id",
  TimeZones = "time_zones",
  Mfa = "mfa",
}

const updatePropertiesMap: Record<UpdateUserEventProperties, string> = {
  [UpdateUserEventProperties.Email]: "Email",
  [UpdateUserEventProperties.PhoneNumber]: "Phone Number",
  [UpdateUserEventProperties.FirstName]: "First Name",
  [UpdateUserEventProperties.LastName]: "Last Name",
  [UpdateUserEventProperties.Groups]: "Role",
  [UpdateUserEventProperties.Status]: "Status",
  [UpdateUserEventProperties.CustomerOrgsId]: "Organization Name",
  [UpdateUserEventProperties.TimeZones]: "Time Zone",
  [UpdateUserEventProperties.Mfa]: "MFA method",
};

export const mapEventName = (eventType: UserEvent["event"]["type"]): string => {
  const output = USER_EVENTS_MAPPER[eventType] ?? "";

  return output;
};

export const generateEventDescriptionMessage = (
  message: string,
  batchName?: Maybe<string>
): string => {
  return batchName ? `${message} via batch ${batchName}` : message;
};

export const generateUpdatedPropsLabel = (changes: string[]): string => {
  return changes
    .map(
      (changedProp) =>
        updatePropertiesMap[changedProp as UpdateUserEventProperties]
    )
    .join(", ");
};

export const mapEventDescription = (row: Pick<UserEvent, "event">): string => {
  const userEvent = row?.event?.type;
  let output = "";
  switch (userEvent) {
    case UserEventType.Created:
      output = `${generateEventDescriptionMessage(
        `User with email ${row.event.data?.email} was created`,
        row.event.batchName
      )}.`;
      break;
    case UserEventType.Updated:
      output = `${generateEventDescriptionMessage(
        `User updated. The following properties were changed: ${generateUpdatedPropsLabel(
          row.event.data?.changes ?? []
        )}`,
        row.event.batchName
      )}.`;
      break;
    default:
      output = "";
  }

  return output;
};
