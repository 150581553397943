import { GridColDef } from "@mui/x-data-grid/models";
import get from "lodash/get";

const getRows = <DataType>(
  data: DataType | null,
  columns: GridColDef[],
  hierarchyFieldName?: string | null,
  hierarchyFieldSeparator?: string | null,
  idFieldName: string = ""
) => {
  const dataElement = data ? (data as any[])[0] : null;
  type Item = typeof dataElement;
  type Row = Item & {
    id: number;
    hierarchy?: string[];
    rowMetadata?: {
      [k: string]: any;
    };
    [k: string]: any;
  };
  const rows: Row[] = [];
  ((data as []) || []).forEach((item, index) => {
    const row: Row = { id: get(item, idFieldName) ?? index };
    if (hierarchyFieldName && hierarchyFieldSeparator) {
      if (item[hierarchyFieldName as keyof typeof item]) {
        const hierarchy = item[
          hierarchyFieldName as keyof typeof item
        ] as string;
        row["hierarchy"] = hierarchy.split(hierarchyFieldSeparator);
      }
    }
    (columns ?? []).forEach((column) => {
      row[column.field] = get(item, column.field);
    });

    // Add metadata if it exists. The `rowMetadata` property is used for conditional rendering of table cells.
    const rowMetadata = (item as any)["rowMetadata"];
    if (rowMetadata) {
      row.rowMetadata = rowMetadata;
    }
    rows.push(row);
  });
  return rows;
};

export { getRows };
