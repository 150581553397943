import { TemperatureUnit } from "../graphql/operations";
import { convertCelsiusToF } from "./convertCelsiusToF";
import { convertFahrenheitToC } from "./convertFahrenheitToC";

export const getTemperatureUnitLabel = (
  unit: TemperatureUnit,
  showAbbreviation?: boolean
): TemperatureUnit | string => {
  const unitResult = TemperatureUnit[unit] ?? TemperatureUnit.Celsius;

  return showAbbreviation ? "°" + unitResult.charAt(0) : unitResult; // TODO: Replace with DEFAULT_TEMPERATURE_UNIT;
};

export const getConvertedTemperatureValueWithUnit = (
  value: number,
  unit: TemperatureUnit
) => {
  let result = value; // default to celsius

  if (unit === TemperatureUnit.Fahrenheit) result = convertCelsiusToF(value);

  return `${Math.floor(result)} Degrees ${getTemperatureUnitLabel(unit)}`;
};

export const getConvertedTemperatureValue = (
  value: number,
  unit: TemperatureUnit
) => {
  let result = value; // default to celsius

  if (unit === TemperatureUnit.Fahrenheit) {
    result = convertCelsiusToF(value);
  }

  return Math.floor(result);
};

export const getConvertedTemperatureValues = (
  values: number[],
  unit: TemperatureUnit
) => values.map((value) => getConvertedTemperatureValue(value, unit));

export const prepareConvertedCelsiusTemperaturesPayload = (
  values: number[],
  unit: TemperatureUnit
): number[] => {
  return values.map((value) => {
    let result = value; // default value is celsius, no need to be converted

    if (unit === TemperatureUnit.Fahrenheit) {
      // We always need the payload in celsius
      result = convertFahrenheitToC(value);
    }

    return Math.floor(result);
  });
};

export const prepareConvertedCelsiusTemperaturePayload = (
  value: number,
  unit: TemperatureUnit
): number => {
  let result = value; // default value is celsius, no need to be converted

  if (unit === TemperatureUnit.Fahrenheit) {
    result = convertCelsiusToF(value);
  }

  return Math.floor(result);
};

export const convertTemperatureThresholdsRange = <
  T extends { [K in keyof T]: number }
>(
  thresholds: T,
  temperatureUnit: TemperatureUnit
): T => {
  return Object.keys(thresholds).reduce(
    (acc, key) => {
      const typedKey = key as keyof T;
      acc[typedKey] = getConvertedTemperatureValue(
        thresholds[typedKey],
        temperatureUnit
      ) as T[keyof T];

      return acc;
    },
    { ...thresholds }
  );
};
