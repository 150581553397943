import * as yup from "yup";

export const addAnyOfMethod = () => {
  // extend yup with anyOf object method
  yup.addMethod(
    yup.object,
    "anyOf",
    function (list: string[], message: string) {
      return this.test({
        name: "anyOf",
        message: message ? message : "At least one item should be selected",
        exclusive: true,
        params: { keys: list.join(", ") },
        test: (value) =>
          value == null || !list.every((f: string) => !value[f].length),
      });
    }
  );
};
