import { memo, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Tab, ThemeProvider, Typography } from "@mui/material";
import { useAuthContext } from "../../../context/AuthContext";
import { useFindDeviceByIdQuery } from "../../../graphql/operations";
import BackIcon from "../../../shared/components/BackIcon/BackIcon";
import Page from "../../../shared/components/Page";
import Tabs from "../../../shared/components/Tabs";
import { TabsVariant } from "../../../shared/components/Tabs/Tabs";
import { WithPermissions } from "../../../shared/components/WithPermissions";
import useBreakpoint from "../../../shared/hooks/useBreakpoint";
import { useSpinner } from "../../../shared/hooks/useSpinner";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { useAssetManagementTheme } from "../../AssetsView/TableView/hooks/useAssetManagementTheme";
import { DeviceActivityLog } from "./DeviceActivityLog/DeviceActivityLog";
import { DeviceSettings } from "./DeviceSettings/DeviceSettings";
import { DeviceSummaryTab } from "./DeviceSummaryTab/DeviceSummaryTab";

const tabs = [
  {
    label: "Summary",
    value: 0,
  },
  {
    label: "Activity Log",
    value: 1,
  },
  {
    label: "Settings",
    value: 2,
  },
];
const DeviceDashboard = () => {
  const { userRolePermissions } = useAuthContext();
  const assetManagementTheme = useAssetManagementTheme();
  const page = useRef();
  const shouldScroll = useBreakpoint("down", "xl");
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  const location = useLocation();
  const { pathname } = location;
  const deviceId = pathname
    .split("/")
    .filter((part) => part !== "")
    .pop();

  const { data: deviceData, isLoading: deviceLoading } = useFindDeviceByIdQuery(
    {
      id: deviceId as string,
    },
    {
      cacheTime: 0,
    }
  );

  const deviceToEdit = useMemo(() => {
    return deviceData?.findDeviceById;
  }, [deviceData]);

  const onBack = () => {
    navigate(NavigationRoutes.Devices);
  };

  const title = deviceToEdit?.imei;
  const subtitle = deviceToEdit?.org_name;

  useSpinner(deviceLoading);

  // should filter out settings tab based on current user permissions
  const filteredTabs = useMemo(() => {
    if (userRolePermissions.device.edit) {
      return tabs;
    }
    return tabs.filter((tab) => tab.value !== 2);
  }, [userRolePermissions.device.edit]);

  return (
    <ThemeProvider theme={assetManagementTheme}>
      <Page ref={page} title="" className={"bg-background"}>
        <Box
          data-testid="device-dashboard"
          className="flex h-14 items-center justify-between px-4"
        >
          <Button
            className="!text-sm !font-bold !text-brand !normal-case"
            size="small"
            startIcon={<BackIcon color={"var(--brand)"} />}
            onClick={onBack}
            data-testid="device-back-btn"
          >
            Back
          </Button>
          <Box className="flex items-center gap-4 text-header-text">
            <Typography
              data-testid="device-title"
              className="!text-lg !font-semibold"
            >
              {title ?? ""}
            </Typography>
            <Typography
              data-testid="device-subtitle"
              className="!text-base !font-medium"
            >
              {subtitle ?? ""}
            </Typography>
          </Box>
        </Box>
        <Tabs
          tabs={filteredTabs}
          centered={!shouldScroll}
          variant={shouldScroll ? TabsVariant.Scroll : TabsVariant.Standard}
          controlled={true}
          scrollButtons={shouldScroll}
          allowScrollButtonsMobile={shouldScroll}
          value={selectedTab}
          onClick={(value) => setSelectedTab(value)}
          showTabContent={false}
          tabClasses={{ "flex justify-center border-x-0": true }}
        >
          {(activeTab) => (
            <Tab
              label={tabs[activeTab].label}
              value={tabs[activeTab].value}
              key={tabs[activeTab].label}
            />
          )}
        </Tabs>
        {selectedTab === 0 && deviceToEdit && (
          <DeviceSummaryTab device={deviceToEdit} />
        )}
        {selectedTab === 1 && deviceToEdit && (
          <DeviceActivityLog device_id={deviceToEdit._id} />
        )}
        {selectedTab === 2 && deviceToEdit && (
          <WithPermissions accessScope="device.edit">
            <DeviceSettings device={deviceToEdit} />
          </WithPermissions>
        )}
      </Page>
    </ThemeProvider>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
DeviceDashboard.displayName = "DeviceDashboard";
export default memo(DeviceDashboard);
