import { UseFormReturn } from "react-hook-form";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { Asset } from "../../../../../../graphql/operations";
import { NOMENCLATURE_NAMES, useNomenclatures } from "../../../hooks";
import { Breakpoints } from "../AssetForm";
import {
  ASSET_ATIS_FORM_FIELDS,
  AssetATISFields,
  AssetFormSectionTitles,
  yesNoOptions,
} from "../assetFormUtils";
import { AccordionWrapper } from "../components/AccordionWrapper";

interface ATISDetailsProps {
  showDetailsHeader?: boolean;
  form: UseFormReturn<Partial<Asset>>;
  breakpoints: Breakpoints;
  asset?: Partial<Asset>;
  disableAllFields?: boolean;
}

export const ATISDetails = ({
  showDetailsHeader = false,
  form,
  breakpoints,
  disableAllFields = false,
}: ATISDetailsProps) => {
  const atisManufacturerNomenclatures = useNomenclatures(
    NOMENCLATURE_NAMES.atisManufacturer
  );

  return (
    <AccordionWrapper
      accordionSummaryTitle={AssetFormSectionTitles.ATIS}
      showDetailsHeader={showDetailsHeader}
      isDashboardSettings={showDetailsHeader}
    >
      <Grid
        container
        rowGap={showDetailsHeader ? 3 : 0}
        className={`!text-primary ${
          showDetailsHeader ? "formSection" : "secondDrawerSection"
        }`}
        spacing={6}
      >
        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_ATIS_FORM_FIELDS[AssetATISFields.ATIS].name}
            label={ASSET_ATIS_FORM_FIELDS[AssetATISFields.ATIS].label}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_ATIS_FORM_FIELDS[AssetATISFields.ATISManufacturer].name}
            label={
              ASSET_ATIS_FORM_FIELDS[AssetATISFields.ATISManufacturer].label
            }
            options={atisManufacturerNomenclatures}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_ATIS_FORM_FIELDS[AssetATISFields.ATISModel].name}
            label={ASSET_ATIS_FORM_FIELDS[AssetATISFields.ATISModel].label}
          />
        </Grid>
      </Grid>
    </AccordionWrapper>
  );
};
