import { FC, useCallback, memo, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import slug from "slug";
import { useAppContext } from "../../../../context/AppContext";
import {
  GetTableDataInput,
  GetAlertsTableDataResponse,
  SortOrder,
  useGetAlertsTableDataQuery,
  AlertsTableData,
  TableViewType,
  TableDomain,
  FileFormat,
} from "../../../../graphql/operations";
import Spinner from "../../../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
} from "../../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../../shared/components/Table/BackEndProcessingTable";
import { usePreferredTimezone } from "../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { useSettingsTheme } from "../../../AssetsView/MapView/hooks/useSettingsTheme";
import { columnVisibilityModel, getColumns } from "./columns";

export const AlertsTable: FC = memo(() => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const theme = useSettingsTheme();
  const timezone = usePreferredTimezone();
  const columns = useMemo(() => getColumns(timezone), [timezone]);
  const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
  const [isExporting, setIsExporting] = useState(false);

  const {
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [queryInput, setQueryInput] = useState<GetTableDataInput>({
    sorting: [{ field: "createdDate", order: SortOrder.Desc }],
    orgId: selectedOrganization.value,
    pagination: {
      skip: 0,
      limit: appConfig.table.defaultRowsPerPage,
    },
  });

  useEffect(() => {
    setQueryInput((prev) => ({
      ...prev,
      orgId: selectedOrganization.value,
    }));
  }, [selectedOrganization]);

  const {
    data: queryData,
    isSuccess,
    isLoading,
  } = useGetAlertsTableDataQuery<GetAlertsTableDataResponse>(
    { input: queryInput },
    {
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getAlertsTableData }) => getAlertsTableData,
    }
  );

  useTableDataExporter<AlertsTableData>({
    queryInput,
    apiRef,
    columns,
    domain: TableDomain.Alerts,
    fileFormat,
    isExporting,
    setExporting: setIsExporting,
    totalCount: queryData?.pagination.total,
  });

  const updateQueryInput = useCallback(
    (data: Partial<GetTableDataInput>) => {
      setQueryInput((prev) => ({ ...prev, ...data }));
    },
    [setQueryInput]
  );

  const navigateToAlertPage = useCallback(
    (row: AlertsTableData) => {
      navigate(`${NavigationRoutes.Alerts}/${slug(row.name)}/${row.alertId}`);
    },
    [navigate]
  );

  const handleExport = useCallback((format: ServerSideExportFormat) => {
    if (format === ServerSideExport.EMAIL) {
      setIsSendingEmail(true);
    } else {
      setFileFormat(format);
      setIsExporting(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        data-testid="alerts-table-container"
        className="h-full w-full px-4 pt-8 pb-4 md:px-6 md:pt-0 md:pb-3 lg:px-16"
      >
        <BackEndProcessingTable
          apiRef={apiRef}
          domain={TableDomain.Alerts}
          isSendingEmail={isSendingEmail}
          setSendingEmail={setIsSendingEmail}
          queryInput={queryInput}
          tableName={"alerts-list"}
          tableType={TableViewType.Alerts}
          columnVisibilityModel={columnVisibilityModel}
          columns={columns}
          onExport={handleExport}
          data={{
            rows: queryData?.data ?? [],
            pagination: queryData?.pagination,
          }}
          updateQueryInput={updateQueryInput}
          sorting={queryInput.sorting ?? undefined}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onRowClick={navigateToAlertPage}
          totalCount={queryData?.pagination.total}
        />
      </Box>
      <Spinner counter={Number(isSendingEmail)} />
    </ThemeProvider>
  );
});
