import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../hooks/theme/useCurrentTheme";

export const useHeaderButtonMenuTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--app_header__bg)",
              width: "302px",
              height: "442px",
              padding: "8px",
              backgroundImage: "unset",
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--app_header__bg)",
              paddingTop: "0px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              fontSize: "1rem",
              "& .MuiListItem-root": {},
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "unset",
              },
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              height: "unset",
              padding: 0,
            },
          },
        },
      },
    })
  );
};
