import { memo, useState, FC, DragEvent } from "react";
import { Control, FieldValues, UseFormReturn, useWatch } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { BatchTitles } from "../../../../constants/batches";
import {
  BatchFieldTypes,
  BatchFormFieldsTypes,
} from "../../../../views/BatchesView/BatchManagementUtils";
import { useFormTheme } from "../../../hooks/theme/useFormTheme";
import FileUploadDialog from "./FileUploadDialog";

type FileUploadFormProps = {
  title: BatchTitles;
  file?: File;
  missingFileError: boolean;
  control: Control;
  allowedFormats: string[];
  dialogFields: BatchFormFieldsTypes;
  onDeleteFile: () => void;
  onFileUpload: (file: File) => void;
  form: UseFormReturn<FieldValues>;
};

const breakpoints = { xs: 12 };

const FileUploadForm: FC<FileUploadFormProps> = ({
  title,
  file,
  missingFileError,
  allowedFormats,
  dialogFields,
  control,
  onFileUpload,
  onDeleteFile,
  form,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const formTheme = useFormTheme();

  const watchOrgs = useWatch({
    name: "org_select",
    control: form.control,
  });

  const handleDragEnter = function (event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  };

  return (
    <ThemeProvider theme={formTheme}>
      <form
        onSubmit={(event) => event.preventDefault()}
        onDragEnter={handleDragEnter}
      >
        <Grid
          container
          className="bg-background topPaddingDrawerSection -mt-16"
        >
          {Object.values(dialogFields)
            .map((item) => {
              if (item.visible.includes(title)) {
                const { name, label, required, type } = item;
                switch (type) {
                  case BatchFieldTypes.Text:
                    return (
                      <Grid item key={name} {...breakpoints}>
                        <TextFieldElement
                          required={required}
                          fullWidth
                          control={control}
                          name={name}
                          label={label}
                          data-testid={name}
                        />
                      </Grid>
                    );
                  case BatchFieldTypes.SingleSelect:
                    return (
                      <Grid item key={name} {...breakpoints}>
                        <AutocompleteElement
                          autocompleteProps={{
                            disabled:
                              name === "product_select" && !watchOrgs?.id,
                          }}
                          loading={!item.values.length}
                          required={required}
                          label={label}
                          control={control}
                          name={name}
                          options={item.values}
                          textFieldProps={{
                            inputProps: { "data-testid": name },
                          }}
                        />
                      </Grid>
                    );
                  case BatchFieldTypes.File:
                    return (
                      <Box key={name} className="container">
                        <FileUploadDialog
                          label={label}
                          file={file}
                          missingFileError={missingFileError}
                          allowedFormats={allowedFormats}
                          dragActive={dragActive}
                          setDragActive={setDragActive}
                          onDeleteFile={onDeleteFile}
                          onFileUpload={onFileUpload}
                        />
                      </Box>
                    );
                  default:
                }
              }
              return null;
            })
            .filter(Boolean)}
        </Grid>
      </form>
    </ThemeProvider>
  );
};

export default memo(FileUploadForm);
