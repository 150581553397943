import { Box } from "@mui/material";
import RegulatorSensorIcon from "../../../../../../../../assets/svgs/sensor-regulator.svg";
import { SensorStatusUppercase } from "../../../../../../../../graphql/operations";
import { CustomTooltip } from "../../../../../../../../shared/components/CustomTooltip/CustomTooltip";
import { useGetSensorStateWidget } from "../../../../AssetShortTooltip/hooks/useGetSensorStateWidget";

export interface RegulatorSensorStatusProps {
  status: SensorStatusUppercase;
}

export const RegulatorSensorStatus: React.FC<RegulatorSensorStatusProps> = ({
  status,
}) => {
  const { getTireSensorChip } = useGetSensorStateWidget();
  const statusDetails = getTireSensorChip(status, {
    iconWidth: "24px",
    iconHeight: "24px",
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
      data-testid="card-regulator-status--sensor-status"
    >
      <Box
        sx={{
          maxWidth: "120px",
          zIndex: 1,
        }}
      >
        <img src={RegulatorSensorIcon} alt={statusDetails.label} />
      </Box>
      <CustomTooltip arrow title={statusDetails.label}>
        <Box
          sx={{
            backgroundColor: "transparent",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -80%)",
            zIndex: 2,
          }}
          data-testid="card-regulator-status--sensor-status--icon"
        >
          {statusDetails.icon}
        </Box>
      </CustomTooltip>
    </Box>
  );
};
