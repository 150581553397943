const RegulatorIcon = () => {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5063 6.11719C26.0523 6.11719 26.6108 6.142 27.1568 6.19163C26.0647 6.09237 24.9602 6.09237 23.8682 6.19163C24.4018 6.142 24.9478 6.11719 25.5063 6.11719Z"
        fill="#231F20"
      />
      <path
        d="M28.3731 6.32777C28.1 6.29055 27.8394 6.25333 27.5664 6.22852C27.827 6.25333 28.1 6.29055 28.3731 6.32777Z"
        fill="#231F20"
      />
      <path
        d="M40.9439 12.023C39.405 10.2239 37.5808 8.7103 35.5579 7.54404C33.684 6.46464 31.6364 5.69541 29.5018 5.27357V3.30087C29.5018 2.44479 28.8069 1.75 27.9506 1.75H23.0486C22.1923 1.75 21.4974 2.44479 21.4974 3.30087V5.27357C17.0546 6.16687 13.0089 8.54901 10.0553 12.023C6.95282 15.6706 5.24023 20.3232 5.24023 25.1247C5.24023 29.9262 6.95282 34.5788 10.0553 38.2264C13.0089 41.7004 17.0546 44.0825 21.4974 44.9758V46.9485C21.4974 47.8046 22.1923 48.4994 23.0486 48.4994H27.9506C28.8069 48.4994 29.5018 47.8046 29.5018 46.9485V44.9758C33.9446 44.0825 37.9903 41.7004 40.9439 38.2264C44.0464 34.5788 45.759 29.9262 45.759 25.1247C45.7466 20.3232 44.0464 15.6706 40.9439 12.023ZM28.7696 43.8468C26.6227 44.219 24.3765 44.219 22.2296 43.8468C13.1082 42.2587 6.49365 34.3927 6.49365 25.1247C6.49365 15.8567 13.1082 7.97829 22.2296 6.40261C22.3661 6.37779 22.5026 6.35298 22.6267 6.34057C22.8997 6.30335 23.1727 6.26613 23.4457 6.24131C23.5823 6.22891 23.7188 6.2165 23.8553 6.20409C24.4013 6.15447 24.9474 6.12965 25.5058 6.12965C26.0519 6.12965 26.6103 6.15447 27.1563 6.20409C27.2929 6.2165 27.4294 6.22891 27.5659 6.24131C27.8389 6.26613 28.1119 6.30335 28.3725 6.34057C28.509 6.36538 28.6455 6.37779 28.7821 6.40261C37.9034 7.99069 44.518 15.8567 44.518 25.1247C44.5056 34.3927 37.891 42.2711 28.7696 43.8468Z"
        fill="#231F20"
      />
      <path
        d="M36.3654 35.6087L35.4719 34.7527C38.0408 32.0852 39.4183 28.5864 39.3562 24.8891C39.2942 21.1919 37.7926 17.7303 35.1244 15.1621C34.901 14.9512 34.7025 14.7651 34.5039 14.5914L35.3106 13.6484C35.5339 13.8345 35.7573 14.0455 35.9931 14.2688C38.8971 17.0728 40.5352 20.8321 40.6096 24.8643C40.6717 28.8966 39.1701 32.7055 36.3654 35.6087Z"
        fill="#3E88C8"
      />
      <path
        d="M15.0069 35.9934C12.1029 33.1894 10.4648 29.4301 10.3903 25.3979C10.3159 21.3656 11.8175 17.5443 14.6222 14.6411C16.7815 12.4078 19.5365 10.9066 22.577 10.311C25.5554 9.7279 28.5958 10.0505 31.3757 11.2167L30.8917 12.3582C25.6423 10.1373 19.4621 11.4028 15.5157 15.4971C12.9468 18.1646 11.5693 21.6634 11.6313 25.3607C11.6934 29.0579 13.195 32.5195 15.8632 35.0877L15.0069 35.9934Z"
        fill="#3E88C8"
      />
      <path
        d="M35.0865 14.466L27.3674 26.6497C26.5856 27.878 24.9971 28.2129 23.7809 27.4561C22.5772 26.6993 22.2173 25.0864 22.9743 23.8705L30.6933 11.6869C31.4503 10.4834 33.0636 10.1236 34.2798 10.8804C34.8879 11.2651 35.2726 11.8606 35.4215 12.5057C35.5705 13.1633 35.4712 13.8705 35.0865 14.466Z"
        fill="#3E88C8"
      />
    </svg>
  );
};

export default RegulatorIcon;
