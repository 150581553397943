import { FC } from "react";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NearMeIcon from "@mui/icons-material/NearMe";
import { Box, Grid, Typography } from "@mui/material";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";

export interface BreadcrumbsFilterCardProps {
  circleBackgroundColor: string;
  iconColor: string;
  filterName: string;
  filterValue: number;
  filterType: string;
  onClick?: any;
  isSelected: boolean;
}

const BreadcrumbsFilterCard: FC<BreadcrumbsFilterCardProps> = ({
  circleBackgroundColor,
  iconColor,
  filterName,
  filterValue,
  filterType,
  onClick,
  isSelected,
}) => {
  const isMobile = useBreakpoint("down", "xl");

  const getFilterIcon = (filterType: string) => {
    switch (filterType) {
      case "Location Update":
        return <NearMeIcon style={{ fill: iconColor }} fontSize="small" />;
      case "Health Event":
        return <HeartBrokenIcon style={{ fill: iconColor }} fontSize="small" />;
      case "Cargo Update":
        return (
          <LocalShippingIcon style={{ fill: iconColor }} fontSize="small" />
        );
      case "Door Event":
        return <DoorSlidingIcon style={{ fill: iconColor }} fontSize="small" />;
      case "Location Entry":
        return <LocationOnIcon style={{ fill: iconColor }} fontSize="small" />;
      default:
        return <LocationOnIcon style={{ fill: iconColor }} fontSize="small" />;
    }
  };
  return (
    <Grid
      item
      sx={{
        borderColor: isSelected
          ? "var(--breadcrumbs-filter-selected)"
          : "var(--breadcrumbs-filters-border)",
      }}
      data-testid={`${filterName}`}
      className="rounded-2xl p-1 m-1 !text-center items-center border-2 rounded-xl border-slate-200 cursor-pointer"
      onClick={onClick}
    >
      <Box
        className={isMobile ? "ml-4 pt-1 pb-1" : "ml-2"}
        sx={{
          gap: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          className="mr-0.5"
          sx={{
            background: circleBackgroundColor,
            borderColor: "var(--gray)",
            borderWidth: "1px",
            borderRadius: "50%",
            width: "26px",
            height: "26px",
          }}
        >
          {getFilterIcon(filterType)}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "row" : "column",
            flexGrow: isMobile ? "1" : "0",
            justifyContent: "center",
          }}
        >
          <Box className="flex mr-2">
            <Typography
              data-testid={`${filterName}-value`}
              className="!text-base !text-xs !text-typography-secondary pr-1"
            >
              {filterValue}
            </Typography>
            <Typography className="font-semibold !text-xs !text-typography-secondary pr-1">
              {filterName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
export default BreadcrumbsFilterCard;
