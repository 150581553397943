import {
  GeofenceAlertHistoryTableData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import {
  TableColumnProps,
  TableGridColDef,
  getTableColumn,
} from "../../../../../shared/components/Table";
import {
  columnTimezoneDateTimeFormatParams,
  imeiValueFormatter,
} from "../../../../../utils";
import { AlertNotificationModal } from "../../../TableView/components";

export const columnVisibilityModel = {
  trigger: true,
  alertNotification: true,
  assetName: true,
  alertType: true,
  imei: true,
  orgName: true,
  remindedAt: true,
};

export const trimmedSorting = (a: string, b: string) =>
  a.trim().localeCompare(b.trim());

export const getColumns = (
  timezone: string
): TableGridColDef<GeofenceAlertHistoryTableData>[] => {
  const columns: TableColumnProps<GeofenceAlertHistoryTableData>[] = [
    {
      field: "trigger",
      headerName: "Trigger",
      options: {
        sortable: false,
        filterable: false,
      },
    },
    {
      field: "alertNotification",
      headerName: "Alert Notification",
      options: {
        renderCell: (params) => {
          return (
            <AlertNotificationModal
              alertNotificationId={String(params.row.id)}
            />
          );
        },
        filterable: false,
        sortable: false,
      },
    },
    {
      field: "assetName",
      headerName: "Asset ID",
      options: {
        valueFormatter: imeiValueFormatter,
        sortComparator: trimmedSorting,
      },
    },
    {
      field: "orgName",
      headerName: "Organization Name",
      options: {
        sortable: false,
        filterable: false,
      },
    },
    {
      field: "alertType",
      headerName: "Alert Type",
      options: {
        valueFormatter: imeiValueFormatter,
        sortComparator: trimmedSorting,
      },
    },
    {
      field: "remindedAt",
      headerName: "Date / Time Alert Sent",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: {
        ...columnTimezoneDateTimeFormatParams(timezone),
        filterable: false,
      },
    },
    {
      field: "imei",
      headerName: "Device ID",
      options: {
        groupable: false,
        valueFormatter: imeiValueFormatter,
      },
    },
  ];

  return columns.map((column) => getTableColumn(column));
};
