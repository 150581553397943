import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useFrequencySelectTheme } from "../../ReportView/hooks/useFrequencySelectTheme";

export const useRemindersTheme = () => {
  const frequencyTheme = useFrequencySelectTheme();

  return createTheme(
    deepmerge(frequencyTheme, {
      components: {
        MuiCheckbox: {
          styleOverrides: {
            root: {
              "&.Mui-checked": {
                color: "var(--brand) !important",
              },
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            switchBase: {
              "&.Mui-checked": {
                color: "var(--white) !important",
              },
            },
            track: {
              backgroundColor: "var(--upload_file__icon) !important",
            },
          },
        },
        MuiInputLabel: {
          defaultProps: {
            shrink: true,
          },
          styleOverrides: {
            root: {
              fontWeight: "500 !important",
              transform: "translate(0, -6px) scale(1)",
              fontSize: "10px !important",
              lineHeight: "10px !important",
            },
            asterisk: {
              fontWeight: "400 !important",
              fontSize: "10px !important",
              lineHeight: "10px !important",
            },
          },
        },
        MuiFormControl: {
          defaultProps: {
            variant: "outlined",
          },
          styleOverrides: {
            root: {
              fontWeight: 700,
              fontSize: 14,
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            outlined: {
              padding: "5px !important",
              backgroundColor: "var(--card__placeholder) !important",
            },
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          },
        },
      },
    })
  );
};
