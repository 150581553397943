import { memo, useRef, FC, DragEvent, ChangeEvent, RefObject } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography, Box, InputLabel, Grid } from "@mui/material";
import { BatchFieldTypes } from "../../../../views/BatchesView/BatchManagementUtils";
import Text from "../../Text";

type FileUploadDialogProps = {
  label: string;
  file?: File;
  missingFileError: boolean;
  allowedFormats: string[];
  dragActive: boolean;
  setDragActive: (val: boolean) => void;
  onDeleteFile: () => void;
  onFileUpload: (file: File) => void;
};

const breakpoints = { xs: 12 };

const FileUploadDialog: FC<FileUploadDialogProps> = ({
  label,
  file,
  missingFileError,
  allowedFormats,
  dragActive,
  setDragActive,
  onFileUpload,
  onDeleteFile,
}) => {
  const inputRef: RefObject<HTMLInputElement> = useRef(null);

  const handleDragEnter = function (event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      onFileUpload(event.dataTransfer.files[0]);
    }
  };

  const handleFileChange = function (event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      onFileUpload(event.target.files[0]);
    }
  };

  const onBrowseButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Box className="container">
      {!file && (
        <Grid item {...breakpoints}>
          <Text
            fontSize={14}
            color={
              missingFileError
                ? "var(--error)"
                : "var(--Grayscale-Dark-Charcoal)"
            }
          >
            {label} <span className="text-error !mb-3.5 ml-[2px]">*</span>
          </Text>
          <input
            data-testid="file-upload-input"
            ref={inputRef}
            className="hidden"
            id="file-upload-input"
            type={BatchFieldTypes.File}
            accept={
              allowedFormats.length > 0
                ? allowedFormats.map((format) => `.${format}`).join(",")
                : undefined
            }
            onChange={handleFileChange}
          />
          {missingFileError && (
            <Text
              fontSize={12}
              classes="!text-error !mb-3.5 !font-normal !-ml-0.45"
            >
              <span className="pr-2">
                <ErrorOutlineIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "var(--error)",
                  }}
                />
              </span>
              File is required!
            </Text>
          )}
          <InputLabel
            className={`!flex h-32 items-center justify-center rounded-lg border border-dashed border-upload_file__form_border bg-upload_file__form_bg ${
              dragActive && "bg-white"
            }`}
            htmlFor="file-upload-input"
          >
            <Box className="flex flex-wrap justify-left text-sm">
              <Typography>Drag & drop your file here or</Typography>
              <button
                className="font-bold text-brand"
                onClick={onBrowseButtonClick}
              >
                &nbsp;Browse
              </button>
            </Box>
          </InputLabel>
          {dragActive && (
            <Box
              className="absolute inset-0 h-full w-full"
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragEnter}
              onDragOver={handleDragEnter}
              onDrop={handleDrop}
            />
          )}
        </Grid>
      )}
      {file && (
        <Grid item {...breakpoints}>
          <Text fontSize={14} color="var(--Grayscale-Dark-Charcoal)">
            {label} <span className="text-error !mb-3.5 ml-[2px]">*</span>
          </Text>
          <Box>
            <Box className="m-2 flex justify-between bg-upload_file__form_bg p-2 text-upload_file__icon">
              <Box className="flex">
                <Box className="mr-5">
                  <DescriptionIcon />
                </Box>
                <Typography className="text-sm text-typography">
                  {file.name}
                </Typography>
              </Box>
              <Box>
                <button onClick={onDeleteFile}>
                  <DeleteIcon />
                </button>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default memo(FileUploadDialog);
