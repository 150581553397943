import { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as DoorSensorDark } from "../../../../../../assets/svgs/doorSensorDark.svg";
import { ReactComponent as DoorSensorLight } from "../../../../../../assets/svgs/doorSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";

export interface DoorHeaderProps {
  title: string;
  noBorder?: boolean;
}

const DoorHeader: FC<DoorHeaderProps> = ({ title, noBorder }) => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const boxStyleSettings = {
    height: "100px",
    width: "100px",
  };
  const svgIconSettings = {
    width: "100%",
    height: "100%",
    display: "block",
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="streched"
      className="pb-4"
    >
      <Box className="!mb-1 justify w-full ">
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Box data-testid="door-icon" sx={boxStyleSettings}>
            {isLightTheme ? (
              <DoorSensorDark style={svgIconSettings} />
            ) : (
              <DoorSensorLight style={svgIconSettings} />
            )}
          </Box>
          <Box>
            <Typography
              variant="caption"
              className="!pl-2 !text-3xl inline-block !font-semibold"
            >
              {title}
            </Typography>
          </Box>
        </Grid>

        {!noBorder && (
          <Box
            className="mt-4"
            style={{
              borderTop: "1px solid var(--border-color)",
            }}
          ></Box>
        )}
      </Box>
    </Grid>
  );
};

export default DoorHeader;
