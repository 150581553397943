import { GridValueGetterParams } from "@mui/x-data-grid-premium";
import { get, round, capitalize } from "lodash";
import {
  AssetInstallEvent,
  AssetReportDataRow,
  AssetTransferEvent,
  TableColumnFormat as ReportColumnFormat,
  TableValueDataType,
} from "../../../../../graphql/operations";
import { BatteryIcon } from "../../../../../shared/components/BatteryIcon";
import { DoorTypeLabel } from "../../../../../shared/components/DoorTypeLabel/DoorTypeLabel";
import {
  columnTimezoneDateTimeFormatParams,
  imeiValueFormatter,
} from "../../../../../utils";
import { stripNAValue } from "../../../../AssetsView/TableView/assetUtils";
import { trimmedSorting } from "../../../../AssetsView/TableView/components/AssetManagementTable/columns";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  org_name: false,
  "sensors.voltage.data.battery": false,
  asset_id: false,
  name: false,
  imei: false,
  prd_cde: false,
  lst_evnt_t: false,
  associated: false,
  "tripStatus.tripState": false,
  "fullAddress.city": false,
  "fullAddress.state": false,
  "fullAddress.postcode": false,
  vin: false,
  "location.coordinates[1]": false,
  "location.coordinates[0]": false,
  category: false,
  tags: false,
  wheel_config: false,
  num_of_axles: false,
  length: false,
  door_type: false,
  last_gps_t: false,
  "sensors.tpmsBeta.receivedDate": false,
  "sensors.tpmsBeta.data.sensors[0].primary_roadside_pressure_psi": false,
  "sensors.tpmsBeta.data.sensors[0].status_primary_roadside_pressure": false,
  "sensors.tpmsBeta.data.sensors[0].primary_roadside_temperature_f": false,
  "sensors.tpmsBeta.data.sensors[0].status_primary_roadside_temperature": false,
  "sensors.tpmsBeta.data.sensors[0].primary_curbside_pressure_psi": false,
  "sensors.tpmsBeta.data.sensors[0].status_primary_curbside_pressure": false,
  "sensors.tpmsBeta.data.sensors[0].primary_curbside_temperature_f": false,
  "sensors.tpmsBeta.data.sensors[0].status_primary_curbside_temperature": false,
  "sensors.tpmsBeta.data.sensors[0].inner_roadside_pressure_psi": false,
  "sensors.tpmsBeta.data.sensors[0].status_inner_roadside_pressure": false,
  "sensors.tpmsBeta.data.sensors[0].inner_roadside_temperature_f": false,
  "sensors.tpmsBeta.data.sensors[0].status_inner_roadside_temperature": false,
  "sensors.tpmsBeta.data.sensors[0].inner_curbside_pressure_psi": false,
  "sensors.tpmsBeta.data.sensors[0].status_inner_curbside_pressure": false,
  "sensors.tpmsBeta.data.sensors[0].inner_curbside_temperature_f": false,
  "sensors.tpmsBeta.data.sensors[0].status_inner_curbside_temperature": false,
  "sensors.tpmsBeta.data.sensors[1].leftOuterTirePressure": false,
  "sensors.tpmsBeta.data.sensors[1].leftOuterTireTemperature": false,
  "sensors.tpmsBeta.data.sensors[1].leftOuterTireStatus": false,
  "sensors.tpmsBeta.data.sensors[1].leftInnerTirePressure": false,
  "sensors.tpmsBeta.data.sensors[1].leftInnerTireTemperature": false,
  "sensors.tpmsBeta.data.sensors[1].leftInnerTireStatus": false,
  "sensors.tpmsBeta.data.sensors[1].rightOuterTirePressure": false,
  "sensors.tpmsBeta.data.sensors[1].rightOuterTireTemperature": false,
  "sensors.tpmsBeta.data.sensors[1].rightOuterTireStatus": false,
  "sensors.tpmsBeta.data.sensors[1].rightInnerTirePressure": false,
  "sensors.tpmsBeta.data.sensors[1].rightInnerTireTemperature": false,
  "sensors.tpmsBeta.data.sensors[1].rightInnerTireStatus": false,
  "sensors.tpmsBeta.data.sensors[2].leftOuterTirePressure": false,
  "sensors.tpmsBeta.data.sensors[2].leftOuterTireTemperature": false,
  "sensors.tpmsBeta.data.sensors[2].leftOuterTireStatus": false,
  "sensors.tpmsBeta.data.sensors[2].leftInnerTirePressure": false,
  "sensors.tpmsBeta.data.sensors[2].leftInnerTireTemperature": false,
  "sensors.tpmsBeta.data.sensors[2].leftInnerTireStatus": false,
  "sensors.tpmsBeta.data.sensors[2].rightOuterTirePressure": false,
  "sensors.tpmsBeta.data.sensors[2].rightOuterTireTemperature": false,
  "sensors.tpmsBeta.data.sensors[2].rightOuterTireStatus": false,
  "sensors.tpmsBeta.data.sensors[2].rightInnerTirePressure": false,
  "sensors.tpmsBeta.data.sensors[2].rightInnerTireTemperature": false,
  "sensors.tpmsBeta.data.sensors[2].rightInnerTireStatus": false,
  "sensors.atisAlpha.condition": false,
  "sensors.liteSentryGamma.data.observations[6].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[0].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[5].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[9].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[3].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[7].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[1].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[8].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[2].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[10].deviation_value": false,
  "sensors.liteSentryGamma.data.observations[4].deviation_value": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].left_temperature": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].right_temperature": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].left_temperature": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].right_temperature": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].left_temperature": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].right_temperature": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].left_status": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].right_status": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].left_status": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].right_status": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].left_status": false,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].right_status": false,
  "sensors.temperature.data.internal": false,
  "sensors.psiWheelEnd.statusPsiWheelEnd": false,
  "sensors.temperature.statusTemperature": false,
  "sensors.voltage.data.main": false,
  "sensors.voltage.data.auxiliary": false,
  "sensors.voltage.data.solarAmperage": false,
  "sensors.voltage.data.statusBattery": false,
  "sensors.voltage.statusVoltage": false,
  "sensors.tpmsBeta.statusTpmsBeta": false,
  "sensors.psiAirSupply.statusPsiAirSupply": false,
  "sensors.psiAirSupply.data.tankPressure": false,
  "sensors.psiAirSupply.data.statusTankPressure": false,
  "sensors.psiAirSupply.data.supplyPressure": false,
  "sensors.psiAirSupply.data.supplyStatus": false,
  "sensors.airbag.data.leftAirBagStatus": false,
  "sensors.airbag.data.rightAirBagStatus": false,
  "sensors.airbag.statusAirbag": false,
  "sensors.bleDoorSensor.data.doorType": false,
  "sensors.bleDoorSensor.data.doorState": false,
  "sensors.absBeta.data.absFaultCodeCount": false,
  "tripStatus.odometer": false,
  "sensors.atisAlpha.statusAtisAlpha": false,
  "sensors.atisAlpha.data.lightActivatedNumber": false,
  "sensors.atisAlpha.data.lightActivatedSeconds": false,
  "sensors.liteSentryGamma.statusLiteSentryGamma": false,
  "sensors.liteSentryGamma.data.stopLightOut": false,
  "sensors.liteSentryGamma.data.rightTurnSignalLightOut": false,
  "sensors.liteSentryGamma.data.leftTurnSignalLightOut": false,
  "sensors.liteSentryGamma.data.markerLightOut": false,
  "sensors.liteSentryGamma.data.licenseLightOut": false,
  "tripStatus.cargoState": false,
  "sensors.cargoCamera.data.floorUsageStatus": false,
  "sensors.cargoCamera.data.floorUsagePercentage": false,
  "sensors.liftgateAdvertisement.statusLiftgate": false,
  "sensors.liftgateAdvertisement.data.liftgateVoltage": false,
  "sensors.psiAirSupply.regulatorHealthStatus": false,
};

export const getColumnValue = (
  params: GridValueGetterParams<
    any,
    AssetTransferEvent | AssetInstallEvent | AssetReportDataRow
  >,
  path: string | number
) => {
  return get(params.row, path);
};

const AXLE_NUMBERS = [1, 2, 3];
const TIRE_SIDES_KEY = ["roadside", "curbside"];
const TIRE_POSITIONS_KEY = ["primary", "inner"];
const TIRE_ATTRIBUTES_KEY = ["pressure_psi", "temperature_f"];
const TIRE_STATUS_ATTRIBUTES_KEY = ["pressure", "temperature"];
const TIRE_SIDES = ["left", "right"];
const TIRE_POSITIONS = ["outer", "inner"];
const TIRE_ATTRIBUTES = ["Pressure", "Temperature"];

const generateAxleColumns = () => {
  const columns: ReportGridColDef[] = [];

  const tpmsBetaSensorsPrefix = "sensors.tpmsBeta.data.sensors";
  AXLE_NUMBERS.forEach((axleNumber, index) => {
    TIRE_POSITIONS_KEY.forEach((tirePosition, tirePositionKey) => {
      TIRE_SIDES_KEY.forEach((tireSide, tireSideKey) => {
        TIRE_ATTRIBUTES_KEY.forEach((attribute, attributeKey) => {
          const field = `${tpmsBetaSensorsPrefix}[${index}].${tirePosition}_${tireSide}_${attribute}`;
          const headerName = `Axle ${axleNumber} ${capitalize(
            TIRE_SIDES[tireSideKey]
          )} ${capitalize(TIRE_POSITIONS[tirePositionKey])} Tire ${
            TIRE_ATTRIBUTES[attributeKey]
          }`;

          const statusField = `${tpmsBetaSensorsPrefix}[${index}].status_${tirePosition}_${tireSide}_${TIRE_STATUS_ATTRIBUTES_KEY[attributeKey]}`;
          const statusHeaderName = `Axle ${axleNumber} ${capitalize(
            TIRE_SIDES[tireSideKey]
          )} ${capitalize(TIRE_POSITIONS[tirePositionKey])} ${
            TIRE_ATTRIBUTES[attributeKey]
          } Status`;

          columns.push({
            field,
            headerName,
            flex: 1,
            minWidth: 120,
            sortable: false,
            format: ReportColumnFormat.String,
            type: "number",
            valueGetter: (params) => getColumnValue(params, field),
            filterable: false,
          });
          columns.push({
            field: statusField,
            headerName: statusHeaderName,
            flex: 1,
            sortable: false,
            minWidth: 120,
            format: ReportColumnFormat.String,
            valueGetter: (params) => getColumnValue(params, statusField),
            filterable: false,
          });
        });
      });
    });
  });
  return columns;
};

const axleColumns = generateAxleColumns();

export const getColumns = (timezone: string): ReportGridColDef[] => [
  {
    field: "sensors.tpmsBeta.receivedDate",
    headerName: `Axles Updated Date`,
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.IsoDateTime,
    valueGetter: (params) =>
      getColumnValue(params, "sensors.tpmsBeta.receivedDate"),
    ...columnTimezoneDateTimeFormatParams(timezone ?? ""),
  },
  {
    field: "org_name",
    headerName: "Organization Name",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    valueGetter: (params) => getColumnValue(params, "org_name"),
  },
  {
    field: "sensors.voltage.data.battery",
    headerName: "Battery Status",
    flex: 1,
    minWidth: 120,
    groupable: false,
    valueGetter: (params) =>
      getColumnValue(params, "sensors.voltage.data.battery"),
    renderCell: (params) => (
      <BatteryIcon
        chargeLevel={params.value ? round(params.value, 2) : undefined}
        svgProps={{ width: "24px", height: "24px" }}
        status={
          (params.row as any).sensors?.voltage?.status
            ? (params.row as any).sensors?.voltage?.status
            : ""
        }
      />
    ),
    disableExport: true,
    filterable: false,
    format: ReportColumnFormat.String,
  },
  {
    field: "asset_id",
    headerName: "Asset ID",
    flex: 1,
    minWidth: 120,
    groupable: false,
    valueFormatter: imeiValueFormatter,
    sortComparator: trimmedSorting,

    format: ReportColumnFormat.String,
    valueGetter: (params) => getColumnValue(params, "asset_id"),
  },
  {
    field: "name",
    headerName: "Asset Nickname",
    flex: 1,
    minWidth: 120,
    valueFormatter: imeiValueFormatter,
    sortComparator: trimmedSorting,
    format: ReportColumnFormat.String,
    valueGetter: (params) => getColumnValue(params, "name"),
  },
  {
    field: "imei",
    headerName: "Device ID",
    flex: 1,
    minWidth: 120,
    groupable: false,
    valueFormatter: imeiValueFormatter,
    format: ReportColumnFormat.ImeiString,
    valueGetter: (params) => getColumnValue(params, "imei"),
  },
  {
    field: "prd_cde",
    headerName: "Product Name",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => getColumnValue(params, "prd_cde"),
    format: ReportColumnFormat.String,
  },
  {
    field: "lst_evnt_t",
    headerName: "Last Reported Date",
    flex: 1,
    minWidth: 190,
    valueGetter: (params) => getColumnValue(params, "lst_evnt_t"),
    ...columnTimezoneDateTimeFormatParams(timezone ?? ""),
    format: ReportColumnFormat.IsoDateTime,
    filterable: false,
  },
  {
    field: "associated",
    headerName: "Associated",
    flex: 1,
    minWidth: 120,
    filterOperators: [
      { label: "Yes", value: "isNotEmpty", getApplyFilterFn: () => null },
      { label: "No", value: "isEmpty", getApplyFilterFn: () => null },
    ],
    format: ReportColumnFormat.String,
    filterable: false,
    sortable: false,
  },
  {
    field: "tripStatus.tripState",
    headerName: "Trip Status",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) =>
      stripNAValue(getColumnValue(params, "tripStatus.tripState")) ?? "",
    format: ReportColumnFormat.String,
  },
  {
    field: "fullAddress.city",
    headerName: "City",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    valueGetter: (params) => getColumnValue(params, "fullAddress.city"),
  },
  {
    field: "fullAddress.state",
    headerName: "State",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    valueGetter: (params) => getColumnValue(params, "fullAddress.state"),
  },
  {
    field: "fullAddress.postcode",
    headerName: "Zip",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    valueGetter: (params) => getColumnValue(params, "fullAddress.postcode"),
  },
  {
    field: "category",
    headerName: "Asset Type",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    valueGetter: (params) => getColumnValue(params, "category"),
  },
  {
    field: "tags",
    headerName: "Asset Tags",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => getColumnValue(params, "tags"),
    format: ReportColumnFormat.String,
  },
  {
    field: "location.coordinates[1]",
    headerName: "Latitude",
    flex: 1,
    minWidth: 120,
    groupable: false,
    valueGetter: (params) =>
      getColumnValue(params, "location.coordinates[1]")?.toString(),
    type: "string",
    filterable: false,
    sortable: false,
    format: ReportColumnFormat.String,
  },
  {
    field: "location.coordinates[0]",
    headerName: "Longitude",
    flex: 1,
    minWidth: 120,
    groupable: false,
    valueGetter: (params) =>
      getColumnValue(params, "location.coordinates[0]")?.toString(),
    type: "string",
    format: ReportColumnFormat.String,
    filterable: false,
    sortable: false,
  },
  {
    field: "vin",
    headerName: "VIN",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    valueGetter: (params) => getColumnValue(params, "vin"),
  },
  {
    field: "wheel_config",
    headerName: "# of Tires",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      const value =
        params.value === -1
          ? undefined
          : stripNAValue(getColumnValue(params, "wheel_config"));
      if (value) {
        return Number.parseInt(value, 10);
      }
      return undefined;
    },
    type: "number",
    format: ReportColumnFormat.String,
  },
  {
    field: "num_of_axles",
    headerName: "# of Axles",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      const value =
        params.value === -1
          ? undefined
          : stripNAValue(getColumnValue(params, "num_of_axles"));
      if (value) {
        return Number.parseInt(value, 10);
      }
      return undefined;
    },
    type: "number",
    format: ReportColumnFormat.String,
  },
  {
    field: "length",
    headerName: "Length",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => getColumnValue(params, "length"),
    renderCell: (params) =>
      !params.value || params.value === -1 ? "" : params.value + "'",
    type: "number",
    format: ReportColumnFormat.String,
  },
  {
    field: "door_type",
    headerName: "Door Type",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      return getColumnValue(params, "door_type");
    },
    renderCell: (params) => <DoorTypeLabel value={params.value} />,
    filterable: false,
    format: ReportColumnFormat.String,
  },
  {
    field: "last_gps_t",
    headerName: "GPS Time",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.IsoDateTime,
    valueGetter: (params) => getColumnValue(params, "last_gps_t"),
    filterable: false,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  {
    field: "tripStatus.cargoState",
    headerName: "Cargo State",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    valueGetter: (params) => getColumnValue(params, "tripStatus.cargoState"),
  },
  {
    field: "sensors.tpmsBeta.statusTpmsBeta",
    headerName: "Overall TPMS Health",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    valueGetter: (params) =>
      getColumnValue(params, "sensors.tpmsBeta.statusTpmsBeta"),
  },
  {
    field: "sensors.liteSentryGamma.data.stopLightOut",
    headerName: "Stop Light Out",
    flex: 1,
    minWidth: 120,
    sortable: false,
    format: ReportColumnFormat.String,
    filterable: false,
    valueGetter: (params) =>
      getColumnValue(params, "sensors.liteSentryGamma.data.stopLightOut"),
  },
  {
    field: "sensors.liteSentryGamma.data.rightTurnSignalLightOut",
    headerName: "Right Turn Signal Light Out",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    filterable: false,
    valueGetter: (params) =>
      getColumnValue(
        params,
        "sensors.liteSentryGamma.data.rightTurnSignalLightOut"
      ),
  },
  {
    field: "sensors.liteSentryGamma.data.leftTurnSignalLightOut",
    headerName: "Left Turn Signal Light Out",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    filterable: false,
    valueGetter: (params) =>
      getColumnValue(
        params,
        "sensors.liteSentryGamma.data.leftTurnSignalLightOut"
      ),
  },
  {
    field: "sensors.liteSentryGamma.data.markerLightOut",
    headerName: "Marker Light Out",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    filterable: false,
    valueGetter: (params) =>
      getColumnValue(params, "sensors.liteSentryGamma.data.markerLightOut"),
  },
  {
    field: "sensors.liteSentryGamma.data.licenseLightOut",
    headerName: "License Light Out",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    filterable: false,
    valueGetter: (params) =>
      getColumnValue(params, "sensors.liteSentryGamma.data.licenseLightOut"),
  },
  ...axleColumns,
];

const inventoryExclusiveFields = [
  {
    key: "sensors.atisAlpha.condition",
    label: "ATIS Condition",
  },
  {
    key: "sensors.liteSentryGamma.data.observations[6].deviation_value",
    label: "Brake Current Deviation - Precheck",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[0].deviation_value",
    label: "Brake Current Deviation - Tractor (Blue Powered)",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[5].deviation_value",
    label: "Brake Current Deviation - Tractor (Blue Unpowered)",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[9].deviation_value",
    label: "Left Turn Signal Current Deviation - Precheck",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[3].deviation_value",
    label: "Left Turn Signal Current Deviation - Tractor",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[7].deviation_value",
    label: "Right Turn Signal Current Deviation - Precheck",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[1].deviation_value",
    label: "Right Turn Signal Current Deviation - Tractor",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[8].deviation_value",
    label: "License Current Deviation - Precheck",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[2].deviation_value",
    label: "License Current Deviation - Tractor",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[10].deviation_value",
    label: "Marker Current Deviation - Precheck",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.liteSentryGamma.data.observations[4].deviation_value",
    label: "Marker Current Deviation - Tractor",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].left_temperature",
    label: "Axle One Left Wheel End Temperature",
    sortable: false,
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].right_temperature",
    label: "Axle One Right Wheel End Temperature",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].left_temperature",
    label: "Axle Two Left Wheel End Temperature",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].right_temperature",
    label: "Axle Two Right Wheel End Temperature",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].left_temperature",
    label: "Axle Three Left Wheel End Temperature",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].right_temperature",
    label: "Axle Three Right Wheel End Temperature",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].left_status",
    label: "Axle One Left Wheel End Status",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].right_status",
    label: "Axle One Right Wheel End Status",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].left_status",
    label: "Axle Two Left Wheel End Status",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].right_status",
    label: "Axle Two Right Wheel End Status",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].left_status",
    label: "Axle Three Left Wheel End Status",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].right_status",
    label: "Axle Three Right Wheel End Status",
    filterable: false,
  },
  {
    key: "sensors.psiWheelEnd.statusPsiWheelEnd",
    label: "Psi Wheel End Status",
  },
  {
    key: "sensors.temperature.data.internal",
    label: "Temperature (internal)",
    type: "number",
  },
  {
    key: "sensors.temperature.statusTemperature",
    label: "Temperature Status",
  },
  {
    key: "sensors.voltage.data.main",
    label: "Primary Battery Voltage",
    type: "number",
  },
  {
    key: "sensors.voltage.data.auxiliary",
    label: "Secondary Battery Voltage",
    type: "number",
  },
  {
    key: "sensors.voltage.data.solarAmperage",
    label: "Solar Battery Voltage",
    type: "number",
  },
  {
    key: "sensors.voltage.data.statusBattery",
    label: "Battery Health Status",
    type: "string",
  },
  {
    key: "sensors.voltage.statusVoltage",
    label: "Voltage Health Status",
    type: "string",
  },
  {
    key: "sensors.psiAirSupply.statusPsiAirSupply",
    label: "Air Supply Health Status",
    type: "string",
  },
  {
    key: "sensors.psiAirSupply.data.tankPressure",
    label: "Air Tank Pressure",
    type: "number",
  },
  {
    key: "sensors.psiAirSupply.data.statusTankPressure",
    label: "Air Tank Health Status",
    type: "string",
  },
  {
    key: "sensors.psiAirSupply.data.supplyPressure",
    label: "Regulator",
    type: "number",
  },
  {
    key: "sensors.psiAirSupply.regulatorHealthStatus",
    label: "Regulator Health Status",
    type: "string",
  },
  {
    key: "sensors.airbag.data.leftAirBagStatus",
    label: "Airbag Left Pressure",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.airbag.data.rightAirBagStatus",
    label: "Airbag Right Pressure",
    type: "number",
    filterable: false,
  },
  {
    key: "sensors.airbag.statusAirbag",
    label: "Airbag Status",
    type: "string",
  },
  {
    key: "sensors.bleDoorSensor.data.doorState",
    label: "Door State",
    type: "string",
  },
  {
    key: "sensors.absBeta.data.absFaultCodeCount",
    label: "Abs Fault Code",
    type: "number",
  },
  {
    key: "tripStatus.odometer",
    label: "Odometer",
    type: "number",
  },
  {
    key: "sensors.atisAlpha.statusAtisAlpha",
    label: "Atis Health Status",
    type: "string",
  },
  {
    key: "sensors.atisAlpha.data.lightActivatedNumber",
    label: "Atis Light Activated Count",
    type: "number",
  },
  {
    key: "sensors.atisAlpha.data.lightActivatedSeconds",
    label: "Atis Light Activated Time",
    type: "number",
  },
  {
    key: "sensors.liteSentryGamma.statusLiteSentryGamma",
    label: "Light Circuit Health Status",
    type: "string",
  },
  {
    key: "sensors.cargoCamera.data.floorUsageStatus",
    label: "Internal Camera Status",
    type: "string",
  },
  {
    key: "sensors.cargoCamera.data.floorUsagePercentage",
    label: "Internal Camera Floor %",
    type: "string",
  },
  {
    key: "sensors.liftgateAdvertisement.statusLiftgate",
    label: "Liftgate Health Status",
    type: "string",
  },
  {
    key: "sensors.liftgateAdvertisement.data.liftgateVoltage",
    label: "Liftgate Voltage",
    type: "number",
  },
];

export const getInventoryColumns = (timezone: string): ReportGridColDef[] => {
  const commonColumns = getColumns(timezone);
  const exclusiveColumns = inventoryExclusiveFields.map((field) => ({
    ...(typeof field.filterable === "boolean" && {
      filterable: field.filterable,
    }),
    field: field.key,
    headerName: field.label,
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    sortable: false,
    type: field.type ?? "string",
    valueGetter: (
      params: GridValueGetterParams<
        any,
        AssetTransferEvent | AssetInstallEvent | AssetReportDataRow
      >
    ) => getColumnValue(params, field.key),
  }));
  return [...commonColumns, ...exclusiveColumns];
};

export const SEARCH_KEYS = [
  "door_type",
  "org_name",
  "asset_id",
  "name",
  "associated",
  "fullAddress.city",
  "fullAddress.state",
  "fullAddress.postcode",
  "vin",
  "category",
  "prd_cde",
  "tags",
  "tripStatus.tripState",
  "imei",
  "tripStatus.cargoState",
  "sensors.tpmsBeta.statusTpmsBeta",
];

const assetInventoryTpmsFieldTypes = new Array(3)
  .fill("")
  .reduce((prev: Record<string, TableValueDataType>, _, i) => {
    prev[`sensors.tpmsBeta.data.sensors[${i}].primary_roadside_pressure_psi`] =
      TableValueDataType.Number;
    prev[
      `sensors.tpmsBeta.data.sensors[${i}].status_primary_roadside_pressure`
    ] = TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].primary_roadside_temperature_f`] =
      TableValueDataType.Number;
    prev[
      `sensors.tpmsBeta.data.sensors[${i}].status_primary_roadside_temperature`
    ] = TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].primary_curbside_pressure_psi`] =
      TableValueDataType.Number;
    prev[
      `sensors.tpmsBeta.data.sensors[${i}].status_primary_curbside_pressure`
    ] = TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].primary_curbside_temperature_f`] =
      TableValueDataType.Number;
    prev[
      `sensors.tpmsBeta.data.sensors[${i}].status_primary_curbside_temperature`
    ] = TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].inner_roadside_pressure_psi`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].status_inner_roadside_pressure`] =
      TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].inner_roadside_temperature_f`] =
      TableValueDataType.Number;
    prev[
      `sensors.tpmsBeta.data.sensors[${i}].status_inner_roadside_temperature`
    ] = TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].inner_curbside_pressure_psi`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].status_inner_curbside_pressure`] =
      TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].inner_curbside_temperature_f`] =
      TableValueDataType.Number;
    prev[
      `sensors.tpmsBeta.data.sensors[${i}].status_inner_curbside_temperature`
    ] = TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].leftOuterTirePressure`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].leftOuterTireTemperature`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].leftOuterTireStatus`] =
      TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].leftInnerTirePressure`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].leftInnerTireTemperature`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].leftInnerTireStatus`] =
      TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].rightOuterTirePressure`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].rightOuterTireTemperature`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].rightOuterTireStatus`] =
      TableValueDataType.String;
    prev[`sensors.tpmsBeta.data.sensors[${i}].rightInnerTirePressure`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].rightInnerTireTemperature`] =
      TableValueDataType.Number;
    prev[`sensors.tpmsBeta.data.sensors[${i}].rightInnerTireStatus`] =
      TableValueDataType.String;
    return prev;
  }, {});

export const ASSET_INVENTORY_COLUMN_TYPES_MAP: Record<
  string,
  TableValueDataType
> = {
  org_name: TableValueDataType.String,
  "sensors.voltage.data.battery": TableValueDataType.Number,
  asset_id: TableValueDataType.String,
  name: TableValueDataType.String,
  imei: TableValueDataType.String,
  prd_cde: TableValueDataType.String,
  lst_evnt_t: TableValueDataType.Date,
  associated: TableValueDataType.String,
  "tripStatus.tripState": TableValueDataType.String,
  "fullAddress.city": TableValueDataType.String,
  "fullAddress.state": TableValueDataType.String,
  "fullAddress.postcode": TableValueDataType.String,
  vin: TableValueDataType.String,
  "location.coordinates[1]": TableValueDataType.Number,
  "location.coordinates[0]": TableValueDataType.Number,
  category: TableValueDataType.String,
  tags: TableValueDataType.String,
  wheel_config: TableValueDataType.Number,
  num_of_axles: TableValueDataType.Number,
  length: TableValueDataType.Number,
  door_type: TableValueDataType.String,
  last_gps_t: TableValueDataType.Date,
  "healthStatuses.tpms": TableValueDataType.String, // no back
  "sensors.tpmsBeta.receivedDate": TableValueDataType.Date,
  ...assetInventoryTpmsFieldTypes,
  //
  "sensors.atisAlpha.condition": TableValueDataType.String,
  "sensors.liteSentryGamma.data.observations[6].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[0].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[5].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[9].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[3].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[7].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[1].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[8].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[2].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[10].deviation_value":
    TableValueDataType.Number,
  "sensors.liteSentryGamma.data.observations[4].deviation_value":
    TableValueDataType.Number,
  //
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].left_temperature":
    TableValueDataType.Number,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].right_temperature":
    TableValueDataType.Number,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].left_temperature":
    TableValueDataType.Number,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].right_temperature":
    TableValueDataType.Number,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].left_temperature":
    TableValueDataType.Number,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].right_temperature":
    TableValueDataType.Number,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].left_status":
    TableValueDataType.String,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[0].right_status":
    TableValueDataType.String,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].left_status":
    TableValueDataType.String,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[1].right_status":
    TableValueDataType.String,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].left_status":
    TableValueDataType.String,
  "sensors.psiWheelEnd.data.psiWheelEndMeasure[2].right_status":
    TableValueDataType.String,
  "sensors.temperature.data.internal": TableValueDataType.Number,
  "sensors.psiWheelEnd.statusPsiWheelEnd": TableValueDataType.String,
  "sensors.temperature.statusTemperature": TableValueDataType.String,
  "sensors.voltage.data.main": TableValueDataType.Number,
  "sensors.voltage.data.auxiliary": TableValueDataType.Number,
  "sensors.voltage.data.solarAmperage": TableValueDataType.Number,
  "sensors.voltage.data.statusBattery": TableValueDataType.String,
  "sensors.voltage.statusVoltage": TableValueDataType.String,
  "sensors.voltage.data.statusVoltage": TableValueDataType.String,
  "sensors.tpmsBeta.statusTpmsBeta": TableValueDataType.String,
  "sensors.psiAirSupply.statusPsiAirSupply": TableValueDataType.String,
  "sensors.psiAirSupply.data.tankPressure": TableValueDataType.Number,
  "sensors.psiAirSupply.data.statusTankPressure": TableValueDataType.String,
  "sensors.psiAirSupply.data.tankStatus": TableValueDataType.String,
  "sensors.psiAirSupply.data.supplyPressure": TableValueDataType.Number,
  "sensors.psiAirSupply.data.supplyStatus": TableValueDataType.String,
  "sensors.airbag.data.leftAirBagStatus": TableValueDataType.Number,
  "sensors.airbag.data.rightAirBagStatus": TableValueDataType.Number,
  "sensors.airbag.statusAirbag": TableValueDataType.String,
  "sensors.bleDoorSensor.data.doorType": TableValueDataType.String,
  "sensors.bleDoorSensor.data.doorState": TableValueDataType.String,
  "sensors.absBeta.data.absFaultCodeCount": TableValueDataType.Number,
  "tripStatus.odometer": TableValueDataType.Number,
  "sensors.atisAlpha.statusAtisAlpha": TableValueDataType.String,
  "sensors.atisAlpha.data.lightActivatedNumber": TableValueDataType.Number,
  "sensors.atisAlpha.data.lightActivatedSeconds": TableValueDataType.Number,
  "sensors.liteSentryGamma.statusLiteSentryGamma": TableValueDataType.String,
  "sensors.liteSentryGamma.data.stopLightOut": TableValueDataType.String,
  "sensors.liteSentryGamma.data.rightTurnSignalLightOut":
    TableValueDataType.String,
  "sensors.liteSentryGamma.data.leftTurnSignalLightOut":
    TableValueDataType.String,
  "sensors.liteSentryGamma.data.markerLightOut": TableValueDataType.String,
  "sensors.liteSentryGamma.data.licenseLightOut": TableValueDataType.String,
  "tripStatus.cargoState": TableValueDataType.String,
  "sensors.cargoCamera.data.floorUsageStatus": TableValueDataType.String,
  "sensors.cargoCamera.data.floorUsagePercentage": TableValueDataType.String,
  "sensors.liftgateAdvertisement.statusLiftgate": TableValueDataType.String,
  "sensors.psiAirSupply.regulatorHealthStatus": TableValueDataType.String,
  "sensors.liftgateAdvertisement.data.liftgateVoltage":
    TableValueDataType.Number,
};
