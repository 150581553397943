import { PressureUnit } from "../graphql/operations";
import { convertMillibarsToPSI, convertPSIToMillibars } from "./convertUnits";

export enum PressureUnitLabel {
  PSI = "PSI",
  Millibars = "Millibars",
}

export const getPressureUnitLabel = (unit: PressureUnit): PressureUnitLabel => {
  if (unit === PressureUnit.Psi) return PressureUnitLabel.PSI;
  if (unit === PressureUnit.Mbar) return PressureUnitLabel.Millibars;

  return PressureUnitLabel.Millibars;
};

export const getConvertedPressureValueWithUnit = (
  value: number,
  unit: PressureUnit
) => {
  let result = value; // default to millibars

  if (unit === PressureUnit.Psi) {
    result = convertMillibarsToPSI(value);
  }

  return `${result} ${getPressureUnitLabel(unit)}`;
};

export const getConvertedPressureValue = (
  value: number,
  unit: PressureUnit
) => {
  let result = value; // default to millibars

  if (unit === PressureUnit.Psi) {
    result = convertMillibarsToPSI(value);
  }

  return result;
};

export const getConvertedPressureValues = (
  values: number[],
  unit: PressureUnit
) => values.map((value) => getConvertedPressureValue(value, unit));

export const prepareConvertedMbarPressuresPayload = (
  values: number[],
  unit: PressureUnit
): number[] => {
  return values.map((value) => {
    let result = value; // default value is millibars, no need to be converted

    if (unit === PressureUnit.Psi) {
      result = convertPSIToMillibars(value);
    }

    return result;
  });
};

export const prepareConvertedMbarPressurePayload = (
  value: number,
  unit: PressureUnit
): number => {
  let result = value; // default value is millibars, no need to be converted

  if (unit === PressureUnit.Psi) {
    result = convertPSIToMillibars(value);
  }

  return result;
};

export const convertPressureThresholdsRange = <
  T extends { [K in keyof T]: number }
>(
  thresholds: T,
  pressureUnit: PressureUnit
): T => {
  return Object.keys(thresholds).reduce(
    (acc, key) => {
      const typedKey = key as keyof T;
      acc[typedKey] = getConvertedPressureValue(
        thresholds[typedKey],
        pressureUnit
      ) as T[keyof T];

      return acc;
    },
    { ...thresholds }
  );
};
