import { memo, FC, useState, useEffect, useMemo } from "react";
import { Box, FormControl, Select, Typography } from "@mui/material";
import { SelectedValues } from "../HierarchySelect/types";
import { ItemType, MultiSelectItem } from "./MultiSelectItem";
import { FieldType, mutateState, SELECT_ALL_VALUE } from "./utils";

type MultiSelectProps = {
  onChange: (values: SelectedValues) => void;
  options: ItemType[];
  title: string;
  selectAllOption?: boolean;
  field?: FieldType;
};

export const MultiSelect: FC<MultiSelectProps> = memo(
  ({ options, onChange, title, selectAllOption = true, field }) => {
    const optionsWithSelectAll = useMemo(
      () =>
        selectAllOption
          ? [
              {
                id: SELECT_ALL_VALUE,
                label: SELECT_ALL_VALUE,
                visible: false,
                selected: false,
              },
              ...options,
            ]
          : options,
      [options, selectAllOption]
    );
    const [selectedOptions, setSelectedOptions] = useState<string[]>(
      field?.value ?? []
    );
    const [state, setState] = useState<ItemType[]>([...optionsWithSelectAll]);

    useEffect(() => {
      setState(optionsWithSelectAll);
      setSelectedOptions(field?.value ?? []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const valueChangeHandler = (item: ItemType) => {
      let values: string[];
      if (item.id === SELECT_ALL_VALUE) {
        if (state.find((stateItem) => stateItem.id === item.id)?.selected) {
          values = [];
        } else {
          values = state.map((item) => item.label);
        }
      } else if (selectedOptions.includes(item.label)) {
        values = selectedOptions.filter((value: string) => item.id !== value);
      } else {
        values = [...selectedOptions, item.label];
      }

      const assetLengthsOptionsWithoutSelectAll = optionsWithSelectAll.filter(
        (value) => value.id !== SELECT_ALL_VALUE
      );

      if (
        state.length === values.length ||
        values.length === assetLengthsOptionsWithoutSelectAll.length
      ) {
        values.unshift(SELECT_ALL_VALUE);
      } else if (
        values.includes(SELECT_ALL_VALUE) &&
        assetLengthsOptionsWithoutSelectAll.length !== values.length
      ) {
        values = values.filter((value) => value !== SELECT_ALL_VALUE);
      }
      setState(
        mutateState(state, (item: ItemType) => ({
          ...item,
          selected: values.includes(item.id),
        }))
      );
      setSelectedOptions(values.filter((value) => value !== SELECT_ALL_VALUE));
      onChange(values.filter((value) => value !== SELECT_ALL_VALUE));
    };

    const buildSelectedValuesString = () => {
      const optionsArray = optionsWithSelectAll;
      let valuesString: string[] = [];

      selectedOptions.forEach((value: string) => {
        const option = optionsArray.find(
          (option: ItemType) => option.id === value
        );
        valuesString.push(option?.label ?? "");
      });

      if (!valuesString.length) {
        return "";
      }
      return valuesString?.join(", ") ?? "";
    };

    return (
      <FormControl className="w-full" data-testid="multi-select">
        <Typography
          className="!mb-2 !text-sm !font-bold"
          data-testid="multi-select-element-label"
        >
          {title}
        </Typography>
        <Select
          data-testid="multi-select-drop-down"
          MenuProps={{
            autoFocus: false,
            sx: {
              "& .MuiMenu-paper": {
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16) !important",
                maxWidth: "min-content",
              },
            },
          }}
          value={selectedOptions}
          multiple
          fullWidth
          displayEmpty
          renderValue={() => buildSelectedValuesString()}
        >
          <Box
            className="min-h-48 max-h-80 max-w-[327px] overflow-y-auto bg-dashboard_subheader__bg min-w-[100%]"
            sx={{ maxWidth: { xs: "200px" } }}
          >
            {state.map((option) => (
              <MultiSelectItem
                key={option.id}
                item={option}
                onValueChange={valueChangeHandler}
              />
            ))}
          </Box>
        </Select>
      </FormControl>
    );
  }
);
