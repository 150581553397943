import React, { ReactNode } from "react";
import { UserAccessScope } from "../../../WithPermissions";
import { SubHeaderActionButton } from "../SubHeaderActionButton/SubHeaderActionButton";
import {
  SubHeaderActionDropdown,
  SubHeaderDropdownActions,
} from "../SubHeaderActionDropdown/SubHeaderActionDropdown";

export enum SubHeaderActionType {
  Button = "button",
  Dropdown = "dropdown",
}

export interface SubHeaderActionProps {
  type: SubHeaderActionType;
  title: string;
  primary?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  showDropdownIcon?: boolean;
  dropdownActions?: SubHeaderDropdownActions[];
  accessScope: UserAccessScope;
  handler: () => void | Promise<any>;
}

export const SubHeaderAction: React.FC<SubHeaderActionProps> = ({
  type,
  title,
  primary,
  disabled,
  icon,
  showDropdownIcon,
  dropdownActions,
  accessScope,
  handler,
}) => {
  switch (type) {
    case SubHeaderActionType.Dropdown:
      return dropdownActions ? (
        <SubHeaderActionDropdown
          title={title}
          showDropdownIcon={showDropdownIcon}
          primary={primary}
          disabled={disabled}
          dropdownActions={dropdownActions}
          accessScope={accessScope}
        />
      ) : null;
    case SubHeaderActionType.Button:
      return (
        <SubHeaderActionButton
          title={title}
          onClick={handler}
          primary={primary}
          disabled={disabled}
          icon={icon}
          accessScope={accessScope}
        />
      );
    default:
      return null;
  }
};
