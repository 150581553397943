import { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  HorizontalBarChart,
  HorizontalBarChartData,
} from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export interface DashboardWidgetLastReportedData {
  total: number;
  today: number;
  yesterday: number;
  weekBefore: number;
  notReported: number;
}

export interface DashboardWidgetLastReported
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetLastReportedData;
}

export interface AssetsLastReportedWidgetProps {
  widget: DashboardWidgetLastReported;
}

export const mapWidgetData = (
  data: DashboardWidgetLastReportedData
): HorizontalBarChartData[] => {
  if (!data) {
    return [];
  }

  return [
    {
      name: "Today",
      value: data.today,
      color: ColorsPalette.ReChartsTeal,
    },
    {
      name: "Yesterday",
      value: data.yesterday,
      color: ColorsPalette.ReChartsPurple,
    },
    {
      name: "The Week Before Yesterday",
      value: data.weekBefore,
      color: ColorsPalette.ReChartsBlue,
    },
    {
      name: "Never Reported",
      value: data.notReported,
      color: ColorsPalette.ReChartsViolet,
    },
  ];
};

export const AssetsLastReportedWidget: React.FC<
  AssetsLastReportedWidgetProps
> = ({ widget }) => {
  const title = widget.name ?? "";

  const customerOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: customerOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(customerOrg),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetLastReportedData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.total ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--last-reported"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the last reported assets
          widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          isLoading={isLoading}
          showPercentages={true}
          showXAxis={false}
          totalRecords={total}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
