import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ClickAwayListener, Popper, ThemeProvider } from "@mui/material";
import {
  ReadOnlyMenuItem,
  ReadOnlyMenuItemProps,
} from "./components/ReadOnlyMenuItem/ReadOnlyMenuItem";
import { useReadOnlyMenuTheme } from "./hooks/useReadOnlyMenuTheme";

export interface ReadOnlyMenuProps {
  menuItems: ReadOnlyMenuItemProps[];
}

export const ReadOnlyMenu: React.FC<ReadOnlyMenuProps> = ({ menuItems }) => {
  const readOnlyMenuTheme = useReadOnlyMenuTheme();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const onClickAwayHandler = () => {
    setOpen(false);
  };

  const id = open && Boolean(anchorEl) ? "read-only-menu-popper" : undefined;

  return (
    <ThemeProvider theme={readOnlyMenuTheme}>
      <ClickAwayListener onClickAway={onClickAwayHandler}>
        <button
          type="button"
          onClick={onClickHandler}
          aria-describedby={id}
          data-testid="read-only-menu-button"
        >
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </button>
      </ClickAwayListener>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        {menuItems.map((menuItem) => (
          <ReadOnlyMenuItem
            text={menuItem.text}
            key={`read-only-menu-item-${menuItem.text}`}
          />
        ))}
      </Popper>
    </ThemeProvider>
  );
};
