import { Maybe } from "../../../../../../graphql/operations";

export enum AirBagHexCode {
  LeftAirBagHexCode = "4d",
  RightAirBagHexCode = "4e",
}

const airBagLabel: {
  [key: string]: "Left Air Bag" | "Right Air Bag" | undefined;
} = {
  [AirBagHexCode.LeftAirBagHexCode]: "Left Air Bag",
  [AirBagHexCode.RightAirBagHexCode]: "Right Air Bag",
} as const;

export const getAirBagPositionLabel = (locationHexCodeStr?: Maybe<string>) => {
  const incomingHexCode = locationHexCodeStr ?? "";

  return airBagLabel[incomingHexCode] ?? "Unknown";
};
