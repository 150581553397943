import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import { useAppContext } from "../../../context/AppContext";
import Text from "../../components/Text";

export interface DrawerHeaderProps {
  text: string;
  sub?: string;
  onClose: () => void;
}

const DrawerHeader: FC<DrawerHeaderProps> = ({ text, sub, onClose }) => {
  const { state } = useAppContext();
  return (
    <Box
      className="w-full p-7 flex justify-between bg-background !text-primary z-[99]"
      sx={{
        boxShadow: "0 2px 4px 0px var(--box-shadow)",
      }}
    >
      <Box className="w-full">
        <Box
          className="flex justify-between items-center"
          data-testid="drawer-header-text"
        >
          <Text
            fontSize={24}
            fontWeight="bold"
            classes="!text-2xl !text-primary"
          >
            {text}
          </Text>
          <IconButton
            aria-label="close"
            data-testid="drawer-header-close-icon"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {sub && (
          <Box className="flex items-center" data-testid="drawer-header-sub">
            <Text
              fontSize={20}
              fontWeight="regular"
              classes="!text-xl !text-primary"
            >
              {sub}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DrawerHeader;
