import { ColorsPalette } from "../../../../../../design-system/colors-palette";
import {
  GetOdometerTrendsWithSpecificPowerSupplyQuery,
  SearchAggregatedEventHistoryQuery,
  TravelDistanceByPowerSource,
} from "../../../../../../graphql/operations";
import { formatDateToUTC } from "../../../../../../utils";
import { convertKmToMiles } from "../../../../../../utils/convertUnits";
import { AdditionalTripDetailsDataFields } from "./types";

export const DEFAULT_ODOMETER_TRENDS_OPTION = 1;

export const DEFAULT_TRIP_DETAILS_OPTION = 1;

export const DEFAULT_TPMS_TRENDS_OPTION = 5;

export enum AggregatedEventHistoryReportTypes {
  Daily = "Daily",
  DailyReportCargoFloorSpace = "DailyReportCargoFloorSpace",
  DailyReportDoor = "DailyReportDoor",
  DailyReportTrip = "DailyReportTrip",
  DailyReportCargo = "DailyReportCargo",
  AssetTripReport = "AssetTripReport",
}

export const FIVE_MINUTES_REFETCH_INTERVAL = 5 * 60 * 1000;

export const chartTickStyle = {
  fontSize: 10,
  fill: "var(--chart-tick-style)",
  fontWeight: 500,
};

export const chartColors = [
  ColorsPalette.PrimaryBlue,
  ColorsPalette.RoyalBlue,
  ColorsPalette.LightCharcoal,
  ColorsPalette.FeatherGray,
];

export const getTripDetailsChartData = (
  tripDetailsHistoryData?: SearchAggregatedEventHistoryQuery
) => {
  let result: AdditionalTripDetailsDataFields[] = [];
  let sortedAggregatedEventHistoryData: Array<AdditionalTripDetailsDataFields> =
    [];

  // sort the array by event history date
  if (
    tripDetailsHistoryData &&
    typeof tripDetailsHistoryData.searchAggregatedEventHistory?.data !==
      "undefined" &&
    tripDetailsHistoryData.searchAggregatedEventHistory.data !== null &&
    tripDetailsHistoryData.searchAggregatedEventHistory.data.length !== 0
  ) {
    sortedAggregatedEventHistoryData =
      tripDetailsHistoryData?.searchAggregatedEventHistory.data
        ?.filter(
          (item): item is AdditionalTripDetailsDataFields => item !== null
        )
        .map((item) => {
          const eventDate = item?.eventHistoryData?.date;
          const validEventDate = eventDate ? new Date(eventDate) : new Date("");
          return {
            ...item,
            eventDate: validEventDate,
          };
        })
        .sort(
          (a, b) =>
            (a?.eventDate?.getTime() || 0) - (b?.eventDate?.getTime() || 0)
        );
  }
  // // re-map the object properties
  if (tripDetailsHistoryData) {
    result = sortedAggregatedEventHistoryData?.map(
      (obj: AdditionalTripDetailsDataFields) => {
        if (obj["eventHistoryData"] === null) return {};
        let currentEventHistoryDate = obj["eventDate"] ?? new Date("");

        let formattedDate = formatDateToUTC(currentEventHistoryDate).toFormat(
          "MM/dd"
        );
        obj["date"] = formattedDate;

        if (obj?.eventHistoryData?.trip?.travelTime) {
          obj["parkedTime"] =
            (obj?.eventHistoryData?.trip?.travelTime ?? 0) -
            (obj?.eventHistoryData?.trip?.totalTravelTime ?? 0);
          obj["travelTime"] = obj?.eventHistoryData?.trip?.totalTravelTime ?? 0;
        }

        return obj;
      }
    );
  }

  return result;
};

export const getOdometerTrendsChartData = (
  odometerTrendsData?: GetOdometerTrendsWithSpecificPowerSupplyQuery,
  isDistanceUnitMiles?: boolean
) => {
  if (!odometerTrendsData?.searchAggregatedEventHistory?.data) return [];

  const sortedOdometerChartData = [
    ...odometerTrendsData.searchAggregatedEventHistory.data,
  ].sort((a, b) => {
    const aDate = new Date(a?.eventHistoryData?.date ?? 0);
    const bDate = new Date(b?.eventHistoryData?.date ?? 0);

    return aDate.getTime() - bDate.getTime();
  });

  const formattedOdometerChartData = sortedOdometerChartData.map(
    (chartData) => {
      const date = chartData?.eventHistoryData?.date
        ? formatDateToUTC(new Date(chartData?.eventHistoryData?.date)).toFormat(
            "MM/dd"
          )
        : "";
      const travelDistanceByPowerSource = chartData?.eventHistoryData?.trip
        ?.travelDistanceByPowerSource as TravelDistanceByPowerSource;
      let travelDistanceWithPrimaryPower;
      let travelDistanceWithSecondaryPower;
      let travelDistanceWithNoPower;
      if (travelDistanceByPowerSource) {
        travelDistanceWithPrimaryPower = isDistanceUnitMiles
          ? convertKmToMiles(
              travelDistanceByPowerSource?.travelDistanceWithPrimaryPower ?? 0
            )
          : travelDistanceByPowerSource.travelDistanceWithPrimaryPower;

        travelDistanceWithSecondaryPower = isDistanceUnitMiles
          ? convertKmToMiles(
              travelDistanceByPowerSource?.travelDistanceWithSecondaryPower ?? 0
            )
          : travelDistanceByPowerSource.travelDistanceWithSecondaryPower;

        travelDistanceWithNoPower = isDistanceUnitMiles
          ? convertKmToMiles(
              travelDistanceByPowerSource?.travelDistanceWithNoPower ?? 0
            )
          : travelDistanceByPowerSource.travelDistanceWithNoPower;
      }

      return {
        date,
        travelDistanceWithPrimaryPower,
        travelDistanceWithSecondaryPower,
        travelDistanceWithNoPower,
      };
    }
  );

  return formattedOdometerChartData;
};
