import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../../../../shared/hooks/theme/useCurrentTheme";

export const useAssetsListFooterTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "&.MuiIconButton-root": {
                backgroundColor: "var(--primary-blue)",
                color: "var(--white)",
                padding: "4px",
                fontSize: "0.875rem",
                marginRight: "10px",

                "&:hover": {
                  backgroundColor: "var(--primary-blue)",
                },
              },
              "&.MuiPaginationItem-root": {
                color: "var(--typography)",
                padding: "0 2px",
                "&.MuiPagination-ul": {
                  justifyContent: "space-evenly",
                },
              },
              "&.MuiPaginationItem-root.Mui-selected": {
                fontWeight: 800,
                color: "var(--feather-gray)",
                backgroundColor: "var(--black)",
              },
              "&.MuiPaginationItem-root.Mui-selected:hover": {
                fontWeight: 800,
                color: "var(--white)",
                backgroundColor: "var(--black)",
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              marginRight: "8px",
              fontSize: "0.875rem",
              "&.MuiInput-root input[type=number]": {
                MozAppearance: "textfield",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "4px",
                width: "30px",
                height: "14px",
                padding: "4px",
              },
              "&.MuiInput-root input[type=number]::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "&.MuiInput-root input[type=number]::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "&.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before, &.MuiInput-root:before":
                {
                  borderBottom: "none",
                },
              "&.MuiInput-root.Mui-disabled:before": {
                borderBottom: "none!important",
              },
              "&.MuiInput-root.MuiInput-underline.Mui-focused:after ": {
                borderBottom: "none!important",
              },
            },
          },
        },
      },
    })
  );
};
