import { MouseEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  ThemeProvider,
} from "@mui/material";
import {
  Dashboard,
  useGetFavoriteDashboardsNamesQuery,
} from "../../../graphql/operations";
import useBreakpoint from "../../../shared/hooks/useBreakpoint";
import { useUserData } from "../../../shared/hooks/useUserData";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { useFavoriteDashboardsDropdownTheme } from "./useFavoriteDashboardsDropdownTheme";

export interface FavoriteDashboardsDropdownProps {
  disabled?: boolean;
}

export const FavoriteDashboardsDropdown: React.FC<
  FavoriteDashboardsDropdownProps
> = ({ disabled }) => {
  // Hooks
  const theme = useFavoriteDashboardsDropdownTheme();
  const isMobile = useBreakpoint("down", "sm");
  const navigate = useNavigate();
  const userData = useUserData();

  // States
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [favoriteDashboardsIds, setFavoriteDashboardsIds] = useState<number[]>(
    []
  );

  // Effects
  useEffect(() => {
    if (userData) {
      const userFavoriteDashboardsIds: number[] =
        (userData?.user_preferences?.favorite_dashboards as number[]) ?? [];
      const orgFavoriteDashboardsIds =
        (userData?.customerOrg?.favorite_dashboards as number[]) ?? [];

      const uniqueFavoriteDashboardsIds = [
        ...new Set([...orgFavoriteDashboardsIds, ...userFavoriteDashboardsIds]),
      ];

      setFavoriteDashboardsIds(uniqueFavoriteDashboardsIds);
    }
  }, [userData]);

  // Queries
  const { data: favoriteDashboardsData } = useGetFavoriteDashboardsNamesQuery(
    {
      input: {
        ids: favoriteDashboardsIds,
      },
    },
    {
      enabled: favoriteDashboardsIds.length > 0,
    }
  );

  // Handlers
  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    handleClose();
  };

  // Constants
  const favoriteDashboards = useMemo(() => {
    return (favoriteDashboardsData?.getDashboardsByIds ?? []) as Dashboard[];
  }, [favoriteDashboardsData]);

  return (
    <ThemeProvider theme={theme}>
      <Button
        className="!font-bold !text-base"
        sx={{
          color: "var(--brand)",
          textTransform: "none",
          minWidth: "unset",
        }}
        data-testid="favorite-dashboards-button"
        disabled={disabled}
        variant="text"
        type="button"
        onClick={handleOpen}
      >
        <DashboardOutlinedIcon />
        {!isMobile && "Dashboards"}
      </Button>
      <Menu
        id="favorite-dashboards-menu"
        data-testid="favorite-dashboards-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isMobile ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isMobile ? "left" : "right",
        }}
      >
        {favoriteDashboards.map(({ id, name }) => (
          <MenuItem
            key={id}
            data-testid={`favorite-dashboard-${name}`}
            onClick={() => {
              handleNavigate(`${NavigationRoutes.Dashboard}/${id}`);
            }}
          >
            <ListItemText primary={name} />
          </MenuItem>
        ))}
        <MenuItem
          data-testid="view-all-dashboards"
          onClick={() => {
            handleNavigate(NavigationRoutes.Dashboard);
          }}
        >
          <ListItemText
            primary="View All Dashboards"
            style={{
              color: "var(--brand)",
            }}
          />
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
};
