import { FC } from "react";
import { Skeleton } from "@mui/material";

const AssetTooltipSensorSkeleton: FC = () => {
  return (
    <div className="flex flex-row justify-between w-full">
      <Skeleton variant="text" width="50%" height="20px" />
      <Skeleton variant="text" width="30px" height="20px" />
    </div>
  );
};

export default AssetTooltipSensorSkeleton;
