import { Paper, Card, Box, Typography, CardContent } from "@mui/material";
import { DashboardLibraryWidget } from "../../../../../graphql/operations";

export type EmptyWidgetProps = {
  widget: Partial<DashboardLibraryWidget>;
};
const EmptyWidget = ({ widget }: EmptyWidgetProps) => {
  return (
    <Paper
      data-testid="hilights-widget-component"
      className="!bg-background"
      square
      elevation={0}
      sx={{
        borderRadius: "8px",
        backgroundImage: "none !important",
      }}
    >
      <Card
        data-testid="dashboard-widget--card"
        className={`background-white border items-center flex flex-col`}
        sx={{
          boxShadow: "none",
          width: "100%",
          borderRadius: "8px",
          borderColor: "var(--asset-card-border)",
        }}
      >
        <CardContent>
          <Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "var(--primary-blue)",
                }}
              >
                {widget.name}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "var(--dashboard-add-widget-card-title)",
                }}
              >
                {"Data will be displayed after you save the dashboard."}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};
export default EmptyWidget;
