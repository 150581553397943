import { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  DoubleGridWidget,
  DoubleGridWidgetData,
} from "../../../../../shared/components/DashboardWidgets/DoubleGridWidget/DoubleGridWiget";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

export interface DashboardWidgetAssetsInDwellData {
  total: number;
  low: AssetsInDwellData;
  medium: AssetsInDwellData;
  large: AssetsInDwellData;
  mega: AssetsInDwellData;
}

export type AssetsInDwellData = {
  count: number;
  percentage: number;
};

export interface DashboardWidgetAssetsInDwell
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetAssetsInDwellData;
}

export interface AssetsInDwellWidgetProps {
  widget: DashboardWidgetAssetsInDwell;
}

export const mapWidgetDataToDoubleGridWidgetData = (
  data: DashboardWidgetAssetsInDwellData
): DoubleGridWidgetData[] => {
  if (!data) {
    return [];
  }

  return [
    {
      title: "Low Dwell",
      subTitle: "(0-3 Days)",
      itemsName: "Assets",
      count: data.low.count,
      percentage: data.low.percentage,
    },
    {
      title: "Medium Dwell",
      subTitle: "(4-7 Days)",
      itemsName: "Assets",
      count: data.medium.count,
      percentage: data.medium.percentage,
    },
    {
      title: "High Dwell",
      subTitle: "(8-29 Days)",
      itemsName: "Assets",
      count: data.large.count,
      percentage: data.large.percentage,
    },
    {
      title: "Mega Dwell",
      subTitle: "(30+ Days)",
      itemsName: "Assets",
      count: data.mega.count,
      percentage: data.mega.percentage,
    },
  ];
};

export const AssetsInDwellWidget: React.FC<AssetsInDwellWidgetProps> = ({
  widget,
}) => {
  const currentOrg = useSelectedOrg();
  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const [data, total] = useMemo(() => {
    const parsedData: DashboardWidgetAssetsInDwellData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetDataToDoubleGridWidgetData(parsedData);
    const total = parsedData?.total ?? 0;

    return [mappedData, total];
  }, [getDashboardWidgetData?.getDashboardWidget?.data]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--assets-in-dwell"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets in dwell
          widget! Please try again later.
        </Typography>
      ) : (
        <DoubleGridWidget
          title={widget.name ?? ""}
          totalCountTitle="Number Of Assets In Dwell"
          totalCount={total}
          isLoading={isLoading}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
