import React, { memo, useEffect, useState, MutableRefObject } from "react";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { isEqual } from "lodash";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import { OrgData, TableViewType } from "../../../../graphql/operations";
import { Table, getRows } from "../../../../shared/components/Table";
import { isSuperAdmin } from "../../../../shared/components/WithPermissions/userRolePermissions";
import { useExportedFileName } from "../../../../shared/hooks/useExportedFileName";
import { formatDate } from "../../../../utils";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";

export const INITIAL_COLUMNS: GridColDef<OrgData>[] = [
  {
    field: "name",
    headerName: "Organization Name",
    flex: 1,
    minWidth: 120,
    filterable: false,
  },
  {
    field: "parent_company",
    headerName: "Parent Organization",
    flex: 1,
    minWidth: 120,
  },
  { field: "brand", headerName: "Brand", flex: 1, minWidth: 120 },
  { field: "description", headerName: "Description", flex: 1, minWidth: 120 },
  { field: "type", headerName: "Type", flex: 1, minWidth: 120 },
  { field: "time_zones", headerName: "Time Zone", flex: 1, minWidth: 120 },
  {
    field: "device_count",
    headerName: "Number of Devices",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "updated_date",
    headerName: "Updated Date",
    type: "date",
    flex: 1,
    minWidth: 120,
    valueFormatter: (params) => formatDate(params.value),
  },
  {
    field: "added_date",
    headerName: "Created Date",
    type: "date",
    flex: 1,
    minWidth: 120,
    valueFormatter: (params) => formatDate(params.value),
  },
  {
    field: "distance_unit_preference",
    headerName: "Distance Unit Preference",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "pressure_unit_preference",
    headerName: "Pressure Unit Preference",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "temperature_unit_preference",
    headerName: "Temperature Unit Preference",
    flex: 1,
    minWidth: 120,
  },
];

const SEARCH_KEYS = INITIAL_COLUMNS.map((column) => column.field);
// for roles other than superadmin. Ref: https://phillips-connect.atlassian.net/browse/PRJIND-6322
const INITIAL_COLUMNS_NO_BRAND = INITIAL_COLUMNS.filter(
  (column) => column.field !== "brand"
);
const SEARCH_KEYS_NO_BRAND = SEARCH_KEYS.filter((key) => key !== "brand");

export const SORT_KEYS: string[] = [
  "name",
  "parent_company",
  "brand",
  "description",
  "type",
  "time_zones",
  "device_count",
  "updated_date",
  "added_date",
  "distance_unit_preference",
  "pressure_unit_preference",
  "temperature_unit_preference",
];

export const columnVisibilityModel = {
  description: false,
};

export interface OrgManagementTableProps {
  onRowClick: (e: any) => void;
  organizations: OrgData[] | null;
  isLoading: boolean;
  isSuccess: boolean;
  isDataRefetching: boolean;
  initialSearch: string;
  apiRef: MutableRefObject<GridApiPremium>;
}

const OrgManagementTable: React.FC<OrgManagementTableProps> = (props) => {
  const {
    organizations,
    isSuccess,
    isLoading,
    apiRef,
    initialSearch,
    isDataRefetching,
  } = props;

  const [rows, setRows] = useState<any[]>([]);
  // Options local state
  const [timezoneOptionsState, setTimezoneOptionsState] = useState<any[]>([]);
  const [pressureUnitsOptionsState, setPressureUnitsOptionsState] = useState<
    any[]
  >([]);
  const [temperatureUnitsOptionsState, setTemperatureUnitsOptionsState] =
    useState<any[]>([]);

  const fileName = useExportedFileName("Organizations");

  const { userInfo } = useAuthContext();
  const {
    state: { appConfig },
  } = useAppContext();

  // Options-getter hooks
  const timezoneOptions = useNomenclatures(NOMENCLATURE_NAMES.timeZones);
  const pressureUnitsOptions = useNomenclatures(
    NOMENCLATURE_NAMES.pressureUnits
  );
  const temperatureUnitsOptions = useNomenclatures(
    NOMENCLATURE_NAMES.temperatureUnits
  );

  // Options-setter effects
  useEffect(() => {
    if (!isEqual(timezoneOptions, timezoneOptionsState)) {
      setTimezoneOptionsState(timezoneOptions);
    }
  }, [timezoneOptions, timezoneOptionsState]);

  useEffect(() => {
    if (!isEqual(pressureUnitsOptions, pressureUnitsOptionsState)) {
      setPressureUnitsOptionsState(pressureUnitsOptions);
    }
  }, [pressureUnitsOptions, pressureUnitsOptionsState]);

  useEffect(() => {
    if (!isEqual(temperatureUnitsOptions, temperatureUnitsOptionsState)) {
      setTemperatureUnitsOptionsState(temperatureUnitsOptions);
    }
  }, [temperatureUnitsOptions, temperatureUnitsOptionsState]);

  const isSuperAdminUser = userInfo && isSuperAdmin(userInfo.groups);
  const [columns, searchKeys] = isSuperAdminUser
    ? [INITIAL_COLUMNS, SEARCH_KEYS]
    : [INITIAL_COLUMNS_NO_BRAND, SEARCH_KEYS_NO_BRAND];

  useEffect(() => {
    // map product name, associated_to_asset
    const orgListWithoutPhillipsConnect = (organizations || []).filter(
      (organization: OrgData) => organization.org_key !== "Phillips Connect"
    );

    const organizationsList = (orgListWithoutPhillipsConnect || []).map(
      (organization: OrgData) => {
        const hierarchy = organization?.org_key
          ? organization?.org_key
              .split("/")
              .filter((x) => x !== "Phillips Connect")
          : null;
        const _orgData = {
          ...organization,
          time_zones:
            timezoneOptionsState?.find(
              (eachTimeZone) => eachTimeZone.value === organization.time_zones
            )?.label || "",
          parent_company: getParentCompany(hierarchy),
          brand: organization?.brand?.name,
          pressure_unit_preference:
            pressureUnitsOptionsState?.find(
              (pressureUnit) =>
                pressureUnit?.value === organization?.pressure_unit_preference
            )?.label ?? "",
          temperature_unit_preference:
            temperatureUnitsOptionsState?.find(
              (tempUnit) =>
                tempUnit?.value === organization?.temperature_unit_preference
            )?.label ?? "",
        };
        return _orgData;
      }
    );

    setRows(
      getRows<typeof organizationsList>(
        organizationsList,
        columns,
        "",
        "",
        "_id"
      )
    );
  }, [
    organizations,
    columns,
    pressureUnitsOptionsState,
    temperatureUnitsOptionsState,
    timezoneOptionsState,
  ]);
  const getParentCompany = (hierarchy: any) => {
    return !!hierarchy && hierarchy?.length > 1 ? hierarchy.reverse()[1] : null;
  };

  return (
    <Box className="w-full h-full pb-4">
      <Table
        {...props}
        tableType={TableViewType.Organizations}
        showToolbar
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        initialSearch={initialSearch}
        loading={isLoading}
        error={!isLoading && !isSuccess ? true : null}
        columnVisibilityModel={columnVisibilityModel}
        enableSearch={true}
        searchKeys={searchKeys}
        sortKeys={SORT_KEYS}
        searchMinLength={appConfig.searchMinLength}
        searchThreshold={0.1}
        searchExactMatch
        data-testid="table-org-management"
        tableName="org"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        isDataRefetching={isDataRefetching}
      />
    </Box>
  );
};

export default memo(OrgManagementTable);
