import { FieldValues, UseFormReturn } from "react-hook-form";
import * as yup from "yup";
import { GeofenceSettingsType } from "../../../../../constants/geofences";
import {
  GeofenceData,
  SetGeofenceRestrictionsInput,
} from "../../../../../graphql/operations";
import { GeofenceInitialCapacity, useCapacityForm } from "./useCapacityForm";
import { GeofenceInitialDetails, useDetailsForm } from "./useDetailsForm";
import {
  GeofenceInitialOperations,
  useOperationsForm,
} from "./useOperationsForm";
import {
  GeofenceInitialRestrictions,
  useRestrictionsForm,
} from "./useRestrinctionsForm";
import {
  appendInitialOpperations,
  getCapacityInitialValues,
  getDetailsInitialValues,
  getRestrictionsInitialValues,
} from "./utils";

type FormReturnType = {
  schema:
    | yup.SchemaOf<GeofenceInitialDetails>
    | yup.SchemaOf<GeofenceInitialCapacity>
    | yup.SchemaOf<GeofenceInitialOperations>
    | yup.SchemaOf<GeofenceInitialRestrictions>;
  form: UseFormReturn<FieldValues, GeofenceData, undefined>;
  getValues: () => Partial<GeofenceData | GeofenceInitialRestrictions>;
};

export const useCurrentForm = (
  type: GeofenceSettingsType,
  initialValues: GeofenceData,
  initialRestrictionValues: SetGeofenceRestrictionsInput | null | undefined
): FormReturnType => {
  const {
    form: detailsGeofenceForm,
    schema: detailsGeofenceSchema,
    getValues: detailsGeofenceGetValues,
  } = useDetailsForm(getDetailsInitialValues(initialValues));
  const {
    form: operationsGeofenceForm,
    schema: operationsGeofenceSchema,
    getValues: operationsGeofenceGetValues,
  } = useOperationsForm(appendInitialOpperations(initialValues));
  const {
    form: capacityGeofenceForm,
    schema: capacityGeofenceSchema,
    getValues: capacityGeofenceGetValues,
  } = useCapacityForm(getCapacityInitialValues(initialValues));
  const {
    form: restrictionsGeofenceForm,
    schema: restrictionsGeofenceSchema,
    getValues: restrictionsGeofenceGetValues,
  } = useRestrictionsForm(
    getRestrictionsInitialValues(initialRestrictionValues)
  );
  switch (type) {
    case GeofenceSettingsType.Details:
      return {
        schema: detailsGeofenceSchema as any,
        form: detailsGeofenceForm,
        getValues: detailsGeofenceGetValues,
      };
    case GeofenceSettingsType.Operations:
      return {
        schema: operationsGeofenceSchema,
        form: operationsGeofenceForm,
        getValues: operationsGeofenceGetValues,
      };
    case GeofenceSettingsType.Capacity:
      return {
        schema: capacityGeofenceSchema,
        form: capacityGeofenceForm,
        getValues: capacityGeofenceGetValues,
      };
    case GeofenceSettingsType.Restrictions:
      return {
        schema: restrictionsGeofenceSchema,
        form: restrictionsGeofenceForm,
        getValues: restrictionsGeofenceGetValues as any, // TODO: Review this and fix types
      };
    default:
      return {} as FormReturnType;
  }
};
