import { ColorsPalette } from "../../../../../../design-system/colors-palette";
import { AggregatedEventHistory } from "../../../../../../graphql/operations";

export const BAR_HEIGHT = 24;

export type CargoData = {
  eventHistoryData?: {
    cargo: Partial<AggregatedEventHistory["cargo"] | null>;
    date?: string | null;
  };
} | null;

/**
 * Function finds the max in an array by first doing preparation to the array to be usable
 * !!! To keep the simplicity of this fn, please don't add any more conditions, just make sure the input is a valid number[]
 */
export function indexOfMax(arr: (number | null)[]) {
  const hasPositive = arr.some((num) => (num as number) > 0);
  // if there are no positive numbers or the array is empty, return -1
  if (!hasPositive || !arr.length) return -1;
  // if there are null values in the array, transform them to 0 to keep the index
  const transformedArr = arr.map((num) => num ?? 0);
  // return the index of the max value in the array
  return arr.indexOf(Math.max(...transformedArr));
}

export function calculateCargoStatusLast30Days(data: Array<CargoData>) {
  let emptyCargoEvents = 0;
  let loadedCargoEvents = 0;

  data.forEach((aggregatedData: CargoData) => {
    const cargoLoadedEvents =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary?.loaded ?? 0;
    const cargoUnloadLoadedEvents =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary?.unloaded ?? 0;

    emptyCargoEvents += cargoUnloadLoadedEvents;
    loadedCargoEvents += cargoLoadedEvents;
  });

  return {
    emptyCargoEvents,
    loadedCargoEvents,
  };
}

export function calculateCargoStatusInLocationsLast30Days(
  data: Array<CargoData>
) {
  let emptyCargoEventsInsideGeofence = 0;
  let emptyCargoEventsOutsideGeofence = 0;
  let loadedCargoEventsInsideGeofence = 0;
  let loadedCargoEventsOutsideGeofence = 0;

  data.forEach((aggregatedData: CargoData) => {
    const loadedCountGeofence =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary
        ?.loadedCountGeofence ?? 0;
    const loadedCountOutGeofence =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary
        ?.loadedCountOutGeofence ?? 0;

    const unloadedCountGeofence =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary
        ?.unloadedCountGeofence ?? 0;
    const unloadedCountOutGeofence =
      aggregatedData?.eventHistoryData?.cargo?.cargoSummary
        ?.unloadedCountOutGeofence ?? 0;

    emptyCargoEventsInsideGeofence += unloadedCountGeofence ?? 0;
    emptyCargoEventsOutsideGeofence += unloadedCountOutGeofence ?? 0;
    loadedCargoEventsInsideGeofence += loadedCountOutGeofence ?? 0;
    loadedCargoEventsOutsideGeofence += loadedCountGeofence ?? 0;
  });

  return {
    emptyCargoEventsInsideGeofence,
    emptyCargoEventsOutsideGeofence,
    loadedCargoEventsInsideGeofence,
    loadedCargoEventsOutsideGeofence,
  };
}

export const getBoxColor = (eventsCount: number) => {
  if (eventsCount === 0) return "border";
  if (eventsCount > 0) return "bg-brand-light-blue";
  return "bg-box-shadow";
};

export const getBoxBorderColor = (eventsCount: number) => {
  if (eventsCount === 0) return ColorsPalette.RoyalBlue;
  if (eventsCount > 0) return ColorsPalette.Concrete;
  return "bg-grayscale-feather-gray";
};
