import { FC, useEffect, useState, useMemo, MutableRefObject } from "react";
import { Box } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useGetConfigurationSetsQuery,
  ConfigurationSetType,
  TableViewType,
} from "../../../../../../graphql/operations";
import { getRows, Table } from "../../../../../../shared/components/Table";
import { useCurrentOrg } from "../../../../../../shared/hooks/useCurrentOrg";
import { useExportedFileName } from "../../../../../../shared/hooks/useExportedFileName";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";
import { assetsTableColumns } from "../../configurationsUtils";

export interface AssetsTableProps {
  apiRef: MutableRefObject<GridApiPremium>;
  orgId: string | undefined;
  onRowClick: (data: any) => void;
}

const AssetsTable: FC<AssetsTableProps> = ({ apiRef, orgId, onRowClick }) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const dataCurrentOrg = useCurrentOrg();
  const isPhillipsConnectMember =
    dataCurrentOrg?.name === "Phillips Connect" ||
    dataCurrentOrg?.org_key === "Phillips Connect";

  const [rowsState, setRowsState] = useState<any[]>([]);

  const fileName: string = useExportedFileName("Assets");

  const {
    data: dataConfigurations,
    isError: isErrorConfiguration,
    // using isFetching because for some reason isLoading stucks on 'true'
    isFetching: isFetchingConfiguration,
  } = useGetConfigurationSetsQuery(
    {
      input: {
        orgId: orgId ?? "",
        type: ConfigurationSetType.AssetType,
        includeSuborgs: true,
      },
    },
    {
      enabled: Boolean(orgId) && isPhillipsConnectMember,
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keysList = useMemo(
    () => assetsTableColumns.map((column) => column.field),
    []
  );
  const dataParsed = useMemo(
    () =>
      dataConfigurations?.getConfigurationSets?.map((singleSet) => {
        const singleSetCopy = singleSet as any;
        const valuePropertyParsed =
          singleSet?.value && JSON.parse(singleSet?.value);

        return {
          _id: singleSet._id,
          name: singleSetCopy.name,
          colorReflectsAsset: valuePropertyParsed.colorReflectsAsset,
          outerRingReflectsDwell: valuePropertyParsed.outerRingReflectsDwell,
          preCheckEnabled: valuePropertyParsed.preCheckEnabled,
          lostCommunicationTime: valuePropertyParsed.lostCommunicationTime,
          centerTextDescriptionLabel: valuePropertyParsed.centerSensor
            ? valuePropertyParsed.centerSensor.centerTextDescriptionLabel
            : "",
          main: valuePropertyParsed.power ? valuePropertyParsed.power.main : "",
          auxiliary: valuePropertyParsed.power
            ? valuePropertyParsed.power.auxiliary
            : "",
          solarAmperage: valuePropertyParsed.power
            ? valuePropertyParsed.power.solarAmperage
            : "",
          battery: valuePropertyParsed.power
            ? valuePropertyParsed.power.battery
            : "",
          type: valuePropertyParsed.centerSensor
            ? valuePropertyParsed.centerSensor.type
            : "",
        };
      }),
    [dataConfigurations?.getConfigurationSets]
  );

  useEffect(() => {
    if (dataParsed) {
      setRowsState(
        getRows<typeof dataParsed>(
          dataParsed,
          assetsTableColumns,
          "",
          "",
          "_id"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataParsed]);

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={TableViewType.AssetConfigurations}
        showToolbar
        rows={rowsState}
        apiRef={apiRef}
        columns={assetsTableColumns}
        onRowClick={onRowClick}
        loading={isFetchingConfiguration}
        error={isErrorConfiguration}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={keysList}
        sortKeys={keysList}
        searchMinLength={appConfig.searchMinLength}
        tableName="assets-config"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        isDataRefetching={false}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default AssetsTable;
