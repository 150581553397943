import { memo, FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/DownloadOutlined";
import { Typography, Box } from "@mui/material";
import MUIDialogTitle from "@mui/material/DialogTitle";

type DialogTitleProps = {
  title?: string;
  subTitle?: string;
  onClose: () => void;
  downloadTemplateFilePath?: string;
};

const DialogTitle: FC<DialogTitleProps> = ({
  title,
  subTitle,
  onClose,
  downloadTemplateFilePath,
}) => (
  <MUIDialogTitle className="bg-background">
    <Box className="flex justify-between">
      <Box className="py-4">
        <Typography className="!text-2xl !font-semibold">{title}</Typography>
      </Box>
      <Box className="py-4 text-upload_file__icon">
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </Box>
    </Box>
    <Box>
      {subTitle && (
        <Box sx={{ display: "flex" }} className="items-center font-semibold">
          <Typography className="!text-base">{subTitle}</Typography>
          {downloadTemplateFilePath && (
            <a href={downloadTemplateFilePath} download>
              <Typography className="!ml-2 text-brand !font-bold">
                Download template
                <FileDownloadIcon fontSize="medium" />
              </Typography>
            </a>
          )}
        </Box>
      )}
    </Box>
  </MUIDialogTitle>
);

export default memo(DialogTitle);
