import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import {
  LiftgateBatteryTableData,
  useGetLiftgateBatteryTableDataQuery,
} from "../../../../../../../../graphql/operations";
import { Table } from "../../../../../../../../shared/components/Table";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../../../../../AdminPanel/tabs/constants";
import { LiftgateHeader } from "../../header/LiftgateHeader";
import { columns, columnVisibilityModel } from "./utils";

type LiftgateBatteryInfoTableProps = {
  imei: string;
};

const LiftgateBatteryInfoTable: React.FC<LiftgateBatteryInfoTableProps> = ({
  imei,
}) => {
  const apiRef = useGridApiRef();
  const [tableRows, setTableRows] = useState<LiftgateBatteryTableData[]>([]);
  const { data, isLoading, isError } = useGetLiftgateBatteryTableDataQuery({
    input: {
      imei,
    },
  });

  useEffect(() => {
    if (data?.getLiftgateBatteryTableData?.data) {
      setTableRows(data.getLiftgateBatteryTableData.data ?? []);
    }
  }, [data?.getLiftgateBatteryTableData?.data]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "var(--card__bg)",
        borderRadius: "0.5rem",
        height: "450px",
      }}
      data-testid="liftgate-battery-info-table-wrapper"
    >
      {/* Header */}
      <Box
        sx={{
          paddingTop: "1rem",
          margin: "1rem",
        }}
        data-testid="liftgate-battery-info-table-header"
      >
        <LiftgateHeader text="Liftgate Battery Information Table" />
      </Box>

      {/* Table */}
      <Box
        sx={{
          height: "50%",
          padding: "1rem",
        }}
      >
        <Table
          sx={{
            height: "70.65%",
          }}
          showPopover={false}
          showToolbar={false}
          pagination={false}
          rows={tableRows}
          apiRef={apiRef}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          loading={isLoading}
          error={isError}
          tableName="LiftgateBatteryInfo"
          isDataRefetching={false}
          mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
          disableColumnMenu
          disableColumnReorder
          disableColumnResize
        />
      </Box>
    </Box>
  );
};

export default LiftgateBatteryInfoTable;
