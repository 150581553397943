import React from "react";
import { AutocompleteElement } from "react-hook-form-mui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  FormControl,
  Grid,
  InputLabel,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { CustomTooltip } from "../../../../../../shared/components/CustomTooltip/CustomTooltip";
import { useFormTheme } from "../../../../../../shared/hooks/theme/useFormTheme";

interface SensorProfileAssignmentFormProps {
  breakpoints?: Record<string, number>;
  isTypeOfAssignmentProfile: boolean;
  form: any;
  SENSOR_FORM_FIELDS: any[];
  handleAssignmentTypeInputChange: (value: any) => void;
  handleSensorProfileInputChange: (value: any) => void;
  isProfileDataLoading: boolean;
}

const SensorProfileAssignmentForm: React.FC<
  SensorProfileAssignmentFormProps
> = ({
  breakpoints = { xs: 12 },
  isTypeOfAssignmentProfile,
  form,
  SENSOR_FORM_FIELDS,
  handleAssignmentTypeInputChange,
  handleSensorProfileInputChange,
  isProfileDataLoading,
}) => {
  const formTheme = useFormTheme();
  const options = SENSOR_FORM_FIELDS[1].options;
  const field = SENSOR_FORM_FIELDS[1].name;
  const sensorProfileId = form.getValues()[field];
  if (sensorProfileId && !isProfileDataLoading) {
    const valueExistsInOptions = options.some(
      (option: any) => option.value === sensorProfileId
    );
    if (!valueExistsInOptions) {
      options.push({
        id: sensorProfileId,
        label: "<Hidden Information>",
        value: sensorProfileId,
        disabled: true,
      });
    }
  }

  return (
    <ThemeProvider theme={formTheme}>
      <form>
        <Grid container className="bg-background noTopPaddingDrawerSection">
          <Grid
            item
            {...breakpoints}
            data-testid="autocomplete-type-of-assignment-sensors"
          >
            <AutocompleteElement
              autocompleteProps={{
                onChange: (_: any, value: any) =>
                  handleAssignmentTypeInputChange(value),
              }}
              rules={{ required: true }}
              matchId={true}
              label={SENSOR_FORM_FIELDS[0].label}
              control={form.control}
              name={SENSOR_FORM_FIELDS[0].name}
              options={SENSOR_FORM_FIELDS[0].options}
            />
          </Grid>
          {isTypeOfAssignmentProfile && (
            <Grid
              item
              {...breakpoints}
              data-testid="autocomplete-profile-sensors"
            >
              <FormControl fullWidth>
                <AutocompleteElement
                  loading={isProfileDataLoading}
                  autocompleteProps={{
                    onChange: (_, value) =>
                      handleSensorProfileInputChange(value),
                    getOptionDisabled: (option) => option.disabled,
                  }}
                  rules={{ required: true }}
                  matchId={true}
                  label={SENSOR_FORM_FIELDS[1].label}
                  control={form.control}
                  name={SENSOR_FORM_FIELDS[1].name}
                  options={options}
                />
                <InputLabel
                  htmlFor="assignment-type-autocomplete"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CustomTooltip
                    title={
                      <Typography variant="caption">
                        Shared assets can have assign sensor settings
                        <br /> (profile) from an external organization.
                      </Typography>
                    }
                    arrow
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      className="sub-header-text"
                      style={{ marginLeft: 115 }}
                    />
                  </CustomTooltip>
                </InputLabel>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </form>
    </ThemeProvider>
  );
};

export default SensorProfileAssignmentForm;
