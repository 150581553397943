import { FC, useEffect } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  Stack,
  TextField,
  MenuItem,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { last } from "lodash";
import { ReactComponent as CameraSensorIconDark } from "../../../../../../../assets/svgs/cameraSensorDark.svg";
import { ReactComponent as CameraSensorIconLight } from "../../../../../../../assets/svgs/cameraSensorLight.svg";
import {
  DEFAULT_CHART_DATE_RANGE_OPTION,
  DEFAULT_DATE_RANGE_OPTIONS,
} from "../../../../../../../constants/map";
import { useAppContext } from "../../../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../../../design-system/colors-palette";
import SensorHeader from "../../../../../../../shared/components/SensorHeader/SensorHeader";
import { DATE_FORMAT, getSubMonths } from "../../../../../../../utils";
import { useAirBagTheme } from "../../../../Shared/AirBagTrendingGraph/useAirBagTheme";
import { useInternalCameraFloorSpaceGraphContext } from "../context";

export interface HeaderProps {
  allowResize: boolean;
  gridWidthIndicator: number;
  handleToggleGridWidth: () => void;
}

const Header: FC<HeaderProps> = ({
  allowResize,
  gridWidthIndicator,
  handleToggleGridWidth,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const AirBagTheme = useAirBagTheme();
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";

  const svgIconSettings = {
    width: "80px",
    height: "80px",
    display: "block",
  };

  const {
    selectedDateRangeOption,
    setSelectedDateRangeOption,

    startDate,
    setStartDate,

    endDate,
    setEndDate,
  } = useInternalCameraFloorSpaceGraphContext();

  useEffect(() => {
    handleDateSelect(DEFAULT_CHART_DATE_RANGE_OPTION);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateSelect = (value: number) => {
    setSelectedDateRangeOption(value);

    const option = DEFAULT_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );

    if (option?.getRange) {
      const [startDate, endDate] = option.getRange();

      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const minAllowedDate = getSubMonths(
    new Date(),
    appConfig.monthsBackDateLimit
  );

  return (
    <ThemeProvider theme={AirBagTheme}>
      <Stack
        direction="row"
        justifyContent="end"
        data-testid="camera-floor-space-trending-graph-header"
      >
        <SensorHeader title="Media Floor Trending Graph" noBorder>
          {isLightTheme ? (
            <CameraSensorIconDark style={svgIconSettings} />
          ) : (
            <CameraSensorIconLight style={svgIconSettings} />
          )}
        </SensorHeader>
        {allowResize && (
          <IconButton
            sx={{
              width: "2rem",
              height: "1rem",
              backgroundColor: "transparent!important",
            }}
            aria-label="toggleWidth"
            data-testid="camera-floor-space-resize-button"
            onClick={() => handleToggleGridWidth()}
          >
            <OpenInFullIcon
              fontSize="medium"
              style={{
                fill: "var(--cargo-trend-expand-icon)",
                marginRight: "0.313rem",
              }}
            />
            <span
              className="font-bold text-sm leading-4"
              style={{ marginTop: "-0.5rem", color: ColorsPalette.LightBlue }}
            >
              {gridWidthIndicator}
            </span>
          </IconButton>
        )}
      </Stack>

      <Box className="mb-5">
        <Stack direction="row" justifyContent="end">
          <Box className="flex items-center justify-end gap-4 py-4">
            <MobileDatePicker
              label="Start date"
              inputFormat={DATE_FORMAT}
              closeOnSelect
              value={startDate}
              minDate={minAllowedDate}
              disableFuture
              onChange={(date) => {
                if (date) {
                  setStartDate(date);
                  setSelectedDateRangeOption(
                    last(DEFAULT_DATE_RANGE_OPTIONS)?.value!
                  );
                }
              }}
              DialogProps={{
                className: "trending-voltage-date-picker",
              }}
              renderInput={(params) => (
                <TextField
                  data-testid="camera-floor-space-chart-start-date"
                  variant="outlined"
                  {...params}
                />
              )}
            />
            <span className="text-base font-normal text-primary">to</span>
            <MobileDatePicker
              DialogProps={{
                className: "trending-voltage-date-picker",
              }}
              label="End date"
              closeOnSelect
              inputFormat={DATE_FORMAT}
              value={endDate}
              minDate={minAllowedDate}
              disableFuture
              onChange={(date) => {
                if (date) {
                  setEndDate(date);
                  setSelectedDateRangeOption(
                    last(DEFAULT_DATE_RANGE_OPTIONS)?.value!
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  data-testid="camera-floor-space-chart-end-date"
                  variant="outlined"
                  {...params}
                />
              )}
            />

            <TextField
              select
              value={selectedDateRangeOption}
              variant="outlined"
              className="w-40"
              onChange={(e) => handleDateSelect(Number(e.target.value))}
              data-testid="camera-floor-space-chart-date-range-select"
            >
              {Object.entries(DEFAULT_DATE_RANGE_OPTIONS).map(
                ([key, option]) => {
                  return (
                    <MenuItem
                      key={key}
                      value={option.value}
                      data-testid={`cargo-graph-date-select-option-${option.value}`}
                    >
                      {option.label}
                    </MenuItem>
                  );
                }
              )}
            </TextField>
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default Header;
