import { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { Marker } from "@react-google-maps/api";
import { useDebouncedCallback } from "use-debounce";
import {
  beaconIconsMapping,
  beaconTypes,
  getFeatureIconType,
} from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import { getGMClusterSVG } from "../../../../../utils/maps/getGMFeatureIcon";
import { MarkerImage } from "../../../../../utils/maps/getGMMarkers";
import { Feature } from "../../../shared/AssetsDataContext";
import { useMapFocusedFeature } from "../../hooks/useMapFocusedFeature";
import AssetShortTooltip from "../AssetShortTooltip";
import { arrowSide } from "../AssetShortTooltip/helpers";
import { ClusterTooltip } from "../ClusterTooltip/ClusterTooltip";
import { useClickHandlers } from "./useClickHandlers";

type AssetsMarkersProps = {
  serverSideMapFeatures: Feature[];
  markerImages: MarkerImage[];
  googleMap: google.maps.Map | null;
};

export const getGMMarkerIcon = (
  iconType: string,
  markerImages: MarkerImage[]
) => {
  const isBeacon = beaconTypes.includes(iconType); // icon emits animated waves
  const isDwell = iconType.indexOf("dwell") > -1; // icon has additional rings around it

  const beaconIcon = beaconIconsMapping[iconType];
  const staticIcon = markerImages.find(
    (markerObj) => markerObj.type === iconType
  );

  const icon: google.maps.Icon = isBeacon
    ? {
        url: beaconIcon as string,
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 20),
      }
    : isDwell
    ? {
        url: staticIcon?.imageData as string,
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 20),
      }
    : {
        url: staticIcon?.imageData as string,
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(40, 40),
      };

  return icon;
};

// Display polygons from GeoJSON data.
const AssetsMarkers: FC<AssetsMarkersProps> = ({
  serverSideMapFeatures,
  markerImages,
  googleMap,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const arrowRef = useRef(null);

  const { focusedFeature, setFocusedFeature } = useMapFocusedFeature<Feature>();
  const { onMarkerClick, onClusterClick } = useClickHandlers({
    googleMap,
    setFocusedFeature,
  });

  const { refs, floatingStyles, middlewareData, placement } = useFloating({
    open: !!focusedFeature,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(20),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
      arrow({ element: arrowRef }),
    ],
  });

  // State to check if feature is hovered to prevent tooltip anomalies, due to debounce on mouse over event
  const [isFeatureHovered, setFeatureHovered] = useState(false);
  // debounce on mouse over event to reduce the number of FindAssetById requests
  const onMouseOverDebounced = useDebouncedCallback((feature: Feature) => {
    if (isFeatureHovered) {
      setFocusedFeature(feature);
    }
  }, appConfig.debounceTime);

  const onMouseOut = () => {
    refs.setReference(null);
    setFocusedFeature(null);
    setFeatureHovered(false);
  };

  const currentSide = placement.split("-")[0];

  // Check if focused feature is still shown on the map to prevent showing tooltip for asset that is no longer displayed (e.g. map being zoomed out)
  const isFocusedFeatureVisible = useMemo(
    () =>
      focusedFeature &&
      serverSideMapFeatures?.some(
        (feature) => feature.properties.id === focusedFeature.properties.id
      ),
    [serverSideMapFeatures, focusedFeature]
  );

  // Reset focused feature when features data is updated
  useEffect(() => {
    setFocusedFeature(null);
  }, [serverSideMapFeatures, setFocusedFeature]);

  return (
    <>
      {!!markerImages.length &&
        serverSideMapFeatures?.map((feature) => {
          const isCluster = feature.properties.cluster;
          let icon: string | google.maps.Icon;
          if (isCluster) {
            const clusterSvg = getGMClusterSVG(
              feature.properties.point_count_abbreviated as string,
              feature.properties.point_count as number,
              feature.properties.assetsCountPerHealthStatus ?? {}
            );
            const encodedSvg = encodeURIComponent(clusterSvg);
            icon = `data:image/svg+xml;charset=UTF-8, ${encodedSvg}`;
          } else {
            const iconType = getFeatureIconType(feature);
            icon = getGMMarkerIcon(iconType, markerImages);
          }

          return (
            <Marker
              key={`${feature.geometry.coordinates[0]}-${feature.geometry.coordinates[1]}`}
              icon={icon}
              zIndex={isCluster ? 10 : 20}
              onMouseOver={(event: google.maps.MapMouseEvent) => {
                refs.setReference(event.domEvent.target as HTMLElement);
                setFeatureHovered(true);
                onMouseOverDebounced(feature);
              }}
              onMouseOut={onMouseOut}
              onClick={() =>
                isCluster ? onClusterClick(feature) : onMarkerClick(feature)
              }
              position={{
                lat: feature.geometry.coordinates[1],
                lng: feature.geometry.coordinates[0],
              }}
              options={{ optimized: false }} // if optimized is set to true the clusters are blinking on hover on single asset
            />
          );
        })}
      {focusedFeature && isFocusedFeatureVisible && (
        <div ref={refs.setFloating} style={floatingStyles}>
          <div
            ref={arrowRef}
            style={{
              position: "absolute",
              width: "16px",
              height: "16px",
              backgroundColor: "var(--custom-tooltip-background)",
              ...arrowSide[currentSide],
              ...(middlewareData.arrow && {
                left: middlewareData.arrow.x ?? arrowSide[currentSide].left,
                top: middlewareData.arrow.y ?? arrowSide[currentSide].top,
              }),
            }}
          />
          {focusedFeature.properties.cluster ? (
            <ClusterTooltip
              totalCount={focusedFeature?.properties.point_count ?? 0}
              assetsCountPerHealthStatus={
                focusedFeature.properties.assetsCountPerHealthStatus
              }
            />
          ) : (
            <AssetShortTooltip {...focusedFeature?.properties} />
          )}
        </div>
      )}
    </>
  );
};

export default memo(AssetsMarkers);
