import { Breakpoint, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 *
 * @param measureUnit = "up" | "down"
 * @param breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | number
 * @returns
 */
const useBreakpoint = (
  measureUnit: "up" | "down",
  breakpoint: Breakpoint | number
) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints[measureUnit](breakpoint));
};

export default useBreakpoint;
