import { useMemo } from "react";
import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import {
  Asset,
  AssetOs,
  FindAssetsByIdsForSharingQueryVariables,
  FindAssetsByIdsForSharingQuery,
  useFindAssetsByIdsForSharingOsQuery,
  useFindAssetsByIdsForSharingQuery,
  FindAssetsByIdsForSharingOsQuery,
  FindAssetsByIdsForSharingOsQueryVariables,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";
import { isNilOrEmpty } from "../../../views/AssetsView/utils";

interface IUseFindAssetsByIdsForSharingHookResult {
  data: Asset[]; // TODO: Replace it with AssetOS when the while implementation is done with PRJIND-9218
  isLoading: boolean;
  refetch: () => void;
}

type FindAssetsByIdsForSharingQueryOptions = UseQueryOptions<
  FindAssetsByIdsForSharingQuery,
  unknown,
  FindAssetsByIdsForSharingQueryVariables,
  QueryKey
> &
  UseQueryOptions<
    FindAssetsByIdsForSharingOsQuery,
    unknown,
    FindAssetsByIdsForSharingOsQueryVariables,
    QueryKey
  >;

export const useFindAssetsByIdsForSharing = (
  queryInput: FindAssetsByIdsForSharingQueryVariables,
  queryOptions?: FindAssetsByIdsForSharingQueryOptions
): IUseFindAssetsByIdsForSharingHookResult => {
  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const queryFn = fetchAssetsFromOpenSearchFeatureFlag
    ? useFindAssetsByIdsForSharingOsQuery
    : useFindAssetsByIdsForSharingQuery;

  const query = queryFn(queryInput, queryOptions);

  const assets = useMemo<Asset[] | null | undefined>(() => {
    const data = fetchAssetsFromOpenSearchFeatureFlag
      ? (query?.data as unknown as FindAssetsByIdsForSharingOsQuery)
          ?.findAssetsByIdsOS
      : (query?.data as unknown as FindAssetsByIdsForSharingQuery)
          ?.findAssetsByIds;

    if (isNilOrEmpty(data)) return [];

    if (fetchAssetsFromOpenSearchFeatureFlag) {
      return data.map((asset) =>
        mapAssetOsToAsset(asset as AssetOs)
      ) as Asset[];
    }

    return data as Asset[];
  }, [query.data, fetchAssetsFromOpenSearchFeatureFlag]);

  return {
    data: assets ?? [],
    isLoading: query.isFetching,
    refetch: query.refetch,
  };
};

const mapAssetOsToAsset = (input: AssetOs): Asset | null => {
  if (!input) {
    return null;
  }

  const { sub_asset_type, ...assetOs } = input;

  return {
    ...assetOs,
    subAssetType: sub_asset_type,
  } as Asset;
};
