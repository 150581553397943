import { useMemo } from "react";
import { formatDate } from "../../utils";
import { useCurrentOrg } from "./useCurrentOrg";

/**
 * Returns a file name for the given scope to append to the exported file
 * @param scope Scope of the exported file. E.g. "Assets", "Users", etc.
 */
export const useExportedFileName = (scope: string) => {
  const org = useCurrentOrg();
  const orgName = org?.name ?? "";
  const formattedDate = formatDate(new Date(), "MM-dd-yyyy");

  return useMemo(
    () => `${orgName} ${scope} - ${formattedDate}`.trim(),
    [scope, orgName, formattedDate]
  );
};
