import { uniqBy } from "lodash";
import { GeofenceData } from "../../../../graphql/operations";

export const useGeofenceNameOptions = (geofences: GeofenceData[]) => {
  const options = geofences
    .map((geofence) => ({
      id: geofence._id,
      label: geofence.geofence?.name ?? "",
    }))
    .sort((a, b) => {
      const aLabel = a.label.trim().toLowerCase();
      const bLabel = b.label.trim().toLowerCase();
      return aLabel.localeCompare(bLabel);
    });
  return uniqBy(options, (o) => o.label.trim());
};
