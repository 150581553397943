import { memo, SVGProps, FC } from "react";

interface LogoProps extends SVGProps<SVGSVGElement> {}

const Logo: FC<LogoProps> = (props) => {
  return (
    <svg
      width="76"
      height="49"
      viewBox="0 0 76 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.1209 16.3094H12.04C14.2494 16.3094 15.6454 15.431 16.3381 13.9184V20.8861H19.8408V15.7311H24.7312V20.8861H28.2339V6.17192H24.7312V11.8664H19.8408V6.17192H16.3381V8.54956C15.6203 7.05396 14.1562 6.17435 11.911 6.17435H6.31603V20.8861H10.1209V16.3094ZM10.1209 9.60778H11.2017C12.0078 9.60778 13.2331 9.79123 13.2331 11.1605C13.2331 12.4277 12.5882 12.8772 11.7009 12.8772H10.1209V9.60778Z"
        fill="currentColor"
      />
      <path
        d="M32.8162 6.13182H29.1272V20.8861H32.8162V6.13182Z"
        fill="currentColor"
      />
      <path
        d="M49.194 6.13182H45.505V20.8861H49.194V6.13182Z"
        fill="currentColor"
      />
      <path
        d="M44.6129 17.4478H37.3507V6.13182H33.6617V20.8861H44.6129V17.4478Z"
        fill="currentColor"
      />
      <path
        d="M44.589 13.4883H41.7109V6.13182H37.9323V16.7444H44.589V13.4883Z"
        fill="currentColor"
      />
      <path
        d="M53.8336 16.3094H55.7528C58.6082 16.3094 60.1058 14.8418 60.4784 12.4337C61.786 15.1819 66.0483 14.4554 66.0483 16.8294C66.0483 17.5195 65.4906 18.0869 64.5794 18.0869C64.2247 18.0869 63.7936 17.7831 63.4998 17.5401C63.1905 17.2972 62.9743 16.9108 62.8979 16.2474H59.855C59.9445 19.45 61.4182 21.0611 64.7824 21.0914C67.2139 21.1133 69.7755 19.721 69.7636 16.3337C69.7468 11.5651 63.9058 12.7423 63.9058 10.0962C63.9058 9.5689 64.2605 9.03675 65.0642 9.03675C65.4201 9.03675 65.7438 9.16311 66.0065 9.34656C66.2692 9.5288 66.4699 10.0148 66.532 10.3404H69.6788C69.6394 7.73919 68.0104 5.89734 64.9209 5.89734C62.6161 5.89734 60.8211 6.97256 60.2753 9.10843C59.6699 7.2763 58.1353 6.17677 55.6238 6.17677H50.0288V20.8885H53.8336V16.3094ZM53.8336 9.60778H54.9144C55.7205 9.60778 56.9458 9.79123 56.9458 11.1605C56.9458 12.4277 56.3009 12.8772 55.4136 12.8772H53.8336V9.60778Z"
        fill="currentColor"
      />
      <path
        d="M49.1128 23.9806H43.9155C43.6181 23.9806 43.378 24.2248 43.378 24.5273C43.378 24.8298 43.6181 25.074 43.9155 25.074H49.1128C49.4101 25.074 49.6502 24.8298 49.6502 24.5273C49.6502 24.2248 49.4101 23.9806 49.1128 23.9806Z"
        fill="currentColor"
      />
      <path
        d="M49.1128 27.8271H43.9155C43.6181 27.8271 43.378 28.0713 43.378 28.3738C43.378 28.6763 43.6181 28.9205 43.9155 28.9205H49.1128C49.4101 28.9205 49.6502 28.6763 49.6502 28.3738C49.6502 28.0713 49.4101 27.8271 49.1128 27.8271Z"
        fill="currentColor"
      />
      <path
        d="M49.1128 31.6784H43.9155C43.6181 31.6784 43.378 31.9226 43.378 32.2252C43.378 32.5277 43.6181 32.7719 43.9155 32.7719H49.1128C49.4101 32.7719 49.6502 32.5277 49.6502 32.2252C49.6502 31.9226 49.4101 31.6784 49.1128 31.6784Z"
        fill="currentColor"
      />
      <path
        d="M59.72 23.9806C59.4227 23.9806 59.1826 24.2248 59.1826 24.5273C59.1826 24.8298 59.4227 25.074 59.72 25.074H62.0918V32.2155C62.0918 32.518 62.3318 32.7622 62.6292 32.7622C62.9266 32.7622 63.1666 32.518 63.1666 32.2155V25.074H65.5407C65.8381 25.074 66.0782 24.8298 66.0782 24.5273C66.0782 24.2248 65.8381 23.9806 65.5407 23.9806H59.72Z"
        fill="currentColor"
      />
      <path
        d="M57.2659 30.7162C56.652 31.3395 55.8363 31.6821 54.9693 31.6821C53.1768 31.6821 51.7174 30.1986 51.7174 28.3738C51.7174 26.549 53.1756 25.0655 54.9693 25.0655C55.8316 25.0655 56.6449 25.4057 57.2575 26.0229C57.4677 26.2355 57.808 26.2343 58.017 26.0192C58.226 25.8042 58.2248 25.4591 58.0134 25.2465C57.1978 24.424 56.117 23.9721 54.9681 23.9721C52.582 23.9721 50.6414 25.9463 50.6414 28.3738C50.6414 30.8013 52.582 32.7755 54.9681 32.7755C56.1218 32.7755 57.2061 32.3187 58.023 31.4901C58.2332 31.2775 58.2332 30.9313 58.023 30.7174C57.814 30.5036 57.4725 30.5036 57.2635 30.7174L57.2659 30.7162Z"
        fill="currentColor"
      />
      <path
        d="M16.3859 30.7162C15.772 31.3395 14.9563 31.6821 14.0893 31.6821C12.2968 31.6821 10.8374 30.1986 10.8374 28.3738C10.8374 26.549 12.2956 25.0655 14.0893 25.0655C14.9516 25.0655 15.7648 25.4057 16.3775 26.0241C16.5889 26.2367 16.928 26.2355 17.137 26.0205C17.346 25.8054 17.3448 25.4604 17.1334 25.2477C16.3178 24.4252 15.237 23.9733 14.0881 23.9733C11.702 23.9733 9.76141 25.9476 9.76141 28.375C9.76141 30.8025 11.702 32.7768 14.0881 32.7768C15.2418 32.7768 16.3261 32.3199 17.143 31.4913C17.3532 31.2775 17.3532 30.9325 17.143 30.7186C16.934 30.5048 16.5937 30.5048 16.3835 30.7186L16.3859 30.7162Z"
        fill="currentColor"
      />
      <path
        d="M25.3044 25.2477C24.4888 24.4252 23.408 23.9733 22.2591 23.9733C19.873 23.9733 17.9324 25.9476 17.9324 28.375C17.9324 30.8025 19.873 32.7768 22.2591 32.7768C23.4128 32.7768 24.4971 32.3199 25.314 31.4913C25.9541 30.8426 26.3721 30.0225 26.5238 29.121C26.5644 28.8756 26.5859 28.6253 26.5859 28.375C26.5859 27.9984 26.5393 27.623 26.4461 27.2609C26.2515 26.5004 25.8562 25.8042 25.3044 25.2477ZM25.4645 28.9363C25.351 29.613 25.0369 30.229 24.5556 30.7162C23.9418 31.3395 23.1261 31.6821 22.2591 31.6821C20.4666 31.6821 19.0072 30.1986 19.0072 28.3738C19.0072 26.549 20.4654 25.0655 22.2591 25.0655C23.1214 25.0655 23.9346 25.4057 24.5473 26.0241C24.9617 26.442 25.2591 26.9657 25.4047 27.5367C25.474 27.8088 25.5098 28.0907 25.5098 28.3738C25.5098 28.5621 25.4943 28.7516 25.4633 28.9351L25.4645 28.9363Z"
        fill="currentColor"
      />
      <path
        d="M41.847 23.9806C41.5497 23.9806 41.3096 24.2248 41.3096 24.5273V30.6142L36.4276 24.1932C36.2867 24.0085 36.0466 23.9344 35.8293 24.0097C35.6119 24.085 35.465 24.2928 35.465 24.5273V32.2155C35.465 32.518 35.7051 32.7622 36.0024 32.7622C36.2998 32.7622 36.5398 32.518 36.5398 32.2155V26.1286L41.4219 32.5496C41.5258 32.6856 41.6834 32.7622 41.847 32.7622C41.9055 32.7622 41.9641 32.7525 42.0202 32.733C42.2375 32.6577 42.3844 32.4499 42.3844 32.2155V24.5273C42.3844 24.2248 42.1444 23.9806 41.847 23.9806Z"
        fill="currentColor"
      />
      <path
        d="M33.9388 23.9806C33.6414 23.9806 33.4014 24.2248 33.4014 24.5273V30.6142L28.5193 24.1932C28.3784 24.0085 28.1384 23.9344 27.921 24.0097C27.7037 24.085 27.5568 24.2928 27.5568 24.5273V32.2155C27.5568 32.518 27.7968 32.7622 28.0942 32.7622C28.3915 32.7622 28.6316 32.518 28.6316 32.2155V26.1286L33.5136 32.5496C33.6175 32.6856 33.7752 32.7622 33.9388 32.7622C33.9973 32.7622 34.0558 32.7525 34.1119 32.733C34.3293 32.6577 34.4762 32.4499 34.4762 32.2155V24.5273C34.4762 24.2248 34.2361 23.9806 33.9388 23.9806Z"
        fill="currentColor"
      />
      <path
        d="M69.0984 21.9346H6.31603V23.028H69.0984V21.9346Z"
        fill="currentColor"
      />
      <path
        d="M2.73212 0C1.36113 0 0.250488 1.1299 0.250488 2.52465V31.4367C0.250488 32.473 0.872687 33.4037 1.82091 33.7852L36.7799 47.8251C37.3651 48.0607 38.0171 48.0607 38.6023 47.8251L73.5612 33.7852C74.5083 33.4049 75.1317 32.473 75.1317 31.4367V2.52465C75.1317 1.13111 74.021 0 72.65 0H2.73212ZM71.906 30.9215L37.6911 44.6625L3.47493 30.9215V3.28035H71.906V30.9215Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Logo);
