export enum NavigationRoutes {
  Root = "/",
  Login = "/login",
  Home = "/home", // Note: Not sure whether we need this route
  Dashboard = "/dashboards",
  Reports = "/reports",
  Alerts = "/alerts",
  Assets = "/assets",
  MyAccount = "/my-account",
  Batches = "/batches",
  Devices = "/devices",
  AssetMap = "/assets/map",
  AssetTable = "/assets/table",
  AssetGallery = "/assets/gallery",
  Geofences = "/geofences",
  AdminPanel = "/admin-panel",
  TermsAndConditions = "/terms-and-conditions",
  Help = "/help",
  CustomLogin = "/custom-login",
  MfaVerifyAuthAppCode = "/mfa-verify-auth-app-code",
  MfaAuthAppSetup = "/mfa-auth-app-setup",
  MfaSmsVerification = "/mfa-sms-verification",
  NewUserPassword = "/new-password",
  ForgotPassword = "/forgot-password",
  ConfirmForgotPassword = "/confirm-forgot-password",
  // Admin Panel sub routes
  AdminPanelBrands = "/admin-panel/brands",
  AdminPanelConfigurations = "/admin-panel/configurations",
  AdminPanelSensors = "/admin-panel/sensors",
  AdminPanelOrganizations = "/admin-panel/organizations",
  AdminPanelProfiles = "/admin-panel/profiles",
  AdminPanelUsers = "/admin-panel/users",
  AdminPanelRegions = "/admin-panel/regions",
  AdminPanelZones = "/admin-panel/zones",
  AdminPanelAutomations = "/admin-panel/automations",

  // Assets sub routes
  Map = "map",
  Table = "table/*",
  Gallery = "gallery/*",
  SharedAssetInvitation = "invitations/:id",
}
