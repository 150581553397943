import { FC, MutableRefObject } from "react";
import { Circle } from "@mui/icons-material";
import { Box, Typography, Chip } from "@mui/material";
import { ReactComponent as CargoSensorDark } from "../../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLight } from "../../../../../../assets/svgs/cargoSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import SensorHeader from "../../../../../../shared/components/SensorHeader/SensorHeader";
import { useContainerDimensions } from "../../../../../../shared/hooks/useContainerDimensions";
import { calculateBarWidthBaseOnContainer } from "../DoorStatus/utils";
import {
  BAR_HEIGHT,
  CargoData,
  calculateCargoStatusInLocationsLast30Days,
  calculateCargoStatusLast30Days,
} from "./utils";

export interface CargoStatusInLocationsProps {
  data: Array<CargoData>;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

const CargoStatusInLocations: FC<CargoStatusInLocationsProps> = ({
  data,
  parentRef,
}) => {
  const {
    state: { theme },
  } = useAppContext();

  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };

  const {
    emptyCargoEventsInsideGeofence,
    emptyCargoEventsOutsideGeofence,
    loadedCargoEventsInsideGeofence,
    loadedCargoEventsOutsideGeofence,
  } = calculateCargoStatusInLocationsLast30Days(data);

  const { emptyCargoEvents, loadedCargoEvents } =
    calculateCargoStatusLast30Days(data);

  const { width: containerWidth } = useContainerDimensions(parentRef);

  const {
    barWidthA: emptyReferenceBarWidth,
    barWidthB: loadedReferenceBarWidth,
  } = calculateBarWidthBaseOnContainer(
    emptyCargoEvents,
    loadedCargoEvents,
    containerWidth
  );

  const totalEmptyEvents =
    emptyCargoEventsInsideGeofence + emptyCargoEventsOutsideGeofence;
  const totalLoadedEvents =
    loadedCargoEventsInsideGeofence + loadedCargoEventsOutsideGeofence;

  // For empty bar geofence split
  const emptyCargoEventsInsideGeofenceWidth =
    (emptyCargoEventsInsideGeofence / totalEmptyEvents) *
    emptyReferenceBarWidth;
  const emptyCargoEventsOutsideGeofenceWidth =
    (emptyCargoEventsOutsideGeofence / totalEmptyEvents) *
    emptyReferenceBarWidth;

  // For loaded bar geofence split
  const loadedCargoEventsInsideGeofenceWidth =
    (loadedCargoEventsInsideGeofence / totalLoadedEvents) *
    loadedReferenceBarWidth;
  const loadedCargoEventsOutsideGeofenceWidth =
    (loadedCargoEventsOutsideGeofence / totalLoadedEvents) *
    loadedReferenceBarWidth;

  const legendKeys: string[] = ["Inside Geofence", "Outside Geofence"];
  const legendLabels = ["Inside Geofence", "Outside Geofence"];
  const legendColors = ["var(--primary-blue)", "var(--indigo)"];
  return (
    <Box
      className="text-sm w-full p-4"
      data-testid="last-30-days-loaded-unloaded-in-locations"
    >
      <SensorHeader title="Cargo Sensor">
        {isLightTheme ? (
          <CargoSensorDark style={svgIconSettings} />
        ) : (
          <CargoSensorLight style={svgIconSettings} />
        )}
      </SensorHeader>

      <Typography
        variant="caption"
        className="!text-lg !font-semibold inline-block pb-4"
        data-testid="battery-widget-label"
      >
        Cargo Status in Locations Last 30 Days
      </Typography>

      <Box className="!mb-2 w-full flex flex-no-wrap">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text flex-shrink-0"
        >
          Empty
        </Typography>
        {emptyCargoEventsOutsideGeofence > 0 && (
          <Box
            data-testid="empty-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              flexBasis: `${emptyCargoEventsOutsideGeofenceWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {emptyCargoEventsOutsideGeofence}
          </Box>
        )}
        {emptyCargoEventsInsideGeofence > 0 && (
          <Box
            data-testid="empty-days-inside-geofence"
            className="rounded inline-block align-middle text-center"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              flexBasis: `${emptyCargoEventsInsideGeofenceWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {emptyCargoEventsInsideGeofence}
          </Box>
        )}
      </Box>
      <Box className="!mb-3 justify w-full flex flex-no-wrap">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Loaded
        </Typography>
        {loadedCargoEventsOutsideGeofence > 0 && (
          <Box
            data-testid="loaded-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              flexBasis: `${loadedCargoEventsOutsideGeofenceWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {loadedCargoEventsOutsideGeofence}
          </Box>
        )}
        {loadedCargoEventsInsideGeofence > 0 && (
          <Box
            data-testid="loaded-days-inside-geofence"
            className="rounded inline-block align-middle text-center"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              flexBasis: `${loadedCargoEventsInsideGeofenceWidth}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {loadedCargoEventsInsideGeofence}
          </Box>
        )}
      </Box>

      <Box className="justify gap-2">
        {legendKeys.map((key, index) => (
          <Chip
            key={key}
            label={legendLabels[index]}
            icon={
              <Circle
                sx={{
                  width: "16px",
                  fill: legendColors[index],
                }}
              />
            }
            className="!h-6 !mb-2 !mr-2 !rounded-full !text-white"
            sx={{
              color: "var(--mid-charcoal)",
              backgroundColor: "var(--grayscale-feather-gray)",
              "& .MuiChip-label": {
                color: "var(--mid-charcoal)",
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CargoStatusInLocations;
