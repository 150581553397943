import { FC, useState, useEffect, useMemo, useCallback } from "react";
import {
  useForm,
  FieldValues,
  AutocompleteElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import { v4 } from "uuid";
import * as yup from "yup";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useFindConfigurationSetByIdQuery,
  ConfigurationSetType,
  useGetConfigurationSetsQuery,
  ConfigurationSet,
  DetentionRuleClockStarts,
  DetentionRuleDayOfDrop,
  DetentionRuleEndOnEmpty,
  DetentionRuleEndOnLoaded,
  DetentionRuleCharge,
  useFindGeofencesByOrgIdsQuery,
  GeofenceData,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import { useFormTheme } from "../../../../../../shared/hooks/theme/useFormTheme";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import { checkOptionEquality } from "../../../../../../utils/checkOptionEquality";
import { useConfigurationSetsApi } from "../../../../hooks/useConfigurationSetsApi";
import {
  Option,
  DetentionRulesDrawers,
  MAX_DETENTION_RULES_PER_ORGANIZATION_LIMIT,
  ERROR_DETENTION_RULE_CONFIGURATION_SET_DUPLICATE,
} from "../../configurationsUtils";
import {
  FormInputs,
  CompanyOption,
  DetentionRuleChargeListItem,
  CreateOrEditDetentionRuleConfigDrawerProps,
} from "./interfaces";
import {
  filter,
  BREAKPOINTS,
  DEFAULT_VALUES,
  getOptionLabelEnum,
} from "./utils";
import { getDetentionRulesConfigSchema } from "./yup.schema";

const CreateOrEditDetentionRuleConfigDrawer: FC<
  CreateOrEditDetentionRuleConfigDrawerProps
> = ({
  isOpen,
  type,
  selectedConfigId,
  selectedOrgId,
  onClose,
  onRequestClose,
}) => {
  const isCreateDrawer = type === DetentionRulesDrawers.Create;
  const isEditDrawer = type === DetentionRulesDrawers.Edit;

  // State
  const [toggleDeleteDialogState, setToggleDeleteDialogState] =
    useState<boolean>(false);
  const [showDayOfDrop, setShowDayOfDrop] = useState<boolean>(
    DEFAULT_VALUES.clockStarts === DetentionRuleClockStarts.DayOfDrop
  );
  const [companyOptions, setCompanyOptions] = useState<CompanyOption[]>([]);
  const [allGeofenceTypes, setAllGeofenceTypes] = useState<ConfigurationSet[]>(
    []
  );
  const [geofenceSubTypeOptions, setGeofenceSubTypeOptions] = useState<
    Option[]
  >([]);
  // For some reason useFieldArray from react-hook-form isn't working properly
  const [currentDetentionCharges, setCurrentDetentionCharges] = useState<
    DetentionRuleChargeListItem[]
  >([{ day: 0, fee: 0, _id: v4() }]);
  const [initialDetentionCharges, setInitialDetentionCharges] = useState<
    DetentionRuleChargeListItem[]
  >(currentDetentionCharges);

  // Hooks
  const formTheme = useFormTheme();
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();

  // Queries
  const { data: dataConfigurations, isFetching: isFetchingConfigurations } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: selectedOrgId ?? "",
          type: ConfigurationSetType.DetentionRule,
          excludeDataFromParents: true,
        },
      },
      {
        enabled: Boolean(selectedOrgId),
      }
    );

  const { data: dataGeofenceTypes, isFetching: isFetchingGeofenceTypes } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: selectedOrgId ?? "",
          type: ConfigurationSetType.GeofenceType,
        },
      },
      { enabled: Boolean(selectedOrgId) }
    );

  const { data: dataGeofences, isFetching: isFetchingGeofences } =
    useFindGeofencesByOrgIdsQuery(
      { orgIds: selectedOrgId ?? [] },
      {
        enabled: Boolean(selectedOrgId),
      }
    );

  const {
    data: dataConfigurationSet,
    isFetching: isFetchingFindConfiguration,
    refetch,
  } = useFindConfigurationSetByIdQuery(
    {
      input: {
        entityId: selectedConfigId ?? "",
      },
    },
    {
      enabled: Boolean(selectedConfigId) && isEditDrawer,
    }
  );

  // Memos
  const headerText = useMemo(
    () => `${isCreateDrawer ? "Create" : "Edit"} Detention Rule`,
    [isCreateDrawer]
  );

  const parsedRules = useMemo(
    () =>
      dataConfigurations?.getConfigurationSets?.map((singleSet) => {
        const { _id } = singleSet;
        const parsedValue = singleSet?.value
          ? JSON.parse(singleSet?.value)
          : {};

        // Here we only use the company name so no need for the other data
        return {
          _id,
          company: parsedValue?.company,
        };
      }),
    [dataConfigurations]
  );

  const geofenceTypes: Option[] = useMemo(
    () =>
      dataGeofenceTypes?.getConfigurationSets?.map(
        (item: ConfigurationSet) => ({
          id: item._id,
          value: item._id,
          label: item.name,
        })
      ) ?? [],
    [dataGeofenceTypes]
  );

  const geofenceOptions: Option[] = useMemo(
    () =>
      dataGeofences?.findGeofencesByOrgIds?.map(
        (geofenceData: GeofenceData) => ({
          id: geofenceData?._id,
          value: geofenceData?._id,
          label: geofenceData?.geofence?.name ?? "Unknown Geofence",
        })
      ) ?? [],
    [dataGeofences]
  );

  const currentDetentionChargesHaveChanged = useMemo<boolean>(
    () =>
      JSON.stringify(currentDetentionCharges) !==
      JSON.stringify(initialDetentionCharges),
    [currentDetentionCharges, initialDetentionCharges]
  );

  // Form data
  const {
    control,
    trigger,
    getValues,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      yup
        .object()
        .shape(
          getDetentionRulesConfigSchema(
            isEditDrawer,
            (parsedRules ?? []).length
          )
        )
    ),
    defaultValues: DEFAULT_VALUES,
  });

  // Effects
  useEffect(() => {
    if (isOpen && isEditDrawer) refetch();
  }, [refetch, isOpen, isEditDrawer]);

  // Always set the new rule to the highest possible priority
  useEffect(() => {
    const highestPriority = (parsedRules ?? []).length;
    if (isCreateDrawer) setValue("priority", highestPriority + 1);
  }, [parsedRules, isCreateDrawer, setValue]);

  useEffect(() => {
    const configurationSet = dataConfigurationSet?.findConfigurationSetById;
    if (configurationSet?.value) {
      const parsedData = JSON.parse(configurationSet.value);
      const { name } = configurationSet;
      const {
        company,
        geofenceType,
        geofenceSubType,
        geofenceName,
        priority,
        clockStarts,
        dayOfDrop,
        endOnEmpty,
        endOnLoaded,
        detentionCharges,
      } = parsedData;

      const formValuesToSet = {
        name,
        company,
        geofenceType,
        geofenceSubType,
        geofenceName,
        priority,
        clockStarts,
        dayOfDrop,
        endOnEmpty,
        endOnLoaded,
      };
      const incomingDetentionCharges = detentionCharges.sort(
        (chargeA: DetentionRuleCharge, chargeB: DetentionRuleCharge) =>
          chargeA.day - chargeB.day
      );
      setCurrentDetentionCharges(incomingDetentionCharges);
      setInitialDetentionCharges(incomingDetentionCharges);
      setShowDayOfDrop(clockStarts === DetentionRuleClockStarts.DayOfDrop);
      reset(formValuesToSet);
    }
  }, [dataConfigurationSet, reset]);

  useEffect(() => {
    const _allGeofenceTypes = dataGeofenceTypes?.getConfigurationSets ?? [];
    setAllGeofenceTypes(_allGeofenceTypes);
    const currentGeofenceType = getValues("geofenceType");
    if (currentGeofenceType && !isEmpty(_allGeofenceTypes)) {
      const geofenceParentId = currentGeofenceType;
      setGeofenceSubTypeOptions(
        _allGeofenceTypes
          .filter(
            (geofence) =>
              geofence?.parentId?.toString() === geofenceParentId?.toString()
          )
          .map((item: ConfigurationSet) => ({
            label: item.name,
            id: item._id,
            value: item._id,
          }))
      );

      // Call reset to set the value again after options have been added
      setValue("geofenceSubType", getValues("geofenceSubType"));
    } else {
      setGeofenceSubTypeOptions([]);
    }
  }, [dataGeofenceTypes, setValue, getValues]);

  useEffect(() => {
    const parsedData =
      dataConfigurationSet?.findConfigurationSetById?.value &&
      JSON.parse(dataConfigurationSet?.findConfigurationSetById?.value);
    if (parsedData?.geofenceType) {
      const currentGeofenceType = parsedData?.geofenceType;
      if (currentGeofenceType && !isEmpty(allGeofenceTypes)) {
        const geofenceParentId = currentGeofenceType;
        setGeofenceSubTypeOptions(
          allGeofenceTypes
            .filter(
              (geofence) =>
                geofence?.parentId?.toString() === geofenceParentId?.toString()
            )
            .map((item: ConfigurationSet) => ({
              label: item.name,
              id: item._id,
              value: item._id,
            }))
        );

        // Call reset to set the value again after options have been added
        if (parsedData?.geofenceSubType)
          setValue("geofenceSubType", parsedData.geofenceSubType);
      } else {
        setGeofenceSubTypeOptions([]);
      }
    }
  }, [dataConfigurationSet, allGeofenceTypes, setValue]);

  useEffect(() => {
    setCompanyOptions(
      uniq(
        parsedRules
          ?.map((rule) => rule?.company)
          .filter((companyName) => Boolean(companyName))
      ).map((companyName) => ({
        id: `company-option-${companyName}`,
        value: companyName,
        label: companyName,
      }))
    );
  }, [parsedRules]);

  // Handlers
  const handleSuccessCreate = useCallback(() => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Detention Rule Configuration created!",
        text: "Your Geofence Detention Rule Configuration was created successfully.",
        severity: "success",
      },
    });

    onClose();
  }, [queryClient, dispatch, onClose]);

  const handleErrorCreate = useCallback(
    (error: Error) => {
      reset(DEFAULT_VALUES);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Failed to create Geofence Detention Rule Configuration!",
          text:
            error?.message === ERROR_DETENTION_RULE_CONFIGURATION_SET_DUPLICATE
              ? mapServerErrorCodeToHumanReadableMessage(error.message)
              : "An error occurred while creating your Geofence Detention Rule Configuration.",
        },
      });
    },
    [reset, dispatch]
  );

  const handleSuccessUpdate = useCallback(() => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Detention Rule Configuration updated!",
        text: "Your Geofence Detention Rule Configuration was updated successfully.",
        severity: "success",
      },
    });

    onClose();
  }, [queryClient, dispatch, onClose]);

  const handleErrorUpdate = useCallback(
    (error: Error) => {
      queryClient.invalidateQueries(["getConfigurationSets"]);

      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Geofence Detention Rule Configuration update failed!",
          text:
            error?.message === ERROR_DETENTION_RULE_CONFIGURATION_SET_DUPLICATE
              ? mapServerErrorCodeToHumanReadableMessage(error.message)
              : "An error occurred while updating your Geofence Detention Rule Configuration.",
          severity: "error",
        },
      });
    },
    [queryClient, dispatch]
  );

  const handleSuccessDelete = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Detention Rule Configuration deleted!",
        text: "Your Geofence Detention Rule Configuration was deleted successfully.",
        severity: "success",
      },
    });

    onClose();
    setToggleDeleteDialogState(false);
  };

  const handleErrorDelete = (error: Error) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Geofence Detention Rule Configuration deletion failed!",
        text: mapServerErrorCodeToHumanReadableMessage(error.message),
        severity: "error",
      },
    });

    onClose();
    setToggleDeleteDialogState(false);
  };

  const {
    isLoadingCreateConfigurationSet,
    createConfigurationSet,

    isLoadingUpdateConfigurationSet,
    updateConfigurationSet,

    isLoadingDeleteConfigurationSet,
    deleteConfigurationSet,
  } = useConfigurationSetsApi({
    createConfigurationSetOnSuccess: handleSuccessCreate,
    createConfigurationSetOnError: handleErrorCreate,

    updateConfigurationSetOnSuccess: handleSuccessUpdate,
    updateConfigurationSetOnError: handleErrorUpdate,

    deleteConfigurationSetOnSuccess: handleSuccessDelete,
    deleteConfigurationSetOnError: handleErrorDelete,
  });

  const isLoading =
    isFetchingConfigurations ||
    isFetchingFindConfiguration ||
    isFetchingGeofenceTypes ||
    isLoadingUpdateConfigurationSet ||
    isLoadingCreateConfigurationSet ||
    isLoadingDeleteConfigurationSet;

  const formHasNotBeenTouched = useMemo(
    () => !isDirty && !currentDetentionChargesHaveChanged,
    [isDirty, currentDetentionChargesHaveChanged]
  );
  const newRulesLimitReached = useMemo(
    () =>
      isCreateDrawer &&
      (parsedRules ?? []).length === MAX_DETENTION_RULES_PER_ORGANIZATION_LIMIT,
    [parsedRules, isCreateDrawer]
  );

  const isSubmitDisabled = useMemo(
    () =>
      isLoading ||
      formHasNotBeenTouched ||
      newRulesLimitReached ||
      currentDetentionCharges.some((charge) => charge.fee < 0),
    [
      formHasNotBeenTouched,
      newRulesLimitReached,
      currentDetentionCharges,
      isLoading,
    ]
  );

  const onSubmit = (data: FieldValues) => {
    const detentionCharges = cloneDeep(currentDetentionCharges).map(
      (charge, index) => ({
        day: index + 1,
        fee: charge.fee,
      })
    );

    const value = JSON.stringify({
      company: data.company,
      geofenceType: data.geofenceType,
      geofenceSubType: data.geofenceSubType,
      geofenceName: data.geofenceName,
      priority: data.priority, // TODO: [PRJIND-9046] Remove this field from the payload once reordering is in, it should only be triggered by the table reordering
      clockStarts: data.clockStarts,
      dayOfDrop:
        data.clockStarts === DetentionRuleClockStarts.DayOfDrop
          ? data?.dayOfDrop
          : null,
      endOnEmpty: data?.endOnEmpty,
      endOnLoaded: data?.endOnLoaded,
      detentionCharges,
    });

    if (isEditDrawer && dataConfigurationSet?.findConfigurationSetById) {
      const { _id } = dataConfigurationSet.findConfigurationSetById;

      updateConfigurationSet({
        _id,
        orgId: selectedOrgId,
        name: data.name,
        value,
      });
    }
    if (isCreateDrawer && selectedOrgId) {
      createConfigurationSet({
        type: ConfigurationSetType.DetentionRule,
        orgId: selectedOrgId,
        name: data.name,
        value,
      });
    }
  };

  const handleAddNewDay = () =>
    setCurrentDetentionCharges((prevCharges) => [
      ...prevCharges,
      { day: 0, fee: 0, _id: v4() },
    ]);

  const handleRemoveDetentionDay = (idToRemove: string) =>
    setCurrentDetentionCharges((prevCharges) =>
      prevCharges.filter(
        (charge) => charge._id.toString() !== idToRemove.toString()
      )
    );

  return (
    <Drawer
      testId="geofence-config-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={headerText} onClose={onClose} />
      <DrawerContent>
        <ThemeProvider theme={formTheme}>
          <form>
            <Grid container className="bg-background noTopPaddingDrawerSection">
              {isCreateDrawer &&
                (parsedRules ?? []).length ===
                  MAX_DETENTION_RULES_PER_ORGANIZATION_LIMIT && (
                  <Alert
                    severity="error"
                    sx={{ mt: "24px" }}
                    data-testid="detention-rules-create-drawer-limit-reached-alert"
                  >
                    You have reached the maximum amount of rules allowed{" "}
                    <b>(25)</b>. Please delete a rule before attempting to
                    create a new one.
                  </Alert>
                )}
              <Grid item {...BREAKPOINTS} data-testid="company-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading,
                    onChange: (_, newValue) => {
                      setValue("company", newValue?.value ?? null);
                      // Handle adding a new dropdown item
                      if (
                        newValue &&
                        !companyOptions.find(
                          (option) => option.id === newValue.id
                        )
                      ) {
                        const newOptionToAdd = {
                          ...omit<CompanyOption>(newValue, ["isNewValue"]),
                          label: newValue.value,
                        };
                        setCompanyOptions(
                          (prevOptions) =>
                            [...prevOptions, newOptionToAdd] as CompanyOption[]
                        );
                      }
                    },
                    filterOptions: (options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;

                      // Suggest the creation of a new company
                      const isExisting = Boolean(
                        options.find((option) => inputValue === option.company)
                      );
                      if (!isEmpty(inputValue) && !isExisting) {
                        const newOption = {
                          id: `company-option-${inputValue}-${v4()}`,
                          label: `Add "${inputValue}"`,
                          value: inputValue,
                          isNewOption: true,
                        };
                        filtered.push(newOption);
                      }

                      return filtered;
                    },
                    getOptionLabel: (option) => {
                      // Value selected with enter, right from the input
                      if (isString(option)) return option;

                      // Handle dynamically created options
                      if (option?.isNewOption) return option?.value;

                      // Regular option
                      return option.label;
                    },
                    renderOption: (props, option) => {
                      const { id } = option;
                      return (
                        <li {...props} key={id}>
                          {option.label}
                        </li>
                      );
                    },
                    isOptionEqualToValue: checkOptionEquality,
                    selectOnFocus: true,
                    clearOnBlur: true,
                    handleHomeEndKeys: true,
                  }}
                  loading={isFetchingConfigurations}
                  label={"Company"}
                  control={control}
                  name={"company"}
                  options={companyOptions}
                />
              </Grid>
              <Grid item {...BREAKPOINTS} data-testid="rule-name-control">
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={"name"}
                  required
                  label={"Detention Rule Name"}
                  disabled={isLoading}
                />
              </Grid>
              {isEditDrawer && (
                <Grid item {...BREAKPOINTS} data-testid="priority-control">
                  <TextFieldElement
                    fullWidth
                    type="number"
                    validation={{
                      min: 1,
                      max: isEditDrawer
                        ? (parsedRules ?? []).length
                        : undefined,
                    }}
                    control={control}
                    name="priority"
                    required
                    label={"Priority"}
                    disabled={isLoading}
                  />
                </Grid>
              )}
              <Grid item {...BREAKPOINTS} data-testid="geofenceType-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading,
                    onChange(_, newValue) {
                      setValue("geofenceType", newValue?.value ?? null);
                      if (newValue?.value) {
                        const geofenceParentId = newValue?.value;
                        setGeofenceSubTypeOptions(
                          allGeofenceTypes
                            .filter(
                              (geofence) =>
                                geofence?.parentId?.toString() ===
                                geofenceParentId?.toString()
                            )
                            .map((item: ConfigurationSet) => ({
                              label: item.name,
                              id: item._id,
                              value: item._id,
                            }))
                        );
                      } else {
                        setGeofenceSubTypeOptions([]);
                        setValue("geofenceSubType", null);
                      }
                    },
                    isOptionEqualToValue: checkOptionEquality,
                  }}
                  loading={isFetchingGeofenceTypes}
                  matchId={true}
                  label={"Geofence Type"}
                  control={control}
                  name={"geofenceType"}
                  options={geofenceTypes}
                />
              </Grid>
              <Grid item {...BREAKPOINTS} data-testid="geofenceSubType-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading,
                    isOptionEqualToValue: checkOptionEquality,
                    noOptionsText: getValues("geofenceType")
                      ? "No options"
                      : "Please choose a geofence type first",
                  }}
                  loading={isFetchingGeofenceTypes}
                  matchId={true}
                  label={"Geofence Sub Type"}
                  control={control}
                  name={"geofenceSubType"}
                  options={geofenceSubTypeOptions}
                />
              </Grid>
              <Grid item {...BREAKPOINTS} data-testid="geofenceName-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading,
                    isOptionEqualToValue: checkOptionEquality,
                  }}
                  loading={isFetchingGeofences}
                  matchId={true}
                  label={"Geofence Name"}
                  control={control}
                  name={"geofenceName"}
                  options={geofenceOptions}
                />
              </Grid>
              <Grid item {...BREAKPOINTS} data-testid="clockStarts-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading,
                    disableClearable: true,
                    onChange(_, newValue) {
                      setValue("clockStarts", newValue?.value ?? undefined);
                      if (
                        newValue?.value !== DetentionRuleClockStarts.DayOfDrop
                      ) {
                        setShowDayOfDrop(false);
                      } else {
                        setShowDayOfDrop(true);
                        setValue("dayOfDrop", DetentionRuleDayOfDrop.EntryTime);
                      }
                    },
                    getOptionLabel: getOptionLabelEnum,
                    isOptionEqualToValue: checkOptionEquality,
                  }}
                  label={"Clock Starts"}
                  control={control}
                  required
                  name={"clockStarts"}
                  options={Object.values(DetentionRuleClockStarts).map(
                    (option) => ({
                      id: option,
                      value: option,
                      label: option,
                    })
                  )}
                />
              </Grid>
              {showDayOfDrop && (
                <Grid item {...BREAKPOINTS} data-testid="dayOfDrop-control">
                  <AutocompleteElement
                    autocompleteProps={{
                      disabled: isLoading,
                      disableClearable: true,
                      onChange(_, newValue) {
                        setValue("dayOfDrop", newValue?.value ?? undefined);
                      },
                      getOptionLabel: getOptionLabelEnum,
                      isOptionEqualToValue: checkOptionEquality,
                    }}
                    label="Day Of Drop"
                    control={control}
                    required
                    name="dayOfDrop"
                    options={Object.values(DetentionRuleDayOfDrop).map(
                      (option) => ({
                        id: option,
                        value: option,
                        label: option,
                      })
                    )}
                  />
                </Grid>
              )}
              <Grid item {...BREAKPOINTS} data-testid="endOnEmpty-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading,
                    onChange(_, newValue) {
                      setValue("endOnEmpty", newValue?.value ?? undefined);
                    },
                    getOptionLabel: getOptionLabelEnum,
                    isOptionEqualToValue: checkOptionEquality,
                  }}
                  label={"End on Empty"}
                  control={control}
                  name={"endOnEmpty"}
                  options={Object.values(DetentionRuleEndOnEmpty).map(
                    (option) => ({
                      id: option,
                      value: option,
                      label: option,
                    })
                  )}
                />
              </Grid>
              <Grid item {...BREAKPOINTS} data-testid="endOnLoaded-control">
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading,
                    onChange(_, newValue) {
                      setValue("endOnLoaded", newValue?.value ?? undefined);
                    },
                    getOptionLabel: getOptionLabelEnum,
                    isOptionEqualToValue: checkOptionEquality,
                  }}
                  label={"End on Loaded"}
                  control={control}
                  name="endOnLoaded"
                  options={Object.values(DetentionRuleEndOnLoaded).map(
                    (option) => ({
                      id: option,
                      value: option,
                      label: option,
                    })
                  )}
                />
              </Grid>
              <Accordion
                defaultExpanded
                sx={{ width: "100%" }}
                className="!px-0 !py-0 !mt-3"
              >
                <AccordionSummary className="!p-0">
                  Detention Charges
                  <Typography
                    component="span"
                    sx={{ color: "red", fontWeight: 600 }}
                  >
                    *
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="!p-0">
                  <Grid
                    item
                    {...BREAKPOINTS}
                    data-testid="detentionCharges-list"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      pt: "0 !important",
                    }}
                  >
                    <Button
                      sx={{
                        color: `${
                          isLoading ? "var(--concrete)" : "var(--brand)"
                        }`,
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: "700",
                        alignSelf: "flex-end",
                      }}
                      data-testid="add-widget-button"
                      disabled={isLoading}
                      variant="text"
                      type="button"
                      onClick={() => handleAddNewDay()}
                    >
                      <AddIcon />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: `${
                            isLoading ? "var(--concrete)" : "var(--brand)"
                          } !important`,
                        }}
                      >
                        Add New Day
                      </Typography>
                    </Button>
                    <Grid className="!pt-3">
                      {currentDetentionCharges.map((charge, index) => (
                        <Grid
                          key={charge._id}
                          item
                          {...BREAKPOINTS}
                          data-testid={`detention-charge-day-${charge._id}-control`}
                        >
                          <TextField
                            fullWidth
                            id={`detention-charge-${charge._id}-fee`}
                            label={`Day ${index + 1}`}
                            type="number"
                            placeholder="Fee for day (USD)"
                            value={charge.fee}
                            disabled={isLoading}
                            inputProps={{
                              min: 0,
                            }}
                            helperText={
                              charge.fee < 0
                                ? "The fee value cannot be less than 0."
                                : undefined
                            }
                            error={charge.fee < 0}
                            onChange={({ target: { value } }) =>
                              setCurrentDetentionCharges((prevCharges) =>
                                prevCharges.map((_charge) =>
                                  _charge._id.toString() ===
                                  charge._id.toString()
                                    ? {
                                        ..._charge,
                                        fee: value ? parseFloat(value) : 0,
                                      }
                                    : _charge
                                )
                              )
                            }
                            InputProps={{
                              endAdornment:
                                index === 0 ? null : (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="Remove Detention Day"
                                      onClick={() =>
                                        handleRemoveDetentionDay(charge._id)
                                      }
                                      edge="end"
                                    >
                                      <DeleteIcon
                                        sx={{
                                          ":hover": { color: "red" },
                                          transition: "all .15s ease-out",
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </form>
        </ThemeProvider>
        <DrawerActions
          cancelBtnTestId="btn-Brand-form-cancel"
          deleteBtnTestId="action-delete-config"
          disabled={isLoading ?? isFetchingFindConfiguration}
          showDeleteBtn={isEditDrawer}
          onDelete={() => setToggleDeleteDialogState(true)}
          onCancel={onClose}
        />

        {toggleDeleteDialogState && (
          <ConfirmationDialog
            title="You are about to delete a Configuration"
            message="Are you sure?"
            open={toggleDeleteDialogState}
            isLoading={isLoadingDeleteConfigurationSet}
            confirmButtonText="Delete Configuration"
            cancelButtonText="Cancel"
            handleConfirmationResult={(value) => {
              if (value) {
                deleteConfigurationSet({
                  entityId: selectedConfigId ?? "",
                });
              } else {
                setToggleDeleteDialogState(false);
              }
            }}
          />
        )}
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingUpdateConfigurationSet ||
          isLoadingCreateConfigurationSet ||
          isLoadingDeleteConfigurationSet
            ? "Saving..."
            : "Save"
        }
        disabled={isSubmitDisabled}
        testId="btn-detention-rule-form-submit"
        submit={async () => {
          const triggerResult = await trigger();
          if (triggerResult) onSubmit(getValues());
        }}
      />
    </Drawer>
  );
};

export default CreateOrEditDetentionRuleConfigDrawer;
