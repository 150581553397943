import { FC, HTMLAttributes, useEffect, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { isEmpty } from "lodash";
import {
  ConfigurationSet,
  ConfigurationSetType,
  ProductData,
  useFindOrgsQuery,
  useGetConfigurationSetsQuery,
} from "../../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../../shared/components/ConfirmationDialog/ConfirmationDialog";
import Drawer from "../../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../../shared/components/Drawer/DrawerHeader";
import { useFormTheme } from "../../../../../../../shared/hooks/theme/useFormTheme";
import { useSettingsTheme } from "../../../../../../AssetsView/MapView/hooks/useSettingsTheme";
import { useOrgsOptions } from "../../../../../../AssetsView/TableView/hooks";
import { DrawerType, ProductDrawers } from "../../../configurationsUtils";
import { useProductForm } from "./useProductForm";

interface OptionType {
  label: string;
  id: string;
}

const initFormValue = {
  _id: "",
  asset_configuration: "",
  organization_name: "",
  product_code: "",
  product_name: "",
} as ProductData;

export interface ProductFormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onChange" | "onSubmit"> {
  onSave?: (data: FieldValues) => any;
  product?: ProductData;
  onChange?: (product?: Partial<ProductData> | undefined) => any;
  isOpen?: boolean;
  setOpen?: (isOpen: boolean) => any;
  disableAllFields?: boolean;
  onRemove: (configurationSetId: string) => any;
  onCancel: () => void;
  drawerType?: DrawerType;
  isLoadingFindConfiguration: boolean;
  isLoadingConfigurationSet: boolean;
}

export const ProductForm: FC<ProductFormProps> = ({
  onSave,
  product,
  onChange,
  isOpen,
  setOpen,
  onRemove,
  onCancel,
  drawerType,
  isLoadingFindConfiguration,
  isLoadingConfigurationSet,
  disableAllFields = false,
  ...props
}) => {
  const formTheme = useFormTheme();
  const breakpoints = { xs: 12 };
  const [currentOrg, setCurrentOrg] = useState<string>("");
  const { form, getValues } = useProductForm(product ? product : initFormValue);

  const isEditDrawer = drawerType === ProductDrawers.Edit;

  const isLoading = isLoadingFindConfiguration || isLoadingConfigurationSet;

  const isSubmitDisabled =
    isLoading || disableAllFields || isEmpty(form.formState.dirtyFields);

  const { data: organizationsGQLData, isFetching: isFetchingOrgs } =
    useFindOrgsQuery({});
  const theme = useSettingsTheme();
  const [toggleDeleteDialogState, setToggleDeleteDialogState] =
    useState<boolean>(false);

  useEffect(() => {
    if (product && product?.organization_name) {
      setCurrentOrg(product?.organization_name);
    }
  }, [product]);

  const {
    data: assetConfigurationsGQLData,
    isFetching: isFetchingAssetConfigurations,
  } = useGetConfigurationSetsQuery(
    {
      input: {
        orgId: currentOrg,
        type: ConfigurationSetType.AssetType,
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const findOrgsData = useMemo(
    () => organizationsGQLData?.findOrgs || [],
    [organizationsGQLData?.findOrgs]
  );
  const organizations = useOrgsOptions(findOrgsData);

  const assetConfigs = useMemo<OptionType[]>(
    () =>
      (
        assetConfigurationsGQLData?.getConfigurationSets as ConfigurationSet[]
      )?.map((x, i) => {
        return { id: x._id, label: x.name, key: i };
      }) || [],
    [assetConfigurationsGQLData?.getConfigurationSets]
  );

  const handleClose = () => {
    form.reset(form.formState.defaultValues);
    onCancel();
  };

  const headerText = `${isEditDrawer ? "Edit" : "Create"} Configuration`;

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        testId="product-sensor-drawer"
        isOpen={isOpen}
        onRequestClose={handleClose}
      >
        <DrawerHeader text={headerText} onClose={onCancel} />

        <DrawerContent>
          <Grid container className="w-full justify-center bg-background">
            <ThemeProvider theme={formTheme}>
              <form>
                <Grid
                  container
                  className="bg-background noTopPaddingDrawerSection"
                >
                  <Grid
                    item
                    {...breakpoints}
                    data-testid="organization-name-autocomplete-container"
                  >
                    <AutocompleteElement
                      autocompleteProps={{
                        disabled: isLoading || disableAllFields,
                        readOnly: disableAllFields,
                        onChange: (event, newInputValue) => {
                          setCurrentOrg(newInputValue?.id);
                        },
                      }}
                      rules={{ required: true }}
                      matchId={true}
                      label={"Organization Name"}
                      control={form.control}
                      name="organization_name"
                      options={organizations}
                      data-testid="organization-name-autocomplete"
                      loading={isFetchingOrgs}
                    />
                  </Grid>
                  <Grid item {...breakpoints}>
                    <TextFieldElement
                      data-testid="product-code-text-field"
                      fullWidth
                      disabled={isLoading || disableAllFields}
                      control={form.control}
                      name="product_code"
                      required
                      label="Code"
                    />
                  </Grid>
                  <Grid item {...breakpoints}>
                    <TextFieldElement
                      data-testid="product-name-text-field"
                      fullWidth
                      disabled={isLoading || disableAllFields}
                      control={form.control}
                      name="product_name"
                      required
                      label="Name"
                      className="bg-background !text-2xl"
                    />
                  </Grid>
                  <Grid item {...breakpoints}>
                    <AutocompleteElement
                      autocompleteProps={{
                        disabled: isLoading || disableAllFields,
                        readOnly: disableAllFields,
                      }}
                      rules={{ required: true }}
                      matchId={true}
                      label={"Asset Configuration"}
                      control={form.control}
                      name="asset_configuration"
                      options={assetConfigs}
                      loading={isFetchingAssetConfigurations}
                    />
                  </Grid>
                </Grid>
              </form>
            </ThemeProvider>
          </Grid>

          <DrawerActions
            deleteBtnTestId="remove-product-submit"
            cancelBtnTestId="cancel-product-submit"
            showDeleteBtn={isEditDrawer}
            disabled={isLoading}
            onDelete={() => {
              setToggleDeleteDialogState(true);
            }}
            onCancel={() => {
              form.reset(form.formState.defaultValues);
              onCancel();
            }}
          />

          {toggleDeleteDialogState && (
            <ConfirmationDialog
              title="You are about to delete a Configuration"
              message="Are you sure?"
              open={toggleDeleteDialogState}
              isLoading={isLoadingConfigurationSet}
              confirmButtonText="Delete Configuration"
              cancelButtonText="Cancel"
              handleConfirmationResult={(value) => {
                if (value) {
                  onRemove(product?._id!);
                  form.reset(form.formState.defaultValues);
                } else {
                  setToggleDeleteDialogState(false);
                }
              }}
            />
          )}
        </DrawerContent>

        <DrawerFooter
          text={isLoadingConfigurationSet ? "Saving..." : "Save"}
          disabled={isSubmitDisabled}
          testId="add-product-submit"
          submit={() => {
            form.handleSubmit((e) => {
              if (!form.formState.isValid || !form.formState.isDirty) {
                onSave?.(getValues());
              }
            })();
          }}
        />
      </Drawer>
    </ThemeProvider>
  );
};
