import { FC } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";

const InternalCameraPercentageFilter: FC = () => {
  const { onChangeFilters, currentFilter } = useAssetsDataContext();

  const min = 0;
  const max = 100;
  return (
    <Box className="flex items-center justify-between h-8 text-white py-[5px] rounded-[4px]">
      <Box className={`flex grow basis-1/2 items-center mr-2 justify-between	`}>
        <Typography className="!text-md text-primary text-typography ">
          Floor Space (%)
        </Typography>
        <Typography className="!text-md text-primary text-typography ">
          {">="}
        </Typography>
      </Box>
      <Box className={`flex items-center grow `}>
        <TextField
          style={{ width: 70 }}
          id="floor-space-percentage"
          value={currentFilter.internalCameraFloorUsagePercentage ?? 0}
          type="number"
          InputProps={{
            inputProps: { max, min, "data-testid": "floor-space-percentage" },
          }}
          InputLabelProps={{
            shrink: true,
            className: "!text-typography",
          }}
          onChange={(e: any) => {
            let value = parseInt(e.target.value, 10);

            if (value > max) value = max;
            if (value < min) value = min;

            onChangeFilters({
              internalCameraFloorUsagePercentage: value,
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default InternalCameraPercentageFilter;
