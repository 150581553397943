export function serializeDwellTime(dwellingDays?: number) {
  if (!dwellingDays || dwellingDays < 0) return "0 days";
  else if (dwellingDays === 1) return "1 day";
  else return `${dwellingDays} days`;
}

const isNAValue = (value?: unknown) => {
  const lowerCaseValue = typeof value === "string" && value?.toLowerCase();
  return lowerCaseValue === "na" || lowerCaseValue === "n/a";
};

export const stripNAValue = <T>(value?: T) => (isNAValue(value) ? "" : value);
