import { FC, useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ArrowPrevIcon from "@mui/icons-material/ArrowBack";
import ArrowNextIcon from "@mui/icons-material/ArrowForward";
import { Button, Box, Typography } from "@mui/material";
import { useInternalCameraSensorContext } from "../context";
import { paginateMediaActivityItems } from "../helpers";
import { MediaActivityItem } from "../interface";
import CarouselItem from "./CarouselItem";

export interface InternalCameraStatusCarouselProps {
  items: MediaActivityItem[];
}

const Carousel: FC<InternalCameraStatusCarouselProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // We need to know the active view in order to paginate the items (one image, four images, etc.)
  const { activeView, activeMediaActivityItems, setActiveMediaActivityItems } =
    useInternalCameraSensorContext();
  const paginatedItems = paginateMediaActivityItems(items, activeView);

  // We need to keep track of the current index in order to disable the prev/next buttons
  const handleCarouselChange = (index: number | undefined) => {
    if (index === undefined) {
      return;
    }

    setCurrentIndex(index);
  };

  // Trigger redrawing of the carousel when the active view changes, so it will recalculate the height
  useEffect(() => {
    // If the current index is greater than the number of items, set it to the last item
    // Note: This may happen when the user changes the active view to a larger number of items
    let nextPage = currentIndex;
    if (currentIndex >= paginatedItems.length) {
      nextPage = paginatedItems.length - 1;
    }
    // Simulate changing the index to 0, so the carousel will redraw
    setCurrentIndex(1);

    // And on the next tick, set the index back to the original value
    setTimeout(() => {
      setCurrentIndex(nextPage);
    }, 2000);
  }, [activeView, setCurrentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the active selection in the context when the current index changes
  useEffect(() => {
    const activeItems = paginatedItems[currentIndex] ?? [];

    // Compare the active items to the current active items, and only update the context if they are different
    if (activeItems.length === activeMediaActivityItems.length) {
      const activeItemsAreEqual = activeItems.every((item, i) => {
        const activeItem = activeMediaActivityItems[i];

        return item.imageUrl === activeItem.imageUrl;
      });

      // If the active items are the same, don't update the context
      if (activeItemsAreEqual) {
        return;
      }
    }

    // Well, the active items are different, so update the context
    setActiveMediaActivityItems(activeItems);
  }, [
    activeView,
    currentIndex,
    paginatedItems,
    activeMediaActivityItems,
    setActiveMediaActivityItems,
  ]);
  return (
    <Box
      className="w-full overflow-hidden"
      data-testid="internal-camera-status--carousel"
    >
      {paginatedItems.length === 0 && (
        <Box className="flex justify-center items-center h-32 text-card__typography text-center">
          There is no such data recorded for this asset.
        </Box>
      )}
      <AliceCarousel
        onSlideChanged={({ item }) => handleCarouselChange(item)}
        renderDotsItem={(e) => (
          <svg
            viewBox="0 0 24 24"
            fill={e.isActive ? "var(--primary-blue)" : "var(--concrete)"}
            width={16}
            height={16}
            style={{ cursor: "pointer" }}
          >
            <circle cx="12" cy="12" r="8"></circle>
          </svg>
        )}
        renderNextButton={({ isDisabled }) => (
          <Button
            sx={{
              display: "flex",
              position: "absolute",
              right: "0",
              bottom: "10px",
              transform: "translateY(-50%)",
              color: isDisabled
                ? "var(--light-charcoal) !important"
                : "var(--primary-blue)",
            }}
            disabled={isDisabled}
          >
            {
              <>
                <Typography
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    textTransform: "none",
                  }}
                >
                  Next
                </Typography>
                <ArrowNextIcon className="ml-2" />
              </>
            }
          </Button>
        )}
        renderPrevButton={({ isDisabled }) => (
          <Button
            sx={{
              display: "flex",
              position: "absolute",
              left: "0",
              bottom: "10px",
              transform: "translateY(-50%)",
              color: isDisabled
                ? "var(--light-charcoal) !important"
                : "var(--primary-blue)",
            }}
            disabled={isDisabled}
          >
            {
              <>
                <ArrowPrevIcon className="mr-2" />
                <Typography
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    textTransform: "none",
                  }}
                >
                  Prev
                </Typography>
              </>
            }
          </Button>
        )}
        autoHeight={true}
        activeIndex={currentIndex}
      >
        {paginatedItems.map((items, i) => (
          <CarouselItem key={i} items={items} />
        ))}
      </AliceCarousel>
    </Box>
  );
};

export default Carousel;
