import { useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import {
  AutocompleteOption,
  FormFieldDropdownOption,
} from "../../../../../../../types/form";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../../../../AssetsView/TableView/hooks";

type MapFaultCodeOptionLabelToValue = (
  faultCodeOption?: string
) => string | undefined;

interface FaultCodeTypeOptionsHook {
  faultCodeOptions: AutocompleteOption[];
  mapFaultCodeOptionValueToLabel: MapFaultCodeOptionLabelToValue;
}

export const useFaultCodeTypeOptions = (): FaultCodeTypeOptionsHook => {
  const [faultCodeOptionsState, setFaultCodeOptionsState] = useState<
    (AutocompleteOption & FormFieldDropdownOption)[]
  >([]);

  const faultCodeOptions = useNomenclatures(NOMENCLATURE_NAMES.faultCodeType);

  useEffect(() => {
    // isEqual is used to prevent endless rerender loops
    if (!isEqual(faultCodeOptions, faultCodeOptionsState)) {
      setFaultCodeOptionsState(faultCodeOptions);
    }
  }, [faultCodeOptions, faultCodeOptionsState]);

  const faultCodeOptionsMap = useMemo(() => {
    return Object.fromEntries(
      faultCodeOptionsState.map((faultCodeOption) => [
        faultCodeOption.value,
        faultCodeOption.label,
      ])
    );
  }, [faultCodeOptionsState]);

  const mapFaultCodeOptionValueToLabel =
    useCallback<MapFaultCodeOptionLabelToValue>(
      (faultCodeOption) => faultCodeOptionsMap[faultCodeOption ?? ""],
      [faultCodeOptionsMap]
    );

  return {
    faultCodeOptions: faultCodeOptionsState,
    mapFaultCodeOptionValueToLabel,
  };
};
