import { useEffect } from "react";

interface GoogleMapsFullscreenProps {
  mapInstance: google.maps.Map | null;
  isMapFullscreen: boolean;
  setIsMapFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useGoogleMapFullscreen = ({
  mapInstance,
  isMapFullscreen,
  setIsMapFullscreen,
}: GoogleMapsFullscreenProps) => {
  useEffect(() => {
    const handleFullscreenChange = () => {
      // Update state based on the current fullscreen state
      setIsMapFullscreen(document.fullscreenElement !== null);
    };

    // Add event listener for fullscreenchange to detect changes in fullscreen mode
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [setIsMapFullscreen]);

  const toggleFullscreen = () => {
    if (mapInstance) {
      const mapContainer = mapInstance.getDiv();
      if (!mapContainer) return;

      if (!isMapFullscreen) {
        if (mapContainer.requestFullscreen) {
          mapContainer.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
      setIsMapFullscreen(!isMapFullscreen);
    }
  };

  return {
    toggleFullscreen,
  };
};

export default useGoogleMapFullscreen;
