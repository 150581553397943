import { useEffect, useRef, useState } from "react";
import RangeSlider from "../../../../../../../../shared/components/RangeSlider/RangeSlider";
import { sliderMarks } from "../../../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";

const minAssetYear = 1900;
const maxAssetYear = new Date().getFullYear();

const AssetYearSlider: React.FC = () => {
  const {
    currentFilter: { assetYear },
    onChangeFilters,
  } = useAssetsDataContext();

  const defaultValues = useRef<number[]>([
    assetYear?.startValue ?? minAssetYear,
    assetYear?.endValue ?? maxAssetYear,
  ]);

  const [values, setValues] = useState<number[]>(defaultValues.current);

  useEffect(() => {
    const incomingValue = [
      assetYear?.startValue ?? minAssetYear,
      assetYear?.endValue ?? maxAssetYear,
    ];
    if (JSON.stringify(incomingValue) !== JSON.stringify(values)) {
      setValues(incomingValue);
    }
    // We only want to trigger this effect when the value in store is different than our local
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [assetYear]);

  const onValueChanged = (values: number | number[]) => {
    if (Array.isArray(values)) {
      // call onChange event to update filter values in asset context
      const [startValue, endValue] = values;
      onChangeFilters({ assetYear: { startValue, endValue } });
    }
  };

  return (
    <RangeSlider
      min={minAssetYear}
      max={maxAssetYear}
      values={values}
      defaultValues={defaultValues.current}
      setValues={setValues}
      marks={sliderMarks(minAssetYear, maxAssetYear, "year")}
      marksMargin={{ left: "1.5rem", right: "-1.5rem" }}
      onChange={onValueChanged}
      shouldFormatSliderNumberLabel={false}
    />
  );
};

export default AssetYearSlider;
