import { Box, Skeleton } from "@mui/material";

export const LineChartLoader = () => {
  const horizontalLine = "2px";

  const dotSize = 10;

  return (
    <Box
      data-testid="loading-overlay-container"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Skeleton
        variant="rectangular"
        data-testid="overlay-horizontal-line"
        sx={{
          position: "absolute",
          bottom: "50%",
          transform: "50%",
          width: "90%",
          height: horizontalLine,
        }}
      />

      {[12, 25, 45, 70, 90].map((position, index) => (
        <Skeleton
          key={`circle-${index}`}
          variant="circular"
          width={dotSize}
          height={dotSize}
          sx={{
            position: "absolute",
            left: `${position}%`,
            bottom: "50%",
            transform: "translateY(50%)",
          }}
        />
      ))}
    </Box>
  );
};
