import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../hooks/theme/useCurrentTheme";

export const useDateTextFieldTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiInputBase: {
          styleOverrides: {
            root: { alignItems: "baseline" },
          },
        },
      },
    })
  );
};
