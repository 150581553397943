import { useUniqueOptions } from "./useUniqueOptions";

export const useGeofenceRestrictionsOptions = (
  assetTypes: string[],
  assetLengths: string[],
  assetDoorTypes: string[]
) => {
  const assetTypesOptions = useUniqueOptions(
    assetTypes.map((assetType) => ({ assetType })),
    "assetType",
    "assetType"
  );

  const assetLengthsOptions = useUniqueOptions(
    assetLengths.map((assetLength) => ({ assetLength })),
    "assetLength",
    "assetLength"
  );

  const assetDoorTypesOptions = useUniqueOptions(
    assetDoorTypes.map((assetDoorType) => ({ assetDoorType })),
    "assetDoorType",
    "assetDoorType"
  );

  return {
    assetTypesOptions: assetTypesOptions.map((option) => ({
      ...option,
      visible: false,
      selected: false,
    })),
    assetLengthsOptions: assetLengthsOptions.map((option) => ({
      ...option,
      visible: false,
      selected: false,
    })),
    assetDoorTypesOptions: assetDoorTypesOptions.map((option) => ({
      ...option,
      visible: false,
      selected: false,
    })),
  };
};
