import {
  Box,
  Card,
  CardHeader,
  Grid,
  Typography,
  CardContent,
} from "@mui/material";
import { useDateInPreferredTimezone } from "../../../../../../shared/hooks/useDateInPreferredTimezone";
import { AssetSensorState } from "../../AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { useGetSensorStateWidget } from "../../AssetShortTooltip/hooks/useGetSensorStateWidget";

type LightCardViewItemProps = {
  label: string;
  deviation: number;
  status: string;
  lastReportedDate: string;
};
const LightCardViewItem = ({
  label,
  deviation,
  status,
  lastReportedDate,
}: LightCardViewItemProps) => {
  const { getTireSensorChip } = useGetSensorStateWidget();
  const lightStatusIcon = getTireSensorChip(status as AssetSensorState, {
    iconWidth: "24px",
    iconHeight: "24px",
  }).icon;

  const timezoneDate = useDateInPreferredTimezone(lastReportedDate);

  return (
    <Grid
      item
      lg={4}
      xl={4}
      sm={6}
      xs={12}
      key={`asset-dashboard--lights-tab--lights-circuit-widget-card`}
    >
      <Box
        className="w-full p-2"
        sx={{ height: "100%" }}
        data-testid="light-sensors-tab-panel--view-item"
      >
        <Grid container className="h-full w-full">
          <Card
            className={`background-white border  cursor-pointer`}
            sx={{
              boxShadow: "none",
              borderRadius: "8px",
              //height: "auto",
              fontFamily: "Montserrat",
              borderColor: "var(--asset-inner-card--border)",
              height: "100%",
            }}
            data-testid="asset-dashboard--lights-tab--lights-circuit-widget--card-summary"
          >
            <CardHeader
              className="!pb-0"
              title={
                <div className="flex">
                  <div className="">
                    <Typography
                      className="text-brand !pb-0"
                      sx={{
                        fontSize: "24px",
                        lineHeight: "24px",
                        fontWeight: 600,
                        paddingBottom: "0px",
                      }}
                    >
                      {label}
                    </Typography>
                  </div>
                </div>
              }
            ></CardHeader>
            <CardContent className="!pb-0 ">
              <Typography
                className="text-card__typography "
                sx={{ marginBottom: "5px", textTransform: "capitalize" }}
              >
                {status}
                <span className="ml-2">{lightStatusIcon}</span>
              </Typography>
              <Typography
                className="text-base text-card__typography "
                sx={{ marginBottom: "5px" }}
              >
                Current Deviation:
                <span className="ml-1 font-bold">{deviation}%</span>
              </Typography>
              <Typography
                className="text-base text-card__typography "
                sx={{ marginBottom: "5px" }}
              >
                Last Reported:
                <span className="ml-1">{timezoneDate}</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </Grid>
  );
};

export default LightCardViewItem;
