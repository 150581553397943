import {
  GetAssetsManufacturersInput,
  useGetAssetsManufacturersOsQuery,
  useGetAssetsManufacturersQuery,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";

export const useGetAssetsManufacturers = (
  input: GetAssetsManufacturersInput
): {
  assetManufacturers: string[];
  isLoading: boolean;
} => {
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const { isLoading: isLoadingManufacturers, data } =
    useGetAssetsManufacturersQuery(
      { input },
      { enabled: !fetchAssetsFromOpenSearchFeatureFlag }
    );
  const { isLoading: isLoadingManufacturersOS, data: dataOS } =
    useGetAssetsManufacturersOsQuery(
      { input },
      { enabled: fetchAssetsFromOpenSearchFeatureFlag }
    );

  const manufacturers = fetchAssetsFromOpenSearchFeatureFlag
    ? dataOS?.getAssetsManufacturersOS
    : data?.getAssetsManufacturers;
  const isLoading = fetchAssetsFromOpenSearchFeatureFlag
    ? isLoadingManufacturersOS
    : isLoadingManufacturers;

  return {
    assetManufacturers: manufacturers ?? [],
    isLoading,
  };
};
