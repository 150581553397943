import { memo, SVGProps, FC } from "react";

interface CargoProps extends SVGProps<SVGSVGElement> {}

const Cargo: FC<CargoProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.297 12.417L30.3741 16.3824L38.5425 3.49044L23.2943 2.86023L25.4568 6.99257C21.2123 9.461 17.3408 12.495 13.9728 16.1093V16.1105H13.9704C13.2292 16.9053 12.5137 17.7244 11.8262 18.568L16.5704 22.4395C17.1726 21.702 17.7992 20.9816 18.4477 20.2867L18.4501 20.2855C21.3452 17.1796 24.6657 14.56 28.297 12.417Z"
        fill="#9BDEED"
      />
      <path
        d="M13.1393 27.2574L7.92087 24.0539C6.74456 25.9677 5.69502 27.9705 4.79785 30.0037L10.3991 32.4746C11.1865 30.6937 12.1056 28.9384 13.1393 27.2574Z"
        fill="#9BDEED"
      />
      <path
        d="M7.68522 40.7981V40.7908C7.88879 39.8558 8.12283 38.9343 8.38247 38.0237L2.50212 36.3281C2.19982 37.3679 1.93408 38.4223 1.70125 39.4901C0.938174 42.9849 0.551758 46.5882 0.551758 50.1976C0.551758 62.4496 5.02784 74.2371 13.156 83.3867L17.732 79.3214C10.601 71.2932 6.67347 60.9502 6.67347 50.1976C6.67347 47.0271 7.01112 43.8638 7.68522 40.7981Z"
        fill="#9BDEED"
      />
      <path
        d="M94.4286 50.1972C94.4286 74.3915 74.747 94.0756 50.5515 94.0756C41.4859 94.0756 32.791 91.3353 25.3954 86.151L21.8799 91.1634C30.3091 97.0742 40.2231 100.197 50.5515 100.197C78.1223 100.197 100.551 77.7693 100.551 50.1972H94.4286Z"
        fill="#9BDEED"
      />
      <path
        d="M93.4217 40.7969L99.4033 39.4913C94.4323 16.7221 73.8901 0.196381 50.5527 0.196381V6.31931C71.0316 6.31931 89.0615 20.8178 93.4217 40.7969Z"
        fill="#9BDEED"
      />
      <path
        d="M41.7002 78.8711V41.4058L79.1667 41.4046V67.8515C79.1667 73.9379 74.2347 78.8711 68.1484 78.8711H41.7002Z"
        fill="#7E7F81"
      />
      <path
        d="M41.7008 41.4039L26.2734 25.9765V63.443L41.7008 78.8691V41.4039Z"
        fill="#605F61"
      />
      <path
        d="M79.1673 41.4031L41.7008 41.4043L26.2734 25.9769H63.7399L79.1673 41.4031Z"
        fill="#535355"
      />
      <path
        d="M40.6006 25.9769L56.028 41.4043H65.9419L50.5158 25.9769H40.6006Z"
        fill="#2F3744"
      />
      <path
        d="M56.0273 41.4053H65.9413V52.9746L60.9849 50.7707L56.0273 52.9746V41.4053Z"
        fill="#434D5A"
      />
      <path
        d="M68.1073 73.8196C67.794 74.1329 67.3832 74.2902 66.9736 74.2902C66.5616 74.2902 66.152 74.1329 65.8387 73.8196L61.2932 69.2729C60.6654 68.6463 60.6654 67.6297 61.2932 67.0031C61.9185 66.3778 62.9351 66.3778 63.5617 67.0031L66.9736 70.4163L76.428 60.9607C76.489 60.8997 76.567 60.8729 76.6352 60.8229C74.6142 58.385 71.5631 56.832 68.1487 56.832C62.0624 56.832 57.1279 61.7652 57.1279 67.8515C57.1279 73.9367 62.0624 78.8711 68.1487 78.8711C74.2338 78.8711 79.1683 73.9367 79.1683 67.8515C79.1683 66.3473 78.8647 64.915 78.3186 63.6083L68.1073 73.8196Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(Cargo);
