import { useMemo } from "react";
import { SensorStatusUppercase } from "../../../../graphql/operations";

const STATUS_COLOR = {
  [SensorStatusUppercase.Healthy]: {
    color: "green",
    status: "Healthy",
  },
  [SensorStatusUppercase.Warning]: {
    color: "caution",
    status: "Warning",
  },
  [SensorStatusUppercase.Alert]: {
    color: "yellow",
    status: "Alert",
  },
  [SensorStatusUppercase.Critical]: {
    color: "error",
    status: "Critical",
  },
  [SensorStatusUppercase.Unknown]: {
    color: "primary-blue",
    status: "",
  },
};

export function useBatteryStatus(isStatusVisible?: boolean, status?: string) {
  return useMemo(() => {
    if (!isStatusVisible) {
      return STATUS_COLOR[SensorStatusUppercase.Unknown];
    }

    let batteryStatus = SensorStatusUppercase.Unknown;
    Object.keys(SensorStatusUppercase).forEach((x) => {
      if (x.toLowerCase() === status?.toLowerCase()) {
        batteryStatus = status as SensorStatusUppercase;
      }
    });
    return STATUS_COLOR[batteryStatus];
  }, [isStatusVisible, status]);
}
