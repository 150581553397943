export const DEFAULT_NUMBER_LOCALE = "en-US"; // 1,000.00
const DEFAULT_MAX_FRACTION_DIGITS = 0; // 1,000

/**
 * Format a number to a string with a specific locale and maximumFractionDigits,
 * so it becomes split by thousands and with a specific number of decimal places.
 *
 * Example:
 * - 100 => "100"
 * - 2000 => "2,000"
 * - 30000.123 => "30,000.12"
 *
 * See the unit tests for more examples on the usage
 */
export const formatNumber = (
  number: number,
  options?: { locale?: string; maximumFractionDigits?: number }
): string => {
  const locale = options?.locale ?? DEFAULT_NUMBER_LOCALE;
  const maximumFractionDigits =
    options?.maximumFractionDigits ?? DEFAULT_MAX_FRACTION_DIGITS;

  return number?.toLocaleString(locale, {
    maximumFractionDigits,
  });
};
