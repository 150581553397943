import { useState, useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { startOfDay, subMonths } from "date-fns";
import { useAppContext } from "../../../../../context/AppContext";
import {
  TableViewType,
  useSearchMediaTableDataQuery,
  MediaTableData,
} from "../../../../../graphql/operations";
import { Table } from "../../../../../shared/components/Table";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import useExportedFileNameForOrg from "../../../../../shared/hooks/useExportedFileNameForOrg";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../../AdminPanel/tabs/constants";
import MediaActivityModal from "../../Assets/CargoTabPanel/InternalCameraStatus/components/MediaActivityModal";
import { InternalCameraSensorProvider } from "../../Assets/CargoTabPanel/InternalCameraStatus/context";
import {
  defaultColumnVisibilityModel,
  getColumns,
  SEARCH_KEYS,
} from "./columns";
import { processMediaTableData } from "./helpers";

export interface MediaTabProps {
  selectedAssetId: string;
  imei: string;
}

export function generateUniqueId() {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  const id = `id_${Array.from(array, (byte) =>
    byte.toString(36).padStart(2, "0")
  ).join("")}`;
  return id;
}

const MediaTab: React.FC<MediaTabProps> = ({ selectedAssetId, imei }) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const gridApiRef = useGridApiRef();

  const { data: assetData } = useFindAssetById(
    {
      assetId: selectedAssetId,
    },
    { enabled: !!selectedAssetId }
  );

  const { orgName } = useMemo(
    () => ({
      orgName: assetData?.org_name ?? "",
    }),
    [assetData]
  );

  const fileName = useExportedFileNameForOrg(orgName, "Media Alerts");
  const userTimezone = usePreferredTimezone();
  const columns = getColumns(userTimezone);

  const {
    data: assetsMediaQueryData,
    isSuccess: assetsMediaDataSuccess,
    isFetching: assetsMediaDataLoading,
  } = useSearchMediaTableDataQuery(
    {
      input: {
        imei,
        startDate: startOfDay(subMonths(new Date(), 6)),
        endDate: startOfDay(new Date()),
      },
    },
    {
      enabled: Boolean(imei),
    }
  );

  const tableRows = useMemo(() => {
    const mediaTableData = (assetsMediaQueryData?.searchEventHistory
      ?.mediaTableData ?? []) as MediaTableData[];

    return processMediaTableData(mediaTableData);
  }, [assetsMediaQueryData]);

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const [initialSearch, setInitialSearch] = useState("");

  useEffect(() => {
    if (search && !assetsMediaDataLoading) {
      setInitialSearch(search);
    }
  }, [assetsMediaDataLoading, search]);

  return (
    <InternalCameraSensorProvider>
      <MediaActivityModal />

      <Box
        className="h-full w-full bg-background"
        data-testid="media-tab-table-container"
      >
        <Box className="h-full w-full pt-2 px-4 pb-4 md:px-6 md:pb-10 lg:px-16 lg:pb-20">
          <Table
            tableType={TableViewType.AssetsMedia}
            columns={columns}
            rows={tableRows}
            initialSearch={initialSearch}
            error={
              !assetsMediaDataLoading && !assetsMediaDataSuccess ? true : null
            }
            showToolbar
            apiRef={gridApiRef}
            columnVisibilityModel={defaultColumnVisibilityModel}
            enableSearch={true}
            searchExactMatch
            searchKeys={SEARCH_KEYS}
            searchMinLength={appConfig.searchMinLength}
            getRowId={(row) => row.id}
            sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
            exportProps={{
              csvOptions: { fileName },
              excelOptions: { fileName },
              printOptions: { fileName },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "lastTriggeredAt", sort: "desc" }],
              },
            }}
            tableName="asset_alerts_table"
            pageSize={25}
            loading={assetsMediaDataLoading}
            allowExport
            disableRowGrouping
            mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
          />
        </Box>
      </Box>
    </InternalCameraSensorProvider>
  );
};

export default MediaTab;
