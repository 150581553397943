import React from "react";
import ArrowNextIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Box,
  IconButton,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";
import slug from "slug";
import {
  useGetAlertNotificationQuery,
  Scalars,
  useGetAlertNotificationByIdQuery,
  GetAlertNotificationQuery,
  GetAlertNotificationByIdQuery,
} from "../../../../../../graphql/operations";
import { NavigationRoutes } from "../../../../../../utils/routes/routesUtils";

// save for future test
//import { alertNotificationMock } from "./alertNotificationMock";

export interface ModalTemplatesProps {
  alertNotificationId: Scalars["ID"];
  isOpen: boolean;
  onClose: () => void;
  useDefaultQuery?: boolean;
}

type IssueAlert = {
  alertType: string;
  assetId: string;
  geofenceType: string;
  geofenceName: string;
  dwellTime: string;
  geofenceStatus: string;
  cargoStatus: string;
  alertSentDate: string;
};

export enum ViewButtonsLabels {
  ViewAlert = "View Alert",
  ViewAsset = "View Asset",
  ViewGeofence = "View Geofence",
}

export const ModalTemplates: React.FC<ModalTemplatesProps> = ({
  isOpen,
  onClose,
  alertNotificationId,
  useDefaultQuery = true,
}) => {
  const { data: alertNotification, isLoading: isAlertLoading } =
    useGetAlertNotificationQuery(
      {
        // for test "65a12713dfe969f188584114"
        id: alertNotificationId,
      },
      {
        enabled: isOpen && useDefaultQuery,
      }
    );

  const { data: alertByIdNotification, isLoading: isAlertByIdLoading } =
    useGetAlertNotificationByIdQuery(
      {
        id: alertNotificationId,
      },
      {
        enabled: isOpen && !useDefaultQuery,
      }
    );

  let alertNotificationData:
    | GetAlertNotificationQuery["getAlertNotificationV2"]
    | GetAlertNotificationByIdQuery["getAlertNotificationByIdV2"]
    | null
    | undefined;

  const isLoading = useDefaultQuery ? isAlertLoading : isAlertByIdLoading;

  if (useDefaultQuery) {
    alertNotificationData = alertNotification?.getAlertNotificationV2;
  } else {
    alertNotificationData = alertByIdNotification?.getAlertNotificationByIdV2;
  }

  let issueData: IssueAlert | undefined;

  if (
    alertNotificationData &&
    alertNotificationData?.issues &&
    alertNotificationData?.issues?.length > 0
  ) {
    const issueString = alertNotificationData.issues[0];
    try {
      issueData = JSON.parse(issueString);
    } catch (error) {
      console.error("JSON Error:", error);
    }
  }

  const mainAlertInfo = {
    "Alert Name": {
      value: alertNotificationData?.alertName,
      dataTestId: "alert-name",
    },
    // TODO: Add Alert type when it is available
    // "Alert Type": {
    //   value: issueData?.alertType,
    //   dataTestId: "alert-type",
    // },
    Message: {
      value: alertNotificationData?.alertMessage?.length
        ? alertNotificationData?.alertMessage
        : "-",
      dataTestId: "message",
    },
    // TODO: Add Alert Send Date when it is available
  };

  const alertDetails = {
    "Asset ID": {
      value: alertNotificationData?.assetInfo?.assetIdentifier,
      dataTestId: "asset-id",
    },
    Address: {
      value: alertNotificationData?.assetInfo?.address,
      dataTestId: "address",
    },
    "Last Reported": {
      value: alertNotificationData?.assetInfo?.lastReportedDate,
      dataTestId: "last-reported",
    },
  };

  const geofenceData = {
    "Geofence Name": {
      value: alertNotificationData?.assetInfo?.geofenceName,
      dataTestId: "geofence-name",
    },
    "Geofence Type": {
      value: alertNotificationData?.assetInfo?.geofenceCategory,
      dataTestId: "category",
    },
    Region: {
      value: alertNotificationData?.assetInfo?.region,
      dataTestId: "region",
    },
    Zone: {
      value: alertNotificationData?.assetInfo?.zone,
      dataTestId: "zone",
    },
  };

  const assetInfo = {
    "Primary Voltage": {
      value: alertNotificationData?.assetInfo?.voltageInfo?.primaryVoltage
        ?.length
        ? alertNotificationData?.assetInfo?.voltageInfo?.primaryVoltage
        : "-",
      dataTestId: "primary-voltage",
    },
    "Secondary Voltage": {
      value: alertNotificationData?.assetInfo?.voltageInfo?.secondaryVoltage
        ?.length
        ? alertNotificationData?.assetInfo?.voltageInfo?.secondaryVoltage
        : "-",
      dataTestId: "secondary-voltage",
    },
    "Battery Voltage": {
      value: alertNotificationData?.assetInfo?.voltageInfo?.batteryVoltage
        ?.length
        ? alertNotificationData?.assetInfo?.voltageInfo?.batteryVoltage
        : "-",
      dataTestId: "battery-voltage",
    },
    Speed: {
      value: alertNotificationData?.assetInfo?.speed?.length
        ? alertNotificationData?.assetInfo?.speed
        : "-",
      dataTestId: "speed",
    },
    "Total Dwell": {
      value: alertNotificationData?.assetInfo?.totalDwell?.length
        ? alertNotificationData?.assetInfo?.totalDwell
        : "-",
      dataTestId: "total-dwell",
    },
    "Cargo Status": {
      value: alertNotificationData?.assetInfo?.cargoStatus?.length
        ? alertNotificationData?.assetInfo?.cargoStatus
        : "-",
      dataTestId: "cargo-status",
    },
  };

  const renderSection = (
    data:
      | typeof mainAlertInfo
      | typeof alertDetails
      | typeof geofenceData
      | typeof assetInfo
  ) => {
    return Object.entries(data).map(
      ([label, { value, dataTestId }]) =>
        value && (
          <Box key={label} data-testid={dataTestId}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                marginRight: "8px",
                textTransform: "capitalize",
              }}
              component="span"
            >
              {label}:
            </Typography>

            {value}
          </Box>
        )
    );
  };

  const renderLink = (url: string, label: ViewButtonsLabels) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "24px",
        }}
        className="!text-info"
      >
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          data-testid={`view--${label}--link`}
        >
          {label}
          <ArrowNextIcon sx={{ marginLeft: "4px", marginBottom: "2px" }} />
        </a>
      </Box>
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <Box
        sx={{
          backgroundColor: "var(--grayscale-feather-gray)",
          position: "relative",
          padding: {
            xs: "32px 22px 0",
            md: "96px 96px 62px 96px",
          },
        }}
        data-testid="alert-notification-modal"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="h-10 w-10"
          data-testid="leave-alert-modal-close-icon-btn"
          sx={{
            color: "var(--black)",
            position: "absolute",
            right: 30,
            top: {
              xs: 50,
              md: 30,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Box
            sx={{
              backgroundColor: "var(--white)",
              borderRadius: "20px",
              padding: {
                xs: "16px",
                md: "16px 32px",
                lg: "16px 64px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: {
                  xs: "43px 57px 18px 64px",
                  md: "81px 0 94px",
                },
              }}
            >
              <img
                alt="Connect1 Logo"
                src="https://connect1-email-assets-bucket.s3.us-west-2.amazonaws.com/assets/connect1-logo_288x59.png"
                style={{
                  border: 0,
                  display: "block",
                  outline: "none",
                  textDecoration: "none",
                  height: "auto",
                  width: "288px",
                  fontSize: "13px",
                }}
              />
            </Box>
            {isLoading || alertNotificationData === null ? (
              <div
                data-testid="circular-progress"
                className="w-full h-16 my-5 justify-center flex"
              >
                {alertNotificationData === null ? (
                  <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                    There is no sent notification
                  </p>
                ) : (
                  <CircularProgress size={50} />
                )}
              </div>
            ) : (
              <>
                {alertNotificationData?.message?.length ? (
                  <Box
                    sx={{
                      marginBottom: {
                        xs: "24px",
                        md: "40px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "20px",
                        },
                        fontWeight: "600",
                        lineHeight: "30px",
                        textTransform: "capitalize",
                      }}
                      className="text-black"
                    >
                      {alertNotificationData?.message}
                    </Typography>
                  </Box>
                ) : null}

                <Grid
                  container
                  rowSpacing={{
                    xs: 2,
                    md: 3,
                  }}
                  columnSpacing={"51px"}
                  sx={{
                    marginBottom: {
                      xs: "32px",
                      md: "81px",
                    },
                  }}
                  className="text-black"
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "24px",
                    }}
                  >
                    {renderSection(mainAlertInfo)}
                    {alertNotificationData?.alertName &&
                      alertNotificationData?.alertId &&
                      renderLink(
                        `${NavigationRoutes.Alerts}/${slug(
                          alertNotificationData?.alertName ?? ""
                        )}/${alertNotificationData?.alertId}`,
                        ViewButtonsLabels.ViewAlert
                      )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {renderSection(alertDetails)}
                    {alertNotificationData?.assetInfo?.assetId &&
                      renderLink(
                        `${NavigationRoutes.AssetTable}/${alertNotificationData?.assetInfo?.assetId}`,
                        ViewButtonsLabels.ViewAsset
                      )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {renderSection(geofenceData)}
                    {alertNotificationData?.assetInfo?.geofenceId &&
                      renderLink(
                        `${NavigationRoutes.Geofences}/${alertNotificationData?.assetInfo?.geofenceId}`,
                        ViewButtonsLabels.ViewGeofence
                      )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {renderSection(assetInfo)}
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: {
                xs: "39px 0 78px",
                md: "65px 0 78px",
              },
            }}
          >
            <img
              alt="PCT Logo"
              src="https://connect1-email-assets-bucket.s3.us-west-2.amazonaws.com/assets/pct-logo_241x151.png"
              style={{
                border: 0,
                display: "block",
                outline: "none",
                textDecoration: "none",
                height: "auto",
                width: "120px",
                fontSize: "13px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
              fontSize: {
                xs: "14px",
                md: "16px",
              },
              fontWeight: 400,
              lineHeight: {
                xs: "22px",
                md: "24px",
              },
            }}
            className="text-light-charcoal"
          >
            <Box sx={{ paddingBottom: "24px" }}>
              Phillips Connect, 5231 California Ave Suite 110,
              <br />
              Irvine, CA 92617, United States
            </Box>
            <Box sx={{ paddingBottom: "24px" }}>
              Connect with us at
              <br />
              Customer Support 1-833-213-5839
              <br />
              phillips-connect.com
            </Box>
            <Box sx={{ paddingBottom: "24px" }}>
              You can Unsubscribe from the system list or if you
              <br />
              don&#x27;t have access you can ask your admin.
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
