import { ReactElement } from "react";
import classNames from "classnames";

export interface PsiWheelEndSensorChipProps {
  position: "left" | "right";
  size?: "small" | "big";
  isSelected?: boolean;
  dataTestid?: string;
}

export const PsiWheelEndSensorChip: React.FC<PsiWheelEndSensorChipProps> = ({
  position,
  size = "small",
  isSelected = false,
  dataTestid = "psi-wheel-end-sensor-chip",
}) => {
  return (
    <div className="flex items-center" data-testid={dataTestid}>
      <div
        className={classNames(
          `border flex items-center justify-center mx-0`,
          {
            "rounded-l-lg -mr-1": position === "left",
          },
          {
            "rounded-r-lg -ml-1": position === "right",
          },
          {
            "w-4 h-7 border-1": size === "small",
          },
          {
            "w-[20px] h-[36px] border-4": size === "big",
          },
          {
            "border-primary-blue": isSelected,
          },
          {
            "border-light-charcoal": !isSelected,
          }
        )}
      ></div>
    </div>
  );
};
