/**
 * Handle dragging file over the form
 * @param event
 */
export const handleDragEnter = (event: DragEvent, setDragActive: Function) => {
  event.preventDefault();
  event.stopPropagation();

  if (event.type === "dragenter" || event.type === "dragover") {
    setDragActive(true);
  } else if (event.type === "dragleave") {
    setDragActive(false);
  }
};

/**
 * Handle dropping the file to the form
 * @param event
 */
export const handleDrop = (
  event: DragEvent,
  setDragActive: Function,
  onFileUpload: Function
) => {
  event.preventDefault();
  event.stopPropagation();
  setDragActive(false);
  if (event?.dataTransfer?.files[0]) {
    onFileUpload(event.dataTransfer.files[0]);
  }
};
