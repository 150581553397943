import React, { createContext, useContext, useState } from "react";
import { MediaActivityItem, InternalCameraView } from "./interface";

interface InternalCameraSensorContextProps {
  children: React.ReactNode;
}

interface InternalCameraSensorContextValue {
  // Control the visibility of the modal that shows the media activity details
  mediaActivityModalIsOpen: boolean;
  setMediaActivityModalIsOpen: (isOpen: boolean) => void;

  // The media activity item that is currently selected (and whose details are shown in the modal)
  mediaActivityItem: MediaActivityItem | undefined;
  setMediaActivityItem: (item: MediaActivityItem) => void;

  // The active view of the carousel (one image, four images, etc.)
  activeView: InternalCameraView;
  setActiveView: (view: InternalCameraView) => void;

  // Keep track of the current page of the media activity carousel
  activeMediaActivityItems: MediaActivityItem[];
  setActiveMediaActivityItems: (items: MediaActivityItem[]) => void;
}

const InternalCameraSensorContext = createContext<
  InternalCameraSensorContextValue | undefined
>(undefined);

export const InternalCameraSensorProvider: React.FC<
  InternalCameraSensorContextProps
> = ({ children }: InternalCameraSensorContextProps) => {
  // Control the visibility of the modal that shows the media activity details
  const [mediaActivityModalIsOpen, setMediaActivityModalIsOpen] =
    useState<boolean>(false);

  // The media activity item that is currently selected (and whose details are shown in the modal)
  const [mediaActivityItem, setMediaActivityItem] =
    useState<MediaActivityItem>();

  // The active view of the carousel (one image, four images, etc.)
  const [activeView, setActiveView] = useState<InternalCameraView>(
    InternalCameraView.OneImage
  );

  // Keep track of the current page of the media activity carousel
  const [activeMediaActivityItems, setActiveMediaActivityItems] = useState<
    MediaActivityItem[]
  >([]);

  const contextValue: InternalCameraSensorContextValue = {
    mediaActivityModalIsOpen,
    setMediaActivityModalIsOpen,

    mediaActivityItem,
    setMediaActivityItem,

    activeView,
    setActiveView,

    activeMediaActivityItems,
    setActiveMediaActivityItems,
  };

  return (
    <InternalCameraSensorContext.Provider value={contextValue}>
      {children}
    </InternalCameraSensorContext.Provider>
  );
};

export const useInternalCameraSensorContext = () => {
  const context = useContext(InternalCameraSensorContext);
  if (!context) {
    throw new Error(
      "useInternalCameraSensorContext must be used within an InternalCameraSensorProvider"
    );
  }
  return context;
};
