import { UseMutateAsyncFunction } from "@tanstack/react-query";
import {
  CreateAssetInput,
  CreateAssetMutation,
  CreateAssetOsMutation,
  Exact,
  useCreateAssetMutation,
  useCreateAssetOsMutation,
} from "../../../graphql/operations";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";
import { useFeatureFlag } from "../../../utils/useFeatureFlag";

interface CreateAssetHook {
  isLoading: boolean;
  mutateAsync: CreateAssetOSMutationInput | CreateAssetMutationInput;
}

type CreateAssetOSMutationInput = UseMutateAsyncFunction<
  CreateAssetOsMutation,
  unknown,
  Exact<{
    asset: CreateAssetInput;
  }>,
  unknown
>;

type CreateAssetMutationInput = UseMutateAsyncFunction<
  CreateAssetMutation,
  unknown,
  Exact<{
    asset: CreateAssetInput;
  }>,
  unknown
>;

export const useCreateAsset = ({
  onSuccessCallback = () => undefined,
  onErrorCallback = () => undefined,
}: {
  onSuccessCallback?: (
    data: CreateAssetMutation | CreateAssetOsMutation,
    variables: Exact<{ asset: CreateAssetInput }>,
    context: unknown
  ) => Promise<unknown> | undefined | void;
  onErrorCallback?: (
    error: unknown,
    variables: Exact<{
      asset: CreateAssetInput;
    }>,
    context: unknown
  ) => Promise<unknown> | undefined | void;
}): CreateAssetHook => {
  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const createAssetMutation = fetchAssetsFromOpenSearchFeatureFlag
    ? useCreateAssetOsMutation
    : useCreateAssetMutation;

  const mutation = createAssetMutation({
    onSuccess: onSuccessCallback,
    onError: onErrorCallback,
  });

  return {
    isLoading: mutation.isLoading,
    mutateAsync: mutation.mutateAsync,
  };
};
