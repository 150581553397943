import { FC } from "react";
import { Box } from "@mui/material";
import Text from "../../../../../../shared/components/Text";

export type AssetInformationProps = {
  orgName: string | undefined | null;
  assetId: string | undefined | null;
  assetNickname: string | undefined | null;
  imei: string | undefined | null;
  prdCde: string | undefined | null;
};

export const AssetInformation: FC<AssetInformationProps> = ({
  orgName,
  assetId,
  assetNickname,
  imei,
  prdCde,
}) => (
  <Box>
    <Text
      fontSize={14}
      classes="bg-background !text-primary"
      dataTestId="organization-name"
    >
      Organization Name:{" "}
      <span className="font-normal pl-1 text-primary mb-2">
        {orgName ?? ""}
      </span>
    </Text>

    <Text
      fontSize={14}
      fontWeight="bold"
      classes="!text-primary"
      dataTestId="asset-id"
    >
      Asset ID:{" "}
      <span className="font-normal pl-1 text-primary mb-2">
        {assetId ?? ""}
      </span>
    </Text>
    <Text
      fontSize={14}
      fontWeight="bold"
      classes="!text-primary"
      dataTestId="asset-nickname"
    >
      Asset Nickname:{" "}
      <span className="font-normal pl-1 text-primary mb-2">
        {assetNickname ?? ""}
      </span>
    </Text>

    <Text
      fontSize={14}
      fontWeight="bold"
      classes="!text-primary"
      dataTestId="device-id"
    >
      Device ID:{" "}
      <span className="font-normal pl-1 text-primary mb-2">{imei ?? ""}</span>
    </Text>

    <Text
      fontSize={14}
      fontWeight="bold"
      classes="!text-primary"
      dataTestId="product-name"
    >
      Product name:{" "}
      <span className="font-normal pl-1 text-primary mb-2">{prdCde ?? ""}</span>
    </Text>
  </Box>
);
