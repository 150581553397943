import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
  useMemo,
  MutableRefObject,
  useRef,
} from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Box, Grid, Select, ThemeProvider, Typography } from "@mui/material";
import { ReportScheduleFrequency } from "../../../graphql/operations";
import { validateYear } from "../../../utils";
import { SchedulingRepeat } from "../../../utils/scheduling";
import { useReportViewTheme } from "../../../views/ReportView/hooks/useCheckBoxSelectTheme";
import { useFrequencySelectTheme } from "../../../views/ReportView/hooks/useFrequencySelectTheme";
import { ScheduleFormFields } from "../../../views/ReportView/interfaces";
import { SelectedValues } from "../HierarchySelect/types";
import { DatePickerElement } from "../react-hook-form-mui";
import { SelectElement } from "../react-hook-form-mui/SelectElement";
import { DefaultFrequencyList } from "./components/DefaultFrequencyList";
import { Occurrence } from "./components/Occurrence";
import {
  AM_PM_OPTIONS,
  EVERY_OPTIONS,
  FREQUENCY_OPTIONS,
  HOUR_OPTIONS,
  ON_OPTIONS,
  REPEAT_OPTIONS,
} from "./constants";
import { getPeriod } from "./helpers";

export type FrequencySelectProps = {
  loading?: boolean;
  values: SelectedValues;
  onChange: (values: ReportScheduleFrequency) => void;
  onBlur: () => void;
  required?: boolean;
  invalid?: boolean;
  form: UseFormReturn<Partial<ScheduleFormFields>>;
  handleScheduleReportSubmit: () => void;
  disabled?: boolean;
};

export const FrequencySelect: FC<FrequencySelectProps> = memo(
  ({
    values,
    onChange,
    onBlur,
    required,
    invalid,
    handleScheduleReportSubmit,
    form,
    disabled,
  }: FrequencySelectProps) => {
    const theme = useFrequencySelectTheme();
    const checkBoxSelectTheme = useReportViewTheme();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isCustomMode, setIsCustomMode] = useState<boolean>(
      values.includes(ReportScheduleFrequency.Custom)
    );
    const select: MutableRefObject<HTMLInputElement | null | undefined> =
      useRef();
    const watchStartDate = useWatch({
      name: "startDate",
      control: form.control,
    });
    const watchEndDate = useWatch({ name: "endDate", control: form.control });

    const watchRepeat = useWatch({
      name: "repeat",
      control: form.control,
    }) as SchedulingRepeat;

    const watchFrequency = useWatch({
      name: "frequency",
      control: form.control,
    }) as ReportScheduleFrequency;

    const watchEvery = useWatch({ name: "every", control: form.control });
    const watchOn = useWatch({ name: "on", control: form.control });

    const [selectedDefaultOption, setSelectedDefaultOption] =
      useState<ReportScheduleFrequency>(watchFrequency);
    // we need this variable to distinguish when user changed the value of the repeat field
    // from daily to weekly and vice versa,
    // and to avoid infinite loop
    let onWasChanged = false;

    const buildOptionsForFieldEvery = (limit: string) => {
      if (limit === "default") {
        return EVERY_OPTIONS;
      } else {
        const dailyEveryOptionsLastIndex = EVERY_OPTIONS.findIndex(
          (i) => i.label === limit
        );
        const dailyEveryOptionsSet = EVERY_OPTIONS.slice(
          0,
          dailyEveryOptionsLastIndex + 1
        );
        return dailyEveryOptionsSet;
      }
    };

    const everyFieldOptions = useMemo(() => {
      switch (watchRepeat) {
        case SchedulingRepeat.Daily:
          return buildOptionsForFieldEvery("31");
        case SchedulingRepeat.Weekly:
          return buildOptionsForFieldEvery("52");
        case SchedulingRepeat.Monthly:
          return buildOptionsForFieldEvery("12");
        default:
          return buildOptionsForFieldEvery("default");
      }
    }, [watchRepeat]);
    useEffect(() => {
      if (watchRepeat === SchedulingRepeat.Daily) {
        form.setValue("on", ["1", "2", "3", "4", "5", "6", "7"]);
        onWasChanged = true; // eslint-disable-line react-hooks/exhaustive-deps
      }
      if (
        watchRepeat === SchedulingRepeat.Monthly ||
        watchRepeat === SchedulingRepeat.Yearly
      ) {
        form.setValue("on", []);
      }
      if (
        watchRepeat === SchedulingRepeat.Yearly ||
        watchRepeat === SchedulingRepeat.Never
      ) {
        form.setValue("every", "");
      }
      if (
        watchRepeat === SchedulingRepeat.Weekly &&
        watchOn &&
        watchOn.length === 7
      ) {
        form.setValue("on", ["1"]);
        onWasChanged = true;
      }
    }, [watchRepeat, form]);

    useEffect(() => {
      // if user unchecked one of the days, we should change the repeat to weekly
      const dailyCondition =
        watchRepeat === SchedulingRepeat.Daily &&
        watchEvery === "1" &&
        watchOn &&
        !!watchOn.length &&
        watchOn.length < 7 &&
        !onWasChanged;
      const weeklyCondition =
        watchRepeat === SchedulingRepeat.Weekly &&
        watchEvery === "1" &&
        watchOn &&
        !!watchOn.length &&
        watchOn.length === 7 &&
        !onWasChanged;

      if (dailyCondition) {
        form.setValue("frequency", ReportScheduleFrequency.Custom);
        form.setValue("repeat", SchedulingRepeat.Weekly);
      } else if (
        // if user checked all days, we should change the repeat to daily
        weeklyCondition
      ) {
        form.setValue("frequency", ReportScheduleFrequency.Custom);
        form.setValue("repeat", SchedulingRepeat.Daily);
      }
    }, [watchOn, form, watchRepeat, watchEvery]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (
        watchEvery &&
        watchEvery !== "1" &&
        watchRepeat === SchedulingRepeat.Daily
      ) {
        form.setValue("on", []);
      }
    }, [watchEvery, watchRepeat, form]);

    const handleSelectFrequency = (value: string) => {
      setSelectedDefaultOption(value as ReportScheduleFrequency);
      setIsCustomMode(value === ReportScheduleFrequency.Custom);
      if (value !== ReportScheduleFrequency.Custom) {
        form.setValue("on", []);
        form.setValue("repeat", "");
        form.setValue("every", "");
      }
    };

    const handleClose = () => {
      // Trigger the onChange event with the new values
      onChange(selectedDefaultOption as ReportScheduleFrequency);
      setIsOpen(false);
      handleScheduleReportSubmit();
    };

    // Prepare the string to be displayed in the select box
    const buildSelectedValuesString = () => {
      const optionsArray = FREQUENCY_OPTIONS;
      let valuesString: string[] = [];
      values.forEach((value) => {
        const option = optionsArray.find((option) => option.value === value);
        valuesString.push(option?.label ?? "");
      });
      return valuesString?.join(", ") ?? "";
    };
    const shouldShowEvery =
      watchRepeat === SchedulingRepeat.Daily ||
      watchRepeat === SchedulingRepeat.Weekly ||
      watchRepeat === SchedulingRepeat.Monthly;
    const shouldShowOn =
      (watchRepeat === SchedulingRepeat.Daily && watchEvery === "1") ||
      watchRepeat === SchedulingRepeat.Weekly;

    let period = getPeriod(watchRepeat);

    const selectWidth = select?.current?.clientWidth;

    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Select
            data-testid="frequency-select"
            MenuProps={{ autoFocus: false }}
            value={values}
            multiple
            error={invalid}
            required={required}
            onBlur={onBlur}
            fullWidth
            displayEmpty
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            renderValue={() => buildSelectedValuesString()}
            disabled={disabled}
            ref={select}
          >
            <Box
              sx={{
                width: selectWidth ? `${selectWidth}px` : "320px",
              }}
              className="min-h-48 max-h-96 overflow-y-auto"
            >
              <Box
                className="pt-2"
                data-testid="schedule-frequency-options-container"
              >
                <DefaultFrequencyList
                  setSelectedDefaultOption={handleSelectFrequency}
                  selectedDefaultOption={selectedDefaultOption}
                />
                <Grid
                  container
                  className="px-4"
                  data-testid="schedule-frequency-date-picker-container"
                >
                  <Grid item xs={12} className="!mb-8">
                    <DatePickerElement
                      label="Start Date"
                      title="Start Date"
                      control={form.control}
                      name="startDate"
                      placeholder="MM/DD/YYYY"
                      PopperProps={{
                        modifiers: [
                          { name: "offset", options: { offset: [24, 2] } },
                        ],
                      }}
                      fullWidth
                      disableHighlightToday
                      validate={validateYear}
                      required={required}
                      rules={{ required: true }}
                    />
                  </Grid>
                  {isCustomMode ? (
                    <>
                      <Grid item container xs={12} className="!mb-8 !items-end">
                        <Grid item xs={3}>
                          <Typography
                            className="!text-sm !font-bold"
                            data-testid="frequency-repeat-label"
                          >
                            Repeat
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <SelectElement
                            name="repeat"
                            control={form.control}
                            options={REPEAT_OPTIONS}
                            required={required}
                            rules={{ required: true }}
                          />
                        </Grid>
                      </Grid>
                      {shouldShowEvery ? (
                        <Grid
                          item
                          container
                          xs={12}
                          className="!mb-8 !items-end"
                        >
                          <Grid item xs={3}>
                            <Typography
                              className="!text-sm !font-bold"
                              data-testid="frequency-repeat-label"
                            >
                              Every
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <SelectElement
                              name="every"
                              control={form.control}
                              options={everyFieldOptions}
                              required={required}
                              rules={{ required: true }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              className="!text-sm !font-bold text-center"
                              data-testid="frequency-repeat-label"
                            >
                              {period}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                      {shouldShowOn ? (
                        <Grid
                          item
                          container
                          xs={12}
                          className="!mb-8 !items-end"
                        >
                          <Grid item xs={3}>
                            <Typography
                              className="!text-sm !font-bold"
                              data-testid="frequency-repeat-label"
                            >
                              On
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <ThemeProvider theme={checkBoxSelectTheme}>
                              <SelectElement
                                name="on"
                                control={form.control}
                                options={ON_OPTIONS}
                                required={required}
                                rules={{ required: true }}
                                multiple
                                renderCheckMarks
                              />
                            </ThemeProvider>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid
                        item
                        container
                        xs={12}
                        className="!mb-8 !items-baseline"
                      >
                        <Grid item xs={3}>
                          <Typography
                            className="!text-sm !font-bold"
                            data-testid="frequency-repeat-label"
                          >
                            At
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <SelectElement
                            name="time"
                            control={form.control}
                            options={HOUR_OPTIONS}
                            required={required}
                            rules={{ required: true }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectElement
                            formControlSx={{ paddingLeft: "16px" }}
                            name="timeFormat"
                            control={form.control}
                            options={AM_PM_OPTIONS}
                            required={required}
                            rules={{ required: true }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  {!isCustomMode ? (
                    <Grid item xs={12} className="!mb-8">
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                          <SelectElement
                            name="time"
                            label="Time"
                            placeholder="HH:MM"
                            control={form.control}
                            options={HOUR_OPTIONS}
                            required={required}
                            rules={{ required: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectElement
                            name="timeFormat"
                            label="AM/PM"
                            placeholder="AM/PM"
                            control={form.control}
                            options={AM_PM_OPTIONS}
                            required={required}
                            rules={{ required: true }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} className="!mb-6">
                    <DatePickerElement
                      label="End Date"
                      title="End Date"
                      control={form.control}
                      name="endDate"
                      placeholder="MM/DD/YYYY"
                      PopperProps={{
                        modifiers: [
                          { name: "offset", options: { offset: [24, 2] } },
                        ],
                      }}
                      fullWidth
                      disableHighlightToday
                      validate={validateYear}
                    />
                  </Grid>
                  {isCustomMode ? (
                    <Grid item xs={12} className="!mb-4">
                      <Occurrence
                        frequency={watchRepeat}
                        startDate={watchStartDate}
                        endDate={watchEndDate}
                        on={watchOn}
                        repeat={watchEvery}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Box>
            </Box>
          </Select>
        </Box>
      </ThemeProvider>
    );
  }
);
