import { memo, SVGProps, FC } from "react";

interface WarningProps extends SVGProps<SVGSVGElement> {}

const Warning: FC<WarningProps> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.09961 7.76389C5.22924 7.76389 5.34267 7.71528 5.43989 7.61806C5.53711 7.52083 5.58572 7.40741 5.58572 7.27778C5.58572 7.14815 5.53711 7.03472 5.43989 6.9375C5.34267 6.84028 5.22924 6.79167 5.09961 6.79167C4.96998 6.79167 4.85655 6.84028 4.75933 6.9375C4.66211 7.03472 4.6135 7.14815 4.6135 7.27778C4.6135 7.40741 4.66211 7.52083 4.75933 7.61806C4.85655 7.71528 4.96998 7.76389 5.09961 7.76389ZM4.68294 5.76389H5.51628V2.11111H4.68294V5.76389ZM3.01628 10L0.0996094 7.08333V2.91667L3.01628 0H7.18294L10.0996 2.91667V7.08333L7.18294 10H3.01628Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(Warning);
