import { FC } from "react";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { TextButton } from "../../../../../shared/components/Button";
import { useInternalCameraSensorContext } from "../../Assets/CargoTabPanel/InternalCameraStatus/context";
import { MediaActivityItem } from "../../Assets/CargoTabPanel/InternalCameraStatus/interface";

export interface ViewImageBtnProps {
  data: MediaActivityItem;
}

export const ViewImageBtn: FC<ViewImageBtnProps> = ({ data }) => {
  const { setMediaActivityModalIsOpen, setMediaActivityItem } =
    useInternalCameraSensorContext();

  const handleOnClick = () => {
    setMediaActivityItem(data);
    setMediaActivityModalIsOpen(true);
  };

  if (!data || !data?.imageUrl) {
    return <span data-testid="media-tab-table--no-data-label">No data</span>;
  }

  return (
    <TextButton
      onClick={handleOnClick}
      text="View Image"
      icon={
        <OpenInNewOutlinedIcon
          sx={{
            fill: "var(--typography-secondary)",
            fontSize: "20px !important",
          }}
        />
      }
      iconPosition="right"
      size="medium"
      theme="blue"
      className="!text-sm !font-bold"
      data-testid="media-tab-table--view-button"
    />
  );
};
