import { FC, useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../../../graphql/operations";
import PieChartWidget, {
  DataItem,
} from "../../../../../../../shared/components/DashboardWidgets/PieChartWidget";
import {
  mapChartColors,
  mapLegendTextColor,
} from "../../../../../../../shared/components/DashboardWidgets/PieChartWidget/utils";
import { useSelectedOrg } from "../../../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../../../shared/utils";

export type DashboardWidgetDevicesPerGroupData = {
  devicesPerGroup: DevicePerGroup[];
};

export type DevicePerGroup = {
  groupName: string;
  totalCount: number;
  percentage: number;
};

export interface DashboardWidgetDevicesPerGroup
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetDevicesPerGroupData;
}

export interface DevicesPerGroupWidgetProps {
  widget: DashboardWidgetDevicesPerGroup;
}

export const mapWidgetData = (
  data: DashboardWidgetDevicesPerGroupData
): DataItem[] => {
  if (!data) {
    return [];
  }
  return data.devicesPerGroup
    .sort((a, b) => a.percentage - b.percentage)
    .map((device, index) => ({
      name: device.groupName,
      value: device.totalCount,
      color: mapChartColors(device.groupName, index),
      legendTextColor: mapLegendTextColor(device.groupName),
    }));
};

export const DevicesPerGroupWidget: FC<DevicesPerGroupWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const data = useMemo(() => {
    const parsedData: DashboardWidgetDevicesPerGroupData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    return mapWidgetData(parsedData);
  }, [getDashboardWidgetData?.getDashboardWidget?.data]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--devices-per-group"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the devices per group
          widget! Please try again later.
        </Typography>
      ) : (
        <PieChartWidget
          title={title}
          isLoading={isLoading}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
