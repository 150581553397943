import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from "@mui/material";
import classNames from "classnames";
import { isBoolean } from "lodash";
import { ToggleButtonOption } from "../ToggleButtons/ToggleButtons";

interface SwitchButtonsProps extends ToggleButtonGroupProps {
  options: ToggleButtonOption[];
  groupclass?: string;
}

export const SwitchButtons = ({
  options,
  ...props
}: SwitchButtonsProps): JSX.Element => {
  const groupStyles =
    props.groupclass ??
    "h-8 overflow-hidden !rounded-3xl !bg-dark-charcoal p-0.5";

  const isValueBoolean = isBoolean(props?.value);

  const buttonStyles = (tab: ToggleButtonOption) =>
    classNames(
      `sm: !rounded-[30px] !border-none !text-xs font-normal !normal-case !leading-normal text-white !py-0 !px-${
        props.groupclass ? "0" : "6"
      }  ${props.className}`,
      {
        "!bg-brand !text-white !font-bold": isValueBoolean
          ? (!!props.value && !tab.isFalsy) || (!props?.value && tab.isFalsy)
          : tab?.value === props?.value,
      }
    );

  return (
    <ToggleButtonGroup {...props} exclusive className={groupStyles}>
      {options.map((tab: ToggleButtonOption, index: number) => (
        <ToggleButton
          key={tab?.value?.toString() ?? index}
          data-index={tab?.value}
          data-testid={tab?.label}
          className={buttonStyles(tab)}
          value={tab?.value ?? ""}
        >
          <div className="flex flex-col">
            <span>{tab?.label}</span>
            <span>{tab?.subLabel}</span>
          </div>
          {tab?.icon && (
            <div className="ml-1.5 flex items-baseline !text-lg">
              {tab?.icon}
            </div>
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
