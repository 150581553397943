import React, {
  FC,
  memo,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  InputAdornment,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { UserData } from "../../../graphql/operations";
import { useFrequencySelectTheme } from "../../../views/ReportView/hooks/useFrequencySelectTheme";
import { SelectedValues } from "../HierarchySelect/types";
import { UsersList } from "../SubscribersSelect/components/UsersList";
import { StateObject } from "../SubscribersSelect/interfaces";
import { convertStateObjectToValuesArr } from "../SubscribersSelect/utils/convertStateObjectToValuesArr";

export type UsersSelectProps = {
  loading?: boolean;
  values: SelectedValues;
  onChange: (values: string[]) => void;
  onBlur: () => void;
  required?: boolean;
  invalid?: boolean;
  usersData: UserData[];
  disabled?: boolean;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
};

export const UsersSelect: FC<UsersSelectProps> = memo(
  ({
    values,
    onChange,
    onBlur,
    required,
    invalid,
    usersData,
    disabled,
    handleUpdateField,
    fieldPath,
  }: UsersSelectProps) => {
    const theme = useFrequencySelectTheme();
    const select: MutableRefObject<HTMLInputElement | null | undefined> =
      useRef();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");

    const preparedUsersState = useMemo(() => {
      const result: StateObject = {};
      usersData.forEach((user) => {
        result[user._id] = values.includes(user._id);
      });
      return result;
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    const [selectedUsers, setSelectedUsers] =
      useState<StateObject>(preparedUsersState);

    useEffect(() => {
      setSelectedUsers(preparedUsersState);
    }, [preparedUsersState]);

    const handleClose = () => {
      const usersList = convertStateObjectToValuesArr(selectedUsers);
      // Trigger the onChange event with the new values
      onChange && onChange(usersList);
      handleUpdateField && handleUpdateField(usersList, fieldPath);

      setIsOpen(false);
    };

    // Prepare the string to be displayed in the select box

    const filteredUsers = usersData.filter((user) =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(search)
    );

    const selectWidth = select?.current?.clientWidth;

    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Select
            data-testid="subscribers-select"
            MenuProps={{ autoFocus: false }}
            value={values}
            multiple
            error={invalid}
            required={required}
            onBlur={onBlur}
            fullWidth
            displayEmpty
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            renderValue={() => []} // we don't want to display any value in the select box
            ref={select}
            disabled={disabled}
          >
            <Box
              sx={{
                width: selectWidth ? `${selectWidth}px` : "320px",
              }}
              className="min-h-48 max-h-96 overflow-y-auto px-4"
            >
              <Box className="pb-5 pt-8">
                <TextField
                  data-testid="subscribers-users-search"
                  variant="standard"
                  placeholder="Search"
                  fullWidth
                  className="!mb-4"
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(event.target.value.toLowerCase());
                  }}
                />

                {usersData.length ? (
                  <UsersList
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    usersData={filteredUsers}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Select>
        </Box>
      </ThemeProvider>
    );
  }
);
