import { FC, memo, MutableRefObject, useRef, useState } from "react";
import { useWatch } from "react-hook-form";
import { Box, Grid, Select, ThemeProvider } from "@mui/material";
import { ReportScheduleFrequency } from "../../../../graphql/operations";
import {
  AM_PM_OPTIONS,
  HOUR_OPTIONS,
  ON_OPTIONS,
} from "../../../../shared/components/FrequencySelect/constants";
import { SelectedValues } from "../../../../shared/components/HierarchySelect/types";
import { DatePickerElement } from "../../../../shared/components/react-hook-form-mui";
import { SelectElement } from "../../../../shared/components/react-hook-form-mui/SelectElement";
import { checkIsValidDate, formatDate, validateYear } from "../../../../utils";
import { fillOnOptions } from "../../../ReportView/helpers/helpers";
import { useReportViewTheme } from "../../../ReportView/hooks/useCheckBoxSelectTheme";
import { useFrequencySelectTheme } from "../../../ReportView/hooks/useFrequencySelectTheme";

export type AlertScheduleSelectProps = {
  loading?: boolean;
  values: SelectedValues;
  onChange: (values: ReportScheduleFrequency) => void;
  onBlur: () => void;
  required?: boolean;
  invalid?: boolean;
  form: any;
};

export const AlertScheduleSelect: FC<AlertScheduleSelectProps> = memo(
  ({ values, onBlur, required, invalid, form }: AlertScheduleSelectProps) => {
    const theme = useFrequencySelectTheme();
    const checkBoxSelectTheme = useReportViewTheme();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const select: MutableRefObject<HTMLInputElement | null | undefined> =
      useRef();
    const handleClose = () => {
      onBlur();
      setIsOpen(false);
    };

    // Prepare the string to be displayed in the select box
    const buildSelectedValuesString = () => {
      return at ? `${from} ${at} ${days} ${until ? `${until}` : ""}` : "";
    };

    const selectWidth = select?.current?.clientWidth;

    const watchStartDate = useWatch({
      name: "reminder.schedule.startDate",
      control: form.control,
    });
    const watchEndDate = useWatch({
      name: "reminder.schedule.endDate",
      control: form.control,
    });
    const watchTimeFrom = useWatch({
      name: "reminder.schedule.timeFrom",
      control: form.control,
    });
    const watchTimeFormatFrom = useWatch({
      name: "reminder.schedule.timeFormatFrom",
      control: form.control,
    });
    const watchTimeTo = useWatch({
      name: "reminder.schedule.timeTo",
      control: form.control,
    });
    const watchTimeFormatTo = useWatch({
      name: "reminder.schedule.timeFormatTo",
      control: form.control,
    });
    const watchDays = useWatch({
      name: "reminder.schedule.days",
      control: form.control,
    });
    const from =
      watchStartDate && checkIsValidDate(watchStartDate)
        ? formatDate(watchStartDate, "MMMM d, yyyy")
        : "";
    const at =
      watchTimeFrom && watchTimeFormatFrom && watchTimeTo && watchTimeFormatTo
        ? ` at ${watchTimeFrom} ${watchTimeFormatFrom} - ${watchTimeTo} ${watchTimeFormatTo}`
        : "";
    const days = ` on ${watchDays
      .map((value: string) => {
        const option = fillOnOptions().find((option) => option.value === value);
        return option?.label;
      })
      .join(", ")}`;
    const until =
      watchEndDate && checkIsValidDate(watchEndDate)
        ? ` until ${watchEndDate && formatDate(watchEndDate, "MMMM d, yyyy")}`
        : "";
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Select
            data-testid="alert-schedule-select"
            MenuProps={{ autoFocus: false }}
            value={values}
            error={invalid}
            required={required}
            onBlur={onBlur}
            multiple
            fullWidth
            displayEmpty
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            renderValue={() => buildSelectedValuesString()}
            ref={select}
          >
            <Box
              sx={{
                width: selectWidth ? `${selectWidth}px` : "320px",
              }}
            >
              <Grid
                container
                className="p-4"
                data-testid="alert-schedule-date-picker-container"
              >
                <Grid
                  item
                  xs={12}
                  className="!mb-8"
                  data-testid="alert-schedule-start-date-picker"
                >
                  <DatePickerElement
                    label="Start"
                    title="Start"
                    control={form.control}
                    name="reminder.schedule.startDate"
                    placeholder="MM/DD/YYYY"
                    PopperProps={{
                      modifiers: [
                        { name: "offset", options: { offset: [24, 2] } },
                      ],
                    }}
                    fullWidth
                    disableHighlightToday
                    validate={validateYear}
                  />
                </Grid>
                <Grid item xs={12} className="!mb-8">
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SelectElement
                        name="reminder.schedule.timeFrom"
                        label="Time from"
                        placeholder="HH:MM"
                        control={form.control}
                        options={HOUR_OPTIONS}
                        required={required}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectElement
                        name="reminder.schedule.timeFormatFrom"
                        label="AM/PM"
                        placeholder="AM/PM"
                        control={form.control}
                        options={AM_PM_OPTIONS}
                        required={required}
                        rules={{ required: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="!mb-8">
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SelectElement
                        name="reminder.schedule.timeTo"
                        label="Time to"
                        placeholder="HH:MM"
                        control={form.control}
                        options={HOUR_OPTIONS}
                        required={required}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectElement
                        name="reminder.schedule.timeFormatTo"
                        label="AM/PM"
                        placeholder="AM/PM"
                        control={form.control}
                        options={AM_PM_OPTIONS}
                        required={required}
                        rules={{ required: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="!mb-8">
                  <ThemeProvider theme={checkBoxSelectTheme}>
                    <SelectElement
                      name="reminder.schedule.days"
                      label="Days"
                      control={form.control}
                      options={ON_OPTIONS}
                      required={required}
                      rules={{ required: true }}
                      multiple
                      renderCheckMarks
                    />
                  </ThemeProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="!mb-8"
                  data-testid="alert-schedule-end-date-picker"
                >
                  <DatePickerElement
                    label="End"
                    title="End"
                    control={form.control}
                    name="reminder.schedule.endDate"
                    placeholder="MM/DD/YYYY"
                    PopperProps={{
                      modifiers: [
                        { name: "offset", options: { offset: [24, 2] } },
                      ],
                    }}
                    fullWidth
                    disableHighlightToday
                    validate={validateYear}
                    prepareValue={(value: Date) => {
                      return new Date(value.setHours(23, 59, 59));
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  This alert will be active starting{" "}
                  <span className="font-semibold">{from}</span>
                  <span className="font-semibold">{at}</span>
                  <span className="font-semibold">{days}</span>
                  <span className="font-semibold">{until}</span>
                </Grid>
              </Grid>
            </Box>
          </Select>
        </Box>
      </ThemeProvider>
    );
  }
);
