import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../hooks/theme/useCurrentTheme";

export const useTableTheme = () => {
  const muiTheme = useCurrentTheme();
  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiDataGrid: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--background)",
            },
            columnHeaderTitleContainer: {
              flexDirection: "row !important",
            },
            columnHeaderDraggableContainer: {
              flexDirection: "row !important",
            },
            filterFormOperatorInput: {
              width: "fit-content",
            },
            filterFormColumnInput: {
              width: "fit-content",
            },
            cell: {
              justifyContent: "flex-start",
            },
            cellCheckbox: {
              justifyContent: "center !important",
            },
          },
        },
      },
    })
  );
};
