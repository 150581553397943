import { faker } from "@faker-js/faker";
import { Factory } from "fishery";
import { UserRole, GetNomenclaturesResult } from "../../graphql/operations";
import { NOMENCLATURE_NAMES } from "../../views/AssetsView/TableView/hooks/useNomenclatures";

type nomenclatures = {
  label: string;
  value: string | number | UserRole;
}[];
type nomenclaturesInput = {
  type: string;
};
export type nomenclaturesResponse = {
  name: string;
  items: string;
};

export const nomenclaturesFactory = Factory.define<
  nomenclatures,
  nomenclaturesInput
>(({ transientParams }) => {
  switch (transientParams.type) {
    case NOMENCLATURE_NAMES.assetType:
      return [
        {
          label: "Boat",
          value: "boat",
        },
        {
          label: "Chassis",
          value: "chassis",
        },
        {
          label: "Container",
          value: "container",
        },
        {
          label: "Dolly",
          value: "dolly",
        },
        {
          label: "Other",
          value: "other",
        },
        {
          label: "Reefer",
          value: "reefer",
        },
        {
          label: "Tractor",
          value: "tractor",
        },
        {
          label: "Trailer",
          value: "trailer",
        },
        {
          label: "Vehicle",
          value: "vehicle",
        },
      ];
    case NOMENCLATURE_NAMES.assetWheels:
      return [
        {
          label: "two",
          value: 2,
        },
        {
          label: "four",
          value: 4,
        },
        {
          label: "eight",
          value: 8,
        },
        {
          label: "twelve",
          value: 12,
        },
      ];
    case NOMENCLATURE_NAMES.MFAMethod:
      return [
        {
          label: "Authenticator app",
          value: "SOFTWARE_TOKEN_MFA",
        },
        {
          label: "SMS",
          value: "SMS_MFA",
        },
      ];
    case NOMENCLATURE_NAMES.sensorStatus:
      return [
        {
          label: "Critical",
          value: "critical",
        },
        {
          label: "Replace",
          value: "replace",
        },
        {
          label: "Healthy",
          value: "healthy",
        },
        {
          label: "Loaded",
          value: "loaded",
        },
        {
          label: "Coming Soon",
          value: "comingSoon",
        },
        {
          label: "Not Installed",
          value: "notInstalled",
        },
      ];
    case NOMENCLATURE_NAMES.timeZones:
      return [
        {
          label: "Atlantic Standard (GMT-4)",
          value: "America/Moncton",
        },
        {
          label: "Eastern Standard (GMT-5)",
          value: "America/New_York",
        },
        {
          label: "Central Standard (GMT-6)",
          value: "America/Chicago",
        },
        {
          label: "Mountain Standard (GMT-7)",
          value: "America/Denver",
        },
        {
          label: "Pacific Standard (GMT-8)",
          value: "America/Los_Angeles",
        },
        {
          label: "Alaskan Standard (GMT-9)",
          value: "America/Anchorage",
        },
        {
          label: "Hawaii-Aleutian Standard (GMT-10)",
          value: "Pacific/Honolulu",
        },
      ];
    case NOMENCLATURE_NAMES.tripStatus:
      return [
        {
          label: "Parked",
          value: "Parked",
        },
        {
          label: "Moving",
          value: "Moving",
        },
      ];
    case NOMENCLATURE_NAMES.dwellStatus:
      return [
        {
          label: "Medium",
          value: "Medium",
        },
        {
          label: "Large",
          value: "Large",
        },
        {
          label: "Mega",
          value: "Mega",
        },
        {
          label: "None",
          value: "none",
        },
      ];
    case NOMENCLATURE_NAMES.defaultSensorState:
      return [
        {
          label: "Healthy",
          value: "healthy",
        },
        {
          label: "Warning",
          value: "warning",
        },
        {
          label: "Alert",
          value: "alert",
        },
        {
          label: "Critical",
          value: "critical",
        },
        {
          label: "Unknown",
          value: "unknown",
        },
      ];
    case NOMENCLATURE_NAMES.dwellSensorState:
      return [
        {
          label: "Low",
          value: "low",
        },
        {
          label: "Medium",
          value: "medium",
        },
        {
          label: "Large",
          value: "large",
        },
        {
          label: "Mega",
          value: "mega",
        },
        {
          label: "None",
          value: "none",
        },
      ];
    case NOMENCLATURE_NAMES.assetSensorTypes:
      return [
        {
          label: "Door",
          value: "door",
        },
        {
          label: "Battery",
          value: "battery",
        },
        {
          label: "Dwell",
          value: "dwell",
        },
        {
          label: "Tires",
          value: "tires",
        },
        {
          label: "Lamp",
          value: "lamp",
        },
      ];
    case NOMENCLATURE_NAMES.assetNumberOfAxles:
      return [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
      ];
    case NOMENCLATURE_NAMES.sensorTypes:
      return [
        {
          label: "Cargo",
          value: "cargo",
        },
        {
          label: "Door",
          value: "door",
        },
        {
          label: "Internal Camera",
          value: "internalCamera",
        },
        {
          label: "SmartLock Gladhand",
          value: "smartLockGladhand",
        },
        {
          label: "T/T Pair",
          value: "smartPair",
        },
      ];
    case NOMENCLATURE_NAMES.defaultConfigurationColors:
      return [
        {
          label: "blue",
          value: "blue",
        },
        {
          label: "green",
          value: "green",
        },
      ];
    case NOMENCLATURE_NAMES.partsManufacturer:
      return [
        {
          label: "Wabco",
          value: "Wabco",
        },
        {
          label: "Bendix",
          value: "Bendix",
        },
        {
          label: "Haldex",
          value: "Haldex",
        },
        {
          label: "Meritor",
          value: "Meritor",
        },
        {
          label: "Hendrickson",
          value: "Hendrickson",
        },
        {
          label: "SAF",
          value: "SAF",
        },
        {
          label: "Holland",
          value: "Holland",
        },
      ];
    case NOMENCLATURE_NAMES.organizationType:
      return [
        {
          label: "Fleet",
          value: "Fleet",
        },
        {
          label: "Retail",
          value: "Retail",
        },
        {
          label: "Factory",
          value: "Factory",
        },
      ];
    case NOMENCLATURE_NAMES.minValuesBySensorType:
      return [
        {
          label: "airbag",
          value: 0,
        },
        {
          label: "battery",
          value: 0,
        },
        {
          label: "camera",
          value: 0,
        },
        {
          label: "percentage",
          value: 0,
        },
        {
          label: "cargoCamera",
          value: 0,
        },
        {
          label: "supplyPressure",
          value: 0,
        },
        {
          label: "pressure",
          value: 0,
        },
        {
          label: "tankPressure",
          value: 0,
        },
        {
          label: "temperature",
          value: 0,
        },
      ];
    case NOMENCLATURE_NAMES.maxValuesBySensorType:
      return [
        {
          label: "airbag",
          value: 200,
        },
        {
          label: "battery",
          value: 4.2,
        },
        {
          label: "camera",
          value: 100,
        },
        {
          label: "percentage",
          value: 100,
        },
        {
          label: "cargoCamera",
          value: 100,
        },
        {
          label: "supplyPressure",
          value: 10000,
        },
        {
          label: "pressure",
          value: 10000,
        },
        {
          label: "tankPressure",
          value: 10000,
        },
        {
          label: "temperature",
          value: 250,
        },
      ];
    case NOMENCLATURE_NAMES.distanceUnits:
      return [
        {
          label: "Miles",
          value: "Miles",
        },
        {
          label: "Kilometers",
          value: "Kilometers",
        },
      ];
    case NOMENCLATURE_NAMES.alertType:
      return [
        {
          label: "Geofence",
          value: "Geofence",
        },
        {
          label: "Cargo",
          value: "Cargo",
        },
        {
          label: "Dwell",
          value: "Dwell",
        },
        {
          label: "Custom",
          value: "Custom",
        },
        {
          label: "Speeding",
          value: "Speeding",
        },
        {
          label: "Moving_without_Primary_or_Secondary_Power",
          value: "Moving without Primary or Secondary Power",
        },
      ];
    case NOMENCLATURE_NAMES.rolesAllowedForSuperAdmin:
      return [
        {
          value: UserRole.Clientadmin,
          label: "Client Admin",
        },
        {
          value: UserRole.Customerservice,
          label: "Customer Service",
        },
        {
          value: UserRole.Equipmentmanager,
          label: "Equipment Manager",
        },
        {
          value: UserRole.Fleetmanager,
          label: "Fleet Manager",
        },
        {
          value: UserRole.Supervisor,
          label: "Supervisor",
        },
        {
          value: UserRole.Superadmin,
          label: "Super Admin",
        },
        {
          value: UserRole.Leaseagent,
          label: "Lease Agent",
        },
        {
          value: UserRole.Loadplanner,
          label: "Load Planner",
        },
        {
          value: UserRole.Fleetviewer,
          label: "Fleet Viewer",
        },
        {
          value: UserRole.Installer,
          label: "Installer",
        },
        {
          value: UserRole.Driver,
          label: "Driver",
        },
        {
          value: UserRole.Maintenancemanager,
          label: "Maintenance Manager",
        },
        {
          value: UserRole.Shoptechnician,
          label: "Shop Technician",
        },
      ];
    case NOMENCLATURE_NAMES.rolesAllowedForClientAdmin:
      return [
        {
          label: "Client Admin",
          value: "clientadmin",
        },
        {
          label: "Customer Service",
          value: "customerservice",
        },
        {
          label: "Equipment Manager",
          value: "equipmentmanager",
        },
        {
          label: "Fleet Manager",
          value: "fleetmanager",
        },
        {
          label: "Supervisor",
          value: "supervisor",
        },
        {
          label: "Lease Agent",
          value: "leaseagent",
        },
        {
          label: "Load Planner",
          value: "loadplanner",
        },
        {
          label: "Fleet Viewer",
          value: "fleetviewer",
        },
        {
          label: "Installer",
          value: "installer",
        },
        {
          label: "Driver",
          value: "driver",
        },
        {
          label: "Maintenance Manager",
          value: "maintenancemanager",
        },
        {
          label: "Shop Technician",
          value: "shoptechnician",
        },
      ];
    case NOMENCLATURE_NAMES.reportType:
      return [
        {
          label: "Asset Transfer",
          value: "ASSET_TRANSFER",
        },
        {
          label: "Asset Install",
          value: "ASSET_INSTALL",
        },
        {
          label: "Asset Inventory",
          value: "ASSET_INVENTORY",
        },
        {
          label: "Alert History",
          value: "ALERT_HISTORY",
        },
        {
          label: "Mileage",
          value: "MILEAGE",
        },
        {
          label: "Asset Yard",
          value: "ASSET_YARD",
        },
        {
          label: "Outside of Geofence",
          value: "OUTSIDE_GEOFENCE",
        },
        {
          label: "Accounts",
          value: "ACCOUNTS",
        },
        {
          label: "Dwell Hierarchy",
          value: "DWELL_HIERARCHY",
        },
        {
          label: "Dwell",
          value: "DWELL",
        },
        {
          label: "Media Activities",
          value: "MEDIA_ACTIVITIES",
        },
        {
          label: "Asset ABS Fault History",
          value: "ASSET_ABS_FAULT_HISTORY",
        },
      ];
    case NOMENCLATURE_NAMES.faultCodeType:
      return [
        {
          label: "ABS",
          value: "abs",
        },
        {
          label: "Air Tank",
          value: "airTank",
        },
        {
          label: "Internal Camera",
          value: "internalCamera",
        },
        {
          label: "Other",
          value: "other",
        },
      ];
    case NOMENCLATURE_NAMES.pressureUnits:
      return [
        {
          label: "Pounds per Square Inch (PSI)",
          value: "psi",
        },
        {
          label: "Millibars",
          value: "mbar",
        },
      ];
    case NOMENCLATURE_NAMES.temperatureUnits:
      return [
        {
          label: "Celsius (°C)",
          value: "Celsius",
        },
        {
          label: "Fahrenheit (°F)",
          value: "Fahrenheit",
        },
      ];
    case NOMENCLATURE_NAMES.territoriesUSA:
      return [
        {
          label: "Alabama",
          value: "Alabama",
        },
        {
          label: "Alaska",
          value: "Alaska",
        },
      ];
    default:
      throw new Error("Invalid NomenclatureType");
  }
});

export const nomenclaturesResponseFactory = Factory.define<
  GetNomenclaturesResult,
  {
    types: string[];
  }
>(({ transientParams }) => {
  const results = transientParams.types!.map((type) => {
    return {
      _id: faker.database.mongodbObjectId(),
      name: type ?? "",
      items: JSON.stringify(
        nomenclaturesFactory.build([], {
          transient: { type: type ?? "" },
        })
      ),
    };
  });

  return {
    data: results,
  };
});
