import { PropsWithChildren } from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from "recharts";
import { ColorsPalette } from "../../../../../../../design-system/colors-palette";
import { BaseBarChartProps } from "../../../../../../../shared/components/BaseVerticalBarChart";
import { chartTickStyle } from "../../../../Assets/SummaryTabPanel/Charts/chartUtils";

export const GeofenceBaseChart = <T,>(props: BaseBarChartProps<T>) => {
  const {
    data,
    xAsisKey,
    yAxisLabel,
    chartStyle,
    width = "100%",
    height = 500,
    children,
  } = props;

  const tickStyles = chartStyle ? chartStyle : chartTickStyle;
  const formatXAxisTick = (tickItem: string) => {
    const date = new Date(tickItem);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}/${day}`;
  };
  return (
    <ResponsiveContainer
      width={width}
      height={height}
      className="responsiveContainer"
    >
      <ComposedChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke={ColorsPalette.Concrete} />

        <XAxis
          dataKey={xAsisKey}
          tick={tickStyles}
          tickLine={false}
          interval={"preserveStart"}
          tickFormatter={formatXAxisTick}
        />

        <YAxis
          label={{
            value: yAxisLabel,
            angle: -90,
            dx: -10,
            style: { textAnchor: "middle", fontWeight: "lighter" },
          }}
          tick={tickStyles}
        />
        {children}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
