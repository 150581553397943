import React, { FC, memo } from "react";
import { Control, Controller, get } from "react-hook-form";
import { Box, FormControl } from "@mui/material";
import { ColorInput } from "../../ColorInput/ColorInput";
import { renderFormErrorHint } from "../../FormControlElement/styledElements";

interface ColorInputProps {
  label?: string;
  name: string;
  control: Control<any>;
  required?: boolean;
  disabled?: boolean;
  errors: any;
  hint?: string;
}

export const ColorInputElement: FC<ColorInputProps> = memo(
  ({ label, name, control, required, disabled, errors, hint }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { ref, onChange, value } }) => (
          <FormControl
            error={Boolean(get(errors, name, undefined))}
            className="w-full"
            disabled={!!disabled}
          >
            <ColorInput
              label={label ?? ""}
              required
              value={value}
              onChange={onChange}
              inputRef={ref}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {renderFormErrorHint(errors, name, hint ?? "")}
            </Box>
          </FormControl>
        )}
      />
    );
  }
);
