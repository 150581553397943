import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import useBreakpoint from "../../../../../../../shared/hooks/useBreakpoint";
import {
  INTERNAL_CAMERA_VIEWS,
  INTERNAL_CAMERA_VIEWS_MOBILE,
} from "../constants";
import { useInternalCameraSensorContext } from "../context";
import { InternalCameraView } from "../interface";

export interface ToggleViewButtonProps {}

const ToggleViewButton: React.FC<ToggleViewButtonProps> = () => {
  const { activeView, setActiveView } = useInternalCameraSensorContext();
  const isMobile = useBreakpoint("down", "sm");

  const viewModes = isMobile
    ? INTERNAL_CAMERA_VIEWS_MOBILE
    : INTERNAL_CAMERA_VIEWS;

  const handleOnClick = (
    event: React.MouseEvent<HTMLElement>,
    newActiveView: string | null
  ) => {
    if (newActiveView !== null) {
      setActiveView(parseInt(newActiveView) as InternalCameraView);
    }
  };

  return (
    <ToggleButtonGroup
      value={activeView}
      exclusive
      onChange={handleOnClick}
      aria-label="Internal Camera Sensor View"
      className="border"
      sx={{
        width: isMobile ? "100%" : "auto",
        justifyContent: isMobile ? "space-between" : "flex-start",
        height: "28px",
        borderRadius: "24px",
        backgroundColor: "var(--concrete)",
        borderColor: "var(--concrete)",
        "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type)":
          {
            borderTopRightRadius: "24px",
            borderBottomRightRadius: "24px",
          },
        "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type)":
          {
            borderTopLeftRadius: "24px",
            borderBottomLeftRadius: "24px",
          },
      }}
      data-testid="asset-dashboard--other-sensors--internal-camera-sensor--toggle-view-button"
    >
      {viewModes.map(({ value, label }) => (
        <ToggleButton
          sx={{
            borderRadius: "24px",
            fontWeight: 500,
            fontSize: isMobile ? "10px" : "12px",
            textTransform: "none",
            borderColor: "var(--concrete)",
            "&.MuiButtonBase-root, &.MuiButtonBase-root:hover": {
              backgroundColor: "var(--concrete)",
              color: "var(--dark-charcoal)",
            },
            "&.Mui-selected,&.Mui-selected:hover": {
              backgroundColor: "var(--blue)",
              color: "var(--white)",
            },
          }}
          value={value}
          aria-label={label}
          key={`toggle-view-button-${value}`}
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleViewButton;
