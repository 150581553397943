import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useInternalCameraSensorContext } from "../../context";
import { downloadMediaActivityItem } from "../../helpers";
import ActionButton from "./ActionButton";

interface DownloadButtonProps {}

const DownloadButton: React.FC<DownloadButtonProps> = () => {
  const { activeMediaActivityItems } = useInternalCameraSensorContext();

  const handleOnClick = () => {
    activeMediaActivityItems.forEach(downloadMediaActivityItem);
  };

  return (
    <ActionButton
      onClick={handleOnClick}
      disabled={activeMediaActivityItems.length === 0}
      dataTestId="asset-dashboard--other-sensors--internal-camera-sensor--download-button"
    >
      <DownloadOutlinedIcon />
    </ActionButton>
  );
};

export default DownloadButton;
