import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { kebabCase } from "lodash";
import { DetailItemValueTypes, DetailListWidgetProps } from "../types";

const GeofenceCapacityWidget = <T extends DetailItemValueTypes>({
  title,
  subTitle,
  detailList,
}: DetailListWidgetProps<T>) => {
  const detailListValues = detailList.filter((item) =>
    item.detailItemValue.split(", ").some((item) => !!Number(item))
  );

  return (
    <Box
      className="rounded-lg bg-dashboard_subheader__bg px-4 py-6 min-h-full"
      data-testid={`capacity-widget-header`}
    >
      <Typography
        className="!text-lg !font-semibold !text-brand"
        data-testid="capacity-widget-title"
      >
        {title}
      </Typography>
      <Typography
        className="!text-xs !font-medium !mb-3 text-asset-info-subheader"
        data-testid={`capacity-widget-subtitle`}
      >
        {subTitle}
      </Typography>
      {!detailListValues.length && (
        <div className="text-base	font-normal	!text-checked">
          Click settings to add minimum and maximum values by asset type.
        </div>
      )}
      <Grid container spacing={1}>
        {Object.entries(detailListValues).map(([key, value]) => {
          return (
            <React.Fragment key={key}>
              <Grid item xs={3}>
                <Box
                  className="my-1 flex gap-1"
                  data-testid={`capacity-widget-${kebabCase(
                    value.label
                  )}-label`}
                >
                  <Typography className="!text-checked">
                    {value.label}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  className="my-1 flex flex-col items-center gap-1"
                  data-testid={`capacity-widget-${kebabCase(
                    value.label
                  )}-label-min`}
                >
                  <Typography className="!text-checked">Min</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  className="my-1 flex flex-col items-center gap-1"
                  style={{ position: "relative" }}
                  data-testid={`capacity-widget-${kebabCase(
                    value.label
                  )}-value-min`}
                >
                  <Typography className="!text-checked">
                    {value.detailItemValue.split(", ")[0]}
                  </Typography>
                  <span
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      borderBottom: "1px solid black",
                      content: "''",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  className="my-1 flex flex-col items-center gap-1"
                  data-testid={`capacity-widget-${kebabCase(
                    value.label
                  )}-label-max`}
                >
                  <Typography className="!text-checked">Max</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  className="my-1 flex flex-col items-center gap-1"
                  style={{ position: "relative" }}
                  data-testid={`capacity-widget-${kebabCase(
                    value.label
                  )}-value-max`}
                >
                  <Typography className="!text-checked">
                    {value.detailItemValue.split(", ")[1]}
                  </Typography>
                  <span
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      borderBottom: "1px solid black",
                      content: "''",
                    }}
                  />
                </Box>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

export default GeofenceCapacityWidget;
