import {
  GetAssetsQueryInput,
  useGetAssetsSharedGroupNamesOsQuery,
  AssetsSharedGroupNamesOs,
} from "../../graphql/operations";

interface UseGetAssetsSharedGroupNamesResponse {
  isFetching: boolean;
  data: AssetsSharedGroupNamesOs[];
}

export const useGetAssetsSharedGroupNamesOS = (
  input: GetAssetsQueryInput
): UseGetAssetsSharedGroupNamesResponse => {
  const { data, isFetching } = useGetAssetsSharedGroupNamesOsQuery(
    { input },
    { enabled: !!input?.orgId }
  );

  return {
    isFetching,
    data:
      data?.getAssetsSharedGroupNamesOS ?? ([] as AssetsSharedGroupNamesOs[]),
  };
};
