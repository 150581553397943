import {
  useState,
  useMemo,
  useCallback,
  forwardRef,
  MutableRefObject,
  useImperativeHandle,
} from "react";
import { FieldValues } from "react-hook-form";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import { GridApi } from "@mui/x-data-grid-premium";
import { capitalize, sortBy } from "lodash";
import { PAGE_SNACKBAR } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { useAuthContext } from "../../../context/AuthContext";
import {
  useGetTableViewPreferencesQuery,
  useMutateTableViewPreferenceMutation,
  useGetUserDataQuery,
  SetTableViewPreferenceInput,
  GetTableViewPreferencesResult,
  TableViewType,
  TableViewPreferenceColumn,
  TableViewPreferenceAction,
} from "../../../graphql/operations";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../utils";
import type { TableViewMenuRef } from "../../../views/ReportView/AssetReport";
import CreateEditTableLayoutDialog, {
  TableLayoutDialogType,
} from "../CreateEditTableLayoutDialog/CreateEditTableLayoutDialog";
import DeleteTableLayoutDialog from "../DeleteTableLayoutDialog/DeleteTableLayoutDialog";
import MenuListItems from "./MenuListItems";

export interface TableViewsMenuProps {
  tableType: TableViewType;
  onTableLayoutChange: (columns: TableViewPreferenceColumn[]) => void;
  onTableLayoutSave: () => void;
  tableViewsMenuRef?: MutableRefObject<TableViewMenuRef | null>;
}

export type PublicPrivateTablesList = {
  publicTablesList: GetTableViewPreferencesResult[];
  privateTablesList: GetTableViewPreferencesResult[];
};

const TableViewsMenu = forwardRef<GridApi, TableViewsMenuProps>(
  (
    { tableType, onTableLayoutChange, onTableLayoutSave, tableViewsMenuRef },
    ref
  ) => {
    const { dispatch } = useAppContext();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [dialogTypeState, setDialogTypeState] =
      useState<TableLayoutDialogType>(TableLayoutDialogType.Create);

    const isEditDialog = dialogTypeState === TableLayoutDialogType.Edit;
    const isDeleteDialog = dialogTypeState === TableLayoutDialogType.Delete;

    const [openCreateEditDialogState, setOpenCreateEditDialogState] =
      useState(false);
    const [openDeleteDialogState, setOpenDeleteDialogState] = useState(false);

    const [selectedTableLayoutState, setSelectedTableLayoutState] = useState<
      | (Partial<GetTableViewPreferencesResult> & { setAsDefault?: boolean })
      | undefined
    >();

    const { isAuthorized } = useAuthContext();

    const {
      data: userData,
      refetch: refetchUserData,
      isFetching: isFetchingUserData,
    } = useGetUserDataQuery(undefined, {
      enabled: isAuthorized,
    });

    const isTableLayoutAlterDisabled = useCallback(
      (listCreatorId: string | undefined) =>
        Boolean(
          userData?.me?.user_preferences?.tableViews?.find(
            (tableView) => tableView?.userId !== listCreatorId
          )
        ),
      [userData?.me?.user_preferences?.tableViews]
    );
    const isTableLayoutDefault = useCallback(
      (name: string | undefined) =>
        Boolean(
          userData?.me?.user_preferences?.tableViews?.find(
            (tableView) => tableView?.name === name
          )
        ),
      [userData?.me?.user_preferences?.tableViews]
    );

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const {
      data: dataTableView,
      isLoading: isLoadingTableViewData,
      isFetching: isFetchingTableViewData,
      refetch: refetchTableView,
    } = useGetTableViewPreferencesQuery({
      input: {
        type: tableType,
      },
    });

    const { mutate, isLoading: isLoadingMutateTableView } =
      useMutateTableViewPreferenceMutation({
        onSuccess: (_, variables) => {
          handleMenuClose();
          setOpenCreateEditDialogState(false);
          setOpenDeleteDialogState(false);

          refetchTableView();
          refetchUserData();

          const action = variables.input.action;
          dispatch({
            type: PAGE_SNACKBAR,
            payload: {
              text: `Table View Successfully ${
                action === TableViewPreferenceAction.Delete
                  ? "Deleted"
                  : action === TableViewPreferenceAction.Update
                  ? isEditDialog
                    ? "Edited"
                    : "Updated"
                  : "Created"
              }!`,
              severity: "success",
            },
          });
        },
        onError: (error) => {
          const actionType = isDeleteDialog
            ? "deleting"
            : isEditDialog
            ? "editing"
            : "creating";

          dispatch({
            type: PAGE_SNACKBAR,
            payload: {
              title: `Table View ${capitalize(actionType)} Failed.`,
              text:
                mapServerErrorCodeToHumanReadableMessage(
                  (error as Error).message
                ) || `An error occurred while ${actionType} a table view.`,
              severity: "error",
            },
          });
        },
      });

    const handleSubmit = (formData?: FieldValues) => {
      const columns = (ref as any)?.current
        ?.getAllColumns()
        .filter((column: any) => column.headerName !== "Checkbox selection")
        .map((column: any, index: number) => ({
          name: column.headerName,
          visibility: Boolean(column.computedWidth), // When the column is hidden, its computed width is 0
          order: index,
          minWidth: Math.trunc(column.computedWidth),
        }));

      const preferences = dataTableView?.getTableViewPreferences || [];
      const userPreferences = userData?.me?.user_preferences?.tableViews || [];

      // Find the current default view by matching views in both sources
      const currentDefaultView = preferences.find((pref) =>
        userPreferences.some((userPref) => userPref?.name === pref?.name)
      );

      // Check if "Default Table View" already exists
      const defaultTableView = preferences.find(
        (pref) => pref?.name === "Default Table View"
      );

      // If formData is provided, use it. Otherwise, set default values for a new "Default Table View"
      const inputValues = formData || {
        shared: false,
        name: currentDefaultView?.name ?? "Default Table View",
        setAsDefault: true,
      };

      // Determine the action based on the conditions
      const action: TableViewPreferenceAction = formData
        ? isEditDialog
          ? TableViewPreferenceAction.Update
          : TableViewPreferenceAction.Create
        : currentDefaultView || defaultTableView
        ? TableViewPreferenceAction.Update
        : TableViewPreferenceAction.Create;

      // Determine the _id based on the conditions
      const _id = formData
        ? selectedTableLayoutState?._id
        : (currentDefaultView || defaultTableView)?._id;

      // Construct the input object
      const input: SetTableViewPreferenceInput = {
        action,
        viewData: {
          _id,
          isPublic: inputValues.shared,
          name: inputValues.name,
          setAsDefault: inputValues.setAsDefault,
          type: tableType,
          userId: userData?.me?._id,
          value: {
            columns,
          },
        },
      };

      // Execute the mutation with the constructed input
      mutate({ input });
      onTableLayoutSave?.();
    };

    // Expose handleSubmit to parent via tableViewsMenuRef
    useImperativeHandle(tableViewsMenuRef, () => ({
      onSubmit: handleSubmit,
    }));

    const handleDeleteLayout = () => {
      mutate({
        input: {
          action: "delete",
          viewData: {
            _id: selectedTableLayoutState?._id,
            type: tableType ?? "devices",
            name: selectedTableLayoutState?.name,
            value: {
              columns: [],
            },
          },
        } as SetTableViewPreferenceInput,
      });
    };
    const opendDeleteTableLayoutDialog = (_id: string, name: string) => {
      setOpenDeleteDialogState(true);
      setSelectedTableLayoutState({ _id, name });

      setDialogTypeState(TableLayoutDialogType.Delete);
    };

    const openCreateTableLayoutDialog = () => {
      setOpenCreateEditDialogState(true);
      setDialogTypeState(TableLayoutDialogType.Create);
    };

    const openEditTableLayoutDialog = ({
      _id,
      isPublic,
      name,
    }: Partial<GetTableViewPreferencesResult>) => {
      setSelectedTableLayoutState({
        _id,
        isPublic,
        name,
        setAsDefault: isTableLayoutDefault(name ?? ""),
      });
      setOpenCreateEditDialogState(true);
      setDialogTypeState(TableLayoutDialogType.Edit);
    };

    // Spliting the public and private table list into two arrays
    const { publicTablesList, privateTablesList } =
      (useMemo(
        () =>
          sortBy(dataTableView?.getTableViewPreferences, "name")?.reduce(
            (acc, obj) => {
              obj?.isPublic
                ? acc.publicTablesList.push(
                    obj as GetTableViewPreferencesResult
                  )
                : acc.privateTablesList.push(
                    obj as GetTableViewPreferencesResult
                  );
              return acc;
            },
            {
              publicTablesList: [] as GetTableViewPreferencesResult[],
              privateTablesList: [] as GetTableViewPreferencesResult[],
            }
          ),
        [dataTableView?.getTableViewPreferences]
      ) as PublicPrivateTablesList) || {};

    return (
      <>
        <Button
          data-testid="menu-trigger-action"
          className={`MuiButton-sizeSmall MuiButton-textSizeSmall !text-sm !font-bold !px-2 MuiSvgIcon-fontSizeMedium !text-brand`}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            ":hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
          startIcon={
            isFetchingTableViewData || isFetchingUserData ? (
              <CircularProgress size={15} className="!text-brand" />
            ) : (
              <BookmarkAddOutlinedIcon />
            )
          }
          disabled={isFetchingTableViewData || isFetchingUserData}
        >
          Views
        </Button>
        <Menu
          id="basic-menu"
          elevation={0}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            maxWidth: "450px",
            minWidth: "300px",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={openCreateTableLayoutDialog}
            data-testid="create-list-action"
            sx={{
              borderBottom: "1px solid var(--divider-color)",
            }}
          >
            <ListItemText>Save New View</ListItemText>
            <ListItemIcon
              sx={{
                paddingLeft: "1rem",
              }}
            >
              <FileDownloadOutlinedIcon
                sx={{ width: "18px", height: "18px" }}
              />
            </ListItemIcon>
          </MenuItem>

          <MenuItem
            data-testid="list-item-action"
            sx={{
              cursor: "default",
              pointerEvents: "none",
              "&:hover": {
                background: "none",
              },
            }}
          >
            <ListItemText className="text-light-charcoal">
              Saved Table Views
            </ListItemText>
          </MenuItem>

          {/* List of saved (isPublic = false ) table views */}

          <MenuListItems
            testId="private-table-views-list"
            list={privateTablesList}
            isDefault={isTableLayoutDefault}
            isAlterDisabled={isTableLayoutAlterDisabled}
            onOpenEditDialog={openEditTableLayoutDialog}
            onOpenDeleteDialog={opendDeleteTableLayoutDialog}
            onSelectItem={onTableLayoutChange}
          />

          <MenuItem
            data-testid="list-item-action"
            sx={{
              borderTop: "1px solid var(--divider-color)",
              cursor: "default",
              pointerEvents: "none",
              "&:hover": {
                background: "none",
              },
            }}
          >
            <ListItemText className="text-light-charcoal">
              Shared Table Views
            </ListItemText>
          </MenuItem>

          {/* List of shared (isPublic = true ) table views */}

          <MenuListItems
            testId="public-table-views-list"
            list={publicTablesList}
            isDefault={isTableLayoutDefault}
            isAlterDisabled={isTableLayoutAlterDisabled}
            onOpenEditDialog={openEditTableLayoutDialog}
            onOpenDeleteDialog={opendDeleteTableLayoutDialog}
            onSelectItem={onTableLayoutChange}
          />
        </Menu>

        {openCreateEditDialogState && (
          <CreateEditTableLayoutDialog
            open={openCreateEditDialogState}
            processing={isLoadingMutateTableView}
            isLoading={isLoadingTableViewData}
            isSharedDisabled={Boolean(selectedTableLayoutState?.isPublic)}
            type={dialogTypeState}
            selectedTableLayout={selectedTableLayoutState}
            onClose={() => setOpenCreateEditDialogState(false)}
            onSubmit={handleSubmit}
          />
        )}

        {openDeleteDialogState && (
          <DeleteTableLayoutDialog
            open={openDeleteDialogState}
            processing={isLoadingMutateTableView}
            isLoading={isLoadingTableViewData}
            selectedTableLayoutName={selectedTableLayoutState?.name ?? ""}
            onClose={() => setOpenDeleteDialogState(false)}
            onDelete={handleDeleteLayout}
          />
        )}
      </>
    );
  }
);

export default TableViewsMenu;
