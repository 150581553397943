import {
  HTMLAttributes,
  useEffect,
  useCallback,
  memo,
  FC,
  useMemo,
} from "react";
import { FieldValues, UseFormReturn, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import {
  DatePickerElement,
  HierarchySelectElement,
} from "../../../../../shared/components/react-hook-form-mui";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { validateYear } from "../../../../../utils";
import { getOrgsHierarchy } from "../../../helpers/getOrgsHierarchy";

export type RunReportFormProps = Omit<
  HTMLAttributes<HTMLFormElement>,
  "onChange" | "onSubmit"
> & {
  onSubmit?: (data: FieldValues) => void;
  onChange?: (value: Partial<Record<string, any>>) => void;
  form: UseFormReturn<Partial<Record<string, any>>>;
  compact?: boolean;
};

export const RunReportForm: FC<RunReportFormProps> = memo(
  ({
    onSubmit,
    onChange,
    form,
    compact = false,
    ...props
  }: RunReportFormProps) => {
    const availableOrgs = useAvailableOrgs();
    const availableOrgsHierarchy = useMemo(
      () => getOrgsHierarchy(availableOrgs),
      [availableOrgs]
    );
    const watchStartDate = useWatch({
      name: "startDate",
      control: form.control,
    });
    const watchEndDate = useWatch({ name: "endDate", control: form.control });

    useEffect(() => {
      if (form.formState.isDirty) {
        onChange && onChange(form.getValues());
      }
    }, [form, onChange, form.formState.isDirty]);

    const breakpoints = compact ? { xs: 6 } : { xs: 6, md: 6, lg: 3 };

    return (
      <form
        {...props}
        autoComplete="off"
        onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
      >
        <Grid
          container
          className="!max-w-full !m-0 !text-primary !w-full"
          rowSpacing={4}
          columnSpacing={6}
        >
          <Grid
            item
            xs={6}
            className="!pl-0"
            data-testid="asset-transfer-report-form-start-date"
          >
            <DatePickerElement
              label="Start Date"
              title="Start Date"
              control={form.control}
              name="startDate"
              placeholder="MM/DD/YYYY"
              maxDate={watchEndDate}
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [24, 2] } }],
              }}
              fullWidth
              disableHighlightToday
              validate={validateYear}
            />
          </Grid>
          <Grid item xs={6} data-testid="asset-transfer-report-form-end-date">
            <DatePickerElement
              label="End Date"
              title="End Date"
              control={form.control}
              name="endDate"
              placeholder="MM/DD/YYYY"
              minDate={watchStartDate}
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [24, 2] } }],
              }}
              fullWidth
              disableHighlightToday
              validate={validateYear}
            />
          </Grid>
          <Grid
            item
            className="!pl-0"
            data-testid="asset-transfer-report-form-company"
            xs={12}
          >
            <HierarchySelectElement
              hierarchy={availableOrgsHierarchy}
              rules={{ required: true }}
              required
              loading={!availableOrgsHierarchy.length}
              label="Organization"
              control={form.control}
              name="orgIds"
              form={form}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
);
