import { FC } from "react";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";
import { SensorFilterSliderColors, SensorFiltersNames } from "../../types";
import { DEFAULT_SENSOR_VALUES } from "../../utils";
import BatteryVoltageSlider from "./BatteryVoltageSlider";

const SolarAmperageSlider: FC = () => {
  const {
    onChangeFilters,
    currentFilter: { solarAmperage },
  } = useAssetsDataContext();

  const onChangeSolarAmperage = (newValues: number[]) => {
    const [minAmperage, maxAmperage] = newValues;

    onChangeFilters({
      solarAmperage: { minAmperage, maxAmperage },
    });
  };

  return (
    <BatteryVoltageSlider
      onChange={onChangeSolarAmperage}
      currentSensor={{
        minValue:
          solarAmperage?.minAmperage ??
          DEFAULT_SENSOR_VALUES[SensorFiltersNames.SolarAmperage]!.min,
        maxValue:
          solarAmperage?.maxAmperage ??
          DEFAULT_SENSOR_VALUES[SensorFiltersNames.SolarAmperage]!.max,
      }}
      maxValue={4}
      marks={[
        {
          value: 0,
          label: `0mA`,
        },
        {
          value: 4,
          label: `4mA`,
        },
      ]}
      valueBarColor={SensorFilterSliderColors.SolarAmperage}
    />
  );
};

export default SolarAmperageSlider;
