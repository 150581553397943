import { useEffect } from "react";
import { useApplicationTheme } from "./useApplicationTheme";
import { useMUITheme } from "./useMUITheme";
import { useTheme } from "./useTheme";

export const useCurrentTheme = () => {
  const { theme, setPreference } = useTheme();
  const { applicationTheme } = useApplicationTheme();

  const muiTheme = useMUITheme(theme);
  useEffect(() => {
    setPreference(applicationTheme);
  }, [setPreference, applicationTheme]);
  return muiTheme;
};
