import {
  AlertsTableData,
  TableColumnFormat,
} from "../../../../graphql/operations";
import Status from "../../../../shared/components/Status";
import { Paused, Running } from "../../../../shared/components/Svg";
import {
  TableGridColDef,
  getTableColumn,
  TableColumnProps,
} from "../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../utils";

export const columnVisibilityModel = {
  name: true,
  type: true,
  status: true,
  createdDate: true,
};

export const getColumns = (
  timezone: string
): TableGridColDef<AlertsTableData>[] => {
  const columns: TableColumnProps<AlertsTableData>[] = [
    { field: "name", headerName: "Alert Name", type: "string" },
    { field: "type", headerName: "Alert Type", type: "string" },
    {
      field: "createdDate",
      headerName: "Created Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      options: {
        renderCell: (params) => getStatus(params.row.status),
      },
    },
    {
      field: "orgName",
      headerName: "Organization",
      type: "string",
      options: { filterable: false },
    },
  ];

  return columns.map(getTableColumn);
};

export const getStatus = (status?: string) => {
  switch (status) {
    case "Running":
      return (
        <Status
          bgColor="bg-green"
          icon={<Running />}
          text="Running"
          maxWidth={103}
        />
      );
    case "Paused":
      return (
        <Status
          bgColor="bg-dark-grey"
          icon={<Paused />}
          text="Paused"
          maxWidth={103}
        />
      );
    default:
      return (
        <Status
          bgColor="bg-dark-grey"
          icon={<Paused />}
          text="Paused"
          maxWidth={103}
        />
      );
  }
};
