import { Box } from "@mui/material";
import {
  ComposedChart,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  YAxis,
  XAxis,
} from "recharts";
import ChartLegend from "../ChartLegend/ChartLegend";
import { ChartTooltip, ChartType } from "../ChartTooltip/ChartTooltip";

const containerHeight = 330;

const TripDetailsChart = <TValue extends Record<string, any>>({
  data,
  lineKeys,
  chartColors,
  tickStyle,
}: {
  data: TValue[];
  lineKeys: string[];
  chartColors: string[];
  tickStyle: any;
}) => {
  return (
    <Box>
      <Box className="relative" style={{ height: containerHeight }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={data} stackOffset="none" barGap={0} barSize={24}>
            <CartesianGrid stroke="#DFDFDF" />
            <XAxis dataKey={"date"} tick={tickStyle} tickLine={false} />
            <YAxis
              yAxisId="hours"
              type="number"
              orientation="left"
              label={{
                value: "Hours",
                angle: -90,
                fontFamily: "Montserrat",
                position: "insideLeft",
                style: { textAnchor: "middle", ...tickStyle },
              }}
              tick={tickStyle}
              domain={[0, 24]}
            />
            <Tooltip content={<ChartTooltip type={ChartType.TripDetails} />} />
            <Bar
              yAxisId="hours"
              dataKey="travelTime"
              fill="#3C88CC"
              stackId="a"
            />
            <Bar
              yAxisId="hours"
              dataKey="parkedTime"
              fill="#1E3768"
              stackId="a"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      {
        <Box className="my-12 flex justify-center gap-8">
          {lineKeys.map((key, index) => (
            <ChartLegend
              key={key}
              label={key}
              checked={false}
              color={chartColors[3]}
              chipColor={chartColors[index]}
              labelColor="#464646"
            />
          ))}
        </Box>
      }
    </Box>
  );
};

export default TripDetailsChart;
