/**
 * Safely change page function to avoid side effects.
 *
 * @param {number} initialValue - The initial value.
 * @param {number} pages - The total number of pages.
 * @param {(val: number) => void} setGoTo - The function to set goTo value.
 * @param {(val: number) => void} onPageChange - The function to handle the page change.
 */
export const onPageChangeHandler = (
  initialValue: number,
  pages: number,
  setGoTo: (val: number) => void,
  onPageChange: (val: number) => void
) => {
  let value = initialValue;

  if (value > pages) {
    value = pages;
    setGoTo(value);
  }

  onPageChange(value);
};
