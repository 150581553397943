import { TemperatureUnit } from "../../graphql/operations";
import { useUserData } from "./useUserData";

export const useGetTemperatureUnitPreference = (): TemperatureUnit => {
  const userData = useUserData();

  const temperatureUnit = userData?.customerOrg?.temperature_unit_preference;

  return temperatureUnit ?? TemperatureUnit.Celsius; // TODO: Replace with DEFAULT_TEMPERATURE_UNIT;
};
