import { FC } from "react";
import { SwitchElement, useWatch } from "react-hook-form-mui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { CustomTooltip } from "../../../../shared/components/CustomTooltip/CustomTooltip";
import { AlertRemindersElement } from "../../../../shared/components/react-hook-form-mui/AlertRemindersElement";
import { AlertScheduleElement } from "../../../../shared/components/react-hook-form-mui/AlertScheduleElement";

interface AlertScheduleBlockProps {
  form: any;
}

export const AlertScheduleBlock: FC<AlertScheduleBlockProps> = ({ form }) => {
  const watchPauseReminders = useWatch({
    name: "reminder.schedule.suspended",
    control: form.control,
  });

  return (
    <Grid container item xs={12} md={6} lg={6} xl={6}>
      <Paper elevation={0} className="w-full">
        <Box className="p-12">
          <Typography className="!pb-6 !text-lg" variant="h6" component="div">
            Schedule
          </Typography>
          <Grid container className="items-baseline justify-between !mb-9">
            <Grid item>
              <Box sx={{ display: "flex" }}>
                <Typography className="!font-normal !text-sm pr-3">
                  Pause running this alert
                </Typography>
                <CustomTooltip
                  title="Reminders and Escalation would be disabled when alert is paused."
                  arrow
                >
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{ color: "text.secondary", ml: 1 }}
                  />
                </CustomTooltip>
              </Box>
            </Grid>
            <Grid item data-testid="alert-schedule-suspended">
              <SwitchElement
                sx={{ marginRight: "0px !important" }}
                label=""
                name="reminder.schedule.suspended"
                control={form.control}
                color="secondary"
              />
            </Grid>
          </Grid>
          <Grid item className="!mb-8">
            <AlertScheduleElement
              form={form}
              label="Schedule"
              control={form.control}
              name="scheduleSummary"
            />
          </Grid>
          <Grid item>
            <AlertRemindersElement
              form={form}
              rules={{ required: false }}
              required
              label="Reminders"
              control={form.control}
              name="remindersSummary"
              disabled={watchPauseReminders}
            />
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};
