import React from "react";
import { Box, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRange, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAppContext } from "../../../../context/AppContext";
import { getSubMonths } from "../../../../utils/date";
import useBreakpoint from "../../../hooks/useBreakpoint";
import {
  DESKTOP_CALENDAR_MONTHS,
  DateRangeLabel,
  MOBILE_CALENDAR_MONTHS,
} from "../constants";
import DateRangePicker from "./DateRangesPicker";

export interface CalendarProps {
  startDate: Date | null;
  endDate: Date | null;
  setDateRange: (value: DateRange<Date>) => void;

  selectedDateRangeOption: DateRangeLabel | null;
  setSelectedDateRangeOption: (value: DateRangeLabel | null) => void;

  minDate?: Date;
  maxDate?: Date;
}

const Calendar: React.FC<CalendarProps> = ({
  startDate,
  endDate,
  setDateRange,

  selectedDateRangeOption,
  setSelectedDateRangeOption,

  minDate,
  maxDate,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const isMobile = useBreakpoint("down", "sm");

  const minAllowedDate =
    minDate ?? getSubMonths(new Date(), appConfig.monthsBackDateLimit);
  const calendars = isMobile ? MOBILE_CALENDAR_MONTHS : DESKTOP_CALENDAR_MONTHS;

  const handleCalendarDateRangeChange = (newValue: DateRange<Date>) => {
    setDateRange(newValue);
    setSelectedDateRangeOption(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          "& .MuiPickerStaticWrapper-content": {
            backgroundColor: "transparent",
            alignItems: "center",
          },
          "& .MuiPickersDay-root.Mui-selected.MuiDateRangePickerDay-day": {
            backgroundColor: "var(--datepicker-selected)",
            color: "var(--white)",
          },
          "& .MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight":
            {
              backgroundColor:
                "var(--datepicker-selected-range-highlight-background)",
              "& .MuiDateRangePickerDay-day.MuiDateRangePickerDay-notSelectedDate":
                {
                  color: "var(--datepicker-selected-range-highlight-text)",
                },
            },
          "& .MuiDateRangePickerViewDesktop-container .MuiPickersArrowSwitcher-root .MuiTypography-subtitle1":
            {
              fontWeight: 700,
            },
        }}
        data-testid="date-range-picker-calendar"
      >
        <DateRangePicker
          selectedDateRangeOption={selectedDateRangeOption}
          setSelectedDateRangeOption={setSelectedDateRangeOption}
          setDateRange={setDateRange}
        />

        <Box data-testid="date-range-picker-calendar--calendar">
          <StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            value={[startDate, endDate]}
            minDate={minAllowedDate}
            maxDate={maxDate}
            calendars={calendars}
            onChange={handleCalendarDateRangeChange}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default Calendar;
