import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../../../../shared/hooks/theme/useCurrentTheme";

export const useOtherSensorsTabPanelTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      typography: {
        caption: {
          color: "var(--other-sensors-cards-header)",
        },
      },
    })
  );
};
