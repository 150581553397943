import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as TemperatureSensorDark } from "../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../assets/svgs/temperatureSensorLight.svg";
import { useAppContext } from "../../../../../context/AppContext";
import { SensorStatusUppercase } from "../../../../../graphql/operations";
import SensorHeader from "../../../../../shared/components/SensorHeader/SensorHeader";
import { useGetSensorStateWidget } from "../../Assets/AssetShortTooltip/hooks/useGetSensorStateWidget";
import { TemperatureSummarydata } from "./helpers";

export interface TemperatureStatusCardProps {
  tempSummary: TemperatureSummarydata;
}

/**
 * Used for illustrating only the status in a designed box
 */
export const TemperatureStatusCard: React.FC<TemperatureStatusCardProps> = ({
  tempSummary,
}) => {
  const { getTireSensorChip } = useGetSensorStateWidget();
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "6.25rem",
    height: "6.25rem",
    display: "block",
  };
  const stateChip = getTireSensorChip(tempSummary.status, {
    iconHeight: "24px",
    iconWidth: "24px",
  });
  return (
    <Box
      sx={{
        padding: "1.5rem",
        backgroundColor: "var(--dashboard_subheader__bg)",
        borderRadius: "8px",
      }}
    >
      <SensorHeader title={tempSummary.title} noBorder>
        {isLightTheme ? (
          <TemperatureSensorDark
            style={{ ...svgIconSettings, marginTop: "0.75rem" }}
          />
        ) : (
          <TemperatureSensorLight
            style={{ ...svgIconSettings, marginTop: "0.75rem" }}
          />
        )}
      </SensorHeader>

      <Box
        className="OuterContainer"
        sx={{
          alignSelf: "center",
          display: "flex",
          marginLeft: "15%",
          marginRight: "15%",
          flexDirection: "column",
          borderRadius: "8px",
        }}
      >
        <Box
          data-testid="temperature-status-container"
          sx={{
            border: "4px solid var(--solid-gray-border-color)",
            alignSelf: "center",
            margin: "8px 0px 8px 0px",
            padding: "24px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {tempSummary.status !== SensorStatusUppercase.Unknown &&
            stateChip.icon}
          <Typography
            sx={{
              fontSize: "16px",
              color: "var(--typography-tooltip)",
            }}
          >
            {tempSummary.title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
