import { Box, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { ReactComponent as TemperatureSensorDark } from "../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../assets/svgs/temperatureSensorLight.svg";
import { useAppContext } from "../../../../../context/AppContext";
import { SensorStatusUppercase } from "../../../../../graphql/operations";
import SensorHeader from "../../../../../shared/components/SensorHeader/SensorHeader";
import { useDateInPreferredTimezone } from "../../../../../shared/hooks/useDateInPreferredTimezone";
import { useGetSensorStateWidget } from "../../Assets/AssetShortTooltip/hooks/useGetSensorStateWidget";
import { defaultBorderStyling } from "../../Assets/TiresTabPanel/constants";
import { TemperatureSummarydata } from "./helpers";

export interface TemperatureInfoCardProps {
  temperatureInfo: TemperatureSummarydata;
}

export const TemperatureInfoCard: React.FC<TemperatureInfoCardProps> = ({
  temperatureInfo,
}) => {
  const {
    state: { theme },
  } = useAppContext();
  const { getTireSensorChip } = useGetSensorStateWidget();
  const stateChip = getTireSensorChip(temperatureInfo.status, {
    iconHeight: "22px",
    iconWidth: "20px",
  });

  const timezoneDate = useDateInPreferredTimezone(
    temperatureInfo.receivedDate,
    "MM/dd/yy h:mm a"
  );

  const isLightTheme = theme.theme === "light";
  const temperatureSensorIconStyles = {
    width: "6.25rem",
    height: "6.25rem",
    display: "block",
    marginTop: "0.75rem",
  };

  return (
    <Box
      data-testid="temperature-box-container"
      sx={{
        border: defaultBorderStyling,
        borderRadius: "8px",
        margin: "0 10px 16px 10px",
        padding: "16px",
      }}
    >
      {/* Summary */}
      {/* Temperature box header */}
      <SensorHeader title={`${temperatureInfo.title} Details`} noBorder>
        {isLightTheme ? (
          <TemperatureSensorDark
            style={{ ...temperatureSensorIconStyles }}
            data-testid="temperature-sensor-dark"
          />
        ) : (
          <TemperatureSensorLight
            style={{ ...temperatureSensorIconStyles }}
            data-testid="temperature-sensor-light"
          />
        )}
      </SensorHeader>

      {/* Status */}
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          paddingLeft: "8px",
        }}
      >
        <Typography
          className="text-base text-card__typography"
          sx={{ marginBottom: "10px" }}
        >
          {capitalize(temperatureInfo.status)}
        </Typography>
        {temperatureInfo.status !== SensorStatusUppercase.Unknown && (
          <span className="ml-1">{stateChip.icon}</span>
        )}
      </Box>

      {/* Temperature */}
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          paddingLeft: "8px",
        }}
      >
        <Typography
          className="text-base text-card__typography"
          sx={{ marginBottom: "10px" }}
        >
          Temperature
        </Typography>
        <Typography
          sx={{
            marginLeft: "4px",
            color: "var(--card__typography)",
            fontWeight: 700,
          }}
        >
          {temperatureInfo.temperature}F
        </Typography>
      </Box>

      {/* Last updated Date */}
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          paddingLeft: "8px",
        }}
      >
        <Typography
          className="text-base text-card__typography"
          sx={{ marginBottom: "10px" }}
        >
          Last Reported
        </Typography>
        <Typography
          sx={{
            marginLeft: "4px",
            color: "var(--card__typography)",
          }}
        >
          {timezoneDate}
        </Typography>
      </Box>
    </Box>
  );
};
