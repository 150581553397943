import { FC, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../context/AppContext";
import {
  SensorStatus,
  useFindAssetsByIdsOsQuery,
} from "../../../../graphql/operations";
import { useFindAssetListOptions } from "../../../../shared/hooks/useFindAssetListOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  isFindAssetByIdQueryEnabled,
  onFindAssetsByIdsOSCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface AlertRegulatorParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const AlertRegulatorParameters: FC<AlertRegulatorParametersProps> = ({
  form,
}: AlertRegulatorParametersProps) => {
  const { state } = useAppContext();
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const [assetsOptionsList, setAssetsOptionsList] = useState<
    { id: string; label: string }[]
  >([]);
  const assetTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const [assetNameSearch, setAssetNameSearch] = useState(
    form.getValues().name ?? ""
  );
  const healthStateOptions = [
    { id: SensorStatus.Warning, label: "Warning" },
    { id: SensorStatus.Alert, label: "Alert" },
    { id: SensorStatus.Critical, label: "Critical" },
  ];

  const watchAssetsValue = useWatch({
    name: "parameters.assetIds",
    control: form.control,
  });
  const watchAssetTypeValue = useWatch({
    name: "parameters.assetType",
    control: form.control,
  });
  let shouldBeEnabled = isFindAssetByIdQueryEnabled(watchAssetsValue);

  const {
    data: asset,
    isLoading: findAssetLoading,
    isSuccess: findAssetSuccess,
  } = useFindAssetsByIdsOsQuery(
    { assetIds: getAlertMultiselectIds(watchAssetsValue) ?? [] },
    {
      enabled: shouldBeEnabled,
    }
  );

  useEffect(() => {
    if (asset && !findAssetLoading && findAssetSuccess) {
      onFindAssetsByIdsOSCallback({ data: asset, form, setSelectedAsset });
    }
  }, [asset, findAssetLoading, findAssetSuccess, form, shouldBeEnabled]);

  useEffect(() => {
    form.setValue("parameters.assetIds", selectedAsset);
  }, [form, selectedAsset]);

  const { options, isFetching } = useFindAssetListOptions(
    assetNameSearch,
    state.appConfig.searchOptionsLimit
  );

  useEffect(() => {
    setAssetsOptionsList(options);
  }, [options]);

  const hideInputValue =
    !selectedAsset &&
    !!watchAssetsValue?.length &&
    findAssetLoading &&
    !findAssetSuccess;

  return (
    <AlertParametersContainer>
      <AssetsParameters
        form={form}
        setAssetNameSearch={setAssetNameSearch}
        setSelectedAsset={setSelectedAsset}
        assetsOptionsList={assetsOptionsList}
        setAssetsOptionsList={setAssetsOptionsList}
        assetTypeOptions={assetTypeOptions}
        watchAssetsValue={watchAssetsValue}
        watchAssetTypeValue={watchAssetTypeValue}
        isFetching={isFetching}
        hideInputValue={hideInputValue}
        assetsSelectTestId="regulator-assets-select"
        assetTypeSelectTestId="regulator-asset-type-select"
      />

      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="regulator-asset-health-state"
      >
        <AutocompleteElement
          matchId={true}
          label="Health State"
          control={form.control}
          name="parameters.regulatorHealthStatus"
          options={healthStateOptions}
        />
      </Grid>
    </AlertParametersContainer>
  );
};
