import { FC } from "react";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import TurnLeftOutlinedIcon from "@mui/icons-material/TurnLeftOutlined";
import { Grid, Tooltip, Typography } from "@mui/material";
import { GeofenceShape } from "../../../../../graphql/operations";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import AssetFilterMenuButton from "../../../shared/AssetsFilterControls/AssetFilterMenuButton";

type GeofenceShapeControlsProps = {
  handleReset: () => void;
  handleUndo: () => void;
  isEditGeofence?: boolean;
};

const GeofenceShapeControls: FC<GeofenceShapeControlsProps> = ({
  handleReset,
  handleUndo,
  isEditGeofence,
}) => {
  const { drawnGeofenceType, drawnGeofenceArea } = useAssetsDataContext();
  return (
    <Grid item xs={12}>
      <Typography
        className="!mb-3 !font-bold flex justify-between"
        sx={{ fontSize: "14px" }}
      >
        {drawnGeofenceType === GeofenceShape.Polygon
          ? GeofenceShape.Polygon
          : "Radius"}{" "}
        Mode
        <span>
          <AssetFilterMenuButton
            onClick={handleReset}
            endIcon={
              <Tooltip title="Refresh">
                <RefreshOutlinedIcon
                  fontSize="inherit"
                  data-testid="reset-icon"
                />
              </Tooltip>
            }
          />

          <AssetFilterMenuButton
            onClick={handleUndo}
            disabled={!isEditGeofence}
            endIcon={
              <Tooltip title="One step back">
                <TurnLeftOutlinedIcon
                  fontSize="inherit"
                  data-testid="undo-icon"
                />
              </Tooltip>
            }
          />
        </span>
      </Typography>
      <Typography className="!font-normal" sx={{ fontSize: "14px" }}>
        Click points on the map to draw{" "}
        {drawnGeofenceType === GeofenceShape.Polygon ? "polygon" : "radius"}{" "}
        shape
      </Typography>
      <Typography className="!mb-3" sx={{ fontSize: "14px" }}>
        Press <span className="!font-bold">"Enter"</span> to save the shape
      </Typography>
      {drawnGeofenceArea && (
        <Typography className="!mb-3 !font-bold" sx={{ fontSize: "14px" }}>
          Area
        </Typography>
      )}
      <Tooltip title="This user didn’t allow to send SMS.">
        <Typography className="!font-normal" sx={{ fontSize: "14px" }}>
          {drawnGeofenceArea}
        </Typography>
      </Tooltip>
    </Grid>
  );
};

export default GeofenceShapeControls;
