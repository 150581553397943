import { useEffect, useState } from "react";

interface MapLegendHook {
  isDrawerOpened: boolean;
  setIsDrawerOpened: (isDrawerOpened: boolean) => void;
}

const useMapLegend = (
  ref: React.MutableRefObject<HTMLElement | null>
): MapLegendHook => {
  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);

  // Close the legend when clicked outside of the box
  useEffect(() => {
    if (!ref || !isDrawerOpened) {
      return;
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        event.target &&
        !ref.current.contains(event.target as Node)
      ) {
        setIsDrawerOpened(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, isDrawerOpened]);

  return {
    isDrawerOpened,
    setIsDrawerOpened,
  };
};

export default useMapLegend;
