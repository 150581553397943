import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useReportViewTheme } from "../../../../hooks";

export const useReportSettingsTheme = () => {
  const reportsTheme = useReportViewTheme();

  return createTheme(
    deepmerge(reportsTheme, {
      components: {
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                opacity: "unset !important",
                color: "var(--light-charcoal) !important",
              },
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              "&::before": {
                backgroundColor: "transparent !important",
              },
            },
          },
        },
      },
    })
  );
};
