import { KeyboardEventHandler } from "react";
import { UseFormReturn } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { Asset } from "../../../../../../graphql/operations";
import { Breakpoints } from "../AssetForm";
import {
  ASSET_SUMMARY_FORM_FIELDS,
  AssetFormSectionTitles,
  AssetSummaryFields,
} from "../assetFormUtils";
import { AccordionWrapper } from "../components/AccordionWrapper";

interface SummaryProps {
  showDetailsHeader?: boolean;
  form: UseFormReturn<Partial<Asset>>;
  breakpoints: Breakpoints;
  asset?: Partial<Asset>;
  disableAllFields?: boolean;
  numberChangeHandler: KeyboardEventHandler<HTMLDivElement>;
}

export const Summary = ({
  showDetailsHeader = false,
  form,
  breakpoints,
  disableAllFields = false,
  numberChangeHandler,
}: SummaryProps) => {
  return (
    <AccordionWrapper
      accordionSummaryTitle={AssetFormSectionTitles.AssetSummary}
      showDetailsHeader={showDetailsHeader}
      isDashboardSettings={showDetailsHeader}
    >
      <Grid
        container
        rowGap={showDetailsHeader ? 3 : 0}
        className={`!text-primary ${
          showDetailsHeader ? "formSection" : "secondDrawerSection"
        }`}
        spacing={6}
      >
        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_SUMMARY_FORM_FIELDS[AssetSummaryFields.ExternalHeight].name
            }
            label={
              ASSET_SUMMARY_FORM_FIELDS[AssetSummaryFields.ExternalHeight].label
            }
          />
        </Grid>
        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_SUMMARY_FORM_FIELDS[AssetSummaryFields.InternalHeight].name
            }
            label={
              ASSET_SUMMARY_FORM_FIELDS[AssetSummaryFields.InternalHeight].label
            }
          />
        </Grid>
        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_SUMMARY_FORM_FIELDS[AssetSummaryFields.InternalLength].name
            }
            label={
              ASSET_SUMMARY_FORM_FIELDS[AssetSummaryFields.InternalLength].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_SUMMARY_FORM_FIELDS[AssetSummaryFields.InternalWidth].name
            }
            label={
              ASSET_SUMMARY_FORM_FIELDS[AssetSummaryFields.InternalWidth].label
            }
          />
        </Grid>
      </Grid>
    </AccordionWrapper>
  );
};
