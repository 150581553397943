import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Text from "../../../../../shared/components/Text";
import UserHistoryTable from "./UserHistoryTable";
import { useActivityLogDialogTheme } from "./useActivityLogDialogTheme";

export interface UserActivityLogDialogProps {
  userEmail: string;
  showUserActivityLogDialog: boolean;
  setShowUserActivityLogDialog: (showUserActivityLogDialog: boolean) => void;
}

const UserActivityLogDialog: FC<UserActivityLogDialogProps> = ({
  userEmail,
  showUserActivityLogDialog,
  setShowUserActivityLogDialog,
}) => {
  const theme = useActivityLogDialogTheme();

  const onClose = () => {
    setShowUserActivityLogDialog(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={showUserActivityLogDialog}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        data-testid="user-activity-log-dialog-container"
      >
        <DialogTitle>
          <div className="flex justify-between items-center">
            <Text
              classes="!text-2xl !font-semibold capitalize"
              dataTestId="user-activity-log-dialog-title"
            >
              Activity Log
            </Text>
            <IconButton
              aria-label="close"
              onClick={onClose}
              data-testid="user-activity-log-dialog-close-icon-btn"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <UserHistoryTable email={userEmail} />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default UserActivityLogDialog;
