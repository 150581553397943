import React, {
  FC,
  MutableRefObject,
  useCallback,
  useMemo,
  useState,
} from "react";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import ClearIcon from "@mui/icons-material/Clear";
import EmailIcon from "@mui/icons-material/Email";
import { Grid, InputAdornment, ThemeProvider } from "@mui/material";
import { GridColumnsState } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../context/AppContext";
import {
  FileFormat,
  GetTableDataInput,
  TableColumnFormat,
  TableColumnInput,
  TableDomain,
  TableStateInput,
  useSendTableDataToEmailMutation,
} from "../../../../graphql/operations";
import { useExportFileTypeOptions } from "../../../../views/AssetsView/TableView/hooks";
import { useFormTheme } from "../../../hooks/theme/useFormTheme";
import { ExtendedGetTableDataInput } from "../../../hooks/useTableDataExporter/useTableDataExporter";
import { useUserData } from "../../../hooks/useUserData";
import { ActionDialog } from "../../ActionDialog/ActionDialog";
import Spinner from "../../Spinner";
import { useSendTableDataToEmailForm } from "./useForm";

export const getTableState = (
  domain: TableDomain,
  columnsState: GridColumnsState | undefined,
  {
    sorting,
    tableFilters,
    searchText,
    imei,
    startDate,
    endDate,
    orgId,
  }: ExtendedGetTableDataInput,
  baseFilters?: Record<string, unknown>
): TableStateInput => {
  const columns: TableColumnInput[] = columnsState
    ? columnsState.all
        .filter((key) => columnsState.columnVisibilityModel[key])
        .map((key) => ({
          disableExport: columnsState.lookup[key].disableExport,
          field: columnsState.lookup[key].field,
          format:
            "format" in columnsState.lookup[key]
              ? ((columnsState.lookup[key] as unknown as { format: string })
                  .format as TableColumnFormat)
              : TableColumnFormat.String,
          label: columnsState.lookup[key].headerName ?? "Column name",
        }))
    : [];

  return {
    domain,
    columns,
    sorting,
    searchText,
    tableFilters,
    ...((baseFilters || orgId) && {
      baseFilters: JSON.stringify({ ...baseFilters, orgId }),
    }),
    startDate,
    endDate,
    imei,
  };
};

interface SendTableDataToEmailProps {
  gridApiRef: MutableRefObject<GridApiPremium>;
  getDataInput: GetTableDataInput;
  tableDomain: TableDomain;
  selectedRowsCount: number;
  baseFilters?: Record<string, unknown>;
  open: boolean;
  onClose: () => void;
}

export const SendTableDataToEmailDialog: FC<SendTableDataToEmailProps> = ({
  open,
  onClose,
  tableDomain,
  gridApiRef,
  getDataInput,
  selectedRowsCount,
  baseFilters,
}) => {
  const { dispatch } = useAppContext();

  const dialogSubmitButtonText = "Send";

  const dialogTitle = useMemo(
    () =>
      `${selectedRowsCount} Row${selectedRowsCount === 1 ? "" : "s"} ${
        selectedRowsCount === 1 ? "Is" : "Are"
      } Selected`,
    [selectedRowsCount]
  );
  const dialogText = useMemo(
    () =>
      `You’re set to send ${selectedRowsCount} record${
        selectedRowsCount === 1 ? "" : "s"
      } to the email below. If you'd like to change the recipient, just update the email field.`,
    [selectedRowsCount]
  );

  const visibleColumnsCount = gridApiRef.current?.state?.columns
    ? Object.values(
        gridApiRef.current.state.columns.columnVisibilityModel
      ).filter((value) => value).length
    : 0;
  const [loading, setLoading] = useState(false);

  const user = useUserData();
  const form = useSendTableDataToEmailForm({ email: user?.email });

  const fileTypeOptions = useExportFileTypeOptions(
    visibleColumnsCount,
    true
  ).map((option) => ({
    id: option.value,
    ...option,
  }));

  const onSuccess = useCallback(
    (message: string) => {
      dispatch({
        type: "PAGE_SNACKBAR",
        payload: {
          title: "Success!",
          text: message,
          severity: "success",
        },
      });
    },
    [dispatch]
  );

  const onFailure = useCallback(
    (message: string) => {
      dispatch({
        type: "PAGE_SNACKBAR",
        payload: {
          title: "Error!",
          text: message,
          severity: "error",
        },
      });
    },
    [dispatch]
  );

  const { mutate } = useSendTableDataToEmailMutation({
    onSettled() {
      setLoading(false);
      form.reset();
      onClose();
    },
    onSuccess() {
      onSuccess("Email sent. Please check the inbox.");
    },
    onError() {
      onFailure("Failed to send email.");
    },
  });

  const handleDialogClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleDialogSubmit = useCallback(async () => {
    const valid = await form.trigger();
    if (!valid) {
      return;
    }
    if (selectedRowsCount === 0) {
      form.reset();
      onClose();
      return onFailure(
        "Cannot export an empty table. Please ensure there is data in the table before attempting to export."
      );
    }

    const {
      fileFormat: { value: fileFormat },
      email,
    } = form.getValues() as unknown as {
      fileFormat: { value: FileFormat };
      email: string;
    };
    const tableState = getTableState(
      tableDomain,
      gridApiRef.current?.state?.columns,
      getDataInput,
      baseFilters
    );

    setLoading(true);

    mutate({
      input: {
        email,
        fileFormat,
        tableState,
      },
    });
  }, [
    form,
    onClose,
    mutate,
    selectedRowsCount,
    onFailure,
    tableDomain,
    gridApiRef,
    getDataInput,
    baseFilters,
  ]);

  const clearEmailField = useCallback(() => {
    form.setValue("email", "");
  }, [form]);

  const theme = useFormTheme();

  return (
    <ThemeProvider theme={theme}>
      <ActionDialog
        open={open}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        name="send-table-data-to-email"
        title={dialogTitle}
        textContent={dialogText}
        submitButtonText={dialogSubmitButtonText}
      >
        <>
          <Spinner counter={loading ? 1 : 0} />
          <form
            autoComplete="off"
            data-testid="export-table-data-to-email-form"
          >
            <Grid container>
              <Grid
                item
                xs={12}
                className="!mb-8"
                data-testid="export-table-data-file-format-select"
              >
                <AutocompleteElement
                  required
                  label="Export File Type"
                  control={form.control}
                  name="fileFormat"
                  options={fileTypeOptions}
                  autocompleteProps={{
                    getOptionDisabled: (option) => option.disabled,
                  }}
                  textFieldProps={{
                    placeholder: "Select file type",
                    inputProps: {
                      "data-testid":
                        "export-table-data-to-email-form-file-format",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} className="!mb-8">
                <TextFieldElement
                  placeholder="Enter an email address to send data"
                  data-testid="export-table-data-to-email-form-email"
                  fullWidth
                  type="email"
                  control={form.control}
                  name="email"
                  required
                  label="Email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ClearIcon
                          className="cursor-pointer"
                          onClick={clearEmailField}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </>
      </ActionDialog>
    </ThemeProvider>
  );
};
