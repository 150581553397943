/**
 * Initializes Pendo
 *
 * @param {string} apiKey - Pendo Api Key
 */
const initializePendo = (apiKey: string): void => {
  // Check if Pendo script is already loaded
  // If not loaded, load Pendo script
  (function (p: any, e: Document, n: string, d: string, o?: any) {
    let v: string[], w: number, x: number, y: HTMLScriptElement, z: Element;
    o = p[d] = p[d] || {}; //NOSONAR
    o._q = [];
    v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
    for (w = 0, x = v.length; w < x; ++w) {
      ((m: string) => {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    }
    y = e.createElement(n) as HTMLScriptElement;
    y.async = true;
    y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
    z = e.getElementsByTagName(n)[0];
    z.parentNode?.insertBefore(y, z);
  })(window, document, "script", "pendo");
};

export default initializePendo;
