import React, { createContext, useContext, useState } from "react";
import {
  ImbalanceVariantsByAxle,
  PsiWheelEnd,
  PsiWheelEndAxle,
  TPMS_Axle,
  TPMS_Tire,
} from "./interfaces";

interface TiresTabContextValue {
  // Keep track of the selected axle (we use it to highlight the selected tire in the charts)
  selectedTpmsAxle: TPMS_Axle | undefined;
  setSelectedTpmsAxle: (selectedTpmsAxle: TPMS_Axle | undefined) => void;

  selectedDualImbalanceRecord: ImbalanceVariantsByAxle | undefined;
  setSelectedDualImbalanceRecord: (
    selectedRecord: ImbalanceVariantsByAxle | undefined
  ) => void;

  // Keep track of the selected tire (we use it to highlight the selected tire in the charts)
  selectedTpmsTire: TPMS_Tire | undefined;
  setSelectedTpmsTire: (selectedTpmsTire: TPMS_Tire | undefined) => void;

  // Keep track of the selected axle (we use it to highlight the selected tire in the charts)
  selectedPsiWheelEndAxle: PsiWheelEndAxle | undefined;
  setSelectedPsiWheelEndAxle: (
    selectedPsiWheelEndAxle: PsiWheelEndAxle | undefined
  ) => void;

  // Keep track of the selected tire (we use it to highlight the selected tire in the charts)
  selectedPsiWheelEnd: PsiWheelEnd | undefined;
  setSelectedPsiWheelEnd: (
    selectedPsiWheelEnd: PsiWheelEnd | undefined
  ) => void;
}

const TiresTabContext = createContext<TiresTabContextValue | undefined>(
  undefined
);

interface TiresTabContextProps {
  children: React.ReactNode;
}

export const TiresTabProvider: React.FC<TiresTabContextProps> = ({
  children,
}: TiresTabContextProps) => {
  // Keep track of the selected axle (we use it to highlight the selected tire in the charts)
  const [selectedTpmsAxle, setSelectedTpmsAxle] = useState<
    TPMS_Axle | undefined
  >();
  const [selectedPsiWheelEndAxle, setSelectedPsiWheelEndAxle] = useState<
    PsiWheelEndAxle | undefined
  >();

  // Keep track of the selected tire (we use it to highlight the selected tire in the charts)
  const [selectedTpmsTire, setSelectedTpmsTire] = useState<
    TPMS_Tire | undefined
  >();
  const [selectedPsiWheelEnd, setSelectedPsiWheelEnd] = useState<
    PsiWheelEnd | undefined
  >();

  const [selectedDualImbalanceRecord, setSelectedDualImbalanceRecord] =
    useState<ImbalanceVariantsByAxle | undefined>();

  const contextValue: TiresTabContextValue = {
    // TPMS
    selectedTpmsAxle,
    setSelectedTpmsAxle,
    selectedTpmsTire,
    setSelectedTpmsTire,

    selectedDualImbalanceRecord,
    setSelectedDualImbalanceRecord,

    // PSС Wheel End
    selectedPsiWheelEndAxle,
    setSelectedPsiWheelEndAxle,
    selectedPsiWheelEnd,
    setSelectedPsiWheelEnd,
  };

  return (
    <TiresTabContext.Provider value={contextValue}>
      {children}
    </TiresTabContext.Provider>
  );
};

export const useTiresTabContext = () => {
  const context = useContext(TiresTabContext);
  if (!context) {
    throw new Error(
      "useTiresTabContext must be used within an TiresTabProvider"
    );
  }
  return context;
};
