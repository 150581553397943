import { useRef, FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slug from "slug";
import { ReportType } from "../../graphql/operations";
import Page from "../../shared/components/Page";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { ReportRecord, ReportTemplate } from "./components";
import { ReportTypeTitle } from "./constants";

export const ReportView: FC = () => {
  const navigate = useNavigate();
  const page = useRef();
  const { id, name } = useParams();

  useEffect(() => {
    if (!id || !name) {
      return navigate(NavigationRoutes.Reports);
    }
    const availableReportTypes = Object.values(ReportType).map((value) =>
      slug(ReportTypeTitle[value])
    );
    // Check if unknown report template name provided
    if (id === "template" && !availableReportTypes.includes(name)) {
      return navigate(NavigationRoutes.Reports);
    }
  }, [id, name, navigate]);

  return (
    <Page ref={page} className={"bg-background"}>
      {id === "template" ? (
        <ReportTemplate templateName={name!} />
      ) : (
        <ReportRecord id={id!} />
      )}
    </Page>
  );
};
