import { ChangeEvent, FC, memo, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { onPageChangeHandler } from "./helpers";
import { useAssetsListFooterTheme } from "./useAssetsListFooterTheme";

export interface AssetsListFooterProps {
  page: number;
  pages: number;
  disabled: boolean;
  onPageChange: (value: number) => void;
}

const AssetsListFooter: FC<AssetsListFooterProps> = ({
  page,
  disabled,
  pages,
  onPageChange,
}) => {
  const theme = useAssetsListFooterTheme();

  const minNumberInputValue = 1;
  const [valueGoTo, setValueGoTo] = useState<number | string>("");

  const onGoToPage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.value?.length !== 0) {
      let value = parseInt(e.target?.value, 10);

      if (value > pages) value = pages;
      if (value < minNumberInputValue) value = minNumberInputValue;

      setValueGoTo(value);
    } else {
      setValueGoTo("");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="mt-3">
        <Pagination
          onChange={(event, value) => onPageChange(value)}
          page={page}
          size="small"
          disabled={disabled}
          hidePrevButton
          hideNextButton
          count={pages}
          shape="rounded"
          data-testid="pagination-container"
        />
      </Box>
      {pages > 0 && (
        <Box
          className="flex items-end justify-end mr-7 mt-1"
          data-testid="go-to-container"
        >
          <Typography
            sx={{
              marginRight: "8px",
              fontSize: "0.875rem",
              lineHeight: "25px",
            }}
          >
            Go to
          </Typography>
          <TextField
            value={valueGoTo}
            inputProps={{
              type: "number",
              min: minNumberInputValue,
              max: pages,
            }}
            disabled={disabled}
            onChange={onGoToPage}
          />
          <IconButton
            onClick={() => {
              onPageChangeHandler(
                valueGoTo as number,
                pages,
                setValueGoTo,
                onPageChange
              );
              setValueGoTo("");
            }}
            disabled={!valueGoTo}
          >
            <ArrowForwardIosIcon
              sx={{
                width: "16px",
                height: "16px",
                fontSize: "0.875rem",
              }}
            />
          </IconButton>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default memo(AssetsListFooter);
