import { FC } from "react";
import {
  OrgData,
  SensorProfileResult,
  ProfileConfigProperty,
  SensorProfileConfigType,
} from "../../../../graphql/operations";
import AirSupplyDrawer from "./Drawers/AirSupplyDrawer";
import AirbagDrawer from "./Drawers/AirbagDrawer";
import AtisDrawer from "./Drawers/AtisDrawer";
import BatteryDrawer from "./Drawers/BatteryDrawer";
import CargoCameraDrawer from "./Drawers/CargoCameraDrawer";
import LiftgateDrawer from "./Drawers/LiftgateDrawer";
import LiteSentryGammaDrawer from "./Drawers/LiteSentryGammaDrawer/LiteSentryGammaDrawer";
import PsiWheelEndDrawer from "./Drawers/PsiWheelEndDrawer";
import TemperatureDrawer from "./Drawers/TemperatureDrawer";
import TpmsDrawer from "./Drawers/TpmsDrawer";
import { DrawerType } from "./profileUtils";

export interface DrawerGatewayProps {
  isOpen: boolean;
  selectedConfigId?: string;
  type: DrawerType;
  sensorType?: SensorProfileConfigType | ProfileConfigProperty;
  selectedOrg?: OrgData;
  sensorProfileData: SensorProfileResult | undefined;
  onClose: () => void;
  onProfileMutation?: () => void;
  onRequestClose?: () => void;
}

const drawer: Record<DrawerType, React.FC<any>> = {
  createBatteryProfile: BatteryDrawer,
  editBatteryProfile: BatteryDrawer,
  createTPMSProfile: TpmsDrawer,
  editTPMSProfile: TpmsDrawer,
  createAirbagProfile: AirbagDrawer,
  editAirbagProfile: AirbagDrawer,
  createCargoCameraProfile: CargoCameraDrawer,
  editCargoCameraProfile: CargoCameraDrawer,
  createAirTankProfile: AirSupplyDrawer,
  editAirTankProfile: AirSupplyDrawer,
  createPsiWheelEndProfile: PsiWheelEndDrawer,
  editPsiWheelEndProfile: PsiWheelEndDrawer,
  createTemperatureProfile: TemperatureDrawer,
  editTemperatureProfile: TemperatureDrawer,
  createAtisProfile: AtisDrawer,
  editAtisProfile: AtisDrawer,
  createLiteSentryGammaProfile: LiteSentryGammaDrawer,
  editLiteSentryGammaProfile: LiteSentryGammaDrawer,
  createLiftgateProfile: LiftgateDrawer,
  editLiftgateProfile: LiftgateDrawer,
};

const DrawerGateway: FC<DrawerGatewayProps> = (props) => {
  const {
    isOpen,
    selectedConfigId,
    selectedOrg,
    type,
    sensorType,
    onClose,
    onProfileMutation,
    onRequestClose,
    ...rest
  } = props;

  const Component = drawer[type as DrawerType];

  return (
    <Component
      isOpen={isOpen}
      type={type}
      sensorType={sensorType}
      selectedOrgId={selectedOrg?._id}
      selectedConfigId={selectedConfigId}
      onClose={onClose}
      onProfileMutation={onProfileMutation}
      onRequestClose={onRequestClose}
      {...rest}
    />
  );
};

export default DrawerGateway;
