import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { omitBy, isNil } from "lodash";
import * as yup from "yup";

export type GeofenceInitialCapacity = {
  configuration: {
    capacity: {
      chassis: {
        min: number | null;
        max: number | null;
      };
      container: {
        min: number | null;
        max: number | null;
      };
      trailer: {
        min: number | null;
        max: number | null;
      };
      dolly: {
        min: number | null;
        max: number | null;
      };
      boat: {
        min: number | null;
        max: number | null;
      };
      tractor: {
        min: number | null;
        max: number | null;
      };
      reefer: {
        min: number | null;
        max: number | null;
      };
      vehicle: {
        min: number | null;
        max: number | null;
      };
      other: {
        min: number | null;
        max: number | null;
      };
    };
  };
};

export const useCapacityForm = (
  initialValues: GeofenceInitialCapacity = {
    configuration: {
      capacity: {
        chassis: {
          min: null,
          max: null,
        },
        container: {
          min: null,
          max: null,
        },
        trailer: {
          min: null,
          max: null,
        },
        dolly: {
          min: null,
          max: null,
        },
        boat: {
          min: null,
          max: null,
        },
        tractor: {
          min: null,
          max: null,
        },
        reefer: {
          min: null,
          max: null,
        },
        vehicle: {
          min: null,
          max: null,
        },
        other: {
          min: null,
          max: null,
        },
      },
    },
  }
) => {
  const commonCapacitySchema = yup
    .object()
    .nullable()
    .notRequired()
    .shape({
      min: yup
        .number()
        .typeError("Min must be a number")
        .notRequired()
        .nullable()
        .min(0, "Min must be greater than or equal to 0")
        .transform((value, originalValue) => {
          // Convert empty string to null to handle the case where max is an empty string
          if (originalValue === "") {
            return null;
          }
          return value;
        })
        .test("min", "Min is required if Max is provided", function (min) {
          const max = this.parent.max;
          return !max || (min !== undefined && min !== null);
        })
        .test("min", "Min must be a non-negative number", function (min) {
          return min === undefined || min === null || min >= 0;
        })
        .test("min", "Min must be less than or equal to Max", function (min) {
          const max = this.parent.max;
          return min === undefined || min === null || min <= max;
        }),
      max: yup
        .number()
        .typeError("Max must be a number")
        .notRequired()
        .nullable()
        .min(0, "Max must be greater than or equal to 0")
        .transform((value, originalValue) => {
          // Convert empty string to null to handle the case where max is an empty string
          if (originalValue === "") {
            return null;
          }
          return value;
        })
        .test("max", "Max is required if Min is provided", function (max) {
          const min = this.parent.min;
          return !min || (max !== undefined && max !== null);
        })
        .test("max", "Max must be a non-negative number", function (max) {
          return max === undefined || max === null || max >= 0;
        })
        .test(
          "max",
          "Max must be greater than or equal to Min",
          function (max) {
            const min = this.parent.min;
            return max === undefined || max === null || max >= min;
          }
        ),
    });
  const schema: yup.SchemaOf<GeofenceInitialCapacity> = yup.object().shape({
    configuration: yup
      .object()
      .default({})
      .notRequired()
      .shape({
        capacity: yup.object().nullable().notRequired().shape({
          chassis: commonCapacitySchema,
          container: commonCapacitySchema,
          trailer: commonCapacitySchema,
          dolly: commonCapacitySchema,
          boat: commonCapacitySchema,
          tractor: commonCapacitySchema,
          reefer: commonCapacitySchema,
          vehicle: commonCapacitySchema,
          other: commonCapacitySchema,
        }),
      }),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    values: omitBy(initialValues, isNil),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
