import { Box, Skeleton } from "@mui/material";

export const HorizontalBarChartOverlay = () => {
  const overlayLeftBarValues = [
    // width of left bars
    // Percentages because we need dynamic responsiveness
    "60%",
    "45%",
    "35%",
    "25%",
    "20%",
    "70%",
  ];

  return (
    <Box
      data-testid="loading-overlay-container"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {overlayLeftBarValues.map((value, index) => (
        <Box display="flex" justifyContent="space-between" key={value}>
          <Skeleton
            variant="rounded"
            data-testid={`overlay-left-bar-${index}`}
            sx={{
              width: value,
              marginTop: "20px",
              marginBottom: "20px",
              height: "42px",
            }}
          />

          <Skeleton
            variant="rounded"
            data-testid={`overlay-right-bar-${index}`}
            sx={{
              height: "42px",
              width: "80px",
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
