import { CheckCircle, Circle } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { kebabCase } from "lodash";

const inverseMap: Record<string, string> = {
  "#F2F2F2": "#464646",
};

const BatteryChartLegend = ({
  label,
  color,
  checked,
  onClick,
}: {
  color: string;
  label: string;
  checked: boolean;
  onClick: () => void;
}) => {
  const textColor = inverseMap[color] || "#fff";

  return (
    <Chip
      label={label}
      onClick={onClick}
      data-testid={`battery-chart-legend-${kebabCase(label)}`}
      icon={
        checked ? (
          <CheckCircle
            data-testid="battery-chart-legend-checked-svg"
            sx={{
              "&.MuiChip-icon.MuiSvgIcon-root": {
                color: textColor,
              },
            }}
          />
        ) : (
          <Circle
            data-testid="battery-chart-legend-unchecked-svg"
            sx={{
              "&.MuiChip-icon.MuiSvgIcon-root": {
                color: textColor,
              },
            }}
          />
        )
      }
      className="!h-10 !rounded-full !px-2"
      sx={{
        backgroundColor: color,
        "&:hover": {
          backgroundColor: color,
        },
        color: textColor,
      }}
    />
  );
};

export default BatteryChartLegend;
