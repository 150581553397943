import { useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { DashboardLibraryWidget } from "../../../../graphql/operations";
import { Button as SubmitButton } from "../../../../shared/components/Button";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";

export interface SaveDiscardChangesPopupProps {
  newlyAddedWidgets: Partial<DashboardLibraryWidget>[];
  onSave: () => void;
  onDiscard: () => void;
  processing: boolean;
}

const SaveDiscardChangesPopup = ({
  newlyAddedWidgets,
  onSave,
  onDiscard,
  processing,
}: SaveDiscardChangesPopupProps) => {
  const isMobile = useBreakpoint("down", "sm");

  const mainContainerStyles = useMemo(
    () =>
      isMobile
        ? {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }
        : {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },
    [isMobile]
  );

  return (
    <>
      {newlyAddedWidgets.length > 0 && (
        <Box
          className="mt-1"
          data-testid="page-dashboard-apply-changes-popup"
          sx={{
            ...mainContainerStyles,
            borderTop:
              "1px solid var(--dashboard-view-apply-changes-popup-border)",
            borderBottom:
              "1px solid var(--dashboard-view-apply-changes-popup-border)",
            backgroundColor: "var(--dashboard-view-header-background)",
          }}
        >
          <Box className="p-4">
            <Typography
              sx={
                isMobile
                  ? { textAlign: "center", color: "var(--primary)" }
                  : { color: "var(--primary)" }
              }
            >
              You have made changes to the dashboard. Would you like to save
              them?
            </Typography>
          </Box>
          <Box className="flex flex-row p-4">
            <Box>
              <Button
                className="!mr-2 !text-base !font-bold !text-brand global-btn !rounded-[100px] !border-2 !border-solid !py-1 !text-sm !font-bold !text-primary"
                variant={"text"}
                type="button"
                onClick={onDiscard}
              >
                Discard
              </Button>
            </Box>
            <Box>
              <SubmitButton
                sx={{ margin: 0, fontSize: "14px !important" }}
                text={processing ? "Saving..." : "Save"}
                disabled={processing}
                theme="blue"
                variant="default"
                type="submit"
                onClick={onSave}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SaveDiscardChangesPopup;
