import { Box, Skeleton } from "@mui/material";

export const PieChartWidgetOverlay = () => {
  return (
    <Box
      data-testid="loading-overlay-container"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Skeleton
          variant="circular"
          sx={{
            aspectRatio: "1/1",
            width: "70%",
            height: "70%",
          }}
        ></Skeleton>
        <Box>
          {[...Array(6).keys()].map((_, index) => (
            <Skeleton
              data-testid={`side-bar-${index}`}
              variant="rounded"
              height="42px"
              width="100px"
              key={index}
              sx={{
                marginBottom: "20px",
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
