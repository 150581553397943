import {
  ReportsTableData,
  TableColumnFormat,
} from "../../../../graphql/operations";
import Status from "../../../../shared/components/Status";
import { Info, Success, Warning } from "../../../../shared/components/Svg";
import {
  getTableColumn,
  TableColumnProps,
  TableGridColDef,
} from "../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../utils";

export const columnVisibilityModel = {
  name: true,
  type: true,
  orgName: true,
  createdBy: true,
  addedAt: true,
  lastRunAt: true,
  updatedAt: true,
  scheduleStatus: true,
  scheduled: true,
};

export const getColumns = (
  timezone: string
): TableGridColDef<ReportsTableData>[] => {
  const columns: TableColumnProps<ReportsTableData>[] = [
    { field: "name", headerName: "Report Name" },
    { field: "type", headerName: "Report Type" },
    {
      field: "orgName",
      headerName: "Organization",
      options: { filterable: false },
    },
    { field: "createdBy", headerName: "Created By" },
    {
      field: "addedAt",
      headerName: "Created Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "lastRunAt",
      headerName: "Last Run",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "scheduled",
      headerName: "Scheduled",
      type: "string",
      format: TableColumnFormat.String,
    },
    {
      field: "scheduleStatus",
      headerName: "Schedule Status",
      type: "string",
      format: TableColumnFormat.String,
      options: {
        minWidth: 130,
        renderCell: ({ row: { scheduleStatus, scheduled } }) =>
          scheduled === "Yes" ? (
            <Status
              bgColor={getBgColor(scheduleStatus)}
              icon={getIcon(scheduleStatus)}
              text={scheduleStatus}
              maxWidth={50}
            />
          ) : null,
      },
    },
  ];

  return columns.map(getTableColumn);
};

enum ReportStatusText {
  Success = "Success",
  Error = "Error",
  Scheduled = "Scheduled",
  Processing = "Processing",
}

const getBgColor = (text: string) => {
  if (text === ReportStatusText.Success) {
    return "bg-green";
  }
  if (text === ReportStatusText.Error) {
    return "bg-caution";
  }
  if (text === ReportStatusText.Processing) {
    return "bg-dark-blue";
  }
  return "bg-dark-grey";
};

const getIcon = (text: string) => {
  if (text === ReportStatusText.Success) {
    return <Success />;
  }
  if (text === ReportStatusText.Error) {
    return <Warning />;
  }
  return <Info width="12px" height="12px" />;
};
