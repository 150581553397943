import * as yup from "yup";
import { ConfigurationSetType } from "../../../../../graphql/operations";
import { transformers } from "../../../../../utils";

enum Manufacturer {
  Bendix = "Bendix",
  Haldex = "Haldex",
  Hendrickson = "Hendrickson",
  Holland = "Holland",
  Maxon = "Maxon",
  Meritor = "Meritor",
  SAF = "SAF",
  Thieman = "Thieman",
  Wabco = "Wabco",
  Waltco = "Waltco",
  Unknown = "Unknown",
}

// Define the enum for Fault Code Type
export enum FaultCodeType {
  ABS = "abs",
  AirTank = "airTank",
  InternalCamera = "internalCamera",
  Liftgate = "liftgate",
  Other = "other",
}

export const uploadFaultCodeSchema = yup.object().shape({
  name: yup.string().required().transform(transformers.string),
  manufacturer: yup
    .string()
    .oneOf(Object.values(Manufacturer))
    .optional()
    .transform(transformers.string),
  faultCodeType: yup
    .string()
    .oneOf(Object.values(FaultCodeType))
    .required("Fault Code Type is a required field")
    .transform(transformers.string),
  pgn: yup.number().optional().transform(transformers.number),
  dtc: yup.string().optional().transform(transformers.string),
  sid: yup.number().optional().transform(transformers.number),
  fmi: yup.number().optional().transform(transformers.number),
  problemArea: yup.string().optional(),
  cause: yup.string().optional(),
  description: yup.string().required("Description is a required field"),
  pctExplains: yup.string().optional(),
});
