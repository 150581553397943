import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../../shared/hooks/theme/useCurrentTheme";

export const useAssetManagementTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "capitalize",
              fontWeight: 700,
              fontSize: 16,
              lineHeight: "100%",
            },
            contained: {
              backgroundColor: "var(--primary-blue) !important",
              borderRadius: "9999px !important",
              minWidth: "16rem",
            },
          },
        },
      },
    })
  );
};
